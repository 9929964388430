import { Box, styled } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import React, { useEffect, useState } from 'react';

interface NestedHeading {
  id: string;
  title: string;
  items: NestedHeading[];
}

const getNestedHeadings = (headingElements) => {
  const nestedHeadings: NestedHeading[] = [];

  headingElements.forEach((heading) => {
    const { innerText: title, id } = heading;

    if (heading.nodeName === 'H2') {
      nestedHeadings.push({ id, title, items: [] });
    } else if (heading.nodeName === 'H3' && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
        items: [],
      });
    }
  });

  return nestedHeadings;
};
const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = useState<NestedHeading[]>([]);

  useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll('.section-header h2, h3.section-subheader')
    );

    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return nestedHeadings;
};

const useIntersectionObserver = (setActiveId: (id: string) => void) => {
  const headingElementsRef = React.useRef({});

  useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll('.section-header h2, h3.section-subheader')
    );
    const callback = (headings: IntersectionObserverEntry[]) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        // eslint-disable-next-line no-param-reassign
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      // Get all headings that are currently visible on the page
      const visibleHeadings: IntersectionObserverEntry[] = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id: string) =>
        headingElements.findIndex((heading) => heading.id === id);

      // If there is only one visible heading, this is our "active" heading
      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
        // If there is more than one visible heading,
        // choose the one that is closest to the top of the page
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort((a, b) =>
          getIndexFromId(a.target.id) > getIndexFromId(b.target.id) ? 1 : 0
        );

        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: '0px 0px -60% 0px',
    });

    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId]);
};

const Ul = styled('ul')(() => ({
  fontSize: 12,
  listStyle: 'none',
  padding: '0 0 16px 0',
  textDecoration: 'none',
}));

const SubLi = styled('li')(() => ({
  color: tokens.palette.neutralCool[500],
  textDecoration: 'none',
  marginLeft: 16,
  borderLeft: `2px solid ${tokens.palette.neutralCool[300]}`,
  padding: '6px 0',
  '&.active': {
    borderLeft: 0,
    color: tokens.palette.primaryBlue[600],
  },
  '&:first-child': {
    paddingTop: 0,
    marginTop: 6,
  },
  '&:hover a': {
    color: tokens.palette.neutralCool[800],
  },
  '&.active a': {
    color: tokens.palette.primaryBlue[600],
    fontWeight: 700,
  },
  a: {
    color: tokens.palette.neutralCool[500],
    paddingLeft: 16,
  },
}));

export default function TableOfContents() {
  const [activeId, setActiveId] = useState<string | null>(null);

  const nestedHeadings = useHeadingsData();

  useIntersectionObserver(setActiveId);

  return (
    <Box position="sticky" top={0} alignSelf="flex-start" minWidth={275}>
      <Ul>
        {nestedHeadings.map((heading) => (
          <li key={heading.id}>
            <a
              style={{
                color: tokens.palette.neutralCool[1000],
                fontSize: 14,
                fontWeight: 700,
                textDecoration: 'none',
              }}
              href={`#${heading.id}`}
              onClick={(e) => {
                e.preventDefault();
                document.querySelector(`#${heading.id}`)?.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
            >
              {heading.title}
            </a>
            {heading.items.length > 0 && (
              <Ul>
                {heading.items.map((child) => (
                  <SubLi key={child.id} className={child.id === activeId ? 'active' : ''}>
                    <a
                      style={{
                        textDecoration: 'none',
                      }}
                      href={`#${child.id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        document.querySelector(`#${child.id}`)?.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }}
                    >
                      {child.title}
                    </a>
                  </SubLi>
                ))}
              </Ul>
            )}
          </li>
        ))}
      </Ul>
    </Box>
  );
}
