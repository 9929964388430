import { deleteRestrictionsTemplate } from '~/api/api';
import useEnqueueToast from '../useToast';

export default function useDeleteRestrictionsTemplate() {
  const enqueueToast = useEnqueueToast();

  const deleteTemplate = async (id: string, name: string) => {
    try {
      await deleteRestrictionsTemplate(id);
      enqueueToast({
        severity: 'success',
        title: `You've successfully deleted your restrictions template: ${name}.`,
      });
      return true;
    } catch (e) {
      enqueueToast({
        severity: 'error',
        title: `Failed to delete template: ${name}.`,
        content: `Please reach out to clientservice@vise.com for help.`,
      });
      return false;
    }
  };

  return { deleteTemplate };
}
