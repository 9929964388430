import { Popover as MuiPopover, PopoverProps as MuiPopoverProps } from '@mui/material';
import {
  bindHover,
  bindPopover,
  bindToggle,
  PopupState as MuiPopupState,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { useMemo } from 'react';

export type PopupState = MuiPopupState;

export interface PopoverTriggerProps extends Partial<Omit<MuiPopoverProps, 'title' | 'body'>> {
  id?: string;
  triggerAction?: 'hover' | 'click';
  /** The element that pops up when the popover is trigger */
  overlay: React.ReactNode | ((popupState: PopupState) => React.ReactNode);
  /** The popover trigger must be able to hold a ref. */
  children: React.ReactElement;
}

export default function PopoverTrigger({
  id: customId,
  triggerAction,
  overlay,
  children,
  ...muiPopoverProps
}: PopoverTriggerProps) {
  const popoverId = useMemo(() => {
    return customId || `popover-${Math.floor(Math.random() * 1000000)}`;
  }, [customId]);
  const popupState = usePopupState({ variant: 'popover', popupId: popoverId });
  const triggerActionProps =
    triggerAction === 'hover' ? bindHover(popupState) : bindToggle(popupState);
  return (
    <>
      {React.cloneElement(children, { ...children.props, ...triggerActionProps, triggerAction })}
      <MuiPopover
        {...bindPopover(popupState)}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        elevation={3}
        {...muiPopoverProps}
        style={
          triggerAction === 'hover'
            ? { ...muiPopoverProps.style, pointerEvents: 'none' }
            : muiPopoverProps.style
        }
        PaperProps={
          triggerAction === 'hover'
            ? {
                ...muiPopoverProps.PaperProps,
                style: { pointerEvents: 'auto', ...muiPopoverProps.PaperProps?.style },
              }
            : muiPopoverProps.PaperProps
        }
      >
        {typeof overlay === 'function' ? overlay(popupState) : overlay}
      </MuiPopover>
    </>
  );
}
