import { DataSource } from '../models/shared';

/** Wrapper for swr key generators - format keys depending on data source
 * so that the right keys are cleared upon data mutations
 */
const generateSwrKey = (rawKey: string, dataSource = 'api' as DataSource): string => {
  return `${dataSource}-${rawKey}`;
};

export default generateSwrKey;
