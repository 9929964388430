import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Unstable_Grid2 as Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { calculateTaxForBondProposal } from '~/api/api';
import { InputDollar } from '~/components';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useEnqueueToast from '~/hooks/useToast';
import { BondScreenProps } from '~/routes/BondPortfolioCreator/BondPortfolioState';
import SummaryPanel from '~/routes/BondPortfolioCreator/SummaryPanel';
import { STATES } from '~/routes/BondPortfolioCreator/states';
import { routeUrls } from '~/routes/BondPortfolioCreator/steps';
import { ReactComponent as CalculatorIcon } from '~/static/images/icons/calculator.svg';
import { ReactComponent as QuestionIcon } from '~/static/images/icons/question-mark-circle.svg';
import HelpButton from '~/synth/Button';
import TextField from '~/synth/TextField';
import amplitude from '~/utils/amplitude';
import StepTitleCard from './components/StepTitleCard';

export default function Taxes({ dispatch, footer, state, summaryPanelRef }: BondScreenProps) {
  useEffect(() => {
    amplitude().logEvent('Bond Builder - Taxes screen', {
      category: EVENT_CATEGORIES.BOND_BUILDER,
      accountId: state.account?.id,
      cashValue: state.cashValue,
    });
  }, [state.cashValue, state.account?.id]);
  const history = useHistory();
  const [isCalculating, setIsCalculating] = useState(false);

  const { control, handleSubmit, setValue, getValues, watch, resetField } = useForm({
    defaultValues: {
      ...state.taxes,
    },
  });

  const onSubmit = (data) => {
    dispatch({ type: 'SELECT_TAX_INFORMATION', value: data });
    history.push(routeUrls.BUILD);
  };

  const taxRateRules = {
    min: { value: 0, message: 'Tax rate must be ≥ 0%' },
    required: { value: true, message: 'This field is required' },
  };

  const watchKnowTaxRate = watch('knowTaxRate');
  const watchState = watch('state');
  const watchTaxFillingStatus = watch('taxFillingStatus');
  const watchStateRate = watch('estimatedStateRate');
  const watchFederalRate = watch('estimatedFederalRate');
  const watchIsAlternativeMinimumTax = watch('isAlternativeMinimumTax');
  const watchEstimatedIncome = watch('estimatedIncome');
  const toast = useEnqueueToast();

  useEffect(() => window.scrollTo({ top: 400, behavior: 'smooth' }), [watchKnowTaxRate]);

  const canRecalculate = Boolean(
    watchKnowTaxRate === 'false' &&
      watchState &&
      watchTaxFillingStatus &&
      watchIsAlternativeMinimumTax &&
      watchEstimatedIncome
  );

  const canSubmit = Boolean(watchFederalRate && watchStateRate);

  async function handleCalculate() {
    setIsCalculating(true);
    try {
      const {
        data: { stateRate, federalRate },
      } = await calculateTaxForBondProposal({
        filingState: watchState,
        filingStatus: watchTaxFillingStatus as 'single' | 'joint',
        grossIncome: Number.parseInt(watchEstimatedIncome, 10),
        subjectToAmt: watchIsAlternativeMinimumTax === 'true',
      });
      setValue('estimatedFederalRate', (federalRate * 100).toFixed(2));
      setValue('estimatedStateRate', (stateRate * 100).toFixed(2));
    } catch (e) {
      toast({
        title: 'There was an error calculating the tax rates.',
        content: 'Please try again or manually enter tax rates.',
        severity: 'error',
      });
    } finally {
      setIsCalculating(false);

      // we need to clear isDirty so that we can detect when inputs change
      // and force user to calculate again
      resetField('state', { defaultValue: watchState });
      resetField('taxFillingStatus', { defaultValue: watchTaxFillingStatus });
      resetField('isAlternativeMinimumTax', { defaultValue: watchIsAlternativeMinimumTax });
      resetField('estimatedIncome', { defaultValue: watchEstimatedIncome });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StepTitleCard
        title="Now, let's determine your client's tax rate."
        description="These inputs are required to optimize your client's bond portfolio."
      />
      <Card sx={{ marginBottom: 8 }}>
        <CardContent>
          <Grid container spacing={4} mb={4}>
            <Grid xs={12}>
              <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h4">Don&apos;t know your client&apos;s tax rate?</Typography>
                <HelpButton
                  onClick={() => {
                    setValue('knowTaxRate', 'false');
                  }}
                  color="primary"
                  size="small"
                  startIcon={<QuestionIcon />}
                  sx={{ ml: 1 }}
                >
                  Assist me
                </HelpButton>
              </Box>
            </Grid>
            {watchKnowTaxRate === 'false' && (
              <Grid container spacing={4}>
                <Grid xs={6}>
                  <InputLabel>Tax filing state</InputLabel>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Select
                        fullWidth
                        {...field}
                        error={fieldState.invalid}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                      >
                        {STATES.map((s) => (
                          <MenuItem
                            key={s.abbreviation}
                            value={s.abbreviation}
                          >{`${s.abbreviation} - ${s.name}`}</MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid xs={6}>
                  <InputLabel>Tax filing status</InputLabel>
                  <Controller
                    name="taxFillingStatus"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <Select fullWidth {...field} error={fieldState.invalid}>
                          <MenuItem value="single">Single</MenuItem>
                          <MenuItem value="joint">Joint</MenuItem>
                        </Select>
                      </>
                    )}
                  />
                </Grid>
                <Grid xs={6}>
                  <Controller
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputLabel>Subject to alternative minimum tax (AMT)</InputLabel>
                        <Select fullWidth {...field} error={fieldState.invalid}>
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      </>
                    )}
                    name="isAlternativeMinimumTax"
                  />
                </Grid>
                <Grid xs={6}>
                  <Controller
                    render={({ field, fieldState }) => (
                      <>
                        <InputLabel>Gross income</InputLabel>
                        <TextField
                          {...field}
                          fullWidth
                          error={fieldState.invalid}
                          autoComplete="off"
                          InputProps={{ inputComponent: InputDollar }}
                        />
                      </>
                    )}
                    name="estimatedIncome"
                    control={control}
                  />
                </Grid>
                <Grid xs={12}>
                  <Alert severity="info">
                    This tax calculator is for reference only. All calculations & outputs should not
                    be interpreted as personalized investment or tax advice from Vise.
                  </Alert>
                </Grid>

                <Grid xs={12}>
                  <Box display="flex" justifyContent="end" width="100%">
                    <LoadingButton
                      color="secondary"
                      startIcon={<CalculatorIcon />}
                      loading={isCalculating}
                      variant="outlined"
                      disabled={!canRecalculate}
                      onClick={handleCalculate}
                    >
                      Calculate
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid xs={6}>
              <InputLabel required>Federal tax rate</InputLabel>
              <Controller
                render={({ field, fieldState }) => (
                  <TextField
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    {...field}
                    autoComplete="off"
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={Boolean(fieldState.error) && fieldState.error?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setValue('state', '');
                      setValue('taxFillingStatus', '');
                      setValue('isAlternativeMinimumTax', '');
                      setValue('estimatedIncome', '');
                    }}
                  />
                )}
                control={control}
                rules={{ ...taxRateRules, max: { value: 50, message: 'Tax rate must be ≤ 50%' } }}
                name="estimatedFederalRate"
              />
            </Grid>

            <Grid xs={6}>
              <InputLabel required>State tax rate</InputLabel>
              <Controller
                rules={{ ...taxRateRules, max: { value: 20, message: 'Tax rate must be ≤ 20%' } }}
                render={({ field, fieldState }) => (
                  <TextField
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    {...field}
                    autoComplete="off"
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={Boolean(fieldState.error) && fieldState.error?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setValue('state', '');
                      setValue('taxFillingStatus', '');
                      setValue('isAlternativeMinimumTax', '');
                      setValue('estimatedIncome', '');
                    }}
                  />
                )}
                control={control}
                name="estimatedStateRate"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box sx={{ my: 4 }}>
        {footer &&
          footer(
            <>
              <div />
              <Button type="submit" variant="contained" disabled={!canSubmit}>
                Continue
              </Button>
            </>
          )}
        {summaryPanelRef?.current &&
          createPortal(
            <SummaryPanel state={{ ...state, taxes: getValues() }} />,
            summaryPanelRef.current
          )}
      </Box>
    </form>
  );
}
