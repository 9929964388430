import isNil from 'lodash/isNil';

type ConvertArrayOfObjectsToCSVReq = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  columnDelimiter?: string;
  lineDelimiter?: string;
};

export const convertArrayOfObjectsToCSV = (req: ConvertArrayOfObjectsToCSVReq) => {
  const { data, columnDelimiter = ',', lineDelimiter = '\n' } = req;
  if (data == null || !data.length) {
    return null;
  }

  const keys = Object.keys(data[0]);
  const rows = data.map((item) =>
    keys
      .map((key) => (isNil(item[key]) ? '' : JSON.stringify(item[key]))) // use empty string for null
      .join(columnDelimiter)
  );

  const headerRow = keys.join(columnDelimiter);
  rows.unshift(headerRow);

  return rows.join(lineDelimiter);
};

type GenerateDownloadReq = { data: string; mimetype: string; filename: string };
export const generateDownload = ({ data, mimetype, filename }: GenerateDownloadReq) => {
  const encoded = `data:${mimetype},${encodeURIComponent(data)}`;

  const link = document.createElement('a');
  link.setAttribute('href', encoded);
  link.setAttribute('download', filename);
  link.click();
};

export const exportCSV = ({ data, filename = 'export.csv' }) => {
  const csv = convertArrayOfObjectsToCSV({ data });
  if (csv == null) {
    return;
  }

  generateDownload({ data: csv, mimetype: 'text/csv;charset=utf-8', filename });
};

export const generateCsvDownload = ({ csv, filename }) => {
  generateDownload({ data: csv, mimetype: 'text/csv;charset=utf-8', filename });
};
