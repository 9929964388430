import { Box, Container, Divider, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Switch, useParams, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import useAccount from '~/hooks/useAccount';
import useBondPortfolioForAccount from '~/hooks/useBondPortfolioForAccount';
import useBondPortfolioData from '~/hooks/useBondPortfolioData';
import useBondPortfolioSampleData from '~/hooks/useBondPortfolioSampleData';
import useClient from '~/hooks/useClient';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import { getCustodianDisplayName, maskAccountNumber } from '~/utils/format';
import {
  BondPortfolioData,
  HighQualityBondPortfolioSampleOption,
  LadderBondPortfolioSampleOption,
  BondPortfolioSampleOptionTreasury,
} from 'vise-types/portfolio';
import LastUpdated from '../Portfolio/LastUpdated';
import SentryRoute from '../SentryRoute';
import BondPortfolioAllocation from './BondPortfolioAllocation';
import BondPortfolioInputs from './BondPortfolioInputs';
import BondPortfolioOverview from './BondPortfolioOverview';
import { ProposalLoading } from './BondProposal';

/* eslint-disable no-nested-ternary */

export default function BondPortfolio() {
  const { accountId } = useParams<{ accountId: string }>();

  const routeMatch = useRouteMatch([`/secure/accounts/${accountId}/bond-portfolio/:tabName`]);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tabName' does not exist on type '{}'.
  const tabName = routeMatch?.params?.tabName;

  const { data: accountData } = useAccount(accountId, true);
  const { data: portfolioData } = useBondPortfolioForAccount(accountId);

  let portfolioParameters: Parameters<typeof useBondPortfolioData>[0] | undefined;
  if (accountData?.data.engineType === 'AB_FIXED_INCOME' && portfolioData?.data.id) {
    portfolioParameters = { accountId } as {
      accountId: string;
      portfolioId: never;
    };
  } else if (
    accountData?.data.engineType != null &&
    // HTTP response returned
    portfolioData != null &&
    // No bond portfolio
    portfolioData.data?.id == null
  ) {
    throw new Error(`invalid engine type '${accountData.data.engineType}' for bond portfolio`);
  }

  const { data: bondPortfolioDataResponse } = useBondPortfolioData(portfolioParameters);

  let aum: number | null | undefined;
  let settings:
    | LadderBondPortfolioSampleOption
    | HighQualityBondPortfolioSampleOption
    | BondPortfolioSampleOptionTreasury
    | undefined;

  if (portfolioData?.data) {
    settings = {
      // @ts-ignore
      instrumentType: portfolioData.data.instrumentType,

      ...(portfolioData.data.instrumentType === 'MUNICIPAL'
        ? {
            productState: portfolioData.data.stateStrategy,
            creditQuality: portfolioData.data.creditQuality,

            ...(portfolioData.data.duration != null
              ? { duration: portfolioData.data.duration }
              : portfolioData.data.maturity != null
              ? { maturity: portfolioData.data.maturity }
              : ({} as never)),
          }
        : portfolioData.data.instrumentType === 'TREASURY'
        ? {
            maturity: portfolioData.data.maturity,
          }
        : ({} as never)),
    };
  }
  if (accountData?.data) {
    aum = accountData.data.cachedAum;
  }

  const { data: sampleDataResponse } = useBondPortfolioSampleData(settings, aum);
  const { data: clientData } = useClient(accountData?.data.viseClientId);

  if (
    accountData == null ||
    portfolioData == null ||
    (bondPortfolioDataResponse == null && sampleDataResponse == null) ||
    clientData == null
  ) {
    return <ProposalLoading />;
  }

  const account = accountData.data;
  const bondPortfolioData = (bondPortfolioDataResponse?.data ||
    sampleDataResponse?.data) as BondPortfolioData;
  const portfolio = portfolioData.data;

  return (
    <>
      <Helmet>
        <title>Portfolio</title>
      </Helmet>
      <Box pt={3}>
        <Container>
          <Box display="flex" justifyContent="space-between" pb={3}>
            <Box>
              <PathBreadcrumbs
                path={[
                  ...(clientData.clientGroup != null
                    ? [
                        {
                          name: clientData.clientGroup?.name ?? '',
                          to: `/secure/households/${clientData.clientGroup?.id}/overview`,
                        },
                      ]
                    : []),
                  { name: account.accountName },
                ]}
                ariaLabel="Page path breadcrumbs"
              />
              <Box mb={1} mt={2}>
                <Typography variant="h2" component="h1">
                  {account.accountName}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box display="inline-block" mr={1.5}>
                  <Typography variant="body1" color="textSecondary">
                    {account.taxable ? 'Non-qualified' : 'Qualified'}
                  </Typography>
                </Box>
                <Box bgcolor="grey.300" alignSelf="stretch">
                  <Divider orientation="vertical" flexItem />
                </Box>
                <Box ml={1.5}>
                  <Typography variant="body1" color="textSecondary">
                    {maskAccountNumber(account.accountNumber)},{' '}
                    {getCustodianDisplayName(account.custodianKey)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
        <Box border={1} borderLeft={0} borderRight={0} borderColor="grey.200">
          <Container>
            <Box display="flex" justifyContent="space-between">
              <Tabs value={tabName || 'overview'} aria-label="Portfolio tabs">
                <Tab
                  value="overview"
                  label="Overview"
                  id="tab-overview"
                  to={`/secure/accounts/${accountId}/bond-portfolio/overview`}
                  aria-controls="overview-screen"
                  component={Link}
                />
                <Tab
                  value="allocation"
                  label="Allocation"
                  id="tab-allocation"
                  to={`/secure/accounts/${accountId}/bond-portfolio/allocation`}
                  aria-controls="allocation-screen"
                  component={Link}
                />
                <Tab
                  value="inputs"
                  label="Inputs"
                  id="tab-inputs"
                  to={`/secure/accounts/${accountId}/bond-portfolio/inputs`}
                  aria-controls="inputs-screen"
                  component={Link}
                />
              </Tabs>
              {bondPortfolioData.metrics.tradeDate != null && (
                // This should be rare
                <Box display="flex" alignItems="center" alignSelf="center">
                  <LastUpdated date={new Date(bondPortfolioData.metrics.tradeDate)} hideTooltip />
                </Box>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
      <Container>
        <Box pt={4} pb={8}>
          <Switch>
            <SentryRoute exact path="/secure/accounts/:accountId/bond-portfolio/overview">
              <Box id="overview-screen" aria-labelledby="tab-overview" role="tabpanel">
                <BondPortfolioOverview
                  settings={portfolio}
                  metrics={bondPortfolioData.metrics}
                  cashFlow={bondPortfolioData.cashFlows}
                  account={account}
                  holdings={bondPortfolioData.holdings}
                />
              </Box>
            </SentryRoute>
            <SentryRoute exact path="/secure/accounts/:accountId/bond-portfolio/allocation">
              <Box id="allocation-screen" aria-labelledby="tab-allocation" role="tabpanel">
                <BondPortfolioAllocation
                  instrumentType={portfolio.instrumentType}
                  metrics={bondPortfolioData.metrics}
                  muniUses={bondPortfolioData.muniUses}
                  creditRating={bondPortfolioData.creditQuality}
                  holdings={bondPortfolioData.holdings}
                />
              </Box>
            </SentryRoute>
            <SentryRoute exact path="/secure/accounts/:accountId/bond-portfolio/inputs">
              <Box id="allocation-screen" aria-labelledby="tab-allocation" role="tabpanel">
                <BondPortfolioInputs settings={portfolio} />
              </Box>
            </SentryRoute>

            <SentryRoute path="/secure/accounts/:accountId/bond-portfolio">
              <Redirect to={`/secure/accounts/${accountId}/bond-portfolio/overview`} />
            </SentryRoute>
          </Switch>
        </Box>
      </Container>
    </>
  );
}
