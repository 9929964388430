import useSWR from 'swr';
import { getTreatments, getTreatmentsWithConfig } from '../api/featureFlag';
import generateSwrKey from '../api/generateSwrKey';

// Currently, this is called in the MainLayout, the facade of almost all components.
// Then, the results are cached and reused.
// The feature flags are reloaded on the remounting of the MainLayout.
const useFeatureFlags = (shouldRevalidate = false) => {
  const { data, error, isValidating, mutate } = useSWR(
    generateSwrKey('featureFlags', 'misc_immutable'),
    () => getTreatments(),
    // These options prevent refreshing and revalidation
    // util another page load.
    {
      revalidateOnFocus: false,
      revalidateOnMount: shouldRevalidate,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    }
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export const useFeatureFlagsWithConfig = () => {
  return useSWR(generateSwrKey('featureFlagsWithConfig', 'misc_immutable'), () =>
    getTreatmentsWithConfig()
  );
};

export default useFeatureFlags;
