import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { ValueType } from 'react-select';
import { Cadence, DistributionSchedule, DistributionScheduleSchema } from 'vise-types/distribution';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { Account } from 'vise-types/portfolio';
import { InputDollar } from '~/components';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { ReactComponent as ArrowLeftIcon } from '~/static/images/icons/arrow-left.svg';
import { ReactComponent as CheckCircleIcon } from '~/static/images/icons/check-circle.svg';
import { ReactComponent as ExclamationCircleIcon } from '~/static/images/icons/exclamation-circle.svg';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { ReactComponent as XCircleIcon } from '~/static/images/icons/x-circle.svg';
import Banner from '~/synth/Banner';
import DatePicker from '~/synth/DatePicker';
import { TextHighlightTag } from '~/synth/Tag';
import TextField from '~/synth/TextField';
import Select from '~/synth/inputs/Select';
import amplitude from '~/utils/amplitude';
import { formatCurrency } from '~/utils/format';
import { CADENCE_TO_LABEL } from '../Constants';
import { CASH_AVAILABLE_TOOLTIP, CashAvailableAndTooltip } from '../components/UtilComponents';

interface FrequencyOption {
  label: string;
  value: Cadence;
}

type WASH_SALES_INSTRUCITONS_TYPE = 'cancel' | 'allow' | null;

interface FormValues {
  description: string;
  amount: number | null;
  frequency: FrequencyOption | null;
  startsOn: moment.Moment | null;
  endsOn: moment.Moment | null;
  immediateDistribution: boolean;
  washSalesInstructions: WASH_SALES_INSTRUCITONS_TYPE;
}

interface DistributionFormProps {
  account: Account;
  intelligence?: PortfolioIntelligenceFull;
  availableForImmediateDistribution: number;
  availableForRecurringDistribution: number;
  scheduleSchema: DistributionScheduleSchema;
  setNewDistributionStep: (step: number) => void;
  setDistribution: (distribution: DistributionSchedule) => void;
  distribution?: DistributionSchedule;
  isEdit: boolean;
  toSelectAccount: boolean;
}

const frequencyOptions = [
  { label: CADENCE_TO_LABEL.DO_NOT_REPEAT, value: 'DO_NOT_REPEAT' },
  { label: CADENCE_TO_LABEL.MONTHLY, value: 'MONTHLY' },
  { label: CADENCE_TO_LABEL.QUARTERLY, value: 'QUARTERLY' },
  { label: CADENCE_TO_LABEL.YEARLY, value: 'YEARLY' },
] as const;

const CADENCE_TO_EXPLANATION = {
  MONTHLY: 'month',
  QUARTERLY: 'every three months',
  YEARLY: 'year',
} as { [key in Cadence]: string };

export default function DistributionForm({
  account,
  intelligence,
  availableForImmediateDistribution,
  availableForRecurringDistribution,
  scheduleSchema,
  setNewDistributionStep,
  setDistribution,
  distribution,
  isEdit,
  toSelectAccount,
}: DistributionFormProps) {
  const theme = useTheme();

  const immediateProcessOnMinDate = moment(scheduleSchema.processOn.minImmediate);
  const recurringProcessOnMinDate = moment(scheduleSchema.processOn.minRecurring);

  const [processOnMinDate, setProcessOnMinDate] = useState(
    distribution?.disType === 0 ? immediateProcessOnMinDate : recurringProcessOnMinDate
  );

  const [availableForDistribution, setAvailableForDistribution] = useState(
    distribution?.disType === 0
      ? availableForImmediateDistribution
      : availableForRecurringDistribution
  );

  const investmentTimelineMoment = intelligence?.constructionInfo.investmentTimeline
    ? moment(intelligence.constructionInfo.investmentTimeline)
    : null;

  let initialStartsOn = processOnMinDate;
  if (distribution?.nextRuns != null && distribution.nextRuns.length > 0) {
    const nextRun = moment(distribution.nextRuns[0]);
    if (nextRun.isAfter(processOnMinDate)) {
      initialStartsOn = nextRun;
    }
  }
  if (distribution?.processOn != null && moment(distribution.processOn).isAfter(initialStartsOn)) {
    initialStartsOn = moment(distribution.processOn);
  }

  const defaultEndsOn = moment(processOnMinDate).add(1, 'years');

  let initialWashSalesInstructions: WASH_SALES_INSTRUCITONS_TYPE = null;
  if (distribution != null && distribution.allowWashSaleIfNeeded !== undefined) {
    initialWashSalesInstructions = distribution.allowWashSaleIfNeeded ? 'allow' : 'cancel';
  }

  const [endsOnOption, setEndsOnOption] = useState<'never' | 'set'>(
    distribution?.processUntil ? 'set' : 'never'
  );
  const handleEndsOnChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'never' || value === 'set') {
      setEndsOnOption(value);
    }
  };

  const [pleaseSelectOption, setPleaseSelectOption] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      immediateDistribution: distribution?.disType === 0,
      description: distribution?.description ?? '',
      amount: distribution?.amount ?? null,
      frequency: distribution?.cadence
        ? { label: CADENCE_TO_LABEL[distribution.cadence], value: distribution.cadence }
        : null,
      startsOn: initialStartsOn,
      endsOn: distribution?.processUntil ? moment(distribution.processUntil) : defaultEndsOn,
      washSalesInstructions: initialWashSalesInstructions,
    },
    onSubmit(values) {
      if (values.washSalesInstructions == null) {
        setPleaseSelectOption(true);
        return;
      }

      const {
        amount,
        description,
        startsOn,
        endsOn,
        frequency,
        immediateDistribution,
        washSalesInstructions,
      } = values;
      if (
        amount != null &&
        description != null &&
        startsOn != null &&
        frequency != null &&
        endsOn != null
      ) {
        setDistribution({
          accountId: account.id,
          amount,
          description,
          processOn: immediateDistribution ? processOnMinDate.toDate() : startsOn.toDate(),
          cadence: frequency.value,
          processUntil: endsOnOption === 'set' ? endsOn.toDate() : null,
          disType: immediateDistribution ? 0 : 1,
          allowWashSaleIfNeeded: washSalesInstructions === 'allow',
        });
        setNewDistributionStep(1);
      }
    },

    validate(values) {
      const errors: FormikErrors<FormValues> = {};
      // Description
      if (values.description.length > 30) {
        errors.description = `You've reached the maximum character limit (${values.description.length}/30)`;
      } else if (!values.description) {
        errors.description = 'Please enter a description.';
      }

      // Amount
      if (values.amount == null) {
        errors.amount = 'Please enter an amount.';
      } else if (values.amount != null && values.amount > availableForDistribution) {
        errors.amount = toSelectAccount
          ? `Amount must be less than ${formatCurrency(availableForDistribution)}.`
          : 'Amount must be less than the available limit.';
      } else if (values.amount != null && values.amount <= 0) {
        errors.amount = 'Amount cannot be zero.';
      }

      // Frequency
      if (values.frequency == null) {
        errors.frequency = 'Please select a frequency.';
      }

      // Dates
      if (!values.startsOn?.isValid()) {
        errors.startsOn = 'Start date is not valid.';
      } else if (!values.endsOn?.isValid()) {
        errors.endsOn = 'End date is not valid.';
      } else if (
        values.startsOn.isBefore(processOnMinDate) ||
        (investmentTimelineMoment && values.startsOn.isAfter(investmentTimelineMoment))
      ) {
        errors.startsOn = 'Start date is not valid.';
      } else if (
        values.frequency?.value !== 'DO_NOT_REPEAT' &&
        endsOnOption === 'set' &&
        (values.endsOn.isBefore(values.startsOn) ||
          (investmentTimelineMoment && values.endsOn.isAfter(investmentTimelineMoment)))
      ) {
        errors.endsOn = 'End date is not valid.';
      }
      return errors;
    },
  });

  const [distributeOption, setDistributeOption] = useState<'immediately' | 'on'>(
    distribution?.disType === 0 ? 'immediately' : 'on'
  );
  const handleDistributionOption = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'immediately' || value === 'on') {
      setDistributeOption(value);
      formik.setFieldValue('immediateDistribution', value === 'immediately');

      setProcessOnMinDate(
        value === 'immediately' ? immediateProcessOnMinDate : recurringProcessOnMinDate
      );

      setAvailableForDistribution(
        value === 'immediately'
          ? availableForImmediateDistribution
          : availableForRecurringDistribution
      );
    }
  };

  const handleFrequencyChange = (selectedOptionValue: ValueType<FrequencyOption>) => {
    formik.errors.frequency = undefined;
    formik.setFieldValue('frequency', selectedOptionValue as FrequencyOption);
    if ((selectedOptionValue as FrequencyOption).value === 'DO_NOT_REPEAT') {
      formik.setFieldValue('immediateDistribution', distributeOption === 'immediately');
    } else {
      formik.setFieldValue('immediateDistribution', false);
    }
  };

  const washSalesComponent = (
    <Box
      borderRadius={theme.shape.borderRadius}
      sx={{ borderColor: 'grey.200' }}
      border={1}
      p={3}
      mt={2}
      display="flex"
      flexDirection="column"
    >
      <Box ml={1.5}>
        <Typography variant="h4">
          <Box display="flex" flexDirection="row">
            If incurring wash sales is required to fund the distribution, how should Vise proceed?{' '}
            <Box color="error.300" ml={0.5}>
              *
            </Box>
          </Box>
        </Typography>
        <Box mt={2}>
          <RadioGroup
            name="Wash sales radio group"
            value={formik.values.washSalesInstructions}
            onChange={(_: React.ChangeEvent<HTMLInputElement>, value: string) => {
              formik.setFieldValue('washSalesInstructions', value);
            }}
          >
            <FormControlLabel
              value="cancel"
              control={<Radio />}
              label={
                <Box display="flex">
                  <Box>Cancel the scheduled distribution</Box>
                  <TextHighlightTag severity="success" ml={1}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <CheckCircleIcon color={theme.palette.success[400]} height={16} width={16} />
                      <Box ml={0.5} color="grey.800">
                        Recommended
                      </Box>
                    </Box>
                  </TextHighlightTag>
                </Box>
              }
              labelPlacement="end"
            />
            <Box ml={3.5}>
              <Typography variant="body1" color="textSecondary">
                We&apos;ll stop the distribution before it gets executed.
              </Typography>
            </Box>
            <Box mt={1}>
              <FormControlLabel
                value="allow"
                control={<Radio />}
                label={
                  <Box display="flex">
                    <Box>Incur wash sales to generate cash for the distribution</Box>
                    <TextHighlightTag severity="warning" ml={1}>
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <ExclamationCircleIcon
                          color={theme.palette.warning[400]}
                          height={16}
                          width={16}
                        />
                        <Box ml={0.5} color="grey.800">
                          Caution
                        </Box>
                      </Box>
                    </TextHighlightTag>
                  </Box>
                }
              />
            </Box>
            <Box ml={3.5}>
              <Typography variant="body1" color="textSecondary">
                We&apos;ll trade positions that may result in wash sales to raise sufficient cash.
              </Typography>
            </Box>
          </RadioGroup>
        </Box>
      </Box>
      {pleaseSelectOption ? (
        <Typography variant="body2" sx={{ color: 'red', ml: 2, mt: 1 }}>
          Please select an option.
        </Typography>
      ) : null}
    </Box>
  );

  return (
    <>
      {!toSelectAccount ? (
        <Box
          p={3}
          borderRadius={theme.shape.borderRadius}
          sx={{ borderColor: 'grey.200' }}
          border={1}
          mt={3}
          mb={2}
        >
          <>{account.accountName}</>
          <CashAvailableAndTooltip cashAvailable={availableForDistribution} />
        </Box>
      ) : null}
      {account.status !== 'ACTIVE' && (
        <Box mb={3}>
          <Banner
            size="small"
            message={
              <>
                <Box color="primary.main" mr={0.5} display="flex" alignItems="center">
                  <InformationCircleIcon width={16} height={16} />
                </Box>
                The selected account is inactive. Distributions will not be fulfilled until the
                account is restored. Please reach out to clientservice@vise.com for help.
              </>
            }
          />
        </Box>
      )}
      {account.rebalancerStatus === 'PAUSED' && (
        <Box mb={3}>
          <Banner
            size="small"
            message={
              <>
                <Box color="primary.main" mr={0.5} display="flex" alignItems="center">
                  <InformationCircleIcon width={16} height={16} />
                </Box>
                The rebalancer is paused for the selected account. Scheduled distributions will not
                be fulfilled if current cash does not meet the need. The rebalancer will still raise
                cash for any immediate distribution needs.
              </>
            }
          />
        </Box>
      )}
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <>
          <Box
            p={3}
            borderRadius={theme.shape.borderRadius}
            sx={{ borderColor: 'grey.200' }}
            border={1}
          >
            <Box mb={3}>
              <TextField
                id="description"
                label="Description"
                name="description"
                placeholder="Give this distribution a name"
                helperText={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : `Character limit: ${formik.values.description.length}/30`
                }
                fullWidth
                value={formik.values.description}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
              />
            </Box>
            <Box mb={0.5} display="flex">
              <Typography variant="h4">Amount</Typography>
              {toSelectAccount ? (
                <Box color="grey.600" mt={0.25} display="flex">
                  <Tooltip title={CASH_AVAILABLE_TOOLTIP} placement="top">
                    <Box ml={0.5}>
                      <InformationCircleIcon />
                    </Box>
                  </Tooltip>
                </Box>
              ) : null}
            </Box>
            <TextField
              id="amount"
              name="amount"
              helperText={
                (formik.touched.amount && formik.errors.amount) ??
                (toSelectAccount && `Available: ${formatCurrency(availableForDistribution)}`)
              }
              InputProps={{ inputComponent: InputDollar }}
              fullWidth
              value={formik.values.amount}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
            />
            <Box my={3}>
              <Box mb={0.5}>
                <Typography variant="h4">Frequency</Typography>
              </Box>
              <Select
                id="frequency"
                name="frequency"
                error={formik.touched.frequency && Boolean(formik.errors.frequency)}
                options={frequencyOptions}
                onBlur={() => formik.setTouched({ frequency: true })}
                onChange={handleFrequencyChange}
                defaultValue={
                  distribution?.cadence
                    ? { label: CADENCE_TO_LABEL[distribution.cadence], value: distribution.cadence }
                    : null
                }
              />
              {/* Select does not have built in helper text */}
              {formik.values.frequency && formik.values.frequency.value !== 'DO_NOT_REPEAT' && (
                <Box color="grey.500" fontSize={12} mt={1}>
                  Recurs every {CADENCE_TO_EXPLANATION[formik.values.frequency.value]} on the
                  selected date.
                </Box>
              )}
              {formik.touched.frequency && formik.values.frequency == null && (
                <Box color="error.main" mt={1} display="flex">
                  <XCircleIcon width={13} height={13} />
                  <Box ml={0.6} fontSize={12}>
                    {formik.errors.frequency}
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              hidden={
                formik.values.frequency?.value == null ||
                formik.values.frequency.value !== 'DO_NOT_REPEAT'
              }
            >
              <Box mt={3}>
                <Box mb={1}>
                  <Typography variant="h4">Date to distribute</Typography>
                </Box>
                <RadioGroup
                  name="Date to distribute radio group"
                  value={distributeOption}
                  onChange={handleDistributionOption}
                >
                  <FormControlLabel value="immediately" control={<Radio />} label="Immediately" />
                  <Box display="flex" alignItems="center">
                    <FormControlLabel value="on" control={<Radio />} label="On" />
                    <DatePicker
                      name="startsOn"
                      fullWidth
                      required
                      disabled={processOnMinDate == null || distributeOption === 'immediately'}
                      minDate={processOnMinDate}
                      maxDate={investmentTimelineMoment}
                      placeholder="Select a date"
                      value={formik.values.startsOn}
                      onChange={(date: moment.Moment | null) =>
                        formik.setFieldValue('startsOn', date == null ? null : date)
                      }
                      onBlur={formik.handleBlur}
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </Box>
            <Box
              hidden={
                formik.values.frequency?.value == null ||
                formik.values.frequency.value === 'DO_NOT_REPEAT'
              }
            >
              <DatePicker
                id="startsOn"
                label="Starts on"
                name="startsOn"
                fullWidth
                required
                disabled={processOnMinDate == null}
                minDate={processOnMinDate}
                maxDate={investmentTimelineMoment}
                placeholder="Select a date"
                value={formik.values.startsOn}
                onChange={(date: moment.Moment | null) =>
                  formik.setFieldValue('startsOn', date == null ? null : date)
                }
                onBlur={formik.handleBlur}
              />
              <Box mt={3}>
                <Box mb={1}>
                  <Typography variant="h4">Ends on</Typography>
                </Box>
                <RadioGroup
                  name="Distribution ends on radio group"
                  value={endsOnOption}
                  onChange={handleEndsOnChange}
                >
                  <FormControlLabel value="never" control={<Radio />} label="Never" />
                  <Box display="flex" alignItems="center">
                    <FormControlLabel value="set" control={<Radio />} label="On" />
                    <DatePicker
                      disabled={endsOnOption === 'never'}
                      minDate={formik.values.startsOn}
                      maxDate={investmentTimelineMoment}
                      name="processOn"
                      onChange={(date: moment.Moment | null) =>
                        formik.setFieldValue('endsOn', date)
                      }
                      placeholder="Select a date"
                      value={formik.values.endsOn}
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </Box>
          </Box>
          {washSalesComponent}
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button
              startIcon={<ArrowLeftIcon />}
              onClick={() => {
                amplitude().logEvent('Action - Exit distribution form', {
                  category: EVENT_CATEGORIES.DISTRIBUTIONS,
                });
                history.back();
              }}
            >
              Back
            </Button>
            {account.status !== 'ACTIVE' && formik.values.immediateDistribution ? (
              <Tooltip
                title="There is a problem with this account and it cannot be traded. Recurring distributions can still be scheduled, but trades to generate cash cannot
          be made until the account issue is resolved."
              >
                <span>
                  <Button disabled>Continue</Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={() =>
                  amplitude().logEvent(
                    `Action - Tap Continue button for ${isEdit ? 'edit' : 'add new'} distribution `,
                    { category: EVENT_CATEGORIES.DISTRIBUTIONS }
                  )
                }
              >
                Continue
              </Button>
            )}
          </Box>
        </>
      </form>
    </>
  );
}
