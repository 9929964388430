import {
  Box,
  BoxProps,
  Button,
  Container,
  Divider,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { isValid } from 'date-fns';
import { keyBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Redirect, Switch, useHistory } from 'react-router';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { getPortfolioPdf } from '~/actions/portfolioIntelligence';
import { executePortfolioIntelligence } from '~/api/api';
import SomethingWentWrong from '~/components/SomethingWentWrong';
import ExecutePortfolio from '~/components/modal/ExecutePortfolio';
import UpdateXrayAccess, { XrayAccessTag } from '~/components/modal/UpdateXrayAccess';
import UploadImage from '~/components/modal/UploadImage';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAllocationsTemplate from '~/hooks/templates/useAllocationsTemplate';
import useRestrictionsTemplates from '~/hooks/templates/useRestrictionsTemplates';
import useAccount from '~/hooks/useAccount';
import useCapitalGains from '~/hooks/useCapitalGains';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useHousehold from '~/hooks/useHousehold';
import usePortfolioIntelligence from '~/hooks/usePortfolioIntelligence';
import usePortfolioMetrics from '~/hooks/usePortfolioMetrics';
import useProductOnboarding from '~/hooks/useProductOnboarding';
import useToast from '~/hooks/useToast';
import useUser from '~/hooks/useUser';
import useXrayAccountPermission from '~/hooks/useXrayAccountPermission';
import { ReactComponent as ExclamationCircleIcon } from '~/static/images/icons/exclamation-circle.svg';
import { ReactComponent as PencilIcon } from '~/static/images/icons/pencil.svg';
import ViseLogoBlack from '~/static/images/vise-logo-black-1.svg';
import Banner from '~/synth/Banner';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import Skeleton from '~/synth/Skeleton';
import { WithLoader, exportCSV } from '~/utils';
import extractStatusAndMessageFromAPIError from '~/utils/apiErrors';
import { getCustodianDisplayName, maskAccountNumber } from '~/utils/format';
import { getAssetClassKeyFromFeatures } from '~/utils/pce2Migration';
import scrollToTop from '~/utils/scrollToTop';
import amplitude from '../../utils/amplitude';
import ProposalDeleteConfirmationModal from '../Households/modal/ProposalDeleteConfirmationModal';
import EditDeleteProposalMenu from '../Households/modal/ProposalEditRenameContextMenu';
import ProposalRenameModal from '../Households/modal/ProposalRenameModal';
import { PROPOSAL_SOURCE_MAP } from '../PortfolioCreator2/Constants';
import SentryRoute from '../SentryRoute';
import ExportMenu from './ExportMenu';
import PortfolioFooterDisclosures from './PortfolioFooterDisclosures';
import PortfolioInputs, { InputsLoadingState } from './PortfolioInputs';
import ProposalInitialTrades, { InitialTradesLoadingState } from './ProposalInitialTrades';
import ProposalTargetPortfolio, { TargetPortfolioLoadingState } from './ProposalTargetPortfolio';
import XrayInsights from './XrayInsights';
import Truncation from './components/Truncation';
import { transformPCE2Trades } from './portfolioUtil';

const BACKTEST_MAX_RETRIES = 10;

function AccountDetail({
  detail,
  value,
  ...restProps
}: { detail: React.ReactNode; value: React.ReactNode } & BoxProps) {
  return (
    <Box {...restProps}>
      <Typography variant="h5" color="textSecondary">
        {detail}
      </Typography>
      <Box mt={1}>{value}</Box>
    </Box>
  );
}

function ProposalLoadingState({
  tabName,
}: {
  tabName: 'target-portfolio' | 'initial-trades' | 'proposal-inputs';
}) {
  let tabLoading: JSX.Element;
  if (tabName === 'proposal-inputs') {
    tabLoading = <InputsLoadingState />;
  } else if (tabName === 'initial-trades') {
    tabLoading = <InitialTradesLoadingState />;
  } else {
    tabLoading = <TargetPortfolioLoadingState />;
  }

  return (
    <>
      <Box bgcolor="white">
        <Box display="flex" justifyContent="space-between" maxWidth={1280} m="auto" px={3}>
          <Box pt={3}>
            <Box width="16em" mt={2}>
              <Typography variant="h2">
                <Skeleton />
              </Typography>
            </Box>
          </Box>
          <Box justifyContent="flex-end" display="flex" alignItems="center">
            <Skeleton height="6em" width="7em" />
            <Box ml={4}>
              <Skeleton height="6em" width="7em" />
            </Box>
            <Divider orientation="vertical" style={{ height: '112px', marginLeft: '32px' }} />
            <Box mr={2} ml={3}>
              <Skeleton height="5em">
                <Button color="secondary">
                  <PencilIcon />
                </Button>
              </Skeleton>
            </Box>
            <Box mr={1.5}>
              <Skeleton height="5em">
                <Button color="secondary">
                  <PencilIcon />
                </Button>
              </Skeleton>
            </Box>
            <Skeleton height="5em">
              <Button variant="contained" color="primary">
                Execute
              </Button>
            </Skeleton>
          </Box>
        </Box>
        <Box border={1} borderLeft={0} borderRight={0} borderColor="grey.200">
          <Box display="flex" justifyContent="space-between" maxWidth={1280} m="auto" px={3} my={2}>
            <Skeleton width="40%" />
          </Box>
        </Box>
      </Box>
      <Box pt={4} pb={8} maxWidth={1280} m="auto" px={3}>
        {tabLoading}
      </Box>
      <Box borderTop={1} bgcolor="white" borderColor="grey.200">
        <Container>
          <PortfolioFooterDisclosures />
        </Container>
      </Box>
    </>
  );
}

export default function Proposal<T>({
  routePrefix = '/secure/proposal',
  breadcrumbs = true,
  disableGutters,
}: { routePrefix?: string; breadcrumbs?: boolean; disableGutters?: boolean } & T) {
  const { data: userData } = useUser(false, true, true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { intelligenceId: intelligenceIdFromRoute } = useParams<{ intelligenceId: string }>();
  const params = new URLSearchParams(history.location.search);
  const noSidebar = params.get('noSidebar') === 'true';
  const intelligenceId = intelligenceIdFromRoute;
  const routeMatch = useRouteMatch([`${routePrefix}/${intelligenceId}/:tabName`]);

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tabName' does not exist on type '{}'.
  const tabName = routeMatch?.params?.tabName;

  const { data: featureFlags } = useFeatureFlags();
  const enableXray = featureFlags?.enable_portfolio_xray === 'on';
  const enableUploadLogo = featureFlags?.enable_upload_logo === 'on';

  const { productOnboardingRequired } = useProductOnboarding();

  const enqueueToast = useToast();
  const [executeModalOpen, setExecuteModalOpen] = useState(false);
  const [accessModalOpen, setAccessModalOpen] = useState(false);
  const [backtestRetries, setBacktestRetries] = useState(0);

  const {
    data: intelligenceArrayData,
    error: intelligenceError,
    mutate: mutateIntelligence,
  } = usePortfolioIntelligence(intelligenceId);

  const intelligence = intelligenceArrayData?.[0];

  const [uploadLogoPdfOpen, setUploadLogoPdfOpen] = useState(false);

  // Keep fetching intelligence (up to 10 times) while backtest solving so the backtest chart will reload once information is ready
  useEffect(() => {
    if (intelligence?.status === 'BACKTEST_SOLVING' && backtestRetries < BACKTEST_MAX_RETRIES) {
      const interval = setInterval(() => {
        setBacktestRetries(backtestRetries + 1);
        mutateIntelligence();
      }, 3000);
      return () => clearInterval(interval);
    }
    return () => null;
  }, [mutateIntelligence, intelligence?.status, backtestRetries]);

  // TODO(rkurohara): Handle this in the API layer. Set accountId to null for sample proposals.
  const { data: accountResponseData, error: accountResponseError } = useAccount(
    intelligence?.accountId === '' ? null : intelligence?.accountId,
    true /* includeSummary */
  );

  const { data: capitalGainsData, error: capitalGainsError } = useCapitalGains(
    accountResponseData?.data?.accountNumber,
    accountResponseData?.data?.custodianKey
  );

  const { data: metricsResponseData, error: metricsResponseError } = usePortfolioMetrics(
    intelligence?.pceVersion === 'pce2' ? accountResponseData?.data.id : undefined
  );

  const { data: householdResponseData, error: householdResponseError } = useHousehold(
    accountResponseData?.data.accountDataSource !== 'XRAY'
      ? intelligence?.client.clientGroupId
      : null
  );

  const { data: allocationsTemplateData, error: allocationsTemplateError } = useAllocationsTemplate(
    intelligence?.allocationTemplateId,
    true
  );

  const { data: restrictionsTemplatesData, error: restrictionsTemplatesError } =
    useRestrictionsTemplates(intelligence?.restrictionTemplateIds, true);

  const {
    data: xrayPermissionData,
    error: xrayPermissionError,
    mutate: mutateXrayPermission,
  } = useXrayAccountPermission(
    featureFlags?.enable_xray_sharing === 'on' &&
      accountResponseData?.data.accountDataSource === 'XRAY'
      ? accountResponseData?.data?.id
      : null
  );

  const isSampleProposal = intelligence?.accountId === '';

  // Error capturing

  useEffect(() => {
    if (intelligenceArrayData && intelligenceArrayData[0] == null) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligenceId} failed to load: missing intelligence data.`
        ),
        {
          extra: {
            intelligenceId,
          },
        }
      );
    }
  }, [intelligenceArrayData, intelligenceId]);

  useEffect(() => {
    if (metricsResponseError != null) {
      Sentry.captureException(
        new Error(
          `Intelligence ${intelligenceId} failed to load metrics: ${metricsResponseError}.`
        ),
        {
          extra: {
            accountId: intelligence?.accountId,
            intelligenceId,
            intelligenceStatus: intelligence?.status,
          },
        }
      );
    }
  }, [intelligence?.accountId, intelligence?.status, intelligenceId, metricsResponseError]);

  useEffect(() => {
    if (accountResponseError) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligenceId} failed to load account. ${accountResponseError}.`
        ),
        {
          extra: {
            accountId: intelligence?.accountId,
            intelligenceId,
            intelligenceStatus: intelligence?.status,
          },
        }
      );
    }
  }, [accountResponseError, intelligence?.accountId, intelligence?.status, intelligenceId]);

  useEffect(() => {
    if (capitalGainsError != null) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligenceId} failed to load capital gains: ${capitalGainsError}.`
        ),
        {
          extra: {
            accountId: intelligence?.accountId,
            intelligenceId,
            intelligenceStatus: intelligence?.status,
          },
        }
      );
    }
  }, [capitalGainsError, intelligence?.accountId, intelligence?.status, intelligenceId]);

  useEffect(() => {
    if (householdResponseError != null) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligenceId} failed to load household: ${householdResponseError}.`
        ),
        {
          extra: {
            accountId: intelligence?.accountId,
            intelligenceId,
            intelligenceStatus: intelligence?.status,
          },
        }
      );
    }
  }, [householdResponseError, intelligence?.accountId, intelligence?.status, intelligenceId]);

  useEffect(() => {
    if (restrictionsTemplatesError != null) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligenceId} failed to load restrictions templates: ${restrictionsTemplatesError}.`
        ),
        {
          extra: {
            restrictionsTemplatesIds: intelligence?.restrictionTemplateIds,
          },
        }
      );
    }
  }, [restrictionsTemplatesError, intelligence?.restrictionTemplateIds, intelligenceId]);

  useEffect(() => {
    if (allocationsTemplateError != null) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligenceId} failed to load allocations templates: ${allocationsTemplateError}.`
        ),
        {
          extra: {
            allocationsTemplateId: intelligence?.allocationTemplateId,
          },
        }
      );
    }
  }, [allocationsTemplateError, intelligence?.allocationTemplateId, intelligenceId]);

  useEffect(() => {
    if (xrayPermissionError != null) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Failed to load X-Ray permission for account ${intelligence?.accountId}: ${xrayPermissionError}.`
        ),
        {
          extra: {
            accountId: intelligence?.accountId,
          },
        }
      );
    }
  }, [intelligence?.accountId, xrayPermissionError]);

  useEffect(() => {
    if (intelligenceError != null) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligenceId} failed to load. ${intelligenceError}.`
        ),
        {
          extra: {
            intelligenceId,
          },
        }
      );
    }
  }, [intelligenceError, intelligenceId]);

  useEffect(() => {
    if (intelligence?.status === 'REJECTED' || intelligence?.proposalType === 'light') {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligenceId} attached to account ${intelligence?.accountId} is not in a valid state.`
        ),
        {
          extra: {
            accountId: intelligence?.accountId,
            intelligenceId,
            intelligenceStatus: intelligence?.status,
          },
        }
      );
    }
  }, [intelligence, intelligenceId]);

  const [isExecuting, setIsExecuting] = useState(false);

  const theme = useTheme();

  if (accountResponseError?.response?.status === 404) {
    return (
      <SomethingWentWrong
        title="We can't find the account you're looking for."
        description="The link you're using may be
          incorrect, or you may not have access to the account."
        disableCta
        error={new Error('could not find account')}
      />
    );
  }

  if (intelligenceError != null) {
    if (intelligenceError.response?.status === 401) {
      return (
        <SomethingWentWrong
          description="This proposal is not accessible to you. If you think this is a mistake, please reach out to the creator of this proposal for access."
          error={intelligenceError}
        />
      );
    }
    return (
      <SomethingWentWrong
        description="There was an error loading the proposal."
        error={intelligenceError}
      />
    );
  }
  if (intelligenceArrayData && intelligenceArrayData[0] == null) {
    return (
      <SomethingWentWrong
        description="This proposal could not be found. It may have been deleted or you may not have permission to view it."
        error={new Error('no intelligence data')}
      />
    );
  }
  if (accountResponseError) {
    return (
      <SomethingWentWrong
        description={`There was an error loading the proposal account ${intelligence?.accountId}.`}
        error={accountResponseError}
      />
    );
  }
  if (metricsResponseError) {
    return (
      <SomethingWentWrong
        description="There was an error loading the proposal metrics."
        error={metricsResponseError}
      />
    );
  }
  if (capitalGainsError) {
    return (
      <SomethingWentWrong
        description="There was an error loading the proposal capital gains."
        error={capitalGainsError}
      />
    );
  }
  if (householdResponseError) {
    return (
      <SomethingWentWrong
        description="There was an error loading the household information."
        error={householdResponseError}
      />
    );
  }

  if (
    intelligence == null ||
    (!isSampleProposal && accountResponseData == null) ||
    (!isSampleProposal && metricsResponseData == null) ||
    (!isSampleProposal && capitalGainsData == null) ||
    (intelligence.allocationTemplateId && allocationsTemplateData == null) ||
    (intelligence.restrictionTemplateIds?.length && restrictionsTemplatesData == null)
  ) {
    return <ProposalLoadingState tabName={tabName} />;
  }

  if (intelligence.status === 'REJECTED' || intelligence.proposalType === 'light') {
    return (
      <SomethingWentWrong
        description="There was an error loading this proposal. We have been notified and will investigate
        the issue."
        error={new Error('rejected proposal or wrong proposal type')}
      />
    );
  }

  if (intelligence.acceptedAt != null && !isExecuting) {
    return <Redirect to={`/secure/accounts/${intelligence.accountId}/portfolio/overview`} />;
  }

  const {
    constructionResponse: { proposedBuys, proposedSells, metrics },
    clientId,
    client,
    name,
    id: portfolioIntelligenceId,
    proposalSource,
  } = intelligence;
  const { targetPortfolio } = metrics.strategyMetrics;

  const pce2InstrumentsBySymbol = keyBy(intelligence?.pce2Instruments, 'symbol');
  const tradeRows = transformPCE2Trades(proposedBuys, proposedSells, pce2InstrumentsBySymbol);

  const account = accountResponseData == null ? null : accountResponseData.data;

  const isXrayOrgAccess = xrayPermissionData?.data?.allowOrgAccess;

  // Determine if the X-Ray insights page is surfaced
  //
  // The data & charts within the X-Ray insights tab isn't very useful and/or
  // applicable outside of this condition.
  //
  // This is defined as all accounts whose holdings are populated via a CSV file and
  // new accounts transitioning onto Vise for the first time but have positions
  // other than just cash
  // prettier-ignore
  const shouldShowXray =
    account != null &&
    (account.accountDataSource === 'XRAY' ||
      (intelligence.constructionResponse.optimizerMode === 'TRANSITION' &&
        // portfolioIntelligenceId is the current, active PCE2 proposal for the account. A
        // non-executed account will leave this field undefined.
        account.portfolioIntelligenceId == null &&
        (account.summary.length <= 0 || account.summary[account.summary.length - 1].cashOnly === false)));

  const proposalName = isSampleProposal
    ? `${client.firstName} ${client.lastName} - Sample`
    : account?.accountName;

  const logExport = (name: string, category: string) => {
    const accountNameForLogging = isSampleProposal
      ? 'sample'
      : maskAccountNumber(account != null ? account.accountNumber : '');
    amplitude().logEvent(name, {
      accountName: accountNameForLogging,
      clientId,
      proposalId: portfolioIntelligenceId,
      category,
    });
  };
  const handleClickExportCsv = () => {
    const tagetAllocationRows = targetPortfolio.assetClassAllocations.map((alloc) => {
      return { ...alloc, assetClass: getAssetClassKeyFromFeatures(alloc.assetClass) };
    });

    const timestamp = moment(intelligence.createdAt).format('L LT');

    if (!isSampleProposal && metricsResponseData) {
      const initialAllocationRows = metricsResponseData?.portfolioMetrics.assetClassAllocations.map(
        (alloc) => {
          return { ...alloc, assetClass: getAssetClassKeyFromFeatures(alloc.assetClass) };
        }
      );
      exportCSV({
        data: initialAllocationRows,
        filename: `${proposalName}-initial-allocation-${timestamp}.csv`,
      });
    }

    exportCSV({
      data: tagetAllocationRows,
      filename: `${proposalName}-target-allocation-${timestamp}.csv`,
    });
    exportCSV({
      data: tradeRows,
      filename: `${proposalName}-trades-${timestamp}.csv`,
    });
    logExport('CsvExportInitiated', EVENT_CATEGORIES.PROPOSAL_OVERVIEW);
  };

  const handleClickExportPdf = async () => {
    if (enableUploadLogo) {
      setUploadLogoPdfOpen(true);
    } else {
      logExport('Start proposal pdf export', EVENT_CATEGORIES.PROPOSAL_OVERVIEW);
      const success = await dispatch(getPortfolioPdf(portfolioIntelligenceId));
      // @ts-expect-error ts-migrate(2774) FIXME: This condition will always return true since the f... Remove this comment to see the full error message
      if (success) {
        logExport('PDF export success', EVENT_CATEGORIES.PROPOSAL_OVERVIEW);
      } else {
        logExport('PDF export error', EVENT_CATEGORIES.PROPOSAL_OVERVIEW);
      }
    }
  };

  const gicsCutoffDate = new Date(featureFlags?.gics_frontend_cutoff_date || 'Invalid');
  const proposalCreatedBeforeCutoffDate =
    isValid(gicsCutoffDate) && new Date(intelligence.createdAt) < gicsCutoffDate;
  const proposalUsesLegacySectors = !!(
    proposalCreatedBeforeCutoffDate &&
    (intelligence.constructionInfo.excludedSectors.length ||
      intelligence.constructionInfo.excludedIndustries?.length)
  );

  const canExecute =
    !proposalUsesLegacySectors &&
    !isSampleProposal &&
    account?.status === 'ACTIVE' &&
    intelligence.constructionInfo.focus !== 'TARGET_VALUE' &&
    account?.accountDataSource !== 'XRAY' &&
    !noSidebar &&
    account.canExecute;

  const onFormSubmit = async (form: { id: string }) => {
    if (account == null) {
      return;
    }

    amplitude().logEvent('ProposalExecuteInitiated', {
      category: EVENT_CATEGORIES.PROPOSAL_OVERVIEW,
    });

    setIsExecuting(true);

    try {
      await executePortfolioIntelligence(form.id);
    } catch (error) {
      const { message } = extractStatusAndMessageFromAPIError(error);
      if (
        message === 'Portfolio intelligence at status "EXECUTED" cannot be executed.' ||
        message === 'A previous order has been created for this portfolio intelligence.'
      ) {
        enqueueToast({
          title: 'Trades pending execution',
          content: 'We cannot execute this proposal while previously executed trades are pending.',
          severity: 'error',
        });
      } else if (
        message.match(
          'There is a strategy center upgrade in progress for this account that would overwrite this proposal'
        )
      ) {
        enqueueToast({
          title: 'Strategy center upgrades pending',
          content:
            'We cannot execute this proposal while there are pending strategy center upgrade(s) in progress for this account that would overwrite the proposal. Please complete or cancel the upgrade(s) before executing this proposal.',
          severity: 'error',
        });
      } else {
        enqueueToast({
          title: 'Something went wrong',
          content: message,
          severity: 'error',
        });
      }
      setIsExecuting(false);
      return;
    }

    history.push(`/secure/accounts/${account.id}/portfolio/overview`);
    scrollToTop();
    enqueueToast({
      title: 'Proposal successfully executed!',
      content:
        'Trade orders have been generated and sent for execution. Once executed, trades will be reflected here on the next business day.',
      severity: 'success',
    });
  };

  // Currently xray sharing only allows read access to shared proposals. Remove this once xray sharing
  // allows for editing.
  const disableWriteAccess =
    account?.accountDataSource === 'XRAY' &&
    !(account.advisorUserId === userData?.id || userData?.isOrgAdmin || userData?.isSuperuser);

  const EditMenu = () => {
    const history = useHistory();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showRenameModal, setShowRenameModal] = useState(false);

    const handleEdit = () => {
      amplitude().logEvent('OpenPortfolioProposalEdit', {
        category: EVENT_CATEGORIES.PROPOSAL_OVERVIEW,
      });
      history.push(
        `/secure/portfolio-creator-next/summary-landing?pid=${encodeURIComponent(intelligenceId)}`
      );
    };

    const disableEdit =
      intelligence.constructionInfo.focus === 'TARGET_VALUE' || proposalUsesLegacySectors;

    return (
      <>
        <EditDeleteProposalMenu
          onRenameClick={() => setShowRenameModal(true)}
          onDeleteClick={() => setShowDeleteConfirmation(true)}
          onEditClick={handleEdit}
          buttonContent="Edit"
          disableEdit={disableEdit}
          disabledReason={
            disableWriteAccess
              ? 'Only the creator of this proposal and organization admins can edit this proposal.'
              : undefined
          }
        />
        <ProposalDeleteConfirmationModal
          proposal={intelligence}
          open={showDeleteConfirmation}
          onCancel={() => setShowDeleteConfirmation(false)}
          onDelete={() => {
            setShowDeleteConfirmation(false);
            history.push(`/secure/households/${client.clientGroupId}/overview`);
          }}
        />
        <ProposalRenameModal
          proposal={intelligence}
          open={showRenameModal}
          onCancel={() => setShowRenameModal(false)}
          onRename={() => setShowRenameModal(false)}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Proposal</title>
      </Helmet>
      <Box bgcolor="white">
        <Box maxWidth="1280px" mx="auto" px={3}>
          {proposalUsesLegacySectors && (
            <Box pt={3}>
              <Banner
                bgColor="warning.100"
                borderColor="warning.200"
                message={
                  <Box display="flex" alignItems="center">
                    <ExclamationCircleIcon color={theme.palette.warning[400]} fontSize={18} />
                    <Box ml={1.5}>
                      <Box>
                        This proposal uses legacy sector restrictions and cannot be executed or
                        edited.
                      </Box>
                      <Box>
                        Please create a new proposal with updated sector restrictions, or reach out
                        to clientservice@vise.com if you have any questions.
                      </Box>
                    </Box>
                  </Box>
                }
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box maxWidth="45%">
              {breadcrumbs && account?.accountDataSource !== 'XRAY' && (
                <Box mb={2}>
                  <WithLoader
                    loader={<Skeleton width="12em" inline />}
                    isLoading={householdResponseData == null}
                  >
                    <PathBreadcrumbs
                      path={[
                        {
                          // householdResponseData should not be undefined here
                          name: householdResponseData?.name ?? '',
                          to: `/secure/households/${householdResponseData?.id}`,
                        },
                        ...(account?.accountDataSource === 'CUSTODIAN'
                          ? [
                              {
                                name: account.accountName,
                                to: `/secure/accounts/${account.id}/portfolio`,
                              },
                            ]
                          : []),
                        { name: name ? `Proposal: ${name}` : 'Proposal' },
                      ]}
                      ariaLabel="Page path breadcrumbs"
                    />
                  </WithLoader>
                </Box>
              )}
              <Truncation style={{ fontWeight: 500, fontSize: '1.5rem', lineHeight: '1.25' }}>
                {proposalName}
              </Truncation>
              {account != null && (
                <Box display="flex" alignItems="center" mt={2}>
                  {account?.accountDataSource === 'XRAY' &&
                    isXrayOrgAccess != null &&
                    featureFlags?.enable_xray_sharing === 'on' && (
                      <>
                        <Box fontWeight="bold" mr={0.5}>
                          X-Ray sharing:
                        </Box>
                        <XrayAccessTag orgAccess={isXrayOrgAccess} />
                        {disableWriteAccess ? (
                          <Tooltip title="Only the creator of this proposal and organization admins can share this proposal.">
                            <Box ml={1}>
                              <Button disabled variant="outlined" size="small">
                                Share
                              </Button>
                            </Box>
                          </Tooltip>
                        ) : (
                          <Button
                            sx={{ ml: 1 }}
                            variant="outlined"
                            onClick={() => setAccessModalOpen(true)}
                            size="small"
                          >
                            Share
                          </Button>
                        )}
                      </>
                    )}
                </Box>
              )}
            </Box>
            <Box justifyContent="flex-end" display="flex" alignItems="center">
              {!isSampleProposal && account != null && (
                <>
                  <AccountDetail
                    detail="Type"
                    value={account.taxable ? 'Non-qualified' : 'Qualified'}
                    mx={4}
                  />
                  {account.accountDataSource === 'CUSTODIAN' && (
                    <AccountDetail
                      detail="Account"
                      value={
                        <>
                          {maskAccountNumber(account.accountNumber)},{' '}
                          {getCustodianDisplayName(account.custodianKey)}
                        </>
                      }
                      mr={4}
                    />
                  )}
                </>
              )}

              <Divider orientation="vertical" style={{ height: '112px' }} />
              <Box mr={2} ml={3}>
                <ExportMenu
                  isIcon
                  hideExportPdf={
                    featureFlags?.enable_pce2_proposal_pdf_export !== 'on' ||
                    intelligence.status !== 'READY'
                  }
                  onExportPdf={handleClickExportPdf}
                  onExportCsv={handleClickExportCsv}
                />
              </Box>
              {PROPOSAL_SOURCE_MAP[proposalSource || 0] !== 'STRATEGY_CENTER' && (
                <>
                  <Box mr={1.5}>
                    <EditMenu />
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!canExecute}
                    onClick={() => setExecuteModalOpen(true)}
                  >
                    Execute
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Box border={1} borderLeft={0} borderRight={0} borderColor="grey.200">
          <Box display="flex" justifyContent="space-between" maxWidth="1280px" mx="auto" px={3}>
            <Tabs value={tabName || 'target-portfolio'} aria-label="Portfolio tabs">
              {shouldShowXray && enableXray ? (
                <Tab
                  value="x-ray"
                  label="X-Ray analysis"
                  id="tab-x-ray"
                  to={`/secure/proposal/${intelligenceId}/x-ray`}
                  aria-controls="x-ray-screen"
                  component={Link}
                />
              ) : null}
              <Tab
                value="target-portfolio"
                label="Target portfolio"
                id="tab-target-portfolio"
                to={`${routePrefix}/${intelligenceId}/target-portfolio`}
                aria-controls="target-portfolio-screen"
                component={Link}
              />
              <Tab
                value="initial-trades"
                label="Initial trades"
                id="tab-initial-trades"
                to={`${routePrefix}/${intelligenceId}/initial-trades`}
                aria-controls="initial-trades-screen"
                component={Link}
                disabled={productOnboardingRequired}
              />
              <Tab
                value="proposal-inputs"
                label="Proposal inputs"
                id="tab-proposal-inputs"
                to={`${routePrefix}/${intelligenceId}/proposal-inputs`}
                aria-controls="proposal-inputs-screen"
                component={Link}
              />
            </Tabs>
          </Box>
        </Box>
      </Box>
      <Container
        sx={{
          ...(disableGutters ? { p: 0 } : {}),
          ...(tabName === 'x-ray' ? { bgcolor: 'white' } : {}),
        }}
        disableGutters={
          disableGutters /* we have to disable the padding set in the theme config and also the responsive gutters */
        }
      >
        <Switch>
          {shouldShowXray && enableXray ? (
            <SentryRoute exact path={`${routePrefix}/:intelligenceId/x-ray`}>
              <Box
                id="x-ray-screen"
                aria-labelledby="tab-x-ray"
                role="tabpanel"
                pt={3}
                bgcolor="white"
              >
                <XrayInsights
                  intelligence={intelligence}
                  gains={capitalGainsData}
                  account={account}
                  noSidebar={noSidebar}
                />
              </Box>
            </SentryRoute>
          ) : null}
          <SentryRoute exact path={`${routePrefix}/:intelligenceId/target-portfolio`}>
            <Box
              id="target-portfolio-screen"
              aria-labelledby="tab-target-portfolio"
              role="tabpanel"
              mt={3}
            >
              <ProposalTargetPortfolio
                portfolioMetrics={metricsResponseData}
                intelligence={intelligence}
                account={account}
                gains={capitalGainsData}
                backtestMaxRetries={backtestRetries >= BACKTEST_MAX_RETRIES}
              />
            </Box>
          </SentryRoute>
          {!productOnboardingRequired && (
            <SentryRoute exact path={`${routePrefix}/:intelligenceId/initial-trades`}>
              <Box
                id="initial-trades-screen"
                aria-labelledby="tab-initial-trades"
                role="tabpanel"
                mt={3}
              >
                <ProposalInitialTrades
                  intelligence={intelligence}
                  account={account}
                  gains={capitalGainsData}
                />
              </Box>
            </SentryRoute>
          )}
          <SentryRoute exact path={`${routePrefix}/:intelligenceId/proposal-inputs`}>
            <Box
              id="proposal-inputs-screen"
              aria-labelledby="tab-proposal-inputs"
              role="tabpanel"
              mt={3}
            >
              <PortfolioInputs
                intelligence={intelligence}
                allocationsTemplate={allocationsTemplateData}
                restrictionsTemplates={restrictionsTemplatesData}
                account={account ?? undefined}
              />
            </Box>
          </SentryRoute>
          <SentryRoute path={`${routePrefix}/:intelligenceId`}>
            <Redirect to={`${routePrefix}/${intelligenceId}/target-portfolio`} />
          </SentryRoute>
        </Switch>
        <PortfolioFooterDisclosures />
      </Container>
      <ExecutePortfolio
        form={{ portfolioIntelligenceId }}
        open={executeModalOpen}
        onClose={() => setExecuteModalOpen(false)}
        onSubmit={onFormSubmit}
      />

      <UploadImage
        open={uploadLogoPdfOpen}
        onClose={() => setUploadLogoPdfOpen(false)}
        title="Download proposal"
        dialogText={
          <>
            <Typography variant="h4" mb={2}>
              First, upload a logo.
            </Typography>
            Add a photo of your organization&apos;s logo if you would like it represented in this
            PDF.
          </>
        }
        defaultImage={userData?.organization?.logoUrl ?? ViseLogoBlack}
        handleSave={async (_data, image) => {
          logExport('Start proposal pdf export', EVENT_CATEGORIES.PROPOSAL_OVERVIEW);
          dispatch(getPortfolioPdf(portfolioIntelligenceId, image));
        }}
      />
      {account && account.accountDataSource === 'XRAY' && (
        <UpdateXrayAccess
          mutate={mutateXrayPermission}
          accountId={account.id}
          isOrgAccess={!!isXrayOrgAccess}
          open={accessModalOpen}
          onClose={() => setAccessModalOpen(false)}
        />
      )}
    </>
  );
}
