import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Cadence, DistributionSchedule, DistributionStatus } from 'vise-types/distribution';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { ReactComponent as CheckCircleIcon } from '~/static/images/icons/check-circle.svg';
import { ReactComponent as PausedIconBase } from '~/static/images/icons/pause-circle-outline.svg';
import { ReactComponent as PencilEdit } from '~/static/images/icons/pencil-alt.svg';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import amplitude from '~/utils/amplitude';
import { formatCurrency } from '~/utils/format';
import useIsModelDelivery from '~/routes/Distributions/useIsModelDelivery';
import {
  DistributionDetailsPanelPausedBanner,
  DistributionDetailsPanelReinvestBanner,
  DistributionDetailsPanelsLowBalanceBanner,
} from './components/DistributionDetailsPanelBanners';
import { Circle, DistributionDetailsDisplay } from './components/UtilComponents';
import { STATUS_TO_LABEL, DISTRIBUTION_TABLE_TYPE } from './Constants';

export function getCadenceDisplayText(cadence: Cadence) {
  return cadence === 'DO_NOT_REPEAT' ? 'One-time' : 'Recurring';
}

const DistributionInstanceRow = ({ date, amount }: { date: Date; amount: number }) => {
  return (
    <Box py={2} display="flex" justifyContent="space-between" boxShadow={8}>
      <Box color="grey.700">{moment(date).format('ll')}</Box>
      <>{formatCurrency(amount)} </>
    </Box>
  );
};
const StatusStepperConnector = () => (
  <Box ml={0.8}>
    {[0, 1, 2, 3].map((n) => (
      <Circle key={n} diameter={4} borderRadius="50%" bgcolor="grey.300" my={1} />
    ))}
  </Box>
);

const StatusStepperStepIcon = (props: StepIconProps) => {
  const { active, completed, icon } = props;
  const lastStep = String(icon) === '4';
  if (active) {
    return (
      <Circle
        boxSizing="content-box"
        diameter={8}
        bgcolor={lastStep ? '#0AB389' : '#7119F6'}
        boxShadow={2}
        border={4}
        borderColor={lastStep ? '#B6FBEA' : '#EADDFE'}
      />
    );
  }
  if (completed) {
    return <CheckCircleIcon />;
  }
  return <Circle diameter={12} border={1} borderColor="grey.400" ml={0.25} />;
};

const PausedIcon = (props: StepIconProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <PausedIconBase {...(props as any)} />;
};

const StatusStepper = withStyles(Stepper, () => ({
  root: { padding: 0 },
}));

const StatusStepperLabel = withStyles(StepLabel, (theme) => ({
  label: {
    '&.MuiStepLabel-label': {
      color: theme.palette.grey[400],
      fontSize: '0.875rem',
      lineHeight: 1.25,
      fontWeight: 400,
    },
  },
  active: {
    '&.MuiStepLabel-label': {
      color: theme.palette.grey[800],
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.25,
    },
  },
}));

const DistributionDrawer = withStyles(Drawer, (theme) => ({
  paper: { boxShadow: theme.shadows[3] },
}));

export interface DistributionDetailsPanelProps {
  onClose: (event: {}, closeReason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick') => void;
  open: boolean;
  distribution?: DistributionSchedule;
  setCancelDistributionModalSettings: ({
    open,
    distribution,
  }: {
    open: boolean;
    distribution?: DistributionSchedule;
  }) => void;
  isGlobalDistribution: boolean;
}

export default function DistributionDetailsPanel({
  onClose,
  open,
  distribution,
  setCancelDistributionModalSettings,
  isGlobalDistribution,
}: DistributionDetailsPanelProps) {
  const statusToState = {
    SCHEDULED: 0,
    TRADES_INITIATED: 1,
    TRADES_EXECUTED: 2,
    CASH_AVAILABLE: 3,
    PAUSED: 4,
  } as {
    [key in DistributionStatus]: number;
  };
  const activeStep = distribution?.status != null ? statusToState[distribution?.status] : 0;
  const isAccountLowBalance = distribution?.lowBalance;
  const daysToReinvest = distribution?.daysToReinvest != null ? distribution?.daysToReinvest : 0;
  const isPaused = distribution?.status === 'PAUSED';
  const isModelDelivery = useIsModelDelivery();
  return (
    <DistributionDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      BackdropProps={{ invisible: true }}
    >
      <Box width={500} height="100vh">
        <Box
          position="fixed"
          display="flex"
          width={500}
          justifyContent="space-between"
          px={3}
          height={72}
          alignItems="center"
          boxShadow={8}
          bgcolor="white"
        >
          <Typography variant="h2">Scheduled distribution</Typography>
          <IconButton
            aria-label="close distributions details drawer"
            onClick={() => onClose({}, 'closeButtonClick')}
            size="large"
          >
            <XIcon fontSize={24} />
          </IconButton>
        </Box>
        {distribution != null ? (
          <>
            <Box pt={8.75} pb={10.5}>
              {isAccountLowBalance ? (
                <Box px={3} pt={2}>
                  <DistributionDetailsPanelsLowBalanceBanner />
                </Box>
              ) : null}
              {daysToReinvest > 0 ? (
                <Box px={3} pt={2}>
                  <DistributionDetailsPanelReinvestBanner daysToReinvest={daysToReinvest} />
                </Box>
              ) : null}
              {isPaused ? (
                <Box px={3} pt={2}>
                  <DistributionDetailsPanelPausedBanner />
                </Box>
              ) : null}
              <Box m={3}>
                <Box mb={2.5}>
                  <Typography variant="h3">Details</Typography>
                </Box>
                <DistributionDetailsDisplay distribution={distribution} variant="side-panel" />
              </Box>
              <Box my={4} mx={3} bgcolor="grey.200">
                <Divider />
              </Box>

              <Box m={3}>
                <Box mb={2.5}>
                  <Typography variant="h3">Status</Typography>
                </Box>
                {isPaused ? (
                  <StatusStepperLabel StepIconComponent={PausedIcon}>
                    <Box my={2}>{STATUS_TO_LABEL.PAUSED}</Box>
                  </StatusStepperLabel>
                ) : (
                  <StatusStepper
                    orientation="vertical"
                    activeStep={activeStep}
                    connector={<StatusStepperConnector />}
                  >
                    <Step key={0}>
                      <StatusStepperLabel StepIconComponent={StatusStepperStepIcon}>
                        <>
                          {STATUS_TO_LABEL.SCHEDULED}
                          {activeStep === 0 && (
                            <Box color="grey.600" mt={0.5}>
                              <Typography variant="body1">
                                {distribution.nextRuns != null && distribution.nextRuns.length > 0
                                  ? moment(distribution.nextRuns?.[0]).format('ll')
                                  : null}
                              </Typography>
                            </Box>
                          )}
                        </>
                      </StatusStepperLabel>
                    </Step>
                    <Step key={1}>
                      <StatusStepperLabel StepIconComponent={StatusStepperStepIcon}>
                        <>
                          {STATUS_TO_LABEL.TRADES_INITIATED}
                          {activeStep === 1 && (
                            <Box color="grey.600" mt={0.5}>
                              <Typography variant="body1">
                                Trades will be executed in one business day.
                              </Typography>
                            </Box>
                          )}
                        </>
                      </StatusStepperLabel>
                    </Step>
                    <Step key={2}>
                      <StatusStepperLabel StepIconComponent={StatusStepperStepIcon}>
                        <>
                          {STATUS_TO_LABEL.TRADES_EXECUTED}
                          {activeStep === 2 && (
                            <Box color="grey.600" mt={0.5}>
                              <Typography variant="body1">
                                Available to withdraw in 1-2 business days.
                              </Typography>
                            </Box>
                          )}
                        </>
                      </StatusStepperLabel>
                    </Step>
                    <Step key={3}>
                      <StatusStepperLabel StepIconComponent={StatusStepperStepIcon}>
                        {STATUS_TO_LABEL.CASH_AVAILABLE}
                      </StatusStepperLabel>
                    </Step>
                  </StatusStepper>
                )}
                {distribution.nextRuns && distribution.nextRuns.length > 0 ? (
                  <>
                    <Box my={4} bgcolor="grey.200">
                      <Divider />
                    </Box>
                    <Box mb={2}>
                      <Typography variant="h3">Upcoming</Typography>
                    </Box>
                    {distribution.nextRuns.map((s) => (
                      <DistributionInstanceRow
                        amount={distribution.amount}
                        date={s}
                        key={s.toString()}
                      />
                    ))}
                  </>
                ) : null}
              </Box>
            </Box>
            <Box
              position="fixed"
              bottom={0}
              py={2.5}
              px={3}
              display="flex"
              width={500}
              justifyContent="space-between"
              boxShadow={9}
              bgcolor="white"
            >
              <Button
                variant="outlined"
                style={{ width: '220px' }}
                color="error"
                onClick={() => {
                  amplitude().logEvent('Action - Tap Cancel schedule button', {
                    category: EVENT_CATEGORIES.DISTRIBUTIONS,
                    scheduleId: distribution.scheduleId,
                  });
                  setCancelDistributionModalSettings({
                    open: true,
                    distribution,
                  });
                }}
              >
                Cancel schedule
              </Button>
              <Button
                variant="outlined"
                style={{ width: '220px' }}
                startIcon={<PencilEdit />}
                component={Link}
                to={{
                  pathname: `/secure/accounts/${distribution.accountId}/distributions/${distribution.scheduleId}/edit`,
                  state: {
                    from: isGlobalDistribution
                      ? DISTRIBUTION_TABLE_TYPE.GLOBAL
                      : DISTRIBUTION_TABLE_TYPE.ACCOUNT,
                    isModelDelivery,
                  },
                }}
                onClick={() =>
                  amplitude().logEvent('Action - Tap Edit button', {
                    category: EVENT_CATEGORIES.DISTRIBUTIONS,
                    scheduleId: distribution.scheduleId,
                  })
                }
              >
                Edit
              </Button>
            </Box>
          </>
        ) : null}
      </Box>
    </DistributionDrawer>
  );
}
