import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps } from '@mui/material';
import React, { useState } from 'react';
import { PortfolioIntelligenceCommon } from 'vise-types/pce1';
import useDeleteRenamePCE2Proposal from '~/hooks/usePCE2Proposal';
import DialogTitle from '~/synth/DialogTitle';
import TextField from '~/synth/TextField';

type Props = {
  onRename: (e: React.MouseEvent<HTMLButtonElement>, proposalName: string) => void;
  onCancel: () => void;
  proposal: Pick<PortfolioIntelligenceCommon, 'accountId' | 'clientId' | 'id' | 'name'>;
} & DialogProps;

const ProposalRenameModal = (props: Props) => {
  const { onCancel, onRename, proposal, ...rest } = props;

  const { renameProposal, renamePending } = useDeleteRenamePCE2Proposal();
  const [proposalName, setProposalName] = useState(proposal?.name || '');

  const handleRename = async (e) => {
    await renameProposal(proposal, proposalName);
    onRename(e, proposalName);
  };

  return (
    <Dialog onClose={onCancel} {...rest}>
      <DialogTitle onClose={onCancel}>Rename proposal</DialogTitle>
      <DialogContent dividers>
        <Box my={3}>
          <TextField
            value={proposalName}
            title="Proposal name"
            fullWidth
            label="Proposal name"
            onChange={(e) => setProposalName(e.currentTarget.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleRename} disabled={renamePending}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProposalRenameModal;
