import { Box, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { TemplateAccountUpdateType, TemplateUpdateEventStatus } from '~/models/api';
import { ReactComponent as MinusCircleIcon } from '~/static/images/icons/minus-circle.svg';
import { DataTable } from '~/synth/DataTable';
import { Column } from 'react-table';
import { formatCurrency } from '~/utils/format';
import { format } from 'date-fns';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import PopoverTrigger from '~/synth/PopoverTrigger';
import PopoverCard from '~/synth/PopoverCard';
import PopoverLink from '~/synth/PopoverLink';
import { AllocationsTemplate, RestrictionsTemplate, TemplateUpdateJob } from 'vise-types/template';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import Card from './Card';
import { AccountCell, CTACell } from './CommonComponents';
import { CONTAINER_WIDTH } from './constants';

interface UnlinkAccountTableType {
  account?: AccountWithPIAndHouseholdInfo;
  value?: number | null;
  linkedSince: string;
  link?: { path: string; buttonCopy: string };
}

function leftPad(Component) {
  return (props) => (
    <Box ml={1}>
      <Component {...props} />
    </Box>
  );
}

const unlinkAccountColumns: Column<UnlinkAccountTableType>[] = [
  { Header: leftPad(() => <>Account</>), accessor: 'account', Cell: leftPad(AccountCell) },
  {
    Header: 'Value',
    accessor: (row) => formatCurrency(row.value),
  },
  {
    Header: 'Linked since',
    accessor: (row) => format(new Date(row.linkedSince), 'M/d/y'),
  },
];

export default function UnlinkedAccountsSection({
  jobData,
  accountsById,
  template,
}: {
  jobData: TemplateUpdateJob;
  accountsById: {
    [key: string]: AccountWithPIAndHouseholdInfo | undefined;
  };
  template: AllocationsTemplate | RestrictionsTemplate | undefined;
}) {
  const { data } = useModelTemplateCenterViewData();
  const theme = useTheme();
  const unlinkAccountTableData: UnlinkAccountTableType[] = useMemo(() => {
    return (
      jobData?.events
        .filter(
          (ev) =>
            ev.accountUpdateType === TemplateAccountUpdateType.REMOVE &&
            ev.status === TemplateUpdateEventStatus.PROPOSAL_CREATED &&
            // If an account becomes untransitioned after a template update job for it is kicked off,
            // exclude it from all actions.
            // This can happening with unlinking/linking or account removal requests etc.
            // We should not be transitioning accounts through template update.
            // If an account is not accepted the proposal is simply deleted so it will be a noop.
            accountsById[ev.accountId] != null
        )
        .map((ev) => {
          if (
            ev.accountUpdateType !== TemplateAccountUpdateType.REMOVE ||
            ev.status !== TemplateUpdateEventStatus.PROPOSAL_CREATED
          ) {
            throw new Error('impossible');
          }
          const account = accountsById[ev.accountId];
          return {
            account,
            linkedSince:
              data?.data.templateLinks.find((l) => l.accountId === ev.accountId)?.linkedSince ||
              new Date().toISOString(),
            link: {
              path: `/secure/strategy-center-proposal/${jobData?.id || ''}/${
                ev.newProposalId || ''
              }?bulk_edit=${jobData.reason === 'BULK_EDIT'}`,
              buttonCopy: 'View proposal',
            },
            value: account?.cachedAum,
          };
        }) || []
    );
  }, [jobData?.events, data?.data.templateLinks, accountsById, jobData?.id, jobData.reason]);

  const unlinkAccountColumnsWithViewProposal: Column<UnlinkAccountTableType>[] = useMemo(() => {
    if (template?.type === 'restrictions') {
      return [...unlinkAccountColumns, { Header: '', Cell: CTACell, accessor: 'link' }];
    }
    return unlinkAccountColumns;
  }, [template?.type]);

  if (!unlinkAccountTableData.length) {
    return null;
  }

  return (
    <Box my={3}>
      <Card fullWidth maxWidth={CONTAINER_WIDTH}>
        <Box display="flex" alignItems="center" mx={3}>
          <Box>
            <Box display="flex" alignItems="center">
              <MinusCircleIcon color={theme.palette.warning[400]} />
              <Box ml={1}>
                <Typography variant="h4">Unlinked accounts</Typography>
              </Box>
            </Box>
            <Box mt={1}>
              <Typography variant="body1" color="textSecondary">
                {unlinkAccountTableData.length} account
                {unlinkAccountTableData.length === 1 ? '' : 's'} will be{' '}
                <PopoverTrigger
                  overlay={({ close }) => (
                    <PopoverCard
                      title="Unlinking restrictions"
                      body="Unlinking an account from a restrictions template generates a new proposal with the associated restrictions removed."
                      onClose={close}
                    />
                  )}
                >
                  <PopoverLink>unlinked</PopoverLink>
                </PopoverTrigger>{' '}
                from the template.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={3}>
          <DataTable
            columns={unlinkAccountColumnsWithViewProposal}
            data={unlinkAccountTableData}
            m={0}
          />
        </Box>
      </Card>
    </Box>
  );
}
