import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Banner from '~/synth/Banner';
import { ReactComponent as ExclamationCircleIcon } from '~/static/images/icons/information-circle.svg';

export function AccountPortfolioPerformanceQANotification() {
  return (
    <Banner
      bgColor="blue.100"
      borderColor="blue.300"
      message={
        <Container>
          <Typography component="div" variant="body2">
            <Box display="inline-block" mr={1} style={{ verticalAlign: 'middle' }}>
              <Box color="blue.600" display="flex" style={{ fontSize: '18px' }}>
                <ExclamationCircleIcon />
              </Box>
            </Box>{' '}
            This account&apos;s performance is pending reconciliation. Performance figures will be
            hidden until this process is complete.
          </Typography>
        </Container>
      }
      size="small"
    />
  );
}

export function HouseholdPortfoliosPerformanceQANotification({
  numAccountsRequiringPerformanceQA,
}: {
  numAccountsRequiringPerformanceQA: number;
}) {
  const accountOrAccounts =
    numAccountsRequiringPerformanceQA === 1
      ? "1 account's"
      : `${numAccountsRequiringPerformanceQA} accounts'`;

  return (
    <Banner
      bgColor="blue.100"
      borderColor="blue.300"
      message={
        <Container>
          <Typography component="div" variant="body2">
            <Box display="flex">
              <Box display="inline-block" mr={1} style={{ verticalAlign: 'middle' }}>
                <Box color="blue.600" display="flex" style={{ fontSize: '18px' }}>
                  <ExclamationCircleIcon />
                </Box>
              </Box>{' '}
              <Box>
                <Box fontWeight="bold" mb={1}>
                  {accountOrAccounts} performance is pending reconciliation
                </Box>
                <Box>
                  Performance figures for impacted accounts will be hidden until this process is
                  complete, which may impact household-level performance data.
                </Box>
              </Box>
            </Box>
          </Typography>
        </Container>
      }
      size="small"
    />
  );
}
