export const FONT_FAMILY =
  '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

const defaultText = {
  fontFamily: FONT_FAMILY,
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: 1.25,
};

export default {
  ...defaultText,
  fontSize: 14,
  // HTML component needs to be specified manually
  title: {
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    fontSize: "2.5rem",
    lineHeight: 1.25,
  },
  h1: {
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    fontSize: "2rem",
    lineHeight: 1.25,
  },
  h2: {
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: 1.25,
  },
  h3: {
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: 1.25,
  },
  h4: {
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: 1.25,
  },
  h5: {
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.25,
  },
  h6: {
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    fontSize: "0.875rem",
    letterSpacing: 1,
    lineHeight: 1.25,
    textTransform: "uppercase" as const,
  },
  // HTML component needs to be specified manually
  h7: {
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    fontSize: "0.75rem",
    letterSpacing: 1,
    lineHeight: 1.25,
  },
  body1: {
    fontFamily: FONT_FAMILY,
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.25,
  },
  body2: defaultText,
  body3: {
    fontFamily: FONT_FAMILY,
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.25,
  },
};
