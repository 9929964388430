import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ChartColorSquare from '~/components/chart/ChartColorSquare';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import { mapValues } from 'lodash';
import { useAssetToColorMap } from '~/hooks/useAssetToColorMap';
import {
  SubCategoryAllocation,
  SubDetailsText,
  ThreeColumn,
} from '~/routes/PortfolioCreator2/screens/components/SummarySections';
import { TiltSelection } from 'vise-types/template';
import Card, { CardTitle as CardTitleBase } from './Card';
import EditButton from './EditButton';
import { tiltSelectionToLabel } from '../utils';

interface Props {
  editDisabled?: boolean;
  onEdit?: () => void;
  equitiesTitle: React.ReactNode;
  fixedIncomeTitle: React.ReactNode;
  allocations: { [key in AssetClassKey]?: number };
  originalAllocations?: { [key in AssetClassKey]?: number };
  alternativesTitle: React.ReactNode;
  maxWidth?: string;
  hideLegend?: boolean;
}

function CardTitle({
  title,
  allocation,
  legendColor,
  originalAllocation,
}: {
  title: React.ReactNode;
  allocation: number;
  originalAllocation?: number;
  legendColor?: string;
}) {
  return (
    <CardTitleBase
      title={
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          {legendColor && <ChartColorSquare color={legendColor} />}
          {title}
        </Box>
      }
      right={
        <Typography variant="h4" component="div">
          {allocation.toFixed(0)}%
        </Typography>
      }
      middle={
        originalAllocation !== undefined ? (
          <Typography variant="h4" component="div">
            {originalAllocation.toFixed(0)}%
          </Typography>
        ) : undefined
      }
    />
  );
}

export function TemplateBody({
  equitiesTitle,
  allocations,
  fixedIncomeTitle,
  alternativesTitle,
  hideLegend,
  originalAllocations,
}: Pick<
  Props,
  | 'equitiesTitle'
  | 'allocations'
  | 'fixedIncomeTitle'
  | 'alternativesTitle'
  | 'hideLegend'
  | 'originalAllocations'
>) {
  const allocationsOutOf1 = mapValues(allocations, (alloc) => (alloc ? alloc / 100 : alloc));
  const originalAllocationsOutOf1 = originalAllocations
    ? mapValues(originalAllocations, (alloc) => (alloc ? alloc / 100 : alloc))
    : undefined;
  const assetToColorMap = useAssetToColorMap();
  const theme = useTheme();

  return (
    <>
      {originalAllocations && (
        <>
          <Box display="flex">
            <Box flex={1} />

            <Box width="20%" textAlign="end">
              <Typography variant="h5" color={theme.palette.grey[700]}>
                Previous
              </Typography>
            </Box>

            <Box width="20%" textAlign="end">
              <Typography variant="h5" color={theme.palette.grey[700]}>
                Updated
              </Typography>
            </Box>
          </Box>
          <Box py={1} />{' '}
        </>
      )}
      <CardTitle
        title={equitiesTitle}
        allocation={allocations.EQUITY || 0}
        originalAllocation={originalAllocations ? originalAllocations.EQUITY || 0 : undefined}
        legendColor={hideLegend ? undefined : assetToColorMap.EQUITY.main}
      />
      <SubCategoryAllocation
        parent="EQUITY/US"
        assetAllocation={allocationsOutOf1}
        originalAssetAllocation={originalAllocationsOutOf1}
      />
      <SubCategoryAllocation
        parent="EQUITY/DEVELOPED"
        assetAllocation={allocationsOutOf1}
        originalAssetAllocation={originalAllocationsOutOf1}
      />
      <SubCategoryAllocation
        parent="EQUITY/EMERGING_EQUITY"
        assetAllocation={allocationsOutOf1}
        originalAssetAllocation={originalAllocationsOutOf1}
      />
      <SubCategoryAllocation
        parent="EQUITY/US_REIT"
        assetAllocation={allocationsOutOf1}
        originalAssetAllocation={originalAllocationsOutOf1}
      />
      <Box py={1} />
      <CardTitle
        title={fixedIncomeTitle}
        allocation={allocations.FIXED_INCOME || 0}
        legendColor={hideLegend ? undefined : assetToColorMap.FIXED_INCOME.main}
        originalAllocation={originalAllocations ? originalAllocations.FIXED_INCOME || 0 : undefined}
      />
      <SubCategoryAllocation
        parent="FIXED_INCOME/DOMESTIC"
        assetAllocation={allocationsOutOf1}
        originalAssetAllocation={originalAllocationsOutOf1}
      />
      <SubCategoryAllocation
        parent="FIXED_INCOME/INTERNATIONAL"
        assetAllocation={allocationsOutOf1}
        originalAssetAllocation={originalAllocationsOutOf1}
      />
      <SubCategoryAllocation
        parent="FIXED_INCOME/EMERGING_FI"
        assetAllocation={allocationsOutOf1}
        originalAssetAllocation={originalAllocationsOutOf1}
      />
      <Box py={1} />
      <CardTitle
        title={alternativesTitle}
        allocation={allocations.ALTERNATIVES || 0}
        originalAllocation={originalAllocations ? originalAllocations.ALTERNATIVES || 0 : undefined}
        legendColor={hideLegend ? undefined : assetToColorMap.ALTERNATIVES.main}
      />
      <SubCategoryAllocation
        parent="ALTERNATIVES/COMMODITIES"
        assetAllocation={allocationsOutOf1}
        originalAssetAllocation={originalAllocationsOutOf1}
      />
    </>
  );
}

export default function AllocationTemplateCard({
  editDisabled,
  onEdit,
  equitiesTitle,
  allocations,
  originalAllocations,
  fixedIncomeTitle,
  alternativesTitle,
  maxWidth,
  tiltSelection,
}: {
  editDisabled?: boolean;
  onEdit?: () => void;
  equitiesTitle: React.ReactNode;
  fixedIncomeTitle: React.ReactNode;
  allocations: { [key in AssetClassKey]?: number };
  originalAllocations?: { [key in AssetClassKey]?: number };
  alternativesTitle: React.ReactNode;
  maxWidth?: string;
  tiltSelection?: TiltSelection | null;
}) {
  const allocationBody = (
    <Card
      header={
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography variant="h3">Asset allocation</Typography>
          </Box>

          {onEdit && (
            <Box>
              <EditButton
                disabled={editDisabled}
                onClick={() => {
                  onEdit();
                }}
              >
                Edit
              </EditButton>
            </Box>
          )}
        </Box>
      }
      maxWidth={maxWidth}
    >
      <TemplateBody
        equitiesTitle={equitiesTitle}
        fixedIncomeTitle={fixedIncomeTitle}
        alternativesTitle={alternativesTitle}
        allocations={allocations}
        originalAllocations={originalAllocations}
      />
    </Card>
  );
  return (
    <>
      <Box mb={3}>{allocationBody}</Box>
      <Card
        header={
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <Typography variant="h3">Tilt settings</Typography>
            </Box>

            {onEdit && (
              <Box>
                <EditButton
                  disabled={editDisabled}
                  onClick={() => {
                    onEdit();
                  }}
                >
                  Edit
                </EditButton>
              </Box>
            )}
          </Box>
        }
        maxWidth={maxWidth}
      >
        <ThreeColumn
          key="tilt type"
          left={<SubDetailsText>Tilt type</SubDetailsText>}
          middle={undefined}
          right={<SubDetailsText>{tiltSelectionToLabel(tiltSelection).type}</SubDetailsText>}
        />
        <Box mt={1}>
          <ThreeColumn
            key="tilt amount"
            left={<SubDetailsText>Tilt amount</SubDetailsText>}
            middle={undefined}
            right={<SubDetailsText>{tiltSelectionToLabel(tiltSelection).value}</SubDetailsText>}
          />
        </Box>
      </Card>
    </>
  );
}
