/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Card, CardContent, Fade, Typography } from '@mui/material';
import LoadingSpinner from '~/synth/LoadingSpinner';
import FadeIn from './ReactFadeIn';
import GPTVisualCard, { VISUAL_TAG_NAMES } from './cards/GPTVisualCard';
import EmailCardHeader from './cards/EmailCardHeader';

// fade animation configuration
const TYPING_SPEED = 500;
const FADE_SPEED = 1500;
// how many words at a time we want to type
const CHUNK_SIZE = 12;

const splitOnMarkdowns = (str: string): { type: 'text' | 'visual'; content: string }[] => {
  const patternString = VISUAL_TAG_NAMES.map((tag) => `<${tag}[^>]*/>`).join('|');
  const regex = new RegExp(patternString, 'g');
  const result: { type: 'text' | 'visual'; content: string }[] = [];
  let lastIndex = 0;
  let match = regex.exec(str);

  while (match !== null) {
    const tagStart = match.index;
    const tagEnd = tagStart + match[0].length;
    if (tagStart !== lastIndex) {
      result.push({ type: 'text', content: str.slice(lastIndex, tagStart) });
    }
    result.push({ type: 'visual', content: match[0] });
    lastIndex = tagEnd;
    match = regex.exec(str);
  }

  if (lastIndex < str.length) {
    result.push({ type: 'text', content: str.slice(lastIndex) });
  }

  return result;
};

// find email tag, remove it and turn the emailMode on
const findEmailTagAndRemoveIfExists = (
  finalText: string,
  toggleEmailAccountId: (arg: string) => void
): string => {
  const emailString = '<email[^>]*/>';
  const regex = new RegExp(emailString, 'g');
  const match = regex.exec(finalText);
  if (match !== null) {
    const accountMatches = /account="([^"]*)"/.exec(match[0]);
    let accountId;
    if (accountMatches !== null) {
      // eslint-disable-next-line prefer-destructuring
      accountId = accountMatches[1];
      toggleEmailAccountId(accountId);
    }
    return finalText.replace(regex, '');
  }

  return finalText;
};

function groupChunks(chunks: string[], chunkSize: number): string[] {
  return chunks.reduce((acc: string[], token: string, index: number) => {
    if (index % chunkSize === 0) {
      acc.push(token);
    } else {
      const lastSentenceIndex = acc.length - 1;
      acc[lastSentenceIndex] = [acc[lastSentenceIndex], token].join(' ');
    }
    return acc;
  }, []);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function removeBrokenHtml(content: string): string {
  const ending = content.slice(-50);

  // Check if the ending contains an incomplete HTML tag
  const incompleteTagMatch = ending.match(/<[^>]+?(?=<|[^<]*$)/);

  if (incompleteTagMatch) {
    const indexOfIncompleteTag = content.lastIndexOf(incompleteTagMatch[0]);
    return content.substring(0, indexOfIncompleteTag);
  }

  return content;
}

function splitContentToChunks(content: string): string[] {
  // This regex captures entire HTML tags with their inner content or plain words

  // remove markdown newlines
  const parsedText = content.replace(/\n/g, ' <br> ');

  // remove broken html element from the end
  // const cleanedText = removeBrokenHtml(parsedText);

  // regex magic
  const regex = /<[^>]+>([^<]+<\/[^>]+>)?[,:;.!?]?|<br\s*\/?>|[^<\s]+[,:;.!?]?/g;
  const chunks = (parsedText.match(regex) as string[]) || [];

  // group words into bigger chunks for better fading effect
  const combinedChunks = groupChunks(chunks, CHUNK_SIZE);

  return combinedChunks;
}

const DisplayMessage = ({
  finalText,
  shouldType,
  loading,
  setTypingFinished,
  hideVisuals,
}: {
  finalText: string;
  shouldType: boolean;
  loading: boolean;
  setTypingFinished: () => void;
  hideVisuals?: boolean;
}) => {
  // if account id is present, we are in email mode and all text is printed in a card
  const [emailAccountId, setEmailAccountId] = useState<string | undefined>(undefined);

  const allElements = useMemo(() => {
    let cleanText = finalText.replace(/\n/g, ' <br> ');
    cleanText = findEmailTagAndRemoveIfExists(cleanText, setEmailAccountId);
    const splitText = splitOnMarkdowns(cleanText);
    return splitText;
  }, [finalText]);

  if (emailAccountId) {
    return (
      <Card>
        <EmailCardHeader
          title="Email Summary"
          onClickCopy={async () => {
            const textToCopy = allElements
              .map((e) => (e.type === 'text' ? e.content : ''))
              .join('');
            const blob = new Blob([textToCopy], { type: 'text/html' });
            const data = [new ClipboardItem({ 'text/html': blob })];
            try {
              await navigator.clipboard.write(data);
              return true;
            } catch (err) {
              return false;
            }
          }}
          copyDisabled={loading}
        />
        <Box>
          <CardContent>
            <MessageTyper
              allElements={allElements}
              loading={loading}
              shouldType={shouldType}
              setTypingFinished={setTypingFinished}
              hideVisuals={hideVisuals}
            />
          </CardContent>
        </Box>
      </Card>
    );
  }

  return (
    <MessageTyper
      allElements={allElements}
      loading={loading}
      shouldType={shouldType}
      setTypingFinished={setTypingFinished}
      hideVisuals={hideVisuals}
    />
  );
};

// iterate over the elements
const MessageTyper = ({ allElements, shouldType, loading, setTypingFinished, hideVisuals }) => {
  const [currentElementIndex, setCurrentElementIndex] = useState(0);

  const finishTyping = useCallback(() => {
    if (!loading && currentElementIndex === allElements.length) {
      setTypingFinished(true);
    } else {
      setCurrentElementIndex((prev) => prev + 1);
    }
  }, [allElements.length, currentElementIndex, loading]);

  return (
    <>
      {allElements.map((element, index) => (
        <>
          {currentElementIndex > index && (
            <TypewriterEffect
              element={element}
              loading={false}
              shouldType={shouldType}
              goToNextElement={undefined}
              hideVisuals={hideVisuals}
              key={index}
            />
          )}
          {currentElementIndex === index && (
            <TypewriterEffect
              element={element}
              loading={loading}
              shouldType={shouldType}
              hideVisuals={hideVisuals}
              goToNextElement={() => {
                finishTyping();
                if (!loading && currentElementIndex === allElements.length - 1) {
                  setTypingFinished(true);
                }
              }}
              key={index}
            />
          )}
        </>
      ))}
    </>
  );
};

const TypewriterEffect = ({
  element,
  loading,
  shouldType,
  hideVisuals,
  goToNextElement,
}: {
  element: { type: 'visual' | 'text'; content: string };
  loading: boolean;
  shouldType: boolean;
  hideVisuals?: boolean;
  goToNextElement?: () => void;
}) => {
  const allChunks = useMemo(() => splitContentToChunks(element.content), [element.content]);
  const [displayedChunks, setDisplayedChunks] = useState<string[]>([]);

  // finish logic
  useEffect(() => {
    if (!loading && displayedChunks.length === allChunks.length) {
      goToNextElement?.();
    }
    return undefined;
  }, [allChunks.length, displayedChunks.length, loading]);

  // typing logic
  useEffect(() => {
    if (shouldType) {
      const interval = setInterval(() => {
        if (displayedChunks.length !== allChunks.length) {
          setDisplayedChunks((prev) => {
            const nextChunk = allChunks[prev.length];
            const previousChunks = allChunks.slice(0, prev.length);
            if (nextChunk) {
              return [...previousChunks, nextChunk];
            }
            return prev;
          });
        }
      }, TYPING_SPEED);

      return () => {
        clearInterval(interval);
      };
    }

    setDisplayedChunks(allChunks);

    return undefined;
  }, [allChunks]);

  return (
    <>
      {element.type === 'text' && (
        <>
          <Typography
            component="span"
            variant="body1"
            sx={{ fontWeight: 400, fontSize: '1.1rem', lineHeight: '30px' }}
          >
            {shouldType && (
              <>
                {displayedChunks.map((word, index) => (
                  <Fade in timeout={FADE_SPEED} key={index}>
                    <Box
                      component="span"
                      dangerouslySetInnerHTML={{ __html: `${word} ` }}
                      sx={{
                        '& a': {
                          color: 'black',
                          backgroundColor: 'transparent',
                          lineHeight: '30px',
                        },
                        '& p': {
                          margin: 0,
                          lineHeight: '30px',
                        },
                        '& .currency': {
                          color: '#1753DE',
                        },
                      }}
                    />
                  </Fade>
                ))}
                {loading && displayedChunks.length === allChunks.length && (
                  <Box component="span" display="inline">
                    <LoadingSpinner />
                  </Box>
                )}
              </>
            )}

            {!shouldType && (
              <Box
                component="span"
                dangerouslySetInnerHTML={{ __html: allChunks.join(' ') }}
                sx={{
                  '& a': {
                    color: 'black',
                    backgroundColor: 'transparent',
                    lineHeight: '30px',
                  },
                  '& p': {
                    margin: 0,
                    lineHeight: '30px',
                  },
                  '& .currency': {
                    color: '#1753DE',
                  },
                }}
              />
            )}
          </Typography>
        </>
      )}

      {element.type === 'visual' && !hideVisuals && (
        <Box py={2}>
          <FadeIn transitionDuration={FADE_SPEED}>
            <GPTVisualCard text={element.content} />
          </FadeIn>
        </Box>
      )}
    </>
  );
};

export default DisplayMessage;
