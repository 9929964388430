import useSWR from 'swr';
import { getUnassignedAccounts } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getUnassignedAccountsSwrKey = () => generateSwrKey(`unassignedAccounts`);

const useUnassignedAccounts = () => {
  const { data, error, isValidating, mutate } = useSWR(getUnassignedAccountsSwrKey(), () =>
    getUnassignedAccounts()
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export default useUnassignedAccounts;
