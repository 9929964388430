import { Box, Button, Link as MuiLink, Typography, useTheme } from '@mui/material';
import { remove } from 'lodash';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { Account } from 'vise-types/portfolio';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import usePortfolioOverview from '~/hooks/usePortfolioOverview';
import useProductOnboarding from '~/hooks/useProductOnboarding';
import useGetFullXray from '~/hooks/useGetFullXray';
import { CapitalGains } from '~/models/api';
import { ActionBarV2 } from '~/routes/PortfolioCreator2/screens/components';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { ReactComponent as XrayIcon } from '~/static/images/icons/x-ray.svg';
import Banner from '~/synth/Banner';
import Skeleton from '~/synth/Skeleton';
import AssetAllocationSection from './AssetAllocationSection';
import HistoricPerformance from './HistoricPerformance';
import RiskMetricsSection from './RiskMetricsSection';
import TableOfContents from './TableOfContents';
import TaxBenefitsSection from './TaxBenefitsSection';

function XrayInsights({
  intelligence,
  account,
  gains,
  noSidebar,
}: {
  intelligence: PortfolioIntelligenceFull;
  account: Account;
  gains?: CapitalGains;
  noSidebar?: boolean;
}) {
  const [currentView, setCurrentView] = useState<('allocations' | 'taxes' | 'historic' | 'risk')[]>(
    ['allocations', 'taxes', 'historic', 'risk']
  );

  const theme = useTheme();
  const history = useHistory();
  // This was originally called in the AssetAllocationSection component level as multiple subcompoents use the same
  // data. It was moved here because the TableOfContents component searches for all the h2/h3 elements in the page
  // on load and the AssetAllocationSection subcomponents were not yet rendered (conditional on the data load).
  const { productOnboardingRequired } = useProductOnboarding();
  const { data: featureFlags } = useFeatureFlags();
  const { data: overviewData } = usePortfolioOverview(account.id);

  if (intelligence.proposalType === 'light') {
    throw new Error(`Portfolio intelligence should be PCE2 and FULL. (id: ${intelligence.id})`);
  }

  const { data: xrayResponse } = useGetFullXray(
    intelligence.status === 'READY' ? intelligence.id : null
  );
  const { riskMetrics, fixedIncomeMetrics, fees, fundOverlap } = xrayResponse?.data ?? {};

  return (
    <>
      <Banner
        size="small"
        borderColor="primary.main"
        bgColor="blue.100"
        message={
          <Box display="flex" alignItems="center">
            <Box mr={1} display="flex" color="primary.main">
              <InformationCircleIcon />
            </Box>
            For Institutional clients only.
          </Box>
        }
      />
      <Box display="flex" alignItems="center" pt={4} pb={5}>
        <Box
          width={40}
          height={40}
          bgcolor={theme.palette.blue[200]}
          mr={1.5}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="50%"
        >
          <XrayIcon fontSize={24} />
        </Box>
        <Typography variant="h2">X-Ray Insights</Typography>
      </Box>
      <Box display="flex" position="relative">
        {overviewData?.data.metrics == null ? (
          <Box minWidth={275} mr={2}>
            <Skeleton width="100%" height="15em" variant="rect" />
          </Box>
        ) : (
          <TableOfContents />
        )}
        <Box>
          {overviewData?.data.metrics == null ? (
            <Box mb={2}>
              <Skeleton width="100%" height="20em" variant="rect" />
            </Box>
          ) : (
            <AssetAllocationSection
              fixedIncomeMetrics={fixedIncomeMetrics}
              fundOverlap={fundOverlap}
              fees={fees}
              onClick={() => {
                if (currentView.includes('allocations')) {
                  const result = [...currentView];
                  remove(result, (n) => n === 'allocations');
                  setCurrentView(result);
                } else {
                  setCurrentView([...currentView, 'allocations']);
                }
              }}
              collapseProps={{ in: currentView.includes('allocations') }}
              intelligence={intelligence}
              account={account}
              portfolioMetrics={overviewData?.data.metrics}
            />
          )}
          {gains != null && account.taxable && (
            <>
              <TaxBenefitsSection
                onClick={() => {
                  if (currentView.includes('taxes')) {
                    const result = [...currentView];
                    remove(result, (n) => n === 'taxes');
                    setCurrentView(result);
                  } else {
                    setCurrentView([...currentView, 'taxes']);
                  }
                }}
                collapseProps={{ in: currentView.includes('taxes') }}
                gains={gains}
                intelligence={intelligence}
              />
            </>
          )}
          <HistoricPerformance
            intelligence={intelligence}
            onClick={() => {
              if (currentView.includes('historic')) {
                const result = [...currentView];
                remove(result, (n) => n === 'historic');
                setCurrentView(result);
              } else {
                setCurrentView([...currentView, 'historic']);
              }
            }}
            collapseProps={{ in: currentView.includes('historic') }}
          />
          {featureFlags?.enable_mpt_metrics === 'on' ? (
            <RiskMetricsSection
              intelligence={intelligence}
              riskMetrics={riskMetrics}
              onClick={() => {
                if (currentView.includes('risk')) {
                  const result = [...currentView];
                  remove(result, (n) => n === 'risk');
                  setCurrentView(result);
                } else {
                  setCurrentView([...currentView, 'risk']);
                }
              }}
              collapseProps={{ in: currentView.includes('risk') }}
            />
          ) : null}
          {productOnboardingRequired &&
            !noSidebar &&
            createPortal(
              <Box position="absolute" bottom="0" width="100%" zIndex="999">
                <ActionBarV2>
                  <Box display="flex" justifyContent="end">
                    <Button
                      onClick={() =>
                        history.push(
                          '/secure/product-onboarding/dashboard/xray-analysis/confirmation'
                        )
                      }
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                </ActionBarV2>
              </Box>,
              document.body
            )}
          <Box py={2} fontSize={12} color="grey.600">
            For more information, see our{' '}
            <MuiLink component={Link} to="/secure/disclosures">
              methodology, assumptions, and other disclosures.
            </MuiLink>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default XrayInsights;
