import React from 'react';
import { Divider, ListItemIcon, Typography, useTheme } from '@mui/material';
import { ReactComponent as PencilIcon } from '~/static/images/icons/pencil.svg';
import { ReactComponent as PencilAltIcon } from '~/static/images/icons/pencil-alt.svg';
import { ReactComponent as TrashIcon } from '~/static/images/icons/trash.svg';
import DropdownButtonMenu, {
  DropdownButtonMenuItem,
  DropdownButtonMenuProps,
} from '~/synth/DropdownButtonMenu';
import { ProposalsModalProps } from './ProposalsModal';

type EditDeleteProposalMenuProps = {
  onEditClick: (e: React.MouseEvent) => void;
  onDeleteClick: (e: React.MouseEvent) => void;
  onRenameClick?: (e: React.MouseEvent) => void;
  disableEdit?: boolean;
  disabledReason?: string;
} & Pick<DropdownButtonMenuProps, 'buttonContent' | 'isIcon' | 'stopPropagation'>;
export const PROPOSALS_MODAL_CLOSED_SETTINGS: Pick<
  ProposalsModalProps,
  | 'open'
  | 'accountName'
  | 'proposals'
  | 'accountId'
  | 'accountNumber'
  | 'viseClientId'
  | 'accountId'
  | 'bondProposals'
  | 'engineType'
> = {
  open: false,
  accountName: '',
  proposals: [],
  accountId: '',
  accountNumber: '',
  viseClientId: '',
  bondProposals: [],
  engineType: 'PCE2',
};

const EditDeleteProposalMenu = ({
  onEditClick,
  onDeleteClick,
  onRenameClick,
  disableEdit,
  disabledReason,
  ...other
}: EditDeleteProposalMenuProps) => {
  const theme = useTheme();

  return (
    <DropdownButtonMenu
      disabledReason={disabledReason}
      {...other}
      buttonProps={{ variant: 'outlined' }}
    >
      {(closeMenu) => [
        <DropdownButtonMenuItem disabled={disableEdit} onClick={(e) => onEditClick(e)} key="1">
          <ListItemIcon>
            <PencilAltIcon style={{ fontSize: '18px', color: 'black' }} />
          </ListItemIcon>
          Edit proposal
        </DropdownButtonMenuItem>,
        onRenameClick && (
          <DropdownButtonMenuItem
            onClick={(e) => {
              onRenameClick(e);
              closeMenu();
            }}
            key="2"
          >
            <ListItemIcon>
              <PencilIcon style={{ fontSize: '18px', color: 'black' }} />
            </ListItemIcon>
            Rename proposal
          </DropdownButtonMenuItem>
        ),
        <Divider key="3" />,
        <DropdownButtonMenuItem onClick={onDeleteClick} key="4">
          <ListItemIcon>
            <TrashIcon color={theme.palette.error[500]} style={{ fontSize: '18px' }} />
          </ListItemIcon>
          <Typography variant="body2" color="error">
            Delete proposal
          </Typography>
        </DropdownButtonMenuItem>,
      ]}
    </DropdownButtonMenu>
  );
};

export default EditDeleteProposalMenu;
