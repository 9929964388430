import React from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Box, Link } from '@mui/material';

interface ToastAction {
  link: string;
  linkText: string;
}

export interface ToastProps extends Omit<AlertProps, 'color' | 'variant' | 'action'> {
  title: string;
  content?: string;
  action?: ToastAction;
}

const Toast = React.forwardRef<HTMLDivElement, ToastProps>(function Toast(
  { title, content, action, severity, ...props }: ToastProps,
  ref
) {
  return (
    <Alert
      ref={ref}
      severity={severity}
      variant={severity === 'error' ? 'standard' : 'filled'}
      action={
        action ? (
          <Box px={1} fontWeight="fontWeightBold">
            <Link href={action.link} color="inherit" underline="always">
              {action.linkText}
            </Link>
          </Box>
        ) : undefined
      }
      {...props}
    >
      <AlertTitle>{title}</AlertTitle>
      {content && <Box mt={1}>{content}</Box>}
    </Alert>
  );
});

export default Toast;
