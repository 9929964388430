import { Card, Grid, styled } from '@mui/material';

export const StretchGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const FlexGrowCard = styled(Card)(() => ({
  flex: 1,
}));
