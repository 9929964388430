import {
  Box,
  Divider,
  Drawer,
  Fade,
  List,
  ListItemButton,
  ListItemIcon,
  Tooltip,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { matchPath } from 'react-router';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { doRequestWithAuth } from '~/api/apiUtil';
import { locale } from '~/constants';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useProductOnboarding from '~/hooks/useProductOnboarding';
import { ReactComponent as BookIcon } from '~/static/images/icons/book.svg';
import { ReactComponent as ModelTemplateCenterIcon } from '~/static/images/icons/chess-piece.svg';
import { ReactComponent as ChevronDoubleLeftIcon } from '~/static/images/icons/chevron-double-left.svg';
import { ReactComponent as DashboardIcon } from '~/static/images/icons/dashboard.svg';
import { ReactComponent as FolderAddIcon } from '~/static/images/icons/folder-add.svg';
import { ReactComponent as LogoutIcon } from '~/static/images/icons/logout.svg';
import { ReactComponent as MoneyBagIcon } from '~/static/images/icons/money-bag.svg';
import { ReactComponent as UserGroupIcon } from '~/static/images/icons/user-group.svg';
import { ReactComponent as TransitionIcon } from '~/static/images/icons/transition.svg';
import { ReactComponent as XrayIcon } from '~/static/images/icons/x-ray.svg';
import { ReactComponent as ChatIcon } from '~/static/images/icons/chat-alt.svg';
import { ReactComponent as ViseIntelligenceIcon } from '~/static/images/icons/vise-intelligence-icon.svg';
import { ReactComponent as MultiAccountIcon } from '~/static/images/icons/multi-account.svg';

import ViseLogoBlackFull from '~/static/images/vise-logo-black-1.svg';
import { TextHighlightTag } from '~/synth/Tag';
import { translate } from '../../../utils';
import amplitude from '../../../utils/amplitude';
import FeedbackModal from './FeedbackModal';

const ITEM_TO_ICON = {
  households: UserGroupIcon,
  distributions: MoneyBagIcon,
  portfolioCreator: FolderAddIcon,
  modelTemplateCenter: ModelTemplateCenterIcon,
  xray: XrayIcon,
  advisorDashboard: DashboardIcon,
  resourceCenter: BookIcon,
  viseIntelligence: ViseIntelligenceIcon,
  sendFeedback: ChatIcon,
  bondBuilder: FolderAddIcon,
  transition: TransitionIcon,
  'multi-account': MultiAccountIcon,
};

const sendClickEvent = (requestedPage: string) => () => {
  amplitude().logEvent('SideBarClick', {
    requestedPage,
    category: EVENT_CATEGORIES.NAVIGATION,
  });
};

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  '&.MuiListItemButton-root:hover': {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },

  '&.MuiListItemButton-root': {
    borderLeft: '4px solid transparent',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),

    color: '#333336',
    '& .MuiListItemIcon-root': {
      color: '#333336',
    },
  },

  '&.Mui-selected': {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: '#f8f8f8',
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));

const useStyles = makeStyles()((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: '230px',
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: '230px',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(8),
  },
  logoHide: {
    transform: 'translateX(4px)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoShow: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuIconCollapse: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuIconExpand: {
    transform: 'rotate(-180deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    overflowX: 'hidden',
    borderRight: 'none',
  },
}));

const Section = ({
  title,
  collapsed,
  children,
}: {
  title?: string;
  collapsed: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Box mb={4}>
      {title ? (
        <Box pb={1} mx={2.5} color="#333336" borderBottom="solid 1px #ebecf4" mb={1}>
          <Typography fontWeight={500} variant="body1" textAlign={collapsed ? 'center' : 'left'}>
            {collapsed ? title.slice(0, 1) : title}
          </Typography>
        </Box>
      ) : null}
      {children}
    </Box>
  );
};

function Option({
  component = Link,
  fade,
  name,
  to,
  /** Normally we determine if the sidebar item (option) is
   *  "active" based on the path of the current
   *  active route. However, some sidebar items may
   *  also be activated on custom routes that don't match their "to"
   *  route - these custom routes can be passed in here.
   *  */
  customMatches,
  disabled,
  showNewTag,
  onClick,
}: {
  component?: string | React.Component | React.ElementType;
  fade: boolean;
  name: keyof typeof ITEM_TO_ICON;
  to?: string;
  customMatches?: string[];
  disabled?: boolean;
  showNewTag?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) {
  const { pathname } = useLocation();
  const match = useRouteMatch(to ?? '');
  const selected =
    (match != null && to != null) ||
    (customMatches &&
      customMatches.some((s) =>
        matchPath(pathname, {
          path: s,
        })
      ));

  const Icon = ITEM_TO_ICON[name];

  return (
    <Tooltip
      arrow
      /* Enable tooltip only when the option is in "faded" mode. When not faded, the text of the
       * option is visible and so the tooltip is redundant. */
      disableFocusListener={!fade}
      disableHoverListener={!fade}
      disableTouchListener={!fade}
      placement="right"
      title={translate(locale, `sidebar.${name}`)}
    >
      <StyledListItem
        disabled={disabled}
        /* FIXME: The `withStyles` HOC somehow loses the `component` prop type and does not extend
         * <Link>, which makes the `to` prop unrecognized as well */
        {...({ component, to } as object)}
        onClick={(event) => {
          onClick?.(event);
          sendClickEvent(name);
        }}
        selected={selected}
      >
        <ListItemIcon>
          <Icon height="20" width="20" />
        </ListItemIcon>
        <Fade appear={false} in={!fade}>
          <Box display="flex" alignItems="center">
            <Typography variant="body1">{translate(locale, `sidebar.${name}`)}</Typography>
            {showNewTag && (
              <TextHighlightTag severity="priority" ml={1}>
                New
              </TextHighlightTag>
            )}
          </Box>
        </Fade>
      </StyledListItem>
    </Tooltip>
  );
}

interface SidebarProps {
  onClickLogout: () => void;
  onToggle: () => void;
  open: boolean;
}

export default function Sidebar({ onClickLogout, onToggle, open }: SidebarProps) {
  const { classes, cx } = useStyles();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = React.useState(false);
  const { data: featureFlags } = useFeatureFlags();
  const enableModelTemplateCenter = featureFlags?.enable_model_template_center === 'on';
  const enablePortfolioXray = featureFlags?.enable_portfolio_xray === 'on';
  const enableResourceCenter = featureFlags?.enable_resource_center === 'on';
  const enableViseIntelligence = featureFlags?.enable_gpt === 'on';
  const enableUserFeedback = featureFlags?.enable_user_feedback === 'on';
  const enableFixedIncome = featureFlags?.enable_fixed_income === 'on';
  const { productOnboardingRequired, isTemplateOnboardingComplete, isXrayComplete, featureFlagOn } =
    useProductOnboarding();
  const enableDashboard = featureFlags?.enable_advisor_dashboard === 'on';
  const enableModelDelivery = featureFlags?.is_enterprise_user === 'on';

  async function handleResourceCenterClick(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    const { data } = await doRequestWithAuth<{ accessToken: string }>({
      method: 'GET',
      requestUrl: '/auth/generate_jwt_external',
    });
    window.location.href = `https://vise-resource-center.bubbleapps.io/vise-resource-center?accessToken=${data.accessToken}`;
  }

  return (
    <>
      <StyledDrawer
        className={cx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: cx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        variant="permanent"
      >
        <List>
          <StyledListItem onClick={onToggle} style={{ marginBottom: '30px' }}>
            <ListItemIcon>
              <ChevronDoubleLeftIcon
                className={cx({
                  [classes.menuIconCollapse]: open,
                  [classes.menuIconExpand]: !open,
                })}
                height="20"
                width="20"
              />
            </ListItemIcon>
          </StyledListItem>
          <Section collapsed={!open}>
            {enableDashboard && (
              <Option fade={!open} name="advisorDashboard" to="/secure/dashboard" />
            )}
          </Section>
          <Section collapsed={!open} title="BUILD">
            {enableModelDelivery ? (
              <>
                {' '}
                <Option
                  fade={!open}
                  component={MuiLink}
                  to="/md/transition"
                  name="transition"
                  disabled={!isXrayComplete && featureFlagOn}
                />
              </>
            ) : (
              <>
                <Option
                  fade={!open}
                  to="/secure/portfolio-creator-next"
                  name="portfolioCreator"
                  disabled={!isXrayComplete && featureFlagOn}
                />
                {enableFixedIncome && (
                  <Option
                    fade={!open}
                    to="/secure/bond-portfolio-creator/select-client"
                    name="bondBuilder"
                    disabled={!isXrayComplete && featureFlagOn}
                    showNewTag
                  />
                )}
                {enableModelTemplateCenter && (
                  <Option
                    fade={!open}
                    name="modelTemplateCenter"
                    to="/secure/strategy-center"
                    disabled={!isTemplateOnboardingComplete && featureFlagOn}
                  />
                )}
              </>
            )}
          </Section>
          <Section collapsed={!open} title="MANAGE">
            {enableModelDelivery ? (
              <>
                <Option
                  customMatches={[
                    '/households',
                    '/secure/clients',
                    '/secure/portfolio-preview/',
                    '/secure/accounts/:id/portfolio',
                  ]}
                  fade={!open}
                  name="households"
                  to="/secure/households"
                  disabled={productOnboardingRequired}
                />
                <Option
                  fade={!open}
                  name="distributions"
                  to="/secure/md/distributions"
                  disabled={productOnboardingRequired}
                />
                <Option
                  fade={!open}
                  component={MuiLink}
                  name="multi-account"
                  to="/md/review"
                  disabled={productOnboardingRequired}
                />
              </>
            ) : (
              <>
                <Option
                  customMatches={[
                    '/households',
                    '/secure/clients',
                    '/secure/portfolio-preview/',
                    '/secure/accounts/:id/portfolio',
                  ]}
                  fade={!open}
                  name="households"
                  to="/secure/households"
                  disabled={productOnboardingRequired}
                />
                <Option
                  fade={!open}
                  name="distributions"
                  to="/secure/distributions"
                  disabled={productOnboardingRequired}
                />
              </>
            )}
          </Section>
          {(!enablePortfolioXray && !enableResourceCenter) || enableModelDelivery ? null : (
            <Section collapsed={!open} title="EXPLAIN">
              {enablePortfolioXray && (
                <Option
                  fade={!open}
                  name="xray"
                  to="/secure/x-ray"
                  disabled={!isXrayComplete && featureFlagOn}
                />
              )}
              {enableViseIntelligence && (
                <Option
                  fade={!open}
                  name="viseIntelligence"
                  to="/secure/intelligence"
                  disabled={productOnboardingRequired}
                />
              )}
              {enableResourceCenter && (
                <Option
                  fade={!open}
                  name="resourceCenter"
                  component="div"
                  onClick={handleResourceCenterClick}
                />
              )}
            </Section>
          )}
        </List>
        {/* Use the same logo twice in different containers to be able to hide the "ise" of "Vise".
         * The containers look like the following:
         *
         *     [ V ][ ise ]
         *
         * When the logo is collapsed, the right [ ise ] container fades out and the [ V ] shifts
         * right slightly to center it.
         */}
        <Box>
          {enableUserFeedback && (
            <Option
              fade={!open}
              name="sendFeedback"
              component="div"
              showNewTag
              onClick={() => {
                setIsFeedbackModalOpen(true);
              }}
            />
          )}

          <Box mx={2.5}>
            <Divider />
          </Box>
          <List>
            <Tooltip
              arrow
              disableFocusListener={open}
              disableHoverListener={open}
              disableTouchListener={open}
              placement="right"
              title={translate(locale, `sidebar.logout`)}
            >
              <StyledListItem onClick={onClickLogout}>
                <ListItemIcon>
                  <LogoutIcon height="20" width="20" />
                </ListItemIcon>
                <Fade appear={false} in={open}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1">{translate(locale, `sidebar.logout`)}</Typography>
                  </Box>
                </Fade>
              </StyledListItem>
            </Tooltip>
          </List>
          <Box mb={3} ml={2.5}>
            <Box
              className={cx({
                [classes.logoHide]: !open,
                [classes.logoShow]: open,
              })}
              display="inline-block"
              overflow="hidden"
              width="24px"
            >
              <img alt="Vise" height="24" src={ViseLogoBlackFull} width="72" />
            </Box>
            <Fade appear={false} in={open}>
              <Box display="inline-block" overflow="hidden" width="50px">
                <img
                  alt=""
                  height="24"
                  src={ViseLogoBlackFull}
                  style={{
                    position: 'relative',
                    left: '-24px',
                  }}
                  width="72"
                />
              </Box>
            </Fade>
          </Box>
        </Box>
      </StyledDrawer>
      {isFeedbackModalOpen && (
        <FeedbackModal
          open={isFeedbackModalOpen}
          onClose={() => {
            setIsFeedbackModalOpen(false);
          }}
        />
      )}
    </>
  );
}
