import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Column } from 'react-table';
import styled from 'styled-components';
import { STRATEGY_PERFORMANCE_METRIC_NAMES } from 'vise-types/pce2';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import useActiveTiltsStrategyPerformanceMetrics from '~/hooks/useActiveTiltsStrategyPerformanceMetrics';
import { DataTable, DataTableContainer } from '~/synth/DataTable';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import PopoverCard from '~/synth/PopoverCard';
import PopoverLink from '~/synth/PopoverLink';
import PopoverTrigger from '~/synth/PopoverTrigger';
import Skeleton from '~/synth/Skeleton';
import { WithLoader } from '~/utils';

interface ActiveTiltColumns {
  strategyPerformance: STRATEGY_PERFORMANCE_METRIC_NAMES;
  atTilt: number | null;
  noTilt: number | null;
  diff: number | null;
}

interface Props {
  assetClassKeys: AssetClassKey[];
  tiltAmount: number;
  tiltType: 'multi-factor' | 'dividend';
  setDisclosuresPanelOpen: (open: boolean) => void;
}

const StyledTab = styled(Tab)`
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 8px;

  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.blue[100]};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const SINGLE_SECURITY_STRATEGY_ASSET_CLASS_TO_LABEL_MAP = new Map<AssetClassKey, string>([
  ['EQUITY/US/LARGE_CAP', 'U.S. Large/mid cap'],
  ['EQUITY/US/SMALL_CAP', 'U.S. Small cap'],
  ['EQUITY/DEVELOPED/LARGE_CAP', 'Dev. ex U.S. Large/mid cap'],
]);

const STRATEGY_PERFORMANCE_METRIC_NAMES_TO_LABEL_MAP = new Map<
  STRATEGY_PERFORMANCE_METRIC_NAMES,
  string
>([
  ['annualized_return', 'Annualized returns'],
  ['annualized_standard_deviation', 'Annualized standard deviation'],
  ['annualized_root_mean_square_error', 'Annualized root mean square error'],
  ['maximum_drawdown', 'Maximum drawdown'],
  [
    'percent_of_rolling_periods_outperforms',
    '% of rolling periods in which tilted portfolio outperforms untilted',
  ],
  ['weighted_dividend_yield', 'Avg. Dividend-yield'],
]);

const LoadingTable = ({ pageSize, tiltAmount }: { pageSize: number; tiltAmount: number }) => {
  const columns = useMemo(
    () => [
      {
        id: 'Strategy Performance',
        width: '52%',
        Cell: <Skeleton inline width="25em" />,
      },
      {
        id: `Tilt at ${tiltAmount}`,
        width: '16%',
        Cell: <Skeleton inline width="25em" />,
      },
      {
        id: 'No tilt',
        width: '16%',
        Cell: <Skeleton inline width="25em" />,
      },
      {
        id: 'Diff.',
        width: '16%',
        Cell: <Skeleton inline width="25em" />,
      },
    ],
    [tiltAmount]
  );

  return <DataTable columns={columns} data={Array(pageSize).fill({})} />;
};

function ActiveTiltsTable({
  assetClassKeys,
  tiltAmount,
  tiltType,
  setDisclosuresPanelOpen,
}: Props) {
  const theme = useTheme();
  const [selectedAssetClassStrategy, setSelectedAssetClassStrategy] = useState<AssetClassKey>(
    assetClassKeys[0]
  );

  const [selectedTimePeriod, setSelectedTimePeriod] = useState<number>(20);
  function handleTimePeriodChange(_event: ChangeEvent<{}>, value: number) {
    setSelectedTimePeriod(value);
  }

  const columns: Column<ActiveTiltColumns>[] = useMemo(
    () => [
      {
        Header: 'Strategy Performance',
        accessor: 'strategyPerformance',
        width: '52%',
        Cell: ({ value }) => {
          let label: string;
          if (value === 'average_rolling_returns') {
            label = `Average rolling ${selectedTimePeriod}-Year returns`;
          } else {
            label = STRATEGY_PERFORMANCE_METRIC_NAMES_TO_LABEL_MAP.get(value) || '';
          }

          return <Box color="grey.600">{label}</Box>;
        },
      },
      {
        Header: `Tilt at ${tiltAmount}`,
        accessor: 'atTilt',
        width: '12%',
        Cell: ({ value }) => <Box color="grey.600">{value == null ? '-' : `${value}%`}</Box>,
      },
      {
        Header: 'No tilt',
        accessor: 'noTilt',
        width: '12%',
        Cell: ({ value }) => <Box color="grey.600">{value == null ? '-' : `${value}%`}</Box>,
      },
      {
        Header: 'Diff.',
        accessor: 'diff',
        width: '12%',
        Cell: ({ value }) => {
          if (value == null) {
            return <Box color="grey.600">-</Box>;
          }
          const isPositive = value > 0;
          const isNegative = value < 0;
          let color = 'grey.600';
          let textValue = `${value.toFixed(2)}%`;
          if (isPositive) {
            color = 'success.400';
            textValue = `+${textValue}`;
          } else if (isNegative) {
            color = 'error.400';
          }

          return <Box color={color}>{textValue}</Box>;
        },
      },
    ],
    [tiltAmount, selectedTimePeriod]
  );

  const { data, error } = useActiveTiltsStrategyPerformanceMetrics({
    tiltAmount,
    tiltType,
    assetClassKey: selectedAssetClassStrategy,
  });
  const isLoading = !data && !error;

  const tableData: { [key: number]: ActiveTiltColumns[] } | null = useMemo(() => {
    if (data) {
      const { results } = data;

      const tableDataSets = {};

      results.forEach(({ timePeriod, strategyPerformanceMetrics }) => {
        tableDataSets[timePeriod] = strategyPerformanceMetrics.map(
          ({ metricName, metric, metricAtTilt0, diffFromTilt0 }) => ({
            strategyPerformance: metricName,
            diff: diffFromTilt0,
            atTilt: metric,
            noTilt: metricAtTilt0,
          })
        );
      });

      return tableDataSets;
    }

    return null;
  }, [data]);

  return (
    <>
      <Box border={`1px solid ${theme.palette.grey[200]}`} p={2.5}>
        <Typography variant="h4">Potential outcomes based on tilt amount</Typography>
      </Box>
      <Box
        border={`1px solid ${theme.palette.grey[200]}`}
        borderTop="none"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        p={2.5}
      >
        <Box color="grey.600" display="flex" flexDirection="row" alignItems="center">
          Strategy:
          <DropdownButtonMenu
            buttonContent={SINGLE_SECURITY_STRATEGY_ASSET_CLASS_TO_LABEL_MAP.get(
              selectedAssetClassStrategy
            )}
            buttonProps={{ variant: 'outlined' }}
            style={{ marginLeft: '16px' }}
          >
            {(closeMenu) => (
              <>
                {Array.from(assetClassKeys).map((assetClassKey) => (
                  <DropdownButtonMenuItem
                    onClick={() => {
                      setSelectedAssetClassStrategy(assetClassKey);
                      closeMenu();
                    }}
                    key={`${assetClassKey}-menu-item`}
                  >
                    {SINGLE_SECURITY_STRATEGY_ASSET_CLASS_TO_LABEL_MAP.get(assetClassKey)}
                  </DropdownButtonMenuItem>
                ))}
              </>
            )}
          </DropdownButtonMenu>
        </Box>
        <Tabs
          TabIndicatorProps={{ hidden: true }}
          value={selectedTimePeriod}
          onChange={handleTimePeriodChange}
          aria-label="Time period selection"
        >
          {data &&
            data.results.map(({ timePeriod }) => (
              <StyledTab label={`${timePeriod} yr`} key={`${timePeriod}-year`} value={timePeriod} />
            ))}
        </Tabs>
      </Box>
      <WithLoader
        isLoading={isLoading}
        loader={<LoadingTable pageSize={tiltType === 'dividend' ? 7 : 6} tiltAmount={tiltAmount} />}
      >
        {tableData && tableData[selectedTimePeriod] && (
          <DataTableContainer>
            <DataTable
              columns={columns}
              data={tableData[selectedTimePeriod]}
              footer={
                <PopoverTrigger
                  overlay={({ close }) => (
                    <PopoverCard
                      title="Backtesting methodology"
                      body={
                        <>
                          Data is calculated from monthly-rebalanced backtests of unsampled target
                          strategies, as of December 31, 2022. Empirical research suggests that
                          tilting towards these premiums increases the expected return of the
                          strategy and is designed to lead to outperformance. Investing in factor
                          premiums requires discipline, as there will be periods of time where
                          factor premiums underperform. The longer you maintain exposure to each
                          premium, the more you improve the probability of strategy outperformance.
                          <Box
                            onClick={() => setDisclosuresPanelOpen(true)}
                            mt={2}
                            color="primary.main"
                            style={{ cursor: 'pointer' }}
                          >
                            View full disclosures
                          </Box>
                        </>
                      }
                      onClose={close}
                    />
                  )}
                >
                  <PopoverLink>How this data is calculated</PopoverLink>
                </PopoverTrigger>
              }
            />
          </DataTableContainer>
        )}
      </WithLoader>
    </>
  );
}

export default ActiveTiltsTable;
