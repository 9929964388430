import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, Divider, Typography, useTheme } from '@mui/material';
import { DataTable } from '~/synth/DataTable';
import {
  AllocationsTemplate,
  GetModelTemplateCenterViewDataResponse,
  RestrictionsTemplate,
} from 'vise-types/template';
import { Column, TableState, UsePaginationState, usePagination, useTable } from 'react-table';
import { formatPercent } from '~/utils/format';
import { format, isValid } from 'date-fns';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import { ReactComponent as DotsHorizontalIcon } from '~/static/images/icons/dots-horizontal.svg';
import { AllocationsTemplateBody, RestrictionTemplateBody, editTemplate } from '~/api/api';
import { useHistory } from 'react-router';
import { useIndexedInstruments } from '~/hooks/useInstruments';
import { useIndexedSectors } from '~/hooks/useSectors';
import useUser from '~/hooks/useUser';
import { mapValues } from 'lodash';
import DialogTitle from '~/synth/DialogTitle';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import PageNavigation from '~/components/table/PageNavigation';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { TemplateBody } from './workflows/AllocationTemplateCard';
import Card from './workflows/Card';
import { RestrictionsTemplateBody } from './workflows/RestrictionsTemplateCard';
import EmptyState from './EmptyState';

const PAGE_SIZE = 10;

interface ActionCallbacks {
  view: () => void;
  restore: () => Promise<void>;
  restoringTemplate: boolean;
  restoringTemplateDisabled?: boolean;
}

interface AllocationTemplateTable {
  date: string;
  editedBy: string;
  equities: string;
  fixedIncome: string;
  alternatives: string;
  actionCallbacks: ActionCallbacks;
}

interface RestrictionsTemplateTable {
  date: string;
  editedBy: string;
  tickers: string;
  sectors: string;
  countries: string;
  values: string;
  actionCallbacks: ActionCallbacks;
}

function MenuCell({
  value: { view, restore, restoringTemplate, restoringTemplateDisabled },
}: {
  value: ActionCallbacks;
}) {
  const theme = useTheme();
  return (
    <DropdownButtonMenu
      isIcon
      buttonContent={<DotsHorizontalIcon color={theme.palette.grey[800]} />}
      buttonProps={{ 'aria-label': 'Action item menu' }}
    >
      {(close) => [
        <DropdownButtonMenuItem
          key="view"
          disabled={restoringTemplateDisabled}
          onClick={() => {
            view();
            close();
          }}
        >
          View
        </DropdownButtonMenuItem>,
        <DropdownButtonMenuItem
          key="dismiss"
          onClick={async () => {
            await restore();
            close();
          }}
          disabled={restoringTemplate || restoringTemplateDisabled}
        >
          Restore
        </DropdownButtonMenuItem>,
      ]}
    </DropdownButtonMenu>
  );
}

const allocationTemplateColumns: Column<AllocationTemplateTable>[] = [
  {
    Header: 'Date/time',
    accessor: 'date',
  },
  {
    Header: 'Edited by',
    accessor: 'editedBy',
  },
  {
    Header: 'Equities',
    accessor: 'equities',
  },
  {
    Header: 'Fixed income',
    accessor: 'fixedIncome',
  },
  {
    Header: 'Alternatives',
    accessor: 'alternatives',
  },
  {
    Header: '',
    accessor: 'actionCallbacks',
    Cell: MenuCell,
  },
];

const restrictionsTemplateColumns: Column<RestrictionsTemplateTable>[] = [
  { Header: 'Date/time', accessor: 'date', width: '20%' },
  { Header: 'Edited by', accessor: 'editedBy' },
  { Header: 'Tickers', accessor: 'tickers' },
  { Header: 'Sectors', accessor: 'sectors' },
  { Header: 'Countries', accessor: 'countries' },
  { Header: 'Values', accessor: 'values' },
  { Header: '', accessor: 'actionCallbacks', Cell: MenuCell },
];

export default function VersionHistory({
  template,
  data,
}: {
  template: AllocationsTemplate | RestrictionsTemplate;
  data: GetModelTemplateCenterViewDataResponse;
}) {
  const [viewModalOpenTemplate, setViewModalOpenTemplate] = useState<
    AllocationsTemplate | RestrictionsTemplate | undefined
  >(undefined);
  const history = useHistory();
  const { data: user } = useUser();
  const [restoringTemplate, setRestoringTemplate] = useState(false);
  const enqueueCoachmark = useEnqueueCoachmark();
  const restoreAllocationTemplate = useCallback(
    async (t: AllocationsTemplateBody) => {
      if (!user) {
        return;
      }
      setRestoringTemplate(true);
      try {
        const { data } = await editTemplate({
          templateId: template.parentId,
          templateBody: {
            name: t.name,
            userId: user.id,
            orgId: t.orgId,
            allocations: t.allocations,
            isCustomAllocation: t.isCustomAllocation,
            customAllocations: t.customAllocations,
          },
        });
        if (data && data.templateId) {
          history.push(
            `/secure/view-template/${data.templateId}?editTemplateId=${data.templateId}`
          );
        }
      } catch (e) {
        enqueueCoachmark({
          severity: 'error',
          title: `Failed to restore template.`,
          content: `Please reach out to clientservice@vise.com for help.`,
        });
      } finally {
        setRestoringTemplate(false);
      }
    },
    [history, template.parentId, user, enqueueCoachmark]
  );

  const allocationTemplateData = useMemo(() => {
    if (template.type === 'restrictions') {
      return [] as AllocationTemplateTable[];
    }
    return data.allocationsTemplates
      .filter((t) => t.originalTemplateId === template.originalTemplateId && t.id !== template.id)
      .sort((t1, t2) => {
        return t2.createdAt.localeCompare(t1.createdAt);
      })
      .map((t) => {
        return {
          date: format(new Date(t.createdAt), 'M/d/Y, p'),
          editedBy: `${t.editedBy.firstName || ''} ${t.editedBy.lastName || ''}`,
          equities: formatPercent(t.allocations.EQUITY, 0),
          fixedIncome: formatPercent(t.allocations.FIXED_INCOME, 0),
          alternatives: formatPercent(t.allocations.ALTERNATIVES, 0),
          actionCallbacks: {
            view: () => setViewModalOpenTemplate(t),
            restore: async () => {
              restoreAllocationTemplate(t);
            },
            restoringTemplate,
          },
        };
      }) as AllocationTemplateTable[];
  }, [
    data.allocationsTemplates,
    template.id,
    template.originalTemplateId,
    template.type,
    restoreAllocationTemplate,
    restoringTemplate,
  ]);

  const restoreRestrictionsTemplate = useCallback(
    async (t: RestrictionTemplateBody) => {
      if (!user) {
        return;
      }
      setRestoringTemplate(true);
      try {
        const { data } = await editTemplate({
          templateId: template.parentId,
          templateBody: {
            name: t.name,
            userId: user.id,
            orgId: t.orgId,
            tickers: t.tickers,
            subSectors: t.subSectors,
            sectors: t.sectors,
            esgAreas: t.esgAreas,
            countries: t.countries,
          },
        });
        if (data && data.templateId) {
          history.push(
            `/secure/view-template/${data.templateId}?editTemplateId=${data.templateId}`
          );
        }
      } catch (e) {
        enqueueCoachmark({
          severity: 'error',
          title: `Failed to restore template.`,
          content: `Please reach out to clientservice@vise.com for help.`,
        });
      } finally {
        setRestoringTemplate(false);
      }
    },
    [history, user, template.parentId, enqueueCoachmark]
  );

  const { data: featureFlags } = useFeatureFlags();
  const gicsCutoffDate = useMemo(
    () => new Date(featureFlags?.gics_frontend_cutoff_date || 'Invalid'),
    [featureFlags?.gics_frontend_cutoff_date]
  );

  const restrictionsTemplateData = useMemo(() => {
    if (template.type === 'allocations') {
      return [] as RestrictionsTemplateTable[];
    }
    return data.restrictionsTemplates
      .filter((t) => t.originalTemplateId === template.originalTemplateId && t.id !== template.id)
      .sort((t1, t2) => {
        return t2.createdAt.localeCompare(t1.createdAt);
      })
      .map((t) => {
        const templateCreatedBeforeCutoffDate =
          isValid(gicsCutoffDate) && new Date(t.createdAt) < gicsCutoffDate;
        const templateUsesLegacySectors =
          templateCreatedBeforeCutoffDate && !!(t.sectors.length || t.subSectors.length);
        return {
          date: format(new Date(t.createdAt), 'M/d/Y, p'),
          editedBy: `${t.editedBy.firstName || ''} ${t.editedBy.lastName || ''}`,
          tickers: t.tickers.length.toString(),
          sectors: t.sectors.length.toString(),
          countries: t.countries.length.toString(),
          values: t.esgAreas.length.toString(),
          actionCallbacks: {
            view: () => setViewModalOpenTemplate(t),
            restore: async () => {
              restoreRestrictionsTemplate(t);
            },
            restoringTemplate,
            restoringTemplateDisabled: templateUsesLegacySectors,
          },
        };
      }) as RestrictionsTemplateTable[];
  }, [
    data.restrictionsTemplates,
    template.originalTemplateId,
    template.id,
    template.type,
    restoreRestrictionsTemplate,
    restoringTemplate,
    gicsCutoffDate,
  ]);
  const allocationTemplateTable = useTable<AllocationTemplateTable>(
    {
      data: allocationTemplateData,
      columns: allocationTemplateColumns,
      initialState: {
        pageSize: PAGE_SIZE,
      } as UsePaginationState<AllocationTemplateTable> & TableState<AllocationTemplateTable>,
    },
    usePagination
  );
  const restrictionsTemplateTable = useTable<RestrictionsTemplateTable>(
    {
      data: restrictionsTemplateData,
      columns: restrictionsTemplateColumns,
      initialState: {
        pageSize: PAGE_SIZE,
      } as UsePaginationState<RestrictionsTemplateTable> & TableState<RestrictionsTemplateTable>,
    },
    usePagination
  );
  const theme = useTheme();
  const { data: instruments } = useIndexedInstruments();
  const { data: sectorInformation } = useIndexedSectors();
  return (
    <Card fullWidth maxWidth="100%">
      {viewModalOpenTemplate && (
        <Dialog open>
          <DialogTitle onClose={() => setViewModalOpenTemplate(undefined)}>
            {format(new Date(viewModalOpenTemplate.createdAt), 'M/d/Y, p')}
            <Box mt={1}>
              <Typography variant="body2" color="textSecondary">
                Edited by {viewModalOpenTemplate.editedBy.firstName}{' '}
                {viewModalOpenTemplate.editedBy.lastName}
              </Typography>
            </Box>
          </DialogTitle>
          <Divider />
          <Box p={3} maxHeight="600px" overflow="auto">
            {viewModalOpenTemplate.type === 'allocations' ? (
              <TemplateBody
                allocations={mapValues(
                  viewModalOpenTemplate.allocations,
                  (val) => val && val * 100
                )}
                equitiesTitle="Equities"
                fixedIncomeTitle="Fixed income"
                alternativesTitle="Alternatives"
                hideLegend
              />
            ) : (
              <RestrictionsTemplateBody
                tickersTitle="Tickers"
                tickers={viewModalOpenTemplate.tickers}
                instruments={instruments}
                sectorsTitle="Sectors"
                sectors={viewModalOpenTemplate.sectors}
                industries={viewModalOpenTemplate.subSectors}
                esgTitle="Values"
                esgAreas={viewModalOpenTemplate.esgAreas}
                sectorInformation={sectorInformation}
                countriesTitle="Countries"
                countries={viewModalOpenTemplate.countries}
              />
            )}
          </Box>
          <Divider />
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              disabled={restoringTemplate}
              onClick={() => {
                if (viewModalOpenTemplate.type === 'allocations') {
                  restoreAllocationTemplate(viewModalOpenTemplate);
                } else {
                  restoreRestrictionsTemplate(viewModalOpenTemplate);
                }
              }}
            >
              Restore
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box flex={1} mx={3} mb={3}>
        <Typography variant="h3">Version history</Typography>
      </Box>
      {allocationTemplateData.length === 0 && restrictionsTemplateData.length === 0 && (
        <EmptyState
          height={400}
          title="No previous versions"
          body="Old versions appear here when you edit this template."
          showCTA={false}
        />
      )}

      {template.type === 'allocations' && allocationTemplateData.length > 0 && (
        <>
          <DataTable m={0} table={allocationTemplateTable} />
          {allocationTemplateData.length > PAGE_SIZE && (
            <Box px={3} pt={4} display="flex" borderTop={`1px solid ${theme.palette.grey[100]}`}>
              <Box flex={1} />
              <PageNavigation
                currentPage={
                  (allocationTemplateTable.state as UsePaginationState<AllocationTemplateTable>)
                    .pageIndex + 1
                }
                numItems={allocationTemplateData.length}
                pageSize={PAGE_SIZE}
                onNextPageClick={() => {
                  allocationTemplateTable.nextPage();
                }}
                onPrevPageClick={() => {
                  allocationTemplateTable.previousPage();
                }}
                onPageNumberClick={(pageNumber) => {
                  allocationTemplateTable.gotoPage(pageNumber - 1);
                }}
              />
            </Box>
          )}
        </>
      )}
      {template.type === 'restrictions' && restrictionsTemplateData.length > 0 && (
        <>
          <DataTable m={0} table={restrictionsTemplateTable} />
          {restrictionsTemplateData.length > PAGE_SIZE && (
            <Box px={3} pt={4} display="flex" borderTop={`1px solid ${theme.palette.grey[100]}`}>
              <Box flex={1} />
              <PageNavigation
                currentPage={
                  (restrictionsTemplateTable.state as UsePaginationState<AllocationTemplateTable>)
                    .pageIndex + 1
                }
                numItems={restrictionsTemplateData.length}
                pageSize={PAGE_SIZE}
                onNextPageClick={() => {
                  restrictionsTemplateTable.nextPage();
                }}
                onPrevPageClick={() => {
                  restrictionsTemplateTable.previousPage();
                }}
                onPageNumberClick={(pageNumber) => {
                  restrictionsTemplateTable.gotoPage(pageNumber - 1);
                }}
              />
            </Box>
          )}
        </>
      )}
    </Card>
  );
}
