import { Box, BoxProps, Button, Card, Divider, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as ChevronLeft } from '~/static/images/icons/chevron-left.svg';
import React, { useMemo, useState } from 'react';
import Metric from '~/synth/Metric';
import CardContent from '~/synth/CardContent';
import { ACCOUNTING_FORMATTER, formatCurrency } from '~/utils/format';
import { ReactComponent as XCircleIcon } from '~/static/images/icons/x-circle.svg';
import { ReactComponent as TimeIcon } from '~/static/images/icons/time.svg';

import CardHeader from '~/synth/CardHeader';
import { ReactComponent as PlusIcon } from '~/static/images/icons/plus.svg';
import { ReactComponent as MinusIcon } from '~/static/images/icons/minus.svg';
import { format } from 'date-fns/esm';
import { Dictionary, groupBy, keyBy } from 'lodash';
import { Order, OrderItem } from 'vise-types/orders';
import { Instrument } from 'vise-types/pce2_instrument';
import { RawGetCapitalGainsResponse } from '~/models/api';
import { Account } from 'vise-types/portfolio';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { DetailsText } from '~/routes/PortfolioCreator2/screens/components/SummarySections';
import { FlexGrowCard, StretchGrid } from '../components/FlexGridCard';
import TradesModal from './TradesModal';
import {
  CatalystLabel,
  IntelligenceLineItem,
  TradeInitiatorLabel,
  TurnoverLabel,
} from '../components/TradeActivityLabels';
import TaxImpactTable from '../components/TaxImpactTable';
import { beforeBackfill } from './Utils';

const PreBackfillEmptyState = () => (
  <Card>
    <Box m="auto" py={8}>
      <Typography variant="h4">
        <Box display="flex" alignItems="center" justifyContent="center">
          <XCircleIcon /> <Box ml={1.5}>Trade rationale unavailable</Box>
        </Box>
      </Typography>
      <Box mt={1.5}>
        <DetailsText color="textSecondary" variant="body1">
          Objectives, improvements, and detractors are only available for orders that occurred after
          October 24th, 2022.
        </DetailsText>
      </Box>
    </Box>
  </Card>
);

const ComingSoonEmptyState = () => (
  <Card>
    <Box m="auto" py={8}>
      <Typography variant="h4">
        <Box display="flex" alignItems="center" justifyContent="center">
          <TimeIcon /> <Box ml={1.5}>Trade rationale available soon</Box>
        </Box>
      </Typography>
      <Box mt={1.5}>
        <DetailsText color="textSecondary" variant="body1">
          Vise is currently processing this order. Check back later for details!
        </DetailsText>
      </Box>
    </Box>
  </Card>
);

const IconCircleBox = ({ children, ...boxProps }: BoxProps) => (
  <Box
    borderRadius="50%"
    height={20}
    width={20}
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...boxProps}
  >
    {children}
  </Box>
);

function TwoColumn({
  left,
  right,
  ...boxProps
}: {
  left: React.ReactNode;
  right: React.ReactNode;
  boxProps?: BoxProps;
}) {
  return (
    <Box display="flex" justifyContent="space-between" {...boxProps}>
      <Box color="grey.500">{left}</Box>
      <Box>{right}</Box>
    </Box>
  );
}

function addInstrumentDetailToTrade(trade: OrderItem, instrumentsBySymbol: Dictionary<Instrument>) {
  const instrument = instrumentsBySymbol[trade.symbolOrCusip];
  return {
    ticker: trade.symbolOrCusip,
    name: instrument?.name ?? '-',
    sector: instrument?.sectors ?? null,
    quantity: trade.quantity,
    // If notationalSharePrice is 0 it means the price is missing
    price: trade.notationalSharePrice || null,
    totalValue: trade.notationalSharePrice
      ? (trade.transactionType === 'SELL' ? 1 : -1) * trade.notationalSharePrice * trade.quantity
      : null,
  };
}

export default function TradeDetail({
  onBack,
  order,
  instruments,
  gains,
  account,
  intelligence,
}: {
  onBack: () => void;
  gains: RawGetCapitalGainsResponse | undefined;
  account: Account | null;
  intelligence: PortfolioIntelligenceFull;
  order?: Order;
  instruments?: Instrument[];
}) {
  const [tradesModalOpen, setTradesModalOpen] = useState(false);

  const instrumentsBySymbol = useMemo(() => keyBy(instruments, 'symbol'), [instruments]);
  const groupedTrades = useMemo(() => groupBy(order?.items, 'transactionType'), [order?.items]);
  const sells = useMemo(
    () => groupedTrades.SELL?.map((s) => addInstrumentDetailToTrade(s, instrumentsBySymbol)),
    [groupedTrades.SELL, instrumentsBySymbol]
  );
  const buys = useMemo(
    () => groupedTrades.BUY?.map((b) => addInstrumentDetailToTrade(b, instrumentsBySymbol)),
    [groupedTrades.BUY, instrumentsBySymbol]
  );

  const orderedImprovements = useMemo(() => {
    const improvements = order?.intelligence?.improvements;
    const primary = order?.intelligence?.primaryObjective;
    return [
      ...(improvements?.filter((i) => i.category === primary) ?? []),
      ...(improvements?.filter((i) => i.category !== primary) ?? []),
    ];
  }, [order?.intelligence?.improvements, order?.intelligence?.primaryObjective]);

  const sellTotal = sells?.reduce((p, c) => p + (c.totalValue ?? 0), 0) ?? 0;
  const buyTotal = buys?.reduce((p, c) => p + (c.totalValue ?? 0), 0) ?? 0;

  if (intelligence.proposalType === 'light') {
    throw new Error(`Portfolio intelligence should be PCE2 and FULL. (id: ${intelligence.id})`);
  }

  const EmptyIntelligenceContent =
    order?.createdAt && beforeBackfill(new Date(order.createdAt))
      ? PreBackfillEmptyState
      : ComingSoonEmptyState;

  return (
    <>
      {order ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <IconButton onClick={onBack} size="large">
                  <ChevronLeft />
                </IconButton>
                <Box ml={1}>
                  <Typography variant="h3">
                    {order.createdAt != null ? (
                      format(new Date(order.createdAt), 'MMMM do, yyyy')
                    ) : (
                      <>N/A</>
                    )}
                  </Typography>
                </Box>
              </Box>
              {order.items != null ? (
                <Button
                  variant="outlined"
                  onClick={() => setTradesModalOpen(true)}
                  color="secondary"
                >
                  View {order.items.length} trade{order.items.length > 1 ? 's' : ''}
                </Button>
              ) : null}
            </Box>
          </Grid>
          <Grid item container spacing={2}>
            <StretchGrid item xs>
              <FlexGrowCard>
                <CardContent>
                  <Metric
                    metric={
                      order.metrics != null ? (
                        <TurnoverLabel turnover={order.metrics.turnoverFraction} />
                      ) : (
                        'N/A'
                      )
                    }
                    label="Turnover"
                  />
                </CardContent>
              </FlexGrowCard>
            </StretchGrid>
            <StretchGrid item xs>
              <FlexGrowCard>
                <CardContent>
                  <Metric
                    metric={
                      <>
                        {order.pce2RunSource != null ? (
                          <TradeInitiatorLabel runSource={order.pce2RunSource} />
                        ) : (
                          'N/A'
                        )}
                      </>
                    }
                    label="Trade initiator"
                  />
                </CardContent>
              </FlexGrowCard>
            </StretchGrid>
            <StretchGrid item xs>
              <FlexGrowCard>
                <CardContent>
                  <Metric
                    metric={
                      <>
                        {order.optimizerMode != null ? (
                          <CatalystLabel optimizerMode={order.optimizerMode} />
                        ) : (
                          'N/A'
                        )}
                      </>
                    }
                    label="Catalyst"
                  />
                </CardContent>
              </FlexGrowCard>
            </StretchGrid>
            <StretchGrid item xs={12} md={5}>
              <FlexGrowCard>
                <CardContent>
                  <Typography variant="body1">
                    <TwoColumn left="Buys" right={formatCurrency(buyTotal, ACCOUNTING_FORMATTER)} />
                    <TwoColumn
                      left="Sells"
                      right={formatCurrency(sellTotal, ACCOUNTING_FORMATTER)}
                    />
                  </Typography>
                  <Box color="grey.200" mt={1.5} mb={2}>
                    <Divider orientation="horizontal" />
                  </Box>
                  <Typography variant="h5">
                    <TwoColumn
                      left="Net cash proceeds"
                      right={formatCurrency(sellTotal + buyTotal, ACCOUNTING_FORMATTER)}
                    />
                  </Typography>
                </CardContent>
              </FlexGrowCard>
            </StretchGrid>
          </Grid>
          {order.intelligence != null ? (
            <>
              <Grid item xs={12}>
                <Card>
                  <CardHeader display="flex">
                    <IconCircleBox color="success.400" bgcolor="success.100" mr={1.5}>
                      <PlusIcon />
                    </IconCircleBox>
                    <Typography variant="h4">Improvements</Typography>
                  </CardHeader>
                  {orderedImprovements.map((i, ind) => (
                    <IntelligenceLineItem
                      key={i.description}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      isLast={ind === order.intelligence!.improvements.length - 1}
                      category={i.category}
                      description={i.description}
                      primary={i.category === order.intelligence?.primaryObjective}
                    />
                  ))}
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader display="flex">
                    <IconCircleBox color="warning.400" bgcolor="warning.100" mr={1.5}>
                      <MinusIcon />
                    </IconCircleBox>
                    <Typography variant="h4">Detractors</Typography>
                  </CardHeader>
                  {order.intelligence.detractors.length === 0 ? (
                    <CardContent color="grey.500">No major detractors.</CardContent>
                  ) : null}
                  {order.intelligence.detractors.map((i, ind) => (
                    <IntelligenceLineItem
                      key={i.description}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      isLast={ind === order.intelligence!.detractors.length - 1}
                      category={i.category}
                      description={i.description}
                    />
                  ))}
                </Card>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <EmptyIntelligenceContent />
            </Grid>
          )}
          {account?.taxable &&
            gains != null &&
            order.metrics != null &&
            intelligence.constructionRequest.taxOptions && (
              <Grid item xs={12}>
                <TaxImpactTable
                  gains={gains}
                  taxOptions={intelligence.constructionRequest.taxOptions}
                  taxMetrics={order.metrics}
                  hideYTD
                  variant="order"
                />
              </Grid>
            )}
          <TradesModal
            open={tradesModalOpen}
            onClose={() => setTradesModalOpen(false)}
            buys={buys ?? []}
            sells={sells ?? []}
            date={order.createdAt}
          />
        </Grid>
      ) : null}
    </>
  );
}
