"use client";
import {
  Box,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  type DataGridProProps,
} from "@mui/x-data-grid-pro";
import tokens from "../tokens/index.js";
import DownloadIcon from "./Icons/DownloadIcon.js";
import React from "react";
import Filter2Horizontal from "./Icons/Filter2HorizontalIcon.js";
import SearchIcon from "./Icons/SearchIcon.js";
import XIcon from "./Icons/XIcon.js";
import FilterIcon from "./Icons/FilterIcon.js";
import PlusIcon from "./Icons/PlusIcon.js";
import TrashIcon from "./Icons/TrashIcon.js";
import baseTheme, { themeConfig } from "../theme/index.js";

export const tableToolbarDatagridProps: Pick<
  DataGridProProps,
  "slotProps" | "slots"
> = {
  slots: {
    toolbar: TableToolbar,
    exportIcon: DownloadIcon,
    filterPanelAddIcon: PlusIcon,
    filterPanelRemoveAllIcon: TrashIcon,
    openFilterButtonIcon: Filter2Horizontal,
    quickFilterIcon: SearchIcon,
    quickFilterClearIcon: XIcon,
    columnFilteredIcon: FilterIcon,
    filterPanelDeleteIcon: XIcon,
  },
  slotProps: {
    baseTextField: {
      variant: "outlined" as const,
      label: null,
    },
    baseSelect: {
      variant: "outlined" as const,
      label: null,
    },
    baseButton: {
      variant: "ghost" as const,
    },
    filterPanel: {
      sx: {
        p: tokens.SPACING_INDICES.xl,
        "& .MuiDataGrid-panelFooter": {
          mt: tokens.SPACING_INDICES["2xl"],
        },
        "& .MuiFormControl-root": {
          mr: tokens.SPACING_INDICES.sm,
        },
        "& .MuiDataGrid-filterForm": {
          alignItems: "center",
        },
      },
    },
  },
};

// Hack because currently the GridToolbarQuickFilter uses fractional spacing values by default which results in error spam when used with an array of spacing values in the theme
// https://github.com/mui/mui-x/issues/5454
const spacingResetTheme = createTheme({
  ...themeConfig,
  spacing: 8,
});

/**
 * Uses a styled version of the MUI table toolbar for more complex tables. The toolbar includes filtering, export, and quick search. To use, add this component to the "toolbar" slot in MUIDatagrid.
 * Props can be passed in using the slotProps prop. The tableToolbarSlotProps export should be added into the MuiDataGrid props to ensure styling is applied correctly.
 * TODO: figure out how to apply this directly in the theme
 */
export default function TableToolbar({
  title,
  description,
  enableExport,
  children,
}: {
  title: React.ReactNode;
  description: React.ReactNode;
  enableExport?: boolean;
  children?: React.ReactNode;
}) {
  const theme = useTheme();
  return (
    <ThemeProvider theme={spacingResetTheme}>
      <GridToolbarContainer sx={{ padding: 0 }}>
        <ThemeProvider theme={baseTheme}>
          <Box
            p={tokens.SPACING_INDICES.xl}
            borderBottom={`solid 1px ${theme.palette.neutralCool[300]}`}
            width="100%"
          >
            <Grid container>
              <Grid item lg={6}>
                <Typography variant="h2">{title}</Typography>
                <Box mt={tokens.SPACING_INDICES.sm}>
                  <Typography variant="body1" color="neutralCool.600">
                    {description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6}>
                <Box display="flex" alignItems="center">
                  <ThemeProvider theme={spacingResetTheme}>
                    <GridToolbarQuickFilter />
                  </ThemeProvider>
                  <Box ml={tokens.SPACING_INDICES.sm}>
                    <GridToolbarFilterButton />
                  </Box>
                  {enableExport && (
                    <Box ml={tokens.SPACING_INDICES.sm}>
                      <GridToolbarExport />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            {children}
          </Box>
        </ThemeProvider>
      </GridToolbarContainer>
    </ThemeProvider>
  );
}
