import {
  Box,
  Card,
  Divider,
  Grid,
  LinearProgress,
  Link as MuiLink,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import { addYears, parseISO } from 'date-fns';
import { first, last } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { AssetClassKey, Feature } from 'vise-types/pce2_instrument';
import { Account } from 'vise-types/portfolio';
import { PortfolioMetricsResponse } from 'vise-types/portfolio_metrics';
import { readableGlidePath } from '~/api/utils';
import { PerformanceChart } from '~/components/chart';
import DateRangeSelector from '~/components/chart/DateRangeSelector';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useInstrumentBenchmarks from '~/hooks/useInstrumentBenchmarks';
import { Benchmark, CapitalGains } from '~/models/api';
import CardContent from '~/synth/CardContent';
import CardHeader from '~/synth/CardHeader';
import { DataTable, DataTableContainer } from '~/synth/DataTable';
import Metric from '~/synth/Metric';
import PercentChange from '~/synth/PercentChange';
import Skeleton from '~/synth/Skeleton';
import Select from '~/synth/inputs/Select';
import amplitude from '~/utils/amplitude';
import { alignChartData } from '~/utils/benchmarks';
import { Notification, theme as dsTheme, tokens } from '@vise_inc/ds-vise';
import {
  WHOLE_DOLLAR_FORMATTER,
  convertToPercent,
  formatCurrency,
  formatPercent,
} from '~/utils/format';
import { ASSET_CLASS_TO_LABEL_MAP, FOCUS_TO_LABEL } from '../PortfolioCreator2/Constants';
import { KeyMetricsTableCard } from './PortfolioOverview2';
import AllocationCard2 from './card/AllocationCard2';
import AllocationMap from './components/AllocationMap';
import DisclosureExpansion from './components/DisclosureExpansion';
import PortfolioMonteCarloChart from './components/PortfolioMonteCarloChart';
import PortfolioValueCardContent from './components/PortfolioValueCardContent';
import { AllocationBar, PCE2AssetNameBox, RightAlignBox } from './components/TableComponents';
import {
  AllocationChartDataPoint,
  adjustAllocationByPctOfManagedValue,
  calculateChartMetric,
  getPCE2AssetClassTitleAndSubtitle,
  groupAssetAllocation,
  riskToFocus,
  transformPCE2AssetClassAllocations,
  transformPCE2BacktestResults,
  transformPCE2MonteCarloResults,
  transformPCE2SectorAllocations,
  transformPCE2SingleStrategyMetrics,
} from './portfolioUtil';
import TaxManagementCard from './components/TaxManagementCard';

const BACKTEST_DISCLOSURES = (
  <Box>
    Backtested performance above represents hypothetical returns and does not reflect trading in
    actual client accounts. Backtested performance also differs from actual performance because it
    is achieved through the retroactive application of portfolios designed with the benefit of
    hindsight. As a result, the portfolios used in the backtesting process may be changed from time
    to time, and the effect on hypothetical performance results could be either favorable or
    unfavorable. As a simulation methodology, in general, backtesting has certain limitations. It
    does not involve or take into account financial risk and does not take into account that
    material and market factors may have impacted investment decision-making, all of which can
    adversely affect actual trading results and performance. Backtested performance also does not
    represent trading costs or the impact of taxes and is not indicative of an adviser&apos;s skill.
    For more information, see our{' '}
    <MuiLink component={Link} to="/secure/disclosures">
      Disclosures page
    </MuiLink>
    .
  </Box>
);

const CHART_TAB_EVENT_NAME = {
  backtest: 'Action - Tap Simulated Backtest',
  projected: 'Action - Tap Projected Value',
};

const BackTestLoading = () => (
  <Box
    textAlign="center"
    height="500px"
    maxWidth="560px"
    m="auto"
    display="flex"
    flexDirection="column"
    justifyContent="center"
  >
    <Typography variant="h4">Backtest and standard deviation data loading</Typography>
    <Box mt={1} color="grey.500" mb={4}>
      This can take up to a minute to load, so go ahead and explore other aspects of this proposal
      while we prepare your backtest and standard deviation data.
    </Box>
    <LinearProgress />
  </Box>
);

const BackTestError = () => (
  <Box
    textAlign="center"
    height="500px"
    maxWidth="700px"
    m="auto"
    display="flex"
    flexDirection="column"
    justifyContent="center"
  >
    <Typography variant="h4">Something went wrong</Typography>
    <Box mt={1} color="grey.500" mb={4}>
      Our backtest failed due to some internal loading issues, and our engineering team is looking
      into the problem. You can reach out to clientservice@vise.com with questions.
    </Box>
  </Box>
);

const BackTestMaxRetries = () => (
  <Box
    textAlign="center"
    height="500px"
    maxWidth="700px"
    m="auto"
    display="flex"
    flexDirection="column"
    justifyContent="center"
  >
    <Typography variant="h4">Backtest data is not available yet</Typography>
    <Box mt={1} color="grey.500" mb={4}>
      The backtest data is taking longer than expected to load. Please check back again later.
    </Box>
  </Box>
);

interface BenchmarkOption {
  label: string;
  value: string;
}

interface ProposalTargetPortfolioProps {
  intelligence: PortfolioIntelligenceFull;
  account: Account | null;
  /** Current metrics of account attached to the proposal - should only ever be `undefined` if the proposal is a sample proposal (not linked to account). */
  portfolioMetrics: PortfolioMetricsResponse | undefined;
  gains?: CapitalGains; // Undefined for sample propsoal
  backtestMaxRetries: boolean;
  fromBulkMigration?: boolean;
}

export default function ProposalTargetPortfolio({
  intelligence,
  account,
  portfolioMetrics,
  gains,
  backtestMaxRetries,
  fromBulkMigration = false,
}: ProposalTargetPortfolioProps) {
  useEffect(() => {
    amplitude().logEvent(
      `Impression - Proposal initail target portfolio${
        fromBulkMigration && ' from bulk migration'
      }`,
      {
        category: fromBulkMigration
          ? EVENT_CATEGORIES.BULK_MIGRATION
          : EVENT_CATEGORIES.PROPOSAL_OVERVIEW,
      }
    );
  }, [fromBulkMigration]);

  if (intelligence.proposalType === 'light') {
    throw new Error(`Portfolio intelligence should be PCE2 and FULL. (id: ${intelligence.id})`);
  }

  const { data: featureFlags } = useFeatureFlags();

  const currentPortfolioMetrics = portfolioMetrics?.portfolioMetrics;
  const isSampleProposal = intelligence.accountId === '';
  const shouldShowTaxManagementCard = !isSampleProposal && account?.taxable && gains != null;
  const {
    constructionResponse: { metrics },
  } = intelligence;
  const { targetPortfolio } = metrics.strategyMetrics;
  const { constructionRequest, constructionResponse } = intelligence;
  const { assetClassAllocationRequest, dividendTilt, taxOptions, etfOnly } = constructionRequest;

  let initialPortfolioValue: number | null;
  if (isSampleProposal) {
    initialPortfolioValue = metrics.proposedPortfolioMetrics.totalValue;
  } else {
    initialPortfolioValue =
      currentPortfolioMetrics == null ? null : currentPortfolioMetrics.totalValue; // Note: currentPortfolioMetrics should not be undefined if the proposal is attached to an account.
  }

  const horizonEndDate =
    assetClassAllocationRequest == null
      ? null
      : moment(assetClassAllocationRequest.timeHorizonStartTimestamp)
          .add(assetClassAllocationRequest.years, 'years')
          .format('YYYY');

  let focus: 'TARGET_VALUE' | 'LOSS_TOLERANCE';
  let targetValue: number | null = null;
  if (assetClassAllocationRequest?.targetValueParams != null) {
    targetValue = assetClassAllocationRequest.targetValueParams.targetValue;
    focus = 'TARGET_VALUE';
  } else {
    focus = 'LOSS_TOLERANCE';
  }

  let riskProfileSubtext: string | undefined;
  // We only want to show this subtext if active tilt is set to a non-zero value
  // and if it's multi-factor.
  if (
    constructionResponse.activeTilt !== 0 &&
    !dividendTilt &&
    focus === 'LOSS_TOLERANCE' &&
    !etfOnly
  ) {
    riskProfileSubtext = `${constructionResponse.activeTilt}/10 multi-factor active tilt`;
  }

  const strategies = targetPortfolio.perAssetClassMetrics.map((metric) => metric.assetClass);

  const theme = useTheme();
  const performanceSeriesColors = [theme.palette.blue[500], theme.palette.accents.magenta.main];

  const usingGlidePath = assetClassAllocationRequest?.glidePathParams != null;
  let initialRisk = usingGlidePath
    ? assetClassAllocationRequest?.glidePathParams?.initialRisk
    : assetClassAllocationRequest?.risk;
  if (
    assetClassAllocationRequest?.customAllocationParams ||
    assetClassAllocationRequest?.glidePathParams?.initialL1Allocations.length
  ) {
    initialRisk = constructionResponse.risk;
  }
  const riskProfile = formatPercent(initialRisk, 0);

  const fromCustomAllocationFlow = !!(
    assetClassAllocationRequest?.customAllocationParams ||
    assetClassAllocationRequest?.glidePathParams?.initialL1Allocations.length
  );

  const glidePathText = `Glide path: ${readableGlidePath(usingGlidePath)}`;

  const [tabValue, setTabValue] = useState<'backtest' | 'projected'>('backtest');
  const [benchmark, setBenchmark] = useState<BenchmarkOption | null>(null);
  const [chartRangeOptions, setChartRangeOptions] = React.useState<{
    min: number | null;
    max: number | null;
  }>({
    min: null,
    max: null,
  });

  const currentChartFirstAvailableDate =
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    intelligence.backtestResults?.timestampSecs.length > 0
      ? // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        first(intelligence.backtestResults.timestampSecs) * 1000
      : null;
  const currentChartLastAvailableDate =
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    intelligence.backtestResults?.timestampSecs.length > 0
      ? // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        last(intelligence.backtestResults.timestampSecs) * 1000
      : null;
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: 'backtest' | 'projected') => {
    amplitude().logEvent(CHART_TAB_EVENT_NAME[newValue], {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
    setTabValue(newValue);
  };

  const { data: instrumentBenchmarks, error: instrumentBenchmarksError } = useInstrumentBenchmarks({
    fetchDailyData: true,
    risk: initialRisk,
  });

  const { assetAllocation: unadjustedAssetAllocation, sectorAllocation } = useMemo(() => {
    return {
      assetAllocation: transformPCE2AssetClassAllocations(targetPortfolio.assetClassAllocations),
      sectorAllocation: transformPCE2SectorAllocations(targetPortfolio.sectorAllocations),
    };
  }, [targetPortfolio.assetClassAllocations, targetPortfolio.sectorAllocations]);

  const assetAllocation = adjustAllocationByPctOfManagedValue(
    unadjustedAssetAllocation,
    targetPortfolio.lockedUnrecognizedFraction * 100,
    false
  );

  const topAssetAllocation = useMemo(
    () => Math.max(...assetAllocation.map((asset) => asset.y)),
    [assetAllocation]
  );

  const groupedAssetAllocation = useMemo(
    () => groupAssetAllocation(assetAllocation),
    [assetAllocation]
  );

  const wholePortfolioColumns = useMemo(
    () =>
      [
        {
          Header: 'Sub-asset class',
          accessor: 'name',
          Cell: (props: { value: AssetClassKey }) => {
            const assetDisplayName = getPCE2AssetClassTitleAndSubtitle(
              props.value,
              intelligence.constructionInfo.smallAccount
            );
            return (
              <PCE2AssetNameBox
                title={assetDisplayName.title}
                subtitle={assetDisplayName.subtitle}
              />
            );
          },
        },
        {
          Header: 'Allocation',
          accessor: 'y',
          Cell: (props: { value: number }) => (
            <RightAlignBox>
              {props.value < 0.0005 ? '< 0.1%' : formatPercent(props.value, 1)}
            </RightAlignBox>
          ),
          align: 'right',
        },
        {
          accessor: 'color',
          Cell: (props: { row: { original: { y: number } } }) => (
            <AllocationBar
              allocation={props.row.original.y}
              topAssetAllocation={topAssetAllocation}
            />
          ),
        },
        {
          Header: 'Value',
          accessor: (row) => row.y * targetPortfolio.totalValue,
          Cell: (props: { value: number }) =>
            props.value > 0 && <RightAlignBox>{formatCurrency(props.value)}</RightAlignBox>,
          align: 'right',
        },
      ] as Column<AllocationChartDataPoint>[],
    [intelligence.constructionInfo.smallAccount, topAssetAllocation, targetPortfolio.totalValue]
  );

  const normalizedBacktest: [number, number][] = useMemo(
    () =>
      intelligence.backtestResults
        ? transformPCE2BacktestResults(intelligence.backtestResults)
        : [],

    [intelligence.backtestResults]
  );

  const benchmarks = useMemo(() => {
    if (instrumentBenchmarks == null) return [];
    if (intelligence.backtestResults) {
      // YYYY-MM-DD to epoch for backtest results
      const dateToEpoch = intelligence.backtestResults.timestampSecs.map((timestamp: number) => [
        moment.unix(timestamp).format(moment.HTML5_FMT.DATE),
        timestamp * 1000,
      ]);
      const backtestTimeStamps: { [key in string]: number } = Object.fromEntries(dateToEpoch);

      const benchmarks = instrumentBenchmarks?.map((b: Benchmark) => {
        // Get benchmark data for days that have backtest data (filtering by day)
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        const datesWithBacktest = b.dailyData.filter((d) => d.date in backtestTimeStamps);
        return {
          ...b,
          dailyDataHC: datesWithBacktest.map(
            (data: { date: string; value: number }) =>
              // Use the same epoch value as backtest data to make sure highcharts recognize them as the same
              // x-axis point.
              [backtestTimeStamps[data.date], data.value] as [number, number]
          ),
          dailyData: datesWithBacktest,
        };
      });
      return benchmarks;
    }
    return null;
  }, [instrumentBenchmarks, intelligence.backtestResults]);

  const performanceData = useMemo(() => {
    if (normalizedBacktest.length > 0) {
      if (benchmark && benchmarks) {
        const benchmarkItem = benchmarks.find((b) => b.name === benchmark.value);
        if (benchmarkItem) {
          const benchmarkData = benchmarkItem.dailyDataHC;
          return alignChartData([
            { name: 'Backtest', data: normalizedBacktest },
            { name: benchmark.value, data: benchmarkData },
          ]);
        }
        return [{ name: 'Backtest', data: normalizedBacktest }];
      }
      return [{ name: 'Backtest', data: normalizedBacktest }];
    }
    return [];
  }, [benchmark, benchmarks, normalizedBacktest]);

  const benchmarkOptions = useMemo(
    () =>
      benchmarks?.map(({ name: label }) => ({
        label,
        value: label,
      })),
    [benchmarks]
  );

  const years = intelligence.constructionRequest.assetClassAllocationRequest?.years;
  const timeHorizonStart =
    intelligence.constructionRequest.assetClassAllocationRequest?.timeHorizonStartTimestamp;
  const simulations = transformPCE2MonteCarloResults(
    portfolioMetrics?.strategyMetrics?.monteCarloResults ??
      intelligence.constructionResponse.metrics.strategyMetrics.monteCarloResults,
    Number(intelligence.constructionInfo.initialValue),
    undefined,
    years && timeHorizonStart ? addYears(parseISO(timeHorizonStart), years) : undefined
  );

  const backtestMetric = useMemo(
    () =>
      performanceData[0]?.data && performanceData[0].data.length >= 1
        ? calculateChartMetric(
            chartRangeOptions.min,
            chartRangeOptions.max,
            performanceData[0].data
          )
        : null,
    [chartRangeOptions.max, chartRangeOptions.min, performanceData]
  );
  const benchmarkMetric = useMemo(
    () =>
      performanceData[1]?.data && performanceData[1].data.length >= 1
        ? calculateChartMetric(
            chartRangeOptions.min,
            chartRangeOptions.max,
            performanceData[1].data
          )
        : null,
    [chartRangeOptions.max, chartRangeOptions.min, performanceData]
  );

  const BackTestChartContent = () => {
    if (backtestMaxRetries) {
      return <BackTestMaxRetries />;
    }
    if (instrumentBenchmarksError || intelligence.status === 'BACKTEST_FAILED') {
      return <BackTestError />;
    }
    // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    if (!intelligence.backtestResults || intelligence.status === 'BACKTEST_SOLVING') {
      return <BackTestLoading />;
    }
    return (
      <PerformanceChart
        chartHeight={300}
        colors={performanceSeriesColors}
        compare
        datas={performanceData}
        view="realview"
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
        min={chartRangeOptions.min}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
        max={chartRangeOptions.max}
      />
    );
  };

  const BackTestChart = () => (
    <>
      <Box mb={2}>
        <BackTestChartContent />
      </Box>
      <Divider />
      <CardContent>
        <DisclosureExpansion expandedDisclosures={BACKTEST_DISCLOSURES} />
      </CardContent>
    </>
  );

  const ChartContent = () => (
    <>
      <CardContent>
        {tabValue === 'backtest' && (
          <Box mb={4} display="flex" justifyContent="space-between">
            <Box display="flex">
              <Box display="flex">
                <Box
                  width={10}
                  height={10}
                  borderRadius="2px"
                  bgcolor="primary.main"
                  mt={0.6}
                  mr={1}
                />
                <Box>
                  <Typography variant="h5" color="textSecondary">
                    Backtest
                  </Typography>
                  <Box mt={1}>
                    {backtestMetric != null ? (
                      <PercentChange value={convertToPercent(backtestMetric)} />
                    ) : (
                      '-'
                    )}
                  </Box>
                </Box>
              </Box>
              {benchmark && (
                <Box display="flex" ml={5}>
                  <Box
                    width={10}
                    height={10}
                    borderRadius="2px"
                    bgcolor={theme.palette.accents.magenta.main}
                    mt={0.6}
                    mr={1}
                  />
                  <Box>
                    <Typography variant="h5" color="textSecondary">
                      {benchmark.label}
                    </Typography>
                    <Box mt={1}>
                      {benchmarkMetric != null ? (
                        <PercentChange value={convertToPercent(benchmarkMetric)} />
                      ) : (
                        '-'
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
              <Box ml={5}>
                <Typography variant="h5" color="textSecondary">
                  Std. deviation (annual)
                </Typography>
                <Box mt={1}>
                  {intelligence.status === 'READY'
                    ? formatPercent(
                        intelligence.backtestPerformanceMetrics.annualizedStandardDeviation
                      )
                    : '-'}
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box color="grey.600" mr={2}>
                Compare:
              </Box>
              <Box width="320px">
                <Select
                  isClearable
                  name="benchmark"
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: BenchmarkOption | null) => void' is ... Remove this comment to see the full error message
                  onChange={(value: BenchmarkOption | null) => setBenchmark(value)}
                  options={benchmarkOptions}
                  placeholder="Add benchmark"
                  value={benchmark}
                />
              </Box>
            </Box>
          </Box>
        )}
      </CardContent>
      {tabValue === 'backtest' ? (
        <BackTestChart />
      ) : (
        // This wrapping element prevents an issue where the Highcharts
        // chart doesn't redraw properly when toggling between charts.
        <>
          <PortfolioMonteCarloChart datas={simulations} />
        </>
      )}
    </>
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Box display="flex">
            <PortfolioValueCardContent
              proposal
              isPce2
              cashToWithdraw={portfolioMetrics?.portfolioCashInformation?.cashToWithdraw ?? 0}
              portfolioValue={initialPortfolioValue}
              sx={{ borderColor: 'grey.200' }}
              border={1}
              borderLeft={0}
              borderTop={0}
              borderBottom={0}
              flex={1}
            />
            {!fromCustomAllocationFlow && (
              <CardContent borderRight={1} flex={1}>
                <Metric
                  metric={FOCUS_TO_LABEL[focus]}
                  label="Focus"
                  subtext={focus === 'LOSS_TOLERANCE' ? glidePathText : undefined}
                />
              </CardContent>
            )}
            {fromCustomAllocationFlow && (
              <CardContent borderRight={1} flex={1}>
                <Metric
                  metric={riskToFocus(initialRisk || 0)[0]}
                  label="Focus"
                  subtext={riskToFocus(initialRisk || 0)[1]}
                />
              </CardContent>
            )}
            {!fromCustomAllocationFlow && (
              <CardContent borderRight={1} flex={1}>
                <Metric
                  metric={
                    focus === 'LOSS_TOLERANCE'
                      ? riskProfile
                      : formatCurrency(targetValue, WHOLE_DOLLAR_FORMATTER)
                  }
                  label={focus === 'LOSS_TOLERANCE' ? 'Risk level' : 'Target value'}
                  subtext={riskProfileSubtext}
                />
              </CardContent>
            )}
            {!fromCustomAllocationFlow && (
              <CardContent flex={1}>
                <Metric
                  metric={
                    assetClassAllocationRequest && assetClassAllocationRequest?.years > 0
                      ? `${horizonEndDate} (${assetClassAllocationRequest?.years ?? '-'} years)`
                      : '-'
                  }
                  label="Horizon"
                />
              </CardContent>
            )}
            {fromCustomAllocationFlow && (
              <CardContent flex={1}>
                <Metric
                  metric={
                    assetClassAllocationRequest && assetClassAllocationRequest?.years > 0
                      ? `${horizonEndDate} (${assetClassAllocationRequest?.years ?? '-'} years)`
                      : 'None'
                  }
                  subtext={`Glide path: ${
                    assetClassAllocationRequest && assetClassAllocationRequest?.years > 0
                      ? 'On'
                      : 'Off'
                  }`}
                  label="Horizon"
                />
              </CardContent>
            )}
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {' '}
        <Card style={{ overflow: 'visible' }}>
          <CardHeader py={0} display="flex" justifyContent="space-between" alignItems="center">
            <Tabs onChange={handleChange} value={tabValue} aria-label="Performance metrics tabs">
              <Tab value="backtest" label="Simulated backtest" />
              <Tab value="projected" label="Projected value" />
            </Tabs>
            <Box>
              <DateRangeSelector
                initialRange="all time"
                firstAvailableDate={currentChartFirstAvailableDate}
                lastAvailableDate={currentChartLastAvailableDate}
                disabled={account?.summary?.length === 0 || tabValue === 'projected'}
                onChangeRange={(range, presetUsed) => {
                  if (presetUsed) {
                    amplitude().logEvent('Action - select date range', {
                      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
                      range: presetUsed,
                      chartType: tabValue,
                    });
                  } else {
                    amplitude().logEvent('Action - submit custom date range', {
                      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
                      chartType: tabValue,
                    });
                  }
                  setChartRangeOptions({
                    min: range.min == null ? null : range.min.valueOf(),
                    max: range.max == null ? null : range.max.valueOf(),
                  });
                }}
              />
            </Box>
          </CardHeader>
          <ChartContent />
        </Card>
      </Grid>
      <Grid item xs={featureFlags?.enable_map === 'on' ? 6 : 12}>
        <AllocationCard2
          allocationType="TARGET"
          assetAllocation={unadjustedAssetAllocation}
          sectorAllocation={sectorAllocation}
          pctOfManagedValue={targetPortfolio.managedFraction * 100}
          lockedUnrecognizedFraction={targetPortfolio.lockedUnrecognizedFraction * 100}
          expanded={featureFlags?.enable_map !== 'on'}
        />
      </Grid>
      {featureFlags?.enable_map === 'on' ? (
        <Grid item xs={6}>
          <AllocationMap assetAllocation={unadjustedAssetAllocation} />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        {!intelligence.constructionInfo.smallAccount &&
        constructionResponse.smallAccount &&
        featureFlags?.disable_small_accounts_ui === 'on' ? (
          <ThemeProvider theme={dsTheme}>
            <Box mb={tokens.SPACING_INDICES.lg}>
              <Notification variant="notice">
                This account&apos;s target asset allocation will be optimized for low-Vise managed
                values. You can customize the asset allocation with our expanded investment
                offerings once the account surpasses $5,000.
              </Notification>
            </Box>
          </ThemeProvider>
        ) : null}
        <DataTableContainer>
          {Object.entries(groupedAssetAllocation).map(([assetClassCategory, assetClass]) => {
            return assetClass.length ? (
              <DataTable
                columns={wholePortfolioColumns}
                data={assetClass}
                subHeader={ASSET_CLASS_TO_LABEL_MAP.get(assetClassCategory as Feature)}
                key={assetClassCategory}
                rowSize="large"
              />
            ) : null;
          })}
        </DataTableContainer>
      </Grid>
      {strategies.length > 0 && !etfOnly && (
        <Grid item xs={12}>
          <KeyMetricsTableCard
            strategies={strategies}
            data={transformPCE2SingleStrategyMetrics(targetPortfolio.perAssetClassMetrics)}
            fullWidth
          />
        </Grid>
      )}

      {shouldShowTaxManagementCard && taxOptions && gains && (
        <Grid item xs={12}>
          <TaxManagementCard gains={gains} taxOptions={taxOptions} autoTlh={taxOptions.autoTlh} />
        </Grid>
      )}
    </Grid>
  );
}

export const TargetPortfolioLoadingState = () => {
  return (
    <>
      <Box mb={2.5}>
        <Card>
          <Box display="flex">
            {[0, 1, 2, 3].map((n) => {
              return (
                <CardContent key={n}>
                  <Skeleton width="10em" height="5em" />
                </CardContent>
              );
            })}
          </Box>
        </Card>
      </Box>
      <Box my={2.5}>
        <Card>
          <CardHeader>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Skeleton variant="text" width="30%" />
              <Skeleton variant="text" width="30%" />
            </Box>
          </CardHeader>
          <CardContent>
            <Skeleton width="100%" height="25em" variant="chart" />
          </CardContent>
        </Card>
      </Box>
      <Card style={{ height: '20em', marginBottom: '20px' }}>
        <CardHeader>
          <Skeleton variant="text" width="15%" />
        </CardHeader>
        <Box p={4} display="flex" alignContent="center">
          <Skeleton width="100%" height="12em" />
          <Box width={40} />
          <Skeleton width="100%" height="12em" />
        </Box>
      </Card>
      <Card>
        <CardContent>
          <Skeleton width="100%" height="50em" />
        </CardContent>
      </Card>
    </>
  );
};
