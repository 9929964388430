import React, { useEffect, useRef, useState } from 'react';
import { AccountWithPIAndHouseholdInfo, HouseholdInfo } from 'vise-types/portfolio';
import { ReactComponent as KnobsIcon } from '~/static/images/icons/knobs.svg';
import PopoverTrigger from '~/synth/PopoverTrigger';
import PopoverLink from '~/synth/PopoverLink';
import { ReactComponent as UserGroupIcon } from '~/static/images/icons/user-group.svg';
import { ReactComponent as ArrowUpIcon } from '~/static/images/icons/chevron-up.svg';
import { ReactComponent as ArrowDownIcon } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as CurrencyDollar } from '~/static/images/icons/currency-dollar.svg';
import { ReactComponent as StatusIcon } from '~/static/images/icons/status-outline.svg';
import { ReactComponent as PieChartIcon } from '~/static/images/icons/chart-pie.svg';
import { ReactComponent as BanIcon } from '~/static/images/icons/ban.svg';
import { SearchIcon } from '~/synth/Icons';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Typography,
  InputAdornment,
  Checkbox,
  FormGroup,
  FormControlLabel,
  PopoverActions,
} from '@mui/material';
import UnstyledButton from '~/synth/UnstyledButton';
import TextField from '~/synth/TextField';
import { debounce, groupBy, mapValues, uniqBy } from 'lodash';
import { GetModelTemplateCenterViewDataResponse } from 'vise-types/template';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import amplitude from '~/utils/amplitude';
import { getAllAccountIdsForFreshTemplateId } from '~/routes/ModelTemplateCenter/utils';
import { matchSorter } from 'match-sorter';
import { InputDollar } from '../Inputs';

export interface FilterState {
  households: string[];
  minimumValue: number | null;
  maximumValue: number | null;
  statusFilters: ('ACTIVE' | 'PAUSED' | 'INACTIVE')[];
  allocationTemplates: string[];
  restrictionTemplates: string[];
}

export const initialFilterState: FilterState = {
  households: [],
  minimumValue: null,
  maximumValue: null,
  statusFilters: [],
  allocationTemplates: [],
  restrictionTemplates: [],
};

export function filterAccounts(
  accounts: AccountWithPIAndHouseholdInfo[],
  filterState: FilterState,
  data: GetModelTemplateCenterViewDataResponse
) {
  return accounts.filter((account) => {
    if (
      filterState.households.length &&
      !filterState.households.includes(account.householdInfo?.id || '')
    ) {
      return false;
    }
    if (
      filterState.minimumValue != null &&
      (account.cachedAum == null || account.cachedAum < filterState.minimumValue)
    ) {
      return false;
    }
    if (
      filterState.maximumValue != null &&
      (account.cachedAum == null || account.cachedAum > filterState.maximumValue)
    ) {
      return false;
    }
    if (filterState.statusFilters.length) {
      const passedStatuses = filterState.statusFilters.filter((status) => {
        if (
          status === 'ACTIVE' &&
          account.status === 'ACTIVE' &&
          account.rebalancerStatus !== 'PAUSED'
        ) {
          return true;
        }
        if (
          status === 'PAUSED' &&
          account.rebalancerStatus === 'PAUSED' &&
          account.status === 'ACTIVE'
        ) {
          return true;
        }
        if (status === 'INACTIVE' && account.status === 'INACTIVE') {
          return true;
        }
        return false;
      });
      if (passedStatuses.length === 0) {
        return false;
      }
    }
    if (filterState.allocationTemplates.length) {
      const accountIdsForFreshTemplateId = getAllAccountIdsForFreshTemplateId(data);
      const passedIds = filterState.allocationTemplates.filter((id) =>
        // We use '' to signify that we only want to include accounts that do *not* have an associated template
        id !== ''
          ? accountIdsForFreshTemplateId[id]?.includes(account.id)
          : !data.templateLinks.filter(
              (link) => link.accountId === account.id && link.templateType === 'allocations'
            ).length
      );
      if (passedIds.length === 0) {
        return false;
      }
    }
    if (filterState.restrictionTemplates.length) {
      const accountIdsForFreshTemplateId = getAllAccountIdsForFreshTemplateId(data);
      const passedIds = filterState.restrictionTemplates.filter((id) =>
        // We use '' to signify that we only want to include accounts that do *not* have an associated template
        id !== ''
          ? accountIdsForFreshTemplateId[id]?.includes(account.id)
          : !data.templateLinks.filter(
              (link) => link.accountId === account.id && link.templateType === 'restrictions'
            ).length
      );
      if (passedIds.length === 0) {
        return false;
      }
    }
    return true;
  });
}

/**
 * Returns a list of household ids where at least one account passes the filters.
 * NOTE: The AUM filter is applied by household, so apply it by pretending the household
 * AUM is the account AUM.
 */
export function filterByHousehold(
  accounts: AccountWithPIAndHouseholdInfo[],
  filterState: FilterState,
  data: GetModelTemplateCenterViewDataResponse
) {
  const accountsByHouseholdId = groupBy(accounts, (account) => account.householdInfo?.id || '');
  const valueByHouseholdInfo = mapValues(accountsByHouseholdId, (accounts) =>
    accounts.reduce((sum, account) => sum + (account.cachedAum || 0), 0)
  );
  const filteredAccounts = filterAccounts(
    accounts.map((account) => ({
      ...account,
      cachedAum: valueByHouseholdInfo[account.householdInfo?.id || ''] || 0,
    })), // see note above about household AUM
    filterState,
    data
  );
  return new Set(
    filteredAccounts
      .map((account) => account.householdInfo?.id)
      .filter((id: string | undefined): id is string => !!id)
  );
}

export function filtersEnabled(filterState: FilterState) {
  return (
    filterState.households.length > 0 ||
    filterState.allocationTemplates.length > 0 ||
    filterState.restrictionTemplates.length > 0 ||
    filterState.minimumValue != null ||
    filterState.maximumValue != null ||
    filterState.statusFilters.length > 0
  );
}

function Section({
  title,
  icon,
  children,
  active,
  action,
}: {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  active: boolean;
  action: PopoverActions | null;
}) {
  const [collapsed, setCollapsed] = useState(active);
  useEffect(() => {
    if (action) {
      action.updatePosition();
    }
  }, [collapsed, action]);
  return (
    <Box>
      <UnstyledButton
        style={{ width: '100%' }}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <Box bgcolor="grey.100" px={2} display="flex" alignItems="center" py={2}>
          <Box mr={1} position="relative" top="1px">
            {icon}
          </Box>
          <Box flex={1}>
            <Typography variant="body2">{title}</Typography>
          </Box>
          {collapsed ? (
            <ArrowUpIcon width="20" height="20" />
          ) : (
            <ArrowDownIcon width="20" height="20" />
          )}
        </Box>
      </UnstyledButton>
      <Collapse in={collapsed}>
        <Box p={2} overflow="auto" maxHeight="250px">
          {children}
        </Box>
      </Collapse>
    </Box>
  );
}

const logApplyFilter = debounce((eventCategory: string, filter: string) => {
  amplitude().logEvent('Action - Apply Account Filter', {
    category: eventCategory,
    filter,
  });
}, 500);

export default function AccountFilter({
  accounts,
  filterState,
  onApplyFilter,
  filterType = 'ACCOUNT',
  eventCategory,
}: {
  accounts: AccountWithPIAndHouseholdInfo[];
  filterState: FilterState;
  onApplyFilter: (state: FilterState) => void;
  filterType?: 'ACCOUNT' | 'HOUSEHOLD';
  eventCategory: string;
}) {
  const [householdFilterSearch, setHouseholdFilterSearch] = useState('');
  const [allocationTemplateFilter, setAllocationTemplateFilter] = useState('');
  const [restrictionTemplateFilter, setRestrictionTemplateFilter] = useState('');
  const allHouseholds = uniqBy(
    accounts
      .map((account) => account.householdInfo)
      .filter((household?: HouseholdInfo): household is HouseholdInfo => !!household)
      .sort((household1, household2) => household1.name.localeCompare(household2.name)),
    (household) => household.id
  );
  const accountIds = new Set(accounts.map((account) => account.id));
  const { data } = useModelTemplateCenterViewData();
  let allocationTemplateOptions: { templateId: string; name: string }[] = [];
  let restrictionTemplateOptions: { templateId: string; name: string }[] = [];
  if (data) {
    const accountIdsByFreshTemplateId = getAllAccountIdsForFreshTemplateId(data.data);
    allocationTemplateOptions = data.data.allocationsTemplates
      .filter(
        // Fresh templates that are associated with one of the accounts being filtered
        (template) =>
          !template.stale &&
          accountIdsByFreshTemplateId[template.parentId] &&
          accountIdsByFreshTemplateId[template.parentId].find((id) => accountIds.has(id))
      )
      .map((template) => ({ templateId: template.parentId, name: template.name }))
      .sort((option1, option2) => option1.name.localeCompare(option2.name));
    restrictionTemplateOptions = data.data.restrictionsTemplates
      .filter(
        // Fresh templates that are associated with one of the accounts being filtered
        (template) =>
          !template.stale &&
          accountIdsByFreshTemplateId[template.parentId] &&
          accountIdsByFreshTemplateId[template.parentId].find((id) => accountIds.has(id))
      )
      .map((template) => ({ templateId: template.parentId, name: template.name }))
      .sort((option1, option2) => option1.name.localeCompare(option2.name));
  }
  const action = useRef<PopoverActions>(null);
  return (
    <PopoverTrigger
      action={action}
      overlay={() => (
        <Box borderRadius="4px" bgcolor="grey.200" width="400px">
          <Box p={2} display="flex" alignItems="center" bgcolor="grey.100">
            <Box flex={1}>
              <Typography variant="h4">Filters</Typography>
            </Box>
            <Button
              disabled={!filtersEnabled(filterState)}
              onClick={() => {
                onApplyFilter(initialFilterState);
                logApplyFilter(eventCategory, 'Clear all');
              }}
              variant="text"
            >
              Clear all
            </Button>
          </Box>
          <Divider />
          {filterType === 'ACCOUNT' && (
            <Section
              action={action.current}
              icon={<UserGroupIcon width={16} height={16} />}
              title="Household"
              active={filterState.households.length > 0}
            >
              <Box bgcolor="white">
                <TextField
                  fullWidth
                  value={householdFilterSearch}
                  onChange={(e) => setHouseholdFilterSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search"
                />
              </Box>
              <Box>
                <FormGroup>
                  {matchSorter(allHouseholds, householdFilterSearch, {
                    keys: ['name'],
                    threshold: matchSorter.rankings.CONTAINS,
                  }).map((household) => {
                    return (
                      <FormControlLabel
                        key={household.id}
                        label={household.name}
                        control={
                          <Checkbox checked={filterState.households.includes(household.id)} />
                        }
                        onChange={(e, checked) => {
                          onApplyFilter({
                            ...filterState,
                            households: checked
                              ? [...filterState.households, household.id]
                              : filterState.households.filter((val) => val !== household.id),
                          });
                          logApplyFilter(eventCategory, 'Households');
                        }}
                      />
                    );
                  })}
                </FormGroup>
              </Box>
            </Section>
          )}
          <Section
            icon={<CurrencyDollar width={16} height={16} />}
            title={filterType === 'ACCOUNT' ? 'Account value' : 'Household value'}
            active={filterState.minimumValue != null || filterState.maximumValue != null}
            action={action.current}
          >
            <Box display="flex" alignItems="center">
              <Box width="160px">
                <TextField
                  InputProps={{ inputComponent: InputDollar, style: { backgroundColor: '#fff' } }}
                  placeholder="Min"
                  value={filterState.minimumValue}
                  onChange={(event) => {
                    onApplyFilter({
                      ...filterState,
                      minimumValue: event.target.value ? Number(event.target.value) : null,
                    });
                    logApplyFilter(eventCategory, 'Account value');
                  }}
                />
              </Box>
              <Box mx={1}>to</Box>
              <Box width="160px">
                <TextField
                  InputProps={{ inputComponent: InputDollar, style: { backgroundColor: '#fff' } }}
                  placeholder="Max"
                  value={filterState.maximumValue}
                  onChange={(event) => {
                    onApplyFilter({
                      ...filterState,
                      maximumValue: event.target.value ? Number(event.target.value) : null,
                    });
                    logApplyFilter(eventCategory, 'Account value');
                  }}
                />
              </Box>
            </Box>
          </Section>
          {filterType === 'ACCOUNT' && (
            <Section
              icon={<StatusIcon width={16} height={16} />}
              title="Account status"
              active={filterState.statusFilters.length > 0}
              action={action.current}
            >
              <FormGroup>
                <FormControlLabel
                  label={`Active (${
                    accounts.filter(
                      (acct) => acct.status === 'ACTIVE' && acct.rebalancerStatus !== 'PAUSED'
                    ).length
                  })`}
                  control={<Checkbox checked={filterState.statusFilters.includes('ACTIVE')} />}
                  onChange={(e, checked) => {
                    onApplyFilter({
                      ...filterState,
                      statusFilters: checked
                        ? [...filterState.statusFilters, 'ACTIVE']
                        : filterState.statusFilters.filter((status) => status !== 'ACTIVE'),
                    });
                    logApplyFilter(eventCategory, 'Account status');
                  }}
                />
                <FormControlLabel
                  label={`Paused (${
                    accounts.filter(
                      (acct) => acct.status === 'ACTIVE' && acct.rebalancerStatus === 'PAUSED'
                    ).length
                  })`}
                  control={<Checkbox checked={filterState.statusFilters.includes('PAUSED')} />}
                  onChange={(e, checked) => {
                    onApplyFilter({
                      ...filterState,
                      statusFilters: checked
                        ? [...filterState.statusFilters, 'PAUSED']
                        : filterState.statusFilters.filter((status) => status !== 'PAUSED'),
                    });
                    logApplyFilter(eventCategory, 'Account status');
                  }}
                />
                <FormControlLabel
                  label={`Inactive (${
                    accounts.filter((acct) => acct.status === 'INACTIVE').length
                  })`}
                  control={<Checkbox checked={filterState.statusFilters.includes('INACTIVE')} />}
                  onChange={(e, checked) => {
                    onApplyFilter({
                      ...filterState,
                      statusFilters: checked
                        ? [...filterState.statusFilters, 'INACTIVE']
                        : filterState.statusFilters.filter((status) => status !== 'INACTIVE'),
                    });
                    logApplyFilter(eventCategory, 'Account status');
                  }}
                />
              </FormGroup>
            </Section>
          )}
          <Section
            icon={<PieChartIcon width={16} height={16} />}
            title="Allocation template"
            active={filterState.allocationTemplates.length > 0}
            action={action.current}
          >
            <Box bgcolor="white">
              <TextField
                fullWidth
                value={allocationTemplateFilter}
                onChange={(e) => setAllocationTemplateFilter(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
              />
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  key=""
                  label="No template"
                  control={<Checkbox checked={filterState.allocationTemplates.includes('')} />}
                  onChange={(e, checked) => {
                    onApplyFilter({
                      ...filterState,
                      allocationTemplates: checked
                        ? [...filterState.allocationTemplates, '']
                        : filterState.allocationTemplates.filter((id) => id !== ''),
                    });
                    logApplyFilter(eventCategory, 'Allocation template');
                  }}
                />
                {matchSorter(allocationTemplateOptions, allocationTemplateFilter, {
                  keys: ['name'],
                  threshold: matchSorter.rankings.CONTAINS,
                }).map((option) => {
                  return (
                    <FormControlLabel
                      key={option.templateId}
                      label={option.name}
                      control={
                        <Checkbox
                          checked={filterState.allocationTemplates.includes(option.templateId)}
                        />
                      }
                      onChange={(e, checked) => {
                        onApplyFilter({
                          ...filterState,
                          allocationTemplates: checked
                            ? [...filterState.allocationTemplates, option.templateId]
                            : filterState.allocationTemplates.filter(
                                (id) => id !== option.templateId
                              ),
                        });
                        logApplyFilter(eventCategory, 'Allocation template');
                      }}
                    />
                  );
                })}
              </FormGroup>
            </Box>
          </Section>
          <Section
            icon={<BanIcon width={16} height={16} />}
            title="Restriction template"
            active={filterState.restrictionTemplates.length > 0}
            action={action.current}
          >
            <Box bgcolor="white">
              <TextField
                fullWidth
                value={restrictionTemplateFilter}
                onChange={(e) => setRestrictionTemplateFilter(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
              />
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  key=""
                  label="No template"
                  control={<Checkbox checked={filterState.restrictionTemplates.includes('')} />}
                  onChange={(e, checked) => {
                    onApplyFilter({
                      ...filterState,
                      restrictionTemplates: checked
                        ? [...filterState.restrictionTemplates, '']
                        : filterState.restrictionTemplates.filter((id) => id !== ''),
                    });
                    logApplyFilter(eventCategory, 'Restriction template');
                  }}
                />
                {matchSorter(restrictionTemplateOptions, restrictionTemplateFilter, {
                  keys: ['name'],
                  threshold: matchSorter.rankings.CONTAINS,
                }).map((option) => {
                  return (
                    <FormControlLabel
                      key={option.templateId}
                      label={option.name}
                      control={
                        <Checkbox
                          checked={filterState.restrictionTemplates.includes(option.templateId)}
                        />
                      }
                      onChange={(e, checked) => {
                        onApplyFilter({
                          ...filterState,
                          restrictionTemplates: checked
                            ? [...filterState.restrictionTemplates, option.templateId]
                            : filterState.restrictionTemplates.filter(
                                (id) => id !== option.templateId
                              ),
                        });
                        logApplyFilter(eventCategory, 'Restriction template');
                      }}
                    />
                  );
                })}
              </FormGroup>
            </Box>
          </Section>
        </Box>
      )}
    >
      <PopoverLink
        style={{
          borderBottom:
            'none' /* We use `styles` because we need to override the deprecated makeStyles usage in the base component */,
        }}
      >
        <Box
          width="44px"
          height="44px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: filtersEnabled(filterState) ? 'blue.100' : undefined }}
        >
          <KnobsIcon />
        </Box>
      </PopoverLink>
    </PopoverTrigger>
  );
}
