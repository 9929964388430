import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import { ReactComponent as CircleX } from '~/static/images/icons/x-circle.svg';
import Upload from '~/static/images/illustrations/upload-file.svg';
import DialogTitle from '~/synth/DialogTitle';

const FIVE_MB = 1024 * 1024 * 5;
interface UploadLogoProps {
  title: string;
  onClose: () => void;
  open: boolean;
  handleSave: (formData?: FormData, image?: string) => void;
  defaultImage?: string;
  dialogText?: React.ReactNode;
  cta?: string;
}

export default function UploadImage({
  title,
  dialogText,
  onClose,
  open,
  handleSave,
  defaultImage,
  cta,
}: UploadLogoProps) {
  const theme = useTheme();
  const enqueueCoachmark = useEnqueueCoachmark();
  const [file, setFile] = useState<File | undefined>();
  const [image, setImage] = useState<string | undefined>();

  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  function handleFile(file) {
    if (file.size > FIVE_MB) {
      enqueueCoachmark({
        title: 'This image is too large',
        content: 'Please upload an image that is 5MB or smaller.',
        severity: 'error',
      });
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader.result as string);
    };
    setFile(file);
    reader.onerror = () => {
      enqueueCoachmark({
        title: 'There was an error uploading the image.',
        content: 'Please make sure the file is in the right format (PNG or JPG).',
        severity: 'error',
      });
    };
  }

  async function handleDefault() {
    const img = await fetch(defaultImage ?? '');
    const blob = await img.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      handleSave(undefined, reader.result as string);
    };
  }

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current?.click();
  };

  async function handleUploadImage(event) {
    const {
      target: { files },
    } = event;

    if (files.length) {
      const file = files[0];
      handleFile(file);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent>
        {dialogText}
        <Box display="flex" mt={3}>
          <Box
            width="50%"
            border={2}
            borderColor={theme.palette.blue[300]}
            borderRadius={1}
            sx={{ borderStyle: 'dashed' }}
            p={2}
            position="relative"
            display="flex"
          >
            <Box m="auto" textAlign="center">
              <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  hidden
                  accept="image/png, image/jpeg, *.svg"
                  onChange={handleUploadImage}
                />
                <div>
                  <img src={Upload} alt="upload" />
                  <Box mb={1} mt={3} display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="body1" fontWeight="bold">
                      Drop your file here, or
                    </Typography>
                    <ButtonBase onClick={onButtonClick}>
                      <Box ml={0.5} color="primary.main">
                        <Typography variant="body1" fontWeight="bold">
                          browse
                        </Typography>
                      </Box>
                    </ButtonBase>
                    .
                  </Box>
                  <Typography variant="caption" component="div" mt={1} color="textSecondary">
                    Image types supported are PNG and JPG, which must be 5MB and under.
                  </Typography>
                </div>
                {dragActive && (
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  />
                )}
              </form>
            </Box>
          </Box>
          <Box
            ml={3}
            border={1}
            borderColor={theme.palette.grey[300]}
            borderRadius={1}
            py={2}
            px={4}
            textAlign="center"
            display="flex"
            flexDirection="column"
            position="relative"
            width="50%"
          >
            {image && (
              <IconButton
                size="small"
                onClick={() => setImage(undefined)}
                sx={{ position: 'absolute', top: 8, right: 8 }}
              >
                <CircleX />
              </IconButton>
            )}
            <Typography variant="h4" mb={1}>
              Preview
            </Typography>
            <Box m="auto" position="relative">
              {(image || defaultImage) && (
                <img src={image ?? defaultImage} width={200} alt="logo" />
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={async () => {
            const data = new FormData();
            if (file) {
              data.append('image', file);
              try {
                handleSave(data, image);
                setImage(undefined);
                onClose();
                return;
              } catch (e) {
                enqueueCoachmark({
                  title: 'There was an error saving the image.',
                  content: 'Please try again.',
                  severity: 'error',
                });
              }
            }
            await handleDefault();
            onClose();
          }}
          variant="contained"
        >
          {cta ?? 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
