import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

// This hook can be used in the base (root) component of a screen/route to override the styling of
// Vise app Root component of the and set its style.height to 100%.
// This is useful if you need to use the entirety of the height, e.g. for creating a footer that
// floats to the bottom of the screen, or to stretch components vertically to fill up the entire
// viewport.
export default function useFullHeightRootContainer(): void {
  // TODO: this should probably be styles?
  useEffect(() => {
    const app = document.getElementById('root');
    if (app == null) {
      Sentry.captureMessage('Failed to set full height: app "root" component not found.');
      return () => {};
    }
    const origHeight = app.style.height;
    app.style.height = '100%';
    // Cleanup: when component unmounts, reset the height to original size
    return () => {
      app.style.height = origHeight;
    };
  }, []);
}
