import React, { useEffect } from 'react';
// @ts-expect-error ts-migrate(2305) FIXME: Module '"react-router-dom"' has no exported member... Remove this comment to see the full error message
import { History, withRouter } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import EmptyState from '../../synth/deprecated/EmptyState';
import { AdminIcon } from '../../synth/Icons';

type ErrorFallbackProps = {
  error: Error;
  resetError?: () => void;
};

const ErrorFallback = ({
  resetError,
  history,
  error,
}: ErrorFallbackProps & { history: History }) => {
  useEffect(() => {
    datadogRum.addError(error);
  }, [error]);
  return (
    <EmptyState
      large
      icon={<AdminIcon />}
      title="Sorry, something went wrong."
      subtitle="Go back"
      subtitleOnClick={() => {
        if (resetError) {
          resetError();
        }
        history.goBack();
      }}
    />
  );
};

// @ts-expect-error ts-migrate(2352) FIXME: Conversion of type 'ComponentClass<Pick<RouteCompo... Remove this comment to see the full error message
export default withRouter(ErrorFallback) as (props: ErrorFallbackProps) => React.ReactElement;
