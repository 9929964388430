import { Button, ButtonBase, Card, CardContent, Divider, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Account } from 'vise-types/portfolio';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAccount from '~/hooks/useAccount';
import useClients from '~/hooks/useClients';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { RawClient } from '~/models/api';
import { BondScreenProps } from '~/routes/BondPortfolioCreator/BondPortfolioState';
import SummaryPanel from '~/routes/BondPortfolioCreator/SummaryPanel';
import Select from '~/synth/inputs/Select';
import amplitude from '~/utils/amplitude';
import { formatCurrency } from '~/utils/format';
import LinkAccountOrCreateClientModal from '../Households/Households/LinkAccountOrCreateClientModal';
import {
  RealProposalCard,
  SampleProposalCard,
} from '../PortfolioCreator2/screens/ProposalTypeScreen';
import StepTitleCard from './components/StepTitleCard';
import { routeUrls } from './steps';

const MIN_PORTFOLIO_VALUE = 125_000;

interface ClientOption {
  label: string;
  value: RawClient;
}

interface FormValues {
  client?: RawClient | null;
  account: Account | null;
  cashValue: number | null;
  proposalType: 'real' | 'sample' | null;
}

export default function ClientDetails({
  state,
  dispatch,
  footer,
  summaryPanelRef,
}: BondScreenProps) {
  useEffect(() => {
    amplitude().logEvent('Bond Builder - Select client screen', {
      category: EVENT_CATEGORIES.BOND_BUILDER,
    });
  }, []);
  const { data: featureFlags } = useFeatureFlags();

  const history = useHistory();
  const { data: clientsData, isLoading: clientsIsLoading } = useClients();

  const [newClientModalOpen, setNewClientModalOpen] = useState(false);
  const [lpoaConfirmed, setLpoaConfirmed] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      client: {
        ...state.client,
      },
      account: state.account,
      cashValue: state.cashValue,
      // eslint-disable-next-line no-nested-ternary
      proposalType: state.account ? 'real' : state.cashValue ? 'sample' : undefined,
    },
  });

  const watchProposalType = watch('proposalType');
  const watchClient = watch('client');
  const watchAccount = watch('account');

  const accountId = new URLSearchParams(document.location.search).get('accountId');
  const { data: response } = useAccount(accountId);
  const preselectedAccount = response?.data;

  const preselectedClient = clientsData?.find((c) => c.id === preselectedAccount?.viseClientId);

  const onSubmit = (data: FormValues) => {
    if (!data.proposalType) {
      setError('proposalType', { message: 'Please select a proposal type' });
      return;
    }
    if (data.proposalType === 'sample' && !data.cashValue) {
      setError('cashValue', { message: 'Please set a cash value' });
      return;
    }

    if (data.proposalType === 'sample' && data.cashValue && data.cashValue < MIN_PORTFOLIO_VALUE) {
      setError('cashValue', {
        message: `Insufficient value. Minimum portfolio size is ${formatCurrency(
          MIN_PORTFOLIO_VALUE
        )}`,
      });
      return;
    }

    if (data.proposalType === 'real' && !data.account) {
      setError('cashValue', { message: 'Please select an account' });
      return;
    }

    if (data.account?.cachedAum && data.account.cachedAum < 125_000) {
      setError('cashValue', {
        message: `Insufficient account value.  Minimum portfolio size is ${formatCurrency(
          MIN_PORTFOLIO_VALUE
        )}. Account size: ${formatCurrency(data.account.cachedAum)}`,
      });
      return;
    }

    if (data.proposalType === 'real' && data.account?.status !== 'ACTIVE') {
      setError('account', { message: 'Account is not active. Please select another account.' });
      return;
    }

    // default required is not going to work with {} object
    if (isEmpty(data.client)) {
      setError('client', { message: 'Required' });
      return;
    }

    dispatch({ type: 'SET_CLIENT', value: data.client });
    if (data.proposalType === 'real') {
      dispatch({ type: 'SET_ACCOUNT', value: data.account });
      dispatch({ type: 'SET_CASH_VALUE', value: null });
    } else {
      dispatch({ type: 'SET_CASH_VALUE', value: data.cashValue });
      dispatch({ type: 'SET_ACCOUNT', value: null });
    }
    history.push(
      data.account?.taxable || data.proposalType === 'sample' ? routeUrls.TAXES : routeUrls.BUILD
    );
  };

  const clientOptions: ClientOption[] = useMemo(
    () =>
      clientsData == null
        ? []
        : clientsData.map<ClientOption>((client) => ({
            label: `${client.firstName} ${client.lastName}`,
            value: client,
          })),
    [clientsData]
  );

  function handleValidationError() {
    // TBD global unexpected error modal/alert
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, handleValidationError)}>
      <StepTitleCard
        title="First, select or add a client."
        description={
          <>
            To begin, search for an existing or{' '}
            <ButtonBase onClick={() => setNewClientModalOpen(true)} sx={{ color: 'primary.main' }}>
              <Typography marginBottom={0.3} component="span" lineHeight={1} fontSize="medium">
                create a new client here.
              </Typography>
            </ButtonBase>{' '}
          </>
        }
      />
      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Typography variant="h3">Client information</Typography>
          <Divider sx={{ marginBottom: 3, marginTop: 0.5 }} />
          <Controller
            name="client"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <>
                <Select<ClientOption>
                  error={fieldState.invalid}
                  {...field}
                  menuPortalTarget={document.body}
                  autoFocus
                  isSearchable
                  inputId="client"
                  isClearable
                  isLoading={clientsIsLoading}
                  options={clientOptions}
                  onChange={(value) => {
                    field.onChange((value as ClientOption)?.value);
                    setValue('account', null);
                    setValue('proposalType', null);
                    clearErrors('client');
                    clearErrors('account');
                    clearErrors('proposalType');
                    clearErrors('cashValue');
                    window.scrollTo({ top: document.body.offsetHeight, behavior: 'smooth' });
                  }}
                  placeholder="Ex: Jane Doe"
                  value={
                    clientOptions.find((option) => option.value.id === field.value?.id) ??
                    clientOptions.find((o) => o.value.id === preselectedClient?.id)
                  }
                />
                <Box color="error.500" mt={1}>
                  {fieldState.invalid && <Typography variant="body1">Required</Typography>}
                </Box>
              </>
            )}
          />
        </CardContent>
      </Card>
      {watchClient && (
        <>
          <Card sx={{ mt: 5 }}>
            <CardContent>
              <Typography variant="h3">How do you want to proceed?</Typography>
              <Divider sx={{ marginBottom: 1.5, marginTop: 0.5 }} />
              <Box mb={2}>
                {featureFlags?.enable_treasuries === 'on' ? (
                  <Typography color="grey.500" fontSize="medium">
                    Please be advised that Municipal and Treasury bond portfolios require a minimum
                    account value of{' '}
                    <Typography fontWeight="700" component="span">
                      $125,000{' '}
                    </Typography>
                    and
                    <Typography fontWeight="700" component="span">
                      {' '}
                      $250,000
                    </Typography>{' '}
                    respectively.
                  </Typography>
                ) : (
                  <Typography color="grey.500" fontSize="medium">
                    Please be advised that individual fixed income portfolios require a minimum
                    account value of{' '}
                    <Typography fontWeight="700" component="span">
                      $125,000.
                    </Typography>
                  </Typography>
                )}
              </Box>
              <RealProposalCard
                account={watchAccount ?? preselectedAccount}
                accountSelectIsDisabled={false}
                active={watchProposalType === 'real' || preselectedAccount}
                clientId={watchClient?.id ?? preselectedClient?.id}
                disabled={false}
                onClick={() => {
                  window.scrollTo({ top: document.body.offsetHeight, behavior: 'smooth' });
                  clearErrors('proposalType');
                  clearErrors('cashValue');
                  setValue('proposalType', 'real');
                }}
                onChange={(account) => {
                  setValue('account', account?.account);
                  if (account?.account.engineType !== 'AB_FIXED_INCOME') {
                    setLpoaConfirmed(false);
                  }
                }}
                showValue
                taxableOnly={featureFlags?.enable_treasuries !== 'on'}
              />
              {watchAccount && watchAccount.engineType !== 'AB_FIXED_INCOME' && !lpoaConfirmed && (
                <Alert sx={{ my: 2 }} severity="info">
                  If you wish to execute a portfolio in &quot;Bond Builder&quot;, please connect
                  with the client service team to finalize an additional Limited Power of Attorney
                  (LPOA).
                  <Box display="flex" justifyContent="end" mt={2}>
                    <Button size="small" variant="contained" onClick={() => setLpoaConfirmed(true)}>
                      Acknowledge
                    </Button>
                  </Box>
                </Alert>
              )}
              <Controller
                render={({ field, fieldState }) => (
                  <>
                    <SampleProposalCard
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        clearErrors('cashValue');
                      }}
                      initialValue={field.value}
                      active={watchProposalType === 'sample'}
                      onClick={() => {
                        window.scrollTo({ top: document.body.offsetHeight, behavior: 'smooth' });
                        clearErrors('proposalType');
                        clearErrors('cashValue');
                        setValue('proposalType', 'sample');
                        setValue('account', null);
                      }}
                      onBlur={() => null}
                    />
                    <Box color="error.500" my={2}>
                      <Typography variant="body1">{fieldState.error?.message}</Typography>
                    </Box>
                  </>
                )}
                name="cashValue"
                control={control}
              />
            </CardContent>
          </Card>
          {errors.proposalType && (
            <Alert sx={{ my: 2 }} severity="error">
              {errors.proposalType.message}
            </Alert>
          )}
        </>
      )}
      {footer &&
        footer(
          <>
            <div />
            <Button
              type="submit"
              variant="contained"
              disabled={
                !lpoaConfirmed &&
                watchAccount?.engineType !== 'AB_FIXED_INCOME' &&
                watchProposalType === 'real'
              }
            >
              Continue
            </Button>
          </>
        )}
      {summaryPanelRef?.current &&
        createPortal(
          <SummaryPanel state={{ ...state, ...getValues() }} />,
          summaryPanelRef.current
        )}
      <LinkAccountOrCreateClientModal
        open={newClientModalOpen}
        onClose={() => setNewClientModalOpen(false)}
        onSuccess={(client) => setValue('client', client)}
      />
    </form>
  );
}
