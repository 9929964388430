import { Box, InputAdornment, Typography, useTheme } from '@mui/material';
import { debounce, keyBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CellProps,
  Column,
  Row,
  TableState,
  UsePaginationState,
  UseSortByOptions,
  UseSortByState,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { AccountWithPIAndHouseholdInfo, BondPortfolioSettings } from 'vise-types/portfolio';
import AccountFilter, {
  filterAccounts,
  initialFilterState,
} from '~/components/portfolio/AccountFilter';
import PageNavigation from '~/components/table/PageNavigation';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAllAccountsWithPIAndHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import useClients from '~/hooks/useClients';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import { Client } from '~/models/api';
import { ProposalSummary } from '~/models/household';
import { ReactComponent as UserIcon } from '~/static/images/icons/user.svg';
import { DataTable, DataTableActionBar, floatCompare } from '~/synth/DataTable';
import { SearchIcon } from '~/synth/Icons';
import PercentChange from '~/synth/PercentChange';
import TextField from '~/synth/TextField';
import EmptyState from '~/synth/deprecated/EmptyState';
import { WithLoader } from '~/utils';
import { convertToPercent, formatCurrency } from '~/utils/format';
import scrollToTop from '~/utils/scrollToTop';
import { NoResultsFound } from '../../Portfolio/components/TableComponents';
import Truncation from '../../Portfolio/components/Truncation';
import ErrorFallback from '../ErrorFallback';
import renderAccountStatus, { ACCOUNT_STATUS_TO_ORDER } from '../components/renderAccountStatus';
import renderRebalancerStatus from '../components/renderRebalancerStatus';
import { AccountNameCell } from '../table/UtilComponents';
import LoadingTable from './LoadingTable';
import ValueCell from './ValueCell';

export type AccountTableData = AccountWithPIAndHouseholdInfo & {
  viseClient?: Client;
  proposals?: ProposalSummary[];
  bondProposals?: BondPortfolioSettings[];
  // Either the rebalancer status or there is some sort of issue with the account
  rebalancerDisplayStatus?: { status: 'ACTIVE' | 'PAUSED'; rebalancerPausedAt: string | null };
};

interface AccountStatusFilterOption {
  label: string;
  value: 'ALL' | 'PAUSED' | 'INACTIVE' | 'PERFORMANCE_HIDDEN';
}

export const renderClientAndHouseholdCell = ({ value }: CellProps<AccountTableData>) => {
  return (
    <Box alignItems="center" flexDirection="column">
      <Box pb={0.25}>
        {value ? (
          <Truncation>{`${value.firstName} ${value.lastName}`}</Truncation>
        ) : (
          <span>&nbsp;</span>
        )}
      </Box>
      <Typography variant="body1" color="textSecondary" component="div">
        <Truncation>{value?.clientGroup?.name || ''}</Truncation>
      </Typography>
    </Box>
  );
};

type PortfoliosTableProps = {
  pageSize?: number;
};

export default function PortfoliosTable({ pageSize = 50 }: PortfoliosTableProps) {
  const { data: featureFlags } = useFeatureFlags();
  const { data: clients, error: clientsError } = useClients();
  const { data: accountsRespWithData, error: accountsRespWithDataError } =
    useAllAccountsWithPIAndHouseholdInfo({
      includeSummaries: true,
      transitioned: true,
      perfSuppressionEnabled: featureFlags?.enable_performance_data !== 'on',
      includePi: true,
    });
  const { data: accountsResp, error: accountsError } = useAllAccountsWithPIAndHouseholdInfo({
    includeSummaries: false,
    transitioned: true,
    perfSuppressionEnabled: featureFlags?.enable_performance_data !== 'on',
  });
  let accounts = accountsResp != null ? accountsResp.data : null;

  if (accountsRespWithData != null) {
    accounts = accountsRespWithData.data;
  }

  const tableData: AccountTableData[] | undefined = useMemo(() => {
    if (accounts == null || clients == null) return undefined;
    const clientsById = keyBy(clients, 'id');
    return accounts.map((account) => {
      return {
        ...account,
        viseClient: account.viseClientId == null ? undefined : clientsById[account.viseClientId],
        rebalancerDisplayStatus: {
          status: account.rebalancerStatus,
          rebalancerPausedAt: account.rebalancerPausedAt,
        },
      };
    });
  }, [accounts, clients]);

  const accountsIsLoading = tableData == null;

  const [query, setQuery] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeFilterQuery = useCallback(
    debounce((nextFilterQuery: string) => setFilterQuery(nextFilterQuery), 350),
    []
  );
  function handleChangeQuery(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
    handleChangeFilterQuery(event.target.value);
  }

  const handleResetQuery = useCallback(() => {
    setQuery('');

    // Cancel any pending filter and reset immediately. Clicking the "X" to clear should give
    // instantaneous feedback rather than delayed.
    handleChangeFilterQuery.cancel();
    setFilterQuery('');
  }, [handleChangeFilterQuery]);

  const [accountStatusFilter, setAccountStatusFilter] = useState<AccountStatusFilterOption | null>(
    null
  );

  // Clear the filters on hide/show events
  useEffect(() => {
    handleResetQuery();
    setAccountStatusFilter(null);
  }, [handleResetQuery]);

  const filteredAccountDataBeforeDropdownFilter: AccountTableData[] = useMemo(
    () =>
      tableData
        ? (
            matchSorter(tableData, filterQuery, {
              keys: ['accountName', 'accountNumber'],
              threshold: matchSorter.rankings.CONTAINS,
            }) || []
          ).filter((account) => {
            if (accountStatusFilter === null) {
              return true;
            }

            switch (accountStatusFilter.value) {
              case 'INACTIVE': {
                return account.status === 'INACTIVE';
              }
              case 'PAUSED': {
                return account.rebalancerStatus === 'PAUSED';
              }
              case 'PERFORMANCE_HIDDEN': {
                return account.performanceQaRequired;
              }
              case 'ALL':
              default: {
                return true;
              }
            }
          })
        : [],
    [filterQuery, accountStatusFilter, tableData]
  );

  const accountColumns = React.useMemo(
    () =>
      [
        {
          Header: 'Account',
          accessor: 'accountName',
          align: 'left',
          Cell: (props) => {
            const { accountName, accountNumber, taxable, custodianKey, engineType } =
              props.row.original;
            return (
              <AccountNameCell
                accountName={accountName}
                accountNumber={accountNumber}
                taxable={taxable}
                custodianKey={custodianKey}
                engineType={engineType}
              />
            );
          },
          // Sort by account name & then by account number
          sortType: (rowA, rowB) => {
            const {
              original: { accountName: accountNameA, accountNumber: accountNumberA },
            } = rowA;
            const {
              original: { accountName: accountNameB, accountNumber: accountNumberB },
            } = rowB;
            const compareResult = accountNameA.localeCompare(accountNameB);
            if (compareResult !== 0) {
              return compareResult;
            }

            return accountNumberA.localeCompare(accountNumberB);
          },
          width: '35%',
        },
        {
          Header: 'Client / Household',
          accessor: 'viseClient',
          Cell: renderClientAndHouseholdCell,
          // First sort by household name within each household sort by account
          sortType: (rowA, rowB) => {
            const result = (rowA.original.viseClient?.clientGroup?.name || '').localeCompare(
              rowB.original.viseClient?.clientGroup?.name || ''
            );
            if (result !== 0) {
              return result;
            }

            const accountNameResult = rowA.original.accountName.localeCompare(
              rowB.original.accountName
            );
            if (accountNameResult !== 0) {
              return accountNameResult;
            }

            return rowA.original.accountNumber.localeCompare(rowB.original.accountNumber);
          },
          width: '25%',
        },
        {
          Header: 'Account status',
          accessor: (account) => ({ status: account.status, statusReason: account.statusReason }),
          Cell: renderAccountStatus,
          sortType: (rowA, rowB) => {
            const { status: statusA } = rowA.original;
            const { status: statusB } = rowB.original;
            return ACCOUNT_STATUS_TO_ORDER[statusA] > ACCOUNT_STATUS_TO_ORDER[statusB];
          },
        },
        {
          Header: 'Rebalancer status',
          accessor: 'rebalancerDisplayStatus',
          Cell: (props) =>
            renderRebalancerStatus({
              value: props.value,
              engineType: props.row.original.engineType,
            }),
          sortType: (rowA) => {
            const { rebalancerDisplayStatus } = rowA.original;
            return rebalancerDisplayStatus?.status === 'ACTIVE' ? 1 : -1;
          },
        },
        ...(featureFlags?.enable_performance_data === 'on'
          ? [
              {
                Header: 'ITD change',
                accessor: (d) => d.bdPerformance?.itdRorFraction,
                id: 'itd',
                Cell: (props) => (
                  <ValueCell
                    value={
                      props.value == null ? (
                        '-'
                      ) : (
                        <PercentChange value={convertToPercent(props.value)} />
                      )
                    }
                    isLoading={!accountsRespWithData && !accountsRespWithDataError}
                  />
                ),
                width: '10%',
                sortType: floatCompare,
              },
            ]
          : // TODO(awang): add risk here
            []),
        {
          Header: 'Value',
          accessor: (d) => (d.summary.length ? d.summary[d.summary.length - 1].marketValue : 0),
          id: 'value',
          Cell: (props) => (
            <ValueCell
              value={formatCurrency(props.value)}
              isLoading={!accountsRespWithData && !accountsRespWithDataError}
            />
          ),
        },
      ] as Column<AccountTableData>[] & UseSortByOptions<AccountTableData>[],
    [featureFlags?.enable_performance_data, accountsRespWithData, accountsRespWithDataError]
  );

  const [dropdownFilterState, setDropdownFilterState] = useState(initialFilterState);
  const { data: modelTemplateCenterData } = useModelTemplateCenterViewData();
  const filteredAccountData = useMemo(() => {
    if (modelTemplateCenterData?.data) {
      return filterAccounts(
        filteredAccountDataBeforeDropdownFilter,
        dropdownFilterState,
        modelTemplateCenterData.data
      );
    }
    return filteredAccountDataBeforeDropdownFilter;
  }, [modelTemplateCenterData?.data, dropdownFilterState, filteredAccountDataBeforeDropdownFilter]);

  const accountTable = useTable<AccountTableData>(
    {
      autoResetSortBy: false,
      columns: accountColumns,
      data: filteredAccountData,
      initialState: {
        sortBy: [{ id: 'accountName' }],
        pageSize,
      } as TableState<AccountTableData> &
        UseSortByState<AccountTableData> &
        UsePaginationState<AccountTableData>,
    },
    useSortBy,
    usePagination
  );

  const theme = useTheme();
  const history = useHistory();
  if (accountsError || clientsError) {
    return <ErrorFallback error={accountsError || clientsError} />;
  }

  return (
    <Box data-testid="accounts-table">
      <DataTableActionBar display="flex" px={2} justifyContent="space-between">
        <Box minWidth={366}>
          <TextField
            InputProps={{
              sx: { paddingRight: 0 },
              endAdornment: (
                <AccountFilter
                  accounts={filteredAccountDataBeforeDropdownFilter}
                  filterState={dropdownFilterState}
                  onApplyFilter={(filterState) => {
                    setDropdownFilterState(filterState);
                    accountTable.gotoPage(0);
                  }}
                  eventCategory={EVENT_CATEGORIES.HOUSEHOLDS}
                />
              ),
              onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
                if (event.currentTarget === event.target && event.key === 'Escape') {
                  handleResetQuery();
                }
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon display="inline-flex" color={theme.palette.grey[500]} />
                </InputAdornment>
              ),
            }}
            aria-label="Search accounts by name"
            fullWidth
            id="account_filter"
            name="filter_accounts"
            onChange={handleChangeQuery}
            placeholder="Search by name or account number"
            value={query}
          />
        </Box>
        {/* <Box display="flex">
          <Box minWidth={240}>
            <Select
              isClearable
              maxMenuHeight={10}
              options={accountStatusFilterOptions}
              value={accountStatusFilter}
              defaultValue={accountStatusFilterOptions[0]}
              onChange={handleStatusFilterChanged}
              placeholder="Account status"
            />
          </Box>
        </Box> */}
      </DataTableActionBar>
      <WithLoader isLoading={accountsIsLoading} loader={<LoadingTable pageSize={pageSize} />}>
        {tableData != null && tableData.length > 0 ? (
          <>
            <DataTable
              rowSize="large"
              table={accountTable}
              onRowClick={(row: Row<AccountTableData>) => {
                history.push(
                  `/secure/accounts/${row.original.id}/${
                    row.original.engineType === 'AB_FIXED_INCOME' ? 'bond-' : ''
                  }portfolio/overview`
                );
                scrollToTop();
              }}
            />
            {!filteredAccountData.length && <NoResultsFound />}
          </>
        ) : (
          <Box py={8}>
            <EmptyState
              icon={<UserIcon />}
              large
              title="No Vise Portfolios"
              subtitle="Please execute a proposal on an account to transition it to a Vise Portfolio."
            />
          </Box>
        )}
      </WithLoader>
      {filteredAccountData != null && filteredAccountData.length > pageSize && (
        <Box
          alignItems="center"
          borderColor="grey.200"
          borderTop={1}
          display="flex"
          justifyContent="flex-end"
          px={3}
          py={2}
        >
          <PageNavigation
            currentPage={(accountTable.state as UsePaginationState<AccountTableData>).pageIndex + 1}
            numItems={filteredAccountData.length}
            pageSize={pageSize}
            onNextPageClick={() => {
              scrollToTop();
              accountTable.nextPage();
            }}
            onPrevPageClick={() => {
              scrollToTop();
              accountTable.previousPage();
            }}
            onPageNumberClick={(pageNumber) => {
              scrollToTop();
              accountTable.gotoPage(pageNumber - 1);
            }}
          />
        </Box>
      )}
    </Box>
  );
}
