import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
} from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';
import { ValueType } from 'react-select';
import { createClient, updateAccount } from '~/api/api';
import useEnqueueToast from '~/hooks/useToast';
import useUnassignedAccounts from '~/hooks/useUnassignedAccounts';
import { RawClient } from '~/models/api';
import DialogTitle from '~/synth/DialogTitle';
import Multiselect from '~/synth/inputs/Multiselect';
import TextField from '~/synth/TextField';
import { maskAccountNumber } from '~/utils/format';

interface AddClientDialogContainerProps {
  householdId: string;
  onClose: () => void;
  isOpen: boolean;
}

interface AddClientDialogProps extends AddClientDialogContainerProps {
  accountOptions: AccountOption[];
  loadingAccountOptions: boolean;
}

interface AccountOption {
  value: string;
  label: string;
  description: string;
}

interface FormValues {
  firstName: string;
  lastName: string;
  accountIds: string[];
}

type SelectedAccountOptions = AccountOption[] | null;

export const AddClientDialog = ({
  householdId,
  onClose,
  isOpen,
  accountOptions,
  loadingAccountOptions,
}: AddClientDialogProps) => {
  const enqueueToast = useEnqueueToast();

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      accountIds: [],
    },

    async onSubmit(values) {
      // Create client
      let newClient: RawClient;
      try {
        newClient = await createClient({
          firstName: values.firstName,
          lastName: values.lastName,
          clientGroupId: householdId,
        });
      } catch (e) {
        enqueueToast({
          title: 'Error creating client',
          severity: 'error',
        });
        onClose();
        formik.resetForm();
        return;
      }

      // Link accounts to client
      try {
        await Promise.all(
          values.accountIds.map((accountId) => updateAccount(accountId, newClient.id))
        );
      } catch (e) {
        enqueueToast({
          title: 'Error linking accounts to new client',
          severity: 'error',
        });
        return;
      } finally {
        onClose();
        formik.resetForm();
      }

      // Send success alert
      enqueueToast({
        title: 'Client created successfully',
        severity: 'success',
      });
    },

    validate(values) {
      const errors: FormikErrors<FormValues> = {};
      if (values.firstName === '') errors.firstName = 'Enter a first name';
      if (values.firstName.length >= 256)
        errors.firstName = 'First name should be under 256 characters';
      if (values.lastName === '') errors.lastName = 'Enter a last name';
      if (values.lastName.length >= 256)
        errors.lastName = 'Last name should be under 256 characters';
      return errors;
    },
  });

  const onCloseWithReset = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Dialog open={isOpen} onClose={onCloseWithReset}>
      <DialogTitle onClose={onCloseWithReset}>Add a client</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                fullWidth
                helperText={formik.touched.firstName && formik.errors.firstName}
                id="firstName"
                label="First name"
                name="firstName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                disabled={formik.isSubmitting}
                value={formik.values.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                fullWidth
                helperText={formik.touched.lastName && formik.errors.lastName}
                id="lastName"
                label="Last name"
                name="lastName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                disabled={formik.isSubmitting}
                value={formik.values.lastName}
              />
            </Grid>
          </Grid>
          <Box mt={3}>
            <FormControl fullWidth>
              <FormLabel>Link accounts</FormLabel>
              <Multiselect<AccountOption>
                isLoading={loadingAccountOptions}
                noOptionsMessage={() => 'No accounts available'}
                menuPortalTarget={document.body}
                menuShouldScrollIntoView={false}
                placeholder={
                  !loadingAccountOptions && isEmpty(accountOptions)
                    ? 'No accounts available'
                    : 'Select accounts'
                }
                options={accountOptions}
                isSearchable
                isDisabled={
                  formik.isSubmitting || (!loadingAccountOptions && isEmpty(accountOptions))
                }
                onChange={(selectedOptions: ValueType<AccountOption>) => {
                  const selectedAccountIds = (
                    (selectedOptions as SelectedAccountOptions) || []
                  ).map((option) => {
                    return option.value;
                  });
                  formik.setFieldValue('accountIds', selectedAccountIds);
                }}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCloseWithReset}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={
              formik.isSubmitting ? <CircularProgress size="1em" color="inherit" /> : undefined
            }
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Save changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const AddClientDialogContainer = ({
  householdId,
  onClose,
  isOpen,
}: AddClientDialogContainerProps) => {
  const {
    data: unassignedAccounts,
    isLoading: unassignedAccountsIsLoading,
    error: unassignedAccountsError,
  } = useUnassignedAccounts();

  const options = useMemo(
    () =>
      unassignedAccounts == null
        ? []
        : unassignedAccounts.map((account) => ({
            value: account.id,
            label: account.name,
            description: maskAccountNumber(account.accountNumber),
          })),
    [unassignedAccounts]
  );

  if (unassignedAccountsError) {
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle onClose={onClose}>Add a client</DialogTitle>
        <DialogContent>
          There was an error loading available accounts. Please refresh and try again.
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <AddClientDialog
      householdId={householdId}
      loadingAccountOptions={unassignedAccountsIsLoading}
      onClose={onClose}
      isOpen={isOpen}
      accountOptions={options}
    />
  );
};

export default AddClientDialogContainer;
