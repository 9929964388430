import { Box, Button, Card, CardContent, Divider, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import { ActivityLogData, TemplateUpdateJob } from 'vise-types/template';
import { AccountNameCell } from '~/routes/Households/table/UtilComponents';
import Truncation from '~/routes/Portfolio/components/Truncation';
import noPinnedTemplateGraphic from '~/static/images/modelTemplateCenter/no-pinned-templates-graphic.svg';

export const ACTIVITY_LOG_LABELS = {
  LINK: 'Link/unlink accounts',
  UPDATE: 'Update accounts',
  BULK_EDIT: 'Update accounts',
  EDIT: 'Edit details',
  INSERT: 'New template',
  DELETE: 'Delete',
  RENAME: 'Rename',
};

export function Cell(props: { value: string | number }) {
  return <Box px={1}>{props.value}</Box>;
}

export function AccountCell(props: { value?: AccountWithPIAndHouseholdInfo }) {
  if (!props.value) {
    return (
      <>
        <Box mb={1}>Account not found</Box>
        <Typography variant="body1" color="textSecondary">
          This account could not be found. It may have been removed from the Vise platform. Please
          reach out to clientservice@vise.com with any questions.
        </Typography>
      </>
    );
  }
  return (
    <AccountNameCell
      accountName={props.value.accountName}
      accountNumber={props.value.accountNumber}
      taxable={props.value.taxable}
      custodianKey={props.value.custodianKey}
    />
  );
}

export function DateCell(props: { value: string }) {
  return <Box>{moment(props.value).format('M/D/YY, h:mma')}</Box>;
}

export function EventCell({
  value: { type, submittedByName },
}: {
  value: { type: ActivityLogData['type']; submittedByName: string };
}) {
  return (
    <Box>
      <Typography variant="body2">{ACTIVITY_LOG_LABELS[type]}</Typography>
      <Box mt={0.5}>
        <Typography variant="body1" color="textSecondary">
          by {submittedByName}
        </Typography>
      </Box>
    </Box>
  );
}

export function TemplateCell({
  value: { name, type },
}: {
  value: { name: string; type: 'allocations' | 'restrictions' };
}) {
  return (
    <Box ml={1}>
      <Typography variant="body2">{name}</Typography>
      <Box mt={0.5}>
        <Typography variant="body1" color="textSecondary">
          {type === 'allocations' ? 'Allocation' : 'Restrictions'}
        </Typography>
      </Box>
    </Box>
  );
}

export function BulkEditCell() {
  return (
    <Box ml={1}>
      <Typography variant="body2">2024 Capital gains budgets</Typography>
      <Box mt={0.5}>
        <Typography variant="body1" color="textSecondary">
          Capital gains
        </Typography>
      </Box>
    </Box>
  );
}

export function VerticalDivider() {
  return (
    <Box bgcolor="grey.300" mx={1.5}>
      <Divider orientation="vertical" flexItem style={{ height: '17.5px' }} />
    </Box>
  );
}

export function scrollToTop() {
  document
    .querySelector('#strategyCenterWorkflowContent')
    ?.scrollTo({ top: 0, behavior: 'smooth' });
}

export function sortLinkingTable<T>(
  rows: ({
    checked: {
      inProgressTemplateLinkingJob?: TemplateUpdateJob | null;
      disabledDueToQualifiedStatus?: boolean;
    };
    value: number;
  } & T)[]
) {
  return [...rows].sort((account1, account2) => {
    const account1Disabled =
      account1.checked.inProgressTemplateLinkingJob ||
      account1.checked.disabledDueToQualifiedStatus;
    const account2Disabled =
      account2.checked.inProgressTemplateLinkingJob ||
      account2.checked.disabledDueToQualifiedStatus;
    if (account1Disabled && !account2Disabled) {
      return 1;
    }
    if (!account1Disabled && account2Disabled) {
      return -1;
    }
    return account2.value - account1.value;
  });
}

export function useIconButtonColor(activeColor: string, isActive: boolean, isDisabled: boolean) {
  const theme = useTheme();
  if (isDisabled) {
    return theme.palette.grey[300];
  }
  if (isActive) {
    return activeColor;
  }
  return '#A3A3A3';
}

export function CTACell({ value }: { value?: { path: string; buttonCopy: string } }) {
  if (!value) {
    return null;
  }
  return (
    <Box display="flex" justifyContent="end">
      <Button component={Link} to={value.path} color="primary">
        {value.buttonCopy}
      </Button>
    </Box>
  );
}

export function leftPad(Component) {
  return (props) => (
    <Box ml={1}>
      <Component {...props} />
    </Box>
  );
}

export function HouseholdCell(props: { value: AccountWithPIAndHouseholdInfo }) {
  return (
    <Box>
      <Typography variant="body2">
        {props.value.firstName} {props.value.lastName}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        <Truncation>{props.value.householdInfo?.name}</Truncation>
      </Typography>
    </Box>
  );
}

export function EmptyCard() {
  return (
    <Card sx={{ height: '100%', minHeight: '320px' }}>
      <CardContent
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
      >
        <img src={noPinnedTemplateGraphic} alt="" />
      </CardContent>
    </Card>
  );
}
