import { Dialog, Box, Button, useTheme, DialogProps } from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { AllocationsTemplate, RestrictionsTemplate } from 'vise-types/template';
import { DataTable } from '~/synth/DataTable';
import DialogTitle from '~/synth/DialogTitle';
import { Cell } from './CommonComponents';

interface VersionTableData {
  createdAt: Date;
  editedBy: string;
  onRestoreVersion: () => void;
}

const linkTableColumns: Column<VersionTableData>[] = [
  {
    Header: () => <Cell value="Date/time" />,
    accessor: 'createdAt',
    Cell: (props) => <Box>{moment(props.value).format('M/D/YY, h:mma')}</Box>,
    align: 'center',
    width: '40%',
  },
  {
    Header: () => <Cell value="Edited by" />,
    accessor: 'editedBy',
    Cell,
    width: '40%',
  },
  {
    accessor: 'onRestoreVersion',
    Cell: (props) => (
      <Button variant="text" onClick={() => props.value()}>
        Restore
      </Button>
    ),
  },
];

function formatEditor(t: AllocationsTemplate | RestrictionsTemplate): string {
  return t.editedBy?.firstName && t.editedBy?.lastName
    ? `${t.editedBy?.firstName} ${t.editedBy?.lastName}`
    : `${t.user.firstName} ${t.user.lastName}`;
}

export default function RestoreVersionModal({
  open,
  onCloseModal,
  updateStateFromOldVersion,
  dialogProps,
  templates,
}: {
  open: boolean;
  onCloseModal: () => void;
  updateStateFromOldVersion: (oldTemplate: AllocationsTemplate | RestrictionsTemplate) => void;
  templates: (AllocationsTemplate | RestrictionsTemplate)[];
  dialogProps?: DialogProps;
}) {
  const theme = useTheme();
  const tableData = useMemo(() => {
    return templates
      .map(
        (t) =>
          ({
            createdAt: new Date(t.createdAt),
            editedBy: formatEditor(t),
            onRestoreVersion: () => updateStateFromOldVersion(t),
          } as VersionTableData)
      )
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
  }, [templates, updateStateFromOldVersion]);

  const table = useTable<VersionTableData>({
    columns: linkTableColumns,
    data: tableData || [],
  });
  return (
    <Dialog {...dialogProps} open={open} onClose={onCloseModal}>
      <DialogTitle onClose={onCloseModal}>
        <Box>Restore a previous version</Box>
      </DialogTitle>

      <Box
        border={`solid 1px ${theme.palette.grey[300]}`}
        borderRadius="4px"
        overflow="auto"
        mx={3}
        my={4}
      >
        <DataTable m={0} table={table} rowSize="small" tableMaxHeight="540px" />
      </Box>
    </Dialog>
  );
}
