import { datadogRum } from '@datadog/browser-rum';
import { Box, Button, Link as MuiLink, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import ErrorRobot from '~/static/images/illustrations/error-robot.png';

interface SomethingWentWrongProps {
  title?: string;
  description: React.ReactNode;
  disableCta?: boolean;
  ctaText?: string;
  ctaOnClick?: () => void;
  error: unknown;
}

export default function SomethingWentWrong({
  title = 'Something went wrong.',
  description,
  disableCta,
  ctaText = 'Click here to try again.',
  ctaOnClick = () => window.location.reload(),
  error,
}: SomethingWentWrongProps) {
  const history = useHistory();

  useEffect(() => {
    datadogRum.addError(error);
  }, [error]);

  return (
    <Box width="100%" height="100vh" display="flex">
      <Box m="auto" textAlign="center">
        <img src={ErrorRobot} width={340} alt="" />
        <Typography variant="h2" mt={5}>
          {title}
        </Typography>
        <Box color="grey.600" mt={2} mb={1.5}>
          {description}
        </Box>
        {disableCta ? null : (
          <Box mb={6}>
            <MuiLink
              display="inline"
              type="button"
              variant="body2"
              component="button"
              onClick={ctaOnClick}
            >
              {ctaText}
            </MuiLink>
          </Box>
        )}
        <Button
          variant="outlined"
          sx={{ background: 'transparent' }}
          onClick={history.goBack}
          color="primary"
        >
          Go back
        </Button>
        <Box mt={5} color="grey.400">
          <Typography variant="caption">
            Our friendly team is here to help. <br /> Please reach out to{' '}
            <MuiLink href="mailto:clientservice@vise.com">clientservice@vise.com</MuiLink> for help.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
