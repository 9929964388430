import { Box, Typography } from '@mui/material';
import { OptionProps, ValueType, components } from 'react-select';
import React from 'react';
import { Account } from '~/models/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import amplitude from '~/utils/amplitude';
import { maskAccountNumber } from '~/utils/format';
import Select, { SelectProps } from './inputs/Select';

export interface AccountOption {
  account: Account;
  label: string;
  value: string;
}

function AccountOption(props: OptionProps<AccountOption>) {
  const { isSelected } = props;
  const { account }: AccountOption = props.data;
  const typographyProps = isSelected ? null : { color: 'textSecondary' as const };

  return (
    <components.Option {...props}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <strong>
            {account.firstName} {account.lastName}
          </strong>
        </Box>
        <Box>
          <Typography {...typographyProps}>{account.custodianKey}</Typography>
        </Box>
      </Box>
      <Box>
        <Typography {...typographyProps}>
          {account.accountType} {maskAccountNumber(account.accountNumber)}
        </Typography>
      </Box>
    </components.Option>
  );
}

export interface AccountSelectProps extends SelectProps<AccountOption> {
  accounts: Account[] | null;
  onChange: (value: ValueType<AccountOption>) => void;
}

export default function AccountSelect({ accounts, onChange, ...selectProps }: AccountSelectProps) {
  const options = React.useMemo(
    () =>
      accounts == null
        ? []
        : accounts.map((acc) => ({
            account: acc,
            label: `${acc.firstName} ${acc.lastName} — ${acc.accountType} ${maskAccountNumber(
              acc.accountNumber
            )}`,
            value: acc.id,
          })),
    [accounts]
  );

  return (
    <Select<AccountOption>
      noOptionsMessage={() => 'No accounts associated with this client.'}
      components={{ Option: AccountOption }}
      isClearable
      onFocus={() => {
        amplitude().logEvent('Tap account drop down', {
          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        });
      }}
      onChange={onChange}
      options={options}
      placeholder="Select an account…"
      {...selectProps}
    />
  );
}
