import React from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Box } from '@mui/material';

export interface CoachmarkProps extends Omit<AlertProps, 'color' | 'variant'> {
  title: string;
  onClose?: (event: React.SyntheticEvent) => void;
  content?: string;
}

const Coachmark = React.forwardRef<unknown, CoachmarkProps>(function Coachmark({
  title,
  content,
  onClose,
  severity,
  ...props
}: CoachmarkProps) {
  return (
    <Alert
      ref={props.ref}
      onClose={onClose}
      severity={severity}
      variant={severity === 'error' ? 'standard' : 'filled'}
      {...props}
    >
      <AlertTitle>{title}</AlertTitle>
      {content && <Box mt={1}>{content}</Box>}
    </Alert>
  );
});

export default Coachmark;
