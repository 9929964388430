import { Box, Button, Divider, Typography } from '@mui/material';
import { isEqual, uniq } from 'lodash';
import React, { useMemo, useReducer, useState } from 'react';
import { RestrictionsTemplate } from 'vise-types/template';
import { Country } from 'vise-types/pce2_instrument';
import { useHistory } from 'react-router';
import CountriesSelector from '~/routes/PortfolioCreator2/screens/components/CountriesSelector';
import EsgSelector from '~/routes/PortfolioCreator2/screens/components/EsgSelector';
import SectorsSelector from '~/routes/PortfolioCreator2/screens/components/SectorsSelector';
import InstrumentsSelector from '~/routes/PortfolioCreator2/screens/components/InstrumentsSelector';
import { ReactComponent as QuestionMarkCircleIcon } from '~/static/images/icons/question-mark-circle.svg';
import { useIndexedInstruments } from '~/hooks/useInstruments';
import { useIndexedSectors } from '~/hooks/useSectors';
import { editTemplate, insertRestrictionsTemplate } from '~/api/api';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useUser from '~/hooks/useUser';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import useAllRestrictionsTemplates from '~/hooks/templates/useAllRestrictionsTemplates';
import ActionBar from './ActionBar';
import Card from './Card';
import Container from './Container';
import Stepper, { Step } from './Stepper';
import NameInput from './NameInput';
import RestrictionHelpModal from './RestrictionHelpModal';
import UpdatedTitle from './UpdatedTitle';
import { scrollToTop } from './CommonComponents';
import { STEPPER_WIDTH } from './constants';
import RestoreVersionModalOpen from './RestoreVersionModalOpen';
import RestrictionsTemplateCard from './RestrictionsTemplateCard';

export function compareStringArrays(arr1: string[], arr2: string[]) {
  const set2 = new Set(arr2);
  return arr1.length === arr2.length && arr1.every((element) => set2.has(element));
}

interface StateT {
  countries: string[];
  tickers: string[];
  sectors: string[];
  industries: string[];
  esgAreas: string[];
  step: 'EDIT' | 'REVIEW' | 'EDIT_LANDING';
  name: string;
  nameIsFree?: boolean;
  originalTemplate?: RestrictionsTemplate;
  saving: boolean;
}

type ActionT =
  | {
      type: 'SELECT_INSTRUMENTS';
      instruments: string[];
    }
  | {
      type: 'REMOVE_INSTRUMENTS';
      instruments: string[];
    }
  | {
      type: 'CHANGE_SECTORS_AND_INDUSTRIES';
      sectorsToAdd: string[];
      sectorsToRemove: string[];
      industriesToAdd: string[];
      industriesToRemove: string[];
    }
  | {
      type: 'SELECT_COUNTRIES';
      countries: string[];
    }
  | {
      type: 'REMOVE_COUNTRIES';
      countries: string[];
    }
  | {
      type: 'SELECT_ESG_AREAS';
      esgAreasToAdd: string[];
      esgAreasToRemove: string[];
    }
  | {
      type: 'CONTINUE';
    }
  | {
      type: 'BACK';
    }
  | {
      type: 'CHANGE_NAME';
      formValue: string;
    }
  | {
      type: 'NAME_IS_FREE';
      free?: boolean;
    }
  | {
      type: 'RENAME_TEMPLATE_SUCCESS';
      name: string;
    }
  | {
      type: 'SAVE';
    }
  | {
      type: 'SAVE_FAILURE';
    }
  | {
      type: 'LOAD_OLD_TEMPLATE';
      template: RestrictionsTemplate;
    };

function stateFromTemplate(template: RestrictionsTemplate): StateT {
  return {
    tickers: template.tickers,
    sectors: template.sectors,
    industries: template.subSectors,
    countries: template.countries as string[],
    esgAreas: template.esgAreas,
    nameIsFree: undefined,
    name: template.name,
    originalTemplate: template,
    step: 'EDIT_LANDING',
    saving: false,
  };
}

function reducer(state: StateT, action: ActionT): StateT {
  switch (action.type) {
    case 'SELECT_INSTRUMENTS': {
      return {
        ...state,
        tickers: action.instruments,
      };
    }
    case 'REMOVE_INSTRUMENTS': {
      const removeSet = new Set(action.instruments);
      return {
        ...state,
        tickers: state.tickers.filter((t) => !removeSet.has(t)),
      };
    }
    case 'CHANGE_SECTORS_AND_INDUSTRIES': {
      const removedSectorsSet = new Set(action.sectorsToRemove);
      const removedIndustriesSet = new Set(action.industriesToRemove);
      return {
        ...state,
        sectors: uniq([...state.sectors, ...action.sectorsToAdd]).filter(
          (s) => !removedSectorsSet.has(s)
        ),
        industries: uniq([...state.industries, ...action.industriesToAdd]).filter(
          (i) => !removedIndustriesSet.has(i)
        ),
      };
    }
    case 'CONTINUE': {
      return {
        ...state,
        step: 'REVIEW',
      };
    }
    case 'BACK': {
      return {
        ...state,
        step: 'EDIT',
      };
    }
    case 'SELECT_COUNTRIES': {
      return {
        ...state,
        countries: action.countries,
      };
    }
    case 'REMOVE_COUNTRIES': {
      const removeSet = new Set(action.countries);
      return {
        ...state,
        countries: state.countries.filter((c) => !removeSet.has(c)),
      };
    }
    case 'SELECT_ESG_AREAS': {
      const removeSet = new Set(action.esgAreasToRemove);
      return {
        ...state,
        esgAreas: uniq([...state.esgAreas, ...action.esgAreasToAdd]).filter(
          (e) => !removeSet.has(e)
        ),
      };
    }
    case 'CHANGE_NAME': {
      return {
        ...state,
        name: action.formValue,
      };
    }
    case 'NAME_IS_FREE': {
      return {
        ...state,
        nameIsFree: action.free,
      };
    }
    case 'RENAME_TEMPLATE_SUCCESS': {
      if (!state.originalTemplate?.name) {
        return state;
      }
      return {
        ...state,
        nameIsFree: undefined,
        originalTemplate: {
          ...state.originalTemplate,
          name: action.name,
        },
      };
    }
    case 'SAVE': {
      return {
        ...state,
        saving: true,
      };
    }
    case 'SAVE_FAILURE': {
      return {
        ...state,
        saving: false,
      };
    }
    case 'LOAD_OLD_TEMPLATE': {
      return {
        ...stateFromTemplate(action.template),
        name: state.name,
        nameIsFree: state.nameIsFree,
        originalTemplate: state.originalTemplate,
        saving: state.saving,
        step: state.step,
      };
    }
    default:
      return state;
  }
}

const initialState: StateT = {
  tickers: [],
  sectors: [],
  industries: [],
  countries: [],
  esgAreas: [],
  step: 'EDIT',
  nameIsFree: undefined,
  name: '',
  saving: false,
};

// to determine if user has any unsubmitted changes
function equalStates(a: Partial<StateT>, b: Partial<StateT>): boolean {
  return (
    isEqual(a.tickers, b.tickers) &&
    isEqual(a.sectors, b.sectors) &&
    isEqual(a.industries, b.industries) &&
    isEqual(a.countries, b.countries) &&
    isEqual(a.esgAreas, b.esgAreas)
  );
}

export default function RestrictionTemplateCreate({
  template,
}: {
  template?: RestrictionsTemplate;
}) {
  const [state, dispatch] = useReducer(
    reducer,
    template ? stateFromTemplate(template) : initialState
  );

  const confirmExit = useMemo(
    () =>
      template
        ? !equalStates(state, stateFromTemplate(template))
        : !equalStates(state, initialState),
    [state, template]
  );
  const nextStep = () => {
    dispatch({ type: 'CONTINUE' });
    scrollToTop();
  };
  const goBack = () => {
    dispatch({ type: 'BACK' });
    scrollToTop();
  };
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [restoreVersionModalOpen, setRestoreVersionModalOpen] = useState<boolean>(false);
  const { data: allTemplates } = useAllRestrictionsTemplates(true);
  const staleTemplates = useMemo(
    () =>
      allTemplates?.filter(
        (t) => t.originalTemplateId === template?.originalTemplateId && template.id !== t.id
      ),
    [allTemplates, template?.id, template?.originalTemplateId]
  );

  const enqueueCoachmark = useEnqueueCoachmark();
  const {
    step,
    tickers,
    sectors,
    industries,
    countries,
    esgAreas,
    name,
    nameIsFree,
    originalTemplate,
  } = state;
  const history = useHistory();
  const { data: instruments } = useIndexedInstruments();
  const { data: sectorInformation } = useIndexedSectors();
  const { data: user } = useUser();
  // Either we are editing a template so we keep the name the same, or we are creating a template so we must pick a name not in use
  const nameIsValid =
    name &&
    ((originalTemplate && name === originalTemplate.name) || (!originalTemplate && nameIsFree));
  const isValid =
    tickers.length || sectors.length || industries.length || countries.length || esgAreas.length;

  let actionBar: React.ReactNode | undefined;
  const restoreVersionButton =
    staleTemplates && staleTemplates.length > 0 && template ? (
      <Button variant="text" color="primary" onClick={() => setRestoreVersionModalOpen(true)}>
        Restore previous version
      </Button>
    ) : (
      <></>
    );
  if (step === 'EDIT') {
    actionBar = (
      <ActionBar
        showBackButton={false}
        continueButtonProps={{
          disabled: !isValid,
          onClick: () => {
            nextStep();
          },
        }}
      />
    );
  } else if (step === 'REVIEW') {
    actionBar = (
      <ActionBar
        showBackButton
        backButtonProps={{ onClick: () => goBack() }}
        continueButtonProps={{
          disabled: !isValid || !user || !nameIsValid,
          onClick: async () => {
            const body = {
              name,
              orgId: user?.organizationId || '',
              userId: user?.id || '',
              tickers,
              subSectors: industries,
              sectors,
              countries: countries as Country[],
              esgAreas,
            };
            dispatch({ type: 'SAVE' });
            try {
              if (originalTemplate) {
                const { data } = await editTemplate({
                  templateId: originalTemplate.parentId,
                  templateBody: body,
                });
                if (data && data.templateId) {
                  history.push(
                    `/secure/strategy-center?editTemplateId=${data.templateId}&templateType=restrictions`
                  );
                }
              } else {
                const template = await insertRestrictionsTemplate(body);
                history.push(
                  `/secure/strategy-center?createTemplateId=${template.parentId}&templateType=restrictions`
                );
              }
            } catch (e) {
              dispatch({ type: 'SAVE_FAILURE' });
              enqueueCoachmark({
                title: 'Failed to save template, please try again later',
                severity: 'error',
              });
            }
          },
          children: 'Save template',
        }}
      />
    );
  }
  return (
    <Container
      templateType="restrictions"
      footer={actionBar}
      headerBarContent={{
        left: (
          <Box color="text.secondary">
            <PathBreadcrumbs
              path={[
                {
                  name: 'Strategy Center',
                  to: '/secure/strategy-center?templateType=restrictions',
                },
                {
                  name: template?.name || 'New restrictions template',
                },
              ]}
              ariaLabel="Restrictions template creation breadcrumbs"
            />
          </Box>
        ),
        right: restoreVersionButton,
      }}
      getExitConfirmation={confirmExit}
    >
      <RestrictionHelpModal open={helpModalOpen} onClose={() => setHelpModalOpen(false)} />
      {staleTemplates && staleTemplates.length > 0 && template && (
        <RestoreVersionModalOpen
          open={restoreVersionModalOpen}
          onCloseModal={() => setRestoreVersionModalOpen(false)}
          updateStateFromOldVersion={(template) => {
            if (template.type !== 'restrictions') {
              return;
            }
            dispatch({
              type: 'LOAD_OLD_TEMPLATE',
              template,
            });

            setRestoreVersionModalOpen(false);
            nextStep();
          }}
          templates={staleTemplates}
        />
      )}
      {step !== 'EDIT_LANDING' && (
        <Box mr={2} width="100%" maxWidth={STEPPER_WIDTH}>
          <Stepper>
            <Step variant={step === 'EDIT' ? 'ACTIVE' : undefined}>Edit details</Step>
            <Step variant="DISABLED">Review & save</Step>
          </Stepper>
        </Box>
      )}
      {step === 'EDIT' && (
        <Card>
          <>
            <Typography variant="h6" color="textSecondary">
              STEP 1
            </Typography>
            <Box mt={1} mb={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="h2">Select restrictions</Typography>
                <Box mx={2} height="34px">
                  <Divider orientation="vertical" />
                </Box>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<QuestionMarkCircleIcon />}
                  onClick={() => setHelpModalOpen(true)}
                >
                  Help me choose restrictions
                </Button>
              </Box>
            </Box>
            <Box mb={4}>
              <InstrumentsSelector
                chipValues={tickers}
                onChange={(stocks) =>
                  dispatch({ type: 'SELECT_INSTRUMENTS', instruments: stocks.map((s) => s.value) })
                }
                onRemove={(stock) => dispatch({ type: 'REMOVE_INSTRUMENTS', instruments: [stock] })}
                value={tickers}
              />
            </Box>
            <Box mb={5}>
              <SectorsSelector
                chipsSectors={sectors}
                chipsIndustries={industries}
                excludedSectors={sectors}
                excludedIndustries={industries}
                onChange={(industriesToAdd, industriesToRemove, sectorsToAdd, sectorsToRemove) =>
                  dispatch({
                    type: 'CHANGE_SECTORS_AND_INDUSTRIES',
                    industriesToAdd: industriesToAdd || [],
                    industriesToRemove: industriesToRemove || [],
                    sectorsToAdd: sectorsToAdd || [],
                    sectorsToRemove: sectorsToRemove || [],
                  })
                }
              />
            </Box>
            <Box mb={5}>
              <CountriesSelector
                value={countries as Country[]}
                onChange={(countries) =>
                  dispatch({ type: 'SELECT_COUNTRIES', countries: countries.map((c) => c.value) })
                }
                onRemove={(country) => dispatch({ type: 'REMOVE_COUNTRIES', countries: [country] })}
              />
            </Box>
            <Box mb={5}>
              <EsgSelector
                excludedProductAreas={esgAreas}
                onChange={(esgAreasToAdd, esgAreasToRemove) =>
                  dispatch({
                    type: 'SELECT_ESG_AREAS',
                    esgAreasToAdd: esgAreasToAdd || [],
                    esgAreasToRemove: esgAreasToRemove || [],
                  })
                }
              />
            </Box>
          </>
        </Card>
      )}
      {(step === 'REVIEW' || step === 'EDIT_LANDING') && (
        <Box width="100%">
          {step === 'REVIEW' && state.originalTemplate ? (
            <Card>
              <Typography variant="h6" color="textSecondary">
                STEP 3
              </Typography>
              <Box mt={1} mb={1.5}>
                <Typography variant="h2">Review & save template</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                Your changes have been applied – review the details of this template below before
                saving.
              </Typography>
            </Card>
          ) : (
            <NameInput
              name={state.name}
              nameIsFree={state.nameIsFree}
              onChange={(val) => dispatch({ type: 'CHANGE_NAME', formValue: val })}
              onCheckTemplateName={(free) => dispatch({ type: 'NAME_IS_FREE', free })}
              templateType="RESTRICTION"
              placeholder="Ex. Environmental ESG"
              editMode={!!state.originalTemplate}
              originalName={state.originalTemplate?.name}
              originalId={state.originalTemplate?.id}
              onRenameTemplateSuccess={(name) =>
                dispatch({ type: 'RENAME_TEMPLATE_SUCCESS', name })
              }
            />
          )}
          <Box mt={3}>
            <RestrictionsTemplateCard
              onEdit={() => goBack()}
              tickersTitle={
                !originalTemplate ||
                compareStringArrays([...originalTemplate.tickers], [...tickers]) ? (
                  'Tickers'
                ) : (
                  <UpdatedTitle title="Tickers" />
                )
              }
              tickers={tickers}
              instruments={instruments}
              sectorsTitle={
                !originalTemplate ||
                compareStringArrays(
                  [...originalTemplate.subSectors, ...originalTemplate.sectors],
                  [...industries, ...sectors]
                ) ? (
                  'Sectors'
                ) : (
                  <UpdatedTitle title="Sectors" />
                )
              }
              sectors={sectors}
              industries={industries}
              sectorInformation={sectorInformation}
              countriesTitle={
                !originalTemplate ||
                compareStringArrays([...originalTemplate.countries], [...countries]) ? (
                  'Countries'
                ) : (
                  <UpdatedTitle title="Countries" />
                )
              }
              countries={countries}
              esgTitle={
                !originalTemplate ||
                compareStringArrays([...originalTemplate.esgAreas], [...esgAreas]) ? (
                  'Values'
                ) : (
                  <UpdatedTitle title="Values" />
                )
              }
              esgAreas={esgAreas}
            />
          </Box>
        </Box>
      )}
    </Container>
  );
}
