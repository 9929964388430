import * as Sentry from '@sentry/react';
import { get, isEmpty } from 'lodash';
import { ActiveTilt, ConcentrationLimit, Restrictions } from 'vise-types/pce1';
import { AssetClassKey, Feature } from 'vise-types/pce2_instrument';
import { AccountSize, Position } from 'vise-types/portfolio';
import { StrategyVersion } from 'vise-types/saved_strategy';
import { AllocationsTemplate, TiltType } from 'vise-types/template';
import { Sector, Subsector } from '~/models/api';
import { Treatments } from '~/models/featureFlag';
import { getAssetClassFeaturesFromKey, getAssetClassKeyFromFeatures } from '~/utils/pce2Migration';
import {
  ACTIVE_TILT_TYPE_TO_LABEL,
  ASSET_CLASSES_WITH_VISE_SINGLE_SECURITY_STRATEGY_TO_LABEL_MAP,
  ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP,
  ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP_SMALL,
  ASSET_CLASS_LEAVES,
  ASSET_CLASS_LEAVES_SMALL,
  ASSET_CLASS_TO_LABEL_MAP,
  ASSET_CLASS_TREES,
  DEVELOPED_ASSET_CLASS_KEYS,
  EQUITIES_ASSET_CLASS_KEY,
  FIXED_INCOME_ASSET_CLASS_KEY,
  NUMBER_OF_SINGLE_SECURITY_ASSET_CLASSES,
  SINGLE_SECURITY_THRESHOLD,
} from './Constants';
import {
  AssetClassTreeNode,
  ConstructionInfo,
  DraftPortfolio,
  Pce2ConstructionInfo,
  RestrictionValidationStatus,
  Screen,
} from './Types';

// Prereqs are Array<Array<Prereq>>. The outer array is a logical OR of its elements, and the inner
// arrays are logical ANDs of their elements.
//
// For example:
//
// prereqsFulfilled([[false], [true, true, true]])
// -> true
//
// prereqsFulfilled([[false], [false, true, true]])
// -> false

export const prereqsFulfilled = (
  screen: Screen,
  options: { draftPortfolio: DraftPortfolio; searchPid: string | null; featureFlags?: Treatments }
): boolean =>
  screen.prereqs.length === 0 ||
  screen.prereqs.some((prereqs) =>
    prereqs.every((prereq) =>
      typeof prereq === 'function' ? prereq(options) : get(options.draftPortfolio, prereq) !== null
    )
  );

export const strategyToPartialConstructionInfo = (
  strategy: StrategyVersion
): Partial<ConstructionInfo> => {
  return {
    assetClasses: strategy.assetClasses || [],
    assetClassConcentrationLimits:
      // Disambiguate between Strategy versions to prevent need for `any`
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      strategy.pceVersion === 'pce2' ? strategy.assetClassConcentrationLimits : ({} as any),
    excludedCountries: strategy.excludedCountries || [],
    excludedIndustries: strategy.excludedIndustries || [],
    excludedSectors: strategy.excludedSectors || [],
    overlay: strategy.tilt,
    etfExclusive: strategy.etfExclusive,
    restrictedStocks: strategy.restrictedStocks || [],
    portfolioMaxSize: strategy.portfolioMaxSize,
    tSliceEnabled: strategy.tSliceEnabled,
    etfExclusiveAssetClasses: strategy.etfAssetClasses,
    minSymbolAssetClasses: strategy.minSymbolAssetClasses,
  };
};

// Flips an asset class' toggled state in given exclusions set if all its descendents have the opposite
// toggled state.
const flipAssetClassIfAllDescendentsAreFlipped = (
  node: AssetClassTreeNode,
  curFeatureSet: Feature[],
  // This will get mutated:
  exclusions: Set<AssetClassKey>,
  removeExclusionIfOneChildOn: boolean
) => {
  if (!node.children || node.children.length === 0) {
    return;
  }

  const assetClassKey = getAssetClassKeyFromFeatures(curFeatureSet);
  const assetClassIsExcluded = exclusions.has(assetClassKey);

  let allChildrenFlipped = true;
  let oneChildOn = false;
  node.children.forEach((child) => {
    const childFeatureSet = [...curFeatureSet, child.feature];
    flipAssetClassIfAllDescendentsAreFlipped(
      child,
      childFeatureSet,
      exclusions,
      removeExclusionIfOneChildOn
    );

    // We checked for the flipped toggled state of all its children.
    allChildrenFlipped &&=
      exclusions.has(getAssetClassKeyFromFeatures(childFeatureSet)) !== assetClassIsExcluded;
    oneChildOn ||= !exclusions.has(getAssetClassKeyFromFeatures(childFeatureSet));
  });

  if ((allChildrenFlipped || (oneChildOn && removeExclusionIfOneChildOn)) && assetClassIsExcluded) {
    exclusions.delete(assetClassKey);
  } else if (allChildrenFlipped) {
    exclusions.add(assetClassKey);
  }
};

/** Generates updated exclusions set that can be used to hydrate the asset class tree UI. Mutates exclusions. */
export const flipAllAssetClassesWhereApplicable = (
  exclusions: Set<AssetClassKey>,
  removeExclusionIfOneChildOn?: boolean
) => {
  ASSET_CLASS_TREES.forEach((child) =>
    flipAssetClassIfAllDescendentsAreFlipped(
      child,
      [child.feature],
      exclusions,
      !!removeExclusionIfOneChildOn
    )
  );
  return exclusions;
};

export const stripNonLeavesFromAssetClassExclusions = (
  exclusions: AssetClassKey[],
  { accountSize }
) => {
  const assetClassLeaves = accountSize === 'SMALL' ? ASSET_CLASS_LEAVES_SMALL : ASSET_CLASS_LEAVES;
  return exclusions.filter((excludedAssetClassKey) => assetClassLeaves.has(excludedAssetClassKey));
};

export const shouldShowCapitalLossesAndGainsScreen = (draftPortfolio: DraftPortfolio): boolean =>
  draftPortfolio.constructionInfo.existingPortfolio !== 'sample-portfolio' &&
  Boolean(draftPortfolio.constructionInfo.existingPortfolio?.taxable);

export function isSmallAccount(accountSize?: AccountSize) {
  return accountSize === 'TINY' || accountSize === 'SMALL';
}

export const shouldSkipRestrictions = ({
  etfExclusive,
  exclusions,
  accountSize,
}: {
  etfExclusive: ConstructionInfo['etfExclusive'];
  exclusions?: AssetClassKey[] | null;
  accountSize?: AccountSize;
}) => {
  if (isSmallAccount(accountSize)) {
    return true;
  }
  if (etfExclusive) {
    return true;
  }

  if (!exclusions) {
    return false;
  }

  return Array.from(ASSET_CLASSES_WITH_VISE_SINGLE_SECURITY_STRATEGY_TO_LABEL_MAP.keys()).every(
    (assetClass) => exclusions.includes(assetClass)
  );
};

export function formatPce1CashConcentrationLimit(
  cashConcentrationLimit: ConcentrationLimit | undefined
): string {
  return cashConcentrationLimit == null
    ? ''
    : (((cashConcentrationLimit.max + cashConcentrationLimit.min) / 2) * 100).toFixed(1);
}

export function groupExcludedIndustries({
  excludedIndustriesSet,
  sectors,
}: {
  excludedIndustriesSet: Set<string>;
  sectors: Sector[];
}) {
  const processedRestrictions = [] as { sector: string; industries: string[] }[];

  if (excludedIndustriesSet.size === 0) {
    return [];
  }
  sectors.forEach((sector) => {
    const industryChildren = sector.industries.reduce((acc: string[], industry: Subsector) => {
      if (excludedIndustriesSet.has(industry.key)) {
        acc.push(industry.name);
      }
      return acc;
    }, []);
    if (industryChildren.length > 0) {
      processedRestrictions.push({ sector: sector.name, industries: industryChildren });
    }
  });
  return processedRestrictions;
}

export function getExcludedSectorsNames({
  excludedSectorsSet,
  sectors,
}: {
  excludedSectorsSet: Set<string>;
  sectors: Sector[];
}) {
  const excludedSectorsNames = [] as string[];
  if (excludedSectorsSet.size === 0) {
    return [];
  }
  sectors.forEach((sector) => {
    if (excludedSectorsSet.has(sector.key)) {
      excludedSectorsNames.push(sector.name);
    }
  });
  return excludedSectorsNames;
}

export function allowConcentrationLimits(draftPortfolio: DraftPortfolio): boolean {
  if (draftPortfolio?.constructionInfo?.focus === 'LOSS_TOLERANCE') {
    return true;
  }

  return false;
}

/*
  Gets the mapping of the top-level asset class key to an array of toggled descendent leaf asset
  class keys for a particular asset class tree.

  Examples of root tree node are EQUITY, FIXED_INCOME, etc., though this would theoretically work
  for any subtree.

  Top-level asset class is one level below the root and we map it to every one of its descendents
  that are both a leaf and are not excluded. This mapping is particularly useful for display
  purposes.
*/
export function getToggledAssetClassLeavesInAssetClassTree(
  rootTreeNode: AssetClassTreeNode,
  exclusions: AssetClassKey[],
  smallAccount: boolean
): Map<AssetClassKey, AssetClassKey[] | null> {
  const { children } = rootTreeNode;
  if (children == null) return new Map();

  const excludedSet = new Set(exclusions);
  const assetClassLeaves = smallAccount ? ASSET_CLASS_LEAVES_SMALL : ASSET_CLASS_LEAVES;
  const assetClassKeyToDescendentsKeyMap = smallAccount
    ? ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP_SMALL
    : ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP;

  return children.reduce((toggledAssetClassMap, topLevelAssetClassNode) => {
    const topLevelAssetClassFeatures = [rootTreeNode.feature, topLevelAssetClassNode.feature];
    const topLevelAssetClassKey = getAssetClassKeyFromFeatures(topLevelAssetClassFeatures);
    const isAssetClassLeaf = assetClassLeaves.has(topLevelAssetClassKey);

    // We make a special case for top level asset classes that are also a leaf node.
    // In this case, we set an entry for it and map it to null, to differentiate it
    // from a mapping to an empty array (which signifies a non-leaf top level asset class
    // with no toggled children).
    if (isAssetClassLeaf && !excludedSet.has(topLevelAssetClassKey)) {
      toggledAssetClassMap.set(topLevelAssetClassKey, null);
    } else if (!isAssetClassLeaf) {
      // A toggled leaf asset class key is just any leaf asset class that is not excluded.
      const toggledLeafKeys = assetClassKeyToDescendentsKeyMap
        .get(topLevelAssetClassKey)
        ?.filter((key) => assetClassLeaves.has(key) && !excludedSet.has(key));

      if (toggledLeafKeys != null && toggledLeafKeys.length > 0) {
        toggledAssetClassMap.set(topLevelAssetClassKey, toggledLeafKeys);
      }
    }

    return toggledAssetClassMap;
  }, new Map<AssetClassKey, AssetClassKey[] | null>());
}

/*
  Returns a comma-separated label derived from the difference between the topLevelAssetClassKey
  and the descendentLeafAssetClassKey.
  E.g. topLevelAssetClassKey = EQUITY/US; descendentLeafAssetClassKey = EQUITY/US/LARGE_CAP
  This would return the label for LARGE_CAP.
*/
export function getToggledAssetClassLeafLabel(
  topLevelAssetClassKey: AssetClassKey,
  descendentLeafAssetClassKey: AssetClassKey
): string {
  const topLevelAssetClassFeatures = getAssetClassFeaturesFromKey(topLevelAssetClassKey);
  const leafFeatures = getAssetClassFeaturesFromKey(descendentLeafAssetClassKey);

  if (
    leafFeatures.length <= topLevelAssetClassFeatures.length ||
    topLevelAssetClassFeatures.every((topLevelFeature, i) => topLevelFeature !== leafFeatures[i])
  ) {
    Sentry.captureMessage(
      `Leaf asset class ${descendentLeafAssetClassKey} is not a descendent of ${topLevelAssetClassKey}`
    );

    // Return empty string
    return '';
  }

  return leafFeatures
    .slice(topLevelAssetClassFeatures.length)
    .map((feature, featureIndex) => {
      const label = ASSET_CLASS_TO_LABEL_MAP.get(feature);
      if (label == null) return '';
      if (featureIndex === 0) {
        return label;
      }
      return label.toLowerCase();
    })
    .join(' — ');
}

export function getAllAssetClassTreeSections(
  primaryAssetClassTreeRoot: AssetClassTreeNode,
  exclusions: AssetClassKey[],
  smallAccount: boolean
): [string, string[] | undefined][] {
  const toggledAssetClassMap = getToggledAssetClassLeavesInAssetClassTree(
    primaryAssetClassTreeRoot,
    exclusions,
    smallAccount
  );
  const assetClassTreeSections: [string, string[] | undefined][] = [];
  toggledAssetClassMap.forEach((toggledDescendentKeys, topLevelAssetClassKey) => {
    const topLevelAssetClassFeatures = getAssetClassFeaturesFromKey(topLevelAssetClassKey);
    const topLevelAssetClassLabel = ASSET_CLASS_TO_LABEL_MAP.get(
      topLevelAssetClassFeatures[topLevelAssetClassFeatures.length - 1]
    );
    if (topLevelAssetClassLabel != null) {
      assetClassTreeSections.push([
        topLevelAssetClassLabel,
        toggledDescendentKeys?.map((key) =>
          getToggledAssetClassLeafLabel(topLevelAssetClassKey, key)
        ),
      ]);
    }
  });
  return assetClassTreeSections;
}

export function shouldShowExcludeCountries(
  assetClassExclusions: AssetClassKey[] | null | undefined
): boolean {
  const assetClassExclusionSet = new Set(assetClassExclusions);
  return !DEVELOPED_ASSET_CLASS_KEYS.every((k) => assetClassExclusionSet.has(k));
}

export function getNumQuestions(draftPortfolio: DraftPortfolio): number {
  return !shouldShowExcludeCountries(
    (draftPortfolio.constructionInfo as Pce2ConstructionInfo).assetClassConcentrationLimits
      ?.exclusions
  )
    ? 5
    : 6;
}

export function assetClassHasInvalidExclusions(
  assetClassKey: AssetClassKey,
  exclusions: AssetClassKey[],
  riskSliderValue: number
): boolean {
  const isEquities = assetClassKey === EQUITIES_ASSET_CLASS_KEY;
  const isFixedIncome = assetClassKey === FIXED_INCOME_ASSET_CLASS_KEY;
  if (!isEquities && !isFixedIncome) return false;
  const exclusionsSet = new Set(exclusions);
  const assetClassLeaves = [...ASSET_CLASS_LEAVES].filter((a) => a.startsWith(assetClassKey));
  return (
    assetClassLeaves.every((e) => exclusionsSet.has(e)) &&
    riskSliderValue === (isEquities ? 100 : 0)
  );
}

export function isRestrictionValidationStatusBlocking(
  validationStatus: RestrictionValidationStatus | null
) {
  return validationStatus === 'INVALID' || validationStatus === 'LOADING';
}

export function isRestrictionsQuestionnaireValid(
  questionnaireData: Partial<Restrictions> | undefined,
  validationStatus: RestrictionValidationStatus | null
) {
  const isValidQuestionnaire =
    !isEmpty(questionnaireData?.excludedCountries) ||
    !isEmpty(questionnaireData?.excludedIndustries) ||
    !isEmpty(questionnaireData?.excludedSectors) ||
    !isEmpty(questionnaireData?.restrictedStocks);

  return !isRestrictionValidationStatusBlocking(validationStatus) && isValidQuestionnaire;
}

export function getDefaultTiltAmountFromRisk(risk: number) {
  return Math.floor(risk / 10);
}

export function shouldShowActiveTiltsScreen({
  accountSize,
  etfExclusive,
  exclusions,
  focus,
  allocationTemplate,
}: {
  focus: ConstructionInfo['focus'];
  etfExclusive: ConstructionInfo['etfExclusive'];
  exclusions?: AssetClassKey[] | null;
  accountSize?: AccountSize;
  allocationTemplate: AllocationsTemplate | null;
}) {
  if (allocationTemplate?.tiltSelection) {
    return false;
  }
  if (isSmallAccount(accountSize)) {
    return false;
  }

  if (etfExclusive || focus !== 'LOSS_TOLERANCE' || !exclusions) {
    return false;
  }

  const exclusionsSet = new Set(exclusions);

  return !Array.from(ASSET_CLASSES_WITH_VISE_SINGLE_SECURITY_STRATEGY_TO_LABEL_MAP.keys()).every(
    (singleStrategyAssetClassKey) => exclusionsSet.has(singleStrategyAssetClassKey)
  );
}

export function getActiveTiltsSelection(activeTilt: ConstructionInfo['activeTilt']): string | null {
  if (!activeTilt) {
    return null;
  }

  if (!activeTilt.isEnabled) {
    return 'Disabled';
  }

  return ACTIVE_TILT_TYPE_TO_LABEL[activeTilt.tiltType];
}

export function getActiveTiltAmountText(
  activeTilt: ConstructionInfo['activeTilt'],
  risk: ConstructionInfo['risk']
): string | null {
  if (!activeTilt || !risk || !activeTilt.isEnabled) {
    return null;
  }

  const defaultTiltAmount = getDefaultTiltAmountFromRisk(risk);
  const tiltAmount = activeTilt.tiltAmount ?? defaultTiltAmount;

  return `${tiltAmount}/10${tiltAmount === defaultTiltAmount ? ' (recommended)' : ''}`;
}

// TODO: do this in the backend instead
// pass initial value into account size endpoint instead of account id
export function getSampleProposalAccountSize(
  initialValue?: number | null,
  targetCashFraction?: number
): AccountSize {
  if (initialValue == null || targetCashFraction == null) {
    return 'UNKNOWN';
  }
  const accountValue = initialValue * (1 - targetCashFraction * 0.01);
  if (accountValue >= 5000) {
    return 'FULL';
  }
  if (accountValue >= 400) {
    return 'SMALL';
  }
  return 'TINY';
}

export type ActiveTiltForDisplay =
  | { portfolioType: 'ETF_EXCLUSIVE' }
  | { portfolioType: 'TARGET_VALUE' }
  | { portfolioType: 'LOSS_TOLERANCE'; activeTilt: ActiveTilt };

export function computeActiveTiltForDisplay(
  activeTiltAmount: number | undefined | null,
  tiltType: TiltType | undefined | null,
  risk: number,
  isEtfExclusive: boolean,
  isSmallAccount: boolean | undefined,
  tiltDisabledInInputs: boolean
): ActiveTiltForDisplay {
  if (isEtfExclusive || isSmallAccount) {
    return {
      portfolioType: 'ETF_EXCLUSIVE',
    };
  }

  const defaultTiltAmount = getDefaultTiltAmountFromRisk(risk);
  const computedTiltAmount = activeTiltAmount != null ? activeTiltAmount : defaultTiltAmount;
  const activeTilt: ActiveTilt = {
    tiltAmount: computedTiltAmount,
    tiltType: tiltType || 'multi-factor',
    isEnabled: !tiltDisabledInInputs && computedTiltAmount > 0,
  };
  return {
    portfolioType: 'LOSS_TOLERANCE',
    activeTilt,
  };
}

export function assetClassIsAboveSingleSecurityThreshold(
  key: AssetClassKey,
  allocations: { [key in AssetClassKey]?: number },
  accountValue: number
) {
  const allocation = allocations[key];
  if (allocation == null) {
    return true;
  }
  return allocation * accountValue >= SINGLE_SECURITY_THRESHOLD;
}

export function showTickerNumberScreen(
  accountSize: AccountSize | undefined,
  exclusions: AssetClassKey[] | undefined | null
) {
  return (
    !isSmallAccount(accountSize) &&
    (exclusions == null ||
      exclusions.filter((val) =>
        ASSET_CLASSES_WITH_VISE_SINGLE_SECURITY_STRATEGY_TO_LABEL_MAP.get(val)
      ).length < NUMBER_OF_SINGLE_SECURITY_ASSET_CLASSES)
  );
}

export function fillDefaultsInPartialConstructionInfo(
  constructionInfo: ConstructionInfo
): ConstructionInfo {
  return {
    ...constructionInfo,
    excludedSectors: constructionInfo.excludedSectors ?? [],
  };
}

export type AssetClassTreeNodeWithPercentage = {
  assetClassKey: AssetClassKey;
  children?: AssetClassTreeNodeWithPercentage[];
  percentage?: string;
};

function computeAllocationPercentagesForAllocationsTree(
  root: AssetClassTreeNodeWithPercentage | undefined,
  allocations: AllocationsTemplate['allocations']
): number {
  if (root == null) {
    return 0;
  }
  const allocationAmountFromChildren = root.children?.reduce(
    (sum, nextChild) =>
      sum + computeAllocationPercentagesForAllocationsTree(nextChild, allocations),
    0
  );
  let allocationAmount: number;
  if (allocations[root.assetClassKey] != null) {
    // Convert to number just in case the JSON conversion didn't type correctly and it's a string:
    const allocationAmountAsNumber = parseFloat((allocations[root.assetClassKey] ?? 0).toString());
    allocationAmount = allocationAmountAsNumber;
  } else {
    allocationAmount = allocationAmountFromChildren ?? 0;
  }
  if (allocationAmount > 0) {
    // eslint-disable-next-line no-param-reassign
    root.percentage = (100 * allocationAmount).toFixed(0);
  }
  return allocationAmount;
}

export function buildAllocationsTreesWithPercentages(
  allocations: AllocationsTemplate['allocations']
): Map<AssetClassKey, AssetClassTreeNodeWithPercentage> {
  const assetClassRootNodes = new Map<AssetClassKey, AssetClassTreeNodeWithPercentage>();
  const assetClassKeys = Object.keys(allocations) as AssetClassKey[];
  assetClassKeys.forEach((assetClassKey) => {
    const features = assetClassKey.split('/') as Feature[];
    const rootAssetClassKey = features[0] as AssetClassKey;
    if (!assetClassRootNodes.has(rootAssetClassKey)) {
      assetClassRootNodes.set(rootAssetClassKey, { assetClassKey: rootAssetClassKey });
    }
    let prevLevelNode = assetClassRootNodes.get(
      rootAssetClassKey
    ) as AssetClassTreeNodeWithPercentage;
    for (let i = 1; i < features.length; i += 1) {
      const nextAssetClassKey = `${prevLevelNode.assetClassKey}/${features[i]}` as AssetClassKey;
      let nextLevelNode = { assetClassKey: nextAssetClassKey };
      if (prevLevelNode.children == null) {
        prevLevelNode.children = [nextLevelNode];
      } else {
        const existingNextLevelNode = prevLevelNode.children.find(
          (childNode) => childNode.assetClassKey === nextAssetClassKey
        );
        if (existingNextLevelNode != null) {
          nextLevelNode = existingNextLevelNode;
        } else {
          prevLevelNode.children.push(nextLevelNode);
        }
      }
      prevLevelNode = nextLevelNode;
    }
  });
  assetClassRootNodes.forEach((rootNode) =>
    computeAllocationPercentagesForAllocationsTree(rootNode, allocations)
  );
  return assetClassRootNodes;
}

export function getAssetClassNameFromKey(assetClassKey: AssetClassKey): string {
  const assetClassKeyComponents = assetClassKey.split('/');
  const assetSubclass = assetClassKeyComponents[assetClassKeyComponents.length - 1];
  const assetClass = ASSET_CLASS_TO_LABEL_MAP.get(assetSubclass as Feature);
  return assetClass ?? assetClassKey;
}

export const closeEquality = (
  num1: number | null | undefined,
  num2: number | null | undefined,
  delta = 0.1
) => {
  if (num1 == null || num2 == null) {
    return false;
  }
  return Math.abs(num1 - num2) < delta;
};

export function calculateTreeLeafsSum(
  node: AssetClassTreeNode,
  assetAllocation: { [key in AssetClassKey]?: number }
) {
  let sum = 0;
  const treeSum = (node: AssetClassTreeNode, branch: Feature[]) => {
    node.children?.forEach((n) => treeSum(n, [...branch, n.feature]));
    if (!node.children) {
      const nodeKey = getAssetClassKeyFromFeatures([...branch]);
      if (assetAllocation) {
        sum += assetAllocation[nodeKey] || 0;
      }
    }
  };
  treeSum(node, [node.feature]);
  return sum;
}

export function isCashPosition(position: Position) {
  return (
    position.symbolOrCusip === ':CASH' ||
    position.securityType === 'CASH_OR_EQIV' ||
    // This is really jank but its what X-ray CSV parsing logic uses (which is the only
    // time this would apply since ingestion will correctly set security_type =
    // 'CASH_OR_EQIV' when applicable) and this should be consistent.
    //
    // See this line https://github.com/viseinc/vise/blob/f15aeb69e168feeddb56f0dac80ad17138d2d5cc/go/snowflake/helpers/helpers.go#L164
    ((position.securityType === 'MF' || position.securityType === 'MUTUAL_FUND') &&
      position.symbolOrCusip.length >= 5 &&
      position.symbolOrCusip.endsWith('XX'))
  );
}

export function isNewTiltType(
  tiltType?: TiltType
): tiltType is
  | 'quality'
  | 'low-volatility'
  | 'low-volatility-quality-yield'
  | 'value'
  | 'momentum'
  | 'quality-value-momentum'
  | 'income-quality-value-momentum' {
  return !!(tiltType && tiltType !== 'dividend' && tiltType !== 'multi-factor');
}
