import { IconButton, Box, Button } from '@mui/material';
import React from 'react';
import styled, { css, CSSObject } from 'styled-components';
import { ReactComponent as ArrowRightIcon } from '~/static/images/icons/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '~/static/images/icons/arrow-left.svg';

type PageNavigationProps = {
  currentPage: number;
  numItems: number;
  pageSize: number;
  onNextPageClick: () => void;
  onPrevPageClick: () => void;
  onPageNumberClick: (pageNumber: number) => void;
  setPageSize?: (num: number) => void;
};

const PageButton = styled.button`
  ${({ theme }) => css(theme.typography.body2 as CSSObject)}
  border-radius: 50%;
  display: inline-block;
  border: none;
  background-color: inherit;
  margin: 0 ${({ theme }) => theme.spacing(1)};
  cursor: pointer;
  display: inline-block;
  &:hover {
    background: ${({ theme }) => theme.palette.grey[100]};
  }
`;

const ItemsText = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const PageNumber = styled.span<{ isCurrentPage?: boolean }>`
  padding: 0px ${({ theme }) => theme.spacing(0.5)};
  font-weight: ${({ isCurrentPage }) => (isCurrentPage ? '600' : 'normal')};
`;

const getSurroundingNumbers = (currentPage: number, numPages: number) => {
  if (currentPage === 1) {
    return [1, 2, 3].filter((n) => n <= numPages);
  }
  if (currentPage === numPages) {
    return [numPages - 2, numPages - 1, numPages].filter((n) => n > 0);
  }

  return [-1, 0, 1].map((n) => currentPage + n).filter((n) => n > 0 && n <= numPages);
};

const PageNavigation = ({
  currentPage,
  pageSize,
  numItems,
  onNextPageClick,
  onPrevPageClick,
  onPageNumberClick,
  setPageSize,
}: PageNavigationProps) => {
  const numPages = Math.floor(numItems / pageSize) + (numItems % pageSize > 0 ? 1 : 0);
  const surroundingNumbers = getSurroundingNumbers(currentPage, numPages);
  const firstRowNumber = pageSize * currentPage - (pageSize - 1);
  const lastRowNumber = Math.min(pageSize * currentPage, numItems);
  return (
    <Box display="flex">
      <Box>
        <ItemsText>
          {firstRowNumber} - {lastRowNumber} out of {numItems} items
        </ItemsText>
        {setPageSize && (
          <Button variant="text" onClick={() => setPageSize(numItems)}>
            View all
          </Button>
        )}
      </Box>
      <Box flex={1} />
      <Box>
        {currentPage !== 1 && (
          <IconButton aria-label="Previous Page" onClick={onPrevPageClick} size="small">
            <ArrowLeftIcon />
          </IconButton>
        )}
        {!surroundingNumbers.includes(1) && (
          <>
            <PageButton key={1} onClick={() => onPageNumberClick(1)}>
              <PageNumber>{1}</PageNumber>
            </PageButton>
            <span>...</span>
          </>
        )}
        {surroundingNumbers.map((n) => (
          <PageButton key={n} onClick={() => onPageNumberClick(n)}>
            <PageNumber isCurrentPage={n === currentPage}>{n}</PageNumber>
          </PageButton>
        ))}
        {!surroundingNumbers.includes(numPages) && (
          <>
            <span>...</span>
            <PageButton key={numPages} onClick={() => onPageNumberClick(numPages)}>
              <PageNumber>{numPages}</PageNumber>
            </PageButton>
          </>
        )}
        {currentPage !== numPages && (
          <IconButton aria-label="Next Page" onClick={onNextPageClick} size="small">
            <ArrowRightIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default PageNavigation;
