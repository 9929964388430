import React from 'react';
import { Box, BoxProps, Divider, Typography } from '@mui/material';
import startingAssetAllocationCopy, {
  StartingAssetAllocationTooltip,
} from '../components/startingAssetAllocationCopy';

interface AssetClassAllocationColumnProps extends BoxProps {
  allocation: number;
  assetClassText: 'Equities' | 'Fixed income';
}

interface AssetClassAllocationDisplayProps {
  risk?: number;
}

export function AssetClassAllocationColumn({
  allocation,
  assetClassText,
  ...boxProps
}: AssetClassAllocationColumnProps) {
  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      <Box color="grey.500" mb={1}>
        <Typography variant="h5">{assetClassText}</Typography>
      </Box>
      <Typography variant="body2">{allocation}%</Typography>
    </Box>
  );
}

export default function AssetClassAllocationDisplay({ risk }: AssetClassAllocationDisplayProps) {
  if (risk == null) {
    return null;
  }

  return (
    <Box data-testid="asset-class-allocation-display">
      <Box display="flex" flexDirection="row" mb={2}>
        <AssetClassAllocationColumn
          allocation={risk}
          assetClassText="Equities"
          flexBasis={52}
          mr={3}
        />
        <AssetClassAllocationColumn
          allocation={100 - risk}
          assetClassText="Fixed income"
          flexBasis={87}
        />
        <Box ml={4} mr={3}>
          <Divider orientation="vertical" />
        </Box>
        <Box flex={3}>
          <Box mb={1}>
            <Typography variant="h5">
              <Box color="grey.600" component="span">
                Starting allocation
                <StartingAssetAllocationTooltip position="relative" left={3} top={2} />
              </Box>
            </Typography>
          </Box>
          <Typography variant="body1">
            <Box component="span" color="grey.600">
              {startingAssetAllocationCopy(risk)}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
