import { Box, Button, Typography } from '@mui/material';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { AllocationsTemplate, RestrictionsTemplate } from 'vise-types/template';
import { renameAllocationsTemplate, renameRestrictionsTemplate } from '~/api/api';
import useAllAllocationsTemplates from '~/hooks/templates/useAllAllocationsTemplates';
import useAllRestrictionsTemplates from '~/hooks/templates/useAllRestrictionsTemplates';
import { ReactComponent as PencilIcon } from '~/static/images/icons/pencil.svg';
import TextField from '~/synth/TextField';
import Card from './Card';

const checkTemplateName = debounce(
  (
    name: string,
    templates: AllocationsTemplate[] | RestrictionsTemplate[],
    cb: (free: boolean) => void
  ) => {
    const typeCastTemplates = templates as { name: string }[];
    cb(!typeCastTemplates.find((t) => t.name.trim().toLowerCase() === name.trim().toLowerCase()));
  },
  500
);

export default function NameInput({
  name,
  nameIsFree,
  onChange,
  templateType,
  onCheckTemplateName,
  placeholder,
  editMode,
  originalName,
  originalId,
  onRenameTemplateSuccess,
}: {
  name: string;
  nameIsFree?: boolean;
  onChange: (value: string) => void;
  templateType: 'ALLOCATION' | 'RESTRICTION';
  onCheckTemplateName: (free?: boolean) => void;
  placeholder: string;
  editMode?: boolean;
  originalName?: string;
  originalId?: string;
  onRenameTemplateSuccess: (name: string) => void;
}) {
  const { data: allAllocationsTemplates } = useAllAllocationsTemplates();
  const { data: allRestrictionsTemplates } = useAllRestrictionsTemplates();
  let nameInputHelperText;
  let success;
  let error;
  if (nameIsFree) {
    success = true;
    nameInputHelperText = 'Template name available';
  } else if (nameIsFree != null && !nameIsFree) {
    error = true;
    nameInputHelperText = 'Template name already taken';
  }
  const allTemplates =
    templateType === 'ALLOCATION' ? allAllocationsTemplates : allRestrictionsTemplates;
  const [editingName, setEditingName] = useState(false);
  const input = (
    <TextField
      disabled={!allTemplates}
      value={name}
      helperText={nameInputHelperText}
      success={success}
      error={error}
      onChange={(e) => {
        if (allTemplates) {
          onChange(e.target.value);
          checkTemplateName(e.target.value, allTemplates, (free) => {
            onCheckTemplateName(free);
          });
        }
      }}
      placeholder={placeholder}
      label={editMode ? undefined : 'Template name'}
      fullWidth
    />
  );
  if (editMode && !editingName) {
    return (
      <Card
        header={
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <Typography variant="h2">Template name</Typography>
            </Box>
            <Button
              startIcon={<PencilIcon />}
              variant="outlined"
              color="primary"
              onClick={() => setEditingName(true)}
            >
              Edit
            </Button>
          </Box>
        }
      >
        <Typography variant="body2">{name}</Typography>
      </Card>
    );
  }
  if (editMode && editingName) {
    return (
      <Card
        header={
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <Typography variant="h2">Template name</Typography>
            </Box>
            <Button
              variant="outlined"
              onClick={() => {
                onChange(originalName || '');
                setEditingName(false);
                onCheckTemplateName(undefined);
              }}
            >
              Cancel
            </Button>
            <Box ml={1.5}>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  if (templateType === 'ALLOCATION') {
                    await renameAllocationsTemplate(originalId || '', name);
                  } else {
                    await renameRestrictionsTemplate(originalId || '', name);
                  }
                  setEditingName(false);
                  onRenameTemplateSuccess(name);
                }}
                disabled={!nameIsFree || !name}
              >
                Save
              </Button>
            </Box>
          </Box>
        }
      >
        {input}
      </Card>
    );
  }
  return (
    <Card>
      <Typography variant="h2">Review & save template</Typography>
      <Box mt={1.5} mb={4}>
        <Typography variant="body2" color="textSecondary">
          You’re almost done! Name this{' '}
          {templateType === 'ALLOCATION' ? 'allocation' : 'restriction'} template to save and
          continue.
        </Typography>
      </Box>
      {input}
    </Card>
  );
}
