/** Active portfolio page */
import { Box, Button, Container, Divider, Tab, Tabs, Typography } from '@mui/material';
import { ThemeProvider, styled } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory } from 'react-router';
import { Link, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { getPortfolioPdf } from '~/actions/portfolioIntelligence';
import { updateAccount } from '~/api/api';
import SomethingWentWrong from '~/components/SomethingWentWrong';
import AutoRebalancer from '~/components/modal/AutoRebalancer';
import RebalancerStatusButton from '~/components/portfolio/RebalancerStatusButton';
import useAllocationsTemplate from '~/hooks/templates/useAllocationsTemplate';
import useRestrictionsTemplates from '~/hooks/templates/useRestrictionsTemplates';
import useAccount from '~/hooks/useAccount';
import useAccountOrders from '~/hooks/useAccountOrders';
import useCapitalGains from '~/hooks/useCapitalGains';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useAccountState from '~/hooks/useAccountState';
import useHousehold from '~/hooks/useHousehold';
import useIntelligenceOrders from '~/hooks/useIntelligenceOrders';
import usePortfolioInsights from '~/hooks/usePortfolioInsights';
import usePortfolioIntelligence from '~/hooks/usePortfolioIntelligence';
import usePortfolioMetrics from '~/hooks/usePortfolioMetrics';
import usePositions from '~/hooks/usePositions';
import useToast from '~/hooks/useToast';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import { Notification, theme as dsTheme, tokens } from '@vise_inc/ds-vise';
import Skeleton from '~/synth/Skeleton';
import { exportCSV } from '~/utils';
import { getCustodianDisplayName, maskAccountNumber } from '~/utils/format';
import { EVENT_CATEGORIES } from '../../constants/amplitude';
import amplitude from '../../utils/amplitude';
import SentryRoute from '../SentryRoute';
import ExportMenu from './ExportMenu';
import LastUpdated from './LastUpdated';
import { RecentlyExecutedOrderBanner } from './OrderNotifications';
import PortfolioAllocation2, { AllocationLoadingState } from './PortfolioAllocation2';
import PortfolioFooterDisclosures from './PortfolioFooterDisclosures';
import PortfolioInputs, { InputsLoadingState } from './PortfolioInputs';
import PortfolioInsights, { UnavailabilityReason } from './PortfolioInsights';
import PortfolioOverview2, { OverviewLoadingState } from './PortfolioOverview2';
import PortfolioActivity, { ActivityLoadingState } from './TradeActivity/PortfolioActivity';
import UntradeableErrorMessage from './UntradeableErrorMessage';
import { getPCE2AccountPositionsRowData } from './portfolioUtil';

const Vr = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.grey[400],
}));

const PortfolioLoadingState = ({ tabName, accountId }: { tabName: string; accountId: string }) => {
  let tabLoading: JSX.Element | null;
  switch (tabName) {
    case 'overview':
      tabLoading = <OverviewLoadingState />;
      break;
    case 'allocation':
      tabLoading = <AllocationLoadingState />;
      break;
    case 'inputs':
      tabLoading = <InputsLoadingState />;
      break;
    case 'activity':
      tabLoading = <ActivityLoadingState />;
      break;
    default:
      tabLoading = null;
  }
  return (
    <>
      <Box pt={3} bgcolor="white">
        <Container>
          <Box display="flex" justifyContent="space-between" pb={3}>
            <Box>
              <Box width="16em" mb={2}>
                <Typography variant="body1" component="h1">
                  <Skeleton />
                </Typography>
              </Box>
              <Box width="25em">
                <Typography variant="h1" component="h1">
                  <Skeleton />
                </Typography>
              </Box>
              <Box width="16em">
                <Typography variant="body1" component="h1">
                  <Skeleton />
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" width="20%">
              <Skeleton>
                <Button variant="contained" color="secondary">
                  Export{' '}
                </Button>
              </Skeleton>
              <Skeleton>
                <Button variant="contained" color="secondary">
                  Manage{' '}
                </Button>
              </Skeleton>
            </Box>
          </Box>
        </Container>
        <Box border={1} borderLeft={0} borderRight={0} borderColor="grey.200">
          <Container>
            <Box display="flex" justifyContent="space-between">
              <Tabs value={tabName || 'overview'}>
                <Tab disabled label="Overview" value="overview" />
                <Tab disabled label="Allocation" value="allocation" />
                <Tab disabled label={<Box display="flex">Activity</Box>} value="activity" />
                <Tab disabled label={<Box display="flex">Insights</Box>} value="insights" />
                <Tab disabled label="Inputs" value="inputs" />
              </Tabs>
              <Box display="flex" alignItems="center" alignSelf="center">
                <Skeleton inline width="17em" height="1.5em" variant="text" />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Container>
        <Box pt={4} pb={8}>
          <Switch>
            <SentryRoute
              exact
              path={[
                '/secure/accounts/:accountId/portfolio/overview',
                '/secure/accounts/:accountId/portfolio/allocation',
                '/secure/accounts/:accountId/portfolio/inputs',
                '/secure/accounts/:accountId/portfolio/activity',
                '/secure/accounts/:accountId/portfolio/insights',
              ]}
            >
              {tabLoading}
            </SentryRoute>
            <SentryRoute path="/secure/accounts/:accountId/portfolio">
              <Redirect to={`/secure/accounts/${accountId}/portfolio/overview`} />
            </SentryRoute>
          </Switch>
        </Box>
      </Container>
      <Box borderTop={1} bgcolor="white" borderColor="grey.200">
        <Container>
          <PortfolioFooterDisclosures />
        </Container>
      </Box>
    </>
  );
};

export default function Portfolio() {
  const history = useHistory();
  const theme = useTheme();
  const { accountId } = useParams<{ accountId: string }>();
  const [autoRebalancerModalOpen, setAutoRebalancerModalOpen] = useState(false);

  const enqueueToast = useToast();
  const { data: featureFlags } = useFeatureFlags();

  const {
    data: accountResponseData,
    error: accountResponseError,
    mutate: mutateAccount,
  } = useAccount(accountId, true);

  const account = accountResponseData?.data;

  const { data: intelligenceArrayData, error: intelligenceError } = usePortfolioIntelligence(
    account?.portfolioIntelligenceId
  );

  const intelligence = intelligenceArrayData?.[0];

  const { data: intelligenceOrders, error: intelligenceOrdersError } = useIntelligenceOrders(
    intelligence?.id
  );

  const { data: accountOrders, error: accountOrdersError } = useAccountOrders(accountId);

  const { data: capitalGainsData, error: capitalGainsError } = useCapitalGains(
    account?.accountNumber,
    account?.custodianKey
  );
  const { data: accountPositionsResponseData, error: accountPositionsResponseError } = usePositions(
    account?.accountNumber,
    account?.custodianKey,
    true /* includePCE2Instruments */
  );

  const { data: householdResponseData, error: householdResponseError } = useHousehold(
    intelligence?.client.clientGroupId
  );
  const { data: pce2MetricsResponseData, error: pce2MetricsResponseError } = usePortfolioMetrics(
    intelligence?.pceVersion === 'pce2' ? accountId : undefined
  );

  const { data: allocationsTemplateData, error: allocationsTemplateError } = useAllocationsTemplate(
    intelligence?.allocationTemplateId,
    true
  );

  const { data: accountStateData } = useAccountState(account?.id);

  const isLiquidationPending = accountStateData?.isLiquidationPending;
  const isRemovalPending = accountStateData?.isRemovalPending;

  const { data: restrictionsTemplatesData, error: restrictionsTemplatesError } =
    useRestrictionsTemplates(intelligence?.restrictionTemplateIds, true);

  const shouldFetchPortfolioIntelligence = account?.rebalancerStatus === 'ACTIVE';
  const { data: portfolioInsightsResponse, error: portfolioInsightsError } = usePortfolioInsights(
    shouldFetchPortfolioIntelligence ? intelligence?.id : undefined
  );

  const portfolioInsightsLoading =
    shouldFetchPortfolioIntelligence && !portfolioInsightsResponse && !portfolioInsightsError;
  let portfolioInsightsUnavailabilityReason: UnavailabilityReason | undefined;
  if (account && account.rebalancerStatus === 'PAUSED') {
    portfolioInsightsUnavailabilityReason = 'REBALANCER_PAUSED';
  } else if (portfolioInsightsError?.response.status === 404) {
    portfolioInsightsUnavailabilityReason = 'TRANSITION_MODE';
  } else if (portfolioInsightsError) {
    portfolioInsightsUnavailabilityReason = 'UNKNOWN';
  }

  const routeMatch = useRouteMatch<{ tabName: string }>([
    `/secure/accounts/${accountId}/portfolio/:tabName`,
  ]);
  const tabName = routeMatch?.params?.tabName;

  useEffect(() => {
    if (accountPositionsResponseError != null) {
      Sentry.captureException(
        new Error(`[Portfolio] Failed to load account positions ${accountId}.`),
        {
          extra: {
            accountId,
            intelligenceId: intelligence?.id,
          },
        }
      );
    }
  }, [accountPositionsResponseError, accountId, intelligence?.id]);

  useEffect(() => {
    if (householdResponseError != null) {
      Sentry.captureException(
        new Error(`[Portfolio] Failed to load household for account ${accountId}.`),
        {
          extra: {
            accountId,
            clientGroupId: intelligence?.client.clientGroupId,
          },
        }
      );
    }
  }, [accountId, householdResponseError, intelligence?.client.clientGroupId]);

  useEffect(() => {
    if (intelligenceOrdersError != null) {
      Sentry.captureException(
        new Error(`[Portfolio] Failed to load orders for intelligence ${intelligence?.id}.`),
        {
          extra: {
            accountId,
            intelligenceId: intelligence?.id,
          },
        }
      );
    }
  }, [intelligenceOrdersError, accountId, intelligence?.id]);

  useEffect(() => {
    if (accountOrdersError != null) {
      Sentry.captureException(
        new Error(`[Portfolio] Failed to load orders for account ${accountId}.`),
        {
          extra: {
            accountId,
          },
        }
      );
    }
  }, [accountOrdersError, accountId]);

  useEffect(() => {
    if (pce2MetricsResponseError != null) {
      Sentry.captureException(
        new Error(`[Portfolio] Failed to load metrics for account ${accountId}.`),
        {
          extra: {
            accountId,
            intelligenceId: intelligence?.id,
          },
        }
      );
    }
  }, [accountId, intelligence?.id, pce2MetricsResponseError]);

  useEffect(() => {
    if (restrictionsTemplatesError != null) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligence?.id} failed to load restrictions templates: ${restrictionsTemplatesError}.`
        ),
        {
          extra: {
            restrictionsTemplatesIds: intelligence?.restrictionTemplateIds,
          },
        }
      );
    }
  }, [restrictionsTemplatesError, intelligence?.restrictionTemplateIds, intelligence?.id]);

  useEffect(() => {
    if (allocationsTemplateError != null) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligence?.id} failed to load allocations templates: ${allocationsTemplateError}.`
        ),
        {
          extra: {
            allocationsTemplateId: intelligence?.allocationTemplateId,
          },
        }
      );
    }
  }, [allocationsTemplateError, intelligence?.allocationTemplateId, intelligence?.id]);

  useEffect(() => {
    if (intelligenceError != null) {
      Sentry.captureException(
        new Error(`[Portfolio] Failed to load intelligence for account ${accountId}.`),
        {
          extra: {
            accountId,
            intelligenceId: intelligence?.id,
          },
        }
      );
    }
  }, [accountId, intelligence?.id, intelligenceError]);

  useEffect(() => {
    if (capitalGainsError != null) {
      Sentry.captureException(
        new Error(`[Portfolio] Failed to load gains for account ${accountId}.`),
        {
          extra: {
            accountId,
            intelligenceId: intelligence?.id,
          },
        }
      );
    }
  }, [accountId, capitalGainsError, intelligence?.id]);

  useEffect(() => {
    if (accountResponseError != null) {
      Sentry.captureException(new Error(`[Portfolio] Failed to load account ${accountId}.`), {
        extra: {
          accountId,
          intelligenceId: intelligence?.id,
        },
      });
    }
  }, [accountId, accountResponseError, intelligence?.id]);

  useEffect(() => {
    // TODO(whuang): Revisit after more pce1 stuff is cleaned up.
    if (intelligence?.status === 'REJECTED') {
      Sentry.captureException(
        new Error(
          `Intelligence ${account?.portfolioIntelligenceId} attached to account ${accountId} is not in a valid state.`
        ),
        {
          extra: {
            accountId,
            intelligenceId: intelligence.id,
            intelligenceStatus: intelligence.status,
            isConstructedPortfolioEmpty: false,
          },
        }
      );
    }
  }, [accountId, account?.portfolioIntelligenceId, intelligence]);

  if (intelligence && intelligence.pceVersion !== 'pce2') {
    return (
      <SomethingWentWrong
        description="There is an issue with the inputs in the current portfolio, please contact clientservice@vise.com for assistance."
        disableCta
        error={new Error('pce1 portfolio')}
      />
    );
  }

  if (accountResponseError?.response?.status === 404) {
    return (
      <SomethingWentWrong
        title="We can't find the account you're looking for."
        description="The link you're using may be
        incorrect, or you may not have access to the account."
        disableCta
        error={new Error('could not find account')}
      />
    );
  }

  const loadingError =
    accountResponseError ||
    accountPositionsResponseError ||
    intelligenceOrdersError ||
    householdResponseError ||
    pce2MetricsResponseError ||
    intelligenceError != null ||
    (intelligenceArrayData &&
      intelligenceArrayData[0] == null &&
      new Error('empty intelligence data')) ||
    capitalGainsError ||
    restrictionsTemplatesError ||
    allocationsTemplateError ||
    accountOrdersError;

  if (loadingError) {
    return (
      <SomethingWentWrong
        description="There was an error loading the portfolio."
        error={loadingError}
      />
    );
  }

  if (
    intelligence == null ||
    account == null ||
    accountPositionsResponseData == null ||
    capitalGainsData == null ||
    householdResponseData == null ||
    pce2MetricsResponseData == null ||
    (intelligence.allocationTemplateId && allocationsTemplateData == null) ||
    (intelligence.restrictionTemplateIds?.length && restrictionsTemplatesData == null)
  ) {
    return <PortfolioLoadingState tabName={tabName ?? 'overview'} accountId={accountId} />;
  }

  const { positionDate, positions } = accountPositionsResponseData;
  const { id: householdId, name: householdName } = householdResponseData;

  const shouldShowError = account.status !== 'ACTIVE';
  const shouldShowRecentlyTraded = intelligenceOrders?.orders.some(
    (order) =>
      order.status === 'EXECUTED' && moment(positionDate).isBefore(order.executedAt ?? undefined)
  );

  let lockedTickers: Set<string>;
  if (intelligence.pceVersion === 'pce2') {
    lockedTickers = new Set(intelligence.constructionRequest.doNotSellSymbolsList);
  } else {
    lockedTickers = new Set(
      intelligence.constructionInfo.existingPortfolio?.positions
        .filter((pos) => pos.locked)
        .map((pos) => pos.symbolOrCusip)
    );
  }

  const handleClickCashManagement = () => {
    amplitude().logEvent('Open cash management', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
    history.push(`/secure/accounts/${accountId}/distributions`);
  };

  const handleClickAutoRebalancer = () => {
    amplitude().logEvent('OpenAutoRebalancer', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
    setAutoRebalancerModalOpen(true);
  };

  const handleClickEditPortfolio = () => {
    history.push(
      `/secure/portfolio-creator-next/summary-landing?pid=${encodeURIComponent(intelligence.id)}`
    );
    amplitude().logEvent('OpenPortfolioEdit', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
  };

  const handleClickLiquidateAccount = () => {
    history.push(`/secure/accounts/${accountId}/liquidate`);
    amplitude().logEvent('LiquidateAccount', {
      category: EVENT_CATEGORIES.LIQUIDATE_ACCOUNT,
    });
  };

  const handleClickRemoveAccountFromVise = () => {
    history.push(`/secure/client/accounts/${accountId}/remove`);
    amplitude().logEvent('RemoveAccountFromVise', {
      category: EVENT_CATEGORIES.REMOVE_ACCOUNT,
    });
  };

  const handleClickExportCsv = () => {
    const exportRows = getPCE2AccountPositionsRowData(
      positions,
      account.custodianKey,
      lockedTickers,
      () => 'all'
    ).all;

    exportCSV({
      data: exportRows,
      filename: `${account.accountName}-${moment()}.csv`,
    });
    amplitude().logEvent('CsvExportInitiated', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
      accountName: account.accountName,
    });
  };

  const handleClickExportPdf = async () => {
    amplitude().logEvent('Start portfolio pdf export', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
      accountName: account.accountName,
    });
    const success = await getPortfolioPdf(intelligence.id);
    // @ts-expect-error ts-migrate(2774) FIXME: This condition will always return true since the f... Remove this comment to see the full error message
    if (success) {
      amplitude().logEvent('PDF export success', {
        category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
        accountName: account.accountName,
        clientId: intelligence.clientId,
      });
    } else {
      amplitude().logEvent('PDF export error', {
        category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
        accountName: account.accountName,
        clientId: intelligence.clientId,
      });
    }
  };

  async function onAutoRebalancerFormSubmit(active: boolean) {
    amplitude().logEvent('SubmitAutoRebalancer', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });

    try {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await updateAccount(account.id, account.viseClientId, active ? 'ACTIVE' : 'PAUSED');
    } catch (err) {
      enqueueToast({
        content: err instanceof Error ? err.message : '',
        severity: 'error',
        title: 'Error while attempting to pause auto rebalancer',
      });
      return;
    }

    // Update the UI with the updated account data
    try {
      await mutateAccount();
    } catch (err) {
      enqueueToast({
        content: err instanceof Error ? err.message : '',
        severity: 'error',
        title: 'Error while attempting to update account details',
      });
    }
  }

  return (
    <>
      <Helmet>
        <title>Portfolio</title>
      </Helmet>
      {shouldShowError && <UntradeableErrorMessage account={account} lastUpdated={positionDate} />}
      {shouldShowRecentlyTraded && <RecentlyExecutedOrderBanner fullWidth />}
      <Box pt={3} bgcolor="white">
        <Container>
          <Box display="flex" justifyContent="space-between" pb={3}>
            <Box>
              <PathBreadcrumbs
                path={[
                  { name: 'Households', to: '/secure/households' },
                  {
                    name: householdName,
                    to: `/secure/households/${householdId}/overview`,
                  },
                  { name: account.accountName },
                ]}
                ariaLabel="Page path breadcrumbs"
              />
              <Box mb={1} mt={2}>
                <Typography variant="h2" component="h1">
                  {account.accountName}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box display="inline-block" mr={1.5}>
                  <Typography variant="body1" color="textSecondary">
                    {account.taxable ? 'Non-qualified' : 'Qualified'}
                  </Typography>
                </Box>
                <Box bgcolor="grey.300" alignSelf="stretch">
                  <Vr orientation="vertical" flexItem />
                </Box>
                <Box ml={1.5}>
                  <Typography variant="body1" color="textSecondary">
                    {maskAccountNumber(account.accountNumber)},{' '}
                    {getCustodianDisplayName(account.custodianKey)}
                  </Typography>
                </Box>
                <Box ml={1.5}>
                  <RebalancerStatusButton
                    accountStatus={account.status}
                    onClick={() => setAutoRebalancerModalOpen(true)}
                    rebalancerStatus={account.rebalancerStatus}
                  />
                </Box>
              </Box>
            </Box>

            <Box justifyContent="flex-end" display="flex" alignItems="center">
              <Box mr={2}>
                <ExportMenu
                  hideExportPdf
                  onExportCsv={handleClickExportCsv}
                  onExportPdf={handleClickExportPdf}
                />
              </Box>
              <DropdownButtonMenu
                buttonProps={{ variant: 'contained', color: 'primary' }}
                buttonContent="Manage portfolio"
              >
                {(closeMenu) => [
                  <DropdownButtonMenuItem
                    disabled={account.status !== 'ACTIVE' || intelligence.pceVersion !== 'pce2'}
                    key="mi-ash-management"
                    onClick={() => {
                      handleClickCashManagement();
                      closeMenu();
                    }}
                  >
                    Cash management
                  </DropdownButtonMenuItem>,
                  <DropdownButtonMenuItem
                    key="mi-auto-rebalancer"
                    onClick={() => {
                      handleClickAutoRebalancer();
                      closeMenu();
                    }}
                  >
                    Auto rebalancer
                  </DropdownButtonMenuItem>,
                  <Divider key="divider" />,
                  <DropdownButtonMenuItem
                    disabled={account.status !== 'ACTIVE' || intelligence.pceVersion !== 'pce2'}
                    key="mi-edit-portfolio"
                    onClick={() => {
                      handleClickEditPortfolio();
                      closeMenu();
                    }}
                  >
                    Edit portfolio
                  </DropdownButtonMenuItem>,
                  <Divider key="divider" />,
                  <DropdownButtonMenuItem
                    disabled={
                      account.status !== 'ACTIVE' ||
                      isLiquidationPending ||
                      intelligence.pceVersion !== 'pce2'
                    }
                    key="mi-liquidate-account"
                    onClick={() => {
                      handleClickLiquidateAccount();
                      closeMenu();
                    }}
                    style={{ color: theme.palette.error.main }}
                  >
                    Liquidate account
                  </DropdownButtonMenuItem>,
                  <DropdownButtonMenuItem
                    disabled={isRemovalPending || intelligence.pceVersion !== 'pce2'}
                    key="mi-remove-account"
                    onClick={() => {
                      handleClickRemoveAccountFromVise();
                      closeMenu();
                    }}
                    style={{ color: theme.palette.error.main }}
                  >
                    Remove account from Vise
                  </DropdownButtonMenuItem>,
                ]}
              </DropdownButtonMenu>
            </Box>
          </Box>
        </Container>
        <Box border={1} borderLeft={0} borderRight={0} borderColor="grey.200">
          <Container>
            <Box display="flex" justifyContent="space-between">
              <Tabs value={tabName || 'overview'} aria-label="Portfolio tabs">
                <Tab
                  value="overview"
                  label="Overview"
                  id="tab-overview"
                  to={`/secure/accounts/${accountId}/portfolio/overview`}
                  aria-controls="overview-screen"
                  component={Link}
                />
                <Tab
                  value="allocation"
                  label="Allocation"
                  id="tab-allocation"
                  to={`/secure/accounts/${accountId}/portfolio/allocation`}
                  aria-controls="allocation-screen"
                  component={Link}
                />
                <Tab
                  value="activity"
                  label={<Box display="flex">Activity</Box>}
                  id="tab-activity"
                  to={`/secure/accounts/${accountId}/portfolio/activity`}
                  aria-controls="activity-screen"
                  component={Link}
                  disabled={accountOrders == null}
                />
                <Tab
                  value="insights"
                  label={<Box display="flex">Insights</Box>}
                  id="tab-insights"
                  to={`/secure/accounts/${accountId}/portfolio/insights`}
                  aria-controls="insights-screen"
                  disabled={
                    portfolioInsightsLoading || portfolioInsightsUnavailabilityReason === 'UNKNOWN'
                  }
                  component={Link}
                  onClick={() =>
                    amplitude().logEvent('Click portfolio insights tab', {
                      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
                    })
                  }
                />
                <Tab
                  value="inputs"
                  label="Inputs"
                  id="tab-inputs"
                  to={`/secure/accounts/${accountId}/portfolio/inputs`}
                  aria-controls="inputs-screen"
                  component={Link}
                />
              </Tabs>
              {positionDate != null && (
                /* Only include "Last updated" if there's any positions at all */
                <Box display="flex" alignItems="center" alignSelf="center">
                  <LastUpdated
                    insightsDate={
                      portfolioInsightsResponse
                        ? new Date(portfolioInsightsResponse.data.generatedAt * 1000)
                        : undefined
                    }
                    date={new Date(positionDate)}
                  />
                </Box>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
      <Container>
        <Box pt={4} pb={8}>
          {featureFlags?.disable_small_accounts_ui === 'on' &&
          intelligence.constructionInfo.smallAccount ? (
            <ThemeProvider theme={dsTheme}>
              <Box mb={tokens.SPACING_INDICES.xl}>
                <Notification variant="notice">
                  This account is now eligible for custom asset allocations. You can update your
                  allocation by clicking on Edit within Manage portfolio.
                </Notification>
              </Box>
            </ThemeProvider>
          ) : null}
          <Switch>
            <SentryRoute exact path="/secure/accounts/:accountId/portfolio/overview">
              <Box id="overview-screen" aria-labelledby="tab-overview" role="tabpanel">
                <PortfolioOverview2
                  intelligence={intelligence}
                  account={{ ...account, gains: capitalGainsData }}
                  orders={intelligenceOrders}
                  pce2Metrics={pce2MetricsResponseData}
                  portfolioInsights={portfolioInsightsResponse?.data}
                  portfolioInsightsUnavailabilityReason={portfolioInsightsUnavailabilityReason}
                />
              </Box>
            </SentryRoute>
            <SentryRoute exact path="/secure/accounts/:accountId/portfolio/allocation">
              <Box id="allocation-screen" aria-labelledby="tab-allocation" role="tabpanel">
                <PortfolioAllocation2
                  intelligence={intelligence}
                  account={account}
                  positions={accountPositionsResponseData}
                  pce2Metrics={pce2MetricsResponseData}
                />
              </Box>
            </SentryRoute>
            <SentryRoute exact path="/secure/accounts/:accountId/portfolio/activity">
              <Box id="activity-screen" aria-labelledby="tab-activity" role="tabpanel">
                <PortfolioActivity
                  orders={accountOrders}
                  account={account}
                  intelligence={intelligence}
                  gains={capitalGainsData}
                />
              </Box>
            </SentryRoute>
            <SentryRoute exact path="/secure/accounts/:accountId/portfolio/insights">
              <Box id="insights-screen" aria-labelledby="tab-insights" role="tabpanel">
                <PortfolioInsights
                  unavailabilityReason={portfolioInsightsUnavailabilityReason}
                  showSubInsights
                  insights={portfolioInsightsResponse?.data}
                  name={account.accountNameShort}
                />
              </Box>
            </SentryRoute>
            <SentryRoute exact path="/secure/accounts/:accountId/portfolio/inputs">
              <Box id="inputs-screen" aria-labelledby="tab-inputs" role="tabpanel">
                <PortfolioInputs
                  account={account}
                  intelligence={intelligence}
                  allocationsTemplate={allocationsTemplateData}
                  restrictionsTemplates={restrictionsTemplatesData}
                />
              </Box>
            </SentryRoute>
            <SentryRoute path="/secure/accounts/:accountId/portfolio">
              <Redirect to={`/secure/accounts/${accountId}/portfolio/overview`} />
            </SentryRoute>
          </Switch>
        </Box>
      </Container>
      <Box borderTop={1} bgcolor="white" borderColor="grey.200">
        <Container>
          <PortfolioFooterDisclosures />
        </Container>
      </Box>
      <AutoRebalancer
        rebalancerPausedAt={account.rebalancerPausedAt}
        rebalancerStatus={account.rebalancerStatus}
        accountStatus={account.status}
        onClose={() => setAutoRebalancerModalOpen(false)}
        onSubmit={(value: boolean) => {
          onAutoRebalancerFormSubmit(value);
          setAutoRebalancerModalOpen(false);
        }}
        open={autoRebalancerModalOpen}
      />
    </>
  );
}
