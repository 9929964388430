import { IconButton, SnackbarContent as SnackbarContentM, styled as styledM } from '@mui/material';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import { RootState } from '~/reducers';
import { closeAlert, removeAlert } from '../actions';
import { Colors } from '../constants';

interface NotifierProps {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messages: any;

  closeAlert: (key: string) => void;
  removeAlert: (key: string) => void;

  // From withSnackbar.
  closeSnackbar: (key: string) => void;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enqueueSnackbar: (message: string, options: any) => void;
}

const SnackbarContent = styledM(SnackbarContentM)({
  backgroundColor: Colors.white,
  color: Colors.grayDark,
});

class Notifier extends Component<NotifierProps> {
  displayed: string[] = [];

  componentDidUpdate() {
    const { closeAlert, closeSnackbar, enqueueSnackbar, messages, removeAlert } = this.props;

    Object.keys(messages).forEach((messageKey) => {
      const { message, dismissed = false, options = {} } = messages[messageKey];

      if (dismissed) {
        closeSnackbar(messageKey);
        return;
      }

      // Do nothing if notification is already displayed
      if (this.displayed.includes(messageKey)) {
        return;
      }

      // Display notification using notistack
      enqueueSnackbar(message, {
        key: messageKey,
        autoHideDuration: 20000,
        content: (key: string) => (
          <SnackbarContent
            action={
              <IconButton color="inherit" onClick={() => closeAlert(key)} size="large">
                <XIcon style={{ color: Colors.blue }} />
              </IconButton>
            }
            message={message}
          />
        ),
        onClose: (event, reason, key) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }
        },
        onExited: (_event, key: string) => {
          // Dispatch action to remove notification from redux store
          removeAlert(key);
          this.removeDisplayed(key);
        },
        ...options,
      });

      // Keep track of alerts that we've displayed
      this.storeDisplayed(messageKey);
    });
  }

  removeDisplayed(key: string) {
    this.displayed = this.displayed.filter((v) => v !== key);
  }

  storeDisplayed(key: string) {
    this.displayed = [...this.displayed, key];
  }

  render() {
    return null;
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => ({
  closeAlert: (key: string) => dispatch(closeAlert(key)),
  removeAlert: (key: string) => dispatch(removeAlert(key)),
});

const mapStateToProps = ({ message: { messages } }: RootState) => ({
  messages,
});

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Notifier));
