import { cache, mutate } from 'swr';
import { DataSource } from '../models/shared';

export default async function revalidateAllFromDataSource(
  dataSource: DataSource
): Promise<unknown> {
  const matcher = new RegExp(`^${dataSource}-`);
  const mutations = cache
    .keys()
    .filter((key) => matcher.test(key))
    .map((key) => mutate(key));
  return Promise.all(mutations);
}
