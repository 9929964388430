import React, { useEffect, useState, memo } from 'react';
import {
  Box,
  ButtonBase,
  Container,
  Divider,
  Fade,
  styled,
  Typography,
  Button,
  useMediaQuery,
  Link as MuiLink,
  ThemeProvider,
  BoxProps,
} from '@mui/material';
import { theme, tokens } from '@vise_inc/ds-vise';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Unstable_Grid2';
import { ReactComponent as MailIcon } from '~/static/images/icons/mail-outline.svg';
import { ReactComponent as CheckIcon } from '~/static/images/icons/check.svg';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import ViseLogoBlack from '~/static/images/vise-logo-black.svg';
import { LoginTextInput } from '~/routes/Login/components';
import { ReactComponent as LockIcon } from '~/static/images/icons/lock-closed-outline.svg';
import { useInterval } from 'usehooks-ts';
import { SwitchTransition } from 'react-transition-group';
import { ReactComponent as ShieldExclamationIcon } from '~/static/images/icons/shield-exclamation.svg';
import ExplainImg from '~/static/images/login/vise-valueprop-explain.png';
import BuildImg from '~/static/images/login/vise-valueprop-build.png';
import ManageImg from '~/static/images/login/vise-valueprop-manage.png';
import BackgroundImg from '~/static/images/login/bg.png';
import { ReactComponent as KeyIcon } from '~/static/images/icons/key.svg';
import { Switch, Link as RouterLink, useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelMfa,
  login,
  loginWithSso,
  requestPasswordReset,
  resetPassword,
  verifyMfa,
} from '~/actions/user';
import { RootState } from '~/reducers';
import { Redirect } from 'react-router';
import { PASSWORD_MATCH_REGEX } from '~/constants';
import Route from '../SentryRoute';

type Inputs = {
  email: string;
  password: string;
  mfaSecret: string;
  newPassword: string;
  confirmPassword: string;
  resetToken: string;
  isUpdateConfirmed: boolean;
};

const s = (v: keyof typeof tokens.SPACING_INDICES) =>
  `${tokens.spacing[tokens.SPACING_INDICES[v]]}px`;

const { neutralCool: cool, semanticRed: red } = tokens.palette;

function ResetPassword({ control }: UseFormReturn<Inputs>) {
  return (
    <Box>
      <Typography variant="h1">Reset password</Typography>
      <Typography mb={s('5xl')}>
        Enter your email and we&apos;ll send you instructions on how to reset your password.
      </Typography>
      <Box mb={s('2xl')}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <LoginTextInput
              IconComponent={MailIcon}
              textFieldProps={{
                ...field,
                id: 'email',
                label: 'Email',
                name: 'email',
                type: 'email',
                autoFocus: true,
                placeholder: 'Enter your email',
              }}
            />
          )}
        />
      </Box>
      <ButtonBase sx={{ mb: s('5xl') }}>
        <RouterLink to="/login">Take me back to the login page</RouterLink>
      </ButtonBase>
      <Button type="submit" fullWidth color="primary" sx={{ mb: 4 }}>
        Reset password
      </Button>
    </Box>
  );
}

function LoginFooter() {
  return (
    <Box mb={s('6xl')}>
      <Typography>
        For any inquiries or to schedule a demo, feel free to contact our{' '}
        <MuiLink href="mailto:clientservice@vise.com">Client Service Team</MuiLink>.
      </Typography>
      <Divider sx={{ my: 4 }} />
      <Typography>
        To delve deeper into our approach,{' '}
        <MuiLink component={RouterLink} to="/disclosures" rel="noopener" target="_blank">
          please refer to our methodology
        </MuiLink>
        , assumptions, and other disclosures.
      </Typography>
    </Box>
  );
}

function LoginSection({ control }: UseFormReturn<Inputs>) {
  const history = useHistory();
  return (
    <Box>
      <Typography variant="h1">Hello Again.</Typography>
      <Typography mb={s('5xl')} color={cool[700]}>
        Welcome back, you’ve been missed.
      </Typography>
      <Box mb={s('2xl')}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <LoginTextInput
              IconComponent={MailIcon}
              textFieldProps={{
                ...field,
                fullWidth: true,
                id: 'email',
                label: 'Email',
                name: 'email',
                type: 'email',
                autoFocus: true,
                placeholder: 'Enter your email',
              }}
            />
          )}
        />
      </Box>
      <Box mb={s('md')}>
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <LoginTextInput
              textFieldProps={{
                ...field,
                fullWidth: true,
                id: 'password',
                label: 'Password',
                name: 'password',
                type: 'password',
              }}
              IconComponent={LockIcon}
            />
          )}
        />
      </Box>
      <ButtonBase sx={{ mb: s('5xl') }}>
        <RouterLink to="/login/reset-password">Forgot password?</RouterLink>
      </ButtonBase>

      <Button type="submit" fullWidth color="primary" variant="primary" sx={{ mb: 4 }}>
        Login
      </Button>
      <Button
        fullWidth
        variant="secondary"
        startIcon={<KeyIcon />}
        sx={{ mb: s('5xl') }}
        onClick={() => {
          const urlParams = new URLSearchParams(window.location.search);
          history.push(`/login/sso?${urlParams.toString()}`);
        }}
      >
        Login with SSO
      </Button>
    </Box>
  );
}

function RequestPasswordReset() {
  const history = useHistory();
  return (
    <Box>
      <Typography variant="h1" mb={s('sm')}>
        Reset password
      </Typography>
      <Typography mb={s('5xl')} color={cool[700]}>
        If there&apos;s a Vise account connected to this email address, we&apos;ll email password
        reset instructions. If you don&apos;t receive the email, please try again and make sure to
        enter the email address associated with your Vise account.{' '}
      </Typography>
      <Button
        sx={{ mb: 4 }}
        variant="primary"
        onClick={() => {
          history.push('/login');
        }}
      >
        Return to login
      </Button>
    </Box>
  );
}

function Mfa({ control, token }: UseFormReturn<Inputs> & { token: string }) {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h1" mb={s('sm')}>
        Your security is our priority
      </Typography>
      <Typography mb={s('5xl')} color={cool[700]}>
        To verify that it&apos;s really you, Vise has sent an access code to your email.
      </Typography>
      <Box mb={s('2xl')}>
        <Controller
          name="mfaSecret"
          control={control}
          render={({ field }) => (
            <LoginTextInput
              IconComponent={ShieldExclamationIcon}
              textFieldProps={{
                ...field,
                id: 'mfaSecret',
                label: 'Access code',
                name: 'mfaSecret',
                autoFocus: true,
                placeholder: 'Enter access code',
              }}
            />
          )}
        />
      </Box>
      <Button sx={{ mb: s('lg') }} variant="contained" type="submit">
        Confirm
      </Button>
      <Button
        sx={{ mb: 4 }}
        variant="outlined"
        onClick={() => {
          dispatch(cancelMfa(token));
          history.push('/login');
        }}
      >
        Cancel
      </Button>
    </Box>
  );
}

function SSOLogin({ control, getValues }: UseFormReturn<Inputs>) {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h1" mb={s('sm')}>
        Continue with SSO
      </Typography>
      <Typography mb={s('5xl')} color={cool[700]}>
        To verify that it&apos;s really you, Vise has sent an access code to your email.
      </Typography>
      <Box mb={s('2xl')}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <LoginTextInput
              IconComponent={MailIcon}
              textFieldProps={{
                ...field,
                id: 'email',
                label: 'Email',
                name: 'email',
                autoFocus: true,
                placeholder: 'email',
              }}
            />
          )}
        />
      </Box>
      <Button
        sx={{ mb: s('lg') }}
        variant="contained"
        onClick={() => {
          dispatch(loginWithSso(getValues('email')));
        }}
      >
        Continue
      </Button>
      <Button
        sx={{ mb: 4 }}
        variant="outlined"
        onClick={() => {
          history.push('/login');
        }}
      >
        Cancel
      </Button>
    </Box>
  );
}

const PasswordRule = ({
  valid,
  children,
  ...other
}: { valid: boolean; children: React.ReactNode } & BoxProps) => {
  return (
    <Box display="flex" gap={1} {...other}>
      {valid ? (
        <Box>
          {' '}
          <CheckIcon width={16} height={16} color="green" />
        </Box>
      ) : (
        <Box>
          <XIcon width={16} height={16} color={red['700']} />
        </Box>
      )}
      <Box sx={{ whiteSpace: 'nowrap' }} mb={1} color={valid ? 'initial' : red['700']}>
        {children}
      </Box>
    </Box>
  );
};

function UpdatePassword({ control, setValue, watch, getFieldState }: UseFormReturn<Inputs>) {
  const { resetToken } = useParams<{ resetToken?: string }>();
  const history = useHistory();

  if (!resetToken) {
    return <Redirect to="/login" />;
  }

  setValue('resetToken', resetToken);

  const newPassword = watch('newPassword');
  const confirmPassword = watch('confirmPassword');
  const isUpdateConfirmed = watch('isUpdateConfirmed');

  const newPasswordError = getFieldState('newPassword').error;

  const hasChar = !!newPassword?.match(/[a-z]/);
  const hasCapitalChar = !!newPassword?.match(/[A-Z]/);
  const hasNumber = !!newPassword?.match(/[0-9]/);
  const isLong = Boolean(newPassword?.length >= 8);
  const invalidChars = newPassword?.replace(/[a-zA-Z\d~!@#$%^&*_+=|<>?]*/g, '');

  const arePasswordsMatch = newPassword === confirmPassword;

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h1" mb={s('sm')}>
        {isUpdateConfirmed ? 'Reset password successful. ' : 'Update password'}
      </Typography>
      <Typography mb={s('5xl')} color={cool[700]}>
        {isUpdateConfirmed
          ? 'Your password was successfully updated. Proceed to the login page and enter your credentials to access Vise.'
          : `Please enter a new password. Don’t use names, surnames, birthdays, anniversaries or common phrases. Allowed special symbols are ~!@#$%^&*_+=|<>?.`}{' '}
      </Typography>
      {!isUpdateConfirmed && (
        <>
          <Box mb={s('md')}>
            <Controller
              control={control}
              name="newPassword"
              rules={{ pattern: { value: PASSWORD_MATCH_REGEX, message: 'invalid password' } }}
              render={({ field }) => (
                <LoginTextInput
                  textFieldProps={{
                    ...field,
                    autoComplete: 'new-password',
                    fullWidth: true,
                    id: 'password',
                    label: 'New Password',
                    name: 'password',
                    type: 'password',
                  }}
                  IconComponent={LockIcon}
                />
              )}
            />
          </Box>
          <Box mb={s('md')}>
            <Controller
              control={control}
              name="confirmPassword"
              rules={{ pattern: { value: PASSWORD_MATCH_REGEX, message: 'invalid password' } }}
              render={({ field, fieldState }) => (
                <>
                  <LoginTextInput
                    textFieldProps={{
                      ...field,
                      autoComplete: 'new-password',
                      fullWidth: true,
                      id: 'password',
                      label: 'Confirm New Password',
                      name: 'password',
                      type: 'password',
                    }}
                    IconComponent={LockIcon}
                  />
                  {fieldState.isDirty && !arePasswordsMatch ? (
                    <Box color={red[700]}>Passwords don&apos;t match. </Box>
                  ) : (
                    <Box sx={{ opacity: 0 }}>empty</Box>
                  )}
                </>
              )}
            />
          </Box>

          <Box my={s('lg')}>
            <PasswordRule valid={isLong} key={0}>
              Your password has to be at least 8 characters long{' '}
            </PasswordRule>
            <PasswordRule mb={1} valid={hasChar}>
              Must contain at least one lower case
            </PasswordRule>
            <PasswordRule mb={1} valid={hasCapitalChar}>
              letter Must contain at least one upper case letter{' '}
            </PasswordRule>
            <PasswordRule mb={1} valid={hasNumber}>
              Must contain at least one digit
            </PasswordRule>
            <PasswordRule mb={1} valid={!invalidChars?.length}>
              Must not contain invalid characters {invalidChars ? `: ${invalidChars}` : ''}
            </PasswordRule>
          </Box>
        </>
      )}
      {isUpdateConfirmed ? (
        <Button sx={{ mb: s('2xl') }} onClick={() => history.push('/login')}>
          Return To Login
        </Button>
      ) : (
        <Button
          disabled={!arePasswordsMatch || !!newPasswordError}
          type="submit"
          sx={{ mb: s('lg') }}
        >
          Update Password
        </Button>
      )}
    </Box>
  );
}

const BlueHeader = styled('span')({
  fontWeight: 'bold',
  background: '-webkit-linear-gradient(rgba(65, 72, 255, 1), rgba(65, 141, 255, 1))',
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
});

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { mfaToken } = useSelector((state: RootState) => state.users);

  // I wish I could avoid use effect but
  // there is no easy way to make await dispatch
  // in onSubmit
  useEffect(() => {
    if (mfaToken) {
      history.push('/login/mfa');
    }
  }, [mfaToken, history]);

  const AnimatedFooterItem = styled(Box)({
    transition: 'all 0.5s ease-in-out',
  });

  const AnimationDots = ({ active }: { active: number }) => {
    let offsetActive = 0;
    switch (active) {
      case 1:
        offsetActive = 14;
        break;
      case 2:
        offsetActive = 36;
        break;
      default:
        offsetActive = 0;
    }

    return (
      <Box position="relative" width="56px">
        <AnimatedFooterItem
          borderRadius={4}
          border={1}
          width={16}
          height={8}
          color="white"
          bgcolor="#161617"
          position="absolute"
          sx={{ transform: `translate(${offsetActive}px)` }}
        />
        <AnimatedFooterItem
          borderRadius={4}
          border={1}
          width={8}
          height={8}
          color="white"
          bgcolor="grey.400"
          position="absolute"
          sx={{ transform: active === 1 || active === 2 ? 'initial' : 'translateX(20px)' }}
        />
        <AnimatedFooterItem
          borderRadius={4}
          border={1}
          width={8}
          height={8}
          color="white"
          bgcolor="grey.400"
          position="absolute"
          sx={{ transform: active === 2 ? 'translateX(20px)' : 'translateX(40px)' }}
        />
      </Box>
    );
  };

  const AnimationSection = memo(() => {
    const images = [BuildImg, ExplainImg, ManageImg];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const HeadingBuild = (
      <div>
        <Typography component="span" variant="h2">
          {' '}
          <BlueHeader>Build</BlueHeader>
        </Typography>

        <Typography component="span" variant="h2">
          {' '}
          portfolios personalized to every client…
        </Typography>
      </div>
    );
    const HeadingManage = (
      <div>
        <Typography component="span" variant="h2">
          <BlueHeader>Manage</BlueHeader>
        </Typography>
        <Typography variant="h2" component="span">
          {' '}
          portfolios effortlessly with automation….
        </Typography>
      </div>
    );
    const HeadingExplain = (
      <div>
        <Typography variant="h2" component="span">
          ...and{' '}
        </Typography>
        <Typography component="span" variant="h2">
          <BlueHeader>Explain</BlueHeader>
        </Typography>
        <Typography variant="h2" component="span">
          {' '}
          portfolios with curated insights at your fingertips.{' '}
        </Typography>
      </div>
    );

    const carouselTitles = [
      <div key={1}>{HeadingBuild}</div>,
      <div key={2}>{HeadingManage}</div>,
      <div key={3}>{HeadingExplain}</div>,
    ];

    const [currentTitle, setCurrentTitle] = useState<number>(0);

    useInterval(() => {
      setCurrentImageIndex((prev) => {
        return (prev + 1) % 3;
      });
      setCurrentTitle((prev) => (prev + 1) % 3);
    }, 4000);
    return (
      <Box maxWidth="420px">
        <SwitchTransition>
          <Fade in timeout={350} key={images[currentImageIndex]}>
            <div>
              {carouselTitles[currentTitle]}
              <img width="100%" alt="animation" src={images[currentImageIndex]} />
            </div>
          </Fade>
        </SwitchTransition>
        <AnimationDots active={currentImageIndex} />
      </Box>
    );
  });

  const isReset = !!useRouteMatch('/login/reset-password');
  const isMfa = !!useRouteMatch('/login/mfa');
  const isSSO = !!useRouteMatch('/login/sso');
  const isUpdatePassword = !!useRouteMatch('/login/update-password');

  const form = useForm<Inputs>({ mode: 'onChange' });
  const { handleSubmit, setValue } = form;

  const onSubmit = async (e: Inputs) => {
    try {
      if (isReset) {
        dispatch(requestPasswordReset({ email: e.email }));
        history.push('/login/reset-requested');
        return;
      }

      if (isMfa) {
        dispatch(verifyMfa(mfaToken, e.mfaSecret));
        return;
      }

      if (isSSO) {
        dispatch(loginWithSso(e.email));
        return;
      }

      if (isUpdatePassword) {
        dispatch(resetPassword({ resetToken: e.resetToken, password: e.newPassword }));
        setValue('isUpdateConfirmed', true);
        return;
      }

      dispatch(login({ email: e.email, password: e.password }));
    } catch (e) {
      // Ignore on purpose, so we don't redirect to an error.
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: '100vh',
          backgroundImage: `url(${BackgroundImg})`,
          backgroundSize: 'cover',
        }}
      >
        <Box
          position="relative"
          sx={{
            height: '100%',
            marginLeft: '75px',
            borderLeft: `1px solid ${cool[200]}`,
          }}
        >
          <Box
            position="absolute"
            width="75px"
            border={`1px solid ${cool[200]}`}
            left="-75px"
            bottom="50%"
          />
          <Box
            position="absolute"
            width="6px"
            height="6px"
            id="circle"
            borderRadius="6px"
            left="-3px"
            bottom="calc(50% - 2px)"
            sx={{ backgroundColor: 'blue', borderWidth: 0 }}
          />
          <Container maxWidth="lg">
            <Helmet>helmet</Helmet>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container pt={s('6xl')}>
                <Grid xs={12} md={6} display="flex" justifyContent="center">
                  <Box flexBasis="320px" mb={s('6xl')}>
                    <img alt="Vise" height="32" src={ViseLogoBlack} width="32" />
                  </Box>
                </Grid>
                <Grid xs={6} display={isSmallScreen ? 'none' : 'flex'} />
                <Grid xs={12} md={6} display="flex" justifyContent="center" alignItems="flex-start">
                  <Box
                    flexBasis="320px"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Switch>
                      <Route path="/login/reset-password">
                        <ResetPassword {...form} />
                      </Route>
                      <Route path="/login/reset-requested">
                        <RequestPasswordReset />
                      </Route>
                      <Route path="/login/mfa">
                        <Mfa {...form} token={mfaToken} />
                      </Route>
                      <Route path="/login/sso">
                        <SSOLogin {...form} />
                      </Route>
                      <Route path="/login/update-password/:resetToken">
                        <UpdatePassword {...form} />
                      </Route>
                      <Route path="/login">
                        {' '}
                        <LoginSection {...form} />
                      </Route>
                    </Switch>
                    <LoginFooter />
                  </Box>
                </Grid>
                <Grid md={6} display={isSmallScreen ? 'none' : 'flex'}>
                  <AnimationSection />
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
