import { Custodian } from 'vise-types/custodian';

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};

export const getAccountTypeDisplayName = (accountType: string) => {
  return capitalize(accountType.toLowerCase().replace('ira', 'IRA'));
};

export const getCustodianDisplayName = (custodianKey: Custodian) => {
  const mapping = {
    TDA: 'TDA',
    SCHWAB: 'Schwab',
    FIDELITY: 'Fidelity',
  } as { [key in Custodian]: string };
  return mapping[custodianKey];
};

export const capitalizeWord = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
};

export const lastFour = (accountNumber: string) => accountNumber.substr(accountNumber.length - 4);

export const maskAccountNumber = (accountNumber: string) => `•••• ${lastFour(accountNumber)}`;

export const maskAccountNumberIfExists = (accountNumber?: string) =>
  accountNumber ? maskAccountNumber(accountNumber) : 'N/A';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  minimumFractionDigits: 2,
  style: 'currency',
});

export const WHOLE_DOLLAR_FORMATTER = new Intl.NumberFormat(undefined, {
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'currency',
});

export const compactDollarFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  notation: 'compact',
  style: 'currency',
  // TODO: builtin types are missing `notation: 'compact'` despite being supported by browsers.
  // Remove this cast once TS has appropriate types.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);

export const ACCOUNTING_FORMATTER = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  minimumFractionDigits: 2,
  style: 'currency',
  currencySign: 'accounting',
});

export const formatCurrency = (num: number | null | undefined, formatter = currencyFormatter) => {
  if (num === null || num === undefined) {
    return 'N/A';
  }
  return formatter.format(num);
};

// Approximate formatting for projections. Should match what we use in the backend for
// goals outcome in portfolio intelligence
export function formatApproximate(num: number | null | undefined) {
  if (num == null) {
    return 'N/A';
  }
  let roundedNum;
  if (num > 1e6) {
    roundedNum = Math.round(num / 1e5) * 1e5;
  } else if (num > 1e4) {
    roundedNum = Math.round(num / 1000) * 1000;
  } else {
    roundedNum = Math.round(num / 100) * 100;
  }
  return formatCurrency(roundedNum, WHOLE_DOLLAR_FORMATTER);
}

export const formatPercent = (num: number | null | undefined, numDigits = 2, suffix = '%') => {
  if (num === null || num === undefined) {
    return 'N/A';
  }

  return `${(num * 100).toFixed(numDigits)}${suffix}`;
};

const toFixedNumber = (num: number, numDigits: number) => {
  const pow = 10 ** numDigits;
  return Math.round(num * pow) / pow;
};

export const formatQuantity = (num: number | null | undefined) => {
  if (num === null || num === undefined) {
    return 'N/A';
  }
  return toFixedNumber(num, 2).toLocaleString();
};

/** This is different from formatPercent in that it converts a number into its percent value an
 * returns it in number (NOT string) form, without the "%"" afterwards.
 */
export const convertToPercent = (num: number, numDigits = 2) => toFixedNumber(num * 100, numDigits);

export const isPositiveInteger = (value: string) => {
  return /^\d+$/.test(value);
};

// MM/DD/YYYY
export const formatDate = (date: Date) => {
  return date.toLocaleDateString('en-US');
};
