import React, { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { Redirect, useHistory } from 'react-router';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import {
  checkGPTJob,
  confirmDisclosures,
  getSessionMessages,
  getSessions,
  getViseIntelSettings,
  newSession,
  postGPTQuestion,
  rateGPTMessage,
} from '~/api/gptApi';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import amplitude from '~/utils/amplitude';
import { ReactComponent as RefreshIcon } from '~/static/images/icons/refresh.svg';
import { ReactComponent as FeedbackIcon } from '~/static/images/icons/mail-outline.svg';
import { ReactComponent as CogIcon } from '~/static/images/icons/cog.svg';

import { CloseIcon, TimeIcon } from '~/synth/Icons';
import ViseLogoBlack from '~/static/images/vise-logo-black.svg';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useGPTJob from '~/hooks/useGPTJob';
import { GPTMessage, GPTSession } from 'vise-types/gpt';
import { ArrowLeft } from '@mui/x-date-pickers/internals/components/icons';
import AssistantMessage from './AssistantMessage';
import UserMessage from './UserMessage';
import GPTInputField from './GPTInputField';
import PromptSuggestions from './PromptSuggestions';
import GPTDisclosuresModal from './GPTDisclosuresModal';
import FeedbackModal from './FeedbackModal';
import GPTSettings from './GPTSettings';
import SessionHistoryDrawer from './SessionHistoryDrawer';

const GPTDashboard = () => {
  const theme = useTheme();
  const [experimental, setExperimental] = useState(false);
  const lastUserMessageRef = React.useRef<HTMLDivElement>(null);

  const [recommendedPrompts, setRecommendedPrompts] = useState<string[]>([]);
  const [selectedRecommendedQuestion, setSelectedRecommendedQuestion] = useState<string>('');

  const [showDisclosuresModal, setShowDisclosuresModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showSessionHistoryDrawer, setShowSessionHistoryDrawer] = useState(false);

  const [typingFinished, setTypingFinished] = useState(true);
  const [recentlyLoaded, setRecentlyLoaded] = useState(false);
  const [pendingJobId, setPendingJobId] = useState<string | null>(null);
  const [currentSessionId, setCurrentSessionId] = useState('');
  const [messages, setMessages] = useState<GPTMessage[]>([]);
  const [allSessions, setAllSessions] = useState<GPTSession[]>([]);

  const history = useHistory();
  const enqueueCoachmark = useEnqueueCoachmark();
  const { data: featureFlags } = useFeatureFlags();

  const scrollToBottom = () => {
    const element = document.getElementById('text-content');
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  const loadSessionHistory = useCallback(async () => {
    try {
      const sessions = await getSessions();
      const latestSession = sessions[0];
      if (!latestSession) {
        const job = await newSession(false);
        const newResponsePlaceholder = {
          id: job.responseMessageId,
          botName: 'viseIntelligence',
          chatRole: 'assistant',
          messageContent: job.responseContent,
          loading: true,
          steps: job.steps,
          recommendedPrompts: [],
        } as GPTMessage;
        setMessages([newResponsePlaceholder]);
        setRecentlyLoaded(true);
        setPendingJobId(job.id);
        setCurrentSessionId(job.sessionId);
        const newSessions = await getSessions();
        setAllSessions(newSessions);

        return;
      }

      setCurrentSessionId(latestSession.sessionId);
      setAllSessions(sessions);
    } catch (e) {
      Sentry.captureException(new Error(`[Intel] Failed to load session history.`));
    }
  }, []);

  const loadSessionData = useCallback(async (sessionId?: string) => {
    if (!sessionId) {
      return;
    }
    try {
      const data = await getSessionMessages(sessionId);

      if (data.existingJobId) {
        const job = await checkGPTJob(data.existingJobId);
        const newResponsePlaceholder = {
          id: job.responseMessageId,
          botName: 'viseIntelligence',
          chatRole: 'assistant',
          messageContent: job.responseContent,
          loading: true,
          steps: job.steps,
          recommendedPrompts: [],
          validations: undefined,
        } as GPTMessage;
        setMessages([...data.messages, newResponsePlaceholder]);
        setRecommendedPrompts(job?.recommendedPrompts || []);
        setRecentlyLoaded(true);
        setPendingJobId(data.existingJobId);
        setTypingFinished(false);
      } else {
        const lastMessage = data.messages[data.messages.length - 1];
        if (lastMessage) {
          setRecommendedPrompts(lastMessage.recommendedPrompts || []);
        }
        setMessages(data.messages || []);
        setTypingFinished(true);
        setPendingJobId(null);
        setRecentlyLoaded(false);
      }
    } catch {
      Sentry.captureException(new Error(`[Intel] Failed to load latest session.`));
    }
  }, []);

  const updateMessagesWithJob = useCallback(
    (job) => {
      let loading = true;
      if (job.jobStatus === 'DONE') {
        loading = false;
        setPendingJobId(null);
      }
      const newMessages = messages.map((msg) => {
        if (msg.id === job.responseMessageId) {
          return {
            ...msg,
            messageContent: job.responseContent,
            loading,
            steps: job.steps,
            validations: job.validations,
          };
        }
        return msg;
      });
      setMessages(newMessages);
      setRecommendedPrompts(job.recommendedPrompts);
    },
    [messages]
  );

  useGPTJob(pendingJobId, (data) => updateMessagesWithJob(data));

  // initial load
  useEffect(() => {
    async function loadSettings() {
      try {
        const data = await getViseIntelSettings();
        if (!data.disclosuresConfirmed) {
          setShowDisclosuresModal(true);
          return;
        }

        if (data.enabledAccounts && data.enabledAccounts.length === 0) {
          setShowSettings(true);
        }
      } catch (e) {
        Sentry.captureException(new Error(`[Intel] Failed to load settings on dashboard.`), {
          extra: { error: e },
        });
      }
    }

    loadSettings();

    loadSessionHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDisclosuresModal]);

  // load data when sessionId changes
  useEffect(() => {
    loadSessionData(currentSessionId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSessionId]);

  const handleRefreshClick = useCallback(async () => {
    setCurrentSessionId('');
    setPendingJobId(null);
    setMessages([]);
    setRecommendedPrompts([]);
    const job = await newSession(experimental);
    await loadSessionHistory();
    const newResponsePlaceholder = {
      id: job.responseMessageId,
      botName: 'viseIntelligence',
      chatRole: 'assistant',
      messageContent: job.responseContent,
      loading: true,
      steps: job.steps,
      recommendedPrompts: [],
    } as GPTMessage;
    setMessages([newResponsePlaceholder]);
    setRecentlyLoaded(true);
    setPendingJobId(job.id);
  }, [experimental, loadSessionHistory]);

  const addRatingToMessage = useCallback(
    (rating, messageId) => {
      const newMessages = messages.map((msg) => {
        if (msg.id === messageId) {
          return {
            ...msg,
            rating,
          };
        }
        return msg;
      });
      setMessages(newMessages);
    },
    [messages, setMessages]
  );

  const handleRatingClick = useCallback(
    async (rating, messageId) => {
      await rateGPTMessage(rating, currentSessionId, messageId);
      addRatingToMessage(rating, messageId);
    },
    [currentSessionId, addRatingToMessage]
  );

  const submitGptQuestion = useCallback(
    async (gptQuestion, experimental) => {
      if (!gptQuestion) {
        return;
      }

      amplitude().logEvent(`submit-gpt-question`, {
        category: EVENT_CATEGORIES.SUBMIT_GPT_QUESTIONS,
      });

      try {
        const data = await postGPTQuestion(gptQuestion, currentSessionId, experimental);

        const newUserMsg = {
          id: data.requestMessageId,
          botName: 'viseIntelligence',
          chatRole: 'user',
          messageContent: gptQuestion,
          recommendedPrompts: [],
        } as GPTMessage;
        const newResponsePlaceholder = {
          id: data.responseMessageId,
          botName: 'viseIntelligence',
          chatRole: 'assistant',
          messageContent: data.responseContent,
          recommendedPrompts: [],
        } as GPTMessage;
        setMessages([...messages, newUserMsg, newResponsePlaceholder]);
        setRecentlyLoaded(true);
        setPendingJobId(data.id);
        setTypingFinished(false);
      } catch (error) {
        Sentry.captureException(new Error(`[Intel] Failed to submit a question.`), {
          extra: { error },
        });
        enqueueCoachmark({
          title:
            "Sorry, we're still in Alpha and have rate limited to the use of Vise Intelligence.",
          content: 'Try again in a few minutes.',
          severity: 'error',
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [currentSessionId, messages, enqueueCoachmark]
  );

  const outdatedSession = allSessions[0]?.sessionId !== currentSessionId;

  if (featureFlags?.enable_gpt !== 'on') {
    return <Redirect to="/secure/dashboard" />;
  }

  if (!messages) {
    return <Box />;
  }

  return (
    <>
      {/* header */}

      <Box
        maxWidth="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="7vh"
        position="sticky"
        top={0}
        bgcolor="white"
        borderBottom="1px solid #F7F7F7"
        boxShadow="0px 2px 2px 0px rgba(0, 0, 0, 0.05)"
        zIndex={1000}
      >
        <SessionHistoryDrawer
          isOpen={showSessionHistoryDrawer}
          onClose={() => setShowSessionHistoryDrawer(false)}
          sessions={allSessions}
          onRowClick={(session) => setCurrentSessionId(session.sessionId)}
          selectedSessionId={currentSessionId}
        />
        <Box width="80%" display="flex" alignItems="center" margin="auto">
          <Box display="flex" gap={2} alignItems="center">
            <img alt="V" src={ViseLogoBlack} height="16" />
            <Typography variant="h4" color="neutralCool.900">
              Vise Intelligence
            </Typography>
          </Box>
          {!showSettings && (
            <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
              {featureFlags?.enable_gpt_developer_mode === 'on' && (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        value={experimental}
                        onChange={() => setExperimental(!experimental)}
                      />
                    }
                    label={
                      <Typography variant="body2" fontSize={14}>
                        Experimental features
                      </Typography>
                    }
                  />
                  <RefreshIcon
                    height="20"
                    width="20"
                    style={{ cursor: 'pointer' }}
                    onClick={handleRefreshClick}
                  />
                </>
              )}
              <FeedbackIcon
                height="17"
                width="17"
                style={{ cursor: 'pointer' }}
                onClick={() => setShowFeedbackModal(true)}
              />
              <CogIcon
                height="20"
                width="20"
                style={{ cursor: 'pointer' }}
                onClick={() => setShowSettings(true)}
              />
              <IconButton
                aria-label="Exit"
                color="inherit"
                onClick={() => history.goBack()}
                role="link"
                size="large"
              >
                <CloseIcon display="inline-flex" />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      {showSettings && (
        <Box width="80%" margin="auto">
          <GPTSettings closeSettings={() => setShowSettings(false)} />
        </Box>
      )}

      {!showSettings && (
        <Box
          height="93vh"
          id="content-container"
          display="flex"
          width="100%"
          position="relative"
          overflow="hidden"
        >
          {/* background gradient */}
          <Box
            sx={{
              background:
                'radial-gradient(50% 50.00% at 50% 50.00%, #E0D2E6 0%, #C8D9F8 43.23%, rgba(200, 217, 248, 0.00) 100%);',
              backgroundRepeat: 'no-repeat',
              borderRadius: '1893px',
              opacity: '0.6',
              position: 'absolute',
              top: 500,
              left: 500,
              zIndex: -1,
              width: '1879px',
              height: '1300px',
            }}
          />

          {/* content */}
          <Box height="100%" display="flex" flexDirection="column" flex={1}>
            {/* text content */}
            <Box
              sx={{ overflowY: 'scroll', scrollBehavior: 'smooth' }}
              id="text-content"
              width="100%"
              height="100%"
              mb="-2rem" // add negative margin to slightly overlap the chatbox
            >
              <Box id="all-messages-container" maxWidth="1180px" margin="auto" width="100%" pb={7}>
                {messages.map((message, i) => {
                  let element;
                  if (message.chatRole === 'assistant') {
                    const shouldType = i === messages.length - 1 && recentlyLoaded;
                    element = (
                      <AssistantMessage
                        content={message.messageContent}
                        shouldType={shouldType}
                        rating={message.rating || 0}
                        onRatingClick={(newRating) => handleRatingClick(newRating, message.id)}
                        steps={message.steps || []}
                        loading={message.loading}
                        setTypingFinished={(value: boolean) => setTypingFinished(value)}
                        typingFinished={shouldType ? typingFinished : true}
                        messageId={message.id}
                        validations={message.validations || []}
                        isOutdated={allSessions[0]?.sessionId !== currentSessionId}
                      />
                    );
                  } else {
                    element = <UserMessage content={message.messageContent} />;
                  }
                  return (
                    <Box
                      key={message.id}
                      id="single-message"
                      my={3}
                      minHeight={
                        i === messages.length - 1
                          ? `calc(93vh - ${
                              172 + (lastUserMessageRef.current?.clientHeight || 0)
                            }px)`
                          : undefined
                      }
                      ref={i === messages.length - 2 ? lastUserMessageRef : undefined}
                    >
                      {element}
                    </Box>
                  );
                })}

                <Box display="flex" gap={4} mt={4} alignItems="center" justifyContent="center">
                  {recommendedPrompts.length > 0 && typingFinished && !outdatedSession && (
                    <PromptSuggestions
                      suggestions={recommendedPrompts}
                      onClick={(suggestion) => setSelectedRecommendedQuestion(suggestion)}
                    />
                  )}

                  {outdatedSession && (
                    <Box>
                      <Button
                        onClick={() => {
                          if (allSessions[0]?.sessionId) {
                            setCurrentSessionId(allSessions[0].sessionId);
                          }
                        }}
                        size="small"
                        color="secondary"
                        variant="outlined"
                        startIcon={<ArrowLeft display="inline-flex" />}
                        sx={{
                          backgroundColor: 'white',
                          borderColor: theme.palette.grey[300],
                          padding: '10px 16px',
                          '&:hover': {
                            borderColor: theme.palette.grey[400],
                            backgroundColor: theme.palette.grey[200],
                          },
                        }}
                      >
                        Move back to the most recent session
                      </Button>
                    </Box>
                  )}

                  <Divider orientation="vertical" flexItem />
                  <Box>
                    <Button
                      onClick={() => setShowSessionHistoryDrawer(true)}
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<TimeIcon display="inline-flex" />}
                      sx={{
                        backgroundColor: 'white',
                        borderColor: theme.palette.grey[300],
                        padding: '10px 16px',
                        '&:hover': {
                          borderColor: theme.palette.grey[400],
                          backgroundColor: theme.palette.grey[200],
                        },
                      }}
                    >
                      Recent
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* chatbox */}
            <Box
              sx={{ backgroundColor: 'rgb(255,255,255,0.0)', backdropFilter: 'blur(20px)' }}
              height="10rem"
              width="100%"
              maxWidth="1180px"
              margin="auto"
              mb="2rem"
              boxShadow="0px 20px 100px 0px rgba(255,255,255,0.8)"
              flex={0}
              borderRadius="12px"
            >
              {!outdatedSession && (
                <Box margin="auto" width="100%">
                  <GPTInputField
                    onSubmit={async (inputQuestion) => {
                      await submitGptQuestion(inputQuestion, experimental);
                      setTimeout(() => {
                        scrollToBottom();
                      }, 200);
                    }}
                    disabled={pendingJobId !== null}
                    value={selectedRecommendedQuestion}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}

      {showDisclosuresModal && (
        <GPTDisclosuresModal
          isOpen={showDisclosuresModal}
          onClose={() => {
            history.goBack();
          }}
          onConfirm={async () => {
            try {
              await confirmDisclosures();
              setShowDisclosuresModal(false);
            } catch (error) {
              enqueueCoachmark({
                title: 'Failed to confirm disclosures.',
                content: 'Try again in a few minutes.',
                severity: 'error',
              });
            }
          }}
        />
      )}
      {showFeedbackModal && (
        <FeedbackModal
          open={showFeedbackModal}
          onClose={() => setShowFeedbackModal(false)}
          type="session"
          resourceId={currentSessionId}
        />
      )}
    </>
  );
};

export default GPTDashboard;
