import React, { useMemo, useState } from 'react';
import { GetModelTemplateCenterViewDataResponse, TemplateUpdateJob } from 'vise-types/template';
import { Box, Button, InputAdornment, Typography, useTheme } from '@mui/material';
import TextField from '~/synth/TextField';
import { SearchIcon } from '~/synth/Icons';
import { DataTable } from '~/synth/DataTable';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import { Link } from 'react-router-dom';
import { Column, TableState, UsePaginationState, usePagination, useTable } from 'react-table';
import { matchSorter } from 'match-sorter';
import { formatCurrency, formatDate } from '~/utils/format';
import { flatten, keyBy } from 'lodash';
import { TextHighlightTag } from '~/synth/Tag';
import PageNavigation from '~/components/table/PageNavigation';
import AccountFilter, {
  filterAccounts,
  initialFilterState,
} from '~/components/portfolio/AccountFilter';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import Card from './workflows/Card';
import { AccountCell } from './workflows/CommonComponents';
import ValueCell from '../Households/Households/ValueCell';
import { getStaleAccountsFromViewData } from './utils';
import EmptyState from './EmptyState';

const PAGE_SIZE = 10;

interface AccountTableData {
  account: AccountWithPIAndHouseholdInfo;
  status: 'UP_TO_DATE' | 'UPDATE_AVAILABLE' | 'UPDATE_IN_PROGRESS';
  linkedSince: string;
}

function StatusCell(props: { value: AccountTableData['status'] }) {
  let severity: 'success' | 'warning' | 'alert' = 'success';
  let text = 'Up to date';
  if (props.value === 'UPDATE_AVAILABLE') {
    severity = 'warning';
    text = 'Update available';
  }
  if (props.value === 'UPDATE_IN_PROGRESS') {
    severity = 'alert';
    text = 'Update in progress';
  }
  return <TextHighlightTag severity={severity}>{text}</TextHighlightTag>;
}

const columns: Column<AccountTableData>[] = [
  { Header: 'Account', accessor: 'account', Cell: AccountCell, width: '31%' },
  {
    Header: 'Status',
    accessor: 'status',
    width: '18%',
    Cell: StatusCell,
  },
  {
    Header: 'Linked since',
    accessor: 'linkedSince',
  },
  {
    Header: 'Value',
    accessor: 'account',
    Cell: (props: { value: AccountWithPIAndHouseholdInfo }) => (
      <ValueCell value={formatCurrency(props.value.cachedAum)} isLoading={false} />
    ),
    id: 'value',
  },
  {
    Header: '',
    id: 'view account',
    accessor: 'account',
    Cell: (props: { value: AccountWithPIAndHouseholdInfo }) => (
      <Button
        component={Link}
        to={`/secure/accounts/${props.value.id}/portfolio/overview`}
        color="secondary"
      >
        View account
      </Button>
    ),
  },
];

export default function LandingPageAccountTable({
  data,
  templateId,
  jobs,
}: {
  data: GetModelTemplateCenterViewDataResponse;
  templateId: string;
  jobs?: TemplateUpdateJob[];
}) {
  const [filterQuery, setFilterQuery] = useState('');
  const [accountFilterState, setAccountFilterState] = useState(initialFilterState);
  const unfilteredTableData = useMemo(() => {
    const accountsWithJobs = new Set(
      flatten((jobs || []).map((job) => job.events)).map((event) => event.accountId)
    );
    const accounts = keyBy(data.accounts, 'id');
    const staleAccountIdsByFreshTemplateId = getStaleAccountsFromViewData(data);
    const staleAccountIds = new Set(staleAccountIdsByFreshTemplateId[templateId] || []);
    const allTemplates = [...data.allocationsTemplates, ...data.restrictionsTemplates];
    const template = allTemplates.find((t) => t.parentId === templateId);
    return data.templateLinks
      .map((link) => {
        const account = accounts[link.accountId];
        if (
          !account ||
          allTemplates.find((t) => t.parentId === link.templateId)?.originalTemplateId !==
            template?.originalTemplateId
        ) {
          return null;
        }
        let status: AccountTableData['status'] = 'UP_TO_DATE';
        if (staleAccountIds.has(link.accountId)) {
          status = 'UPDATE_AVAILABLE';
        }
        if (accountsWithJobs.has(link.accountId)) {
          status = 'UPDATE_IN_PROGRESS';
        }
        return {
          account,
          status,
          linkedSince: formatDate(new Date(link.linkedSince)),
        };
      })
      .filter(
        (row: AccountTableData | null): row is AccountTableData => !!row
      ) as AccountTableData[];
  }, [data, jobs, templateId]);
  const tableData = useMemo(() => {
    const accountsFilteredByDropdown = new Set(
      filterAccounts(
        unfilteredTableData.map((row) => row.account),
        accountFilterState,
        data
      ).map((acct) => acct.id)
    );
    return matchSorter(unfilteredTableData, filterQuery, {
      keys: ['account.accountName', 'account.accountNumber'],
      threshold: matchSorter.rankings.CONTAINS,
    })
      .filter((row) => accountsFilteredByDropdown.has(row.account.id))
      .sort((account1, account2) => {
        if (account1?.status === 'UP_TO_DATE' && account2?.status !== 'UP_TO_DATE') {
          return 1;
        }
        if (account1?.status !== 'UP_TO_DATE' && account2?.status === 'UP_TO_DATE') {
          return -1;
        }
        return (account2?.account.cachedAum || 0) - (account1?.account.cachedAum || 0);
      }) as AccountTableData[];
  }, [data, filterQuery, accountFilterState, unfilteredTableData]);
  const theme = useTheme();
  const table = useTable<AccountTableData>(
    {
      columns,
      data: tableData,
      initialState: {
        pageSize: PAGE_SIZE,
      } as TableState<AccountTableData> & UsePaginationState<AccountTableData>,
      autoResetPage: false,
    },
    usePagination
  );
  return (
    <Card fullWidth maxWidth="100%">
      <Box mx={3} mb={3} display="flex" alignItems="start" justifyContent="space-between">
        <Typography variant="h3">Linked accounts</Typography>
        <Box width="360px">
          <TextField
            fullWidth
            value={filterQuery}
            onChange={(e) => setFilterQuery(e.target.value)}
            placeholder="Search by name or account number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <AccountFilter
                  accounts={unfilteredTableData.map((row) => row.account)}
                  filterState={accountFilterState}
                  onApplyFilter={(filterState) => {
                    setAccountFilterState(filterState);
                    table.gotoPage(0);
                  }}
                  eventCategory={EVENT_CATEGORIES.STRATEGY_CENTER}
                />
              ),
              sx: { paddingRight: 0 },
            }}
          />
        </Box>
      </Box>
      {tableData.length === 0 && (
        <EmptyState
          height={400}
          title="No accounts linked"
          body="Linked accounts show up here"
          showCTA={false}
        />
      )}
      {tableData.length > 0 && (
        <>
          <DataTable m={0} table={table} />
          {tableData.length > PAGE_SIZE && (
            <Box px={3} pt={4} display="flex" borderTop={`1px solid ${theme.palette.grey[100]}`}>
              <Box flex={1} />
              <PageNavigation
                currentPage={(table.state as UsePaginationState<AccountTableData>).pageIndex + 1}
                numItems={tableData.length}
                pageSize={PAGE_SIZE}
                onNextPageClick={() => {
                  table.nextPage();
                }}
                onPrevPageClick={() => {
                  table.previousPage();
                }}
                onPageNumberClick={(pageNumber) => {
                  table.gotoPage(pageNumber - 1);
                }}
              />
            </Box>
          )}
        </>
      )}
    </Card>
  );
}
