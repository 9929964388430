import { Box, BoxProps, Divider, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CENTER_CARD_WIDTH_LARGE } from './constants';

export default function Card({
  children,
  header,
  fullWidth,
  maxWidth = CENTER_CARD_WIDTH_LARGE,
  ...boxProps
}: {
  maxWidth?: BoxProps['maxWidth'];
  children: React.ReactNode;
  header?: React.ReactNode;
  fullWidth?: boolean;
} & BoxProps) {
  const theme = useTheme();
  return (
    <Box
      bgcolor="white"
      border={`solid 1px ${theme.palette.grey[200]}`}
      borderRadius="4px"
      maxWidth={maxWidth}
      width="100%"
      boxShadow={theme.shadows[1]}
      {...boxProps}
    >
      {header && (
        <>
          <Box px={3} py={2.5}>
            {header}
          </Box>
          <Divider />
        </>
      )}
      <Box px={fullWidth ? 0 : 3} pb={4} pt={header ? 3 : 4}>
        {children}
      </Box>
    </Box>
  );
}

export function CardTitle({
  title,
  middle,
  right,
}: {
  title: React.ReactNode;
  right?: React.ReactNode;
  middle?: React.ReactNode;
}) {
  return (
    <Box>
      <Box display="flex">
        <Box width="60%">
          <Typography variant="h4">{title}</Typography>
        </Box>
        <Box width="20%" textAlign="end">
          {middle}
        </Box>
        <Box width="20%" textAlign="end">
          {right}
        </Box>
      </Box>
      <Box my={1.5}>
        <Divider />
      </Box>
    </Box>
  );
}
