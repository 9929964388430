import styled, { css } from 'styled-components';
import InteractiveContainerBase from './InteractiveContainerBase';

// padding-left on this container counteracts the negative margin-left of `MuiFormLabel`, which is
// the only child currently used inside this container. (ex. AssetClassesScreen.tsx,
// CashAllocationScreen.tsx)
const SelectorRow = styled(InteractiveContainerBase)`
  border: 0;
  border-radius: 0;
  padding: ${({ theme }) => theme.spacing(2)} 0 ${({ theme }) => theme.spacing(2)} 3px;
  ${({ selectable, theme }) =>
    selectable
      ? css`
          &:hover:not(.disabled),
          &:focus:not(.disabled) {
            .CheckboxIcon {
              box-shadow: inset 0 0 0 1px ${theme.palette.primary.main},
                inset 0 -1px 0 ${theme.palette.primary.main};
            }
            .RadioIcon {
              box-shadow: inset 0 0 0 1px ${theme.palette.primary.main},
                inset 0 -1px 0 ${theme.palette.primary.main};
            }
          }
          &:hover:not(.disabled) {
            cursor: pointer;
          }
          &:not(.disabled) {
            cursor: pointer;
          }
        `
      : ''};
`;

export const SelectorRowGroup = styled.div<{ noBorders?: boolean }>`
  margin: 0;
  & ${SelectorRow} {
    border-bottom: ${(props) =>
      props.noBorders ? null : `solid 1px ${({ theme }) => theme.palette.grey[300]}`};
  }
  & ${SelectorRow}:first-child {
    border-top: ${(props) =>
      props.noBorders ? null : `solid 1px ${({ theme }) => theme.palette.grey[300]}`};
  }
`;

export default SelectorRow;
