// eslint-disable-next-line import/prefer-default-export
export const EVENT_CATEGORIES = {
  NAVIGATION: 'Navigation',
  LOGIN: 'Login',
  PORTFOLIO_OVERVIEW: 'Portfolio overview',
  PROPOSAL_OVERVIEW: 'Proposal overview',
  HOUSEHOLDS: 'Households',
  PORTFOLIO_CONSTRUCTION: 'Portfolio Construction',
  PORTFOLIO_EXECUTION: 'Portfolio Execution',
  BULK_MIGRATION: 'Bulk Migration',
  DISTRIBUTIONS: 'Distributions',
  SUBMIT_GPT_QUESTIONS: 'Submit GPT Questions',
  STRATEGY_CENTER: 'Strategy center',
  LIQUIDATE_ACCOUNT: 'Liquidate account',
  REMOVE_ACCOUNT: 'Remove account',
  BOND_BUILDER: 'Bond Builder',
};
