import { Box, Grid, Link as MuiLink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';

export default function PortfolioFooterDisclosures() {
  return (
    <Box mt={8}>
      <Typography color="textSecondary" paragraph variant="body1">
        <strong>Disclosures</strong>
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm xs={12}>
          <Typography color="textSecondary" paragraph variant="caption">
            Holdings and values are generally based on same-day morning prices. Prices and values
            for proposed trades are based on the end-of-day closing of the day on which the
            recommendations were last generated.
          </Typography>
          <Typography color="textSecondary" paragraph variant="caption">
            Past performance is not indicative of future results. Differences in account size,
            timing of transactions and market conditions prevailing at the time of investment may
            lead to different results, and clients may lose money.
          </Typography>
          <Typography color="textSecondary" paragraph variant="caption">
            DISCLAIMER: THERE ARE NO WARRANTIES, EXPRESSED OR IMPLIED, AS TO ACCURACY, COMPLETENESS,
            OR RESULTS OBTAINED FROM ANY INFORMATION PROVIDED HEREIN OR ON THE MATERIAL PROVIDED.
            The site does not constitute a complete description of Vise&apos;s investment services
            and is for informational purposes only. Any statements regarding market or other
            financial information is obtained from sources which Vise and its suppliers believe to
            be reliable, but Vise does not warrant or guarantee the timeliness or accuracy of this
            information. Neither Vise&apos;s information providers nor Vise shall be liable for any
            errors or inaccuracies, regardless of cause, or the lack of timeliness of, or for any
            delay or interruption in the transmission thereof to the user. All investments involve
            risk, including foreign currency exchange rates, political risks, market risk, different
            methods of accounting and financial reporting, and foreign taxes. Your use of these and
            all materials provided by Vise, including the www.vise.com website is your
            acknowledgement that you have read and understood the full disclaimer as stated above.
          </Typography>
        </Grid>
        <Grid item sm xs={12}>
          <Typography color="textSecondary" paragraph variant="caption">
            <strong>Simulated backtest</strong>
          </Typography>
          <Typography color="textSecondary" paragraph variant="caption">
            Backtested performance above represents hypothetical returns and does not reflect
            trading in actual client accounts. Backtested performance also differs from actual
            performance because it is achieved through the retroactive application of portfolios
            designed with the benefit of hindsight. As a result, the portfolios used in the
            backtesting process may be changed from time to time and the effect on hypothetical
            performance results could be either favorable or unfavorable. As a simulation
            methodology, in general, backtesting has certain limitations. It does not involve or
            take into account financial risk and does not take into account that material and market
            factors may have impacted investment decision making, all of which can adversely affect
            actual trading results and performance. Backtested performance also does not represent
            trading costs or the impact of taxes.
          </Typography>
          <Typography color="textSecondary" paragraph variant="caption">
            <strong>Monte Carlo simulations</strong>
          </Typography>
          <Typography color="textSecondary" paragraph variant="caption">
            The Monte Carlo simulation results above are provided for educational purposes and to
            model the probability of different outcomes of using Vise&apos;s services. This Monte
            Carlo model is intended to project how the portfolio may perform over a range of various
            market outcomes, based upon the composition of the portfolio, the investment time
            horizon, and any contributions. The Monte Carlo results are created using statistical
            sampling to generate a large number of possible future portfolio returns. The median
            return can be used as an illustration of one potential outcome but actual returns are
            likely to be different.
          </Typography>
          <Typography color="textSecondary" paragraph variant="caption">
            See important information about our{' '}
            <MuiLink component={Link} to="/secure/disclosures">
              methodology and assumptions.
            </MuiLink>
            .
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
