import React, { useReducer, useState, useMemo, useEffect } from 'react';
import DialogTitle from '~/synth/DialogTitle';
import { Box, Button, Dialog, DialogActions, Typography, Divider } from '@mui/material';
import confirmationModalImage from '~/static/images/modelTemplateCenter/account-linking-confirmation.svg';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import { updateLinkedAccounts } from '~/api/api';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import { keyBy } from 'lodash';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import useTemplatesByParentIds from '~/hooks/templates/useTemplatesByParentIds';

import { EVENT_CATEGORIES } from '~/constants/amplitude';
import amplitude from '~/utils/amplitude';
import ActionBar from './ActionBar';
import Container from './Container';
import Stepper, { Step } from './Stepper';
import Unlink from './UnlinkTable';
import AccountLinkTable from './LinkTable';
import reducer from './templateLinkReducer';
import LinkingSummary from './LinkingSummary';
import { VerticalDivider, scrollToTop } from './CommonComponents';
import ExecuteDialog from './ExecuteDialog';

interface ConfirmationModalProps {
  onClose: () => void;
  numberOfAccounts: number;
  onSubmit: () => void;
  disabled: boolean;
  doNotGenerateProposals: boolean;
  accountsToUnlink: AccountWithPIAndHouseholdInfo[];
}

type STEP_TYPE = 'LINK' | 'UNLINK' | 'REVIEW';

function ConfirmationModal({
  onClose,
  numberOfAccounts,
  onSubmit,
  disabled,
  doNotGenerateProposals,
  accountsToUnlink,
}: ConfirmationModalProps) {
  if (doNotGenerateProposals) {
    return (
      <ExecuteDialog
        open
        onClose={onClose}
        linkUnlinkData={accountsToUnlink.map((acc) => ({
          name: acc.accountName,
          action: 'UNLINK',
        }))}
        onSubmit={onSubmit}
      />
    );
  }
  return (
    <Box maxWidth="470px">
      <Dialog open onClose={onClose}>
        <DialogTitle onClose={onClose} />
        <Box display="flex" alignItems="center" justifyContent="center" height="100%" my={1}>
          <img src={confirmationModalImage} alt="" />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" my={1}>
          <Typography variant="h2">Generate proposals</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="grey.500"
          textAlign="center"
          mb={1.5}
        >
          <Box width="70%">
            <Typography>
              You&apos;ll be able to review proposals for {numberOfAccounts || 0} accounts before
              saving/executing your changes to this template.
            </Typography>
          </Box>
        </Box>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disabled={disabled}>
            Cancel
          </Button>
          <Button disabled={disabled} variant="contained" color="primary" onClick={onSubmit}>
            Generate proposals
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default function TemplateLink() {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [stepChoice, setStepBase] = useState<STEP_TYPE>('UNLINK');
  const setStep = (step) => {
    setStepBase(step);
    scrollToTop();
  };
  const history = useHistory();
  const match = useRouteMatch<{ parentTemplateId: string }>();
  const { parentTemplateId } = match.params;
  const { data, error, mutate: accountLinkPageDataMutate } = useModelTemplateCenterViewData();

  const { data: templates } = useTemplatesByParentIds({ ids: [parentTemplateId] });
  const template = templates && templates[0];

  const [saving, setSaving] = useState<boolean>(false);

  const [state, dispatch] = useReducer(reducer, {
    unlinkAccountsIds: [],
    linkAccountsIds: [],
  });
  const linkOnlyStepMode = useMemo(
    () =>
      data?.data.templateLinks.filter((l) => l.originalTemplateId === template?.originalTemplateId)
        .length === 0,
    [data?.data.templateLinks, template?.originalTemplateId]
  );

  const step = linkOnlyStepMode && stepChoice === 'UNLINK' ? 'LINK' : stepChoice;

  useEffect(() => {
    amplitude().logEvent('Impression - template linking page', {
      category: EVENT_CATEGORIES.STRATEGY_CENTER,
      step,
    });
  }, [step]);

  const footer = (step: STEP_TYPE | undefined) => {
    if (!step) {
      return <></>;
    }
    switch (step) {
      case 'UNLINK':
        return (
          <ActionBar
            showBackButton={false}
            continueButtonProps={{
              onClick: () => {
                setStep('LINK');
              },
            }}
          />
        );
      case 'LINK':
        return (
          <ActionBar
            showBackButton={!linkOnlyStepMode}
            continueButtonProps={{
              onClick: () => {
                setStep('REVIEW');
              },
              disabled: state.unlinkAccountsIds.length === 0 && state.linkAccountsIds.length === 0,
            }}
            backButtonProps={
              linkOnlyStepMode
                ? undefined
                : {
                    onClick: () => {
                      setStep('UNLINK');
                    },
                  }
            }
          />
        );
      case 'REVIEW':
        return (
          <ActionBar
            showBackButton
            continueButtonProps={{
              onClick: () => setShowConfirmationModal(true),
              children: 'Finish editing',
            }}
            backButtonProps={{
              onClick: () => {
                setStep('LINK');
              },
            }}
          />
        );
      default:
        throw Error(`Step value is invalid: step: ${step}`);
    }
  };

  const accountsById = useMemo(() => keyBy(data?.data.accounts, 'id'), [data]);
  const enqueueCoachmark = useEnqueueCoachmark();
  if (!data && !error) {
    return <Container isLoading />;
  }
  if (error) {
    return <Typography variant="body2">Error loading data</Typography>;
  }
  if (!data || !template) {
    return null;
  }
  return (
    <Container
      templateType={template.type}
      footer={footer(step)}
      headerBarContent={{
        left: (
          <Box color="text.secondary">
            <PathBreadcrumbs
              path={[
                {
                  name: 'Strategy Center',
                  to: `/secure/strategy-center?${template.type}`,
                },
                {
                  name: template.name,
                },
              ]}
              ariaLabel="Template linking breadcrumbs"
            />
          </Box>
        ),
        right: (
          <Typography variant="body1" color="textSecondary">
            {template.type === 'restrictions' && (
              <Box display="flex">
                <Typography variant="body1" color="textSecondary">
                  {template.tickers.length} Tickers
                </Typography>
                <VerticalDivider />
                <Typography variant="body1" color="textSecondary">
                  {template.sectors.length} Sectors
                </Typography>
                <VerticalDivider />
                <Typography variant="body1" color="textSecondary">
                  {template.countries.length} Countries
                </Typography>
                <VerticalDivider />
                <Typography variant="body1" color="textSecondary">
                  {template.esgAreas.length} Values
                </Typography>
              </Box>
            )}

            {template.type === 'allocations' && (
              <Box display="flex">
                <Typography variant="body1" color="textSecondary">
                  {((template.allocations.EQUITY || 0) * 100).toFixed(0)}% Equity
                </Typography>
                <VerticalDivider />
                <Typography variant="body1" color="textSecondary">
                  {((template.allocations.FIXED_INCOME || 0) * 100).toFixed(0)}% Sectors
                </Typography>
                <VerticalDivider />
                <Typography variant="body1" color="textSecondary">
                  {((template.allocations.ALTERNATIVES || 0) * 100).toFixed(0)}% Alternatives
                </Typography>
              </Box>
            )}
          </Typography>
        ),
      }}
    >
      <Box display="flex" alignContent="center">
        <Box mr={3}>
          <Stepper>
            {!linkOnlyStepMode && (
              <Step variant={step === 'UNLINK' ? 'ACTIVE' : undefined}>Unlink accounts</Step>
            )}
            <Step variant={step === 'LINK' ? 'ACTIVE' : undefined}>Link new accounts</Step>
            <Step
              variant={(() => {
                if (step === 'REVIEW') {
                  return 'ACTIVE';
                }
                if (state.unlinkAccountsIds.length === 0 && state.linkAccountsIds.length === 0) {
                  return 'DISABLED';
                }
                return undefined;
              })()}
            >
              Review & save
            </Step>
          </Stepper>
        </Box>

        <Box>
          {step === 'UNLINK' && !linkOnlyStepMode && (
            <Unlink
              data={data.data}
              dispatch={dispatch}
              unlinkAccountsIds={state.unlinkAccountsIds}
              template={template}
            />
          )}
          {step === 'LINK' && (
            <AccountLinkTable
              data={data.data}
              dispatch={dispatch}
              linkAccountIds={state.linkAccountsIds}
              template={template}
            />
          )}
          {step === 'REVIEW' && (
            <LinkingSummary
              data={data.data}
              linkAccountIds={state.linkAccountsIds}
              unlinkAccountIds={state.unlinkAccountsIds}
              setStep={setStep}
              template={template}
            />
          )}
        </Box>
      </Box>
      {showConfirmationModal && (
        <ConfirmationModal
          onSubmit={async () => {
            setSaving(true);
            try {
              await updateLinkedAccounts(
                parentTemplateId,
                state.unlinkAccountsIds,
                state.linkAccountsIds,
                'LINK'
              );
              await accountLinkPageDataMutate();
              history.push(`/secure/strategy-center?templateType=${template.type}`);
              amplitude().logEvent('Click start template linking job', {
                category: EVENT_CATEGORIES.STRATEGY_CENTER,
                templateId: template.id,
                templateType: template.type,
              });
            } catch (e) {
              enqueueCoachmark({
                title: 'Failed update linked accounts',
                content: 'Please reach out to clientservice@vise.com for help',
                severity: 'error',
              });
              setSaving(false);
              amplitude().logEvent('Error – start template linking job', {
                category: EVENT_CATEGORIES.STRATEGY_CENTER,
                templateId: template.id,
                templateType: template.type,
              });
            }
          }}
          onClose={() => setShowConfirmationModal(false)}
          numberOfAccounts={state.linkAccountsIds.length + state.unlinkAccountsIds.length}
          disabled={!!saving}
          doNotGenerateProposals={
            state.linkAccountsIds.length === 0 && template.type === 'allocations'
          }
          accountsToUnlink={state.unlinkAccountsIds.map((id) => accountsById[id])}
        />
      )}
    </Container>
  );
}
