import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControlLabel,
  Step,
  StepLabel,
  Typography,
  useTheme,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

import { AllocationsTemplate } from 'vise-types/template';
import { updateUserMetadata } from '~/api/api';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useProductOnboarding from '~/hooks/useProductOnboarding';
import useEnqueueToast from '~/hooks/useToast';
import useUser from '~/hooks/useUser';
import { ReactComponent as CheckIcon } from '~/static/images/icons/check.svg';
import ChartsReportsImage from '~/static/images/onboarding/chart-reports.svg';
import confettiBackground from '~/static/images/onboarding/confetti.png';
import OnboardClientImage from '~/static/images/onboarding/onboard-client.svg';
import CubesImage from '~/static/images/onboarding/onboarding-cubes.svg';
import PsaSignImage from '~/static/images/onboarding/psa.svg';
import DialogTitle from '~/synth/DialogTitle';
import Skeleton from '~/synth/Skeleton';
import { TextHighlightTag } from '~/synth/Tag';
import ClientOnboardingLanding from './ClientOnboardingLanding';
import { OnboardingStepper } from './OnboardingStepper';
import SignPsaDialog from './SignPsaDialog';
import { ProductOnboardingRoutes } from './routes';
import SignatoryInviteDialog from './SignatoryInviteDialog';

function StrategyCenterConfirmationDialog(props: DialogProps) {
  const history = useHistory();
  const { state: allocationTemplate } = useLocation<AllocationsTemplate>();

  if (!allocationTemplate) {
    return (
      <Dialog {...props}>
        <DialogTitle
          onClose={() => {
            history.push(`${ProductOnboardingRoutes.strategyCenter}`);
          }}
        >
          We&apos;ve encountered an error
        </DialogTitle>
        <DialogContent>
          There was an error saving your allocation template. Please reach out to
          clientservice@vise.com.
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog {...props}>
      <DialogTitle
        onClose={() => {
          history.push(`${ProductOnboardingRoutes.strategyCenter}`);
        }}
      />
      <DialogContent>
        <Typography variant="h2">New allocation template created!</Typography>
        <p>
          Your allocation template, {allocationTemplate.name}, has been successfully saved. In the
          Strategy Center section of the platform, you can view, edit, and apply this template and
          other templates.
        </p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained">Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function SampleProposalConfirmationDialog(props: DialogProps) {
  const history = useHistory();

  return (
    <Dialog {...props}>
      <DialogTitle
        onClose={() => {
          history.push(`${ProductOnboardingRoutes.xray}`);
        }}
      >
        <Box display="flex" justifyContent="center" mb={4} mt={2}>
          <img src={ChartsReportsImage} alt="cubes" width={256} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h2" textAlign="center" mb={2}>
          New Proposal Created!
        </Typography>
        <Typography textAlign="center">
          Congratulations on creating a sample proposal for your client. To view all proposals,
          visit the X-ray Analysis tab on the navigation bar on the left.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => history.push(`${ProductOnboardingRoutes.xray}`)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TemplateCenterSkippedDialog(props: DialogProps) {
  const history = useHistory();
  const updateAndClose = async () => {
    await updateUserMetadata({ templateOnboardingStatus: 'COMPLETED' });
    history.push(ProductOnboardingRoutes.xray);
  };

  return (
    <Dialog {...props}>
      <DialogTitle onClose={updateAndClose}>
        <Box display="flex" justifyContent="center" mb={4} mt={2}>
          <img src={CubesImage} alt="cubes" width={256} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h2" mb={4} textAlign="center">
          Template creation skipped.
        </Typography>
        <Typography textAlign="center">
          You have opted to skip creating an initial allocation template. Should you change your
          mind throughout the product onboarding experience, navigate to the strategy center to
          initiate creating a template.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={updateAndClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const updateIsSignatory = debounce((isSignatory) => {
  updateUserMetadata({ isSignatory });
}, 300);

function CenterContent() {
  const history = useHistory();

  const {
    isXrayComplete,
    isTemplateOnboardingComplete,
    isPsaSigned,
    isSignatory,
    organizationName,
  } = useProductOnboarding();

  const [isSignatoryChecked, setIsSignatoryChecked] = useState(isSignatory);

  return (
    <Box display="flex" alignItems="stretch" pl={2}>
      <Switch>
        <Route path={ProductOnboardingRoutes.strategyCenter}>
          <img src={CubesImage} width={243} alt="cubes" />
        </Route>
        <Route path={ProductOnboardingRoutes.xray}>
          <img src={ChartsReportsImage} width={243} alt="charts" />
        </Route>
        <Route path={ProductOnboardingRoutes.psa}>
          <img src={PsaSignImage} width={243} alt="psa" />
        </Route>
        <Route path={ProductOnboardingRoutes.startOnboard}>
          <img src={OnboardClientImage} width={243} alt="onboard a client" />
        </Route>
      </Switch>
      <Box pl={4}>
        <Switch>
          <Typography variant="h3" mb={2}>
            <Route path={ProductOnboardingRoutes.strategyCenter}>Strategy Center</Route>
            <Route path={ProductOnboardingRoutes.xray}>Portfolio Proposal</Route>
            <Route path={ProductOnboardingRoutes.psa}>Sign Platform & Sub-Advisory Agreement</Route>
            <Route path={ProductOnboardingRoutes.startOnboard}>Onboard Client</Route>
          </Typography>
        </Switch>
        <Switch>
          <Box mb={5} color="grey.500">
            <Route path={ProductOnboardingRoutes.strategyCenter}>
              {isXrayComplete
                ? `Congratulations on creating a model template. To view or edit, visit the strategy center.`
                : `Create an allocation template within our Strategy Center. Facilitate portfolio
              management across portfolios by easily building, editing and applying models with
              ease.`}
            </Route>
            <Route path={ProductOnboardingRoutes.xray}>
              Create a Vise managed portfolio for your client. Upload your client&apos;s existing
              holdings and customize the portfolio based on your inputs. We will provide an X-ray
              analysis comparing your client&apos;s existing portfolio with one managed by Vise.
              {isXrayComplete && (
                <Typography mt={2}>
                  To view the portfolio&apos;s you&apos;ve created{' '}
                  <Link
                    to={{
                      pathname: '/secure/x-ray',
                      state: {
                        productOnboardingRedirectUrl: `${ProductOnboardingRoutes.strategyCenter}`,
                      },
                    }}
                  >
                    x-ray analysis section.
                  </Link>
                </Typography>
              )}
            </Route>
            <Route path={ProductOnboardingRoutes.psa}>
              A Platform & Sub-Advisory Agreement must be reviewed and signed by an authorized
              signatory from your firm before you can onboard clients and access the full platform.
              {!isPsaSigned && (
                <Box mt={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSignatoryChecked}
                        onChange={(e) => {
                          // just to reflect the change on the UI immediately
                          setIsSignatoryChecked(e.target.checked);

                          updateIsSignatory(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography>
                        I&apos;m an authorized signatory at {organizationName}
                      </Typography>
                    }
                  />
                </Box>
              )}
            </Route>
            <Route path={ProductOnboardingRoutes.startOnboard}>
              Onboard your first client client onto the Vise platform. Select the client you wish to
              onboard and follow our step-by-step guide.
            </Route>
          </Box>
        </Switch>

        <Switch>
          <Route exact path={ProductOnboardingRoutes.home}>
            <Redirect to={ProductOnboardingRoutes.strategyCenter} />
          </Route>
          <Route exact path={ProductOnboardingRoutes.strategyCenter}>
            <Box display="flex" alignItems="center">
              <Button
                size="small"
                variant="contained"
                sx={{ mr: 2 }}
                onClick={() => {
                  history.push('/secure/template/allocation/create', {
                    productOnboardingRedirectUrl: `${ProductOnboardingRoutes.strategyCenter}`,
                  });
                }}
              >
                Create template
              </Button>
              {isTemplateOnboardingComplete && (
                <TextHighlightTag mr={2} severity="success" hasBorder>
                  <CheckIcon /> <Box ml={0.5}>Complete</Box>
                </TextHighlightTag>
              )}
              {!isTemplateOnboardingComplete && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => history.push(`${ProductOnboardingRoutes.strategyCenter}/skip`)}
                >
                  Skip for now
                </Button>
              )}
            </Box>
          </Route>
          <Route exact path={ProductOnboardingRoutes.xray}>
            {isXrayComplete ? (
              <TextHighlightTag mr={2} severity="success" hasBorder>
                <CheckIcon /> <Box ml={0.5}>Complete</Box>
              </TextHighlightTag>
            ) : (
              <Button
                size="small"
                variant="contained"
                sx={{ mr: 2 }}
                onClick={() => {
                  history.push('/secure/portfolio-creator-next', {
                    productOnboardingRedirectUrl: `${ProductOnboardingRoutes.xray}`,
                  });
                }}
              >
                Create
              </Button>
            )}
          </Route>
          <Route path={ProductOnboardingRoutes.psa}>
            {isPsaSigned ? (
              <TextHighlightTag severity="success" hasBorder>
                <CheckIcon /> <Box ml={0.5}>Complete</Box>
              </TextHighlightTag>
            ) : (
              <Button
                variant="contained"
                size="small"
                sx={{ mr: 2 }}
                onClick={() =>
                  history.push(
                    `${ProductOnboardingRoutes.psa}/${
                      isSignatoryChecked ? 'sign' : 'invite-signatory'
                    }`
                  )
                }
              >
                {isSignatoryChecked ? 'Sign' : 'Invite Signatory'}
              </Button>
            )}
          </Route>
          <Route exact path={ProductOnboardingRoutes.startOnboard}>
            <Button
              size="small"
              variant="contained"
              sx={{ mr: 2 }}
              onClick={() => history.push(ProductOnboardingRoutes.selectClient)}
            >
              Begin onboarding
            </Button>
          </Route>
        </Switch>
      </Box>
    </Box>
  );
}

const Stepper = () => {
  const { isPsaSigned, isXrayComplete, isTemplateOnboardingComplete, isClientOnboardingComplete } =
    useProductOnboarding();
  const history = useHistory();
  const updateStepper = new URLSearchParams(history.location.search).get('updateStepper');
  const routeToStep = useMemo(
    () => ({
      'strategy-center': {
        name: 'Create allocation template',
        completed: isTemplateOnboardingComplete,
        link: ProductOnboardingRoutes.strategyCenter,
      },
      'xray-analysis': {
        name: 'Create a portfolio',
        completed: isXrayComplete,
        link: ProductOnboardingRoutes.xray,
      },
      psa: {
        name: 'Sign PSA document',
        completed: isPsaSigned,
        link: ProductOnboardingRoutes.psa,
      },
      'client-onboarding': {
        name: 'Onboard your first client',
        completed: isClientOnboardingComplete,
        link: ProductOnboardingRoutes.startOnboard,
      },
    }),
    [isPsaSigned, isXrayComplete, isClientOnboardingComplete, isTemplateOnboardingComplete]
  );

  const nextUnfinishedStep = Object.values(routeToStep).find((v) => {
    return !v.completed;
  });

  const stepsUrls = Object.values(routeToStep).map((i) => i.link);
  const activeStep: number = stepsUrls.findIndex((step) => step === history.location.pathname);

  return (
    <Box maxWidth={400}>
      {nextUnfinishedStep && updateStepper === 'true' && <Redirect to={nextUnfinishedStep?.link} />}
      <OnboardingStepper stepperProps={{ activeStep }}>
        {Object.values(routeToStep).map(({ name, completed, link }) => (
          <Step key={name} completed={completed}>
            <Link to={link} style={{ textDecoration: 'none' }}>
              <StepLabel>{name}</StepLabel>
            </Link>
          </Step>
        ))}
      </OnboardingStepper>
    </Box>
  );
};

export default function ProductOnboarding() {
  const { data: { enable_product_onboarding: enableProductOnboarding } = {} } =
    useFeatureFlags(true);

  const theme = useTheme();
  const enqueueToast = useEnqueueToast();
  const { productOnboardingRequired, isSignatory, isPsaSigned } = useProductOnboarding();

  const { data: user, error: userError } = useUser(false, true);

  useEffect(() => {
    if (userError != null) {
      enqueueToast({
        title: 'We encountered an error when trying to load your account.',
        content: 'Please reach out to clientservice@vise.com.',
        severity: 'error',
      });
      Sentry.captureException(new Error(`[ProductOnboarding] Failed to load user`));
    }
  }, [userError, enqueueToast]);

  if (!user?.userMetaData) {
    return (
      <Box px={8} pt={2}>
        <Skeleton width="100%" height="20vh" />
        <Skeleton width="100%" height="40vh" />
      </Box>
    );
  }

  const MainOnboarding = () => (
    <Box pt={8} px={8}>
      {(enableProductOnboarding === 'off' || !productOnboardingRequired) && (
        <Redirect to="/secure/dashboard" />
      )}
      <Box>
        <Box
          position="absolute"
          top={-3}
          left={0}
          height={300}
          overflow="hidden"
          sx={{ opacity: 0.1 }}
        >
          <img src={confettiBackground} alt="" />
        </Box>
        <Typography variant="h1" mb={2}>
          Hello, {user.firstName}. Welcome to the Vise Platform!
        </Typography>
        <Typography variant="h3" mb={2}>
          Learn how to use the Vise platform with a simple four-step guide. Each step is designed to
          teach you about a different part of the platform.{' '}
        </Typography>
        <Typography mb={6} color="grey.600">
          Follow the steps below to build a sample portfolio managed by Vise and see how we can help
          support your firm.
        </Typography>
      </Box>
      <Box display="flex">
        <Box pr={4} sx={{ borderRight: `1px solid ${theme.palette.grey[200]}` }}>
          <Stepper />
        </Box>
        <Box pr={2}>
          <CenterContent />
        </Box>
      </Box>
      <Route path={`${ProductOnboardingRoutes.strategyCenter}/confirmation`}>
        <StrategyCenterConfirmationDialog open />
      </Route>
      <Route path={`${ProductOnboardingRoutes.strategyCenter}/skip`}>
        <TemplateCenterSkippedDialog open />
      </Route>
      <Route path={`${ProductOnboardingRoutes.xray}/confirmation`}>
        <SampleProposalConfirmationDialog open />
      </Route>
      <Route path={`${ProductOnboardingRoutes.xray}/confirmation`}>
        <SampleProposalConfirmationDialog open />
      </Route>
      <Route path={`${ProductOnboardingRoutes.psa}/sign`}>
        {isSignatory && <SignPsaDialog open alreadySigned={isPsaSigned} />}
      </Route>
      <Route path={`${ProductOnboardingRoutes.psa}/invite-signatory`}>
        {!isSignatory && !isPsaSigned && <SignatoryInviteDialog open />}
      </Route>
    </Box>
  );

  return (
    <Switch>
      <Route path={ProductOnboardingRoutes.home}>
        <MainOnboarding />
      </Route>
      <Route path={ProductOnboardingRoutes.onboard}>
        <ClientOnboardingLanding />
      </Route>
    </Switch>
  );
}
