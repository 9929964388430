import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface PageContentContainerProps extends BoxProps {
  gutterTop?: boolean;
  centerContent?: boolean;
}

const PageContentContainer = ({
  centerContent,
  children,
  gutterTop,
  ...customProps
}: PageContentContainerProps) => {
  return (
    <Box width="100%" minHeight="100%" px={3} pb={5} pt={gutterTop ? 5 : 2} {...customProps}>
      <Box
        maxWidth={1280}
        marginLeft={centerContent ? 'auto' : 'initial'}
        marginRight={centerContent ? 'auto' : 'initial'}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageContentContainer;
