import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';

export default function StepTitleCard({
  title,
  description,
}: {
  title: string;
  description?: React.ReactNode;
}) {
  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography mt={1} mb={1.5} variant="h2">
          {title}
        </Typography>
        {description && (
          <Typography mb={2} variant="body1">
            {description}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
