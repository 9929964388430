import {
  Box,
  Button,
  ButtonProps,
  Divider as DividerBase,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ConcentrationLimit } from 'vise-types/pce1';
import { AssetClassKey, Feature } from 'vise-types/pce2_instrument';
import { glidePathValue, readableGlidePath, riskToRiskSlider } from '~/api/utils';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useSectors from '~/hooks/useSectors';
import { getRemainingYears } from '~/routes/Portfolio/portfolioUtil';
import { ReactComponent as CheckCircleIcon } from '~/static/images/icons/check-circle.svg';
import { ReactComponent as DocumentTextIcon } from '~/static/images/icons/document-text.svg';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { ReactComponent as PencilIcon } from '~/static/images/icons/pencil.svg';
import summaryBannerImage from '~/static/images/summary-banner-image.svg';
import TextField from '~/synth/TextField';
import { formatCurrency, formatPercent, maskAccountNumber } from '~/utils/format';
import {
  ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP,
  ASSET_CLASS_TO_LABEL_MAP,
  ASSET_CLASS_TREES,
  COUNTRY_TO_LABEL_MAP,
  ESG_LABEL_MAP,
  FOCUS_TO_LABEL,
  SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS,
  SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL,
  SMALL_ACCOUNT_EQUITY_ASSET_CLASS_KEYS,
  SMALL_ACCOUNT_FI_ASSET_CLASS_KEYS,
} from '../../Constants';
import {
  AssetClassTreeNode,
  ConstructionInfo,
  DraftPortfolio,
  DraftPortfolioDispatch,
  Pce2CapitalGainsLimits,
  ScreenProps,
} from '../../Types';
import {
  computeActiveTiltForDisplay,
  formatPce1CashConcentrationLimit,
  getActiveTiltAmountText,
  getAllAssetClassTreeSections,
  getExcludedSectorsNames,
  groupExcludedIndustries,
  isSmallAccount,
  shouldSkipRestrictions,
} from '../../utils';
import CreatePortfolioButton from '../SummaryLandingScreen/CreatePortfolioButton';
import SkipProposalModal from '../SummaryLandingScreen/SkipProposalModal';
import { ActionBarV2, BackButton } from '../components';
import ActiveTiltSelection from '../components/ActiveTiltSelection';
import {
  DetailsText,
  Section,
  SubCategoryAllocation,
  SubDetailsText,
  sumKeys,
} from '../components/SummarySections';
import { CircleIcon, PC_CATEGORY, updatedCategoriesForDraftPortfolio } from './SummaryUtils';

export const equitiesKeys = ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get('EQUITY') || [];
export const fixedIncomeKeys = ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get('FIXED_INCOME') || [];
export const alternativesKeys = ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get('ALTERNATIVES') || [];

const Divider = styled(DividerBase)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
}));

export const Body = styled(Box)(({ theme }) => ({
  '@media (min-width: 700px)': {
    width: 630,
  },
  [`${theme.breakpoints.up('sm')}`]: {
    marginLeft: 70,
  },
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderColor: theme.palette.grey[200],
  borderStyle: 'solid',
  borderWidth: '1px 0 1px 0',
  [`${theme.breakpoints.up('sm')}`]: {
    paddingLeft: 70,
  },
  marginBottom: theme.spacing(5),
  display: 'flex',
}));

interface EditButtonProps extends ButtonProps {
  to: string;
}

function EditButton(props: EditButtonProps) {
  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <Button
      {...props}
      replace
      variant="text"
      component={Link}
      startIcon={<PencilIcon width="16" height="16" />}
      size="small"
    >
      Edit
    </Button>
  );
}

function UpdatedPill() {
  return (
    <Box
      borderRadius="20px"
      color="gray.600"
      bgcolor="blue.100"
      display="inline-block"
      py={0.5}
      px={1}
      ml={1.5}
      style={{ verticalAlign: 'bottom' }}
    >
      <Typography variant="body1">Updated</Typography>
    </Box>
  );
}

function SectionHeader({
  children,
  to,
  tooltip,
}: {
  children: React.ReactNode;
  to?: string | null;
  tooltip?: string | null;
}) {
  return (
    <Box display="flex" alignItems="center" mb={3.5}>
      <Box display="flex" flex={1} alignItems="center">
        <Typography variant="h2">{children}</Typography>
        {tooltip != null ? (
          <Tooltip placement="top" title={tooltip}>
            <Box display="flex" alignItems="center" color="grey.500" ml={1} mt={0.5}>
              <InformationCircleIcon />
            </Box>
          </Tooltip>
        ) : null}
      </Box>
      {to ? <EditButton to={to} /> : null}
    </Box>
  );
}

function SectionSubHeader({
  children,
  to,
  updated,
}: {
  children: React.ReactNode;
  to?: string | null;
  updated?: boolean;
}) {
  return (
    <Box mb={2}>
      <Box display="flex" alignItems="center" mb={1}>
        <Box display="flex" flex={1}>
          <Typography variant="h4" color="textPrimary">
            {children}
          </Typography>
          {updated ? <UpdatedPill /> : null}
        </Box>
        {to ? <EditButton to={to} /> : null}
      </Box>
      <Divider />
    </Box>
  );
}

function MainSection({ children }: { children: React.ReactNode }) {
  return (
    <Box mt={1.5} mb={5} component="section">
      {children}
    </Box>
  );
}

function CapitalGainsLimit({
  limit,
  shortTerm = false,
}: {
  limit: Pce2CapitalGainsLimits;
  shortTerm?: boolean;
}) {
  return (
    <Section>
      <DetailsText>{shortTerm ? 'Short-term' : 'Long-term'} gains limit</DetailsText>
      <SubDetailsText
        testId={
          shortTerm
            ? 'portfolio-summary-landing-short-term-gains-limit'
            : 'portfolio-summary-landing-long-term-gains-limit'
        }
      >
        {limit?.maximumAmount != null
          ? `${formatCurrency(limit.maximumAmount)} ${
              limit.shouldLimitSmallestAmount ? '(minimum amount)' : 'per year'
            }`
          : 'No limit set'}
      </SubDetailsText>
    </Section>
  );
}

function AssetClassCategoryList({
  title,
  primaryAssetClassTreeRoot,
  exclusions,
  updated,
  onlyUpdatedValues,
}: {
  title: string;
  primaryAssetClassTreeRoot: AssetClassTreeNode;
  exclusions: AssetClassKey[];
  updated: boolean;
  onlyUpdatedValues: boolean;
}) {
  if (onlyUpdatedValues && !updated) {
    return <></>;
  }

  const allAssetClassTreeNodes = getAllAssetClassTreeSections(
    primaryAssetClassTreeRoot,
    exclusions,
    false
  );
  return (
    <Section isOuterSection>
      <SectionSubHeader to="/secure/portfolio-creator-next/asset-classes" updated={updated}>
        {title}
      </SectionSubHeader>
      {allAssetClassTreeNodes.length === 0 ? (
        <DetailsText>None selected</DetailsText>
      ) : (
        allAssetClassTreeNodes.map(([topLevelAssetClassLabel, toggledDescendentKeys]) => (
          <Section key={topLevelAssetClassLabel}>
            <DetailsText>{topLevelAssetClassLabel}</DetailsText>
            {toggledDescendentKeys?.map((key) => (
              <SubDetailsText key={key}>{key}</SubDetailsText>
            ))}
          </Section>
        ))
      )}
    </Section>
  );
}

function SmallAccountAssetCLassCategoryList({
  exclusions,
  updatedCategories,
  onlyUpdatedValues,
}: {
  exclusions: AssetClassKey[];
  updatedCategories: Set<PC_CATEGORY>;
  onlyUpdatedValues: boolean;
}) {
  const exclusionsSet = new Set(exclusions);
  const toggledEquities = SMALL_ACCOUNT_EQUITY_ASSET_CLASS_KEYS.filter(
    (x) => !exclusionsSet.has(x)
  );
  const toggledFi = SMALL_ACCOUNT_FI_ASSET_CLASS_KEYS.filter((x) => !exclusionsSet.has(x));

  return (
    <>
      {(!onlyUpdatedValues || updatedCategories.has('EQUITIES')) && (
        <>
          <SectionSubHeader
            to="/secure/portfolio-creator-next/asset-classes"
            updated={updatedCategories.has('EQUITIES')}
          >
            Equities
          </SectionSubHeader>
          <Section isOuterSection>
            {toggledEquities.length > 0 ? (
              toggledEquities.map((equity) => (
                <Section key={equity}>
                  <DetailsText>{SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL[equity]}</DetailsText>
                </Section>
              ))
            ) : (
              <DetailsText>None selected</DetailsText>
            )}
          </Section>
        </>
      )}
      {(!onlyUpdatedValues || updatedCategories.has('FIXED_INCOME')) && (
        <>
          <SectionSubHeader
            to="/secure/portfolio-creator-next/asset-classes"
            updated={updatedCategories.has('FIXED_INCOME')}
          >
            Fixed income
          </SectionSubHeader>
          <Section isOuterSection>
            {toggledFi.length > 0 ? (
              toggledFi.map((fi) => (
                <Section key={fi}>
                  <DetailsText>{SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL[fi]}</DetailsText>
                </Section>
              ))
            ) : (
              <DetailsText>None selected</DetailsText>
            )}
          </Section>
        </>
      )}
    </>
  );
}

function ExcludedSectors({
  excludedSectors,
  excludedIndustries,
}: {
  excludedSectors: ConstructionInfo['excludedSectors'];
  excludedIndustries: ConstructionInfo['excludedIndustries'];
}) {
  const { data: sectors } = useSectors();
  const excludedSectorsSet = useMemo(() => new Set(excludedSectors), [excludedSectors]);
  const excludedIndustriesSet = useMemo(() => new Set(excludedIndustries), [excludedIndustries]);
  if (!excludedSectors?.length && !excludedIndustries?.length) {
    return (
      <Section>
        <DetailsText>None selected</DetailsText>
      </Section>
    );
  }
  if (sectors == null) {
    // Sector data is loading or errored
    return (
      <Section>
        <DetailsText>Loading...</DetailsText>
      </Section>
    );
  }

  const groupedExcludedIndustries = groupExcludedIndustries({ excludedIndustriesSet, sectors });
  const excludedSectorNames = getExcludedSectorsNames({ excludedSectorsSet, sectors });

  const excludedSectorContent: JSX.Element[] = [];
  groupedExcludedIndustries.forEach((restriction) => {
    excludedSectorContent.push(
      <Section>
        <DetailsText key={restriction.sector}>{restriction.sector}</DetailsText>
        {restriction.industries.map((industry) => (
          <SubDetailsText key={industry}>{industry}</SubDetailsText>
        ))}
      </Section>
    );
  });
  excludedSectorNames.forEach((name) => {
    excludedSectorContent.push(
      <Section key={name}>
        <DetailsText>{name}</DetailsText>
        <SubDetailsText>All sub-sectors selected</SubDetailsText>
      </Section>
    );
  });
  return <>{excludedSectorContent}</>;
}

interface SummaryLandingScreenProps {
  clientName: string;
  proposalName?: string;
  householdName: string;
  draftPortfolio: DraftPortfolio;
  sortedLockedPositions: string[] | null;
  shouldShowCapitalLossesAndGainsScreen: boolean;
  handleCreatePortfolioIntelligence: ({
    proposalName,
    executeProposal,
  }: {
    proposalName?: string;
    executeProposal?: boolean;
  }) => Promise<void>;
  awaitingPost: boolean;
  isCreatePortfolioDisabled: boolean;
  onBack: ScreenProps['onBack'];
  cashConcentrationLimit: ConcentrationLimit | undefined;
  dpDispatch: DraftPortfolioDispatch;
}

function TransitionSettings({
  draftPortfolio,
  updatedCategories,
  onlyUpdatedValues,
  sortedLockedPositions,
  cashConcentrationLimit,
}: {
  draftPortfolio: DraftPortfolio;
  updatedCategories: Set<PC_CATEGORY>;
  onlyUpdatedValues: boolean;
  sortedLockedPositions: string[] | null;
  cashConcentrationLimit: ConcentrationLimit | undefined;
}) {
  const { constructionInfo } = draftPortfolio;
  const { existingPortfolio, mmf } = constructionInfo;

  if (
    !updatedCategories.has('LOCKED_POSITIONS') &&
    !updatedCategories.has('CASH_ALLOCATION') &&
    onlyUpdatedValues
  ) {
    return <></>;
  }

  return (
    <MainSection>
      <SectionHeader>Transition settings</SectionHeader>
      {(!onlyUpdatedValues || updatedCategories.has('LOCKED_POSITIONS')) && (
        <Section isOuterSection>
          <SectionSubHeader
            to={
              existingPortfolio === 'sample-portfolio'
                ? null
                : '/secure/portfolio-creator-next/locked-positions'
            }
            updated={updatedCategories.has('LOCKED_POSITIONS')}
          >
            Locked positions
          </SectionSubHeader>
          {sortedLockedPositions && sortedLockedPositions.length ? (
            sortedLockedPositions.map((position) => (
              <DetailsText key={position}>{position}</DetailsText>
            ))
          ) : (
            <DetailsText>None selected</DetailsText>
          )}
        </Section>
      )}
      {(!onlyUpdatedValues || updatedCategories.has('CASH_ALLOCATION')) && (
        <Section isOuterSection>
          <SectionSubHeader
            to="/secure/portfolio-creator-next/cash-allocation"
            updated={updatedCategories.has('CASH_ALLOCATION')}
          >
            Cash allocation
          </SectionSubHeader>
          <DetailsText>
            {cashConcentrationLimit == null
              ? 'No allocation set'
              : `${formatPce1CashConcentrationLimit(cashConcentrationLimit)}%`}
          </DetailsText>
          {mmf && <SubDetailsText>MMF enabled</SubDetailsText>}
        </Section>
      )}
    </MainSection>
  );
}

function TaxManagement({
  draftPortfolio,
  updatedCategories,
  onlyUpdatedValues,
  shouldShowCapitalLossesAndGainsScreen,
}: {
  draftPortfolio: DraftPortfolio;
  updatedCategories: Set<PC_CATEGORY>;
  onlyUpdatedValues: boolean;
  shouldShowCapitalLossesAndGainsScreen: boolean;
}) {
  const { data: featureFlags } = useFeatureFlags();
  const { constructionInfo } = draftPortfolio;
  const {
    autoTlh,
    capitalGainsLimits,
    longTermFederalTaxRate,
    longTermStateTaxRate,
    shortTermFederalTaxRate,
    shortTermStateTaxRate,
    existingPortfolio,
  } = constructionInfo;

  if (
    (!updatedCategories.has('TAX_MANAGEMENT') && onlyUpdatedValues) ||
    !shouldShowCapitalLossesAndGainsScreen
  ) {
    return <></>;
  }

  return (
    <MainSection>
      <SectionHeader>Taxes</SectionHeader>

      {autoTlh != null && (!onlyUpdatedValues || updatedCategories.has('TAX_MANAGEMENT')) ? (
        <>
          <Section isOuterSection>
            <SectionSubHeader
              to="/secure/portfolio-creator-next/capital-losses-and-gains"
              updated={updatedCategories.has('TAX_MANAGEMENT')}
            >
              Tax management
            </SectionSubHeader>
            <Section>
              <DetailsText testId="portfolio-summary-landing-tax-loss-harvesting-toggle">
                <Box display="flex">
                  Tax loss harvesting:
                  {autoTlh ? (
                    <Box color="success.400" ml={0.5}>
                      Active
                    </Box>
                  ) : (
                    ' Off'
                  )}
                </Box>
              </DetailsText>
            </Section>
            {capitalGainsLimits && (
              <>
                <CapitalGainsLimit limit={capitalGainsLimits.longTermGainsLimits} />
                <CapitalGainsLimit limit={capitalGainsLimits.shortTermGainsLimits} shortTerm />
              </>
            )}
            {existingPortfolio !== 'sample-portfolio' &&
            existingPortfolio?.accountDataSource === 'XRAY' &&
            featureFlags?.enable_tax_rates === 'on' ? (
              <>
                <Section>
                  <DetailsText>Long-term tax rate</DetailsText>
                  <SubDetailsText>
                    {longTermFederalTaxRate != null && longTermStateTaxRate != null
                      ? formatPercent(longTermFederalTaxRate + longTermStateTaxRate, 1)
                      : '-'}
                  </SubDetailsText>
                </Section>
                <DetailsText>Short-term tax rate</DetailsText>
                <SubDetailsText>
                  {shortTermFederalTaxRate != null && shortTermStateTaxRate != null
                    ? formatPercent(shortTermFederalTaxRate + shortTermStateTaxRate, 1)
                    : '-'}
                </SubDetailsText>
              </>
            ) : null}
          </Section>
        </>
      ) : null}
    </MainSection>
  );
}

function FocusComponent({
  draftPortfolio,
  updatedCategories,
  onlyUpdatedValues,
}: {
  draftPortfolio: DraftPortfolio;
  updatedCategories: Set<PC_CATEGORY>;
  onlyUpdatedValues: boolean;
}) {
  const { constructionInfo } = draftPortfolio;
  const { focus, useGlidePath } = constructionInfo;

  const investmentTimelineMoment = constructionInfo.investmentTimeline
    ? moment(constructionInfo.investmentTimeline)
    : null;

  if (focus == null) {
    return <></>;
  }

  if (!onlyUpdatedValues || updatedCategories.has('PORTFOLIO_FOCUS')) {
    return (
      <MainSection>
        <SectionHeader>Portfolio focus</SectionHeader>
        <Section isOuterSection>
          <SectionSubHeader
            to="/secure/portfolio-creator-next/risk-tolerance"
            updated={updatedCategories.has('PORTFOLIO_FOCUS')}
          >
            Focus
          </SectionSubHeader>
          <DetailsText>{FOCUS_TO_LABEL[focus]}</DetailsText>
        </Section>
        <Section isOuterSection>
          {constructionInfo.focus === 'LOSS_TOLERANCE' ? (
            <>
              <SectionSubHeader
                to="/secure/portfolio-creator-next/risk-tolerance"
                updated={updatedCategories.has('PORTFOLIO_FOCUS')}
              >
                Risk Profile
              </SectionSubHeader>
              <Section>
                <DetailsText>Allocation</DetailsText>
                <SubDetailsText>
                  {riskToRiskSlider(constructionInfo.risk)}% Equities,{' '}
                  {100 - riskToRiskSlider(constructionInfo.risk)}% Fixed income
                </SubDetailsText>
              </Section>
              <Section>
                <DetailsText>Glide path</DetailsText>
                <SubDetailsText>
                  {readableGlidePath(
                    glidePathValue(
                      useGlidePath,
                      getRemainingYears(investmentTimelineMoment, moment())
                    )
                  )}
                </SubDetailsText>
              </Section>
            </>
          ) : (
            <Section>
              <DetailsText>Goal</DetailsText>
              <SubDetailsText>{formatCurrency(constructionInfo.targetValue)}</SubDetailsText>
            </Section>
          )}
          {investmentTimelineMoment != null && (
            <Section>
              <DetailsText>Horizon</DetailsText>
              <SubDetailsText>
                {investmentTimelineMoment.year()} (
                {getRemainingYears(investmentTimelineMoment, moment())} years remaining)
              </SubDetailsText>
            </Section>
          )}
        </Section>
      </MainSection>
    );
  }

  return <></>;
}

function AssetClasses({
  draftPortfolio,
  updatedCategories,
  onlyUpdatedValues,
  isSmall,
}: {
  draftPortfolio: DraftPortfolio;
  updatedCategories: Set<PC_CATEGORY>;
  onlyUpdatedValues: boolean;
  isSmall: boolean;
}) {
  const { constructionInfo } = draftPortfolio;
  const { assetClassConcentrationLimits } = constructionInfo;
  const exclusions = assetClassConcentrationLimits?.exclusions;

  if (
    !updatedCategories.has('INVESTMENT_VEHICLES') &&
    !updatedCategories.has('EQUITIES') &&
    !updatedCategories.has('FIXED_INCOME') &&
    !updatedCategories.has('ALTERNATIVES') &&
    !updatedCategories.has('AC_CONCENTRATION_LIMITS') &&
    onlyUpdatedValues
  ) {
    return <></>;
  }

  const concentrationLimits = (
    <Section isOuterSection>
      {assetClassConcentrationLimits?.isEnabled &&
      (assetClassConcentrationLimits?.equities || assetClassConcentrationLimits?.fixedIncome) ? (
        <SectionSubHeader
          to="/secure/portfolio-creator-next/asset-classes"
          updated={updatedCategories.has('AC_CONCENTRATION_LIMITS')}
        >
          Max concentration limits
        </SectionSubHeader>
      ) : null}
      {assetClassConcentrationLimits?.isEnabled && assetClassConcentrationLimits?.equities ? (
        <DetailsText>Equities {assetClassConcentrationLimits?.equities}%</DetailsText>
      ) : null}
      {assetClassConcentrationLimits?.isEnabled && assetClassConcentrationLimits?.fixedIncome ? (
        <DetailsText>Fixed income {assetClassConcentrationLimits?.fixedIncome}%</DetailsText>
      ) : null}
    </Section>
  );

  return (
    <MainSection>
      <SectionHeader>Asset classes</SectionHeader>
      {isSmall ? (
        <SmallAccountAssetCLassCategoryList
          exclusions={exclusions ?? []}
          updatedCategories={updatedCategories}
          onlyUpdatedValues={onlyUpdatedValues}
        />
      ) : (
        <>
          <AssetClassCategoryList
            title="Equities"
            exclusions={exclusions ?? []}
            primaryAssetClassTreeRoot={ASSET_CLASS_TREES[0]}
            updated={updatedCategories.has('EQUITIES')}
            onlyUpdatedValues={onlyUpdatedValues}
          />
          <AssetClassCategoryList
            title="Fixed income"
            exclusions={exclusions ?? []}
            primaryAssetClassTreeRoot={ASSET_CLASS_TREES[1]}
            updated={updatedCategories.has('FIXED_INCOME')}
            onlyUpdatedValues={onlyUpdatedValues}
          />
          <AssetClassCategoryList
            title="Alternatives"
            exclusions={exclusions ?? []}
            primaryAssetClassTreeRoot={ASSET_CLASS_TREES[2]}
            updated={updatedCategories.has('ALTERNATIVES')}
            onlyUpdatedValues={onlyUpdatedValues}
          />
          {(!onlyUpdatedValues || updatedCategories.has('AC_CONCENTRATION_LIMITS')) &&
            concentrationLimits}
        </>
      )}
    </MainSection>
  );
}

function Restrictions({
  draftPortfolio,
  updatedCategories,
  onlyUpdatedValues,
  isSmall,
}: {
  draftPortfolio: DraftPortfolio;
  updatedCategories: Set<PC_CATEGORY>;
  onlyUpdatedValues: boolean;
  isSmall: boolean;
}) {
  const { constructionInfo, accountSize } = draftPortfolio;
  const {
    etfExclusive,
    restrictedStocks,
    excludedIndustries,
    excludedSectors,
    excludedCountries,
    excludedEsgAreas,
  } = constructionInfo;

  const skippedRestrictions = shouldSkipRestrictions({
    etfExclusive,
    exclusions: constructionInfo.assetClassConcentrationLimits?.exclusions,
    accountSize,
  });

  if (
    !updatedCategories.has('RESTRICTIONS_TEMPLATE') &&
    !updatedCategories.has('RESTRICTED_EQUITIES') &&
    !updatedCategories.has('RESTRICTED_SECTORS') &&
    !updatedCategories.has('RESTRICTED_COUNTRIES') &&
    !updatedCategories.has('RESTRICTED_ESG_AREAS') &&
    onlyUpdatedValues
  ) {
    return <></>;
  }

  const restrictions = (
    <>
      {onlyUpdatedValues && !updatedCategories.has('RESTRICTED_EQUITIES') ? null : (
        <Section isOuterSection>
          <SectionSubHeader
            to="/secure/portfolio-creator-next/build-restrictions"
            updated={updatedCategories.has('RESTRICTED_EQUITIES')}
          >
            Restricted equities
          </SectionSubHeader>
          {restrictedStocks?.length ? (
            restrictedStocks.map((stock) => (
              <Section key={stock}>
                <DetailsText>{stock}</DetailsText>{' '}
              </Section>
            ))
          ) : (
            <Section>
              <DetailsText>None selected</DetailsText>
            </Section>
          )}
        </Section>
      )}
      {onlyUpdatedValues && !updatedCategories.has('RESTRICTED_SECTORS') ? null : (
        <Section isOuterSection>
          <SectionSubHeader
            to="/secure/portfolio-creator-next/build-restrictions"
            updated={updatedCategories.has('RESTRICTED_SECTORS')}
          >
            Restricted sectors
          </SectionSubHeader>
          <ExcludedSectors
            excludedIndustries={excludedIndustries}
            excludedSectors={excludedSectors}
          />
        </Section>
      )}

      {onlyUpdatedValues && !updatedCategories.has('RESTRICTED_COUNTRIES') ? null : (
        <Box data-testid="restricted-countries-summary">
          <Section isOuterSection>
            <SectionSubHeader
              to="/secure/portfolio-creator-next/build-restrictions"
              updated={updatedCategories.has('RESTRICTED_COUNTRIES')}
            >
              Restricted countries
            </SectionSubHeader>
            {excludedCountries?.length ? (
              excludedCountries.map((country) => (
                <Section key={country}>
                  <DetailsText>{COUNTRY_TO_LABEL_MAP[country]}</DetailsText>
                </Section>
              ))
            ) : (
              <Section>
                <DetailsText>None selected</DetailsText>
              </Section>
            )}
          </Section>
        </Box>
      )}

      {onlyUpdatedValues && !updatedCategories.has('RESTRICTED_ESG_AREAS') ? null : (
        <Section isOuterSection>
          <SectionSubHeader
            to="/secure/portfolio-creator-next/build-restrictions"
            updated={updatedCategories.has('RESTRICTED_ESG_AREAS')}
          >
            Restricted ESG Values
          </SectionSubHeader>
          {excludedEsgAreas?.length ? (
            excludedEsgAreas.map((esgArea) => (
              <Section key={esgArea}>
                <DetailsText>{ESG_LABEL_MAP[esgArea].label}</DetailsText>
              </Section>
            ))
          ) : (
            <Section>
              <DetailsText>None selected</DetailsText>
            </Section>
          )}
        </Section>
      )}
    </>
  );

  return (
    <MainSection>
      <SectionHeader>Restrictions</SectionHeader>
      {!onlyUpdatedValues || updatedCategories.has('RESTRICTIONS_TEMPLATE') ? (
        <Section isOuterSection>
          <SectionSubHeader
            updated={updatedCategories.has('RESTRICTIONS_TEMPLATE')}
            to="/secure/portfolio-creator-next/build-restrictions"
          >
            Strategy type
          </SectionSubHeader>
          <DetailsText>Saved template</DetailsText>
          {draftPortfolio.restrictionsTemplates?.length ? (
            <Box data-testid="restrictions-template-name">
              {draftPortfolio.restrictionsTemplates.map((t) => (
                <SubDetailsText key={t.name}>
                  {t.name}{' '}
                  <Box component="span" color="grey.400">
                    (Created {moment(t.createdAt).format('MMM DD, YYYY')})
                  </Box>
                </SubDetailsText>
              ))}
            </Box>
          ) : (
            'None selected'
          )}
        </Section>
      ) : null}
      {skippedRestrictions || isSmall ? null : restrictions}
    </MainSection>
  );
}

function Tilts({
  draftPortfolio,
  updatedCategories,
  onlyUpdatedValues,
  isSmall,
}: {
  draftPortfolio: DraftPortfolio;
  updatedCategories: Set<PC_CATEGORY>;
  onlyUpdatedValues: boolean;
  isSmall: boolean;
}) {
  const { constructionInfo } = draftPortfolio;
  const { etfExclusive, activeTilt, risk } = constructionInfo;

  const activeTiltForDisplay = computeActiveTiltForDisplay(
    activeTilt?.tiltAmount,
    activeTilt?.tiltType,
    riskToRiskSlider(risk),
    !!etfExclusive,
    isSmall,
    activeTilt ? !activeTilt.isEnabled : false
  );

  let activeTiltAmountText: string | null = null;
  if (activeTiltForDisplay.portfolioType === 'TARGET_VALUE') {
    activeTiltAmountText = 'Auto-adjusted by Vise';
  } else if (activeTiltForDisplay.portfolioType === 'LOSS_TOLERANCE') {
    activeTiltAmountText = getActiveTiltAmountText(
      activeTiltForDisplay.activeTilt,
      riskToRiskSlider(risk)
    );
  }

  if (!updatedCategories.has('ACTIVE_TILT') && onlyUpdatedValues) {
    return <></>;
  }

  return (
    <MainSection>
      <SectionHeader>Tilts</SectionHeader>
      {(!onlyUpdatedValues || updatedCategories.has('ACTIVE_TILT')) && (
        <Section isOuterSection>
          <SectionSubHeader
            to="/secure/portfolio-creator-next/active-tilts"
            updated={updatedCategories.has('ACTIVE_TILT')}
          >
            Active tilts
          </SectionSubHeader>
          <Section>
            <DetailsText>Tilt Type</DetailsText>
            <SubDetailsText>
              <ActiveTiltSelection activeTiltForDisplay={activeTiltForDisplay} />
            </SubDetailsText>
          </Section>
          {activeTiltAmountText == null ? null : (
            <Section>
              <DetailsText>Amount</DetailsText>
              <SubDetailsText>{activeTiltAmountText}</SubDetailsText>
            </Section>
          )}
        </Section>
      )}
    </MainSection>
  );
}

function Allocation({
  draftPortfolio,
  updatedCategories,
  isSmall,
  onlyUpdatedValues,
}: {
  draftPortfolio: DraftPortfolio;
  updatedCategories: Set<PC_CATEGORY>;
  isSmall: boolean;
  onlyUpdatedValues?: boolean;
}) {
  const { data: featureFlags } = useFeatureFlags();
  const { constructionInfo } = draftPortfolio;
  const {
    assetClassConcentrationLimits,
    etfExclusiveAssetClasses,
    minSymbolAssetClasses,
    etfExclusive,
    useGlidePath,
    assetAllocation,
  } = constructionInfo;
  const investmentTimelineMoment = constructionInfo.investmentTimeline
    ? moment(constructionInfo.investmentTimeline)
    : null;

  const equityConcentrationLimits = constructionInfo.assetClassConcentrationLimits?.equities;
  const fixedIncomeConcentrationLimits =
    constructionInfo.assetClassConcentrationLimits?.fixedIncome;
  const alternativesConcentrationLimits =
    constructionInfo.assetClassConcentrationLimits?.alternatives;
  const hasConcentrationLimits =
    constructionInfo.assetClassConcentrationLimits?.isEnabled &&
    (equityConcentrationLimits != null ||
      fixedIncomeConcentrationLimits != null ||
      alternativesConcentrationLimits != null);

  let investmentVehiclesBody;
  if (etfExclusive || isSmall) {
    investmentVehiclesBody = <DetailsText>Limit to ETFs</DetailsText>;
  } else {
    const exclusionsSet = new Set(assetClassConcentrationLimits?.exclusions || []);
    const etfExclusiveAssetClassesSet = new Set(etfExclusiveAssetClasses);
    const minSymbolAssetClassesSet = new Set(minSymbolAssetClasses);
    investmentVehiclesBody = SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.filter(
      (key) => !exclusionsSet.has(key)
    ).map((key) => {
      const name = key
        .split('/')
        .slice(1)
        .map((feature) => ASSET_CLASS_TO_LABEL_MAP.get(feature as Feature) || '')
        .join(' ');
      return (
        <Box mb={2} key={key}>
          <DetailsText>{name}</DetailsText>
          <SubDetailsText>
            {etfExclusiveAssetClassesSet.has(key) ? 'ETF-only' : 'Single-securities'}
          </SubDetailsText>
          <Box hidden={featureFlags?.disable_hold_on_custom_number_of_tickers !== 'on'}>
            <SubDetailsText>
              {minSymbolAssetClassesSet.has(key)
                ? 'Fewer holdings'
                : 'Vise-recommended asset concentration'}
            </SubDetailsText>
          </Box>
        </Box>
      );
    });
  }

  if (
    onlyUpdatedValues &&
    !updatedCategories.has('ALLOCATION_TEMPLATE') &&
    !updatedCategories.has('CONSTRAINTS') &&
    !updatedCategories.has('PORTFOLIO_SPLIT') &&
    !updatedCategories.has('EQUITY_ALLOCATION') &&
    !updatedCategories.has('FIXED_INCOME_ALLOCATION') &&
    !updatedCategories.has('ALTERNATIVES_ALLOCATION') &&
    !updatedCategories.has('INVESTMENT_VEHICLES')
  ) {
    return null;
  }

  const hasGlidePath = investmentTimelineMoment && useGlidePath;
  // TODO(jatwood): Update hardcoded copy
  return (
    <MainSection>
      <SectionHeader>Allocation</SectionHeader>
      {!onlyUpdatedValues || updatedCategories.has('ALLOCATION_TEMPLATE') ? (
        <Section isOuterSection>
          <SectionSubHeader
            updated={updatedCategories.has('ALLOCATION_TEMPLATE')}
            to="/secure/portfolio-creator-next/select-allocation-template"
          >
            Strategy type
          </SectionSubHeader>
          <DetailsText>Saved template</DetailsText>
          <SubDetailsText>
            <Box data-cy="allocation-template-name">
              {draftPortfolio.allocationTemplate?.name || 'None selected'}{' '}
            </Box>
            {draftPortfolio.allocationTemplate?.createdAt && (
              <Box component="span" color="grey.400">
                (Created{' '}
                {moment(draftPortfolio.allocationTemplate.createdAt).format('MMM DD, YYYY')})
              </Box>
            )}
          </SubDetailsText>
        </Section>
      ) : null}
      {!onlyUpdatedValues || updatedCategories.has('CONSTRAINTS') ? (
        <Section isOuterSection>
          <SectionSubHeader
            updated={updatedCategories.has('CONSTRAINTS')}
            to="/secure/portfolio-creator-next/custom-allocation"
          >
            Portfolio constraints
          </SectionSubHeader>
          {hasGlidePath && (
            <>
              <Section>
                <DetailsText>Horizon</DetailsText>
                <SubDetailsText>
                  {investmentTimelineMoment.year()} (
                  {getRemainingYears(investmentTimelineMoment, moment())} years remaining)
                </SubDetailsText>
                <SubDetailsText>
                  Glide path:{' '}
                  {readableGlidePath(
                    glidePathValue(
                      constructionInfo.useGlidePath,
                      getRemainingYears(investmentTimelineMoment, moment())
                    )
                  )}
                </SubDetailsText>
              </Section>
            </>
          )}
          {hasConcentrationLimits && (
            <>
              <Section>
                <Box mb={2}>
                  <DetailsText>Max concentration limits</DetailsText>
                </Box>
                {equityConcentrationLimits != null && (
                  <DetailsText>Equities {equityConcentrationLimits}%</DetailsText>
                )}
                {fixedIncomeConcentrationLimits != null && (
                  <DetailsText>Fixed income {fixedIncomeConcentrationLimits}%</DetailsText>
                )}
                {alternativesConcentrationLimits != null && (
                  <DetailsText>Alternatives {alternativesConcentrationLimits}%</DetailsText>
                )}
              </Section>
            </>
          )}
          {!hasConcentrationLimits && !hasGlidePath && <DetailsText>No constraints</DetailsText>}
        </Section>
      ) : null}
      {!onlyUpdatedValues || updatedCategories.has('PORTFOLIO_SPLIT') ? (
        <Section isOuterSection>
          <SectionSubHeader
            updated={updatedCategories.has('PORTFOLIO_SPLIT')}
            to="/secure/portfolio-creator-next/custom-allocation"
          >
            Portfolio split
          </SectionSubHeader>
          <Section>
            <Section>
              <DetailsText>Equities</DetailsText>
              <SubDetailsText testId="equities-split">
                {(sumKeys(equitiesKeys, constructionInfo.assetAllocation) * 100).toFixed(0)}%
              </SubDetailsText>
            </Section>
            <Section>
              <DetailsText>Fixed Income</DetailsText>
              <SubDetailsText testId="fixed-income-split">
                {(sumKeys(fixedIncomeKeys, constructionInfo.assetAllocation) * 100).toFixed(0)}%
              </SubDetailsText>
            </Section>
            <Section>
              <DetailsText>Alternatives</DetailsText>
              <SubDetailsText testId="alternatives-split">
                {(sumKeys(alternativesKeys, assetAllocation) * 100).toFixed(0)}%
              </SubDetailsText>
            </Section>
          </Section>
        </Section>
      ) : null}
      {!onlyUpdatedValues || updatedCategories.has('EQUITY_ALLOCATION') ? (
        <Section isOuterSection>
          <SectionSubHeader
            updated={updatedCategories.has('EQUITY_ALLOCATION')}
            to="/secure/portfolio-creator-next/custom-allocation"
          >
            Equities
          </SectionSubHeader>
          <Box pr={2}>
            <SubCategoryAllocation parent="EQUITY/US" assetAllocation={assetAllocation} />
            <SubCategoryAllocation parent="EQUITY/DEVELOPED" assetAllocation={assetAllocation} />
            <SubCategoryAllocation
              parent="EQUITY/EMERGING_EQUITY"
              assetAllocation={assetAllocation}
            />
            <SubCategoryAllocation parent="EQUITY/US_REIT" assetAllocation={assetAllocation} />
          </Box>
        </Section>
      ) : null}
      {!onlyUpdatedValues || updatedCategories.has('FIXED_INCOME_ALLOCATION') ? (
        <Section isOuterSection>
          <SectionSubHeader
            updated={updatedCategories.has('FIXED_INCOME_ALLOCATION')}
            to="/secure/portfolio-creator-next/custom-allocation"
          >
            Fixed Income
          </SectionSubHeader>
          <Box pr={2}>
            <SubCategoryAllocation
              parent="FIXED_INCOME/DOMESTIC"
              assetAllocation={assetAllocation}
            />
            <SubCategoryAllocation
              parent="FIXED_INCOME/INTERNATIONAL"
              assetAllocation={assetAllocation}
            />
            <SubCategoryAllocation
              parent="FIXED_INCOME/EMERGING_FI"
              assetAllocation={assetAllocation}
            />
          </Box>
        </Section>
      ) : null}
      {!onlyUpdatedValues || updatedCategories.has('ALTERNATIVES_ALLOCATION') ? (
        <Section isOuterSection>
          <SectionSubHeader
            updated={updatedCategories.has('ALTERNATIVES_ALLOCATION')}
            to="/secure/portfolio-creator-next/custom-allocation"
          >
            Alternatives
          </SectionSubHeader>
          <Box pr={2}>
            <SubCategoryAllocation
              parent="ALTERNATIVES/COMMODITIES"
              assetAllocation={assetAllocation}
            />
          </Box>
        </Section>
      ) : null}
      {!onlyUpdatedValues || updatedCategories.has('INVESTMENT_VEHICLES') ? (
        <Section isOuterSection>
          <SectionSubHeader
            to="/secure/portfolio-creator-next/fine-tune-strategy"
            updated={updatedCategories.has('INVESTMENT_VEHICLES')}
          >
            Investment vehicles
          </SectionSubHeader>
          {investmentVehiclesBody}
        </Section>
      ) : null}
    </MainSection>
  );
}

export default function SummaryLandingScreen({
  clientName,
  householdName,
  draftPortfolio,
  sortedLockedPositions,
  shouldShowCapitalLossesAndGainsScreen,
  onBack,
  awaitingPost,
  isCreatePortfolioDisabled,
  handleCreatePortfolioIntelligence,
  cashConcentrationLimit,
  dpDispatch,
}: SummaryLandingScreenProps) {
  const { constructionInfo, isEditMode, accountSize, name: proposalName } = draftPortfolio;
  const { existingPortfolio } = constructionInfo;

  const [proposalNameValue, setProposalNameValue] = useState(proposalName);
  const { data: featureFlags } = useFeatureFlags();
  const isSmall = isSmallAccount(accountSize);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [updatedCategories, setUpdatedCategories] = useState<Set<PC_CATEGORY>>(new Set());

  useEffect(() => {
    if (featureFlags?.enable_return_to_summary === 'on') {
      dpDispatch({
        type: 'SET_PREVIOUS_DRAFT_PORTFOLIO',
      });
    }
  }, [featureFlags?.enable_return_to_summary, dpDispatch]);

  useEffect(() => {
    const updatedCategoriesForDp =
      featureFlags?.enable_optional_proposal_review === 'on'
        ? updatedCategoriesForDraftPortfolio(draftPortfolio, true)
        : new Set<PC_CATEGORY>();
    setUpdatedCategories(updatedCategoriesForDp);
  }, [featureFlags?.enable_optional_proposal_review, draftPortfolio]);

  // TODO(rkurohara): In order to directly execute proposals, the proposal that is being edited
  // must be current active proposal. Consider race conditions here if the active proposal
  // changes during
  const suggestSkipProposal =
    !isEmpty(updatedCategories) &&
    existingPortfolio !== 'sample-portfolio' &&
    existingPortfolio?.status === 'ACTIVE' &&
    existingPortfolio.portfolioIntelligenceId ===
      draftPortfolio.boostrappedPortfolioIntelligenceId &&
    Array.from(updatedCategories).every(
      (category) =>
        category === 'LOCKED_POSITIONS' ||
        category === 'TAX_MANAGEMENT' ||
        category === 'RESTRICTED_EQUITIES' ||
        category === 'RESTRICTED_SECTORS' ||
        category === 'RESTRICTED_COUNTRIES' ||
        category === 'RESTRICTED_ESG_AREAS'
    );

  return (
    <>
      <HeaderContainer>
        <Box flex={1} py={3}>
          <Typography variant="h1">Portfolio Summary</Typography>
        </Box>
        <img alt="" src={summaryBannerImage} />
      </HeaderContainer>
      <Body>
        <Box mb={6}>
          <TextField
            fullWidth
            value={proposalNameValue}
            onChange={(e) => setProposalNameValue(e.target.value)}
            label="Proposal Name"
            title="Proposal Name"
          />
        </Box>
        {updatedCategories.size !== 0 && (
          <>
            <Box mb={8}>
              <Box display="flex" alignItems="center" mb={5}>
                <CircleIcon width={32} height={32}>
                  <DocumentTextIcon width={20} height={20} />
                </CircleIcon>
                <Box ml={2}>
                  <Typography variant="h6">UPDATES</Typography>
                </Box>
              </Box>
              <TransitionSettings
                draftPortfolio={draftPortfolio}
                updatedCategories={updatedCategories}
                onlyUpdatedValues
                sortedLockedPositions={sortedLockedPositions}
                cashConcentrationLimit={cashConcentrationLimit}
              />
              {isSmall && (
                <>
                  <FocusComponent
                    draftPortfolio={draftPortfolio}
                    updatedCategories={updatedCategories}
                    onlyUpdatedValues
                  />
                  <AssetClasses
                    draftPortfolio={draftPortfolio}
                    updatedCategories={updatedCategories}
                    onlyUpdatedValues
                    isSmall={isSmall}
                  />
                </>
              )}
              {!isSmall && (
                <Allocation
                  draftPortfolio={draftPortfolio}
                  isSmall={isSmall}
                  updatedCategories={updatedCategories}
                  onlyUpdatedValues
                />
              )}
              <Tilts
                draftPortfolio={draftPortfolio}
                updatedCategories={updatedCategories}
                onlyUpdatedValues
                isSmall={isSmall}
              />
              <Restrictions
                draftPortfolio={draftPortfolio}
                updatedCategories={updatedCategories}
                onlyUpdatedValues
                isSmall={isSmall}
              />
              <TaxManagement
                draftPortfolio={draftPortfolio}
                updatedCategories={updatedCategories}
                onlyUpdatedValues
                shouldShowCapitalLossesAndGainsScreen={shouldShowCapitalLossesAndGainsScreen}
              />
            </Box>
            <Box mb={5}>
              <Box display="flex" alignItems="center" mb={5}>
                <CircleIcon width={32} height={32}>
                  <CheckCircleIcon width={20} height={20} />
                </CircleIcon>
                <Box ml={2}>
                  <Typography variant="h6">FULL SUMMARY</Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
        <MainSection>
          <SectionHeader
            to={isEditMode ? null : '/secure/portfolio-creator-next/select-client'}
            tooltip={
              isEditMode
                ? 'Client details may not be modified when a proposal is being edited.'
                : null
            }
          >
            Client details
          </SectionHeader>
          <Section isOuterSection>
            <SectionSubHeader>Client and household</SectionSubHeader>
            <DetailsText>{householdName}</DetailsText>
            <SubDetailsText>{clientName}</SubDetailsText>
          </Section>
          {existingPortfolio && existingPortfolio !== 'sample-portfolio' && (
            <>
              {existingPortfolio.accountDataSource !== 'XRAY' && (
                <Section isOuterSection>
                  <SectionSubHeader>Account name</SectionSubHeader>

                  <DetailsText>{existingPortfolio.name}</DetailsText>
                  <SubDetailsText>
                    {`${maskAccountNumber(existingPortfolio.accountNumber)}, ${
                      existingPortfolio.custodianKey
                    }`}
                  </SubDetailsText>
                </Section>
              )}
              <Section isOuterSection>
                <SectionSubHeader>Account type</SectionSubHeader>
                <DetailsText>{`${
                  existingPortfolio.taxable ? 'Non-qualified' : 'Qualified'
                }`}</DetailsText>
              </Section>
            </>
          )}
          <Section isOuterSection>
            <SectionSubHeader>Proposal type</SectionSubHeader>
            <DetailsText>
              {existingPortfolio === 'sample-portfolio' ||
              existingPortfolio?.accountDataSource === 'XRAY'
                ? 'Sample portfolio'
                : 'Linked to account'}
            </DetailsText>
          </Section>
        </MainSection>
        <TransitionSettings
          draftPortfolio={draftPortfolio}
          updatedCategories={updatedCategories}
          onlyUpdatedValues={false}
          sortedLockedPositions={sortedLockedPositions}
          cashConcentrationLimit={cashConcentrationLimit}
        />
        {isSmall && (
          <FocusComponent
            draftPortfolio={draftPortfolio}
            updatedCategories={updatedCategories}
            onlyUpdatedValues={false}
          />
        )}
        {isSmall && (
          <AssetClasses
            draftPortfolio={draftPortfolio}
            updatedCategories={updatedCategories}
            onlyUpdatedValues={false}
            isSmall={isSmall}
          />
        )}
        {!isSmall && (
          <Allocation
            draftPortfolio={draftPortfolio}
            isSmall={isSmall}
            updatedCategories={updatedCategories}
          />
        )}
        <Tilts
          draftPortfolio={draftPortfolio}
          updatedCategories={updatedCategories}
          onlyUpdatedValues={false}
          isSmall={isSmall}
        />
        <Restrictions
          draftPortfolio={draftPortfolio}
          updatedCategories={updatedCategories}
          onlyUpdatedValues={false}
          isSmall={isSmall}
        />
        <TaxManagement
          draftPortfolio={draftPortfolio}
          updatedCategories={updatedCategories}
          onlyUpdatedValues={false}
          shouldShowCapitalLossesAndGainsScreen={shouldShowCapitalLossesAndGainsScreen}
        />
      </Body>
      <ActionBarV2>
        <Box display="flex" justifyContent="space-between">
          <BackButton onClick={() => onBack()} />
          <Box textAlign="right">
            <CreatePortfolioButton
              disabled={isCreatePortfolioDisabled}
              loading={awaitingPost && !suggestSkipProposal}
              onClick={() => {
                if (suggestSkipProposal) {
                  setIsConfirmationModalOpen(true);
                } else {
                  handleCreatePortfolioIntelligence({ proposalName: proposalNameValue });
                }
              }}
            />
          </Box>
        </Box>
      </ActionBarV2>
      <SkipProposalModal
        open={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        awaitingPost={awaitingPost}
        onCreatePortfolioIntelligence={() =>
          handleCreatePortfolioIntelligence({
            proposalName: proposalNameValue,
          })
        }
        onCreateAndExecutePortfolioIntelligence={() =>
          handleCreatePortfolioIntelligence({
            proposalName: proposalNameValue,
            executeProposal: true,
          })
        }
      />
    </>
  );
}
