import { Typography as TypographyBase } from "@mui/material";
import type { TypographyProps } from "@mui/material";
import React from "react";

/**
 * For storybook autodocs, don't use this, instead use the MUI Typography export directly
 * @param props MUI Typography props
 * @returns
 */
export default function Typography(props: TypographyProps): React.ReactElement {
  return <TypographyBase {...props} />;
}
