import React from 'react';
import get from 'lodash/get';
import { FormControlLabel, Checkbox, Radio, FormControlLabelProps } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

/** Utils for selector checkboxes/radios, which have
 * a label and optional description.
 */

const useMultilineSelectorFormControlLabelStyles = makeStyles()({
  root: {
    '&.MuiFormControlLabel-root': {
      alignItems: 'flex-start',
    },
  },
});

export function MultilineSelectorFormControlLabel(props: FormControlLabelProps) {
  const { classes } = useMultilineSelectorFormControlLabelStyles();
  return <FormControlLabel className={classes.root} {...props} />;
}

const useMultilineSelectorDescriptionStyles = makeStyles<{ disabled: unknown }>()(
  (theme, props) => ({
    root: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(theme.typography.body1 as any),
      lineHeight: 11 / 7,
      margin: theme.spacing(1, 0, 0),
      color: props.disabled ? theme.palette.grey[400] : theme.palette.grey[500],
    },
  })
);

export function MultilineSelectorDescription(props) {
  const { disabled, ...other } = props;
  const { classes } = useMultilineSelectorDescriptionStyles({ disabled });
  return <p className={classes.root} {...other} />;
}

const useMultilineSelectorTitleStyles = makeStyles<{ disabled: unknown; fontWeight: string }>()(
  (theme, props) => ({
    root: {
      color: props.disabled ? theme.palette.grey[400] : theme.palette.text.primary,
      fontWeight: props.fontWeight ? get(theme.palette, props.fontWeight) : undefined,
    },
  })
);

export function MultilineSelectorTitle(props) {
  const { disabled, fontWeight, ...other } = props;
  const { classes } = useMultilineSelectorTitleStyles({ disabled, fontWeight });
  return <span className={classes.root} {...other} />;
}

const useMultilineCheckboxStyles = makeStyles()((theme) => ({
  root: {
    '&.MuiCheckbox-root': {
      padding: theme.spacing(0, 1, 0, 1),
    },
  },
}));

export function MultilineCheckbox(props) {
  const { classes } = useMultilineCheckboxStyles();
  return <Checkbox className={classes.root} {...props} />;
}

const useMultilineRadioStyles = makeStyles()((theme) => ({
  root: {
    '&.MuiRadio-root': {
      padding: theme.spacing(0, 1, 0, 1),
    },
  },
}));

export function MultilineRadio(props) {
  const { classes } = useMultilineRadioStyles();
  return <Radio className={classes.root} {...props} />;
}
