import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as PlusIcon } from '~/static/images/icons/plus.svg';

import Highlighter from 'react-highlight-words';
import { components, createFilter, OptionProps, ValueType } from 'react-select';
import { linkAccountToClient } from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useClients from '~/hooks/useClients';
import { useCoachmarkEffect } from '~/hooks/useCoachmark';
import { RawClient } from '~/models/api';
import DialogTitle from '~/synth/DialogTitle';
import Select from '~/synth/inputs/Select';
import amplitude from '~/utils/amplitude';
import extractStatusAndMessageFromAPIError from '~/utils/apiErrors';
import { getCustodianDisplayName, maskAccountNumber } from '~/utils/format';
import LinkAccountOrCreateClientModal from '~/routes/Households/Households/LinkAccountOrCreateClientModal';
import { ScreenProps } from '../Types';
import { ActionFooter, ContentBox } from './components';

interface ClientOption {
  client: RawClient | null;
  label: string;
  value: string;
}

type SelectedClientOption = ClientOption | null;

const Option = React.memo(function Option(props: OptionProps<ClientOption>) {
  const { label, selectProps } = props;
  return props.data.client == null ? (
    <Box
      bottom={0}
      pb={0.5}
      position="sticky"
      style={{
        backgroundColor: 'white',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.06)',
      }}
    >
      <components.Option {...props}>
        <strong>{props.children}</strong>
      </components.Option>
    </Box>
  ) : (
    <components.Option {...props}>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Highlighter
          autoEscape
          highlightTag="strong"
          searchWords={selectProps.inputValue ? [selectProps.inputValue.trim()] : []}
          textToHighlight={label}
        />
        {/* When option is selected ensure "selected" state can style the text to ensure a minimum
         * contrast between household name and background. */}
        <Typography color={props.isSelected ? undefined : 'textSecondary'}>
          {props.data.client.clientGroup.name}
        </Typography>
      </Box>
    </components.Option>
  );
});

const ADD_CLIENT_OPTION: ClientOption = { client: null, label: '+ Add new client', value: 'NEW' };

const defaultFilter = createFilter({});
const clientOptionsFilter = (
  option: { data: ClientOption; label: string; value: string },
  rawInput: string
) => {
  return option.data === ADD_CLIENT_OPTION ? true : defaultFilter(option, rawInput);
};

export default function SelectClientStep({
  draftPortfolio: {
    bootstrapType,
    constructionInfo: { clientId, existingPortfolio },
    isEditMode,
  },
  onContinue,
  dpDispatch,
}: ScreenProps) {
  const isAccountUnlinked =
    bootstrapType === 'ACCOUNT' &&
    existingPortfolio !== 'sample-portfolio' &&
    existingPortfolio != null &&
    existingPortfolio.viseClientId == null;

  const [clientToLinkToNewAccount, setClientToLinkToNewAccount] = useState<string | null>(null);
  const [isClientLinkLoading, setIsClientLinkLoading] = useState<boolean>(false);
  const [clientLinkErrorMessage, setClientLinkErrorMessage] = useState<string | null>(null);
  const [newClientModalOpen, setNewClientModalOpen] = useState(false);

  useCoachmarkEffect({
    show: clientLinkErrorMessage != null,
    severity: 'error',
    title: 'Error linking account',
    content: clientLinkErrorMessage ?? undefined,
  });

  useEffect(() => {
    amplitude().logEvent('Impression - client details', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);
  const { data: clientsData, isLoading: clientsIsLoading } = useClients();

  const clientOptions: ClientOption[] = useMemo(
    () =>
      clientsData == null
        ? [ADD_CLIENT_OPTION]
        : clientsData
            .map<ClientOption>((client) => ({
              client,
              label: `${client.firstName} ${client.lastName}`,
              value: client.id,
            }))
            .concat([ADD_CLIENT_OPTION]),
    [clientsData]
  );

  const selectedClientOption = useMemo(
    () => (clientId == null ? null : clientOptions.find((co) => co.client?.id === clientId)),
    [clientOptions, clientId]
  );

  function handleOnChange(selectValue: ValueType<ClientOption>): void {
    const value = selectValue as SelectedClientOption;
    if (value === ADD_CLIENT_OPTION) {
      amplitude().logEvent('Select create client in dropdown', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      });
      if (clientId != null) dpDispatch({ clientId: null, type: 'SET_EXISTING_CLIENT' });
      onContinue('createClient');
    } else {
      amplitude().logEvent('Select client', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        clientId: value == null || value?.client == null ? null : value.client.id,
      });
      dpDispatch({
        clientId: value == null || value?.client == null ? null : value.client.id,
        type: 'SET_EXISTING_CLIENT',
      });
    }
  }

  function handleSubmit(event: React.FormEvent): void {
    event.preventDefault();

    if (isAccountUnlinked) {
      setClientToLinkToNewAccount(clientId);
    } else {
      amplitude().logEvent('Continue to proposal from existing client', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        clientId,
      });
      onContinue();
    }
  }

  let existingAccountID: string | undefined;
  let existingAccountDispayName = '';
  if (existingPortfolio != null && existingPortfolio !== 'sample-portfolio') {
    existingAccountID = existingPortfolio.id;
    const accountName = existingPortfolio.name;
    const maskedAccountNumber = maskAccountNumber(existingPortfolio.accountNumber);
    const custodianDisplayName = getCustodianDisplayName(existingPortfolio.custodianKey);
    existingAccountDispayName = `${accountName} (${maskedAccountNumber}, ${custodianDisplayName})`;
  }

  function onLinkNewAccountDialogCloseEvent(): void {
    if (!isClientLinkLoading) {
      setClientToLinkToNewAccount(null);
    }
  }

  async function handleLinkNewAccountToClient(): Promise<void> {
    if (clientId == null || existingAccountID == null) {
      return;
    }

    setIsClientLinkLoading(true);
    try {
      const account = await linkAccountToClient(clientId, existingAccountID);
      dpDispatch({
        viseClientId: account.viseClientId,
        type: 'UPDATE_ACCOUNT_CLIENT_ID',
      });
      amplitude().logEvent('Continue to proposal after linking account to client', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        clientId,
      });
      onContinue();
    } catch (e) {
      const { message } = extractStatusAndMessageFromAPIError(e);
      setClientLinkErrorMessage(message);
    } finally {
      setIsClientLinkLoading(false);
    }
  }

  return (
    <>
      <Dialog open={clientToLinkToNewAccount != null} onClose={onLinkNewAccountDialogCloseEvent}>
        <DialogTitle onClose={onLinkNewAccountDialogCloseEvent}>
          Link new account to client?
        </DialogTitle>
        <DialogContent>
          {existingAccountDispayName} will be linked to existing client{' '}
          {selectedClientOption?.label}. This action{' '}
          <span style={{ textDecoration: 'underline' }}>cannot</span> be undone.
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            disabled={isClientLinkLoading}
            variant="outlined"
            onClick={onLinkNewAccountDialogCloseEvent}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isClientLinkLoading}
            variant="contained"
            onClick={handleLinkNewAccountToClient}
          >
            Link and continue
          </Button>
        </DialogActions>
      </Dialog>
      <ContentBox>
        <Box mb={4}>
          <Typography variant="h1">
            {isAccountUnlinked
              ? 'First, assign a client and household to this account.'
              : 'First, select or add a client.'}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <FormLabel htmlFor="client">
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h3" data-cy="select-client-step-title">
                  Search for an existing or create a prospective client.
                </Typography>
                <Box display="flex" alignItems="center">
                  <Button
                    size="small"
                    variant="text"
                    color="primary"
                    startIcon={<PlusIcon />}
                    onClick={() => {
                      amplitude().logEvent('Select create client through button', {
                        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                      });
                      setNewClientModalOpen(true);
                    }}
                  >
                    New client
                  </Button>
                </Box>
              </Box>
            </FormLabel>
            <Select<ClientOption>
              autoFocus
              isSearchable
              components={{ Option }}
              filterOption={clientOptionsFilter}
              inputId="client"
              isClearable
              isDisabled={isEditMode}
              isLoading={clientsIsLoading}
              options={clientOptions}
              onChange={handleOnChange}
              onFocus={() =>
                amplitude().logEvent('Tap client search', {
                  category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                })
              }
              placeholder="Ex: Jane Doe"
              required
              styles={{
                menuList(provided) {
                  return {
                    ...provided,
                    paddingBottom: 0,
                  };
                },
              }}
              value={selectedClientOption}
            />
          </FormControl>
          <ActionFooter sx={{ justifyContent: 'flex-end', borderTop: 1 }}>
            <Button color="primary" disabled={clientId == null} type="submit" variant="contained">
              Continue
            </Button>
          </ActionFooter>
        </form>
      </ContentBox>
      <LinkAccountOrCreateClientModal
        open={newClientModalOpen}
        onClose={() => setNewClientModalOpen(false)}
        onSuccess={(client) => dpDispatch({ clientId: client.id, type: 'SET_EXISTING_CLIENT' })}
      />
    </>
  );
}
