import React from 'react';
import { AccountStatus } from 'vise-types/portfolio';
import Truncation from '~/routes/Portfolio/components/Truncation';
import { TextHighlightTag } from '~/synth/Tag';

const displayTag = (text, severity) => {
  return text ? (
    // Set width to make sure truncation activates
    <TextHighlightTag severity={severity} fontWeight={400} maxWidth="100%">
      <Truncation>{text}</Truncation>
    </TextHighlightTag>
  ) : (
    <></>
  );
};

export default function renderAccountRemovalStatus({
  value,
}: {
  value?: { status: AccountStatus; statusReason?: string | null; isRemovalPending?: boolean };
}) {
  if (!value) {
    return null;
  }
  const { status, statusReason, isRemovalPending } = value;
  let text = '';
  let severity: 'alert' | 'info' = 'info';

  // if the account is still active and isRemovalPending is true in AccountChurnReason for the account, display "Pending Removal" as purple
  // if the account is inactive, display it as gray
  if (status === 'ACTIVE' && isRemovalPending) {
    text = 'Pending removal';
    severity = 'alert';
  } else if (statusReason === 'account_removal_event' || isRemovalPending) {
    text = 'Pending removal';
    severity = 'info';
  }

  return displayTag(text, severity);
}
