import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  CircularProgress,
} from '@mui/material';
import DialogTitle from '~/synth/DialogTitle';

interface DeleteItemModalProps {
  title: React.ReactNode;
  content: React.ReactNode;
  isLoading: boolean;
  isOpen: boolean;
  cancelButtonContent?: React.ReactNode;
  confirmButtonContent?: React.ReactNode;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteItemModal = ({
  title,
  content,
  isLoading,
  isOpen,
  confirmButtonContent = 'Yes',
  cancelButtonContent = 'Cancel',
  onClose,
  onSubmit,
}: DeleteItemModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body2">{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isLoading} onClick={onClose}>
          {cancelButtonContent}
        </Button>
        <Button
          startIcon={isLoading ? <CircularProgress size="1em" color="inherit" /> : undefined}
          disabled={isLoading}
          color="error"
          variant="outlined"
          onClick={onSubmit}
        >
          {confirmButtonContent}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteItemModal;
