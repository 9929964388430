export default {
  // Base shadow - Most used shadow. Typically for cards.
  base: "0px 1px 4px rgba(0, 0, 0, 0.08)",
  // Medium shadow
  medium: "0px 4px 8px rgba(0, 0, 0, 0.04)",
  // Large shadow - Modals and popovers. Items that sit on the highest level above content.
  large: "0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05)",
  // Inner shadow - showing scrollability
  inner: "inset 0 2px 4px rgba(0, 0, 0, 0.06)",
  // big
  hero: "0px 10px 15px 0px rgba(14, 50, 133, 0.1)",
};
