import useSWR from 'swr';
import { getHouseholdsWithSummary } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';
import useFeatureFlags from './useFeatureFlags';

export const getHouseholdsWithSummarySwrKey = (perfSuppressionEnabled, excludeMarketData) =>
  generateSwrKey(
    `householdsWithSummary?perfSuppressionEnabled=${perfSuppressionEnabled}&excludeMarketData=${excludeMarketData}`
  );

const useHouseholdsWithSummary = (excludeMarketData: boolean) => {
  const { data: featureFlags } = useFeatureFlags();
  const perfSuppressionEnabled = featureFlags?.enable_performance_data !== 'on';
  const isModelDelivery = featureFlags?.is_enterprise_user === 'on';
  const { data, error, isValidating, mutate } = useSWR(
    getHouseholdsWithSummarySwrKey(perfSuppressionEnabled, excludeMarketData),
    () =>
      getHouseholdsWithSummary(
        perfSuppressionEnabled,
        excludeMarketData,
        isModelDelivery ? 'MODEL_DELIVERY' : null
      )
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export default useHouseholdsWithSummary;
