import { Box, Button, ButtonProps, SxProps } from '@mui/material';
import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '~/static/images/icons/arrow-left.svg';

export default function ActionBar({
  backButtonProps,
  continueButtonProps,
  showBackButton,
  showCancelButton,
  cancelButtonProps,
  sx,
}: {
  backButtonProps?: ButtonProps;
  continueButtonProps: ButtonProps;
  showBackButton: boolean;
  showCancelButton?: boolean;
  cancelButtonProps?: ButtonProps;
  sx?: SxProps;
}) {
  return (
    <Box display="flex" alignItems="center" width="100%" py={3} sx={sx}>
      <Box flex={1}>
        {showBackButton && (
          <Button startIcon={<ArrowLeftIcon />} {...backButtonProps}>
            Back
          </Button>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {showCancelButton && (
          <Box mr={1.5}>
            <Button variant="outlined" color="secondary" {...cancelButtonProps}>
              {cancelButtonProps?.children || 'Cancel'}
            </Button>
          </Box>
        )}
        <Button variant="contained" type="button" color="primary" {...continueButtonProps}>
          {continueButtonProps.children || 'Continue'}
        </Button>
      </Box>
    </Box>
  );
}
