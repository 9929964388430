import styled from 'styled-components';
import { Box } from '@mui/material';
import SelectorRow, { SelectorRowGroup } from '~/synth/SelectorRow';

export const ItemRowsContainer = styled(SelectorRowGroup)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const ItemRow = styled(SelectorRow)`
  padding: ${({ theme }) => theme.spacing(2)} 0px;
`;

export const EmptyItemsContainer = styled(Box).attrs({
  borderTop: 1,
  borderColor: 'grey.300',
  py: 2,
})``;
