import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { State } from '~/routes/ProductOnboarding/ClientOnboardingState';
import { ProductOnboardingRoutes } from '~/routes/ProductOnboarding/routes';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getProductOnboardingPdfs, updateUserMetadata } from '~/api/api';
import { ReactComponent as PencilEdit } from '~/static/images/icons/pencil.svg';
import DialogTitle from '~/synth/DialogTitle';
import { Redirect, Route, useHistory } from 'react-router';
import ShuttleImg from '~/static/images/stuttle.svg';
import { ProductOnboardingGeneratePdfRequest } from 'vise-types/response';

// eslint-disable-next-line import/prefer-default-export
export function ProductOnboardingReviewPage({
  state,
  renderFooter,
}: {
  state: State;
  renderFooter: ({ isLoading }: { isLoading: boolean }) => React.ReactNode;
}) {
  const {
    currentAccountInformation,
    custodian,
    slMasterAccountNumber,
    brokerageAccountNumber,
    accountHolders,
  } = state;

  const { handleSubmit } = useForm();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);

    const {
      isNewAccountInAdvisorsBook,
      slMasterAccountNumber,
      brokerageAccountNumber,
      accountHolders,
      faMasterAccountNumber,
      previousSamName,
      previousSamProductName,
      fidelityAccountNumber,
      custodian,
    } = state;

    const payload: ProductOnboardingGeneratePdfRequest =
      custodian?.value === 'FIDELITY'
        ? {
            type: 'fidelity',
            fidelityAccountNumber,
            previousSamName,
            previousSamProductName,
            shouldChangeSam: Boolean(previousSamName && previousSamProductName),
            accountHolders: [currentAccountInformation, ...accountHolders],
          }
        : {
            type: 'schwab',
            slMasterAccountNumber,
            brokerageAccountNumber,
            isNewAccountInAdvisorsBook,
            faMasterAccountNumber,
            accountHolders: [currentAccountInformation, ...accountHolders],
          };

    const { data: pdfUrls } = await getProductOnboardingPdfs(payload);

    // Hack: Handle download(s) automatically. If more than one file is generated (ie. Fidelity
    // might generate two files), we add a slight delay to trick so the browser can handle them both.
    // We should probably use a library to handle this or return a zip file instead.
    const download = (url) => {
      const a = document.createElement('a');
      a.href = url;
      a.download = '';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    download(pdfUrls[0]);
    if (pdfUrls.length === 2) {
      setTimeout(() => {
        download(pdfUrls[1]);
      }, 2000);
    }

    await updateUserMetadata({ clientOnboardingStatus: 'COMPLETED' });
    setIsLoading(false);

    history.push(`${ProductOnboardingRoutes.review}/confirmation`);
  };

  const ConfirmationDialog = () => {
    return (
      <Dialog open>
        <DialogTitle
          onClose={() => {
            history.push('/secure/dashboard');
          }}
        />
        <DialogContent>
          <Box my={4} display="flex" justifyContent="center">
            <img src={ShuttleImg} width={150} height={150} alt="shuttle" />
          </Box>
          <Box textAlign="center" mx={5}>
            <Typography variant="h2">Congratulations! 🎉</Typography>
            <Box my={2}>
              You have now unlocked the full potential the Vise platform has to offer your business
              and managing your clients.{' '}
            </Box>
            If you ever need to review the Onboarding steps again, you can refer to the Resource for
            guidance. We are excited to see how you will use the Vise platform to grow and manage
            your business!
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              history.push('/secure/dashboard');
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const FidelityInfo = () => (
    <Box display="flex" justifyContent="space-between" color="grey.600">
      <Box>
        <p>Custodian type</p>
        <p>Fidelity account number</p>
        {state.managedByAnotherSam?.value === 'yes' && (
          <>
            <p>Previous SAM name</p>
            <p>Previous SAM product name</p>
          </>
        )}
        <p>Account holder 1</p>
        {accountHolders.map((accountHolder, index) => (
          <p key={`${accountHolder.lastName}${accountHolder.firstName}`}>
            Account holder {index + 2}
          </p>
        ))}
      </Box>
      <Box textAlign="end" paddingRight={2}>
        <p>{custodian?.label}</p>
        <p>{state.fidelityAccountNumber}</p>
        {state.managedByAnotherSam?.value === 'yes' && (
          <>
            <p>{state.previousSamName}</p>
            <p>{state.previousSamProductName}</p>
          </>
        )}
        <p>{`${currentAccountInformation.firstName} ${currentAccountInformation.lastName}`}</p>
        {accountHolders.map((accountHolder) => (
          <p
            key={accountHolder.lastName}
          >{`${accountHolder.firstName} ${accountHolder.lastName}`}</p>
        ))}
      </Box>
    </Box>
  );

  const SchwabInfo = () => (
    <Box display="flex" justifyContent="space-between" color="grey.600">
      <Box>
        <p>Custodian type</p>
        <p>SL master account number</p>
        <p>Brokerage account number</p>
        {state.isNewAccountInAdvisorsBook && <p>FA master account number</p>}
        <p>Account holder 1</p>
        {accountHolders.map((accountHolder, index) => (
          <p key={`${accountHolder.lastName}${accountHolder.firstName}`}>
            Account holder {index + 2}
          </p>
        ))}
      </Box>
      <Box textAlign="end" paddingRight={2}>
        <p>{custodian?.label}</p>
        <p>{slMasterAccountNumber}</p>
        <p>{brokerageAccountNumber}</p>
        {state.isNewAccountInAdvisorsBook && <p>{state.faMasterAccountNumber}</p>}
        <p>{`${currentAccountInformation.firstName} ${currentAccountInformation.lastName}`}</p>
        {accountHolders.map((accountHolder) => (
          <p
            key={accountHolder.lastName}
          >{`${accountHolder.firstName} ${accountHolder.lastName}`}</p>
        ))}
      </Box>
    </Box>
  );

  if (!state.selectedXray) return <Redirect to={ProductOnboardingRoutes.selectClient} />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p={4}>
        <Box display="flex" justifyContent="space-between" mb={2} alignItems="baseline">
          <Typography variant="h2" mb={3}>
            Review & finalize
          </Typography>
        </Box>

        <Typography mb={6}>
          You’re almost done, let’s review the data collected and wrap up this final step. Once
          you’ve reviewed the data, download a copy of the custodian authorization forms to send to
          your client.
        </Typography>

        <Box px={1}>
          <Box display="flex" justifyContent="space-between" alignItems="baseline">
            <Typography variant="h3">Client details</Typography>
            <Link to={ProductOnboardingRoutes.selectClient}>
              {' '}
              <Button color="secondary" startIcon={<PencilEdit />}>
                Edit
              </Button>
            </Link>
          </Box>
          <Divider sx={{ mt: 1 }} />
          <Box display="flex" justifyContent="space-between" color="grey.600">
            <Box>
              <p>First name</p>
              {currentAccountInformation.middleName && <p>Middle Name</p>}
              <p>Last name</p>
              <p>Household name</p>
            </Box>
            <Box textAlign="end" paddingRight={2}>
              <p>{currentAccountInformation.firstName}</p>
              {currentAccountInformation.middleName && (
                <p>{currentAccountInformation.middleName}</p>
              )}
              <p>{currentAccountInformation.lastName}</p>
              <p>{state.selectedClient?.householdName}</p>
            </Box>
          </Box>
        </Box>

        <Box px={1}>
          <Box display="flex" justifyContent="space-between" alignItems="baseline" mt={2}>
            <Typography variant="h3">Proposal details</Typography>
            <Link to={ProductOnboardingRoutes.selectXray}>
              {' '}
              <Button color="secondary" startIcon={<PencilEdit />}>
                Edit
              </Button>
            </Link>{' '}
          </Box>
          <Divider sx={{ mt: 1 }} />
          <Box display="flex" justifyContent="space-between" color="grey.600">
            <Box>
              <p>Custodian</p>
              <p>Account type</p>
            </Box>
            <Box textAlign="end" paddingRight={2}>
              <p>{custodian?.label}</p>
              <p>
                <p>{state.selectedXray?.taxable ? 'Non-qualified' : 'Qualified'}</p>
              </p>
            </Box>
          </Box>
        </Box>

        <Box px={1} sx={{ backgroundColor: 'blue.100' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
            <Typography variant="h3">Authorization form details</Typography>
            <Link to={ProductOnboardingRoutes.custodianForm}>
              {' '}
              <Button color="secondary" startIcon={<PencilEdit />}>
                Edit
              </Button>
            </Link>
          </Box>
          <Divider sx={{ mt: 1 }} />
          {state.custodian?.value === 'FIDELITY' && <FidelityInfo />}
          {state.custodian?.value === 'SCHWAB' && <SchwabInfo />}
        </Box>
        <Alert severity="warning" sx={{ maxWidth: '100%', my: 2 }}>
          In order to complete this process, we kindly request that you follow up with your end
          client and have them also sign the custodian authorization documents. Thank you for your
          cooperation.
        </Alert>
        {renderFooter({ isLoading })}
        <Route path={`${ProductOnboardingRoutes.review}/confirmation`}>
          <ConfirmationDialog />
        </Route>
      </Box>
    </form>
  );
}
