import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  InputAdornment,
  InputProps,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from 'tss-react/mui';

import { AxiosError } from 'axios';
import { difference, inRange, mapValues, pickBy, toLower, uniq } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import { getAssetAllocation } from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useUser from '~/hooks/useUser';
import { Account } from '~/models/api';
import AssetClassTree from '~/routes/ModelTemplateCenter/workflows/AssetClassTree';
import { ReactComponent as ExclamationIcon } from '~/static/images/icons/exclamation.svg';
import { ReactComponent as KnobsIcon } from '~/static/images/icons/knobs.svg';
import { ReactComponent as QuestionIcon } from '~/static/images/icons/question-mark-circle.svg';
import { ReactComponent as TrashIcon } from '~/static/images/icons/trash.svg';
import HelpButton from '~/synth/Button';
import CardContent from '~/synth/CardContent';
import CardHeader from '~/synth/CardHeader';
import { SelectorCheckboxGroup } from '~/synth/SelectorCheckbox';
import TextField from '~/synth/TextField';
import amplitude from '~/utils/amplitude';
import { getAssetClassKeyFromFeatures } from '~/utils/pce2Migration';
import {
  ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP,
  ASSET_CLASS_TO_LABEL_MAP,
  ASSET_CLASS_TREES,
} from '../../Constants';
import ReturnToSummary from '../../ReturnToSummary';
import { Pce2ConstructionInfo } from '../../Types';
import { fillInAssetAllocation } from '../../useDraftPortfolioReducer';
import {
  calculateTreeLeafsSum,
  closeEquality,
  flipAllAssetClassesWhereApplicable,
} from '../../utils';
import { ActionFooter, BackButton, ContentBox, ExplainerText } from '../components';
import ChooseAllocationModal from '../components/ChooseAllocationModal';
import { DetailsText } from '../components/SummarySections';
import ClearAllModal from './ClearAllModal';
import SaveAllocationsTemplateModal, {
  SaveAllocationsTemplateModalProps,
} from './SaveAllocationsTemplateModal';
import { AssetClassScreenProps } from './types';

const SAVE_TEMPLATE_MODAL_CLOSED_SETTINGS = {
  open: false,
  isCustomAllocation: false,
  customAllocations: [],
} as Pick<
  SaveAllocationsTemplateModalProps,
  'allocations' | 'orgId' | 'userId' | 'open' | 'isCustomAllocation' | 'customAllocations'
>;

function LocallyDelayedNumberFormat({ value, onChange, ...props }) {
  const [localValue, setLocalValue] = useState<string | undefined>();
  return (
    <NumberFormat
      decimalScale={1}
      value={localValue != null ? localValue : value}
      onBlur={() => {
        setLocalValue(undefined);
      }}
      isAllowed={(values) => inRange(values.floatValue || 0, 0, 100.1)}
      onChange={(e) => {
        onChange(e);
        setLocalValue(e.target.value);
      }}
      {...props}
    />
  );
}

function TotalNumberFormatTextField({ ...props }) {
  const theme = useTheme();
  let inputProps: Partial<InputProps> = props.error
    ? {
        style: { backgroundColor: theme.palette.error[100] },
      }
    : { style: { backgroundColor: theme.palette.success[100] } };
  if (props.InputProps != null) {
    inputProps = { ...props.InputProps, ...inputProps };
  }
  return <TextField {...props} InputProps={inputProps} />;
}

const SelectorCheckboxTree = SelectorCheckboxGroup;

const usePCE2Styles = makeStyles()((theme) => ({
  assetClassHeader: {
    backgroundColor: theme.palette.grey[100],
  },
}));

// we have to use -1px hack to be able to trigger IntersectionObserver
const StickyAssetAllocationsHeader = styled(Box)`
  display: flex;
  align-items: baseline;
  position: sticky;
  top: -1px;
  background: white;
  padding: ${(props) => props.theme.spacing(2)} 0;
  z-index: 10;
  &.sticky {
    box-shadow: 0 1px 0 ${(props) => props.theme.palette.grey[300]};
  }
`;

export default function PCE2AssetClassScreen({
  dpDispatch,
  draftPortfolio,
  onBack,
  onContinue,
  onReturnToSummary,
}: AssetClassScreenProps) {
  const {
    etfExclusive: isEtfExclusive,
    assetAllocation,
    customAllocations,
  } = draftPortfolio.constructionInfo as Pce2ConstructionInfo;

  const [saveTemplateModalSettings, setSaveTemplateModalSettings] = useState<
    Pick<
      SaveAllocationsTemplateModalProps,
      'allocations' | 'orgId' | 'userId' | 'open' | 'isCustomAllocation' | 'customAllocations'
    >
  >(SAVE_TEMPLATE_MODAL_CLOSED_SETTINGS);

  const { data: userData } = useUser();

  const { classes: pce2Styles } = usePCE2Styles();

  const assetAllocationCopy = mapValues(assetAllocation, (num) => (num || 0) * 100);

  const [localExclusions, setLocalExclusions] = useState<AssetClassKey[]>(
    Object.keys(pickBy(assetAllocationCopy, (num) => num === 0)) as AssetClassKey[]
  );

  const [lockedClasses, setLockedState] = useState(customAllocations || []);

  const [splitValues, setSplitValues] = useState({
    equityAllocation: (assetAllocation?.EQUITY || 0) * 100,
    fixedIncomeAllocation: (assetAllocation?.FIXED_INCOME || 0) * 100,
    alternatives: (assetAllocation?.ALTERNATIVES || 0) * 100,
  });

  const [clearAllModalOpen, setClearAllModalOpen] = useState(false);

  const [chooseAllocationModalOpen, setChooseAllocationModalOpen] = useState(false);

  const totalSplit = Object.values(splitValues).reduce((acc, value) => acc + value, 0);

  const handleSubmit = (event) => {
    event.preventDefault();
    onContinue();
  };

  const toggledAssetClasses = useMemo(() => {
    const exclusionSet = new Set(localExclusions);
    const toggledSet = new Set<AssetClassKey>();

    const addAllFeatureSets = (node, curFeatureSet) => {
      if (!node) {
        return;
      }

      const assetClassKey = getAssetClassKeyFromFeatures(curFeatureSet);
      if (!exclusionSet.has(assetClassKey)) {
        toggledSet.add(assetClassKey);
      }

      if (node.children) {
        node.children.forEach((child) =>
          addAllFeatureSets(child, [...curFeatureSet, child.feature])
        );
      }
    };

    ASSET_CLASS_TREES.forEach((node) => addAllFeatureSets(node, [node.feature]));

    return toggledSet;
  }, [localExclusions]);

  const handleCustomAllocationChange = (assetClass: AssetClassKey, allocation: string) => {
    let allocationNumber = parseFloat(allocation);
    if (isNaN(allocationNumber)) {
      allocationNumber = 0;
    }
    const newAllocation =
      assetClass && allocation != null
        ? { ...assetAllocationCopy, [assetClass]: allocationNumber }
        : assetAllocationCopy;
    dpDispatch({
      type: 'SET_ASSET_CLASS_ALLOCATIONS',
      allocations: mapValues(newAllocation, (value) => (value && !isNaN(value) ? value / 100 : 0)),
      customAllocations: uniq([
        ...(draftPortfolio.constructionInfo.customAllocations || []),
        assetClass,
      ]),
    });
    dpDispatch({
      type: 'REMOVE_ALLOCATIONS_TEMPLATE',
    });
    setLockedState(uniq([...lockedClasses, assetClass]));
    amplitude().logEvent('Manully edit asset class allocation', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      assetClass,
    });
  };

  const createHandleOnToggleAssetClass = useCallback(
    (assetClassKey: AssetClassKey) => () => {
      const exclusionSet = new Set(localExclusions);
      let amplitudeEventName: string;
      if (exclusionSet.has(assetClassKey)) {
        amplitudeEventName = 'Deselect';
        exclusionSet.delete(assetClassKey);
      } else {
        amplitudeEventName = 'Select';
        exclusionSet.add(assetClassKey);
      }
      amplitude().logEvent(`${amplitudeEventName} asset class`, {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        assetClass: assetClassKey,
      });
      setLocalExclusions((prev) => {
        const exclusionsToToggle = [
          assetClassKey,
          ...(ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get(assetClassKey) || []),
        ];
        const newExclusions = toggledAssetClasses.has(assetClassKey)
          ? uniq([...prev, ...exclusionsToToggle])
          : difference(prev, exclusionsToToggle);
        const newExclusionsSet = new Set(newExclusions);
        flipAllAssetClassesWhereApplicable(newExclusionsSet, true);

        const customAllocations = draftPortfolio.constructionInfo.customAllocations || [];
        dpDispatch({
          type: 'SET_ASSET_CLASS_ALLOCATIONS',
          allocations: mapValues(assetAllocationCopy, (value, key) =>
            !value || isNaN(value) || newExclusionsSet.has(key as AssetClassKey) ? 0 : value / 100
          ),
          customAllocations: customAllocations.filter(
            (customAllocationKey) => !newExclusionsSet.has(customAllocationKey)
          ),
        });

        setLockedState(lockedClasses.filter((key) => !newExclusionsSet.has(key)));

        return Array.from(newExclusionsSet);
      });
    },
    [
      toggledAssetClasses,
      dpDispatch,
      assetAllocationCopy,
      localExclusions,
      lockedClasses,
      draftPortfolio.constructionInfo.customAllocations,
    ]
  );

  const isAllAllocationsValid = ASSET_CLASS_TREES.every((node, index) =>
    closeEquality(
      calculateTreeLeafsSum(node, assetAllocationCopy),
      Object.values(splitValues)[index]
    )
  );

  const isValidAllocation =
    closeEquality(assetAllocationCopy.FIXED_INCOME, splitValues.fixedIncomeAllocation) &&
    closeEquality(assetAllocationCopy.EQUITY, splitValues.equityAllocation) &&
    closeEquality(assetAllocationCopy.ALTERNATIVES, splitValues.alternatives) &&
    closeEquality(totalSplit, 100) &&
    isAllAllocationsValid;

  const handleLockClick = (assetClass: AssetClassKey) => {
    setLockedState((prev) => {
      if (lockedClasses.includes(assetClass)) {
        const index = lockedClasses.findIndex((e) => e === assetClass);
        if (index === -1) return prev;
        const updatedLockedClasses = [...prev];
        updatedLockedClasses.splice(index, 1);
        return updatedLockedClasses;
      }
      return [...prev, assetClass];
    });
  };
  const enqueueCoachmark = useEnqueueCoachmark();
  const account = draftPortfolio.constructionInfo.existingPortfolio;
  const { taxable: isTaxable } =
    !account || account === 'sample-portfolio' ? { taxable: true } : account;
  const onRebalance = async (split?: typeof splitValues) => {
    const allocations: {
      [key in Extract<AssetClassKey, 'EQUITY' | 'FIXED_INCOME' | 'ALTERNATIVES'>]?: number;
    } = {
      EQUITY: split?.equityAllocation ?? splitValues.equityAllocation / 100,
      FIXED_INCOME: split?.fixedIncomeAllocation ?? splitValues.fixedIncomeAllocation / 100,
      ALTERNATIVES: split?.alternatives ?? splitValues.alternatives / 100,
    };
    if (!split) {
      lockedClasses.forEach((key) => {
        allocations[key] = (assetAllocationCopy[key] || 0) / 100;
      });
    }

    try {
      const filteredAllocations = split
        ? {}
        : localExclusions.reduce((acc, current) => ({ ...acc, [current]: 0 }), {});
      // lock Municipal to 0 in case account is not taxable
      let municipalAllocation = {};
      if (!isTaxable) {
        municipalAllocation = { 'FIXED_INCOME/DOMESTIC/MUNICIPAL': 0 };
      }

      const rebalanceAllocationParam = {
        ...allocations,
        ...filteredAllocations,
        ...municipalAllocation,
      };
      const newAllocations = await getAssetAllocation({
        assetAllocation: rebalanceAllocationParam,
      });

      const filledInAssetAllocation = fillInAssetAllocation(newAllocations.data);
      // If we're providing a split we should get rid of previously selected exclusions
      const newExclusions = [
        ...Object.keys(filledInAssetAllocation).filter((key) => !filledInAssetAllocation[key]),
        ...(split ? [] : localExclusions),
      ];
      setLocalExclusions(newExclusions as AssetClassKey[]);
      dpDispatch({
        type: 'SET_ASSET_CLASS_ALLOCATIONS',
        allocations: filledInAssetAllocation,
        customAllocations: lockedClasses.filter((lockedKey) => !newExclusions.includes(lockedKey)),
      });
    } catch (err) {
      if (err instanceof AxiosError && err.response?.data?.message === 'UNACHIEVABLE_ALLOCATION') {
        enqueueCoachmark({
          content:
            'Could not achieve allocation with given constraints. Please adjust your portfolio split or locked allocations.',
          severity: 'error',
          title: 'Failed to optimize the portfolio',
        });
      } else {
        enqueueCoachmark({
          content: 'Please try again later',
          severity: 'error',
          title: 'Failed to optimize the portfolio',
        });
      }
    }
  };

  const handleSplitUpdate = async (key: keyof typeof splitValues, value: string) => {
    const parsedValue = parseFloat(value);
    const newSplit = isNaN(parsedValue)
      ? { ...splitValues, [key]: 0 }
      : { ...splitValues, [key]: parsedValue };
    const totalSplit =
      newSplit.equityAllocation + newSplit.fixedIncomeAllocation + newSplit.alternatives;
    if (closeEquality(totalSplit, 100)) {
      await onRebalance({
        equityAllocation: newSplit.equityAllocation / 100,
        fixedIncomeAllocation: newSplit.fixedIncomeAllocation / 100,
        alternatives: newSplit.alternatives / 100,
      });
    }
    setSplitValues(newSplit);
  };

  const onRebalanceHandler = () => {
    onRebalance();
  };

  const handleClearAll = () => {
    setSplitValues({ equityAllocation: 0, fixedIncomeAllocation: 0, alternatives: 0 });
    setLockedState([]);
    setLocalExclusions([]);
    setChooseAllocationModalOpen(false);
    dpDispatch({
      type: 'SET_ASSET_CLASS_ALLOCATIONS',
      allocations: mapValues(assetAllocation, () => 0),
      customAllocations: [],
    });
  };

  const handleChooseAllocations = (
    allocations: { [key in AssetClassKey]?: number },
    split: typeof splitValues
  ) => {
    setSplitValues(mapValues(split, (v) => v * 100));
    const filledInAssetAllocation = fillInAssetAllocation(allocations);
    dpDispatch({
      type: 'SET_ASSET_CLASS_ALLOCATIONS',
      allocations: filledInAssetAllocation,
      customAllocations: [],
      isCustomAllocation: false,
    });
    setLocalExclusions(
      Object.keys(filledInAssetAllocation).filter(
        (key) => !filledInAssetAllocation[key]
      ) as AssetClassKey[]
    );
    setLockedState([]);
  };

  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef.current) {
      const observer = new IntersectionObserver(
        (e: IntersectionObserverEntry[]) => {
          headerRef.current?.classList.toggle('sticky', e[0].intersectionRatio < 1);
        },
        { threshold: [1] }
      );
      observer.observe(headerRef.current);
    }
  });

  return (
    <ContentBox>
      <Box mb={2}>
        <Typography variant="h1">Customize the target allocation.</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h4">Portfolio split</Typography>
        <Box ml={2}>
          <HelpButton
            data-cy="need-help-button"
            color="primary"
            size="small"
            startIcon={<QuestionIcon />}
            onClick={() => setChooseAllocationModalOpen(true)}
          >
            Assist me
          </HelpButton>
        </Box>
      </Box>
      <ExplainerText mb={5} mt={1.5}>
        Based on your inputs below, Vise will provide an initial recommendation for your
        client&apos;s asset class allocation to optimize the portfolio.
      </ExplainerText>
      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <LocallyDelayedNumberFormat
              label="Equities"
              id="equity-split-input"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              min={0}
              defaultValue={0}
              customInput={TextField}
              value={splitValues.equityAllocation}
              onChange={(e) => handleSplitUpdate('equityAllocation', e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <LocallyDelayedNumberFormat
              id="fixed-split-input"
              label="Fixed Income"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              min={0}
              defaultValue={0}
              customInput={TextField}
              value={splitValues.fixedIncomeAllocation}
              onChange={(e) => handleSplitUpdate('fixedIncomeAllocation', e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <LocallyDelayedNumberFormat
              id="alternatives-split-input"
              label="Alternatives"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              min={0}
              defaultValue={0}
              customInput={TextField}
              value={splitValues.alternatives}
              onChange={(e) => handleSplitUpdate('alternatives', e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberFormat
              id="total-split-input"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              error={!closeEquality(totalSplit, 100)}
              customInput={TotalNumberFormatTextField}
              width={25}
              decimalScale={1}
              value={totalSplit}
              label="Total"
              disabled
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      {closeEquality(totalSplit, 100) && (
        <>
          <StickyAssetAllocationsHeader as="div" ref={headerRef}>
            <Typography variant="h3">Asset Allocation</Typography>
            <Box ml="auto" display="flex">
              <Button
                color="secondary"
                data-cy="clear-all-button"
                size="small"
                startIcon={<TrashIcon />}
                onClick={() => setClearAllModalOpen(true)}
              >
                Clear All
              </Button>
              <Box pl={1}>
                <Button
                  color="secondary"
                  size="small"
                  variant="outlined"
                  startIcon={<KnobsIcon width={16} height={16} />}
                  disabled={
                    !closeEquality(totalSplit, 100) ||
                    !!(splitValues.alternatives && localExclusions.includes('ALTERNATIVES')) ||
                    !!(
                      splitValues.fixedIncomeAllocation && localExclusions.includes('FIXED_INCOME')
                    ) ||
                    !!(splitValues.equityAllocation && localExclusions.includes('EQUITY'))
                  }
                  onClick={onRebalanceHandler}
                >
                  Recalculate
                </Button>
              </Box>
            </Box>
          </StickyAssetAllocationsHeader>
          <Box mb={3.5}>
            <DetailsText variant="body1" color="textSecondary">
              Modify the allocation based on your client&apos;s needs. Recalculating will treat
              custom values as inputs and adjust the remaining sub-asset classes pro-rata according
              to your portfolio split.
            </DetailsText>
          </Box>
        </>
      )}
      <form onSubmit={handleSubmit}>
        <Box
          hidden={!closeEquality(totalSplit, 100)}
          data-cy="custom-allocations-container"
          data-testid="custom-allocations-container"
        >
          {ASSET_CLASS_TREES.map((assetClass, index) => (
            <React.Fragment key={assetClass.feature}>
              <Box mb={3}>
                <Card variant="outlined">
                  <CardHeader className={pce2Styles.assetClassHeader}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      id={`${ASSET_CLASS_TO_LABEL_MAP.get(assetClass.feature)}-header`}
                    >
                      <Typography variant="h4">
                        {ASSET_CLASS_TO_LABEL_MAP.get(assetClass.feature)}
                      </Typography>
                      <Typography variant="h4">% of Vise managed value</Typography>
                    </Box>
                  </CardHeader>
                  <CardContent py={0}>
                    {assetAllocation?.[assetClass.feature] !== 0 ? (
                      <Box>
                        {assetClass.children?.map((assetClassBranch) => (
                          <SelectorCheckboxTree
                            key={ASSET_CLASS_TO_LABEL_MAP.get(assetClassBranch.feature)}
                          >
                            <AssetClassTree
                              curFeatureSet={[assetClass.feature]}
                              curAssetClass={assetClassBranch}
                              depth={0}
                              lockedAssetClasses={lockedClasses}
                              onLockClick={handleLockClick}
                              onClickCreator={createHandleOnToggleAssetClass}
                              onAllocationChange={handleCustomAllocationChange}
                              checkedSet={toggledAssetClasses}
                              isEtfExclusive={isEtfExclusive}
                              assetAllocation={assetAllocationCopy || {}}
                              disabledCheck={(key) => {
                                const account =
                                  draftPortfolio.constructionInfo.existingPortfolio !==
                                  'sample-portfolio'
                                    ? (draftPortfolio.constructionInfo.existingPortfolio as Account)
                                    : undefined;
                                return (
                                  !!account &&
                                  !account.taxable &&
                                  key === 'FIXED_INCOME/DOMESTIC/MUNICIPAL'
                                );
                              }}
                              exclusions={localExclusions}
                              customAllocations={
                                draftPortfolio.constructionInfo.customAllocations || []
                              }
                            />
                          </SelectorCheckboxTree>
                        ))}
                        <Box display="flex" justifyContent="space-between" py={1.5}>
                          <Typography variant="subtitle1">Total</Typography>
                          <Box width={125}>
                            <NumberFormat
                              id={`subtotal-${toLower(assetClass.feature)}`}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                              error={
                                !closeEquality(
                                  assetAllocationCopy[assetClass.feature],
                                  Object.values(splitValues)[index]
                                )
                              }
                              customInput={TotalNumberFormatTextField}
                              decimalScale={1}
                              value={assetAllocationCopy && assetAllocationCopy[assetClass.feature]}
                              disabled
                            />
                            {!closeEquality(
                              assetAllocationCopy[assetClass.feature],
                              Object.values(splitValues)[index]
                            ) && (
                              <Box display="flex" alignItems="center" mt={0.5}>
                                <Box mr={0.5} display="flex">
                                  <ExclamationIcon color="red" />
                                </Box>
                                <Typography style={{ color: 'red' }} variant="caption">
                                  Must equal{' '}
                                  {Math.round(Object.values(splitValues)[index] * 10) / 10}%
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box p={3} color="grey.400">
                        <Typography>
                          {`Adjust the portfolio split at the top of the page to add ${ASSET_CLASS_TO_LABEL_MAP.get(
                            assetClass.feature
                          )} to the portfolio.`}
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </React.Fragment>
          ))}
        </Box>
        <ActionFooter borderTop={0} justifyContent="space-between">
          <BackButton onClick={() => onBack()} />
          <Box display="flex">
            <Box mr={1.5}>
              <Button
                color="secondary"
                variant="outlined"
                disabled={!isValidAllocation || userData == null}
                data-testid="save-custom-allocation-template-button"
                onClick={() => {
                  amplitude().logEvent('Open save allocation template modal', {
                    category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                  });
                  return (
                    userData != null &&
                    setSaveTemplateModalSettings({
                      open: true,
                      allocations: mapValues(assetAllocationCopy, (value) =>
                        value ? value / 100 : 0
                      ),
                      userId: userData.id,
                      orgId: userData.organizationId,
                      isCustomAllocation: !!draftPortfolio.constructionInfo.isCustomAllocation,
                      customAllocations: draftPortfolio.constructionInfo.customAllocations || [],
                    })
                  );
                }}
              >
                Save as a template
              </Button>
            </Box>
            <ReturnToSummary
              draftPortfolio={draftPortfolio}
              disabled={!isValidAllocation}
              onReturnToSummary={onReturnToSummary}
              mr={1.5}
            />
            <Button disabled={!isValidAllocation} color="primary" type="submit" variant="contained">
              Continue
            </Button>
          </Box>
        </ActionFooter>
      </form>
      <SaveAllocationsTemplateModal
        dpDispatch={dpDispatch}
        open={saveTemplateModalSettings.open}
        onClose={() => {
          amplitude().logEvent('Close save allocation template modal', {
            category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
          });
          setSaveTemplateModalSettings(SAVE_TEMPLATE_MODAL_CLOSED_SETTINGS);
        }}
        allocations={saveTemplateModalSettings.allocations}
        userId={saveTemplateModalSettings.userId}
        orgId={saveTemplateModalSettings.orgId}
        isCustomAllocation={saveTemplateModalSettings.isCustomAllocation}
        customAllocations={saveTemplateModalSettings.customAllocations}
      />
      <ClearAllModal
        open={clearAllModalOpen}
        onCancel={() => setClearAllModalOpen(false)}
        onClearAll={handleClearAll}
      />
      <ChooseAllocationModal
        open={chooseAllocationModalOpen}
        onClose={() => setChooseAllocationModalOpen(false)}
        onLoadAllocation={handleChooseAllocations}
        taxable={isTaxable}
      />
    </ContentBox>
  );
}
