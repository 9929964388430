import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import AllocationChart from '~/components/portfolio/AllocationChart2';
import { processAssetClassAllocationData } from '~/routes/Portfolio/card/AllocationCard2';
import { useAssetToColorMap } from '~/hooks/useAssetToColorMap';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import { ASSET_CLASS_LEAVES } from '~/routes/PortfolioCreator2/Constants';
import { ReactComponent as InsightsIcon } from '~/static/images/icons/insights-bulb.svg';
import { pickBy } from 'lodash';

import Card from './Card';
import AssetAllocationCopy from './AssetAllocationCopy';

function riskFromAllocation(allocation: { [key in AssetClassKey]?: number }) {
  const equityAllocation = allocation.EQUITY || 0;
  const fixedIncomeAllocation = allocation.FIXED_INCOME || 0;

  return equityAllocation || fixedIncomeAllocation
    ? equityAllocation / (equityAllocation + fixedIncomeAllocation)
    : 0;
}

export default function AllocationTemplateChart({
  allocations,
}: {
  allocations: { [key in AssetClassKey]?: number };
}) {
  const leafAllocations = Object.entries(
    pickBy(allocations, (val, key) => ASSET_CLASS_LEAVES.has(key as AssetClassKey))
  ).map((val) => ({ name: val[0], y: val[1] || 0 }));

  const assetToColorMap = useAssetToColorMap();

  const processedAllocationsForCharts = processAssetClassAllocationData(
    leafAllocations,
    assetToColorMap
  );
  return (
    <Card fullWidth position="sticky" top={0}>
      <Box mx={3} display="flex" alignItems="center">
        <Box mr={1.5} position="relative" top="3px">
          <InsightsIcon width={24} height={24} />
        </Box>
        <Typography variant="h4">About this allocation</Typography>
      </Box>
      <Box mt={3}>
        <Divider />
      </Box>
      <Box mt={2}>
        <AllocationChart
          data={processedAllocationsForCharts[0]}
          groupedData={processedAllocationsForCharts[1]}
        />
        <Box mx={3}>
          <AssetAllocationCopy risk={riskFromAllocation(allocations)} />
        </Box>
      </Box>
    </Card>
  );
}
