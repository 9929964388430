import { SvgIcon, type SvgIconProps } from "@mui/material";
import React from "react";

export default function ChevronDownIcon(
  props: SvgIconProps,
): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.8337 7.5L10.0003 13.3333L4.16699 7.5"
          stroke="currentcolor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
