import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SomethingWentWrong from '~/components/SomethingWentWrong';
import useAccount from '~/hooks/useAccount';
import useDistributionSchedule from '~/hooks/useDistributionSchedule';
import usePortfolioIntelligence from '~/hooks/usePortfolioIntelligence';
import usePortfolioMetrics from '~/hooks/usePortfolioMetrics';
import Distributions from './Distributions';
import DistributionsLoading from './components/DistributionLoading';
import getColumns from './getColumns';

export default function AccountDistributions() {
  const { accountId } = useParams<{ accountId: string }>();

  const { data: accountData, error: accountError } = useAccount(accountId);
  const { data: intelligenceArrayData, error: intelligenceError } = usePortfolioIntelligence(
    accountData?.data?.portfolioIntelligenceId
  );

  const intelligence = intelligenceArrayData?.[0];

  const {
    data: distributionSchedule,
    error: distributionScheduleError,
    mutate: mutateDistributionSchedule,
  } = useDistributionSchedule(
    intelligence?.constructionInfo.clientId,
    accountData?.data?.custodianKey,
    accountData?.data?.accountNumber
  );

  const { data: pce2MetricsResponseData, error: pce2MetricsResponseError } =
    usePortfolioMetrics(accountId);

  useEffect(() => {
    if (accountError != null) {
      Sentry.captureException(new Error(`[Distributions] Failed to load account ${accountId}.`), {
        extra: {
          accountId,
        },
      });
    }
  }, [accountId, accountError]);

  useEffect(() => {
    if (intelligenceError != null) {
      Sentry.captureException(
        new Error(
          `[Distributions] Failed to load intelligence ${accountData?.data?.portfolioIntelligenceId}.`
        ),
        {
          extra: {
            accountId,
          },
        }
      );
    }
  }, [accountId, accountData?.data?.portfolioIntelligenceId, intelligenceError]);

  useEffect(() => {
    if (distributionScheduleError != null) {
      Sentry.captureException(
        new Error(`[Distributions] Failed to load distribution schedule for account ${accountId}.`),
        {
          extra: {
            clientId: intelligence?.constructionInfo.clientId,
            custodianKey: accountData?.data?.custodianKey,
            accountNumber: accountData?.data?.accountNumber,
          },
        }
      );
    }
  }, [
    accountData?.data?.accountNumber,
    accountData?.data?.custodianKey,
    accountId,
    intelligence?.constructionInfo.clientId,
    distributionScheduleError,
  ]);

  useEffect(() => {
    if (pce2MetricsResponseError != null) {
      Sentry.captureException(
        new Error(`[Distributions] Failed to load metrics for account ${accountId}.`),
        {
          extra: {
            accountId,
          },
        }
      );
    }
  }, [accountId, pce2MetricsResponseError]);

  if (accountError?.response?.status === 404) {
    return (
      <SomethingWentWrong
        title="We can't find the account you're looking for."
        description="The link you're using may be
        incorrect, or you may not have access to the account."
        disableCta
        error={new Error('Account not found')}
      />
    );
  }

  const loadingError =
    accountError || intelligenceError || distributionScheduleError || pce2MetricsResponseError;
  if (loadingError) {
    return (
      <SomethingWentWrong
        description="There was an error loading distributions for this account."
        error={loadingError}
      />
    );
  }

  if (
    accountData == null ||
    intelligence == null ||
    distributionSchedule == null ||
    pce2MetricsResponseData == null
  ) {
    return <DistributionsLoading isGlobalDistribution={false} />;
  }

  const cashToWithdraw = pce2MetricsResponseData.portfolioCashInformation?.cashToWithdraw;

  const { data: account } = accountData;

  return (
    <Distributions
      distributionSchedule={distributionSchedule}
      cashToWithdraw={cashToWithdraw}
      account={account}
      columns={getColumns()}
      mutateDistributionSchedule={mutateDistributionSchedule}
    />
  );
}
