import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Link,
  LinkProps,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { keyBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import {
  AllocationsTemplate,
  GetModelTemplateCenterViewDataResponse,
  RestrictionsTemplate,
} from 'vise-types/template';
import { updateLinkedAccounts } from '~/api/api';
import PageNavigation from '~/components/table/PageNavigation';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useDeleteAllocationsTemplate from '~/hooks/templates/useDeleteAllocationsTemplate';
import useDeleteRestrictionsTemplate from '~/hooks/templates/useDeleteRestrictionsTemplate';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useInProgressJobs from '~/hooks/useInProgressJobs';
import { ReactComponent as AllocationIcon } from '~/static/images/icons/allocation.svg';
import { ReactComponent as DotsHorizontalIcon } from '~/static/images/icons/dots-horizontal.svg';
import { ReactComponent as RestrictionIcon } from '~/static/images/icons/restrictions.svg';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import { SearchIcon } from '~/synth/Icons';
import { TextHighlightTag } from '~/synth/Tag';
import TextField from '~/synth/TextField';
import amplitude from '~/utils/amplitude';
import { DeleteTemplateModal } from '../PortfolioCreator2/templates/CommonComponents';
import EmptyState from './EmptyState';
import {
  getAllAccountIdsForFreshTemplateId,
  getStaleAccountsFromViewData,
  rightPad,
  tiltSelectionToLabel,
} from './utils';
import { EmptyCard } from './workflows/CommonComponents';

const PAGE_SIZE = 6;

function DescriptionRow({ dt, dd }: { dt: string; dd: string | number | React.ReactElement }) {
  return (
    <Box display="flex" alignItems="center" my={1}>
      <Box flex={1} component="dt">
        <Typography variant="body1">{dt}</Typography>
      </Box>
      <Box>
        <Typography variant="body1" color="textSecondary">
          {dd}
        </Typography>
      </Box>
    </Box>
  );
}

function UpdateTag({
  updatesAvailable,
  updateInProgress,
  noLinkedAccounts,
}: {
  updatesAvailable: number;
  updateInProgress: boolean;
  noLinkedAccounts: boolean;
}) {
  let severity: 'success' | 'warning' | 'alert' | 'info' = 'success';
  let text = 'Up to date';
  if (updateInProgress) {
    severity = 'alert';
    text = 'Update in progress';
  } else if (updatesAvailable > 0) {
    severity = 'warning';
    text = `${updatesAvailable} account${updatesAvailable === 1 ? '' : 's'} available to update`;
  } else if (noLinkedAccounts) {
    severity = 'info';
    text = 'N/A';
  }
  return <TextHighlightTag severity={severity}>{text}</TextHighlightTag>;
}

function useTemplateLandingPageEnabled() {
  return true;
}

function TemplateLandingPageLink(props: LinkProps<RouterLink> & RouterLinkProps) {
  return (
    <Link
      underline="none"
      component={RouterLink}
      borderRadius="4px"
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'block',
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'primary.main',
        },
        height: '100%',
      }}
      {...props}
    />
  );
}

function AllocationTemplateCard<JobMutateT, TemplateMutateT>({
  template,
  linkedAccounts,
  staleAccountIds,
  updateInProgress,
  onMutateJobs,
  mutateTemplate,
}: {
  template: AllocationsTemplate;
  linkedAccounts: number;
  staleAccountIds: string[];
  updateInProgress: boolean;
  onMutateJobs: () => Promise<JobMutateT>;
  mutateTemplate: () => Promise<TemplateMutateT>;
}) {
  const templateLandingPageEnabled = useTemplateLandingPageEnabled();
  const theme = useTheme();
  const history = useHistory();
  const enqueueCoachmark = useEnqueueCoachmark();
  const { deleteTemplate } = useDeleteAllocationsTemplate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  let cardBody = (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box mb={3} margin="auto">
          <Typography variant="h3">{template.name}</Typography>
          <Box margin="auto" />
          {!templateLandingPageEnabled && (
            <Box>
              <DropdownButtonMenu
                isIcon
                buttonContent={<DotsHorizontalIcon color={theme.palette.grey[800]} />}
                buttonProps={{ 'aria-label': 'Template menu' }}
              >
                {(close) => [
                  <DropdownButtonMenuItem
                    key="view"
                    onClick={() => {
                      history.push(`/secure/template/${template.type}/edit/${template.id}`);
                    }}
                    disabled={updateInProgress}
                  >
                    View/edit template details
                  </DropdownButtonMenuItem>,
                  <DropdownButtonMenuItem
                    key="link-unlink-accounts"
                    onClick={() => {
                      history.push(`/secure/template/link/${template.parentId}`);
                    }}
                    disabled={updateInProgress}
                  >
                    Link/unlink accounts
                  </DropdownButtonMenuItem>,
                  <DropdownButtonMenuItem
                    key="update-accounts"
                    onClick={() => {
                      updateLinkedAccounts(template.parentId, [], staleAccountIds, 'UPDATE')
                        .then(() => onMutateJobs())
                        .catch(() => {
                          enqueueCoachmark({
                            title: 'Failed to update accounts',
                            content: 'Please reach out to clientservice@vise.com for help.',
                            severity: 'error',
                          });
                        });
                      close();
                    }}
                    disabled={staleAccountIds.length === 0 || updateInProgress}
                  >
                    Update accounts to latest version
                  </DropdownButtonMenuItem>,
                  <DropdownButtonMenuItem
                    disabled={updateInProgress || linkedAccounts > 0 || staleAccountIds.length > 0}
                    key="delete"
                    onClick={() => {
                      close();
                      setDeleteModalOpen(true);
                    }}
                    sx={{ color: theme.palette.error.main }}
                  >
                    Delete template
                  </DropdownButtonMenuItem>,
                ]}
              </DropdownButtonMenu>
            </Box>
          )}
        </Box>
        <Box component="dl">
          <DescriptionRow
            dt="Built by"
            dd={`${template.user.firstName} ${template.user.lastName}`}
          />
          <DescriptionRow
            dt="Last edit"
            dd={format(new Date(template.updatedAt || template.createdAt), 'M/d/yyyy')}
          />
          <DescriptionRow dt="Linked accounts" dd={linkedAccounts} />
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <Box component="dl">
          <DescriptionRow
            dt="Equities"
            dd={`${((template.allocations.EQUITY || 0) * 100).toFixed(0)}%`}
          />
          <DescriptionRow
            dt="Fixed income"
            dd={`${((template.allocations.FIXED_INCOME || 0) * 100).toFixed(0)}%`}
          />
          <DescriptionRow
            dt="Alternatives"
            dd={`${((template.allocations.ALTERNATIVES || 0) * 100).toFixed(0)}%`}
          />
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <Box component="dl">
          <DescriptionRow dt="Tilt Type" dd={tiltSelectionToLabel(template.tiltSelection).type} />
          <DescriptionRow
            dt="Tilt Amount"
            dd={tiltSelectionToLabel(template.tiltSelection).value}
          />
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <DescriptionRow
          dt="Account status"
          dd={
            <UpdateTag
              updateInProgress={updateInProgress}
              updatesAvailable={staleAccountIds.length}
              noLinkedAccounts={linkedAccounts === 0}
            />
          }
        />
      </CardContent>
    </Card>
  );
  if (templateLandingPageEnabled) {
    cardBody = (
      <TemplateLandingPageLink
        to={`/secure/view-template/${template.parentId}`}
        onClick={() =>
          amplitude().logEvent('Click on allocation template card', {
            category: EVENT_CATEGORIES.STRATEGY_CENTER,
            templateId: template.parentId,
          })
        }
      >
        {cardBody}
      </TemplateLandingPageLink>
    );
  }
  return (
    <>
      <DeleteTemplateModal
        deleteTemplate={deleteTemplate}
        open={deleteModalOpen}
        templateId={template.id}
        templateName={template.name}
        onClose={() => setDeleteModalOpen(false)}
        onTemplateDeleted={async () => {
          await mutateTemplate();
          setDeleteModalOpen(false);
        }}
        variant="allocation"
        eventCategory={EVENT_CATEGORIES.STRATEGY_CENTER}
      />
      {cardBody}
    </>
  );
}

function RestrictionsTemplateCard<MutateJobT, MutateTemplateT>({
  template,
  linkedAccounts,
  staleAccountIds,
  updateInProgress,
  onMutateJobs,
  mutateTemplate,
}: {
  template: RestrictionsTemplate;
  linkedAccounts: number;
  staleAccountIds: string[];
  updateInProgress: boolean;
  onMutateJobs: () => Promise<MutateJobT>;
  mutateTemplate: () => Promise<MutateTemplateT>;
}) {
  const templateLandingPageEnabled = useTemplateLandingPageEnabled();
  const history = useHistory();
  const theme = useTheme();
  const enqueueCoachmark = useEnqueueCoachmark();
  const { deleteTemplate } = useDeleteRestrictionsTemplate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  let cardBody = (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box mb={3} display="flex" margin="auto" alignItems="center">
          <Box>
            <Typography variant="h3">{template.name}</Typography>
          </Box>
          <Box margin="auto" />
          {!templateLandingPageEnabled && (
            <Box>
              <DropdownButtonMenu
                isIcon
                buttonContent={<DotsHorizontalIcon color={theme.palette.grey[800]} />}
                buttonProps={{ 'aria-label': 'Template menu' }}
              >
                {(close) => [
                  <DropdownButtonMenuItem
                    key="view"
                    onClick={() => {
                      history.push(`/secure/template/${template.type}/edit/${template.id}`);
                    }}
                    disabled={updateInProgress}
                  >
                    View/edit template details
                  </DropdownButtonMenuItem>,
                  <DropdownButtonMenuItem
                    key="link-unlink-accounts"
                    onClick={() => {
                      history.push(`/secure/template/link/${template.parentId}`);
                    }}
                    disabled={updateInProgress}
                  >
                    Link/unlink accounts
                  </DropdownButtonMenuItem>,
                  <DropdownButtonMenuItem
                    key="update-accounts"
                    onClick={() => {
                      updateLinkedAccounts(template.parentId, [], staleAccountIds, 'UPDATE')
                        .then(() => onMutateJobs())
                        .catch(() => {
                          enqueueCoachmark({
                            title: 'Failed to update accounts',
                            content: 'Please reach out to clientservice@vise.com for help.',
                            severity: 'error',
                          });
                        });
                      close();
                    }}
                    disabled={staleAccountIds.length === 0 || updateInProgress}
                  >
                    Update accounts to the latest version
                  </DropdownButtonMenuItem>,
                  <DropdownButtonMenuItem
                    disabled={updateInProgress || linkedAccounts > 0 || staleAccountIds.length > 0}
                    key="delete"
                    onClick={() => {
                      close();
                      setDeleteModalOpen(true);
                    }}
                    sx={{ color: theme.palette.error.main }}
                  >
                    Delete template
                  </DropdownButtonMenuItem>,
                ]}
              </DropdownButtonMenu>
            </Box>
          )}
        </Box>
        <Box component="dl">
          <DescriptionRow
            dt="Built by"
            dd={`${template.user.firstName} ${template.user.lastName}`}
          />
          <DescriptionRow
            dt="Last edit"
            dd={format(new Date(template.updatedAt || template.createdAt), 'M/d/yyyy')}
          />
          <DescriptionRow dt="Linked accounts" dd={linkedAccounts} />
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <Box component="dl">
          <DescriptionRow dt="Tickers" dd={`${template.tickers.length || 0}`} />
          <DescriptionRow dt="Countries" dd={`${template.countries.length || 0}`} />
          <DescriptionRow dt="Sectors" dd={`${template.sectors.length || 0}`} />
          <DescriptionRow dt="Sub-sectors" dd={`${template.subSectors.length || 0}`} />
          <DescriptionRow dt="Values" dd={`${template.esgAreas.length || 0}`} />
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <DescriptionRow
          dt="Account status"
          dd={
            <UpdateTag
              updateInProgress={updateInProgress}
              updatesAvailable={staleAccountIds.length}
              noLinkedAccounts={linkedAccounts === 0}
            />
          }
        />
      </CardContent>
    </Card>
  );
  if (templateLandingPageEnabled) {
    cardBody = (
      <TemplateLandingPageLink
        to={`/secure/view-template/${template.parentId}`}
        onClick={() =>
          amplitude().logEvent('Click on restrictions template card', {
            category: EVENT_CATEGORIES.STRATEGY_CENTER,
            templateId: template.parentId,
          })
        }
      >
        {cardBody}
      </TemplateLandingPageLink>
    );
  }
  return (
    <>
      <DeleteTemplateModal
        deleteTemplate={deleteTemplate}
        open={deleteModalOpen}
        templateId={template.id}
        templateName={template.name}
        onClose={() => setDeleteModalOpen(false)}
        onTemplateDeleted={async () => {
          await mutateTemplate();
          setDeleteModalOpen(false);
        }}
        variant="restrictions"
        eventCategory={EVENT_CATEGORIES.STRATEGY_CENTER}
      />
      {cardBody}
    </>
  );
}

function AllocationTabLabel() {
  return (
    <Box display="flex" alignItems="center" pb={2}>
      <Box mr={1} pt={0.6}>
        <AllocationIcon />
      </Box>
      <Box>
        <Typography variant="h4">Allocation</Typography>
      </Box>
    </Box>
  );
}

function RestrictionsTabLabel() {
  return (
    <Box display="flex" alignItems="center" pb={2}>
      <Box mr={1} pt={0.6}>
        <RestrictionIcon />
      </Box>
      <Box>
        <Typography variant="h4">Restrictions</Typography>
      </Box>
    </Box>
  );
}

export default function TemplateBrowser<MutateTemplateT>({
  data,
  mutateTemplate,
}: {
  data: GetModelTemplateCenterViewDataResponse;
  mutateTemplate: () => Promise<MutateTemplateT>;
}) {
  const { search } = useLocation();
  const templateType = new URLSearchParams(search).get('templateType');
  const [templatesTabState, setTemplatesTabState] = useState<'ALLOCATION' | 'RESTRICTION'>(
    templateType === 'restrictions' ? 'RESTRICTION' : 'ALLOCATION'
  );
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [allocationTemplatePage, setAllocationTemplatePage] = useState<number>(0);
  const [restrictionTemplatePage, setRestrictionTemplatePage] = useState<number>(0);
  const { data: jobs, mutate } = useInProgressJobs();
  const onMutateJobs = () => mutate();
  const staleAccountIdsByFreshTemplateId = useMemo(
    () => getStaleAccountsFromViewData(data),
    [data]
  );
  const allAccountIdsByFreshTemplateId = useMemo(
    () => getAllAccountIdsForFreshTemplateId(data),
    [data]
  );
  const jobsByParentTemplateId = useMemo(
    () => keyBy(jobs?.data || [], (j) => j.templateId),
    [jobs?.data]
  );
  // when switching tabs, we want an empty search bar
  useEffect(() => {
    setFilterQuery('');
  }, [templatesTabState]);

  const compareTemplateSort = useCallback(
    (
      t1: AllocationsTemplate | RestrictionsTemplate,
      t2: AllocationsTemplate | RestrictionsTemplate
    ) => {
      // Linking job or update in progress
      if (jobsByParentTemplateId[t1.parentId] && !jobsByParentTemplateId[t2.parentId]) {
        return -1;
      }
      if (!jobsByParentTemplateId[t1.parentId] && jobsByParentTemplateId[t2.parentId]) {
        return 1;
      }
      // Pending updates for template 1 but not template 2
      if (
        staleAccountIdsByFreshTemplateId[t1.parentId] &&
        !staleAccountIdsByFreshTemplateId[t2.parentId]
      ) {
        return -1;
      }
      // The reverse
      if (
        !staleAccountIdsByFreshTemplateId[t1.parentId] &&
        staleAccountIdsByFreshTemplateId[t2.parentId]
      ) {
        return 1;
      }

      // If template 1 has linked accounts and template 2 doesn't
      if (
        allAccountIdsByFreshTemplateId[t1.parentId] &&
        !allAccountIdsByFreshTemplateId[t2.parentId]
      ) {
        return -1;
      }
      // The reverse
      if (
        !allAccountIdsByFreshTemplateId[t1.parentId] &&
        allAccountIdsByFreshTemplateId[t2.parentId]
      ) {
        return 1;
      }

      // Finally sort by created date, descending
      return t2.createdAt.localeCompare(t1.createdAt);
    },
    [jobsByParentTemplateId, staleAccountIdsByFreshTemplateId, allAccountIdsByFreshTemplateId]
  );

  const filteredRestrictionTemplates = useMemo(() => {
    if (templatesTabState !== 'RESTRICTION') {
      return [];
    }
    return matchSorter(data.restrictionsTemplates, filterQuery || '', {
      keys: ['name'],
      threshold: matchSorter.rankings.CONTAINS,
    })
      .filter((template) => !template.stale)
      .sort(compareTemplateSort);
  }, [data.restrictionsTemplates, filterQuery, templatesTabState, compareTemplateSort]);

  const filteredAllocationTemplates = useMemo(() => {
    if (templatesTabState !== 'ALLOCATION') {
      return [];
    }
    return matchSorter(data.allocationsTemplates, filterQuery || '', {
      keys: ['name'],
      threshold: matchSorter.rankings.CONTAINS,
    })
      .filter((template) => !template.stale)
      .sort(compareTemplateSort);
  }, [data.allocationsTemplates, filterQuery, templatesTabState, compareTemplateSort]);

  if (!jobs) {
    return null;
  }

  return (
    <Container>
      <Card>
        <Box display="flex" mx={3} alignItems="flex-end">
          <Box>
            <Tabs
              value={templatesTabState}
              onChange={(e, tabState) => setTemplatesTabState(tabState)}
            >
              <Tab label={<AllocationTabLabel />} value="ALLOCATION" />
              <Tab label={<RestrictionsTabLabel />} value="RESTRICTION" />
            </Tabs>
          </Box>
          <Box margin="auto" />
          <Box width="360px" my={2}>
            <TextField
              fullWidth
              value={filterQuery}
              onChange={(e) => setFilterQuery(e.target.value)}
              placeholder="Search by name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Divider />
        {templatesTabState === 'ALLOCATION' && filteredAllocationTemplates.length > 0 && (
          <>
            <Box m={3}>
              <Grid container spacing={3}>
                {rightPad(
                  filteredAllocationTemplates.slice(
                    allocationTemplatePage * PAGE_SIZE,
                    allocationTemplatePage * PAGE_SIZE + PAGE_SIZE
                  ),
                  PAGE_SIZE
                ).map((template, ind) => {
                  let content;
                  if (template == null) {
                    content = <EmptyCard />;
                  } else {
                    content = (
                      <AllocationTemplateCard
                        linkedAccounts={
                          allAccountIdsByFreshTemplateId[template.parentId]?.length || 0
                        }
                        template={template}
                        key={template.id}
                        staleAccountIds={staleAccountIdsByFreshTemplateId[template.parentId] || []}
                        updateInProgress={!!jobsByParentTemplateId[template.parentId]}
                        onMutateJobs={onMutateJobs}
                        mutateTemplate={mutateTemplate}
                      />
                    );
                  }
                  return (
                    // Prepended with other context
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid item xs={6} md={4} key={`${template?.id ?? 'empty'}-${ind}`}>
                      {content}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            {filteredAllocationTemplates.length > PAGE_SIZE && (
              <>
                <Divider />
                <Box display="flex" my={4}>
                  <Box flex={1} />
                  <Box mr={3}>
                    <PageNavigation
                      currentPage={allocationTemplatePage + 1}
                      numItems={filteredAllocationTemplates.length}
                      pageSize={PAGE_SIZE}
                      onNextPageClick={() => {
                        setAllocationTemplatePage(allocationTemplatePage + 1);
                      }}
                      onPrevPageClick={() => {
                        setAllocationTemplatePage(allocationTemplatePage - 1);
                      }}
                      onPageNumberClick={(pageNumber) => {
                        setAllocationTemplatePage(pageNumber - 1);
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
        {templatesTabState === 'RESTRICTION' && filteredRestrictionTemplates.length > 0 && (
          <>
            <Box m={3}>
              <Grid container spacing={3}>
                {rightPad(
                  filteredRestrictionTemplates.slice(
                    restrictionTemplatePage * PAGE_SIZE,
                    restrictionTemplatePage * PAGE_SIZE + PAGE_SIZE
                  ),
                  PAGE_SIZE
                ).map((template, ind) => {
                  let content;
                  if (template == null) {
                    content = <EmptyCard />;
                  } else {
                    content = (
                      <RestrictionsTemplateCard
                        linkedAccounts={
                          allAccountIdsByFreshTemplateId[template.parentId]?.length || 0
                        }
                        template={template}
                        key={template.id}
                        staleAccountIds={staleAccountIdsByFreshTemplateId[template.parentId] || []}
                        updateInProgress={!!jobsByParentTemplateId[template.parentId]}
                        onMutateJobs={onMutateJobs}
                        mutateTemplate={mutateTemplate}
                      />
                    );
                  }
                  return (
                    // Prepended with other context
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid item xs={6} md={4} key={`${template?.id ?? 'empty'}-${ind}`}>
                      {content}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            {filteredRestrictionTemplates.length > PAGE_SIZE && (
              <>
                <Divider />
                <Box display="flex" my={4}>
                  <Box flex={1} />
                  <Box mr={3}>
                    <PageNavigation
                      currentPage={restrictionTemplatePage + 1}
                      numItems={filteredRestrictionTemplates.length}
                      pageSize={PAGE_SIZE}
                      onNextPageClick={() => {
                        setRestrictionTemplatePage(restrictionTemplatePage + 1);
                      }}
                      onPrevPageClick={() => {
                        setRestrictionTemplatePage(restrictionTemplatePage - 1);
                      }}
                      onPageNumberClick={(pageNumber) => {
                        setRestrictionTemplatePage(pageNumber - 1);
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
        {templatesTabState === 'ALLOCATION' && data.allocationsTemplates.length === 0 && (
          <EmptyState
            title="No allocation templates"
            body="There are currently no allocation templates, click below to start building."
            height={400}
          />
        )}
        {templatesTabState === 'RESTRICTION' && data.restrictionsTemplates.length === 0 && (
          <EmptyState
            title="No restriction templates"
            body="There are currently no restriction templates, click below to start building."
            height={400}
          />
        )}
      </Card>
    </Container>
  );
}

export { AllocationTemplateCard };
