import useSWR from 'swr';
import { getPortfolioInsights } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getPortfolioInsightsSwrKey = (id: string) => generateSwrKey(`portfolioInsights/${id}`);

export default function usePortfolioInsights(proposalId: string | null | undefined) {
  return useSWR(
    proposalId ? getPortfolioInsightsSwrKey(proposalId) : null,
    proposalId == null ? undefined : () => getPortfolioInsights({ proposalId }),
    {
      onErrorRetry: (err, key, config, revalidate, { retryCount }) => {
        // Don't refetch if the insight isn't found, it's not generated for this portfolio
        if (err && err.response.status === 404) {
          return;
        }
        // Retry up to 5 times
        if (retryCount && retryCount >= 5) {
          return;
        }
        // Exponentially backoff
        setTimeout(() => revalidate({ retryCount }), retryCount ? 2 ** retryCount * 1000 : 1000);
      },
    }
  );
}
