import { cloneDeep, isEmpty, isEqual, keyBy } from 'lodash';
import { Box, BoxProps } from '@mui/material';
import { Feature } from 'vise-types/pce2_instrument';
import React from 'react';
import { diff } from 'deep-diff';
import { FeatureFlagResponse } from '~/models/featureFlag';
import { AssetClassTreeNode, DraftPortfolio } from '../../Types';
import { ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP, ASSET_CLASS_TREES } from '../../Constants';
import { getAllAssetClassTreeSections } from '../../utils';
import { sumKeys } from '../components/SummarySections';

export type PC_CATEGORY =
  | 'LOCKED_POSITIONS'
  | 'TAX_MANAGEMENT'
  | 'RESTRICTIONS_TEMPLATE'
  | 'RESTRICTED_EQUITIES'
  | 'RESTRICTED_SECTORS'
  | 'RESTRICTED_COUNTRIES'
  | 'RESTRICTED_ESG_AREAS'
  | 'CASH_ALLOCATION'
  | 'PORTFOLIO_FOCUS'
  | 'EQUITIES'
  | 'FIXED_INCOME'
  | 'ALTERNATIVES'
  | 'ACTIVE_TILT'
  | 'SAVED_STRATEGY'
  | 'ACCOUNT_SIZE'
  | 'AC_CONCENTRATION_LIMITS'
  // Allocation fields
  | 'ALLOCATION_TEMPLATE'
  | 'CONSTRAINTS'
  | 'PORTFOLIO_SPLIT'
  | 'EQUITY_ALLOCATION'
  | 'FIXED_INCOME_ALLOCATION'
  | 'ALTERNATIVES_ALLOCATION'
  | 'INVESTMENT_VEHICLES';

export const CATEGORY_TO_COPY = {
  PORTFOLIO_FOCUS: 'Portfolio focus',
  INVESTMENT_VEHICLES: 'Investment vehicles',
  EQUITIES: 'Asset classes',
  FIXED_INCOME: 'Asset classes',
  ALTERNATIVES: 'Asset classes',
  AC_CONCENTRATION_LIMITS: 'Asset concentration limits',
  RESTRICTIONS_TEMPLATE: 'Restrictions template',
  RESTRICTED_EQUITIES: 'Restrictions',
  RESTRICTED_SECTORS: 'Restrictions',
  RESTRICTED_COUNTRIES: 'Restrictions',
  ACTIVE_TILT: 'Active tilts',
  SAVED_STRATEGY: 'Strategy',
  ACCOUNT_SIZE: 'Account size',
  CONSTRAINTS: 'Constraints',
  ALLOCATION_TEMPLATE: 'Allocation template',
  PORTFOLIO_SPLIT: 'Portfolio split',
  EQUITY_ALLOCATION: 'Equities',
  FIXED_INCOME_ALLOCATION: 'Fixed income',
  ALTERNATIVES_ALLOCATION: 'Alternatives',
} as { [key in PC_CATEGORY]: string };

const CATEGORY_TO_FIELDS = {
  LOCKED_POSITIONS: new Set(['lockedPositions']),
  TAX_MANAGEMENT: new Set(['autoTlh', 'capitalGainsLimits']),
  RESTRICTIONS_TEMPLATE: new Set(['restrictionsTemplatesIds']),
  RESTRICTED_EQUITIES: new Set(['restrictedStocks']),
  RESTRICTED_SECTORS: new Set(['excludedIndustries', 'excludedSectors']),
  RESTRICTED_COUNTRIES: new Set(['excludedCountries']),
  RESTRICTED_ESG_AREAS: new Set(['excludedEsgAreas']),
  CASH_ALLOCATION: new Set(['concentrationLimits']),
  PORTFOLIO_FOCUS: new Set(['focus', 'risk', 'useGlidePath', 'targetValue', 'investmentTimeline']),
  ACTIVE_TILT: new Set(['activeTilt']),
  SAVED_STRATEGY: new Set([
    'newSavedStrategyName',
    'savedStrategy',
    'strategyId',
    'strategyInternalUuid',
    'overwriteExistingStrategy',
    'isBootstrappedStrategyOutdated',
    'bootstrappedStrategy',
  ]),
  ACCOUNT_SIZE: new Set(['accountSize']),
  ALLOCATION_TEMPLATE: new Set(['allocationsTemplateId', 'useAllocationTemplate']),
} as { [key in PC_CATEGORY]: Set<string> };

const isEmptyOrNull = (value): boolean => {
  if (value == null) return true;
  if (typeof value === 'object') {
    return isEmpty(value);
  }

  return false;
};

function hasDifferenceInAssetClass(
  primaryAssetClassTreeRoot: AssetClassTreeNode,
  compareDraftPortfolio: DraftPortfolio,
  nextDraftPortfolio: DraftPortfolio
): boolean {
  const compareExclusions =
    compareDraftPortfolio.constructionInfo.assetClassConcentrationLimits?.exclusions ?? [];
  const nextExclusions =
    nextDraftPortfolio.constructionInfo.assetClassConcentrationLimits?.exclusions ?? [];

  const compareAssetClassTreeNodes = getAllAssetClassTreeSections(
    primaryAssetClassTreeRoot,
    compareExclusions,
    false
  );
  const nextAssetClassTreeNodes = getAllAssetClassTreeSections(
    primaryAssetClassTreeRoot,
    nextExclusions,
    false
  );

  return !isEqual(compareAssetClassTreeNodes, nextAssetClassTreeNodes);
}

// 'exclusions' is a field on AC concentration limits, but is unrelated
function hasDifferenceInACConcentrationLimits(
  compareDraftPortfolio: DraftPortfolio,
  nextDraftPortfolio: DraftPortfolio
): boolean {
  const compareLimits = cloneDeep(
    compareDraftPortfolio.constructionInfo.assetClassConcentrationLimits
  );
  const nextLimits = cloneDeep(nextDraftPortfolio.constructionInfo.assetClassConcentrationLimits);
  if (compareLimits != null) {
    compareLimits.exclusions = [];
  }
  if (nextLimits != null) {
    nextLimits.exclusions = [];
  }

  return !isEqual(compareLimits, nextLimits);
}

function hasDifferenceInInvestmentVehicles(
  compareDraftPortfolio: DraftPortfolio,
  nextDraftPortfolio: DraftPortfolio
): boolean {
  const compareETFExclusive = compareDraftPortfolio.constructionInfo.etfExclusive;
  const compareIsSmall =
    compareDraftPortfolio.accountSize === 'TINY' || compareDraftPortfolio.accountSize === 'SMALL';

  const nextETFExclusive = nextDraftPortfolio.constructionInfo.etfExclusive;
  const nextIsSmall =
    nextDraftPortfolio.accountSize === 'TINY' || nextDraftPortfolio.accountSize === 'SMALL';

  let etfAssetClassesAreSame = true;
  if (
    'etfExclusiveAssetClasses' in compareDraftPortfolio.constructionInfo &&
    'etfExclusiveAssetClasses' in nextDraftPortfolio.constructionInfo
  ) {
    etfAssetClassesAreSame = isEqual(
      keyBy(compareDraftPortfolio.constructionInfo.etfExclusiveAssetClasses, (id) => id),
      keyBy(nextDraftPortfolio.constructionInfo.etfExclusiveAssetClasses, (id) => id)
    );
  }

  const minSymbolAssetClassesAreSame = isEqual(
    keyBy(compareDraftPortfolio.constructionInfo.minSymbolAssetClasses || [], (id) => id),
    keyBy(nextDraftPortfolio.constructionInfo.minSymbolAssetClasses || [], (id) => id)
  );

  return (
    (compareETFExclusive || compareIsSmall) !== (nextETFExclusive || nextIsSmall) ||
    !etfAssetClassesAreSame ||
    !minSymbolAssetClassesAreSame
  );
}

function hasChangedConstraints(
  compareDraftPortfolio: DraftPortfolio,
  nextDraftPortfolio: DraftPortfolio
) {
  const glidePath1 =
    !!compareDraftPortfolio.constructionInfo.useGlidePath &&
    compareDraftPortfolio.constructionInfo.investmentTimeline;
  const glidePath2 =
    !!nextDraftPortfolio.constructionInfo.useGlidePath &&
    nextDraftPortfolio.constructionInfo.investmentTimeline;

  if (glidePath1 !== glidePath2) {
    return true;
  }

  // Glide path is enabled for both portfolios, don't check limits
  if (glidePath1 && glidePath2) {
    return false;
  }

  return hasDifferenceInACConcentrationLimits(compareDraftPortfolio, nextDraftPortfolio);
}

function floatArrayHasChanged(array1: number[], array2: number[]) {
  if (array1.length !== array2.length) {
    return true;
  }
  return array1.reduce(
    (hasChanged, num, idx) => hasChanged || Math.abs(num - array2[idx]) >= 0.0001,
    false
  );
}

function hasChangedAllocation(
  keyPrefix: Feature,
  compareDraftPortfolio: DraftPortfolio,
  nextDraftPortfolio: DraftPortfolio
) {
  const compareAlloc = compareDraftPortfolio.constructionInfo.assetAllocation || {};
  const nextAlloc = nextDraftPortfolio.constructionInfo.assetAllocation || {};
  const compareKeys = Object.keys(compareAlloc)
    .filter((k) => k.startsWith(`${keyPrefix}/`))
    .sort();
  const nextKeys = Object.keys(nextAlloc)
    .filter((k) => k.startsWith(`${keyPrefix}/`))
    .sort();

  if (!isEqual(compareKeys, nextKeys)) {
    return true;
  }

  return floatArrayHasChanged(
    compareKeys.map((k) => compareAlloc[k]),
    nextKeys.map((k) => nextAlloc[k])
  );
}

function hasChangedPortfolioSplit(
  compareDraftPortfolio: DraftPortfolio,
  nextDraftPortfolio: DraftPortfolio
) {
  const getPortfolioSplit = (draftPortfolio: DraftPortfolio) => {
    const assetAllocation = draftPortfolio.constructionInfo.assetAllocation || {};
    const equitiesKeys = ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get('EQUITY') || [];
    const fixedIncomeKeys = ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get('FIXED_INCOME') || [];
    const alternativesKeys = ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get('ALTERNATIVES') || [];
    return [
      sumKeys(equitiesKeys, assetAllocation),
      sumKeys(fixedIncomeKeys, assetAllocation),
      sumKeys(alternativesKeys, assetAllocation),
    ];
  };
  return floatArrayHasChanged(
    getPortfolioSplit(compareDraftPortfolio),
    getPortfolioSplit(nextDraftPortfolio)
  );
}

export function updatedCategoriesForDraftPortfolio(
  draftPortfolio: DraftPortfolio,
  compareOrignal: boolean
): Set<PC_CATEGORY> {
  const updatedCategories: Set<PC_CATEGORY> = new Set();

  const nextDraftPortfolio = draftPortfolio;

  const { originalDraftPortfolio, previousDraftPortfolio } = draftPortfolio;
  const compareDraftPortfolio = compareOrignal ? originalDraftPortfolio : previousDraftPortfolio;

  if (compareDraftPortfolio != null) {
    const differences = diff(compareDraftPortfolio, nextDraftPortfolio);

    const modifiedFields =
      differences != null
        ? differences
            // "A" means that an array has a element appended so always include
            // For other types, check that either the compare or next value is not null
            .filter(
              (diff) => diff.kind === 'A' || !isEmptyOrNull(diff.lhs) || !isEmptyOrNull(diff.rhs)
            )
            .map((diff) => diff.path)
            .flat(2)
        : [];

    Object.keys(CATEGORY_TO_FIELDS).forEach((category) => {
      const fields = CATEGORY_TO_FIELDS[category];
      if (Array.from(modifiedFields).some((modifiedField) => fields.has(modifiedField))) {
        updatedCategories.add(category as PC_CATEGORY);
      }
    });

    if (hasDifferenceInInvestmentVehicles(compareDraftPortfolio, nextDraftPortfolio)) {
      updatedCategories.add('INVESTMENT_VEHICLES');
    }
    if (
      hasDifferenceInAssetClass(ASSET_CLASS_TREES[0], compareDraftPortfolio, nextDraftPortfolio)
    ) {
      updatedCategories.add('EQUITIES');
    }
    if (
      hasDifferenceInAssetClass(ASSET_CLASS_TREES[1], compareDraftPortfolio, nextDraftPortfolio)
    ) {
      updatedCategories.add('FIXED_INCOME');
    }
    if (
      hasDifferenceInAssetClass(ASSET_CLASS_TREES[2], compareDraftPortfolio, nextDraftPortfolio)
    ) {
      updatedCategories.add('ALTERNATIVES');
    }
    if (hasDifferenceInACConcentrationLimits(compareDraftPortfolio, nextDraftPortfolio)) {
      updatedCategories.add('AC_CONCENTRATION_LIMITS');
    }
    if (hasChangedConstraints(compareDraftPortfolio, nextDraftPortfolio)) {
      updatedCategories.add('CONSTRAINTS');
    }
    if (hasChangedPortfolioSplit(compareDraftPortfolio, nextDraftPortfolio)) {
      updatedCategories.add('PORTFOLIO_SPLIT');
    }
    if (hasChangedAllocation('EQUITY', compareDraftPortfolio, nextDraftPortfolio)) {
      updatedCategories.add('EQUITY_ALLOCATION');
    }
    if (hasChangedAllocation('FIXED_INCOME', compareDraftPortfolio, nextDraftPortfolio)) {
      updatedCategories.add('FIXED_INCOME_ALLOCATION');
    }
    if (hasChangedAllocation('ALTERNATIVES', compareDraftPortfolio, nextDraftPortfolio)) {
      updatedCategories.add('ALTERNATIVES_ALLOCATION');
    }
  }

  return updatedCategories;
}

export function CircleIcon({ children, ...boxProps }: { children: React.ReactNode } & BoxProps) {
  return (
    <Box
      color="blue.400"
      bgcolor="blue.200"
      borderRadius="50%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...boxProps}
    >
      {children}
    </Box>
  );
}

export function cantSkipCategories({
  draftPortfolio,
  treatments,
}: {
  draftPortfolio: DraftPortfolio;
  treatments: FeatureFlagResponse;
}) {
  const updatedCategories =
    treatments.enable_return_to_summary === 'on'
      ? updatedCategoriesForDraftPortfolio(draftPortfolio, false)
      : new Set<PC_CATEGORY>();

  return Array.from(updatedCategories).filter(
    (category) =>
      category !== 'LOCKED_POSITIONS' &&
      category !== 'TAX_MANAGEMENT' &&
      category !== 'CASH_ALLOCATION'
  );
}
