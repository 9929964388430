import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RestrictionsTemplate } from 'vise-types/template';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAllRestrictionsTemplates from '~/hooks/templates/useAllRestrictionsTemplates';
import useDeleteRestrictionsTemplate from '~/hooks/templates/useDeleteRestrictionsTemplate';
import useRenameRestrictionsTemplate from '~/hooks/templates/useRenameRestrictionsTemplate';
import { useCoachmarkEffect } from '~/hooks/useCoachmark';
import { RootState } from '~/reducers';
import amplitude from '~/utils/amplitude';
import {
  BalancedPaddedContainer,
  DeleteTemplateModal,
  PaddedContainer,
  RenameTemplateModal,
  TemplateCard,
  TemplateSkeletons,
} from '../../templates/CommonComponents';
import TemplatesSearchAndFilterBar from '../../templates/TemplatesSearchAndFilterBar';
import { ScreenProps } from '../../Types';
import { shouldShowExcludeCountries } from '../../utils';
import { BackButton, ContentBox, ExplainerText, StickyBottomActionContainer } from '../components';
import {
  ExcludedCountries,
  ExcludedEsgAreas,
  ExcludedSectors,
  RestrictedStocks,
} from '../components/SummarySections';
import useRestrictionsTemplateValidation from './useRestrictionsTemplateValidation';

const DELETE_TEMPLATE_MODAL_CLOSED = { open: false, templateId: '', templateName: '' };
const RENAME_TEMPLATE_MODAL_CLOSED = { open: false, templateId: '' };

export default function SelectRestrictionsTemplatesScreen({
  draftPortfolio,
  onBack,
  dpDispatch,
  onContinue,
}: ScreenProps) {
  useEffect(() => {
    amplitude().logEvent('Impression - Select restrictions templates', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);

  const {
    restrictionsTemplatesIds,
    constructionInfo: { assetClassConcentrationLimits },
  } = draftPortfolio;

  const {
    data: allRestrictionsTemplates,
    error: restrictionsTemplatesError,
    mutate: mutateRestrictionsTemplates,
  } = useAllRestrictionsTemplates();
  const currentUserId = useSelector((state: RootState) => state.users.currentUser.id);
  const { deleteTemplate } = useDeleteRestrictionsTemplate();
  const { renameTemplate } = useRenameRestrictionsTemplate();
  const { isNameValid, isTemplateNameTaken } = useRestrictionsTemplateValidation();

  useCoachmarkEffect({
    show: restrictionsTemplatesError,
    title:
      'Sorry, there was an error loading your restritions templates. Please re-load this page.',
    severity: 'error',
  });

  const [filteredTemplates, setFilteredTemplates] = useState<RestrictionsTemplate[] | undefined>(
    undefined
  );

  const [deleteTemplateModalSettings, setDeleteTemplateModalSettings] = useState<{
    open: boolean;
    templateId: string;
    templateName: string;
  }>(DELETE_TEMPLATE_MODAL_CLOSED);

  const [renameTempalateModalSettings, setRenameTemplateModalSettings] = useState<{
    open: boolean;
    templateId: string;
  }>(RENAME_TEMPLATE_MODAL_CLOSED);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    onContinue('default');
  }

  const allowCountryRestrictions = shouldShowExcludeCountries(
    assetClassConcentrationLimits?.exclusions
  );

  function handleSelectTemplateClick(template: RestrictionsTemplate, selected: boolean) {
    if (selected) {
      dpDispatch({ type: 'REMOVE_RESTRICTIONS_TEMPLATE', templateId: template.id });
      amplitude().logEvent('Deselect restrictions template from restrictions template page', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        templateId: template.id,
      });
    } else {
      dpDispatch({ type: 'ADD_RESTRICTIONS_TEMPLATE', restrictions: template });
      amplitude().logEvent('Select restrictions template', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        templateId: template.id,
      });
    }
  }

  function handleTemplateDeleted(templateId: string) {
    if (restrictionsTemplatesIds?.find((id) => id === templateId) != null) {
      dpDispatch({ type: 'REMOVE_RESTRICTIONS_TEMPLATE', templateId });
    }
    setFilteredTemplates((prev) => prev?.filter((template) => template.id !== templateId));
    mutateRestrictionsTemplates();
  }

  return (
    <>
      <Box position="sticky" top="0" zIndex={10} bgcolor="white">
        <ContentBox maxWidth={750}>
          <PaddedContainer>
            <Box mb={2}>
              <Typography variant="h1">Select one or more restrictions templates.</Typography>
            </Box>
            <ExplainerText>
              Tickers, sectors, and countries contained in the template(s) selected below will be
              excluded from this portfolio.
            </ExplainerText>
          </PaddedContainer>
        </ContentBox>
        <TemplatesSearchAndFilterBar<RestrictionsTemplate>
          allTemplates={allRestrictionsTemplates ?? []}
          currentUserId={currentUserId}
          filteredTemplates={filteredTemplates ?? []}
          onFilteredTemplatesUpdated={setFilteredTemplates}
        />
      </Box>
      <form onSubmit={handleSubmit}>
        <BalancedPaddedContainer>
          <Grid container spacing={3}>
            {filteredTemplates == null ? (
              <TemplateSkeletons />
            ) : (
              <>
                {filteredTemplates.map((t) => (
                  <Grid item lg={4} xs={12} key={t.id}>
                    <TemplateCard
                      createdAt={t.createdAt}
                      creatorFirstName={t.user.firstName}
                      creatorLastName={t.user.lastName}
                      disabled={!!(!allowCountryRestrictions && t.countries.length)}
                      id={t.id}
                      name={t.name}
                      selected={(restrictionsTemplatesIds ?? []).includes(t.id)}
                      onSelect={(selected) => handleSelectTemplateClick(t, selected)}
                      variant="restrictions"
                      onClickDelete={() =>
                        setDeleteTemplateModalSettings({
                          open: true,
                          templateId: t.id,
                          templateName: t.name,
                        })
                      }
                      onClickRename={() =>
                        setRenameTemplateModalSettings({ open: true, templateId: t.id })
                      }
                    >
                      <RestrictedStocks restrictedStocks={t.tickers} compact />
                      <ExcludedSectors
                        excludedIndustries={t.subSectors}
                        excludedSectors={t.sectors}
                        compact
                      />
                      <ExcludedCountries restrictedCountries={t.countries} compact />
                      <ExcludedEsgAreas excludedEsgAreas={t.esgAreas} compact />
                    </TemplateCard>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </BalancedPaddedContainer>
        <StickyBottomActionContainer>
          <Box display="flex" justifyContent="space-between">
            <BackButton onClick={() => onBack()} />
            <Button color="primary" type="submit" variant="contained">
              Continue
            </Button>
          </Box>
        </StickyBottomActionContainer>
      </form>
      <DeleteTemplateModal
        variant="restrictions"
        onTemplateDeleted={() => handleTemplateDeleted(deleteTemplateModalSettings.templateId)}
        open={deleteTemplateModalSettings.open}
        onClose={() => setDeleteTemplateModalSettings(DELETE_TEMPLATE_MODAL_CLOSED)}
        templateId={deleteTemplateModalSettings.templateId}
        templateName={deleteTemplateModalSettings.templateName}
        deleteTemplate={deleteTemplate}
        eventCategory={EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION}
      />
      <RenameTemplateModal
        variant="restrictions"
        data-testid="rename-template-modal"
        open={renameTempalateModalSettings.open}
        templateId={renameTempalateModalSettings.templateId}
        onClose={() => setRenameTemplateModalSettings(RENAME_TEMPLATE_MODAL_CLOSED)}
        renameTemplate={renameTemplate}
        isNameValid={isNameValid}
        isTemplateNameTaken={isTemplateNameTaken}
        onTemplateRenamed={() => mutateRestrictionsTemplates}
      />
    </>
  );
}
