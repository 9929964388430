import { Box, Card, Typography, Grid, Divider, Tabs, Tab } from '@mui/material';
import React from 'react';
import CardHeader from '~/synth/CardHeader';
import AllocationChart from '~/components/portfolio/AllocationChart2';
import { formatCurrency, formatPercent } from '~/utils/format';
import { Allocation } from '~/models/api';
import { useAssetToColorMap } from '~/hooks/useAssetToColorMap';
import { groupBy, keyBy } from 'lodash';
import { AssetClassKey, Feature } from 'vise-types/pce2_instrument';
import {
  transformPCE2AssetClassAllocations,
  adjustAllocationByPctOfManagedValue,
  AllocationChartDataPoint,
} from '../../Portfolio/portfolioUtil';
import { processAssetClassAllocationData } from '../../Portfolio/card/AllocationCard2';
import { ASSET_CLASS_TO_LABEL_MAP } from '../../PortfolioCreator2/Constants';
import GPTCardHeader from './GPTCardHeader';
import LoadingCard from './LoadingCard';
import CardDisclosures from './CardDisclosures';
import usePortfolioMetricsWithLogging from './usePortfolioMetricsWithLogging';
import usePortfolioIntelligenceWithLogging from './usePortfolioIntelligenceWithLogging';
import useAccountWithLogging from './useAccountWithLogging';

const L1_ASSET_CLASS_LABEL_TO_KEY_MAP = {
  Equities: 'EQUITY',
  'Fixed income': 'FIXED_INCOME',
  Alternatives: 'ALTERNATIVES',
  Cash: 'CASH',
  'Other assets': 'UNKNOWN',
};

const AssetClassRow = ({ name, y, bold }: { name: AssetClassKey; y: number; bold?: boolean }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" my={1.5}>
    <Typography mr={3} variant="body2" fontWeight={bold ? 700 : 400}>
      {name.split('/').length > 1
        ? name
            .split('/')
            .slice(1)
            .map((feature) => ASSET_CLASS_TO_LABEL_MAP.get(feature as Feature) || '')
            .join(' ')
        : ASSET_CLASS_TO_LABEL_MAP.get(name as Feature)}
    </Typography>

    <Typography variant="body2" fontWeight={bold ? 700 : 400}>
      {formatPercent(y / 100, 1)}
    </Typography>
  </Box>
);

const AssetClassTable = ({
  assetAllocationChartData,
  selectedAssetClass,
  setSelectedAssetClass,
  aum,
}: {
  assetAllocationChartData: AllocationChartDataPoint[][];
  selectedAssetClass: AssetClassKey;
  setSelectedAssetClass: (assetClass: AssetClassKey) => void;
  aum?: number;
}) => {
  const L1AssetClassesMap = keyBy(assetAllocationChartData[1], (item) => item.name);

  // L1 -> L2s mapping
  const groupedAssetAllocationChartData = groupBy(
    assetAllocationChartData[0],
    (data) => data.name.split('/')[0]
  );

  return (
    <Card>
      <CardHeader>
        <Box display="flex" alignItems="center" justifyContent="flex-start" gap={2}>
          <Typography variant="h4">Total Portfolio Value</Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant="body2">{formatCurrency(aum)}</Typography>
        </Box>
      </CardHeader>

      <Box mx={2}>
        {Object.keys(groupedAssetAllocationChartData).length > 1 && (
          <Box px={1}>
            <Tabs
              value={selectedAssetClass}
              onChange={(e, newTab) => setSelectedAssetClass(newTab)}
            >
              {groupedAssetAllocationChartData.EQUITY && (
                <Tab
                  value="EQUITY"
                  label="Equities"
                  id="equities-tab"
                  aria-controls="equities-tab-content"
                />
              )}
              {groupedAssetAllocationChartData.FIXED_INCOME && (
                <Tab
                  value="FIXED_INCOME"
                  label="Fixed Income"
                  id="fixed-income-tab"
                  aria-controls="fixed-income-tab-content"
                />
              )}
              {groupedAssetAllocationChartData.ALTERNATIVES && (
                <Tab
                  value="ALTERNATIVES"
                  label="Alternatives"
                  id="alternatives-tab"
                  aria-controls="alternatives-tab-content"
                />
              )}
            </Tabs>
            <Divider orientation="horizontal" />
          </Box>
        )}
        <Box
          height={Object.keys(groupedAssetAllocationChartData).length > 1 ? '316px' : '400px'}
          overflow="scroll"
          px={1}
        >
          {L1AssetClassesMap[selectedAssetClass] && (
            <AssetClassRow
              name={selectedAssetClass}
              y={(L1AssetClassesMap[selectedAssetClass] as { y: number }).y * 100}
              bold
            />
          )}

          {(groupedAssetAllocationChartData[selectedAssetClass] || []).map((data) => {
            return (
              <Box key={data.name}>
                <Divider />
                <AssetClassRow name={data.name as AssetClassKey} y={data.y * 100} />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Card>
  );
};

function AssetAllocationChart({
  assetAllocation,
  aum,
}: {
  assetAllocation: Allocation;
  aum?: number;
}) {
  const assetToColorMap = useAssetToColorMap();
  const assetAllocationChartData = processAssetClassAllocationData(
    assetAllocation,
    assetToColorMap
  );
  const [selectedAssetClass, setSelectedAssetClass] = React.useState<AssetClassKey>('EQUITY');

  return (
    <Grid container data-testid="allocations">
      <Grid item sm={4} xs={12} display="flex" alignItems="center" justifyContent="center">
        <AllocationChart
          data={assetAllocationChartData[0]}
          groupedData={assetAllocationChartData[1]}
          height={300}
          onClickSelection={(event) => {
            if (Object.keys(L1_ASSET_CLASS_LABEL_TO_KEY_MAP).includes(event.point.name)) {
              setSelectedAssetClass(
                L1_ASSET_CLASS_LABEL_TO_KEY_MAP[event.point.name] as AssetClassKey
              );

              return;
            }

            const L1 = event.point.name.split('/')[0];
            setSelectedAssetClass(L1 as AssetClassKey);
          }}
        />
      </Grid>
      <Grid item sm={1}>
        <Box display="flex" justifyContent="center" height="100%" mr={2}>
          <Divider orientation="vertical" />
        </Box>
      </Grid>
      <Grid item sm={7} xs={12}>
        {assetAllocation.length === 0 ? (
          <Box mt={1.5}>
            <Typography color="textSecondary" variant="body2">
              No asset allocation
            </Typography>
          </Box>
        ) : (
          <AssetClassTable
            assetAllocationChartData={assetAllocationChartData}
            selectedAssetClass={selectedAssetClass}
            setSelectedAssetClass={setSelectedAssetClass}
            aum={aum}
          />
        )}
      </Grid>
    </Grid>
  );
}

const GPTAssetAllocationChart = ({ accountId }) => {
  const { data: accountResponseData } = useAccountWithLogging({
    accountId,
    includeSummary: true,
    callerName: 'GPTAssetAllocationChart',
  });
  const account = accountResponseData?.data;

  const { data: intelligenceArrayData } = usePortfolioIntelligenceWithLogging(
    account?.portfolioIntelligenceId,
    'GPTAssetAllocationChart'
  );
  const intelligence = intelligenceArrayData?.[0];

  const { data: pce2Metrics } = usePortfolioMetricsWithLogging(
    accountId,
    'GPTAssetAllocationChart'
  );

  const { pctOfManagedValue, unadjustedAssetAllocation, lockedUnrecognizedFraction } =
    React.useMemo(() => {
      // temporary for testing
      // const targetAllocation = pce2Metrics?.strategyMetrics?.targetPortfolio.assetClassAllocations;
      return {
        pctOfManagedValue: pce2Metrics ? pce2Metrics.portfolioMetrics.managedFraction * 100.0 : 0.0,
        lockedUnrecognizedFraction: pce2Metrics
          ? pce2Metrics.portfolioMetrics.lockedUnrecognizedFraction * 100.0
          : 0.0,
        unadjustedAssetAllocation: pce2Metrics
          ? transformPCE2AssetClassAllocations(pce2Metrics.portfolioMetrics.assetClassAllocations)
          : [],
      };
    }, [pce2Metrics]);

  const [allocationsOfViseManagedValue, allocationsOfTotalPortfolio] = [
    adjustAllocationByPctOfManagedValue(
      unadjustedAssetAllocation,
      lockedUnrecognizedFraction,
      true
    ),
    adjustAllocationByPctOfManagedValue(
      unadjustedAssetAllocation,
      lockedUnrecognizedFraction,
      false
    ),
  ];

  const showViseManagedControls =
    pctOfManagedValue != null &&
    pctOfManagedValue < 100 &&
    // Since we zero out cash allocation, a cash-only portfolio will not have any allocations
    allocationsOfViseManagedValue.length > 0;

  const adjustedAssetAllocationData = showViseManagedControls
    ? allocationsOfViseManagedValue
    : allocationsOfTotalPortfolio;

  if (!intelligence || !pce2Metrics || !account) {
    return <LoadingCard contentHeight="510px" />;
  }

  return (
    <Card style={{ overflow: 'visible' }}>
      <GPTCardHeader
        title="Asset Allocation"
        buttonPathname={`/secure/accounts/${accountId}/portfolio/overview`}
        buttonCopy="View account"
        account={account}
      />

      <Box display="flex" flexDirection="row" p={3} height="510px">
        <AssetAllocationChart
          assetAllocation={adjustedAssetAllocationData}
          aum={account?.cachedAum || undefined}
        />
      </Box>
      <Divider />
      <CardDisclosures />
    </Card>
  );
};

export default GPTAssetAllocationChart;
