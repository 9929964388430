import { Custodian } from 'vise-types/custodian';
import { Cadence } from 'vise-types/distribution';
import { upsertDistributionSchedule } from '../api/api';
import useEnqueueToast from './useToast';

type UpsertDistributionOptions = {
  amount: number;
  processOn: Date;
  cadence: Cadence;
  clientId: string;
  custodianCode: Custodian;
  accountNumber: string;
  processUntil: Date | null;
  allowMultipleRecurring?: boolean;
  description?: string;
  id?: string;
  allowWashSaleIfNeeded?: boolean;
};

export default function useUpsertDistribution() {
  const enqueueToast = useEnqueueToast();
  const upsertDistribution = async ({
    amount,
    cadence,
    accountNumber,
    custodianCode,
    clientId,
    processOn,
    processUntil,
    allowMultipleRecurring,
    description,
    id,
    allowWashSaleIfNeeded,
  }: UpsertDistributionOptions) => {
    try {
      await upsertDistributionSchedule(
        amount,
        processOn,
        cadence,
        clientId,
        custodianCode,
        accountNumber,
        processUntil,
        allowMultipleRecurring,
        description,
        id,
        allowWashSaleIfNeeded
      );
      enqueueToast({
        severity: 'success',
        title: `You've successfully updated your distribution schedule.`,
      });
      return true;
    } catch (e) {
      enqueueToast({
        severity: 'error',
        title: `Failed to update distribution schedule.`,
        content: `Please reach out to clientservice@vise.com for help.`,
      });
      return false;
    }
  };
  return { upsertDistribution };
}
