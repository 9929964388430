import { Box, Button, Card, CardActionArea, Collapse, Divider, Typography } from '@mui/material';

// eslint-disable-next-line no-restricted-imports
import { withStyles } from 'tss-react/mui';

import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { AssetClassKey, Feature } from 'vise-types/pce2_instrument';
import { InputYear } from '~/components';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useGlidePathFinalResults from '~/hooks/useGlidePathFinalResults';
import { ReactComponent as ChevronDown } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '~/static/images/icons/chevron-up.svg';
import SelectorCheckbox from '~/synth/SelectorCheckbox';
import { Tag } from '~/synth/Tag';
import TextField from '~/synth/TextField';
import { calculatePortfolioHorizon } from '~/utils/ageUtils';
import amplitude from '~/utils/amplitude';
import { ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP, ASSET_CLASS_TO_LABEL_MAP } from '../../Constants';
import ReturnToSummary from '../../ReturnToSummary';
import { Pce2ConstructionInfo, ScreenProps } from '../../Types';
import { allowConcentrationLimits, shouldSkipRestrictions } from '../../utils';
import { calculateNextDateXYearsFromTomorrow } from '../PCE2RiskToleranceScreen';
import { ActionFooter, BackButton, ContentBox, ExplainerText } from '../components';
import MaxConcentrationLimitSection, {
  FormValues,
} from '../components/MaxConcentrationLimitSectionV2';
import { DetailsText, onlyTerminalKeys, sumKeys } from '../components/SummarySections';

const StyledCardActionArea = withStyles(CardActionArea, (_theme, _params, classes) => ({
  root: {
    [`&:hover .${classes.focusHighlight}`]: { opacity: 0 },
  },
  focusHighlight: {},
}));

type AssetClassContentProps = {
  parent: AssetClassKey;
  assetAllocation?: Pce2ConstructionInfo['assetAllocation'];
  finalAllocation?: Pce2ConstructionInfo['assetAllocation'];
};

const AssetClassContent = (props: AssetClassContentProps) => {
  const allocation = onlyTerminalKeys(props.assetAllocation || {});
  const childrenKeys = [...(ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get(props.parent) || [])]
    .filter((key) => allocation[key] != null)
    .sort((key1, key2) => (allocation[key2] || 0) - (allocation[key1] || 0));
  const sum = childrenKeys.length
    ? sumKeys(childrenKeys, allocation)
    : allocation[props.parent] || 0;

  const finalAllocation = onlyTerminalKeys(props.finalAllocation || {});
  const finalChildrenKeys = [...(ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get(props.parent) || [])]
    .filter((key) => finalAllocation[key] != null)
    .sort((key1, key2) => (finalAllocation[key2] || 0) - (finalAllocation[key1] || 0));
  const finalSum = finalChildrenKeys.length
    ? sumKeys(finalChildrenKeys, finalAllocation)
    : finalAllocation[props.parent] || 0;
  return (
    <Box mt={2}>
      <Box display="flex" width="100%">
        <Box width="80%">
          <DetailsText variant="body2">
            {props.parent
              .split('/')
              .slice(1)
              .map((feature) => ASSET_CLASS_TO_LABEL_MAP.get(feature as Feature) || '')
              .join(' ')}
          </DetailsText>
        </Box>
        <Box width="10%" textAlign="end">
          <DetailsText variant="body2">{(sum * 100).toFixed(0)}%</DetailsText>
        </Box>
        <Box width="10%" textAlign="end">
          <DetailsText variant="body2">{(finalSum * 100).toFixed(0)}%</DetailsText>
        </Box>
      </Box>

      {childrenKeys.map((key) => (
        <Box display="flex" width="100%" key={key}>
          <Box width="80%">
            <DetailsText variant="body2" color="textSecondary">
              {key
                .split('/')
                .slice(2)
                .map((feature) => ASSET_CLASS_TO_LABEL_MAP.get(feature as Feature) || '')
                .join(' ')}
            </DetailsText>
          </Box>
          <Box width="10%" textAlign="end">
            <DetailsText variant="body2" color="textSecondary">
              {((allocation[key] || 0) * 100).toFixed(0)}%
            </DetailsText>
          </Box>
          <Box width="10%" textAlign="end">
            <DetailsText variant="body2" color="textSecondary">
              {((finalAllocation[key] || 0) * 100).toFixed(0)}%
            </DetailsText>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export function GlidePathExpansionCard({
  assetAllocation,
  finalAssetAllocation,
}: {
  assetAllocation: Pce2ConstructionInfo['assetAllocation'];
  finalAssetAllocation: Pce2ConstructionInfo['assetAllocation'];
}) {
  const [isDetailsExpanded, setIsDetailsExpanded] = useState<boolean>();
  const finalAllocationLoaded = finalAssetAllocation !== undefined;
  function handleToggleOpen() {
    if (!finalAllocationLoaded) {
      return;
    }
    setIsDetailsExpanded(!isDetailsExpanded);
  }
  return (
    <Box mb={1.5} mt={2}>
      <Card>
        <StyledCardActionArea onClick={handleToggleOpen}>
          <Box display="flex" justifyContent="space-between" pl={3} pr={2} py={2}>
            <Box display="flex">
              <Box data-testid="expand-restriction-templates-card">
                {isDetailsExpanded ? (
                  <ChevronUp width="20" height="20" />
                ) : (
                  <ChevronDown width="20" height="20" />
                )}
              </Box>
              <Box ml={3} my="auto">
                <Typography data-testid="restrictions-template-name" variant="h4">
                  How will my client’s allocation change over time?
                </Typography>
              </Box>
            </Box>
          </Box>
          <Collapse in={isDetailsExpanded}>
            <Box mx={3} mb={1.5}>
              <Box display="flex" width="100%" mb={1} mt={2}>
                <Box width="80%">
                  <Typography variant="h4">Equities</Typography>
                </Box>
                <Box width="10%" textAlign="end">
                  <Typography variant="h4">Initial</Typography>
                </Box>
                <Box width="10%" textAlign="end">
                  <Typography variant="h4">Final</Typography>
                </Box>
              </Box>

              <Divider />
              <Box mt={1}>
                <AssetClassContent
                  parent="EQUITY/US"
                  assetAllocation={assetAllocation}
                  finalAllocation={finalAssetAllocation}
                />
                <AssetClassContent
                  parent="EQUITY/DEVELOPED"
                  assetAllocation={assetAllocation}
                  finalAllocation={finalAssetAllocation}
                />
                <AssetClassContent
                  parent="EQUITY/EMERGING_EQUITY"
                  assetAllocation={assetAllocation}
                  finalAllocation={finalAssetAllocation}
                />
                <AssetClassContent
                  parent="EQUITY/US_REIT"
                  assetAllocation={assetAllocation}
                  finalAllocation={finalAssetAllocation}
                />
              </Box>

              <Box display="flex" width="100%" mb={1} mt={2}>
                <Box width="80%">
                  <Typography variant="h4">Fixed Income</Typography>
                </Box>
              </Box>

              <Divider />

              <Box mt={1}>
                <AssetClassContent
                  parent="FIXED_INCOME/DOMESTIC"
                  assetAllocation={assetAllocation}
                  finalAllocation={finalAssetAllocation}
                />
                <AssetClassContent
                  parent="FIXED_INCOME/INTERNATIONAL"
                  assetAllocation={assetAllocation}
                  finalAllocation={finalAssetAllocation}
                />
                <AssetClassContent
                  parent="FIXED_INCOME/EMERGING_FI"
                  assetAllocation={assetAllocation}
                  finalAllocation={finalAssetAllocation}
                />
              </Box>

              <Box display="flex" width="100%" mb={1} mt={2}>
                <Box width="80%">
                  <Typography variant="h4">Alternatives</Typography>
                </Box>
              </Box>

              <Divider />
              <Box mt={1}>
                <AssetClassContent
                  parent="ALTERNATIVES/COMMODITIES"
                  assetAllocation={assetAllocation}
                  finalAllocation={finalAssetAllocation}
                />
              </Box>
            </Box>
          </Collapse>
        </StyledCardActionArea>
      </Card>
    </Box>
  );
}

export default function SpecialConditionsScreen({
  draftPortfolio,
  onBack,
  onContinue,
  dpDispatch,
}: ScreenProps) {
  const { constructionInfo, editModeOriginalInvestmentTimeline, accountSize } = draftPortfolio;
  const {
    assetClassConcentrationLimits: {
      isEnabled: applyConcentrationLimits = false,
      equities: equitiesConcentrationLimit,
      fixedIncome: fixedIncomeConcentrationLimit,
      alternatives: alternativesConcentrationLimit,
      exclusions,
    } = {} as Exclude<Pce2ConstructionInfo['assetClassConcentrationLimits'], undefined>,
    investmentTimeline,
    useGlidePath,
    etfExclusive,
    assetClassConcentrationLimits,
    isCustomAllocation,
    assetAllocation,
    existingPortfolio,
  } = constructionInfo as Pce2ConstructionInfo;

  const originalInvestmentTimelineYears = useMemo(() => {
    return editModeOriginalInvestmentTimeline == null
      ? null
      : calculatePortfolioHorizon(editModeOriginalInvestmentTimeline, new Date());
  }, [editModeOriginalInvestmentTimeline]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      applyConcentrationLimits,
      equitiesConcentrationLimit: equitiesConcentrationLimit ?? '',
      fixedIncomeConcentrationLimit: fixedIncomeConcentrationLimit ?? '',
      alternativesConcentrationLimit: alternativesConcentrationLimit ?? '',
      timeHorizon:
        investmentTimeline == null
          ? null
          : calculatePortfolioHorizon(investmentTimeline, new Date()),
    },
    mode: 'onChange',
  });

  const timeHorizon = useWatch({ control, name: 'timeHorizon' });

  const commitValues = useMemo(
    () =>
      debounce((nextValues) => {
        const nextEquitiesConcentrationLimit =
          nextValues.equitiesConcentrationLimit === '' ||
          nextValues.equitiesConcentrationLimit === undefined
            ? null
            : nextValues.equitiesConcentrationLimit;

        dpDispatch({
          type: 'PCE2_SET_CONCENTRATION_LIMITS_EQUITIES',
          equities: nextEquitiesConcentrationLimit,
        });

        const nextFixedIncomeConcentrationLimit =
          nextValues.fixedIncomeConcentrationLimit === '' ||
          nextValues.fixedIncomeConcentrationLimit === undefined
            ? null
            : nextValues.fixedIncomeConcentrationLimit;
        dpDispatch({
          type: 'PCE2_SET_CONCENTRATION_LIMITS_FIXED_INCOME',
          fixedIncome: nextFixedIncomeConcentrationLimit,
        });

        const nextAlternativesConcentrationLimit =
          nextValues.alternativesConcentrationLimit === '' ||
          nextValues.alternativesConcentrationLimit == null
            ? null
            : nextValues.alternativesConcentrationLimit;

        dpDispatch({
          type: 'PCE2_SET_CONCENTRATION_LIMITS_ALTERNATIVES',
          alternatives: nextAlternativesConcentrationLimit,
        });

        amplitude().logEvent(
          nextValues.applyConcentrationLimits
            ? 'Enable concentration limits'
            : 'Disable concentration limits',
          {
            category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
          }
        );
        dpDispatch({
          type: 'PCE2_SET_CONCENTRATION_LIMITS_IS_ENABLED',
          isEnabled: nextValues.applyConcentrationLimits,
        });
      }, 100),
    [dpDispatch]
  );

  const commitTimeHorizon = useMemo(
    () =>
      debounce((newYears?: number | null) => {
        if (errors.timeHorizon) {
          amplitude().logEvent('Portfolio horizon error', {
            category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
            portfolioHorizon: newYears,
          });
          return;
        }

        let nextInvestmentTimeline: string | null;
        if (newYears == null || Number.isNaN(newYears)) {
          nextInvestmentTimeline = null;
        } else if (newYears === originalInvestmentTimelineYears) {
          nextInvestmentTimeline = editModeOriginalInvestmentTimeline;
        } else {
          nextInvestmentTimeline = calculateNextDateXYearsFromTomorrow(newYears);
        }
        dpDispatch({ type: 'SET_INVESTMENT_TIMELINE', investmentTimeline: nextInvestmentTimeline });
      }, 250),
    [errors, editModeOriginalInvestmentTimeline, originalInvestmentTimelineYears, dpDispatch]
  );

  const concentrationLimitExclusions = assetClassConcentrationLimits?.exclusions || [];
  const { data: finalAssetAllocation } = useGlidePathFinalResults(
    assetAllocation,
    concentrationLimitExclusions
  );

  useEffect(() => {
    commitTimeHorizon(timeHorizon);
  }, [commitTimeHorizon, timeHorizon]);

  const onSubmit = useCallback(() => {
    commitValues.flush();
    commitTimeHorizon.flush();
    if (
      shouldSkipRestrictions({
        etfExclusive,
        accountSize,
        exclusions: assetClassConcentrationLimits?.exclusions,
      })
    ) {
      if (existingPortfolio !== 'sample-portfolio' && existingPortfolio?.taxable) {
        onContinue('capGains');
        return;
      }
      onContinue('summary');
    } else if (draftPortfolio.allocationTemplate?.tiltSelection) {
      onContinue('buildRestrictions');
    } else {
      onContinue();
    }
  }, [
    commitValues,
    commitTimeHorizon,
    etfExclusive,
    accountSize,
    assetClassConcentrationLimits?.exclusions,
    onContinue,
    existingPortfolio,
    draftPortfolio.allocationTemplate?.tiltSelection,
  ]);

  function handleToggleGlidePath() {
    let amplitudeEventName: string;
    if (useGlidePath) {
      amplitudeEventName = 'Unset';
      dpDispatch({
        type: 'SET_INVESTMENT_TIMELINE',
        investmentTimeline: null,
      });
      setValue('timeHorizon', null);
    } else {
      amplitudeEventName = 'Set';
    }
    amplitude().logEvent(`${amplitudeEventName} glide path`, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
    dpDispatch({
      type: 'SET_USE_GLIDE_PATH',
      useGlidePath: !useGlidePath,
    });
  }

  const nextButtonDisabled =
    !isValid || !!errors.timeHorizon || (useGlidePath && investmentTimeline == null);

  return (
    <ContentBox>
      <Typography variant="h1">
        Does this portfolio have any special conditions?
        <Tag ml={1} height="34px" verticalAlign="middle" py={1}>
          <Box fontSize={14}>Optional</Box>
        </Tag>
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box pt={4} pb={useGlidePath ? 4 : 1}>
          <Typography variant="h3" id="glide-path">
            Glide path
          </Typography>
          <ExplainerText pt={3}>
            If glide path is enabled, Vise will automatically transition the portfolio toward
            lower-risk fixed income assets, reducing your client&apos;s equity allocation by 80%
            over the specified time horizon. Note that portfolios with custom allocations are
            ineligible for glide path.
          </ExplainerText>
          <SelectorCheckbox
            onChange={handleToggleGlidePath}
            name="useGlidePath"
            checked={!!useGlidePath}
            disabled={isCustomAllocation || applyConcentrationLimits}
            label="Put this portfolio on a glide path"
          />
          {useGlidePath && (
            <Box mt={0.5}>
              <Controller
                control={control}
                name="timeHorizon"
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                render={({ field: { ref: _ref, ...fieldRest } }) => (
                  <TextField
                    {...fieldRest}
                    error={!!errors.timeHorizon}
                    label="Time horizon"
                    helperText="Enter a value between 5 and 100 years."
                    InputProps={{ inputComponent: InputYear }}
                  />
                )}
                rules={{ min: 5, max: 100 }}
              />
              {finalAssetAllocation?.data ? (
                <GlidePathExpansionCard
                  assetAllocation={assetAllocation}
                  finalAssetAllocation={finalAssetAllocation.data}
                />
              ) : null}
            </Box>
          )}
        </Box>
        {applyConcentrationLimits && (
          <Box mb={2}>
            <Typography variant="body2" color="textSecondary">
              Portfolios with asset class concentration limits cannot have a glide path since their
              allocation will transition over time.
            </Typography>
          </Box>
        )}
        <Divider />
        <Box pt={4}>
          {allowConcentrationLimits(draftPortfolio) &&
            draftPortfolio.constructionInfo.assetAllocation && (
              <>
                <Typography variant="h3">Asset concentration limits</Typography>
                <ExplainerText pt={3}>
                  Introducing asset class concentration limits may alter the target allocation of
                  the portfolio. Capital gains limits may be exceeded to respect asset class
                  concentration limits. Note that asset class concentration limits must exceed your
                  target allocation by at least 10%.
                </ExplainerText>
                <MaxConcentrationLimitSection
                  commitValues={commitValues}
                  control={control}
                  allocations={draftPortfolio.constructionInfo.assetAllocation}
                  exclusions={exclusions ?? []}
                  disabled={useGlidePath}
                />
              </>
            )}
        </Box>
        <ActionFooter justifyContent="space-between">
          <BackButton onClick={() => onBack()} />
          <Box display="flex">
            <ReturnToSummary
              disabled={false}
              draftPortfolio={draftPortfolio}
              onReturnToSummary={() => onContinue('summary')}
              mr={1.5}
            />
            <Button color="primary" type="submit" variant="contained" disabled={nextButtonDisabled}>
              Continue
            </Button>
          </Box>
        </ActionFooter>
      </form>
    </ContentBox>
  );
}
