import useSWR from 'swr';
import { InputConstructionInfo } from 'vise-types/pce1';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import { getGlidePathFinalResults } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getGlidePathFinalResultsKey = (assetAllocation, assetClassConcentrationLimits) => {
  const key = JSON.stringify(assetAllocation, assetClassConcentrationLimits);
  return generateSwrKey(`get-glide-path-results/${key}`);
};

const useGlidePathFinalResults = (
  assetAllocation: InputConstructionInfo['assetAllocation'] | undefined | null,
  assetClassConcentrationLimits: AssetClassKey[]
) => {
  const { data, error, isValidating, mutate } = useSWR(
    assetAllocation !== null
      ? getGlidePathFinalResultsKey(assetAllocation, assetClassConcentrationLimits)
      : null,
    assetAllocation == null
      ? undefined
      : () =>
          getGlidePathFinalResults({
            assetAllocation,
            concentrationLimits: assetClassConcentrationLimits,
          }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    }
  );

  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export default useGlidePathFinalResults;
