import { Box } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import Highcharts, { type SeriesPieOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { type BondPortfolioMuniUseAllocation } from 'vise-types/portfolio';
import useCategoricalDataVisColors from '~/hooks/useCategoricalDataVisColors';
import { formatPercent } from '~/utils/format';
import { MUNI_USE_TO_LABEL } from './Utils';

type PropType = {
  muniUses: BondPortfolioMuniUseAllocation;
};

export default function BondPortfolioSectorChart({ muniUses }: PropType) {
  const sectorColors = useCategoricalDataVisColors();

  const data: SeriesPieOptions['data'] = muniUses.map((muniUse, idx) => ({
    name: MUNI_USE_TO_LABEL[muniUse.muniUse],
    y: muniUse.aggregateWeight * 100,
    color: sectorColors[idx],
  }));

  const sectorsChartOptions: Highcharts.Options = {
    chart: { type: 'pie', backgroundColor: undefined, height: 300 },
    plotOptions: {
      pie: {
        size: '100%',
        innerSize: '80%',
        dataLabels: {
          enabled: false,
        },
      },
    },
    title: { text: '' },
    credits: { enabled: false },
    tooltip: { pointFormat: '<b>{point.percentage:.1f}%</b>' },
    series: [
      {
        type: 'pie',
        name: 'Allocation',
        data,
      },
    ],
  };

  return <HighchartsReact options={sectorsChartOptions} />;
}

export function ChartLegend({ muniUses }: PropType) {
  const sectorColors = useCategoricalDataVisColors();

  return (
    <Box>
      {muniUses.map((muniUse, idx) => (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          key={muniUse.muniUse}
        >
          <Box display="flex" alignItems="center" mt={2}>
            <Box width={12} height={12} borderRadius={0.5} bgcolor={sectorColors[idx]} mr={1} />
            {MUNI_USE_TO_LABEL[muniUse.muniUse]}
          </Box>
          <Box color={tokens.palette.neutralCool[700]}>
            {formatPercent(muniUse.aggregateWeight, 1)}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
