import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
} from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';
import { ValueType } from 'react-select';
import { createClient, updateAccount } from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useEnqueueToast from '~/hooks/useToast';
import useUnassignedAccounts from '~/hooks/useUnassignedAccounts';
import { RawClient } from '~/models/api';
import DialogTitle from '~/synth/DialogTitle';
import Multiselect from '~/synth/inputs/Multiselect';
import TextField from '~/synth/TextField';
import amplitude from '~/utils/amplitude';
import { maskAccountNumber } from '~/utils/format';

interface AddHouseholdDialogContainerProps {
  onClose: () => void;
  onHouseholdCreated: (householdId: string) => void;
  open: boolean;
}

interface AccountOption {
  value: string;
  label: string;
  description: string;
}

interface FormValues {
  householdName: string;
  clientFirstName: string;
  clientLastName: string;
  accountIds: string[];
}
interface AddHouseholdDialogProps {
  accountOptions: AccountOption[];
  accountOptionsLoading: boolean;
  accountOptionsError: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
  onClose: () => void;
  open: boolean;
}

type SelectedAccountOptions = AccountOption[] | null;

export const AddHouseholdModal = ({
  onSubmit,
  onClose,
  open,
  accountOptions,
  accountOptionsLoading,
  accountOptionsError,
}: AddHouseholdDialogProps) => {
  const formik = useFormik<FormValues>({
    initialValues: {
      clientFirstName: '',
      clientLastName: '',
      accountIds: [],
      householdName: '',
    },

    async onSubmit(values) {
      await onSubmit(values);
    },

    validate(values) {
      const errors: FormikErrors<FormValues> = {};
      if (values.householdName === '') errors.householdName = 'Enter a household name';
      if (values.householdName.length >= 256)
        errors.householdName = 'Household name should be under 256 characters';
      if (values.clientFirstName === '') errors.clientFirstName = 'Enter a first name';
      if (values.clientFirstName.length >= 256)
        errors.clientFirstName = 'First name should be under 256 characters';
      if (values.clientLastName === '') errors.clientLastName = 'Enter a last name';
      if (values.clientLastName.length >= 256)
        errors.clientLastName = 'Last name should be under 256 characters';
      return errors;
    },
  });

  const onCloseWithReset = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Dialog open={open} onClose={onCloseWithReset}>
      <DialogTitle onClose={onCloseWithReset}>Add a new household</DialogTitle>
      {accountOptionsError ? (
        <DialogContent>
          There was an error loading available accounts. Please refresh and try again.
        </DialogContent>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={1}>
              <Box width="100%" mb={4}>
                <TextField
                  autoFocus
                  error={formik.touched.householdName && Boolean(formik.errors.householdName)}
                  fullWidth
                  helperText={formik.touched.householdName && formik.errors.householdName}
                  id="householdName"
                  label="Household name"
                  name="householdName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  InputLabelProps={{ required: true }}
                  disabled={formik.isSubmitting}
                  value={formik.values.householdName}
                />
              </Box>
              <Box mb={3}>
                Enter the details of the first client you&apos;d like to add to this household.
              </Box>
              <Grid item xs={6}>
                <TextField
                  error={formik.touched.clientFirstName && Boolean(formik.errors.clientFirstName)}
                  fullWidth
                  helperText={formik.touched.clientFirstName && formik.errors.clientFirstName}
                  id="clientFirstName"
                  label="First name"
                  name="clientFirstName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  InputLabelProps={{ required: true }}
                  disabled={formik.isSubmitting}
                  value={formik.values.clientFirstName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={formik.touched.clientLastName && Boolean(formik.errors.clientLastName)}
                  fullWidth
                  helperText={formik.touched.clientLastName && formik.errors.clientLastName}
                  id="clientLastName"
                  label="Last name"
                  name="clientLastName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  InputLabelProps={{ required: true }}
                  disabled={formik.isSubmitting}
                  value={formik.values.clientLastName}
                />
              </Grid>
            </Grid>
            <Box mt={3}>
              <FormControl fullWidth>
                <FormLabel>Link accounts</FormLabel>
                <Multiselect<AccountOption>
                  isLoading={accountOptionsLoading}
                  isDisabled={
                    formik.isSubmitting || (!accountOptionsLoading && isEmpty(accountOptions))
                  }
                  menuPortalTarget={document.body}
                  menuShouldScrollIntoView={false}
                  placeholder={
                    !accountOptionsLoading && isEmpty(accountOptions)
                      ? 'No accounts available'
                      : 'Select accounts'
                  }
                  options={accountOptions}
                  isSearchable
                  onChange={(selectedOptions: ValueType<AccountOption>) => {
                    const selectedAccountIds = (
                      (selectedOptions as SelectedAccountOptions) || []
                    ).map((option) => {
                      return option.value;
                    });
                    formik.setFieldValue('accountIds', selectedAccountIds);
                  }}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onCloseWithReset}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              startIcon={
                formik.isSubmitting ? <CircularProgress size="1em" color="inherit" /> : undefined
              }
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save changes
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

const AddHouseholdModalContainer = ({
  onClose,
  onHouseholdCreated,
  open,
}: AddHouseholdDialogContainerProps) => {
  const enqueueToast = useEnqueueToast();
  const {
    data: unassignedAccounts,
    isLoading: unassignedAccountsIsLoading,
    error: unassignedAccountsError,
  } = useUnassignedAccounts();
  const options = useMemo(
    () =>
      unassignedAccounts == null
        ? []
        : unassignedAccounts.map((account) => ({
            value: account.id,
            label: account.name,
            description: maskAccountNumber(account.accountNumber),
          })),
    [unassignedAccounts]
  );

  const handleSubmit = async ({
    householdName,
    clientFirstName,
    clientLastName,
    accountIds,
  }: FormValues) => {
    // Create client
    let newClient: RawClient;
    try {
      newClient = await createClient(
        {
          firstName: clientFirstName,
          lastName: clientLastName,
        },
        {
          name: householdName,
        }
      );
    } catch (e) {
      enqueueToast({
        title: 'There was an error creating the new household.',
        content: 'Please refresh and try again.',
        severity: 'error',
      });
      return;
    }
    amplitude().logEvent('AddHouseholdSuccess', {
      category: EVENT_CATEGORIES.HOUSEHOLDS,
      householdName,
    });
    amplitude().logEvent('AddClientSuccess', {
      category: EVENT_CATEGORIES.HOUSEHOLDS,
      clientId: newClient.id,
    });

    // Link accounts to client
    try {
      await Promise.all(accountIds.map((accountId) => updateAccount(accountId, newClient.id)));
    } catch (e) {
      enqueueToast({
        title: 'There was an error the linking the account(s) to the new client',
        content: 'Please try again on the "Manage household" page.',
        severity: 'error',
      });
      return;
    } finally {
      onClose();
      if (newClient.clientGroupId) {
        onHouseholdCreated(newClient.clientGroupId);
      }
    }
    amplitude().logEvent('AddAccountSuccess', {
      category: EVENT_CATEGORIES.HOUSEHOLDS,
      accounts: accountIds.join(','),
    });
    enqueueToast({
      title: 'Household created successfully',
      severity: 'success',
    });
  };

  return (
    <AddHouseholdModal
      accountOptionsLoading={unassignedAccountsIsLoading}
      accountOptionsError={unassignedAccountsError}
      onClose={onClose}
      onSubmit={handleSubmit}
      open={open}
      accountOptions={options}
    />
  );
};

export default AddHouseholdModalContainer;
