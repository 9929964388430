import {
  Box,
  Divider,
  Grid,
  Link as MuiLink,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import { Notification, theme, tokens } from '@vise_inc/ds-vise';
import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useAllAccountsWithPIAndHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import { RootState } from '~/reducers';
import { ReactComponent as BookIcon } from '~/static/images/icons/book.svg';
import { ReactComponent as BriefcaseIcon } from '~/static/images/icons/briefcase.svg';
import { ReactComponent as ChessPieceIcon } from '~/static/images/icons/chess-piece.svg';
import { ReactComponent as LineChartIcon } from '~/static/images/icons/line-chart.svg';
import { ReactComponent as PlayIcon } from '~/static/images/icons/play-circle-outline.svg';
import { ReactComponent as SearchIcon } from '~/static/images/icons/search.svg';
import { formatCurrency } from '~/utils/format';
import { useFeatureFlagsWithConfig } from '~/hooks/useFeatureFlags';
import OnboardedSection from './OnboardedSection';
import Article from './components/Article';
import Stepper from './components/Stepper';
import TopLevelMetricsCard from './components/TopLevelMetricsCard';

export default function Dashboard() {
  const themeBase = useTheme();
  const { data: untransitionedAccounts } = useAllAccountsWithPIAndHouseholdInfo({
    includeSummaries: false,
    transitioned: false,
  });
  const { data: transitionedAccounts } = useAllAccountsWithPIAndHouseholdInfo({
    includeSummaries: false,
    transitioned: true,
  });
  const { data: templateData } = useModelTemplateCenterViewData();
  const {
    users: { currentUser },
  } = useSelector((state: RootState) => state);

  const date = new Date();

  const { data: featureFlagsWithConfig } = useFeatureFlagsWithConfig();

  if (!untransitionedAccounts || !transitionedAccounts || !templateData) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box px={6} py={4} maxWidth="1220px" mb={6}>
        <Box mb={6}>
          <Typography variant="h1" mb={3}>
            Welcome back{currentUser.firstName ? `, ${currentUser.firstName}` : ''}.
          </Typography>
          <Box display="flex" color={tokens.palette.neutralCool[600]}>
            {format(date, 'EEEE')} <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            {format(date, 'MMMM d, yyyy')}
          </Box>
        </Box>
        <Grid container spacing={6}>
          {featureFlagsWithConfig?.enable_banner?.treatment === 'on' ? (
            <Grid item xs={12}>
              <Notification
                title={featureFlagsWithConfig.enable_banner.config.title}
                variant={featureFlagsWithConfig.enable_banner.config.variant || 'info'}
              >
                {featureFlagsWithConfig.enable_banner.config.message}
              </Notification>
            </Grid>
          ) : null}
          <Grid item container xs={12} md={6} spacing={4}>
            <Grid item xs={6}>
              <TopLevelMetricsCard
                title="Total AUM"
                header={formatCurrency(
                  transitionedAccounts.data.reduce((sum, acct) => sum + (acct.cachedAum || 0), 0)
                )}
                description="Total AUM on the Vise platform."
                backgroundColor="primaryBlue.100"
                borderColor="primaryBlue.900"
                icon={<LineChartIcon color={tokens.palette.primaryBlue[900]} height={20} />}
                to="/secure/households/households"
              />
            </Grid>
            <Grid item xs={6}>
              <TopLevelMetricsCard
                title="Total Unexecuted Accounts"
                header={untransitionedAccounts.data.length}
                description="Total accounts ready to be onboarded."
                backgroundColor="secondaryTurquoise.100"
                borderColor="secondaryTurquoise.800"
                icon={<LineChartIcon color={tokens.palette.secondaryTurquoise[800]} height={20} />}
                to="/secure/households/untransitioned"
              />
            </Grid>
            <Grid item xs={6}>
              <TopLevelMetricsCard
                title="Total Managed Accounts"
                header={transitionedAccounts.data.length}
                description="Total portfolios managed by you"
                backgroundColor="secondaryYellow.100"
                borderColor="secondaryYellow.800"
                icon={<BriefcaseIcon color={tokens.palette.secondaryYellow[800]} height={20} />}
                to="/secure/households/portfolios"
              />
            </Grid>
            <Grid item xs={6}>
              <TopLevelMetricsCard
                title="Total Strategies Created"
                header={
                  templateData.data.allocationsTemplates.filter((t) => !t.stale && !t.deletedAt)
                    .length
                }
                description="Total number of allocation templates"
                backgroundColor="secondaryGreen.100"
                borderColor="secondaryGreen.800"
                icon={<ChessPieceIcon color={tokens.palette.secondaryGreen[800]} height={20} />}
                to="/secure/strategy-center"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stepper
              steps={[
                {
                  actionType: 'Quick action',
                  title: 'Schedule a meeting',
                  body: 'The Vise Client Success team is here to support you. Our team is committed to providing you with all the resources and assistance you need to make the most of our platform. If you have any questions or concerns, schedule a call today.',
                  cta: 'Schedule a call',
                  ctaLink: 'mailto: clientservice@vise.com',
                },
                {
                  actionType: 'Quick action',
                  title: 'Create a proposal',
                  body: 'Build sample proposals for prospective clients using Vise’s new x-ray analysis tool.',
                  cta: 'Create proposal',
                  ctaLink: '/secure/x-ray',
                },
                {
                  actionType: 'Quick action',
                  title: 'Distributions',
                  body: "View and and schedule upcoming distributions across your client's accounts.",
                  cta: 'View distributions',
                  ctaLink: '/secure/distributions',
                },
                {
                  actionType: 'Quick action',
                  title: 'Create a strategy',
                  body: 'Create an allocation model in the strategy center. Utilize templates to facilitate portfolio management across portfolios by creating, editing, and applying them with ease.',
                  cta: 'Create a strategy',
                  ctaLink: '/secure/strategy-center',
                },
              ]}
            />
          </Grid>
        </Grid>
        <OnboardedSection themeBase={themeBase} />
        <Box my={4}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Article
                title="Investment strategy"
                header="Tax loss harvesting summary"
                backgroundColor={tokens.palette.secondaryTurquoise[100]}
                borderColor={tokens.palette.secondaryTurquoise[400]}
                description="Learn more about the fundamentals of tax loss harvesting through this summary."
                icon={<BookIcon color={tokens.palette.neutralCool[1000]} height={16} />}
                action="Read"
                to="https://drive.google.com/file/d/1pST6BGMBY8ZbwaGElB4bASI3JJwSsPw0/view"
              />
            </Grid>
            <Grid item xs={4}>
              <Article
                title="Build"
                header="Custom asset allocations"
                backgroundColor={tokens.palette.neutralCool[100]}
                borderColor={tokens.palette.neutralCool[400]}
                description="Learn more about how to create custom allocations for your clients."
                icon={<PlayIcon color={tokens.palette.neutralCool[1000]} height={16} />}
                action="Watch tutorial"
                to="https://vimeo.com/769023391/df8c5c8656"
                ctaType="video"
              />
            </Grid>
            <Grid item xs={4}>
              <Article
                title="Resources"
                header="Advisor resource center"
                backgroundColor={tokens.palette.primaryBlue[100]}
                borderColor={tokens.palette.primaryBlue[300]}
                description="Learn more about Vise's product offerings and investment methodology."
                icon={<SearchIcon color={tokens.palette.neutralCool[1000]} height={16} />}
                action="Explore"
                to="https://vise-resource-center.bubbleapps.io/vise-resource-center"
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={tokens.SPACING_INDICES['5xl']}>
          <Typography variant="body3" color="neutralCool.600">
            For more information, see our{' '}
            <MuiLink component={Link} to="/secure/disclosures">
              methodology and assumptions.
            </MuiLink>
            .
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
