/* eslint-disable no-console */
import {
  GPTChatResponse,
  GPTJob,
  GPTSession,
  GPTSessionMessages,
  HouseholdMeetingPrepData,
  MeetingPrepSession,
  ViseIntelSettings,
} from 'vise-types/gpt';
import { doRequestWithAuth } from './apiUtil';

export async function postGPTQuestion(
  question: string,
  sessionId: string,
  experimental
): Promise<GPTJob> {
  console.time('postGPTQuestion');
  const { data } = await doRequestWithAuth<GPTJob>({
    method: 'POST',
    requestUrl: `/gpt/session/${sessionId}/message`,
    data: {
      question,
      experimental,
    },
    shouldRevalidate: false,
  });
  console.timeEnd('postGPTQuestion');
  return data;
}

export async function newSession(experimental: boolean): Promise<GPTJob> {
  const { data } = await doRequestWithAuth<GPTJob>({
    method: 'POST',
    requestUrl: '/gpt/session',
    data: { experimental },
  });
  return data;
}

export async function getSessions(): Promise<GPTSession[]> {
  const { data } = await doRequestWithAuth<GPTSession[]>({
    method: 'GET',
    requestUrl: '/gpt/session',
  });
  return data;
}

export async function getSessionMessages(sessionId: string): Promise<GPTSessionMessages> {
  const { data } = await doRequestWithAuth<GPTSessionMessages>({
    method: 'GET',
    requestUrl: `/gpt/session/${sessionId}/message`,
  });
  return data;
}

export async function rateGPTMessage(
  rating: number,
  sessionId: string,
  messageId: string
): Promise<GPTChatResponse> {
  const { data } = await doRequestWithAuth<GPTChatResponse>({
    method: 'POST',
    requestUrl: `/gpt/session/${sessionId}/message/${messageId}/rating`,
    data: {
      rating,
    },
  });
  return data;
}

export async function checkGPTJob(jobId): Promise<GPTJob> {
  const { data } = await doRequestWithAuth<GPTJob>({
    method: 'GET',
    requestUrl: `/gpt/job/${jobId}`,
  });
  return data;
}

export async function confirmDisclosures(): Promise<boolean> {
  const { data } = await doRequestWithAuth<boolean>({
    method: 'POST',
    requestUrl: `/gpt/confirm-disclosures`,
  });
  return data;
}

export async function submitMessageFeedback({
  messageId,
  content,
}: {
  messageId: string;
  content: string;
}): Promise<void> {
  const { data } = await doRequestWithAuth<void>({
    method: 'POST',
    requestUrl: `/gpt/message/${messageId}/feedback`,
    data: { content },
  });
  return data;
}

export async function submitSessionFeedback({
  sessionId,
  content,
}: {
  sessionId: string;
  content: string;
}): Promise<void> {
  const { data } = await doRequestWithAuth<void>({
    method: 'POST',
    requestUrl: `/gpt/session/${sessionId}/feedback`,
    data: { content },
  });
  return data;
}

export async function getViseIntelSettings(): Promise<ViseIntelSettings> {
  const { data } = await doRequestWithAuth<ViseIntelSettings>({
    method: 'GET',
    requestUrl: `/gpt/settings`,
  });
  return data;
}

export async function upsertIntelSettings({
  enabledAccounts,
}: {
  enabledAccounts: string[];
}): Promise<void> {
  await doRequestWithAuth<void>({
    method: 'POST',
    requestUrl: `/gpt/settings`,
    data: { settings: { enabledAccounts } },
  });
}

export async function generateMeetingPrep({ accountId }: { accountId: string }) {
  const { data } = await doRequestWithAuth<GPTJob>({
    method: 'POST',
    requestUrl: `/gpt/account/${accountId}/meeting-prep`,
    data: { accountId },
  });
  return data;
}

export async function getMeetingPrepSession({ accountId }: { accountId: string }) {
  const { data } = await doRequestWithAuth<MeetingPrepSession>({
    method: 'GET',
    requestUrl: `/gpt/account/${accountId}/meeting-prep`,
  });
  return data;
}

export async function getHouseholdMeetingPrep({ householdId }: { householdId: string }) {
  const { data } = await doRequestWithAuth<HouseholdMeetingPrepData[]>({
    method: 'GET',
    requestUrl: `/gpt/meeting-prep/${householdId}`,
  });
  return data;
}

export async function generateHouseholdMeetingPrep({ householdId }: { householdId: string }) {
  const { data } = await doRequestWithAuth<GPTJob>({
    method: 'POST',
    requestUrl: `/gpt/meeting-prep/${householdId}`,
    data: { householdId },
  });
  return data;
}
