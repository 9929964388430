import {
  Box,
  Button,
  Card,
  Grid,
  Link,
  Tab,
  Tabs,
  ToggleButton as ToggleButtonBase,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import { tokens } from '@vise_inc/ds-vise';
import { addYears, format, parseISO } from 'date-fns';
import { first, keyBy, last } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link as RouterDOMLink, useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { PCE2SpecificPortfolioIntelligenceFull, PortfolioInsightsResponse } from 'vise-types/pce2';
import { AssetClass } from 'vise-types/pce2_instrument';
import { Account } from 'vise-types/portfolio';
import { PortfolioMetricsResponse } from 'vise-types/portfolio_metrics';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAccountState from '~/hooks/useAccountState';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useProposalStatus from '~/hooks/usePortfolioStatus';
import {
  AdjustedSimulation,
  CapitalGains,
  GetPortfolioIntelligenceOrdersResponse,
} from '~/models/api';
import { ReactComponent as ArrowDownIcon } from '~/static/images/icons/arrow-down.svg';
import { ReactComponent as ArrowRightIcon } from '~/static/images/icons/arrow-right.svg';
import { ReactComponent as ArrowUpIcon } from '~/static/images/icons/arrow-up.svg';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import Banner from '~/synth/Banner';
import CardContent from '~/synth/CardContent';
import CardFooter from '~/synth/CardFooter';
import CardHeader from '~/synth/CardHeader';
import { DataTable } from '~/synth/DataTable';
import Metric from '~/synth/Metric';
import Skeleton from '~/synth/Skeleton';
import UnderlineTooltipIndicator from '~/synth/UnderlineTooltipIndicator';
import ViseBadge from '~/synth/ViseBadge';
import amplitude from '~/utils/amplitude';
import { formatCurrency, formatPercent } from '~/utils/format';
import { getAssetClassKeyFromFeatures } from '~/utils/pce2Migration';
import DateRangeSelector from '../../components/chart/DateRangeSelector';
import ProposalUnacceptConfirmationModal from '../Households/modal/UnacceptProposalConfirmationModal';
import { ASSET_CLASS_TO_LABEL_MAP } from '../PortfolioCreator2/Constants';
import { PendingOrderBanner } from './OrderNotifications';
import PortfolioInsights, { UnavailabilityReason, ViewDetailsLink } from './PortfolioInsights';
import { FlexGrowCard, StretchGrid } from './components/FlexGridCard';
import PortfolioAccountValueChart from './components/PortfolioAccountValueCard';
import PortfolioMonteCarloChart from './components/PortfolioMonteCarloChart';
import { RightAlignBox } from './components/TableComponents';
import TaxManagementCard from './components/TaxManagementCard';
import { MetricTableRow, riskToFocus, transformPCE2MonteCarloResults } from './portfolioUtil';

export const OverviewLoadingState = () => {
  return (
    <>
      <Grid container spacing={2}>
        {[0, 1, 2].map((n) => {
          return (
            <StretchGrid item md={12} lg={4} key={n}>
              <FlexGrowCard>
                <CardContent>
                  <Skeleton width="80%">
                    <Metric metric={0} label="Loading" size="large" />
                  </Skeleton>
                  <Box height="5.5em" />
                </CardContent>
              </FlexGrowCard>
            </StretchGrid>
          );
        })}
      </Grid>
      <Box my={2.5}>
        <Card>
          <CardHeader>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Skeleton variant="text" width="30%" />
              <Skeleton variant="text" width="30%" />
            </Box>
          </CardHeader>
          <CardContent>
            <Skeleton width="100%" height="40em" variant="chart" />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export type AccountWithGainsInfo = Account & { gains: CapitalGains };

const MARKET_CAP_HELPER_TEXT =
  'Market Capitalization is the value of a company. Smaller market cap companies tend to have higher expected returns.';

const DIVIDEND_YIELD_HELPER_TEXT =
  'This yield is a ratio (dividend/price) that shows how much a company pays out in dividends each year relative to its stock price.';

const PRICE_TO_EARNINGS_HELPER_TEXT =
  "Price-to-Earnings is the ratio of a company's share price to the company's earnings per share.";

const PRICE_TO_BOOK_HELPER_TEXT =
  'A Vise-specific measure of the relative price of a company. It adjusts the book value of equity to include intangible assets improving accuracy. Lower values tend to have higher expected returns.';

const PROFITABILITY_HELPER_TEXT =
  'The ratio between a mid-income-statement measure of company profits divided by the company’s intangible-adjusted book value of equity. Higher profitability correlates to higher expected returns.';

const METRIC_TO_HELPER = {
  'Market cap': MARKET_CAP_HELPER_TEXT,
  'Dividend yield': DIVIDEND_YIELD_HELPER_TEXT,
  'P/E': PRICE_TO_EARNINGS_HELPER_TEXT,
  'P/B +': PRICE_TO_BOOK_HELPER_TEXT,
  Profitability: PROFITABILITY_HELPER_TEXT,
} as const;

const CHART_TAB_EVENT_NAME = {
  'account-value-tab': 'Action - Tap Performance',
  'performance-tab': 'Action - Tap Monte Carlo',
  'monte-carlo-tab': 'Action - Tap Account Value',
};

const ToggleButton = withStyles(ToggleButtonBase, () => ({
  root: {
    height: 'auto',
  },
}));

function MetricCard({
  mtdRorFraction,
  ytdRorFraction,
  itdRorFraction,
  showPerformance,
  updatedAt,
}: {
  mtdRorFraction?: number;
  ytdRorFraction?: number;
  itdRorFraction?: number;
  showPerformance: boolean;
  updatedAt?: Date;
}) {
  const theme = useTheme();

  const [cardSettings, setCardSettings] = useState<{
    timePeriod: 'mtd' | 'ytd' | 'qtd' | 'itd';
    rawMetric: number | undefined;
  }>({
    timePeriod: 'mtd',
    rawMetric: mtdRorFraction,
  });

  let metric: React.ReactNode;
  if (!showPerformance) {
    metric = (
      <Tooltip title="Performance reporting is currently under construction. Please reach out to clientservice@vise.com for ad hoc performance reporting needs. We apologize for the inconvenience.">
        <UnderlineTooltipIndicator color="grey.400" variant="h2">
          Unavailable
        </UnderlineTooltipIndicator>
      </Tooltip>
    );
  } else {
    metric = formatPercent(cardSettings.rawMetric);
  }

  return (
    <FlexGrowCard>
      <CardContent>
        <Box mb={0.25}>
          <Metric
            metric={metric}
            label={
              <Box display="flex" width="100%" justifyContent="space-between">
                <Box>
                  {/* This shouldn't happen, mostly just to appease types */}
                  Net return as of{' '}
                  {updatedAt == null ? 'N/A' : format(new Date(updatedAt), 'MMM d, yyyy')}
                </Box>
                <Tooltip
                  placement="top"
                  title="All performance figures displayed are in terms of total time weighted returns, which compensates for any deposits/withdrawals made in the account and provides a more accurate representation of performance."
                >
                  <div>
                    <InformationCircleIcon />
                  </div>
                </Tooltip>
              </Box>
            }
            size="large"
          />
        </Box>
      </CardContent>
      {showPerformance && (
        <CardFooter bgcolor="grey.100">
          <ToggleButtonGroup
            exclusive
            value={cardSettings.timePeriod}
            onChange={(_e, newValue: 'mtd' | 'ytd' | 'itd') => {
              if (newValue !== null) {
                let newMetric: number | undefined;
                if (newValue === 'mtd') {
                  newMetric = mtdRorFraction;
                } else if (newValue === 'ytd') {
                  newMetric = ytdRorFraction;
                } else {
                  newMetric = itdRorFraction;
                }
                setCardSettings({ timePeriod: newValue, rawMetric: newMetric });
              }
            }}
            size="small"
            // Negative margin to counteract the left padding on the toggle button group,
            // which causes misalignment with the metric.
            style={{ marginLeft: theme.spacing(-1) }}
          >
            <ToggleButton value="mtd">MTD</ToggleButton>
            <ToggleButton value="ytd">YTD</ToggleButton>
            <ToggleButton value="itd">ITD</ToggleButton>
          </ToggleButtonGroup>
        </CardFooter>
      )}
    </FlexGrowCard>
  );
}

function PortfolioFocusCard2({ allocationLink, risk }: { allocationLink: string; risk: number }) {
  return (
    <FlexGrowCard>
      <CardContent>
        <Metric
          size="large"
          label="Focus"
          subtext={riskToFocus(risk)[1]}
          metric={riskToFocus(risk)[0]}
        />
      </CardContent>
      <CardFooter bgcolor="grey.100">
        <Link to={allocationLink} component={RouterDOMLink} color="textPrimary">
          <Box display="flex" alignItems="center">
            <Typography variant="h5" component="p">
              View allocation
            </Typography>
            <Box ml={1} display="flex" alignItems="center">
              <ArrowRightIcon />
            </Box>
          </Box>
        </Link>
      </CardFooter>
    </FlexGrowCard>
  );
}

export function MetricRow({
  name,
  metric,
  isLast = false,
}: {
  name: React.ReactNode;
  metric: React.ReactNode;
  isLast?: boolean;
}) {
  return (
    <CardContent
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderBottom={isLast ? 0 : 1}
      flexGrow={0}
      py={2}
    >
      <Box color="grey.600">{name}</Box>
      <Typography variant="body2">{metric}</Typography>
    </CardContent>
  );
}

export function MetricName({ metricName }: { metricName: string }) {
  return (
    <Tooltip title={METRIC_TO_HELPER[metricName]}>
      <UnderlineTooltipIndicator>{metricName}</UnderlineTooltipIndicator>
    </Tooltip>
  );
}

export function KeyMetricsTableCard({
  strategies,
  data,
  fullWidth = false,
}: {
  strategies: AssetClass[];
  data: MetricTableRow[];
  fullWidth?: boolean;
}) {
  const width = fullWidth ? '20%' : '15%';
  const developedLargeCapWidth = fullWidth ? '20%' : '30%';
  const sortedStrategies = React.useMemo(() => {
    function sortBy(a: AssetClass, b: AssetClass) {
      if (a[1] === b[1]) {
        return a[2] < b[2] ? -1 : 1;
      }
      return a[1] < b[1] ? 1 : -1;
    }
    return strategies.sort(sortBy);
  }, [strategies]);
  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Metrics',
          accessor: 'metric',
          Cell: (props: { value: string }) => <MetricName metricName={props.value} />,
        },
        ...sortedStrategies.map((strategy) => ({
          Header:
            strategy[1] === 'DEVELOPED'
              ? 'Dev. large cap (ex U.S.)'
              : ASSET_CLASS_TO_LABEL_MAP.get(strategy[2]),
          accessor: getAssetClassKeyFromFeatures(strategy),
          Cell: (props: { value: string | number }) => <RightAlignBox>{props.value}</RightAlignBox>,
          align: 'right',
          width: strategy[1] === 'DEVELOPED' ? developedLargeCapWidth : width,
        })),
      ] as Column<MetricTableRow>[],
    [developedLargeCapWidth, sortedStrategies, width]
  );
  return (
    <FlexGrowCard>
      <CardHeader display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <ViseBadge mr={1.5} />
          <Typography variant="h4">Vise single stock strategy</Typography>
        </Box>
        <Typography variant="body1" color="textSecondary">
          Value-weighted
        </Typography>
      </CardHeader>
      <DataTable headerSize="small" columns={columns} data={data} />
    </FlexGrowCard>
  );
}

function PerformanceCard({
  account,
  monteCarloData,
}: {
  account: Account;
  monteCarloData: AdjustedSimulation[];
}) {
  const [tabValue, setTabValue] = React.useState<
    'account-value-tab' | 'performance-tab' | 'monte-carlo-tab'
  >('account-value-tab');
  let currentChartFirstAvailableDate: moment.MomentInput | null;
  let currentChartLastAvailableDate: moment.MomentInput | null;
  if (tabValue === 'monte-carlo-tab') {
    currentChartFirstAvailableDate = Math.min(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      ...monteCarloData.map((mc) => first(mc.configuredData)?.[0])
    );
    currentChartLastAvailableDate = Math.max(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      ...monteCarloData.map((mc) => last(mc.configuredData)?.[0])
    );
  } else {
    currentChartFirstAvailableDate =
      account.summary?.length > 0 ? first(account.summary)?.date : null;
    currentChartLastAvailableDate =
      account.summary?.length > 0 ? last(account.summary)?.date : null;
  }
  const [chartRangeOptions, setChartRangeOptions] = React.useState<{
    min: number | undefined;
    max: number | undefined;
  }>({
    min: undefined,
    max: undefined,
  });
  const handleChange = (
    _event: React.ChangeEvent<{}>,
    newValue: 'account-value-tab' | 'performance-tab' | 'monte-carlo-tab'
  ) => {
    amplitude().logEvent(CHART_TAB_EVENT_NAME[newValue], {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
    setTabValue(newValue);
  };

  let content: React.ReactNode;
  if (tabValue === 'performance-tab') {
    content = <></>;
  } else if (tabValue === 'account-value-tab') {
    content = (
      <PortfolioAccountValueChart
        accountSummary={account.summary}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        minDate={chartRangeOptions.min}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        maxDate={chartRangeOptions.max}
      />
    );
  } else {
    content = <PortfolioMonteCarloChart datas={monteCarloData} />;
  }

  return (
    <Card>
      <Box
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        border={1}
        borderLeft={0}
        borderRight={0}
        borderTop={0}
        borderColor="grey.200"
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="account performance charts options"
        >
          <Tab
            label="Account value"
            value="account-value-tab"
            id="account-value-tab"
            aria-controls="account-value-tab"
          />

          <Tab
            label="Projected value"
            value="monte-carlo-tab"
            id="monte-carlo-tab"
            aria-controls="monte-carlo-tab"
          />
          <Tooltip title="Performance reporting is currently under construction. Please reach out to clientservice@vise.com for ad hoc performance reporting needs. We apologize for the inconvenience.">
            <span>
              <Tab label="Performance" disabled />
            </span>
          </Tooltip>
        </Tabs>
        <DateRangeSelector
          initialRange="all time"
          firstAvailableDate={currentChartFirstAvailableDate}
          lastAvailableDate={currentChartLastAvailableDate}
          disabled={account.summary?.length === 0 || tabValue === 'monte-carlo-tab'}
          onChangeRange={(range, presetUsed) => {
            const tabName = tabValue.split('-').slice(0, -1).join(' ');
            if (presetUsed) {
              amplitude().logEvent('Action - select date range', {
                category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
                range: presetUsed,
                chartType: tabName,
              });
            } else {
              amplitude().logEvent('Action - submit custom date range', {
                category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
                chartType: tabName,
              });
            }
            setChartRangeOptions({
              min: range.min == null ? undefined : range.min.valueOf(),
              max: range.max == null ? undefined : range.max.valueOf(),
            });
          }}
        />
      </Box>
      {content}
    </Card>
  );
}
interface PortfolioOverview2Props {
  intelligence: PortfolioIntelligenceFull;
  account: AccountWithGainsInfo;
  orders: GetPortfolioIntelligenceOrdersResponse | undefined;
  pce2Metrics: PortfolioMetricsResponse;
  portfolioInsights?: PortfolioInsightsResponse;
  portfolioInsightsUnavailabilityReason?: UnavailabilityReason;
}

function getAccountValueChange(summary: AccountWithGainsInfo['summary']): {
  changeInValue: number;
  percentageChange: number;
  outdatedValue: boolean;
  asOf: number;
} | null {
  // Need at least 3 days of data to show change in value. Technically 2 days of data is enough, but if the
  // change in value in the first 2 days is > 10% it will error.
  if (summary.length < 3) {
    return null;
  }
  let latestMarketValue = summary[summary.length - 1];
  let latestMinusOneMarketValue = summary[summary.length - 2];
  let outdatedValue = false;
  // Show % change of previous day if the account value has changed by 10% or more in the last day.
  // Account value changes of 10% are not common due to performance, but can still occur due to
  // inflow or outflows of cash (distributions, net deposits, etc)
  if (
    Math.abs(
      (latestMarketValue.marketValue - latestMinusOneMarketValue.marketValue) /
        latestMinusOneMarketValue?.marketValue
    ) > 0.1
  ) {
    latestMarketValue = summary[summary.length - 2];
    latestMinusOneMarketValue = summary[summary.length - 3];
    outdatedValue = true;
  }

  const changeInValue = latestMarketValue?.marketValue - latestMinusOneMarketValue?.marketValue;
  return {
    changeInValue,
    percentageChange: changeInValue / latestMinusOneMarketValue.marketValue,
    outdatedValue,
    asOf: latestMarketValue.date,
  };
}

export default function PortfolioOverview2({
  account,
  intelligence,
  orders,
  pce2Metrics,
  portfolioInsights,
  portfolioInsightsUnavailabilityReason,
}: PortfolioOverview2Props) {
  useEffect(() => {
    amplitude().logEvent('Impression - Portfolio Overview', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
  }, []);

  const [showUnacceptConfirmation, setShowUnacceptConfirmation] = useState(false);
  const history = useHistory();

  if (intelligence.status === 'REJECTED' || intelligence.proposalType === 'light') {
    throw new Error('');
  }
  const { data: featureFlags } = useFeatureFlags();
  const showPerformance = featureFlags?.enable_performance_data === 'on';
  const enableUnacceptProposal = featureFlags?.enable_unaccept_proposal === 'on';

  const { data: accountStateData } = useAccountState(account?.id);
  const { data: proposalStatusData } = useProposalStatus(intelligence.id ? intelligence.id : null);

  const isLiquidationPending = accountStateData?.isLiquidationPending;
  const isRemovalPending = accountStateData?.isRemovalPending;
  const isProposalCancellable = proposalStatusData?.isCancellable;

  const { client } = intelligence;
  const years = intelligence.constructionRequest.assetClassAllocationRequest?.years;
  const timeHorizonStart =
    intelligence.constructionRequest.assetClassAllocationRequest?.timeHorizonStartTimestamp;

  const simulations = transformPCE2MonteCarloResults(
    pce2Metrics.strategyMetrics?.monteCarloResults ??
      (intelligence as PCE2SpecificPortfolioIntelligenceFull).constructionResponse.metrics
        .strategyMetrics.monteCarloResults,
    Number(intelligence.constructionInfo.initialValue),
    undefined,
    years && timeHorizonStart ? addYears(parseISO(timeHorizonStart), years) : undefined
  );

  const instrumentsBySymbol = React.useMemo(
    () => keyBy(orders?.instruments, 'symbol'),
    [orders?.instruments]
  );

  const valueChangeReturn = getAccountValueChange(account.summary);
  return (
    <>
      {orders?.orders.map((order, index) => {
        if (order.status === 'PENDING' && order.items && order.items?.length > 0) {
          return (
            <Box key={order.id} mt={index === 0 ? undefined : 2} pb={2.5}>
              <PendingOrderBanner instrumentsBySymbol={instrumentsBySymbol} order={order} />
            </Box>
          );
        }
        return null;
      })}

      {isLiquidationPending && (
        <Box mb={3}>
          <Banner
            size="small"
            bgColor="warning.100"
            borderColor="warning.400"
            message={
              <Box>
                <Typography variant="h4">
                  <Box display="flex" alignItems="center">
                    <InformationCircleIcon width={20} height={20} />
                    <Box ml={1.5}>Liquidation request submitted</Box>
                  </Box>
                </Typography>
                <Box mt={1.5} ml={4} color="grey.700">
                  Your liquidation request has been submitted and the rebalancer has been paused.
                  Liquidation requests received after 2 PM EST/EDT may not be processed until the
                  following business day. Client Service team has been notified. A team member will
                  reach out to you within one business day.
                </Box>
              </Box>
            }
          />
        </Box>
      )}
      {isRemovalPending && (
        <Box mb={3}>
          <Banner
            bgColor="warning.100"
            borderColor="warning.400"
            size="small"
            message={
              <Box>
                <Typography variant="h4">
                  <Box display="flex" alignItems="center">
                    <InformationCircleIcon width={20} height={20} />
                    <Box ml={1.5}>Your account removal request has been submitted</Box>
                  </Box>
                </Typography>
                <Box mt={1.5} ml={4} color="grey.700">
                  Client Service team has been notified. A team member will reach out to you within
                  one business day.
                </Box>
              </Box>
            }
          />
        </Box>
      )}

      {showPerformance && (
        <Box mb={3}>
          <Banner
            size="small"
            message={
              <Box>
                <Typography variant="h4">
                  <Box display="flex" alignItems="center">
                    <InformationCircleIcon width={20} height={20} />
                    <Box ml={1.5}>Monthly performance has been enabled!</Box>
                  </Box>
                </Typography>
                <Box mt={1.5} ml={4} color="grey.700">
                  Vise will display time weighted returns periodically on a month over month basis.
                  Quarterly reports and additional performance reporting is available upon request.
                  Please reach out to clientservice@vise.com for more information.
                </Box>
              </Box>
            }
          />
        </Box>
      )}

      {enableUnacceptProposal && isProposalCancellable && (
        <Box mb={3}>
          <Banner
            size="small"
            bgColor="warning.100"
            borderColor="warning.400"
            message={
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h4" color="warning.400">
                    <InformationCircleIcon width={20} height={20} />
                  </Typography>
                  <Box ml={2} color="grey.700">
                    <Typography variant="body2">
                      Proposals may be unaccepted if the trading process has not begun on the
                      portfolio. Unaccepting the proposal will undo these changes made to the
                      portfolio. The most recently executed/active (and not unaccepted) proposal for
                      this account will now be the active proposal.
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => setShowUnacceptConfirmation(true)}
                    size="small"
                    type="button"
                    variant="text"
                    color="warning"
                  >
                    Unaccept
                  </Button>
                  <ProposalUnacceptConfirmationModal
                    proposal={intelligence}
                    accountId={account.id}
                    open={showUnacceptConfirmation}
                    onClose={() => setShowUnacceptConfirmation(false)}
                    onUnaccept={() => {
                      setShowUnacceptConfirmation(false);
                      history.push(`/secure/households/${client.clientGroupId}/overview`);
                    }}
                  />
                </Box>
              </Box>
            }
          />
        </Box>
      )}
      <Grid container spacing={2}>
        <StretchGrid item md={12} lg={4}>
          <FlexGrowCard>
            <CardContent>
              <Metric
                label="Total portfolio value"
                metric={formatCurrency(account.summary?.[account.summary.length - 1].marketValue)}
              />
              {/* TODO: better UX than just hiding for when there is not enough summary data */}
              {valueChangeReturn != null ? (
                <Box display="flex" mt={1}>
                  <Box
                    color={
                      valueChangeReturn.changeInValue > 0
                        ? tokens.palette.semanticGreen[600]
                        : tokens.palette.semanticRed[600]
                    }
                    display="flex"
                    alignItems="center"
                  >
                    {valueChangeReturn.changeInValue > 0 ? (
                      <ArrowUpIcon fontSize={16} />
                    ) : (
                      <ArrowDownIcon fontSize={16} />
                    )}
                    <Box fontSize={12} fontWeight={500} ml={0.5}>
                      {formatCurrency(valueChangeReturn.changeInValue)} (
                      {formatPercent(valueChangeReturn.percentageChange)}){' '}
                      <span style={{ color: tokens.palette.neutralCool[500] }}>
                        As of {format(new Date(valueChangeReturn.asOf), 'MMM d, yyyy')}
                      </span>
                    </Box>
                  </Box>

                  <Tooltip
                    title={
                      valueChangeReturn.outdatedValue
                        ? 'Account value changes are not available because of large cash flows that may have impacted this account. Please check again at a later time.'
                        : 'Changes in portfolio value reflect market values provided by your custodian including any cash flows, such as distributions or deposits. '
                    }
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      ml={0.5}
                      color={tokens.palette.neutralCool[500]}
                    >
                      <InformationCircleIcon />
                    </Box>
                  </Tooltip>
                </Box>
              ) : (
                <></>
              )}
            </CardContent>
          </FlexGrowCard>
        </StretchGrid>
        <StretchGrid item md={12} lg={4}>
          <MetricCard
            mtdRorFraction={account.performance?.mtd}
            ytdRorFraction={account.performance?.ytd}
            itdRorFraction={account.performance?.itd}
            showPerformance={showPerformance}
            updatedAt={account.performance?.asOf}
          />
        </StretchGrid>
        <StretchGrid item md={12} lg={4}>
          <PortfolioFocusCard2
            allocationLink={`/secure/accounts/${account.id}/portfolio/allocation`}
            risk={
              (intelligence as PCE2SpecificPortfolioIntelligenceFull).constructionResponse.risk || 0
            }
          />
        </StretchGrid>
      </Grid>
      <Box my={2.5}>
        <PerformanceCard monteCarloData={simulations} account={account} />
      </Box>
      <Grid container spacing={2}>
        {portfolioInsightsUnavailabilityReason !== 'UNKNOWN' && (
          <Grid item md={12}>
            <PortfolioInsights
              link={<ViewDetailsLink account={account} />}
              insights={portfolioInsights}
              unavailabilityReason={portfolioInsightsUnavailabilityReason}
              name={account.accountNameShort}
            />
          </Grid>
        )}
        {account.taxable && (
          <StretchGrid item md={12} lg={12}>
            <TaxManagementCard
              gains={account.gains}
              taxOptions={intelligence.constructionRequest.taxOptions}
              autoTlh={!!intelligence.constructionRequest.taxOptions?.autoTlh}
            />
          </StretchGrid>
        )}
      </Grid>
    </>
  );
}
