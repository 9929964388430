import useSWR from 'swr';
import { getMMFEligibility } from '~/api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useMMFEligibility(
  accountId?: string,
  lockedPositions?: string[],
  targetCashFraction?: number
) {
  return useSWR(
    accountId == null
      ? null
      : generateSwrKey(`/portfolio/accounts/mmf-eligibility?accountId=${accountId}${
          lockedPositions == null ? '' : `&lockedPositions=${lockedPositions}`
        }
      ${targetCashFraction == null ? '' : `&targetCashFraction=${targetCashFraction}`}`),
    accountId == null
      ? undefined
      : () => getMMFEligibility({ accountId, lockedPositions, targetCashFraction })
  );
}
