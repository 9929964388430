import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { FormikErrors, useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { ValueType } from 'react-select';
import { Position } from 'vise-types/portfolio';
import {
  deleteXrayPositions,
  insertXrayCashPosition,
  insertXrayPosition,
  updateXrayPositionTaxLots,
} from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import usePositions from '~/hooks/usePositions';
import { ReactComponent as PlusIcon } from '~/static/images/icons/plus.svg';
import TextField from '~/synth/TextField';
import amplitude from '~/utils/amplitude';
import ScreenHistoryContext from '../ScreenHistoryContext';
import { ScreenProps } from '../Types';
import { XrayDispatchContext } from '../context/XrayContext';
import { BackButton, ExplainerText, StickyBottomActionContainer } from './components';
import EditHoldingDetailsPanel from './components/EditHoldingDetailsPanel';
import HoldingsTable from './components/HoldingsTable';
import TickerSelector, { SelectedTickerOption, TickerOption } from './components/TickerSelector';

interface FormValues {
  ticker: string;
  quantity: number | string;
}

export default function ManualAddPositionsScreen({
  onBack,
  onContinue,
  draftPortfolio,
}: ScreenProps) {
  const {
    constructionInfo: { existingPortfolio },
  } = draftPortfolio;
  useEffect(() => {
    amplitude().logEvent('Impression - Manual add positions screen', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);
  const xrayDispatch = useContext(XrayDispatchContext);
  const screenHistory = useContext(ScreenHistoryContext);

  const enqueueCoachmark = useEnqueueCoachmark();
  const [isLoading, setIsLoading] = useState(false);
  const [currentEditPosition, setCurrentEditPosition] = useState<Position | null>(null);

  const { data: positionsData, mutate: mutatePositionsData } = usePositions(
    existingPortfolio != null && existingPortfolio !== 'sample-portfolio'
      ? existingPortfolio.accountNumber
      : null,
    existingPortfolio != null && existingPortfolio !== 'sample-portfolio'
      ? existingPortfolio.custodianKey
      : null,
    true /* includePCE2Instruments */,
    true /* includeTaxLots */
  );

  const formik = useFormik<FormValues>({
    initialValues: {
      ticker: '',
      quantity: '',
    },

    async onSubmit(values) {
      if (existingPortfolio === 'sample-portfolio' || existingPortfolio == null) {
        return;
      }
      amplitude().logEvent('Add manual position', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      });
      try {
        setIsLoading(true);
        await insertXrayPosition({
          accountId: existingPortfolio.id,
          symbolOrCusip: values.ticker,
          quantity:
            typeof values.quantity === 'string' ? parseInt(values.quantity, 10) : values.quantity,
        });
        mutatePositionsData();
      } catch (e) {
        const axiosError = e as AxiosError<{ msg: string; details: string }>;
        if (axiosError.code === AxiosError.ERR_BAD_REQUEST && axiosError.response != null) {
          const { details: errorDetails } = axiosError.response.data;
          // details will contain GRPC number. split on :
          // 3 INVALID_ARGUMENT: Attempted to add a position that exists already.
          const detailsWithoutCode = errorDetails.split(':');
          const details = detailsWithoutCode.length > 1 ? detailsWithoutCode[1] : '';
          enqueueCoachmark({
            title: `There was an error adding the position: ${values.ticker}`,
            content: details,
            severity: 'error',
          });
        } else {
          enqueueCoachmark({
            title: `There was an error adding the position: ${values.ticker}`,
            severity: 'error',
          });
        }
      }
      setIsLoading(false);
    },

    validate(values) {
      const errors: FormikErrors<FormValues> = {};
      if (values.quantity != null && Number(values.quantity) < 1)
        errors.quantity = 'Quantity must be > 0';
      if (!values.ticker) errors.ticker = 'Please select a ticker';
      return errors;
    },
  });

  const handlePositionUpdate = async (position: Position) => {
    if (existingPortfolio === 'sample-portfolio' || !existingPortfolio) return;
    setIsLoading(true);

    if (position.securityType === 'CASH_OR_EQIV') {
      try {
        await insertXrayCashPosition({
          cashAmount: position.quantity,
          accountId: existingPortfolio.id,
        });
        setCurrentEditPosition(null);
        mutatePositionsData();
        xrayDispatch({
          type: 'SET_XRAY_CASH_AMOUNT',
          cashAmount: position.quantity,
        });
      } catch (e) {
        enqueueCoachmark({
          title: `There was an error updating the cash amount.`,
          severity: 'error',
        });
      }
      setIsLoading(false);
      return;
    }

    try {
      await updateXrayPositionTaxLots({
        accountId: existingPortfolio.id,
        position,
      });
      setCurrentEditPosition(null);
      mutatePositionsData();
    } catch (e) {
      enqueueCoachmark({
        title: `There was an error updating the position: ${position.symbolOrCusip}`,
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  const handleDeletePosition = async (position: Position) => {
    if (existingPortfolio === 'sample-portfolio' || existingPortfolio == null) return;
    amplitude().logEvent('Click delete csv position', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
    setIsLoading(true);
    try {
      await deleteXrayPositions({
        accountId: existingPortfolio.id,
        tickers: [position.symbolOrCusip],
      });
      mutatePositionsData();
    } catch (e) {
      enqueueCoachmark({
        title: `There was an error deleting the position: ${position.symbolOrCusip}`,
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Box px={8.75} py={4} height="100%">
        <Box width={743} mb={3}>
          <Box mb={2}>
            <Typography variant="h1">Add your client&apos;s holdings below.</Typography>
          </Box>
          <ExplainerText mb={2}>
            Please note that tax management will be omitted from proposals without complete cost
            basis inputs.
          </ExplainerText>
          <form onSubmit={formik.handleSubmit}>
            <Box display="flex" alignItems="end">
              <Box width="60%">
                <TickerSelector
                  value={formik.values.ticker}
                  onChange={(selectedOption: ValueType<TickerOption>) => {
                    const selectedTicker = selectedOption as SelectedTickerOption;
                    formik.setFieldValue('ticker', selectedTicker?.value);
                  }}
                />
              </Box>
              <Box width="60%" mx={1.5}>
                <TextField
                  label="Quantity"
                  placeholder="ex. 10"
                  fullWidth
                  id="quantity"
                  name="quantity"
                  value={formik.values.quantity}
                  type="number"
                  onChange={formik.handleChange}
                  error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                />
              </Box>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  startIcon={
                    isLoading ? <CircularProgress size="1em" color="inherit" /> : <PlusIcon />
                  }
                  disabled={isLoading}
                >
                  Add
                </Button>
              </div>
            </Box>
          </form>
        </Box>
        <HoldingsTable
          data={positionsData?.positions || []}
          onEdit={(position) => {
            amplitude().logEvent('Click edit csv position', {
              category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
            });
            setCurrentEditPosition(position);
          }}
          onDelete={(position) => handleDeletePosition(position)}
        />
      </Box>
      {currentEditPosition && (
        <EditHoldingDetailsPanel
          open={Boolean(currentEditPosition)}
          position={currentEditPosition}
          onChange={handlePositionUpdate}
          onClose={() => {
            setCurrentEditPosition(null);
          }}
          isLoading={isLoading}
        />
      )}
      <StickyBottomActionContainer>
        <Box display="flex" justifyContent="space-between">
          <BackButton onClick={() => onBack(screenHistory[screenHistory.length - 2])} />
          <Button
            color="primary"
            variant="contained"
            disabled={isLoading || (positionsData && positionsData?.positions.length < 1)}
            onClick={(event) => {
              event.preventDefault();
              onContinue();
            }}
          >
            Continue
          </Button>
        </Box>
      </StickyBottomActionContainer>
    </>
  );
}
