import { Breadcrumbs, Link, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const TRUNCATION_LENGTH = 30;

const useStyles = makeStyles()((theme) => ({
  root: {
    '&.MuiTypography-colorTextSecondary': {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

function StyledLink({ to, children }: { to: string; children: React.ReactNode }) {
  const { classes: styles } = useStyles();
  return (
    <Link
      underline="none"
      color="textSecondary"
      to={to}
      component={RouterLink}
      className={styles.root}
    >
      {children}
    </Link>
  );
}

export interface BreadcrumbSegmentInfo {
  name: string;
  to?: string;
}

function BreadCrumbSegment({ segmentInfo }: { segmentInfo: BreadcrumbSegmentInfo }) {
  return segmentInfo.name.length > TRUNCATION_LENGTH ? (
    <Tooltip title={segmentInfo.name}>
      <StyledLink to={segmentInfo.to ?? '/'}>
        {segmentInfo.name.slice(0, TRUNCATION_LENGTH - 1)}…
      </StyledLink>
    </Tooltip>
  ) : (
    <StyledLink to={segmentInfo.to ?? '/'}>{segmentInfo.name}</StyledLink>
  );
}

function PathBreadcrumbs({
  path,
  ariaLabel,
}: {
  path: BreadcrumbSegmentInfo[];
  ariaLabel?: string;
}) {
  return (
    <Breadcrumbs aria-label={ariaLabel}>
      {path.slice(0, -1).map((segment) => (
        <BreadCrumbSegment key={segment.name} segmentInfo={segment} />
      ))}
      <Typography variant="body1" color="textPrimary">
        {path[path.length - 1].name}
      </Typography>
    </Breadcrumbs>
  );
}

export default PathBreadcrumbs;
