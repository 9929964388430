import useSWR from 'swr';
import { getUserClients } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useClients(key: string | null | undefined = 'client/v2/clients') {
  const { data, error, isValidating, mutate } = useSWR(
    key == null ? null : generateSwrKey(key),
    () => getUserClients()
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
}
