"use client";
import { SvgIcon, type SvgIconProps } from "@mui/material";
import React from "react";

/**
 * X-Icon for closing dialogs. Note that you should use HTMLColor to style the color. Font-size should be one of small, medium, large, which correspond to 16, 20 and 24 px size Icons
 * @param props MUI SVGIcon props
 * @returns
 */
export default function XIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 5L15 15M5 15L15 5L5 15Z"
          stroke="currentcolor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
