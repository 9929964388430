import { Box, Collapse, CollapseProps, Divider, Grid, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { tokens } from '@vise_inc/ds-vise';
import { addYears, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { AlphaBackTestsResponse } from 'vise-types/xray';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useIsEtfOnlyProposal from '~/hooks/useIsEtfOnlyProposal';
import useTaxAlphaBacktests from '~/hooks/useTaxAlphaBacktests';
import { CapitalGains } from '~/models/api';
import { ReactComponent as CurrencyDollar } from '~/static/images/icons/currency-dollar.svg';
import Skeleton from '~/synth/Skeleton';
import { formatApproximate, formatCurrency, formatPercent } from '~/utils/format';
import PortfolioMonteCarloChart from '../components/PortfolioMonteCarloChart';
import { transformPCE2MonteCarloResults } from '../portfolioUtil';
import TaxScenarios from './components/TaxScenarios';
import { SectionSubtitle, SectionTitle } from './components/UtilComponents';

function TaxExtrapolation({
  intelligence,
  taxAlpha,
}: {
  intelligence: PortfolioIntelligenceFull;
  taxAlpha: AlphaBackTestsResponse | undefined;
}) {
  if (intelligence.proposalType === 'light') {
    throw new Error(`Portfolio intelligence should be PCE2 and FULL. (id: ${intelligence.id})`);
  }

  const {
    constructionRequest: { assetClassAllocationRequest },
    constructionResponse: {
      metrics: {
        strategyMetrics: { monteCarloResults },
      },
    },
  } = intelligence;
  const years = assetClassAllocationRequest?.years;
  const timeHorizonStart = assetClassAllocationRequest?.timeHorizonStartTimestamp;
  // Only show 50th percentile projection
  const filteredMonteCarloResults = monteCarloResults.filter((d) => d.percentile === 50);
  const simulations =
    taxAlpha == null
      ? null
      : transformPCE2MonteCarloResults(
          filteredMonteCarloResults,
          -taxAlpha.totalTaxSavingsDollar,
          undefined,
          years && timeHorizonStart ? addYears(parseISO(timeHorizonStart), years) : undefined
        );

  return (
    <Box mb={5}>
      <Box fontWeight={500} mb={1}>
        Growth of year 1 tax savings
      </Box>
      {simulations == null ? (
        <Skeleton variant="chart" width="100%" height="20em" />
      ) : (
        <PortfolioMonteCarloChart datas={simulations} />
      )}
      <Box mt={2}>
        <b>What is the future value of these tax savings?</b>
        <Box mt={1.5} color={tokens.palette.neutralCool[600]}>
          {taxAlpha == null ? (
            <Skeleton width="100%" height="5em" variant="rect" />
          ) : (
            <>
              Using Vise&apos;s tax loss harvesting capabilities will allow you to keep more money
              in the market and let it work for you. If your{' '}
              {formatCurrency(-taxAlpha.totalTaxSavingsDollar)} in net savings remains invested in
              the market, Vise estimates a{' '}
              <span style={{ color: `${tokens.palette.primaryBlue[400]}` }}>
                <b>
                  {formatPercent(
                    -(taxAlpha.aggregateShortTermTaxGains + taxAlpha.aggregateLongTermTaxGains),
                    1
                  )}
                </b>
              </span>{' '}
              annual benefit from tax loss harvesting on average.
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

const TaxAlphaSection = ({ taxAlpha }: { taxAlpha: AlphaBackTestsResponse | undefined }) => {
  return (
    <Box mt={3} mb={5}>
      <SectionSubtitle title="Benefits of Vise Tax Loss Harvesting" id="benefits-of-tlh" mb={4} />
      <Box color={tokens.palette.neutralCool[600]} mb={4}>
        Vise&apos;s automated tax loss harvesting algorithm could provide significant after-tax
        benefits for your client&apos;s account. Based on historical simulations:
      </Box>

      <Grid container>
        {taxAlpha == null ? (
          <Skeleton width="100%" height="20em" variant="rect" />
        ) : (
          <>
            <Grid
              item
              xs={6}
              alignSelf="center"
              /* This might not be well supported by older browsers */
              sx={{
                backgroundImage: 'linear-gradient(115.94deg, #5F1543 27.25%, #1753DE 81.96%)',
                backgroundSize: '100%',
                backgroundClip: 'text',
              }}
            >
              <Box height="100%">
                <Typography variant="h5" mb={4}>
                  <Box display="flex" alignItems="center">
                    <CurrencyDollar /> <Box ml={1}>Estimated Total Tax Savings per year</Box>
                  </Box>
                </Typography>

                <Typography
                  variant="h1"
                  sx={{
                    WebkitTextFillColor: 'white',
                    WebkitTextStrokeColor: 'transparent',
                    fontSize: '6rem',
                    fontWeight: '500',
                    WebkitTextStrokeWidth: '4px',
                  }}
                >
                  {taxAlpha.totalTaxSavingsDollar === 0
                    ? formatApproximate(0)
                    : formatApproximate(-1 * taxAlpha.totalTaxSavingsDollar)}
                </Typography>
                <Box mt={5} color="grey.600" fontStyle="italic" fontSize={10}>
                  Estimated total tax savings is represented as a numeric dollar amount of estimated
                  tax savings.
                </Box>
              </Box>
            </Grid>
            <Grid sm={6} item alignItems="flex-start">
              <Typography variant="h5">
                <Box display="flex" alignItems="center">
                  <CurrencyDollar /> <Box ml={1}>Annual after-tax benefit</Box>
                </Box>
              </Typography>
              <Box
                mt={4}
                width={128}
                height={128}
                borderRadius="50%"
                bgcolor="primary.main"
                color="white"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h2">
                  {-(taxAlpha.aggregateShortTermTaxGains + taxAlpha.aggregateLongTermTaxGains) > 0
                    ? '+ '
                    : ''}
                  {formatPercent(
                    -(taxAlpha.aggregateShortTermTaxGains + taxAlpha.aggregateLongTermTaxGains),
                    1
                  )}
                </Typography>
              </Box>

              <Box color="grey.600" fontStyle="italic" fontSize={10} mt={4}>
                Estimates are based on historical simulations of tax losses harvested in a similar
                Vise model portfolio between 2000 to 2023.
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      <Typography mb={1.5} variant="h4" mt={5}>
        What is the impact of tax loss harvesting by Vise?
      </Typography>
      <Box color={tokens.palette.neutralCool[600]}>
        Investing in single securities gives Vise an edge in tax loss harvesting compared to
        directly investing in traditional mutual funds and ETF&apos;s.
        <Box my={2}>
          By seeking tax-loss harvesting opportunities daily, Vise could have added add up to XX bps
          to the after-tax return of your single security strategy over the last year.
        </Box>
        The after-tax benefit assumes that harvested losses are used to offset gains and/or taxable
        income up to IRS limits, allowing your capital to stay invested and compound for longer.
      </Box>
    </Box>
  );
};

export default function TaxBenefitsSection({
  gains,
  intelligence,
  collapseProps,
  onClick,
}: {
  gains: CapitalGains;
  intelligence: PortfolioIntelligenceFull;
  collapseProps?: CollapseProps;
  onClick: () => void;
}) {
  const { data: taxAlphaData, error: taxAlphaError } = useTaxAlphaBacktests(intelligence.id);
  const { data: isEtfOnlyProposalResponse } = useIsEtfOnlyProposal({ proposalId: intelligence.id });
  const isEtfOnly = Boolean(isEtfOnlyProposalResponse?.data);

  const enqueueCoachmark = useEnqueueCoachmark();

  // Error capturing
  if (taxAlphaError) {
    enqueueCoachmark({
      title: 'There was an error loading the tax loss harvesting benefits.',
      severity: 'error',
    });
  }

  useEffect(() => {
    if (taxAlphaError != null) {
      Sentry.captureException(
        new Error(`[TaxAlphaSection] Failed to load tax alpha: ${taxAlphaError}.`),
        {
          extra: {
            intelligenceId: intelligence.id,
          },
        }
      );
    }
  }, [taxAlphaError, intelligence.id]);

  return (
    <>
      <SectionTitle
        title="Tax Benefits & Transition Analysis"
        subtext=" Vise offers a best-in-class tax management experience that leverages technology to
              look at your client's portfolio daily. Continuous tax loss harvesting can
              generate significant tax benefits even in up-markets and produces better outcomes
              consistently than fixed-schedule tax loss harvesting."
        onClick={onClick}
        collapseProps={collapseProps}
        id="automated-tlh"
      />

      <Collapse {...collapseProps}>
        {!isEtfOnly ? (
          <>
            <TaxAlphaSection taxAlpha={taxAlphaData?.data} />
            <TaxExtrapolation taxAlpha={taxAlphaData?.data} intelligence={intelligence} />
            <Divider />
          </>
        ) : null}
        <TaxScenarios intelligence={intelligence} gains={gains} />
      </Collapse>
    </>
  );
}
