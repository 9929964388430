import { RUN_SOURCE } from 'vise-types/orders';
import { OptimizerMode } from 'vise-types/pce2';
import { Sector } from 'vise-types/pce2_instrument';

const HIGH_TURNOVER_THRESHHOLD = 0.1;
const MED_TURNOVER_THRESHHOLD = 0.03;

type CATEGORY =
  | 'ASSET_CLASS'
  | 'TAX_LOSS_HARVESTING'
  | 'SINGLE_STOCK'
  | 'ETF'
  | 'CASH'
  | 'CASH_DISTRIBUTION'
  | 'INITIAL_TRADES'
  | 'ASSET_CLASS_COMPLIANCE'
  | 'CASH_COMPLIANCE'
  | 'NEW_TARGET_CASH';

export type INITIATOR = 'VISE' | 'ADVISOR';
export type CATALYST = 'REBALANCE' | 'CONSTRAINTS' | 'CASH_RAISE' | 'INITIAL_TRADES';
export type TURNOVER_SEVERITY = 'HIGH' | 'MED' | 'LOW';

export interface Trade {
  ticker: string;
  name: string;
  sector: Sector[] | null;
  quantity: number;
  price: number | null;
  totalValue: number | null;
}

export const CATEGORY_TO_LABEL = {
  ASSET_CLASS: 'Asset allocation',
  TAX_LOSS_HARVESTING: 'Tax loss harvesting',
  SINGLE_STOCK: 'Single-securities allocation',
  ETF: 'ETF allocation',
  CASH: 'Cash allocation',
  CASH_DISTRIBUTION: 'Cash distribution',
  INITIAL_TRADES: 'Portfolio transition',
  ASSET_CLASS_COMPLIANCE: 'Asset allocation',
  CASH_COMPLIANCE: 'Cash allocation',
  NEW_TARGET_CASH: 'Cash allocation',
} as { [key in CATEGORY]: string };

export const PRIMARY_OBJECTIVE_TO_LABEL = {
  ASSET_CLASS: 'Asset class improvements',
  TAX_LOSS_HARVESTING: 'Tax loss harvesting',
  SINGLE_STOCK: 'Single-security improvements',
  ETF: 'ETF allocation improvements',
  CASH: 'Cash target improvements',
  CASH_DISTRIBUTION: 'Cash distribution',
  INITIAL_TRADES: 'Transition portfolio to target',
  ASSET_CLASS_COMPLIANCE: 'Asset class concentration limits',
  CASH_COMPLIANCE: 'Target cash allocation',
  NEW_TARGET_CASH: 'New target cash allocation',
} as { [key in CATEGORY]: string };

export const MODE_TO_CATALYST = {
  DEFAULT: 'REBALANCE',
  BUY_ONLY: 'CONSTRAINTS',
  SELL_ONLY: 'CONSTRAINTS',
  COMPLIANCE: 'CONSTRAINTS',
  RAISE_CASH: 'CASH_RAISE',
  TRANSITION: 'INITIAL_TRADES',
  // DO_NOT_HOLD is deprecated so it's not included here

  // VANILLA should only be used for the backtest so it shouldn't ever show up in an
  // actual order
  VANILLA: 'REBALANCE',
} as { [key in OptimizerMode]: CATALYST };

export const RUN_SOURCE_TO_INITIATOR = {
  ADVISOR_CREATING_STRATEGY: 'ADVISOR',
  ADVISOR_IMMEDIATE_CASH_WITHDRAWAL: 'ADVISOR',
  VISE_ADMIN: 'VISE',
  REBALANCER: 'VISE',
} as { [key in RUN_SOURCE]: INITIATOR };

export function turnOverToSeverity(turnover: number): TURNOVER_SEVERITY {
  if (turnover >= HIGH_TURNOVER_THRESHHOLD) {
    return 'HIGH';
  }
  if (turnover >= MED_TURNOVER_THRESHHOLD) {
    return 'MED';
  }
  return 'LOW';
}

export function beforeBackfill(date: Date): boolean {
  return date < new Date('10/24/2022');
}
