import useSWR from 'swr';
import { getHouseholdAccountsWithSummary } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getHouseholdAccountsWithSummarySwrKey = (householdId: string) =>
  generateSwrKey(`householdAccountsWithSummary/${householdId}`);

const useHouseholdAccountsWithSummary = (householdId: string | null | undefined) => {
  const { data, error, isValidating, mutate } = useSWR(
    householdId ? getHouseholdAccountsWithSummarySwrKey(householdId) : null,
    householdId == null
      ? undefined
      : () => getHouseholdAccountsWithSummary(householdId, { risk: true })
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export default useHouseholdAccountsWithSummary;
