import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import {
  LAYOUT_L_PADDING_EXACT_SM,
  LAYOUT_L_PADDING_SPACING_XS,
  LAYOUT_R_PADDING_SPACING,
} from './Constants';
import PortfolioSummary from './PortfolioSummary';
import PortfolioSummary2 from './PortfolioSummary2';
import { DraftPortfolio } from './Types';
import { isSmallAccount } from './utils';

const scrollToTopOnRouteChange = (id) => (Component) => (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollTo({ top: 0 });
    }
  }, [pathname]);
  return <Component id={id} {...props} />;
};

// Padding is applied to this component rather than to a wrapping `Container` so that components
// that overflow, like the popover for react-select, are not cut off by this Box's
// `overflow:hidden`.
const PaddedGrid = styled(
  scrollToTopOnRouteChange('portfolio-creator-content-scroll-container')(Grid)
)`
  height: 100%;
  overflow: auto;
  padding-left: ${({ theme }) => theme.spacing(LAYOUT_L_PADDING_SPACING_XS)};
  padding-right: ${({ theme }) => theme.spacing(LAYOUT_R_PADDING_SPACING)};

  ${({ theme }) =>
    css`
      ${theme.breakpoints.up('sm')} {
        padding-left: ${LAYOUT_L_PADDING_EXACT_SM}px;
      }
    `}
`;

interface Props {
  currentScreenName: string;
  draftPortfolio: DraftPortfolio;
  largestStepVisited: number;
}

const SummaryLayout: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  currentScreenName,
  draftPortfolio,
  largestStepVisited,
}) => {
  const PortfolioSummaryComponent = !isSmallAccount(draftPortfolio.accountSize)
    ? PortfolioSummary2
    : PortfolioSummary;
  return (
    // On screens sized "xs" the overflow is not hidden, and the content is able to be scrolled.
    <Box display="flex" flex={1} height="100%" overflow={{ sm: 'hidden' }} p={0}>
      <Grid container>
        <PaddedGrid item xs>
          {children}
        </PaddedGrid>
        <Grid item style={{ height: '100%', width: '379px' }}>
          <PortfolioSummaryComponent
            currentScreenName={currentScreenName}
            draftPortfolio={draftPortfolio}
            largestStepVisited={largestStepVisited}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SummaryLayout;
