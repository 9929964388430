import { Box, Dialog, Typography } from '@mui/material';
import React from 'react';
import DialogTitle from '~/synth/DialogTitle';
import { ProposalSummary } from '../../../models/household';
import LoadingSpinner from '../../../synth/LoadingSpinner';
import { BondProposalsTable, ProposalsModalFooter, ProposalsTable } from './ProposalsModal';
import { BondPortfolioSettings } from '../../../../../vise-types/portfolio';

export type SampleProposalsModalProps = {
  proposals: ProposalSummary[];
  bondProposals: BondPortfolioSettings[];
  open: boolean;
  onClose: () => void;
  isLoading?: boolean;
};

export const SampleProposalsModal = ({
  isLoading,
  onClose,
  open,
  proposals,
  bondProposals,
}: SampleProposalsModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle onClose={onClose}>Proposals</DialogTitle>
      <Box
        py={2.5}
        px={3}
        sx={{ borderColor: 'grey.200' }}
        border={1}
        borderLeft={0}
        borderRight={0}
        color="grey.600"
      >
        Select a sample portfolio proposal created for this household.
      </Box>
      <Box minHeight={300}>
        {isLoading && <LoadingSpinner />}

        {!isLoading && proposals?.length > 0 && (
          <Box my={2}>
            <ProposalsTable proposals={proposals} />
          </Box>
        )}
        {!isLoading && bondProposals?.length > 0 && (
          <Box my={2}>
            <Typography variant="h4" ml={2} mb={1}>
              Fixed Income
            </Typography>
            <BondProposalsTable bondProposals={bondProposals} />
          </Box>
        )}
      </Box>
      <ProposalsModalFooter />
    </Dialog>
  );
};
