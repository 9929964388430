import { Box, Container, Divider, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React, { useEffect, useReducer } from 'react';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import { GetModelTemplateCenterViewDataResponse } from 'vise-types/template';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import LoadingSpinner from '~/synth/LoadingSpinner';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import amplitude from '~/utils/amplitude';
import { datadogRum } from '@datadog/browser-rum';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import TemplateBrowser from './TemplateBrowser';
import ActivityLog from './activity_log/ActivityLog';
import NotificationsSection from './NotificationsSection';
import LinkTemplateCTA from './LinkTemplateCTA';
import AccountsAndAllocationsTable from './AccountsTable';
import BulkEdit from './workflows/BulkEdit';

interface MTCState {
  data: GetModelTemplateCenterViewDataResponse | undefined;
  bootstrapError: Error | undefined;
}

type Action =
  | {
      type: 'BOOTSTRAP_SUCCEESS';
      data: GetModelTemplateCenterViewDataResponse;
    }
  | {
      type: 'BOOTSTRAP_FAILURE';
      error: Error;
    };

function reducer(state: MTCState, action: Action): MTCState {
  switch (action.type) {
    case 'BOOTSTRAP_SUCCEESS': {
      return {
        bootstrapError: undefined,
        data: action.data,
      };
      break;
    }
    case 'BOOTSTRAP_FAILURE': {
      return {
        data: undefined,
        bootstrapError: action.error,
      };
    }
    default:
      return state;
  }
}

function getScreenName(screen) {
  switch (screen) {
    case 'activity': {
      return 'activity';
    }
    case 'accounts': {
      return 'accounts';
    }
    case 'bulk-edit': {
      return 'bulkEdit';
    }
    default: {
      return 'overview';
    }
  }
}

export default function ModelTemplateCenter() {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, { data: undefined, bootstrapError: undefined });
  const {
    data: bootstrapData,
    error: bootstrapError,
    mutate: mutateTemplate,
  } = useModelTemplateCenterViewData();
  const { data, bootstrapError: error } = state;
  useEffect(() => {
    if (bootstrapData) {
      dispatch({
        type: 'BOOTSTRAP_SUCCEESS',
        data: bootstrapData.data,
      });
    } else if (bootstrapError) {
      dispatch({
        type: 'BOOTSTRAP_FAILURE',
        error: bootstrapError,
      });
    }
  }, [bootstrapData, bootstrapError]);
  useEffect(() => {
    if (error) {
      datadogRum.addError(error);
    }
  }, [error]);
  const match = useRouteMatch<{ screen: string }>('/secure/strategy-center/:screen');
  const screenName = getScreenName(match?.params.screen);
  const theme = useTheme();
  const { data: featureFlags } = useFeatureFlags();

  if (error) {
    return (
      <Box padding={2}>
        <Typography variant="body2">Error loading data</Typography>
      </Box>
    );
  }
  if (!data) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <LinkTemplateCTA
        operation="CREATE"
        templates={[...data.allocationsTemplates, ...data.restrictionsTemplates]}
        links={data.templateLinks}
      />
      <LinkTemplateCTA
        operation="EDIT"
        templates={[...data.allocationsTemplates, ...data.restrictionsTemplates]}
        links={data.templateLinks}
      />
      <Box bgcolor="white">
        <Container>
          <Box
            pt={4}
            mb={2}
            sx={{
              alignItems: 'center',
              maxWidth: '1280px',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'flex',
            }}
          >
            <Box flex={2}>
              <Box mb={1.5}>
                <Typography variant="h1">Strategy center</Typography>
              </Box>
              <Box color={theme.palette.grey[600]}>
                <Typography variant="body2">
                  Create, edit, and apply templates across portfolios to manage your business with
                  ease.
                </Typography>
              </Box>
            </Box>
            <Box flex={1} textAlign="right">
              <Box my={2} display="flex" alignItems="center" justifyContent="flex-end">
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography variant="body2" color="textSecondary">
                    Templates{' '}
                  </Typography>
                  <Box mb={1} />
                  <Typography variant="body2">
                    {
                      [
                        ...(data.allocationsTemplates || []),
                        ...(data.restrictionsTemplates || []),
                      ].filter((t) => !t.stale).length
                    }
                  </Typography>
                </Box>

                <Box color={theme.palette.grey[300]} mx={4}>
                  <Divider orientation="vertical" flexItem style={{ height: '44px' }} />
                </Box>

                <DropdownButtonMenu
                  buttonProps={{ color: 'primary', variant: 'contained' }}
                  buttonContent="Create template"
                >
                  {() => [
                    <DropdownButtonMenuItem
                      key="allocation"
                      onClick={() => {
                        history.push('/secure/template/allocation/create');
                        amplitude().logEvent('Click create allocations template on home page', {
                          category: EVENT_CATEGORIES.STRATEGY_CENTER,
                        });
                      }}
                    >
                      Allocation
                    </DropdownButtonMenuItem>,
                    <DropdownButtonMenuItem
                      onClick={() => {
                        history.push('/secure/template/restriction/create');
                        amplitude().logEvent('Click create restrictions template on home page', {
                          category: EVENT_CATEGORIES.STRATEGY_CENTER,
                        });
                      }}
                      key="restrictions"
                    >
                      Restrictions
                    </DropdownButtonMenuItem>,
                  ]}
                </DropdownButtonMenu>
              </Box>
            </Box>
          </Box>
        </Container>
        <Divider />
        <Box borderBottom={1} borderColor="grey.200" mb={3}>
          <Container>
            <Tabs value={screenName}>
              <Tab
                label="Templates"
                value="overview"
                to="/secure/strategy-center"
                component={Link}
                id="mtc-tab-overview"
                aria-controls="mtc-overview-screen"
                onClick={() =>
                  amplitude().logEvent('Click templates tab', {
                    category: EVENT_CATEGORIES.STRATEGY_CENTER,
                  })
                }
              />
              {featureFlags?.enable_bulk_edit === 'on' ? (
                <Tab
                  label="Bulk edit"
                  value="bulkEdit"
                  to="/secure/strategy-center/bulk-edit"
                  component={Link}
                  id="mtc-tab-bulk-edit"
                  aria-controls="mtc-bulk-edit-screen"
                  onClick={() =>
                    amplitude().logEvent('Click bulk edit tab', {
                      category: EVENT_CATEGORIES.STRATEGY_CENTER,
                    })
                  }
                />
              ) : null}
              <Tab
                label="Accounts"
                value="accounts"
                to="/secure/strategy-center/accounts"
                component={Link}
                id="mtc-tab-accounts"
                aria-controls="mtc-accounts-screen"
                onClick={() =>
                  amplitude().logEvent('Click accounts tab', {
                    category: EVENT_CATEGORIES.STRATEGY_CENTER,
                  })
                }
              />
              {featureFlags?.enable_activity_log === 'on' && (
                <Tab
                  label="Activity"
                  value="activity"
                  to="/secure/strategy-center/activity"
                  component={Link}
                  id="mtc-tab-activity"
                  aria-controls="mtc-activity-screen"
                  onClick={() =>
                    amplitude().logEvent('Click activity log tab', {
                      category: EVENT_CATEGORIES.STRATEGY_CENTER,
                    })
                  }
                />
              )}
            </Tabs>
          </Container>
        </Box>
      </Box>
      {screenName === 'overview' && (
        <>
          <Box maxWidth="1280px" margin="auto">
            <Box mx={3} mb={4}>
              <NotificationsSection mutateData={mutateTemplate} data={data} />
            </Box>
          </Box>
          <Box id="mtc-overview-screen" aria-labelledby="mtc-tab-overview">
            <TemplateBrowser mutateTemplate={mutateTemplate} data={data} />
          </Box>
        </>
      )}
      {screenName === 'activity' && <ActivityLog />}
      {screenName === 'accounts' && <AccountsAndAllocationsTable data={data} />}
      {screenName === 'bulkEdit' && (
        <>
          <Box maxWidth="1280px" margin="auto">
            <Box id="bulk-edit-screen" aria-labelledby="mtc-tab-bulk-edit">
              <BulkEdit data={data} />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
