import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { sumBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { Position } from 'vise-types/portfolio';

import { liquidateAccount } from '~/api/api';
import SomethingWentWrong from '~/components/SomethingWentWrong';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAccount from '~/hooks/useAccount';
import useCapitalGains from '~/hooks/useCapitalGains';
import useHousehold from '~/hooks/useHousehold';
import usePortfolioIntelligence from '~/hooks/usePortfolioIntelligence';
import usePositions from '~/hooks/usePositions';
import useEnqueueToast from '~/hooks/useToast';
import Truncation from '~/routes/Portfolio/components/Truncation';
import { ASSET_TYPE_TO_LABEL } from '~/routes/PortfolioCreator2/Constants';
import CardContent from '~/synth/CardContent';
import CardHeader from '~/synth/CardHeader';
import LoadingSpinner from '~/synth/LoadingSpinner';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import amplitude from '~/utils/amplitude';
import { ACCOUNTING_FORMATTER, formatCurrency } from '~/utils/format';

import useAccountState from '~/hooks/useAccountState';
import usePortfolioInsights from '~/hooks/usePortfolioInsights';
import usePortfolioMetrics from '~/hooks/usePortfolioMetrics';
import LastUpdated from '~/routes/Portfolio/LastUpdated';

const CardSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  '&:last-child': {
    border: 'none',
  },
}));

interface EventTableData {
  event: string;
  estimatedProceeds: string;
  gainLoss: string;
  shortTermGainLoss: string;
}

const liquidationText =
  'Account liquidation will sell all assets in the portfolio for cash. An investor may choose to liquidate an account for various reasons such as switching brokerages or covering large purchases (e.g., buying a house or paying unexpected medical bills). \n\nAt the end of this process, you may choose to retain or remove this account from the Vise platform.';

export default function LiquidateAccount() {
  const { accountId } = useParams<{ accountId: string }>();
  const { data: accountData, error: accountResponseError } = useAccount(accountId);
  const theme = useTheme();
  const { data: intelligenceArrayData, error: intelligenceError } = usePortfolioIntelligence(
    accountData?.data?.portfolioIntelligenceId
  );

  const intelligence = intelligenceArrayData?.[0];
  const { data: accountPositionsResponseData, error: accountPositionsResponseError } = usePositions(
    accountData?.data?.accountNumber,
    accountData?.data?.custodianKey,
    true /* includePCE2Instruments */
  );

  const enqueueToast = useEnqueueToast();
  const history = useHistory();
  const { data: capitalGainsData } = useCapitalGains(
    accountData?.data?.accountNumber,
    accountData?.data?.custodianKey,
    [], // Pass empty locked positions set so we fetch data for all positions
    true // Include all positions in data
  );
  const { data: householdResponseData, error: householdResponseError } = useHousehold(
    intelligence?.client.clientGroupId
  );

  const shouldFetchPortfolioIntelligence = accountData?.data.rebalancerStatus === 'ACTIVE';

  const { data: portfolioInsightsResponse } = usePortfolioInsights(
    shouldFetchPortfolioIntelligence ? intelligence?.id : undefined
  );

  const { data: accountStateData } = useAccountState(
    accountData?.data?.id ? accountData?.data?.id : null
  );

  const { data: portfolioMetrics } = usePortfolioMetrics(
    intelligence?.pceVersion === 'pce2' ? accountId : undefined
  );

  const isLiquidationPending = accountStateData?.isLiquidationPending;
  const totalValue = portfolioMetrics?.portfolioMetrics.totalValue;

  const lockedPositionsData: Position[] = useMemo(() => {
    const lockedTickers = new Set(intelligence?.constructionRequest.doNotSellSymbolsList);
    return accountPositionsResponseData
      ? accountPositionsResponseData.positions.filter((pos) => lockedTickers.has(pos.symbolOrCusip))
      : [];
  }, [accountPositionsResponseData, intelligence?.constructionRequest.doNotSellSymbolsList]);

  const totalLockedPositionsValue = sumBy(lockedPositionsData, 'marketValue');

  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');

  const loadingError =
    intelligenceError ||
    householdResponseError ||
    accountResponseError ||
    accountPositionsResponseError;
  const portfolioPage = `/secure/accounts/${accountId}/portfolio/overview`;
  if (loadingError) {
    return (
      <SomethingWentWrong
        description="There was an error loading the portfolio."
        error={loadingError}
      />
    );
  }
  if (
    intelligence == null ||
    (intelligenceArrayData && intelligenceArrayData[0] == null) ||
    householdResponseData == null ||
    accountData == null ||
    accountPositionsResponseData == null
  ) {
    return <LoadingSpinner />;
  }
  const {
    unrealizedLongTermGains: capitalGain,
    unrealizedLongTermLosses: capitalLosses,
    unrealizedShortTermGains: shortTermCapGain,
    unrealizedShortTermLosses: shortTermCapLoss,
  } = capitalGainsData || {};

  const capGainLossNumber = capitalGain || (capitalLosses ? -1 * capitalLosses : 0);
  const shortTermCapGainLoss = shortTermCapGain || (shortTermCapLoss ? -1 * shortTermCapLoss : 0);

  const capGainColor = (capGainLossNumberToColor) => {
    if (capGainLossNumberToColor === 0) return `${theme.palette.grey[200]}`;
    return capGainLossNumberToColor > 0
      ? `${theme.palette.success[500]}`
      : `${theme.palette.error[500]}`;
  };

  const { id: householdId, name: householdName } = householdResponseData;

  const { positionDate } = accountPositionsResponseData;

  const asOfDate = positionDate != null ? new Date(positionDate) : new Date();
  const asOf = format(asOfDate, 'MMM d');

  const termsAndDisclosuresText = `The current value of the portfolio is reflected as of ${asOf} and will be subject to change pending any account activity and market fluctuations. Please note that this transaction may create potential tax impact to your client's account. \n\n To successfully liquidate your account, you consent and understand that Vise may need to:`;
  const liquidationActions = [
    'Breach the capital gains budget set for the account',
    'Allow wash sales to occur in the account and / or',
    'Bypass any existing locked positions in the account.',
  ];
  const finalTerms = `Once the liquidation request has been received, Vise will pause the rebalancer for the associated account. If the account is funded in the future, you may unpause the rebalancer for this account on the account's overview page. \n\n Liquidation requests received after 2 PM EST/EDT may not be processed until the following business day.`;
  const finalTermsSplit = finalTerms.split('overview page');

  const eventGridColumns: GridColDef<EventTableData>[] = [
    {
      headerName: 'Event',
      field: 'event',
      flex: 1,
    },
    {
      headerName: 'Estimated proceeds',
      field: 'estimatedProceeds',
      flex: 1,
    },
    {
      headerName: 'Estimated long term capital gains/losses',
      renderCell: ({ value }) => {
        return (
          <p
            style={{
              color: capGainColor(capGainLossNumber),
            }}
          >
            {value}
          </p>
        );
      },
      field: 'gainLoss',
      flex: 1,
    },
    {
      headerName: 'Estimated short term capital gains/losses',
      renderCell: ({ value }) => {
        return (
          <p
            style={{
              color: capGainColor(shortTermCapGainLoss),
            }}
          >
            {value}
          </p>
        );
      },
      field: 'shortTermGainLoss',
      flex: 1,
    },
  ];

  const eventGridData = [
    {
      event: 'Full liquidation',
      estimatedProceeds: formatCurrency(totalValue),
      gainLoss: formatCurrency(capGainLossNumber, ACCOUNTING_FORMATTER),
      shortTermGainLoss: formatCurrency(shortTermCapGainLoss, ACCOUNTING_FORMATTER),
    },
  ];

  const lockedPositionsColumns: GridColDef<Position>[] = [
    {
      headerName: 'Ticker',
      valueGetter: ({ row }) => row.symbolOrCusip,
      field: 'ticker',
      maxWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Name',
      renderCell: (props) => <Truncation>{props.value}</Truncation>,
      field: 'name',
      flex: 1,
    },
    {
      headerName: 'Asset type',
      renderCell: ({ value }) => <Truncation>{ASSET_TYPE_TO_LABEL[value]}</Truncation>,
      field: 'assetType',
      flex: 1,
    },
    {
      headerName: 'Value',
      renderCell: ({ value }) => <>{formatCurrency(value)}</>,
      field: 'marketValue',
      flex: 1,
    },
  ];

  const liquidationInfoJSON = {
    disclosures: {
      liquidation: liquidationText,
      lockedPositionsColumns,
      lockedPositions: lockedPositionsData,
      totalLockedPositionsValue,
      eventGridColumns,
      eventGridData,
      termsAndDisclosures: termsAndDisclosuresText,
      liquidationActions,
      finalTerms,
    },
    notes,
  };

  async function handleSubmit() {
    try {
      await liquidateAccount(accountId, liquidationInfoJSON);
    } catch (error) {
      enqueueToast({
        title: 'There was an error liquidating the account',
        content: 'Please reach out to clientservice@vise.com for help.',
        severity: 'error',
      });
      history.push(portfolioPage);
      return;
    }
    history.push(`/secure/accounts/${accountId}/confirm`);
    enqueueToast({
      title: 'Liquidation request submitted',
    });
  }

  async function handleOnClickCancel() {
    history.push(portfolioPage);
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Container>
          <Box m={3} mb={15}>
            <Grid item lg={12}>
              <Box margin="auto" maxWidth="1280px">
                <Box display="flex-end" alignItems="flex" alignSelf="center">
                  <PathBreadcrumbs
                    path={[
                      {
                        name: householdName,
                        to: `/secure/households/${householdId}`,
                      },
                      {
                        name: accountData.data.accountName,
                        to: `/secure/accounts/${accountId}/portfolio/overview`,
                      },
                      {
                        name: 'Liquidate Account',
                      },
                    ]}
                    ariaLabel="Page path breadcrumbs"
                  />

                  {positionDate != null && (
                    <Box
                      display="flex"
                      alignItems="center"
                      alignSelf="center"
                      justifyContent="flex-end"
                    >
                      <LastUpdated
                        insightsDate={
                          portfolioInsightsResponse
                            ? new Date(portfolioInsightsResponse.data.generatedAt * 1000)
                            : undefined
                        }
                        date={new Date(positionDate)}
                      />
                    </Box>
                  )}
                </Box>
                <Card sx={{ mt: 3, mb: 3 }}>
                  <CardHeader>
                    <Typography variant="h4">Liquidate Account</Typography>
                  </CardHeader>
                  <CardContent display="flex">
                    <Box mb={0.25}>
                      <Box display="flex" width="100%" justifyContent="space-between">
                        <Box style={{ whiteSpace: 'pre-line' }}>{liquidationText}</Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
                <Card sx={{ mt: 3, mb: 3 }}>
                  <Box>
                    <DataGridPro
                      columns={eventGridColumns}
                      rows={eventGridData}
                      getRowId={(row) => row.event}
                    />
                  </Box>
                </Card>
                <Card sx={{ mt: 3, mb: 3 }}>
                  <Box>
                    <CardHeader>
                      <Typography variant="h4">Locked positions</Typography>
                    </CardHeader>

                    {lockedPositionsData ? (
                      <Box>
                        <DataGridPro
                          columns={lockedPositionsColumns}
                          rows={lockedPositionsData}
                          getRowId={(row) => row.symbolOrCusip}
                          autoHeight
                        />
                        <CardSection
                          bgcolor="grey.100"
                          boxShadow={5}
                          display="flex"
                          justifyContent="space-between"
                          p={3}
                        >
                          <Box>
                            <strong>Total Locked Positions Value</strong>
                          </Box>
                          <Box>
                            <strong>{formatCurrency(totalLockedPositionsValue)}</strong>
                          </Box>
                        </CardSection>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Card>
                <Card sx={{ mt: 3, mb: 3 }}>
                  <Box p={3}>
                    <Typography mb={3} display="flex" flexDirection="row">
                      Why are you liquidating this account?
                      <Box color="error.300" ml={0.5}>
                        *
                      </Box>
                    </Typography>

                    <TextField
                      fullWidth
                      autoFocus
                      placeholder="Placeholder"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </Box>
                </Card>

                <Card>
                  <CardHeader>
                    <Typography variant="h4">Terms & Disclosures</Typography>
                  </CardHeader>
                  <CardContent style={{ whiteSpace: 'pre-line' }}>
                    {termsAndDisclosuresText}
                    <ol>
                      {liquidationActions.map((o) => (
                        <li key={o}>{o}</li>
                      ))}
                    </ol>
                    {finalTermsSplit[0]}
                    <a href={portfolioPage} rel="noreferrer" target="_blank">
                      overview page
                    </a>
                    {finalTermsSplit[1]}
                  </CardContent>
                  <Divider />
                  <Box display="flex" m={3} alignItems="center">
                    <Checkbox
                      checked={isApproved}
                      onChange={(e, checked) => {
                        amplitude().logEvent(
                          `${checked ? 'Select' : 'Deselect'} approve liquidation request`,
                          { category: EVENT_CATEGORIES.LIQUIDATE_ACCOUNT }
                        );
                        setIsApproved(!isApproved);
                      }}
                    />
                    <Box ml={1}>
                      <Typography variant="body1">
                        I certify that I have read, understand, and agree to the above terms and
                        disclosures.
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Box>
        </Container>

        <Box
          display="flex"
          position="absolute"
          width="-webkit-calc(100% - 230px)"
          bottom="0"
          py={3}
          justifyContent="flex-end"
          px={3}
          bgcolor="white"
          borderTop={`solid 1px ${theme.palette.grey[200]}`}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1.5}>
              <Button variant="outlined" color="secondary" onClick={handleOnClickCancel}>
                Cancel
              </Button>
            </Box>
            <Button
              variant="contained"
              color="error"
              onClick={handleSubmit}
              disabled={!isApproved || !notes || isLiquidationPending}
            >
              Fully Liquidate
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
}
