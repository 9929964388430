/** =====================
 * DANGER! Ths file will be deprecated soon. Do not use it or any of its exports.
 * ======================
 */
import enUS from './en-US.json';

/** =====================
 * DANGER! These (Colors) are deprecated. Use the design system theme instead.
 * ======================
 */
export const Colors = {
  accentBlue: '#4da1ff',
  accentBlueDark: '#1345B9',
  accentBlueGradient: 'rgba(77, 161, 255, 0.10)',
  accentBlueLight: '#00c6ff',
  accentBlueMiddle: '#4072d9',

  accentGreen: '#3E9762',
  accentGreenDark: '#0a8e90',
  accentGreenGradient: 'rgba(22, 209, 181, 0.16)',
  accentGreenLight: '#44e9b7',
  accentGreenMiddle: '#13b69e',

  accentOrange: '#FEA701',
  accentOrangeGradient: 'rgba(254, 165, 1, 0.10)',
  accentRed: '#CF2626',
  accentRedDark: '#9c1515',
  accentRedGradient: 'rgba(226, 42, 42, 0.10)',
  accentRedLight: 'rgba(226, 42, 42, 0.16)',
  accentYellow: '#ffda41',
  accentYellowGradient: 'rgba(255, 217, 65, 0.10)',

  accentPurple: '#d14c94',
  accentPurpleDark: '#600e34',
  accentPurpleGradient: 'rgba(209, 76, 147, 0.10)',
  accentPurpleLight: '#dbb9e7',
  accentPurpleMiddle: '#a00b5a',

  black: '#000000',

  blue: '#1753DE',
  blueDark: '#1345B9',
  blueGradient: 'linear-gradient(to left, #DAE4FB, #1345B9)',
  blueLight: '#DAE4FB',
  blueTranslucent: 'rgba(23, 83, 222, 0.06)',

  chartBlue: 'linear-gradient(to left, #00cbff, #09a6ec)',
  chartGreen: 'linear-gradient(to left, #16d1b5, #00eecb)',
  chartOrange: 'linear-gradient(to left, #ffda41, #fea701)',
  chartRed: 'linear-gradient(to left, #fd416a, #ef0033)',

  gray: '#e0e4ee',
  grayDark: '#434A67',
  grayLight: '#f1f4fb',
  grayLighter: '#f3f6fe',
  grayLighterEven: '#c0c5d2',
  grayLight2: '#E2E8F3',
  grayMiddle: '#a5b1d5',
  grayMiddler: '#8e92a1',
  grayTable: '#f8f9fe',
  grayTableText: '#8e92a1',
  grayTablePlaceholder: '#4b536f',

  white: '#FFFFFF',

  clearIndicatorHoverBackground: 'rgb(255, 189, 173);',
  clearIndicatorHoverColor: 'rgb(222, 53, 11)',

  // New colors.
  stepDoneBgColor: '#2196f3',
  stepDoneTextColor: '#FFF',
  stepDoneDetailsColor: '#9E9FAB',
  stepSelectedGapColor: '#FFF',
  stepSelectedDetailsColor: '#2196f3',
  stepSelectedSubLevelDetailsColor: '#000',
  stepTodoBgColor: '#D4D9E2',
  stepTodoGapColor: '#FFF',
  stepTodoTextColor: '#D4D9E2',
  stepTodoDetailsColor: '#9E9FAB',
};

export const Translations = {
  enUS,
};

export const locale = window.navigator.language || 'en-US';

// TODO(rteammco): Move this to a utils package shared between api and web
export const PASSWORD_MATCH_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d~!@#$%^&*_+=|<>?]{8,}$/;

export const MoveToCashConcentrations = [
  {
    asset_class: 'International Equities',
    min: 0.0,
    max: 1.0,
  },
  {
    asset_class: 'US Bonds',
    min: 0.0,
    max: 1.0,
  },
  {
    asset_class: 'US Equities',
    min: 0.0,
    max: 1.0,
  },
  {
    asset_class: 'US Equities Small Cap',
    min: 0.0,
    max: 1.0,
  },
] as const;

export { ActionType } from './ActionType';

export enum Custodian {
  TDA = 'TDA',
  SCHWAB = 'SCHWAB',
  FIDELITY = 'FIDELITY',
}

export const CustodianName = {
  [Custodian.SCHWAB]: 'Charles Schwab',
  [Custodian.TDA]: 'TD Ameritrade Institutional',
  [Custodian.FIDELITY]: 'Fidelity Investments',
};

// PCE2_INCONSISTENT_LOTS_TOLERANCE is the tolerance value within PCE2 for the
// difference in position quantity vs. summed lot quantity.
//
// This value is also defined in vise-types/pce2.d.ts. These must be kept in sync
// if either changes.
export const PCE2_INCONSISTENT_LOTS_TOLERANCE = 0.001;
