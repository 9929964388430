import { Box, Collapse, Divider, Typography, useTheme } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { styled } from '@mui/system';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ConcentrationLimit } from 'vise-types/pce1';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import { readableGlidePath, riskToRiskSlider } from '~/api/utils';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useClients from '~/hooks/useClients';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useHousehold from '~/hooks/useHousehold';
import { Account } from '~/models/api';
import { ReactComponent as ChevronDown } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '~/static/images/icons/chevron-up.svg';
import { ReactComponent as UserIcon } from '~/static/images/icons/user.svg';
import Skeleton from '~/synth/Skeleton';
import { calculatePortfolioHorizon } from '~/utils/ageUtils';
import amplitude from '~/utils/amplitude';
import { getAssetClassFeaturesFromKey } from '~/utils/pce2Migration';
import {
  formatCurrency,
  formatPercent,
  getCustodianDisplayName,
  maskAccountNumber,
} from '../../utils/format';
import Truncation from '../Portfolio/components/Truncation';
import {
  ASSET_CLASS_TO_LABEL_MAP,
  ASSET_CLASS_TREES,
  FOCUS_TO_LABEL,
  SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL,
  SMALL_ACCOUNT_EQUITY_ASSET_CLASS_KEYS,
  SMALL_ACCOUNT_FI_ASSET_CLASS_KEYS,
} from './Constants';
import useScreens from './Screens';
import {
  AssetClassTreeNode,
  ConstructionInfo,
  DraftPortfolio,
  Pce2CapitalGainsLimits,
  Pce2ConstructionInfo,
} from './Types';
import { cantSkipCategories } from './screens/SummaryLandingScreen/SummaryUtils';
import { EditButton } from './screens/components';
import ActiveTiltSelection from './screens/components/ActiveTiltSelection';
import {
  BorderBox,
  ExcludedCountries,
  ExcludedSectors,
  RestrictedStocks,
  SubSectionBody,
  SubSectionHeader,
  SubSectionSubText,
  SubSectionText,
} from './screens/components/SummarySections';
import {
  ActiveTiltForDisplay,
  computeActiveTiltForDisplay,
  formatPce1CashConcentrationLimit,
  getActiveTiltAmountText,
  getToggledAssetClassLeafLabel,
  getToggledAssetClassLeavesInAssetClassTree,
  isSmallAccount as isSmallAccountFn,
  shouldShowCapitalLossesAndGainsScreen,
  shouldSkipRestrictions,
} from './utils';

const PADDING_X = 5;

const Body = styled(Box)`
  overflow: auto;
`;

export const Section = withStyles(Box, (theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(0, PADDING_X),
  },
}));

const SectionHeader = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  mb: 1.5,

  // When a button is present inside a header it makes the header 44px tall. Ensure header is always
  // this height so the layout stays the same with our without the button.
  minHeight: '44px',
  pr: 3,
});

const UserCircleIcon = () => {
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      borderRadius="50%"
      display="flex"
      height={40}
      justifyContent="center"
      minHeight={40}
      minWidth={40}
      width={40}
      bgcolor={theme.palette.blue[100]}
      color={theme.palette.blue[300]}
    >
      <UserIcon />
    </Box>
  );
};

function logEditButtonClick(section: string) {
  amplitude().logEvent('Edit input from panel', {
    category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    section,
  });
}

type AssetClassCategorySectionBodyProps = {
  exclusions: AssetClassKey[];
  isSmallAccount: boolean;
  primaryAssetClassTreeRoot: AssetClassTreeNode;
};

const AssetClassCategorySectionBody = (props: AssetClassCategorySectionBodyProps) => {
  const { exclusions, isSmallAccount, primaryAssetClassTreeRoot } = props;
  const toggledAssetClassMap = getToggledAssetClassLeavesInAssetClassTree(
    primaryAssetClassTreeRoot,
    exclusions,
    isSmallAccount
  );

  if (toggledAssetClassMap.size === 0) {
    return (
      <SubSectionBody>
        <SubSectionText>None selected</SubSectionText>
      </SubSectionBody>
    );
  }

  const assetClassTreeSections: JSX.Element[] = [];

  toggledAssetClassMap.forEach((toggledDescendentKeys, topLevelAssetClassKey) => {
    const topLevelAssetClassFeatures = getAssetClassFeaturesFromKey(topLevelAssetClassKey);
    const topLevelAssetClassLabel = ASSET_CLASS_TO_LABEL_MAP.get(
      topLevelAssetClassFeatures[topLevelAssetClassFeatures.length - 1]
    );

    // Top level key is also a leaf in this case.
    if (toggledDescendentKeys == null) {
      assetClassTreeSections.push(
        <SubSectionBody key={topLevelAssetClassLabel}>
          <SubSectionText>{topLevelAssetClassLabel}</SubSectionText>
        </SubSectionBody>
      );
    } else {
      assetClassTreeSections.push(
        <Box pr={PADDING_X} paddingTop={2} key={topLevelAssetClassLabel}>
          <SubSectionText>{topLevelAssetClassLabel}</SubSectionText>
          {toggledDescendentKeys.length > 0 ? (
            <>
              {toggledDescendentKeys.map((descendentKey) => (
                <SubSectionSubText key={descendentKey}>
                  {getToggledAssetClassLeafLabel(topLevelAssetClassKey, descendentKey)}
                </SubSectionSubText>
              ))}
            </>
          ) : (
            <SubSectionSubText>None selected</SubSectionSubText>
          )}
        </Box>
      );
    }
  });

  return <>{assetClassTreeSections}</>;
};

const SmallAccountAssetClassCategorySectionBody = ({
  exclusions,
}: {
  exclusions: AssetClassKey[];
}) => {
  const exclusionsSet = new Set(exclusions);
  const toggledEquities = SMALL_ACCOUNT_EQUITY_ASSET_CLASS_KEYS.filter(
    (x) => !exclusionsSet.has(x)
  );
  const toggledFi = SMALL_ACCOUNT_FI_ASSET_CLASS_KEYS.filter((x) => !exclusionsSet.has(x));

  return (
    <>
      <SubSectionHeader>Equities</SubSectionHeader>
      <SubSectionBody>
        {toggledEquities.length > 0 ? (
          toggledEquities.map((equity) => (
            <SubSectionText key={equity}>
              {SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL[equity]}
            </SubSectionText>
          ))
        ) : (
          <SubSectionText>None selected</SubSectionText>
        )}
      </SubSectionBody>
      <SubSectionHeader>Fixed income</SubSectionHeader>
      <SubSectionBody>
        {toggledFi.length > 0 ? (
          toggledFi.map((fi) => (
            <SubSectionText key={fi}> {SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL[fi]}</SubSectionText>
          ))
        ) : (
          <SubSectionText>None selected</SubSectionText>
        )}
      </SubSectionBody>
    </>
  );
};

// Renders one of the following:
//
//   - `clientId` non-null?
//     * that real client and its household
//   - `newClientInfo` non-null OR `newHouseholdInfo` non-null?
//     * new client info + "existing" household info
//     * new client info + "new" client info
const HouseholdAndClientBody = React.memo(function HouseholdAndClientBody({
  clientId,
  newClientInfo,
  newHouseholdInfo,
  existingPortfolio,
  initialValue,
}: {
  clientId: string | null;
  newClientInfo: DraftPortfolio['newClientInfo'];
  newHouseholdInfo: DraftPortfolio['newHouseholdInfo'];
  existingPortfolio: 'sample-portfolio' | Account | null;
  initialValue: number | null;
}) {
  const { data: clientsData, isValidating: clientsIsValidating } = useClients(
    clientId == null ? null : undefined
  );

  const { data: householdData } = useHousehold(
    newHouseholdInfo?.type === 'existing' ? newHouseholdInfo.household.id : null
  );

  const client = useMemo(() => {
    return clientsData == null ? null : clientsData.find((datum) => datum.id === clientId);
  }, [clientId, clientsData]);

  if (clientId == null && newClientInfo == null && newHouseholdInfo == null) {
    return <SubSectionBody>None selected</SubSectionBody>;
  }

  let content: React.ReactNode;
  if (clientId == null) {
    let clientContent: React.ReactNode;
    let householdContent: React.ReactNode;
    if (newClientInfo != null) {
      clientContent = (
        <SubSectionSubText>
          {newClientInfo.client.firstName} {newClientInfo.client.lastName}
        </SubSectionSubText>
      );
    }

    if (newHouseholdInfo?.type === 'new') {
      householdContent = <SubSectionText>{newHouseholdInfo.household.name}</SubSectionText>;
    } else if (householdData != null) {
      householdContent = <SubSectionText>{householdData.name}</SubSectionText>;
    }

    content = (
      <>
        {householdContent}
        {clientContent}
      </>
    );
  } else if (clientsData == null && clientsIsValidating) {
    content = <SubSectionSubText>Loading client…</SubSectionSubText>;
  } else if (client == null) {
    content = <SubSectionSubText>Unknown client</SubSectionSubText>;
  } else if (existingPortfolio != null) {
    let PortfolioInfo: JSX.Element;
    if (existingPortfolio !== 'sample-portfolio') {
      const accountInformation = `${client.firstName} ${client.lastName} - ${existingPortfolio.accountType}`;
      PortfolioInfo = (
        <>
          <Typography variant="h4">
            <Truncation>{accountInformation}</Truncation>
          </Typography>
          <Box display="flex" color="grey.500" mt={0.5}>
            {existingPortfolio.taxable ? 'Non-qualified' : 'Qualified'}
            {existingPortfolio.accountDataSource !== 'XRAY' && (
              <>
                <Box bgcolor="grey.300" mx={1}>
                  <Divider orientation="vertical" flexItem />
                </Box>
                <Box>
                  {maskAccountNumber(existingPortfolio.accountNumber)}
                  {', '}
                  {getCustodianDisplayName(existingPortfolio.custodianKey)}
                </Box>
              </>
            )}
          </Box>
        </>
      );
    } else {
      const clientName = `${client.firstName} ${client.lastName}`;
      PortfolioInfo = (
        <>
          <Typography variant="h4">
            <Truncation>{clientName}</Truncation>
          </Typography>
          <Box color="grey.500" mt={0.5}>
            Initial value: {formatCurrency(initialValue)}
          </Box>
        </>
      );
    }

    content = (
      <Box display="flex" alignItems="center" data-testid="account-summary" py={3}>
        <UserCircleIcon />
        <Box ml={3} width={235}>
          {PortfolioInfo}
        </Box>
      </Box>
    );
  }

  return content == null ? null : <>{content}</>;
});

function ActiveTiltAmountText({
  activeTiltForDisplay,
  risk,
}: {
  activeTiltForDisplay: ActiveTiltForDisplay;
  risk: number;
}) {
  let activeTiltAmountText;
  if (activeTiltForDisplay.portfolioType === 'TARGET_VALUE') {
    activeTiltAmountText = 'Auto-adjusted by Vise';
  } else if (activeTiltForDisplay.portfolioType === 'LOSS_TOLERANCE') {
    activeTiltAmountText = getActiveTiltAmountText(
      activeTiltForDisplay.activeTilt,
      riskToRiskSlider(risk)
    );
  } else {
    return null;
  }
  return (
    <>
      <Box mb={4} data-testid="active-tilts-amount-text">
        <SubSectionHeader>Amount</SubSectionHeader>
        <SubSectionBody>
          <SubSectionText>{activeTiltAmountText}</SubSectionText>
        </SubSectionBody>
      </Box>
    </>
  );
}

interface Collapsable {
  open: boolean;
  onClick: () => void;
  canEdit?: boolean;
}

type CollapseSectionProps = {
  title: string | React.ReactNode;
  children: React.ReactNode;
} & Collapsable;

const CollapseSection = ({ title, children, open, onClick }: CollapseSectionProps) => {
  return (
    <Box width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        onClick={onClick}
        style={{
          cursor: 'pointer',
        }}
        py={2}
      >
        {title}
        <div>
          {open ? <ChevronUp width="20" height="20" /> : <ChevronDown width="20" height="20" />}
        </div>
      </Box>
      <Collapse in={open}>{children}</Collapse>
    </Box>
  );
};

type TransitionSettingsProps = {
  currentScreenName: string | number;
  draftPortfolio: DraftPortfolio;
  longTermGainsLimits: Pce2CapitalGainsLimits | null;
  shortTermGainsLimits: Pce2CapitalGainsLimits | null;
  cashConcentrationLimit?: ConcentrationLimit;
  sortedLockedPositions: string[] | null;
  lockedPositions: string[];
} & Partial<ConstructionInfo | Pce2ConstructionInfo> &
  Collapsable;
const TransitionSettings = ({
  currentScreenName,
  lockedPositions,
  existingPortfolio,
  sortedLockedPositions,
  draftPortfolio,
  concentrationLimits,
  cashConcentrationLimit,
  ...rest
}: TransitionSettingsProps) => {
  if (!existingPortfolio) return null;
  if (!draftPortfolio) return null;
  return (
    <CollapseSection title={<Typography variant="h3">Transition Settings</Typography>} {...rest}>
      <div>
        {(currentScreenName !== 'locked-positions' && lockedPositions == null) ||
        existingPortfolio === 'sample-portfolio' ? null : (
          <Box data-testid="locked-positions-summary">
            <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
              <Typography variant="h4">Locked positions</Typography>
              <EditButton
                onClick={() => logEditButtonClick('locked positions')}
                to="/secure/portfolio-creator-next/locked-positions"
              >
                Edit
              </EditButton>
            </BorderBox>
            {sortedLockedPositions == null || sortedLockedPositions.length === 0 ? (
              <SubSectionBody>
                <SubSectionText>None selected</SubSectionText>
              </SubSectionBody>
            ) : (
              Array.isArray(sortedLockedPositions) && (
                <SubSectionBody>
                  {sortedLockedPositions.map((lp) => (
                    <SubSectionText key={lp}>{lp}</SubSectionText>
                  ))}
                </SubSectionBody>
              )
            )}
          </Box>
        )}
        {concentrationLimits != null && (
          <Box mt={2}>
            <SubSectionHeader>Cash allocation</SubSectionHeader>
            <SubSectionBody>
              <SubSectionText>
                {cashConcentrationLimit == null ? (
                  <>No allocation set</>
                ) : (
                  `${formatPce1CashConcentrationLimit(cashConcentrationLimit)}%`
                )}
              </SubSectionText>
            </SubSectionBody>
          </Box>
        )}
      </div>
    </CollapseSection>
  );
};

type TaxManagementProps = {
  currentScreenName: string | number;
  draftPortfolio: DraftPortfolio;
  longTermGainsLimits: Pce2CapitalGainsLimits | null;
  shortTermGainsLimits: Pce2CapitalGainsLimits | null;
} & Partial<ConstructionInfo | Pce2ConstructionInfo> &
  Collapsable;

const TaxManagement = ({
  currentScreenName,
  existingPortfolio,
  draftPortfolio,
  autoTlh,
  longTermGainsLimits,
  shortTermGainsLimits,
  longTermFederalTaxRate,
  longTermStateTaxRate,
  shortTermFederalTaxRate,
  shortTermStateTaxRate,
  ...rest
}: TaxManagementProps) => {
  const { data: featureFlags } = useFeatureFlags();
  if (!existingPortfolio) return null;
  if (existingPortfolio === 'sample-portfolio' || !existingPortfolio.taxable) return null;
  if (!draftPortfolio) return null;
  return (
    <>
      {currentScreenName === 'capital-losses-and-gains' || autoTlh != null ? (
        <Section>
          <CollapseSection title={<Typography variant="h3">Taxes</Typography>} {...rest}>
            <div>
              <Box mt={1.5} data-testid="tax-management-summary">
                <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
                  <Typography variant="h4">Tax management</Typography>
                  {currentScreenName !== 'capital-losses-and-gains' && (
                    <EditButton
                      onClick={() => logEditButtonClick('tax management')}
                      to="/secure/portfolio-creator-next/capital-losses-and-gains"
                    >
                      Edit
                    </EditButton>
                  )}
                </BorderBox>
                <SubSectionBody>
                  <SubSectionText>
                    Tax loss harvesting:{' '}
                    {autoTlh ? (
                      <Box color="green" display="inline">
                        Active
                      </Box>
                    ) : (
                      <>Off</>
                    )}
                  </SubSectionText>
                  {longTermGainsLimits != null && longTermGainsLimits.shouldLimitGains ? (
                    <Box mt={2}>
                      <Typography variant="inherit">Long-term gain limit</Typography>
                      <Box mt={0.5}>
                        <Typography color="textSecondary" variant="body1">
                          {longTermGainsLimits.maximumAmount != null
                            ? formatCurrency(longTermGainsLimits.maximumAmount) +
                              (longTermGainsLimits.shouldLimitSmallestAmount
                                ? ' (minimum amount)'
                                : '')
                            : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                  {shortTermGainsLimits != null && shortTermGainsLimits.shouldLimitGains ? (
                    <Box mt={2}>
                      <Typography variant="inherit">Short-term gain limit</Typography>
                      <Box mt={0.5}>
                        <Typography color="textSecondary" variant="body1">
                          {shortTermGainsLimits.maximumAmount != null
                            ? formatCurrency(shortTermGainsLimits.maximumAmount) +
                              (shortTermGainsLimits.shouldLimitSmallestAmount
                                ? ' (minimum amount)'
                                : '')
                            : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                  {existingPortfolio.accountDataSource === 'XRAY' &&
                  featureFlags?.enable_tax_rates === 'on' ? (
                    <>
                      <Box mt={2}>
                        <Typography variant="inherit">Long-term tax rate</Typography>
                        <Box mt={0.5}>
                          <Typography color="textSecondary" variant="body1">
                            {longTermFederalTaxRate != null && longTermStateTaxRate != null
                              ? formatPercent(longTermFederalTaxRate + longTermStateTaxRate, 1)
                              : '-'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box mt={2}>
                        <Typography variant="inherit">Short-term tax rate</Typography>
                        <Box mt={0.5}>
                          <Typography color="textSecondary" variant="body1">
                            {shortTermFederalTaxRate != null && shortTermStateTaxRate != null
                              ? formatPercent(shortTermFederalTaxRate + shortTermStateTaxRate, 1)
                              : '-'}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  ) : null}
                </SubSectionBody>
              </Box>
            </div>
          </CollapseSection>
        </Section>
      ) : null}
    </>
  );
};

type FocusProps = {
  currentScreenName: string | number;
  isSmallAccount: boolean;
} & Partial<ConstructionInfo | Pce2ConstructionInfo> &
  Collapsable;

export const Focus = ({
  investmentTimeline,
  focus,
  targetValue,
  risk,
  useGlidePath,
  currentScreenName,
  canEdit,
  isSmallAccount,
  ...rest
}: FocusProps) => {
  const screens = useScreens(isSmallAccount);
  const EditButtonSection =
    screens[currentScreenName].order > screens['proposal-type'].order || canEdit ? (
      <EditButton
        onClick={() => logEditButtonClick('proposal type')}
        to="/secure/portfolio-creator-next/proposal-type"
      />
    ) : null;

  return (
    <CollapseSection title={<Typography variant="h3">Portfolio Focus</Typography>} {...rest}>
      <Box mt={1}>
        <Box display="flex" alignItems="baseline" justifyContent="space-between">
          <Typography variant="h4">Focus</Typography>
          {EditButtonSection}
        </Box>
        {focus == null ? null : (
          <>
            <SubSectionBody data-testid="portfolio-focus-summary">
              <SubSectionText>{FOCUS_TO_LABEL[focus]}</SubSectionText>
            </SubSectionBody>
            {investmentTimeline == null ? null : (
              <Box mt={1} data-testid="portfolio-horizon-summary">
                <SubSectionHeader>
                  <Box display="flex" alignItems="baseline" justifyContent="space-between">
                    <div>Horizon</div>
                    {EditButtonSection}
                  </Box>
                </SubSectionHeader>
                <SubSectionBody>
                  <SubSectionText>
                    {calculatePortfolioHorizon(investmentTimeline, new Date())} years
                  </SubSectionText>
                </SubSectionBody>
              </Box>
            )}
            {focus === 'TARGET_VALUE' && targetValue != null ? (
              <Box mt={1} data-testid="portfolio-target-value-summary">
                <SubSectionHeader>Target value</SubSectionHeader>
                <SubSectionBody>
                  <SubSectionText>{formatCurrency(targetValue)}</SubSectionText>
                </SubSectionBody>
              </Box>
            ) : null}
            {focus === 'LOSS_TOLERANCE' && risk != null ? (
              <Box mt={1} data-testid="portfolio-risk-summary">
                <SubSectionHeader>
                  <Box display="flex" alignItems="baseline" justifyContent="space-between">
                    <div>Risk profile</div>
                    {EditButtonSection}
                  </Box>
                </SubSectionHeader>
                <SubSectionBody>
                  <SubSectionText>{riskToRiskSlider(risk)}</SubSectionText>
                </SubSectionBody>
              </Box>
            ) : null}
            {focus === 'LOSS_TOLERANCE' && useGlidePath != null ? (
              <Box mt={1} data-testid="portfolio-glide-path-summary">
                <SubSectionHeader>
                  <Box display="flex" alignItems="baseline" justifyContent="space-between">
                    <div>Glide path</div>
                    {EditButtonSection}
                  </Box>
                </SubSectionHeader>
                <SubSectionBody>
                  <SubSectionText>{readableGlidePath(useGlidePath)}</SubSectionText>
                </SubSectionBody>
              </Box>
            ) : null}
          </>
        )}
      </Box>
    </CollapseSection>
  );
};

type StrategyProps = {
  currentScreenName: string | number;
  draftPortfolio: Pick<
    DraftPortfolio,
    'newSavedStrategyName' | 'savedStrategy' | 'strategyId' | 'pickSavedStrategy'
  >;
  canEdit: boolean;
  isSmallAccount: boolean;
} & Partial<ConstructionInfo | Pce2ConstructionInfo> &
  Collapsable;

export const Strategy = ({
  currentScreenName,
  draftPortfolio,
  canEdit,
  isSmallAccount,
  ...rest
}: StrategyProps) => {
  const screens = useScreens(isSmallAccount);
  let strategyTypeContent = draftPortfolio.newSavedStrategyName;
  if (!isEmpty(draftPortfolio.newSavedStrategyName)) {
    strategyTypeContent = draftPortfolio.newSavedStrategyName;
  } else if (draftPortfolio.strategyId != null && draftPortfolio.savedStrategy !== null) {
    strategyTypeContent = draftPortfolio.savedStrategy.name;
  } else if (draftPortfolio.pickSavedStrategy) {
    if (draftPortfolio.savedStrategy == null) {
      // User elected to choose an existing strategy but hasn't chosen
      // the strategy yet.
      strategyTypeContent = 'Existing strategy';
    } else if (draftPortfolio.newSavedStrategyName == null) {
      // User elected to choose an existing strategy, then changed it, then
      // elected not to save it as a new strategy or overwrite the existing strategy.
      strategyTypeContent = 'None selected';
    }
  } else if (draftPortfolio.newSavedStrategyName == null) {
    strategyTypeContent = 'None selected';
  } else {
    strategyTypeContent = 'Custom strategy';
  }

  const EditButtonSection =
    screens[currentScreenName].order > screens['build-or-use-strategy'].order || canEdit ? (
      <EditButton
        onClick={() => logEditButtonClick('strategy builder')}
        to="/secure/portfolio-creator-next/build-or-use-strategy"
      />
    ) : null;

  return (
    <CollapseSection title={<Typography variant="h3">Strategy builder</Typography>} {...rest}>
      <SubSectionHeader>
        <Box display="flex" alignItems="baseline" justifyContent="space-between">
          <div>Strategy type</div>
          {EditButtonSection}
        </Box>
      </SubSectionHeader>
      <SubSectionBody>
        {draftPortfolio.pickSavedStrategy == null && draftPortfolio.strategyId == null ? null : (
          <SubSectionText data-testid="strategy-type-content">{strategyTypeContent}</SubSectionText>
        )}
      </SubSectionBody>
    </CollapseSection>
  );
};

type AssetClassesProps = {
  currentScreenName: string | number;
  exclusions: AssetClassKey[];
  isSmallAccount: boolean;
  canEdit: boolean;
} & Partial<ConstructionInfo | Pce2ConstructionInfo> &
  Collapsable;

export const AssetClasses = ({
  exclusions,
  currentScreenName,
  isSmallAccount,
  etfExclusive,
  canEdit,
  ...rest
}: AssetClassesProps) => {
  const screens = useScreens(isSmallAccount);
  const EditButtonSection =
    screens[currentScreenName].order > screens['asset-classes'].order || canEdit ? (
      <EditButton
        onClick={() => logEditButtonClick('asset classes')}
        to="/secure/portfolio-creator-next/asset-classes"
      />
    ) : null;

  return exclusions == null ? null : (
    <CollapseSection title={<Typography variant="h3">Asset classes</Typography>} {...rest}>
      <>
        <Box mb={4} data-testid="securities-summary">
          <Box display="flex" alignItems="baseline" justifyContent="space-between">
            <SubSectionHeader>
              {isSmallAccount ? 'Investment Vehicles' : 'Securities'}
            </SubSectionHeader>
            {EditButtonSection}
          </Box>
          <SubSectionBody>
            <SubSectionText>
              {etfExclusive || isSmallAccount ? 'Limit to ETFs' : 'Optimize for single securities'}
            </SubSectionText>
          </SubSectionBody>
        </Box>
        <Box mb={4} data-testid="securities-summary">
          <SubSectionHeader>
            {isSmallAccount ? 'Investment Vehicles' : 'Securities'}
          </SubSectionHeader>
          <SubSectionBody>
            <SubSectionText>
              {etfExclusive || isSmallAccount ? 'Limit to ETFs' : 'Optimize for single securities'}
            </SubSectionText>
          </SubSectionBody>
        </Box>
        {isSmallAccount ? (
          <SmallAccountAssetClassCategorySectionBody exclusions={exclusions} />
        ) : (
          <>
            <Box mb={4} data-testid="equities-summary">
              <SubSectionHeader>Equities</SubSectionHeader>
              <AssetClassCategorySectionBody
                exclusions={exclusions}
                isSmallAccount={isSmallAccount}
                primaryAssetClassTreeRoot={ASSET_CLASS_TREES[0]}
              />
            </Box>
            <Box mb={4} data-testid="fixed-income-summary">
              <SubSectionHeader>Fixed income</SubSectionHeader>
              <AssetClassCategorySectionBody
                isSmallAccount={isSmallAccount}
                exclusions={exclusions}
                primaryAssetClassTreeRoot={ASSET_CLASS_TREES[1]}
              />
            </Box>
            <Box data-testid="alternatives-summary">
              <SubSectionHeader>Alternatives</SubSectionHeader>
              <AssetClassCategorySectionBody
                isSmallAccount={isSmallAccount}
                exclusions={exclusions}
                primaryAssetClassTreeRoot={ASSET_CLASS_TREES[2]}
              />
            </Box>
          </>
        )}
      </>
    </CollapseSection>
  );
};

type TiltProps = {
  currentScreenName: string | number;
  activeTiltForDisplay?: ActiveTiltForDisplay;
  canEdit: boolean;
  risk: number;
  isSmallAccount: boolean;
} & Collapsable;
export const Tilt = ({
  activeTiltForDisplay,
  currentScreenName,
  risk,
  canEdit,
  isSmallAccount,
  ...rest
}: TiltProps) => {
  const screens = useScreens(isSmallAccount);
  return (
    <>
      {!activeTiltForDisplay ? null : (
        <CollapseSection title={<Typography variant="h3">Tilt</Typography>} {...rest}>
          <SectionHeader>
            {screens[currentScreenName].order > screens['active-tilts'].order || canEdit ? (
              <EditButton
                onClick={() => logEditButtonClick('active tilts')}
                to="/secure/portfolio-creator-next/active-tilts"
              />
            ) : null}
          </SectionHeader>
          <>
            <Box mb={4} data-testid="active-tilts-selection">
              <SubSectionHeader>Selection</SubSectionHeader>
              <SubSectionBody>
                <SubSectionText>
                  <ActiveTiltSelection activeTiltForDisplay={activeTiltForDisplay} />
                </SubSectionText>
              </SubSectionBody>
            </Box>
            <ActiveTiltAmountText activeTiltForDisplay={activeTiltForDisplay} risk={risk} />
          </>
        </CollapseSection>
      )}
    </>
  );
};

type RestrictionsProps = {
  currentScreenName: string | number;
  exclusions: AssetClassKey[];
  canEdit: boolean;
} & Pick<
  ConstructionInfo,
  | 'etfExclusive'
  | 'restrictedStocks'
  | 'excludedCountries'
  | 'excludedSectors'
  | 'excludedIndustries'
  | 'excludedEsgAreas'
> &
  Pick<DraftPortfolio, 'accountSize'> &
  Collapsable;
const Restrictions = ({
  currentScreenName,
  etfExclusive,
  restrictedStocks,
  excludedCountries,
  excludedSectors,
  accountSize,
  excludedIndustries,
  exclusions,
  canEdit,
  ...rest
}: RestrictionsProps) => {
  const isSmallAccount = isSmallAccountFn(accountSize);
  const screens = useScreens(isSmallAccount);
  return (
    <>
      {!isSmallAccount &&
      (currentScreenName === 'restrictions-landing' ||
        restrictedStocks != null ||
        excludedSectors != null ||
        excludedCountries != null) ? (
        <CollapseSection title={<Typography variant="h3">Restrictions</Typography>} {...rest}>
          <div data-testid="restrictions-summary">
            <SectionHeader>
              {screens[currentScreenName].order > screens['restrictions-landing'].order ||
              canEdit ? (
                <EditButton
                  onClick={() => logEditButtonClick('restrictions')}
                  to="/secure/portfolio-creator-next/restrictions-landing"
                  disabled={shouldSkipRestrictions({
                    etfExclusive,
                    exclusions,
                  })}
                />
              ) : null}
            </SectionHeader>
            <RestrictedStocks restrictedStocks={restrictedStocks} />
            <ExcludedSectors
              excludedIndustries={excludedIndustries}
              excludedSectors={excludedSectors}
            />
            <ExcludedCountries restrictedCountries={excludedCountries} />
          </div>
        </CollapseSection>
      ) : null}{' '}
    </>
  );
};

function PortfolioSummaryContent({ currentScreenName, draftPortfolio }: PortfolioSummaryProps) {
  const {
    constructionInfo,
    lockedPositions,
    newClientInfo,
    newHouseholdInfo,
    pickSavedStrategy,
    savedStrategy,
    accountSize,
  } = draftPortfolio;
  const {
    activeTilt,
    clientId,
    concentrationLimits,
    etfExclusive,
    existingPortfolio,
    focus,
    risk,
    initialValue,
  } = constructionInfo;

  type PortfolioSummarySection =
    | 'TRANSITION_SETTINGS'
    | 'PORTFOLIO_FOCUS'
    | 'STRATEGY'
    | 'ASSET_CLASSES'
    | 'TILTS'
    | 'RESTRICTIONS'
    | 'TAXES';
  const [collapseItems, setCollapseItems] = useState(new Array<PortfolioSummarySection>());
  const isSmallAccount = isSmallAccountFn(accountSize);
  const screens = useScreens(isSmallAccount);
  const updateCollapseItem = useCallback((item: PortfolioSummarySection) => {
    setCollapseItems((prev) => {
      if (prev.includes(item)) {
        const idx = prev.findIndex((i) => i === item);
        const updatedItems = [...prev];
        updatedItems.splice(idx, 1);
        return updatedItems;
      }
      return [...prev, item];
    });
  }, []);

  useEffect(() => {
    switch (currentScreenName) {
      case 'locked-positions': {
        setCollapseItems(['TRANSITION_SETTINGS']);
        break;
      }
      case 'risk-tolerance': {
        setCollapseItems(['PORTFOLIO_FOCUS']);
        break;
      }
      case 'build-or-use-strategy': {
        setCollapseItems(['STRATEGY']);
        break;
      }
      case 'asset-classes': {
        setCollapseItems(['ASSET_CLASSES']);
        break;
      }
      case 'active-tilts': {
        setCollapseItems(['TILTS']);
        break;
      }
      case 'restrictions-landing': {
        setCollapseItems(['RESTRICTIONS']);
        break;
      }
      default:
        break;
    }
  }, [currentScreenName, updateCollapseItem]);

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'exclusions' does not exist on type 'Asse... Remove this comment to see the full error message
  let exclusions: Pce2ConstructionInfo['assetClassConcentrationLimits']['exclusions'];
  if ('assetClassConcentrationLimits' in constructionInfo) {
    exclusions = constructionInfo.assetClassConcentrationLimits?.exclusions;
  }

  const {
    constructionInfo: {
      autoTlh,
      capitalGainsLimits,
      longTermFederalTaxRate,
      longTermStateTaxRate,
      shortTermFederalTaxRate,
      shortTermStateTaxRate,
    },
  } = draftPortfolio;

  const {
    longTermGainsLimits,
    shortTermGainsLimits,
  }: {
    longTermGainsLimits: Pce2CapitalGainsLimits | null;
    shortTermGainsLimits: Pce2CapitalGainsLimits | null;
  } = capitalGainsLimits ?? { longTermGainsLimits: null, shortTermGainsLimits: null };

  const sortedLockedPositions = useMemo(
    // Slice `lockedPositions` to copy it first because `sort` mutates the array.
    () => (lockedPositions == null ? null : lockedPositions.slice().sort()),
    [lockedPositions]
  );

  const cashConcentrationLimit = concentrationLimits?.find(
    (concentrationLimit) => concentrationLimit.asset_class === 'Cash'
  );

  const activeTiltForDisplay =
    activeTilt || screens[currentScreenName].order > screens['active-tilts'].order
      ? computeActiveTiltForDisplay(
          activeTilt?.tiltAmount,
          activeTilt?.tiltType,
          riskToRiskSlider(risk),
          !!etfExclusive,
          isSmallAccount,
          activeTilt ? !activeTilt.isEnabled : false
        )
      : undefined;

  const { data: featureFlags } = useFeatureFlags();

  const cantEditCatagories = cantSkipCategories({
    draftPortfolio,
    featureFlags,
  });

  return (
    <>
      <>
        <Section style={{ position: 'sticky', top: '0', zIndex: 10, backgroundColor: 'white' }}>
          {clientId && existingPortfolio && (
            <HouseholdAndClientBody
              clientId={clientId}
              newClientInfo={newClientInfo}
              newHouseholdInfo={newHouseholdInfo}
              existingPortfolio={existingPortfolio}
              initialValue={initialValue}
            />
          )}
        </Section>
      </>
      {(currentScreenName === 'locked-positions' ||
        shouldShowCapitalLossesAndGainsScreen(draftPortfolio)) && (
        <Section>
          <TransitionSettings
            open={collapseItems.includes('TRANSITION_SETTINGS')}
            onClick={() => updateCollapseItem('TRANSITION_SETTINGS')}
            currentScreenName={currentScreenName}
            lockedPositions={lockedPositions}
            existingPortfolio={existingPortfolio}
            sortedLockedPositions={sortedLockedPositions}
            draftPortfolio={draftPortfolio}
            autoTlh={autoTlh}
            longTermGainsLimits={longTermGainsLimits}
            shortTermGainsLimits={shortTermGainsLimits}
            concentrationLimits={concentrationLimits}
            cashConcentrationLimit={cashConcentrationLimit}
          />
        </Section>
      )}
      {currentScreenName !== 'risk-tolerance' && focus == null ? null : (
        <Section>
          <Focus
            canEdit={cantEditCatagories.length === 0}
            open={collapseItems.includes('PORTFOLIO_FOCUS')}
            onClick={() => updateCollapseItem('PORTFOLIO_FOCUS')}
            currentScreenName={currentScreenName}
            isSmallAccount={isSmallAccountFn(accountSize)}
            {...constructionInfo}
          />
        </Section>
      )}
      {(currentScreenName !== 'build-or-use-strategy' &&
        pickSavedStrategy == null &&
        savedStrategy == null) ||
      isSmallAccount ? null : (
        <Section>
          <Strategy
            canEdit={cantEditCatagories.length === 0}
            open={collapseItems.includes('STRATEGY')}
            onClick={() => updateCollapseItem('STRATEGY')}
            currentScreenName={currentScreenName}
            draftPortfolio={draftPortfolio}
            isSmallAccount={isSmallAccount}
            {...constructionInfo}
          />
        </Section>
      )}
      <Section>
        <AssetClasses
          canEdit={cantEditCatagories.length === 0}
          open={collapseItems.includes('ASSET_CLASSES')}
          onClick={() => updateCollapseItem('ASSET_CLASSES')}
          {...constructionInfo}
          isSmallAccount={isSmallAccount}
          currentScreenName={currentScreenName}
          exclusions={exclusions}
        />
      </Section>
      <Section data-testid="active-tilts-summary">
        <Tilt
          canEdit={cantEditCatagories.length === 0}
          open={collapseItems.includes('TILTS')}
          onClick={() => updateCollapseItem('TILTS')}
          activeTiltForDisplay={activeTiltForDisplay}
          currentScreenName={currentScreenName}
          risk={risk}
          isSmallAccount={isSmallAccount}
        />
      </Section>
      <Section>
        <Restrictions
          canEdit={cantEditCatagories.length === 0}
          open={collapseItems.includes('RESTRICTIONS')}
          onClick={() => updateCollapseItem('RESTRICTIONS')}
          currentScreenName={currentScreenName}
          exclusions={exclusions}
          {...draftPortfolio}
          {...draftPortfolio.constructionInfo}
        />
      </Section>
      <Section>
        <TaxManagement
          open={collapseItems.includes('TAXES')}
          onClick={() => updateCollapseItem('TAXES')}
          currentScreenName={currentScreenName}
          existingPortfolio={existingPortfolio}
          draftPortfolio={draftPortfolio}
          autoTlh={autoTlh}
          longTermGainsLimits={longTermGainsLimits}
          shortTermGainsLimits={shortTermGainsLimits}
          longTermFederalTaxRate={longTermFederalTaxRate}
          longTermStateTaxRate={longTermStateTaxRate}
          shortTermFederalTaxRate={shortTermFederalTaxRate}
          shortTermStateTaxRate={shortTermStateTaxRate}
        />
      </Section>
    </>
  );
}

interface PortfolioSummaryProps {
  draftPortfolio: DraftPortfolio;
  currentScreenName: string;
  largestStepVisited?: number;
}

export default function PortfolioSummary({
  draftPortfolio,
  currentScreenName,
}: PortfolioSummaryProps) {
  // This dummy div is to ensure the portfolio summary side bar is always scroll to the bottom
  const hiddenDiv = useRef(null);
  useEffect(() => {
    if (hiddenDiv.current) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      hiddenDiv.current.scrollIntoView({ block: 'end' });
    }
  });

  return (
    <Box
      bgcolor="white"
      sx={{ borderLeft: 1, borderColor: 'grey.200' }}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      {draftPortfolio.accountSize === 'SMALL' && (
        <Box bgcolor="blue.100" display="flex" alignItems="center" height={56} px={4}>
          <Typography variant="body2">Optimized for low value.</Typography>
        </Box>
      )}
      {draftPortfolio.accountSize === 'TINY' && (
        <Box bgcolor="#FCF0F0" display="flex" alignItems="center" height={56} px={4}>
          <Typography variant="body2">Insufficient Vise-managed value.</Typography>
        </Box>
      )}
      <Body px={4}>
        {draftPortfolio.bootstrappingState === 'READY' ? (
          <>
            <PortfolioSummaryContent
              currentScreenName={currentScreenName}
              draftPortfolio={draftPortfolio}
            />
            <div ref={hiddenDiv} />
          </>
        ) : (
          <Section>
            <SectionHeader>
              <Typography variant="h3">Client details</Typography>
            </SectionHeader>
            <SubSectionHeader>
              <Skeleton height="30px" />
            </SubSectionHeader>
            <SubSectionBody>
              <Skeleton height="30px" />
            </SubSectionBody>
          </Section>
        )}
      </Body>
    </Box>
  );
}
