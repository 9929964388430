/* eslint-disable react/no-this-in-sfc */
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { GetNotificationsParams } from '~/actions/notifications';
import { Notification } from '~/models/api';
import { ReactComponent as DocumentIcon } from '~/static/images/icons/document.svg';
import { ReactComponent as DotsIcon } from '~/static/images/icons/dots-horizontal.svg';
import { ReactComponent as TimeIcon } from '~/static/images/icons/time.svg';
import { ReactComponent as UserIcon } from '~/static/images/icons/user.svg';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import { TextHighlightTag } from '~/synth/Tag';
import { getNotifications, markNotificationAsRead } from '../../../actions';
import { Heading, HelperText, P, VirtualizedList } from '../../../components';
import { Colors } from '../../../constants';
import EmptyState from '../../../synth/deprecated/EmptyState';
import { CheckCircleIcon, NotificationIcon } from '../../../synth/Icons';
import { translate } from '../../../utils';

interface NotificationDrawerProps {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any;
  locale: string;
  markNotificationAsRead: (id?: string) => void;
  getNotifications: (options: GetNotificationsParams) => void;
  notifications: Notification[];
  notificationsCount: number;
  onClose: () => void;
  open: boolean;
}

interface NotificationDrawerState {
  actionTab: string;
  readAllAnchor?: HTMLElement;
}

const DrawerContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
  width: 480px;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 15px;
`;

const TableContainer = styled.div`
  display: flex;
`;

const noAllRowsRenderer = () => {
  return (
    <EmptyState
      icon={<CheckCircleIcon />}
      title="There are no actions or notifications from the last 7 days"
    />
  );
};

const noActionRowsRenderer = () => {
  return <EmptyState icon={<CheckCircleIcon />} title="There are no pending actions" />;
};

const noNotificationRowsRenderer = () => {
  return <EmptyState icon={<NotificationIcon />} title="All notifications have been read" />;
};

class NotificationDrawer extends React.Component<NotificationDrawerProps, NotificationDrawerState> {
  NotificationsItemsPerPage = 8;

  constructor(props: NotificationDrawerProps) {
    super(props);
    this.state = {
      actionTab: 'allActions',
    };
  }

  public onMarkAsRead = (notificationId: string) => {
    this.props.markNotificationAsRead(notificationId);
  };

  public onMarkAllAsRead = () => {
    this.props.markNotificationAsRead();
    this.props.onClose();
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public actionsRowRenderer = (filteredNotifications: any[]) => {
    const { history, locale, onClose } = this.props;
    return ({ index, key, style }) => {
      const notification = filteredNotifications[index];
      const {
        createdAt,
        data: { actionText, clientName, message, portfolioIntelligenceId, portfolioName, title },
        id,
        read,
        type,
        updatedAt,
      } = notification;
      const displayDate = moment(createdAt).format('LLLL');
      const completedDate = moment(updatedAt).format('LL');

      let buttonText;
      let buttonAction;
      switch (type) {
        case 'INFO':
          buttonText = translate(locale, 'buttons.markAsRead');
          buttonAction = () => {
            onClose();
            this.onMarkAsRead(id);
          };
          break;
        case 'ACTION':
          buttonText = actionText || '';
          buttonAction = actionText
            ? () => {
                onClose();
                this.onMarkAsRead(id);
                history.push(`/secure/portfolio-preview/${portfolioIntelligenceId}`);
              }
            : () => {};
          break;
        default:
          buttonText = translate(locale, 'buttons.markAsRead');
          buttonAction = () => {
            onClose();
            this.onMarkAsRead(id);
          };
      }

      return (
        <div key={key} style={style}>
          <div
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(142, 146, 161, 0.07), rgba(243, 246, 254, 0))`,
              padding: '25px 15px 15px 15px',
            }}
          >
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {notification.type === 'ACTION' && (
                <TextHighlightTag severity="priority">
                  {read ? 'Completed Action' : 'New Action'}
                </TextHighlightTag>
              )}
              {notification.type === 'INFO' && <Heading type="h3">{title}</Heading>}
              <span
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <TimeIcon
                  style={{
                    color: Colors.grayMiddle,
                    fontSize: '16px',
                    marginRight: '8px',
                  }}
                />
                <Typography color="textSecondary" variant="caption">
                  {displayDate}
                </Typography>
              </span>
            </div>
            <div>
              {notification.type === 'ACTION' && (
                <Heading margin="8px 0 0 0" type="h3">
                  {title}
                </Heading>
              )}
              <P margin="8px 0 25px 0" size="14px">
                {message}
              </P>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <span
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    marginBottom: '8px',
                  }}
                >
                  <UserIcon
                    style={{
                      color: Colors.grayMiddle,
                      fontSize: '16px',
                      marginRight: '8px',
                    }}
                  />
                  <Typography color="textSecondary">{clientName}</Typography>
                </span>
                <span
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <DocumentIcon
                    style={{
                      color: Colors.grayMiddle,
                      fontSize: '16px',
                      marginRight: '8px',
                    }}
                  />
                  <Typography color="textSecondary">{portfolioName}</Typography>
                </span>
              </div>
              {!read && (
                <Button
                  onClick={() => {
                    onClose();
                    this.onMarkAsRead(id);
                    buttonAction();
                  }}
                  variant="outlined"
                >
                  {buttonText}
                </Button>
              )}
              {read && (
                <P color={Colors.grayMiddle} size="12px">
                  {`${type === 'ACTION' ? 'Completed ' : 'Read '} ${completedDate}`}
                </P>
              )}
            </div>
          </div>
        </div>
      );
    };
  };

  public onTabClick = (
    _event: React.ChangeEvent<{}>,
    newValue: 'actionActions' | 'notificationActions'
  ) => {
    const { getNotifications } = this.props;
    getNotifications({
      limit: this.NotificationsItemsPerPage,
      offset: 0,
      type: newValue,
    });
    this.setState((prev: NotificationDrawerState) => ({ ...prev, actionTab: newValue }));
  };

  render() {
    const { notifications, open, onClose } = this.props;
    const { actionTab } = this.state;

    let noRowsRenderer;
    switch (actionTab) {
      case 'allActions':
        noRowsRenderer = noAllRowsRenderer;
        break;
      case 'actionActions':
        noRowsRenderer = noActionRowsRenderer;
        break;
      case 'notificationActions':
        noRowsRenderer = noNotificationRowsRenderer;
        break;
      default:
        noRowsRenderer = noAllRowsRenderer;
    }

    const numberOfPages = Math.ceil(this.props.notificationsCount / this.NotificationsItemsPerPage);

    const handlePageChange = (e, n) => {
      const offset = (n - 1) * this.NotificationsItemsPerPage;
      this.props.getNotifications({ limit: this.NotificationsItemsPerPage, offset });
    };

    const { readAllAnchor } = this.state;

    return (
      <Drawer anchor="right" onClose={onClose} open={open}>
        <DrawerContainer>
          <HeaderContainer>
            <Box display="flex">
              <Typography variant="h1">Notifications</Typography>
              <IconButton
                aria-label="display more actions"
                edge="end"
                color="inherit"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  this.setState({ readAllAnchor: e.currentTarget })
                }
                size="large"
              >
                <DotsIcon height="20" width="20" />
              </IconButton>
              <Menu
                anchorEl={readAllAnchor}
                keepMounted
                open={Boolean(readAllAnchor)}
                onClose={() => this.setState({ readAllAnchor: undefined })}
              >
                <MenuItem onClick={this.onMarkAllAsRead}>Mark All as read</MenuItem>
              </Menu>
            </Box>
            <Box position="absolute" right="4px" top="4px">
              <IconButton color="inherit" onClick={onClose} size="large">
                <XIcon height="20" width="20" />
              </IconButton>
            </Box>
          </HeaderContainer>
          <Box mx={2}>
            <Tabs value={actionTab} onChange={this.onTabClick}>
              <Tab label="All" value="allActions" />
              <Tab label="Action" value="actionActions" />
              <Tab label="Notifications" value="notificationActions" />
            </Tabs>
          </Box>
          <HelperText margin="8px 15px">{moment().format('MMMM DD, YYYY')}</HelperText>
          {numberOfPages > 1 && <Pagination count={numberOfPages} onChange={handlePageChange} />}
          <TableContainer style={{ height: '90%' }}>
            <VirtualizedList
              noRowsRenderer={noRowsRenderer}
              rowCount={notifications.length}
              rowHeight={230}
              rowRenderer={this.actionsRowRenderer(notifications)}
            />
          </TableContainer>
        </DrawerContainer>
      </Drawer>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => ({
  markNotificationAsRead: (id: string) => dispatch(markNotificationAsRead(id)),
  getNotifications: (options: GetNotificationsParams) => dispatch(getNotifications(options)),
});

const mapStateToProps = ({ app: { notifications, notificationsCount } }) => ({
  notifications,
  notificationsCount,
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ConnectedComponent<typeof Notifi... Remove this comment to see the full error message
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationDrawer));
