import { Box, ButtonBase, Collapse, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { ReactComponent as ChevronDown } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '~/static/images/icons/chevron-up.svg';

const useStyles = makeStyles()((theme) => ({
  root: {
    '&.MuiBox-root': {
      '&:hover': {
        color: theme.palette.grey[700],
        cursor: 'pointer',
      },
    },
  },
}));

export default function DisclosureExpansion({
  minimizedDisclosures,
  expandedDisclosures,
}: {
  minimizedDisclosures?: React.ReactNode;
  expandedDisclosures: React.ReactNode;
}) {
  const { classes: styles } = useStyles();
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Box>
      <Typography variant="caption" color="textSecondary">
        <Box>{minimizedDisclosures}</Box>
        <Collapse in={!collapsed}>
          <Box mt={minimizedDisclosures ? 1 : 0}>{expandedDisclosures}</Box>
        </Collapse>
      </Typography>
      <Box mt={minimizedDisclosures ? 1 : 0}>
        <ButtonBase onClick={() => setCollapsed(!collapsed)}>
          <Box color="grey.500" className={styles.root}>
            <Typography variant="h5">
              <Box display="flex" alignItems="center">
                <Box mr={1}>{minimizedDisclosures ? 'Full disclosures' : 'Disclosures'}</Box>
                {collapsed ? <ChevronDown /> : <ChevronUp />}
              </Box>
            </Typography>
          </Box>
        </ButtonBase>
      </Box>
    </Box>
  );
}

export function TaxDisclosureExpansion({
  minimizedTaxDisclosures,
}: {
  minimizedTaxDisclosures?: string;
}) {
  return (
    <DisclosureExpansion
      minimizedDisclosures={minimizedTaxDisclosures}
      expandedDisclosures={
        <>
          <Box mt={minimizedTaxDisclosures ? 1 : 0} mb={1}>
            Neither Vise nor any of our affiliates provides tax or legal advice and, therefore, are
            not responsible for developing, implementing, or evaluating any tax strategies that may
            be employed by the Client. The investment and tax strategies mentioned here may not be
            suitable for everyone. Each investor needs to review an investment or tax strategy for
            his or her own particular situation before making any decision.This information is not
            intended to be a substitute for specific individualized tax, legal or investment
            planning advice. Where specific advice is necessary or appropriate, Vise recommends
            consultation with a qualified tax advisor, CPA, financial planner or investment manager.
          </Box>
          Vise is not responsible for any trading activity that occurred prior to Vise&apos;s
          management of the account, and Vise may lack visibility to certain wash sales, should they
          occur as a result of transactions in unlinked or external accounts. Be aware that if the
          Client and/or the Client&apos;s spouse have other taxable or non-taxable investment
          accounts, and the Client holds in those accounts any of the securities (including options
          contracts) held in the Client&apos;s account at Vise, the Intermediary cannot trade any of
          those securities 30 days before or after Vise trades those same securities as part of the
          tax-loss harvesting strategy to avoid possible wash sales and, as a result, a
          nullification of any tax benefits of the strategy. For more information on the wash sale
          rule, please read IRS Publication 550.
        </>
      }
    />
  );
}
