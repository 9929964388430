import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { DistributionSchedule } from 'vise-types/distribution';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useDeleteDistribution from '~/hooks/useDeleteDistribution';
import DialogTitle from '~/synth/DialogTitle';
import amplitude from '~/utils/amplitude';

export interface CancelDistributionModalProps {
  open: boolean;
  onClose: () => void;
  distribution?: DistributionSchedule;
  mutateSchedule: () => void;
  setDistributionDetailsPanelSettings: ({
    open,
    distribution,
  }: {
    open: boolean;
    distribution?: DistributionSchedule;
  }) => void;
}

export default function CancelDistributionModal({
  open,
  onClose,
  distribution,
  mutateSchedule,
  setDistributionDetailsPanelSettings,
}: CancelDistributionModalProps) {
  const { scheduleId, accountId } = distribution || {};
  const { deleteDistribution } = useDeleteDistribution();
  const [isSubmitting, setIsSubmitting] = useState(false);
  async function handleCancelDistribution() {
    amplitude().logEvent('Action - Confirm cancel distribution schedule', {
      category: EVENT_CATEGORIES.DISTRIBUTIONS,
      scheduleId,
    });
    if (scheduleId != null && accountId != null && !isSubmitting) {
      setIsSubmitting(true);
      const deletionSuccess = await deleteDistribution(scheduleId, accountId);
      if (deletionSuccess) {
        onClose();
        setDistributionDetailsPanelSettings({ open: false, distribution: undefined });
        mutateSchedule();
      }
      setIsSubmitting(false);
    }
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Cancel Distribution</DialogTitle>
      <DialogContent dividers>
        <Box color="grey.600" py={1}>
          All information related to this schedule such as past distributions will be deleted and
          inaccessible. Are you sure you want to cancel this scheduled distribution?
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={distribution == null || isSubmitting}
          onClick={handleCancelDistribution}
        >
          Yes, cancel distribution
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            amplitude().logEvent('Action - Abort cancel distribution schedule', {
              category: EVENT_CATEGORIES.DISTRIBUTIONS,
              scheduleId,
            });
            onClose();
          }}
        >
          No, go back
        </Button>
      </DialogActions>
    </Dialog>
  );
}
