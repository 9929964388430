import { Box, Chip, FormControl, FormHelperText, FormLabel, Grid } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useState } from 'react';
import { ActionMeta } from 'react-select';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useSectors, { useIndexedSectors } from '~/hooks/useSectors';
import { Subsector } from '~/models/api';
import { XCircle } from '~/synth/Icons';
import StyledMultiselect from '~/synth/inputs/StyledMultiselect';
import StyledSelect from '~/synth/inputs/StyledSelect';
import amplitude from '~/utils/amplitude';
import { DetailsText } from './SummarySections';

export const SectorsHelperText = ({
  error = false,
  id = 'subsectors-helper',
}: {
  error?: boolean;
  id?: string;
}) => {
  return (
    <FormHelperText error={error} component="div" id={id}>
      {error ? <XCircle display="inline-flex" size="small" top="1px" /> : null}
      {error ? (
        <>Remove a few restrictions to ensure a well-optimized portfolio</>
      ) : (
        <>Select fewer than 40 sub-sectors to ensure a well-diversified portfolio</>
      )}
    </FormHelperText>
  );
};

type SectorOption = {
  label: string;
  value: string;
  industries: Subsector[];
};

type SubsectorOption = {
  label: string;
  value: string;
  sector: string;
};

type SectorsSelectorProps = {
  excludedSectors: string[];
  excludedIndustries: string[];
  chipsSectors: string[];
  chipsIndustries: string[];
  onChange: (
    industriesToAdd: string[] | null,
    industriesToDelete: string[] | null,
    sectorsToAdd: string[] | null,
    sectorsToDelete: string[] | null
  ) => void;
  /**
   * The name of the questionnaire step on which this selector is used,
   * to be sent with Amplitude events.
   */
  questionnaireStepName?: string | null;
};

export function sendRestrictionsChangeEvents(
  sectorsToAdd: string[] | null,
  sectorsToDelete: string[] | null,
  industriesToAdd: string[] | null,
  industriesToDelete: string[] | null,
  questionnaireStepName: string | null | undefined
) {
  if (industriesToAdd != null && !isEmpty(industriesToAdd)) {
    amplitude().logEvent('Add sub-sector restriction', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      restrictionQuestion: questionnaireStepName,
      subsector: industriesToAdd.length === 1 ? industriesToAdd[0] : industriesToAdd,
    });
  }
  if (industriesToDelete != null && !isEmpty(industriesToDelete)) {
    amplitude().logEvent('Remove sub-sector restriction', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      restrictionQuestion: questionnaireStepName,
      subsector: industriesToDelete.length === 1 ? industriesToDelete[0] : industriesToDelete,
    });
  }
  if (sectorsToAdd != null && !isEmpty(sectorsToAdd)) {
    amplitude().logEvent('Add sector restriction', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      restrictionQuestion: questionnaireStepName,
      sector: sectorsToAdd.length === 1 ? sectorsToAdd[0] : sectorsToAdd,
    });
  }
  if (sectorsToDelete != null && !isEmpty(sectorsToDelete)) {
    amplitude().logEvent('Remove sector restriction', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      restrictionQuestion: questionnaireStepName,
      sector: sectorsToDelete.length === 1 ? sectorsToDelete[0] : sectorsToDelete,
    });
  }
}

export default function SectorsSelector({
  excludedSectors,
  excludedIndustries,
  chipsSectors,
  chipsIndustries,
  questionnaireStepName,
  onChange,
}: SectorsSelectorProps) {
  const [selectedSector, setSelectedSector] = useState<SectorOption | null>(null);

  const { data: sectors } = useSectors();

  const { data: sectorsAndSubsectorsByKey } = useIndexedSectors();

  const excludedSectorsSet = useMemo(() => new Set(excludedSectors), [excludedSectors]);
  const excludedIndustriesSet = useMemo(() => new Set(excludedIndustries), [excludedIndustries]);

  const subsectorKeyToSectorKey = useMemo(
    () =>
      sectors == null
        ? {}
        : sectors.reduce((acc, sector) => {
            sector.industries.forEach((subsector) => {
              acc[subsector.key] = sector.key;
            });
            return acc;
          }, {}),
    [sectors]
  );
  const selectedSubsectorOptions = useMemo(() => {
    if (selectedSector == null) {
      return [];
    }
    // If the entire sector of this subsector has been selected,
    // or this specific subsector has been selected, include it
    const selectedSubsectors = excludedSectorsSet.has(selectedSector.value)
      ? selectedSector.industries
      : selectedSector.industries.filter((subsector) => excludedIndustriesSet.has(subsector.key));
    return selectedSubsectors.map((subsector) => ({
      label: subsector.name,
      value: subsector.key,
      sector: selectedSector.value,
    }));
  }, [excludedSectorsSet, excludedIndustriesSet, selectedSector]);

  const sectorOptions = useMemo(
    () =>
      sectors == null
        ? []
        : sectors
            .map((s) => ({
              label: s.name,
              value: s.key,
              industries: s.industries,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
    [sectors]
  );

  const subsectorOptions = useMemo(
    () =>
      selectedSector == null
        ? []
        : selectedSector.industries
            .map((ss) => ({
              label: ss.name,
              value: ss.key,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
    [selectedSector]
  );

  function handleSectorChange(option: SectorOption) {
    setSelectedSector(option);
  }

  function handleSubsectorsChange(
    allSelectedOptions: SubsectorOption[],
    { action }: ActionMeta<SubsectorOption>
  ) {
    if (
      action !== 'select-option' &&
      action !== 'deselect-option' &&
      action !== 'clear' &&
      action !== 'set-value'
    )
      return;
    let industriesToAdd: string[] | null = null;
    let sectorsToAdd: string[] | null = null;
    let industriesToDelete: string[] | null = null;
    let sectorsToDelete: string[] | null = null;

    if (selectedSector && allSelectedOptions.length === selectedSector.industries.length) {
      // If all subsectors in the current sector are selected,
      // clear the selected industries in the sector from `excludedIndustries`
      //  and then add the sector to `excludedSectors`
      industriesToDelete = excludedIndustries.filter(
        (subsector) => subsectorKeyToSectorKey[subsector] === selectedSector.value
      );
      sectorsToAdd = [selectedSector.value];
    } else {
      // If not all subsectors in the curent sector are selected,
      // clear the sector from `excludedSectors` (if it's there)
      // and then add all the selected industries (if any) to `excludedIndustries`
      if (selectedSector && excludedSectorsSet.has(selectedSector.value)) {
        sectorsToDelete = [selectedSector.value];
      }
      const selectedSubsectors = allSelectedOptions.map((s) => s.value);
      const selectedSubsectorsSet = new Set(selectedSubsectors);
      industriesToAdd = selectedSubsectors.filter((s) => !excludedIndustriesSet.has(s));
      industriesToDelete = excludedIndustries.filter(
        (s) => subsectorKeyToSectorKey[s] === selectedSector?.value && !selectedSubsectorsSet.has(s)
      );
    }
    sendRestrictionsChangeEvents(
      sectorsToAdd,
      sectorsToDelete,
      industriesToAdd,
      industriesToDelete,
      questionnaireStepName
    );
    onChange(industriesToAdd, industriesToDelete, sectorsToAdd, sectorsToDelete);
  }

  function handleDeleteSectorOrSubsectorChip(key: string) {
    if (sectorsAndSubsectorsByKey) {
      if (excludedSectorsSet.has(key)) {
        // If it's a sector
        amplitude().logEvent('Remove sector restriction', {
          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
          restrictionQuestion: questionnaireStepName,
          sector: sectorsAndSubsectorsByKey[key].name,
          usedChip: true,
        });
        onChange(null, null, null, [key]);
      } else {
        // If it's a subsector
        amplitude().logEvent('Remove sub-sector restriction', {
          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
          restrictionQuestion: questionnaireStepName,
          subsector: sectorsAndSubsectorsByKey[key].name,
          usedChip: true,
        });
        onChange(null, [key], null, null);
      }
    }
  }

  return (
    <>
      <FormControl fullWidth>
        <Box mb={1}>
          <FormLabel htmlFor="sectors">Sectors</FormLabel>
          <Box mt={0.5}>
            <DetailsText color="textSecondary" variant="body1">
              Exclude groups of companies based on their Standard Industrial Classification (SIC)
              code, which is how industries are classified in SEC filings.
            </DetailsText>
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={4}>
            <StyledSelect
              isLoading={sectorOptions == null}
              options={sectorOptions}
              onChange={handleSectorChange}
              placeholder="All sectors"
              isSearchable={false}
              onFocus={() => {
                amplitude().logEvent('Tap sector dropdown', {
                  category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                  restrictionQuestion: questionnaireStepName,
                });
              }}
              classNamePrefix="react-select"
              inputId="sectors"
              value={selectedSector}
            />
          </Grid>
          <Grid item xs={8}>
            <StyledMultiselect
              aria-describedby="subsectors-helper"
              isDisabled={selectedSector == null}
              // Need this map for the onChange handler
              isLoading={subsectorKeyToSectorKey == null}
              options={subsectorOptions}
              allowSelectAll
              onFocus={() => {
                amplitude().logEvent('Tap sub-sector dropdown', {
                  category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                  restrictionQuestion: questionnaireStepName,
                });
              }}
              classNamePrefix="react-select"
              onChange={handleSubsectorsChange}
              placeholder="Browse sub-sectors"
              inputId="subsectors"
              value={selectedSubsectorOptions}
            />
          </Grid>
        </Grid>
      </FormControl>
      {sectorsAndSubsectorsByKey &&
        chipsSectors.concat(chipsIndustries).map((sector) => (
          <Box mr={1.5} mt={1.5} key={sector}>
            <Chip
              label={sectorsAndSubsectorsByKey[sector].name}
              onDelete={() => {
                handleDeleteSectorOrSubsectorChip(sector);
              }}
            />
          </Box>
        ))}
    </>
  );
}
