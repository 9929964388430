import { Box, Card, Grid, Link, ThemeProvider, Tooltip } from '@mui/material';
import { keyBy, uniq, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Column } from 'react-table';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { AssetClassKey, Feature } from 'vise-types/pce2_instrument';
import { Account, PortfolioPositionResponse } from 'vise-types/portfolio';
import { PortfolioMetricsResponse } from 'vise-types/portfolio_metrics';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import usePortfolioInsights from '~/hooks/usePortfolioInsights';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import CardContent from '~/synth/CardContent';
import CardHeader from '~/synth/CardHeader';
import { DataTable, DataTableContainer, DataTableTitle } from '~/synth/DataTable';
import Skeleton from '~/synth/Skeleton';
import { formatCurrency, formatPercent } from '~/utils/format';
import { getAssetClassFeaturesFromKey } from '~/utils/pce2Migration';
import { theme, Notification, tokens } from '@vise_inc/ds-vise';
import amplitude from '../../utils/amplitude';
import { ASSET_CLASS_TO_LABEL_MAP } from '../PortfolioCreator2/Constants';
import { ViewDetailsLink } from './PortfolioInsights';
import AllocationCard2 from './card/AllocationCard2';
import InsightsCardFooter from './card/InsightsCardFooter';
import AllocationMap from './components/AllocationMap';
import HoldingsModal from './components/HoldingsModal';
import {
  AllocationBar,
  PCE2AssetNameBox,
  RightAlignBox,
  ViewPositionsButton,
} from './components/TableComponents';
import {
  AssetAllocationWithPositionsAndValue,
  PricedPortfolioHolding,
  addPositionsAndValueToAssetAllocation,
  adjustAllocationByPctOfManagedValue,
  getPCE2AccountPositionsRowData,
  getPCE2AssetClassTitleAndSubtitle,
  groupAssetAllocation,
  transformPCE2AssetClassAllocations,
  transformPCE2SectorAllocations,
} from './portfolioUtil';

interface PortfolioAllocationProps {
  intelligence: PortfolioIntelligenceFull;
  account: Account;
  positions: PortfolioPositionResponse;
  pce2Metrics: PortfolioMetricsResponse;
}

export const AllocationLoadingState = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Card style={{ height: '27em' }}>
            <CardHeader>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Skeleton variant="text" width="15%" />
                <Skeleton variant="text" width="25%" />
              </Box>
            </CardHeader>
            <CardContent style={{ height: '100%' }}>
              <Skeleton width="100%" height="100%" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ height: '27em' }}>
            <CardHeader>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Skeleton variant="text" width="15%" />
                <Skeleton variant="text" width="25%" />
              </Box>
            </CardHeader>
            <CardContent style={{ height: '100%' }}>
              <Skeleton width="100%" height="100%" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Skeleton width="100%" height="50vh" />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default function PortfolioAllocation2({
  intelligence,
  account,
  positions,
  pce2Metrics,
}: PortfolioAllocationProps) {
  useEffect(() => {
    amplitude().logEvent('Impression - Portfolio Allocation', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
  }, []);
  if (intelligence.status === 'REJECTED' || intelligence.proposalType === 'light') {
    throw new Error('');
  }

  const { data: featureFlags } = useFeatureFlags();

  const {
    pctOfManagedValue,
    unadjustedAssetAllocation,
    unadjustedTargetAssetAllocation,
    unadjustedSectorAllocation,
    lockedUnrecognizedFraction,
  } = React.useMemo(() => {
    return {
      pctOfManagedValue: pce2Metrics ? pce2Metrics.portfolioMetrics.managedFraction : 0.0,
      lockedUnrecognizedFraction: pce2Metrics
        ? pce2Metrics.portfolioMetrics.lockedUnrecognizedFraction
        : 0.0,
      unadjustedAssetAllocation: pce2Metrics
        ? transformPCE2AssetClassAllocations(pce2Metrics.portfolioMetrics.assetClassAllocations)
        : [],
      unadjustedTargetAssetAllocation: pce2Metrics.strategyMetrics?.targetPortfolio
        .assetClassAllocations
        ? transformPCE2AssetClassAllocations(
            pce2Metrics.strategyMetrics.targetPortfolio.assetClassAllocations
          )
        : [],

      unadjustedSectorAllocation: pce2Metrics
        ? transformPCE2SectorAllocations(pce2Metrics.portfolioMetrics.sectorAllocations)
        : [],
    };
  }, [pce2Metrics]);

  const [adjustedAssetAllocation, adjustedTargetAssetAllocation] = React.useMemo(() => {
    return [
      adjustAllocationByPctOfManagedValue(
        unadjustedAssetAllocation,
        lockedUnrecognizedFraction,
        false
      ),
      adjustAllocationByPctOfManagedValue(
        unadjustedTargetAssetAllocation,
        pce2Metrics.strategyMetrics?.targetPortfolio.lockedUnrecognizedFraction || 0,
        false
      ),
    ];
  }, [
    unadjustedAssetAllocation,
    lockedUnrecognizedFraction,
    unadjustedTargetAssetAllocation,
    pce2Metrics.strategyMetrics?.targetPortfolio.lockedUnrecognizedFraction,
  ]);

  const [holdingsModalOpen, setHoldingsModalOpen] = useState(false);
  const [holdingsModalTitle, setHoldingsModalTitle] = useState('');
  const [holdingsModalSubtitle, setHoldingsModalSubtitle] = useState<string | undefined>(undefined);
  const [holdingsModalData, setHoldingsModalData] = useState<PricedPortfolioHolding[]>([]);
  const [holdingsModalColumns, setHoldingsModalColumns] = useState<'asset' | 'sector' | undefined>(
    undefined
  );

  function openModal(
    title: string,
    data: PricedPortfolioHolding[],
    type?: 'asset' | 'sector',
    subtitle?: string
  ) {
    setHoldingsModalTitle(title);
    setHoldingsModalData(data);
    setHoldingsModalColumns(type);
    setHoldingsModalSubtitle(subtitle);
    amplitude().logEvent('OpenHoldingsModal', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
    setHoldingsModalOpen(true);
  }

  const positionsGroupedByAssetClass = React.useMemo(() => {
    const lockedTickers = new Set(intelligence.constructionRequest.doNotSellSymbolsList);

    return getPCE2AccountPositionsRowData(
      positions.positions,
      account.custodianKey,
      lockedTickers,
      (securityType, assetClass): Feature => {
        const features = getAssetClassFeaturesFromKey(assetClass);
        if (features.length > 0 && ASSET_CLASS_TO_LABEL_MAP.get(features[0])) {
          return features[0] as Feature;
        }

        if (securityType === 'CASH_OR_EQIV' || securityType === 'MF') {
          return 'CASH';
        }

        return 'UNKNOWN';
      }
    );
  }, [
    intelligence.constructionRequest.doNotSellSymbolsList,
    positions.positions,
    account.custodianKey,
  ]);

  const sortedHoldings: PricedPortfolioHolding[] = React.useMemo(() => {
    const allHoldings = Object.values(positionsGroupedByAssetClass).flat();
    const sorted = allHoldings.sort((a, b) =>
      a.value != null && b.value != null && a.value < b.value ? 1 : -1
    );
    return sorted.map((holding, idx) => {
      return { ...holding, ranking: idx + 1 };
    });
  }, [positionsGroupedByAssetClass]);

  const nonCashHoldings = React.useMemo(
    () => sortedHoldings.filter((h) => h.assetClass !== 'CASH'),
    [sortedHoldings]
  );

  const topAssetAllocation = React.useMemo(
    () => Math.max(...adjustedAssetAllocation.map((asset) => asset.y)),
    [adjustedAssetAllocation]
  );

  const groupedAssetAllocation = React.useMemo(
    () =>
      addPositionsAndValueToAssetAllocation(positionsGroupedByAssetClass, adjustedAssetAllocation),
    [positionsGroupedByAssetClass, adjustedAssetAllocation]
  );

  const groupedAdjustedTargetAssetAllocation = groupAssetAllocation(adjustedTargetAssetAllocation);

  const adjustedTargetAssetAllocationByName = keyBy(
    adjustedTargetAssetAllocation,
    (alloc) => alloc.name
  ) as Partial<Record<AssetClassKey, { name: string; y: number }>>;

  const allCategories = uniq([
    ...Object.keys(groupedAssetAllocation),
    ...Object.keys(groupedAdjustedTargetAssetAllocation),
  ]);

  const groupedAssetAllocationWithTarget: {
    [key: string]: {
      name: string;
      y: number;
      target: number;
      positions?: PricedPortfolioHolding[];
      value?: number;
    }[];
  } = {};
  allCategories.forEach((category) => {
    const groupedAssetHoldings = (groupedAssetAllocation[category] || []).map((alloc) => ({
      ...alloc,
      target: adjustedTargetAssetAllocationByName[alloc.name]?.y || 0,
    }));
    const targetHoldings = (groupedAdjustedTargetAssetAllocation[category] || []).map((alloc) => ({
      name: alloc.name,
      target: alloc.y,
      y: 0,
    }));
    groupedAssetAllocationWithTarget[category] = uniqBy(
      [...groupedAssetHoldings, ...targetHoldings],
      (holding) => holding.name
    );
  });

  // Columns for bottom table with holdings grouped by asset class
  const wholePortfolioColumns = React.useMemo(
    () =>
      [
        {
          Header: 'Sub-asset class',
          accessor: 'name',
          Cell: (props: { value: AssetClassKey }) => {
            const assetDisplayName = getPCE2AssetClassTitleAndSubtitle(
              props.value,
              intelligence.constructionInfo.smallAccount
            );
            return (
              <PCE2AssetNameBox
                title={assetDisplayName.title}
                subtitle={assetDisplayName.subtitle}
              />
            );
          },
        },
        {
          Header: (
            <Box display="flex" alignItems="center">
              <Box mr={0.5}>Target</Box>
              {featureFlags?.disable_small_accounts_ui === 'on' ? (
                <Tooltip title="If your portfolio is currently on a glide path or its managed value is below $2,500 then your current target allocation may differ from the portfolio's original target allocation.">
                  <Box display="flex" alignItems="center">
                    <InformationCircleIcon />
                  </Box>
                </Tooltip>
              ) : null}
            </Box>
          ),
          accessor: 'target',
          Cell: (props: { value?: number }) => {
            let text: string;
            if (!props.value) {
              text = '-';
            } else {
              text = props.value < 0.0005 ? '< 0.1%' : formatPercent(props.value, 1);
            }
            return <RightAlignBox>{text}</RightAlignBox>;
          },
          align: 'right',
        },
        {
          Header: 'Current',
          accessor: 'y',
          Cell: (props: { value?: number }) => {
            let text: string;
            if (!props.value) {
              text = '-';
            } else {
              text = props.value < 0.0005 ? '< 0.1%' : formatPercent(props.value, 1);
            }
            return <RightAlignBox>{text}</RightAlignBox>;
          },
          align: 'right',
        },
        {
          accessor: 'color',
          Cell: (props: { row: { original: { y: number } } }) => (
            <AllocationBar
              allocation={props.row.original.y}
              topAssetAllocation={topAssetAllocation}
            />
          ),
        },
        {
          Header: 'Value',
          accessor: 'value',
          Cell: (props) =>
            props.value && props.value > 0 ? (
              <RightAlignBox>{formatCurrency(props.value)}</RightAlignBox>
            ) : (
              <RightAlignBox>-</RightAlignBox>
            ),
          align: 'right',
        },
        {
          Header: '# of positions',
          accessor: 'positions',
          Cell: (props: {
            value?: PricedPortfolioHolding[];
            row: { original: { name: string } };
          }) => {
            const assetName = props.row.original.name;
            const assetDisplayName = getPCE2AssetClassTitleAndSubtitle(
              assetName as AssetClassKey,
              intelligence.constructionInfo.smallAccount
            );
            if (!props.value) {
              return <RightAlignBox>-</RightAlignBox>;
            }
            return (
              <ViewPositionsButton
                count={props.value.length}
                onClick={() => {
                  openModal(
                    assetDisplayName?.title ?? assetName,
                    props.value || [],
                    assetName === 'Cash' ||
                      assetName === 'CASH' ||
                      assetName === 'Unclassified securities' ||
                      assetName === 'UNKNOWN'
                      ? undefined
                      : 'sector',
                    assetDisplayName?.subtitle
                  );
                }}
              />
            );
          },
          align: 'right',
        },
      ] as Column<AssetAllocationWithPositionsAndValue>[],
    [
      intelligence.constructionInfo.smallAccount,
      topAssetAllocation,
      featureFlags?.disable_small_accounts_ui,
    ]
  );

  const holdingsTableButton = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      onClick={() => {
        openModal('All holdings', nonCashHoldings, 'asset');
      }}
      component="button"
      variant="h4"
      color="textPrimary"
    >
      View all {nonCashHoldings.length} holdings
    </Link>
  );

  const { data: portfolioInsightsResponse } = usePortfolioInsights(intelligence?.id);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={featureFlags?.enable_map === 'on' ? 6 : 12}>
          <AllocationCard2
            assetAllocation={unadjustedAssetAllocation}
            pctOfManagedValue={pctOfManagedValue}
            sectorAllocation={unadjustedSectorAllocation}
            lockedUnrecognizedFraction={lockedUnrecognizedFraction}
            portfolioInsights={
              portfolioInsightsResponse ? portfolioInsightsResponse.data : undefined
            }
            account={account}
            expanded={featureFlags?.enable_map !== 'on'}
            showInsights
          />
        </Grid>
        {featureFlags?.enable_map === 'on' ? (
          <Grid item xs={6}>
            <AllocationMap
              assetAllocation={unadjustedAssetAllocation}
              positions={sortedHoldings}
              restrictedCountries={intelligence.constructionRequest.doNotHoldCountriesList}
            />
          </Grid>
        ) : null}
        <Grid item>
          {!intelligence.constructionInfo.smallAccount &&
          pce2Metrics.smallAccount &&
          featureFlags?.disable_small_accounts_ui === 'on' ? (
            <ThemeProvider theme={theme}>
              <Box mb={tokens.SPACING_INDICES.lg}>
                <Notification variant="notice">
                  This account&apos;s target asset allocation will be optimized for low-Vise managed
                  values. You can customize the asset allocation with our expanded investment
                  offerings once the account surpasses $5,000.
                </Notification>
              </Box>
            </ThemeProvider>
          ) : null}
          <DataTableContainer>
            {pctOfManagedValue != null && pctOfManagedValue < 1 && (
              <DataTableTitle py={2.5} title="Asset classes" metaData={holdingsTableButton} />
            )}
            {Object.entries(groupedAssetAllocationWithTarget).map(([assetClass, holdings]) => {
              return holdings.length ? (
                <DataTable
                  columns={wholePortfolioColumns}
                  data={holdings}
                  subHeader={ASSET_CLASS_TO_LABEL_MAP.get(assetClass as Feature)}
                  key={assetClass}
                  rowSize="large"
                />
              ) : null;
            })}
            {portfolioInsightsResponse && (
              <InsightsCardFooter
                title={`About ${account.accountNameShort}'s asset classes`}
                body={portfolioInsightsResponse.data.subAssetAllocation.insightText}
                link={
                  <Box mt={2.5} mb={1}>
                    <ViewDetailsLink account={account} openInsights={['subAssetAllocation']} />
                  </Box>
                }
              />
            )}
          </DataTableContainer>
        </Grid>
      </Grid>
      {holdingsModalOpen && (
        <HoldingsModal
          title={holdingsModalTitle}
          subtitle={holdingsModalSubtitle}
          type={holdingsModalColumns}
          data={holdingsModalData}
          onClose={() => setHoldingsModalOpen(false)}
          open={holdingsModalOpen}
        />
      )}
    </>
  );
}
