import {
  Box,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState, useMemo, useCallback } from 'react';
import { Column, TableState, usePagination, UsePaginationState, useTable } from 'react-table';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import CardHeader from '~/synth/CardHeader';
import { isEqual, keyBy } from 'lodash';
import { DataTable } from '~/synth/DataTable';
import { SearchIcon } from '~/synth/Icons';
import TextField from '~/synth/TextField';
import PageNavigation from '~/components/table/PageNavigation';
import {
  TemplateUpdateJob,
  TEMPLATE_TYPE,
  GetModelTemplateCenterViewDataResponse,
  AllocationsTemplate,
  RestrictionsTemplate,
} from 'vise-types/template';
import { ReactComponent as PlusCircleIcon } from '~/static/images/icons/plus-circle.svg';
import { matchSorter } from 'match-sorter';
import ValueCell from '~/routes/Households/Households/ValueCell';
import { formatCurrency } from '~/utils/format';
import AccountFilter, {
  filterAccounts,
  initialFilterState,
} from '~/components/portfolio/AccountFilter';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { Action } from './templateLinkReducer';
import { AccountCell, Cell, sortLinkingTable, useIconButtonColor } from './CommonComponents';
import { formatTemplateNames, getAccountsWithExistingNames } from '../utils';

const PAGE_SIZE = 10;

function TemplateNameCell(props: { value: string[] }) {
  const content = formatTemplateNames(props.value);
  return (
    <Box px={1}>
      <Typography variant="body2">{content}</Typography>
    </Box>
  );
}

interface LinkTableData {
  checked: {
    toAdd: boolean;
    onToggleAdd: () => void;
    inProgressTemplateLinkingJob?: TemplateUpdateJob | null;
    disabledDueToQualifiedStatus?: boolean;
    testId: string;
  };
  account: AccountWithPIAndHouseholdInfo;
  value: number;
  currentTemplateNames: string[]; // allocations: template | restrictions: number of templates
  templateType: TEMPLATE_TYPE;
}

function LinkCell(props: {
  value: {
    toAdd: boolean;
    onToggleAdd: () => void;
    inProgressTemplateLinkingJob?:
      | (TemplateUpdateJob & { submittedBy: { firstName: string; lastName: string } })
      | null;
    disabledDueToQualifiedStatus?: boolean;
    testId: string;
  };
}) {
  const buttonDisabled =
    !!props.value.inProgressTemplateLinkingJob || !!props.value.disabledDueToQualifiedStatus;
  const theme = useTheme();
  const buttonColor = useIconButtonColor(
    theme.palette.success[400],
    props.value.toAdd,
    buttonDisabled
  );
  const body = (
    <Box>
      <IconButton
        aria-label={props.value.toAdd ? 'Checked' : 'Unchecked'}
        onClick={props.value.onToggleAdd}
        disabled={buttonDisabled}
        size="large"
        data-testid={props.value.testId}
      >
        <PlusCircleIcon height={25} width={25} color={buttonColor} />
      </IconButton>
    </Box>
  );
  if (props.value.inProgressTemplateLinkingJob || props.value.disabledDueToQualifiedStatus) {
    return (
      <Tooltip
        title={
          props.value.inProgressTemplateLinkingJob
            ? `This account cannot be unlinked right now because there is a template update for it in progress started by ${props.value.inProgressTemplateLinkingJob.submittedBy.firstName} ${props.value.inProgressTemplateLinkingJob.submittedBy.lastName}.`
            : 'Non-taxable accounts are not eligible to invest in municipal bonds.'
        }
      >
        {body}
      </Tooltip>
    );
  }
  return body;
}

function TemplateNameHeader(props) {
  return (
    <Box>
      Current {props.data[0]?.templateType === 'allocations' ? 'allocation' : 'restriction'}{' '}
      template(s)
    </Box>
  );
}

const linkTableHeaderCells = {
  checked: function CheckedComponent(props) {
    const theme = useTheme();
    const buttonColor = useIconButtonColor(
      theme.palette.success[400],
      props.column.allChecked,
      false
    );
    return (
      <Box pl={0.5}>
        <IconButton
          aria-label={props.column.allChecked ? 'Checked' : 'Unchecked'}
          onClick={() => props.column.onToggleAllChecked()}
          size="large"
        >
          <PlusCircleIcon height={25} width={25} color={buttonColor} />
        </IconButton>
      </Box>
    );
  },
  account: () => <Cell value="Account" />,
  value: () => <Cell value="Value" />,
  currentTemplateNames: TemplateNameHeader,
};

const linkTableColumns: Column<LinkTableData>[] = [
  {
    Header: linkTableHeaderCells.checked,
    accessor: 'checked',
    Cell: LinkCell,
    width: '8%',
    align: 'center',
  },
  { Header: linkTableHeaderCells.account, accessor: 'account', Cell: AccountCell, width: '31%' },
  {
    Header: linkTableHeaderCells.value,
    accessor: 'value',
    Cell: (props) => <ValueCell value={formatCurrency(props.value)} isLoading={false} />,
    width: '18%',
  },
  {
    Header: TemplateNameHeader,
    accessor: 'currentTemplateNames',
    Cell: TemplateNameCell,
  },
];

export default function AccountLinkTable({
  data,
  dispatch,
  linkAccountIds,
  template,
}: {
  data: GetModelTemplateCenterViewDataResponse;
  dispatch: (value: Action) => void;
  linkAccountIds: string[];
  template: AllocationsTemplate | RestrictionsTemplate;
}) {
  const [filterQuery, setFilterQuery] = useState<string>('');
  const accountsById = useMemo(() => keyBy(data.accounts, 'id'), [data.accounts]);
  const addedAccountIds = useMemo(() => keyBy(linkAccountIds), [linkAccountIds]);

  const accountsWithExistingNames = useMemo(
    () => getAccountsWithExistingNames(data, template),
    [data, template]
  );

  const linkAccount = useCallback(
    (accountId: string) => {
      dispatch({
        type: 'LINK_ACCOUNT',
        accountId,
      });
    },
    [dispatch]
  );

  const accountTableDataWithoutDropdownFilters = useMemo(() => {
    const linksToTargetTemplate = data.templateLinks.filter(
      (t) => t.originalTemplateId === template.originalTemplateId
    );
    const currentlyLinkedAccountIds = keyBy(linksToTargetTemplate, 'accountId');
    const currentlyUnlinkedAccounts = accountsWithExistingNames.filter(
      (account) => currentlyLinkedAccountIds[account.id] === undefined
    );

    const templateHasMunicipalAllocations =
      template.type === 'allocations' &&
      typeof template.allocations['FIXED_INCOME/DOMESTIC/MUNICIPAL'] === 'number' &&
      template.allocations['FIXED_INCOME/DOMESTIC/MUNICIPAL'] > 0;
    const accounts = currentlyUnlinkedAccounts
      .filter((acc) => acc.status === 'ACTIVE')
      .map(
        (account) =>
          ({
            checked: {
              toAdd: !!addedAccountIds[account.id],
              onToggleAdd: () => linkAccount(account.id),
              inProgressTemplateLinkingJob: account.inProgressTemplateLinkingJob,
              disabledDueToQualifiedStatus: templateHasMunicipalAllocations && !account.taxable,
              testId: `link-${account.custodianKey}-${account.accountNumber}`,
            },
            account: accountsById[account.id],
            value: accountsById[account.id].cachedAum,
            currentTemplateNames: account.existingTemplateNames,
            templateType: template.type,
          } as LinkTableData)
      );
    return sortLinkingTable(
      matchSorter(accounts, filterQuery, {
        keys: ['account.accountName'],
        threshold: matchSorter.rankings.CONTAINS,
      })
    );
  }, [
    data.templateLinks,
    template,
    accountsWithExistingNames,
    filterQuery,
    addedAccountIds,
    accountsById,
    linkAccount,
  ]);

  const [toggleFilterState, setToggleFilterState] = useState(initialFilterState);
  const { data: modelTemplateCenterData } = useModelTemplateCenterViewData(false);
  const accountTableData = useMemo(() => {
    if (modelTemplateCenterData?.data) {
      const accountIds = filterAccounts(
        accountTableDataWithoutDropdownFilters.map((row) => row.account),
        toggleFilterState,
        modelTemplateCenterData.data
      ).map((acct) => acct.id);
      return accountTableDataWithoutDropdownFilters.filter((row) =>
        accountIds.includes(row.account.id)
      );
    }
    return accountTableDataWithoutDropdownFilters;
  }, [accountTableDataWithoutDropdownFilters, modelTemplateCenterData?.data, toggleFilterState]);

  const allVisibleAndSelectableAccountIds = useMemo(
    () =>
      accountTableData
        .filter(
          (row) =>
            !row.checked.disabledDueToQualifiedStatus && !row.checked.inProgressTemplateLinkingJob
        )
        .map((row) => row.account?.id),
    [accountTableData]
  );

  const allChecked = isEqual(new Set(linkAccountIds), new Set(allVisibleAndSelectableAccountIds));

  const columnsWithToggleData = useMemo(() => {
    return [
      {
        ...linkTableColumns[0],
        allChecked,
        onToggleAllChecked: () =>
          dispatch({ type: 'LINK_ACCOUNTS', accountIds: allVisibleAndSelectableAccountIds }),
      },
      ...linkTableColumns.slice(1),
    ];
  }, [allChecked, allVisibleAndSelectableAccountIds, dispatch]);

  const accountTable = useTable<LinkTableData>(
    {
      columns: columnsWithToggleData,
      data: accountTableData,
      initialState: {
        pageSize: PAGE_SIZE,
      } as TableState<LinkTableData> & UsePaginationState<LinkTableData>,
      autoResetPage: false,
    },
    usePagination
  );

  const theme = useTheme();

  return (
    <Box>
      <Card>
        <CardHeader>
          <Box display="flex" alignItems="end" margin="auto">
            <Box width="536px">
              <Typography variant="h6" color="textSecondary">
                STEP{' '}
                {data.templateLinks.filter(
                  (l) => l.originalTemplateId === template.originalTemplateId
                ).length === 0
                  ? '1'
                  : '2'}
              </Typography>
              <Box mb={0.5} />
              <Box display="flex" alignItems="center">
                <Typography variant="h3">Apply to new accounts</Typography>
                {linkAccountIds.length > 0 && (
                  <>
                    <Box bgcolor="grey.300" mx={1.5}>
                      <Divider orientation="vertical" flexItem style={{ height: '17.5px' }} />
                    </Box>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                    >{` ${linkAccountIds.length} added `}</Typography>
                  </>
                )}
              </Box>
              <Box mb={0.5} />
              <Typography variant="body2" color="textSecondary">
                Add any accounts you’d like to link to this template.{' '}
                {template.type === 'restrictions'
                  ? 'Restriction templates are additive and do not replace existing restriction templates.'
                  : ''}
              </Typography>
            </Box>
            <Box flex={1} />
            <Box width="360px">
              <TextField
                fullWidth
                onChange={(e) => {
                  setFilterQuery(e.target.value);
                  accountTable.gotoPage(0);
                }}
                placeholder="Search by name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <AccountFilter
                      accounts={accountTableDataWithoutDropdownFilters.map((row) => row.account)}
                      filterState={toggleFilterState}
                      onApplyFilter={(filterState) => {
                        setToggleFilterState(filterState);
                        accountTable.gotoPage(0);
                      }}
                      eventCategory={EVENT_CATEGORIES.STRATEGY_CENTER}
                    />
                  ),
                  sx: { paddingRight: 0 },
                }}
              />
            </Box>
          </Box>
        </CardHeader>
        <Box width="972px">
          <DataTable
            customRowProps={(row) =>
              row.values.checked.toAdd
                ? { style: { backgroundColor: theme.palette.success[100] } }
                : {}
            }
            m={0}
            table={accountTable}
            rowSize="medium"
          />
          {accountTableData.length > PAGE_SIZE && (
            <Box px={3} py={4} display="flex" borderTop={`1px solid ${theme.palette.grey[100]}`}>
              <Box flex={1} />
              <PageNavigation
                currentPage={
                  (accountTable.state as UsePaginationState<LinkTableData>).pageIndex + 1
                }
                numItems={accountTableData.length}
                pageSize={PAGE_SIZE}
                onNextPageClick={() => {
                  accountTable.nextPage();
                }}
                onPrevPageClick={() => {
                  accountTable.previousPage();
                }}
                onPageNumberClick={(pageNumber) => {
                  accountTable.gotoPage(pageNumber - 1);
                }}
              />
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
}
