import { Screen } from './Types';
import ActiveTiltsScreen from './screens/ActiveTiltsScreen';
import CapitalLossesAndGainsScreen from './screens/CapitalLossesAndGainsScreen';
import CashAllocationScreen from './screens/CashAllocationScreen';
import CashPositionScreen from './screens/CashPositionScreen';
import CreateClientScreen from './screens/CreateClientScreen';
import {
  CsvRealizedGainsUploadScreen,
  CsvUnrealizedGainsUploadScreen,
} from './screens/CsvUploadScreen';
import CustomAllocations from './screens/CustomAllocations';
import PCE2SmallAccountAssetClassScreen from './screens/CustomAllocations/PCE2SmallAccountAssetClassScreen';
import ManualAddPositionsScreen from './screens/ManualAddPositionsScreen';
import ProposalTypeScreen from './screens/ProposalTypeScreen';
import ReviewPortfolioScreen from './screens/ReviewPortfolioScreen';
import SelectClientScreen from './screens/SelectClientScreen';
import SummaryLandingScreen from './screens/SummaryLandingScreenV2';
import TickerNumberScreen from './screens/TickerNumberScreen';
import TornadoScreen from './screens/TornadoScreen';
import BuildOrUseAllocationTemplateScreen from './screens/allocation/BuildOrUseAllocationTemplateScreen';
import SelectAllocationsTemplatesScreen from './screens/allocation/SelectAllocationsTemplatesScreen';
import SpecialConditionsScreen from './screens/allocation/SpecialConditionsScreen';
import BuildRestrictionsScreen from './screens/restrictions/BuildRestrictionsScreen';
import SelectRestrictionsTemplatesScreen from './screens/restrictions/SelectRestrictionsTemplatesScreen';
import { isSmallAccount, shouldSkipRestrictions, showTickerNumberScreen } from './utils';
import LockedPositionsScreen from './screens/LockedPositionsScreen';
import DayOneClosedLotsScreen from './DayOneClosedLotsScreen';
import { SMALL_ACCOUNT_MIGRATION_STARTING_POINT } from './Constants';

function clientValidator({
  draftPortfolio: {
    constructionInfo: { clientId },
    newClientInfo,
    newHouseholdInfo,
  },
}) {
  return clientId != null || (newClientInfo != null && newHouseholdInfo != null);
}

function searchPidValidator({ searchPid }) {
  return typeof searchPid === 'string';
}

const screens = {
  'select-client': {
    component: SelectClientScreen,
    order: 1,
    prereqs: [],
    getPrevScreen() {
      return '';
    },
    showSummary: true,
    stepperValue: 'client-details',
    next: {
      createClient: 'create-client',
      default: 'proposal-type',
    },
  },
  'create-client': {
    component: CreateClientScreen,
    order: 1.1,
    prereqs: [],
    getPrevScreen() {
      return 'select-client';
    },
    showSummary: true,
    stepperValue: 'client-details',
    next: { default: 'proposal-type' },
  },
  'proposal-type': {
    component: ProposalTypeScreen,
    prereqs: [[clientValidator]],
    order: 2,
    getPrevScreen(draftPortfolio) {
      const {
        constructionInfo: { clientId },
      } = draftPortfolio;
      if (clientId === null) {
        return 'create-client';
      }
      return 'select-client';
    },
    showSummary: true,
    stepperValue: 'client-details',
    next: {
      cashAllocation: 'cash-allocation',
      csvUploadSelectCustodian: 'csv-upload-unrealized',
      dayOneClosedLots: 'day-one-closed-lots',
      default: 'locked-positions',
    },
  },
  'manual-upload-positions': {
    component: ManualAddPositionsScreen,
    prereqs: [[clientValidator]],
    order: 3.1,
    getPrevScreen() {
      return 'proposal-type';
    },
    showSummary: false,
    stepperValue: 'client-details',
    next: {
      default: 'upload-cash-position',
    },
  },
  // CsvUploadScreen
  'csv-upload-unrealized': {
    component: CsvUnrealizedGainsUploadScreen,
    prereqs: [[clientValidator]],
    order: 4,
    getPrevScreen() {
      return 'proposal-type';
    },
    showSummary: true,
    stepperValue: 'client-details',
    next: {
      default: 'csv-upload-realized',
      manual: 'manual-upload-positions',
      cash: 'upload-cash-position',
    },
  },
  'csv-upload-realized': {
    component: CsvRealizedGainsUploadScreen,
    prereqs: [
      [
        clientValidator,
        'constructionInfo.xRayFiles.unrealizedGains',
        'constructionInfo.existingPortfolio',
      ],
    ],
    order: 5,
    getPrevScreen() {
      return 'csv-upload-unrealized';
    },
    showSummary: true,
    stepperValue: 'client-details',
    next: {
      default: 'upload-cash-position',
    },
  },
  'upload-cash-position': {
    component: CashPositionScreen,
    prereqs: [[clientValidator, 'constructionInfo.existingPortfolio']],
    order: 6,
    getPrevScreen(draftPortfolio) {
      const account = draftPortfolio.constructionInfo.existingPortfolio;
      if (account !== 'sample-portfolio' && account?.taxable) {
        return 'csv-upload-realized';
      }
      return 'csv-upload-unrealized';
    },
    showSummary: true,
    stepperValue: 'client-details',
    next: { default: 'review-portfolio' },
  },
  'review-portfolio': {
    component: ReviewPortfolioScreen,
    prereqs: [
      [
        clientValidator,
        'constructionInfo.xRayFiles.unrealizedGains',
        'constructionInfo.xRayFiles.realizedGains',
        'constructionInfo.existingPortfolio',
      ],
    ],
    order: 7,
    getPrevScreen() {
      return 'upload-cash-position';
    },
    showSummary: false,
    stepperValue: 'client-details',
    next: { default: 'locked-positions', editHoldings: 'manual-upload-positions' },
  },
  'day-one-closed-lots': {
    component: DayOneClosedLotsScreen,
    prereqs: [[clientValidator]],
    order: 2.5,
    getPrevScreen() {
      return 'proposal-type';
    },
    showSummary: true,
    stepperValue: 'transition-settings',
    next: {
      default: 'locked-positions',
    },
  },
  'locked-positions': {
    component: LockedPositionsScreen,
    prereqs: [[clientValidator, 'constructionInfo.existingPortfolio']],
    order: 3,
    getPrevScreen(draftPortfolio) {
      const { existingPortfolio } = draftPortfolio.constructionInfo;
      if (
        existingPortfolio !== 'sample-portfolio' &&
        existingPortfolio?.accountDataSource === 'XRAY'
      ) {
        return 'review-portfolio';
      }
      return 'proposal-type';
    },
    showSummary: true,
    defaultsSetOnLand: ['lockedPositions'],
    stepperValue: 'transition-settings',
    next: {
      default: 'cash-allocation',
      summary: 'summary-landing',
    },
  },
  'cash-allocation': {
    component: CashAllocationScreen,
    order: 4,
    defaultsSetOnLand: ['constructionInfo.concentrationLimits'],
    getPrevScreen(draftPortfolio) {
      const { existingPortfolio } = draftPortfolio.constructionInfo;
      if (existingPortfolio === 'sample-portfolio') {
        return 'proposal-type';
      }
      return 'locked-positions';
    },
    prereqs: [
      [clientValidator, 'constructionInfo.existingPortfolio', 'constructionInfo.initialValue'],
    ],
    showSummary: true,
    stepperValue: 'transition-settings',
    next: {
      default: 'build-or-use-allocation-template',
      riskTolerance: 'risk-tolerance',
      summary: 'summary-landing',
    },
  },
  'build-or-use-allocation-template': {
    component: BuildOrUseAllocationTemplateScreen,
    // Avoid dependency cycle
    order: SMALL_ACCOUNT_MIGRATION_STARTING_POINT,
    getPrevScreen() {
      return 'cash-allocation';
    },
    prereqs: [
      [clientValidator, 'constructionInfo.existingPortfolio', 'constructionInfo.initialValue'],
    ],
    showSummary: true,
    stepperValue: 'allocation',
    next: {
      default: 'custom-allocation',
      selectTemplate: 'select-allocation-template',
      summary: 'summary-landing',
    },
  },
  'select-allocation-template': {
    component: SelectAllocationsTemplatesScreen,
    prereqs: [
      [clientValidator, 'constructionInfo.existingPortfolio', 'constructionInfo.assetAllocation'],
    ],
    getPrevScreen() {
      return 'build-or-use-allocation-template';
    },
    order: 7.1,
    showSummary: false,
    stepperValue: 'allocation',
    next: {
      default: 'fine-tune-strategy',
    },
  },
  'custom-allocation': {
    component: CustomAllocations,
    defaultsSetOnLand: ['constructionInfo.assetClasses'],
    prereqs: [
      [clientValidator, 'constructionInfo.existingPortfolio', 'constructionInfo.assetAllocation'],
    ],
    getPrevScreen() {
      return 'build-or-use-allocation-template';
    },
    order: 7.2,
    showSummary: true,
    stepperValue: 'allocation',
    next: {
      default: 'fine-tune-strategy',
      specialConditions: 'special-conditions',
      summary: 'summary-landing',
    },
  },
  'fine-tune-strategy': {
    component: TickerNumberScreen,
    order: 8,
    getPrevScreen(draftPortfolio) {
      if (draftPortfolio.useAllocationTemplate) {
        return 'select-allocation-template';
      }
      return 'custom-allocation';
    },
    prereqs: [
      [clientValidator, 'constructionInfo.existingPortfolio', 'constructionInfo.initialValue'],
    ],
    showSummary: true,
    stepperValue: 'allocation',
    next: { default: 'special-conditions', summary: 'summary-landing' },
  },
  'special-conditions': {
    component: SpecialConditionsScreen,
    order: 9,
    getPrevScreen(draftPortfolio) {
      const {
        constructionInfo: { assetClassConcentrationLimits },
        accountSize,
        useAllocationTemplate,
      } = draftPortfolio;
      if (showTickerNumberScreen(accountSize, assetClassConcentrationLimits?.exclusions)) {
        return 'fine-tune-strategy';
      }
      if (useAllocationTemplate) {
        return 'select-allocation-template';
      }
      return 'custom-allocation';
    },
    prereqs: [
      [clientValidator, 'constructionInfo.existingPortfolio', 'constructionInfo.initialValue'],
    ],
    showSummary: true,
    stepperValue: 'allocation',
    next: {
      default: 'active-tilts',
      summary: 'summary-landing',
      capGains: 'capital-losses-and-gains',
      buildRestrictions: 'build-restrictions',
    },
  },
  'active-tilts': {
    component: ActiveTiltsScreen,
    order: 10,
    defaultsSetOnLand: ['constructionInfo.activeTilt'],
    getPrevScreen() {
      return 'special-conditions';
    },
    prereqs: [
      [clientValidator, 'constructionInfo.existingPortfolio', 'constructionInfo.initialValue'],
    ],
    showSummary: true,
    stepperValue: 'tilts',
    next: {
      default: 'build-restrictions',
      summary: 'summary-landing',
    },
  },
  'build-restrictions': {
    component: BuildRestrictionsScreen,
    order: 11,
    defaultsSetOnLand: [
      'constructionInfo.excludedSectors',
      'constructionInfo.restrictedStocks',
      'constructionInfo.excludedIndustries',
      'constructionInfo.excludedCountries',
      'constructionInfo.excludedEsgAreas',
    ],
    getPrevScreen(draftPortfolio) {
      if (draftPortfolio.allocationTemplate?.tiltSelection) {
        return 'special-conditions';
      }
      return 'active-tilts';
    },
    prereqs: [
      [clientValidator, 'constructionInfo.existingPortfolio', 'constructionInfo.initialValue'],
    ],
    showSummary: true,
    stepperValue: 'restrictions',
    next: {
      default: 'summary-landing',
      selectRestrictionsTemplate: 'select-restrictions-template',
      summary: 'summary-landing',
      capGains: 'capital-losses-and-gains',
    },
  },
  'select-restrictions-template': {
    component: SelectRestrictionsTemplatesScreen,
    order: 11.1,
    getPrevScreen() {
      return 'build-restrictions';
    },
    prereqs: [
      [clientValidator, 'constructionInfo.existingPortfolio', 'constructionInfo.initialValue'],
    ],
    showSummary: false,
    stepperValue: 'restrictions',
    next: { default: 'build-restrictions' },
  },
  'capital-losses-and-gains': {
    component: CapitalLossesAndGainsScreen,
    order: 12,
    getPrevScreen(draftPortfolio) {
      const {
        accountSize,
        constructionInfo: { etfExclusive, assetClassConcentrationLimits },
      } = draftPortfolio;

      if (isSmallAccount(draftPortfolio.accountSize)) {
        return 'asset-classes';
      }
      if (
        shouldSkipRestrictions({
          etfExclusive,
          exclusions: assetClassConcentrationLimits?.exclusions,
          accountSize,
        })
      ) {
        return 'active-tilts';
      }
      return 'build-restrictions';
    },
    prereqs: [[clientValidator, 'constructionInfo.existingPortfolio']],
    showSummary: true,
    defaultsSetOnLand: ['constructionInfo.autoTlh'],
    stepperValue: 'taxes',
    next: {
      default: 'summary-landing',
    },
  },
  'summary-landing': {
    component: SummaryLandingScreen,
    order: 13,
    getPrevScreen(draftPortfolio) {
      const {
        constructionInfo: { existingPortfolio },
      } = draftPortfolio;

      if (existingPortfolio !== 'sample-portfolio' && existingPortfolio?.taxable) {
        return 'capital-losses-and-gains';
      }

      if (isSmallAccount(draftPortfolio.accountSize)) {
        return 'asset-classes';
      }

      return 'build-restrictions';
    },
    prereqs: [
      // TODO(rteammco): Review the prereqs here
      [searchPidValidator],
      [clientValidator, 'constructionInfo.existingPortfolio', 'constructionInfo.initialValue'],
    ],
    showSummary: false,
    stepperValue: 'summary',
    next: null,
    showStepper: () => false,
  },
} as { [key: string]: Screen };

const smallAccountSpecificScreens = {
  'risk-tolerance': {
    component: TornadoScreen,
    order: 6,
    getPrevScreen() {
      return 'cash-allocation';
    },
    prereqs: [
      [
        clientValidator,
        'constructionInfo.existingPortfolio',
        'constructionInfo.initialValue',
        'constructionInfo.focus',
      ],
    ],
    showSummary: true,
    stepperValue: 'portfolio-focus',
    next: {
      default: 'asset-classes',
      summary: 'summary-landing',
    },
  },
  'asset-classes': {
    component: PCE2SmallAccountAssetClassScreen,
    order: 6.1,
    defaultsSetOnLand: [
      'constructionInfo.assetClasses',
      'constructionInfo.assetClassConcentrationLimits.equities',
      'constructionInfo.assetClassConcentrationLimits.exclusions',
      'constructionInfo.assetClassConcentrationLimits.fixedIncome',
      'constructionInfo.etfExclusive',
      'constructionInfo.etfExclusiveAssetClasses',
    ],
    getPrevScreen() {
      return 'risk-tolerance';
    },
    prereqs: [
      [
        clientValidator,
        'constructionInfo.existingPortfolio',
        'constructionInfo.initialValue',
        'constructionInfo.focus',
        'constructionInfo.investmentTimeline',
      ],
    ],
    showSummary: true,
    stepperValue: 'asset-classes',
    next: {
      default: 'summary-landing',
      capGains: 'capital-losses-and-gains',
    },
  },
} as { [key: string]: Screen };

export default function useScreens(isSmall: boolean) {
  if (isSmall) {
    return { ...screens, ...smallAccountSpecificScreens };
  }
  return screens;
}
