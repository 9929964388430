import { Box, Button, Collapse, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { OptionProps, OptionsType, ValueType, components } from 'react-select';
import styled from 'styled-components';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { useCoachmarkEffect } from '~/hooks/useCoachmark';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import usePositions from '~/hooks/usePositions';
import LoadingSpinner from '~/synth/LoadingSpinner';
import { TextHighlightTag } from '~/synth/Tag';
import Select from '~/synth/inputs/Select';
import amplitude from '~/utils/amplitude';
import { tokens } from '@vise_inc/ds-vise';
import { getClosedLots } from '~/api/api';
import useProductOnboarding from '~/hooks/useProductOnboarding';
import { InputDollar } from '../../../components';
import useClientAccounts from '../../../hooks/useClientAccounts';
import { Account } from '../../../models/api';
import TextField from '../../../synth/TextField';
import {
  formatCurrency,
  getAccountTypeDisplayName,
  maskAccountNumber,
} from '../../../utils/format';
import { ProposalType, ScreenProps } from '../Types';
import LinkAccountIcon from '../icons/LinkAccountIcon';
import SampleIcon from '../icons/SampleIcon';
import UploadIcon from '../icons/UploadIcon';
import {
  ActionFooter,
  BackButton,
  Card,
  CardSection,
  CardTextTitleAndDescription,
  ContentBox,
} from './components';
import { XrayDispatchContext } from '../context/XrayContext';

interface TaxableOption {
  label: string;
  value: boolean;
}

interface AccountOption {
  account: Account;
  label: ReactNode;
  value: string;
}

function AccountOption(props: OptionProps<AccountOption>) {
  const { isSelected } = props;
  const { account }: AccountOption = props.data;
  const typographyProps = isSelected ? null : { color: 'textSecondary' as const };

  return (
    <components.Option {...props}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <strong>
            {account.firstName} {account.lastName}
          </strong>
        </Box>
        <Box>
          <Typography {...typographyProps}>
            {account.cachedAum != null && <>Portfolio value: {formatCurrency(account.cachedAum)}</>}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography {...typographyProps}>
          {getAccountTypeDisplayName(account.accountType)}{' '}
          {maskAccountNumber(account.accountNumber)}
        </Typography>
      </Box>
    </components.Option>
  );
}

const StyledProposalBoxFooter = styled(Box).attrs({ mt: 2, pt: 2 })`
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

function AccountSelectFooter({
  accounts,
  isDisabled,
  isLoading,
  onChange,
  value,
  showValue,
}: {
  accounts: Account[] | undefined;
  isDisabled: boolean;
  isLoading: boolean;
  onChange: (value: ValueType<AccountOption>) => void;
  value: Account | null;
  showValue?: boolean;
}) {
  const options: OptionsType<AccountOption> | undefined = useMemo(
    () =>
      accounts == null
        ? undefined
        : accounts.map((acc) => ({
            account: acc,
            label: `${acc.firstName} ${acc.lastName} — ${getAccountTypeDisplayName(
              acc.accountType
            )} ${maskAccountNumber(acc.accountNumber)}`,
            value: acc.id,
          })),
    [accounts]
  );

  const selectedOption = useMemo(
    // Check `account.id` for equality rather than Object identity because the accounts might be
    // reloaded and result in new Objects.
    () => (options == null ? null : options.find((opt) => opt.account.id === value?.id)),
    [value, options]
  );

  return (
    <StyledProposalBoxFooter>
      <Select<AccountOption>
        autoFocus
        noOptionsMessage={() => 'There are no accounts associated with this client.'}
        components={{ Option: AccountOption }}
        isClearable
        isDisabled={isDisabled}
        isLoading={isLoading}
        onFocus={() => {
          amplitude().logEvent('Tap account drop down', {
            category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
          });
        }}
        onChange={onChange}
        options={options}
        placeholder="Select an account…"
        value={selectedOption}
      />
      {value && showValue && (
        <Box
          py={1}
          px={1}
          my={2}
          display="flex"
          justifyContent="space-between"
          sx={{ backgroundColor: tokens.palette.neutralCool[100] }}
        >
          <Typography>Portfolio value</Typography>
          {formatCurrency(value.cachedAum)}
        </Box>
      )}
    </StyledProposalBoxFooter>
  );
}

type RealProposalTypeCollapseState = 'in' | 'out';

export function SampleProposalCard({
  initialValue,
  active,
  disabled,
  onClick,
  onBlur,
  onChange,
}: {
  initialValue?: number | null;
  active: boolean;
  disabled?: boolean;
  onClick: () => void;
  onBlur: () => void;
  onChange: (event: { target: { value: number } }) => void;
}) {
  return (
    <Card active={active} disabled={disabled} onClick={onClick} selectable>
      <CardSection>
        <Box display="flex">
          <Box mr={2}>
            <SampleIcon active={active} />
          </Box>
          <CardTextTitleAndDescription title="Build a sample proposal from cash">
            Build a sample proposal based on an initial portfolio value.
          </CardTextTitleAndDescription>
        </Box>
        <Collapse in={active}>
          <StyledProposalBoxFooter>
            <TextField
              InputProps={{ inputComponent: InputDollar }}
              autoFocus
              fullWidth
              id="initial_value"
              label="Initial portfolio value"
              onBlur={onBlur}
              // @ts-expect-error FIXME: Type 'string' is not assignable to type 'number'
              onChange={onChange}
              placeholder="ex. $200,000"
              value={initialValue}
            />
          </StyledProposalBoxFooter>
        </Collapse>
      </CardSection>
    </Card>
  );
}

export function RealProposalCard({
  account,
  accountSelectIsDisabled,
  active,
  clientId,
  disabled,
  onChange,
  onClick,
  showValue = false,
  taxableOnly = false,
}) {
  const [realProposalTypeCollapseState, setRealProposalTypeCollapseState] =
    useState<RealProposalTypeCollapseState>(account == null ? 'out' : 'in');
  const { data: accountsData, isLoading: accountsIsLoading } = useClientAccounts(clientId);
  let accounts = accountsData;
  if (taxableOnly) {
    accounts = accountsData?.filter((a) => a.taxable);
  }

  const cardDisabled = disabled || accounts == null || accounts.length === 0;
  return (
    <Card active={active} disabled={cardDisabled} mb={2} onClick={onClick} selectable>
      <CardSection>
        <Box display="flex">
          <Box mr={2}>
            <LinkAccountIcon disabled={disabled} active={active} />
          </Box>
          <CardTextTitleAndDescription title="Link an account">
            Build a proposal around an existing client&apos;s custodian account so you can execute
            it later.
          </CardTextTitleAndDescription>
        </Box>
        <Collapse
          in={active}
          onEnter={() => {
            setRealProposalTypeCollapseState('in');
          }}
          onExited={() => {
            setRealProposalTypeCollapseState('out');
          }}
        >
          {realProposalTypeCollapseState === 'in' ? (
            <AccountSelectFooter
              showValue={showValue}
              accounts={accounts}
              isDisabled={accountSelectIsDisabled}
              isLoading={accountsIsLoading}
              onChange={onChange}
              value={account}
            />
          ) : null}
        </Collapse>
      </CardSection>
    </Card>
  );
}

export default function ProposalTypeScreen({
  draftPortfolio: {
    constructionInfo: { clientId, existingPortfolio, initialValue: dpInitialValue },
    isEditMode,
    lockedPositions,
  },
  dpDispatch,
  onBack,
  onContinue,
}: ScreenProps) {
  useEffect(() => {
    amplitude().logEvent('Impression - Proposal Selection', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);

  const theme = useTheme();

  const { data: featureFlags } = useFeatureFlags();
  const xrayEnabled = featureFlags?.enable_portfolio_xray === 'on';

  const { productOnboardingRequired } = useProductOnboarding();

  const [taxableSelected, setTaxableSelected] = useState(false);
  const xrayDispatch = useContext(XrayDispatchContext);
  useEffect(() => xrayDispatch({ type: 'RESET_XRAY_CONTEXT' }), [xrayDispatch]);

  const [proposalType, setProposalType] = useState<ProposalType | null>(() => {
    if (existingPortfolio === 'sample-portfolio') return 'sample';
    if (existingPortfolio != null) return 'real';
    return null;
  });

  const { data: positionsData, error: positionsError } = usePositions(
    existingPortfolio === 'sample-portfolio' ? null : existingPortfolio?.accountNumber,
    existingPortfolio === 'sample-portfolio' ? null : existingPortfolio?.custodianKey
  );

  const isLoadingPositionData =
    positionsError == null &&
    positionsData == null &&
    existingPortfolio != null &&
    existingPortfolio !== 'sample-portfolio';

  // Filter out locked unrecognized positions from initial value
  const calculatedInitialValue = useMemo(() => {
    const lockedPositionsSet = new Set(lockedPositions);
    return positionsData?.positions.reduce(
      (acc, curr) =>
        lockedPositionsSet.has(curr.symbolOrCusip) && curr.securityType === 'UNKNOWN'
          ? acc
          : acc + curr.marketValue,
      0
    );
  }, [lockedPositions, positionsData?.positions]);

  const [initialValue, setInitialValue] = useState(
    existingPortfolio === 'sample-portfolio' ? dpInitialValue : calculatedInitialValue
  );

  const existingAccountIsChosen =
    existingPortfolio != null && existingPortfolio !== 'sample-portfolio';
  const existingAccountIsNotFunded =
    existingPortfolio !== 'sample-portfolio' &&
    existingPortfolio?.status === 'INACTIVE' &&
    existingPortfolio?.statusReason === 'empty_position_info';
  const existingAccountHasMissingTaxLots =
    existingPortfolio !== 'sample-portfolio' &&
    existingPortfolio?.status === 'INACTIVE' &&
    existingPortfolio?.statusReason === 'missing_tax_info';

  useEffect(() => {
    if (existingAccountIsChosen && existingAccountIsNotFunded) {
      amplitude().logEvent('Account selection error', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        accountName: existingPortfolio?.name,
      });
    }
  }, [existingAccountIsChosen, existingAccountIsNotFunded, existingPortfolio]);

  useEffect(() => {
    if (calculatedInitialValue != null) {
      dpDispatch({
        type: 'SET_INITIAL_VALUE',
        initialValue: calculatedInitialValue,
      });
    }
  }, [positionsData, dpDispatch, calculatedInitialValue]);

  useCoachmarkEffect({
    show: existingAccountIsChosen && positionsError != null,
    title: 'Please select another account',
    content: 'We ran into an error loading information for the selected account',
    severity: 'error',
  });

  useCoachmarkEffect({
    show: existingAccountIsChosen && existingAccountIsNotFunded,
    title: 'Please select another account.',
    content: 'This account needs to be funded before a portfolio can be built.',
    severity: 'error',
  });

  useCoachmarkEffect({
    show: existingAccountIsChosen && existingAccountHasMissingTaxLots,
    title: 'Account error',
    content:
      'Portfolio creation is not currently available due to missing or invalid tax lots. ' +
      'Please reach out to clientservice@vise.com for assistance.',
    severity: 'error',
  });

  function handleChangeAccount(opt: { account: Account | null }) {
    if (opt?.account) {
      amplitude().logEvent('Tap account', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        accountName: opt.account.name,
      });
    }
    dpDispatch({
      type: 'SET_ACCOUNT',
      existingPortfolio: opt?.account == null ? null : opt.account,
    });
  }

  function handleChangeInitialValue(event: { target: { value: number } }) {
    const nextInitialValue = event.target.value;
    setInitialValue(nextInitialValue);
  }

  function handleBlurInitialValue() {
    dpDispatch({ type: 'SET_INITIAL_VALUE', initialValue: initialValue ?? null });
  }

  function handleSetProposalType(nextProposalType: ProposalType) {
    dpDispatch({ type: 'SET_PROPOSAL_TYPE', proposalType: nextProposalType });
    setProposalType(nextProposalType);
    let action: string;
    if (nextProposalType === 'real') action = 'executable';
    else if (nextProposalType === 'sample') action = 'sample';
    else action = 'imported';
    amplitude().logEvent(`Select ${action} proposal`, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    let nextInitialValue: number | null;
    if (proposalType === 'sample') {
      nextInitialValue = initialValue ?? null;
    } else if (positionsData != null) {
      nextInitialValue = positionsData.positions.reduce((acc, curr) => acc + curr.marketValue, 0);
    } else {
      nextInitialValue = null;
    }

    // Set initial value in case user hit 'enter' on keyboard to move to the next screen
    // without ever blurring the input field
    dpDispatch({
      type: 'SET_INITIAL_VALUE',
      initialValue: nextInitialValue,
    });

    // navigate to dayOneClosedLots only if account is <90 days old & has no existing manual closed lots
    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(new Date().getDate() - 90);
    let needsDayOneClosedLots =
      existingAccountIsChosen &&
      existingPortfolio.taxable &&
      ninetyDaysAgo < new Date(existingPortfolio.createdAt);

    if (needsDayOneClosedLots && existingAccountIsChosen) {
      const closedLots = await getClosedLots(existingPortfolio.id);
      needsDayOneClosedLots = !closedLots.find((lot) => lot.accountDataSource === 'MANUAL');
    }

    if (existingPortfolio === 'sample-portfolio') {
      amplitude().logEvent('Continue to cash allocation', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      });
      onContinue('cashAllocation');
    } else if (proposalType === 'upload') {
      amplitude().logEvent('Continue to csv upload', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      });
      onContinue('csvUploadSelectCustodian');
    } else if (needsDayOneClosedLots && featureFlags?.enable_day_one_closed_lots === 'on') {
      onContinue('dayOneClosedLots');
    } else {
      amplitude().logEvent('Continue to locked positions', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      });
      onContinue('default');
    }
  }

  const isValid =
    (proposalType === 'real' &&
      existingAccountIsChosen &&
      !existingAccountHasMissingTaxLots &&
      !existingAccountIsNotFunded &&
      !isLoadingPositionData) ||
    (proposalType === 'sample' && initialValue != null) ||
    (proposalType === 'upload' && taxableSelected);

  return (
    <ContentBox>
      <Box mb={4}>
        <Typography variant="h1">How do you want to proceed?</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <RealProposalCard
          account={existingPortfolio}
          accountSelectIsDisabled={isEditMode}
          active={proposalType === 'real'}
          clientId={clientId}
          disabled={isEditMode}
          onClick={() => {
            if (!isEditMode && proposalType !== 'real') handleSetProposalType('real');
          }}
          onChange={handleChangeAccount}
        />
        {featureFlags?.enable_upload_positions === 'on' && (
          <Card
            disabled={isEditMode}
            onClick={() => {
              if (!isEditMode && proposalType !== 'upload') handleSetProposalType('upload');
            }}
            selectable
            active={proposalType === 'upload'}
            mb={2}
            data-testid="upload_posotions_menu_item"
          >
            <CardSection>
              <Box display="flex">
                <Box mr={2}>
                  <UploadIcon active={proposalType === 'upload'} />
                </Box>
                <CardTextTitleAndDescription
                  title={
                    <Box display="flex" alignItems="center">
                      {xrayEnabled ? 'Import a portfolio' : "Import a client's portfolio"}
                      <TextHighlightTag severity="priority" ml={1.5} color="blue.600">
                        {xrayEnabled && (
                          <Box pr={1} display="flex" justifyContent="center">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.33333 1V3.66667V1ZM1 2.33333H3.66667H1ZM3 10.3333V13V10.3333ZM1.66667 11.6667H4.33333H1.66667ZM7.66667 1L9.19067 5.57133L13 7L9.19067 8.42867L7.66667 13L6.14267 8.42867L2.33333 7L6.14267 5.57133L7.66667 1Z"
                                stroke="#1753DE"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Box>
                        )}
                        {xrayEnabled ? 'New X-Ray Analysis' : 'New'}
                      </TextHighlightTag>
                    </Box>
                  }
                >
                  {xrayEnabled
                    ? "Build a sample proposal and use Vise's new X-Ray comparison tool to compare existing holdings versus a Vise managed portfolio."
                    : "Build a sample proposal by importing an existing client's portfolio or manually entering a prospective client's current holdings."}
                </CardTextTitleAndDescription>
              </Box>
              <Collapse in={proposalType === 'upload'}>
                <StyledProposalBoxFooter>
                  <Select
                    id="taxable"
                    name="taxable"
                    options={
                      [
                        { label: 'Qualified', value: false },
                        { label: 'Non-qualified', value: true },
                      ] as TaxableOption[]
                    }
                    onChange={(v) => {
                      if (v != null) {
                        const option = v as TaxableOption;
                        xrayDispatch({ type: 'SET_XRAY_TAXABLE', taxable: option.value });
                        setTaxableSelected(true);
                      }
                    }}
                  />
                </StyledProposalBoxFooter>
              </Collapse>
            </CardSection>
          </Card>
        )}
        <SampleProposalCard
          active={proposalType === 'sample'}
          disabled={isEditMode || productOnboardingRequired}
          onClick={() => {
            if (!isEditMode && proposalType !== 'sample') handleSetProposalType('sample');
          }}
          initialValue={initialValue}
          onBlur={handleBlurInitialValue}
          onChange={handleChangeInitialValue}
        />
        <ActionFooter justifyContent="space-between" sx={{ borderTop: 1 }}>
          <BackButton onClick={() => onBack()} />
          <Box display="flex">
            {isLoadingPositionData && (
              <Box mr={1} title="Loading account position data…">
                <LoadingSpinner color={theme.palette.primary.dark} size="2rem" />
              </Box>
            )}
            <Button
              color="primary"
              disabled={!isValid}
              style={{ flexShrink: 0 }}
              type="submit"
              variant="contained"
            >
              Continue
            </Button>
          </Box>
        </ActionFooter>
      </form>
    </ContentBox>
  );
}
