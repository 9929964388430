import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getActiveTransaction } from '@sentry/tracing';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import LoadingSpinner from '../../synth/LoadingSpinner';
import { getNotifications } from '../../actions/notifications';
import { fetchTokenForSSOLogin, verifyUser } from '../../actions/user';
import { Content } from '../../components';
import { RootState } from '../../reducers';
import { Route } from '../../models/shared';
import renderRoutes from '../renderRoutes';

const MainLayout: React.FunctionComponent<React.PropsWithChildren<{ route: Route }>> = ({
  route,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading: featureFlagIsLoading } = useFeatureFlags(true); // Always revalidate when the layout is reloaded.

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(history.location.search);

      // If we logged in via SSO, we need to fetch a token using our session cookie.
      const ssoLogin = searchParams.get('sso');
      if (ssoLogin) {
        await dispatch(fetchTokenForSSOLogin());

        searchParams.delete('sso');
        history.replace({ search: searchParams.toString() });
      }

      const valid = await dispatch(verifyUser());
      if (!valid) {
        // Temporary workaround. This logic messes with the sentry react-router
        // tracing, so we do a little bit of fixing here.
        const activeTransaction = getActiveTransaction();
        if (activeTransaction) {
          activeTransaction.setName('/login');
          activeTransaction.setTag('isRedirect', true);
          activeTransaction.setTag('path', history.location.pathname);
          activeTransaction.setTag('search', history.location.search);
          activeTransaction.setTag('hash', history.location.hash);
        }

        history.push({
          pathname: '/login',
          search: `?path=${encodeURIComponent(
            history.location.pathname
          )}&search=${encodeURIComponent(history.location.search)}&hash=${encodeURIComponent(
            history.location.hash
          )}`,
        });
        return;
      }

      dispatch(getNotifications({ limit: 8, offset: 0 }));
    })();
  }, [dispatch, history]);

  const currentUser = useSelector((state: RootState) => state.users.currentUser);

  const currentUserIsLoading = currentUser == null;

  return currentUserIsLoading || featureFlagIsLoading ? (
    <Content>
      <LoadingSpinner size={100} />
    </Content>
  ) : (
    renderRoutes(route?.routes, currentUser)
  );
};

export default MainLayout;
