import { exportCSV } from './export';
import { tokenStore } from './tokenStore';
import translate from './translator';
import WithLoader from './WithLoader';

export { exportCSV, tokenStore, translate, WithLoader };

export const almostEqual = (num: number, compareTo: number) => {
  return Math.abs(num - compareTo) < 1e-6;
};
