import { Box, Button, Card, CardContent, Divider, Tooltip, Typography } from '@mui/material';
import { sumBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { fillInXrayLots } from '~/api/api';
import { PCE2_INCONSISTENT_LOTS_TOLERANCE } from '~/constants';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import usePortfolioOverview from '~/hooks/usePortfolioOverview';
import usePositions from '~/hooks/usePositions';
import AllocationCard2 from '~/routes/Portfolio/card/AllocationCard2';
import {
  transformPCE2AssetClassAllocations,
  transformPCE2SectorAllocations,
} from '~/routes/Portfolio/portfolioUtil';
import { ReactComponent as WarningIcon } from '~/static/images/icons/exclamation.svg';
import CardHeader from '~/synth/CardHeader';
import Skeleton from '~/synth/Skeleton';
import { TextHighlightTag } from '~/synth/Tag';
import amplitude from '~/utils/amplitude';
import { ScreenProps } from '../Types';
import { XrayContext } from '../context/XrayContext';
import { BackButton, StickyBottomActionContainer } from './components';
import HoldingsTable from './components/HoldingsTable';
import IncompleteTaxLotsModal from './components/IncompleteTaxLotsModal';

export default function ReviewPortfolioScreen({ onBack, onContinue, draftPortfolio }: ScreenProps) {
  const {
    constructionInfo: { existingPortfolio },
  } = draftPortfolio;

  useEffect(() => {
    amplitude().logEvent('Impression - Reveiw uploaded portfolio screen', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);

  const [taxLotsModalOpen, setTaxLotsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const enqueueCoachmark = useEnqueueCoachmark();

  const { data: positionsData } = usePositions(
    existingPortfolio != null && existingPortfolio !== 'sample-portfolio'
      ? existingPortfolio.accountNumber
      : null,
    existingPortfolio != null && existingPortfolio !== 'sample-portfolio'
      ? existingPortfolio.custodianKey
      : null,
    true /* includePCE2Instruments */,
    true /* includeTaxLots */
  );

  const { data: overviewData } = usePortfolioOverview(
    existingPortfolio != null && existingPortfolio !== 'sample-portfolio'
      ? existingPortfolio.id
      : null
  );

  const hasCompleteLots = positionsData?.positions.every(
    (p) => Math.abs(p.quantity - sumBy(p.taxLots, 'quantity')) < PCE2_INCONSISTENT_LOTS_TOLERANCE
  );

  async function handleFillInLots() {
    if (existingPortfolio == null || existingPortfolio === 'sample-portfolio') return;
    amplitude().logEvent('Continue with filling in default tax lots', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
    try {
      setIsLoading(true);
      await fillInXrayLots(existingPortfolio.id);
      onContinue();
    } catch (e) {
      enqueueCoachmark({ title: 'There was an error filling in tax lots', severity: 'error' });
    }
    setIsLoading(false);
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (!hasCompleteLots) {
      setTaxLotsModalOpen(true);
    } else {
      onContinue();
    }
  }

  const xrayData = useContext(XrayContext);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box px={8.75} py={4}>
          <Typography variant="h1">Review your client&apos;s current portfolio below.</Typography>
          <Box mb={3} mt={4}>
            {overviewData == null || overviewData?.data == null ? (
              <Card>
                <CardHeader pl={3} pr={2} py={2.5}>
                  <Skeleton variant="text" width="15%" />
                </CardHeader>
                <CardContent>
                  <Box height="216px">
                    <Skeleton width="100%" height="100%" />
                  </Box>
                </CardContent>
              </Card>
            ) : (
              <AllocationCard2
                expanded
                assetAllocation={transformPCE2AssetClassAllocations(
                  overviewData.data.metrics?.assetClassAllocations ?? []
                )}
                sectorAllocation={transformPCE2SectorAllocations(
                  overviewData.data.metrics?.sectorAllocations ?? []
                )}
              />
            )}
          </Box>
          <HoldingsTable data={positionsData?.positions} />
        </Box>
        <StickyBottomActionContainer>
          <Box display="flex" justifyContent="space-between">
            <BackButton onClick={() => onBack(undefined, xrayData)} disabled={isLoading} />
            <Box display="flex" alignItems="center">
              {/* Only show the "Tax lots missing" chip if the data has been loaded and its computed lots are missing */}
              {positionsData != null && !hasCompleteLots ? (
                <Tooltip title="Please complete cost basis information for a more accurate proposal.">
                  <TextHighlightTag severity="warning" textAlign="center">
                    <WarningIcon />
                    <Box ml={0.5}>Tax lots missing</Box>
                  </TextHighlightTag>
                </Tooltip>
              ) : null}
              <Box height="100%" mx={4}>
                <Divider orientation="vertical" />
              </Box>
              <Button
                color="secondary"
                variant="outlined"
                onClick={(e) => {
                  amplitude().logEvent('Click edit holdings button', {
                    category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                  });
                  e.preventDefault();
                  onContinue('editHoldings');
                }}
              >
                Edit holdings
              </Button>
              <Box ml={1.5}>
                {/* Don't allow form submission before it "Tax lots missing" status can be computed */}
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isLoading || positionsData == null}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </StickyBottomActionContainer>
      </form>
      {existingPortfolio != null && existingPortfolio !== 'sample-portfolio' ? (
        <IncompleteTaxLotsModal
          open={taxLotsModalOpen}
          onClose={() => setTaxLotsModalOpen(false)}
          handleEditHoldings={() => {
            amplitude().logEvent('Continue to edit holdings after being prompted', {
              category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
            });
            setTaxLotsModalOpen(false);
            onContinue('editHoldings');
          }}
          handleFillInLots={handleFillInLots}
          disableActions={isLoading}
        />
      ) : null}
    </>
  );
}
