import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import DialogTitle from '~/synth/DialogTitle';
import { ReactComponent as ExclamationIcon } from '~/static/images/icons/exclamation.svg';

interface ExecutePortfolioProps {
  form: {
    portfolioIntelligenceId: string;
  };
  onClose: () => void;
  onSubmit: (form: { id: string }) => Promise<void>;
  open: boolean;
}

export default function ExecutePortfolio({
  form: { portfolioIntelligenceId },
  onClose,
  onSubmit,
  open,
}: ExecutePortfolioProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  async function handleFormSubmit() {
    setIsLoading(true);

    // Note: intentionally do not `catch` because error handling is done in
    // ~/Portfolio/PortfolioPreview.tsx.
    try {
      await onSubmit({
        id: portfolioIntelligenceId,
      });
    } finally {
      setIsLoading(false);
    }

    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <Box display="flex">
          <Box alignItems="center" display="flex" mr={1}>
            <ExclamationIcon />
          </Box>
          Execute portfolio
        </Box>
      </DialogTitle>
      <DialogContent>
        Executing will trigger trades on your account. Are you sure you want to execute?
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" disabled={isLoading} onClick={handleFormSubmit} variant="contained">
          {isLoading ? 'Executing...' : 'Execute'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
