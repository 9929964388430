import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const UnderlineTooltipIndicator = React.forwardRef(
  (
    {
      children,
      inline = false,
      color,
      variant = 'body2',
      ...restProps
    }: {
      children: React.ReactNode;
      inline?: boolean;
      color?: BoxProps['color'];
      variant?: TypographyProps['variant'];
    },
    ref
  ) => {
    const theme = useTheme();
    const styles = {
      display: inline ? 'inline' : 'inline-block',
      borderBottom: `1px dashed ${theme.palette.blue[300]}`,
    };
    return (
      <Box
        style={styles}
        // Box is missing the type definition for a ref. But passing
        // a ref in actually works as intended, hence the @ts-ignore:
        ref={ref}
        component={inline ? 'span' : undefined}
        {...restProps}
        color={color}
      >
        <Typography color={color} component={inline ? 'span' : 'p'} variant={variant}>
          {children}
        </Typography>
      </Box>
    );
  }
);
export default UnderlineTooltipIndicator;
