import { Box, BoxProps, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { PortfolioInsightsResponse } from 'vise-types/pce2';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import Metric from '~/synth/Metric';
import UnderlineTooltipIndicator from '~/synth/UnderlineTooltipIndicator';
import { formatCurrency, formatApproximate } from '~/utils/format';

export default function PortfolioValueCardContent({
  portfolioValue,
  cashToWithdraw,
  isPce2,
  proposal = false,
  medianExpectedReturn,
  portfolioInsights,
  yearsRemaining,
  ...boxProps
}: {
  portfolioValue: number | null;
  cashToWithdraw: number;
  isPce2: boolean;
  proposal?: boolean;
  medianExpectedReturn?: number;
  portfolioInsights?: PortfolioInsightsResponse;
  yearsRemaining?: number;
} & BoxProps) {
  const shouldShowCashWithheld = cashToWithdraw > 0 && isPce2;
  return (
    <Box {...boxProps}>
      <Box
        pt={2.5}
        data-testid="portfolio-performance"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box px={3} mb={3}>
          <Metric
            metric={portfolioValue != null ? formatCurrency(portfolioValue) : '-'}
            label={
              <Box display="flex" justifyContent="space-between" width="100%">
                {proposal ? 'Initial' : 'Total'} portfolio value
                {shouldShowCashWithheld && (
                  <Tooltip
                    title={`${
                      proposal ? 'Initial' : 'Total'
                    } portfolio value reflects the dollar amount Vise uses to optimize the portfolio. Account value will be different when cash is being withheld for upcoming distrubitions.`}
                  >
                    <div>
                      <InformationCircleIcon />
                    </div>
                  </Tooltip>
                )}
              </Box>
            }
            size="large"
          />
          {medianExpectedReturn && portfolioInsights && yearsRemaining && (
            <Box my={1}>
              <Tooltip title={portfolioInsights.goalsOutcome.insightText}>
                <Typography color="textSecondary" variant="body1">
                  Projected to be{' '}
                  <UnderlineTooltipIndicator>
                    <Typography component="span" color="textSecondary" variant="body1">
                      {formatApproximate(medianExpectedReturn)}
                    </Typography>
                  </UnderlineTooltipIndicator>{' '}
                  in {yearsRemaining} years.
                </Typography>
              </Tooltip>
            </Box>
          )}
        </Box>
        {shouldShowCashWithheld && (
          <Box
            sx={{ color: 'grey.600', borderColor: 'grey.200' }}
            px={3}
            py={1.5}
            border={1}
            borderLeft={0}
            borderRight={0}
            borderBottom={0}
          >
            {formatCurrency(cashToWithdraw)} withheld for distributions
          </Box>
        )}
      </Box>
    </Box>
  );
}
