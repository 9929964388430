import { useTheme, Theme } from '@mui/material/styles';

export const getDefaultChartOptions = (theme: Theme) => {
  return {
    navigator: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    labels: {
      style: {
        fontFamily: theme.typography.fontFamily,
      },
    },
    chart: {
      style: {
        fontFamily: theme.typography.fontFamily,
      },
    },
    scrollbar: { enabled: false },
    xAxis: {
      dateTimeLabelFormats: {
        day: '%b %e',
        week: '%b %e',
        month: '%b %Y',
        year: '%Y',
      },
      labels: {
        style: {
          // Color of the axis itself
          lineColor: theme.palette.grey[600],
          color: theme.palette.grey[400],
        },
      },
    },
    yAxis: {
      opposite: false,
      gridLineColor: theme.palette.grey[200],
      labels: {
        style: {
          color: theme.palette.grey[400],
        },
      },
    },
    rangeSelector: { enabled: false },
  };
};

const useDefaultChartOptions = () => {
  const theme = useTheme();
  return getDefaultChartOptions(theme);
};

export default useDefaultChartOptions;
