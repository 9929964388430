import {
  Box,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
  Link,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import {
  downloadUploadedBulkEditCSV,
  executeTemplateUpdateJob,
  getTemplateUpdateJob,
} from '~/api/api';
import { ReactComponent as DownloadIcon } from '~/static/images/icons/download.svg';
import { TemplateAccountUpdateType, TemplateUpdateEventStatus } from '~/models/api';
import { keyBy, mapValues } from 'lodash';
import { getAccountTypeDisplayName, formatPercent } from '~/utils/format';
import { ReactComponent as CalendarIcon } from '~/static/images/icons/calendar.svg';
import { ReactComponent as RefreshIcon } from '~/static/images/icons/refresh.svg';
import { ReactComponent as PencilIcon } from '~/static/images/icons/pencil.svg';
import { TemplateUpdateJob } from 'vise-types/template';
import { format } from 'date-fns';
import { Loader } from '~/components';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useAllAccountsWithHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import useAllAllocationsTemplates from '~/hooks/templates/useAllAllocationsTemplates';
import useAllRestrictionsTemplates from '~/hooks/templates/useAllRestrictionsTemplates';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import DialogTitle from '~/synth/DialogTitle';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import useEnqueueToast from '~/hooks/useToast';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import amplitude from '~/utils/amplitude';
import Container from './Container';
import ActionBar from './ActionBar';
import ExecuteDialog, { Action } from './ExecuteDialog';
import { VerticalDivider } from './CommonComponents';
import SuccessfulProposalsSection from './SuccessfulProposalsSection';
import UnlinkedAccountsSection from './UnlinkedAccountsSection';
import ErroredProposalsSection from './ErroredProposalsSection';

export default function ReviewProposals() {
  const match = useRouteMatch<{ jobId: string }>();
  const [jobData, setJobData] = useState<TemplateUpdateJob | null>(null);
  const [accountsToLink, setAccountsToLink] = useState<{ [key: string]: boolean }>({});
  const { data: allocationsTemplates } = useAllAllocationsTemplates();
  const { data: restrictionsTemplates } = useAllRestrictionsTemplates();
  const { mutate } = useModelTemplateCenterViewData();
  const template = useMemo(() => {
    return [...(allocationsTemplates || []), ...(restrictionsTemplates || [])].find(
      (t) => t.parentId === jobData?.templateId
    );
  }, [allocationsTemplates, restrictionsTemplates, jobData?.templateId]);

  useEffect(() => {
    amplitude().logEvent('Impression - review proposals page', {
      category: EVENT_CATEGORIES.STRATEGY_CENTER,
      jobId: match.params.jobId,
    });
  }, [match.params.jobId]);

  useEffect(() => {
    const fetchJobData = async () => {
      const jobDataResponse = await getTemplateUpdateJob(match.params.jobId);
      if (jobDataResponse) {
        setJobData(jobDataResponse);
        setAccountsToLink(
          mapValues(
            keyBy(
              jobDataResponse.events
                .filter(
                  (ev) =>
                    ev.accountUpdateType === TemplateAccountUpdateType.APPLY &&
                    ev.status === TemplateUpdateEventStatus.PROPOSAL_CREATED
                )
                .map((ev) => ev.accountId),
              (id) => id
            ),
            () => true
          )
        );
      }
    };
    fetchJobData();
  }, [match.params.jobId]);

  const { data: accounts } = useAllAccountsWithHouseholdInfo({});
  const accountsById: { [key: string]: AccountWithPIAndHouseholdInfo | undefined } = useMemo(
    () => keyBy(accounts?.data, (account) => account.id),
    [accounts?.data]
  );
  const successfulAccountIds = useMemo(() => {
    return (
      jobData?.events
        .filter(
          (ev) =>
            ev.accountUpdateType === TemplateAccountUpdateType.APPLY &&
            ev.status === TemplateUpdateEventStatus.PROPOSAL_CREATED
        )
        .map((ev) => ev.accountId) || []
    );
  }, [jobData?.events]);

  const theme = useTheme();
  const history = useHistory();
  const [cancelingJob, setCancelingJob] = useState<boolean>(false);
  const [executeModalOpen, setExecuteModalOpen] = useState<boolean>(false);
  const executeModalData = useMemo(() => {
    return (
      jobData?.events
        .filter((e) => {
          // For linking operations, do not include errors
          if (
            jobData.reason === 'LINK' &&
            e.status !== TemplateUpdateEventStatus.PROPOSAL_CREATED
          ) {
            return false;
          }
          // For linking operations, do not include accounts we've deselected
          if (
            jobData.reason === 'LINK' &&
            e.accountUpdateType === TemplateAccountUpdateType.APPLY &&
            !accountsToLink[e.accountId]
          ) {
            return false;
          }
          // Bulk edit operations include everything
          return true;
        })
        .map((ev) => {
          let action: Action = 'LINK';
          const account = accountsById[ev.accountId];
          if (account && accountsToLink[account.id] && jobData.reason === 'LINK') {
            action = 'LINK';
          } else if (account && accountsToLink[account.id] && jobData.reason === 'UPDATE') {
            action = 'UPDATE';
          } else if (account && !accountsToLink[account.id] && jobData.reason === 'LINK') {
            action = 'UNLINK';
          } else if (jobData.reason === 'BULK_EDIT' && account && accountsToLink[account.id]) {
            action = 'BULK_UPDATE';
          } else {
            action = 'REMAIN';
          }
          return {
            name: `${account?.accountName} - ${getAccountTypeDisplayName(
              account?.accountType || ''
            )}`,
            action,
          };
        }) || []
    );
  }, [jobData?.events, accountsToLink, accountsById, jobData?.reason]);
  const [proposalsExecuting, setProposalsExecuting] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const enqueueCoachmark = useEnqueueCoachmark();
  const enqueueToast = useEnqueueToast();
  if (!jobData || !accounts || !allocationsTemplates || !restrictionsTemplates) {
    return <Container isLoading />;
  }
  const accountsToExecute = [
    ...successfulAccountIds.filter((id) => accountsToLink[id]),
    ...jobData.events
      .filter((ev) => ev.accountUpdateType === TemplateAccountUpdateType.REMOVE && ev.newProposalId)
      .map((ev) => ev.accountId),
  ];
  if (proposalsExecuting) {
    return <Loader message="Executing proposals..." />;
  }

  let targetName = '';
  if (template?.type === 'restrictions') {
    targetName = `restrictions template ${template.name}`;
  } else if (template?.type === 'allocations') {
    targetName = `allocation template ${template.name}`;
  } else if (!template) {
    targetName = '2024 Capital gains budget';
  }

  const cancelDialog = (
    <Dialog open={cancelDialogOpen}>
      <DialogTitle onClose={() => setCancelDialogOpen(false)}>Cancel update</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2">
          Are you sure you want to cancel the update in progress for the {targetName}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={cancelingJob}
          onClick={() => setCancelDialogOpen(false)}
          variant="outlined"
          color="secondary"
        >
          Close
        </Button>
        <Button
          disabled={cancelingJob}
          variant="contained"
          color="primary"
          onClick={async () => {
            setCancelingJob(true);
            try {
              await executeTemplateUpdateJob(jobData.id, []);
              await mutate();
              amplitude().logEvent('Cancel template update job', {
                category: EVENT_CATEGORIES.STRATEGY_CENTER,
                accounts: accountsToExecute,
                jobType: jobData.reason,
              });
              history.push('/secure/strategy-center');
            } catch (e) {
              enqueueCoachmark({
                title: 'Failed to cancel update.',
                content: 'Please reach out to clientservice@vise.com for help.',
                severity: 'error',
              });
              amplitude().logEvent('Error - cancel template update job', {
                category: EVENT_CATEGORIES.STRATEGY_CENTER,
                accounts: accountsToExecute,
                jobType: jobData.reason,
              });
              setCancelingJob(false);
            }
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  const numberOfProposals = jobData.events.filter(
    (ev) => 'turnoverFraction' in ev && typeof ev.turnoverFraction === 'number'
  ).length;

  return (
    <Container
      templateType={template?.type}
      footer={
        <ActionBar
          showBackButton={false}
          showCancelButton
          cancelButtonProps={{
            onClick: async () => {
              setCancelDialogOpen(true);
            },
            color: 'error',
            children: 'Delete',
          }}
          continueButtonProps={{
            disabled: cancelingJob,
            children: 'Execute',
            onClick: () => {
              setExecuteModalOpen(true);
            },
          }}
        />
      }
      headerBarContent={{
        left: (
          <Box color="text.secondary">
            <PathBreadcrumbs
              path={[
                {
                  name: 'Strategy Center',
                  to: '/secure/strategy-center',
                },
                ...(template?.name ? [{ name: template.name }] : []),
              ]}
              ariaLabel="Proposal review breadcrumbs"
            />
          </Box>
        ),
        right: (
          <Typography variant="body1" color="textSecondary">
            <Box display="flex">
              <Box mr={1} position="relative" top="2px">
                <CalendarIcon />
              </Box>
              {format(new Date(jobData.createdAt), 'P, h:maaa')}
              {jobData.reason !== 'BULK_EDIT' ? (
                <>
                  <VerticalDivider />
                  {jobData.reason === 'LINK' ? (
                    <>
                      <Box mr={1} position="relative" top="2px">
                        <PencilIcon />
                      </Box>
                      Link and/or unlink accounts
                    </>
                  ) : (
                    <>
                      <Box mr={1} position="relative" top="2px">
                        <RefreshIcon />
                      </Box>
                      Update linked accounts
                    </>
                  )}
                </>
              ) : null}
            </Box>
          </Typography>
        ),
      }}
    >
      <Box>
        <Box
          bgcolor="white"
          display="flex"
          alignItems="stretch"
          border={`solid 1px ${theme.palette.grey[200]}`}
          boxShadow={theme.shadows[1]}
          borderRadius="4px"
        >
          <Box p={3} width="33%">
            <Typography variant="h5" color="textSecondary">
              Proposals
            </Typography>
            <Box mt={1}>
              <Typography variant="h3" component="div">
                {successfulAccountIds.length}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Divider orientation="vertical" />
          </Box>
          <Box p={3} width="33%">
            <Typography variant="h5" color="textSecondary">
              Avg. turnover
            </Typography>
            <Box mt={1}>
              <Typography variant="h3" component="div">
                {formatPercent(
                  jobData.events.reduce((avg, ev) => {
                    if (
                      !('turnoverFraction' in ev) ||
                      typeof ev.turnoverFraction !== 'number' ||
                      numberOfProposals === 0
                    ) {
                      return avg;
                    }
                    return avg + ev.turnoverFraction / numberOfProposals;
                  }, 0),
                  1
                )}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Divider orientation="vertical" />
          </Box>
          <Box p={3} width="33%">
            <Typography variant="h5" color="textSecondary">
              {jobData.reason !== 'BULK_EDIT' ? 'Glide path adjustments' : 'Input file'}
            </Typography>
            <Box mt={1}>
              {jobData.reason !== 'BULK_EDIT' ? (
                <Typography variant="h3" component="div">
                  {
                    jobData.events.filter(
                      (ev) =>
                        ev.accountUpdateType === TemplateAccountUpdateType.APPLY &&
                        ev.status === TemplateUpdateEventStatus.PROPOSAL_CREATED &&
                        ev.glidepathRemoved
                    ).length
                  }
                </Typography>
              ) : (
                <>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    onClick={async () => {
                      const resp = await downloadUploadedBulkEditCSV(jobData.id);
                      if (resp.data) {
                        window.location.href = resp.data.url;
                      }
                    }}
                    component="button"
                    sx={{ textDecoration: 'none' }}
                  >
                    <Box display="flex">
                      <Box mr={0.5}>
                        <Typography variant="h4" component="div">
                          Download
                        </Typography>
                      </Box>
                      <DownloadIcon height={20} width={20} />
                    </Box>
                  </Link>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <ExecuteDialog
          requireConfirmation
          open={executeModalOpen || false}
          onClose={() => setExecuteModalOpen(false)}
          linkUnlinkData={executeModalData}
          onSubmit={() => {
            setProposalsExecuting(true);
            executeTemplateUpdateJob(match.params.jobId, accountsToExecute)
              .then(() => {
                history.push('/secure/strategy-center');
                amplitude().logEvent('Execute template update job', {
                  category: EVENT_CATEGORIES.STRATEGY_CENTER,
                  accounts: accountsToExecute,
                  jobType: jobData.reason.toLowerCase(),
                });
                if (accountsToExecute.length > 0) {
                  enqueueToast({
                    title: `${accountsToExecute.length} successfully executed`,
                    severity: 'success',
                    content:
                      'Trade orders have been generated and sent for execution. Once executed, trades will be reflected on the next business day.',
                  });
                } else {
                  enqueueToast({
                    title: 'No proposals to execute',
                    severity: 'success',
                    content: 'The update has been successfully cancelled.',
                  });
                }
              })
              .catch(() => {
                setProposalsExecuting(false);
                amplitude().logEvent('Error - execute template update job', {
                  category: EVENT_CATEGORIES.STRATEGY_CENTER,
                  accounts: accountsToExecute,
                  jobType: jobData.reason.toLowerCase(),
                });
                enqueueCoachmark({
                  title: 'Failed to execute proposals.',
                  content: 'Please reach out to clientservice@vise.com for help.',
                  severity: 'error',
                });
              });
          }}
        />
        <ErroredProposalsSection jobData={jobData} accountsById={accountsById} />
        <UnlinkedAccountsSection
          jobData={jobData}
          accountsById={accountsById}
          template={template}
        />
        <SuccessfulProposalsSection
          jobData={jobData}
          accountsById={accountsById}
          accountsToLink={accountsToLink}
          setAccountsToLink={setAccountsToLink}
          template={template}
        />
        {cancelDialog}
      </Box>
    </Container>
  );
}
