import React, { useRef } from 'react';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import SelectorContainer from './SelectorContainer';
import {
  MultilineSelectorFormControlLabel,
  MultilineSelectorDescription,
  MultilineSelectorTitle,
  MultilineCheckbox,
} from './MultilineSelector';

interface ContainedSelectorCheckboxProps {
  label: React.ReactNode;
  checked: boolean;
  description?: React.ReactNode;
  name: string;
  inputProps?: SwitchBaseProps['inputProps'];
  onClick?: (newCheckedValue: boolean) => void;
  disabled: boolean;
}

const ContainedSelectorCheckbox = React.forwardRef<unknown, ContainedSelectorCheckboxProps>(
  function ContainedSelectorCheckbox(
    {
      label,
      checked,
      description,
      name,
      inputProps,
      onClick,
      disabled,
    }: ContainedSelectorCheckboxProps,
    ref
  ) {
    const checkboxRef = useRef<HTMLElement>();

    return (
      <SelectorContainer
        active={checked}
        disabled={disabled}
        onClick={() => {
          checkboxRef.current?.click();
        }}
        selectable
        p={2.5}
      >
        <MultilineSelectorFormControlLabel
          disabled={disabled}
          onClick={(e) => e.stopPropagation()}
          ref={ref}
          control={
            <MultilineCheckbox
              // Disallow tab indexing on checkbox in favor of using tab indexing on the entire container
              tabIndex={-1}
              inputProps={inputProps}
              color="default"
              checked={checked}
              inputRef={checkboxRef}
              onClick={() => {
                if (onClick != null) onClick(!checked);
              }}
              name={name}
              disabled={disabled}
            />
          }
          label={
            <>
              <MultilineSelectorTitle disabled={disabled}>{label}</MultilineSelectorTitle>
              {description && (
                <MultilineSelectorDescription disabled={disabled} fontWeight={500}>
                  {description}
                </MultilineSelectorDescription>
              )}
            </>
          }
        />
      </SelectorContainer>
    );
  }
);

export default ContainedSelectorCheckbox;
