import React, { useEffect, useRef, useCallback } from 'react';
import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import Coachmark, { CoachmarkProps } from '~/synth/Coachmark';
import { Box } from '@mui/material';

interface CoachmarkEffectProps
  extends Partial<
      Pick<CoachmarkProps, 'content' | 'severity' | 'icon' | 'role' | 'action' | 'closeText'>
    >,
    Pick<CoachmarkProps, 'title'> {
  show: boolean;
}
export function useCoachmarkEffect({
  show,
  title,
  content,
  severity,
  action,
  closeText,
  icon,
  role,
}: CoachmarkEffectProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const alertKeyRef = useRef<SnackbarKey | null>(null);

  useEffect(() => {
    function handleCloseAlert() {
      if (alertKeyRef.current == null) return;
      closeSnackbar(alertKeyRef.current);
      alertKeyRef.current = null;
    }
    if (show) {
      if (alertKeyRef.current == null) {
        alertKeyRef.current = enqueueSnackbar(title, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          persist: true,
          content: () => (
            <div>
              <Coachmark
                title={title}
                content={content}
                onClose={() => handleCloseAlert()}
                severity={severity}
                action={action}
                closeText={closeText}
                icon={icon}
                role={role}
              />
            </div>
          ),
        });
      }
    } else {
      handleCloseAlert();
    }
    return () => {
      handleCloseAlert();
    };
  }, [
    closeSnackbar,
    content,
    enqueueSnackbar,
    title,
    show,
    severity,
    action,
    closeText,
    icon,
    role,
  ]);
}

export function useLoadPageDataErrorCoachmarkEffect(show: boolean, title?: string): void {
  useCoachmarkEffect({
    show,
    severity: 'error',
    title: title != null ? title : 'There was an error retrieving the data for this page.',
    content:
      'This could be due to lost connection, your session ending, or an internal error. Please refresh and try again.',
  });
}

export function useEnqueueCoachmark() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const enqueueCoachmark = useCallback(
    ({
      content,
      title,
      persist = true,
      ...props
    }: Omit<CoachmarkProps, 'onClose'> & Pick<OptionsObject, 'persist'>) => {
      function handleCloseAlert(key: string | number) {
        closeSnackbar(key);
      }
      enqueueSnackbar(title, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        persist,
        content: (key) => (
          <Box component="div">
            <Coachmark
              title={title}
              content={content}
              onClose={() => handleCloseAlert(key)}
              {...props}
            />
          </Box>
        ),
      });
    },
    [closeSnackbar, enqueueSnackbar]
  );
  return enqueueCoachmark;
}
