import { Box, IconButton, InputAdornment, useTheme } from '@mui/material';
import { debounce, filter, orderBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ValueType } from 'react-select';
import { AllocationsTemplate, RestrictionsTemplate } from 'vise-types/template';
import { ReactComponent as SearchIcon } from '~/static/images/icons/search.svg';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import Select from '~/synth/inputs/Select';
import TextField from '~/synth/TextField';
import { PaddedContainer } from './CommonComponents';

type TemplateType = AllocationsTemplate | RestrictionsTemplate;

type SortBy = 'alphabetical' | 'date created';
type BuiltBy = 'me' | 'others' | 'everyone';

interface SelectOption<T> {
  disabled?: boolean;
  label: string;
  value: T;
}

const sortByOptions = [
  {
    label: 'Alphabetical',
    value: 'alphabetical',
  },
  { label: 'Date created', value: 'date created' },
] as SelectOption<SortBy>[];

const sortByToConfig = {
  alphabetical: {
    properties: ['name', 'createdAt'],
    orders: ['asc', 'desc'],
  },
  'date created': {
    properties: ['createdAt', 'name'],
    orders: ['desc', 'asc'],
  },
} as {
  [key in SortBy]: {
    properties: string[];
    orders: ('desc' | 'asc')[];
  };
};

interface TemplatesSearchBarProps<T extends TemplateType> {
  allTemplates: T[];
  filteredTemplates: T[];
  currentUserId: string;
  onFilteredTemplatesUpdated: (filteredTemplates: T[]) => void;
}

export default function TemplatesSearchAndFilterBar<T extends TemplateType>({
  allTemplates,
  currentUserId,
  filteredTemplates,
  onFilteredTemplatesUpdated,
}: TemplatesSearchBarProps<T>) {
  const theme = useTheme();

  const [builtBy, setBuiltBy] = useState<BuiltBy>('everyone');
  const [sortBy, setSortBy] = useState<SortBy>('alphabetical');
  const [query, setQuery] = useState('');

  const selectedSortByOption = useMemo(
    () => sortByOptions.find((s) => s.value === sortBy),
    [sortBy]
  );

  const builtByOptions = useMemo(
    () =>
      [
        { label: 'Me', value: 'me' },
        { label: 'Others', value: 'others' },
        { label: 'Everyone', value: 'everyone' },
      ] as SelectOption<BuiltBy>[],
    []
  );

  const selectedBuiltByOption = useMemo(
    () => builtByOptions.find((s) => s.value === builtBy),
    [builtBy, builtByOptions]
  );

  const builtByToFilter = useMemo(
    () =>
      ({
        me: (t: T) => t.userId === currentUserId,
        others: (t: T) => t.userId !== currentUserId,
        everyone: (t: T) => t,
      } as { [key in BuiltBy]: object | ((s: T) => boolean) }),
    [currentUserId]
  );

  const applyFiltersAndSort = useCallback(
    ({
      nextQuery,
      nextBuiltBy,
      nextSortBy,
    }: {
      nextQuery: string;
      nextBuiltBy: BuiltBy;
      nextSortBy: SortBy;
    }): void => {
      const searchFilteredTemplates = matchSorter(allTemplates, nextQuery, {
        keys: ['name'],
        threshold: matchSorter.rankings.CONTAINS,
      });
      const updatedFilteredTemplates =
        nextBuiltBy == null
          ? []
          : (orderBy(
              filter(searchFilteredTemplates, builtByToFilter[nextBuiltBy]),
              sortByToConfig[nextSortBy].properties,
              sortByToConfig[nextSortBy].orders
            ) as T[]);
      onFilteredTemplatesUpdated(updatedFilteredTemplates);
    },
    [allTemplates, builtByToFilter, onFilteredTemplatesUpdated]
  );

  useEffect(() => {
    applyFiltersAndSort({ nextQuery: query, nextBuiltBy: builtBy, nextSortBy: sortBy });
  }, [applyFiltersAndSort, builtBy, query, sortBy]);

  const debounceApplyFiltersAndSort = useMemo(
    () => debounce(applyFiltersAndSort, 250),
    [applyFiltersAndSort]
  );

  function handleChangeQuery(event: React.ChangeEvent<HTMLInputElement>) {
    const nextQuery = event.target.value;
    setQuery(nextQuery);
    debounceApplyFiltersAndSort({ nextQuery, nextBuiltBy: builtBy, nextSortBy: sortBy });
  }

  function handleResetQuery() {
    setQuery('');
    debounceApplyFiltersAndSort({ nextQuery: '', nextBuiltBy: builtBy, nextSortBy: sortBy });
  }

  return (
    <Box
      bgcolor="white"
      border={1}
      borderColor="grey.300"
      borderLeft={0}
      borderRight={0}
      mb={3}
      py={1}
      width="100%"
    >
      <PaddedContainer>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box width={300}>
            <TextField
              fullWidth
              id="searchTemplates"
              placeholder={`Search ${filteredTemplates.length} available templates`}
              value={query}
              InputProps={{
                endAdornment: query ? (
                  <InputAdornment position="end">
                    <IconButton onClick={handleResetQuery} size="small" type="button">
                      <XIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon display="inline-flex" color={theme.palette.grey[500]} />
                  </InputAdornment>
                ),
              }}
              onChange={handleChangeQuery}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" flexBasis="50%">
            <Box alignItems="center" width="100%" display="flex" mr={3} maxWidth={275}>
              <Box color="grey.600" mr={1.5} whiteSpace="nowrap">
                Sort by:
              </Box>
              <Box width="100%">
                <Select
                  fullWidth
                  isSearchable={false}
                  options={sortByOptions}
                  value={selectedSortByOption}
                  onChange={(value: ValueType<SelectOption<SortBy>>) =>
                    setSortBy((value as SelectOption<SortBy>).value)
                  }
                />
              </Box>
            </Box>
            <Box maxWidth={225} alignItems="center" width="100%" display="flex" mr={3}>
              <Box color="grey.600" mr={1.5} whiteSpace="nowrap">
                Built by:
              </Box>
              <Box width="100%">
                <Select
                  fullWidth
                  isOptionDisabled={(o) => Boolean(o.disabled)}
                  isSearchable={false}
                  options={builtByOptions}
                  value={selectedBuiltByOption}
                  onChange={(value: ValueType<SelectOption<BuiltBy>>) =>
                    setBuiltBy((value as SelectOption<BuiltBy>).value)
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </PaddedContainer>
    </Box>
  );
}
