import useSWR from 'swr';
import { getHouseholdBondProposals } from '~/api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getHouseholdBondProposalsSwrKey = (householdId: string) =>
  generateSwrKey(`householdBondProposals/${householdId}`);

const useHouseholdBondProposals = (householdId: string | undefined | null) => {
  const { data, error, isValidating, mutate } = useSWR(
    householdId ? getHouseholdBondProposalsSwrKey(householdId) : null,
    householdId == null ? undefined : () => getHouseholdBondProposals(householdId)
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export default useHouseholdBondProposals;
