import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DistributionSchedule } from 'vise-types/distribution';
import { Account } from 'vise-types/portfolio';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useImmediateDrawdown from '~/hooks/useImmediateDrawdown';
import useUpsertDistribution from '~/hooks/useUpsertDistribution';
import { ReactComponent as ArrowLeftIcon } from '~/static/images/icons/arrow-left.svg';
import { ReactComponent as ClipboardIcon } from '~/static/images/icons/clipboad.svg';
import amplitude from '~/utils/amplitude';
import { ReactComponent as ExclamationIcon } from '~/static/images/icons/exclamation.svg';
import { CashAvailableAndTooltip, DistributionDetailsDisplay } from '../components/UtilComponents';

export default function PreviewDistribution({
  account,
  distribution,
  proposalId,
  scheduleIdToEdit,
  mutateSchedule,
  availableForImmediateDistribution,
  availableForRecurringDistribution,
  isSubmitting,
  setIsSubmitting,
  fromGlobalDistribution,
  onBack,
  isModelDelivery,
}: {
  account: Account;
  distribution: DistributionSchedule;
  proposalId?: string;
  scheduleIdToEdit?: string;
  mutateSchedule: () => void;
  availableForImmediateDistribution: number;
  availableForRecurringDistribution: number;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  fromGlobalDistribution: boolean;
  onBack: () => void;
  isModelDelivery: boolean;
}) {
  useEffect(() => {
    amplitude().logEvent('Impression - Preview distribution form', {
      category: EVENT_CATEGORIES.DISTRIBUTIONS,
    });
  }, []);
  const { upsertDistribution } = useUpsertDistribution();
  const { immediateDrawdown } = useImmediateDrawdown();
  const [showImmediateDistroFailure, setShowImmediateDistroFailure] = useState(false);
  const theme = useTheme();
  const history = useHistory();

  const { custodianKey: custodianCode, viseClientId: clientId, accountNumber, id } = account;
  const isValid = clientId != null && custodianCode != null && accountNumber != null;

  const availableForDistribution =
    distribution.disType === 0
      ? availableForImmediateDistribution
      : availableForRecurringDistribution;

  async function handleSubmit() {
    if (clientId != null && custodianCode != null && accountNumber != null && !isSubmitting) {
      setIsSubmitting(true);
      let upsert = false;
      if (distribution.disType === 0) {
        upsert = await immediateDrawdown({
          ...distribution,
          custodianCode,
          accountNumber,
          proposalId,
          id: scheduleIdToEdit,
        });
      } else {
        upsert = await upsertDistribution({
          ...distribution,
          accountNumber,
          clientId,
          custodianCode,
          allowMultipleRecurring: true,
          id: scheduleIdToEdit,
        });
      }
      if (upsert) {
        mutateSchedule();
        if (fromGlobalDistribution) {
          if (isModelDelivery) {
            history.push('/secure/md/distributions');
          } else {
            history.push(`/secure/distributions`);
          }
        } else {
          history.push(`/secure/accounts/${id}/distributions`);
        }
      } else if (distribution.disType === 0) {
        setShowImmediateDistroFailure(true);
      }

      setIsSubmitting(false);
    }
  }

  return (
    <>
      <Box mt={5}>
        <Typography variant="h1">Preview distribution</Typography>
      </Box>
      <Box
        p={3}
        pb={3}
        sx={{ borderColor: 'grey.200', borderRadius: theme.shape.borderRadius }}
        border={1}
        mt={3}
        mb={2}
      >
        <>
          <>{account.accountName}</>
          <CashAvailableAndTooltip cashAvailable={availableForDistribution} />
        </>
        <Box my={3} sx={{ bgcolor: 'grey.200' }}>
          <Divider />
        </Box>
        <DistributionDetailsDisplay distribution={distribution} variant="preview" />
        <Box mt={1} mb={3} sx={{ bgcolor: 'grey.200' }}>
          <Divider />
        </Box>
        <Typography variant="body1" color="textSecondary">
          Taking a cash distribution will often require changes to the investment portfolio to meet
          the requested cash need. Please be aware that generating cash for a distribution may
          require selling securities, which can lead to exceeding long term or short term capital
          gains budgets. For risk tolerance accounts, the risk profile will be maintained.
        </Typography>
      </Box>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <>
          <Box
            sx={{ borderColor: 'grey.200', borderRadius: theme.shape.borderRadius }}
            border={1}
            p={3}
            display="flex"
          >
            <ClipboardIcon fontSize={24} color={theme.palette.primary.main} />
            Reminder: Set &apos;Move Money Request&apos; via custodian if not already set.
          </Box>
          {showImmediateDistroFailure ? (
            <Box
              borderRadius={theme.shape.borderRadius}
              sx={{
                bgcolor: 'error.100',
                borderColor: 'error.200',
                borderRadius: theme.shape.borderRadius,
              }}
              border={1}
              p={3}
              mt={2}
              display="flex"
              flexDirection="column"
            >
              <Box display="flex">
                <Box color="error.400" display="flex" style={{ fontSize: '20px' }}>
                  <ExclamationIcon />
                </Box>
                <Box ml={1.5}>
                  <Typography variant="h4">Distribution failed</Typography>
                  <Box mt={1}>
                    <Typography variant="body2" color="textSecondary">
                      {!distribution.allowWashSaleIfNeeded
                        ? 'Wash sales may need to be incurred to generate the distribution. Go back to the previous page and lower the distribution amount or allow wash sales to continue.'
                        : 'Please reach out to clientservice@vise.com for help.'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
          <Box display="flex" justifyContent="space-between" mt={4}>
            <Button
              startIcon={<ArrowLeftIcon />}
              onClick={() => {
                amplitude().logEvent('Action - Return to edit distribution form', {
                  category: EVENT_CATEGORIES.DISTRIBUTIONS,
                });
                onBack();
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid || isSubmitting}
              onClick={() =>
                amplitude().logEvent('Action - Submit distribution', {
                  category: EVENT_CATEGORIES.DISTRIBUTIONS,
                })
              }
            >
              Submit distribution
            </Button>
          </Box>
        </>
      </form>
    </>
  );
}
