import useSWR from 'swr';
import { getUser } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useUser(
  fetchAggData?: boolean,
  fetchMetaData?: boolean,
  fetchPhoto?: boolean
) {
  return useSWR(
    generateSwrKey(
      `userData?fetchAggData=${fetchAggData ? 'true' : 'false'},userMetaData=${
        fetchMetaData ? 'true' : 'false'
      },fetchPhoto=${fetchPhoto ? 'true' : 'false'}`
    ),
    () => getUser(fetchAggData, fetchMetaData, fetchPhoto)
  );
}
