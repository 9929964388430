interface Token {
  accessToken: string;
  refreshToken: string;
}

const tokenStore = {
  clear() {
    return window.sessionStorage.removeItem('token');
  },
  getAuthToken(): Token | null {
    const token = window.sessionStorage.getItem('token');

    if (!token) {
      return null;
    }

    try {
      return JSON.parse(token);
    } catch (e) {
      return null;
    }
  },

  // TODO(whuang): Change to use HttpOnly cookie.
  setAuthToken(token: Token) {
    window.sessionStorage.setItem('token', JSON.stringify(token));
  },
};

// TODO(whuang): Fix this.
// eslint-disable-next-line import/prefer-default-export
export { tokenStore };
