import useSWR from 'swr';
import keyBy from 'lodash/keyBy';
import { getInstruments } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getInstrumentsSwrKey = () => generateSwrKey(`instruments`);
export const getIndexedInstrumentsSwrKey = () => generateSwrKey(`indexed-instruments`);

const useInstruments = (symbols?: string[], includeCountry?: boolean) => {
  const { data, error, isValidating, mutate } = useSWR(getInstrumentsSwrKey(), () =>
    getInstruments(symbols, includeCountry)
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export const useIndexedInstruments = () => {
  const { data: instrumentsData, error: instrumentsError } = useInstruments();
  const { data, error, isValidating, mutate } = useSWR(
    () => (instrumentsData ? getIndexedInstrumentsSwrKey() : null),
    () => {
      return Promise.resolve(keyBy(instrumentsData, 'symbol'));
    }
  );

  return {
    data,
    isLoading: (!instrumentsError && !instrumentsData) || (!error && !data),
    isValidating,
    error: instrumentsError || error,
    mutate,
  };
};

export default useInstruments;
