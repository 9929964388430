import * as Sentry from '@sentry/react';
import { AssetClassConcentrationLimits, CreatePortfolioIntelligenceData } from 'vise-types/pce1';
import { cloneDeep, defaults, pick, uniq } from 'lodash';
import { AccountSize } from 'vise-types/portfolio';
import { ConstructionInfo, Pce2ConstructionInfo } from './Types';
import { stripNonLeavesFromAssetClassExclusions } from './utils';

export default function preparePortfolioIntelligenceData(
  userId: string,
  constructionInfo:
    | ConstructionInfo
    | Pce2ConstructionInfo
    | CreatePortfolioIntelligenceData['constructionInfo'],
  constructionMetadata: CreatePortfolioIntelligenceData['constructionMetadata'] | null,
  lockedPositions: string[],
  {
    newSavedStrategyName,
    strategyId,
    strategyInternalUuid,
    overwriteExistingStrategy,
    allocationTemplateId,
    restrictionTemplateIds,
  }: {
    newSavedStrategyName?: string | null;
    strategyId: string | null;
    strategyInternalUuid: string | null;
    overwriteExistingStrategy?: boolean | null;
    allocationTemplateId?: string;
    restrictionTemplateIds?: string[];
  },
  // [PCE1] TLH values get carried over after a TLH proposal is generated because the existing
  // construction info is passed on to future proposals. (Until it gets cleared during rebalance)
  isPCE1TLH = false,
  taxable = true,
  accountSize?: AccountSize,
  proposalName?: string
): CreatePortfolioIntelligenceData {
  const defaultedConstructionInfo = defaults({}, cloneDeep(constructionInfo), {
    distributionTimeline: constructionInfo.investmentTimeline,
    risk: 0,
    targetValue: 0,
    restrictedStocks: [],
    excludedSectors: [],
    excludedIndustries: [],
  });

  if (!defaultedConstructionInfo.activeTilt?.isEnabled) {
    defaultedConstructionInfo.overlay = 'NONE';
  } else if (defaultedConstructionInfo.activeTilt?.isEnabled) {
    defaultedConstructionInfo.overlay =
      defaultedConstructionInfo.activeTilt.tiltType === 'dividend' ? 'DIVIDEND' : 'MULTI-FACTOR';
  }

  if (defaultedConstructionInfo.assetClassConcentrationLimits?.exclusions) {
    // We only want to send the leaf exclusions nodes to the api.
    defaultedConstructionInfo.assetClassConcentrationLimits.exclusions =
      stripNonLeavesFromAssetClassExclusions(
        defaultedConstructionInfo.assetClassConcentrationLimits.exclusions,
        { accountSize }
      );
  }

  const existingPortfolio =
    constructionInfo.existingPortfolio === 'sample-portfolio'
      ? null
      : pick(constructionInfo.existingPortfolio, [
          'accountNumber',
          'advisorId',
          'accountType',
          'custodianKey',
          'firstName',
          'id',
          'lastName',
          'portfolioIntelligenceId',
          'taxable',
          'transitioned',
          'viseClientId',
        ]);

  const portfolioIntelligenceData: CreatePortfolioIntelligenceData = {
    pceVersion: 'pce2',
    // In strict mode these params are nullable.
    // I don't feel comfortable asserting that they are non-nullable here and
    // it would be too far out of scope to fix the issues at the source so for now we'll just ignore errors
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    clientId: constructionInfo.clientId,
    proposalName,
    constructionInfo: {
      ...defaultedConstructionInfo,
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<Account | AccountDetails> | null' is... Remove this comment to see the full error message
      existingPortfolio,
      userId,
      smallAccount: accountSize === 'SMALL',
    },
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'ConstructionMetadata | null' is not assignab... Remove this comment to see the full error message
    constructionMetadata,
    lockedPositions,
    newSavedStrategyName: newSavedStrategyName || null,
    strategyId,
    strategyInternalUuid,
    overwriteExistingStrategy: overwriteExistingStrategy || null,
    allocationTemplateId,
    restrictionTemplateIds,
  };

  // This restructures the TLH / cap gains data, since it's organized differently in the API layer
  const portfolioIntelligenceDataExtended: { [key: string]: unknown } = {
    ...portfolioIntelligenceData,
  };
  let constructionInfoExtended: { [key: string]: unknown } = {
    ...portfolioIntelligenceData.constructionInfo,
  };

  if (!isPCE1TLH) {
    delete constructionInfoExtended.ytdLongTermTarget;
    delete constructionInfoExtended.ytdShortTermTarget;
    delete constructionInfoExtended.taxLossHarvesting;
  }

  delete constructionInfoExtended.autoTlh;
  delete constructionInfoExtended.capitalGainsLimits;
  let autoTlh = false;
  if ('autoTlh' in constructionInfo && constructionInfo.autoTlh != null) {
    autoTlh = constructionInfo.autoTlh;
    constructionInfoExtended.taxLossHarvesting = autoTlh;
  }
  if ('capitalGainsLimits' in constructionInfo && constructionInfo.capitalGainsLimits != null) {
    const { capitalGainsLimits } = constructionInfo;
    const { longTermGainsLimits, shortTermGainsLimits } = capitalGainsLimits;
    const constructionInfoCapGains: { [key: string]: number } = {};
    if (longTermGainsLimits.shouldLimitGains && longTermGainsLimits.maximumAmount != null) {
      constructionInfoCapGains.ytdLongTermTarget = longTermGainsLimits.maximumAmount;
    }
    if (shortTermGainsLimits.shouldLimitGains && shortTermGainsLimits.maximumAmount != null) {
      constructionInfoCapGains.ytdShortTermTarget = shortTermGainsLimits.maximumAmount;
    }
    constructionInfoExtended = {
      ...constructionInfoExtended,
      ...constructionInfoCapGains,
    };
  }

  if (!taxable && accountSize !== 'SMALL') {
    const typesafeAssetClassObject =
      constructionInfoExtended.assetClassConcentrationLimits &&
      typeof constructionInfoExtended.assetClassConcentrationLimits === 'object'
        ? (constructionInfoExtended.assetClassConcentrationLimits as AssetClassConcentrationLimits)
        : { exclusions: [] };
    constructionInfoExtended = {
      ...constructionInfoExtended,
      assetClassConcentrationLimits: {
        ...typesafeAssetClassObject,
        exclusions: uniq([
          ...(Array.isArray(typesafeAssetClassObject.exclusions)
            ? typesafeAssetClassObject.exclusions
            : []),
          'FIXED_INCOME/DOMESTIC/MUNICIPAL',
        ]),
      },
    };
  }

  try {
    portfolioIntelligenceDataExtended.constructionInfo = constructionInfoExtended;
    return portfolioIntelligenceDataExtended as CreatePortfolioIntelligenceData;
  } catch (e) {
    Sentry.captureException(e);
    return portfolioIntelligenceData;
  }
}
