import axios, { Method, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import httpStatus from 'http-status';
import { DataSource } from '../models/shared';
import revalidateAllFromDataSource from '../utils/swrCache';
import { tokenStore } from '../utils';
import { RawRefreshUserTokenResponse } from '../models/api';
import { EVENT_CATEGORIES } from '../constants/amplitude';
import amplitude from '../utils/amplitude';

const API_BASE_URL_PREFIX = '/api';

let isRefreshingToken = false;

// For accumulating failed requests during token refresh.
let failedRequests: {
  resolve: (value?: unknown) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason?: any) => void;
}[] = [];

const generateAuthHeaders = () => {
  const authToken = tokenStore.getAuthToken();
  const token = authToken ? authToken.accessToken : undefined;
  if (!token) {
    throw new Error('No valid access token.');
  }

  return `Bearer ${token}`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const doRequest = async <ResponseType, ErrorType = unknown>({
  data,
  dataSourceIfNotCoreApi,
  headers,
  ignoredResponseStatuses,
  method,
  requestUrl,
  shouldRevalidate = true,
  timeout,
}: {
  data?: AxiosRequestConfig['data'];
  dataSourceIfNotCoreApi?: DataSource;
  headers?: AxiosRequestConfig['headers'];
  ignoredResponseStatuses?: number[];
  method: Method;
  requestUrl: string;
  shouldRevalidate?: boolean;
  timeout?: number;
}): Promise<AxiosResponse<ResponseType>> => {
  const url = dataSourceIfNotCoreApi ? requestUrl : `${API_BASE_URL_PREFIX}${requestUrl}`;
  try {
    const res = await axios.request<ResponseType>({ method, url, headers, data, timeout });
    if (
      shouldRevalidate &&
      method !== 'get' &&
      method !== 'GET' &&
      method !== 'delete' &&
      method !== 'DELETE' &&
      method !== 'options' &&
      method !== 'OPTIONS'
    ) {
      await revalidateAllFromDataSource(dataSourceIfNotCoreApi || 'api');
    }
    return res;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      const e = err as AxiosError<ResponseType & { message?: string; errors?: unknown[] }>;
      // We want to publish to sentry if:
      // 1. We did not get a status code in the response
      // OR
      // 2. It's a client side error && it's not an error code handled elsewhere (like 401) && it's
      //    not a jwt expired error
      if (
        !e.response?.status ||
        (!ignoredResponseStatuses?.includes(e.response?.status) &&
          httpStatus[`${e.response?.status}_CLASS`] === httpStatus.classes.CLIENT_ERROR &&
          e.response?.data?.message !== 'jwt expired')
      ) {
        Sentry.captureException(e, {
          extra: {
            // `data.errors` comes from validators in the API. If there was a validation error, it
            // will be present in this `errors` array.
            errors: e.response?.data?.errors,
            message: e.response?.data?.message,
          },
        });
      }
    } else {
      // Did not get a response from the server, something else happened
      Sentry.captureException(err);
    }
    throw err;
  }
};

const refreshUserTokens = async () => {
  const tokens = tokenStore.getAuthToken();
  let response: AxiosResponse<RawRefreshUserTokenResponse>;
  try {
    // * Do not use doRequestWithAuth because it would attempt to refresh token again.
    // * Do not use doRequest because it would attempt to revalidate data and await a new token
    //   before doing so, causing a deadlock.
    response = await axios.request<RawRefreshUserTokenResponse>({
      data: {
        token: tokens?.refreshToken,
      },
      method: 'post',
      url: `${API_BASE_URL_PREFIX}/auth/refresh_token`,
    });
  } catch (error) {
    amplitude().logEvent('UserLogOutExpiredToken', {
      category: EVENT_CATEGORIES.LOGIN,
    });
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
    throw error;
  }

  const { data } = response;
  if (data) {
    tokenStore.setAuthToken({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
    });
  }
};

const doRequestWithAuth = async <ResponseType>({
  additionalHeaders,
  data,
  dataSourceIfNotCoreApi,
  ignoredResponseStatuses,
  method,
  requestUrl,
  shouldRevalidate = true,
  timeout,
}: {
  additionalHeaders?: AxiosRequestConfig['headers'];
  data?: AxiosRequestConfig['data'];
  dataSourceIfNotCoreApi?: DataSource;
  ignoredResponseStatuses?: number[];
  method: Method;
  requestUrl: string;
  shouldRevalidate?: boolean;
  timeout?: number;
}): Promise<AxiosResponse<ResponseType>> => {
  const secureHeaders = additionalHeaders || {};
  secureHeaders.Authorization = generateAuthHeaders();
  try {
    return await doRequest<ResponseType>({
      data,
      dataSourceIfNotCoreApi,
      headers: secureHeaders,
      ignoredResponseStatuses,
      method,
      requestUrl,
      shouldRevalidate,
      timeout,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const e = err as AxiosError<ResponseType & { message?: string; errors?: unknown[] }>;
      if (e.response && e.response.data?.message === 'jwt expired') {
        if (isRefreshingToken) {
          // If the token refresh is ongoing, return a promise to retry the query after
          // the token refresh is done.
          return new Promise((resolve, reject) => {
            failedRequests.push({ resolve, reject });
          })
            .then(() => {
              secureHeaders.Authorization = generateAuthHeaders();
              return doRequest<ResponseType>({
                requestUrl,
                method,
                headers: secureHeaders,
                data,
                dataSourceIfNotCoreApi,
              });
            })
            .catch((e) => Promise.reject(e));
        }

        // Refresh tokens and retry.
        try {
          isRefreshingToken = true;
          await refreshUserTokens();
          isRefreshingToken = false;

          // Resolve all of the retrying requests.
          failedRequests.forEach((p) => p.resolve());

          // Redo the original query.
          secureHeaders.Authorization = generateAuthHeaders();
          return doRequest<ResponseType>({
            requestUrl,
            method,
            headers: secureHeaders,
            data,
            dataSourceIfNotCoreApi,
          });
        } catch (e) {
          isRefreshingToken = false;
          // Fail all of the retrying requests.
          failedRequests.forEach((p) => p.reject(e));
        }
        failedRequests = [];
      }
    }
    throw err;
  }
};

export { doRequest, doRequestWithAuth };
