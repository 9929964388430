import React from 'react';

type WithLoaderProps = {
  children: React.ReactNode;
  isLoading: boolean;
  loader: React.ReactNode;
};

const WithLoader = ({ loader, children, isLoading }: WithLoaderProps) =>
  isLoading ? <>{loader}</> : <>{children}</>;
export default WithLoader;
