// XS, SM, MD, LG, XL, 2XL, 3XL, 4XL, 5XL, 6XL
export default [0, 4, 8, 12, 16, 24, 32, 40, 48, 64, 80];

/**
 * Easy way to translate from spacers in Figma to the MUI index
 */
export const SPACING_INDICES = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
  "2xl": 6,
  "3xl": 7,
  "4xl": 8,
  "5xl": 9,
  "6xl": 10,
};
