import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import amplitude from '../utils/amplitude';
import useUser from './useUser';

function setSentryUser(user: { id: string } | null) {
  if (user == null) {
    Sentry.configureScope((scope) => scope.setUser(null));
  } else {
    Sentry.setUser({ id: user.id });
  }
}

export default function useUserTracking() {
  const { data: fullUserData } = useUser(false);
  return useEffect(() => {
    const removeUser = () => {
      datadogRum.clearUser();
      amplitude().setUserId(null);
      setSentryUser(null);
    };
    if (fullUserData) {
      const { id, lastLogin, dateJoined, organizationName } = fullUserData;
      amplitude().setUserId(id);
      setSentryUser(fullUserData);
      amplitude().setUserProperties({
        lastLogin,
        dateJoined,
        firm: organizationName ?? 'Unknown',
      });
      datadogRum.setUser({
        id,
        organization: organizationName ?? 'Unknown',
      });
    } else {
      removeUser();
    }
    return removeUser;
  }, [fullUserData]);
}
