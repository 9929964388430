import {
  Box,
  CircularProgress,
  ClickAwayListener,
  CollapseProps,
  Divider,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
  TypographyProps,
  styled,
  useTheme,
} from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import React, { useState } from 'react';
import { TaxOptions } from 'vise-types/pce2';
import { TransitionAnalysis, TransitionAnalysisDataPoint } from 'vise-types/xray';
import { Card } from '~/routes/PortfolioCreator2/screens/components';
import { ReactComponent as ArrowDownIcon } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ArrowUpIcon } from '~/static/images/icons/chevron-up.svg';
import UnderlineTooltipIndicator from '~/synth/UnderlineTooltipIndicator';
import { ACCOUNTING_FORMATTER, formatCurrency, formatPercent } from '~/utils/format';

export const StyledList = styled(List)(({ theme }) => ({
  listStyleType: 'disc',
  color: theme.palette.grey[600],
  paddingLeft: theme.spacing(2),
  paddingTop: 0,
}));

export const StyledListItem = styled(ListItem)(() => ({
  display: 'list-item',
  paddingLeft: 0,
}));

export const TaxTransitionDisclaimer = ({ taxOptions }: { taxOptions?: TaxOptions }) => (
  <Typography color="grey.600" fontSize={10} fontStyle="italic">
    {`Tax cost is computed by taking the estimated realized short-term net gains and long-term
net gains and multiplying by assumed tax rates of ${formatPercent(
      taxOptions?.longTermTaxRate ?? 0.2,
      0
    )} for short-term gains and ${formatPercent(taxOptions?.shortTermTaxRate ?? 0.5, 0)} for
long-term gains. Actual tax costs may vary based on client's actual tax rates.`}
    <br />
    <br />
    {`Active share is a measure of the difference between a portfolio's holdings and the custom
Vise target. A portfolio that fully aligns with its Vise target model has an active share
of zero, while a portfolio that owns entirely out-of-target securities has an active share
of 100.`}
    <br />
    <br />
    The percentage of portfolio transitioned highlights how much the existing portfolio overlaps
    with the Vise target given a transition scenario. A portfolio with 100% indicates the
    transitioned portfolio is fully in line with the Vise target asset allocation and security
    levels.
  </Typography>
);

export function TaxTransitionDataVis({
  data,
  setHighlightedSection,
  taxOptions,
}: {
  data: TransitionAnalysis;
  setHighlightedSection?: (section: 'unrealized' | 'tax-rates' | null) => void;
  taxOptions?: TaxOptions;
}) {
  const theme = useTheme();
  const targetScenarioIndex = data.scenarios.findIndex((scenario) => scenario.original);
  const scenarioLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
  scenarioLabels.splice(targetScenarioIndex, 0, 'Target scenario');
  const ScenarioColors = ['#a46cf8', '#855dd9', '#664fba', '#48409a', '#29327b'] as const;
  const stTax = taxOptions?.shortTermTaxRate ?? 0.5;
  const ltTax = taxOptions?.longTermTaxRate ?? 0.2;

  const TaxTransitionCard = ({
    activeShare,
    longTermCapitalGains,
    longTermCapitalLosses,
    longTermCapitalGainsNet,
    shortTermCapitalGainsNet,
    longTermTaxGainsNet,
    shortTermTaxGainsNet,
    unrealizedLongTermGains,
    portfolioValue,
    maxCapGainsLong,
    scenarioName,
    original,
    color,
  }: TransitionAnalysisDataPoint & { scenarioName: string } & Pick<
      TransitionAnalysis,
      'unrealizedLongTermGains' | 'unrealizedShortTermGains' | 'portfolioValue'
    > & { color: string }) => {
    const [unrealizedTooltipOpen, setUnrealizedTooltipOpen] = useState(false);
    const [realizedTooltipOpen, setRealizedTooltipOpen] = useState(false);
    const [taxTooltipOpen, setTaxTooltipOpen] = useState(false);
    return (
      <Card
        sx={{
          width: '133px',
          height: '100%',
          minHeight: '391px',
          bgcolor: original ? 'blue.100' : null,
          borderColor: original ? `${theme.palette.primary.main} !important` : null,
          boxShadow: original ? theme.shadows[3] : null,
        }}
        py={2}
        px={1}
      >
        <Box height="100%">
          {/* Capital gain budget */}
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setUnrealizedTooltipOpen(false)}
            open={unrealizedTooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <Box pr={2}>Long-term net unrealized gains</Box>
                  <div>{formatCurrency(unrealizedLongTermGains, ACCOUNTING_FORMATTER)}</div>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <div>% of net unrealized gains</div>
                  <div>{formatPercent((maxCapGainsLong || 0) / unrealizedLongTermGains)}</div>
                </Box>
                <Divider />
                <Box display="flex" fontSize={16} justifyContent="space-between" mt={2}>
                  <b>Capital gains budget</b>
                  <b>{formatCurrency(maxCapGainsLong)}</b>
                </Box>
              </Box>
            }
            placement="bottom"
          >
            <Box
              textAlign="center"
              pb={1}
              borderRadius={1}
              onClick={() => setUnrealizedTooltipOpen(true)}
              onMouseEnter={() => setHighlightedSection?.('unrealized')}
              onMouseLeave={() => setHighlightedSection?.(null)}
              sx={{
                '&:hover': {
                  backgroundColor: tokens.palette.secondaryPurple[100],
                  boxShadow: tokens.shadow.medium,
                  cursor: 'pointer',
                },
              }}
            >
              <Typography fontWeight={700} fontSize={14} mb={1} whiteSpace="nowrap">
                {scenarioName}
              </Typography>
              <UnderlineTooltipIndicator>
                <Typography fontSize={14}>
                  {maxCapGainsLong != null ? formatCurrency(maxCapGainsLong) : 'No budget'}
                </Typography>
              </UnderlineTooltipIndicator>
              <Typography color="grey.600" fontSize={12} mt={0.5}>
                {/* No budget shouldn't show as a percentage of the unrealized long term gains */}
                {maxCapGainsLong != null && unrealizedLongTermGains > 0
                  ? formatPercent((maxCapGainsLong || 0) / unrealizedLongTermGains)
                  : '-'}
              </Typography>
            </Box>
          </Tooltip>
          <Divider sx={{ pb: 2 }} />

          {/*  Estimated realized gains */}
          <ClickAwayListener onClickAway={() => setRealizedTooltipOpen(false)}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setRealizedTooltipOpen(false)}
              open={realizedTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <Box>
                  <Box display="flex" justifyContent="space-between">
                    <div>Long-term realized gains</div>
                    <div>{formatCurrency(longTermCapitalGains, ACCOUNTING_FORMATTER)}</div>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <div>Long-term realized losses</div>
                    <div>{formatCurrency(-longTermCapitalLosses, ACCOUNTING_FORMATTER)}</div>
                  </Box>
                  <Divider />
                  <Box display="flex" fontSize={16} justifyContent="space-between" mt={2}>
                    <Box fontWeight={500} pr={2}>
                      Estimated realized gains
                    </Box>
                    <b>{formatCurrency(longTermCapitalGains - longTermCapitalLosses)}</b>
                  </Box>
                </Box>
              }
              placement="bottom"
            >
              <Box
                textAlign="center"
                sx={{
                  '&:hover': {
                    backgroundColor: tokens.palette.secondaryPurple[100],
                    boxShadow: tokens.shadow.medium,
                    cursor: 'pointer',
                  },
                }}
                onClick={() => setRealizedTooltipOpen(true)}
                mt={2}
                pt={2}
              >
                <UnderlineTooltipIndicator>
                  <Typography fontSize={14}>
                    {formatCurrency(longTermCapitalGainsNet, ACCOUNTING_FORMATTER)}
                  </Typography>
                </UnderlineTooltipIndicator>
                <Typography pb={2} color="grey.600" fontSize={12} mt={0.5}>
                  {unrealizedLongTermGains > 0
                    ? formatPercent(longTermCapitalGainsNet / unrealizedLongTermGains)
                    : '-'}
                </Typography>
              </Box>
            </Tooltip>
          </ClickAwayListener>
          <Divider sx={{ mb: 1, pt: 1.7 }} />

          {/* tax */}
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setTaxTooltipOpen(false)}
            open={taxTooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <div>Short-term net realized gains</div>
                  <div>{formatCurrency(shortTermCapitalGainsNet, ACCOUNTING_FORMATTER)}</div>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <div>Short-term tax rate</div>
                  <div>{formatPercent(stTax)}</div>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box pr={2}>Long-term net realized gains</Box>
                  <div>{formatCurrency(longTermCapitalGainsNet, ACCOUNTING_FORMATTER)}</div>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <div>Long-term tax rate</div>
                  <div>{formatPercent(ltTax)}</div>
                </Box>
                <Divider />
                <Box display="flex" fontSize={16} justifyContent="space-between" mt={2}>
                  <b>Estimated tax cost</b>
                  <b>
                    {formatCurrency(
                      shortTermCapitalGainsNet * stTax + longTermCapitalGainsNet * ltTax
                    )}
                  </b>
                </Box>
              </Box>
            }
            placement="bottom"
          >
            <Box
              textAlign="center"
              onMouseEnter={() => setHighlightedSection?.('tax-rates')}
              onMouseLeave={() => setHighlightedSection?.(null)}
              onClick={() => setTaxTooltipOpen(true)}
              sx={{
                '&:hover': {
                  backgroundColor: tokens.palette.secondaryPurple[100],
                  boxShadow: tokens.shadow.medium,
                  cursor: 'pointer',
                },
              }}
              pb={1}
            >
              <UnderlineTooltipIndicator>
                <Typography mt={2.4} fontSize={14}>
                  {formatCurrency(
                    longTermTaxGainsNet + shortTermTaxGainsNet > 0
                      ? longTermTaxGainsNet + shortTermTaxGainsNet
                      : 0,
                    ACCOUNTING_FORMATTER
                  )}
                </Typography>
              </UnderlineTooltipIndicator>
              <Typography color="grey.600" fontSize={12} mt={0.5}>
                {formatPercent(
                  (longTermTaxGainsNet + shortTermTaxGainsNet > 0
                    ? longTermTaxGainsNet + shortTermTaxGainsNet
                    : 0) / portfolioValue
                )}
              </Typography>
            </Box>
          </Tooltip>
          <Divider sx={{ pb: 1.5 }} />

          {/* Percentage of portfolio transitioned */}
          <Box
            textAlign="center"
            height={124}
            position="relative"
            borderBottom={1}
            borderColor={tokens.palette.neutralCool[300]}
          >
            <CircularProgress
              variant="determinate"
              value={100}
              size="75px"
              thickness={2.5}
              sx={{
                '& .MuiCircularProgress-circle': {
                  color: tokens.palette.neutralCool[200],
                },
                position: 'absolute',
                top: 22,
                right: 20,
              }}
            />
            <CircularProgress
              variant="determinate"
              value={100 - activeShare * 100}
              size="75px"
              thickness={2.5}
              sx={{
                '& .MuiCircularProgress-circle': {
                  color,
                },
                position: 'absolute',
                top: 22,
                right: 20,
              }}
            />
            <Box
              fontSize={14}
              fontWeight={500}
              color={color}
              position="absolute"
              top={50}
              right={32}
            >
              {formatPercent(1 - activeShare)}
            </Box>
          </Box>

          {/* Active Share */}
          <Box textAlign="center">
            <Typography mt={4} mb={1.8} variant="body1">
              {formatPercent(activeShare)}
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  };

  return (
    <>
      <Grid item xs={2}>
        <Box
          height="100%"
          maxWidth={144}
          minHeight={391}
          p={2}
          bgcolor={tokens.palette.neutralCool[100]}
        >
          <Box height={88}>
            <Typography variant="body3">Capital gains budget</Typography>
            <Typography color="grey.600" fontSize={12}>
              % of net unrealized gains
            </Typography>
          </Box>
          <Divider sx={{ mb: 2 }} />

          <Box height={82}>
            <Typography variant="body3">Estimated realized gains</Typography>
            <Typography color="grey.600" fontSize={12}>
              % of net unrealized gains
            </Typography>
          </Box>
          <Divider sx={{ mb: 2 }} />

          <Box height={70}>
            <Typography variant="body3">Estimated Tax cost</Typography>
            <Typography color="grey.600" fontSize={12}>
              % of portfolio value
            </Typography>
          </Box>
          <Divider sx={{ mb: 2 }} />

          <Box height={106}>
            <Typography variant="body3">% of portfolio transitioned</Typography>
          </Box>
          <Divider sx={{ mb: 2 }} />

          <Box height={83}>
            <Typography mt={4} variant="body3">
              Active share
            </Typography>
          </Box>
        </Box>
      </Grid>
      {data.scenarios.map((scenario, index) => (
        <Grid item key={scenario.longTermCapitalGains} xs={2}>
          <TaxTransitionCard
            {...scenario}
            color={ScenarioColors[index]}
            scenarioName={scenarioLabels[index]}
            unrealizedLongTermGains={data.unrealizedLongTermGains}
            portfolioValue={data.portfolioValue}
            unrealizedShortTermGains={data.unrealizedLongTermGains}
          />
        </Grid>
      ))}
    </>
  );
}

export function SectionTitle({
  title,
  subtext,
  onClick,
  collapseProps,
  id,
}: {
  title: string;
  subtext: string;
  onClick: () => void;
  collapseProps: CollapseProps | undefined;
  id?: string;
}) {
  return (
    <div className="section-header">
      <Box
        px={2}
        py={1.5}
        borderRadius={1}
        onClick={onClick}
        sx={{
          background: 'linear-gradient(92deg, #E3EBFC 0%, rgba(237, 224, 255, 0.50) 100%);',
          cursor: 'pointer',
        }}
        display="flex"
        alignItems="center"
      >
        <Typography variant="h2" lineHeight={1.25} id={id} pt={10} mt={-10}>
          {title}
        </Typography>
        <Box ml="auto">
          {collapseProps?.in ? <ArrowUpIcon fontSize={20} /> : <ArrowDownIcon fontSize={20} />}
        </Box>
      </Box>
      <Box mt={3} mb={4} color={tokens.palette.neutralCool[800]}>
        {subtext}
      </Box>
    </div>
  );
}

export function SectionSubtitle({
  title,
  id,
  ...restProps
}: {
  title: string;
  id: string;
} & TypographyProps) {
  return (
    <Typography
      variant="h3"
      className="section-subheader"
      id={id}
      sx={{ scrollMarginTop: 80 }}
      {...restProps}
    >
      {title}
    </Typography>
  );
}
