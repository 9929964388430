import {
  Box,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { capitalize } from 'lodash';
import { theme, tokens } from '@vise_inc/ds-vise';
import { add, setDate } from 'date-fns';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import {
  Account,
  BondPortfolioCashFlow,
  BondPortfolioHolding,
  BondPortfolioMetrics,
  BondPortfolioSettings,
} from 'vise-types/portfolio';
import useCapitalGains from '~/hooks/useCapitalGains';
import CardFooter from '~/synth/CardFooter';
import CardHeader from '~/synth/CardHeader';
import Metric from '~/synth/Metric';
import { formatCurrency, formatPercent } from '~/utils/format';
import PortfolioAccountValueChart from '../Portfolio/components/PortfolioAccountValueCard';
import TaxManagementCard from '../Portfolio/components/TaxManagementCard';
import { DURATION_OR_MATURITY_TO_LABEL, metricsContent } from './Utils';

export default function BondPortfolioOverview({
  settings,
  account,
  metrics,
  cashFlow,
  holdings,
  isProposal,
}: {
  settings: BondPortfolioSettings;
  account?: Account;
  metrics: BondPortfolioMetrics;
  cashFlow: BondPortfolioCashFlow[];
  holdings: BondPortfolioHolding[];
  isProposal?: boolean;
}) {
  const { data: capitalGainsData } = useCapitalGains(account?.accountNumber, account?.custodianKey);
  const [tabValue, setTabValue] = React.useState<'account-value-tab' | 'portfolio-cashflow-tab'>(
    isProposal ? 'portfolio-cashflow-tab' : 'account-value-tab'
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cashflowChartState, setCashflowChartState] = useState<'coupon' | 'couponAndMaturity'>(
    'coupon'
  );

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    newValue: 'account-value-tab' | 'portfolio-cashflow-tab'
  ) => {
    setTabValue(newValue);
  };

  const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

  type DateString = `${number}/${number}/${number}`;

  // Prefill the next 12 months with $0 for Highcharts to ensure every month
  // is included
  const cashflowByMonth: { [key: DateString]: number } = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    .map((addMonths) => {
      const today = new Date();
      today.setDate(1);

      return add(today, { months: addMonths });
    })
    .reduce(
      (
        previousValue: { [key: DateString]: number },
        currentValue: Date
      ): { [key: DateString]: number } => ({
        ...previousValue,
        // prettier-ignore
        [`${currentValue.getMonth() + 1}/${currentValue.getDate()}/${currentValue.getFullYear()}`]: 0,
      }),
      {}
    );

  cashFlow.forEach(({ monthsList }) => {
    monthsList.forEach(({ month, amount }) => {
      let couponPaymentDate = new Date(month);
      couponPaymentDate = new Date(couponPaymentDate.getTime() + timezoneOffset);
      couponPaymentDate = setDate(couponPaymentDate, 1);

      // prettier-ignore
      const dateKey = `${couponPaymentDate.getMonth() + 1}/${couponPaymentDate.getDate()}/${couponPaymentDate.getFullYear()}`;
      cashflowByMonth[dateKey] = (cashflowByMonth[dateKey] ?? 0) + amount;
    });
  });

  const maturityByDate: { [key: string]: number } = {};

  holdings.forEach(({ maturity, units }) => {
    if (maturity == null) return;
    let maturityDate = new Date(maturity);
    maturityDate = new Date(maturityDate.getTime() + timezoneOffset);
    maturityDate = setDate(maturityDate, 1);
    const dateKey = maturityDate.toDateString();
    if (maturityByDate[dateKey]) {
      maturityByDate[dateKey] += units;
    } else {
      maturityByDate[dateKey] = units;
    }
  });

  const chartData = Object.entries(cashflowByMonth)
    .map((x) => [new Date(x[0]).getTime(), x[1]])
    .sort((a, b) => a[0] - b[0]) as [number, number][];

  const chartDataStack = Object.entries(maturityByDate)
    .map((x) => [new Date(x[0]).getTime(), x[1]])
    .sort((a, b) => a[0] - b[0]) as [number, number][];

  const cashflowChartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      backgroundColor: undefined,
      style: { fontFamily: '"Helvetica Neue", Arial, "Noto Sans", sans-serif' },
    },
    title: { text: '' },
    credits: { enabled: false },
    yAxis: {
      gridLineDashStyle: 'Dash',
      labels: {
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return `$${Highcharts.numberFormat(this.value, 0, '', ',')}`;
        },
      },
      title: {
        text: 'Projected Coupon Payments',
        style: { fontWeight: '600', color: tokens.palette.neutralCool[1000] },
      },
    },
    xAxis: {
      type: 'datetime',
      min: chartData[0][0],
      labels: { format: '{value:%b %Y}' },
      title: {
        text: 'Date',
        style: { fontWeight: '600', color: tokens.palette.neutralCool[1000] },
      },
    },
    tooltip: {
      headerFormat: '<b>{point.x:%b %Y}</b><br>',
      pointFormatter: function format() {
        // eslint-disable-next-line react/no-this-in-sfc
        return formatCurrency(this.y);
      },
    },
    plotOptions: { column: { stacking: 'normal' } },
    series: [
      {
        data: chartData,
        type: 'column',
        pointWidth: cashflowChartState === 'couponAndMaturity' ? 10 : 40,
        color: {
          linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1,
          },
          stops: [
            [0, '#418DFF'],
            [1, '#4148FF'],
          ],
        },
      },
      ...(cashflowChartState === 'couponAndMaturity'
        ? [
            {
              data: chartDataStack,
              type: 'column',
              pointWidth: 10,
              color: {
                linearGradient: {
                  x1: 0,
                  x2: 0,
                  y1: 0,
                  y2: 1,
                },
                stops: [
                  [0, theme.palette.secondaryPurple[400]],
                  [1, theme.palette.secondaryPurple[500]],
                ],
              },
            } as SeriesOptionsType,
          ]
        : []),
    ],
    legend: { enabled: false },
  };

  const YTDCard = ({
    yieldToWorst,
    taxEquivalentYTW,
    instrumentType,
  }: {
    yieldToWorst: number;
    taxEquivalentYTW?: number;
    instrumentType: BondPortfolioSettings['instrumentType'];
  }) => {
    const enableTaxEquivalentYTW = taxEquivalentYTW != null && instrumentType === 'MUNICIPAL';

    // Default to tax equivalent if applicable
    const [metric, setMetric] = useState<'tax' | 'ytw'>(enableTaxEquivalentYTW ? 'tax' : 'ytw');

    return (
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Metric
            label={<>Yield to Worst</>}
            metric={formatPercent(
              !enableTaxEquivalentYTW || metric === 'ytw' ? yieldToWorst : taxEquivalentYTW,
              2
            )}
          />
        </CardContent>
        {enableTaxEquivalentYTW && (
          <CardFooter>
            <ToggleButtonGroup
              exclusive
              value={metric}
              onChange={(_, newValue) => {
                if (newValue != null) {
                  setMetric(newValue);
                }
              }}
              style={{ marginLeft: -8 }}
            >
              <ToggleButton value="tax">Tax equivalent</ToggleButton>
              <ToggleButton value="ytw">YTW</ToggleButton>
            </ToggleButtonGroup>
          </CardFooter>
        )}
      </Card>
    );
  };

  // Handle grid sizing for settings w/o duration or maturity settings
  const gridItemSize = settings.instrumentType === 'MUNICIPAL' ? 3 : 4;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item container spacing={2} xs={12}>
          <Grid item xs={gridItemSize}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Metric
                  label="Total portfolio value"
                  metric={formatCurrency(account?.cachedAum ?? settings.aum)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={gridItemSize}>
            <YTDCard instrumentType={settings.instrumentType} {...metrics} />
          </Grid>
          <Grid item xs={gridItemSize}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Metric label="Sector" metric={capitalize(settings.instrumentType)} />
              </CardContent>
            </Card>
          </Grid>
          {settings.instrumentType === 'MUNICIPAL' && (
            <Grid item xs={gridItemSize}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Metric
                    label="Structure"
                    metric={
                      <div>
                        <div>{settings.duration ? 'Target duration' : 'Ladder'}</div>
                        <Box color="grey.500" fontWeight={500} fontSize={12} mt={1}>
                          {DURATION_OR_MATURITY_TO_LABEL[settings.duration ?? settings.maturity]}
                        </Box>
                      </div>
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Card>
            {!isProposal && (
              <Box borderBottom={1} borderColor="grey.200" px={3} mb={1}>
                <Tabs value={tabValue} onChange={handleChange}>
                  <Tab
                    label="Account value"
                    value="account-value-tab"
                    id="account-value-tab"
                    aria-controls="account-value-tab"
                  />

                  <Tab
                    label="Portfolio cashflow"
                    value="portfolio-cashflow-tab"
                    id="portfolio-cashflow-tab"
                    aria-controls="portfolio-cashflow-tab"
                  />
                </Tabs>
              </Box>
            )}
            {account != null ? (
              <Box hidden={tabValue !== 'account-value-tab' || isProposal}>
                <CardContent>
                  <PortfolioAccountValueChart
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    accountSummary={account!.summary}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    maxDate={account!.summary[account!.summary.length - 1]?.date}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    minDate={account!.summary[0]?.date}
                  />
                </CardContent>
              </Box>
            ) : null}

            <Box hidden={tabValue !== 'portfolio-cashflow-tab'}>
              {isProposal && (
                <CardHeader display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="h4">Cash flow</Typography>
                  {/* Reenable when we have proper designs */}
                  {/* <ToggleButtonGroup
                  exclusive
                  value={cashflowChartState}
                  onChange={(_, newValue) => {
                    if (newValue != null) {
                      setCashflowChartState(newValue);
                    }
                  }}
                >
                  <ToggleButton value="coupon">Coupon</ToggleButton>
                  <ToggleButton value="couponAndMaturity">Coupon and maturity</ToggleButton>
                </ToggleButtonGroup> */}
                </CardHeader>
              )}
              <CardContent>
                <Box display="flex" mb={5}>
                  <Metric
                    label="Projected cash flow (12 months)"
                    metric={formatCurrency(metrics.cashFlowProjection)}
                  />
                </Box>
                <HighchartsReact options={cashflowChartOptions} />
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader>
              <Typography variant="h4">Bond portfolio characteristics</Typography>
            </CardHeader>
            <CardContent>
              {metricsContent(metrics, holdings).map(({ field, value }) => (
                <Box key={value?.toString()} display="flex" justifyContent="space-between" py={1}>
                  <Box color="black">{field}</Box>
                  <Box color={tokens.palette.neutralCool[800]}>{value}</Box>
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
        {account &&
          account.taxable &&
          capitalGainsData != null &&
          settings.instrumentType === 'MUNICIPAL' && (
            <Grid item xs={12}>
              <TaxManagementCard
                autoTlh={
                  'activeTaxManagement' in settings ? settings.activeTaxManagement : undefined
                }
                gains={capitalGainsData}
                excludeTableAndDisclosures={!isProposal}
              />
            </Grid>
          )}
      </Grid>
    </>
  );
}
