import { Box, Button, Card, Collapse, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import get from 'lodash/get';
import React from 'react';
import { ReactComponent as ChevronDown } from '~/static/images/icons/chevron-down.svg';

const useBoxStyles = makeStyles<{ borderColor: string }>()((theme, props) => ({
  expandable: {
    cursor: 'pointer',
    position: 'relative',
    // Ensure the box-shadow from this clickable box stacks on top of the expandable content. If
    // the expandable content has positioned content, like a `position:sticky` header, that content
    // would stack on top of the box-shadow without this z-index.
    zIndex: theme.zIndex.mobileStepper,
  },
  expanded: {
    borderBottom: `1px solid ${get(theme.palette, props.borderColor)}`,
  },
}));
const useCardStyles = makeStyles<{
  borderColor: string;
  bgColor: string;
  color: string | undefined;
  fullWidth: unknown;
}>()((theme, props) => ({
  root: {
    borderColor: get(theme.palette, props.borderColor) ?? props.borderColor,
    backgroundColor: get(theme.palette, props.bgColor) ?? props.bgColor,
    color: props.color ? get(theme.palette, props.color) : undefined,
    borderRadius: props.fullWidth ? 0 : undefined,
    borderLeft: props.fullWidth ? 'none' : undefined,
    borderRight: props.fullWidth ? 'none' : undefined,
  },
}));

const useChevronDownStyles = makeStyles()({
  expanded: {
    transform: 'rotate(180deg)',
  },
  root: {
    transition: 'transform 0.25s',
  },
});

interface BannerProps {
  bgColor?: string;
  borderColor?: string;
  color?: string;
  stripeColor?: string;
  action?: string;
  actionCollapse?: string;
  actionExpand?: string;
  expandable?: boolean;
  expandedContent?: React.ReactNode;
  message?: React.ReactNode;
  size?: 'small';
  title?: string;
  fullWidth?: boolean;
  actionButtonComponent?: React.ComponentType<
    React.PropsWithChildren<React.PropsWithChildren<unknown>>
  >;
  actionButtonProps?: object;
}

export default function Banner({
  bgColor = 'blue.100',
  borderColor = 'blue.300',
  color,
  stripeColor,
  action,
  actionCollapse,
  actionExpand,
  expandable = false,
  expandedContent,
  message,
  size,
  title,
  fullWidth = false,
  actionButtonComponent,
  actionButtonProps,
}: BannerProps) {
  const [expanded, setExpanded] = React.useState(false);
  const { classes: boxStyles, cx } = useBoxStyles({ borderColor });
  const { classes: chevronStyles } = useChevronDownStyles();
  const { classes: cardStyles } = useCardStyles({ bgColor, borderColor, color, fullWidth });

  let actionButtonContent: string | undefined;
  if (expandable && expanded) {
    actionButtonContent = actionCollapse;
  } else if (expandable && !expanded) {
    actionButtonContent = actionExpand;
  } else {
    actionButtonContent = action;
  }
  // must be capitalized
  const ActionButtonComponent = actionButtonComponent;

  return (
    <Card variant="outlined" className={cx(cardStyles.root)}>
      <Box
        aria-expanded={expandable ? expanded : undefined}
        // (Safari): borderRadius required for Safari to round the border of this <Box> the same as
        // the parent <Box>, otherwise square border overflows the <Box>
        sx={{
          borderLeft: stripeColor ? 4 : undefined,
          borderColor: stripeColor,
        }}
        className={cx({
          [boxStyles.expandable]: expandable,
          [boxStyles.expanded]: expanded,
        })}
        onClick={
          expandable
            ? () => {
                setExpanded((currExpanded) => !currExpanded);
              }
            : undefined
        }
        px={size === 'small' ? 2 : 3}
        py={1.5}
        role={expandable ? 'button' : undefined}
        tabIndex={expandable ? 0 : undefined}
      >
        <Box
          display={fullWidth ? 'block' : 'flex'}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box alignItems="center" display="flex" py={size === 'small' ? 0.5 : 1.5}>
            {title && <Typography variant="h4">{title}</Typography>}
            {title && message && (
              <Box mx={2} position="relative" width="1px">
                <Box borderColor={borderColor} borderLeft={1} height="20px" />
              </Box>
            )}
            {message}
          </Box>
          {actionButtonContent != null && (
            <Box>
              {ActionButtonComponent != null ? (
                <ActionButtonComponent {...actionButtonProps}>
                  {actionButtonContent}
                </ActionButtonComponent>
              ) : (
                <Button
                  color="secondary"
                  endIcon={
                    expandable && (
                      <ChevronDown
                        className={cx(chevronStyles.root, { [chevronStyles.expanded]: expanded })}
                      />
                    )
                  }
                >
                  {actionButtonContent}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {expandable && (
        <Collapse in={expanded} mountOnEnter unmountOnExit>
          <Box bgcolor="white">{expandedContent}</Box>
        </Collapse>
      )}
    </Card>
  );
}
