import { Box, Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: center;
`;

interface EmptyStateProps {
  icon?: React.ReactElement;
  large?: boolean;
  subtitle?: React.ReactNode;
  subtitleOnClick?: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  title: string;
}

const EmptyStateText = styled.span<Pick<EmptyStateProps, 'large'>>`
  ${({ theme, large }) => `
    color: ${theme.palette.grey[400]};
    font-size: ${large ? 16 : 14}px;
    line-height: ${large ? 20 : 17}px;
  `}
`;

const Subtitle = styled(EmptyStateText)`
  ${({ theme }) => `
    font-weight: normal;
    margin-top: ${theme.spacing(2)};
  `}
`;

const Title = styled(EmptyStateText)`
  font-weight: 500;
`;

const IconContainer = styled.div`
  ${({ theme }) => `
    display: inline;
    color: ${theme.palette.grey[300]};
    margin-bottom: ${theme.spacing(2)};
  `}
`;

const EmptyState = ({ title, subtitle, icon, large, subtitleOnClick }: EmptyStateProps) => {
  return (
    <Container>
      {icon != null ? (
        <IconContainer>
          {React.cloneElement(icon, { customSize: large ? '36px' : undefined })}
        </IconContainer>
      ) : null}
      <Title large={large}>{title}</Title>
      {subtitle && subtitleOnClick && (
        <Box mt={2}>
          <Button color="primary" onClick={subtitleOnClick} size={large ? undefined : 'small'}>
            {subtitle}
          </Button>
        </Box>
      )}
      {subtitle && !subtitleOnClick && <Subtitle large={large}>{subtitle}</Subtitle>}
    </Container>
  );
};

export default EmptyState;
