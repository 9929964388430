import { useState } from 'react';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { unacceptPCE2Proposal } from '../api/api';
import useEnqueueToast from './useToast';

const usePCE2UnacceptProposal = () => {
  const enqueueToast = useEnqueueToast();

  type Proposal = Pick<PortfolioIntelligenceFull, 'id' | 'accountId' | 'clientId'>;
  const [unacceptPending, setUnacceptPending] = useState(false);

  const unacceptProposal = async (proposal: Proposal, accountId: string) => {
    try {
      setUnacceptPending(true);
      const { id } = proposal;
      await unacceptPCE2Proposal(id, accountId);
      setUnacceptPending(false);
      enqueueToast({
        title: 'Successfully unaccepted proposal',
        severity: 'success',
      });
      return null;
    } catch (e) {
      enqueueToast({
        title: 'Could not unaccept the proposal',
        severity: 'warning',
        content: 'Please reach out to clientservice@vise.com for help.',
      });
      return e;
    }
  };

  return { unacceptProposal, unacceptPending };
};

export default usePCE2UnacceptProposal;
