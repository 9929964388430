import { Box, Typography } from '@mui/material';
import { sumBy } from 'lodash';
import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { Position } from 'vise-types/portfolio';
import { PCE2_INCONSISTENT_LOTS_TOLERANCE } from '~/constants';
import { ReactComponent as DotsHorizontal } from '~/static/images/icons/dots-horizontal.svg';
import LoadingTable from '~/routes/Households/Households/LoadingTable';
import Truncation from '~/routes/Portfolio/components/Truncation';
import { DataTable, DataTableContainer, DataTableTitle } from '~/synth/DataTable';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import { TextHighlightTag } from '~/synth/Tag';
import { ACCOUNTING_FORMATTER, formatCurrency } from '~/utils/format';
import { ASSET_TYPE_TO_LABEL } from '../../Constants';
import { isCashPosition } from '../../utils';

const GainsOrLossFormatter = ({ gains }: { gains: number }) => {
  let normalized = gains;
  // "Normalize" gains/loss that's really close to $0 as $0.00
  if (Math.abs(gains) < 0.001) {
    normalized = 0.0;
  }

  const textColor = normalized < 0 ? 'error.400' : 'success.400';
  return (
    <Box color={normalized === 0 ? '' : textColor}>
      {formatCurrency(normalized, ACCOUNTING_FORMATTER)}
    </Box>
  );
};

export default function HoldingsTable({
  data,
  onEdit,
  onDelete,
}: {
  data?: Position[];
  onEdit?: (position: Position) => void;
  onDelete?: (position: Position) => void;
}) {
  const holdingsTableColumns = useMemo(
    () =>
      [
        {
          Header: 'Ticker',
          accessor: 'symbolOrCusip',
          width: '10%',
          Cell: (props) => (props.value === ':CASH' ? '' : props.value),
        },
        {
          Header: 'Name',
          accessor: 'name',
          Cell: (props) => <Truncation>{props.value}</Truncation>,
        },
        {
          Header: 'Asset type',
          accessor: 'assetType',
          Cell: (props) => {
            const assetType = props.value;
            return ASSET_TYPE_TO_LABEL[assetType];
          },
          width: '10%',
        },
        {
          Header: 'Quantity',
          accessor: 'quantity',
          Cell: (props) => {
            if (isCashPosition(props.row.original)) {
              return '';
            }
            return props.value;
          },
          width: '10%',
        },
        {
          Header: 'Total cost basis',
          accessor: 'price',
          Cell: (props) => {
            const { taxLots, quantity } = props.row.original;
            if (isCashPosition(props.row.original)) {
              return '';
            }

            // Since we are sharing columns between the original row and subrows, show the price if the tax lot
            // quantities match up, or if the row is a tax lot.
            return Math.abs(sumBy(taxLots, 'quantity') - quantity) <
              PCE2_INCONSISTENT_LOTS_TOLERANCE || taxLots == null ? (
              formatCurrency(props.value)
            ) : (
              <TextHighlightTag severity="warning">-</TextHighlightTag>
            );
          },
          width: '10%',
        },
        {
          Header: 'Gain/loss',
          accessor: 'gains',
          Cell: (props) => {
            const { taxLots, quantity } = props.row.original;
            // Cash doesn't have cost basis and it doesn't have gains/loss
            if (isCashPosition(props.row.original)) {
              return '';
            }

            const defaultCellComponent = <GainsOrLossFormatter gains={props.value} />;
            const invalidValueComponent = <TextHighlightTag severity="warning">-</TextHighlightTag>;

            // This is as sub row (for an individual tax lot)
            if (taxLots === undefined) {
              return defaultCellComponent;
            }

            // If there's no lots (i.e., no cost basis) then there's no gains/loss to compute
            if (taxLots.length <= 0) {
              return invalidValueComponent;
            }

            // This should be an invalid state; position quantity is _usually_ defined
            // by the sum of its lots. And editing/saving lots into an invalid state
            // should be impossible.
            //
            // But sanity check if invalid lots show the warning dash
            if (
              Math.abs(sumBy(taxLots, 'quantity') - quantity) > PCE2_INCONSISTENT_LOTS_TOLERANCE
            ) {
              return invalidValueComponent;
            }

            // Position row with valid tax lots
            return defaultCellComponent;
          },
          width: '10%',
        },
        {
          Header: 'Value',
          accessor: 'marketValue',
          Cell: (props) => {
            return props.value ? formatCurrency(props.value) : '';
          },
        },
        ...(onEdit != null && onDelete != null
          ? [
              {
                Header: '',
                accessor: 'none',
                Cell: (props) =>
                  props.row.original.taxLots ? (
                    <Box mr={1}>
                      <DropdownButtonMenu isIcon buttonContent={<DotsHorizontal />}>
                        {(closeMenu) => [
                          ...[
                            <DropdownButtonMenuItem
                              key="edit"
                              onClick={() => {
                                onEdit(props.row.original);
                                closeMenu();
                              }}
                            >
                              Edit
                            </DropdownButtonMenuItem>,
                          ],
                          <DropdownButtonMenuItem
                            key="delete"
                            onClick={() => {
                              onDelete(props.row.original);
                              closeMenu();
                            }}
                          >
                            Delete
                          </DropdownButtonMenuItem>,
                        ]}
                      </DropdownButtonMenu>
                    </Box>
                  ) : null,
                width: '5%',
              },
            ]
          : []),
      ] as Column<Position>[],
    [onEdit, onDelete]
  );

  const tableData = useMemo(() => {
    const withSubrows = data?.map((position) => {
      return {
        ...position,
        gains: 0,
        subRows: position.taxLots?.map((lot) => {
          return {
            // Not great but we have to align the purchase date of the subrows to the ticker column
            // of the main row.
            symbolOrCusip: lot.purchaseDate,
            price: lot.costBasis,
            quantity: lot.quantity,
            gains: position.price * lot.quantity - lot.costBasis,
          };
        }),
      };
    });

    withSubrows?.forEach((p) => {
      // eslint-disable-next-line no-param-reassign
      p.gains = sumBy(p.subRows, 'gains');
      // eslint-disable-next-line no-param-reassign
      p.price =
        // If the lots are complete, sum up lots cost basis, otherwise use stale price
        p.subRows != null && sumBy(p.subRows, 'quantity') === p.quantity
          ? sumBy(p.subRows, 'price')
          : p.price;
    });
    return withSubrows;
  }, [data]);

  const portfolioValue = useMemo(
    () => tableData?.reduce((value, next) => value + next.marketValue, 0),
    [tableData]
  );

  return (
    <DataTableContainer>
      <DataTableTitle title="Holdings" metaData={data == null ? '' : `${data.length} holdings`} />
      {tableData == null ? (
        <LoadingTable pageSize={10} />
      ) : (
        <DataTable
          columns={holdingsTableColumns}
          data={tableData}
          expandable
          tableMaxHeight="35vh"
          m={0}
        />
      )}
      <Box px={3} py={2.5}>
        <Typography variant="h4">
          <Box display="flex" justifyContent="space-between">
            <Box>Estimated portfolio value</Box>
            <Box>{formatCurrency(portfolioValue)}</Box>
          </Box>
        </Typography>
      </Box>
    </DataTableContainer>
  );
}
