import * as emailValidator from 'email-validator';
import extractStatusAndMessageFromAPIError from '~/utils/apiErrors';
import { ActionType } from '../constants/ActionType';
import { doRequestWithAuth, doRequest } from '../api/apiUtil';
import { closeAlert, enqueueAlert } from './message';
import { tokenStore } from '../utils';
import { EVENT_CATEGORIES } from '../constants/amplitude';
import amplitude from '../utils/amplitude';

export const login =
  ({ email, password }) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: any): Promise<boolean> => {
    dispatch(closeAlert());
    dispatch({ type: ActionType.LOGIN_USER_REQUESTED });
    try {
      if (!emailValidator.validate(email)) {
        throw new Error('Invalid email address');
      }

      if (!password) {
        throw new Error('Password cannot be empty');
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data }: { data: any } = await doRequest({
        data: {
          email,
          password,
        },
        // 401 is expected when user types in incorrect password or when account is locked
        ignoredResponseStatuses: [401],
        method: 'post',
        requestUrl: '/auth/login',
      });

      // Ask for MFA secret.
      if (data && data.mfaRequired) {
        dispatch({ type: ActionType.MFA_REQUIRED, data });
        return false;
      }

      dispatch({ type: ActionType.LOGIN_USER_FULFILLED, loginResponse: data });
      amplitude().logEvent('UserLogInSuccess', {
        category: EVENT_CATEGORIES.LOGIN,
      });

      return true;
    } catch (error) {
      const { message } = extractStatusAndMessageFromAPIError(error);
      amplitude().logEvent('UserLogInFailed', {
        category: EVENT_CATEGORIES.LOGIN,
        errorType: message,
      });
      dispatch({ type: ActionType.LOGIN_USER_FAILED });
      dispatch(enqueueAlert({ message }));
      return false;
    }
  };

export const loginWithSso =
  (email) =>
  async (dispatch): Promise<boolean> => {
    dispatch(closeAlert());
    dispatch({ type: ActionType.LOGIN_USER_REQUESTED });

    try {
      if (!emailValidator.validate(email)) {
        throw new Error('Invalid email address');
      }
      const queryParams = new URLSearchParams(window.location.search);
      const nextPath = queryParams.get('path') || '';
      const nextSearch = queryParams.get('search') || '';

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data }: { data: any } = await doRequest({
        data: {
          email,
          redirectPath: nextPath,
          redirectSearch: nextSearch,
        },
        method: 'post',
        requestUrl: '/auth/sso/login',
      });
      window.location.href = data.authorizationUrl;
      return true;
    } catch (error) {
      const { message } = extractStatusAndMessageFromAPIError(error);
      amplitude().logEvent('UserLogInFailed', {
        category: EVENT_CATEGORIES.LOGIN,
        errorType: message,
      });
      dispatch({ type: ActionType.LOGIN_USER_FAILED });
      dispatch(enqueueAlert({ message }));
      throw error;
    }
  };

export const verifyMfa =
  (mfaToken: string, mfaSecret: string) =>
  async (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: any
  ): Promise<boolean> => {
    dispatch(closeAlert());
    try {
      const { data } = await doRequest({
        method: 'post',
        requestUrl: '/auth/mfa',
        data: {
          mfaToken,
          mfaSecret,
        },
      });

      dispatch({ type: ActionType.LOGIN_USER_FULFILLED, loginResponse: data });

      return true;
    } catch (error) {
      const { message } = extractStatusAndMessageFromAPIError(error);
      dispatch(enqueueAlert({ message }));

      return false;
    }
  };

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cancelMfa = (mfaToken: string) => async (dispatch: any) => {
  try {
    await doRequest({ method: 'post', requestUrl: '/auth/cancel_mfa', data: { mfaToken } });
  } catch (e) {
    // Ignore.
  }
  dispatch({ type: ActionType.MFA_CANCELLED });
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logout = () => async (dispatch: any) => {
  try {
    await doRequestWithAuth({
      method: 'post',
      requestUrl: '/auth/logout',
      shouldRevalidate: false,
    });
    amplitude().logEvent('UserLogOut', {
      category: EVENT_CATEGORIES.LOGIN,
    });
  } catch (err) {
    dispatch(enqueueAlert({ message: 'Error occurred during logout.' }));
  } finally {
    dispatch({ type: ActionType.LOGOUT_USER_FULFILLED });
    // Do a full redirect to start app state from scratch. This guarantees 100% correctness for the
    // state of the app as opposed to trying to reset state in Redux, useSWR, and any other
    // libraries that maintain state in memory.
    window.location.href = '/login';
  }
};

/**
 * Return true if the user is verified or false if the user fails the verification or
 * it did not verify the user.
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const verifyUser = () => async (dispatch: any) => {
  try {
    const tokens = tokenStore.getAuthToken();

    if (!tokens || !tokens.accessToken) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.log('Nothing to verify.');
      return false;
    }

    const { data } = await doRequestWithAuth({
      method: 'post',
      requestUrl: '/auth/verify',
      data: {
        token: tokens.accessToken,
      },
    });

    dispatch({ verifyResponse: data, type: ActionType.VERIFY_USER });

    return true;
  } catch (e) {
    amplitude().logEvent('UserLogOutExpiredToken', {
      category: EVENT_CATEGORIES.LOGIN,
    });

    dispatch(logout());
    dispatch(enqueueAlert({ message: 'Session ended. Please relogin. ' }));

    return false;
  }
};

export const fetchTokenForSSOLogin =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: any) => {
    try {
      const { data } = await doRequest({
        method: 'get',
        requestUrl: '/auth/sso/token',
      });

      dispatch({ type: ActionType.LOGIN_USER_FULFILLED, loginResponse: data });

      return true;
    } catch (e) {
      const { message } = extractStatusAndMessageFromAPIError(e);
      amplitude().logEvent('UserLogInFailed', {
        category: EVENT_CATEGORIES.LOGIN,
        errorType: message,
      });
      dispatch({ type: ActionType.LOGIN_USER_FAILED });
      dispatch(enqueueAlert({ message }));
      throw e;
    }
  };

export const requestPasswordReset =
  ({ email }: { email: string }) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: any) => {
    try {
      await doRequest({
        method: 'post',
        requestUrl: '/auth/send_password_reset_link',
        data: {
          email,
        },
      });

      dispatch(enqueueAlert({ message: `Sent password reset link to ${email}` }));

      return true;
    } catch (error) {
      const { message } = extractStatusAndMessageFromAPIError(error);
      dispatch(enqueueAlert({ message }));

      return false;
    }
  };

/**
 * This is used for resetting password from the email link (with a token).
 */
export const resetPassword =
  (
    {
      resetToken,
      password,
    }: {
      resetToken: string;
      password: string;
    } // TODO: Fix this the next time the file is edited.
  ) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: any) => {
    try {
      await doRequest({
        method: 'post',
        requestUrl: '/auth/reset_password',
        data: {
          resetToken,
          password,
        },
      });

      return true;
    } catch (error) {
      const { message } = extractStatusAndMessageFromAPIError(error);
      dispatch(enqueueAlert({ message }));

      return false;
    }
  };

/**
 * This is used for changing an old password to a new one.
 */
export const updatePassword =
  ({ oldPassword, newPassword }) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: any) => {
    try {
      await doRequestWithAuth({
        method: 'post',
        requestUrl: '/auth/update_password',
        data: {
          oldPassword,
          newPassword,
        },
      });

      return true;
    } catch (e) {
      const { message } = extractStatusAndMessageFromAPIError(e);
      dispatch(enqueueAlert({ message: `Error: ${message}` }));

      return false;
    }
  };
