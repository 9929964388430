import useSWR from 'swr';
import { getXrayAccountPermission } from '~/api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useXrayAccountPermission(accountId: string | null | undefined) {
  return useSWR(
    accountId == null ? null : generateSwrKey(`/xray/permission/${accountId}$`),
    accountId == null ? undefined : () => getXrayAccountPermission(accountId)
  );
}
