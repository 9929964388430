import { Box, Button } from '@mui/material';
import React from 'react';

interface SaveFooterProps {
  formId: string;
}

const SaveFooter = React.forwardRef(({ formId }: SaveFooterProps, ref) => {
  return (
    <Box
      // Box is missing the type definition for a ref. But passing
      // a ref in actually works as intended, hence the @ts-ignore:
      ref={ref}
      bgcolor="white"
      borderColor="grey.100"
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      position="fixed"
      width="100%"
      bottom={0}
      right={0}
      px={5}
      py={2.5}
      borderTop={1}
      style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)' }}
    >
      <Button variant="contained" color="primary" type="submit" form={formId}>
        Save changes
      </Button>
    </Box>
  );
});

export default SaveFooter;
