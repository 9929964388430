import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { ActiveTiltForDisplay, getActiveTiltsSelection } from '../../utils';

export default function ActiveTiltSelection({
  activeTiltForDisplay,
}: {
  activeTiltForDisplay: ActiveTiltForDisplay;
}) {
  if (activeTiltForDisplay.portfolioType === 'ETF_EXCLUSIVE') {
    return (
      <Box display="flex" alignItems="center">
        <Box>Disabled</Box>
        <Tooltip title="ETF-only portfolios do not have an active tilt.">
          <Box display="flex" alignItems="center" color="grey.500" ml={0.5}>
            <InformationCircleIcon />
          </Box>
        </Tooltip>
      </Box>
    );
  }
  if (activeTiltForDisplay.portfolioType === 'TARGET_VALUE') {
    return (
      <Box display="flex" alignItems="center">
        <Box>Multi-factor (set by Vise) </Box>
        <Tooltip title="Vise automatically adjusts the asset allocation and active tilt based on the portfolio's target value and time horizon.">
          <Box display="flex" alignItems="center" color="grey.500" ml={0.5}>
            <InformationCircleIcon />
          </Box>
        </Tooltip>
      </Box>
    );
  }
  if (activeTiltForDisplay.portfolioType === 'LOSS_TOLERANCE') {
    return <>{getActiveTiltsSelection(activeTiltForDisplay.activeTilt)}</>;
  }
  return null;
}
