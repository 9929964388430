import useSWR from 'swr';
import { getInstrumentBenchmarks } from '~/api/api';
import generateSwrKey from '~/api/generateSwrKey';

export default function useInstrumentBenchmarks(options?: {
  fetchDailyData?: boolean;
  risk?: number;
}) {
  return useSWR(
    generateSwrKey(
      `/benchmark/benchmarks?fetchDailyData=${options?.fetchDailyData ? 'true' : ''}&risk=${
        options?.risk ?? ''
      }`
    ),
    () => getInstrumentBenchmarks(options)
  );
}
