import useSWR from 'swr';
import { getActiveTiltStrategyPerformanceMetrics } from '~/api/api';
import { GetActiveTiltStrategyPerformanceMetricsRequest } from '~/models/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useActiveTiltsStrategyPerformanceMetrics({
  tiltAmount,
  tiltType,
  assetClassKey,
}: GetActiveTiltStrategyPerformanceMetricsRequest) {
  return useSWR(
    [
      generateSwrKey(
        `/portfolio/active_tilt_strategy_performance_metrics?tiltAmount=${tiltAmount}&tiltType=${tiltType}&assetClassKey=${assetClassKey}`
      ),
    ],
    () =>
      getActiveTiltStrategyPerformanceMetrics({
        tiltAmount,
        tiltType,
        assetClassKey,
      })
  );
}
