import { Box, Typography } from '@mui/material';
import React from 'react';
import Banner from '~/synth/Banner';

export const DistributionsWarningBanner = (props: { numSchedules: number }) => (
  <Banner
    stripeColor="warning.400"
    bgColor="warning.100"
    message={
      <Box>
        <Typography variant="h4">
          {props.numSchedules} recurring distribution{props.numSchedules > 1 ? 's are' : 'is'} at
          risk of failing in the next 3 months due to insufficient value of positions that can be
          traded to cash.
        </Typography>
        <Box color="grey.600" mt={0.5}>
          Please adjust the schedule parameters or add cash to the portfolio
          {props.numSchedules > 1 ? 's' : ''} in order to continue with the schedule.
        </Box>
      </Box>
    }
    borderColor="warning.200"
  />
);

export const DistributionsErrorBanner = () => (
  <Banner
    stripeColor="error.400"
    bgColor="error.100"
    message={
      <Box>
        <Typography variant="h4">
          Distributions are paused due to insufficient available balance.
        </Typography>
        <Box color="grey.600" mt={0.5}>
          Vise will resume the paused distributions when the portfolio gains to a sufficient value.
        </Box>
      </Box>
    }
    borderColor="error.100"
  />
);
