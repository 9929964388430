import { Box, Button, FormControl, FormLabel, Grid, Typography } from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { OptionProps, components } from 'react-select';
import styled from 'styled-components';
import { createClient } from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useEnqueueToast from '~/hooks/useToast';
import { RawClient } from '~/models/api';
import { CreatableSelect } from '~/synth/inputs/Select';
import amplitude from '~/utils/amplitude';
import useHouseholdsWithSummary from '../../../hooks/useHouseholdsWithSummary';
import TextField from '../../../synth/TextField';
import { ScreenProps } from '../Types';
import { ActionFooter, BackButton, ContentBox } from './components';

interface ExistingHouseholdInfo {
  household: { id: string };
  type: 'existing';
}

interface ExistingHouseholdOption {
  householdInfo: ExistingHouseholdInfo;
  label: string;
  value: string;
}

interface NewHouseholdInfo {
  household: { name: string };
  type: 'new';
}

interface NewHouseholdOption {
  householdInfo: NewHouseholdInfo;
  label: string;
  value: string;
}

type HouseholdOption = ExistingHouseholdOption | NewHouseholdOption;

interface FormValues {
  firstName: string;
  lastName: string;
  newHouseholdInfo: ExistingHouseholdInfo | NewHouseholdInfo | undefined;
}

const Option = React.memo(function Option(props: OptionProps<HouseholdOption>) {
  const { data, isSelected, label, selectProps } = props;

  if (data.household == null) {
    return <components.Option {...props} />;
  }

  const clientContent = <>{data.household.clientCount} clients</>;
  return (
    <components.Option {...props}>
      <Box justifyContent="space-between" display="flex">
        <Highlighter
          autoEscape
          highlightTag="strong"
          searchWords={selectProps.inputValue ? [selectProps.inputValue] : []}
          textToHighlight={label}
        />
        <Box>
          {isSelected ? (
            clientContent
          ) : (
            <Typography color="textSecondary">{clientContent}</Typography>
          )}
        </Box>
      </Box>
    </components.Option>
  );
});

const BorderedBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export default function CreateClientScreen({
  dpDispatch,
  draftPortfolio: { newClientInfo, newHouseholdInfo },
  onBack,
  onContinue,
}: ScreenProps) {
  const { data: householdsData, isLoading: householdsIsLoading } = useHouseholdsWithSummary(true);
  const enqueueToast = useEnqueueToast();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const commitValues = useCallback(
    // Debounce "committing" values so only values that have settled are possibly written to the
    // store (and to the summary sidebar from the user's view). Values are only submitted if
    // (1) they are valid (2) they have changed from the last time this function was called.
    debounce((currValues, nextValues) => {
      if (
        currValues.firstName !== nextValues.firstName ||
        currValues.lastName !== nextValues.lastName
      ) {
        dpDispatch({
          newClientInfo: {
            client: { firstName: nextValues.firstName, lastName: nextValues.lastName },
            type: 'new',
          },
          type: 'SET_NEW_CLIENT_INFO',
        });
      }
    }, 250),
    [dpDispatch]
  );

  // Cancel pending debounces to ensure they don't trigger after this component unmounts.
  useEffect(() => {
    return () => {
      commitValues.cancel();
    };
  }, [commitValues]);

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: newClientInfo == null ? '' : newClientInfo.client.firstName,
      lastName: newClientInfo == null ? '' : newClientInfo.client.lastName,
      newHouseholdInfo: newHouseholdInfo ?? undefined,
    },
    async onSubmit() {
      // ->
      // if new household, create household, create client and link to household
      // if existing household, create client under existing household
      if (newHouseholdInfo == null || newClientInfo == null) return;
      let newClient: RawClient;
      try {
        newClient = await createClient(
          {
            firstName: newClientInfo.client.firstName,
            lastName: newClientInfo.client.lastName,
            clientGroupId:
              newHouseholdInfo.type === 'existing' ? newHouseholdInfo.household.id : undefined,
          },
          newHouseholdInfo.type === 'new' ? { name: newHouseholdInfo.household.name } : undefined
        );

        dpDispatch({
          clientId: newClient.id,
          type: 'SET_EXISTING_CLIENT',
        });
      } catch (e) {
        enqueueToast({
          title: 'There was an error creating the new client.',
          content: 'Please refresh and try again.',
          severity: 'error',
        });
        return;
      }

      amplitude().logEvent(
        newHouseholdInfo?.type === 'new'
          ? 'Continue to proposal from new household'
          : 'Continue to proposal from merged household',
        {
          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        }
      );
      onContinue();
    },
    validate(values) {
      const errors: FormikErrors<FormValues> = {};
      if (values.firstName === '') errors.firstName = 'First name is required';
      if (values.lastName === '') errors.lastName = 'Last name is required';
      if (values.newHouseholdInfo == null) errors.newHouseholdInfo = undefined;

      commitValues(
        { firstName: newClientInfo?.client.firstName, lastName: newClientInfo?.client.lastName },
        values
      );
      return errors;
    },
  });

  const householdOptions: HouseholdOption[] | null = useMemo(() => {
    return householdsData == null
      ? null
      : householdsData.map((hh) => ({
          householdInfo: { household: hh, type: 'existing' },
          label: hh.name,
          value: hh.id,
        }));
  }, [householdsData]);

  const formikNewHouseholdInfo = formik.values.newHouseholdInfo;
  const selectedHouseholdOption = useMemo(() => {
    if (formikNewHouseholdInfo == null) {
      return null;
    }

    if (formikNewHouseholdInfo.type === 'new') {
      return {
        householdInfo: formikNewHouseholdInfo,
        label: formikNewHouseholdInfo.household.name,
        value: formikNewHouseholdInfo.household.name,
      };
    }

    return householdOptions?.find(
      (opt) =>
        opt.householdInfo.type === 'existing' &&
        opt.householdInfo.household.id === formikNewHouseholdInfo.household.id
    );
  }, [formikNewHouseholdInfo, householdOptions]);

  function handleChangeHousehold(opt: HouseholdOption) {
    if (opt == null) {
      formik.setFieldValue('newHouseholdInfo', null);
      dpDispatch({
        newHouseholdInfo: null,
        type: 'SET_NEW_HOUSEHOLD_INFO',
      });
    } else {
      if (opt.householdInfo.type !== 'existing')
        throw new Error('householdInfo should exist for option');

      const nextNewHouseholdInfo = {
        household: { id: opt.householdInfo.household.id },
        type: 'existing' as const,
      };

      amplitude().logEvent('Select existing household', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        householdName: opt.label,
      });
      formik.setFieldValue('newHouseholdInfo', nextNewHouseholdInfo);
      dpDispatch({ newHouseholdInfo: nextNewHouseholdInfo, type: 'SET_NEW_HOUSEHOLD_INFO' });
    }
  }

  function handleCreateHousehold(name: string) {
    const nextNewHouseholdInfo = { household: { name }, type: 'new' as const };
    amplitude().logEvent('Create new household', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      householdName: name,
    });
    formik.setFieldValue('newHouseholdInfo', nextNewHouseholdInfo);
    dpDispatch({ newHouseholdInfo: nextNewHouseholdInfo, type: 'SET_NEW_HOUSEHOLD_INFO' });
  }

  return (
    <ContentBox>
      <Box mb={2}>
        <Typography variant="h1">Create a new client.</Typography>
      </Box>
      <Box display="flex" mb={2}>
        Create your client and assign a household name.
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <BorderedBox mb={2} pb={3}>
          <Grid container spacing={1}>
            <Grid item sm>
              <TextField
                autoFocus
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                fullWidth
                helperText={formik.touched.firstName && formik.errors.firstName}
                id="firstName"
                label="First name"
                name="firstName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.firstName}
              />
            </Grid>
            <Grid item sm>
              <TextField
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                fullWidth
                helperText={formik.touched.lastName && formik.errors.lastName}
                id="lastName"
                label="Last name"
                name="lastName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.lastName}
              />
            </Grid>
          </Grid>
        </BorderedBox>
        <Box>
          <Box mb={2}>
            <Typography variant="h3">
              Create a new household or merge client into an existing household
            </Typography>
          </Box>
          <FormControl fullWidth>
            <FormLabel htmlFor="household.name">Search for a household</FormLabel>
            <CreatableSelect
              components={{ Option }}
              formatCreateLabel={(inputValue: string) => <>+ Create {inputValue}</>}
              inputId="household.name"
              isClearable
              isLoading={householdsIsLoading}
              name="household.name"
              options={householdOptions}
              onChange={handleChangeHousehold}
              onCreateOption={handleCreateHousehold}
              onFocus={() => {
                amplitude().logEvent('Tap household search', {
                  category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                });
              }}
              placeholder="Ex: Carter Household"
              required
              value={selectedHouseholdOption}
            />
          </FormControl>
        </Box>
        <ActionFooter justifyContent="space-between">
          <BackButton onClick={() => onBack()} />
          <Button color="primary" disabled={!formik.isValid} type="submit" variant="contained">
            Continue
          </Button>
        </ActionFooter>
      </form>
    </ContentBox>
  );
}
