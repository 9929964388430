import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';

export function CardTitle({ children, ...restProps }: BoxProps) {
  return (
    <Box mb={1} {...restProps}>
      <Typography variant="h5" color="textSecondary">
        {children}
      </Typography>
    </Box>
  );
}

export default function CaptionWithContent({
  caption,
  content,
  ...boxProps
}: {
  caption: React.ReactNode;
  content: React.ReactNode;
} & Omit<BoxProps, 'content'>) {
  return (
    <Box {...boxProps}>
      <CardTitle>{caption}</CardTitle>
      <>{content}</>
    </Box>
  );
}
