import { useTheme } from '@mui/material';

export default function useCreditToColor() {
  const theme = useTheme();

  const CREDIT_TO_COLOR = {
    AAA: theme.palette.purple[100],
    AA_PLUS: theme.palette.mint.main,
    AA: theme.palette.blue[300],
    A: theme.palette.pink.light,
    UNKNOWN: theme.palette.grey[300],
    NOT_RATED: theme.palette.grey[600],
  };

  return { CREDIT_TO_COLOR };
}
