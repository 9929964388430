/**
 * New types defining interfaces between API and Web should instead live in ts/vise-types, so that the types may also be used
 * by API. Types in this file are being slowly moved over to the shared library.
 */
import { Custodian } from 'vise-types/custodian';
import { Order } from 'vise-types/orders';
import {
  ConstructedPortfolio,
  ConstructedPortfolioMetrics,
  LossBehavior,
  PortfolioIntelligenceOverview,
  StrategyMetadata,
} from 'vise-types/pce1';
import { StrategyPerformanceMetric } from 'vise-types/pce2';
import {
  AssetClassKey,
  Instrument as PCE2Instrument,
  Sector as PCE2Sector,
} from 'vise-types/pce2_instrument';
import {
  Account as RawAccount,
  PerformanceMetrics,
  PortfolioList,
  PortfolioSummary,
  RebalancerStatus,
  TimeWeightedReturn,
  AccountDataSource,
  EngineType,
} from 'vise-types/portfolio';

export type RawRefreshUserTokenResponse = {
  id: string;
  accessToken: string;
  refreshToken: string;
};

export type RawGetCapitalGainsResponse = {
  ytdLongTermGainLoss: number;
  ytdShortTermGainLoss: number;
  ytdShortTermGains: number;
  ytdShortTermLosses: number;
  ytdLongTermGains: number;
  ytdLongTermLosses: number;
  unrealizedShortTermLosses: number;
  unrealizedLongTermLosses: number;
  unrealizedShortTermGains: number;
  unrealizedLongTermGains: number;
  totalShortTermLosses: number;
  totalLongTermLosses: number;
  totalCostBasisBySymbolOrCusip: { [key: string]: number };
};

export type CapitalGains = RawGetCapitalGainsResponse;

export type Account = {
  accountNumber: string;
  accountType: string;
  advisorId: string;
  id: string;
  name: string;
  transitioned: boolean;
  firstName: string;
  lastName: string;
  nickname?: string;
  longName?: string;
  viseClientId?: string;
  taxable: boolean;
  custodianKey: Custodian;
  intelligence?: {
    risk?: number;
    pceVersion: string | undefined;
  };
  portfolioIntelligenceId?: string;
  summary: PortfolioSummary[];
  accountMetrics: PerformanceMetrics | null;
  status: string;
  statusReason?: string;
  rebalancerPausedAt: string | null;
  rebalancerStatus: RebalancerStatus;
  performanceQaRequired: boolean;
  accountDataSource: AccountDataSource;
  performance?: RawAccount['performance'];
  createdAt: string;
  engineType?: EngineType;
  cachedAum?: number | null;
};

export const ALL_EMPLOYMENT_STATUS_VALUES = [
  'EMPLOYED',
  'SELF_EMPLOYED',
  'RETIRED',
  'UNEMPLOYED',
] as const;

export type EmploymentStatus = (typeof ALL_EMPLOYMENT_STATUS_VALUES)[number];

export const EMPLOYMENT_STATUS_LABELS_MAP = [
  {
    label: 'Employed',
    value: 'EMPLOYED',
  },
  {
    label: 'Self-employed',
    value: 'SELF_EMPLOYED',
  },
  { label: 'Retired', value: 'RETIRED' },
  { label: 'Unemployed', value: 'UNEMPLOYED' },
] as Readonly<{ label: string; value: EmploymentStatus }[]>;

export type Client = RawClient;

export type NewClient = Partial<Omit<RawClient, 'accountValue' | 'id' | 'userId'>> &
  Pick<RawClient, 'firstName' | 'lastName'>;

export type RawGetHouseholdAccountsWithSummaryResponse = PortfolioList;

export interface Distribution {
  date: string;
  clientId: string;
  custodianCode: string;
  accountNumber: string;
  amount: number;
  portfolioIntelligenceId: string;
}

export type HouseholdAccountsWithSummary = {
  accounts: Account[];
  householdAggSummary: PortfolioSummary[];
  householdMetrics: PerformanceMetrics | null;
  householdMetricsForValidPerformanceAccounts: PerformanceMetrics | null;
  timeWeightedReturns: TimeWeightedReturn[];
  timeWeightedReturnsForValidPerformanceAccounts: TimeWeightedReturn[];
};

export type RawGetUnassignedAccountsResponse = RawAccount[];

export type RawGetClientAccountsResponse = RawAccount[];

export type RawGetHouseholdClientsResponse = RawClient[];

export type RawGetUserClientsResponse = RawClient[];

export type RawGetClientsResponse = RawClient[];

export type RawHousehold = {
  description: string;
  id: string;
  name: string;
  userId: string;
};

export type RawGetHouseholdResponse = RawHousehold;

export type RawDeleteHouseholdResponse = RawHousehold;

export type RawClient = {
  accountValue: number;
  birthday: Date;
  clientGroup?: RawHousehold;
  clientGroupId?: string;
  description?: string;
  employmentStatus: EmploymentStatus;
  firstName: string;
  id: string;
  lastName: string;
  lossBehavior: LossBehavior;
  portfolioMaxSize?: number;
  retirementDate: Date;
  userId: string;
};

export type Household = RawHousehold;

export type NewHousehold = { description?: string; name: string };

export type UpdateHouseholdRequestData = { id: string; name: string; description?: string };

export type UnconstrainedEfficientFrontier = {
  columns: string[];
  data: number[][];
};

export const ALL_PORTFOLIO_INTELLIGENCE_STATUS_VALUES = [
  'DECLINED',
  'ERROR',
  'EXECUTED',
  'NEEDS_REFRESH',
  'PENDING',
  'READY',
  'SOLVING',
] as const;

export type Subsector = {
  name: string;
  key: string;
  esg: {
    environmental: boolean;
    sin: boolean;
  };
};

export type Sector = {
  name: string;
  key: string;
  industries: Subsector[]; // industry == subsector in our terminology
};

export interface TransformedPortfolioTrade {
  action: 'Buy' | 'Sell';
  name: string;
  price: number;
  shares: number;
  ticker: string;
  value: number;
}

export interface AdjustedSimulation {
  configuredData: number[][];
  percentile: string;
  target: null;
}

export type AssetClassAllocation = { name: AssetClassKey | 'LOCKED'; y: number }[];
export type SectorAllocation = { name: PCE2Sector | 'LOCKED'; y: number }[];
export type Allocation = AssetClassAllocation | SectorAllocation;

export interface AdjustedConstructedPortfolioMetrics
  extends Omit<ConstructedPortfolioMetrics, 'asset_allocation' | 'sector_allocation'> {
  asset_allocation: Allocation;
  factors: string[];
  newExpectedStandardDeviation: number;
  newPortfolioMean: number;
  newProjectedSharpeRatio: number;
  newVariance: number;
  oldExpectedStandardDeviation: number;
  oldPortfolioMean: number;
  oldProjectedSharpeRatio: number;
  oldVariance: number;
  sector_allocation: Allocation;
}

interface AdjustedConstructedPortfolio
  extends Omit<ConstructedPortfolio, 'backtest' | 'metrics' | 'simulations'> {
  backtest: number[][];
  metrics: AdjustedConstructedPortfolioMetrics;
  simulations: AdjustedSimulation[];
}

// a variation of PortfolioIntelligenceFull with constructedPortfolio and instruments type swapped out
export type AdjustedPortfolioIntelligence =
  PortfolioIntelligenceOverview<AdjustedConstructedPortfolio> & {
    pceVersion: 'pce2';
    pce2Instruments: { [symbol: string]: PCE2Instrument };
    strategyMetadata: StrategyMetadata;
  };

export type RawGetHouseholdProposalsResponse = PortfolioIntelligenceOverview[];

export interface GetPortfolioIntelligenceOrdersResponse {
  instruments: PCE2Instrument[];
  orders: Order[];
}

export type HouseholdWithSummary = Household & {
  clientCount: number;
  transitionedPortfolioCount: number;
  untransitionedPortfolioCount: number;
  aum?: number;
  householdMetrics?: PerformanceMetrics;
  householdMetricsForValidPerformanceAccounts?: PerformanceMetrics;
};

export type RawHouseholdWithSummary = RawHousehold & {
  clientCount: number;
  transitionedPortfolioCount: number;
  untransitionedPortfolioCount: number;
  householdAggSummary: PortfolioSummary[];
  householdMetrics: PerformanceMetrics;
  householdMetricsForValidPerformanceAccounts: PerformanceMetrics;
};

export type RawGetHouseholdsWithSummaryResponse = RawHouseholdWithSummary[];

export interface Notification {
  id: string;
  read: boolean;
  type: 'ACTION' | 'INFO';
}

export interface RawBenchmark {
  dailyData?: { date: string; value: number }[];
  description: string;
  name: string;
}

export interface Benchmark extends RawBenchmark {
  dailyDataHC: [number, number][];
}

export interface PerformanceAndBenchmarks {
  bdTimeseries: [number, number][];
  benchmarks: Benchmark[];
}

export interface GetActiveTiltStrategyPerformanceMetricsRequest {
  assetClassKey: AssetClassKey;
  tiltAmount: number;
  tiltType: 'multi-factor' | 'dividend';
}

interface StrategyPerformanceMetricsAtTimePeriod {
  timePeriod: number;
  strategyPerformanceMetrics: StrategyPerformanceMetric[];
}

export interface GetActiveTiltStrategyPerformanceMetricsResponse {
  results: StrategyPerformanceMetricsAtTimePeriod[];
}

export interface PinResponse {
  templateId: string;
  finalToggleState: boolean;
  id: string;
  type: 'allocations' | 'restrictions';
}

export enum TemplateUpdateJobStatus {
  PENDING = 'PENDING',
  PROPOSALS_CREATED = 'PROPOSALS_CREATED',
  APPLIED = 'APPLIED',
  IGNORED = 'IGNORED',
}

export interface JobsResponse {
  templateId: string;
  templateName: string;
  templateType: 'ALLOCATION' | 'RESTRICTION';
  numberProposalsCreated: number;
  numberAccountsAffected: number;
  numberErrors: number;
  jobStatus: TemplateUpdateJobStatus;
  id: string;
}

export enum TemplateUpdateEventStatus {
  PROPOSAL_PENDING = 'PROPOSAL_PENDING',
  PROPOSAL_CREATED = 'PROPOSAL_CREATED',
  PROPOSAL_ERROR = 'PROPOSAL_ERROR',

  // Upon successful proposal, an update can either be executed or ignored.
  PROPOSAL_IGNORED = 'PROPOSAL_IGNORED',
  PROPOSAL_EXECUTED = 'PROPOSAL_EXECUTED',

  // We canceled a bulk edit job before proposal were generated
  PROPOSAL_CANCELLED = 'PROPOSAL_CANCELLED',
}

export enum TemplateAccountUpdateType {
  APPLY = 'APPLY',
  REMOVE = 'REMOVE',
}
