import { Box, Card, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';
import { TaxOptions } from 'vise-types/pce2';
import { CapitalGains } from '~/models/api';
import CardHeader from '~/synth/CardHeader';
import { ACCOUNTING_FORMATTER, formatCurrency } from '~/utils/format';
import { TaxDisclosureExpansion } from './DisclosureExpansion';

function NetGainsFormatter({ gains, limit }: { gains: number; limit?: number }) {
  return (
    <Box display="flex">
      {formatCurrency(gains, ACCOUNTING_FORMATTER)}
      <Box color="grey.500" ml={0.5}>
        {limit != null ? <>of {formatCurrency(limit)}</> : <>(no limit set)</>}
      </Box>
    </Box>
  );
}

export default function TaxManagementCard({
  gains,
  autoTlh,
  taxOptions,
  excludeTableAndDisclosures,
}: {
  gains: CapitalGains;
  autoTlh?: boolean;
  taxOptions?: TaxOptions;
  excludeTableAndDisclosures?: boolean;
}) {
  const { ytdLongTermGainLoss, ytdShortTermGainLoss } = gains;

  const rows = [
    {
      id: 'net',
      rowType: 'Net',
      shortTerm: [
        ytdShortTermGainLoss,
        ...(taxOptions?.maxCapGainsShort ? [taxOptions.maxCapGainsShort] : []),
      ],
      longTerm: [
        ytdLongTermGainLoss,
        ...(taxOptions?.maxCapGainsLong ? [taxOptions.maxCapGainsLong] : []),
      ],
    },
  ];

  const columns: GridColDef[] = [
    {
      headerName: '',
      field: 'rowType',
      renderCell: ({ value, row }) => (
        <Box fontWeight={row.rowType === 'Net' ? 500 : undefined}>{value}</Box>
      ),
      flex: 1,
    },
    {
      headerName: 'Short term',
      field: 'shortTerm',
      renderCell: ({ value }) =>
        value.length > 1 ? (
          <NetGainsFormatter gains={value[0]} limit={value[1]} />
        ) : (
          formatCurrency(value, ACCOUNTING_FORMATTER)
        ),
      flex: 1,
    },
    {
      headerName: 'Long term',
      field: 'longTerm',
      renderCell: ({ value }) =>
        value.length > 1 ? (
          <NetGainsFormatter gains={value[0]} limit={value[1]} />
        ) : (
          formatCurrency(value, ACCOUNTING_FORMATTER)
        ),
      flex: 1,
    },
  ];

  return (
    <Card>
      <CardHeader>
        <Typography variant="h4">Tax management</Typography>
      </CardHeader>
      {autoTlh != null && (
        <CardHeader display="flex" justifyContent="space-between">
          Tax loss harvesting
          <Box color={autoTlh ? 'success.400' : 'grey.600'}>{autoTlh ? 'Active' : 'Off'}</Box>
        </CardHeader>
      )}
      {excludeTableAndDisclosures !== false && (
        <>
          <DataGridPro columns={columns} rows={rows} autoHeight />
          <Box px={3} py={2}>
            <TaxDisclosureExpansion />
          </Box>
        </>
      )}
    </Card>
  );
}
