import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import { ReactComponent as ChevronDown } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '~/static/images/icons/chevron-up.svg';
import { ReactComponent as LinkIcon } from '~/static/images/icons/link.svg';
import { ReactComponent as LockIcon } from '~/static/images/icons/lock-closed.svg';
import { ReactComponent as UserGroupIcon } from '~/static/images/icons/user-group.svg';

import React, { useState } from 'react';
import { toggleXrayAccountPermission } from '~/api/api';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import DialogTitle from '~/synth/DialogTitle';
import { TextHighlightTag } from '~/synth/Tag';

export function XrayAccessTag({ orgAccess }: { orgAccess: boolean }) {
  return (
    <TextHighlightTag severity={orgAccess ? 'alert' : 'default'} sx={{ mr: 1 }}>
      <Box mr={0.5}>{orgAccess ? 'Org access' : 'Private'}</Box>
      {orgAccess ? <UserGroupIcon /> : <LockIcon />}
    </TextHighlightTag>
  );
}

export default function UpdateXrayAccess({
  mutate,
  accountId,
  isOrgAccess,
  open,
  onClose,
}: {
  mutate: () => void;
  accountId: string;
  isOrgAccess: boolean;
  open: boolean;
  onClose: () => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const enqueueCoachmark = useEnqueueCoachmark();

  const [orgAccess, setOrgAccess] = useState(isOrgAccess);
  const [copySuccess, setCopySuccess] = useState(false);

  async function handleSubmit() {
    try {
      const result = await toggleXrayAccountPermission(accountId, orgAccess);
      if (result.data.success) {
        mutate();
        onClose();
      }
    } catch {
      enqueueCoachmark({
        title: 'There was an issue updating the X-Ray permissions.',
        content: 'Please reach out to clientservice@vise.com.',
        severity: 'error',
      });
    }
  }

  async function handleCopy() {
    await navigator.clipboard.writeText(window.location.href);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  }

  return (
    <Dialog open={open}>
      <DialogTitle
        onClose={() => {
          onClose();
          setOrgAccess(isOrgAccess);
        }}
      >
        Share this X-Ray
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography color="textSecondary">
            Give members of your organization access to this X-Ray by copying the share link below
            and sending it to your colleagues.
          </Typography>
          <Box fontWeight="bold" mt={2} mb={2}>
            Permissions
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py={2}
            borderTop={1}
            borderBottom={1}
            borderColor={tokens.palette.neutralCool[200]}
          >
            <Typography color="textSecondary">X-Ray sharing</Typography>
            <ButtonBase onClick={handleClick}>
              <XrayAccessTag orgAccess={orgAccess} />
              {menuOpen ? <ChevronUp /> : <ChevronDown />}
            </ButtonBase>
            <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleClose} sx={{ mt: 1 }}>
              <MenuItem
                onClick={() => {
                  setOrgAccess(false);
                  handleClose();
                }}
              >
                Private
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOrgAccess(true);
                  handleClose();
                }}
              >
                Org access
              </MenuItem>
            </Menu>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
            <TextField disabled={!orgAccess} value={window.location.href} sx={{ width: '60%' }} />

            <Button
              size="small"
              variant="text"
              disabled={!orgAccess}
              endIcon={!copySuccess && <LinkIcon />}
              onClick={handleCopy}
            >
              {copySuccess ? (
                <Box
                  sx={{
                    backgroundImage:
                      'linear-gradient(90deg, #2CC4D2 -6.16%, #1753DE 40.12%, #670AF4 85.58%)',
                    backgroundSize: '100%',
                    backgroundClip: 'text',
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    Link copied to clipboard!
                  </Box>
                </Box>
              ) : (
                'Copy link'
              )}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} type="button" variant="outlined">
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={isOrgAccess === orgAccess}
          >
            Save changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
