import { Button } from '@mui/material';
import { styled } from '@mui/system';

const HelpButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.blue[100],
  borderRadius: 20,
  '&:hover': {
    backgroundColor: theme.palette.blue[200],
  },
}));

export default HelpButton;
