"use client";

import React from "react";
import { Box } from "@mui/material";
import type { BoxProps } from "@mui/material";
import Typography from "./Typography.js";
import { SPACING_INDICES } from "../tokens/spacing.js";

export type Variant =
  | "info"
  | "priority"
  | "in-progress"
  | "success"
  | "warning"
  | "error";

export default function Pill({
  children,
  variant,
  icon,
  sx,
}: {
  children: React.ReactNode;
  variant: Variant;
  icon?: React.ReactNode;
  sx?: BoxProps["sx"];
}): React.ReactElement {
  let color = "neutralCool.600";
  let bgColor = "neutralCool.200";
  if (variant === "priority") {
    color = "primaryBlue.600";
    bgColor = "primaryBlue.100";
  }
  if (variant === "in-progress") {
    color = "secondaryPurple.600";
    bgColor = "secondaryPurple.100";
  }
  if (variant === "success") {
    color = "semanticGreen.600";
    bgColor = "semanticGreen.100";
  }
  if (variant === "warning") {
    color = "semanticOrange.600";
    bgColor = "semanticOrange.100";
  }
  if (variant === "error") {
    color = "semanticRed.600";
    bgColor = "semanticRed.100";
  }
  let body = children;
  if (icon != null) {
    body = (
      <Box display="flex" alignItems="center">
        <Box
          height="16px"
          width="16px"
          alignItems="center"
          justifyContent="center"
          mr={SPACING_INDICES.xs}
        >
          {icon}
        </Box>
        <Box>{children}</Box>
      </Box>
    );
  }
  return (
    <Box
      px="12px"
      py="4px"
      borderRadius="20px"
      bgcolor={bgColor}
      display="inline-block"
      sx={sx}
    >
      <Typography variant="body2" color={color}>
        {body}
      </Typography>
    </Box>
  );
}
