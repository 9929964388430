import { Box, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';
import { TaxOptions } from 'vise-types/pce2';
import { TradesMetrics } from 'vise-types/portfolio_metrics';
import { RawGetCapitalGainsResponse } from '~/models/api';
import CardHeader from '~/synth/CardHeader';
import { DataTableContainer } from '~/synth/DataTable';
import { ACCOUNTING_FORMATTER, formatCurrency } from '~/utils/format';
import { TaxDisclosureExpansion } from './DisclosureExpansion';

const PROPOSAL_DISCLOSURE =
  'These figures represent estimated impact on realized gains/losses of these proposal changes at the time this proposal was generated. Actual impact will be determined based on the trades that will be executed, which may be different to the trades listed below.';

const ORDER_DISCLOSURE =
  "The figures displayed here represent an estimate of this trade's impact on the portfolio at the time of generation. The actual impact of this trade on your portfolio is dependent upon market conditions at the point of execution and may vary from the estimated figures displayed above.";

interface TaxImpactTableRow {
  metric: string;
  longTerm: number;
  shortTerm: number;
  total: number;
  variant: 'order' | 'proposal';
}

export default function TaxImpactTable({
  gains,
  taxOptions,
  taxMetrics,
  hideYTD,
  variant,
}: {
  gains: RawGetCapitalGainsResponse;
  taxOptions: TaxOptions;
  taxMetrics: TradesMetrics;
  hideYTD?: boolean;
  variant: 'proposal' | 'order';
}) {
  const { shortTermCapGains, shortTermCapLosses, longTermCapGains, longTermCapLosses } = taxMetrics;
  const { ytdLongTermGainLoss, ytdShortTermGainLoss } = gains;
  const { maxCapGainsShort, maxCapGainsLong } = taxOptions;

  const columns: GridColDef[] = [
    { headerName: 'Potential realized gain/loss', field: 'metric', flex: 1, sortable: false },
    {
      headerName: 'Short term',
      headerAlign: 'right',
      field: 'shortTerm',
      align: 'right',
      renderCell: (props) => (
        <Box display="flex" justifyContent="flex-end">
          {formatCurrency(props.value, ACCOUNTING_FORMATTER)}
          {props.row.variant === 'netImpact' && (
            <Box ml={0.5} color="grey.500">
              {maxCapGainsShort != null ? (
                <>of {formatCurrency(maxCapGainsShort)}</>
              ) : (
                <>(no limit set)</>
              )}
            </Box>
          )}
        </Box>
      ),
      flex: 1,
      sortable: false,
    },

    {
      headerName: 'Long term',
      headerAlign: 'right',
      field: 'longTerm',
      align: 'right',
      renderCell: (props) => (
        <Box display="flex" justifyContent="flex-end">
          {formatCurrency(props.value, ACCOUNTING_FORMATTER)}
          {props.row.variant === 'netImpact' && (
            <Box ml={0.5} color="grey.500">
              {maxCapGainsLong != null ? (
                <>of {formatCurrency(maxCapGainsLong)}</>
              ) : (
                <>(no limit set)</>
              )}
            </Box>
          )}
        </Box>
      ),
      flex: 1,
      sortable: false,
    },

    {
      align: 'right',
      headerAlign: 'right',
      headerName: 'Total',
      field: 'total',

      renderCell: (props) => (
        <div style={{ textAlign: 'right' }}>
          {formatCurrency(props.value, ACCOUNTING_FORMATTER)}
        </div>
      ),
      flex: 1,
      sortable: false,
    },
  ];
  const data = [
    {
      metric: 'Gains',
      shortTerm: shortTermCapGains,
      longTerm: longTermCapGains,
      total: shortTermCapGains + longTermCapGains,
      variant: 'tradeImpact',
    },
    {
      metric: 'Losses',
      shortTerm: -shortTermCapLosses,
      longTerm: -longTermCapLosses,
      total: -(shortTermCapLosses + longTermCapLosses),
      variant: 'tradeImpact',
    },
    {
      metric: 'Net',
      shortTerm: shortTermCapGains - shortTermCapLosses,
      longTerm: longTermCapGains - longTermCapLosses,
      total: shortTermCapGains - shortTermCapLosses + (longTermCapGains - longTermCapLosses),
      variant: 'tradeImpact',
    },
    ...(!hideYTD
      ? [
          {
            metric: 'Net realized YTD',
            shortTerm: ytdShortTermGainLoss + shortTermCapGains - shortTermCapLosses,
            longTerm: ytdLongTermGainLoss + longTermCapGains - longTermCapLosses,
            total:
              ytdShortTermGainLoss +
              ytdLongTermGainLoss +
              shortTermCapGains -
              shortTermCapLosses +
              (longTermCapGains - longTermCapLosses),
            variant: 'netImpact',
          },
        ]
      : []),
  ] as TaxImpactTableRow[];

  return (
    <DataTableContainer>
      <CardHeader>
        <Typography variant="h4">
          {variant === 'proposal' ? 'Projected realized gains/losses' : 'Realized gains/losses'}
        </Typography>
      </CardHeader>
      <DataGridPro
        columns={columns}
        rows={data}
        getRowId={(row) => `${row.metric}_${row.shortTerm}_${row.longTerm}_${row.total}`}
      />
      <Box py={2} px={3}>
        <TaxDisclosureExpansion
          minimizedTaxDisclosures={variant === 'proposal' ? PROPOSAL_DISCLOSURE : ORDER_DISCLOSURE}
        />
      </Box>
    </DataTableContainer>
  );
}
