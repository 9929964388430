import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import useAllocationsTemplate from '~/hooks/templates/useAllocationsTemplate';
import useRestrictionsTemplates from '~/hooks/templates/useRestrictionsTemplates';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { isValid } from 'date-fns';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import amplitude from '~/utils/amplitude';
import AllocationTemplateCreate from './AllocationTemplateCreate';
import RestrictionTemplateCreate from './RestrictionTemplateCreate';

function AllocationTemplateEdit({ templateId }: { templateId: string }) {
  const { data: template } = useAllocationsTemplate(templateId);
  if (!template) {
    return null;
  }
  return <AllocationTemplateCreate template={template} />;
}

function RestrictionTemplateEdit({ templateId }: { templateId: string }) {
  const { data: template } = useRestrictionsTemplates([templateId]);
  const { data: featureFlags } = useFeatureFlags();
  if (!template || !template.length || !featureFlags) {
    return null;
  }
  const gicsCutoffDate = new Date(featureFlags.gics_frontend_cutoff_date || 'Invalid');
  const templateCreatedBeforeCutoffDate =
    isValid(gicsCutoffDate) && new Date(template[0].createdAt) < gicsCutoffDate;
  const templateHasLegacySectors =
    templateCreatedBeforeCutoffDate &&
    (template[0].sectors.length || template[0].subSectors.length);
  return (
    <RestrictionTemplateCreate
      template={
        templateHasLegacySectors ? { ...template[0], subSectors: [], sectors: [] } : template[0]
      }
    />
  );
}

export default function TemplateEdit() {
  const match = useRouteMatch<{
    templateType: 'allocations' | 'restrictions';
    templateId: string;
  }>();
  useEffect(() => {
    amplitude().logEvent(`Impression - ${match.params.templateType} template edit flow`, {
      category: EVENT_CATEGORIES.STRATEGY_CENTER,
    });
  }, [match.params.templateType]);
  if (match.params.templateType === 'allocations') {
    return <AllocationTemplateEdit templateId={match.params.templateId} />;
  }
  return <RestrictionTemplateEdit templateId={match.params.templateId} />;
}
