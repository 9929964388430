import { useTheme } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';

interface AssetColors {
  main: string;
  colors: string[];
}

export interface AssetToColorMap {
  EQUITY: AssetColors;
  FIXED_INCOME: AssetColors;
  ALTERNATIVES: AssetColors;
  CASH: AssetColors;
  UNKNOWN: AssetColors;
  MORE: AssetColors;
  LOCKED: AssetColors;
}

// The color ordering for each sections alternates between dark to light and light to dark.
// This is so the allocation donut chart creates a gradient from dark to light to dark etc.
export function useAssetToColorMap() {
  const theme = useTheme();

  return {
    EQUITY: {
      main: theme.palette.purple[200],
      colors: Object.values(theme.palette.purple),
    },
    FIXED_INCOME: {
      main: theme.palette.blue[300],
      colors: Object.values(theme.palette.assetBlue).reverse(),
    },
    ALTERNATIVES: {
      main: theme.palette.pink.light,
      colors: Object.values(theme.palette.pink),
    },
    CASH: {
      main: tokens.palette.secondaryTurquoise[300],
      colors: [tokens.palette.secondaryTurquoise[300]],
    },

    UNKNOWN: {
      main: theme.palette.grey[300],
      colors: [theme.palette.grey[300], theme.palette.grey[400]],
    },
    MORE: {
      main: theme.palette.grey[300],
      colors: [theme.palette.grey[300], theme.palette.grey[400]],
    },
    LOCKED: {
      main: theme.palette.grey[300],
      colors: [theme.palette.grey[300], theme.palette.grey[400]],
    },
  };
}
