import { Box, Container, IconButton } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Column, Row } from 'react-table';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import useAllAccountsWithPIAndHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import ViseLogoBlack from '~/static/images/vise-logo-black.svg';
import { DataTable } from '~/synth/DataTable';
import { AccountNameCell } from '../Households/table/UtilComponents';

export default function BulkUpdate() {
  const { data: accountsRespWithData } = useAllAccountsWithPIAndHouseholdInfo({
    transitioned: true,
  });

  // TODO: pass selected rows to api
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRows, setSelectedRows] = useState<Row<AccountWithPIAndHouseholdInfo>[]>([]);

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Account',
          accessor: 'accountName',
          align: 'left',
          Cell: (props) => {
            const { accountName, accountNumber, taxable, custodianKey } = props.row.original;
            return (
              <AccountNameCell
                accountName={accountName}
                accountNumber={accountNumber}
                taxable={taxable}
                custodianKey={custodianKey}
              />
            );
          },
          // Sort by account name & then by account number
          sortType: (rowA, rowB) => {
            const {
              original: { accountName: accountNameA, accountNumber: accountNumberA },
            } = rowA;
            const {
              original: { accountName: accountNameB, accountNumber: accountNumberB },
            } = rowB;
            const compareResult = accountNameA.localeCompare(accountNameB);
            if (compareResult !== 0) {
              return compareResult;
            }

            return accountNumberA.localeCompare(accountNumberB);
          },
          width: '35%',
        },
      ] as Column<AccountWithPIAndHouseholdInfo>[],
    []
  );
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>Bulk update</title>
      </Helmet>
      <Box boxShadow="0px 1px 0px #E9E9E9">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          maxWidth={1200}
          m="auto"
        >
          <Box display="flex">
            <img alt="V" src={ViseLogoBlack} height="21" width="17" />
            <Box fontWeight={500} ml={1.5}>
              Bulk update
            </Box>
          </Box>
          <IconButton
            aria-label="Exit"
            color="inherit"
            onClick={() => history.goBack()}
            role="link"
            size="large"
          >
            <XIcon display="inline-flex" />
          </IconButton>
        </Box>
      </Box>
      <Container>
        {accountsRespWithData?.data ? (
          <DataTable
            columns={columns}
            data={accountsRespWithData.data}
            onRowSelect={(rows) => setSelectedRows(rows)}
          />
        ) : (
          <></>
        )}
      </Container>
    </>
  );
}
