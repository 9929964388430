import React, { useMemo } from 'react';
import { Card, Divider, Grid, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { theme, tokens } from '@vise_inc/ds-vise';
import { formatCurrency, getAccountTypeDisplayName, getCustodianDisplayName } from '~/utils/format';
import { ReactComponent as LineChartIcon } from '~/static/images/icons/line-chart.svg';
import { ReactComponent as ChessPieceIcon } from '~/static/images/icons/chess-piece.svg';
import { ReactComponent as BriefcaseIcon } from '~/static/images/icons/briefcase.svg';
import useAllAccountsWithHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import useAllocationsTemplate from '~/hooks/templates/useAllocationsTemplate';
import moment from 'moment';
import { Account } from 'vise-types/portfolio';
import { getHorizonContent } from '../../Portfolio/PortfolioInputs';
import renderAccountStatus from '../../Households/components/renderAccountStatus';
import GPTCardHeader from './GPTCardHeader';
import LoadingCard from './LoadingCard';
import CardDisclosures from './CardDisclosures';
import usePortfolioMetricsWithLogging from './usePortfolioMetricsWithLogging';
import usePortfolioIntelligenceWithLogging from './usePortfolioIntelligenceWithLogging';

function TopLevelMetricsCard({
  title,
  bottomLeft,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // bottomRight,
  backgroundColor,
  borderColor,
  icon,
}: {
  title: React.ReactNode;
  bottomLeft: React.ReactNode;
  // bottomRight: React.ReactNode;
  description?: React.ReactNode;
  backgroundColor: string;
  borderColor: string;
  icon: React.ReactNode;
}) {
  return (
    <ThemeProvider theme={theme}>
      <Box
        boxShadow={tokens.shadow.base}
        borderRadius="4px"
        sx={{ borderColor, borderStyle: 'solid', borderWidth: '1px' }}
        p={4}
        bgcolor={backgroundColor}
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography variant="h5" color="neutralCool.600">
              {title}
            </Typography>
          </Box>
          {icon}
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography variant="h3" color="neutralCool.1000">
              {bottomLeft}
            </Typography>
          </Box>
          {/* <Box>
            <Typography variant="h5" color={borderColor}>
              {bottomRight}
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const RowDetails = ({
  left,
  right,
  dividerBelow,
}: {
  left: string;
  right: React.ReactNode;
  dividerBelow?: boolean;
}) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography color={theme.palette.grey[900]} fontSize={12} fontWeight={500}>
          {left}
        </Typography>
        <Typography color={theme.palette.grey[600]} fontSize={12} fontWeight={500}>
          {right}
        </Typography>
      </Box>
      {dividerBelow && <Divider />}
    </>
  );
};

const GPTPortfolioInsightsCard = ({ accountId }) => {
  // const { data: accountResponseData } = useAccount(accountId, true);

  const { data: accountsWithHouseholds } = useAllAccountsWithHouseholdInfo({
    includeSummaries: false,
    includePi: true,
  });
  const accountWithHousehold = useMemo(
    () => accountsWithHouseholds?.data.find((account) => account.id === accountId),
    [accountsWithHouseholds, accountId]
  );

  const { data: portfolioMetrics } = usePortfolioMetricsWithLogging(
    accountId,
    'GPTPortfolioInsightsCard'
  );

  const { data: portfolioIntelligences } = usePortfolioIntelligenceWithLogging(
    accountWithHousehold?.portfolioIntelligenceId,
    'GPTPortfolioInsightsCard'
  );

  const intelligence = portfolioIntelligences?.[0];

  const { data: allocationTemplate } = useAllocationsTemplate(intelligence?.allocationTemplateId);

  const assetClassAllocationRequest = intelligence?.constructionRequest.assetClassAllocationRequest;
  const horizonEndDate =
    assetClassAllocationRequest == null || assetClassAllocationRequest.years === 0
      ? null
      : moment(assetClassAllocationRequest.timeHorizonStartTimestamp).add(
          assetClassAllocationRequest.years,
          'years'
        );

  const allocationSplit = useMemo(() => {
    const managedFraction = portfolioMetrics?.strategyMetrics?.targetPortfolio.managedFraction || 1;
    if (!portfolioMetrics?.strategyMetrics?.targetPortfolio.assetClassAllocations) {
      return '–';
    }

    const equity = portfolioMetrics?.strategyMetrics?.targetPortfolio.assetClassAllocations.reduce(
      (acc, alloc) => acc + (alloc.assetClass.includes('EQUITY') ? alloc.allocationFraction : 0),
      0
    );
    const fixedIncome =
      portfolioMetrics?.strategyMetrics?.targetPortfolio.assetClassAllocations.reduce(
        (acc, alloc) =>
          acc + (alloc.assetClass.includes('FIXED_INCOME') ? alloc.allocationFraction : 0),
        0
      );
    const alternatives =
      portfolioMetrics?.strategyMetrics?.targetPortfolio.assetClassAllocations.reduce(
        (acc, alloc) =>
          acc + (alloc.assetClass.includes('ALTERNATIVES') ? alloc.allocationFraction : 0),
        0
      );

    return `${((equity / managedFraction) * 100).toFixed(0) || 0}/${
      ((fixedIncome / managedFraction) * 100).toFixed(0) || 0
    }/${((alternatives / managedFraction) * 100).toFixed(0) || 0}`;
  }, [
    portfolioMetrics?.strategyMetrics?.targetPortfolio.assetClassAllocations,
    portfolioMetrics?.strategyMetrics?.targetPortfolio.managedFraction,
  ]);

  const rebalancerStatus = useMemo(() => {
    if (accountWithHousehold?.rebalancerStatus === 'ACTIVE') {
      return 'Active';
    }
    if (accountWithHousehold?.rebalancerStatus === 'PAUSED') {
      return 'Paused';
    }
    return null;
  }, [accountWithHousehold?.rebalancerStatus]);

  if (!accountWithHousehold || !portfolioMetrics) {
    return <LoadingCard contentHeight="290px" />;
  }

  return (
    <Card>
      <GPTCardHeader
        title="Portfolio Insights"
        buttonPathname={`/secure/accounts/${accountId}/portfolio/overview`}
        buttonCopy="View account"
        account={accountWithHousehold as Account}
      />
      <Box display="flex" flexDirection="row" py={2} px={3} height="290px">
        <Box display="flex" flexDirection="column" width="500px">
          <Typography color="neutralsWarm.1000" fontWeight="500">
            {`${accountWithHousehold.accountName} – ${getAccountTypeDisplayName(
              accountWithHousehold?.accountType || ''
            )}`}
          </Typography>
          <Box py={2} />
          <Box display="flex" flexDirection="column" gap={1}>
            <RowDetails left="Household name" right={accountWithHousehold.householdInfo?.name} />
            <Divider />
            <RowDetails
              left="Account status"
              right={renderAccountStatus({
                value: {
                  status: accountWithHousehold.status,
                  statusReason: accountWithHousehold.statusReason,
                },
                statusOnly: true,
              })}
            />
            <Divider />
            <RowDetails left="Rebalancer status" right={rebalancerStatus} />
            <Divider />
            {horizonEndDate && (
              <>
                <RowDetails left="Time horizon" right={getHorizonContent(horizonEndDate)} />
                <Divider />
              </>
            )}
            <RowDetails left="Target portfolio split" right={allocationSplit} />
            <Divider />
            <RowDetails left="Template name" right={allocationTemplate?.name || '–'} />
          </Box>
          <Box py={2} />
        </Box>

        <Box px={4}>
          <Divider orientation="vertical" />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TopLevelMetricsCard
              title="Portfolio Value"
              bottomLeft={formatCurrency(portfolioMetrics.portfolioMetrics.totalValue)}
              backgroundColor="primaryBlue.100"
              borderColor="primaryBlue.900"
              icon={<LineChartIcon color={tokens.palette.primaryBlue[900]} height={20} />}
            />
          </Grid>
          <Grid item xs={6}>
            <TopLevelMetricsCard
              title="Account type"
              bottomLeft={accountWithHousehold.taxable ? 'Taxable' : 'Non-taxable'}
              backgroundColor="secondaryTurquoise.100"
              borderColor="secondaryTurquoise.800"
              icon={<LineChartIcon color={tokens.palette.secondaryTurquoise[800]} height={20} />}
            />
          </Grid>
          <Grid item xs={6}>
            <TopLevelMetricsCard
              title="Portfolio cash value"
              bottomLeft={formatCurrency(portfolioMetrics.portfolioMetrics.cashValue)}
              backgroundColor="secondaryYellow.100"
              borderColor="secondaryYellow.800"
              icon={<BriefcaseIcon color={tokens.palette.secondaryYellow[800]} height={20} />}
            />
          </Grid>
          <Grid item xs={6}>
            <TopLevelMetricsCard
              title="Custodial account"
              bottomLeft={getCustodianDisplayName(accountWithHousehold.custodianKey)}
              backgroundColor="secondaryGreen.100"
              borderColor="secondaryGreen.800"
              icon={<ChessPieceIcon color={tokens.palette.secondaryGreen[800]} height={20} />}
            />
          </Grid>
        </Grid>
      </Box>
      <Box height="24px" />
      <Divider />
      <CardDisclosures />
    </Card>
  );
};

export default GPTPortfolioInsightsCard;
