import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { ReactComponent as ExclamationIcon } from '~/static/images/icons/exclamation-circle.svg';
import DialogTitle from '~/synth/DialogTitle';

type ClearAllModalProps = {
  onClearAll: () => void;
  onCancel: () => void;
} & DialogProps;

export default function ClearAllModal(props: ClearAllModalProps) {
  const { onClearAll, onCancel, ...other } = props;
  return (
    <Dialog {...other} data-cy="clear-all-modal">
      <DialogTitle onClose={onCancel}>
        <Box display="flex">
          <ExclamationIcon color="red" />
          <Box ml={1}>
            <Typography variant="h3"> Clear All?</Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        Clearing all will deselect all subasset classes and remove any previously entered allocation
        values.
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button
          data-cy="clear-all-modal-button"
          variant="outlined"
          color="error"
          onClick={() => {
            onClearAll();
            onCancel();
          }}
        >
          Clear All
        </Button>
      </DialogActions>
    </Dialog>
  );
}
