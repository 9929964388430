import useSWR from 'swr';
import { getTemplateUpdateInProgressJobs } from '~/api/api';

export default function useInProgressJobs(
  {
    refreshInterval,
    bulkEdit,
  }: {
    refreshInterval?: number;
    bulkEdit?: boolean;
  } = { refreshInterval: 3000, bulkEdit: false }
) {
  return useSWR(
    `in-progress-template-update-jobs-${bulkEdit ? 'bulk-edit' : ''}`,
    () => getTemplateUpdateInProgressJobs(bulkEdit),
    {
      refreshInterval,
    }
  );
}
