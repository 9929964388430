import styled, { css } from 'styled-components';
import Select from './Select';

const StyledSelect = styled(Select)`
  & .react-select__control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-width: 1px;
    color: ${({ theme }) => theme.palette.grey[800]};
    background: ${({ theme }) => theme.palette.grey[200]};
    &:not(.react-select__control--is-focused) {
      border-color: ${({ theme }) => theme.palette.grey[200]};
      ${({ error, theme }) =>
        error
          ? css`
              border-top-color: ${theme.palette.error[500]};
              border-left-color: ${theme.palette.error[500]};
              border-bottom-color: ${theme.palette.error[500]};
            `
          : null}
    }
  }
  & .react-select__placeholder {
    color: ${({ theme }) => theme.palette.grey[800]};
  }
`;

export default StyledSelect;
