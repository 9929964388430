import * as Sentry from '@sentry/react';
import { keyBy, uniqBy } from 'lodash';
import React, { useEffect } from 'react';
import SomethingWentWrong from '~/components/SomethingWentWrong';
import useAllAccountsWithPIAndHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import useAllDistributionSchedules from '~/hooks/useAllDistributionSchedules';
import Distributions from './Distributions';
import DistributionsLoading from './components/DistributionLoading';
import getColumns from './getColumns';

export default function GlobalDistributions() {
  const {
    data: distributionSchedule,
    error: distributionScheduleError,
    mutate: mutateDistributionSchedule,
  } = useAllDistributionSchedules();

  const { data: accountsRespPce2, error: accountsError } = useAllAccountsWithPIAndHouseholdInfo({
    includeSummaries: false,
    includePi: true,
    transitioned: true,
  });

  const { data: accountsRespModelDelivery } = useAllAccountsWithPIAndHouseholdInfo({
    includeSummaries: false,
    engineType: 'MODEL_DELIVERY',
  });

  const accounts =
    accountsRespPce2 != null && accountsRespModelDelivery != null
      ? uniqBy(
          [...accountsRespPce2.data, ...accountsRespModelDelivery.data],
          (account) => account.id
        )
      : undefined;
  const accountsKeyById = keyBy(accounts, 'id');

  useEffect(() => {
    if (distributionScheduleError != null) {
      Sentry.captureException(
        new Error(`[Distributions] Failed to load all distribution schedules.`)
      );
    }
  }, [distributionScheduleError]);

  useEffect(() => {
    if (accountsError != null) {
      Sentry.captureException(new Error(`[Distributions] Failed to load all accounts info.`));
    }
  }, [accountsError]);

  if (distributionScheduleError) {
    return (
      <SomethingWentWrong
        description="There was an error loading distributions for this account."
        error={distributionScheduleError}
      />
    );
  }

  if (accountsError) {
    return (
      <SomethingWentWrong
        description="There was an error loading account info."
        error={accountsError}
      />
    );
  }

  if (distributionSchedule == null || accounts == null) {
    return <DistributionsLoading isGlobalDistribution />;
  }

  return (
    <Distributions
      distributionSchedule={distributionSchedule}
      allAccountInfo={accounts}
      columns={getColumns(accountsKeyById)}
      mutateDistributionSchedule={mutateDistributionSchedule}
    />
  );
}
