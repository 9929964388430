import React from 'react';
import { Box, Typography } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';

export default function PromptSuggestions({ suggestions, onClick }) {
  return (
    <Box display="flex" gap={4}>
      {suggestions.map((suggestion) => (
        <Box
          key={suggestion}
          onClick={() => onClick(suggestion)}
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex={1}
          sx={{
            borderRadius: '12px',
            border: `1px solid ${tokens.palette.neutralCool[300]}`,
            background: tokens.palette.neutralCool[100],
            cursor: 'pointer',
            '&:hover': {
              boxShadow: '0px 0px 10px rgba(72, 134, 255, 0.11)',
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: tokens.palette.neutralCool[1000],
              fontSamily: 'Helvetica Neue',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '1.5',
              letterSpacing: '-0.14px',
            }}
          >
            {suggestion}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
