import useSWR from 'swr';
import { getAccountState } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getAccountStateSwrKey = (accountId: string) =>
  generateSwrKey(`/portfolio/accounts/${accountId}/account-state`);

export default function useAccountState(accountId: string | undefined | null) {
  const useSWRRes = useSWR(
    accountId ? getAccountStateSwrKey(accountId) : null,
    accountId == null ? undefined : () => getAccountState(accountId)
  );
  return useSWRRes;
}
