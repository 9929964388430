import { addYears, differenceInMonths, differenceInYears, subYears } from 'date-fns';
import { find, findLast, first, groupBy, isEmpty, last, sumBy } from 'lodash';
import moment from 'moment';
import { Custodian } from 'vise-types/custodian';
import { Simulation } from 'vise-types/pce1';
import { PCE2BacktestResults, PCE2Buy, PCE2Position, PCE2Sell } from 'vise-types/pce2';
import {
  AssetClassKey,
  Feature,
  FeatureL1,
  Instrument as PCE2Instrument,
} from 'vise-types/pce2_instrument';
import { Account, PortfolioSummary, Position } from 'vise-types/portfolio';
import {
  AssetClassAllocation as PCE2AssetClassAllocation,
  MonteCarloResults as PCE2MonteCarloResults,
  SectorAllocation as PCE2SectorAllocation,
  PerAssetClassPortfolioMetrics,
  PortfolioMetricsResponse,
} from 'vise-types/portfolio_metrics';
import {
  AdjustedSimulation,
  Allocation,
  AssetClassAllocation,
  SectorAllocation,
  TransformedPortfolioTrade,
} from '~/models/api';
import { compactDollarFormatter, formatCurrency, formatPercent } from '~/utils/format';
import { getAssetClassFeaturesFromKey, getAssetClassKeyFromFeatures } from '~/utils/pce2Migration';
import {
  ASSET_CLASS_TO_LABEL_MAP,
  SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL,
} from '../PortfolioCreator2/Constants';
import { ASSET_CLASS_TO_ORDER_PCE2 } from './Constants';

export interface PricedPortfolioHolding {
  allocation: number;
  assetClass?: AssetClassKey;
  locked: boolean;
  name?: string;
  price?: number;
  sector?: string;
  securityType?: string;
  shares: number;
  ticker: string;
  value?: number;
  ranking?: number;
  target?: number;
}

export interface AssetAllocationWithPositionsAndValue {
  positions?: PricedPortfolioHolding[];
  value?: number;
  name: string;
  y: number;
}

// Assigns custom names to cash positions, returns undefined to unrecognized tickers
export function getCustodianCashNameFromTicker(
  ticker: string,
  custodian: Custodian | null | undefined
) {
  if (ticker === 'MMDA12') {
    return 'TDAI FDIC Insured Deposit Account';
  }
  if (ticker === 'FCASH') {
    return 'FCASH Fidelity Free Credit Balance';
  }
  if (ticker === ':CASH' && custodian === 'SCHWAB') {
    return 'Schwab FDIC Insured Deposit Account';
  }
  if (ticker === ':CASH' || ticker === 'CJPXX') {
    return 'Cash';
  }
  return undefined;
}

// TODO(hung/rohit/lavanya): This functionality should be moved to MDS (or the API layer for instruments on top of MDS) when it's ready.
// This function does two things:
// -> Assigns custom names to MMDA12, FCASH, :CASH, and CJPXX since the instruments endpoint
//    does not supply those names
// -> Assigns the asset class 'Cash' to the above tickers
function transformCustodianCashName<
  Holding extends {
    ticker: string;
    assetClass?: AssetClassKey;
    value: number;
    shares: number;
    name?: string;
  }
>(holding: Holding, custodian: Custodian | null): Holding {
  let isCash = true;
  const newName = getCustodianCashNameFromTicker(holding.ticker, custodian);
  if (!newName) {
    isCash = false;
  }

  return {
    ...holding,
    // Fall back to the holding's original name and then to its ticker if it doesn't match any of
    // the `newName` cases above.
    //
    // Note: `holding.name` might be an empty string so `||` instead of `??` is intentional to fall
    // back to `ticker`
    name: newName || holding.name || holding.ticker,
    assetClass: isCash ? ('CASH' as AssetClassKey) : (holding.assetClass as AssetClassKey),
    value: isCash ? holding.shares : holding.value,
  };
}

export function getPCE2AccountPositionsRowData<Category extends string | number | symbol>(
  positions: Position[] | undefined = [],
  custodian: Custodian | null,
  lockedTickers: Set<string>,
  categorizationFunc: (securityType: string | undefined, assetClass: AssetClassKey) => Category,
  pctOfManagedValue = 1
): { [category in Category]: PricedPortfolioHolding[] } {
  const totalMarketValue = sumBy(positions, 'marketValue');

  const pricedPortfolioHoldings: PricedPortfolioHolding[] = positions
    .map((position) => {
      // Note: a `0` amount means that `quantity` should be used
      //
      // * amount - used for cash-like and bonds, like 5000 (amount) of :CASH
      // * quantity - used for equities, like 5 (quantity) shares of AAPL
      const shares =
        position.amount == null || position.amount === 0 ? position.quantity : position.amount;

      return {
        allocation: (position.marketValue / totalMarketValue) * pctOfManagedValue,
        assetClass: getAssetClassKeyFromFeatures(position?.assetClass ?? []),
        locked: lockedTickers.has(position.symbolOrCusip),
        name: position.name,
        price: position.price,
        sector: position.sectors != null ? position.sectors.join('/') : undefined,
        securityType: position.securityType,
        shares,
        ticker: position.symbolOrCusip,
        value: position.marketValue,
      };
    })
    .map((pricedPosition) => transformCustodianCashName(pricedPosition, custodian));

  return groupBy(pricedPortfolioHoldings, ({ assetClass, securityType }) =>
    categorizationFunc(securityType, assetClass as AssetClassKey)
  ) as { [category in Category]: PricedPortfolioHolding[] };
}

export const getPCE2ProposalHoldingsRowData = <Category extends string | number | symbol>(
  portfolioHoldings: PCE2Position[],
  totalValue: number,
  custodian: Custodian | null,
  instruments: { [ticker: string]: PCE2Instrument } = {},
  lockedTickers: Set<string>,
  categorizationFunc: (assetClass: AssetClassKey, securityType: string | undefined) => Category
): { [category in Category]: PricedPortfolioHolding[] } => {
  const pricedPortfolioHoldings = portfolioHoldings
    .map((holding) => {
      const instrumentForTicker = instruments[holding.symbolOrCusip];
      const price = instrumentForTicker?.price ?? holding.priceEstimate;
      return {
        assetClass: getAssetClassKeyFromFeatures(instrumentForTicker?.assetClass ?? []),
        locked: lockedTickers.has(holding.symbolOrCusip),
        name: instrumentForTicker ? instrumentForTicker.name : '',
        price,
        sector: instrumentForTicker?.sectors[0] ?? undefined,
        securityType: holding.securityType,
        value: price * holding.quantity,
        ticker: holding.symbolOrCusip,
        shares: holding.quantity,
        allocation: (holding.quantity * price) / totalValue,
      };
    })
    .map((pricedPosition) => transformCustodianCashName(pricedPosition, custodian));

  return groupBy(pricedPortfolioHoldings, ({ assetClass, securityType }) =>
    categorizationFunc(assetClass as AssetClassKey, securityType)
  ) as { [category in Category]: PricedPortfolioHolding[] };
};

export const transformPCE2Buys = (
  buys: PCE2Buy[],
  instruments: { [symbol: string]: PCE2Instrument }
): TransformedPortfolioTrade[] => {
  return buys
    .map((buy) => ({
      action: 'Buy' as const,
      price: buy.buyNotionalSharePrice,
      shares: buy.shares,
      ticker: buy.symbolOrCusip,
      value: buy.shares * buy.buyNotionalSharePrice,
      name:
        instruments[buy.symbolOrCusip] && instruments[buy.symbolOrCusip].name
          ? instruments[buy.symbolOrCusip].name
          : '',
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const transformPCE2Sells = (
  sells: PCE2Sell[],
  instruments: { [symbol: string]: PCE2Instrument }
): TransformedPortfolioTrade[] => {
  return sells
    .map((sell) => ({
      action: 'Sell' as const,
      price: sell.sellNotionalSharePrice,
      shares: sell.shares,
      ticker: sell.symbolOrCusip,
      value: sell.shares * sell.sellNotionalSharePrice,
      name:
        instruments[sell.symbolOrCusip] && instruments[sell.symbolOrCusip].name
          ? instruments[sell.symbolOrCusip].name
          : '',
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const transformPCE2Trades = (
  buys: PCE2Buy[],
  sells: PCE2Sell[],
  instruments: { [symbol: string]: PCE2Instrument }
): TransformedPortfolioTrade[] => {
  const transformedBuys = transformPCE2Buys(buys, instruments);
  const transformedSells = transformPCE2Sells(sells, instruments);
  return transformedBuys.concat(transformedSells).sort((a, b) => a.name.localeCompare(b.name));
};

export function timestampToLocaleString(timestamp: string): string {
  const date = new Date(timestamp);
  const now = new Date();
  return date.toLocaleString('default', {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    timeZoneName: 'short',
    year: date.getFullYear() === now.getFullYear() ? undefined : 'numeric',
  });
}

export function getAccountValue({ summary }: Account) {
  return summary && summary.length > 0 ? summary[summary.length - 1].marketValue : undefined;
}

export function transformPCE2AssetClassAllocations(
  assetAllocation: PCE2AssetClassAllocation
): AssetClassAllocation {
  return assetAllocation.map((alloc) => ({
    name: (alloc.assetClass && getAssetClassKeyFromFeatures(alloc.assetClass)) || 'UNKNOWN',
    y: alloc.allocationFraction,
  }));
}

export function transformPCE2SectorAllocations(
  sectorAllocation: PCE2SectorAllocation
): SectorAllocation {
  return sectorAllocation.map((alloc) => ({
    name: alloc.sector ?? 'UNKNOWN_SECTOR',
    y: alloc.allocationFraction,
  }));
}

const PERCENTILE_TO_DESCRIPTION = {
  25: 'Below average',
  50: 'Average',
  75: 'Above average',
};

export function transformPCE2MonteCarloResults(
  mcResults: PCE2MonteCarloResults,
  initialValue: number,
  todayOverride: moment.Moment | undefined,
  endDate: Date | undefined
): AdjustedSimulation[] {
  const today = todayOverride || moment().startOf('day');
  // e.g. If the horizon is 50 years from proposal creation, and we are a month in, then the difference in full years is 49, so we
  // should add 1 more. Set a minimum horizon of 30 years out
  let years = differenceInYears(endDate ?? addYears(today.toDate(), 30), today.toDate()) + 1;
  if (years < 31) {
    years = 31;
  }
  return (
    mcResults
      // Only showing 3 simulations
      .filter((r) => r.percentile === 25 || r.percentile === 50 || r.percentile === 75)
      .map(({ percentile, values }) => {
        const configuredData = values
          .slice(0, years + 1)
          .map((point, index) => [
            moment(today.clone().add(index, 'y')).valueOf(),
            point * initialValue,
          ]);

        return {
          configuredData,
          percentile: PERCENTILE_TO_DESCRIPTION[percentile],
          target: null,
        };
      })
  );
}

export function transformPCE2BacktestResults(backtest: PCE2BacktestResults): [number, number][] {
  const first = backtest.values.length > 0 ? backtest.values[0] : 1;
  return backtest.values.map((value, index) => [
    backtest.timestampSecs[index] * 1000, // return timestamp in milliseconds to match pce1
    (value / first) * 100,
  ]);
}

/** Returns millis from epoch to day start UTC */
export function getOnboardedDate({ summary }: Account) {
  return summary && summary.length > 0 ? summary[0].date : undefined;
}

export function getMedianExpectedReturnPCE2(
  metrics: PortfolioMetricsResponse,
  investmentTimeline: Date | undefined
): number | undefined {
  const now = new Date();
  const endDate = investmentTimeline || addYears(now, 20);
  const years = differenceInYears(endDate, now);
  const months = differenceInMonths(subYears(endDate, years), now);

  const mc = metrics.strategyMetrics?.monteCarloResults ?? [];
  if (isEmpty(mc)) {
    return undefined;
  }
  const medianMc = mc.find((result) => result.percentile === 50);
  if (!medianMc) {
    return undefined;
  }
  const endPoint = medianMc.values[years] || last(medianMc.values) || 0;
  const endPointPlus1 = medianMc.values[years + 1] || last(medianMc.values) || 0;

  const medianReturn = endPoint + (endPointPlus1 - endPoint) * (months / 12);
  return medianReturn * metrics.portfolioMetrics.totalValue;
}

export const getAdjustedPCE1Simulations = (simulations: Simulation[]) => {
  const percentiles = ['25.00%', '50.00%', '75.00%'];
  const today = moment().startOf('day');
  const foundPercentiles = new Set();

  return simulations
    .filter(({ percentile, target }) => {
      // Sometimes `simulations` has duplicate data. Avoid including duplicates in chart:
      if (percentiles.includes(percentile) && !foundPercentiles.has(percentile)) {
        foundPercentiles.add(percentile);
        return true;
      }
      return target && !foundPercentiles.has(percentile);
    })
    .map(({ percentile, simulation }: { percentile: string; simulation: number[] }) => {
      const configuredData = simulation.map((point, index) => [
        moment(today.clone().add(index, 'y')).valueOf(),
        point,
      ]);

      return {
        configuredData,
        percentile,
        target: null,
      };
    });
};

export interface AllocationChartDataPoint {
  name: string;
  y: number;
  color?: string;
}

/**
 * Group asset allocations under asset classes.
 * Prepares data for AllocationCard and holdings table on Portfolio overview allocations tab
 * @param assetAllocation Array of assets
 * @returns Array of assets grouped under Equities, Fixed income, Alternatives, Cash, or More....
 */
export function groupAssetAllocation(assetAllocation: AllocationChartDataPoint[]) {
  const assetClassAllocation: Record<FeatureL1 | 'LOCKED', AllocationChartDataPoint[]> = {
    EQUITY: [],
    FIXED_INCOME: [],
    ALTERNATIVES: [],
    CASH: [],
    UNKNOWN: [],
    LOCKED: [],
  };

  assetAllocation.forEach((asset: AllocationChartDataPoint) => {
    const category = getAssetClassFeaturesFromKey(asset.name as AssetClassKey)[0];
    if (category === 'LOCKED') {
      (assetClassAllocation.LOCKED as AllocationChartDataPoint[]).push({ ...asset });
    } else if (category && ASSET_CLASS_TO_LABEL_MAP.get(category)) {
      assetClassAllocation[category]?.push({ ...asset });
    } else {
      (assetClassAllocation.UNKNOWN as AllocationChartDataPoint[]).push({ ...asset });
    }
  });

  return assetClassAllocation;
}

// Anything less that 0.005% is treated as 0 since we only display two decimal places in the charts and tables
const ALLOCATION_ROUND_TO_ZERO_AMOUNT = 0.00005;
export function adjustAllocationByPctOfManagedValue(
  allocation: Allocation,
  lockedUnrecognizedFraction: number | null | undefined,
  onlyShowViseManagedAssets: boolean
) {
  if (lockedUnrecognizedFraction == null) {
    return allocation;
  }

  const adjustedAllocations = allocation
    .map((alloc) => {
      let adjustedValue = alloc.y;
      if (alloc.name === 'CASH' && onlyShowViseManagedAssets) {
        adjustedValue = 0;
      } else if (alloc.name === 'UNKNOWN') {
        adjustedValue = alloc.y - lockedUnrecognizedFraction;
      }

      return {
        name: alloc.name,
        // We account for tiny rounding errors create non-zero values less than 0.005%
        y: adjustedValue < ALLOCATION_ROUND_TO_ZERO_AMOUNT ? 0 : adjustedValue,
      };
    })
    .filter((alloc) => alloc.y > 0);

  const sum = sumBy(adjustedAllocations, 'y');
  if (onlyShowViseManagedAssets) {
    // After pulling out locked unrecognized positions and target cash, renormalize the allocations to be out of the remaining fraction
    return adjustedAllocations.map((alloc) => ({
      name: alloc.name,
      y: alloc.y / sum,
    }));
  }

  if (sum < 1 - ALLOCATION_ROUND_TO_ZERO_AMOUNT) {
    // If we're showing the total portfolio, the remaining portion of the allocation will be locked, unrecognized positions
    adjustedAllocations.push({ name: 'LOCKED', y: 1 - sum });
  }

  return adjustedAllocations;
}

// Remove the "UNKNOWN_SECTOR" sector from sector allocation and recalculate percentages of UNKNOWN_SECTOR sectors to
// match 100%. "UNKNOWN_SECTOR" includes cash and fixed income currently, but those should be excluded.
// TODO: Do this in PCE; but for now this is adjusted in the client.
export function adjustSectorAllocationByOther(sectorAllocation: SectorAllocation) {
  const otherSector = sectorAllocation.find((sector) => sector.name === 'UNKNOWN_SECTOR');

  return otherSector == null
    ? sectorAllocation
    : sectorAllocation
        .filter((sector) => sector.name !== 'UNKNOWN_SECTOR')
        .map((sector) => ({ ...sector, y: sector.y / (1 - otherSector.y) }));
}

export function getSummaryDataInRange(
  summaryData: PortfolioSummary[],
  minDate: number | null,
  maxDate: number | null
) {
  let summaryDataInRange: [PortfolioSummary, PortfolioSummary] | null;
  if (summaryData.length === 0) {
    summaryDataInRange = null;
  } else {
    const firstSummary =
      minDate == null ? first(summaryData) : find(summaryData, (datum) => datum.date >= minDate);
    const lastSummary =
      maxDate == null ? last(summaryData) : findLast(summaryData, (datum) => datum.date <= maxDate);
    if (firstSummary != null && lastSummary != null) {
      summaryDataInRange = [firstSummary, lastSummary];
    } else {
      summaryDataInRange = null;
    }
  }
  return summaryDataInRange;
}

export function addPositionsAndValueToAssetAllocation<Features extends FeatureL1>(
  positionsGroupedByAssetClass: Record<Features, PricedPortfolioHolding[]>,
  assetAllocation: Allocation
): {
  [key in Features]: AssetAllocationWithPositionsAndValue[];
} {
  const allocationWithPositionsAndValue = (
    Object.entries(groupAssetAllocation(assetAllocation)) as [
      FeatureL1,
      AllocationChartDataPoint[]
    ][]
  ).map(([assetClass, subassets]) => {
    const subassetsWithPositionsAndValue = subassets.map((subasset: AllocationChartDataPoint) => {
      // Add # of positions and total value information to asset class allocation information.
      const positions: PricedPortfolioHolding[] = [];
      let value = 0;

      positionsGroupedByAssetClass[assetClass]?.forEach((asset: PricedPortfolioHolding) => {
        if (
          asset.assetClass === subasset.name ||
          // Unrecognized positions have a blank asset class
          // TODO (jatwood) have unrecognized positions be "UNKNOWN instead of ""
          // Locked and unrecognized get added to "not managed by vise"
          (!asset.assetClass && asset.locked && subasset.name === 'Assets not managed by Vise') ||
          // Unlocked and unrecognized are added to "unclassified securities"
          (!asset.assetClass && !asset.locked && subasset.name !== 'Assets not managed by Vise')
        ) {
          positions.push(asset);
          value += asset.value ?? 0;
        }
      });
      return { ...subasset, positions, value };
    });

    const sortedSubAssetsWithPositionsAndValue = subassetsWithPositionsAndValue.sort((a, b) =>
      ASSET_CLASS_TO_ORDER_PCE2[a.name] > ASSET_CLASS_TO_ORDER_PCE2[b.name] ? 1 : -1
    );
    return [assetClass, sortedSubAssetsWithPositionsAndValue];
  });
  return Object.fromEntries(allocationWithPositionsAndValue);
}

export interface MetricTableRow {
  metric: string;
}

export function transformPCE2SingleStrategyMetrics(
  perAssetClassMetrics: PerAssetClassPortfolioMetrics[]
): MetricTableRow[] {
  const metricsTable = [
    { metric: 'Market cap' },
    { metric: 'Dividend yield' },
    { metric: 'P/B +' },
    { metric: 'Profitability' },
  ];
  perAssetClassMetrics.forEach((metric) => {
    const strategyName = getAssetClassKeyFromFeatures(metric.assetClass);
    // Market cap
    metricsTable[0][strategyName] = formatCurrency(
      metric.valueWeightedMarketCap,
      compactDollarFormatter
    );
    // Dividend yield
    metricsTable[1][strategyName] = formatPercent(metric.valueWeightedDividendYield);
    // P/B + (NOTE: the CompanyValue is book to market, and we need the inverse of that for price to book)
    metricsTable[2][strategyName] = (1 / metric.valueWeightedCompanyValue).toFixed(2);
    // Profitability
    metricsTable[3][strategyName] = metric.valueWeightedCashFlow.toFixed(2);
  });
  return metricsTable;
}

export function getRemainingYears(
  investmentTimeline: moment.Moment | null,
  fromMoment: moment.Moment
) {
  // Need to subtract 1 day because when a proposal is created on 2021-09-03 with horizon of 20 years
  // the investment timeline is 2041-09-04. This prevents the UI from showing an extra year when the
  // proposal is first executed/created.
  if (investmentTimeline != null) {
    return Math.ceil(moment(investmentTimeline.subtract(1, 'day')).diff(fromMoment, 'years', true));
  }
  return null;
}

// Get the relavant parts of a asset class for its display name.
export function getPCE2AssetClassTitleAndSubtitle(
  assetClassKey: AssetClassKey | 'LOCKED',
  isSmallAccount?: boolean
): { title: string; subtitle?: string } {
  if (isSmallAccount && SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL[assetClassKey]) {
    return { title: SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL[assetClassKey] };
  }

  if (assetClassKey === 'LOCKED') {
    return {
      title: 'Assets not managed by Vise',
    };
  }

  const features = getAssetClassFeaturesFromKey(assetClassKey);
  if (features.length > 2) {
    return {
      title: ASSET_CLASS_TO_LABEL_MAP.get(features[features.length - 1] as Feature) ?? '',
      subtitle: ASSET_CLASS_TO_LABEL_MAP.get(features[features.length - 2] as Feature),
    };
  }

  // For asset classes with only 2 levels (e.g FIXED_INCOME/EMERGING_FI) we only want the last level
  return { title: ASSET_CLASS_TO_LABEL_MAP.get(features[features.length - 1] as Feature) ?? '' };
}

export function assetClassChartWithTargets<Features extends Feature | 'LOCKED'>(
  assetClassChartData: {
    [key in Features]: AssetAllocationWithPositionsAndValue[];
  },
  targetAllocation: AssetClassAllocation
): {
  [key in Features]: (AssetAllocationWithPositionsAndValue | { name: string; target: number })[];
} {
  return targetAllocation.reduce((newChartData, allocation) => {
    const category =
      allocation.name === 'LOCKED' ? 'LOCKED' : getAssetClassFeaturesFromKey(allocation.name)[0];

    if (!category) {
      return newChartData;
    }

    const rows = newChartData[category as Feature];
    if (!rows) {
      return {
        ...newChartData,
        [category]: [{ target: allocation.y, name: allocation.name }],
      };
    }

    if (!rows.find((row) => row.name === allocation.name)) {
      return {
        ...newChartData,
        [category]: [...rows, { target: allocation.y, name: allocation.name }],
      };
    }

    return {
      ...newChartData,
      [category]: rows.map((row) =>
        row.name === allocation.name ? { ...row, target: allocation.y } : row
      ),
    };
  }, assetClassChartData);
}

export function riskToFocus(risk: number) {
  if (risk <= 0.3) {
    return ['Conservative', 'Preserve wealth with lower volatility.'];
  }
  if (risk <= 0.5) {
    return ['Moderately Conservative', 'Preserve wealth with limited, strategic exposure to risk.'];
  }
  if (risk <= 0.7) {
    return ['Moderate', 'Balanced risk for long-term capital appreciation.'];
  }
  if (risk <= 0.85) {
    return ['Moderately Aggressive', 'Growth with limited protection against volatility.'];
  }
  return ['Aggressive', 'Growth with high expected returns and volatility.'];
}

export function calculateChartMetric(
  chartMinDate: number | null,
  chartMaxDate: number | null,
  metrics: [number, number][]
) {
  const minDate = chartMinDate ? Math.max(chartMinDate, metrics[0][0]) : metrics[0][0];
  const maxDate = chartMaxDate
    ? Math.min(chartMaxDate, metrics[metrics.length - 1][0])
    : metrics[metrics.length - 1][0];
  const closestMinMetric = metrics.reduce((prev, cur) =>
    Math.abs(cur[0] - minDate) < Math.abs(prev[0] - minDate) ? cur : prev
  )[1];
  const closestMaxMetric = metrics.reduce((prev, cur) =>
    Math.abs(cur[0] - maxDate) < Math.abs(prev[0] - maxDate) ? cur : prev
  )[1];

  return closestMaxMetric / closestMinMetric - 1;
}
