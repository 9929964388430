import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Divider, Fade, Slide, SlideProps } from '@mui/material';
import rocketImage from '~/static/images/onboarding/launch-rocket-with-clouds.svg';
import confetti from 'canvas-confetti';
import { ReactComponent as ChevronLeftIcon } from '~/static/images/icons/chevron-left.svg';

function getStepContent(stepIndex: number, children: React.ReactElement[] | React.ReactElement) {
  if (Array.isArray(children)) {
    return children[stepIndex] || null;
  }
  return children;
}

function throwConfetti(count = 3) {
  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  // throw it in random directions (more realistic).
  for (let i = 0; i < count; i += 1) {
    confetti({
      angle: randomInRange(55, 125),
      spread: randomInRange(50, 70),
      particleCount: randomInRange(50, 100),
      origin: { y: 0.4 },
    });
  }
}

export interface HorizontalStepperProps {
  /** Active step */
  currentStep?: number;
  /** Whether the next button should be disabled */
  disableNextButton?: ((activeStep: number) => boolean) | boolean;
  /** Text to display on the "Next" button */
  nextButtonText?: ((activeStep: number) => string) | string;
  /** Text to display on the "Finish" button */
  finishButtonText?: string;
  /** When clicking the "Next" action button on every step */
  onNextClick?: (activeStep: number) => void;
  /** When clicking the "Back" button on every step */
  onPreviousClick?: (activeStep: number) => void;
  /** When clicking the "Skip" action button on every step */
  onSkipClick?: (activeStep: number) => void;
  /** When clicking the action button on the last step */
  onFinishClick?: () => void;
  /** When clicking the action button on the completed step (ie. after the last step) */
  onExitClick?: () => void;
  /** Whether the skip button should be visible */
  showSkipButton?: ((activeStep: number) => boolean) | boolean;
  /** When skipping, how many steps to skip */
  numberOfStepsToSkip?: ((activeStep: number) => number) | number;
  /**
   * If set,  a confetti animation is displayed on that step.
   * (ie. 0 means first step, -1 means upon completion of all the steps).
   */
  showConfettiOnStep?: number;
  /** Text to show upon completion of all the steps */
  completedHeading?: string;
  /** Text to show upon completion of all the steps */
  completedText?: () => React.ReactElement | string;
  children: React.ReactElement[] | React.ReactElement;
}

export default function HorizontalStepper({
  currentStep = 0,
  children,
  showConfettiOnStep,
  disableNextButton = false,
  nextButtonText = 'Next',
  finishButtonText = 'Finish',
  completedHeading = 'Congratulations!',
  completedText = () => 'All steps completed.',
  showSkipButton = false,
  numberOfStepsToSkip = 1,
  onNextClick,
  onPreviousClick,
  onSkipClick,
  onFinishClick,
  onExitClick,
}: HorizontalStepperProps) {
  const [activeStep, setActiveStep] = useState(currentStep);
  const [shouldSlide, setShouldSlide] = useState(true);
  const [slideDirection, setSlideDirection] = useState<SlideProps['direction']>();

  const totalSteps = useMemo(() => (Array.isArray(children) ? children.length : 1), [children]);

  const shouldDisableNextButton =
    typeof disableNextButton === 'boolean' ? disableNextButton : disableNextButton(activeStep);

  const shouldShowSkipButton =
    typeof showSkipButton === 'boolean' ? showSkipButton : showSkipButton(activeStep);

  const stepsToSkip =
    typeof numberOfStepsToSkip === 'number' ? numberOfStepsToSkip : numberOfStepsToSkip(activeStep);

  const nextStepButtonText =
    typeof nextButtonText === 'string' ? nextButtonText : nextButtonText(activeStep);

  useEffect(() => {
    setActiveStep(currentStep);
    if (
      showConfettiOnStep === currentStep ||
      (showConfettiOnStep === -1 && currentStep === totalSteps)
    ) {
      throwConfetti();
    }
  }, [currentStep, showConfettiOnStep, totalSteps]);

  const slideAnimate = (direction: SlideProps['direction']) => {
    setSlideDirection(direction);

    // Slight delay, for a nicer animation.
    setShouldSlide(false);
    setTimeout(() => setShouldSlide(true), 0);
  };

  const addSteps = (howMany: number, callback?: (nextStep: number) => void) => {
    const isLastStep = activeStep === totalSteps - 1;
    const stepsToAdd = isLastStep ? 1 : howMany;

    let newStep = activeStep + stepsToAdd;
    setActiveStep((prevActiveStep) => {
      newStep = prevActiveStep + stepsToAdd;
      return newStep;
    });

    if (callback) {
      callback(newStep);
    }
    if (isLastStep && onFinishClick) {
      onFinishClick();
    }

    slideAnimate('left');
  };

  const handleNext = () => {
    addSteps(1, onNextClick);
  };

  const handleSkip = () => {
    addSteps(stepsToSkip, onSkipClick);
  };

  const handleKeyDown = ({ key }) => {
    if (!shouldDisableNextButton && key === 'Enter') {
      handleNext();
    }
  };

  const handleBack = () => {
    let newStep = activeStep - 1;
    setActiveStep((prevActiveStep) => {
      newStep = prevActiveStep - 1;
      return newStep;
    });
    if (onPreviousClick) {
      onPreviousClick(newStep);
    }
    slideAnimate('right');
  };

  const handleExit = () => {
    if (onExitClick) {
      onExitClick();
    }
  };

  return (
    <>
      {activeStep === totalSteps ? (
        <Box display="flex" flexDirection="column" justifyContent="center" pt={2} pb={5}>
          <Box pb={5.5}>
            <img src={rocketImage} alt="Rocket" />
          </Box>
          <Box pb={2.7}>
            <Typography variant="h1">{completedHeading}</Typography>
          </Box>
          {completedText()}
          <Box display="flex" flexDirection="column" justifyContent="center" py={4.5}>
            <Button variant="contained" sx={{ width: '100%' }} onClick={handleExit}>
              Launch
            </Button>
          </Box>
        </Box>
      ) : (
        <Fade in={shouldSlide}>
          <Slide in={shouldSlide} mountOnEnter unmountOnExit direction={slideDirection}>
            <Box onKeyDown={handleKeyDown}>
              <Box pb={1} display="flex">
                {activeStep ? (
                  <Button
                    sx={{
                      px: 0,
                      py: 0,
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    onClick={handleBack}
                    startIcon={<ChevronLeftIcon />}
                    variant="text"
                  >
                    Back
                  </Button>
                ) : null}
              </Box>
              {getStepContent(activeStep, children)}
              <Box py={3.5} display="flex" justifyContent="center" flexDirection="column">
                <Button
                  variant="contained"
                  sx={{ width: '100%' }}
                  color="primary"
                  onClick={handleNext}
                  disabled={shouldDisableNextButton}
                >
                  {activeStep === totalSteps - 1 ? finishButtonText : nextStepButtonText}
                </Button>
                {shouldShowSkipButton ? (
                  <>
                    <Divider sx={{ my: 4 }} />
                    <Button
                      variant="outlined"
                      sx={{ width: '100%' }}
                      color="primary"
                      onClick={handleSkip}
                    >
                      Skip for now
                    </Button>
                  </>
                ) : null}
              </Box>
            </Box>
          </Slide>
        </Fade>
      )}
    </>
  );
}
