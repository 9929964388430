import { Box, Theme, ThemeProvider } from '@mui/material';
import React from 'react';
import ActivityMonitor from './components/Sections';

// Todo use one theme here
export default function OnboardedSection({ themeBase }: { themeBase: Theme }) {
  return (
    <Box mt={8}>
      <ThemeProvider theme={themeBase}>
        <ActivityMonitor />
      </ThemeProvider>
    </Box>
  );
}
