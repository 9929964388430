import React from 'react';
import linkAccountDisabledIcon from '~/static/images/PortfolioCreator2/linkAccount-rounded-disabled.svg';
import linkAccountActiveIcon from '~/static/images/PortfolioCreator2/linkAccount-rounded-active.svg';
import linkAccountInactiveIcon from '~/static/images/PortfolioCreator2/linkAccount-rounded-inactive.svg';

interface LinkAccountIconProps {
  active?: boolean;
  disabled?: boolean;
}

export default function LinkAccountIcon({ active, disabled }: LinkAccountIconProps) {
  let src: string;
  if (disabled) {
    src = linkAccountDisabledIcon;
  } else if (active) {
    src = linkAccountActiveIcon;
  } else {
    src = linkAccountInactiveIcon;
  }
  return <img alt="" height={120} width={120} src={src} />;
}
