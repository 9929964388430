import { Box, Divider, InputAdornment, Typography, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Column,
  Row,
  TableState,
  usePagination,
  UsePaginationState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
} from 'react-table';
import PageNavigation from '~/components/table/PageNavigation';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAllAccountsWithPIAndHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import { HouseholdWithSummary } from '~/models/api';
import { HouseholdData } from '~/models/household';
import { ReactComponent as UserGroupIcon } from '~/static/images/icons/user-group.svg';
import { DataTable, DataTableActionBar } from '~/synth/DataTable';
import EmptyState from '~/synth/deprecated/EmptyState';
import { SearchIcon } from '~/synth/Icons';
import TextField from '~/synth/TextField';
import { WithLoader } from '~/utils';
import amplitude from '~/utils/amplitude';
import { formatCurrency } from '~/utils/format';
import scrollToTop from '~/utils/scrollToTop';
import AccountFilter, {
  filterByHousehold,
  filtersEnabled,
  initialFilterState,
} from '~/components/portfolio/AccountFilter';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import { NoResultsFound } from '../../Portfolio/components/TableComponents';
import LoadingTable from './LoadingTable';
import ValueCell from './ValueCell';

function HouseholdName({
  name,
  numClients,
  activePortfolios,
}: {
  name: string;
  numClients: number;
  activePortfolios: number;
}) {
  return (
    <Box display="flex" alignItems="center">
      <Box pr={1.5}>
        {/* This span is for cypress tests to effectively locate the household name */}
        <span>{name}</span>
      </Box>
      <Typography variant="body1" color="textSecondary">
        {numClients} clients
      </Typography>
      <Box bgcolor="grey.300" mx={1.5}>
        <Divider orientation="vertical" flexItem style={{ height: '17.5px' }} />
      </Box>
      <Typography variant="body1" color="textSecondary">{` ${activePortfolios} active ${
        activePortfolios === 1 ? 'portfolio' : 'portfolios'
      }`}</Typography>
    </Box>
  );
}

type HouseholdsTableProps = {
  households?: HouseholdWithSummary[];
  householdsIsLoading: boolean;
  openAddHouseholdModal: () => void;
  pageSize?: number;
  aumIsLoading: boolean;
};

const HouseholdsTable = ({
  households,
  householdsIsLoading,
  openAddHouseholdModal,
  pageSize = 50,
  aumIsLoading,
}: HouseholdsTableProps) => {
  const [query, setQuery] = useState('');
  const [filterQuery, setFilterQuery] = useState('');

  const { data: accountsResp } = useAllAccountsWithPIAndHouseholdInfo({
    includeSummaries: false,
    transitioned: true,
  });
  const accounts = accountsResp != null ? accountsResp.data : null;
  const householdsIdsToNumAccountsRequiringPerformanceQa: Map<string, number> = useMemo(() => {
    if (accounts == null) {
      return new Map();
    }
    return accounts
      .filter((account) => account.performanceQaRequired)
      .reduce((map, nextAccount) => {
        const householdId = nextAccount.householdInfo?.id;
        if (householdId != null) {
          const existingCount = map.get(householdId);
          if (existingCount != null) {
            map.set(householdId, existingCount + 1);
          } else {
            map.set(householdId, 1);
          }
        }
        return map;
      }, new Map<string, number>());
  }, [accounts]);

  const tableData = useMemo(
    () =>
      households
        ? households.map((household) => ({
            id: household.id,
            name: household.name,
            numAccountsRequiringPerformanceQa:
              householdsIdsToNumAccountsRequiringPerformanceQa.get(household.id) ?? 0,
            numAccountsTransitioned: household.transitionedPortfolioCount,
            numAccountsUntransitioned: household.untransitionedPortfolioCount,
            numClients: household.clientCount,
            aum: household.aum,
            qtd: household.householdMetricsForValidPerformanceAccounts?.qtdTimeWeightedPercent,
            ytd: household.householdMetricsForValidPerformanceAccounts?.ytdTimeWeightedPercent,
            itd: household.householdMetricsForValidPerformanceAccounts?.itdTimeWeightedPercent,
          }))
        : [],
    [households, householdsIdsToNumAccountsRequiringPerformanceQa]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeFilterQuery = useCallback(
    debounce((nextFilterQuery: string) => setFilterQuery(nextFilterQuery), 350),
    []
  );

  function handleChangeQuery(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
    handleChangeFilterQuery(event.target.value);
  }

  const handleResetQuery = useCallback(() => {
    setQuery('');

    // Cancel any pending filter and reset immediately. Clicking the "X" to clear should give
    // instantaneous feedback rather than delayed.
    handleChangeFilterQuery.cancel();
    setFilterQuery('');
  }, [handleChangeFilterQuery]);

  // Clear filter on show/hide events
  useEffect(() => handleResetQuery(), [handleResetQuery]);

  const [dropdownFilterState, setDropdownFilterState] = useState(initialFilterState);

  const filteredTableData = useMemo(
    () =>
      matchSorter(tableData, filterQuery, {
        keys: ['name'],
        threshold: matchSorter.rankings.CONTAINS,
      }),
    [filterQuery, tableData]
  );

  const { data: modelTemplateCenterData } = useModelTemplateCenterViewData();

  const dropdownFilteredHouseholdTableData = useMemo(() => {
    if (modelTemplateCenterData?.data && accounts && filtersEnabled(dropdownFilterState)) {
      const householdIds = filterByHousehold(
        accounts,
        dropdownFilterState,
        modelTemplateCenterData.data
      );
      return filteredTableData.filter((row) => householdIds.has(row.id));
    }
    return filteredTableData;
  }, [modelTemplateCenterData?.data, accounts, dropdownFilterState, filteredTableData]);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Household name',
          accessor: 'name',
          Cell: (props) => {
            const { original } = props.row;
            return (
              <HouseholdName
                name={props.value}
                numClients={original.numClients}
                activePortfolios={original.numAccountsTransitioned}
              />
            );
          },
          width: '65%',
        },
        {
          Header: 'Accounts to transition',
          accessor: 'numAccountsUntransitioned',
          Cell: (props) => props.value,
          width: '18%',
        },
        {
          Header: 'Value',
          accessor: 'aum',
          Cell: (props) => (
            <ValueCell value={formatCurrency(props.value)} isLoading={aumIsLoading} />
          ),
        },
      ] as Column<HouseholdData>[] & UseSortByOptions<HouseholdData>[],
    [aumIsLoading]
  );

  const table = useTable<HouseholdData>(
    {
      autoResetSortBy: false,
      columns,
      data: dropdownFilteredHouseholdTableData,
      initialState: {
        pageSize,
        sortBy: [{ id: 'name' }],
      } as TableState<HouseholdData> &
        UseSortByState<HouseholdData> &
        UsePaginationState<HouseholdData>,
    },
    useSortBy,
    usePagination
  );

  const theme = useTheme();
  const history = useHistory();
  return (
    <>
      <DataTableActionBar maxWidth={366} px={2}>
        <TextField
          aria-label="Search households by name"
          id="household_filter"
          name="filter_households"
          fullWidth
          onChange={handleChangeQuery}
          placeholder="Search by household name"
          value={query}
          InputProps={{
            endAdornment: accounts && (
              <AccountFilter
                accounts={accounts}
                filterState={dropdownFilterState}
                onApplyFilter={(filterState) => {
                  setDropdownFilterState(filterState);
                  table.gotoPage(0);
                }}
                filterType="HOUSEHOLD"
                eventCategory={EVENT_CATEGORIES.HOUSEHOLDS}
              />
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon display="inline-flex" color={theme.palette.grey[500]} />
              </InputAdornment>
            ),
            sx: { paddingRight: 0 },
          }}
        />
      </DataTableActionBar>
      <WithLoader isLoading={householdsIsLoading} loader={<LoadingTable pageSize={pageSize} />}>
        {households != null && households.length > 0 ? (
          <>
            <DataTable
              table={table}
              onRowClick={(row: Row<HouseholdData>) => {
                amplitude().logEvent('ViewHousehold', {
                  category: EVENT_CATEGORIES.HOUSEHOLDS,
                  householdName: row.original.name,
                });
                history.push(`/secure/households/${row.original.id}/overview`);
              }}
            />
            {!dropdownFilteredHouseholdTableData.length && <NoResultsFound />}
          </>
        ) : (
          <Box py={8}>
            <EmptyState
              icon={<UserGroupIcon />}
              large
              title="You don't have any households set up yet."
              subtitle="Please add a household"
              subtitleOnClick={openAddHouseholdModal}
            />
          </Box>
        )}
      </WithLoader>
      {households != null && households.length > pageSize && (
        <Box
          alignItems="center"
          borderColor="grey.200"
          borderTop={1}
          display="flex"
          justifyContent="flex-end"
          px={3}
          py={2}
        >
          <PageNavigation
            currentPage={(table.state as UsePaginationState<HouseholdData>).pageIndex + 1}
            numItems={dropdownFilteredHouseholdTableData.length}
            pageSize={pageSize}
            onNextPageClick={() => {
              scrollToTop();
              table.nextPage();
            }}
            onPrevPageClick={() => {
              scrollToTop();
              table.previousPage();
            }}
            onPageNumberClick={(pageNumber) => {
              scrollToTop();
              table.gotoPage(pageNumber - 1);
            }}
          />
        </Box>
      )}
    </>
  );
};

export default HouseholdsTable;
