import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useInsterRestrictionsTemplate from '~/hooks/templates/useInsertRestrictionsTemplate';
import DialogTitle from '~/synth/DialogTitle';
import amplitude from '~/utils/amplitude';
import { NewTemplateNameField } from '../../templates/CommonComponents';
import { DraftPortfolioDispatch } from '../../Types';
import useRestrictionsTemplateValidation from './useRestrictionsTemplateValidation';
import { RestrictionsTemplateContent } from './UtilComponents';

export const EMPTY_TEMPLATE = {
  name: '',
  tickers: [],
  subSectors: [],
  sectors: [],
  countries: [],
  esgAreas: [],
} as RestrictionsTemplateContent;

export interface SaveRestrictionsTemplateModalProps {
  open: boolean;
  onClose: () => void;
  onSetLiveTemplate: (template: RestrictionsTemplateContent) => void;
  onSetActiveSection: (section: 'templates' | 'additional' | null) => void;
  dpDispatch: DraftPortfolioDispatch;
  templateContent?: RestrictionsTemplateContent;
  userId?: string;
  orgId?: string;
}

export default function SaveRestrictionsTemplateModal({
  open,
  templateContent,
  userId,
  orgId,
  onClose,
  onSetLiveTemplate,
  onSetActiveSection,
  dpDispatch,
}: SaveRestrictionsTemplateModalProps) {
  const { insertTemplate } = useInsterRestrictionsTemplate();
  const { isNameValid, isTemplateNameTaken } = useRestrictionsTemplateValidation();

  const [templateName, setTemplateName] = useState('');

  const [helperTextState, setHelperTextState] = useState<'error' | 'success' | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkTemplateName = useCallback(
    debounce((newName: string) => {
      if (isNameValid?.(newName)) {
        setHelperTextState('success');
      } else if (isTemplateNameTaken?.(newName)) {
        setHelperTextState('error');
        amplitude().logEvent('Error - template name already taken', {
          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        });
      }
    }, 500),
    [isNameValid, isTemplateNameTaken]
  );

  async function handleClickSaveTemplate() {
    amplitude().logEvent('Action - Confirm save restrictions template', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
    if (
      userId != null &&
      orgId != null &&
      !isSubmitting &&
      templateContent != null &&
      templateName
    ) {
      setIsSubmitting(true);
      const insertedTemplate = await insertTemplate({
        ...templateContent,
        userId,
        orgId,
        name: templateName,
      });
      if (insertedTemplate) {
        onSetLiveTemplate(EMPTY_TEMPLATE);
        dpDispatch({
          type: 'ADD_RESTRICTIONS_TEMPLATE',
          restrictions: insertedTemplate,
        });
        onSetActiveSection(null);
        onClose();
      }
      setIsSubmitting(false);
      setTemplateName('');
    }
  }

  useEffect(() => {
    return () => {
      checkTemplateName.cancel();
    };
  }, [checkTemplateName]);

  return (
    <Dialog open={open}>
      <DialogTitle onClose={onClose}>Name your restriction template.</DialogTitle>
      <DialogContent dividers>
        <NewTemplateNameField
          variant="restrictions"
          value={templateName}
          error={helperTextState === 'error'}
          success={helperTextState === 'success'}
          onChange={(v) => {
            setHelperTextState(null);
            setTemplateName(v);
            checkTemplateName(v);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickSaveTemplate}
          disabled={
            isSubmitting ||
            templateContent == null ||
            userId == null ||
            orgId == null ||
            !templateName ||
            helperTextState !== 'success' ||
            isNameValid == null ||
            isTemplateNameTaken == null
          }
        >
          Save and continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
