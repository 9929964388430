import { datadogRum } from '@datadog/browser-rum';
import { Box, Button, Card, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { isValid } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import {
  AssetClassAllocationRequest,
  PCE2SpecificPortfolioIntelligence,
  PCE2SpecificPortfolioIntelligenceFull,
} from 'vise-types/pce2';
import { AssetClass, AssetClassKey, Country, Feature } from 'vise-types/pce2_instrument';
import { Account } from 'vise-types/portfolio';
import { AllocationsTemplate, RestrictionsTemplate, TiltType } from 'vise-types/template';
import { getAssetAllocation } from '~/api/api';
import { riskToRiskSlider } from '~/api/utils';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import usePortfolioIntelligence from '~/hooks/usePortfolioIntelligence';
import useSectors from '~/hooks/useSectors';
import useEnqueueToast from '~/hooks/useToast';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { ReactComponent as PencilIcon } from '~/static/images/icons/pencil.svg';
import CardContent from '~/synth/CardContent';
import CardHeader from '~/synth/CardHeader';
import Skeleton from '~/synth/Skeleton';
import { convertToPercent, formatCurrency, formatPercent } from '~/utils/format';
import { getAssetClassFeaturesFromKey, getAssetClassKeyFromFeatures } from '~/utils/pce2Migration';
import { EVENT_CATEGORIES } from '../../constants/amplitude';
import amplitude from '../../utils/amplitude';
import {
  ASSET_CLASS_TO_LABEL_MAP,
  ASSET_CLASS_TREES_SMALL,
  COUNTRY_TO_LABEL_MAP,
  ESG_LABEL_MAP,
  PROPOSAL_SOURCE_MAP,
  SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS,
} from '../PortfolioCreator2/Constants';
import { AssetClassTreeNode } from '../PortfolioCreator2/Types';
import {
  alternativesKeys,
  equitiesKeys,
  fixedIncomeKeys,
} from '../PortfolioCreator2/screens/SummaryLandingScreenV2/SummaryLandingScreen';
import {
  SubCategoryAllocation,
  ThreeColumn,
  sumKeys,
} from '../PortfolioCreator2/screens/components/SummarySections';
import {
  ActiveTiltForDisplay,
  computeActiveTiltForDisplay,
  getActiveTiltAmountText,
  getActiveTiltsSelection,
  getExcludedSectorsNames,
  getToggledAssetClassLeafLabel,
  getToggledAssetClassLeavesInAssetClassTree,
  groupExcludedIndustries,
} from '../PortfolioCreator2/utils';
import CaptionWithContent, { CardTitle } from './components/CaptionWithContent';
import { getRemainingYears } from './portfolioUtil';

const CardSection = withStyles(Box, (theme) => ({
  root: {
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '&:last-child': {
      border: 'none',
    },
  },
}));

export function getHorizonContent(horizonEndDate: moment.Moment | null, proposal = false) {
  if (horizonEndDate == null) {
    return <>-</>;
  }
  const horizon = horizonEndDate.year();
  if (isNaN(horizon)) {
    return <>-</>;
  }
  const diff = horizonEndDate.diff(moment(), 'years', true);
  const remainingYears = getRemainingYears(horizonEndDate, moment());
  // Focus on horizon year for proposal list modal
  const content = proposal ? (
    <>{`${remainingYears} ${diff > 1 ? 'years' : 'year'} (${horizon})`}</>
  ) : (
    <>
      {horizon} {diff < 1 ? '(Less than a year remaining)' : `(${remainingYears} years remaining)`}
    </>
  );
  return (
    <>
      {diff > 0 ? (
        <>{content}</>
      ) : (
        <Tooltip title="Date is in the past. Change to a date in the future for optimal performance.">
          <Box display="flex" alignItems="center">
            <Box mr={0.5} color="warning.400">
              <InformationCircleIcon />
            </Box>
            {horizon}
          </Box>
        </Tooltip>
      )}
    </>
  );
}

const ExcludedContent = React.memo<{
  restrictedStocks: PortfolioIntelligenceFull['constructionInfo']['restrictedStocks'];
  excludedSectors: PortfolioIntelligenceFull['constructionInfo']['excludedSectors'];
  excludedIndustries: PortfolioIntelligenceFull['constructionInfo']['excludedIndustries'];
  excludedCountries: Country[];
  excludedEsgAreas: string[];
  useLegacySectors: boolean;
}>(function ExcludedContent({
  restrictedStocks,
  excludedSectors,
  excludedIndustries,
  excludedCountries,
  excludedEsgAreas,
  useLegacySectors,
}) {
  const { data: sectors } = useSectors(useLegacySectors);
  const excludedSectorsSet = useMemo(() => new Set(excludedSectors), [excludedSectors]);
  const excludedIndustriesSet = useMemo(() => new Set(excludedIndustries), [excludedIndustries]);

  let excludedSectorContent: React.ReactNode;

  if (excludedSectors.length === 0 && excludedIndustries?.length === 0) {
    excludedSectorContent = null;
  } else if (sectors == null) {
    excludedSectorContent = <>Loading...</>;
  } else {
    const groupedExcludedIndustries = groupExcludedIndustries({ excludedIndustriesSet, sectors });
    const excludedSectorsNames = getExcludedSectorsNames({ excludedSectorsSet, sectors });

    const excludedSectorsArray: JSX.Element[] = [];
    groupedExcludedIndustries.forEach((restriction) => {
      excludedSectorsArray.push(
        <Box key={restriction.sector}>
          <>{restriction.sector}</>
          <Typography variant="body1" color="textSecondary">
            <ul
              style={{ listStyle: 'none', padding: 0, margin: 0 }}
              aria-label={`${restriction.sector} restrictions`}
            >
              {restriction.industries.map((industry) => (
                <li key={industry} style={{ marginTop: 4 }}>
                  {industry}
                </li>
              ))}
            </ul>
          </Typography>
        </Box>
      );
    });

    excludedSectorsNames.forEach((sector) => {
      excludedSectorsArray.push(
        <Box key={sector}>
          <>{sector}</>
          <Typography variant="body1" color="textSecondary">
            All sub-sectors selected
          </Typography>
        </Box>
      );
    });

    excludedSectorContent = excludedSectorsArray;
  }

  return (
    <>
      <CardTitle>Equities</CardTitle>
      {restrictedStocks?.length > 0 ? (
        <>
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }} aria-label="Equity restrictions">
            {restrictedStocks.map((stock) => (
              <li key={stock} style={{ marginTop: 4 }}>
                {stock}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>None selected</>
      )}
      <Box my={2}>
        <Divider />
      </Box>
      <CardTitle>Sectors</CardTitle>
      {excludedSectorContent !== null ? <>{excludedSectorContent}</> : <>None selected</>}
      <Box my={2}>
        <Divider />
      </Box>
      <CardTitle>Countries</CardTitle>
      {excludedCountries.length > 0 ? (
        <>
          <ul
            style={{ listStyle: 'none', padding: 0, margin: 0 }}
            aria-label="Country restrictions"
          >
            {excludedCountries.map((country) => (
              <li key={country} style={{ marginTop: 4 }}>
                {COUNTRY_TO_LABEL_MAP[country] ?? country}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>None selected</>
      )}
      <Box my={2}>
        <Divider />
      </Box>
      <CardTitle>Values</CardTitle>
      {excludedEsgAreas.length > 0 ? (
        <>
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }} aria-label="ESG restrictions">
            {excludedEsgAreas.map((area) => (
              <li key={area} style={{ marginTop: 4 }}>
                {ESG_LABEL_MAP[area].label}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>None selected</>
      )}
    </>
  );
});

const RestrictionsCard = React.memo(function RestrictionsCard({
  etfOnly,
  excludedSectors,
  excludedIndustries,
  restrictedStocks,
  excludedCountries,
  excludedEsgAreas,
  useLegacySectors,
}: {
  etfOnly: boolean;
  excludedSectors: PortfolioIntelligenceFull['constructionInfo']['excludedSectors'];
  excludedIndustries: PortfolioIntelligenceFull['constructionInfo']['excludedIndustries'];
  restrictedStocks: PortfolioIntelligenceFull['constructionInfo']['restrictedStocks'];
  excludedCountries: Country[];
  excludedEsgAreas: string[];
  useLegacySectors: boolean;
}) {
  if (etfOnly) {
    return (
      <CardSection>
        <CardTitle>Security selection</CardTitle>
        <>Limit to ETFs</>
      </CardSection>
    );
  }

  return (
    <>
      <ExcludedContent
        restrictedStocks={restrictedStocks}
        excludedIndustries={excludedIndustries}
        excludedSectors={excludedSectors}
        excludedCountries={excludedCountries}
        excludedEsgAreas={excludedEsgAreas}
        useLegacySectors={useLegacySectors}
      />
    </>
  );
});

export const InputsLoadingState = () => {
  return (
    <>
      <Skeleton variant="text">
        <h2>Allocation</h2>
      </Skeleton>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <Card>
            <CardContent>
              <Skeleton inline variant="text" width="70%" height="5em" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6}>
          <Card>
            <CardContent>
              <Skeleton inline variant="text" width="70%" height="5em" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Skeleton inline variant="text" width="100%" height="30em" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Skeleton inline variant="text" width="100%" height="20em" />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const Pce2AssetClassCategoryList = ({
  exclusions,
  primaryAssetClassTreeRoot,
  smallAccount = false,
}: {
  exclusions: AssetClassKey[];
  primaryAssetClassTreeRoot: AssetClassTreeNode;
  smallAccount: boolean;
}) => {
  const toggledAssetClassMap = getToggledAssetClassLeavesInAssetClassTree(
    primaryAssetClassTreeRoot,
    exclusions,
    smallAccount
  );

  const assetClassTreeSections: JSX.Element[] = [];

  toggledAssetClassMap.forEach((toggledDescendentKeys, topLevelAssetClassKey) => {
    const topLevelAssetClassFeatures = getAssetClassFeaturesFromKey(topLevelAssetClassKey);
    const topLevelAssetClassLabel = ASSET_CLASS_TO_LABEL_MAP.get(
      topLevelAssetClassFeatures[topLevelAssetClassFeatures.length - 1]
    );

    if (toggledDescendentKeys === null) {
      assetClassTreeSections.push(
        <Box mt={1.5} key={topLevelAssetClassLabel}>
          {topLevelAssetClassLabel}
        </Box>
      );
    } else if (toggledDescendentKeys.length > 0) {
      assetClassTreeSections.push(
        <Box key={topLevelAssetClassLabel}>
          <Box paddingBottom={0.5}>{topLevelAssetClassLabel}</Box>
          {toggledDescendentKeys.map((descendentKey, i) => (
            <Box key={descendentKey} mb={i + 1 === toggledDescendentKeys.length ? 1.5 : 0.5}>
              <Typography color="textSecondary" variant="body1">
                {getToggledAssetClassLeafLabel(topLevelAssetClassKey, descendentKey)}
              </Typography>
            </Box>
          ))}
        </Box>
      );
    }
  });

  return assetClassTreeSections.length > 0 ? (
    <Box mb={2}>
      <CardTitle>{ASSET_CLASS_TO_LABEL_MAP.get(primaryAssetClassTreeRoot.feature)}</CardTitle>
      {assetClassTreeSections}
    </Box>
  ) : null;
};

function SecurityTiltRow({
  activeTiltForDisplay,
  risk,
}: {
  activeTiltForDisplay: ActiveTiltForDisplay;
  risk: number;
}) {
  let body = <></>;
  if (activeTiltForDisplay.portfolioType === 'ETF_EXCLUSIVE') {
    body = (
      <>
        <Box display="flex" alignItems="center">
          <Box>Active tilt disabled </Box>
          <Tooltip title="ETF-only portfolios do not have an active tilt.">
            <Box display="flex" alignItems="center" color="grey.500" ml={0.5}>
              <InformationCircleIcon />
            </Box>
          </Tooltip>
        </Box>
      </>
    );
  } else if (activeTiltForDisplay.portfolioType === 'LOSS_TOLERANCE') {
    const tiltAmountText = getActiveTiltAmountText(
      activeTiltForDisplay.activeTilt,
      riskToRiskSlider(risk)
    );
    body = (
      <Grid container direction="row">
        {activeTiltForDisplay.activeTilt.isEnabled ? (
          <Grid item>
            <CardTitle>Tilt Type</CardTitle>
            <Typography variant="body2">
              {getActiveTiltsSelection(activeTiltForDisplay.activeTilt)}
            </Typography>
          </Grid>
        ) : (
          'Active tilt disabled'
        )}
        {tiltAmountText && (
          <>
            <Divider
              flexItem
              orientation="vertical"
              variant="middle"
              style={{ marginLeft: 32, marginRight: 32 }}
            />
            <Grid item>
              <CardTitle>Amount</CardTitle>
              <Typography variant="body2">{tiltAmountText}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
  return body;
}

interface PortfolioInputsProps {
  account?: Account;
  intelligence: PortfolioIntelligenceFull;
  allocationsTemplate?: AllocationsTemplate;
  restrictionsTemplates?: RestrictionsTemplate[];
}

export default function PortfolioInputs({
  account,
  intelligence,
  allocationsTemplate,
  restrictionsTemplates,
}: PortfolioInputsProps) {
  useEffect(() => {
    amplitude().logEvent('Impression - Portfolio Inputs', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
  }, []);

  if (intelligence.status === 'REJECTED' || intelligence.proposalType === 'light') {
    throw new Error('');
  }

  const { data: currentAccountPortfolioIntelligence } = usePortfolioIntelligence(
    account?.portfolioIntelligenceId !== intelligence.id
      ? account?.portfolioIntelligenceId
      : undefined
  );

  const currentTargetAllocation =
    currentAccountPortfolioIntelligence?.length &&
    currentAccountPortfolioIntelligence[0].pceVersion === 'pce2' &&
    currentAccountPortfolioIntelligence[0].proposalType === 'full'
      ? currentAccountPortfolioIntelligence[0].constructionResponse.targetAllocation.reduce(
          (allocationMap, alloc) => {
            return {
              ...allocationMap,
              [alloc.assetClass.join('/')]: alloc.allocation,
            };
          },
          {} as Record<string, number>
        )
      : undefined;

  const validRestrictionsTemplates = restrictionsTemplates?.filter((t) => t.deletedAt == null);

  const { constructionRequest } = intelligence as PCE2SpecificPortfolioIntelligence;
  const { constructionResponse } = intelligence as PCE2SpecificPortfolioIntelligenceFull;
  const {
    assetClassAllocationRequest,
    etfOnly: constructionRequestEtfOnly,
    doNotHoldSymbolsList,
    doNotSellSymbolsList,
    targetCashFraction,
    doNotHoldCountriesList,
    doNotHoldEsgAreasList,
    taxOptions,
    doNotPromoteAssetClasses,
    minSymbolAssetClasses,
    activeTilt: activeTiltAmount,
    dividendTilt,
    manageMoneyMarketFunds,
    tiltPerAssetClassesList,
  } = constructionRequest;
  const { concentrationLimitsList, customAllocationParams, glidePathParams } =
    assetClassAllocationRequest as AssetClassAllocationRequest;
  const etfExclusiveAssetClasses = doNotPromoteAssetClasses.map(
    (assetClass) => assetClass.join('/') as AssetClassKey
  );
  const minSymbolAssetClassesKeys = minSymbolAssetClasses.map(
    (assetClass) => assetClass.join('/') as AssetClassKey
  );
  const lockedPositions = doNotSellSymbolsList;
  const autoTlh = taxOptions?.autoTlh;
  const maxCapGainsLong = taxOptions?.maxCapGainsLong;
  const maxCapGainsShort = taxOptions?.maxCapGainsShort;

  const horizonEndDate =
    assetClassAllocationRequest == null || assetClassAllocationRequest.years === 0
      ? null
      : moment(assetClassAllocationRequest.timeHorizonStartTimestamp).add(
          assetClassAllocationRequest.years,
          'years'
        );

  const etfOnly = constructionRequestEtfOnly;

  const usingGlidePath = assetClassAllocationRequest?.glidePathParams != null;
  let initialRisk = usingGlidePath
    ? assetClassAllocationRequest?.glidePathParams?.initialRisk
    : assetClassAllocationRequest?.risk;
  if (assetClassAllocationRequest?.customAllocationParams) {
    initialRisk = constructionResponse.risk;
  }

  const restrictedStocks = doNotHoldSymbolsList;
  const excludedCountries = doNotHoldCountriesList;
  const excludedEsgAreas = doNotHoldEsgAreasList;
  const pce2AssetClassExclusions = concentrationLimitsList
    .filter((c) => c.concentrationLimit === 0)
    .map((c) => getAssetClassKeyFromFeatures(c.assetClass as AssetClass));
  const cashAllocationPct =
    targetCashFraction == null ? null : convertToPercent(targetCashFraction);
  const fixedIncomeConcentrationLimit =
    concentrationLimitsList.find(
      (c) => getAssetClassKeyFromFeatures(c.assetClass as AssetClass) === 'FIXED_INCOME'
    )?.concentrationLimit || null; // Purposefully ignore concentration limit of "0" as well.
  const equitiesConcentrationLimit =
    concentrationLimitsList.find(
      (c) => getAssetClassKeyFromFeatures(c.assetClass as AssetClass) === 'EQUITY'
    )?.concentrationLimit || null; // Purposefully ignore concentration limit of "0" as well.
  const alternativesConcentrationLimit =
    concentrationLimitsList.find(
      (c) => getAssetClassKeyFromFeatures(c.assetClass as AssetClass) === 'ALTERNATIVES'
    )?.concentrationLimit || null; // Purposefully ignore concentration limit of "0" as well.

  const { excludedIndustries, excludedSectors, risk, smallAccount } = intelligence.constructionInfo;

  const { data: featureFlags } = useFeatureFlags();

  const gicsCutoffDate = new Date(featureFlags?.gics_frontend_cutoff_date || 'Invalid');
  const proposalCreatedBeforeCutoffDate =
    isValid(gicsCutoffDate) && new Date(intelligence.createdAt) < gicsCutoffDate;
  const proposalUsesLegacySectors = !!(
    proposalCreatedBeforeCutoffDate &&
    (intelligence.constructionInfo.excludedSectors.length ||
      intelligence.constructionInfo.excludedIndustries?.length)
  );

  // NOTE:
  // For vise-recommended allocations and risk-tolerance proposals we do not have a full allocation in the construction request.
  // Vise-recommended allocations only have the L1 split, and risk tolerance portfolios only have the EQ/FI ratio. In order to get
  // the full allocation as an input, we have to take advantage of the get-asset-allocation helper API that we use on the custom allocation
  // screen. The output matches what the user would have seen had they inputted the split into the custom allocation screen.
  // For risk-tolerance proposals, we also have to take into account whether or not alternatives are enabled. If they are, their default allocation is 5%,
  // and the equity/fixed-income portion of the allocation is reduced pro-rata. We also need to take into account exclusions for both of these cases.
  // Once the endpoint gives us back a full allocation, we set it in state and display it. Since the endpoint is fast, we can just display the loading state a little
  // bit longer while we wait for a response.

  // Memoize the data so we don't refetch asset allocation
  const glidePathAllocation = glidePathParams?.initialL1Allocations.length
    ? glidePathParams.initialL1Allocations
    : undefined;
  const nonGlidePathAllocation = customAllocationParams?.allocations.length
    ? customAllocationParams.allocations
    : undefined;
  const customAssetAllocation: { [key in AssetClassKey]?: number } | undefined = useMemo(
    () =>
      (glidePathAllocation || nonGlidePathAllocation)?.reduce((prev, curr) => {
        return { ...prev, [curr.assetClass.join('/')]: curr.allocationFraction };
      }, {}),
    [glidePathAllocation, nonGlidePathAllocation]
  );

  const enqueueToast = useEnqueueToast();

  const [assetAllocation, setAssetAlloction] = useState<
    { [key in AssetClassKey]?: number } | undefined
  >();
  useEffect(() => {
    if (customAssetAllocation && Object.keys(customAssetAllocation).length > 3) {
      setAssetAlloction(customAssetAllocation);
      return;
    }
    const fetchAllocations = async () => {
      let allocation: { [key in AssetClassKey]?: number } | undefined = customAssetAllocation;
      if (!allocation || Object.keys(allocation).length === 0) {
        const risk =
          (typeof assetClassAllocationRequest?.glidePathParams?.initialRisk === 'number'
            ? assetClassAllocationRequest?.glidePathParams?.initialRisk
            : assetClassAllocationRequest?.risk) || 0;
        const alternativesEnabled = !assetClassAllocationRequest?.concentrationLimitsList.find(
          (limit) =>
            limit.assetClass?.join('/') === 'ALTERNATIVES/COMMODITIES' &&
            limit.concentrationLimit === 0
        );
        const splitFraction = alternativesEnabled ? 0.95 : 1;
        allocation = {
          EQUITY: risk * splitFraction,
          FIXED_INCOME: (1 - risk) * splitFraction,
          ALTERNATIVES: 1 - splitFraction,
        };
      }
      allocation = (assetClassAllocationRequest?.concentrationLimitsList || []).reduce(
        (alloc, limit) => {
          const key = limit.assetClass?.join('/') || '';
          if (limit.concentrationLimit === 0) {
            return {
              ...alloc,
              [key]: 0,
            };
          }
          return alloc;
        },
        allocation
      );
      try {
        const defaultAllocation = await getAssetAllocation({ assetAllocation: allocation || {} });
        if (defaultAllocation.data) {
          setAssetAlloction(defaultAllocation.data);
        }
      } catch (e) {
        datadogRum.addError(e);
        enqueueToast({
          severity: 'error',
          title: 'There was an error loading portfolio inputs.',
          content: 'Please try again later.',
        });
      }
    };
    fetchAllocations();
  }, [
    customAssetAllocation,
    assetClassAllocationRequest?.concentrationLimitsList,
    assetClassAllocationRequest?.glidePathParams?.initialRisk,
    assetClassAllocationRequest?.risk,
    enqueueToast,
  ]);

  if (!assetAllocation) {
    return <InputsLoadingState />;
  }

  const nonSmallAccountAssetClassContent = (
    <>
      {currentTargetAllocation ? (
        <ThreeColumn
          key="equity"
          left={<CardTitle>Equities</CardTitle>}
          middle={
            <Typography variant="h5" component="div">
              Previous
            </Typography>
          }
          right={
            <Typography component="div" variant="h5">
              Proposed
            </Typography>
          }
        />
      ) : (
        <CardTitle>Equities</CardTitle>
      )}
      <SubCategoryAllocation
        parent="EQUITY/US"
        assetAllocation={assetAllocation}
        originalAssetAllocation={currentTargetAllocation}
      />
      <SubCategoryAllocation
        parent="EQUITY/DEVELOPED"
        assetAllocation={assetAllocation}
        originalAssetAllocation={currentTargetAllocation}
      />
      <SubCategoryAllocation
        parent="EQUITY/EMERGING_EQUITY"
        assetAllocation={assetAllocation}
        originalAssetAllocation={currentTargetAllocation}
      />
      <SubCategoryAllocation
        parent="EQUITY/US_REIT"
        assetAllocation={assetAllocation}
        originalAssetAllocation={currentTargetAllocation}
      />
      <Box my={2}>
        <Divider />
      </Box>
      {currentTargetAllocation ? (
        <ThreeColumn
          key="fi"
          left={<CardTitle>Fixed income</CardTitle>}
          middle={
            <Typography variant="h5" component="div">
              Previous
            </Typography>
          }
          right={
            <Typography component="div" variant="h5">
              Proposed
            </Typography>
          }
        />
      ) : (
        <CardTitle>Fixed income</CardTitle>
      )}
      <SubCategoryAllocation
        parent="FIXED_INCOME/DOMESTIC"
        assetAllocation={assetAllocation}
        originalAssetAllocation={currentTargetAllocation}
      />
      <SubCategoryAllocation
        parent="FIXED_INCOME/INTERNATIONAL"
        assetAllocation={assetAllocation}
        originalAssetAllocation={currentTargetAllocation}
      />
      <SubCategoryAllocation
        parent="FIXED_INCOME/EMERGING_FI"
        assetAllocation={assetAllocation}
        originalAssetAllocation={currentTargetAllocation}
      />
      <Box my={2}>
        <Divider />
      </Box>
      {currentTargetAllocation ? (
        <ThreeColumn
          key="alts"
          left={<CardTitle>Alternatives</CardTitle>}
          middle={
            <Typography variant="h5" component="div">
              Previous
            </Typography>
          }
          right={
            <Typography component="div" variant="h5">
              Proposed
            </Typography>
          }
        />
      ) : (
        <CardTitle>Alternatives</CardTitle>
      )}
      <SubCategoryAllocation
        parent="ALTERNATIVES/COMMODITIES"
        originalAssetAllocation={currentTargetAllocation}
        assetAllocation={assetAllocation}
      />
    </>
  );

  const constructionRequestTilt = tiltPerAssetClassesList[0]?.tiltType;

  let tiltType: TiltType = 'multi-factor';
  if (dividendTilt) {
    tiltType = 'dividend';
  } else if (constructionRequestTilt === 'LOW_VOLATILITY') {
    tiltType = 'low-volatility';
  } else if (constructionRequestTilt === 'LOW_VOLATILITY_QUALITY_YIELD') {
    tiltType = 'low-volatility-quality-yield';
  } else if (constructionRequestTilt === 'QUALITY') {
    tiltType = 'quality';
  } else if (constructionRequestTilt === 'VALUE') {
    tiltType = 'value';
  } else if (constructionRequestTilt === 'MOMENTUM') {
    tiltType = 'momentum';
  } else if (constructionRequestTilt === 'QUALITY_VALUE_MOMENTUM') {
    tiltType = 'quality-value-momentum';
  } else if (constructionRequestTilt === 'QUALITY_VALUE_MOMENTUM_WITH_INCOME_FILTER_300') {
    tiltType = 'income-quality-value-momentum';
  }

  const computedActiveTiltForDisplay = computeActiveTiltForDisplay(
    activeTiltAmount,
    tiltType,
    riskToRiskSlider(risk),
    etfOnly,
    !!smallAccount,
    activeTiltAmount === 0
  );

  const glidePathContent = usingGlidePath ? (
    <>
      <CardTitle>Glide path</CardTitle>
      <Box color="success.400" mb={0.5}>
        Enabled
      </Box>
      <Typography variant="body1" color="textSecondary">
        {getHorizonContent(horizonEndDate)}
      </Typography>
    </>
  ) : null;

  const assetClassConcentrationContent =
    !smallAccount &&
    (fixedIncomeConcentrationLimit != null ||
      equitiesConcentrationLimit != null ||
      alternativesConcentrationLimit != null) ? (
      <>
        <CardTitle>Asset class concentration limits</CardTitle>
        {equitiesConcentrationLimit != null && (
          <>
            <>Equities</>
            <Typography variant="body2" color="textSecondary">
              {convertToPercent(equitiesConcentrationLimit)}%
            </Typography>
          </>
        )}
        {fixedIncomeConcentrationLimit != null && (
          <Box mt={1.5}>
            <>Fixed income</>
            <Typography variant="body2" color="textSecondary">
              {convertToPercent(fixedIncomeConcentrationLimit)}%
            </Typography>
          </Box>
        )}
        {alternativesConcentrationLimit != null && (
          <Box mt={1.5}>
            <>Alternatives</>
            <Typography variant="body2" color="textSecondary">
              {convertToPercent(alternativesConcentrationLimit)}%
            </Typography>
          </Box>
        )}
      </>
    ) : null;

  const allocationTemplateContent =
    allocationsTemplate != null ? (
      <CaptionWithContent
        caption="Template"
        content={
          <Box display="flex">
            <Box mr={1} data-cy="allocation-template-name">
              {allocationsTemplate.name}
            </Box>
            <Box color="grey.400">
              (Created {moment(allocationsTemplate.createdAt).format('MMM DD, YYYY')})
            </Box>
          </Box>
        }
      />
    ) : (
      <>
        <Box mb={0.5}>Custom allocation</Box>
        <Typography variant="body1" color="textSecondary">
          No template selected
        </Typography>
      </>
    );

  return (
    <Grid container spacing={4}>
      <Grid item container spacing={2}>
        <Grid item lg={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Allocation</Typography>
            {account != null &&
              PROPOSAL_SOURCE_MAP[intelligence.proposalSource || 0] === 'ADVISOR' && (
                <Button
                  color="secondary"
                  to={`/secure/portfolio-creator-next/summary-landing?pid=${encodeURIComponent(
                    intelligence.id
                  )}`}
                  onClick={() => {
                    amplitude().logEvent('OpenPortfolioEdit', {
                      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
                    });
                  }}
                  startIcon={<PencilIcon />}
                  disabled={account.status !== 'ACTIVE'}
                  component={Link}
                >
                  Edit portfolio
                </Button>
              )}
          </Box>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardHeader>
                <Typography variant="h4">Strategy Type</Typography>
              </CardHeader>
              <CardContent>
                <>{allocationTemplateContent}</>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardHeader>
                <Typography variant="h4">Portfolio split</Typography>
              </CardHeader>
              <CardContent display="flex">
                <CaptionWithContent
                  caption="Equities"
                  data-testid="split-equities"
                  content={
                    smallAccount
                      ? formatPercent(initialRisk, 0)
                      : `${(sumKeys(equitiesKeys, assetAllocation) * 100).toFixed(0)}%`
                  }
                />
                <Box mx={4}>
                  <Divider orientation="vertical" variant="middle" />
                </Box>
                <CaptionWithContent
                  caption="Fixed income"
                  data-testid="split-fixedIncome"
                  content={
                    smallAccount
                      ? formatPercent(initialRisk != null ? 1 - initialRisk : null, 0)
                      : `${(sumKeys(fixedIncomeKeys, assetAllocation) * 100).toFixed(0)}%`
                  }
                />
                {sumKeys(alternativesKeys, assetAllocation) > 0 && !smallAccount && (
                  <>
                    <Box mx={4}>
                      <Divider orientation="vertical" variant="middle" />
                    </Box>
                    <CaptionWithContent
                      data-testid="split-fixedIncome"
                      caption="Alternatives"
                      content={`${(sumKeys(alternativesKeys, assetAllocation) * 100).toFixed(0)}%`}
                    />
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <Card>
            <CardHeader>
              <Typography variant="h4">Asset classes</Typography>
            </CardHeader>
            <CardContent pb={1}>
              {smallAccount ? (
                <>
                  <Pce2AssetClassCategoryList
                    exclusions={pce2AssetClassExclusions}
                    primaryAssetClassTreeRoot={ASSET_CLASS_TREES_SMALL[0]}
                    smallAccount
                  />
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Pce2AssetClassCategoryList
                    exclusions={pce2AssetClassExclusions}
                    primaryAssetClassTreeRoot={ASSET_CLASS_TREES_SMALL[1]}
                    smallAccount
                  />
                </>
              ) : (
                <>{nonSmallAccountAssetClassContent}</>
              )}
            </CardContent>
          </Card>
        </Grid>
        {!smallAccount && (
          <>
            <Grid item lg={12}>
              <Card>
                <CardHeader>
                  <Typography variant="h4">Investment strategy</Typography>
                </CardHeader>
                <CardContent>
                  <Typography variant="h5" color="textSecondary">
                    Investment vehicles
                  </Typography>
                  <Box mt={1}>
                    {SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.filter(
                      (key) => !pce2AssetClassExclusions.find((val) => val === key)
                    ).map((key, i) => {
                      return (
                        <Box key={key} mt={i === 0 ? 0 : 1.5}>
                          <Box mb={0.5}>
                            {key
                              .split('/')
                              .splice(1)
                              .map((feature) => ASSET_CLASS_TO_LABEL_MAP.get(feature as Feature))
                              .join(' ')}
                          </Box>
                          <Typography color="textSecondary" variant="body1">
                            {etfExclusiveAssetClasses.find((val) => val === key)
                              ? 'ETF-only'
                              : 'Single-securities'}
                            <Box
                              color="grey.600"
                              hidden={
                                featureFlags?.disable_hold_on_custom_number_of_tickers !== 'on'
                              }
                            >
                              {minSymbolAssetClassesKeys.find((key2) => key2 === key)
                                ? 'Fewer holdings'
                                : 'Vise-recommended asset concentration'}
                            </Box>
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={12}>
              <Card>
                <CardHeader>
                  <Typography variant="h4">Special conditions</Typography>
                </CardHeader>
                <CardContent>
                  {glidePathContent}
                  {assetClassConcentrationContent}
                  {glidePathContent == null && assetClassConcentrationContent == null
                    ? 'None selected'
                    : null}
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>

      <Grid item container spacing={2}>
        <Grid item lg={12}>
          <Typography variant="h2">Tilts</Typography>
        </Grid>
        <Grid item lg={12}>
          <Card>
            <CardContent display="flex" data-testid="active-tilt-input">
              <SecurityTiltRow
                activeTiltForDisplay={computedActiveTiltForDisplay}
                risk={intelligence.constructionInfo.risk}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {!smallAccount && (
        <Grid item container spacing={2}>
          <Grid item lg={12}>
            <Typography variant="h2">Restrictions</Typography>
          </Grid>
          <Grid item lg={12}>
            <Card data-testid="inputs-restrictions-template-card">
              <CardContent>
                <CaptionWithContent
                  caption="Templates"
                  content={
                    <>
                      {validRestrictionsTemplates?.length ? (
                        <>
                          {validRestrictionsTemplates.map((t) => (
                            <Box key={t.name}>
                              {t.name}
                              <Box component="span" color="grey.400" ml={1}>
                                (Created {moment(t.createdAt).format('MMM DD, YYYY')})
                              </Box>
                            </Box>
                          ))}
                        </>
                      ) : (
                        'None selected'
                      )}
                    </>
                  }
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={12}>
            <Card data-testid="inputs-restrictions-card">
              <CardContent>
                <RestrictionsCard
                  etfOnly={etfOnly}
                  excludedSectors={excludedSectors}
                  restrictedStocks={restrictedStocks}
                  excludedIndustries={excludedIndustries}
                  excludedCountries={excludedCountries}
                  excludedEsgAreas={excludedEsgAreas}
                  useLegacySectors={proposalUsesLegacySectors}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <Grid item container spacing={2}>
        <Grid item lg={12}>
          <Typography variant="h2">Transition settings</Typography>
        </Grid>
        <Grid item lg={12}>
          <Card>
            <CardContent>
              <CardTitle>Locked positions</CardTitle>
              {lockedPositions.length > 0 ? (
                <>
                  <ul
                    style={{ listStyle: 'none', padding: 0, margin: 0 }}
                    aria-label="locked positions list"
                  >
                    {lockedPositions.map((symbol, idx) => (
                      <li key={symbol} style={{ marginTop: idx === 0 ? 0 : 4 }}>
                        {symbol}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>No locked positions</>
              )}
              <Box my={2}>
                <Divider />
              </Box>
              <CardTitle>Cash allocation</CardTitle>
              <Box mb={0.5}>
                {cashAllocationPct == null ? 'No allocation set' : `${cashAllocationPct}%`}
              </Box>
              {manageMoneyMarketFunds ? (
                <Typography variant="body1" color="textSecondary">
                  MMF enabled
                </Typography>
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {account?.taxable && (
        <Grid item container spacing={2}>
          <Grid item lg={12}>
            <Typography variant="h2">Taxes</Typography>
          </Grid>
          <Grid item lg={12}>
            <Card>
              <CardContent>
                <CardTitle>Tax management</CardTitle>
                <Box display="flex">
                  <Box mr={0.6}>Tax loss harvesting:</Box>
                  {autoTlh ? <Box color="success.400">Active</Box> : 'Off'}
                </Box>
                <Box mt={1} mb={0.5}>
                  Long-term gain limit
                </Box>
                <Typography variant="body1" color="textSecondary">
                  {maxCapGainsLong == null
                    ? 'No limit set'
                    : `${formatCurrency(maxCapGainsLong)} per year`}
                </Typography>

                <Box mt={1} mb={0.5}>
                  Short-term gain limit
                </Box>
                <Typography variant="body1" color="textSecondary">
                  {maxCapGainsShort == null
                    ? 'No limit set'
                    : `${formatCurrency(maxCapGainsShort)} per year`}
                </Typography>
                {featureFlags?.enable_tax_rates === 'on' && account.accountDataSource === 'XRAY' ? (
                  <>
                    <Box mt={1} mb={0.5}>
                      Long-term tax rate
                    </Box>
                    <Typography variant="body1" color="textSecondary">
                      {taxOptions?.longTermTaxRate != null
                        ? formatPercent(taxOptions.longTermTaxRate, 1)
                        : null}
                    </Typography>

                    <Box mt={1} mb={0.5}>
                      Short-term tax rate
                    </Box>
                    <Typography variant="body1" color="textSecondary">
                      {taxOptions?.shortTermTaxRate != null
                        ? formatPercent(taxOptions.shortTermTaxRate, 1)
                        : null}
                    </Typography>
                  </>
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
