import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import { FormikErrors, FormikProps, useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { createClient, updateAccount } from '~/api/api';
import useAccount from '~/hooks/useAccount';
import useHouseholdClients from '~/hooks/useHouseholdClients';
import useHouseholdsWithSummary from '~/hooks/useHouseholdsWithSummary';
import useEnqueueToast from '~/hooks/useToast';
import { RawClient } from '~/models/api';
import {
  Card,
  CardSection,
  CardTextTitleAndDescription,
} from '~/routes/PortfolioCreator2/screens/components';
import CreateHousehold from '~/static/images/illustrations/create-household.png';
import SearchHousehold from '~/static/images/illustrations/search-household.png';
import DialogTitle from '~/synth/DialogTitle';
import TextField from '~/synth/TextField';
import Select from '~/synth/inputs/Select';
import { AccountNameCell } from '../table/UtilComponents';

interface HouseholdOption {
  label: string;
  value: string;
}

interface ClientOption {
  label: string;
  value: string;
}

interface ExistingHouseholdFormValues {
  household: HouseholdOption | null;
  client: ClientOption | null;
  existingHouseholdClientFirstName: string | null;
  existingHouseholdClientLastName: string | null;
}

interface NewHouseholdFormValues {
  householdName: string | null;
  newHouseholdClientFirstName: string | null;
  newHouseholdClientLastName: string | null;
}

export function useNewHouseholdFormik(
  accountId?: string | null,
  onSubmitSuccess?: () => void,
  onSubmitError?: () => void,
  onClientCreationSuccess?: (client: RawClient) => void
): FormikProps<NewHouseholdFormValues> {
  const newHouseholdForm = useFormik<NewHouseholdFormValues>({
    initialValues: {
      householdName: null,
      newHouseholdClientFirstName: null,
      newHouseholdClientLastName: null,
    },
    validate(values) {
      const errors: FormikErrors<NewHouseholdFormValues> = {};
      if (!values.householdName) {
        errors.householdName = 'Household name is required';
      }
      if (!values.newHouseholdClientFirstName) {
        errors.newHouseholdClientFirstName = 'First name is required';
      }
      if (!values.newHouseholdClientLastName) {
        errors.newHouseholdClientLastName = 'Last name is required';
      }
      if (values.householdName && values.householdName?.length >= 256) {
        errors.householdName = 'Household name should be under 256 characters';
      }
      if (values.newHouseholdClientFirstName && values.newHouseholdClientFirstName?.length >= 256) {
        errors.newHouseholdClientFirstName = 'First name should be under 256 characters';
      }
      if (values.newHouseholdClientLastName && values.newHouseholdClientLastName?.length >= 256) {
        errors.newHouseholdClientLastName = 'Last name should be under 256 characters';
      }
      return errors;
    },
    async onSubmit(values) {
      const { newHouseholdClientFirstName, newHouseholdClientLastName, householdName } = values;
      if (!newHouseholdClientFirstName || !newHouseholdClientLastName || !householdName) return;
      try {
        const newClient = await createClient(
          { firstName: newHouseholdClientFirstName, lastName: newHouseholdClientLastName },
          { name: householdName }
        );
        onClientCreationSuccess?.(newClient);
        if (accountId) {
          await updateAccount(accountId, newClient.id);
        }
      } catch (e) {
        onSubmitError?.();
      }
      newHouseholdForm.resetForm();
      onSubmitSuccess?.();
    },
  });
  return newHouseholdForm;
}

export function NewHouseholdForm({
  newHouseholdForm,
}: {
  newHouseholdForm: FormikProps<NewHouseholdFormValues>;
}) {
  return (
    <form onSubmit={newHouseholdForm.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">Client info</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Household name"
            value={newHouseholdForm.values.householdName}
            onChange={newHouseholdForm.handleChange}
            onBlur={newHouseholdForm.handleBlur}
            placeholder="Ex: Smith Household"
            fullWidth
            error={
              newHouseholdForm.touched.householdName &&
              Boolean(newHouseholdForm.errors.householdName)
            }
            helperText={
              newHouseholdForm.touched.householdName && newHouseholdForm.errors.householdName
            }
            InputLabelProps={{ required: true }}
            id="householdName"
          />
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="First name"
              value={newHouseholdForm.values.newHouseholdClientFirstName}
              onChange={newHouseholdForm.handleChange}
              onBlur={newHouseholdForm.handleBlur}
              error={
                newHouseholdForm.touched.newHouseholdClientFirstName &&
                Boolean(newHouseholdForm.errors.newHouseholdClientFirstName)
              }
              helperText={
                newHouseholdForm.touched.newHouseholdClientFirstName &&
                newHouseholdForm.errors.newHouseholdClientFirstName
              }
              InputLabelProps={{ required: true }}
              id="newHouseholdClientFirstName"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last name"
              value={newHouseholdForm.values.newHouseholdClientLastName}
              onChange={newHouseholdForm.handleChange}
              onBlur={newHouseholdForm.handleBlur}
              error={
                newHouseholdForm.touched.newHouseholdClientLastName &&
                Boolean(newHouseholdForm.errors.newHouseholdClientLastName)
              }
              helperText={
                newHouseholdForm.touched.newHouseholdClientLastName &&
                newHouseholdForm.errors.newHouseholdClientLastName
              }
              InputLabelProps={{ required: true }}
              id="newHouseholdClientLastName"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="end">
            <Button
              type="submit"
              disabled={newHouseholdForm.isSubmitting}
              startIcon={
                newHouseholdForm.isSubmitting ? (
                  <CircularProgress size="1em" color="inherit" />
                ) : undefined
              }
              color="primary"
              variant="contained"
            >
              Confirm
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

/**
 * Create a client in a new household
 * Create a client in an existing household
 * If accountId is passed in, link the account to the newly created client.
 */
export default function LinkAccountOrCreateClientModal({
  accountId,
  open,
  onClose,
  onSuccess,
  onLinkAccountToExistingClient,
}: {
  accountId?: string | null;
  open: boolean;
  onClose: () => void;
  onSuccess?: (client: RawClient) => void;
  onLinkAccountToExistingClient?: (clientId: string) => void;
}) {
  const enqueueToast = useEnqueueToast();
  const [activeSection, setActiveSection] = useState<'new' | 'existing' | null>(null);
  const [showNewClient, setShowNewClient] = useState(false);

  const newHouseholdForm = useNewHouseholdFormik(
    accountId,
    onClose,
    () =>
      enqueueToast({
        title: `There was an error ${accountId ? 'linking the account' : 'creating a new client'}.`,
        content: 'Please reach out to clientservice@vise.com for help',
        severity: 'error',
      }),
    onSuccess
  );

  const existingHouseholdForm = useFormik<ExistingHouseholdFormValues>({
    initialValues: {
      household: null,
      client: null,
      existingHouseholdClientFirstName: null,
      existingHouseholdClientLastName: null,
    },
    validate(values) {
      const errors: FormikErrors<ExistingHouseholdFormValues> = {};
      if (values.household == null) {
        errors.household = 'Household is required';
      }
      if (
        !(values.existingHouseholdClientFirstName && values.existingHouseholdClientLastName) &&
        values.client == null
      ) {
        errors.client = 'Client information is required';
      }
      if (
        values.existingHouseholdClientFirstName &&
        values.existingHouseholdClientFirstName?.length >= 256
      ) {
        errors.existingHouseholdClientFirstName = 'First name should be under 256 characters';
      }
      if (
        values.existingHouseholdClientLastName &&
        values.existingHouseholdClientLastName?.length >= 256
      ) {
        errors.existingHouseholdClientLastName = 'Last name should be under 256 characters';
      }
      return errors;
    },
    async onSubmit(values) {
      const {
        existingHouseholdClientFirstName,
        existingHouseholdClientLastName,
        household,
        client,
      } = values;

      if (
        household == null ||
        (existingHouseholdClientFirstName == null &&
          existingHouseholdClientLastName == null &&
          client == null)
      )
        return;

      try {
        if (client == null) {
          const newClient = await createClient({
            firstName: existingHouseholdClientFirstName ?? '',
            lastName: existingHouseholdClientLastName ?? '',
            clientGroupId: household.value,
          });
          if (accountId) {
            await updateAccount(accountId, newClient.id);
          }
          onSuccess?.(newClient);
        } else if (accountId) {
          await updateAccount(accountId, client.value);
          onLinkAccountToExistingClient?.(client.value);
        }
      } catch (e) {
        enqueueToast({
          title: `There was an error ${
            accountId ? 'linking the account' : 'creating a new client'
          }.`,
          content: 'Please reach out to clientservice@vise.com for help',
          severity: 'error',
        });
      }
      existingHouseholdForm.resetForm();
      onClose();
    },
  });

  const { data: accountData } = useAccount(accountId);
  const { data: householdsData, isLoading: householdsIsLoading } = useHouseholdsWithSummary(true);
  const { data: householdClientData, isLoading: householdClientIsLoading } = useHouseholdClients(
    existingHouseholdForm.values.household?.value
  );

  const householdOptions: HouseholdOption[] | undefined = useMemo(() => {
    return householdsData == null
      ? undefined
      : householdsData.map((hh) => ({
          label: hh.name,
          value: hh.id,
        }));
  }, [householdsData]);

  const clientOptions: ClientOption[] | undefined = useMemo(() => {
    return householdClientData == null
      ? undefined
      : householdClientData.map((c) => ({
          label: `${c.firstName} ${c.lastName}`,
          value: c.id,
        }));
  }, [householdClientData]);

  return (
    <Dialog
      onClose={() => {
        onClose();
        newHouseholdForm.resetForm();
        existingHouseholdForm.resetForm();
      }}
      open={open}
      sx={{ '.MuiPaper-root': { overflow: 'visible' } }}
    >
      <DialogTitle
        onClose={() => {
          onClose();
          newHouseholdForm.resetForm();
          existingHouseholdForm.resetForm();
        }}
      >
        {accountId ? 'Link account' : 'Create a client'}
      </DialogTitle>
      {accountData?.data != null ? (
        <Box ml={3}>
          <AccountNameCell
            accountName={accountData.data.accountName}
            accountNumber={accountData.data.accountNumber}
            custodianKey={accountData.data.custodianKey}
            taxable={accountData.data.taxable}
          />
        </Box>
      ) : null}

      <DialogContent sx={{ overflow: 'visible' }}>
        <Card
          selectable
          onClick={() => setActiveSection('existing')}
          active={activeSection === 'existing'}
          mb={2}
        >
          <form onSubmit={existingHouseholdForm.handleSubmit}>
            <CardSection>
              <Box display="flex">
                <img src={SearchHousehold} alt="Search household" style={{ marginRight: '32px' }} />
                <CardTextTitleAndDescription title="Search for a household">
                  {accountId
                    ? 'Link this account to an existing household.'
                    : 'Create a client in an existing household.'}
                </CardTextTitleAndDescription>
              </Box>
              <Collapse in={activeSection === 'existing'}>
                <Box mt={1.5} mb={2}>
                  <Divider />
                </Box>
                <Select<HouseholdOption>
                  required
                  inputId="household.name"
                  isClearable
                  isLoading={householdsIsLoading}
                  name="household.name"
                  options={householdOptions}
                  onChange={(option) => {
                    existingHouseholdForm.setFieldValue('household', option);
                    existingHouseholdForm.setFieldValue('client', null);
                  }}
                  onBlur={existingHouseholdForm.handleBlur}
                  placeholder="Ex: Carter Household"
                  value={existingHouseholdForm.values.household}
                  error={
                    existingHouseholdForm.touched.household &&
                    Boolean(existingHouseholdForm.errors.household)
                  }
                />
                <FormHelperText sx={{ color: 'error.main' }}>
                  {existingHouseholdForm.touched.household &&
                    existingHouseholdForm.errors.household}
                </FormHelperText>
                <Collapse in={existingHouseholdForm.values.household != null}>
                  {accountId ? (
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mt={1.5}
                        mb={0.5}
                        fontWeight="bold"
                      >
                        Select or create a client
                        <Button
                          color="primary"
                          size="small"
                          onClick={() => {
                            setShowNewClient(true);
                            existingHouseholdForm.setFieldValue('client', null);
                          }}
                        >
                          + Add new
                        </Button>
                      </Box>
                      <Select<ClientOption>
                        inputId="client"
                        isClearable
                        isLoading={householdClientIsLoading}
                        name="client"
                        options={clientOptions}
                        onChange={(option) => {
                          existingHouseholdForm.setFieldValue('client', option);
                          setShowNewClient(false);
                        }}
                        placeholder="Ex: John Carter"
                        required
                        value={existingHouseholdForm.values.client}
                        error={
                          existingHouseholdForm.touched.client &&
                          Boolean(existingHouseholdForm.errors.client)
                        }
                      />
                      <FormHelperText sx={{ color: 'error.main' }}>
                        {existingHouseholdForm.touched.client &&
                          existingHouseholdForm.errors.client}
                      </FormHelperText>
                    </>
                  ) : null}
                  <Collapse in={showNewClient || !accountId}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          label="First name"
                          value={existingHouseholdForm.values.existingHouseholdClientFirstName}
                          onChange={existingHouseholdForm.handleChange}
                          onBlur={existingHouseholdForm.handleBlur}
                          id="existingHouseholdClientFirstName"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Last name"
                          value={existingHouseholdForm.values.existingHouseholdClientLastName}
                          onChange={existingHouseholdForm.handleChange}
                          onBlur={existingHouseholdForm.handleBlur}
                          id="existingHouseholdClientLastName"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                  <Box display="flex" justifyContent="end">
                    <Button
                      sx={{ marginTop: 2 }}
                      type="submit"
                      disabled={existingHouseholdForm.isSubmitting}
                      startIcon={
                        existingHouseholdForm.isSubmitting ? (
                          <CircularProgress size="1em" color="inherit" />
                        ) : undefined
                      }
                      color="primary"
                      variant="contained"
                    >
                      Confirm
                    </Button>
                  </Box>
                </Collapse>
              </Collapse>
            </CardSection>
          </form>
        </Card>
        <Card selectable onClick={() => setActiveSection('new')} active={activeSection === 'new'}>
          <CardSection>
            <Box display="flex">
              <img src={CreateHousehold} alt="Create household" style={{ marginRight: '32px' }} />
              <CardTextTitleAndDescription title="Create a new household">
                {accountId
                  ? 'Create a new household and link this account to it.'
                  : 'Create a new client in a new household.'}
              </CardTextTitleAndDescription>
            </Box>
            <Collapse in={activeSection === 'new'}>
              <Box my={2}>
                <Divider />
              </Box>
              <NewHouseholdForm newHouseholdForm={newHouseholdForm} />
            </Collapse>
          </CardSection>
        </Card>
      </DialogContent>
    </Dialog>
  );
}
