import { Avatar, Badge, Box, Divider, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as NotificationIcon } from '~/static/images/icons/notification.svg';
import { ReactComponent as CogIcon } from '~/static/images/icons/cog.svg';
import { Link } from 'react-router-dom';
import amplitude from '~/utils/amplitude';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useUser from '~/hooks/useUser';
import { useHistory } from 'react-router';
import NotificationDrawer from './components/NotificationDrawer';
import Sidebar from './components/Sidebar';
import { logout } from '../../actions/user';
import { Content, Header } from '../../components';
import { locale } from '../../constants';
import { RootState } from '../../reducers';
import { Route } from '../../models/shared';
import renderRoutes from '../renderRoutes';

const SidebarLayout: React.FunctionComponent<React.PropsWithChildren<{ route: Route }>> = ({
  route,
}) => {
  const { data: user } = useUser(false, false, true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSidebar, setSidebar] = useState(true);
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const noSidebar = params.get('noSidebar') === 'true';

  const dispatch = useDispatch();
  const submitLogout = () => dispatch(logout());
  const toggleDrawer = () => setOpenDrawer(!openDrawer);
  const toggleSidebar = () => {
    setSidebar(!openSidebar);
    amplitude().logEvent(openSidebar ? 'Close side nav' : 'Open side nav', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  };
  const notifications = useSelector((state: RootState) => state.app.notifications);
  const currentUser = useSelector((state: RootState) => state.users.currentUser);

  // TODO(whuang): This should be part of the reducer.
  const unreadNotificationsCount = notifications.filter(
    (notification) => !notification.read
  ).length;

  if (!user) {
    return null;
  }

  return (
    <>
      {noSidebar ? null : (
        <>
          <Header open={openSidebar}>
            <IconButton color="inherit" onClick={toggleDrawer} size="large">
              <Badge variant="dot" color="secondary" invisible={unreadNotificationsCount === 0}>
                <NotificationIcon height="24" width="24" />
              </Badge>
            </IconButton>
            <IconButton component={Link} color="inherit" to="/secure/settings" size="large">
              <CogIcon height="24" width="24" />
            </IconButton>
            <Box height="100%" py={1}>
              <Divider orientation="vertical" />
            </Box>
            <Box ml={3} color="#333336">
              <Box display="flex">
                {user.imgUrl ? (
                  <Box width={40} height={40} borderRadius={99} overflow="hidden">
                    <img src={user.imgUrl} width={40} alt="profile" />
                  </Box>
                ) : (
                  <Avatar sx={{ bgcolor: '#d5f3f6', color: '#1a767e' }}>
                    {user.firstName.slice(0, 1)}
                    {user.lastName.slice(0, 1)}
                  </Avatar>
                )}
                <Box ml={1}>
                  <Typography variant="body1">
                    {user.firstName} {user.lastName}
                  </Typography>
                  <Typography fontSize="12px" fontWeight={500}>
                    RIA
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Header>
          <Sidebar onClickLogout={submitLogout} onToggle={toggleSidebar} open={openSidebar} />
        </>
      )}
      <Content noSidebar={noSidebar} open={openSidebar}>
        {renderRoutes(route.routes, currentUser)}
      </Content>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ locale: string; onClose: () => void; open:... Remove this comment to see the full error message */}
      <NotificationDrawer locale={locale} onClose={toggleDrawer} open={openDrawer} />
    </>
  );
};

export default SidebarLayout;
