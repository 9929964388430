import { uniq } from 'lodash';

export interface TemplateLinkState {
  linkAccountsIds: string[];
  unlinkAccountsIds: string[];
}

export type Action =
  | {
      type: 'UNLINK_ACCOUNT';
      accountId: string;
    }
  | {
      type: 'UNLINK_ACCOUNTS';
      accountIds: string[];
    }
  | {
      type: 'LINK_ACCOUNT';
      accountId: string;
    }
  | {
      type: 'LINK_ACCOUNTS';
      accountIds: string[];
    };

export default function reducer(state: TemplateLinkState, action: Action): TemplateLinkState {
  switch (action.type) {
    case 'UNLINK_ACCOUNT': {
      const newIds = state.unlinkAccountsIds.includes(action.accountId)
        ? state.unlinkAccountsIds.filter((id) => id !== action.accountId)
        : uniq([action.accountId, ...state.unlinkAccountsIds]);

      return {
        ...state,
        unlinkAccountsIds: newIds,
      };
    }
    case 'UNLINK_ACCOUNTS': {
      const newIds = action.accountIds.every((id) => state.unlinkAccountsIds.includes(id))
        ? state.unlinkAccountsIds.filter((id) => !action.accountIds.includes(id))
        : uniq([...state.unlinkAccountsIds, ...action.accountIds]);

      return {
        ...state,
        unlinkAccountsIds: newIds,
      };
    }
    case 'LINK_ACCOUNT': {
      const newIds = state.linkAccountsIds.includes(action.accountId)
        ? state.linkAccountsIds.filter((id) => id !== action.accountId)
        : uniq([action.accountId, ...state.linkAccountsIds]);

      return {
        ...state,
        linkAccountsIds: newIds,
      };
    }
    case 'LINK_ACCOUNTS': {
      const newIds = action.accountIds.every((id) => state.linkAccountsIds.includes(id))
        ? state.linkAccountsIds.filter((id) => !action.accountIds.includes(id))
        : uniq([...state.linkAccountsIds, ...action.accountIds]);

      return {
        ...state,
        linkAccountsIds: newIds,
      };
    }
    default:
      return state;
  }
}
