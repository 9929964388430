import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
import { find as lodashFind } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { DistributionSchedule } from 'vise-types/distribution';
import SomethingWentWrong from '~/components/SomethingWentWrong';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAllAccountsWithPIAndHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import useAccount from '~/hooks/useAccount';
import useDistributionSchedule from '~/hooks/useDistributionSchedule';
import useDistributionScheduleSchema from '~/hooks/useDistributionScheduleSchema';
import usePortfolioIntelligence from '~/hooks/usePortfolioIntelligence';
import usePortfolioMetrics from '~/hooks/usePortfolioMetrics';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import Skeleton from '~/synth/Skeleton';
import amplitude from '~/utils/amplitude';
import AccountSelect, { AccountOption } from '~/synth/AccountSelect';
import { normalizeRawAccount } from '~/api/api';
import DistributionForm from './DistributionForm';
import PreviewDistribution from './PreviewDistribution';
import { DISTRIBUTION_TABLE_TYPE } from '../Constants';

type SelectedAccountOption = AccountOption | null;

function AccountSelectWrapper({
  selectedAccount,
  setSelectedAccount,
}: {
  selectedAccount: SelectedAccountOption | null;
  setSelectedAccount: (account: SelectedAccountOption) => void;
}) {
  const { data: accountsResp, error: accountsError } = useAllAccountsWithPIAndHouseholdInfo({
    includeSummaries: false,
    includePi: true,
  });

  const accounts =
    accountsResp != null && accountsResp.data
      ? accountsResp.data
          .map((a) => normalizeRawAccount(a))
          .filter(
            (a) => a?.intelligence?.pceVersion === 'pce2' || a?.engineType === 'MODEL_DELIVERY'
          )
      : null;

  useEffect(() => {
    if (accountsError != null) {
      Sentry.captureException(
        new Error(`[AccountSelectWrapper] Failed to load all accounts info.`)
      );
    }
  }, [accountsError]);

  // TODO: Fix this
  if (accountsError != null) {
    return <Box>Error</Box>;
  }

  return (
    <>
      <Box mt={3}>
        <Typography variant="h4">Select account</Typography>
      </Box>
      <Box mt={1}>
        <AccountSelect
          accounts={accounts}
          isDisabled={accounts == null}
          isLoading={accountsResp == null}
          onChange={(accountOption) => setSelectedAccount(accountOption as SelectedAccountOption)}
          value={selectedAccount}
          noOptionsMessage={() => 'No accounts available.'}
          optionHeight={70}
        />
      </Box>
    </>
  );
}

export default function AddOrEditDistribution() {
  let { accountId } = useParams<{ accountId?: string }>();
  const { scheduleId } = useParams<{ scheduleId?: string }>();
  const theme = useTheme();

  const history = useHistory();
  const location = useLocation<{ from: string; isModelDelivery: boolean }>();
  const { isModelDelivery } = location.state;
  const toSelectAccount = accountId == null;

  const isEdit = scheduleId != null;

  const fromGlobalDistribution = location.state?.from === DISTRIBUTION_TABLE_TYPE.GLOBAL;

  const [selectedAccount, setSelectedAccount] = useState<SelectedAccountOption | null>(null);

  if (selectedAccount != null) {
    accountId = selectedAccount.account.id;
  }

  const { data: accountData, error: accountError } = useAccount(accountId);
  const [distribution, setDistribution] = useState<DistributionSchedule | null>(null);
  const [newDistributionStep, setNewDistributionStep] = useState(0);
  const [editSchedule, setEditSchedule] = useState<DistributionSchedule | undefined>(undefined);

  const { data: intelligenceArrayData, error: intelligenceError } = usePortfolioIntelligence(
    accountData?.data?.portfolioIntelligenceId
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const intelligence = intelligenceArrayData?.[0];

  // TODO: Add rpc to fetch 1 distro by schedule id
  const {
    data: distributionSchedule,
    mutate: mutateDistributionSchedule,
    error: distributionScheduleError,
  } = useDistributionSchedule(
    intelligence?.constructionInfo.clientId ||
      (accountData?.data.engineType === 'MODEL_DELIVERY'
        ? accountData?.data.viseClientId
        : undefined),
    accountData?.data?.custodianKey,
    accountData?.data?.accountNumber
  );

  const { data: portfolioMetrics, error: portfolioMetricsError } = usePortfolioMetrics(
    intelligence?.pceVersion === 'pce2' || accountData?.data.engineType === 'MODEL_DELIVERY'
      ? accountId
      : undefined
  );

  const { data: distributionScheduleSchema, error: distributionScheduleSchemaError } =
    useDistributionScheduleSchema();

  useEffect(
    () => setEditSchedule(lodashFind(distributionSchedule, { scheduleId })),
    [distributionSchedule, scheduleId]
  );

  useEffect(() => {
    amplitude().logEvent(
      `Impression - ${isEdit ? 'Edit' : 'Add new'} distribution ${
        toSelectAccount ? 'with account selector' : ''
      }`,
      {
        category: EVENT_CATEGORIES.DISTRIBUTIONS,
      }
    );
  }, [isEdit, toSelectAccount]);

  // Error capturing
  useEffect(() => {
    if (distributionScheduleError != null) {
      Sentry.captureException(
        new Error(
          `[AddOrEditDistribution] Distributions failed to load. ${distributionScheduleError}.`
        ),
        {
          extra: {
            accountId,
          },
        }
      );
    }
  }, [accountId, distributionScheduleError]);

  useEffect(() => {
    if (intelligenceError != null) {
      Sentry.captureException(
        new Error(
          `[AddOrEditDistribution] Intelligence ${intelligence?.id} failed to load. ${intelligenceError}.`
        ),
        {
          extra: {
            accountId,
            intelligenceId: intelligence?.id,
          },
        }
      );
    }
  }, [accountId, intelligence?.id, intelligenceError]);

  useEffect(() => {
    if (intelligenceArrayData && intelligenceArrayData[0] == null) {
      Sentry.captureException(
        new Error(
          `[AddOrEditDistribution] Failed to load intelligence for account ${accountId}: missing intelligence data.`
        ),
        {
          extra: {
            accountId,
          },
        }
      );
    }
  }, [accountId, intelligenceArrayData]);

  useEffect(() => {
    if (portfolioMetricsError) {
      Sentry.captureException(
        new Error(
          `[AddOrEditDistribution] Account ${accountId} with intelligence ${intelligence?.id} failed to load metrics: ${portfolioMetricsError}.`
        ),
        {
          extra: {
            accountId,
            intelligenceId: intelligence?.id,
            intelligenceStatus: intelligence?.status,
          },
        }
      );
    }
  }, [accountId, intelligence?.id, intelligence?.status, portfolioMetricsError]);

  useEffect(() => {
    if (accountError != null) {
      Sentry.captureException(
        new Error(`[AddOrEditDistribution] Failed to load account. ${accountError}.`),
        {
          extra: {
            accountId,
            intelligendId: intelligence?.id,
            intelligenceStatus: intelligence?.status,
          },
        }
      );
    }
  }, [accountError, accountId, intelligence?.id, intelligence?.status]);

  if (accountError) {
    return (
      <SomethingWentWrong
        description={`There was an error loading the account ${accountId}.`}
        error={accountError}
      />
    );
  }

  if (intelligenceError) {
    return (
      <SomethingWentWrong
        description="There was an error loading the portfolio information."
        error={intelligenceError}
      />
    );
  }

  if (intelligenceArrayData && intelligenceArrayData[0] == null) {
    return (
      <SomethingWentWrong
        description="There was an error loading the account: missing intelligence data."
        error={new Error('Failed to load intelligence data')}
      />
    );
  }

  if (portfolioMetricsError) {
    return (
      <SomethingWentWrong
        description="There was an error loading the portfolio metrics."
        error={portfolioMetricsError}
      />
    );
  }

  if (accountError?.response.status === 404) {
    return (
      <SomethingWentWrong
        title="We can't find the account you're looking for."
        description="The link you're using may be
        incorrect, or you may not have access to the account."
        disableCta
        error={new Error('Missing account data')}
      />
    );
  }

  if (distributionScheduleSchemaError) {
    return (
      <SomethingWentWrong
        description="There was an error loading the distribution schema."
        error={distributionScheduleSchemaError}
      />
    );
  }

  if (
    !toSelectAccount &&
    (accountData == null ||
      (intelligence == null && accountData.data.engineType !== 'MODEL_DELIVERY') ||
      portfolioMetrics == null ||
      distributionScheduleSchema == null ||
      distributionSchedule == null)
  ) {
    return (
      <>
        <Box
          height={64}
          border={1}
          borderRight={0}
          borderLeft={0}
          borderTop={0}
          borderColor="grey.200"
          display="flex"
          justifyContent="end"
          alignItems="center"
          px={3}
        >
          <Box>
            <IconButton
              aria-label="Exit"
              color="inherit"
              onClick={() => history.goBack()}
              size="large"
            >
              <XIcon display="inline-flex" />
            </IconButton>
          </Box>
        </Box>
        <Box maxWidth={720} minWidth={452} mx="auto">
          <>
            <Box mt={5}>
              <Typography variant="h1">
                {isEdit ? 'Edit distribution schedule' : 'Add a new distribution'}
              </Typography>
            </Box>
            <Box
              p={3}
              sx={{ borderColor: 'grey.200', borderRadius: theme.shape.borderRadius }}
              border={1}
              mt={3}
              mb={2}
            >
              <Skeleton width="4em" />
              <Skeleton width="8em" />
            </Box>
            <Box
              p={3}
              sx={{ borderColor: 'grey.200', borderRadius: theme.shape.borderRadius }}
              border={1}
              mb={3}
            >
              <Skeleton width="100%" height="20em" />
            </Box>
            <Button color="primary" variant="contained" disabled>
              Continue
            </Button>
          </>
        </Box>
      </>
    );
  }

  if (
    !isSubmitting &&
    isEdit &&
    (editSchedule == null ||
      (editSchedule.accountId !== null && editSchedule.accountId !== accountId))
  ) {
    return (
      <SomethingWentWrong
        description="We can't find the distribution schedule you're looking for. The link you're using may be
    incorrect, or you may not have access to the distribution schedule."
        disableCta
        error={new Error('could not load schedule to edit')}
      />
    );
  }

  const cashInfomation = portfolioMetrics?.portfolioCashInformation;
  const availableForImmediateDistribution = cashInfomation?.availableForImmediateDistribution;
  const availableForRecurringDistribution = cashInfomation?.availableForRecurringDistribution;

  const account = accountData?.data;

  const accountSelected = accountId != null;

  const stepOneComponent = (
    <>
      <Box mt={5} mb={toSelectAccount ? 3 : 5}>
        <Typography variant="h1">
          {isEdit ? 'Edit distribution schedule' : 'Add a new distribution'}
        </Typography>
        {toSelectAccount ? (
          <AccountSelectWrapper
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        ) : null}
      </Box>
      {accountSelected &&
        (account == null || // Loading Account Data
        availableForImmediateDistribution == null ||
        availableForRecurringDistribution == null ||
        distributionScheduleSchema == null ? (
          <>
            <Box
              p={3}
              sx={{ borderColor: 'grey.200', borderRadius: theme.shape.borderRadius }}
              border={1}
              mb={3}
            >
              <Skeleton width="100%" height="20em" />
            </Box>
            <Button color="primary" variant="contained" disabled>
              Continue
            </Button>
          </>
        ) : (
          <DistributionForm
            account={account}
            intelligence={intelligence}
            availableForImmediateDistribution={availableForImmediateDistribution}
            availableForRecurringDistribution={availableForRecurringDistribution}
            setNewDistributionStep={setNewDistributionStep}
            setDistribution={setDistribution}
            scheduleSchema={distributionScheduleSchema}
            distribution={editSchedule}
            isEdit={isEdit}
            toSelectAccount={toSelectAccount}
          />
        ))}
    </>
  );

  return (
    <Box pb={5}>
      <Box
        height={64}
        border={1}
        borderRight={0}
        borderLeft={0}
        borderTop={0}
        borderColor="grey.200"
        display="flex"
        justifyContent="end"
        alignItems="center"
        px={3}
      >
        <Box>
          <IconButton
            aria-label="Exit"
            color="inherit"
            onClick={() => {
              amplitude().logEvent('Action - Exit distribution form', {
                category: EVENT_CATEGORIES.DISTRIBUTIONS,
              });
              history.goBack();
            }}
            size="large"
          >
            <XIcon display="inline-flex" />
          </IconButton>
        </Box>
      </Box>
      <Box maxWidth={720} minWidth={452} mx="auto">
        {newDistributionStep === 0 && stepOneComponent}
        {newDistributionStep === 1 &&
          distribution &&
          account != null &&
          availableForImmediateDistribution != null &&
          availableForRecurringDistribution != null && (
            <PreviewDistribution
              isModelDelivery={isModelDelivery}
              account={account}
              distribution={distribution}
              proposalId={intelligence?.id}
              scheduleIdToEdit={scheduleId}
              mutateSchedule={mutateDistributionSchedule}
              availableForImmediateDistribution={availableForImmediateDistribution}
              availableForRecurringDistribution={availableForRecurringDistribution}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              fromGlobalDistribution={fromGlobalDistribution}
              onBack={() => {
                setNewDistributionStep(0);
                setEditSchedule(distribution ?? undefined);
              }}
            />
          )}
      </Box>
    </Box>
  );
}
