import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import useInProgressJobs from '~/hooks/useInProgressJobs';
import Proposal from '~/routes/Portfolio/Proposal';
import { ReactComponent as ArrowLeftIcon } from '~/static/images/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '~/static/images/icons/arrow-right.svg';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import amplitude from '~/utils/amplitude';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { CONTAINER_WIDTH } from './constants';
import Container from './Container';

export default function ViewProposalInJob() {
  const routeMatch = useRouteMatch<{ jobId: string; proposalId: string }>([
    `/secure/strategy-center-proposal/:jobId/:proposalId`,
  ]);
  const jobId = routeMatch?.params.jobId;
  const proposalId = routeMatch?.params.proposalId;

  useEffect(() => {
    amplitude().logEvent('Impression - view proposal in job', {
      category: EVENT_CATEGORIES.STRATEGY_CENTER,
      jobId,
      proposalId,
    });
  }, [jobId, proposalId]);

  const strategyCenterJobs = useInProgressJobs({
    refreshInterval: undefined,
  });
  const bulkEditJobs = useInProgressJobs({
    refreshInterval: undefined,
    bulkEdit: true,
  });
  if (!jobId || !proposalId) {
    return null;
  }
  const job = [...(bulkEditJobs.data?.data || []), ...(strategyCenterJobs.data?.data || [])].find(
    (job) => job.id === jobId
  );
  if (!job) {
    return null;
  }
  const proposalIds = job.events
    .map((event) => event.newProposalId)
    .filter((maybeId: string | null): maybeId is string => !!maybeId);
  const index = proposalIds.findIndex((id) => id === proposalId);
  return (
    <Container
      footer={
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Button
              startIcon={<ArrowLeftIcon />}
              component={Link}
              to={`/secure/template/job/${jobId}`}
              variant="outlined"
              color="secondary"
            >
              Return to summary
            </Button>
          </Box>
          <Box mr={3}>
            <Typography variant="body1" color="textSecondary">
              {index + 1} of {proposalIds.length}
            </Typography>
          </Box>
          <Box mr={1.5}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={`/secure/strategy-center-proposal/${jobId}/${proposalIds[index - 1]}`}
              disabled={index === 0}
            >
              <ArrowLeftIcon />
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/secure/strategy-center-proposal/${jobId}/${proposalIds[index + 1]}`}
            disabled={index === proposalIds.length - 1}
          >
            <ArrowRightIcon />
          </Button>
        </Box>
      }
      fullHeight
    >
      <Box maxWidth={CONTAINER_WIDTH} margin="auto">
        <Proposal
          breadcrumbs={false}
          disableGutters
          routePrefix={`/secure/strategy-center-proposal/${jobId}`}
        />
      </Box>
    </Container>
  );
}
