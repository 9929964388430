import { OutlinedInput, OutlinedInputProps, styled } from '@mui/material';
import React from 'react';
import NumberFormat, { NumberFormatValues, NumberFormatProps } from 'react-number-format';

const StyledInput = styled(OutlinedInput)({
  '& input': {
    fontSize: '14px',
  },
  marginTop: '5px',
  width: '100%',
});

export const Input = ({ type = 'text', ...rest }: OutlinedInputProps) => (
  <StyledInput {...rest} type={type} />
);

interface InputDollarProps extends NumberFormatProps {
  onChange: (event: {
    target: { name: string; value: NumberFormatValues['floatValue'] | string };
  }) => void;
  name: string;
}

export const InputDollar = React.forwardRef(
  ({ inputRef, name, onChange, ...other }: InputDollarProps) => {
    return (
      <NumberFormat
        {...other}
        decimalScale={2}
        defaultValue="0"
        getInputRef={inputRef}
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        isAllowed={({ formattedValue, floatValue }: any) =>
          formattedValue === '' || floatValue >= 0
        }
        onValueChange={(values: NumberFormatValues) => {
          onChange({ target: { name, value: values.floatValue } });
        }}
        prefix="$"
        thousandSeparator
      />
    );
  }
);

interface InputYearProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [arbitrary: string]: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
}

export const InputYear = ({ onChange, ...other }: InputYearProps) => {
  return (
    <NumberFormat
      {...other}
      allowNegative={false}
      defaultValue="1"
      getInputRef={other.inputRef}
      onValueChange={(values: NumberFormatValues) => {
        if (onChange) {
          onChange({
            target: {
              name: other.name,
              value: values.floatValue ? Math.floor(values.floatValue) : undefined,
            },
          });
        }
      }}
      suffix=" years"
    />
  );
};
