import { Box, Typography } from '@mui/material';
import React from 'react';

export default function AssetAllocationCopy({ risk }: { risk: number }) {
  let body: null | React.ReactNode = null;
  if (risk <= 0.3) {
    body = (
      <>
        <Typography paragraph variant="body1">
          The Conservative portfolio primarily aims to preserve capital with a large allocation to
          less volatile assets such as diversified fixed income.
        </Typography>
        <Typography paragraph variant="body1">
          The portfolio also contains a small exposure to both domestic and international equity
          funds to capture upside potential.
        </Typography>
      </>
    );
  } else if (risk <= 0.5) {
    body = (
      <>
        <Typography paragraph variant="body1">
          The Moderately Conservative portfolio primarily aims to preserve capital with a large
          allocation to less volatile assets classes such as diversified fixed income.
        </Typography>
        <Typography paragraph variant="body1">
          The portfolio also contains a moderate exposure to both domestic and international equity
          funds for additional upside potential relative to more conservative allocations.
        </Typography>
      </>
    );
  } else if (risk <= 0.7) {
    body = (
      <Typography paragraph variant="body1">
        This allocation is for investors with a long-term time horizon and a focus on growth but
        want some less volatile investments built into their portfolio to reduce volatility and
        increase downside protection.
      </Typography>
    );
  } else if (risk <= 0.85) {
    body = (
      <>
        <Typography paragraph variant="body1">
          This allocation is well-suited for investors with a long-term time horizon and a high risk
          tolerance, who want to target moderate growth for their portfolio.
        </Typography>
        <Typography paragraph variant="body1">
          To reduce volatility, portfolios using this template will contain some conservative
          investments compared to more aggressive, all-stock portfolios.
        </Typography>
      </>
    );
  } else {
    body = (
      <>
        <Typography paragraph variant="body1">
          This allocation is for investors with a long-term time horizon and a high risk tolerance
          who want to focus on the growth of the portfolio.
        </Typography>
        <Typography paragraph variant="body1">
          The portfolio may experience substantial volatility and higher potential losses in
          exchange for the prospect of higher expected returns.
        </Typography>
      </>
    );
  }
  return <Box color="grey.700">{body}</Box>;
}
