import { Box, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import ViseLogoBlack from '~/static/images/vise-logo-black.svg';
import { Colors } from '../constants';
import Progress from './Progress';

// TO-DO: When we get the chance, change interface name not to be prefixed with I
// eslint-disable-next-line @typescript-eslint/naming-convention
interface ILoaderProps {
  showMessages?: boolean;
  message?: string;
  showFixedIncomeMessages?: boolean;
}

// TO-DO: When we get the chance, change interface name not to be prefixed with I
// eslint-disable-next-line @typescript-eslint/naming-convention
interface ILoaderState {
  messageIndex: number;
}

const messages = [
  'Determining preliminary asset allocation',
  'Analyzing equity data',
  'Determining investment strategy',
  'Backtesting',
  'Running montecarlo simulation',
  'Customizing asset allocation',
  'Generating portfolio intelligence',
] as const;

const fixedIncomeMessages = [
  'Determining preliminary strategy',
  'Analyzing market data',
  'Determining investment strategy',
  'Building Portfolio',
  'Running analytics',
  'Generating results',
];

export const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
`;

export const LoaderOverlay = styled.div`
  align-items: center;
  background-color: ${Colors.white};
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 10000;
`;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/prefer-default-export
export class Loader extends React.Component<ILoaderProps, ILoaderState> {
  private interval: ReturnType<typeof setInterval> | null = null;

  constructor(props: ILoaderProps) {
    super(props);

    this.state = {
      messageIndex: 0,
    };
  }

  public componentDidMount = () => {
    if (this.props.showMessages) {
      this.interval = setInterval(() => this.updateMessage(), 6000);
    }
  };

  public componentWillUnmount = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  };

  public render = () => {
    const { messageIndex } = this.state;
    const { message } = this.props;

    return (
      <LoaderOverlay>
        <LoaderContainer data-testid="loading-progress">
          <Box mb={5}>
            <img alt="V" src={ViseLogoBlack} height="96" />
          </Box>
          <div style={{ width: '100%' }}>
            <Progress variant="indeterminate" />
          </div>
          {this.props.message && (
            <Typography variant="h4" color="textSecondary">
              {message}
            </Typography>
          )}
          {this.props.showMessages && (
            <Typography variant="h4" color="textSecondary">
              {messages[messageIndex]}
            </Typography>
          )}
          {this.props.showFixedIncomeMessages && (
            <Typography variant="h4" color="textSecondary">
              {fixedIncomeMessages[messageIndex]}
            </Typography>
          )}
        </LoaderContainer>
      </LoaderOverlay>
    );
  };

  private updateMessage = () =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.setState((prev: any) => {
      const newIdx = prev.messageIndex + 1;
      const length = messages.length - 1;

      return {
        messageIndex: newIdx <= length ? newIdx : 0,
      };
    });
}
