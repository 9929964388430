import orderBy from 'lodash/orderBy';
import { intersectionBy } from 'lodash';
import { ActionType } from '../constants';

interface Notification {
  created_at: string;
  id: string;
  read: boolean;
}

interface GetNotificationsFulfilledAction {
  notifications: Notification[];
  count: number;
  timestamp: string;
  type: typeof ActionType.GET_NOTIFICATIONS_FULFILLED;
}

interface MarkNotificationAsReadFulfilledAction {
  notifications: Notification[];
  type: typeof ActionType.MARK_NOTIFICATION_AS_READ_FULFILLED;
}

interface TosAgreementChange {
  type: typeof ActionType.TOS_AGREEMENT_CHANGE;
  value: string;
}

type Action =
  | GetNotificationsFulfilledAction
  | MarkNotificationAsReadFulfilledAction
  | TosAgreementChange;

export type State = {
  locale: string;
  notifications: Notification[];
  notificationsCount: number;
  notificationsTimestamp: string | null;
  tos: string | null;
};

const initialState = {
  // TODO(whuang): this is being removed. Use locale in src/constants/index.ts directly.
  locale: window.navigator.language || 'en-US',
  notifications: [],
  notificationsCount: 0,
  notificationsTimestamp: null,
  tos: null,
};

const app = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_NOTIFICATIONS_FULFILLED: {
      const { notifications, count, timestamp } = action;

      return {
        ...state,
        notifications: notifications ? orderBy(notifications, ['created_at'], ['desc']) : [],
        notificationsCount: count,
        notificationsTimestamp: timestamp,
      };
    }

    case ActionType.MARK_NOTIFICATION_AS_READ_FULFILLED: {
      const { notifications } = action;

      const updatedNotifications = intersectionBy(state.notifications, notifications, 'id');

      updatedNotifications.forEach((notification) => {
        /* eslint-disable no-param-reassign */
        notification.read = true;
      });

      return {
        ...state,
        notifications: [...state.notifications],
        notificationsCount: updatedNotifications.length,
      };
    }

    case ActionType.TOS_AGREEMENT_CHANGE: {
      localStorage.setItem('TOS_Confirmation', action.value);
      return {
        ...state,
        tos: action.value,
      };
    }

    default:
      return state;
  }
};

export default app;
