enum ActionType {
  CLEAR_POSITIONS = 'CLEAR_POSITIONS',
  CLOSE_MESSAGE = 'CLOSE_MESSAGE',
  CREATE_ORDER = 'CREATE_ORDER',
  CLICK_PORTFOLIO_SUMMARY_SECTION = 'CLICK_PORTFOLIO_SUMMARY_SECTION',
  GET_ACCOUNT_REQUESTED = 'GET_ACCOUNT_REQUESTED',
  GET_ACCOUNT_FULFILLED = 'GET_ACCOUNT_FULFILLED',
  GET_CLIENTS_FAILED = 'GET_CLIENTS_FAILED',
  GET_CLIENTS_FULFILLED = 'GET_CLIENTS_FULFILLED',
  GET_CLIENTS_V2_FULFILLED = 'GET_CLIENTS_V2_FULFILLED',
  GET_CLIENTS_REQUESTED = 'GET_CLIENTS_REQUESTED',
  GET_DISTRIBUTION_SCHEDULE_FULFILLED = 'GET_DISTRIBUTION_SCHEDULE_FULFILLED',
  GET_DISTRIBUTION_SCHEDULE_REQUESTED = 'GET_DISTRIBUTION_SCHEDULE_REQUESTED',
  GET_INSTRUMENTS_ERROR = 'GET_INSTRUMENTS_ERROR',
  GET_INSTRUMENTS_FAILED = 'GET_INSTRUMENTS_FAILED',
  GET_INSTRUMENTS_FULFILLED = 'GET_INSTRUMENTS_FULFILLED',
  GET_INSTRUMENTS_REQUESTED = 'GET_INSTRUMENTS_REQUESTED',
  GET_INSTRUMENT_BENCHMARKS_ERROR = 'GET_INSTRUMENT_BENCHMARKS_ERROR',
  GET_INSTRUMENT_BENCHMARKS_FAILED = 'GET_INSTRUMENT_BENCHMARKS_FAILED',
  GET_INSTRUMENT_BENCHMARKS_FULFILLED = 'GET_INSTRUMENT_BENCHMARKS_FULFILLED',
  GET_INSTRUMENT_BENCHMARKS_REQUESTED = 'GET_INSTRUMENT_BENCHMARKS_REQUESTED',
  GET_NOTIFICATIONS_FULFILLED = 'GET_NOTIFICATIONS_FULFILLED',
  GET_NOTIFICATIONS_REQUESTED = 'GET_NOTIFICATIONS_REQUESTED',
  GET_PORTFOLIO_INTELLIGENCE_FULFILLED = 'GET_PORTFOLIO_INTELLIGENCE_FULFILLED',
  GET_PORTFOLIO_INTELLIGENCE_REQUESTED = 'GET_PORTFOLIO_INTELLIGENCE_REQUESTED',
  GET_PORTFOLIO_INTELLIGENCE_FAILED = 'GET_PORTFOLIO_INTELLIGENCE_FAILED',
  GET_POSITIONS_FAILED = 'GET_POSITIONS_FAILED',
  GET_POSITIONS_FULFILLED = 'GET_POSITIONS_FULFILLED',
  GET_POSITIONS_REQUESTED = 'GET_POSITIONS_REQUESTED',
  GET_FEATURE_TREATMENTS_REQUESTED = 'GET_FEATURE_TREATMENTS_REQUESTED',
  GET_FEATURE_TREATMENTS_FULFILLED = 'GET_FEATURE_TREATMENTS_FULFILLED',
  GET_FEATURE_TREATMENTS_FAILED = 'GET_FEATURE_TREATMENTS_FAILED',
  GET_USERS_FULFILLED = 'GET_USERS_FULFILLED',
  GET_USERS_REQUESTED = 'GET_USERS_REQUESTED',
  GET_USER_INFO_FULFILLED = 'GET_USER_INFO_FULFILLED',
  GET_USER_INFO_REQUESTED = 'GET_USER_INFO_REQUESTED',
  LOGIN_USER_FAILED = 'LOGIN_USER_FAILED',
  LOGIN_USER_FULFILLED = 'LOGIN_USER_FULFILLED',
  LOGIN_USER_REQUESTED = 'LOGIN_USER_REQUESTED',
  LOGOUT_USER_FULFILLED = 'LOGOUT_USER_FULFILLED',
  MARK_AS_READ = 'MARK_AS_READ',
  MARK_NOTIFICATION_AS_READ_FULFILLED = 'MARK_NOTIFICATION_AS_READ_FULFILLED',
  MARK_NOTIFICATION_AS_READ_REQUESTED = 'MARK_NOTIFICATION_AS_READ_REQUESTED',
  MFA_REQUIRED = 'MFA_REQUIRED',
  MFA_CANCELLED = 'MFA_CANCELLED',
  REFRESH_TOKEN_FULFILLED = 'REFRESH_TOKEN_FULFILLED',
  REMOVE_CLIENT_FROM_CLIENTS = 'REMOVE_CLIENT_FROM_CLIENTS',
  REMOVE_CLIENT_GROUP_FROM_CLIENT_GROUPS = 'REMOVE_CLIENT_GROUP_FROM_CLIENT_GROUPS',
  REMOVE_MESSAGE = 'REMOVE_MESSAGE',
  RESET_CLIENT_GROUPS_STATE = 'RESET_CLIENT_GROUPS_STATE',
  RESET_CLIENT_STATE = 'RESET_CLIENT_STATE',
  RESET_ORDER_STATE = 'RESET_ORDER_STATE',
  SHOW_MESSAGE = 'SHOW_MESSAGE',
  TOS_AGREEMENT_CHANGE = 'TOS_AGREEMENT_CHANGE',
  UPSERT_CLIENT_FROM_CLIENTS = 'UPSERT_CLIENT_FROM_CLIENTS',
  UPSERT_CLIENT_GROUP_FROM_CLIENT_GROUPS = 'UPSERT_CLIENT_GROUP_FROM_CLIENT_GROUPS',
  UPSERT_DISTRIBUTION_SCHEDULE_FULFILLED = 'UPSERT_DISTRIBUTION_SCHEDULE_FULFILLED',
  UPSERT_DISTRIBUTION_SCHEDULE_REQUESTED = 'UPSERT_DISTRIBUTION_SCHEDULE_REQUESTED',
  UPSERT_USER_FULFILLED = 'UPSERT_USER_FULFILLED',
  UPSERT_USER_REQUESTED = 'UPSERT_USER_REQUESTED',
  VERIFY_USER = 'VERIFY_USER',
}

// TODO(whuang): Use export default.
// eslint-disable-next-line
export { ActionType };
