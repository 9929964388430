export function riskToRiskSlider(risk: number) {
  return Math.round(risk * 200);
}

export function riskSliderToRisk(riskSlider: number) {
  return riskSlider / 200;
}

/**
 * This converts useGlidePath, which is defined as an optional pce2 parameter for backwards
 * compatibility purposes, into a strict boolean value based on the default time horizon rules:
 * if the time horizon is below 5 years we turn the glide path off
 * and if the time horizon is 5 or more years we default to turning the glide path on.
 */
export function glidePathValue(
  useGlidePath: boolean | null | undefined,
  timeHorizon: number | null
): boolean {
  if (useGlidePath == null) {
    return timeHorizon != null && timeHorizon >= 5;
  }

  return useGlidePath;
}

export function readableGlidePath(useGlidePath: boolean): string {
  return useGlidePath ? 'On' : 'Off';
}
