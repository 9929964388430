import { Box, Button, Dialog, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridEventListener } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router';
import { BondPortfolioSettings, EngineType } from 'vise-types/portfolio';
import {
  CREDIT_QUALITY_TO_LABEL,
  DURATION_OR_MATURITY_TO_LABEL,
} from '~/routes/BondPortfolio/Utils';
import { mapStateValueToLabel } from '~/routes/BondPortfolioCreator/states';
import Truncation from '~/routes/Portfolio/components/Truncation';
import { ReactComponent as ChevronRightIcon } from '~/static/images/icons/chevron-right.svg';
import DialogTitle from '~/synth/DialogTitle';
import { ProposalSummary } from '../../../models/household';
import { formatPercent, maskAccountNumberIfExists } from '../../../utils/format';

export type ProposalsModalProps = {
  proposals: ProposalSummary[];
  bondProposals: BondPortfolioSettings[];
  open: boolean;
  onClose: () => void;
  accountName: string;
  accountNumber: string;
  viseClientId?: string;
  accountId: string;
  engineType: EngineType;
};

type ProposalsTableProps = {
  proposals: ProposalSummary[];
};

export const ProposalsTable = ({ proposals }: ProposalsTableProps) => {
  const history = useHistory();
  const proposalsTableColumns: GridColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1,
      align: 'left',
      renderCell: ({ value }) => <Truncation>{value}</Truncation> || '-',
    },
    {
      headerName: 'Created on',
      field: 'dateCreated',
      renderCell: ({ value }) => (
        <div>
          <Box display="flex">
            <Box display="flex" mb={0.5}>
              <Box mr={1}>{format(new Date(value), 'MM/dd/yyyy')}</Box>
            </Box>
          </Box>
          <Typography variant="body1" color="textSecondary">
            {format(new Date(value), 'h:mm a')}
          </Typography>
        </div>
      ),
      flex: 1,
    },
    {
      headerName: 'Risk score',
      field: 'riskScore',
      renderCell: ({ value }) => formatPercent(value),
      align: 'right',
      headerAlign: 'right',
      flex: 1,
    },
    {
      headerName: '',
      field: 'id',
      renderCell: () => <ChevronRightIcon />,
      flex: 0.2,
    },
  ];

  const handleEvent: GridEventListener<'rowClick'> = (params) => {
    history.push(`/secure/proposal/${params.id}`);
  };

  return (
    <Box py={1} px={2} height={250}>
      <DataGridPro
        columns={proposalsTableColumns}
        rows={proposals}
        onRowClick={handleEvent}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'dateCreated',
                sort: 'desc',
              },
            ],
          },
        }}
      />
    </Box>
  );
};

export const BondProposalsTable = ({
  bondProposals,
}: {
  bondProposals: BondPortfolioSettings[];
}) => {
  const history = useHistory();
  const proposalsTableColumns: GridColDef[] = [
    {
      headerName: 'Created on',
      field: 'createdAt',
      renderCell: ({ value }) => (
        <div>
          <Box display="flex">
            <Box display="flex" mb={0.5}>
              <Box mr={1}>{format(new Date(value), 'MM/dd/yyyy')}</Box>
            </Box>
          </Box>
          <Typography variant="body1" color="textSecondary">
            {format(new Date(value), 'h:mm a')}
          </Typography>
        </div>
      ),
      flex: 1,
    },
    {
      headerName: 'Bond sector',
      field: 'instrumentType',
      renderCell: ({ value }) =>
        // eslint-disable-next-line no-nested-ternary
        value === 'MUNICIPAL' ? 'Municipal' : value === 'TREASURY' ? 'Treasury' : ('' as never),
      flex: 1,
    },
    {
      headerName: 'Structure',
      field: 'maturity',
      renderCell: ({ value }) => (value != null ? 'Ladder' : 'Target duration'),
      flex: 1,
    },
    {
      headerName: 'Duration',
      field: 'duration',
      renderCell: (props) => DURATION_OR_MATURITY_TO_LABEL[props.value ?? props.row.maturity],
      flex: 1,
    },
    {
      headerName: 'State strategy',
      field: 'stateStrategy',
      renderCell: ({ value }) => mapStateValueToLabel[value],
      flex: 1,
    },
    {
      headerName: 'Credit quality',
      field: 'creditQuality',
      renderCell: ({ value }) => CREDIT_QUALITY_TO_LABEL[value],
      flex: 1,
    },
    {
      headerName: '',
      field: 'id',
      renderCell: () => <ChevronRightIcon />,
      flex: 0.5,
    },
  ];

  const handleEvent: GridEventListener<'rowClick'> = (params) => {
    history.push(`/secure/bond-proposal/${params.id}`);
  };

  return (
    <Box py={1} px={2} height={250}>
      <DataGridPro
        columns={proposalsTableColumns}
        rows={bondProposals}
        onRowClick={handleEvent}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'createdAt',
                sort: 'desc',
              },
            ],
          },
        }}
      />
    </Box>
  );
};

export const ProposalsModalFooter = () => (
  <Box
    p={3}
    sx={{ borderColor: 'grey.200' }}
    border={1}
    borderLeft={0}
    borderRight={0}
    borderBottom={0}
    boxShadow="0px -1px 0px #E9E9E9, 0px -2px 8px rgba(0, 0, 0, 0.04)"
  >
    <Typography variant="body1" color="textSecondary">
      Proposals will expire after 30 days
    </Typography>
  </Box>
);

export const ProposalsModal = ({
  accountId,
  accountName,
  accountNumber,
  viseClientId,
  open,
  onClose,
  proposals,
  bondProposals,
  engineType,
}: ProposalsModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle onClose={onClose}>Proposals</DialogTitle>
      <Box
        py={2}
        px={3}
        sx={{ borderColor: 'grey.200' }}
        border={1}
        borderLeft={0}
        borderRight={0}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex">
          Select a proposal for&nbsp;
          <Truncation>
            {`${accountName ?? '-'} (${maskAccountNumberIfExists(accountNumber)}).`}
          </Truncation>
        </Box>
        <Button
          size="small"
          disabled={!accountNumber}
          variant="contained"
          color="primary"
          href={
            engineType === 'AB_FIXED_INCOME'
              ? `secure/bond-portfolio-creator/select-client`
              : `/secure/portfolio-creator-next?clientId=${viseClientId}&accountId=${accountId}`
          }
          onClick={(event: React.MouseEvent) => {
            // Prevent clicking this link from triggering the `onclick` in the ancestor row
            event.stopPropagation();
          }}
        >
          Create new proposal
        </Button>
      </Box>
      <Box minHeight={300} px={1}>
        {proposals.length > 0 && (
          <Box my={2}>
            <ProposalsTable proposals={proposals} />
          </Box>
        )}
        {bondProposals.length > 0 && (
          <Box my={2}>
            <Typography variant="h4" ml={2} mb={1}>
              Fixed Income
            </Typography>
            <BondProposalsTable bondProposals={bondProposals} />
          </Box>
        )}
      </Box>
      <ProposalsModalFooter />
    </Dialog>
  );
};
