import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '@mui/material';

const Base = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  transition: border-color, box-shadow 0.25s;
`;

export interface CardProps extends BoxProps {
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  selectable?: boolean;
}

/** An unstyled card with active and disabled variants that can be selectable. */
export default function InteractiveContainerBase({
  active = false,
  children,
  className,
  disabled = false,
  onClick,
  selectable = false,
  ...restProps
}: CardProps) {
  function clickIfEnabled() {
    if (!disabled && onClick) {
      onClick();
    }
  }

  function handleKeypress(event: React.KeyboardEvent<HTMLElement>) {
    if (event.currentTarget === event.target && event.key === 'Enter') {
      clickIfEnabled();
    }
  }

  const extraProps = selectable
    ? {
        'aria-disabled': disabled,
        'aria-pressed': active,
        className: `${className} ${disabled ? 'disabled' : ''}`,
        onClick: clickIfEnabled,
        onKeyPress: handleKeypress,
        role: 'button',
        tabIndex: disabled ? undefined : 0,
      }
    : { className };

  return (
    <Base {...extraProps} {...restProps}>
      {children}
    </Base>
  );
}
