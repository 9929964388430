import { useTheme } from '@mui/material';
import React from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  height: 56px;
  left: 0;
  margin-left: ${({ open }: { open: boolean; bgColor: string }) => (open ? '230px' : '57px')};
  position: fixed;
  right: 0;
  top: 0;
  transition: ${({ open }: { open: boolean }) =>
    open
      ? 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
      : 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'};
  width: ${({ open }: { open: boolean }) => (open ? 'calc(100% - 230px)' : 'calc(100% - 57px)')};
  z-index: 1200;
  background-color: ${({ bgColor }: { bgColor: string }) => bgColor};
`;

const Toolbar = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: 100%;
  padding: 0 ${({ theme }) => theme.spacing(3)};
  position: relative;
`;

// eslint-disable-next-line import/prefer-default-export
export const Header: React.FunctionComponent<React.PropsWithChildren<{ open: boolean }>> = ({
  children,
  open,
}) => {
  const bgWhiteMatch = useRouteMatch({
    // Use an all-white background for all paths in this array
    path: [
      '/accounts/:accountId/proposals',
      '/households/:householdId/proposals',
      '/secure/households/:householdId/manage',
      '/secure/households/:householdId/client/:clientId/manage',
      '/secure/strategy-center',
      '/secure/view-template',
      '/secure/distributions',
      '/secure/x-ray',
    ],
  });
  const theme = useTheme();
  const bgColor = bgWhiteMatch ? '#fff' : theme.palette.grey[100];
  return (
    <HeaderContainer open={open} bgColor={bgColor}>
      <Toolbar>{children}</Toolbar>
    </HeaderContainer>
  );
};
