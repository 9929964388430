import { Typography, useTheme } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { MenuProps, OptionProps, OptionTypeBase, Styles, ValueContainerProps } from 'react-select';
import { components } from 'react-windowed-select';
import styled from 'styled-components';
import {
  MultilineCheckbox,
  MultilineSelectorDescription,
  MultilineSelectorFormControlLabel,
  MultilineSelectorTitle,
} from '../MultilineSelector';
import Select, { AsyncSelect, AsyncSelectProps, SelectProps } from './Select';

const StyledMultilineSelectorDescription = styled(MultilineSelectorDescription)`
  margin: ${({ theme }) => theme.spacing(0.5, 0, 0)};
`;

const StyledMultilineSelectorTitle = styled(MultilineSelectorTitle)`
  font-weight: 500;
`;

const SelectAllButtonContainer = styled.div`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
`;

function Option<T extends OptionTypeBase>(props: OptionProps<T>) {
  const { label, data, selectProps, isSelected } = props;

  const searchWords = selectProps.inputValue ? [selectProps.inputValue.trim()] : [];
  return (
    <components.Option {...props}>
      <MultilineSelectorFormControlLabel
        onClick={(e) => e.preventDefault()}
        control={<MultilineCheckbox tabIndex={-1} color="primary" checked={isSelected} />}
        label={
          data.description == null ? (
            <Highlighter
              autoEscape
              highlightTag="strong"
              searchWords={searchWords}
              textToHighlight={label}
            />
          ) : (
            <>
              <StyledMultilineSelectorTitle>{label}</StyledMultilineSelectorTitle>
              <StyledMultilineSelectorDescription>
                <Highlighter
                  autoEscape
                  highlightTag="strong"
                  searchWords={searchWords}
                  textToHighlight={data.description}
                />
              </StyledMultilineSelectorDescription>
            </>
          )
        }
      />
    </components.Option>
  );
}

function ValueContainer<T extends OptionTypeBase>(props: ValueContainerProps<T>) {
  const { children, getValue, selectProps } = props;
  const value = getValue();
  const numSelected = Array.isArray(value) ? value.length : 0;

  if (!children) {
    return null;
  }

  return (
    <components.ValueContainer {...props}>
      {React.cloneElement(children[1])}
      {numSelected > 0 && !(selectProps.isSearchable && selectProps.inputValue) ? (
        <>Selected ({numSelected})</>
      ) : (
        children[0]
      )}
    </components.ValueContainer>
  );
}

function MenuWithSelectAll<T extends OptionTypeBase>(menuProps: MenuProps<T>) {
  const { options, getValue, setValue } = menuProps;
  const optionsLength = options.length;
  const value = getValue();
  const numSelected = Array.isArray(value) ? value.length : 0;
  const isAllSelected = numSelected === optionsLength;
  function handleSelectAllClick() {
    if (isAllSelected) {
      return setValue([], 'set-value');
    }
    return setValue([...options], 'set-value');
  }

  return (
    <components.Menu {...menuProps}>
      <>
        {menuProps.children}
        {/* TODO: Fix - this select all button isn't accessible via keyboard navigation due to
        the default keyboard navigation on the menu. */}
        <div
          role="button"
          tabIndex={-1}
          onClick={(e) => {
            e.stopPropagation();
            handleSelectAllClick();
          }}
          onKeyPress={(event: React.KeyboardEvent<HTMLElement>) => {
            event.stopPropagation();
            if (event.currentTarget === event.target && event.key === 'Enter') {
              handleSelectAllClick();
            }
          }}
        >
          {/* Cast to any here because of erroneous react-select typings; menuProps does in fact have
          all of the props */}
          <SelectAllButtonContainer>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <components.Option {...(menuProps as any as OptionProps<T>)}>
              <MultilineSelectorFormControlLabel
                onClick={(e) => {
                  e.preventDefault();
                }}
                control={
                  <MultilineCheckbox tabIndex={-1} color="primary" checked={isAllSelected} />
                }
                label={
                  <Typography variant="body2">
                    <strong>Select all</strong>
                  </Typography>
                }
              />
            </components.Option>
          </SelectAllButtonContainer>
        </div>
      </>
    </components.Menu>
  );
}

type CustomMultiselectProps = {
  allowSelectAll?: boolean;
};
interface AsyncMultiselectProps<T extends OptionTypeBase>
  extends AsyncSelectProps<T>,
    CustomMultiselectProps {}
interface MultiselectProps<T extends OptionTypeBase>
  extends SelectProps<T>,
    CustomMultiselectProps {}

function useMultiselectProps<T extends OptionTypeBase>({
  allowSelectAll,
  ...props
}: AsyncMultiselectProps<T> | MultiselectProps<T>) {
  const theme = useTheme();
  const customStyles = {
    menuPortal: (provided) => {
      return {
        ...provided,
        zIndex: theme.zIndex.tooltip,
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        maxHeight: 300,
      };
    },
    option: (provided, state) => {
      let background: React.ReactText | undefined;
      if (state.isSelected) {
        background = 'inherit';
        if (state.isFocused) {
          background = state.theme.colors.primary25;
        }
      } else {
        background = provided.background;
      }
      return {
        ...provided,
        padding: theme.spacing(1.5, 1.5),
        background,
        color: state.isSelected ? 'inherit' : provided.background,
        '&:hover': {
          backgroundColor: state.theme.colors.primary25,
          '.CheckboxIcon': {
            boxShadow:
              !state.isDisabled &&
              `inset 0 0 0 1px ${theme.palette.primary.main},
            inset 0 -1px 0 ${theme.palette.primary.main}`,
          },
        },
        '&:active': {
          ...provided[':active'],
          backgroundColor: state.isSelected ? 'inherit' : provided[':active'].backgroundColor,
        },
      };
    },
  } as Styles;

  return {
    components: {
      Option,
      ...(allowSelectAll ? { Menu: MenuWithSelectAll } : {}),
      ValueContainer,
      ...props.components,
    },
    isMulti: true,
    controlShouldRenderValue: false,
    isSearchable: false,
    closeMenuOnSelect: false,
    hideSelectedOptions: false,
    ...omit(props, ['components', 'styles']),
    styles: { ...customStyles, ...(props.styles || {}) },
  };
}

function Multiselect<T extends OptionTypeBase>(props: MultiselectProps<T>) {
  if (
    process.env.NODE_ENV === 'development' &&
    !isEmpty(props.options) &&
    props.options &&
    'options' in props.options[0] &&
    props.allowSelectAll
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      `Should not set allowSelectAll to true in a multi object type dropdown. "Select all" button will not work properly due to grouped option structure.`
    );
  }
  const newProps: MultiselectProps<T> = useMultiselectProps(props);
  return <Select<T> {...newProps} />;
}

export function AsyncMultiselect<T extends OptionTypeBase>(props: AsyncMultiselectProps<T>) {
  if (
    process.env.NODE_ENV === 'development' &&
    !isEmpty(props.options) &&
    props.options &&
    'options' in props.options[0] &&
    props.allowSelectAll
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      `Should not set allowSelectAll to true in a multi object type dropdown. "Select all" button will not work properly due to grouped option structure.`
    );
  }
  const newProps = useMultiselectProps(props) as AsyncSelectProps<T>;
  return <AsyncSelect<T> {...newProps} />;
}

export default Multiselect;
