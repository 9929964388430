import React, { useState } from 'react';
import { deleteHousehold } from '~/api/api';
import useEnqueueToast from '~/hooks/useToast';
import amplitude from '~/utils/amplitude';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import DeleteItemModal from './DeleteItemModal';

interface DeleteHouseholdModalContainerProps {
  householdId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const DeleteHouseholdModalContainer = ({
  isOpen,
  onClose,
  householdId,
  onSuccess,
}: DeleteHouseholdModalContainerProps) => {
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const enqueueToast = useEnqueueToast();

  return (
    <DeleteItemModal
      title="Delete this household?"
      content="Deleting this household will delete clients, accounts, proposals and executed
    portfolios in the household."
      confirmButtonContent="Yes, delete household"
      isLoading={isSendingRequest}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={async () => {
        amplitude().logEvent('Tap confirm delete household', {
          category: EVENT_CATEGORIES.HOUSEHOLDS,
          householdId,
        });
        setIsSendingRequest(true);
        try {
          await deleteHousehold(householdId);
        } catch (error) {
          onClose();
          enqueueToast({
            title: 'Error deleting household',
            severity: 'error',
          });
          return;
        } finally {
          setIsSendingRequest(false);
        }
        onSuccess();
      }}
    />
  );
};

export default DeleteHouseholdModalContainer;
