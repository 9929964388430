import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router';
import DialogTitle from '~/synth/DialogTitle';
import useUser from '~/hooks/useUser';
import PsaSignedImage from '~/static/images/onboarding/psa.svg';
import { esignPlatformServiceAgreement } from '~/api/api';
import LoadingSpinner from '~/synth/LoadingSpinner';
import { ProductOnboardingRoutes } from './routes';

const PSA_REQUEST_ID = 'psaRequestId';

export default function SignPsaDialog({
  alreadySigned,
  ...props
}: DialogProps & { alreadySigned: boolean }) {
  const shouldAbortDocuSignRequest = useRef(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { data: userData } = useUser(false, true);

  const navigateBack = useCallback(() => {
    shouldAbortDocuSignRequest.current = true;
    history.replace(`${ProductOnboardingRoutes.psa}`);
  }, [history]);

  useEffect(() => {
    // Check if we're here after being redirected from DocuSign.
    const { searchParams, pathname } = new URL(window.location.href);
    const docuSignEvent = searchParams.get('event');
    const returnedPsaRequestId = pathname.split('/').pop();

    const psaSignSuccess =
      docuSignEvent === 'signing_complete' &&
      returnedPsaRequestId === window.sessionStorage.getItem(PSA_REQUEST_ID);

    if (psaSignSuccess) {
      esignPlatformServiceAgreement({
        userId: userData?.id as string,
        signed: true,
      });
      window.sessionStorage.removeItem(PSA_REQUEST_ID);
    }
  }, [userData?.id]);

  const handleSignButtonClick = async () => {
    setIsLoading(true);
    try {
      const { pathname } = history.location;
      // Prevent signatory impersonation by creating a custom request URL
      // to set the PSA as signed in the backend later on.
      const psaRequestId = uuidv4();
      const returnPath = pathname.startsWith('/') ? pathname.replace('/', '') : pathname;

      const { redirectUrl } = await esignPlatformServiceAgreement({
        userId: userData?.id as string,
        returnPath: `${returnPath}/${psaRequestId}`,
      });

      if (!shouldAbortDocuSignRequest.current) {
        window.sessionStorage.setItem(PSA_REQUEST_ID, psaRequestId);
        window.location.href = redirectUrl;

        // Allow for retries if DocuSign takes too long to respond.
        setTimeout(() => {
          setIsLoading(false);
        }, 20_000);
      }
    } catch (err) {
      setError(true);
      setIsLoading(false);
    } finally {
      // Intentionally not setting isLoading to false here since the user will
      // be redirected to DocuSign anyways, and doing so would just create
      // an unwanted flickering on the UI while the redirection happens.
    }
  };

  if (!userData || error) {
    return (
      <Dialog {...props}>
        <DialogTitle onClose={navigateBack}>We&apos;ve encountered an error</DialogTitle>
        <DialogContent>There was an error. Please try again later.</DialogContent>
      </Dialog>
    );
  }

  if (isLoading) {
    return (
      <Dialog {...props}>
        <DialogTitle onClose={navigateBack}>Redirecting to DocuSign...</DialogTitle>
        <Box mt={9} mb={12}>
          <LoadingSpinner />
          <Typography color="text.secondary" variant="body2" textAlign="center" mt={2}>
            Please wait...
          </Typography>
        </Box>
      </Dialog>
    );
  }

  if (alreadySigned) {
    return (
      <Dialog {...props}>
        <DialogTitle onClose={navigateBack} />
        <DialogContent sx={{ textAlign: 'center' }}>
          <Box mt={2} mb={3}>
            <img src={PsaSignedImage} style={{ width: 200 }} alt="PSA signed" />
          </Box>
          <Typography variant="h2">Platform & Sub-Advisory Agreement Signed!</Typography>
          <Typography color="text.secondary" variant="body2" mt={2}>
            Congratulations on signing the Vise PSA document. <br />
            We are delighted to have you on board.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="contained" onClick={navigateBack}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <Dialog {...props}>
      <DialogTitle onClose={navigateBack}>Sign Platform & Sub-Advisory Agreement</DialogTitle>
      <DialogContent>
        <Box mt={2} mb={2}>
          <Box>
            <p>
              To finish your Vise platform registration, you&apos;ll need to review and sign a
              Platform & Sub-Advisory Agreement. By clicking the button below, you&apos;ll go to a
              DocuSign document to review and sign.
            </p>
            <p>After signing, you will be redirected back to this page.</p>
          </Box>
          <Alert severity="info" style={{ maxWidth: '100%', width: '100%' }}>
            Please note that only one authorized signatory is required to represent the entity /
            firm.
          </Alert>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={navigateBack} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSignButtonClick} disabled={isLoading}>
          Review & sign
        </Button>
      </DialogActions>
    </Dialog>
  );
}
