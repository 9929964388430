import { Box, Button, Card, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { ReactComponent as CheckCircleIcon } from '~/static/images/icons/check-circle.svg';
import Banner from '~/synth/Banner';
import CardContent from '~/synth/CardContent';
import CardHeader from '~/synth/CardHeader';

export default function LiquidateAccountConfirmation() {
  const { accountId } = useParams<{ accountId: string }>();

  const history = useHistory();
  const theme = useTheme();

  async function handleSubmit() {
    history.push(`/secure/accounts/${accountId}/portfolio/overview`);
  }

  async function handleRemoveFromVise() {
    return history.push(`/secure/client/accounts/${accountId}/remove`);
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box m={3} mb={15}>
          <Grid item lg={12}>
            <Box margin="auto" maxWidth="1280px" pt={3} pb={3}>
              <Banner
                size="small"
                bgColor="success.100"
                borderColor="success.400"
                message={
                  <Box>
                    <Typography variant="h4">
                      <Box display="flex" alignItems="center">
                        <CheckCircleIcon /> <Box ml={1}>Liquidation request has been submitted</Box>
                      </Box>
                    </Typography>
                    <Box mt={1.5} color="grey.700">
                      Your liquidation request has been submitted and the rebalancer has been
                      paused. Liquidation requests received after 2 PM EST/EDT may not be processed
                      until the following business day. Client Service team has been notified. A
                      team member will reach out to you within one business day.
                    </Box>
                  </Box>
                }
              />
              <Card sx={{ mt: 5 }}>
                <CardHeader>
                  <Typography variant="h4">Account liquidation has been initiated</Typography>
                </CardHeader>
                <CardContent display="flex">
                  <Box display="flex" width="100%" justifyContent="space-between" mb={0.25}>
                    Trades associated with this account liquidation will be reflected within the
                    next business day.
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Box>

        <Box
          display="flex"
          position="absolute"
          width="-webkit-calc(100% - 230px)"
          bottom={0}
          py={3}
          justifyContent="flex-end"
          px={3}
          bgcolor="white"
          borderTop={`solid 1px ${theme.palette.grey[200]}`}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1.5}>
              <Button variant="outlined" color="secondary" onClick={handleRemoveFromVise}>
                Remove Account from Vise
              </Button>
            </Box>
            <Button variant="contained" color="primary" type="submit">
              Finish
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
}
