import { Box, Card } from '@mui/material';
import React from 'react';
import CardHeader from '~/synth/CardHeader';
import Skeleton from '~/synth/Skeleton';

const LoadingCard = ({ contentHeight }: { contentHeight: string }) => {
  return (
    <Card>
      <CardHeader>
        <Box height="80px">
          <Skeleton variant="rect" width="100%" height="100%" />
        </Box>
      </CardHeader>
      <Box height={contentHeight} p={3}>
        <Skeleton variant="rect" width="100%" height="100%" />
      </Box>
    </Card>
  );
};

export default LoadingCard;
