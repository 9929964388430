import { Box, Grid, InputAdornment, Tooltip, Typography } from '@mui/material';
import { DebouncedFunc } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import SelectorCheckbox from '~/synth/SelectorCheckbox';
import TextField from '~/synth/TextField';
import {
  ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP,
  EQUITIES_ASSET_CLASS_KEY,
  FIXED_INCOME_ASSET_CLASS_KEY,
  ALTERNATIVES_ASSET_CLASS_KEY,
} from '../../Constants';

interface MaxConcentrationLimitSectionProps {
  commitValues: DebouncedFunc<(nextValues: FormValues) => void>;
  control: Control<FormValues>;
  exclusions: string[];
  disabled?: boolean;
  allocations: { [key in AssetClassKey]?: number };
}

export interface FormValues {
  applyConcentrationLimits: boolean;
  equitiesConcentrationLimit: '' | number;
  fixedIncomeConcentrationLimit: '' | number;
  alternativesConcentrationLimit: '' | number;
  // For sharing between PCE2AssetClassScreen and SpecialConditionsScreen
  // This component will eventually be removed from PCE2AssetClassScreen
  timeHorizon?: number | null;
}

export default function MaxConcentrationLimitSection({
  commitValues,
  control,
  exclusions,
  disabled,
  allocations,
}: MaxConcentrationLimitSectionProps) {
  const exclusionsSet = new Set(exclusions);

  const isEquitiesDisabled = [
    EQUITIES_ASSET_CLASS_KEY,
    ...(ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get(EQUITIES_ASSET_CLASS_KEY) ?? []),
  ].every((assetClassKey) => exclusionsSet.has(assetClassKey));

  const isFixedIncomeDisabled = [
    FIXED_INCOME_ASSET_CLASS_KEY,
    ...(ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get(FIXED_INCOME_ASSET_CLASS_KEY) ?? []),
  ].every((assetClassKey) => exclusionsSet.has(assetClassKey));

  const isAlternativesDisabled = [
    ALTERNATIVES_ASSET_CLASS_KEY,
    ...(ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get(ALTERNATIVES_ASSET_CLASS_KEY) ?? []),
  ].every((assetClassKey) => exclusionsSet.has(assetClassKey));

  const applyConcentrationLimitState = useWatch({ control, name: 'applyConcentrationLimits' });
  const equitiesConcentrationLimitState = useWatch({ control, name: 'equitiesConcentrationLimit' });
  const fixedIncomeConcentrationLimitState = useWatch({
    control,
    name: 'fixedIncomeConcentrationLimit',
  });
  const alternativesConcentrationLimitState = useWatch({
    control,
    name: 'alternativesConcentrationLimit',
  });

  useEffect(() => {
    commitValues({
      applyConcentrationLimits: applyConcentrationLimitState,
      equitiesConcentrationLimit: equitiesConcentrationLimitState,
      fixedIncomeConcentrationLimit: fixedIncomeConcentrationLimitState,
      alternativesConcentrationLimit: alternativesConcentrationLimitState,
    });
  }, [
    applyConcentrationLimitState,
    equitiesConcentrationLimitState,
    fixedIncomeConcentrationLimitState,
    alternativesConcentrationLimitState,
    commitValues,
  ]);

  const generateHandleOnConcentrationLimitChange = useCallback(
    (onChange) => (e) => {
      const { value } = e.target;
      if (value === '') {
        onChange(value);
      }

      const numVal = parseInt(value, 10);
      if (!Number.isNaN(numVal) && numVal >= 0 && numVal <= 100) {
        onChange(numVal);
      }
    },
    []
  );

  const maxEquityThreshold = Math.floor(
    allocations.EQUITY ? allocations.EQUITY * 10 + allocations.EQUITY * 100 : 0
  );
  const maxFixedThreshold = Math.floor(
    allocations.FIXED_INCOME ? allocations.FIXED_INCOME * 10 + allocations.FIXED_INCOME * 100 : 0
  );
  const maxAlternativesThreshold = Math.floor(
    allocations.ALTERNATIVES ? allocations.ALTERNATIVES * 10 + allocations.ALTERNATIVES * 100 : 0
  );

  return (
    <>
      <Controller
        control={control}
        name="applyConcentrationLimits"
        render={({ field: { onChange, value, name } }) => (
          <SelectorCheckbox
            onChange={onChange}
            disabled={disabled}
            name={name}
            checked={value as boolean}
            label="Set asset class concentration limits"
          />
        )}
      />
      {disabled && (
        <Box mt={1}>
          <Typography variant="body1" color="textSecondary">
            Portfolios with a glide path cannot have asset class limits because the glide path will
            update the portfolio&apos;s allocation over time.
          </Typography>
        </Box>
      )}
      {applyConcentrationLimitState && (
        <>
          <Box color="grey.500" mb={3}>
            Please note that introducing asset class concentration limits may change the target
            allocation of the portfolio. Maximum capital gains limits may be exceeded to respect
            asset class concentration limits.
          </Box>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={4}>
              <Tooltip
                disableHoverListener={allocations.EQUITY !== 0}
                disableFocusListener={allocations.EQUITY !== 0}
                placement="bottom"
                title="Concentration limits for this asset class are unavailable since its allocation is 0%"
              >
                <span>
                  <Controller
                    control={control}
                    name="equitiesConcentrationLimit"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        disabled={isEquitiesDisabled || !allocations.EQUITY}
                        error={fieldState.invalid}
                        helperText={
                          fieldState.invalid && `must be atleast ${maxEquityThreshold.toFixed(1)}%`
                        }
                        fullWidth
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        id="equitiesConcentrationLimit"
                        label="Max Equities"
                        onChange={generateHandleOnConcentrationLimitChange(field.onChange)}
                      />
                    )}
                    rules={{
                      min: maxEquityThreshold,
                    }}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                placement="bottom"
                disableHoverListener={allocations.FIXED_INCOME !== 0}
                disableFocusListener={allocations.FIXED_INCOME !== 0}
                title="Concentration limits for this asset class are unavailable since its allocation is 0%"
              >
                <span>
                  <Controller
                    control={control}
                    name="fixedIncomeConcentrationLimit"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        disabled={isFixedIncomeDisabled || !allocations.FIXED_INCOME}
                        error={fieldState.invalid}
                        helperText={
                          fieldState.invalid && `must be atleast ${maxFixedThreshold.toFixed(1)}%`
                        }
                        fullWidth
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        id="fixedIncomeConcentrationLimit"
                        label="Max Fixed Income"
                        onChange={generateHandleOnConcentrationLimitChange(field.onChange)}
                      />
                    )}
                    rules={{
                      min: maxFixedThreshold,
                    }}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                placement="bottom"
                disableHoverListener={allocations.ALTERNATIVES !== 0}
                disableFocusListener={allocations.ALTERNATIVES !== 0}
                title="Concentration limits for this asset class are unavailable since its allocation is 0%"
              >
                <span>
                  <Controller
                    control={control}
                    name="alternativesConcentrationLimit"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        disabled={isAlternativesDisabled || !allocations.ALTERNATIVES}
                        error={fieldState.invalid}
                        helperText={
                          fieldState.invalid &&
                          `must be atleast ${maxAlternativesThreshold.toFixed(1)}%`
                        }
                        fullWidth
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        id="alternativesConcentrationLimit"
                        label="Max Alternatives"
                        onChange={generateHandleOnConcentrationLimitChange(field.onChange)}
                      />
                    )}
                    rules={{
                      min: maxAlternativesThreshold,
                    }}
                  />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
