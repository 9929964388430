import useSWR from 'swr';
import { getBondPortfolioData } from '~/api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useBondPortfolioData(params?: Parameters<typeof getBondPortfolioData>[0]) {
  const { portfolioId, accountId } = params || {};
  let key: string | null = null;

  if (portfolioId) {
    key = generateSwrKey(`/bond-portfolio/data/${new URLSearchParams({ portfolioId }).toString()}`);
  } else if (accountId) {
    key = generateSwrKey(`/bond-portfolio/data/${new URLSearchParams({ accountId }).toString()}`);
  }

  const fetcher = params != null ? () => getBondPortfolioData(params) : null;

  return useSWR(key, fetcher, {
    // See https://swr.vercel.app/docs/error-handling#error-retry
    onErrorRetry(err, _key, _config, revalidate, { retryCount }) {
      if (err.status === 404) {
        // API will respond with 404 when the data isn't available yet. Don't retry
        return;
      }

      // Retry after 5 seconds.
      setTimeout(() => revalidate({ retryCount }), 5000);
    },
  });
}
