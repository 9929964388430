import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  Divider,
  FormControlLabel,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { Column } from 'react-table';
import { DataTable } from '~/synth/DataTable';
import DialogTitle from '~/synth/DialogTitle';
import { TextHighlightTag } from '~/synth/Tag';

export type Action = 'UNLINK' | 'LINK' | 'UPDATE' | 'REMAIN' | 'BULK_UPDATE';

function ActionCell({ value }: { value: Action }) {
  let severity: 'warning' | 'success' | 'error' = 'success';
  let text = '';
  switch (value) {
    case 'UNLINK': {
      severity = 'error';
      text = 'Unlink';
      break;
    }
    case 'LINK': {
      severity = 'success';
      text = 'Link';
      break;
    }
    case 'UPDATE': {
      severity = 'success';
      text = 'Update to latest version';
      break;
    }
    case 'REMAIN': {
      severity = 'warning';
      text = 'Do not update';
      break;
    }
    case 'BULK_UPDATE': {
      severity = 'success';
      text = 'Update 2024 Capital gains budget';
      break;
    }
    default:
      break;
  }
  return <TextHighlightTag severity={severity}>{text}</TextHighlightTag>;
}

const executeColumn: Column<{ name: string; action: Action }>[] = [
  {
    Header: 'Account',
    accessor: 'name',
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: ActionCell,
  },
];

export default function ExecuteDialog({
  open,
  onClose,
  linkUnlinkData,
  onSubmit,
  requireConfirmation,
}: {
  open: boolean;
  onClose: () => void;
  linkUnlinkData: {
    name: string;
    action: Action;
  }[];
  onSubmit: () => void;
  requireConfirmation?: boolean;
}) {
  const theme = useTheme();
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const numberOfAccountsToExecute = linkUnlinkData.filter((row) => row.action !== 'REMAIN').length;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Save & execute changes?</DialogTitle>
      <Box
        border={`solid 1px ${theme.palette.grey[300]}`}
        borderRadius="4px"
        mx={2}
        maxHeight="306px"
        overflow="auto"
      >
        <DataTable columns={executeColumn} data={linkUnlinkData} m={0} />
      </Box>
      {requireConfirmation && numberOfAccountsToExecute > 0 && (
        <Box mt={3} border={`solid 1px ${theme.palette.grey[300]}`} p={3} mx={2} borderRadius="4px">
          <FormControlLabel
            label={`I understand that saving this update may result in trades across ${numberOfAccountsToExecute} account${
              numberOfAccountsToExecute ? '' : 's'
            }.`}
            control={<Checkbox checked={confirmed} onClick={() => setConfirmed(!confirmed)} />}
          />
        </Box>
      )}
      <Box mt={3}>
        <Divider />
      </Box>
      <DialogActions>
        <Button color="secondary" onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={!confirmed && requireConfirmation && numberOfAccountsToExecute > 0}
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          Save & execute changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
