import React from 'react';
import GPTPerformanceChart from './GPTPerformanceChart';
import GPTAssetAllocationChart from './GPTAllocationChart';
import GPTPortfolioInsightsCard from './GPTPortfolioInsightsCard';
import GPTSectorAllocationChart from './GPTSectorAllocationCard';
import GPTPortfolioHoldingsCard from './GPTPortfolioHoldingsCard';
import GPTTradingActivityCard from './GPTTradingActivityCard';
import GPTGeoAllocationMapCard from './GPTGeoAllocationMapCard';
import GPTDistributionsCard from './GPTDistributionsCard';

// GPT generates tags that look like this: <${tagname} account="1234" />, this specifies which tags we parse and replace with visuals
export const VISUAL_TAG_NAMES = [
  'performance',
  'allocations',
  'insights',
  'sectors',
  'holdings',
  'trades',
  'countries',
  'distributions',
];

const renderVisual = (text) => {
  const result = VISUAL_TAG_NAMES.find((tag) => {
    const patternString = `<${tag}\\s+([^>]+?)\\s*(?:\\/>|>)`;
    const pattern = new RegExp(patternString, 'g');
    const match = pattern.exec(text);
    if (!match) {
      return false;
    }
    return true;
  });

  if (!result) {
    return undefined;
  }

  const patternString = `<${result}\\s+([^>]+?)\\s*(?:\\/>|>)`;
  const pattern = new RegExp(patternString, 'g');
  const match = pattern.exec(text);

  if (!match) {
    return undefined;
  }

  const attributes = match[1];
  const accountMatches = /account="([^"]*)"/.exec(attributes);
  let accountId;
  if (accountMatches !== null) {
    // eslint-disable-next-line prefer-destructuring
    accountId = accountMatches[1];
  }

  switch (result) {
    case 'performance':
      return <GPTPerformanceChart accountId={accountId} />;
    case 'allocations':
      return <GPTAssetAllocationChart accountId={accountId} />;
    case 'insights':
      return <GPTPortfolioInsightsCard accountId={accountId} />;
    case 'sectors':
      return <GPTSectorAllocationChart accountId={accountId} />;
    case 'holdings':
      return <GPTPortfolioHoldingsCard accountId={accountId} />;
    case 'trades':
      return <GPTTradingActivityCard accountId={accountId} />;
    case 'countries':
      return <GPTGeoAllocationMapCard accountId={accountId} />;
    case 'distributions':
      return <GPTDistributionsCard accountId={accountId} />;
    default:
      return undefined;
  }
};

const GPTVisualCard = ({ text }: { text: string }) => {
  const element = renderVisual(text);

  // TODO(mkiisa): need to add an error state (loading state should be handled by each individual visual)
  if (!element) {
    return null;
  }
  return element;
};

export default GPTVisualCard;
