import {
  Box,
  Button,
  Checkbox as CheckboxBase,
  DialogActions,
  DialogContent as DialogContentBase,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { withStyles } from 'tss-react/mui';

import amplitude from '~/utils/amplitude';

import React, { useState } from 'react';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import { getAssetAllocation } from '~/api/api';
import DialogTitle from '~/synth/DialogTitle';
import conservativeAllocationIcon from '~/static/images/PortfolioCreator2/conservative-allocation.svg';
import aggressiveAllocationIcon from '~/static/images/PortfolioCreator2/aggressive-allocation.svg';
import moderatelyConservativeAllocationIcon from '~/static/images/PortfolioCreator2/moderately-conservative-allocation.svg';
import moderateAllocationIcon from '~/static/images/PortfolioCreator2/moderate-allocation.svg';
import moderatelyAggressiveAllocationIcon from '~/static/images/PortfolioCreator2/moderately-aggressive-allocation.svg';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import HelpDialog from '../HelpDialog';

const DEFUALT_ALTERNATIVES_ALLOCATION = 0.05;

type SelectorValues =
  | 'conservative'
  | 'aggressive'
  | 'moderately-conservative'
  | 'moderate'
  | 'moderately-aggressive';

const config = [
  {
    value: 'conservative',
    title: 'Conservative',
    description: '30% Equities, 70% Fixed Income',
    image: conservativeAllocationIcon,
  },
  {
    value: 'moderately-conservative',
    title: 'Moderately conservative',
    description: '50% Equities, 50% Fixed Income',
    image: moderatelyConservativeAllocationIcon,
  },
  {
    value: 'moderate',
    title: 'Moderate',
    description: '70% Equities, 30% Fixed Income',
    image: moderateAllocationIcon,
  },
  {
    value: 'moderately-aggressive',
    title: 'Moderately aggressive',
    description: '85% Equities, 15% Fixed Income',
    image: moderatelyAggressiveAllocationIcon,
  },
  {
    value: 'aggressive',
    title: 'Aggressive',
    description: '100% Equities',
    image: aggressiveAllocationIcon,
  },
];

const configWithAlternatives = [
  {
    value: 'conservative',
    title: 'Conservative',
    description: '28.5% Equities, 66.5% Fixed Income, 5% Alternatives',
    image: conservativeAllocationIcon,
  },
  {
    value: 'moderately-conservative',
    title: 'Moderately conservative',
    description: '47.5% Equities, 47.5% Fixed Income, 5% Alternatives',
    image: moderatelyConservativeAllocationIcon,
  },
  {
    value: 'moderate',
    title: 'Moderate',
    description: '66.5% Equities, 28.5% Fixed Income, 5% Alternatives',
    image: moderateAllocationIcon,
  },
  {
    value: 'moderately-aggressive',
    title: 'Moderately aggressive',
    description: '80.8% Equities, 14.2% Fixed Income, 5% Alternatives',
    image: moderatelyAggressiveAllocationIcon,
  },
  {
    value: 'aggressive',
    title: 'Aggressive',
    description: '95% Equities, 5% Alternatives',
    image: aggressiveAllocationIcon,
  },
];

function selectorValueToSplit(split: SelectorValues) {
  switch (split) {
    case 'conservative':
      return { equityAllocation: 0.3, fixedIncomeAllocation: 0.7, alternatives: 0 };
    case 'aggressive':
      return { equityAllocation: 1, fixedIncomeAllocation: 0, alternatives: 0 };
    case 'moderately-conservative':
      return { equityAllocation: 0.5, fixedIncomeAllocation: 0.5, alternatives: 0 };
    case 'moderate':
      return { equityAllocation: 0.7, fixedIncomeAllocation: 0.3, alternatives: 0 };
    case 'moderately-aggressive':
      return { equityAllocation: 0.85, fixedIncomeAllocation: 0.15, alternatives: 0 };
    default:
      throw new Error('Unknown allocation');
  }
}

const Checkbox = withStyles(CheckboxBase, {
  root: {
    padding: 0,
  },
});

const DialogContent = withStyles(DialogContentBase, {
  dividers: {
    borderTopWidth: 0,
  },
  root: {
    padding: '0 24px',
  },
});

export default function ChooseAllocationModal({
  open,
  onClose: onCloseProp,
  onLoadAllocation,
  taxable,
}: {
  open: boolean;
  onClose: () => void;
  onLoadAllocation: (
    alloc: { [key in AssetClassKey]?: number },
    split: ReturnType<typeof selectorValueToSplit>
  ) => void;
  taxable: boolean;
}) {
  const [value, setValue] = useState<SelectorValues | null>(null);
  const [addAlternatives, setAddAlternatives] = useState<boolean>(false);
  const [allocationLoading, setAllocationLoading] = useState(false);
  const onClose = () => {
    setAllocationLoading(false);
    onCloseProp();
  };
  return (
    <HelpDialog open={open} onClose={onClose} data-cy="choose-a-portfolio-split-modal">
      <DialogTitle onClose={onClose}>Choosing a portfolio split</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Typography variant="h4">What is your client&apos;s risk tolerance?</Typography>
        </Box>
        <RadioGroup
          value={value}
          onChange={(e, newValue) => {
            amplitude().logEvent(`Select preset value: ${newValue}`, {
              category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
            });
            setValue(newValue as SelectorValues);
          }}
        >
          {(addAlternatives ? configWithAlternatives : config).map(
            ({ value, title, description, image }) => (
              <Box mb={3} key={value}>
                <FormControlLabel
                  data-cy={value}
                  control={<Radio />}
                  value={value}
                  label={
                    <Box display="flex" alignItems="center">
                      <img height={40} width={40} alt="" src={image} />
                      <Box ml={2}>
                        <Box mb={0.5}>
                          <Typography variant="h5">{title}</Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                          {description}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </Box>
            )
          )}
        </RadioGroup>
        <Divider />
        <Box mt={3} mb={2}>
          <Typography variant="h4">Is your client interested in Alternatives?</Typography>
        </Box>
        <Box display="flex">
          <Checkbox
            checked={addAlternatives}
            onChange={(e, checked) => {
              amplitude().logEvent(
                `${checked ? 'Select' : 'Deselect'} alternatives in preset allocations modal`,
                { category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION }
              );
              setAddAlternatives(checked);
            }}
          />
          <Box ml={1}>
            <Typography variant="body1">Yes, add Alternatives to portfolio.</Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={allocationLoading} onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          data-cy="apply-split-button"
          disabled={value == null || allocationLoading}
          variant="contained"
          color="primary"
          onClick={async () => {
            if (value == null) {
              return;
            }
            setAllocationLoading(true);
            try {
              const split = selectorValueToSplit(value);
              if (addAlternatives) {
                split.equityAllocation *= 1 - DEFUALT_ALTERNATIVES_ALLOCATION;
                split.fixedIncomeAllocation *= 1 - DEFUALT_ALTERNATIVES_ALLOCATION;
                split.alternatives = DEFUALT_ALTERNATIVES_ALLOCATION;
              }
              const { data: allocations } = await getAssetAllocation({
                assetAllocation: {
                  EQUITY: split.equityAllocation,
                  FIXED_INCOME: split.fixedIncomeAllocation,
                  ALTERNATIVES: addAlternatives ? DEFUALT_ALTERNATIVES_ALLOCATION : 0,
                  'FIXED_INCOME/DOMESTIC/MUNICIPAL': taxable ? undefined : 0,
                },
              });
              onLoadAllocation(allocations as { [key in AssetClassKey]?: number }, split);
              onClose();
            } catch (e) {
              setAllocationLoading(false);
            }
          }}
        >
          Apply recommended split
        </Button>
      </DialogActions>
    </HelpDialog>
  );
}
