import React, { ReactNode, Reducer } from 'react';
import {
  Account,
  BondPortfolioCreditQuality,
  BondPortfolioDuration,
  BondPortfolioMuniMaturity,
  BondPortfolioTreasuryMaturity,
  BondPortfolioStructure,
  BondPortfolioSettings,
  BondPortfolioStateStrategy,
  BondPortfolioData,
  BondInstrumentType,
} from 'vise-types/portfolio';
import { RawClient } from '~/models/api';

export type Taxes = {
  estimatedIncome: string;
  taxFillingStatus: string;
  state: string;
  isAlternativeMinimumTax: 'true' | 'false' | '';
  knowTaxRate: 'true' | 'false' | '';
  estimatedFederalRate: string;
  estimatedStateRate: string;
};

export const statesStrategyArray = [
  'NATIONAL',
  'STATE_AL',
  'STATE_AZ',
  'STATE_CA',
  'STATE_CO',
  'STATE_CT',
  'STATE_GA',
  'STATE_IN',
  'STATE_MD',
  'STATE_MA',
  'STATE_MI',
  'STATE_MO',
  'STATE_NJ',
  'STATE_NY',
  'STATE_NC',
  'STATE_OH',
  'STATE_OR',
  'STATE_PA',
  'STATE_TX',
  'STATE_VA',
] as const;

export type BuildInputs = {
  bondSector: BondInstrumentType | '';
  stateStrategy: BondPortfolioStateStrategy | '';
  structure: BondPortfolioStructure | '';
  duration: BondPortfolioDuration | '';
  maturity: BondPortfolioMuniMaturity | BondPortfolioTreasuryMaturity | '';
  creditQuality: BondPortfolioCreditQuality | '';
  activeTaxManagement: boolean;
};

export type State = {
  client?: RawClient | null;
  taxes: Taxes;
  account: Account | null;
  buildInputs: BuildInputs;
  cashValue: number | null;
  existingProposalId?: string;
  sampleData: BondPortfolioData | null;
  isLoading: boolean;
};

export type Action =
  | {
      type: 'SET_CLIENT';
      value: State['client'];
    }
  | { type: 'SELECT_TAX_INFORMATION'; value: State['taxes'] }
  | { type: 'SET_ACCOUNT'; value: State['account'] }
  | { type: 'SET_CASH_VALUE'; value: State['cashValue'] }
  | { type: 'SET_BUILD_INPUTS'; value: State['buildInputs'] }
  | { type: 'SET_EXISTING_PROPOSAL_ID'; value: State['existingProposalId'] }
  | { type: 'SET_SAMPLE_DATA'; value: State['sampleData'] }
  | { type: 'SET_IS_LOADING'; value: State['isLoading'] };

export const initialState: State = {
  client: null,
  taxes: {
    estimatedIncome: '',
    isAlternativeMinimumTax: '',
    taxFillingStatus: '',
    state: '',
    estimatedFederalRate: '',
    estimatedStateRate: '',
    knowTaxRate: '',
  },
  buildInputs: {
    creditQuality: '',
    duration: '',
    maturity: '',
    structure: '',
    stateStrategy: '',
    bondSector: '',
    activeTaxManagement: true,
  },
  account: null,
  cashValue: null,
  existingProposalId: undefined,
  sampleData: null,
  isLoading: false,
};

export type BondScreenProps = {
  state: State;
  dispatch: React.Dispatch<Action>;
  footer?: React.FC<ReactNode>;
  summaryPanelRef?: React.RefObject<HTMLElement> | null;
};

export const reducer: Reducer<typeof initialState, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_CLIENT':
      return { ...state, client: action.value };
    case 'SET_ACCOUNT':
      return { ...state, account: action.value };
    case 'SET_CASH_VALUE':
      return { ...state, cashValue: action.value };
    case 'SELECT_TAX_INFORMATION':
      return { ...state, taxes: action.value };
    case 'SET_BUILD_INPUTS':
      return { ...state, buildInputs: action.value };
    case 'SET_EXISTING_PROPOSAL_ID':
      return { ...state, existingProposalId: action.value };
    case 'SET_SAMPLE_DATA':
      return { ...state, sampleData: action.value };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.value };
    default:
      return state;
  }
};

/**
 * bondPortfolioStateToSettings translates the given UI state to the request body for
 * creating a bond portfolio settings.
 *
 * NOTE that estimatedStateRate & estimatedFederalRate are string percentages, e.g.,
 * 25 & 5 for 25% and 5% respectively. These are converted to decimals upon storing in
 * the BondPortfolioSettings: 25% -> .25 and 5% -> 0.05.
 */
export function bondPortfolioStateToSettings(state: State): BondPortfolioSettings {
  const { buildInputs, taxes, account, client, cashValue } = state;
  const { bondSector, creditQuality, duration, maturity, activeTaxManagement, stateStrategy } =
    buildInputs;
  const { isAlternativeMinimumTax, estimatedFederalRate, estimatedStateRate } = taxes;

  if (bondSector === 'MUNICIPAL' && creditQuality === '') {
    throw new Error('Municipal input creditQuality is empty');
  }

  if (bondSector === 'MUNICIPAL' && stateStrategy === '') {
    throw new Error('Municipla input stateStrategy is empty');
  }

  if (maturity === '' && duration === '') {
    throw new Error('maturity and duration are both empty');
  }

  return {
    instrumentType: bondSector,
    // eslint-disable-next-line no-nested-ternary
    ...(account != null
      ? { accountId: account.id }
      : client != null
      ? { clientId: client.id, aum: cashValue }
      : ({} as never)),
    stateTaxRate: parseFloat(estimatedStateRate) / 100,
    fedTaxRate: parseFloat(estimatedFederalRate) / 100,
    subjectToAmt: isAlternativeMinimumTax === 'true',
    activeTaxManagement,
    creditQuality,
    stateStrategy,
    // eslint-disable-next-line no-nested-ternary
    ...(maturity !== ''
      ? { maturity: maturity as BondPortfolioMuniMaturity | BondPortfolioTreasuryMaturity }
      : duration !== ''
      ? { duration: duration as BondPortfolioDuration }
      : {}),
  } as BondPortfolioSettings;
}
