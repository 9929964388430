import { State } from '~/reducers/app';
import { enqueueAlert } from './message';
import { doRequestWithAuth } from '../api/apiUtil';
import { ActionType } from '../constants';
import { NotificationResponse } from '../../../vise-types/response';

export type GetNotificationsParams = {
  limit?: number;
  offset?: number;
  type?: 'actionActions' | 'notificationActions';
};
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNotifications = (options?: GetNotificationsParams) => async (dispatch: any) => {
  dispatch({ type: ActionType.GET_NOTIFICATIONS_REQUESTED });
  let urlParams = '';
  if (options) {
    const { limit, offset, type } = options;
    const typeParam = type ? `&type=${type}` : '';
    urlParams = `?limit=${limit}&offset=${offset}${typeParam}`;
  }

  try {
    const { data } = await doRequestWithAuth<NotificationResponse<Notification>>({
      method: 'get',
      requestUrl: `/notification/all${urlParams}`,
    });

    const { metadata, data: responseData, errors } = data;

    if (errors) {
      throw new Error('');
    }

    if (!responseData || !metadata) return;

    dispatch({
      notifications: responseData.notifications,
      count: metadata.count,
      timestamp: metadata.timestamp,
      type: ActionType.GET_NOTIFICATIONS_FULFILLED,
    });
  } catch (e) {
    dispatch(enqueueAlert({ message: 'Failed to fetch notifications.' }));
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-console
  }
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const markNotificationAsRead = (id: string) => async (dispatch: any, getState: any) => {
  dispatch({ type: ActionType.MARK_NOTIFICATION_AS_READ_REQUESTED });

  try {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { app } = getState();
    const { notificationsTimestamp } = app as State;
    const { data } = id
      ? await doRequestWithAuth<{ success: string }>({
          method: 'post',
          requestUrl: '/notification/update',
          data: {
            type: ActionType.MARK_AS_READ,
            notificationId: id,
          },
        })
      : await doRequestWithAuth<{ success: string }>({
          method: 'post',
          requestUrl: `/notification/update_all`,
          data: {
            timestamp: notificationsTimestamp,
          },
        });

    if (data && data.success) {
      dispatch({
        type: ActionType.MARK_NOTIFICATION_AS_READ_FULFILLED,
        notifications: data.success,
      });
    }
  } catch (e) {
    dispatch(enqueueAlert({ message: 'Cannot mark notification(s) as read' }));
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
