import useSWR from 'swr';
import { getExecutedBondProposal } from '~/api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useBondPortfolioForAccount(accountId: string | null | undefined) {
  return useSWR(
    accountId == null ? null : generateSwrKey(`/bond-portfolio/account/${accountId}`),
    accountId == null ? undefined : () => getExecutedBondProposal(accountId)
  );
}
