import useSWR from 'swr';
import {
  BondPortfolioSampleOptionTreasury,
  HighQualityBondPortfolioSampleOption,
  LadderBondPortfolioSampleOption,
} from 'vise-types/portfolio';
import { getBondPortfolioSampleData } from '~/api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useBondPortfolioSampleData(
  settings:
    | LadderBondPortfolioSampleOption
    | HighQualityBondPortfolioSampleOption
    | BondPortfolioSampleOptionTreasury
    | null
    | undefined,
  aum: number | null | undefined
) {
  const params = new URLSearchParams({
    ...settings,
    ...(aum != null ? { aum: aum.toString() } : {}),
  });

  return useSWR(
    settings == null || aum == null
      ? null
      : generateSwrKey(`/bond-portfolio/data/${params.toString()}`),
    settings == null || aum == null ? undefined : () => getBondPortfolioSampleData(settings, aum)
  );
}
