import { Box, Checkbox, Divider, InputAdornment, Typography, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { TemplateAccountUpdateType, TemplateUpdateEventStatus } from '~/models/api';
import { ReactComponent as CheckCircleIcon } from '~/static/images/icons/check-circle.svg';
import { DataTable, floatCompare } from '~/synth/DataTable';
import { TextHighlightTag } from '~/synth/Tag';
import { every, keyBy, mapValues } from 'lodash';
import TextField from '~/synth/TextField';
import { SearchIcon } from '~/synth/Icons';
import {
  usePagination,
  useTable,
  TableState,
  UsePaginationState,
  Column,
  useSortBy,
  UseSortByState,
} from 'react-table';
import { matchSorter } from 'match-sorter';
import { formatCurrency, formatPercent } from '~/utils/format';
import { ReactComponent as RightArrowIcon } from '~/static/images/icons/arrow-right.svg';
import { AllocationsTemplate, RestrictionsTemplate, TemplateUpdateJob } from 'vise-types/template';
import PageNavigation from '~/components/table/PageNavigation';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import ValueCell from '~/routes/Households/Households/ValueCell';
import PopoverTrigger from '~/synth/PopoverTrigger';
import PopoverCard from '~/synth/PopoverCard';
import PopoverLink from '~/synth/PopoverLink';
import AccountFilter, {
  filterAccounts,
  initialFilterState,
} from '~/components/portfolio/AccountFilter';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import Card from './Card';
import { AccountCell, leftPad, CTACell } from './CommonComponents';
import { CONTAINER_WIDTH } from './constants';

interface SuccessfulProposalTable {
  account?: AccountWithPIAndHouseholdInfo;
  value?: number | null;
  link: {
    path: string;
    buttonCopy: string;
  };
  tilt: {
    oldTilt: number;
    newTilt: number;
  };
  glidepathRemoved: boolean;
  selected: {
    selected: boolean;
    onToggle: () => void;
  };
  turnoverFraction: number;
}

function TiltCell({
  value: { oldTilt, newTilt },
}: {
  value: { oldTilt: number | null; newTilt: number | null };
}) {
  const theme = useTheme();
  if (newTilt == null || oldTilt == null) {
    return <Typography variant="body2">N/A</Typography>;
  }
  const oldTiltText = !oldTilt ? 'No tilt' : `${oldTilt}/10`;
  const newTiltText = !newTilt ? 'No tilt' : `${newTilt}/10`;
  return (
    <Box display="flex" alignItems="center">
      <Box color={theme.palette.grey[400]}>
        <Typography variant="body2">{oldTiltText}</Typography>
      </Box>
      <Box mx={1}>
        <RightArrowIcon color={theme.palette.grey[400]} />
      </Box>
      <Typography variant="body2">{newTiltText}</Typography>
    </Box>
  );
}

function GlidpathCell({ value }: { value: boolean }) {
  return (
    <TextHighlightTag severity={value ? 'warning' : 'default'}>
      {value ? 'Removed' : 'None'}
    </TextHighlightTag>
  );
}

function ToggleHeader(props) {
  return (
    <Checkbox checked={props.column.allAccountsSelected} onChange={() => props.column.onToggle()} />
  );
}

function ToggleCell({ value }: { value: SuccessfulProposalTable['selected'] }) {
  return <Checkbox checked={value.selected} onChange={() => value.onToggle()} />;
}

function createPopoverHeader(linkText: string, body: string) {
  return () => {
    return (
      <PopoverTrigger
        triggerAction="hover"
        overlay={({ close }) => <PopoverCard title={linkText} body={body} onClose={close} />}
      >
        <PopoverLink>{linkText}</PopoverLink>
      </PopoverTrigger>
    );
  };
}

const PAGE_SIZE = 10;

const successfulProposalsColumns: Column<SuccessfulProposalTable>[] = [
  {
    Header: ToggleHeader,
    accessor: 'selected',
    Cell: ToggleCell,
    width: '7%',
    id: 'checkbox',
  },
  {
    Header: 'Account',
    accessor: 'account',
    Cell: (props) => {
      if (!props.value) {
        return null;
      }
      return <AccountCell value={props.value} />;
    },
    width: '25%',
    id: 'account',
  },
  {
    Header: 'Turnover',
    accessor: 'turnoverFraction',
    sortType: 'floatCompare',
    Cell: (row) => <>{formatPercent(row.value, 1)}</>,
    id: 'turnoverFraction',
    width: '13%',
  },
  {
    Header: createPopoverHeader(
      'Tilt amount',
      "For accounts with active tilt enabled, tilt will be adjusted to the Vise-recommended amount based on the template's portfolio split. Tilt type (multi-factor or dividend) will remain unchanged."
    ),
    accessor: 'tilt',
    Cell: TiltCell,
    id: 'tilt',
    width: '13%',
  },
  {
    Header: createPopoverHeader(
      'Glide path',
      "We currently do not support glide paths for custom allocations. As a result, glide paths that conflict with this template's allocation will be removed."
    ),
    accessor: 'glidepathRemoved',
    Cell: GlidpathCell,
    id: 'glidePath',
    width: '10%',
  },
  {
    Header: 'Value',
    accessor: 'value',
    Cell: function ValueColum(props) {
      return <ValueCell value={formatCurrency(props.value)} isLoading={false} />;
    },
    width: '15%',
  },
  {
    Header: '',
    Cell: CTACell,
    accessor: 'link',
  },
];

export default function SuccessfulProposalsSection({
  jobData,
  accountsById,
  accountsToLink,
  setAccountsToLink,
  template,
  activityLogFlag,
  maxWidth,
}: {
  jobData: TemplateUpdateJob;
  accountsById: {
    [key: string]: AccountWithPIAndHouseholdInfo | undefined;
  };
  accountsToLink: {
    [key: string]: boolean;
  };
  setAccountsToLink: (
    value: React.SetStateAction<{
      [key: string]: boolean;
    }>
  ) => void;
  template: RestrictionsTemplate | AllocationsTemplate | undefined;
  activityLogFlag?: boolean;
  maxWidth?: string;
}) {
  const { data: modelTemplateCenterData } = useModelTemplateCenterViewData(false);
  const [filterQuery, setFilterQuery] = useState('');
  const [toggleFilterState, setToggleFilterState] = useState(initialFilterState);
  const theme = useTheme();

  const successfulAccountIds = useMemo(() => {
    return (
      jobData?.events
        .filter(
          (ev) =>
            ev.accountUpdateType === TemplateAccountUpdateType.APPLY &&
            ev.status === TemplateUpdateEventStatus.PROPOSAL_CREATED &&
            // If an account becomes untransitioned after a template update job for it is kicked off,
            // exclude it from all actions.
            // This can happening with unlinking/linking or account removal requests etc.
            // We should not be transitioning accounts through template update.
            // If an account is not accepted the proposal is simply deleted so it will be a noop.
            accountsById[ev.accountId] != null
        )
        .map((ev) => ev.accountId) || []
    );
  }, [jobData?.events, accountsById]);

  const allAccountsSelected = every(successfulAccountIds, (id) => accountsToLink[id]);

  const columnsWithToggleData = useMemo(() => {
    return [
      {
        ...successfulProposalsColumns[0],
        allAccountsSelected,
        onToggle: () => {
          if (allAccountsSelected) {
            setAccountsToLink({});
          } else {
            setAccountsToLink(
              mapValues(
                keyBy(successfulAccountIds, (id) => id),
                () => true
              )
            );
          }
        },
      },
      ...successfulProposalsColumns.slice(1),
    ]
      .filter((c) => {
        // remove checkbox column for activity log
        if (activityLogFlag && c.id === 'checkbox') {
          return false;
        }

        // Use all columns for allocation templates
        if (template?.type === 'allocations') {
          return true;
        }
        // Glide path and tilt changes aren't relevant for restriction template edits
        return c.id !== 'glidePath' && c.id !== 'tilt';
      })
      .map((c) =>
        c.id === 'account' && activityLogFlag
          ? ({
              ...c,
              Header: leftPad(() => <>Account</>),
              Cell: leftPad(AccountCell),
            } as Column<SuccessfulProposalTable>)
          : c
      );
  }, [
    activityLogFlag,
    allAccountsSelected,
    setAccountsToLink,
    successfulAccountIds,
    template?.type,
  ]);

  const successfulProposalsTableDataUnfiltered = useMemo(() => {
    return (jobData || { events: [] }).events
      .filter(
        (event) =>
          event.accountUpdateType === TemplateAccountUpdateType.APPLY &&
          // If an account becomes untransitioned after a template update job for it is kicked off,
          // exclude it from all actions.
          // This can happening with unlinking/linking or account removal requests etc.
          // We should not be transitioning accounts through template update.
          // If an account is not accepted the proposal is simply deleted so it will be a noop.
          accountsById[event.accountId] != null &&
          (activityLogFlag
            ? event.status === TemplateUpdateEventStatus.PROPOSAL_EXECUTED
            : event.status === TemplateUpdateEventStatus.PROPOSAL_CREATED)
      )
      .map((event) => {
        const account = accountsById[event.accountId];
        const link = activityLogFlag
          ? `/secure/accounts/${event.accountId}/portfolio/overview`
          : `/secure/strategy-center-proposal/${jobData?.id || ''}/${event.newProposalId || ''}`;
        return {
          account,
          value: account?.cachedAum,
          link: {
            path: link,
            buttonCopy: activityLogFlag ? 'View account' : 'View proposal',
          },
          tilt: {
            oldTilt: event.previousActiveTilt,
            newTilt: event.newActiveTilt,
          },
          glidepathRemoved: !!event.glidepathRemoved,
          selected: {
            selected: accountsToLink[event.accountId],
            onToggle: () => {
              setAccountsToLink({
                ...accountsToLink,
                [event.accountId]: !accountsToLink[event.accountId],
              });
            },
          },
          turnoverFraction: event.turnoverFraction || 0,
        } as SuccessfulProposalTable;
      });
  }, [activityLogFlag, jobData, accountsById, accountsToLink, setAccountsToLink]);

  const successfulProposalsTableData = useMemo(() => {
    if (modelTemplateCenterData?.data) {
      const accountIds = filterAccounts(
        successfulProposalsTableDataUnfiltered
          .map((row) => row.account)
          .filter(
            (
              acct: AccountWithPIAndHouseholdInfo | undefined
            ): acct is AccountWithPIAndHouseholdInfo => !!acct
          ),
        toggleFilterState,
        modelTemplateCenterData.data
      ).map((acct) => acct.id);
      return matchSorter(
        successfulProposalsTableDataUnfiltered.filter((row) =>
          accountIds.includes(row.account?.id || '')
        ),
        filterQuery,
        {
          keys: ['account.accountName', 'account.accountNumber'],
          threshold: matchSorter.rankings.CONTAINS,
        }
      );
    }
    return successfulProposalsTableDataUnfiltered;
  }, [
    modelTemplateCenterData?.data,
    successfulProposalsTableDataUnfiltered,
    toggleFilterState,
    filterQuery,
  ]);

  const successfulProposalsTable = useTable<SuccessfulProposalTable>(
    {
      columns: columnsWithToggleData,
      data: successfulProposalsTableData,
      sortTypes: { floatCompare },
      initialState: {
        pageSize: PAGE_SIZE,
        sortBy: [{ id: 'turnoverFraction', desc: true }],
      } as TableState<SuccessfulProposalTable> &
        UsePaginationState<SuccessfulProposalTable> &
        UseSortByState<SuccessfulProposalTable>,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  if (!successfulProposalsTableDataUnfiltered.length) {
    return null;
  }

  let deselectCopy = '';
  if (jobData.reason === 'LINK') {
    deselectCopy = "Deselect any accounts you'd like to omit from linking.";
  } else if (jobData.reason === 'UPDATE') {
    deselectCopy =
      "Deselect accounts to omit them from updating to this template's latest version.";
  } else if (jobData.reason === 'BULK_EDIT') {
    deselectCopy = 'Deselect any accounts you’d like to omit from this update.';
  }

  const getActionCopy = () => {
    switch (jobData.reason) {
      case 'BULK_EDIT':
        return successfulProposalsTableData.length === 1
          ? ' had its capital gains budgets updated'
          : 's had their capital gains budgets updated';
      case 'LINK':
        return successfulProposalsTableData.length === 1
          ? ' was linked to this template.'
          : 's were linked to this template.';
      default:
        return successfulProposalsTableData.length === 1
          ? ' was updated to the latest version of this template.'
          : 's were updated to the latest version of this template.';
    }
  };

  return (
    <Box my={3}>
      <Card fullWidth maxWidth={maxWidth || CONTAINER_WIDTH}>
        <Box display="flex" alignItems="center" mx={3}>
          <Box>
            <Box display="flex" alignItems="center">
              <CheckCircleIcon color={theme.palette.success[400]} />
              <Box ml={1}>
                {activityLogFlag && (
                  <Typography variant="h4">
                    {jobData.reason === 'LINK' ? 'Linked accounts' : 'Updated accounts'}
                  </Typography>
                )}
                {!activityLogFlag && <Typography variant="h4">Successful proposals</Typography>}
              </Box>
            </Box>
            <Box mt={1}>
              {!activityLogFlag && (
                <Typography variant="body1" color="textSecondary">
                  {successfulAccountIds.length} account
                  {successfulAccountIds.length === 1 ? '' : 's'} are ready for execution.{' '}
                  {deselectCopy}
                </Typography>
              )}
              {activityLogFlag && (
                <Typography variant="body1" color="textSecondary">
                  {successfulProposalsTableData.length} account
                  {getActionCopy()}
                </Typography>
              )}
            </Box>
          </Box>
          <Box flex={1} />
          <Box width="360px">
            <TextField
              fullWidth
              value={filterQuery}
              onChange={(e) => {
                setFilterQuery(e.target.value);
                successfulProposalsTable.gotoPage(0);
              }}
              placeholder="Search by name or account number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <AccountFilter
                    accounts={successfulProposalsTableDataUnfiltered
                      .map((row) => row.account)
                      .filter(
                        (
                          acct: AccountWithPIAndHouseholdInfo | undefined
                        ): acct is AccountWithPIAndHouseholdInfo => !!acct
                      )}
                    filterState={toggleFilterState}
                    onApplyFilter={(filterState) => {
                      setToggleFilterState(filterState);
                      successfulProposalsTable.gotoPage(0);
                    }}
                    eventCategory={EVENT_CATEGORIES.STRATEGY_CENTER}
                  />
                ),
                sx: { paddingRight: 0 },
              }}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <DataTable table={successfulProposalsTable} m={0} rowSize="large" />
        </Box>
        {successfulProposalsTableData.length > PAGE_SIZE && (
          <>
            <Box mt={3}>
              <Divider />
            </Box>
            <Box px={3} pt={4} display="flex">
              <Box flex={1} />
              <PageNavigation
                currentPage={
                  (successfulProposalsTable.state as UsePaginationState<SuccessfulProposalTable>)
                    .pageIndex + 1
                }
                numItems={successfulProposalsTableData.length}
                onNextPageClick={() => successfulProposalsTable.nextPage()}
                onPrevPageClick={() => successfulProposalsTable.previousPage()}
                onPageNumberClick={(pageNumber) =>
                  successfulProposalsTable.gotoPage(pageNumber - 1)
                }
                pageSize={PAGE_SIZE}
              />
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
}
