import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface CardFooterProps extends BoxProps {
  borderStyle?: string;
}

const CardFooter = ({ borderStyle, ...props }: CardFooterProps) => (
  <Box
    flexGrow={0}
    borderTop={`1px ${borderStyle ?? 'solid'}`}
    borderColor="grey.200"
    px={3}
    py={1.5}
    {...props}
  />
);

export default CardFooter;
