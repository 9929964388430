import useSWR from 'swr';
import { getPortfolioMetrics } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getPortfolioMetricsSwrKey = (accountId: string) =>
  generateSwrKey(`/portfolio/accounts/${accountId}/metrics`);

export default function usePortfolioMetrics(accountId?: string | null) {
  return useSWR(
    accountId ? getPortfolioMetricsSwrKey(accountId) : null,
    accountId == null ? undefined : () => getPortfolioMetrics(accountId)
  );
}
