import useSWR from 'swr';
import { getPositions } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getAccountPositionsSwrKey = (
  accountNumber: string,
  custodianId: string,
  includePCE2Instruments = false,
  includeLots = false,
  includeCountry = false
) =>
  generateSwrKey(
    `/portfolio/positions?portfolio_id=${accountNumber}&custodian_id=${custodianId}&include_pce2_instruments=${includePCE2Instruments}&include_tax_lots=${includeLots}&include_country=${includeCountry}`
  );

export default function usePositions(
  accountNumber: string | undefined | null,
  custodianId: string | undefined | null,
  includePCE2Instruments?: boolean,
  includeTaxLots?: boolean,
  includeCountry?: boolean
) {
  const { data, error, isValidating, mutate } = useSWR(
    accountNumber && custodianId
      ? getAccountPositionsSwrKey(
          accountNumber,
          custodianId,
          includePCE2Instruments,
          includeTaxLots,
          includeCountry
        )
      : null,
    accountNumber == null || custodianId == null
      ? undefined
      : () =>
          getPositions(accountNumber, custodianId, {
            includePCE2Instruments,
            includeTaxLots,
            includeCountry,
          })
  );

  return {
    data,
    isLoading: !error && data === undefined,
    isValidating,
    error,
    mutate,
  };
}
