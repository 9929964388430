import { Box } from '@mui/material';
import React from 'react';
import { ReactComponent as ArrowDownIcon } from '~/static/images/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '~/static/images/icons/arrow-up.svg';

const getIcon = (value: number) => {
  if (value > 0) {
    return <ArrowUpIcon style={{ color: 'success.primary' }} />;
  }
  if (value < 0) {
    return <ArrowDownIcon />;
  }
  return undefined;
};

/**
 * Percent change indicator text.
 */
const PercentChange = ({ value }: { value: number }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="end">
      <Box mr={0.25}>{value}%</Box>
      <Box display="flex" color={value > 0 ? 'success.main' : 'error.main'}>
        {getIcon(value)}
      </Box>
    </Box>
  );
};

export default PercentChange;
