import { ButtonProps } from '@mui/material';
import React from 'react';
import { ReactComponent as DownloadIcon } from '~/static/images/icons/download.svg';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';

type Props = {
  hideExportPdf?: boolean;
  onExportCsv: () => void;
  onExportPdf?: () => void;
  isIcon?: boolean;
};

export default function ExportMenu({
  hideExportPdf = false,
  onExportCsv,
  onExportPdf,
  isIcon = false,
  ...buttonProps
}: Props & ButtonProps) {
  function handleClickExportCsv(closeMenu: () => void) {
    onExportCsv();
    closeMenu();
  }

  function handleClickExportPdf(closeMenu: () => void) {
    if (onExportPdf) {
      onExportPdf();
    }

    closeMenu();
  }

  return (
    <DropdownButtonMenu
      // There is too much spacing in the button when there is only and icon.
      // This is caused by the min-width properties set at the root of the Button element.
      // The adjusted padding is to align the height with the "Execute" button it will be next to.
      style={isIcon ? { minWidth: 0, padding: '13px 15px' } : {}}
      buttonContent={isIcon ? <DownloadIcon /> : 'Export'}
      chevron={false}
      buttonProps={{ variant: isIcon ? 'outlined' : 'text', ...buttonProps }}
    >
      {(closeMenu) => [
        <DropdownButtonMenuItem key="mi-export-csv" onClick={() => handleClickExportCsv(closeMenu)}>
          Export to CSV
        </DropdownButtonMenuItem>,
        !hideExportPdf && (
          <DropdownButtonMenuItem
            key="mi-export-pdf"
            disabled={onExportPdf == null}
            onClick={() => handleClickExportPdf(closeMenu)}
          >
            Export to PDF
          </DropdownButtonMenuItem>
        ),
      ]}
    </DropdownButtonMenu>
  );
}
