import React from 'react';
import { Box, BoxProps } from '@mui/material';

const CardHeader = (props: BoxProps) => (
  <Box
    border={1}
    borderTop={0}
    borderLeft={0}
    borderRight={0}
    sx={{ borderColor: 'grey.200' }}
    px={3}
    py={2.5}
    {...props}
  />
);

export default CardHeader;
