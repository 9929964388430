import { Box, ButtonBase, useTheme } from '@mui/material';
import React from 'react';
import { RebalancerStatus } from 'vise-types/portfolio';
import { ReactComponent as PauseIcon } from '~/static/images/icons/pause-circle-outline.svg';
import { ReactComponent as PlayIcon } from '~/static/images/icons/play-circle-outline.svg';
import { TextHighlightTag } from '~/synth/Tag';

interface RebalancerStatusProps {
  rebalancerStatus: RebalancerStatus;
  accountStatus: string;
  onClick: () => void;
}

export default function RebalancerStatus({
  rebalancerStatus,
  accountStatus,
  onClick,
}: RebalancerStatusProps) {
  const theme = useTheme();
  const rebalancerIsActive = rebalancerStatus === 'ACTIVE';
  const accountIsActive = accountStatus === 'ACTIVE';

  // If the account is not active (missing tax lots, etc) reblance will be skipped even if
  // rebalancer_status=ACTIVE. To avoid confusion for the advisor we _always_ show paused when the
  // account is inactive.
  const displayRebalancerActive = accountIsActive && rebalancerIsActive;

  let status: string;
  if (displayRebalancerActive) {
    status = 'Active';
  } else if (rebalancerStatus === 'PAUSED') {
    status = 'Paused';
  } else {
    status = 'Paused by Vise';
  }

  return (
    <ButtonBase onClick={onClick}>
      <TextHighlightTag severity={displayRebalancerActive ? 'success' : 'warning'}>
        <Box display="flex" flexDirection="row" alignItems="center" fontSize={14}>
          <Box mr={0.5} color="grey.800">
            Auto rebalancer:
          </Box>
          <Box color={displayRebalancerActive ? 'success.400' : 'warning.400'}>
            {status}
            &nbsp;
          </Box>
          {displayRebalancerActive ? (
            <PlayIcon color={theme.palette.success[400]} height={16} width={16} />
          ) : (
            <PauseIcon color={theme.palette.warning[400]} height={16} width={16} />
          )}
        </Box>
      </TextHighlightTag>
    </ButtonBase>
  );
}
