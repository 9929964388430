import { Box, BoxProps, Tooltip, Typography, ButtonBase, InputAdornment } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  ASSET_CLASSES_WITH_VISE_SINGLE_SECURITY_STRATEGY_TO_LABEL_MAP,
  ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP,
  ASSET_CLASS_TO_LABEL_MAP,
} from '~/routes/PortfolioCreator2/Constants';
import ViseBadge from '~/synth/ViseBadge';
import { ReactComponent as LockClosed } from '~/static/images/icons/lock-closed.svg';
import { ReactComponent as LockOpen } from '~/static/images/icons/lock-open.svg';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { getAssetClassKeyFromFeatures } from '~/utils/pce2Migration';
import { flatMap, inRange, toLower } from 'lodash';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import NumberFormat from 'react-number-format';
import SelectorCheckbox, { ContainedSelectorCheckboxProps } from '~/synth/SelectorCheckbox';
import TextField from '~/synth/TextField';
import { AssetClassKey, Feature } from 'vise-types/pce2_instrument';
import { AssetClassTreeNode } from '~/routes/PortfolioCreator2/Types';

export function LocallyDelayedNumberFormat({ value, onChange, ...props }) {
  const [localValue, setLocalValue] = useState<string | undefined>();
  return (
    <NumberFormat
      decimalScale={1}
      value={localValue != null ? localValue : value}
      onBlur={() => {
        setLocalValue(undefined);
      }}
      isAllowed={(values) => inRange(values.floatValue || 0, 0, 100.1)}
      onChange={(e) => {
        onChange(e);
        setLocalValue(e.target.value);
      }}
      {...props}
    />
  );
}

const StyledLocallyDelayedNumberFormat = styled(LocallyDelayedNumberFormat)`
  ${({ isEdited, theme }) => (isEdited ? `background-color: ${theme.palette.blue[100]}` : ``)}
`;

// TO-DO: Use default checkbox instead of SelectorCheckbox
const useSelectorCheckboxBranchStyles = makeStyles()((theme) => ({
  root: {
    border: 'none !important',
    marginLeft: `calc(var(--depth) * ${theme.spacing(4)})`,
    paddingBottom: `${theme.spacing(1.5)} !important`,
    paddingTop: `${theme.spacing(1.5)} !important`,
    position: 'relative',
  },
  branch: {
    '& .MuiButtonBase-root': {
      '&::after': {
        content: '""',
        position: 'absolute',
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        width: '14px',
        left: '-14px',
        top: '10px',
        zIndex: '0',
      },
    },
  },
  parent: {
    '& .MuiButtonBase-root': {
      '&::before': {
        content: '""',
        position: 'absolute',
        borderLeft: `1px solid ${theme.palette.grey[200]}`,
        left: '18px',
        top: '29px',
        zIndex: 0,
      },
    },
  },
  twoDescendantBorderHeight: {
    '& .MuiButtonBase-root': {
      '&::before': {
        height: '120px',
      },
    },
  },
  domesticFixedIncomeBorderHeight: {
    '& .MuiButtonBase-root': {
      '&::before': {
        height: '678px',
      },
    },
  },
}));

const StyledTreeItem = styled(Box)<
  ThemedStyledProps<BoxProps & { $nextItemDepth: number }, DefaultTheme>
>(({ theme, $nextItemDepth }) => {
  let width;
  if ($nextItemDepth === 0) {
    width = '0px';
  } else if ($nextItemDepth === 1) {
    width = '31px';
  } else {
    width = '64px';
  }
  return {
    padding: '12px 0',
    position: 'relative',
    '&:last-child': {
      borderBottom: 'initial',
      '&::after': {
        left: '0px',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '69px',
      width: `calc(100% - ${width})`,
      left: $nextItemDepth > 1 ? '64px' : '31px',
      borderBottom: `1px dashed ${theme.palette.grey[200]}`,
      content: '""',
    },
  };
});

type SelectorCheckboxBranchProps = {
  isBranch: boolean;
  isParent: boolean;
  isLocked: boolean;
  allocation: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  showTextInput: boolean;
  onLockClick: () => void;
  isEdited: boolean;
  nextItemDepth: number;
  numDescendants: number;
} & ContainedSelectorCheckboxProps;

function SelectorCheckboxBranch({
  isBranch,
  isParent,
  isLocked,
  isEdited,
  allocation,
  onChange,
  onLockClick,
  showTextInput,
  nextItemDepth,
  numDescendants,
  ...props
}: SelectorCheckboxBranchProps) {
  const { classes: branchStyles, cx } = useSelectorCheckboxBranchStyles();
  const { name } = props;
  return (
    <StyledTreeItem display="flex" $nextItemDepth={nextItemDepth}>
      <SelectorCheckbox
        {...props}
        className={cx(
          branchStyles.root,
          isBranch && branchStyles.branch,
          isParent && branchStyles.parent,
          // This is super hacky but basically all L2/L3 asset classes have either 9 or 2 descendants so we can hardcode the height of the left border.
          // Calculating the border programmatically results in weird fractions of a pixel that are just as hacky.
          numDescendants > 2
            ? branchStyles.domesticFixedIncomeBorderHeight
            : branchStyles.twoDescendantBorderHeight
        )}
      />
      {showTextInput && (
        <Box ml="auto" display="flex">
          <ButtonBase onClick={onLockClick}>
            {!isParent &&
              (isLocked ? (
                <LockClosed data-testid={`locked-icon-${name}`} width={18} height={20} />
              ) : (
                <LockOpen width={18} height={20} />
              ))}
          </ButtonBase>
          <Box width={124} ml={3}>
            <StyledLocallyDelayedNumberFormat // Use `NumberFormat` for its `decimalScale` functionality
              id={`allocation-${toLower(name)}`}
              InputProps={{
                endAdornment: isEdited ? (
                  <Tooltip title="This value was customized.">
                    <InputAdornment position="end">%</InputAdornment>
                  </Tooltip>
                ) : (
                  <InputAdornment position="end">%</InputAdornment>
                ),
              }}
              isEdited={isEdited}
              customInput={TextField}
              max={1}
              disabled={isParent}
              decimalScale={1}
              name="allocation"
              onChange={(e) => onChange(e)}
              value={allocation}
            />
          </Box>
        </Box>
      )}
    </StyledTreeItem>
  );
}

interface AssetClassTreeProps {
  curFeatureSet: Feature[];
  curAssetClass: AssetClassTreeNode;
  depth: number;
  assetAllocation: { [key in AssetClassKey]?: number };
  onClickCreator: (key: AssetClassKey) => () => void;
  checkedSet: Set<string>;
  isEtfExclusive: boolean | null;
  disabledCheck: (key: AssetClassKey) => boolean;
  onAllocationChange: (key: AssetClassKey, value: string) => void;
  exclusions: AssetClassKey[];
  lockedAssetClasses: AssetClassKey[];
  onLockClick: (assetClass: AssetClassKey) => void;
  customAllocations: AssetClassKey[];
  isLastBranch?: boolean;
}

export default function AssetClassTree({
  curFeatureSet,
  curAssetClass,
  depth,
  onAllocationChange,
  onClickCreator,
  checkedSet,
  lockedAssetClasses,
  onLockClick,
  isEtfExclusive,
  assetAllocation,
  disabledCheck,
  exclusions,
  customAllocations,
  isLastBranch,
}: AssetClassTreeProps) {
  const featureSetForCurAssetClass = [...curFeatureSet, curAssetClass.feature];

  const assetClassKey = getAssetClassKeyFromFeatures(featureSetForCurAssetClass);

  // We want to get to total descendents instead of direct children since the descendents take up
  // space below this branch too.
  const numDescendents = ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get(assetClassKey)?.length ?? 0;

  const childSubTree = flatMap(curAssetClass.children || [], (assetClass, i) => (
    <AssetClassTree
      assetAllocation={assetAllocation}
      curFeatureSet={featureSetForCurAssetClass}
      curAssetClass={assetClass}
      depth={depth + 1}
      lockedAssetClasses={lockedAssetClasses}
      onLockClick={onLockClick}
      onAllocationChange={onAllocationChange}
      onClickCreator={onClickCreator}
      checkedSet={checkedSet}
      key={assetClass.feature}
      isEtfExclusive={isEtfExclusive}
      disabledCheck={disabledCheck}
      exclusions={exclusions}
      customAllocations={customAllocations}
      isLastBranch={i === (curAssetClass.children || []).length - 1}
    />
  ));

  const labelText = ASSET_CLASS_TO_LABEL_MAP.get(curAssetClass.feature);

  const label = useMemo(() => {
    if (
      assetClassKey === 'FIXED_INCOME/DOMESTIC/MUNICIPAL' &&
      disabledCheck('FIXED_INCOME/DOMESTIC/MUNICIPAL')
    ) {
      return (
        <Box display="flex" alignItems="center">
          {labelText}
          <Tooltip
            title="Non-taxable accounts are not eligible to invest in municipal bonds."
            placement="top-start"
          >
            <Box ml={0.5} display="flex">
              <InformationCircleIcon />
            </Box>
          </Tooltip>
        </Box>
      );
    }
    if (
      ASSET_CLASSES_WITH_VISE_SINGLE_SECURITY_STRATEGY_TO_LABEL_MAP.has(assetClassKey) &&
      assetAllocation[assetClassKey] &&
      !isEtfExclusive
    ) {
      return (
        <Box display="flex">
          {labelText}
          <Tooltip
            title={
              ASSET_CLASSES_WITH_VISE_SINGLE_SECURITY_STRATEGY_TO_LABEL_MAP.get(assetClassKey) ?? ''
            }
            placement="top-start"
          >
            <Box>
              <ViseBadge ml={1.25} />
            </Box>
          </Tooltip>
        </Box>
      );
    }
    return labelText;
  }, [labelText, assetClassKey, isEtfExclusive, assetAllocation, disabledCheck]);

  const customPill = customAllocations.includes(assetClassKey) ? (
    <Box
      color="grey.600"
      bgcolor="blue.100"
      borderRadius="20px"
      textAlign="center"
      display="flex"
      alignItems="center"
      ml={1.5}
      py="1px"
      px="8px"
    >
      <Typography variant="body1">Custom</Typography>
    </Box>
  ) : null;

  let nextItemDepth = depth;
  if (childSubTree.length > 0) {
    nextItemDepth += 1;
  } else if (isLastBranch) {
    nextItemDepth -= 1;
  }

  return (
    <>
      <SelectorCheckboxBranch
        isBranch={depth > 0}
        isLocked={lockedAssetClasses.includes(assetClassKey)}
        onLockClick={() => onLockClick(assetClassKey)}
        isParent={numDescendents > 0}
        name={assetClassKey}
        label={
          <Box display="flex" alignItems="center">
            {label}
            {customPill}
          </Box>
        }
        isEdited={customAllocations.includes(assetClassKey)}
        allocation={assetAllocation[assetClassKey] || 0}
        onChange={(e) => onAllocationChange(assetClassKey, e.target.value)}
        checked={checkedSet.has(assetClassKey)}
        onClick={onClickCreator(assetClassKey)}
        disabled={disabledCheck(assetClassKey)}
        style={{
          // TO-DO: We might want to modify our css properties to allow css variables
          // Link: https://github.com/frenic/csstype#what-should-i-do-when-i-get-type-errors
          ['--depth' as string]: depth,
        }}
        showTextInput={!exclusions.includes(assetClassKey)}
        nextItemDepth={nextItemDepth}
        numDescendants={numDescendents}
      />
      {childSubTree}
    </>
  );
}
