import { SvgIcon, type SvgIconProps } from "@mui/material";
import React from "react";

export default function CheckboxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" fill="none" />
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="1.5"
          stroke="currentcolor"
        />
      </svg>
    </SvgIcon>
  );
}

export const checkboxIconElement = <CheckboxIcon />;

function CheckboxCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_11307_9094)">
          <rect width="20" height="20" rx="2" fill="#1753DE" />
          <path
            d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_11307_9094">
            <rect width="20" height="20" rx="2" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export const checkboxCheckedIconElement = <CheckboxCheckedIcon />;
