import { renameAllocationsTemplate } from '~/api/api';
import useEnqueueToast from '../useToast';

export default function useRenameAllocationsTemplate() {
  const enqueueToast = useEnqueueToast();

  const renameTemplate = async (id: string, name: string) => {
    try {
      await renameAllocationsTemplate(id, name);
      enqueueToast({
        severity: 'success',
        title: `You've successfully renamed your allocations template.`,
      });
      return true;
    } catch (e) {
      enqueueToast({
        severity: 'error',
        title: `Failed to rename template`,
        content: `Please reach out to clientservice@vise.com for help.`,
      });
      return false;
    }
  };

  return { renameTemplate };
}
