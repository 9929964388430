import React, { useState } from 'react';
import { unlinkAccount } from '~/api/api';
import useEnqueueToast from '~/hooks/useToast';
import amplitude from '~/utils/amplitude';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import DeleteItemModal from './DeleteItemModal';

export interface UnlinkAccountModalContainerProps {
  accountId: string | undefined;
  clientId: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const UnlinkAccountModalContainer = ({
  accountId,
  clientId,
  isOpen,
  onClose,
  onSuccess,
}: UnlinkAccountModalContainerProps) => {
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const enqueueToast = useEnqueueToast();

  return (
    <DeleteItemModal
      title="Unlink this account?"
      content="Unlinking this account will unlink clients, accounts, proposals and executed
    portfolios associated with the account."
      confirmButtonContent="Yes, unlink account"
      isLoading={isSendingRequest}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={async () => {
        amplitude().logEvent('Tap confirm unlink account', {
          category: EVENT_CATEGORIES.HOUSEHOLDS,
          accountId,
        });

        if (accountId == null) {
          enqueueToast({
            title: 'Error unlinking account: missing accountId',
            severity: 'error',
          });
          return;
        }

        if (clientId == null) {
          enqueueToast({
            title: 'Error unlinking account: missing clientId',
            severity: 'error',
          });
          return;
        }

        setIsSendingRequest(true);
        try {
          await unlinkAccount(clientId, accountId);
        } catch (error) {
          onClose();
          enqueueToast({
            title: 'Error unlinking account',
            severity: 'error',
          });
          return;
        } finally {
          setIsSendingRequest(false);
        }
        onSuccess();
      }}
    />
  );
};

export default UnlinkAccountModalContainer;
