import { AssetClassKey, Sector } from 'vise-types/pce2_instrument';

export const SECTOR_TO_LABEL_MAP = new Map<Sector, string>([
  ['UNKNOWN_SECTOR', 'Unknown'],
  ['FINANCIAL_SERVICES', 'Financial services'],
  ['HEALTHCARE', 'Healthcare'],
  ['CONSUMER_CYCLICAL', 'Consumer cyclical'],
  ['TECHNOLOGY', 'Technology'],
  ['CONSUMER_DEFENSIVE', 'Consumer defensive'],
  ['INDUSTRIALS', 'Industrials'],
  ['REAL_ESTATE', 'Real estate'],
  ['COMMUNICATION_SERVICES', 'Communication services'],
  ['UTILITIES', 'Utilities'],
  ['ENERGY', 'Energy'],
  ['BASIC_MATERIALS', 'Basic materials'],
]);

export const ASSET_CLASS_TO_ORDER_PCE2 = {
  'EQUITY/US/LARGE_CAP': 0,
  'EQUITY/US/SMALL_CAP': 1,
  'EQUITY/US_REIT': 2,
  'EQUITY/DEVELOPED/LARGE_CAP': 3,
  'EQUITY/DEVELOPED/SMALL_CAP': 4,
  'EQUITY/EMERGING_EQUITY': 5,
  'FIXED_INCOME/DOMESTIC/TREASURY/SHORT': 6,
  'FIXED_INCOME/DOMESTIC/TREASURY/INTERMEDIATE': 7,
  'FIXED_INCOME/DOMESTIC/CORPORATE/SHORT': 8,
  'FIXED_INCOME/DOMESTIC/CORPORATE/INTERMEDIATE': 9,
  'FIXED_INCOME/DOMESTIC/SECURITIZED': 10,
  'FIXED_INCOME/DOMESTIC/HIGH_YIELD': 11,
  'FIXED_INCOME/DOMESTIC/TIPS': 12,
  'FIXED_INCOME/DOMESTIC/MUNICIPAL': 13,
  'FIXED_INCOME/INTERNATIONAL': 14,
  'FIXED_INCOME/EMERGING_FI': 15,
  'ALTERNATIVES/COMMODITIES': 16,
} as { [key in AssetClassKey]: number };
