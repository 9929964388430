import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { XrayClient } from 'vise-types/xray';
import DialogTitle from '~/synth/DialogTitle';
import { Controller, useForm } from 'react-hook-form';
import useEnqueueToast from '~/hooks/useToast';
import { saveReadyForOnboardingProposals } from '~/api/api';
import { Action, State } from './ClientOnboardingState';
import { ProductOnboardingRoutes } from './routes';

export function NoXrayDialog({
  selectedClient,
  ...props
}: { selectedClient: XrayClient } & DialogProps) {
  const history = useHistory();

  return (
    <Dialog {...props}>
      <DialogTitle onClose={() => history.goBack()}>Continue with no proposal?</DialogTitle>
      <DialogContent>
        The client you selected has no associated proposal. Creating a proposal for this client will
        help you better understand the client&apos;s account&apos;s transition onto Vise.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => history.push(ProductOnboardingRoutes.custodianForm)}
          color="secondary"
          variant="outlined"
        >
          Continue
        </Button>
        <Button
          variant="contained"
          onClick={() =>
            history.push(`/secure/portfolio-creator-next?clientId=${selectedClient.clientId}`)
          }
        >
          Create proposal
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function SelectXray({
  selectedClient,
  dispatch,
  renderFooter,
  state,
}: {
  selectedXray?: string;
  selectedClient: XrayClient | null;
  dispatch: React.Dispatch<Action>;
  renderFooter: () => React.ReactNode;
  state: State;
}) {
  const history = useHistory();
  const { control, handleSubmit } = useForm({ defaultValues: state });
  const toast = useEnqueueToast();
  const [viewProposalId, setViewProposalId] = useState(null);

  if (!selectedClient) return <Redirect to={ProductOnboardingRoutes.selectClient} />;

  const rows = selectedClient?.proposalInfos ?? [];
  const columns: GridColDef[] = [
    {
      headerName: 'Date Created',
      field: 'createdAt',
      flex: 1,
      renderCell(params) {
        const date = new Date(params.value);
        return (
          <div>
            <>
              {format(date, 'MM/dd/yyyy')}
              <Typography variant="body1" color="textSecondary">
                {format(date, 'HH:mm a')}
              </Typography>
            </>
          </div>
        );
      },
    },
    { headerName: 'Proposal Name', field: 'proposalName', flex: 1 },
    {
      headerName: 'Account Type',
      field: 'taxable',
      flex: 1,
      renderCell: ({ row }) => (row.taxable ? 'Non-qualified' : 'Qualified'),
    },
    {
      headerName: 'Proposal',
      flex: 1,
      field: 'link',
      renderCell: ({ row }) => (
        <Button onClick={() => setViewProposalId(row.proposalId)}>View proposal</Button>
      ),
    },
  ];

  const onSubmit = async (data: State) => {
    if (!data.selectedXray) return;
    dispatch({
      type: 'selectedXray',
      value: data.selectedXray,
    });
    await saveReadyForOnboardingProposals([
      {
        proposalId: data.selectedXray?.proposalId.toString(),
        accountNumber: 'fake_account_number_TDB',
        message: 'product onboarding',
      },
    ]);
    history.push(ProductOnboardingRoutes.custodianForm);
  };

  const handleSubmitError = () => {
    toast({ title: 'Select at least one proposal', severity: 'warning' });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, handleSubmitError)}>
      <Box p={4} height="calc(100vh - 200px)">
        <Typography variant="h2" mb={2}>
          Select proposal
        </Typography>
        <Box mb={6} color="grey.600">
          Next, let’s select which specific proposal you’d like to onboard for{' '}
          {selectedClient.firstName} {selectedClient.lastName}.
        </Box>
        <Box pb={4} height="calc(100% - 80px)">
          <Controller
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <DataGridPro
                pagination
                autoPageSize
                rowHeight={72}
                hideFooter={false}
                columns={columns}
                rows={rows}
                getRowId={(row) => row.proposalId}
                rowSelectionModel={value?.proposalId}
                disableRowSelectionOnClick={false}
                disableMultipleRowSelection
                onRowSelectionModelChange={(change) =>
                  onChange(rows.find((r) => r.proposalId === change?.[0]))
                }
              />
            )}
            control={control}
            name="selectedXray"
          />
        </Box>
        {renderFooter()}
      </Box>
      {viewProposalId && (
        <Dialog open maxWidth="lg" sx={{ '& .MuiDialog-paper': { height: '90vh' } }}>
          <DialogTitle onClose={() => setViewProposalId(null)}>Proposal</DialogTitle>
          <DialogContent>
            <iframe
              title="view proposal dialog"
              src={`/secure/proposal/${viewProposalId}/x-ray?noSidebar=true`}
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          </DialogContent>
        </Dialog>
      )}
    </form>
  );
}
