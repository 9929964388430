import { groupBy, keyBy } from 'lodash';
import {
  AllocationsTemplate,
  GetModelTemplateCenterViewDataResponse,
  RestrictionsTemplate,
  TemplateUpdateJob,
} from 'vise-types/template';
import { ACTIVE_TILT_TYPE_TO_LABEL } from '../PortfolioCreator2/Constants';

export const formatTemplateNames = (names: string[]): string => {
  if (names.length === 0) {
    return 'None';
  }
  const content = names.length >= 2 ? `${names.length}` : `${names[0]}`;
  return content;
};

function getOriginalTemplateIdToFreshTemplateId(data: GetModelTemplateCenterViewDataResponse) {
  return [...data.restrictionsTemplates, ...data.allocationsTemplates].reduce((accum, template) => {
    if (template.stale) {
      return accum;
    }
    return {
      ...accum,
      [template.originalTemplateId]: template.parentId,
    };
  }, {} as { [key: string]: string });
}

// Get a map from non-stale template id to all account ids that are linked to stale versions of the template
// Stale account ids will have at least 1 id. If inProgressJobs is passed, exclude accounts that are part of
// an active update
export function getStaleAccountsFromViewData(
  data: GetModelTemplateCenterViewDataResponse,
  inProgressJobs?: TemplateUpdateJob[]
) {
  const templatesByParentId = keyBy(
    [...data.allocationsTemplates, ...data.restrictionsTemplates],
    (t) => t.parentId
  );

  const originalTemplateIdToFreshTemplateId = getOriginalTemplateIdToFreshTemplateId(data);

  const accountIdToInProgressJob = {};
  if (inProgressJobs) {
    inProgressJobs.forEach((job) => {
      job.events.forEach((event) => {
        accountIdToInProgressJob[event.accountId] = job.id;
      });
    });
  }

  return data.templateLinks.reduce((accum, link) => {
    const template = templatesByParentId[link.templateId];
    const newAccum = { ...accum };
    if (!template) {
      return newAccum;
    }
    if (template.stale) {
      const freshTemplateId = originalTemplateIdToFreshTemplateId[template.originalTemplateId];
      if (!freshTemplateId) {
        return newAccum;
      }
      if (accountIdToInProgressJob[link.accountId]) {
        return newAccum;
      }
      if (!newAccum[freshTemplateId]) {
        newAccum[freshTemplateId] = [];
      }
      newAccum[freshTemplateId].push(link.accountId);
    }
    return newAccum;
  }, {} as { [key: string]: string[] });
}

export function getAllAccountIdsForFreshTemplateId(data: GetModelTemplateCenterViewDataResponse) {
  const templatesByParentId = keyBy(
    [...data.allocationsTemplates, ...data.restrictionsTemplates],
    (t) => t.parentId
  );

  const originalTemplateIdToFreshTemplateId = getOriginalTemplateIdToFreshTemplateId(data);

  return data.templateLinks.reduce((accum, link) => {
    const template = templatesByParentId[link.templateId];
    const newAccum = { ...accum };
    if (!template) {
      return newAccum;
    }
    let freshTemplateId;
    if (template.stale) {
      freshTemplateId = originalTemplateIdToFreshTemplateId[template.originalTemplateId];
    } else {
      freshTemplateId = template.parentId;
    }
    if (!freshTemplateId) {
      return newAccum;
    }
    if (!newAccum[freshTemplateId]) {
      newAccum[freshTemplateId] = [];
    }
    newAccum[freshTemplateId].push(link.accountId);
    return newAccum;
  }, {} as { [key: string]: string[] });
}

export function getAccountsWithExistingNames(
  data: GetModelTemplateCenterViewDataResponse,
  template: AllocationsTemplate | RestrictionsTemplate
) {
  const linkMap = groupBy(data.templateLinks, 'accountId');
  const templatesByParentId = keyBy(
    [...data.allocationsTemplates, ...data.restrictionsTemplates],
    'parentId'
  );

  return data.accounts.map((acc) => ({
    existingTemplateNames:
      linkMap[acc.id]
        ?.filter(
          (val) =>
            templatesByParentId[val.templateId] &&
            templatesByParentId[val.templateId].type === template.type
        )
        .map((val) => templatesByParentId[val.templateId].name) || [],
    ...acc,
  }));
}

export function rightPad<T>(arr: T[], n: number) {
  return Array(n)
    .fill(undefined)
    .map((val, i) => (arr[i] != null ? arr[i] : undefined));
}

export function tiltSelectionToLabel(tiltSelection?: AllocationsTemplate['tiltSelection']) {
  if (!tiltSelection) {
    return {
      type: 'None',
      value: 'N/A',
    };
  }
  if (!tiltSelection.isEnabled) {
    return {
      type: 'Passive Market (No Tilt)',
      value: 'N/A',
    };
  }
  return {
    type: ACTIVE_TILT_TYPE_TO_LABEL[tiltSelection.tiltType],
    value: tiltSelection.tiltAmount.toString(),
  };
}
