import { Box, Divider, Link as MuiLink, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { PerformanceChart } from '~/components/chart';
import { AdjustedSimulation } from '~/models/api';
import CardContent from '~/synth/CardContent';
import DisclosureExpansion from './DisclosureExpansion';

const MINIMIZED_MONTE_CARLO_DISCLOSURES = `The Monte Carlo simulation results above are provided for educational purposes and to model the probability of different outcomes of using Vise's services. This Monte Carlo model is intended to project how the portfolio may perform over a range of various market outcomes based on the composition of the portfolio, the investment time horizon, and any contributions.`;
const FULL_MONTE_CARLO_DISCLOSURES = (
  <>
    The Monte Carlo results are created using statistical sampling to generate a large number of
    possible future portfolio returns. The median return can be used as an illustration of one
    potential outcome, but actual returns are likely to be different.
    <Box mt={1}>
      Vise uses the initial portfolio for which we have calculated the expected mean and expected
      volatility. We simulate the daily return for the portfolio using 1000 random simulations over
      the investment horizon (random paths). From those simulations, Vise displays the 25th, 50th,
      75th, and 95th percentile to provide the user an illustration of how well or poorly the given
      portfolio could theoretically perform. For reference, the 25th percentile is a poor but
      possible outcome, where 25% of the simulated outcomes were at that level or worse, but 75%
      were better. The 75th percentile is an unusually good outcome. Only 25% of the simulated
      outcomes were better, but 75% were worse. Half of the simulated outcomes (50% of the total)
      fell between those two levels, creating a helpful range to look at when planning for
      retirement. See the Disclosures page for more information on hypothetical performance. For
      more information, see our{' '}
      <MuiLink component={Link} to="/secure/disclosures">
        Disclosures page
      </MuiLink>
      .
    </Box>
  </>
);

export default function PortfolioMonteCarloChart({
  datas,
  min,
  max,
  withNativeDateRangeSelector,
}: {
  datas: AdjustedSimulation[];
  min?: number;
  max?: number;
  withNativeDateRangeSelector?: boolean;
}) {
  const theme = useTheme();
  return (
    <>
      <PerformanceChart
        colors={[theme.palette.blue[500], theme.palette.blue[400], theme.palette.blue[300]]}
        datas={datas}
        view="simulation"
        min={min}
        max={max}
        withNativeDateRangeSelector={withNativeDateRangeSelector}
      />
      <Divider />
      <CardContent>
        <DisclosureExpansion
          minimizedDisclosures={MINIMIZED_MONTE_CARLO_DISCLOSURES}
          expandedDisclosures={FULL_MONTE_CARLO_DISCLOSURES}
        />
      </CardContent>
    </>
  );
}
