import { Box, BoxProps, Slider, SliderProps } from '@mui/material';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { TextHighlightTag } from '~/synth/Tag';

interface Props extends Omit<BoxProps, 'onChange'> {
  value: number;
  onChange: SliderProps['onChange'];
}

const StyledSlider = styled(Slider)`
  & .MuiSlider-dragging {
    border: 1px solid ${({ theme }) => theme.palette.grey[600]};
  }

  & .MuiSlider-rail {
    height: 4px;
    opacity: 1;
    color: ${({ theme }) => theme.palette.grey[300]};
  }

  & .MuiSlider-thumb {
    border: 1px solid ${({ theme }) => theme.palette.grey[800]};
  }

  & .MuiSlider-thumb::before {
    border: none;
  }

  & .MuiSlider-mark,
  .MuiSlider-markActive {
    background-color: white;
    color: white;
    opacity: 1;
    width: 5px;
  }

  & .MuiSlider-track {
    color: ${({ theme }) => theme.palette.grey[800]};
    height: 4px;
  }
`;

const MARKS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({ label: value, value }));

function TiltAmountSlider({ onChange, value, ...boxProps }: Props) {
  const handleOnChange = useCallback(
    (_event: Event, value: number | number[], activeThumb: number) => {
      if (value !== 0 && onChange) {
        onChange(_event, value, activeThumb);
      }
    },
    [onChange]
  );

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" {...boxProps}>
      <StyledSlider
        min={0}
        step={1}
        marks={MARKS}
        max={10}
        value={value}
        onChange={handleOnChange}
        valueLabelDisplay="off"
      />
      <TextHighlightTag
        ml={2}
        mt={0.5}
        px={0.5}
        py={0.5}
        height="100%"
        severity="info"
        minWidth="52px"
        textAlign="center"
      >{`${value}/10`}</TextHighlightTag>
    </Box>
  );
}

export default TiltAmountSlider;
