import { Box, Typography } from '@mui/material';
import React from 'react';

export const HouseholdStatHeader: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <Box mb={1.5}>
    <Typography variant="h4">
      <Box color="grey.600">{children}</Box>
    </Typography>
  </Box>
);

const HouseholdStat: React.FunctionComponent<React.PropsWithChildren<{ color?: string }>> = ({
  children,
  color,
}) => (
  <Box color={color}>
    <Typography variant="h2">{children}</Typography>
  </Box>
);

export default HouseholdStat;
