import useSWR from 'swr';
import { getPortfolioIntelligence } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getPortfolioIntelligenceSwrKey = (id: string) =>
  generateSwrKey(`portfolioIntelligence/${id}`);

export default function usePortfolioIntelligence(id: string | null | undefined, swrConfig?: {}) {
  return useSWR(
    id ? getPortfolioIntelligenceSwrKey(id) : null,
    id == null ? undefined : () => getPortfolioIntelligence(id),
    swrConfig
  );
}
