import React from "react";
import { SPACING_INDICES } from "../tokens/spacing.js";
import { useTheme, Box } from "@mui/material";
import { ExclamationCircleIcon, InformationCircleIcon } from "../index.js";
import Typography from "./Typography.js";

interface NotificationProps {
  variant: "notice" | "info";
  children: React.ReactNode;
  title?: React.ReactNode;
}

export default function Notification({
  variant,
  children,
  title,
}: NotificationProps): React.ReactElement {
  const theme = useTheme();
  let bgColor = "";
  let borderColor = "";
  let icon: React.ReactNode = null;
  if (variant === "notice") {
    bgColor = theme.palette.semanticOrange[100];
    borderColor = theme.palette.semanticOrange[600];
    icon = <ExclamationCircleIcon htmlColor={borderColor} />;
  } else if (variant === "info") {
    bgColor = theme.palette.primaryBlue[100];
    borderColor = theme.palette.primaryBlue[600];
    icon = <InformationCircleIcon htmlColor={borderColor} />;
  }

  return (
    <Box
      p={SPACING_INDICES.lg}
      border={`solid 1px ${borderColor}`}
      bgcolor={bgColor}
      display="flex"
      borderRadius="4px"
    >
      {icon}
      <Box ml={SPACING_INDICES.sm}>
        {title ? (
          <Box mb={SPACING_INDICES.lg}>
            <Typography variant="h4" color="neutralCool.1000">
              {title}
            </Typography>
          </Box>
        ) : null}
        <Typography variant="body1" color="neutralCool.900">
          {children}
        </Typography>
      </Box>
    </Box>
  );
}
