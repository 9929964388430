import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Alert from '@mui/material/Alert';
import Select from '~/synth/inputs/Select';
import TextField from '~/synth/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import { ReactComponent as TrashIcon } from '~/static/images/icons/trash.svg';
import { Redirect, useHistory } from 'react-router';
import { ProductOnboardingRoutes } from '~/routes/ProductOnboarding/routes';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as PlusIcon } from '~/static/images/icons/plus.svg';

import { TextHighlightTag } from '~/synth/Tag';
import useEnqueueToast from '~/hooks/useToast';
import { State, Action, CustodianSelectValues, isSamOptions } from './ClientOnboardingState';

export default function CustodianForm({
  state,
  dispatch,
  renderFooter,
}: {
  state: State;
  dispatch: React.Dispatch<Action>;
  renderFooter: () => React.ReactNode;
}) {
  // Ensure unique keys on account holder fields.
  const [accountHoldersFieldKeys, setAccountHoldersFieldKeys] = useState<string[]>(
    state.accountHolders?.map(() => uuidv4())
  );

  const theme = useTheme();
  const history = useHistory();
  const toast = useEnqueueToast();
  const { handleSubmit, control } = useForm({
    defaultValues: state,
    shouldUseNativeValidation: true,
  });

  if (!state.selectedClient) return <Redirect to={ProductOnboardingRoutes.selectClient} />;

  const onSubmit = () => {
    history.push(ProductOnboardingRoutes.review);
  };

  const onError = () => {
    toast({ title: 'Please select a custodian', severity: 'warning' });
  };

  const MandatoryAsterisk = () => <span style={{ color: theme.palette.orange[600] }}>*</span>;

  const isManagedByAnotherSam = state.managedByAnotherSam?.value === 'yes';
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Box p={4}>
          <Typography variant="h2" mb={4}>
            Authorization forms
          </Typography>
          <Typography>
            In order to add your client to the Vise platform, you&apos;ll need to fill out a few
            custodian authorization forms. To begin, please select the custodian type below.
            <p>
              Once complete, you&apos;ll be able to download a PDF document to then send to your
              client to sign.
            </p>
          </Typography>
          <Alert
            sx={{
              mb: 4,
              borderWidth: '1px',
              borderColor: 'blue.300',
              borderStyle: 'solid',
              width: '100%',
            }}
            severity="info"
          >
            Please be aware that in order this step to be considered complete, your client needs to
            sign the document.
          </Alert>

          <Typography variant="h2" mb={2}>
            Custodian info
          </Typography>
          <Grid container spacing={2} mb={2}>
            <Grid xs={6}>
              <Typography variant="h4" mb={1.5}>
                Select custodian
              </Typography>
              <Controller
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      name="custodian select"
                      required
                      value={value}
                      options={CustodianSelectValues}
                      onChange={(e) => {
                        onChange(e);
                        if (e)
                          dispatch({
                            type: 'custodian',
                            value: e as (typeof CustodianSelectValues)[number],
                          });
                      }}
                    />
                  );
                }}
                name="custodian"
                control={control}
              />
            </Grid>
            {state.custodian?.value === 'FIDELITY' && (
              <Grid xs={6}>
                <TextField
                  label={
                    <>
                      Existing fidelity account <MandatoryAsterisk />
                    </>
                  }
                  required
                  fullWidth
                  inputProps={{ maxLength: 9 }}
                  value={state.fidelityAccountNumber}
                  onChange={(e) =>
                    dispatch({ type: 'fidelityAccountNumber', value: e.target.value })
                  }
                />
              </Grid>
            )}
          </Grid>
          <Collapse in={Boolean(state.custodian)}>
            {state?.custodian && state?.custodian.value === 'SCHWAB' && (
              <div>
                <Typography variant="h2">Investor advisor (IA) information</Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  <Grid xs={6}>
                    <TextField
                      hidden
                      label={
                        <>
                          SL master account number <MandatoryAsterisk />
                        </>
                      }
                      value={state.slMasterAccountNumber}
                      inputProps={{ maxLength: 32, required: true }}
                      onChange={(e) =>
                        dispatch({ type: 'slMasterAccountNumber', value: e.currentTarget.value })
                      }
                      fullWidth
                    />
                    <p color="grey.600">
                      If you don&apos;t already have SL master account number, reach out to the
                      client success team here.{' '}
                    </p>
                  </Grid>
                  <Grid xs={6}>
                    <FormControl>
                      <FormLabel>Is this a new account in your advisor’s book?</FormLabel>
                      <RadioGroup
                        row
                        value={state.isNewAccountInAdvisorsBook ? 'yes' : 'no'}
                        onChange={(change) =>
                          dispatch({
                            type: 'isNewAccountInAdvisorsBook',
                            value: change.target.value === 'yes',
                          })
                        }
                      >
                        <FormControlLabel control={<Radio />} label="No" value="no" />
                        <FormControlLabel control={<Radio />} label="Yes" value="yes" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid xs={6}>
                    <TextField
                      value={state.faMasterAccountNumber}
                      onChange={(change) =>
                        dispatch({ type: 'faMasterAccountNumber', value: change.target.value })
                      }
                      required={state.isNewAccountInAdvisorsBook}
                      disabled={!state.isNewAccountInAdvisorsBook}
                      label={
                        state.isNewAccountInAdvisorsBook ? (
                          <>
                            FA master account number <MandatoryAsterisk />{' '}
                          </>
                        ) : (
                          'FA master account number'
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Typography variant="h2" mb={2} mt={4}>
                  Account holder information
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2} sx={{ mb: 4 }}>
                  <Grid xs={6}>
                    <TextField
                      label={
                        <>
                          Brokerage account number <MandatoryAsterisk />{' '}
                        </>
                      }
                      type="text"
                      required
                      inputProps={{ maxLength: 22, required: true }}
                      value={state.brokerageAccountNumber}
                      onChange={(e) =>
                        dispatch({ type: 'brokerageAccountNumber', value: e.currentTarget.value })
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {state?.custodian?.value === 'FIDELITY' && (
              <Box mb={4}>
                <Typography variant="h2">Separately managed account information</Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  <Grid xs={6}>
                    <Select
                      required
                      value={state.managedByAnotherSam}
                      options={isSamOptions}
                      onChange={(e) =>
                        dispatch({
                          type: 'managedByAnotherSam',
                          value: e as (typeof isSamOptions)[number],
                        })
                      }
                    />
                    <p>
                      Is this account currently being managed by another Separate Account Manager
                      (SAM)?
                    </p>
                  </Grid>

                  {isManagedByAnotherSam && (
                    <>
                      <Grid xs={12} mb={4}>
                        <Alert severity="warning" style={{ maxWidth: '100%' }}>
                          Complete the below fields to terminate SAM from current account.
                        </Alert>
                      </Grid>
                      <Grid xs={6}>
                        <TextField
                          required
                          value={state.previousSamName}
                          onChange={(e) =>
                            dispatch({ type: 'previousSamName', value: e.target.value })
                          }
                          fullWidth
                          label={
                            <>
                              SAM firm name <MandatoryAsterisk />
                            </>
                          }
                        />
                        <p>This will refer to the current SAM firm name.</p>
                      </Grid>
                      <Grid xs={6}>
                        <TextField
                          required
                          value={state.previousSamProductName}
                          fullWidth
                          onChange={(e) =>
                            dispatch({ type: 'previousSamProductName', value: e.target.value })
                          }
                          label={
                            <>
                              SAM product name <MandatoryAsterisk />
                            </>
                          }
                        />
                        <p>This will refer to the current SAM product name.</p>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            )}

            {state?.custodian && (
              <Grid container spacing={2} mb={6}>
                <Grid xs={5}>
                  <TextField
                    value={state.currentAccountInformation.firstName}
                    required
                    onChange={(e) =>
                      dispatch({
                        type: 'currentAccountInformation',
                        value: {
                          ...state.currentAccountInformation,
                          firstName: e.currentTarget.value,
                        },
                      })
                    }
                    label={
                      <>
                        First Name <MandatoryAsterisk />
                      </>
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    value={state.currentAccountInformation.middleName}
                    onChange={(e) =>
                      dispatch({
                        type: 'currentAccountInformation',
                        value: {
                          ...state.currentAccountInformation,
                          middleName: e.currentTarget.value,
                        },
                      })
                    }
                    label="Middle Name"
                    fullWidth
                  />
                </Grid>
                <Grid xs={4}>
                  <TextField
                    value={state.currentAccountInformation.lastName}
                    required
                    onChange={(e) =>
                      dispatch({
                        type: 'currentAccountInformation',
                        value: {
                          ...state.currentAccountInformation,
                          lastName: e.currentTarget.value,
                        },
                      })
                    }
                    label={
                      <>
                        Last Name <MandatoryAsterisk />
                      </>
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}

            <Box>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h2">
                  Additional account holders{' '}
                  <TextHighlightTag severity="priority">Optional</TextHighlightTag>
                </Typography>
                <Box>
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    sx={{ fontWeight: 700 }}
                    startIcon={<PlusIcon />}
                    disabled={
                      state.accountHolders.length >= (state?.custodian?.value === 'SCHWAB' ? 4 : 3)
                    }
                    onClick={() => {
                      dispatch({
                        type: 'accountHolders',
                        value: [
                          ...state.accountHolders,
                          { firstName: '', middleName: '', lastName: '' },
                        ],
                      });

                      setAccountHoldersFieldKeys([...accountHoldersFieldKeys, uuidv4()]);

                      // Scroll down with a slight delay, so that user can see the
                      // new fields added at the bottom.
                      setTimeout(() => {
                        window.scrollTo({
                          top: document.body.scrollHeight,
                          behavior: 'smooth',
                        });
                      }, 200);
                    }}
                    aria-label="Add account holder"
                  >
                    Add
                  </Button>
                </Box>
              </Box>
              <Divider sx={{ my: 1 }} />
            </Box>
            <Box mt={2} mb={10}>
              {state.accountHolders.length > 0 &&
                accountHoldersFieldKeys.map((key, i) => (
                  <Grid container spacing={2} mb={1} key={key}>
                    <Grid xs={4}>
                      <TextField
                        required
                        label={
                          <>
                            First name <MandatoryAsterisk />
                          </>
                        }
                        value={state.accountHolders[i]?.firstName}
                        onChange={(e) => {
                          dispatch({
                            type: 'accountHolders',
                            value: state.accountHolders.map((v, j) => ({
                              ...v,
                              ...(j === i && { firstName: e.currentTarget.value }),
                            })),
                          });
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={3}>
                      <TextField
                        label="Middle name"
                        value={state.accountHolders[i]?.middleName}
                        onChange={(e) => {
                          dispatch({
                            type: 'accountHolders',
                            value: state.accountHolders.map((v, j) => ({
                              ...v,
                              ...(j === i && { middleName: e.currentTarget.value }),
                            })),
                          });
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={4}>
                      <TextField
                        label={
                          <>
                            Last name <MandatoryAsterisk />
                          </>
                        }
                        required
                        value={state.accountHolders[i]?.lastName}
                        onChange={(e) => {
                          dispatch({
                            type: 'accountHolders',
                            value: state.accountHolders.map((v, j) => ({
                              ...v,
                              ...(j === i && { lastName: e.currentTarget.value }),
                            })),
                          });
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={1} pt={5}>
                      <IconButton
                        onClick={() => {
                          dispatch({
                            type: 'accountHolders',
                            value: state.accountHolders.filter((_, j) => j !== i),
                          });

                          setAccountHoldersFieldKeys(
                            accountHoldersFieldKeys.filter((k) => k !== key)
                          );
                        }}
                        aria-label="Remove account holder"
                      >
                        <TrashIcon height="20" width="20" />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
            </Box>
          </Collapse>
          {renderFooter()}
        </Box>
      </form>
    </>
  );
}
