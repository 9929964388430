import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import React from 'react';
import { AssetClass, AssetClassKey } from 'vise-types/pce2_instrument';
import { AssetClassAllocation } from 'vise-types/portfolio_metrics';
import { useAssetToColorMap } from '~/hooks/useAssetToColorMap';
import { formatPercent } from '~/utils/format';
import { getPCE2AssetClassTitleAndSubtitle } from '../../portfolioUtil';
import Truncation from '../../components/Truncation';

function AllocationBar({
  assetClass,
  allocation,
  color,
}: {
  assetClass: AssetClass;
  allocation: number;
  color: string;
}) {
  const { title, subtitle } = getPCE2AssetClassTitleAndSubtitle(
    assetClass.join('/') as AssetClassKey
  );

  return (
    <Grid item container>
      <Grid item xs={6}>
        <Box fontSize={12} fontWeight={500} color={tokens.palette.neutralCool[600]}>
          <Box display="flex" justifyContent="space-between">
            <Box width="75%">
              <Truncation>{`${title} ${subtitle ?? ''}`}</Truncation>
            </Box>
            <div>{formatPercent(allocation)}</div>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <LinearProgress
          sx={{
            marginLeft: 2,
            borderRadius: '0 8px 8px 0',
            height: 12,
            width: '100%',
            background: tokens.palette.neutralCool[200],
            '.MuiLinearProgress-bar': { borderRadius: '8px', background: color },
          }}
          variant="determinate"
          value={allocation * 100}
        />
      </Grid>
    </Grid>
  );
}

export default function AssetAllocationBreakdown({
  currentAllocation,
  targetAllocation,
}: {
  currentAllocation: AssetClassAllocation;
  targetAllocation: AssetClassAllocation;
}) {
  const currentEquities = currentAllocation.filter((a) => a.assetClass[0] === 'EQUITY');
  const currentFI = currentAllocation.filter((a) => a.assetClass[0] === 'FIXED_INCOME');
  const currentAlts = currentAllocation.filter((a) => a.assetClass[0] === 'ALTERNATIVES');
  const currentCash = currentAllocation.filter((a) => a.assetClass[0] === 'CASH');

  const targetEquities = targetAllocation.filter((a) => a.assetClass[0] === 'EQUITY');
  const targetFI = targetAllocation.filter((a) => a.assetClass[0] === 'FIXED_INCOME');
  const targetAlts = targetAllocation.filter((a) => a.assetClass[0] === 'ALTERNATIVES');
  const targetCash = targetAllocation.filter((a) => a.assetClass[0] === 'CASH');

  const colors = useAssetToColorMap();

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" pb={5}>
      <Grid container spacing={2}>
        {(currentEquities.length > 0 || targetEquities.length > 0) && (
          <>
            <Grid item xs={6}>
              <Box
                py={0.5}
                px={2}
                mb={2}
                bgcolor={tokens.palette.secondaryPurple[100]}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h5">Equity</Typography>
                <Typography variant="h5">
                  {formatPercent(currentEquities.reduce((acc, e) => acc + e.allocationFraction, 0))}
                </Typography>
              </Box>
              <Box px={2}>
                <Grid container spacing={1}>
                  {currentEquities.map((e, i) => (
                    <AllocationBar
                      key={e.assetClass.join('/')}
                      assetClass={e.assetClass}
                      allocation={e.allocationFraction}
                      color={colors.EQUITY.colors[colors.EQUITY.colors.length - i - 1]}
                    />
                  ))}
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                py={0.5}
                px={2}
                mb={2}
                bgcolor={tokens.palette.secondaryPurple[100]}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h5">Equity</Typography>
                <Typography variant="h5">
                  {formatPercent(targetEquities.reduce((acc, e) => acc + e.allocationFraction, 0))}
                </Typography>
              </Box>
              <Box px={2}>
                <Grid container spacing={1}>
                  {targetEquities.map((e, i) => (
                    <AllocationBar
                      key={e.assetClass.join('/')}
                      assetClass={e.assetClass}
                      allocation={e.allocationFraction}
                      color={colors.EQUITY.colors[colors.EQUITY.colors.length - i - 1]}
                    />
                  ))}
                </Grid>
              </Box>
            </Grid>
          </>
        )}
        {(currentFI.length > 0 || targetFI.length > 0) && (
          <>
            <Grid item xs={6}>
              <Box
                py={0.5}
                px={2}
                my={2}
                bgcolor={tokens.palette.primaryBlue[100]}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h5">Fixed Income</Typography>
                <Typography variant="h5">
                  {formatPercent(currentFI.reduce((acc, e) => acc + e.allocationFraction, 0))}
                </Typography>
              </Box>
              <Box px={2}>
                <Grid container spacing={1}>
                  {currentFI.map((e, i) => (
                    <AllocationBar
                      key={e.assetClass.join('/')}
                      assetClass={e.assetClass}
                      allocation={e.allocationFraction}
                      color={colors.FIXED_INCOME.colors[i]}
                    />
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                py={0.5}
                px={2}
                my={2}
                bgcolor={tokens.palette.primaryBlue[100]}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h5">Fixed Income</Typography>
                <Typography variant="h5">
                  {formatPercent(targetFI.reduce((acc, e) => acc + e.allocationFraction, 0))}
                </Typography>
              </Box>
              <Box px={2}>
                <Grid container spacing={1}>
                  {targetFI.map((e, i) => (
                    <AllocationBar
                      key={e.assetClass.join('/')}
                      assetClass={e.assetClass}
                      allocation={e.allocationFraction}
                      color={colors.FIXED_INCOME.colors[i]}
                    />
                  ))}
                </Grid>
              </Box>
            </Grid>
          </>
        )}
        {(currentAlts.length > 0 || targetAlts.length > 0) && (
          <>
            <Grid item xs={6}>
              <Box
                py={0.5}
                px={2}
                my={2}
                bgcolor={tokens.palette.secondaryMagenta[100]}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h5">Alternatives</Typography>
                <Typography variant="h5">
                  {formatPercent(currentAlts.reduce((acc, e) => acc + e.allocationFraction, 0))}
                </Typography>
              </Box>
              <Box px={2}>
                <Grid container spacing={1}>
                  {currentAlts.map((e, i) => (
                    <AllocationBar
                      key={e.assetClass.join('/')}
                      assetClass={e.assetClass}
                      allocation={e.allocationFraction}
                      color={colors.ALTERNATIVES.colors[i]}
                    />
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                py={0.5}
                px={2}
                my={2}
                bgcolor={tokens.palette.secondaryMagenta[100]}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h5">Alternatives</Typography>
                <Typography variant="h5">
                  {formatPercent(targetAlts.reduce((acc, e) => acc + e.allocationFraction, 0))}
                </Typography>
              </Box>
              <Box px={2}>
                <Grid container spacing={1}>
                  {targetAlts.map((e, i) => (
                    <AllocationBar
                      key={e.assetClass.join('/')}
                      assetClass={e.assetClass}
                      allocation={e.allocationFraction}
                      color={colors.ALTERNATIVES.colors[i]}
                    />
                  ))}
                </Grid>
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Box
            py={0.5}
            px={2}
            my={2}
            bgcolor={tokens.palette.secondaryTurquoise[100]}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="h5">Cash</Typography>
            <Typography variant="h5">
              {formatPercent(currentCash[0]?.allocationFraction ?? 0)}
            </Typography>
          </Box>
          <Box px={2}>
            <Grid container spacing={1}>
              {currentCash.map((e) => (
                <AllocationBar
                  key={e.assetClass.join('/')}
                  assetClass={e.assetClass}
                  allocation={e.allocationFraction}
                  color={colors.CASH.colors[0]}
                />
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            py={0.5}
            px={2}
            my={2}
            bgcolor={tokens.palette.secondaryTurquoise[100]}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="h5">Cash</Typography>
            <Typography variant="h5">{formatPercent(targetCash[0]?.allocationFraction)}</Typography>
          </Box>
          <Box px={2}>
            <Grid container spacing={1}>
              {targetCash.map((e) => (
                <AllocationBar
                  key={e.assetClass.join('/')}
                  assetClass={e.assetClass}
                  allocation={e.allocationFraction}
                  color={colors.CASH.colors[0]}
                />
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            px={2}
            mt={1}
            pt={0.5}
            pb={2}
            fontSize={12}
            fontWeight={500}
            color={tokens.palette.neutralCool[600]}
            borderTop={1}
            borderColor={tokens.palette.neutralCool[300]}
          >
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                % of portfolio
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="space-between">
                  {[0, 25, 50, 75, 100].map((p) => (
                    <Box key={p}>{p}%</Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            px={2}
            mt={1}
            pt={0.5}
            pb={2}
            fontSize={12}
            fontWeight={500}
            color={tokens.palette.neutralCool[600]}
            borderTop={1}
            borderColor={tokens.palette.neutralCool[300]}
          >
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                % of portfolio
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="space-between">
                  {[0, 25, 50, 75, 100].map((p) => (
                    <Box key={p}>{p}%</Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
