import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';

export default function Truncation({
  style,
  children,
}: {
  style?: React.CSSProperties;
  children: string | null | undefined;
}) {
  const divRef = React.useRef<HTMLDivElement>();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    function compareSize() {
      clearTimeout(timeoutId);
      // Debounce mechanism to prevent this from firing continuously while the user
      // is actively resizing the window.
      timeoutId = global.setTimeout(
        () =>
          setIsEllipsisActive(
            divRef.current == null ? false : divRef.current.clientWidth < divRef.current.scrollWidth
          ),
        150
      );
    }
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  if (children == null) {
    return null;
  }

  return (
    <Tooltip title={children} disableHoverListener={!isEllipsisActive}>
      {/* It would make more sense to use a Box here but that is not currently supported.
            https://github.com/mui-org/material-ui/issues/19284 */}
      <div
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', ...style }}
        ref={(nextRef) => {
          divRef.current = nextRef ?? undefined;
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
}
