import { Box, Card, Divider } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import useDistributionSchedule from '~/hooks/useDistributionSchedule';
import Distributions from '../../Distributions/Distributions';
import getColumns from '../../Distributions/getColumns';
import CardDisclosures from './CardDisclosures';
import GPTCardHeader from './GPTCardHeader';
import LoadingCard from './LoadingCard';
import useAccountWithLogging from './useAccountWithLogging';
import usePortfolioIntelligenceWithLogging from './usePortfolioIntelligenceWithLogging';
import usePortfolioMetricsWithLogging from './usePortfolioMetricsWithLogging';

export default function GPTDistributionsCard({ accountId }: { accountId?: string }) {
  const { data: accountData } = useAccountWithLogging({
    accountId,
    callerName: 'GPTDistributionsCard',
  });
  const { data: intelligenceArrayData } = usePortfolioIntelligenceWithLogging(
    accountData?.data?.portfolioIntelligenceId,
    'GPTDistributionsCard'
  );

  const intelligence = intelligenceArrayData?.[0];

  const {
    data: distributionSchedule,
    error: distributionsError,
    mutate: mutateDistributionSchedule,
  } = useDistributionSchedule(
    intelligence?.constructionInfo.clientId,
    accountData?.data?.custodianKey,
    accountData?.data?.accountNumber
  );

  const { data: pce2MetricsResponseData } = usePortfolioMetricsWithLogging(
    accountId,
    'GPTDistributionsCard'
  );

  useEffect(() => {
    if (distributionsError != null) {
      Sentry.captureException(
        new Error(
          `[GPTDistributionsCard] Failed to load metrics for account ${accountId}. ${distributionsError}`
        ),
        {
          extra: {
            accountId,
            accountNumber: accountData?.data?.accountNumber,
            custodianKey: accountData?.data?.custodianKey,
            clientId: intelligence?.constructionInfo.clientId,
          },
        }
      );
    }
  }, [
    accountData?.data?.accountNumber,
    accountData?.data?.custodianKey,
    accountId,
    distributionsError,
    intelligence?.constructionInfo.clientId,
  ]);

  if (
    accountData == null ||
    intelligence == null ||
    distributionSchedule == null ||
    pce2MetricsResponseData == null
  ) {
    return <LoadingCard contentHeight="350px" />;
  }

  const cashToWithdraw = pce2MetricsResponseData.portfolioCashInformation?.cashToWithdraw;

  const { data: account } = accountData;

  return (
    <Card style={{ overflow: 'visible' }}>
      <GPTCardHeader
        title="Distributions"
        buttonPathname={`/secure/accounts/${accountId}/distributions`}
        buttonCopy="View distributions"
        account={account}
      />
      <Box height="350px">
        <Distributions
          distributionSchedule={distributionSchedule}
          cashToWithdraw={cashToWithdraw}
          account={account}
          columns={getColumns()}
          mutateDistributionSchedule={mutateDistributionSchedule}
          showTableOnly
        />
      </Box>
      <Divider />
      <CardDisclosures />
    </Card>
  );
}
