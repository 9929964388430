import useSWR from 'swr';
import { getAccount } from '~/api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useAccount(
  accountId: string | null | undefined,
  includeSummary?: boolean,
  includeAllocationChanges?: boolean
) {
  return useSWR(
    accountId == null
      ? null
      : generateSwrKey(
          `/portfolio/accounts/${accountId}${includeSummary ? '?include_summary=true&' : ''}${
            includeAllocationChanges ? 'include_allocation_changes=true' : ''
          }`
        ),
    accountId == null
      ? undefined
      : () => getAccount(accountId, includeSummary, includeAllocationChanges)
  );
}
