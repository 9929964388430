import axios, { AxiosError } from 'axios';
import { doRequest, doRequestWithAuth } from './apiUtil';

type RegisterRequestPayload = {
  email: string;
  password: string;
  confirmPassword: string;
  acceptedTerms: boolean;
};

export async function register(payload: RegisterRequestPayload) {
  try {
    await doRequest({
      method: 'POST',
      requestUrl: '/auth/register',
      data: payload,
      shouldRevalidate: false,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const errorData = (err as AxiosError<{ errors: { msg: string }[] }>).response?.data;
      throw errorData;
    }
    throw err;
  }
}

export async function confirmEmail(confirmationCode: string) {
  await doRequest({
    method: 'POST',
    requestUrl: '/auth/confirm_email',
    data: { confirmationCode },
    shouldRevalidate: false,
  });
}

export async function inviteToRegister(payload: { inviteeEmail: string; inviteeName?: string }[]) {
  await doRequestWithAuth({
    method: 'POST',
    requestUrl: '/auth/send_registration_invite',
    data: payload,
    shouldRevalidate: false,
  });
}
