import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Dictionary } from 'lodash';
import React from 'react';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import { ReactComponent as ChevronRight } from '~/static/images/icons/chevron-right.svg';
import { formatCurrency, maskAccountNumber } from '~/utils/format';
import renderRebalancerStatus from '../Households/components/renderRebalancerStatus';
import Truncation from '../Portfolio/components/Truncation';
import {
  CADENCE_TO_LABEL,
  sortByDistributionStatus,
  sortByNextDistribution,
  sortByProcessUntil,
} from './Constants';
import { getCadenceDisplayText } from './DistributionDetailsPanel';
import DistributionStatusPill from './components/DistributionStatusPill';
import { DateCell, DescriptionCell } from './components/UtilComponents';

export default function getColumns(
  accountsKeyById?: Dictionary<AccountWithPIAndHouseholdInfo>
): GridColDef[] {
  return [
    ...(accountsKeyById
      ? [
          {
            renderHeader: () => <Box ml={8}>Account</Box>,
            field: 'accountName',
            flex: 1.5,
            renderCell: (props) =>
              props.value != null &&
              props.row.custodianCode != null &&
              props.row.accountNumber != null ? (
                <DescriptionCell
                  title={props.value}
                  subtitle={`${props.row.custodianCode}, ${maskAccountNumber(
                    props.row.accountNumber
                  )}`}
                  paused={props.row.status === 'PAUSED'}
                  lowBalance={props.row.lowBalance === true}
                />
              ) : null,
          },
          {
            headerName: 'Rebalancer status',
            field: 'accountId',
            renderCell: (props) =>
              props.value
                ? renderRebalancerStatus({
                    value: {
                      status: accountsKeyById[props.value].rebalancerStatus,
                      rebalancerPausedAt: accountsKeyById[props.value].rebalancerPausedAt,
                    },
                  })
                : null,
            flex: 0.8,
            sortComparator: (rowA) => {
              const rebalancerStatus = rowA ? accountsKeyById[rowA].rebalancerStatus : null;

              return rebalancerStatus === 'ACTIVE' ? 1 : -1;
            },
          },
        ]
      : []),
    {
      headerName: 'Description',
      field: 'description',
      flex: 1,
      renderCell: (props) => (
        <Box width="100%">
          <Truncation>
            {props.value ?? `${getCadenceDisplayText(props.row.cadence)} distribution`}
          </Truncation>
          <Box color="grey.600">
            <Typography variant="body1">
              {props.value && getCadenceDisplayText(props.row.cadence)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      headerName: 'Amount',
      field: 'amount',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: (props) => formatCurrency(props.value),
    },
    {
      headerName: 'Frequency',
      field: 'cadence',
      flex: 0.8,
      renderCell: (props) => <Box color="grey.600">{CADENCE_TO_LABEL[props.value]}</Box>,
    },
    {
      headerName: 'Next distribution',
      field: 'nextRuns',
      flex: 1,
      renderCell: (props) => (
        <DateCell date={props.value != null && props.value.length > 0 ? props.value?.[0] : null} />
      ),
      sortComparator: sortByNextDistribution,
    },
    ...(accountsKeyById
      ? []
      : [
          {
            headerName: 'Ends on',
            field: 'processUntil',
            flex: 1,
            renderCell: (props) => <DateCell date={props.value} />,
            sortComparator: sortByProcessUntil,
          },
        ]),
    {
      headerName: 'Status',
      field: 'status',
      renderCell: (props) =>
        props.value != null ? <DistributionStatusPill status={props.value} /> : <div />,
      flex: 0.9,
      sortComparator: sortByDistributionStatus,
    },
    {
      headerName: '',
      field: 'arrow',
      renderCell: () => <ChevronRight />,
      flex: 0.5,
      sortable: false,
    },
  ];
}
