import { Box, Button, Typography } from '@mui/material';
import React, { ChangeEvent, useMemo } from 'react';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { useCoachmarkEffect } from '~/hooks/useCoachmark';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import Banner from '~/synth/Banner';
import SelectorCheckbox, { SelectorCheckboxGroup } from '~/synth/SelectorCheckbox';
import { TextHighlightTag } from '~/synth/Tag';
import amplitude from '~/utils/amplitude';
import {
  SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL,
  SMALL_ACCOUNT_EQUITY_ASSET_CLASS_KEYS,
  SMALL_ACCOUNT_FI_ASSET_CLASS_KEYS,
} from '../../Constants';
import { ActionFooter, BackButton, ContentBox } from '../components';
import ReturnToSummary from '../../ReturnToSummary';
import { ScreenProps } from '../../Types';

const ASSET_CLASS_SELECTION_ERROR_MESSAGE =
  'Select at least one asset class from Equities or Fixed Income.' as const;

const AssetClassLabel = ({ children }: { children: React.ReactNode }) => (
  <Box display="flex" alignItems="center">
    <Box mr={1}>{children}</Box>
    <TextHighlightTag>ETF</TextHighlightTag>
  </Box>
);

export default function PCE2SmallAccountAssetClassScreen({
  dpDispatch,
  draftPortfolio,
  onBack,
  onContinue,
}: ScreenProps) {
  const { constructionInfo } = draftPortfolio;
  const exclusionsSet = useMemo(
    () => new Set(constructionInfo.assetClassConcentrationLimits?.exclusions),
    [constructionInfo.assetClassConcentrationLimits?.exclusions]
  );

  function handleToggleAssetClass(event: ChangeEvent<HTMLInputElement>) {
    const assetClassKey = event.target.name as AssetClassKey;
    let amplitudeEventName: string;
    if (exclusionsSet.has(assetClassKey)) {
      amplitudeEventName = 'Deselect';
      exclusionsSet.delete(assetClassKey);
    } else {
      amplitudeEventName = 'Select';
      exclusionsSet.add(assetClassKey);
    }
    amplitude().logEvent(`${amplitudeEventName} asset class`, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      assetClass: assetClassKey,
    });
    dpDispatch({
      type: 'PCE2_SET_CONCENTRATION_LIMITS_EXCLUSIONS',
      exclusions: Array.from(exclusionsSet),
    });
  }
  let eventText: string;

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (
      constructionInfo.existingPortfolio !== 'sample-portfolio' &&
      constructionInfo.existingPortfolio?.taxable
    ) {
      onContinue('capGains');
      eventText = 'tax management';
      return;
    }
    onContinue();
    eventText = 'summary';
    amplitude().logEvent(`Continue to ${eventText}`, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }

  const validAssetClassSelection = useMemo(
    () =>
      ![...SMALL_ACCOUNT_EQUITY_ASSET_CLASS_KEYS, ...SMALL_ACCOUNT_FI_ASSET_CLASS_KEYS].every((a) =>
        exclusionsSet.has(a)
      ),
    [exclusionsSet]
  );

  useCoachmarkEffect({
    show: !validAssetClassSelection,
    title: ASSET_CLASS_SELECTION_ERROR_MESSAGE,
    severity: 'error' as const,
  });

  return (
    <ContentBox>
      <Box mb={2}>
        <Typography variant="h1">Review the asset classes for this portfolio</Typography>
      </Box>
      <Box color="grey.600" mb={3}>
        Vise will build a target portfolio using the selected asset classes below.
      </Box>
      <Banner
        size="small"
        borderColor="blue.200"
        message={
          <Box display="flex" alignItems="center" color="blue.400">
            <InformationCircleIcon width={16} height={16} />
            <Box ml={1} color="grey.800">
              Only ETFs available due to low account value.
            </Box>
          </Box>
        }
      />
      <form onSubmit={handleSubmit}>
        <Box mt={3}>
          <Typography variant="h4">Asset classes</Typography>
          <Box mt={1.5} mb={4}>
            <Box borderRadius="1px" bgcolor="grey.100" py={1} px={1.5}>
              <Typography variant="h5">Equities</Typography>
            </Box>
            <SelectorCheckboxGroup>
              {SMALL_ACCOUNT_EQUITY_ASSET_CLASS_KEYS.map((equity) => (
                <SelectorCheckbox
                  key={equity}
                  onChange={handleToggleAssetClass}
                  label={
                    <AssetClassLabel>
                      {SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL[equity]}
                    </AssetClassLabel>
                  }
                  name={equity}
                  checked={!exclusionsSet.has(equity)}
                />
              ))}
            </SelectorCheckboxGroup>
          </Box>
          <Box borderRadius="1px" bgcolor="grey.100" py={1} px={1.5}>
            <Typography variant="h5">Fixed income</Typography>
          </Box>
          <SelectorCheckboxGroup>
            {SMALL_ACCOUNT_FI_ASSET_CLASS_KEYS.map((fi) => (
              <SelectorCheckbox
                key={fi}
                onChange={handleToggleAssetClass}
                label={
                  <AssetClassLabel>{SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL[fi]}</AssetClassLabel>
                }
                name={fi}
                checked={!exclusionsSet.has(fi)}
              />
            ))}
          </SelectorCheckboxGroup>
        </Box>
        <ActionFooter borderTop={0} justifyContent="space-between">
          <BackButton onClick={() => onBack()} />
          <Box display="flex">
            <ReturnToSummary
              draftPortfolio={draftPortfolio}
              disabled={!validAssetClassSelection}
              onReturnToSummary={() => onContinue()}
              mr={1.5}
            />
            <Button
              disabled={!validAssetClassSelection}
              color="primary"
              type="submit"
              variant="contained"
            >
              Continue
            </Button>
          </Box>
        </ActionFooter>
      </form>
    </ContentBox>
  );
}
