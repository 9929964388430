import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as ThumbsUp } from '~/static/images/icons/thumbs-up.svg';
import { ReactComponent as ThumbsDown } from '~/static/images/icons/thumbs-down.svg';
import { ReactComponent as CheckIcon } from '~/static/images/icons/check.svg';
import { ReactComponent as ViseLogo } from '~/static/images/icons/vise-logo-black.svg';

import LoadingSpinner from '~/synth/LoadingSpinner';
import PopoverLink from '~/synth/PopoverLink';
import PopoverTrigger from '~/synth/PopoverTrigger';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import DisplayMessage from './MessageTyper';
import ValidationsPopover from './ValidationsPopover';
import FeedbackModal from './FeedbackModal';

export const LoadingSteps = ({ steps }: { steps: { name: string; isLoading: boolean }[] }) => {
  return (
    <Box>
      {steps.map((step) => (
        <Box key={step.name} mb={1}>
          <Box
            borderRadius="20px"
            border="1px solid"
            borderColor="grey.200"
            bgcolor="#FAFAFA"
            mr={1}
            py={0.75}
            px={1.5}
            display="flex"
            alignItems="center"
            columnGap={1}
            width="fit-content"
            height={37}
          >
            {step.isLoading ? (
              <Box flexShrink={0}>
                <LoadingSpinner size={20} color="grey.300" />
              </Box>
            ) : (
              <CheckIcon width={20} height={20} color="#04A77D" />
            )}
            <Typography color={step.isLoading ? 'black' : 'grey.400'}>{step.name}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const AssistantMessage = ({
  content,
  shouldType,
  rating,
  onRatingClick,
  steps,
  loading,
  setTypingFinished,
  typingFinished,
  messageId,
  validations,
  isOutdated = false,
}) => {
  const [hovered, setHovered] = useState('');
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const { data: featureFlags } = useFeatureFlags();

  let parsedContent = '';
  if (content) {
    try {
      parsedContent = JSON.parse(content).answer;
    } catch {
      parsedContent = content;
    }
  }

  const opacityRating = (ratingValue, expected, isHovered) => {
    if (ratingValue === expected || isHovered) {
      return '1.0';
    }
    return '0.3';
  };

  return (
    <>
      <Box mb={3}>
        {shouldType && !steps.every((s) => !s.isLoading) && <LoadingSteps steps={steps} />}
      </Box>
      {parsedContent.length > 0 && (
        <Box
          border="1px solid #F7F7F7"
          borderRadius="4px"
          bgcolor="rgba(250, 250, 250, 0.20)"
          boxShadow="0px 6px 16px 0px rgba(0, 0, 0, 0.05)"
          sx={{ backdropFilter: 'blur(20px)' }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            width="100%"
            height="100%"
            my={2.5}
            ml={3}
            gap={1}
          >
            <ViseLogo width="24" height="24" />

            <Box>
              <Typography sx={{ lineHeight: '20px', opacity: 0.6 }}>Vise Intelligence</Typography>
            </Box>
          </Box>

          <Box
            border="1px solid #F7F7F7"
            id="inner-message-box"
            mx={2.5}
            bgcolor="rgba(255, 255, 255, 0.60)"
            borderRadius="6px"
            boxShadow="0px 2px 2px 0px rgba(0, 0, 0, 0.05)"
            sx={{ backdropFilter: 'blur(40px)' }}
            mb={2.5}
          >
            <Box m={3} id="message-content-box">
              <DisplayMessage
                finalText={parsedContent}
                shouldType={shouldType}
                loading={loading}
                setTypingFinished={setTypingFinished}
                hideVisuals={isOutdated}
              />
              {isOutdated && (
                <Box mt={5} display="flex" alignItems="center" gap={1}>
                  <Typography variant="body2" color="textSecondary">
                    <span role="img" aria-label="warning">
                      ⚠️
                    </span>
                    &nbsp; Any visuals in this response may now be outdated and, as such, have been
                    hidden.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          {parsedContent && typingFinished && (
            <Box display="flex" alignItems="center" mb={2.5} mx={3}>
              <ThumbsDown
                style={{
                  cursor: 'pointer',
                  marginRight: '10px',
                  opacity: opacityRating(rating, -1, hovered === 'down'),
                }}
                onClick={() => onRatingClick(-1)}
                onMouseEnter={() => setHovered('down')}
                onMouseLeave={() => setHovered('')}
                width="30"
                height="30"
              />
              <ThumbsUp
                style={{
                  cursor: 'pointer',
                  opacity: opacityRating(rating, 1, hovered === 'up'),
                }}
                onClick={() => onRatingClick(1)}
                onMouseEnter={() => setHovered('up')}
                onMouseLeave={() => setHovered('')}
                width="30"
                height="30"
              />
              <Box ml={2} display="flex" gap={0.5}>
                <Typography variant="body2" color="textSecondary">
                  Was this response helpful? Leave feedback
                </Typography>
                <Button
                  onClick={() => setShowFeedbackModal(true)}
                  sx={{
                    textTransform: 'none',
                    minWidth: 0,
                    padding: 0,
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  <Typography variant="body2">here.</Typography>
                </Button>
              </Box>

              <Box flex={1} />
              {validations.length > 0 && featureFlags?.enable_gpt_validations === 'on' && (
                <PopoverTrigger
                  triggerAction="click"
                  overlay={({ close }) => (
                    <ValidationsPopover validations={validations} onClose={close} />
                  )}
                >
                  <PopoverLink>Validations</PopoverLink>
                </PopoverTrigger>
              )}
            </Box>
          )}
        </Box>
      )}

      {showFeedbackModal && (
        <FeedbackModal
          open={showFeedbackModal}
          onClose={() => setShowFeedbackModal(false)}
          type="message"
          resourceId={messageId}
        />
      )}
    </>
  );
};

export default AssistantMessage;
