import React, { createContext } from 'react';
import { Custodian } from 'vise-types/custodian';
import { CsvFileType } from 'vise-types/xray';

export type XrayData = {
  custodian: Custodian | null;
  csvFileType: CsvFileType | null;
  cashAmount?: number | null;
  unrealizedGains: string | null;
  realizedGains: string | null;
  taxable: boolean;
};

export const defaultXrayData: XrayData = {
  custodian: null,
  csvFileType: 'UNKNOWN',
  unrealizedGains: null,
  realizedGains: null,
  taxable: true,
};

export type XrayAction =
  | {
      type: 'SET_XRAY_CUSTODIAN';
      xrayCustodian: Custodian;
    }
  | {
      type: 'SET_XRAY_REALIZED_GAINS_FILE';
      fileName: string | null;
    }
  | {
      type: 'SET_XRAY_UNREALIZED_GAINS_FILE';
      fileName: string | null;
    }
  | {
      type: 'SET_XRAY_CASH_AMOUNT';
      cashAmount: number;
    }
  | { type: 'SET_XRAY_TAXABLE'; taxable: boolean }
  | { type: 'SET_CSV_FILE_TYPE'; fileType: XrayData['csvFileType'] }
  | {
      type: 'RESET_XRAY_CONTEXT';
    };

export const XrayReducer: React.Reducer<XrayData, XrayAction> = (prevState, action) => {
  switch (action.type) {
    case 'SET_XRAY_CUSTODIAN':
      return { ...prevState, custodian: action.xrayCustodian };
    case 'SET_XRAY_REALIZED_GAINS_FILE':
      return { ...prevState, realizedGains: action.fileName };
    case 'SET_XRAY_UNREALIZED_GAINS_FILE':
      return { ...prevState, unrealizedGains: action.fileName };
    case 'SET_XRAY_CASH_AMOUNT':
      return { ...prevState, cashAmount: action.cashAmount };
    case 'SET_XRAY_TAXABLE':
      return { ...prevState, taxable: action.taxable };
    case 'SET_CSV_FILE_TYPE':
      return { ...prevState, csvFileType: action.fileType };
    case 'RESET_XRAY_CONTEXT':
      return defaultXrayData;
    default:
      return prevState;
  }
};

export const XrayContext = createContext<XrayData>(defaultXrayData);

export const XrayDispatchContext = createContext<React.Dispatch<XrayAction>>(() => {});
