import moment from 'moment';
import queryString from 'querystring';
import { Custodian } from 'vise-types/custodian';
import { Cadence, DistributionSchedule, DistributionScheduleSchema } from 'vise-types/distribution';
import { Order, PCE2OrdersInfo } from 'vise-types/orders';
import {
  CreatePortfolioIntelligenceData,
  InputConstructionInfo,
  PortfolioIntelligenceFull,
  PortfolioIntelligenceOverview,
} from 'vise-types/pce1';
import {
  BDPerformanceWithBenchmarks,
  DeleteDistributionResponse,
  DeleteTemplateResponse,
  ImmediateDrawdownResponse,
  PortfolioInsightsResponse,
  RiskFromTargetValueResponse,
  UpsertDistributionResponse,
  ValidateSingleStockRestrictionsRequest,
  ValidateSingleStockRestrictionsResponse,
} from 'vise-types/pce2';
import { AssetClassKey, Country, Instrument } from 'vise-types/pce2_instrument';
import {
  AccountSize,
  AccountStateResponse,
  BondPortfolioData,
  BondPortfolioMetrics,
  BondPortfolioSampleOptionTreasury,
  BondPortfolioSectorAllocation,
  BondPortfolioSettings,
  ClosedLotModel,
  EngineType,
  GetAccountSizeRequest,
  HighQualityBondPortfolioSampleOption,
  LadderBondPortfolioSampleOption,
  LiquidationInfoType,
  MMFEligibility,
  PortfolioPositionResponse,
  Position,
  ProposalStatusResponse,
  Account as RawAccount,
  RebalancerStatus,
} from 'vise-types/portfolio';
import { PortfolioMetrics, PortfolioMetricsResponse } from 'vise-types/portfolio_metrics';
import {
  AccountsResponse,
  ActivityMonitorNotificationData,
  ActivityMonitorResponse,
  NotificationResponse,
  ProductOnboardingGeneratePdfRequest,
} from 'vise-types/response';
import { CalculateTaxParams, TaxRates } from 'vise-types/tax';
import {
  ActivityDetailsResponse,
  ActivityLogViewData,
  AllocationsTemplate,
  GetModelTemplateCenterViewDataResponse,
  RestrictionsTemplate,
  TemplateUpdateJob,
  TiltSelection,
} from 'vise-types/template';
import { User, UserWithClientData } from 'vise-types/user';
import {
  AlphaBackTestsResponse,
  BenchmarkDataResponse,
  CalculateFeesResponse,
  CalculateFixedIncomeMetricsResponse,
  FundOverlapResponse,
  RiskMetricsByPeriod,
  TransitionAnalysis,
  XrayAnalysisResponse,
  XrayClientsApiResponse,
  XrayReadyForOnboardingRequest,
} from 'vise-types/xray';
import {
  Account,
  Benchmark,
  CapitalGains,
  Client,
  GetActiveTiltStrategyPerformanceMetricsRequest,
  GetActiveTiltStrategyPerformanceMetricsResponse,
  GetPortfolioIntelligenceOrdersResponse,
  Household,
  HouseholdAccountsWithSummary,
  HouseholdWithSummary,
  NewClient,
  NewHousehold,
  PerformanceAndBenchmarks,
  PinResponse,
  RawBenchmark,
  RawClient,
  RawDeleteHouseholdResponse,
  RawGetCapitalGainsResponse,
  RawGetClientAccountsResponse,
  RawGetClientsResponse,
  RawGetHouseholdAccountsWithSummaryResponse,
  RawGetHouseholdClientsResponse,
  RawGetHouseholdProposalsResponse,
  RawGetHouseholdResponse,
  RawGetHouseholdsWithSummaryResponse,
  RawGetUnassignedAccountsResponse,
  RawGetUserClientsResponse,
  RawHouseholdWithSummary,
  Sector,
  UpdateHouseholdRequestData,
} from '~/models/api';
import { ConstructionInfo } from '~/routes/PortfolioCreator2/Types';
import { doRequestWithAuth } from './apiUtil';

/** NORMALIZE/DENORMALIZE HELPERS */

// Note: The risk number that we get from the UI, which is [0, 1],
// is mapped to [0, 0.5] by dividing by 2 before being stored.
// The user still understands risk as [0, 1], so we normalize
// by reversing that operation here.
const normalizeRisk = (risk: number) => risk * 2;

export const normalizeRawAccount = (rawAccount: RawAccount): Account => {
  const account = {
    ...rawAccount,
    name: rawAccount.accountName,
  };
  if (account.intelligence?.risk !== undefined && account.intelligence?.risk !== null) {
    account.intelligence.risk = normalizeRisk(account.intelligence.risk);
  }
  return account;
};

function normalizePortfolioIntelligenceOverview(
  rawPortfolioIntelligenceOverview: PortfolioIntelligenceOverview
): PortfolioIntelligenceOverview {
  const { constructionInfo, ...overview } = rawPortfolioIntelligenceOverview;
  return {
    ...overview,
    constructionInfo: {
      ...constructionInfo,
      risk: normalizeRisk(constructionInfo.risk),
    },
  };
}

const normalizeHouseholdWithSummary = (
  rawHouseholdWithSummary: RawHouseholdWithSummary
): HouseholdWithSummary => {
  const {
    id,
    name,
    description,
    clientCount,
    userId,
    transitionedPortfolioCount,
    untransitionedPortfolioCount,
    householdAggSummary: aggSummary,
    householdMetrics,
    householdMetricsForValidPerformanceAccounts,
  } = rawHouseholdWithSummary;
  const aum =
    aggSummary && aggSummary.length > 0 ? aggSummary[aggSummary.length - 1].marketValue : 0;
  return {
    id,
    name,
    description,
    userId,
    clientCount,
    transitionedPortfolioCount,
    untransitionedPortfolioCount,
    ...(aggSummary
      ? {
          aum,
          householdMetrics,
          householdMetricsForValidPerformanceAccounts,
        }
      : {}),
  };
};

export const normalizeInstrumentBenchmarks = (rawBenchmarks: RawBenchmark[]): Benchmark[] => {
  // Convert date string to epoch now instead of render for performance.
  const benchmarks = rawBenchmarks.map((benchmark) => {
    const dailyDataHC = (benchmark.dailyData || []).map(
      (data) => [moment.utc(data.date).valueOf(), data.value] as [number, number]
    );
    return { ...benchmark, dailyDataHC };
  });
  return benchmarks;
};

/** API CALLS */

export const getAccount = (
  accountId: string,
  includeSummary?: boolean,
  includeAllocationChanges?: boolean
) =>
  doRequestWithAuth<RawAccount>({
    method: 'GET',
    requestUrl: `/portfolio/accounts/${accountId}${includeSummary ? '?include_summary=true&' : ''}${
      includeAllocationChanges ? 'include_allocation_changes=true' : ''
    }`,
  });

export const getAccountWithAccountNumber = (
  custodianId: string,
  accountNumber: string,
  includeSummary?: boolean
) =>
  doRequestWithAuth<Account>({
    method: 'GET',
    requestUrl: `/portfolio/accounts/${custodianId}/${accountNumber}${
      includeSummary ? '?include_summary=true' : ''
    }`,
  });

// Dates are set to EST timezone in the API layer. Locally, dates should correspond to EST dates.
export const getDistributionScheduleSchema = async (): Promise<DistributionScheduleSchema> => {
  const { data } = await doRequestWithAuth<DistributionScheduleSchema>({
    requestUrl: '/distribution/schedule/schema',
    method: 'GET',
  });

  const immediateProcessOnMinEST = new Date(data.processOn.minImmediate).toLocaleString('en-US', {
    timeZone: 'America/New_York',
  });
  const recurringProcessOnMinEST = new Date(data.processOn.minRecurring).toLocaleString('en-US', {
    timeZone: 'America/New_York',
  });

  return {
    processOn: {
      minImmediate: immediateProcessOnMinEST,
      minRecurring: recurringProcessOnMinEST,
    },
  };
};

// Dates are set to EST timezone in the API layer. Locally, dates should correspond to EST dates.
const convertDistributionSchedulesToLocalTime = (schedules: DistributionSchedule[]) => {
  return schedules.map((row) => {
    const processOnEST = new Date(row.processOn).toLocaleString('en-US', {
      timeZone: 'America/New_York',
    });
    const processOn = new Date(processOnEST);

    let processUntil: Date | null = null;
    if (row.processUntil != null) {
      const processUntilEst = new Date(row.processUntil).toLocaleString('en-US', {
        timeZone: 'America/New_York',
      });
      processUntil = new Date(processUntilEst);
    }

    let nextRuns: Date[] | undefined;
    if (row.nextRuns != null) {
      nextRuns = row.nextRuns.map((run) => {
        const runEst = new Date(run).toLocaleString('en-US', {
          timeZone: 'America/New_York',
        });
        return new Date(runEst);
      });
    }

    return { ...row, processOn, processUntil, nextRuns };
  });
};

export const getDistributionSchedule = async (
  clientId: string,
  custodianCode: Custodian,
  accountNumber: string
): Promise<DistributionSchedule[]> => {
  const { data } = await doRequestWithAuth<DistributionSchedule[]>({
    requestUrl: `/distribution/schedule?${queryString.stringify({
      clientId,
      custodianCode,
      accountNumber,
    })}`,
    method: 'GET',
  });

  return convertDistributionSchedulesToLocalTime(data);
};

export const getAllDistributionSchedules = async (): Promise<DistributionSchedule[]> => {
  const { data } = await doRequestWithAuth<DistributionSchedule[]>({
    requestUrl: `/distribution/all`,
    method: 'GET',
  });

  return convertDistributionSchedulesToLocalTime(data);
};

export const upsertDistributionSchedule = async (
  amount: number,
  processOn: Date,
  cadence: Cadence,
  clientId: string,
  custodianCode: Custodian,
  accountNumber: string,
  processUntil: Date | null,
  allowMultipleRecurring?: boolean,
  description?: string,
  id?: string,
  allowWashSaleIfNeeded?: boolean
): Promise<UpsertDistributionResponse> => {
  const { data } = await doRequestWithAuth<UpsertDistributionResponse>({
    method: 'POST',
    requestUrl: '/distribution/schedule',
    data: {
      clientId,
      custodianCode,
      accountNumber,
      amount,
      processOn,
      cadence,
      processUntil,
      allowMultipleRecurring,
      description,
      id,
      processOnStr: processOn.toLocaleDateString('en-US'),
      processUntilStr: processUntil?.toLocaleDateString('en-US'),
      allowWashSaleIfNeeded,
    },
  });
  return data;
};

export const deleteSchedule = async (
  scheduleId: string,
  accountId: string
): Promise<DeleteDistributionResponse> => {
  const { data } = await doRequestWithAuth<DeleteDistributionResponse>({
    requestUrl: `/distribution/schedule?${queryString.stringify({ scheduleId, accountId })}`,
    method: 'DELETE',
  });
  return data;
};

export const executeImmediateDrawdown = async (
  amount: number,
  custodianCode: Custodian,
  accountNumber: string,
  proposalId?: string,
  processOnStr?: string,
  description?: string,
  id?: string,
  allowWashSaleIfNeeded?: boolean
): Promise<ImmediateDrawdownResponse> => {
  const { data } = await doRequestWithAuth<ImmediateDrawdownResponse>({
    method: 'post',
    requestUrl: '/distribution/immediate_drawdown',
    data: {
      amount,
      processOnStr,
      accountNumber,
      custodianCode,
      proposalId,
      description,
      id,
      allowWashSaleIfNeeded,
    },
  });
  return data;
};

export const getHouseholdAccountsWithSummary = async (
  householdId: string,
  includes?: {
    risk?: boolean;
  }
): Promise<HouseholdAccountsWithSummary> => {
  const { data } = await doRequestWithAuth<RawGetHouseholdAccountsWithSummaryResponse>({
    requestUrl: `/client/client_groups/${householdId}/accounts?include_summary=true${
      includes?.risk ? `&include_risk=true` : ''
    }`,
    method: 'GET',
  });
  // Normalize
  const accounts = data.accounts.map((a) => normalizeRawAccount(a));
  return { ...data, accounts };
};

export const getUnassignedAccounts = async (): Promise<Account[]> => {
  const { data } = await doRequestWithAuth<RawGetUnassignedAccountsResponse>({
    requestUrl: `/portfolio/accounts/potential`,
    method: 'GET',
  });
  return data.map((a) => normalizeRawAccount(a));
};

export const getClientAccounts = async (
  clientId: string,
  engineType?: EngineType
): Promise<Account[]> => {
  const { data } = await doRequestWithAuth<RawGetClientAccountsResponse>({
    requestUrl: `/client/clients/${clientId}/accounts${
      engineType ? `?engineType=${engineType}` : ''
    }`,
    method: 'GET',
  });
  return data.map((a) => normalizeRawAccount(a));
};

export const getHouseholdProposals = async (
  householdId: string
): Promise<PortfolioIntelligenceOverview[]> => {
  const { data } = await doRequestWithAuth<RawGetHouseholdProposalsResponse>({
    requestUrl: `/client/client_groups/${householdId}/proposals`,
    method: 'GET',
  });

  return data.map((p) => normalizePortfolioIntelligenceOverview(p));
};

export const getHouseholdBondProposals = async (
  householdId: string
): Promise<BondPortfolioSettings[]> => {
  const { data } = await doRequestWithAuth<BondPortfolioSettings[]>({
    requestUrl: `/client/client_groups/${householdId}/bond-proposals`,
    method: 'GET',
  });

  return data;
};

export const getHouseholdClients = async (householdId: string): Promise<Client[]> => {
  const { data } = await doRequestWithAuth<RawGetHouseholdClientsResponse>({
    requestUrl: `/client/client_groups/${householdId}/clients`,
    method: 'GET',
  });
  return data;
};

export const getClosedLots = async (accountId: string): Promise<ClosedLotModel[]> => {
  const { data } = await doRequestWithAuth<ClosedLotModel[]>({
    requestUrl: `/portfolio/closed-lots?accountId=${accountId}`,
    method: 'GET',
  });
  return data;
};

export const getRiskFromTargetValue = async ({
  targetValue,
  currentValue,
  years,
  isSmallAccount,
}: {
  targetValue: number;
  currentValue: number;
  years: number;
  isSmallAccount: boolean;
}): Promise<RiskFromTargetValueResponse> => {
  const { data } = await doRequestWithAuth<RiskFromTargetValueResponse>({
    method: 'get',
    requestUrl: `/portfolio/risk-from-target-value?targetValue=${targetValue}&currentValue=${currentValue}&years=${years}&isSmallAccount=${isSmallAccount}`,
  });
  return data;
};

export const getUserClients = async (): Promise<Client[]> => {
  const { data } = await doRequestWithAuth<RawGetUserClientsResponse>({
    method: 'get',
    requestUrl: `/client/v2/clients`,
  });
  return data;
};

export const getClient = async (clientId: string): Promise<Client> => {
  const { data } = await doRequestWithAuth<RawGetClientsResponse>({
    requestUrl: `/client/clients/${clientId}`,
    method: 'GET',
  });
  return data[0];
};

export const getHousehold = async (householdId: string): Promise<Household> => {
  const { data } = await doRequestWithAuth<RawGetHouseholdResponse>({
    requestUrl: `/client/client_groups/${householdId}`,
    method: 'GET',
  });
  return data;
};

export const getCapitalGains = async (
  accountNumber: string,
  custodianKey: Custodian,
  lockedPositions?: string[], // If not declared will use locked positions of existing portfolio
  includeComplianceGainsLosses = false
): Promise<CapitalGains> => {
  const { data } = await doRequestWithAuth<RawGetCapitalGainsResponse>({
    requestUrl: `/portfolio/gains?account_number=${accountNumber}&custodian_id=${custodianKey}${
      lockedPositions != null ? `&locked_positions=${lockedPositions}` : ''
    }&include_compliance_gains_losses=${includeComplianceGainsLosses ? 'true' : 'false'}`,
    method: 'GET',
  });
  return data;
};

export async function getPortfolioIntelligence(id: string) {
  const { data } = await doRequestWithAuth<PortfolioIntelligenceFull[]>({
    method: 'get',
    requestUrl: `/portfolio/intelligence/${encodeURIComponent(id)}`,
  });
  return data;
}

export async function getPortfolioIntelligenceOrders(intelligenceId: string) {
  const { data } = await doRequestWithAuth<GetPortfolioIntelligenceOrdersResponse>({
    requestUrl: `/portfolio/intelligence/${intelligenceId}/orders`,
    method: 'get',
  });
  return data;
}

export async function getAccountOrders(accountId: string) {
  const { data } = await doRequestWithAuth<GetPortfolioIntelligenceOrdersResponse>({
    requestUrl: `/portfolio/accounts/${accountId}/orders`,
    method: 'get',
  });
  return data;
}

export async function getPositions(
  accountNumber: string,
  custodianId: string,
  options?: { includePCE2Instruments?: boolean; includeTaxLots?: boolean; includeCountry?: boolean }
): Promise<PortfolioPositionResponse> {
  const requestUrl = `/portfolio/positions?portfolio_id=${encodeURIComponent(
    accountNumber
  )}&custodian_id=${encodeURIComponent(custodianId)}${
    options?.includePCE2Instruments ? '&include_pce2_instruments=true' : ''
  }${options?.includeTaxLots ? '&include_tax_lots=true' : ''}${
    options?.includeCountry ? '&include_country=true' : ''
  }`;
  const { data } = await doRequestWithAuth<PortfolioPositionResponse>({
    method: 'get',
    requestUrl,
  });
  return data;
}

export async function getPortfolioMetrics(accountId: string): Promise<PortfolioMetricsResponse> {
  const requestUrl = `/portfolio/accounts/${accountId}/metrics`;
  const { data } = await doRequestWithAuth<PortfolioMetricsResponse>({
    method: 'get',
    requestUrl,
  });
  return data;
}

/** For now, can only associate accounts with clients. */
export const updateAccount = async (
  accountId: string,
  clientId: string,
  rebalancerStatus?: RebalancerStatus
): Promise<Account> => {
  const { data } = await doRequestWithAuth<RawAccount>({
    requestUrl: `/portfolio/accounts/${accountId}`,
    method: 'POST',
    data: {
      viseClientId: clientId,
      ...(rebalancerStatus ? { rebalancerStatus } : {}),
    },
  });
  return normalizeRawAccount(data);
};

export const createClient = async (
  clientInfo: NewClient,
  householdInfo?: NewHousehold
): Promise<Client> => {
  const { data } = await doRequestWithAuth<RawClient>({
    requestUrl: `/client/client`,
    method: 'POST',
    data: {
      clientGroup: householdInfo,
      ...clientInfo,
    },
  });
  return data;
};

export const updateClient = async (clientInfo: RawClient): Promise<RawClient> => {
  const { data } = await doRequestWithAuth<RawClient>({
    requestUrl: `/client/client`,
    method: 'POST',
    data: clientInfo,
  });
  return data;
};

export const updateHousehold = async (
  householdInfo: UpdateHouseholdRequestData
): Promise<Household> => {
  const { data } = await doRequestWithAuth<Household>({
    requestUrl: `/client/client_group`,
    method: 'POST',
    data: householdInfo,
  });
  return data;
};

export const deleteHousehold = async (householdId: string): Promise<Household> => {
  const { data } = await doRequestWithAuth<RawDeleteHouseholdResponse>({
    requestUrl: `/client/client_group/${householdId}`,
    method: 'DELETE',
  });
  return data;
};

export const deleteClient = async (clientId: string): Promise<RawClient> => {
  const { data } = await doRequestWithAuth<RawClient>({
    requestUrl: `/client/client/${clientId}`,
    method: 'DELETE',
  });
  return data;
};

export const linkAccountToClient = async (
  clientId: string,
  accountId: string
): Promise<RawAccount> => {
  const { data } = await doRequestWithAuth<RawAccount>({
    requestUrl: `/client/client/${clientId}/account/${accountId}`,
    method: 'POST',
  });
  return data;
};

export const unlinkAccount = async (clientId: string, accountId: string): Promise<RawAccount> => {
  const { data } = await doRequestWithAuth<RawAccount>({
    requestUrl: `/client/client/${clientId}/account/${accountId}`,
    method: 'DELETE',
  });
  return data;
};

export const removeAccountFromVise = async (
  accountId: string,
  churnCategoryName: string,
  removeFromViseNotes?: string
): Promise<RawAccount> => {
  const { data } = await doRequestWithAuth<RawAccount>({
    requestUrl: `/client/accounts/${accountId}/remove`,
    method: 'POST',
    data: { churnCategoryName, removeFromViseNotes },
  });
  return data;
};

export const getSectors = async (useLegacySectors?: boolean): Promise<Sector[]> => {
  const { data } = await doRequestWithAuth<Sector[]>({
    method: 'GET',
    requestUrl: `/sector?useLegacySectors=${useLegacySectors ? 'true' : ''}`,
  });
  return data;
};

// Gets most recent liquidation request
export async function getAccountState(accountId: string): Promise<AccountStateResponse> {
  const requestUrl = `/portfolio/accounts/${accountId}/account-state`;
  const { data } = await doRequestWithAuth<AccountStateResponse>({
    method: 'GET',
    requestUrl,
  });
  return data;
}

export const liquidateAccount = async (
  accountId: string,
  liquidationInfoJSON: LiquidationInfoType
): Promise<RawAccount> => {
  const { data } = await doRequestWithAuth<RawAccount>({
    requestUrl: `/portfolio/accounts/${accountId}/liquidate`,
    method: 'POST',
    data: { liquidationInfoJSON },
  });
  return data;
};

export const getInstruments = async (
  symbols?: string[],
  includeCountry?: boolean
): Promise<Instrument[]> => {
  const { data } = await doRequestWithAuth<Instrument[]>({
    method: 'GET',
    requestUrl: `/instrument/instruments?${symbols != null ? `symbols=${symbols}` : ''}${
      includeCountry != null ? `&includeCountry=${includeCountry}` : ''
    }`,
  });
  return data;
};

export const getInstrumentBenchmarks = async (options?: {
  fetchDailyData?: boolean;
  risk?: number;
}): Promise<Benchmark[]> => {
  let url = `/benchmark/benchmarks?fetchDailyData=${encodeURIComponent(
    options?.fetchDailyData ? 'true' : ''
  )}`;
  if (options?.risk !== undefined) {
    url += `&risk=${encodeURIComponent(options.risk)}`;
  }

  const { data: instrumentBenchmarks } = await doRequestWithAuth<RawBenchmark[]>({
    method: 'get',
    requestUrl: url,
  });
  return normalizeInstrumentBenchmarks(instrumentBenchmarks);
};

export const getBDTimeseriesWithBenchmarks = async (
  accountId: string
): Promise<PerformanceAndBenchmarks> => {
  const url = `/benchmark/benchmarks/performance/${accountId}`;

  const { data: perfAndBenchmarks } = await doRequestWithAuth<BDPerformanceWithBenchmarks>({
    method: 'get',
    requestUrl: url,
  });

  const normalizedPerformanceAndBenchmarks = {
    ...perfAndBenchmarks,
    benchmarks: normalizeInstrumentBenchmarks(perfAndBenchmarks.benchmarks),
  };
  return normalizedPerformanceAndBenchmarks;
};

export const getHouseholdsWithSummary = async (
  perfSuppressionEnabled: boolean,
  excludeMarketData: boolean,
  engineType: string | null
): Promise<HouseholdWithSummary[]> => {
  const { data } = await doRequestWithAuth<RawGetHouseholdsWithSummaryResponse>({
    requestUrl: `/client/client_groups?fetchAggData=true&perf_suppression_enabled=${String(
      perfSuppressionEnabled
    )}&exclude_market_data=${String(excludeMarketData)}&engine_type=${engineType}`,
    method: 'GET',
  });
  return data.map((h) => normalizeHouseholdWithSummary(h));
};

export const getAllAccountsWithHouseholdInfo = async (
  include_summaries = false,
  include_pi = true,
  transitioned: boolean | undefined,
  perfSuppressionEnabled: boolean,
  engineType: string | undefined
): Promise<AccountsResponse> => {
  const searchParams = new URLSearchParams({
    include_summaries: String(include_summaries),
    include_pi: String(include_pi),
    perf_suppression_enabled: String(perfSuppressionEnabled),
  });
  if (transitioned != null) searchParams.append('transitioned', String(transitioned));
  if (engineType != null) {
    searchParams.append('engineType', engineType);
  }
  const { data } = await doRequestWithAuth<AccountsResponse>({
    requestUrl: `/portfolio/accounts?${searchParams.toString()}`,
    method: 'GET',
  });

  return data;
};

export const getAllProposals = async (): Promise<PortfolioIntelligenceOverview[]> => {
  const { data } = await doRequestWithAuth<RawGetHouseholdProposalsResponse>({
    requestUrl: `/portfolio/intelligences/all`,
    method: 'GET',
  });
  return data.map((p) => normalizePortfolioIntelligenceOverview(p));
};

export const getAllAccountProposals = async (
  accountId: string
): Promise<PortfolioIntelligenceOverview[]> => {
  // differs from getHouseholdProposals because we only fetch pce2 and full proposal objects
  const { data } = await doRequestWithAuth<RawGetHouseholdProposalsResponse>({
    requestUrl: `/portfolio/intelligences/for_account/${accountId}`,
    method: 'GET',
  });
  return data.map((p) => normalizePortfolioIntelligenceOverview(p));
};

export const deletePCE2Proposal = async (
  proposalId: string,
  clientId: string,
  accountId?: string | null
): Promise<null> => {
  const { data } = await doRequestWithAuth<null>({
    requestUrl: `/portfolio/intelligence/${proposalId}`,
    method: 'DELETE',
    data: {
      accountId,
      clientId,
    },
  });
  return data;
};

export const renamePCE2Proposal = async (
  proposalId: string,
  proposalName: string,
  clientId: string,
  accountId?: string | null
): Promise<null> => {
  const { data } = await doRequestWithAuth<null>({
    requestUrl: `/portfolio/intelligence/${proposalId}`,
    method: 'PATCH',
    data: {
      proposalName,
      clientId,
      accountId,
    },
  });
  return data;
};

export const unacceptPCE2Proposal = async (
  proposalId: string,
  accountId: string
): Promise<null> => {
  const { data } = await doRequestWithAuth<null>({
    requestUrl: `/portfolio/intelligence/${proposalId}/unaccept`,
    method: 'POST',
    data: {
      accountId,
    },
  });
  return data;
};

export const getProposalStatus = async (proposalId: string): Promise<ProposalStatusResponse> => {
  const { data } = await doRequestWithAuth<ProposalStatusResponse>({
    requestUrl: `/portfolio/intelligence/${proposalId}/status`,
    method: 'GET',
  });
  return data;
};

export const createPortfolioIntelligence = async (
  constructionData: CreatePortfolioIntelligenceData
): Promise<PortfolioIntelligenceOverview> => {
  const { data } = await doRequestWithAuth<PortfolioIntelligenceOverview>({
    method: 'post',
    requestUrl: '/portfolio/intelligence',
    data: constructionData,
  });
  return data;
};

export const executePortfolioIntelligence = async (
  intelligenceId: string,
  options?: { shouldRevalidate: boolean }
): Promise<Order> => {
  const { data } = await doRequestWithAuth<Order>({
    requestUrl: `/portfolio/intelligence/${encodeURIComponent(intelligenceId)}/execute`,
    method: 'POST',
    shouldRevalidate: options?.shouldRevalidate ?? true,
  });
  return data;
};

export const createPortfolioPdf = async (
  intelligenceId: string,
  logoImgUrl?: string
): Promise<string> => {
  const { data } = await doRequestWithAuth<string>({
    requestUrl: `/pdf/portfolio`,
    method: 'POST',
    data: {
      portfolioIntelligenceId: intelligenceId,
      logoImgUrl,
    },
  });
  return data;
};

export const esignPlatformServiceAgreement = async (
  payload: ESignaturePlatformServiceAgreement
) => {
  const { data } = await doRequestWithAuth<{ redirectUrl: string; envelopeId: string }>({
    requestUrl: `/esignature/psa`,
    method: 'POST',
    data: payload,
  });
  return data;
};

export const getUser = async (
  fetchAggData?: boolean,
  fetchMetaData?: boolean,
  fetchPhoto?: boolean
): Promise<UserWithClientData | User> => {
  const searchParams = new URLSearchParams();
  searchParams.append('fetchMetaData', fetchMetaData ? 'true' : 'false');
  searchParams.append('fetchAggData', fetchAggData ? 'true' : 'false');
  searchParams.append('fetchPhoto', fetchPhoto ? 'true' : 'false');
  const { data } = await doRequestWithAuth<UserWithClientData | User>({
    requestUrl: `/user/user?${searchParams.toString()}`,
    method: 'GET',
  });
  return data;
};

export const updateUser = async (
  reqData: Pick<User, 'id' | 'firstName' | 'lastName'> & {
    userMetadata: Partial<Pick<User, 'userMetaData'>['userMetaData']>;
  }
): Promise<Partial<User>> => {
  const { data } = await doRequestWithAuth<Partial<User>>({
    requestUrl: `/user/user/${reqData.id}`,
    method: 'PATCH',
    data: reqData,
  });
  return data;
};

export const updateUserMetadata = async (data: Partial<User['userMetaData']>) =>
  doRequestWithAuth<Partial<User['userMetaData']>>({
    requestUrl: '/user/user_metadata',
    method: 'patch',
    data,
  });

export function uploadUserProfilePhoto(data: FormData, userId: string) {
  return doRequestWithAuth<{ success: boolean; url: string }>({
    method: 'POST',
    requestUrl: `/user/user/${userId}/photo`,
    data,
    additionalHeaders: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function uploadOrgLogo(data: FormData, orgId: string) {
  return doRequestWithAuth<{ success: boolean; url: string }>({
    method: 'POST',
    requestUrl: `/organization/${orgId}/logo`,
    data,
    additionalHeaders: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function toggleXrayDefaultPermission(orgId: string, allowOrgAccess: boolean) {
  return doRequestWithAuth<{ success: boolean }>({
    method: 'POST',
    requestUrl: `/organization/toggle-xray-permission/${orgId}?allowOrgAccess=${allowOrgAccess}`,
  });
}

export const validateSingleStockRestrictions = async (
  reqData: ValidateSingleStockRestrictionsRequest
): Promise<ValidateSingleStockRestrictionsResponse> => {
  const { data } = await doRequestWithAuth<ValidateSingleStockRestrictionsResponse>({
    requestUrl: `/portfolio/validate-single-stock-restrictions`,
    method: 'POST',
    data: reqData,
  });
  return data;
};

export const getActiveTiltStrategyPerformanceMetrics = async (
  reqData: GetActiveTiltStrategyPerformanceMetricsRequest
): Promise<GetActiveTiltStrategyPerformanceMetricsResponse> => {
  const { tiltAmount, tiltType, assetClassKey } = reqData;

  // For cache-busting
  const version = process.env.VERSION ?? '';

  const { data } = await doRequestWithAuth<GetActiveTiltStrategyPerformanceMetricsResponse>({
    requestUrl: `/portfolio/active_tilt_strategy_performance_metrics?tiltAmount=${tiltAmount}&tiltType=${tiltType}&assetClassKey=${assetClassKey}&version=${version}`,
    method: 'GET',
  });
  return data;
};

export async function submitUserFeedback(payload: {
  feedbackType: string;
  message: string;
  question: string;
  rating?: number;
}): Promise<void> {
  const { data } = await doRequestWithAuth<void>({
    method: 'POST',
    requestUrl: '/user/user_feedback',
    data: payload,
  });
  return data;
}

export const getAccountsOrderInfo = async (): Promise<PCE2OrdersInfo[]> => {
  const { data } = await doRequestWithAuth<PCE2OrdersInfo[]>({
    requestUrl: `/portfolio/accounts/order-info`,
    method: 'GET',
  });
  return data;
};

export const getAccountSize = async (reqData: GetAccountSizeRequest): Promise<AccountSize> => {
  const { accountId, lockedPositions, manageMoneyMarketFunds, targetCashFraction } = reqData;
  const { data } = await doRequestWithAuth<AccountSize>({
    requestUrl: `/portfolio/accounts/account-size?accountId=${accountId}${
      lockedPositions == null ? '' : `&lockedPositions=${lockedPositions}`
    }
    ${manageMoneyMarketFunds == null ? '' : `&manageMoneyMarketFunds=${manageMoneyMarketFunds}`}
   ${targetCashFraction == null ? '' : `&targetCashFraction=${targetCashFraction}`}`,
    method: 'GET',
  });
  return data;
};

export const getMMFEligibility = async (
  reqData: GetAccountSizeRequest
): Promise<MMFEligibility> => {
  const { accountId, lockedPositions, targetCashFraction } = reqData;
  const { data } = await doRequestWithAuth<MMFEligibility>({
    requestUrl: `/portfolio/accounts/mmf-eligibility?accountId=${accountId}${
      lockedPositions == null ? '' : `&lockedPositions=${lockedPositions}`
    }
   ${targetCashFraction == null ? '' : `&targetCashFraction=${targetCashFraction}`}`,
    method: 'GET',
  });
  return data;
};

export const getAllRestrictionsTemplates = async ({
  includeStale,
  includeDeleted,
}: {
  includeStale?: boolean;
  includeDeleted?: boolean;
}): Promise<RestrictionsTemplate[]> => {
  const { data } = await doRequestWithAuth<RestrictionsTemplate[]>({
    requestUrl: `/template/restrictions/all?${queryString.stringify({
      includeStale,
      includeDeleted,
    })}`,
    method: 'GET',
  });
  return data;
};

export const getRestrictionsTemplates = async (
  ids: string[],
  fetchDeleted?: boolean
): Promise<RestrictionsTemplate[]> => {
  const { data } = await doRequestWithAuth<RestrictionsTemplate[]>({
    requestUrl: `/template/restrictions?ids=${ids}&fetchDeleted=${!!fetchDeleted}`,
    method: 'GET',
  });
  return data;
};

export const deleteRestrictionsTemplate = async (id: string): Promise<DeleteTemplateResponse> => {
  const { data } = await doRequestWithAuth<DeleteTemplateResponse>({
    requestUrl: `/template/restrictions/${id}`,
    method: 'DELETE',
  });
  return data;
};

export const renameRestrictionsTemplate = async (id: string, newName: string): Promise<null> => {
  const { data } = await doRequestWithAuth<null>({
    requestUrl: `/template/restrictions/${id}`,
    method: 'PATCH',
    data: {
      newName,
    },
  });
  return data;
};

export const renameAllocationsTemplate = async (id: string, newName: string): Promise<null> => {
  const { data } = await doRequestWithAuth<null>({
    requestUrl: `/template/allocations/${id}`,
    method: 'PATCH',
    data: {
      newName,
    },
  });
  return data;
};

export interface RestrictionTemplateBody {
  name: string;
  userId: string;
  orgId: string;
  tickers: string[];
  subSectors: string[];
  sectors: string[];
  countries: Country[];
  esgAreas: string[];
}

interface ESignaturePlatformServiceAgreement {
  userId: string;
  returnPath?: string;
  signed?: boolean;
}

export const insertRestrictionsTemplate = async ({
  name,
  userId,
  orgId,
  tickers,
  subSectors,
  sectors,
  countries,
  esgAreas,
}: RestrictionTemplateBody): Promise<RestrictionsTemplate> => {
  const { data } = await doRequestWithAuth<RestrictionsTemplate>({
    requestUrl: `/template/restrictions/create`,
    method: 'POST',
    data: {
      name,
      userId,
      orgId,
      tickers,
      subSectors,
      sectors,
      countries,
      esgAreas,
    },
  });
  return data;
};

export const getAllAllocationsTemplates = async ({
  includeStale,
  includeDeleted,
}: {
  includeStale?: boolean;
  includeDeleted?: boolean;
}): Promise<AllocationsTemplate[]> => {
  const { data } = await doRequestWithAuth<AllocationsTemplate[]>({
    requestUrl: `/template/allocations/all?${queryString.stringify({
      includeStale,
      includeDeleted,
    })}`,
    method: 'GET',
  });
  return data;
};

export const getAllocationsTemplate = async (
  id: string,
  fetchDeleted?: boolean
): Promise<AllocationsTemplate> => {
  const { data } = await doRequestWithAuth<AllocationsTemplate>({
    requestUrl: `/template/allocations/${id}?fetchDeleted=${!!fetchDeleted}`,
    method: 'GET',
  });
  return data;
};

export const deleteAllocationsTemplate = async (id: string): Promise<AllocationsTemplate> => {
  const { data } = await doRequestWithAuth<AllocationsTemplate>({
    requestUrl: `/template/allocations/${id}`,
    method: 'DELETE',
  });
  return data;
};

export interface AllocationsTemplateBody {
  name: string;
  userId: string;
  orgId: string;
  allocations: { [key in AssetClassKey]?: number };
  isCustomAllocation: boolean;
  customAllocations: AssetClassKey[];
  tiltSelection?: TiltSelection | null;
}

export const insertAllocationsTemplate = async ({
  name,
  userId,
  orgId,
  allocations,
  isCustomAllocation,
  customAllocations,
  tiltSelection,
}: AllocationsTemplateBody): Promise<AllocationsTemplate> => {
  const { data } = await doRequestWithAuth<AllocationsTemplate>({
    requestUrl: `/template/allocations/create`,
    method: 'POST',
    data: {
      name,
      userId,
      orgId,
      allocations,
      isCustomAllocation,
      customAllocations,
      tiltSelection,
    },
  });
  return data;
};

export const editTemplate = ({
  templateId,
  templateBody,
}: {
  templateId: string;
  templateBody: AllocationsTemplateBody | RestrictionTemplateBody;
}) => {
  return doRequestWithAuth<{ success: boolean; templateId: string }>({
    requestUrl: `/template/${templateId}/edit`,
    method: 'POST',
    data: templateBody,
  });
};

export const duplicateTemplate = ({
  templateId,
  type,
  newName,
}: {
  templateId: string;
  type: 'allocations' | 'restrictions';
  newName: string;
}) => {
  return doRequestWithAuth<{
    success: boolean;
    newTemplate: RestrictionsTemplate | AllocationsTemplate;
    type: 'restrictions' | 'allocations';
  }>({
    requestUrl: `/template/${templateId}/duplicate`,
    method: 'POST',
    data: { type, newName },
  });
};

export const getTemplatesByParentIds = async ({
  ids,
  includeStale,
  includeDeleted,
}: {
  ids: string[];
  includeStale?: boolean;
  includeDeleted?: boolean;
}): Promise<(RestrictionsTemplate | AllocationsTemplate)[]> => {
  const allocationsTemplates = await getAllAllocationsTemplates({ includeStale, includeDeleted });
  const restrictionsTemplates = await getAllRestrictionsTemplates({ includeStale, includeDeleted });
  const idsSet = new Set(ids);
  return [...allocationsTemplates, ...restrictionsTemplates].filter((template) =>
    idsSet.has(template.parentId)
  );
};

export async function getNumSymbolsPerAssetClass(params: {
  accountId?: string | null;
  constructionInfo: ConstructionInfo;
  lockedPositions: string[];
}) {
  return doRequestWithAuth<{ [key in AssetClassKey]?: number }>({
    requestUrl: `/portfolio/intelligence/get-symbol-count`,
    method: 'POST',
    data: params,
  });
}

export async function getAssetAllocation(params: {
  assetAllocation: ConstructionInfo['assetAllocation'];
}) {
  return doRequestWithAuth<{ [key in AssetClassKey]?: number }>({
    requestUrl: `/portfolio/intelligence/get-asset-allocation`,
    method: 'POST',
    data: params,
  });
}

export async function getPortfolioInsights(params: { proposalId: string }) {
  return doRequestWithAuth<PortfolioInsightsResponse>({
    requestUrl: `/portfolio/intelligence/${params.proposalId}/insights`,
    method: 'GET',
  });
}

// x-ray endpoints
export async function createXRayAccount(params: {
  clientId: string | null;
  taxable: boolean | null;
  accountId?: string | null;
}) {
  return doRequestWithAuth<Account>({
    requestUrl: '/xray/account',
    method: 'POST',
    data: params,
  });
}

export async function uploadOpenLots(
  data: ArrayBuffer,
  fileFormat: string,
  clientId: string,
  accountId: string | null,
  taxable: boolean,
  fileContentType: string,
  fileName?: string | null // TODO: remove null once frontend merges CSV + XLSX into one option)
) {
  return doRequestWithAuth<{
    account: Account;
  }>({
    requestUrl: '/xray/upload-open-lots',
    method: 'POST',
    additionalHeaders: {
      'Content-Type': fileContentType,
      fileFormat,
      clientId,
      accountId: accountId || '',
      taxable,
      fileName: fileName || '', // optional to be backwards compatible. TODO: remove '' once frontend merges CSV + XLSX into one option
    },
    data,
  });
}

export async function uploadClosedLots(
  data: ArrayBuffer,
  fileFormat: string,
  accountId: string,
  fileContentType: string,
  fileName?: string | null // TODO: remove null once frontend merges CSV + XLSX into one option)
) {
  return doRequestWithAuth<{
    account: Account;
    closedLots: { gainLoss: number; capitalGainType: string }[] | undefined;
  }>({
    requestUrl: '/xray/upload-closed-lots',
    method: 'POST',
    additionalHeaders: {
      'Content-Type': fileContentType,
      fileFormat,
      accountId,
      fileName: fileName || '', // optional to be backwards compatible. TODO: remove '' once frontend merges CSV + XLSX into one option
    },
    data,
  });
}

export async function insertXrayCashPosition(params: { accountId: string; cashAmount: number }) {
  return doRequestWithAuth<null>({
    requestUrl: '/xray/cash',
    method: 'POST',
    data: params,
  });
}

export async function deleteXrayPositions({
  accountId,
  tickers,
}: {
  accountId: string;
  tickers?: string[];
}) {
  let url = `/xray/positions?accountId=${encodeURIComponent(accountId)}`;
  if (tickers != null && tickers.length) {
    url += `&tickers=${encodeURIComponent(tickers.join(','))}`;
  }

  return doRequestWithAuth<null>({
    requestUrl: url,
    method: 'DELETE',
  });
}

export async function deleteClosedLots({ accountId }: { accountId: string }) {
  return doRequestWithAuth<null>({
    requestUrl: `/portfolio/closed-lots?accountId=${encodeURIComponent(accountId)}`,
    method: 'DELETE',
  });
}

export async function insertXrayPosition(params: {
  accountId: string;
  symbolOrCusip: string;
  quantity: number;
}) {
  return doRequestWithAuth<{ success: boolean }>({
    requestUrl: '/xray/position',
    method: 'POST',
    data: params,
  });
}

export async function fillInXrayLots(accountId: string) {
  return doRequestWithAuth<{ success: boolean }>({
    requestUrl: `/xray/fill-lots/${accountId}`,
    method: 'POST',
  });
}

export async function updateXrayPositionTaxLots(params: { accountId: string; position: Position }) {
  return doRequestWithAuth<{ success: boolean }>({
    requestUrl: '/xray/position',
    method: 'PUT',
    data: params,
  });
}
export async function getPortfolioOverview(id: string) {
  return doRequestWithAuth<{ metrics?: PortfolioMetrics }>({
    requestUrl: `/xray/overview/${id}`,
    method: 'GET',
  });
}

export async function getXrayBenchmarks() {
  return doRequestWithAuth<BenchmarkDataResponse>({
    requestUrl: '/xray/benchmarks',
    method: 'GET',
  });
}

export async function getTransitionAnalysis(accountId: string, proposalId: string) {
  return doRequestWithAuth<TransitionAnalysis>({
    requestUrl: `/xray/transition-analysis?${queryString.stringify({ accountId, proposalId })}`,
    method: 'GET',
  });
}

export function getTaxAlphaBacktests(proposalId: string) {
  return doRequestWithAuth<AlphaBackTestsResponse>({
    requestUrl: `/xray/taxAlphaBacktests/${proposalId}`,
    method: 'GET',
  });
}

export function getXrayClients() {
  return doRequestWithAuth<XrayClientsApiResponse<boolean>>({
    requestUrl: '/xray/accounts',
    method: 'GET',
  });
}

export function getRiskMetrics(proposalId: string) {
  return doRequestWithAuth<RiskMetricsByPeriod>({
    requestUrl: `/xray/risk-metrics/${proposalId}`,
    method: 'GET',
  });
}

export function getFixedIncomeMetrics(proposalId: string) {
  return doRequestWithAuth<CalculateFixedIncomeMetricsResponse>({
    requestUrl: `/xray/fixed-income-metrics/${proposalId}`,
    method: 'GET',
  });
}

export function toggleXrayAccountPermission(accountId: string, allowOrgAccess: boolean) {
  return doRequestWithAuth<{ success: boolean }>({
    requestUrl: `/xray/toggle-permission/${accountId}?allowOrgAccess=${allowOrgAccess}`,
    method: 'POST',
  });
}

export function getXrayAccountPermission(accountId: string) {
  return doRequestWithAuth<{ allowOrgAccess: boolean }>({
    requestUrl: `/xray/permission/${accountId}`,
    method: 'GET',
  });
}

// x-ray ends ----------------

export async function getGlidePathFinalResults(params: {
  assetAllocation: InputConstructionInfo['assetAllocation'];
  concentrationLimits: AssetClassKey[];
}) {
  return doRequestWithAuth<ConstructionInfo['assetAllocation']>({
    requestUrl: `/portfolio/intelligence/get-glide-path-results`,
    method: 'POST',
    data: params,
  });
}

export async function getModelTemplateCenterViewData() {
  return doRequestWithAuth<GetModelTemplateCenterViewDataResponse>({
    requestUrl: '/template/get-model-template-center-view-data',
    method: 'GET',
  });
}

export async function getActivityLogViewData() {
  return doRequestWithAuth<ActivityLogViewData[]>({
    requestUrl: '/template/get-activity-log-view-data',
    method: 'GET',
  });
}

export async function getActivityDetails(activityId: string) {
  return doRequestWithAuth<ActivityDetailsResponse>({
    requestUrl: `/template/activity/${activityId}`,
    method: 'GET',
  });
}

export async function toggleTemplatePin(id: string, type: 'allocations' | 'restrictions') {
  return doRequestWithAuth<PinResponse>({
    requestUrl: `/template/pin/${id}?type=${type}`,
    method: 'PUT',
  });
}

export async function getTemplateUpdateInProgressJobs(bulkEdits?: boolean) {
  return doRequestWithAuth<TemplateUpdateJob[]>({
    requestUrl: `/template/jobs/in-progress?bulkEdits=${bulkEdits ? 'true' : 'false'}`,
    method: 'GET',
  });
}

export async function getTemplateUpdateJob(jobId: string) {
  const { data: job } = await doRequestWithAuth<TemplateUpdateJob>({
    requestUrl: `/template/jobs/${jobId}`,
    method: 'GET',
  });
  return job;
}

export async function executeTemplateUpdateJob(jobId: string, accountsToExecute: string[]) {
  return doRequestWithAuth<{ success: boolean; failures?: number }>({
    requestUrl: `/template/jobs/${jobId}/execute`,
    method: 'POST',
    data: { accountsToExecute },
  });
}

export function updateLinkedAccounts(
  templateId: string,
  unlinkAccounts: string[],
  linkAccounts: string[],
  reason: 'LINK' | 'UPDATE'
) {
  return doRequestWithAuth<{ success: boolean; jobId: string }>({
    requestUrl: `/template/${templateId}/linkedAccounts`,
    method: 'POST',
    data: { unlinkAccounts, linkAccounts, reason },
  });
}

export function saveReadyForOnboardingProposals(data: XrayReadyForOnboardingRequest) {
  return doRequestWithAuth<boolean>({
    requestUrl: '/xray/onboarding',
    method: 'POST',
    data,
  });
}

export function getProductOnboardingPdfs(data: ProductOnboardingGeneratePdfRequest) {
  return doRequestWithAuth<string[]>({
    requestUrl: '/pdf/custodian_form',
    method: 'POST',
    data,
  });
}

export function getProposalFees(proposalId: string) {
  return doRequestWithAuth<CalculateFeesResponse>({
    requestUrl: `/xray/calculateFees/${proposalId}`,
    method: 'GET',
  });
}

export function isEtfOnlyProposal(proposalId: string) {
  return doRequestWithAuth<boolean>({
    requestUrl: `/xray/isEtfOnly/${proposalId}`,
    method: 'GET',
  });
}

export function calculateFundOverlap(accountId: string) {
  return doRequestWithAuth<FundOverlapResponse>({
    requestUrl: `/xray/fundOverlap/${accountId}`,
    method: 'GET',
  });
}

export function getActivityMonitorNotifications(completed?: boolean) {
  return doRequestWithAuth<NotificationResponse<ActivityMonitorResponse>>({
    requestUrl: `/notification/activity_monitor${completed ? '?completed=true' : ''}`,
    method: 'GET',
  });
}

export function calculateBondPortfolioMetrics() {
  return doRequestWithAuth<BondPortfolioMetrics>({
    requestUrl: `/bond-portfolio/calculate-metrics`,
    method: `GET`,
  });
}

export function calculateBondPortfolioSectors() {
  return doRequestWithAuth<BondPortfolioSectorAllocation>({
    requestUrl: `/bond-portfolio/calculate-sectors`,
    method: `GET`,
  });
}

export function executeBondProposal(proposalId: string) {
  return doRequestWithAuth<{ success: boolean }>({
    requestUrl: `/bond-portfolio/execute/${proposalId}`,
    method: `POST`,
  });
}

export function createBondProposal(portfolioSettings: BondPortfolioSettings) {
  return doRequestWithAuth<BondPortfolioSettings>({
    requestUrl: `/bond-portfolio/create`,
    method: `POST`,
    data: portfolioSettings,
  });
}

export function getExecutedBondProposal(accountId: string) {
  return doRequestWithAuth<BondPortfolioSettings>({
    method: 'GET',
    requestUrl: `/bond-portfolio/account/${accountId}`,
  });
}

export function getBondPortfolioSampleModelOptions(date?: string) {
  return doRequestWithAuth<
    (HighQualityBondPortfolioSampleOption | LadderBondPortfolioSampleOption)[]
  >({
    method: 'GET',
    requestUrl: `/bond-portfolio/model-options${date != null ? `?date=${date}` : ''}`,
  });
}

export function getBondPortfolioSampleData(
  settings:
    | HighQualityBondPortfolioSampleOption
    | LadderBondPortfolioSampleOption
    | BondPortfolioSampleOptionTreasury
    | null,
  aum: number | null
) {
  const params = new URLSearchParams({
    ...settings,
    ...(aum != null ? { aum: aum.toString() } : {}),
  });
  return doRequestWithAuth<BondPortfolioData>({
    method: 'GET',
    requestUrl: `/bond-portfolio/data?${params.toString()}`,
  });
}

export function getBondPortfolioData(
  params: { portfolioId: string; accountId: never } | { portfolioId: never; accountId: string }
) {
  return doRequestWithAuth<BondPortfolioData>({
    method: 'GET',
    requestUrl: `/bond-portfolio/data?${new URLSearchParams(params).toString()}`,
  });
}

export function getBondProposal(proposalId: string) {
  return doRequestWithAuth<BondPortfolioSettings>({
    method: 'GET',
    requestUrl: `/bond-portfolio/${proposalId}`,
  });
}

export function previewTaxTransition(
  accountId: string,
  lockedPositions: string[],
  constructionInfo: ConstructionInfo
) {
  return doRequestWithAuth<TransitionAnalysis>({
    method: 'POST',
    requestUrl: `/xray/previewTaxTransition/${accountId}`,
    data: {
      lockedPositions,
      constructionInfo,
    },
  });
}

export function calculateTaxForBondProposal(params: CalculateTaxParams) {
  const urlParams = new URLSearchParams(Object.entries(params));
  return doRequestWithAuth<TaxRates>({
    method: 'GET',
    requestUrl: `/bond-portfolio/calculate-taxes?${urlParams.toString()}`,
  });
}

export function downloadBulkEditCSV() {
  return doRequestWithAuth<{ csv: string }>({
    method: 'GET',
    requestUrl: '/bulk-edit/cap-gains-budget/download',
  });
}

export function uploadBulkEditCSV(data: ArrayBuffer, fileName: string) {
  return doRequestWithAuth<{ success: boolean; details?: string; title?: string }>({
    method: 'POST',
    requestUrl: '/bulk-edit/cap-gains-budget/csvUpload',
    additionalHeaders: {
      'Content-Type': 'text/csv',
      fileName,
    },
    data,
  });
}

export function downloadUploadedBulkEditCSV(jobId: string) {
  return doRequestWithAuth<{ url: string }>({
    method: 'GET',
    requestUrl: `/bulk-edit/${jobId}/bulkEditUploadedCSV`,
  });
}

export async function cancelBulkUpload(jobId: string) {
  return doRequestWithAuth<{ url: string }>({
    method: 'POST',
    requestUrl: `/bulk-edit/job/${jobId}/cancel`,
  });
}

export async function completeActivityMonitorNotification(notificationId: string) {
  return doRequestWithAuth<{ success: boolean }>({
    method: 'POST',
    requestUrl: `/notification/activity_monitor/complete/${notificationId}`,
  });
}

export async function updateActivityMonitorNotification(
  notificationId: string,
  data: ActivityMonitorNotificationData
) {
  return doRequestWithAuth<{}>({
    method: 'POST',
    requestUrl: `/notification/activity_monitor/${notificationId}/update`,
    data: {
      data,
    },
  });
}

export function fullXrayAnalysis(proposalId: string) {
  return doRequestWithAuth<XrayAnalysisResponse>({
    method: 'GET',
    requestUrl: `/xray/full-xray-analysis/${proposalId}`,
  });
}
