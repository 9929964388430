import { Box, Button, Dialog, DialogActions, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AllocationsTemplate, RestrictionsTemplate, TEMPLATE_TYPE } from 'vise-types/template';
import { updateLinkedAccounts } from '~/api/api';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import launchImage from '~/static/images/modelTemplateCenter/launch.svg';
import themesImage from '~/static/images/modelTemplateCenter/themes.svg';
import DialogTitle from '~/synth/DialogTitle';

export default function LinkTemplateCTA({
  templates,
  operation,
  links,
}: {
  templates: (AllocationsTemplate | RestrictionsTemplate)[];
  operation: 'CREATE' | 'EDIT';
  links: { accountId: string; templateId: string; templateType: TEMPLATE_TYPE }[];
}) {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const queryKey = operation === 'CREATE' ? 'createTemplateId' : 'editTemplateId';
  const templateId = searchParams.get(queryKey);
  const matchedTemplate = templates.find((t) => t.parentId === templateId);
  const [updating, setUpdating] = useState(false);
  const enqueueCoachmark = useEnqueueCoachmark();

  if (!matchedTemplate) {
    return null;
  }
  const close = () => {
    const newSearchParams = new URLSearchParams(search);
    newSearchParams.delete(queryKey);
    history.replace(`${pathname}?${newSearchParams.toString()}`);
  };
  return (
    <Dialog open>
      <DialogTitle onClose={close} />
      <Box py={4} mx={3}>
        <Box width="150px" margin="auto">
          <img src={operation === 'CREATE' ? launchImage : themesImage} alt="" width="100%" />
        </Box>
        <Box mt={4} textAlign="center">
          <Typography variant="h2">
            {operation === 'CREATE' ? 'New template created!' : 'Template edit successful!'}
          </Typography>
        </Box>
        <Box mt={2} textAlign="center">
          <Typography variant="body2" color="textSecondary">
            {operation === 'CREATE' ? (
              <>
                Your {'allocations' in matchedTemplate ? 'allocations' : 'restrictions'} template,{' '}
                <strong>{matchedTemplate.name}</strong>, has been successfully saved. Would you like
                to link accounts to it?
              </>
            ) : (
              <>
                Your changes to <strong>{matchedTemplate.name}</strong> have been saved and
                published to your organization. Would you like to update linked accounts to the new
                version of the template?
              </>
            )}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <DialogActions>
        <Button onClick={close} variant="outlined">
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={updating}
          onClick={async () => {
            if (operation === 'CREATE') {
              // Rewrite the history stack so going back doesn't pop up the modal again
              close();
              history.push(`/secure/template/link/${matchedTemplate.parentId}`);
            } else {
              setUpdating(true);
              try {
                await updateLinkedAccounts(
                  matchedTemplate.parentId,
                  [],
                  links
                    .filter((l) => {
                      // Include all accounts linked to stale versions of the new template
                      const template = templates.find((t) => l.templateId === t.parentId);
                      if (!template || template.parentId === templateId) {
                        return false;
                      }
                      return template.originalTemplateId === matchedTemplate.originalTemplateId;
                    })
                    .map((l) => l.accountId),
                  'UPDATE'
                );
                close();
              } catch (e) {
                enqueueCoachmark({
                  title: 'Failed to update linked accounts',
                  content: 'Please reach out to clientservice@vise.com for help',
                  severity: 'error',
                });
                setUpdating(false);
              }
            }
          }}
        >
          {operation === 'EDIT' ? 'Update linked accounts' : 'Link accounts'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
