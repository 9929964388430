import React from 'react';
import allocationActiveIcon from '~/static/images/PortfolioCreator2/allocation-rounded-active.svg';
import allocationInactiveIcon from '~/static/images/PortfolioCreator2/allocation-rounded-inactive.svg';

interface AllocationIconProps {
  active: boolean;
}

export default function AllocationIcon({ active }: AllocationIconProps) {
  return (
    <img
      height={120}
      width={120}
      src={active ? allocationActiveIcon : allocationInactiveIcon}
      alt=""
    />
  );
}
