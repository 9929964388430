import usePortfolioIntelligence from '~/hooks/usePortfolioIntelligence';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export default function usePortfolioIntelligenceWithLogging(
  intelligenceId: string | undefined,
  callerName: string,
  extra: { [key: string]: unknown } = {}
) {
  const { data, error } = usePortfolioIntelligence(intelligenceId);

  useEffect(() => {
    if (error != null) {
      Sentry.captureException(
        new Error(
          `[${callerName}] Failed to load intelligence with id: ${intelligenceId}. ${error}`
        ),
        {
          extra: {
            intelligenceId,
            ...extra,
          },
        }
      );
    }
  }, [callerName, error, extra, intelligenceId]);

  return {
    data,
    error,
  };
}
