import { Box, BoxProps, Tooltip, Typography } from '@mui/material';
import { pickBy } from 'lodash';
import React, { useMemo } from 'react';
import { AssetClassKey, Country, Feature } from 'vise-types/pce2_instrument';
import { useIndexedInstruments } from '~/hooks/useInstruments';
import useSectors from '~/hooks/useSectors';
import { Sector } from '~/models/api';
import Truncation from '~/routes/Portfolio/components/Truncation';
import { EditButton, logEditButtonClick } from '.';
import {
  ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP,
  ASSET_CLASS_TO_LABEL_MAP,
  COUNTRY_TO_LABEL_MAP,
  ESG_LABEL_MAP,
} from '../../Constants';

export const BorderBox = ({ children, ...rest }: BoxProps) => {
  return (
    // `borderColor` is not respected with `border` declaration, use a workaround
    // see https://github.com/mui-org/material-ui/issues/16995#issuecomment-594134826
    <Box
      border={1}
      borderLeft={0}
      borderRight={0}
      borderTop={0}
      borderColor="grey.200"
      pb={1.5}
      {...rest}
    >
      {children}
    </Box>
  );
};

export const SubSectionHeader = ({ children }) => {
  return (
    <BorderBox>
      <Typography variant="h4">{children}</Typography>
    </BorderBox>
  );
};

export const SubSectionBody = ({
  children,
  ...restProps
}: {
  children: React.ReactNode;
} & BoxProps) => (
  <Box py={2} {...restProps}>
    {children}
  </Box>
);

export const SubSectionText: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
  ...restProps
}) => <Box {...restProps}>{children}</Box>;

export const SubSectionSubText: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <Box color="grey.500" mt={0.5}>
    <Typography variant="body1">{children}</Typography>
  </Box>
);

export function ExcludedSectors({
  excludedSectors,
  excludedIndustries,
  compact,
  editButton,
}: {
  excludedSectors?: string[] | null;
  excludedIndustries?: string[] | null;
  compact?: boolean;
  editButton?: boolean;
}) {
  const { data: sectors } = useSectors();
  const excludedSectorsSet = useMemo(() => new Set(excludedSectors), [excludedSectors]);
  const excludedIndustriesSet = useMemo(() => new Set(excludedIndustries), [excludedIndustries]);

  let content: React.ReactNode;
  if (excludedSectors?.length === 0 && excludedIndustries?.length === 0) {
    content = (
      <SubSectionBody>
        <SubSectionText>None selected</SubSectionText>
      </SubSectionBody>
    );
  } else if (sectors == null) {
    content = (
      <SubSectionBody>
        <SubSectionText>Loading…</SubSectionText>
      </SubSectionBody>
    );
  } else {
    content = (
      <SubSectionBody>
        {(sectors.length > 2 && compact ? sectors.slice(0, 2) : sectors).map((sector: Sector) => {
          let innerContent: React.ReactNode = null;

          if (excludedSectorsSet.has(sector.key)) {
            // Entire sector was chosen
            innerContent = <SubSectionSubText>All sub-sectors selected</SubSectionSubText>;
          }

          // Gather any excluded industries and render them with their parent sector if any exist
          const industryChildren = sector.industries.reduce((acc, industry) => {
            if (excludedIndustriesSet.has(industry.key)) {
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
              acc.push(<SubSectionSubText key={industry.key}>{industry.name}</SubSectionSubText>);
            }
            return acc;
          }, []);

          if (industryChildren.length > 0) {
            innerContent = <>{industryChildren}</>;
          }

          if (innerContent == null) return null;
          return (
            <Box key={sector.key} mb={2}>
              <SubSectionText>{sector.name}</SubSectionText>
              {innerContent}
            </Box>
          );
        })}
        {sectors.length > 2 && compact ? (
          <Tooltip
            title={sectors
              .slice(2)
              .map((sector) => sector.name)
              .join(', ')}
          >
            <Box mt={1}>
              <Typography variant="body2" color="textSecondary">
                and {sectors.length - 2} more…
              </Typography>
            </Box>
          </Tooltip>
        ) : null}
      </SubSectionBody>
    );
  }

  return excludedSectors == null || excludedIndustries == null ? null : (
    <Box data-testid="restricted-sectors-summary">
      {editButton ? (
        <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
          <Typography variant="h4">Sectors</Typography>
          <EditButton
            onClick={() => logEditButtonClick('sectors')}
            to="/secure/portfolio-creator-next/build-restrictions"
          />
        </BorderBox>
      ) : (
        <SubSectionHeader>Sectors</SubSectionHeader>
      )}
      {content}
    </Box>
  );
}

export function RestrictedStocks({
  restrictedStocks,
  compact,
  editButton,
}: {
  restrictedStocks: string[] | undefined | null;
  compact?: boolean;
  editButton?: boolean;
}) {
  const { data: instruments } = useIndexedInstruments();

  return restrictedStocks == null ? null : (
    <Box data-testid="restricted-stocks-summary">
      {editButton ? (
        <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
          <Typography variant="h4">Tickers</Typography>
          <EditButton
            onClick={() => logEditButtonClick('tickers')}
            to="/secure/portfolio-creator-next/build-restrictions"
          />
        </BorderBox>
      ) : (
        <SubSectionHeader>Tickers</SubSectionHeader>
      )}
      {restrictedStocks.length === 0 ? (
        <SubSectionBody>
          <SubSectionText>None selected</SubSectionText>
        </SubSectionBody>
      ) : (
        <SubSectionBody>
          {(restrictedStocks.length > 2 && compact
            ? restrictedStocks.slice(0, 2)
            : restrictedStocks
          ).map((symbol: string, index: number) => {
            return (
              <Box key={symbol} mt={index === 0 ? 0 : 2}>
                <SubSectionText>{symbol}</SubSectionText>
                <SubSectionSubText>{instruments?.[symbol]?.name ?? <>…</>}</SubSectionSubText>
              </Box>
            );
          })}
          {restrictedStocks.length > 2 && compact ? (
            <Tooltip title={restrictedStocks.slice(2).join(', ')}>
              <Box mt={1}>
                <Typography variant="body2" color="textSecondary">
                  and {restrictedStocks.length - 2} more…
                </Typography>
              </Box>
            </Tooltip>
          ) : null}
        </SubSectionBody>
      )}
    </Box>
  );
}

export function ExcludedEsgAreas({
  excludedEsgAreas,
  compact,
  editButton,
}: {
  excludedEsgAreas: string[] | undefined | null;
  compact?: boolean;
  editButton?: boolean;
}) {
  return excludedEsgAreas == null ? null : (
    <Box data-testid="restricted-esg-areas-summary">
      {editButton ? (
        <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
          <Typography variant="h4">Values</Typography>
          <EditButton
            onClick={() => logEditButtonClick('values')}
            to="/secure/portfolio-creator-next/build-restrictions"
          />
        </BorderBox>
      ) : (
        <SubSectionHeader>Values</SubSectionHeader>
      )}
      {excludedEsgAreas.length === 0 ? (
        <SubSectionBody>
          <SubSectionText>None selected</SubSectionText>
        </SubSectionBody>
      ) : (
        <SubSectionBody>
          {(excludedEsgAreas.length > 2 && compact
            ? excludedEsgAreas.slice(0, 2)
            : excludedEsgAreas
          ).map((esgArea: string, index: number) => {
            return (
              <Box key={esgArea} mt={index === 0 ? 0 : 2}>
                <SubSectionText>{ESG_LABEL_MAP[esgArea].label}</SubSectionText>
              </Box>
            );
          })}
          {excludedEsgAreas.length > 2 && compact ? (
            <Tooltip
              title={excludedEsgAreas
                .map((esgArea) => ESG_LABEL_MAP[esgArea].label)
                .slice(2)
                .join(', ')}
            >
              <Box mt={1}>
                <Typography variant="body2" color="textSecondary">
                  and {excludedEsgAreas.length - 2} more…
                </Typography>
              </Box>
            </Tooltip>
          ) : null}
        </SubSectionBody>
      )}
    </Box>
  );
}

export function ExcludedCountries({
  restrictedCountries: excludedCountries,
  compact,
  editButton,
}: {
  restrictedCountries?: Country[] | null;
  compact?: boolean;
  editButton?: boolean;
}) {
  return excludedCountries == null ? null : (
    <Box data-testid="restricted-countries-summary">
      {editButton ? (
        <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
          <Typography variant="h4">Countries</Typography>
          <EditButton
            onClick={() => logEditButtonClick('countries')}
            to="/secure/portfolio-creator-next/build-restrictions"
          />
        </BorderBox>
      ) : (
        <SubSectionHeader>Countries</SubSectionHeader>
      )}
      {excludedCountries.length === 0 ? (
        <SubSectionBody>
          <SubSectionText>None selected</SubSectionText>
        </SubSectionBody>
      ) : (
        <SubSectionBody>
          {(excludedCountries.length > 2 && compact
            ? excludedCountries.slice(0, 2)
            : excludedCountries
          ).map((country: Country, index: number) => {
            return (
              <Box key={country} mt={index === 0 ? 0 : 2}>
                <SubSectionText>{COUNTRY_TO_LABEL_MAP[country]}</SubSectionText>
              </Box>
            );
          })}
          {excludedCountries.length > 2 && compact ? (
            <Tooltip
              title={excludedCountries
                .map((c) => COUNTRY_TO_LABEL_MAP[c])
                .slice(2)
                .join(', ')}
            >
              <Box mt={1}>
                <Typography variant="body2" color="textSecondary">
                  and {excludedCountries.length - 2} more…
                </Typography>
              </Box>
            </Tooltip>
          ) : null}
        </SubSectionBody>
      )}
    </Box>
  );
}

export function DetailsText({
  children,
  variant = 'body2',
  color = 'textPrimary',
  testId,
  textComponent = 'p',
}: {
  children: React.ReactNode;
  variant?: 'body2' | 'body1';
  color?: 'textPrimary' | 'textSecondary';
  testId?: string;
  textComponent?: 'p' | 'div';
}) {
  return (
    <Box mb={0.5}>
      <Typography variant={variant} color={color} data-testid={testId} component={textComponent}>
        {children}
      </Typography>
    </Box>
  );
}

export function SubDetailsText({
  children,
  testId,
  textComponent,
}: {
  children: React.ReactNode;
  testId?: string;
  textComponent?: 'p' | 'div';
}) {
  return (
    <DetailsText
      variant="body1"
      textComponent={textComponent}
      color="textSecondary"
      testId={testId}
    >
      {children}
    </DetailsText>
  );
}

export function Section({
  children,
  isOuterSection,
}: {
  children: React.ReactNode;
  isOuterSection?: boolean;
}) {
  return (
    <Box mb={isOuterSection ? 4 : 2} component="section">
      {children}
    </Box>
  );
}

function TwoColumn({ left, right }) {
  return (
    <Box display="flex" width="100%">
      <Box maxWidth="80%" mr="auto">
        {left}
      </Box>
      <Box justifySelf="end">{right}</Box>
    </Box>
  );
}

export function ThreeColumn({
  key,
  left,
  middle,
  right,
}: {
  key: string;
  left: React.ReactElement;
  middle?: React.ReactElement | undefined;
  right: React.ReactElement;
}) {
  if (!middle) {
    return <TwoColumn key={key} left={left} right={right} />;
  }

  return (
    <Box display="flex" width="100%">
      <Box width="60%" mr="auto">
        {left}
      </Box>
      <Box width="20%" textAlign="end">
        {middle}
      </Box>
      <Box width="20%" textAlign="end">
        {right}
      </Box>
    </Box>
  );
}

export function onlyTerminalKeys(assetAllocation: { [key in AssetClassKey]?: number }) {
  return pickBy(assetAllocation, (alloc, key) => {
    const descendants = ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get(key as AssetClassKey);
    return !descendants || descendants.length === 0;
  });
}

export function sumKeys(
  keys: AssetClassKey[],
  assetAllocation: { [key in AssetClassKey]?: number } = {}
) {
  const terminalAllocation = onlyTerminalKeys(assetAllocation);
  return keys.map((key) => terminalAllocation[key] || 0).reduce((sum, alloc) => sum + alloc, 0);
}

export function SubCategoryAllocation({
  assetAllocation,
  parent,
  originalAssetAllocation,
}: {
  parent: AssetClassKey;
  assetAllocation?: { [key in AssetClassKey]?: number };
  originalAssetAllocation?: { [key in AssetClassKey]?: number }; // for displaying editing changes
}) {
  const allocation = onlyTerminalKeys(assetAllocation || {});
  const originalAllocation = onlyTerminalKeys(originalAssetAllocation || {});

  const childrenKeys = [...(ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get(parent) || [])]
    .filter((key) => allocation[key] != null)
    .sort((key1, key2) => (allocation[key2] || 0) - (allocation[key1] || 0));
  const sum = childrenKeys.length ? sumKeys(childrenKeys, allocation) : allocation[parent] || 0;
  const originalSum = childrenKeys.length
    ? sumKeys(childrenKeys, originalAllocation)
    : originalAllocation[parent] || 0;

  return (
    <Section>
      <ThreeColumn
        key={parent}
        left={
          <DetailsText textComponent="div">
            <Truncation>
              {parent
                .split('/')
                .slice(1)
                .map((feature) => ASSET_CLASS_TO_LABEL_MAP.get(feature as Feature) || '')
                .join(' ')}
            </Truncation>
          </DetailsText>
        }
        middle={
          originalAssetAllocation ? (
            <DetailsText variant="body1" testId={`allocation-input-${parent}`}>
              {(originalSum * 100).toFixed(0)}%
            </DetailsText>
          ) : undefined
        }
        right={
          <DetailsText variant="body1" testId={`allocation-input-${parent}`}>
            {(sum * 100).toFixed(0)}%
          </DetailsText>
        }
      />
      {childrenKeys.map((key) => (
        <ThreeColumn
          key={key}
          left={
            <SubDetailsText textComponent="div">
              <Truncation>
                {key
                  .split('/')
                  .slice(2)
                  .map((feature) => ASSET_CLASS_TO_LABEL_MAP.get(feature as Feature) || '')
                  .join(' - ')}
              </Truncation>
            </SubDetailsText>
          }
          middle={
            originalAssetAllocation ? (
              <SubDetailsText testId={`allocation-input-${key}`}>
                {((originalAllocation[key] || 0) * 100).toFixed(0)}%
              </SubDetailsText>
            ) : undefined
          }
          right={
            <SubDetailsText testId={`allocation-input-${key}`}>
              {((allocation[key] || 0) * 100).toFixed(0)}%
            </SubDetailsText>
          }
        />
      ))}
    </Section>
  );
}
