// eslint-disable-next-line no-restricted-imports
import {
  Box,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';

interface DialogTitleProps extends MuiDialogTitleProps {
  onClose: () => void;
}

const StyledMuiDialogTitle = styled(MuiDialogTitle)`
  position: relative;
  padding: 24px 24px 20px 24px;
  text-transform: none;
`;

const DialogTitle = (props: DialogTitleProps) => {
  const { onClose } = props;
  return (
    <StyledMuiDialogTitle {...props}>
      <Box position="absolute" right="4px" top="4px">
        <IconButton color="inherit" onClick={onClose} size="large">
          <XIcon height="20" width="20" />
        </IconButton>
      </Box>
      <Typography variant="h2" component="div">
        {props.children}
      </Typography>
    </StyledMuiDialogTitle>
  );
};

export default DialogTitle;
