"use client";

import { createTheme } from "@mui/material";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import tokens from "../tokens/index.js";
import palette from "../tokens/palette.js";
import spacing, { SPACING_INDICES } from "../tokens/spacing.js";
import shadow from "../tokens/shadow.js";
import type { ThemeOptions } from "@mui/material/styles";
import ChevronDownIcon from "../components/Icons/ChevronDownIcon.js";
import {
  radioCheckedIconElement,
  radioIconElement,
} from "../components/Icons/RadioIcon.js";
import {
  checkboxCheckedIconElement,
  checkboxIconElement,
} from "../components/Icons/CheckboxIcon.js";

type PaletteT = Record<number, string>;

const BUTTON_PADDING = "12px 16px";
const BUTTON_PADDING_OUTLINED_SMALL = "8px 16px";
const BUTTON_PADDING_SMALL = "8px 16px";

declare module "@mui/material/styles" {
  interface Theme {
    viseShadows: {
      base: string;
      medium: string;
      large: string;
      inner: string;
    };
  }

  interface ThemeOptions {
    viseShadows?: {
      base: string;
      medium: string;
      large: string;
      inner: string;
    };
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }

  interface Palette {
    primaryBlue: PaletteT;
    neutralCool: PaletteT;
    secondaryGreen: PaletteT;
    secondaryTurquoise: PaletteT;
    secondaryYellow: PaletteT;
    secondaryPurple: PaletteT;
    secondaryMagenta: PaletteT;
    secondarySubtleGreen: PaletteT;
    secondarySubtleOrange: PaletteT;
    secondarySubtleYellow: PaletteT;
    secondaryGold: PaletteT;
    secondaryLimeGreen: PaletteT;
    semanticGreen: PaletteT;
    semanticOrange: PaletteT;
    semanticRed: PaletteT;
  }

  interface PaletteOptions {
    primaryBlue?: PaletteT;
    neutralCool?: PaletteT;
    secondaryGreen?: PaletteT;
    secondaryTurquoise?: PaletteT;
    secondaryYellow?: PaletteT;
    secondaryPurple?: PaletteT;
    secondarySubtleGreen?: PaletteT;
    secondarySubtleOrange?: PaletteT;
    secondarySubtleYellow?: PaletteT;
    secondaryGold?: PaletteT;
    secondaryLimeGreen?: PaletteT;
    semanticRed?: PaletteT;
    semanticGreen?: PaletteT;
    semanticOrange?: PaletteT;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    title: true;
    h7: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    destructive: true;
    ghost: true;
  }
}

export const themeConfig = {
  palette: tokens.palette,
  typography: tokens.typography,
  spacing: tokens.spacing,
  // Separating out the shadows so existing components aren't broken since the default is an array of 25 strings
  // But using named variables is more concise and clear
  viseShadows: tokens.shadow,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "12px",
        },
        root: {
          backgroundColor: "#fff",
          fontSize: "1rem",
          lineHeight: 1.25,
          color: palette.neutralCool[1000],
          "& .MuiOutlinedInput-input": {
            "&::placeholder": {
              opacity: 1,
              color: palette.neutralCool[600],
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: `solid 1px ${palette.neutralCool[300]}`,
            borderRadius: "4px",
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.neutralCool[800],
            },
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.primaryBlue[600],
            },
          },
          "&.Mui-disabled": {
            color: palette.neutralCool[300],
            backgroundColor: palette.neutralCool[100],
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.neutralCool[300],
            },
          },
          "&.Mui-readOnly": {
            backgroundColor: palette.primaryBlue[100],
            color: palette.primaryBlue[800],
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.primaryBlue[500],
            },
          },
          "&.Mui-error": {
            "& .MuiOutlineInput-notchedOutline": {
              borderColor: palette.semanticRed[600],
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#fff",
          padding: "12px",
          fontSize: "0.875rem",
          fontWeight: 400,
          color: palette.neutralCool[600],
          lineHeight: 1.25,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontWeight: 500,
          lineHeight: 1.25,
          color: palette.neutralCool[1000],
          marginBottom: "10px",
          display: "block",
          "& .MuiFormLabel-asterisk": {
            color: palette.semanticOrange[600],
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(207, 208, 217, 0.25)",
          height: "8px",
          borderRadius: "16px",
        },
        bar: {
          background:
            "linear-gradient(90.04deg, #2CC4D2 -6.16%, #1753DE 40.12%, #670AF4 85.58%)",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: palette.neutralCool[100],
          },
          "&:active": {
            backgroundColor: palette.primaryBlue[100],
          },
          "&:focus-visible": {
            backgroundColor: palette.primaryBlue[100],
          },
          color: "#000",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "4px",
          boxShadow: shadow.large,
          backgroundColor: "#fff",
          maxHeight: "none",
          maxWidth: "none",
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        viewBox: "0 0 20 20",
      },
      styleOverrides: {
        fontSizeInherit: {
          fontSize: "1.25rem",
        },
        fontSizeMedium: {
          fontSize: "1.25rem",
        },
        fontSizeLarge: {
          fontSize: "1.5rem",
        },
        fontSizeSmall: {
          fontSize: "1rem",
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: `${tokens.spacing[tokens.SPACING_INDICES.lg]}px`,
      },
    },
    MuiDataGrid: {
      defaultProps: {
        columnHeaderHeight: 56,
        rowHeight: 56,
        hideFooter: true,
        disableColumnMenu: true,
        disableRowSelectionOnClick: true,
      },
      styleOverrides: {
        columnHeaders: {
          backgroundColor: palette.neutralCool[100],
        },
        columnHeader: {
          padding: `0 ${spacing[SPACING_INDICES.xl]}px`,
        },
        withBorderColor: {
          borderColor: palette.neutralCool[300],
        },
        columnHeaderTitle: {
          fontSize: "0.875rem",
          fontWeight: 700,
          lineHeight: 1.25,
          color: palette.neutralCool[600],
        },
        cell: {
          padding: `0 ${spacing[SPACING_INDICES.xl]}px`,
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: 1.25,
          color: palette.neutralCool[1000],
        },
        root: {
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderColor: palette.neutralCool[300],
          borderRadius: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: tokens.palette.neutralCool[300],
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: 0,
            "& .MuiOutlinedInput-input": {
              padding: "12px",
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: tokens.shadow.base,
        },
      },
      variants: [
        { props: { elevation: 1 }, style: { boxShadow: tokens.shadow.medium } },
      ],
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
      defaultProps: {
        titleTypographyProps: { variant: "h3" },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: palette.neutralCool[1000],
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          paddingTop: spacing[tokens.SPACING_INDICES.sm],
          paddingBottom: spacing[tokens.SPACING_INDICES.lg],
          paddingLeft: 0,
          paddingRight: 0,
          minWidth: 0,
          "&:not(:last-child)": {
            marginRight: spacing[tokens.SPACING_INDICES.xl],
          },
          textTransform: "none",
          fontWeight: 500,
          fontSize: "1rem",
          lineHeight: 1.25,
          color: palette.neutralCool[600],
          "&.Mui-selected": {
            color: palette.neutralCool[1000],
            outline: 0,
          },
          ":hover": {
            color: palette.neutralCool[800],
          },
          "&:disabled": {
            color: palette.neutralCool[500],
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title: "h1",
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          h7: "h6",
          body1: "p",
          body2: "p",
          body3: "p",
          inherit: "p",
        },
      },
      // Default colors - h5/h6 use cool 900, other variants use cool 1000
      variants: [
        {
          props: { variant: "title" },
          style: { color: tokens.palette.neutralCool[1000] },
        },
        {
          props: { variant: "h1" },
          style: { color: tokens.palette.neutralCool[1000] },
        },
        {
          props: { variant: "h2" },
          style: { color: tokens.palette.neutralCool[1000] },
        },
        {
          props: { variant: "h3" },
          style: { color: tokens.palette.neutralCool[1000] },
        },
        {
          props: { variant: "h4" },
          style: { color: tokens.palette.neutralCool[1000] },
        },
        {
          props: { variant: "h5" },
          style: { color: tokens.palette.neutralCool[900] },
        },
        {
          props: { variant: "h6" },
          style: { color: tokens.palette.neutralCool[900] },
        },
        {
          props: { variant: "h7" },
          style: { color: tokens.palette.neutralCool[900] },
        },
        {
          props: { variant: "body1" },
          style: { color: tokens.palette.neutralCool[1000] },
        },
        {
          props: { variant: "body2" },
          style: { color: tokens.palette.neutralCool[1000] },
        },
        {
          props: { variant: "body3" },
          style: { color: tokens.palette.neutralCool[1000] },
        },
      ],
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          "& > button": {
            marginRight: "8px",
          },
        },
        firstButton: {
          borderRadius: "4px",
        },
        lastButton: {
          borderRadius: "4px",
        },
        middleButton: {
          borderRadius: "4px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          lineHeight: 1.25,
          fontWeight: 500,
          textTransform: "none",
          fontFamily: tokens.FONT_FAMILY,
          padding: "8px",
          backgroundColor: "transparent",
          color: palette.neutralCool[600],
          borderWidth: 0,
          "&:hover": {
            backgroundColor: palette.primaryBlue[200],
            color: palette.primaryBlue[600],
          },
          "&.Mui-selected:hover": {
            backgroundColor: palette.primaryBlue[200],
            color: palette.primaryBlue[600],
          },
          "&:active": {
            color: palette.primaryBlue[600],
            backgroundColor: palette.primaryBlue[100],
          },
          "&.Mui-selected": {
            color: palette.primaryBlue[600],
            backgroundColor: palette.primaryBlue[100],
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "primary",
      },
      styleOverrides: {
        root: {
          fontSize: "1rem",
          lineHeight: "20px",
          fontWeight: 500,
          textTransform: "none",
          fontFamily: tokens.FONT_FAMILY,
          "&:disabled": {
            color: palette.neutralCool[400],
            borderColor: palette.neutralCool[300],
          },
        },
      },
      variants: [
        {
          props: { variant: "primary" },
          style: {
            padding: BUTTON_PADDING,
            backgroundColor: palette.primary.main,
            color: "#fff",
            "&:hover": {
              backgroundColor: palette.primaryBlue[700],
            },
            "&:active": {
              backgroundColor: palette.primaryBlue[900],
            },
            "&.Mui-disabled": {
              backgroundColor: palette.neutralCool[100],
            },
          },
        },
        {
          props: { variant: "primary", size: "small" },
          style: {
            padding: BUTTON_PADDING_SMALL,
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            padding: BUTTON_PADDING,
            backgroundColor: "#fff",
            color: palette.primary.main,
            border: `solid 1px ${palette.primary.main}`,
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: palette.primaryBlue[100],
            },
            "&:active": {
              backgroundColor: palette.primaryBlue[200],
            },
            "&:disabled": {
              backgroundColor: palette.neutralCool[100],
              color: palette.neutralCool[300],
              border: `solid 1px ${palette.neutralCool[300]}`,
            },
          },
        },
        {
          props: { variant: "secondary", size: "small" },
          style: {
            padding: BUTTON_PADDING_OUTLINED_SMALL,
          },
        },
        {
          props: { variant: "tertiary" },
          style: {
            padding: BUTTON_PADDING,
            backgroundColor: "#fff",
            color: palette.neutralCool[1000],
            border: `solid 1px ${palette.neutralCool[300]}`,
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: palette.neutralCool[100],
            },
            "&:active": {
              backgroundColor: palette.neutralCool[200],
            },
            "&:disabled": {
              backgroundColor: palette.neutralCool[100],
              color: palette.neutralCool[400],
            },
          },
        },
        {
          props: { variant: "tertiary", size: "small" },
          style: {
            padding: BUTTON_PADDING_OUTLINED_SMALL,
          },
        },
        {
          props: { variant: "destructive" },
          style: {
            padding: BUTTON_PADDING,
            backgroundColor: "#fff",
            border: `solid 1px ${palette.semanticRed[600]}`,
            borderRadius: "4px",
            color: palette.semanticRed[600],
            "&:hover": {
              backgroundColor: palette.semanticRed[100],
            },
            "&:active": {
              backgroundColor: palette.semanticRed[200],
            },
            "&:disabled": {
              backgroundColor: "#fff",
            },
          },
        },
        {
          props: { variant: "destructive", size: "small" },
          style: {
            padding: BUTTON_PADDING_OUTLINED_SMALL,
          },
        },
        {
          props: { variant: "ghost" },
          style: {
            padding: "12px",
            backgroundColor: "transparent",
            color: palette.neutralCool[1000],
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: palette.neutralCool[100],
            },
            "&:active": {
              backgroundColor: palette.neutralCool[200],
            },
            "&:disabled": {
              color: palette.neutralCool[400],
            },
          },
        },
        {
          props: { variant: "ghost", size: "small" },
          style: {
            padding: "8px 12px",
          },
        },
      ],
    },
    MuiNativeSelect: {
      defaultProps: {
        IconComponent: ChevronDownIcon,
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ChevronDownIcon,
      },
    },
    MuiRadio: {
      defaultProps: {
        icon: radioIconElement,
        checkedIcon: radioCheckedIconElement,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: checkboxIconElement,
        checkedIcon: checkboxCheckedIconElement,
      },
      styleOverrides: {
        root: {
          color: tokens.palette.neutralCool[300],
          "& .MuiSvgIcon-root": {
            backgroundColor: "#fff",
          },
          "&:hover": {
            color: tokens.palette.neutralCool[800],
          },
          "&.Mui-disabled": {
            "& .MuiSvgIcon-root": {
              backgroundColor: tokens.palette.neutralCool[100],
            },
          },
        },
      },
    },
  },
} as ThemeOptions;

const theme = createTheme(themeConfig);

export default theme;
