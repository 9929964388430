import React from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themeObj from './theme';

type ThemeProviderProps = {
  children: React.ReactNode;
};

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    // Tell material-ui to inject its styles first so that
    // styled-components can override/extend the styles, else
    // material-ui will override:
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={themeObj}>
        {/* Pass theme to styled-components as well: */}
        <StyledComponentsThemeProvider theme={themeObj}>
          <CssBaseline />
          {children}
        </StyledComponentsThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
