import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { EngineType } from 'vise-types/portfolio';
import { TextHighlightTag } from '~/synth/Tag';
import { accountStatusTooltipText } from '../table/UtilComponents';

export default function renderRebalancerStatus({
  value,
  engineType,
}: {
  value?: { status: 'ACTIVE' | 'PAUSED'; rebalancerPausedAt: string | null };
  engineType?: EngineType;
}) {
  if (!value) {
    return null;
  }
  const { rebalancerPausedAt, status } = value;
  let text: string | React.ReactElement = '';
  let severity: 'success' | 'warning' | 'default' = 'success';
  if (status === 'ACTIVE') {
    text = 'Active';
    severity = 'success';
  } else if (status === 'PAUSED') {
    text = 'Paused';
    severity = 'warning';
  }

  if (engineType != null && ['AB_FIXED_INCOME'].indexOf(engineType) >= 0) {
    text = 'N/A';
    severity = 'default';
  }

  const tooltipText = accountStatusTooltipText({ rebalancerPausedAt, status });
  if (tooltipText) {
    text = (
      <Tooltip title={tooltipText}>
        <Box>{text}</Box>
      </Tooltip>
    );
  }
  return (
    <TextHighlightTag severity={severity} fontWeight={400}>
      {text}
    </TextHighlightTag>
  );
}
