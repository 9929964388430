import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import useUser from '~/hooks/useUser';
import runikImage from '~/static/images/runik.png';

const DEMO_IMAGES = {
  'runik@vise.com': runikImage,
};

const UserMessage = ({ content }) => {
  const { data: user } = useUser();
  if (!user) {
    return null;
  }

  let imageAvailable = false;
  const image = DEMO_IMAGES[user.email];
  if (image) {
    imageAvailable = true;
  }

  const style: React.CSSProperties = {
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    objectFit: 'cover',
  };
  return (
    <Box
      border="1px solid #F7F7F7"
      borderRadius="4px"
      bgcolor="rgba(250, 250, 250, 0.20)"
      boxShadow="0px 6px 16px 0px rgba(0, 0, 0, 0.05)"
      sx={{ backdropFilter: 'blur(20px)' }}
    >
      <Box p={2} pl={3} display="flex" justifyContent="start" gap={2} alignItems="center">
        <Box sx={{ width: 45, height: 45 }}>
          {imageAvailable ? (
            <img src={image} alt="Logo" style={style} />
          ) : (
            <Avatar sx={{ bgcolor: '#d5f3f6', color: '#1a767e', width: 45, height: 45 }}>
              {user.firstName.slice(0, 1)}
              {user.lastName.slice(0, 1)}
            </Avatar>
          )}
        </Box>
        <Box pt={0}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 400, fontSize: '1.1rem', lineHeight: '30px' }}
          >
            {content}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserMessage;
