import React, { useMemo, useState } from 'react';
import {
  Button,
  Menu,
  ButtonProps,
  MenuProps,
  MenuItem,
  MenuItemProps,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as ChevronDownIcon } from '~/static/images/icons/chevron-down.svg';

const CHEVRON_OPEN_TRANSFORM = 'rotate(-180deg)';

export type DropdownButtonMenuProps = {
  id?: string;
  buttonProps?: Partial<ButtonProps>;
  menuProps?: Partial<MenuProps>;
  buttonContent: React.ReactNode;
  children: (closeMenu: () => void) => React.ReactNode;
  isIcon?: boolean;
  style?: React.CSSProperties;
  stopPropagation?: boolean | null;
  disabled?: boolean;
  chevron?: boolean;
  disabledReason?: string;
};

const useMenuItemStyles = makeStyles()((theme) => ({
  root: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(theme.typography.body2 as any),
    borderLeftWidth: 0,
    padding: theme.spacing(2),
  },
}));

export function DropdownButtonMenuItem(props: MenuItemProps) {
  const { classes } = useMenuItemStyles();
  return <MenuItem disableGutters className={classes.root} {...props} />;
}

export default function DropdownButtonMenu({
  buttonContent,
  buttonProps,
  menuProps,
  id: customId,
  children,
  isIcon = false,
  style,
  disabled = false,
  chevron = true,
  disabledReason,
}: DropdownButtonMenuProps) {
  const menuId = useMemo(() => {
    return customId || `dropdown-btn-menu-${Math.floor(Math.random() * 1000000)}`;
  }, [customId]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);

  const DynamicButton: React.ElementType = isIcon ? IconButton : Button;

  function closeMenu() {
    setAnchorEl(undefined);
  }

  const endIcon = chevron && (
    <ChevronDownIcon
      style={{
        fontSize: '18px',
        transform: anchorEl == null ? undefined : CHEVRON_OPEN_TRANSFORM,
        transition: 'transform 0.25s',
      }}
    />
  );

  return (
    <>
      {disabledReason ? (
        <Tooltip title={disabledReason}>
          <span>
            <DynamicButton disabled style={style} {...(isIcon ? {} : { endIcon })}>
              {buttonContent}
            </DynamicButton>
          </span>
        </Tooltip>
      ) : (
        <>
          <DynamicButton
            color="secondary"
            disabled={disabled}
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget);
            }}
            style={style}
            {...(isIcon ? {} : { endIcon })}
            {...buttonProps}
          >
            {buttonContent}
          </DynamicButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'right',
              vertical: (anchorEl?.offsetHeight ?? 0) + 2,
            }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            onClose={closeMenu}
            open={anchorEl != null}
            MenuListProps={{ disablePadding: true }}
            PaperProps={{
              style: {
                minWidth: 112,
                maxWidth: 321,
              },
            }}
            {...menuProps}
          >
            <Box>{children(closeMenu)}</Box>
          </Menu>
        </>
      )}
    </>
  );
}
