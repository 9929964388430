import { Box, ButtonBase, Drawer, DrawerProps, Typography } from '@mui/material';
import React from 'react';
import { Custodian } from 'vise-types/custodian';
import { CloseIcon } from '~/synth/Icons';
import { getCustodianDisplayName } from '~/utils/format';

export default function LearnMoreDrawer({
  open,
  onClose,
  onXClick,
  custodian,
}: Pick<DrawerProps, 'open' | 'onClose'> & { onXClick: () => void; custodian: Custodian }) {
  const schwabContent = (
    <>
      <ol type="1">
        <li>
          Log on to <a href="https://si2.schwabinstitutional.com/">Charles Schwab Advisor Center</a>
          .
        </li>
        <li>
          Select <b>“Accounts”</b> from the ribbon banner located on the top left side of the
          landing page.
        </li>
        <li>
          In the side panel on the left hand side of the landing page, there is a drop down filter
          that defaults to <b>&quot;Find Account&quot;</b>.
        </li>
        <ol type="a">
          <li>
            In the search bar directly below this drop down, enter the account number. Then, click
            <b>“Search.”</b>
          </li>
        </ol>
        <li>
          Once on the landing page for the desired account, navigate to the second layer ribbon
          banner at the top of the page. This banner includes items such as Activity, Alerts,
          Balances, Positions, etc.
        </li>
        <ol type="a">
          <li>
            Click on the <b>“Cost Basis” tab</b>.
          </li>
          <li>
            The default view for the Cost Basis in Advisor Center is unrealized gain and loss by
            position.
          </li>
        </ol>
        <li>To export Unrealized Gain/Loss by tax lot:</li>
        <ol type="a">
          <li>
            From the default landing page for Cost Basis, select the <b>export icon</b> located on
            the right hand side of the screen.
          </li>
          <li>
            From the prompt that appears, select the option <b>“Export only lot level detail”</b>{' '}
            and save the CSV file.
          </li>
        </ol>
        <li>To export Realized Gain/Loss by tax lot:</li>
        <ol type="a">
          <li>
            From the Cost Basis page, towards the top of the screen and directly above the account
            number/title, there is a toggle between Unrealized Gain/(Loss) and Realized Gain/(Loss).
          </li>
          <li>
            Select <b>“Realized Gains/(Loss).”</b>
          </li>
          <li>
            Once on the Realized Gain/(Loss) screen, use the Filter to isolate the desired date
            range.
          </li>
          <li>
            After populating with desired period parameters, click the green <b>“Apply Filter”</b>
            button.
          </li>
          <li>
            After the filter is applied, select the <b>export icon</b> located on the right hand
            side of the screen.
          </li>
          <li>
            From the prompt that appears, select the option <b>“Export only lot level detail”</b>{' '}
            and save the CSV file.
          </li>
        </ol>
        <li>
          After saving files, drag and drop or browse and select each CSV in their respective
          uploader to import your client’s portfolio to Vise.
        </li>
      </ol>
    </>
  );

  const tdaContent = (
    <>
      <ol type="1">
        <li>
          Log on to{' '}
          <a href="https://veoone.tdainstitutional.com/">TDA’s Veo One institutional platform</a>.
        </li>
        <li>
          Input the account number into the search bar located at the top of the landing page.
        </li>
        <ol type="a">
          <li>
            Once the desired account number is entered, click on the{' '}
            <b>banner for the individual’s account.</b>
          </li>
        </ol>
        <li>
          From the landing page of the account, navigate to the <b>Cost Basis tab.</b>
        </li>
        <ol type="a">
          <li>
            The Cost Basis tab is located two tabs to the right of “Balances, Positions & History”,
            which is the default landing page at the account level in Veo One.
          </li>
        </ol>
        <li>
          Once on the Cost Basis tab for the account, you’ll see various tabs correlated to
          different tax aspects under the ribbon that displays Wolters Kluwer GainsKeeper.
        </li>
        <ol type="a">
          <li>
            Select the <b>“Export Data” tab.</b>
          </li>
          <li>
            Scroll down until you see the section titled{' '}
            <b>“Export Realized or Unrealized Data to Excel”</b> that is denoted by a large
            Microsoft Excel Icon.
          </li>
        </ol>
        <li>
          To export <b>Unrealized Gain/Loss</b> by tax lot:
        </li>
        <ol type="a">
          <li>
            Select <b>“Unrealized Longs for Current Year”</b> on the dropdown filter within the
            “Export Realized or Unrealized Data to Excel” section.
          </li>
          <li>
            Click on the hyperlink directly below the Excel Icon titled{' '}
            <b>“Get Report as Excel File.”</b>
          </li>
          <li>
            When saving the file, toggle the file type from a “Microsoft Excel Worksheet” to
            <b>“Microsoft Excel Comma Separated Values File.”</b>
          </li>
        </ol>
        <li>
          To export <b>Realized Gain/Loss</b> by tax lot:
        </li>
        <ol type="a">
          <li>
            Select <b>“Aggregate Realized for 2022”</b> on the dropdown filter within the “Export
            Realized or Unrealized Data to Excel” section.
          </li>
          <li>
            Click on the hyperlink directly below the Excel Icon titled{' '}
            <b>“Get Report as Excel File.”</b>
          </li>
          <li>
            When saving the file, toggle the file type from a “Microsoft Excel Worksheet” to
            <b>“Microsoft Excel Comma Separated Values File.”</b>
          </li>
        </ol>
        <li>
          After saving files, drag and drop or browse and select each CSV in their respective
          uploader to import your client’s portfolio to Vise.{' '}
        </li>
      </ol>
    </>
  );

  const fidelityContent = (
    <>
      <ol type="1">
        <li>
          Log on to Fidelity’s <a href="https://wealthcentral.advisorchannel.com/">Wealthscape</a>{' '}
          web portal.
        </li>
        <li>
          On the top left hand corner of the default landing page, select the{' '}
          <b>“Account Search”</b> tab located directly under the Wealthscape logo.
        </li>
        <li>
          In the Account Search explorer that appears, enter the account number into the search bar
          at the top of the explorer.
        </li>
        <ol type="a">
          <li>
            In the table below, click on the <b>hyperlinked account number</b> that will appear once
            the account is entered into the search bar.
          </li>
        </ol>
        <li>
          From the account record (in the top left corner), there is a tab with the account number
          and the last name of the client. Click on this tab to open/expand a drop down list of
          actions and records for the account.
        </li>
        <li>
          To export <b>Unrealized Gain/Loss</b> by tax lot:
        </li>
        <ol type="a">
          <li>
            Select <b>“Open/Unrealized”</b> directly underneath “Positions.”
          </li>
          <li>
            Click the <b>small green Microsoft excel icon</b> on the right hand side of the page,
            directly above the tabular breakdown that appears. Wealthscape will generate a prompt
            within your machine’s file explorer to save the file as a CSV.
          </li>
        </ol>
        <li>
          To export <b>Realized Gain/Loss</b> by tax lot:
        </li>
        <ol type="a">
          <li>
            Select <b>“Previous Sales/Realized.”</b>
          </li>
          <li>Select the desired time period for the data from one of the drop down filters.</li>
          <li>
            Once the desired period is selected, click on the <b>blue ”Display” button</b> located
            directly to the right.
          </li>
          <li>
            After populating with desired period parameters, click the green <b>“Apply Filter”</b>{' '}
            button.
          </li>
          <li>
            With the relevant RGL displayed, click on the <b>“CSV”</b> icon on the upper right hand
            side of the screen to export the data.
          </li>
        </ol>
        <li>
          After saving files, drag and drop or browse and select each CSV in their respective
          uploader to import your client’s portfolio to Vise.{' '}
        </li>
      </ol>
    </>
  );

  let content;
  switch (custodian) {
    case 'FIDELITY':
      content = fidelityContent;
      break;
    case 'SCHWAB':
      content = schwabContent;
      break;
    case 'TDA':
      content = tdaContent;
      break;
    default:
      content = <>Unsupported custodian</>;
  }

  return (
    <Drawer
      open={open}
      title={`Importing ${getCustodianDisplayName(custodian)} portfolios`}
      anchor="right"
      onClose={onClose}
    >
      <Box width={500} height="100vh">
        <Box
          display="flex"
          zIndex={10}
          width={500}
          justifyContent="space-between"
          px={3}
          alignItems="center"
          position="sticky"
          top={0}
          height={72}
          boxShadow={8}
          bgcolor="white"
        >
          <Typography variant="h3">
            Importing {getCustodianDisplayName(custodian)} portfolios
          </Typography>
          <ButtonBase onClick={onXClick} title="Close Learn More drawer">
            <CloseIcon />
          </ButtonBase>
        </Box>
        <Box py={1} pl={1} pr={3}>
          {content}
        </Box>
      </Box>
    </Drawer>
  );
}
