import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  TextareaAutosize,
  Typography,
  styled,
  Link as MuiLink,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import { tokens } from '@vise_inc/ds-vise';
import openBox from '~/static/images/illustrations/open-box.svg';
import diamond from '~/static/images/illustrations/diamond.svg';
import DialogTitle from '~/synth/DialogTitle';
import useEnqueueToast from '~/hooks/useToast';
import { submitUserFeedback } from '~/api/api';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialogContent-root:last-child': {
    paddingBottom: `${theme.spacing(3)} !important`,
  },
}));

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  border: `1px solid ${tokens.palette.neutralCool[500]}`,
  outline: 'none',
  '&:focus': {
    borderColor: theme.palette.blue[600],
  },
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles()((theme) => ({
  textarea: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const OptionButton = styled(Button)(({ theme, active }: { theme?: Theme; active: boolean }) => ({
  marginTop: theme?.spacing(1),
  borderRadius: theme?.spacing(3),
  fontWeight: 500,
  color: theme?.palette.grey[800],
  margin: theme?.spacing(0.5),
  marginBottom: theme?.spacing(1),
  padding: theme?.spacing(1, 2),
  border: `1px solid ${active ? theme?.palette.blue[600] : tokens.palette.neutralCool[300]}`,
  backgroundColor: active ? theme?.palette.blue[100] : 'transparent',
  '&:hover': {
    borderColor: active ? theme?.palette.blue[600] : tokens.palette.neutralCool[300],
    backgroundColor: active ? theme?.palette.blue[100] : tokens.palette.neutralCool[100],
  },
}));

const QUESTION = 'Let us know how we’re doing.';
type FeedbackType = 'SUGGESTION' | 'BUG_REPORT' | 'COMPLAINT' | 'OTHER';

const feedbackTypeMap: Record<FeedbackType, string> = {
  OTHER: '👍 I like something',
  SUGGESTION: '💡 I have a suggestion',
  BUG_REPORT: '🪲 Report a bug',
  COMPLAINT: '⚠️ File a complaint',
};

const FeedbackModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const enqueueToast = useEnqueueToast();
  const { classes } = useStyles();
  const [feedbackType, setFeedbackType] = useState<FeedbackType | null>(null);
  const [message, setMessage] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSuggestionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setSubmitted(true);
      await submitUserFeedback({
        question: QUESTION,
        feedbackType: feedbackType as FeedbackType,
        message,
      });
    } catch (error) {
      setSubmitted(false);
      enqueueToast({
        severity: 'error',
        title: `Failed to submit feedback`,
        content: `Please reach out to clientservice@vise.com for assistance.`,
      });
    }
  };

  const handleClose = () => {
    onClose();
  };

  if (submitted) {
    return (
      <StyledDialog open={open} maxWidth="sm">
        <DialogTitle onClose={handleClose} />
        <DialogContent>
          <Box mt={8} mb={16} textAlign="center">
            <img src={diamond} width={120} alt="" />
            <Typography variant="h2" fontWeight={500} mt={2}>
              Thank you for your feedback!
            </Typography>
            <Typography color="grey.600" mt={2} align="center">
              Your feedback is highly valued. We&apos;ll try our best to address any issues as
              <br /> soon as possible.
              <br />
              <br />
              We appreciate your time and effort.
            </Typography>
          </Box>
        </DialogContent>
      </StyledDialog>
    );
  }

  return (
    <StyledDialog open={open} maxWidth="sm">
      <DialogTitle onClose={handleClose} />
      <DialogContent>
        <Box>
          <Box mt={3} mb={2} textAlign="center">
            <img src={openBox} width={120} alt="" />
            <Typography variant="h2" fontWeight={500} mt={2}>
              {QUESTION}
            </Typography>
          </Box>

          <form onSubmit={handleSubmit}>
            <Box>
              <Typography variant="body2" color="grey.600" mt={4} mb={3} align="center">
                What kind of product feedback do you have?
              </Typography>
              <FormControl
                sx={{
                  display: 'block',
                  textAlign: 'center',
                }}
              >
                <OptionButton
                  color="primary"
                  variant="outlined"
                  fullWidth={false}
                  active={feedbackType === 'OTHER'}
                  onClick={() => setFeedbackType('OTHER')}
                >
                  {feedbackTypeMap.OTHER}
                </OptionButton>
                <OptionButton
                  color="primary"
                  variant="outlined"
                  active={feedbackType === 'SUGGESTION'}
                  onClick={() => setFeedbackType('SUGGESTION')}
                >
                  {feedbackTypeMap.SUGGESTION}
                </OptionButton>
                <OptionButton
                  color="primary"
                  variant="outlined"
                  active={feedbackType === 'BUG_REPORT'}
                  onClick={() => setFeedbackType('BUG_REPORT')}
                >
                  {feedbackTypeMap.BUG_REPORT}
                </OptionButton>
                <OptionButton
                  color="primary"
                  variant="outlined"
                  active={feedbackType === 'COMPLAINT'}
                  onClick={() => setFeedbackType('COMPLAINT')}
                >
                  {feedbackTypeMap.COMPLAINT}
                </OptionButton>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={{ width: '100%' }}>
                <Typography variant="body1" fontWeight={600} mt={1} mb={1}>
                  Comment <Typography sx={{ color: 'error.400', display: 'inline' }}>*</Typography>
                </Typography>
                <StyledTextareaAutosize
                  minRows={6}
                  placeholder="Type here..."
                  className={classes.textarea}
                  value={message}
                  onChange={handleSuggestionChange}
                  required
                />
                <Typography variant="body2" fontSize={12} align="center" mb={4}>
                  For urgent matters related to trading activity or investments, reach out directly
                  <br />
                  to <MuiLink href="mailto:clientservice@vise.com">
                    clientservice@vise.com
                  </MuiLink>{' '}
                  or call us at (929) 273-0476.
                </Typography>
              </FormControl>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                disabled={!feedbackType || !message}
              >
                Send feedback
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default FeedbackModal;
