import {
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import { ReactComponent as CopyIcon } from '~/static/images/icons/copy.svg';
import { ReactComponent as LinkIcon } from '~/static/images/icons/link.svg';
import { ReactComponent as PencilIcon } from '~/static/images/icons/pencil.svg';
import { ReactComponent as RefreshIcon } from '~/static/images/icons/refresh.svg';
import { ReactComponent as TrashIcon } from '~/static/images/icons/trash.svg';
import DropdownButtonMenu, {
  DropdownButtonMenuItem as DropdownButtonMenuItemBase,
} from '~/synth/DropdownButtonMenu';

import { mapValues } from 'lodash';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { duplicateTemplate, updateLinkedAccounts } from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useDeleteAllocationsTemplate from '~/hooks/templates/useDeleteAllocationsTemplate';
import useDeleteRestrictionsTemplate from '~/hooks/templates/useDeleteRestrictionsTemplate';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useInProgressJobs from '~/hooks/useInProgressJobs';
import { useIndexedInstruments } from '~/hooks/useInstruments';
import { useIndexedSectors } from '~/hooks/useSectors';
import { ReactComponent as ExclamationCircleIcon } from '~/static/images/icons/exclamation-circle.svg';
import LoadingSpinner from '~/synth/LoadingSpinner';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import { TextHighlightTag } from '~/synth/Tag';
import amplitude from '~/utils/amplitude';
import scrollToTop from '~/utils/scrollToTop';
import { DeleteTemplateModal } from '../PortfolioCreator2/templates/CommonComponents';
import LandingPageAccountTable from './LandingPageAccountTable';
import LinkTemplateCTA from './LinkTemplateCTA';
import VersionHistory from './VersionHistory';
import { getAllAccountIdsForFreshTemplateId, getStaleAccountsFromViewData } from './utils';
import AllocationTemplateCard from './workflows/AllocationTemplateCard';
import AllocationTemplateChart from './workflows/AllocationTemplateChart';
import RestrictionsTemplateCard from './workflows/RestrictionsTemplateCard';

const UPDATE_IN_PROGRESS_TIP =
  'There are updates in progress for this template. Cancel or execute these updates in order to make further changes to this template.';

function DropdownButtonMenuItem<MenuItemProps>({
  tooltip,
  disabled,
  ...otherProps
}: { tooltip?: string; disabled?: boolean } & MenuItemProps) {
  if (tooltip && disabled) {
    return (
      <Tooltip title={tooltip}>
        <Box>
          <DropdownButtonMenuItemBase {...otherProps} disabled />
        </Box>
      </Tooltip>
    );
  }
  return <DropdownButtonMenuItemBase {...otherProps} />;
}

export default function TemplateLandingPage() {
  const history = useHistory();
  const { data, error } = useModelTemplateCenterViewData(false);
  const { data: jobs, mutate: onMutateJobs } = useInProgressJobs();
  const match = useRouteMatch<{ templateId: string }>();
  const screenMatch = useRouteMatch<{ templateId: string; screenName: string }>(
    `${match.path}/:screenName`
  );
  useEffect(() => {
    amplitude().logEvent('Impression - template landing page', {
      category: EVENT_CATEGORIES.STRATEGY_CENTER,
    });
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [match.params.templateId]);

  const screenName = screenMatch ? screenMatch.params.screenName : 'details';

  const theme = useTheme();
  const updateInProgress = !!jobs?.data.find((job) => job.templateId === match.params.templateId);
  const enqueueCoachmark = useEnqueueCoachmark();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { deleteTemplate: deleteAllocationsTemplate } = useDeleteAllocationsTemplate();
  const { deleteTemplate: deleteRestrictionsTemplate } = useDeleteRestrictionsTemplate();
  const { data: instruments } = useIndexedInstruments();
  const { data: sectorInformation } = useIndexedSectors();
  if (error) {
    return (
      <Box padding={2}>
        <Typography variant="body2">Error loading data</Typography>
      </Box>
    );
  }
  if (!data) {
    return <LoadingSpinner />;
  }
  const allAccountIdsByFreshTemplateId = getAllAccountIdsForFreshTemplateId(data.data);
  const linkedAccounts = (allAccountIdsByFreshTemplateId[match.params.templateId] || []).length;
  const staleAccountIdsByFreshTemplateId = getStaleAccountsFromViewData(data.data);
  const staleAccountIds = staleAccountIdsByFreshTemplateId[match.params.templateId] || [];
  const template = [
    ...(data?.data.allocationsTemplates || []),
    ...(data?.data.restrictionsTemplates || []),
  ].find((template) => template.parentId === match.params.templateId);
  if (!template) {
    return null;
  }
  if (template.stale) {
    const freshTemplate = [
      ...(data?.data.allocationsTemplates || []),
      ...(data?.data.restrictionsTemplates || []),
    ].find((t) => t.originalTemplateId === template.originalTemplateId && !t.stale);
    return (
      <Redirect
        to={
          freshTemplate
            ? `/secure/view-template/${freshTemplate.parentId}`
            : `/secure/strategy-center`
        }
      />
    );
  }
  const urlPrefix = `/secure/view-template/${template.parentId}`;
  let status = 'Up to date';
  let severity: 'success' | 'warning' | 'alert' = 'success';
  if (staleAccountIds.length) {
    severity = 'warning';
    status = `${staleAccountIds.length} update${staleAccountIds.length === 1 ? '' : 's'} available`;
  }
  if (updateInProgress) {
    severity = 'alert';
    status = 'Update in progress';
  }
  return (
    <>
      <LinkTemplateCTA
        operation="CREATE"
        templates={[...data.data.allocationsTemplates, ...data.data.restrictionsTemplates]}
        links={data.data.templateLinks}
      />
      <LinkTemplateCTA
        operation="EDIT"
        templates={[...data.data.allocationsTemplates, ...data.data.restrictionsTemplates]}
        links={data.data.templateLinks}
      />
      <DeleteTemplateModal
        deleteTemplate={
          template.type === 'allocations' ? deleteAllocationsTemplate : deleteRestrictionsTemplate
        }
        open={deleteModalOpen}
        templateId={template.id}
        templateName={template.name}
        onClose={() => setDeleteModalOpen(false)}
        onTemplateDeleted={async () => {
          history.push('/secure/strategy-center');
        }}
        variant={template.type === 'allocations' ? 'allocation' : 'restrictions'}
        eventCategory={EVENT_CATEGORIES.STRATEGY_CENTER}
      />
      <Box bgcolor="white">
        <Box pt={4}>
          <Container>
            <Box
              mb={2}
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box flex={2}>
                <Box mb={1.5}>
                  <PathBreadcrumbs
                    path={[
                      { name: 'Templates', to: '/secure/strategy-center' },
                      { name: template?.name || '' },
                    ]}
                    ariaLabel="Strategy center navigation"
                  />
                </Box>
                <Box mb={1.5}>
                  <Typography variant="h1">{template?.name}</Typography>
                </Box>
                <Box color={theme.palette.grey[600]} display="flex">
                  <Typography variant="body2">
                    Built by {template?.user.firstName} {template?.user.lastName}
                  </Typography>
                  <Box mx={2}>
                    <Divider orientation="vertical" />
                  </Box>
                  <TextHighlightTag severity={severity}>{status}</TextHighlightTag>
                </Box>
              </Box>
              <Box flex={1} textAlign="right">
                <Box my={2} display="flex" alignItems="center" justifyContent="flex-end">
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography variant="body2" color="textSecondary">
                      Linked accounts{' '}
                    </Typography>
                    <Box mb={1} />
                    <Typography variant="body2">{linkedAccounts}</Typography>
                  </Box>

                  <Box color={theme.palette.grey[300]} mx={4}>
                    <Divider orientation="vertical" flexItem style={{ height: '44px' }} />
                  </Box>

                  <DropdownButtonMenu
                    buttonProps={{ color: 'primary', variant: 'contained' }}
                    buttonContent="Actions"
                  >
                    {() => [
                      <DropdownButtonMenuItem
                        key="link-unlink-accounts"
                        onClick={() => {
                          history.push(`/secure/template/link/${template.parentId}`);
                          amplitude().logEvent('Click on link/unlink template details', {
                            category: EVENT_CATEGORIES.STRATEGY_CENTER,
                            templateId: template.parentId,
                          });
                        }}
                        disabled={updateInProgress}
                        tooltip={UPDATE_IN_PROGRESS_TIP}
                      >
                        <LinkIcon style={{ marginRight: 8 }} fontSize={18} /> Link/unlink accounts
                      </DropdownButtonMenuItem>,
                      <DropdownButtonMenuItem
                        key="update-accounts"
                        onClick={() => {
                          updateLinkedAccounts(template.parentId, [], staleAccountIds, 'UPDATE')
                            .then(() => {
                              onMutateJobs();
                              amplitude().logEvent('Update accounts to latest version success', {
                                category: EVENT_CATEGORIES.STRATEGY_CENTER,
                                templateId: template.parentId,
                              });
                            })
                            .catch(() => {
                              enqueueCoachmark({
                                title: 'Failed to update accounts',
                                content: 'Please reach out to clientservice@vise.com for help.',
                                severity: 'error',
                              });
                              amplitude().logEvent('Error – update accounts to latest version', {
                                category: EVENT_CATEGORIES.STRATEGY_CENTER,
                                templateId: template.parentId,
                              });
                            });
                        }}
                        disabled={staleAccountIds.length === 0 || updateInProgress}
                        tooltip={
                          updateInProgress ? UPDATE_IN_PROGRESS_TIP : 'All accounts are up to date.'
                        }
                      >
                        <RefreshIcon style={{ marginRight: 8 }} fontSize={18} /> Update accounts to
                        latest version
                      </DropdownButtonMenuItem>,
                      <Divider key="divider" />,
                      <DropdownButtonMenuItem
                        key="view"
                        onClick={() => {
                          history.push(`/secure/template/${template.type}/edit/${template.id}`);
                          amplitude().logEvent('Click on edit template details', {
                            category: EVENT_CATEGORIES.STRATEGY_CENTER,
                            templateId: template.parentId,
                          });
                        }}
                        disabled={updateInProgress}
                        tooltip={UPDATE_IN_PROGRESS_TIP}
                      >
                        <PencilIcon style={{ marginRight: 8 }} fontSize={18} /> Edit
                      </DropdownButtonMenuItem>,
                      <DropdownButtonMenuItem
                        key="duplicate"
                        onClick={async () => {
                          try {
                            const resp = await duplicateTemplate({
                              templateId: template.id,
                              type: template.type,
                              newName: `${template.name} (Copy)`,
                            });
                            history.push(`/secure/view-template/${resp.data.newTemplate.parentId}`);
                          } catch (e) {
                            enqueueCoachmark({
                              title: 'Failed to duplicate template',
                              content: 'Please reach out to clientservice@vise.com',
                              severity: 'error',
                            });
                          }
                        }}
                      >
                        <CopyIcon style={{ marginRight: 8 }} fontSize={18} /> Duplicate
                      </DropdownButtonMenuItem>,
                      <DropdownButtonMenuItem
                        disabled={
                          updateInProgress || linkedAccounts > 0 || staleAccountIds.length > 0
                        }
                        key="delete"
                        onClick={() => {
                          setDeleteModalOpen(true);
                        }}
                        color="red"
                        tooltip={
                          linkedAccounts > 0
                            ? 'This template is currently linked to accounts across your organization. Work with your team to unlink all accounts associated to this template in order to make it eligible for deletion.'
                            : UPDATE_IN_PROGRESS_TIP
                        }
                      >
                        <TrashIcon style={{ marginRight: 8 }} fontSize={18} />
                        Delete
                      </DropdownButtonMenuItem>,
                    ]}
                  </DropdownButtonMenu>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <Divider />
        <Container>
          <Tabs value={screenName}>
            <Tab
              label="Template details"
              value="details"
              to={`${urlPrefix}/details`}
              component={Link}
              id="mtc-tab-details"
              aria-controls="mtc-details-screen"
              onClick={() =>
                amplitude().logEvent('Click template details tab', {
                  category: EVENT_CATEGORIES.STRATEGY_CENTER,
                })
              }
            />
            <Tab
              label={
                staleAccountIds.length ? (
                  <Box display="flex" alignItems="center">
                    <Box mr={1} position="relative" top="2px">
                      <ExclamationCircleIcon color={theme.palette.orange[600]} />
                    </Box>
                    <Box>Linked accounts</Box>
                  </Box>
                ) : (
                  'Linked accounts'
                )
              }
              value="accounts"
              to={`${urlPrefix}/accounts`}
              component={Link}
              id="mtc-tab-accounts"
              aria-controls="mtc-accounts-screen"
              onClick={() =>
                amplitude().logEvent('Click linked accounts tab ', {
                  category: EVENT_CATEGORIES.STRATEGY_CENTER,
                })
              }
            />
            <Tab
              label="Version history"
              value="history"
              to={`${urlPrefix}/history`}
              component={Link}
              id="mtc-tab-history"
              aria-controls="mtc-history-screen"
              onClick={() =>
                amplitude().logEvent('Click version history tab ', {
                  category: EVENT_CATEGORIES.STRATEGY_CENTER,
                })
              }
            />
          </Tabs>
        </Container>
      </Box>
      <Container>
        <Box margin="auto" pt={3} pb={3}>
          {template.type === 'allocations' && screenName === 'details' && (
            <Grid container id="mtc-details-screen">
              <Grid item lg={8}>
                <Box mr={2} mb={4}>
                  <AllocationTemplateCard
                    allocations={mapValues(template.allocations, (val) => val && val * 100)}
                    equitiesTitle="Equities"
                    fixedIncomeTitle="Fixed income"
                    alternativesTitle="Alternatives"
                    maxWidth="100%"
                    tiltSelection={template.tiltSelection}
                  />
                </Box>
              </Grid>
              <Grid item lg={4}>
                <AllocationTemplateChart
                  allocations={mapValues(template.allocations, (val) => val && val * 100)}
                />
              </Grid>
            </Grid>
          )}
          {template.type === 'restrictions' && screenName === 'details' && (
            <Grid container id="mtc-details-screen">
              <Grid item xs={12}>
                <RestrictionsTemplateCard
                  tickersTitle="Tickers"
                  tickers={template.tickers}
                  instruments={instruments}
                  sectorsTitle="Sectors"
                  sectors={template.sectors}
                  industries={template.subSectors}
                  esgTitle="Values"
                  esgAreas={template.esgAreas}
                  sectorInformation={sectorInformation}
                  countriesTitle="Countries"
                  countries={template.countries}
                  maxWidth="100%"
                />
              </Grid>
            </Grid>
          )}
          {screenName === 'accounts' && (
            <Grid container id="mtc-accounts-screen">
              <Grid item xs={12}>
                <LandingPageAccountTable
                  jobs={jobs?.data}
                  templateId={template.parentId}
                  data={data.data}
                />
              </Grid>
            </Grid>
          )}
          {screenName === 'history' && (
            <Grid container id="mtc-history-screen">
              <Grid item xs={12}>
                <VersionHistory data={data.data} template={template} />
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </>
  );
}
