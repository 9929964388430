import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import React from 'react';
import clsx from 'clsx';
import { ReactComponent as QuestionMarkCircle } from '~/static/images/icons/question-mark-circle.svg';
import { ReactComponent as CheckCircleIcon } from '../static/images/icons/check-circle.svg';
import { ReactComponent as XCircleIcon } from '../static/images/icons/x-circle.svg';
import { ReactComponent as WarningIcon } from '../static/images/icons/exclamation.svg';

interface TextFieldProps extends Omit<MuiTextFieldProps, 'variant'> {
  success?: boolean;
  warning?: boolean;
}

// Mimics MUI's [`<TextField>`][1] and its props closely but does not offer "variants"; Vise Styles
// has only one variant on an input, and so this component has no "variant" prop.
export default function TextField({
  FormHelperTextProps,
  InputLabelProps,
  InputProps,
  autoComplete,
  autoFocus = false,
  className,
  color = 'primary',
  defaultValue,
  disabled,
  error,
  fullWidth,
  helperText,
  hiddenLabel,
  id,
  inputProps,
  inputRef,
  label,
  multiline = false,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  ref,
  rows,
  maxRows,
  size,
  required = false,
  success,
  type,
  value,
  warning,
}: TextFieldProps) {
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;
  return (
    // See implementation of MUI's [`<TextField>`][0] for mapping of which `TextFieldProps` should
    // go to the wrapping `<FormControl>`.
    <FormControl
      className={className}
      color={color}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
      size={size}
      ref={ref}
      required={required}
    >
      {label == null ? null : (
        // * hard-code `error=false` to disable error styling for labels not used in Synth
        // * use `required={!!InputLabelProps?.required}` to disable appending asterisks to indicate "required"
        // * `required` is deliberately set to `true` in the `InputLabelProps` object. Synth
        //   sometimes but not always uses asterisks in the label to indicate required fields.
        <FormLabel
          {...InputLabelProps}
          error={false}
          htmlFor={id}
          id={inputLabelId}
          required={!!InputLabelProps?.required}
        >
          {label}
        </FormLabel>
      )}
      <OutlinedInput
        aria-describedby={helperTextId}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        fullWidth={fullWidth}
        id={id}
        multiline={multiline}
        name={name}
        rows={rows}
        maxRows={maxRows}
        type={type}
        value={value}
        inputProps={inputProps}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        {...InputProps}
        className={clsx(InputProps?.className, { 'Mui-success': success, 'Mui-warning': warning })}
        inputRef={inputRef}
      />
      {!helperText ? null : (
        // Use a `<div>` because the default for `FormHelperText` is `<p>`. However, `<p>` cannot
        // have `<div>` children, like the `<XCircle>`.
        <FormHelperText
          component="div"
          {...FormHelperTextProps}
          className={clsx(FormHelperTextProps?.className, {
            'Mui-success': success,
            'Mui-warning': warning,
          })}
          id={helperTextId}
        >
          {error && <XCircleIcon height={13} width={13} style={{ marginRight: 5 }} />}
          {success && <CheckCircleIcon height={13} width={13} style={{ marginRight: 5 }} />}
          {warning && <WarningIcon height={13} width={13} style={{ marginRight: 5 }} />}
          {!error && !success && !warning && (
            <QuestionMarkCircle height={13} width={13} style={{ marginRight: 5 }} />
          )}
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

// [0]: https://github.com/mui-org/material-ui/blob/3c078f2f6943976fc4d3ed5d886bbfa0f391e4a2/packages/material-ui/src/TextField/TextField.js#L156-L166
// [1]: https://material-ui.com/components/text-fields/
