import { Box, BoxProps, Button, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';

export interface Props<T> extends Omit<BoxProps, 'children'> {
  children: ({ isEditMode }: { isEditMode?: boolean }) => React.ReactNode;
  disabled?: boolean;
  isEditMode?: boolean;
  onCancel: (prevValue: T) => void;
  onSave?: () => void;
  onToggleEditMode?: () => void;
  title: string;
  value: T;
}

function NestedFormEdit<T>({
  children,
  isEditMode,
  disabled,
  onCancel,
  onSave,
  onToggleEditMode,
  title,
  value,
  ...boxProps
}: Props<T>) {
  const prevValueRef = useRef<T | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (isEditMode && prevValueRef.current == null) {
      prevValueRef.current = value;
    } else if (!isEditMode && prevValueRef.current != null) {
      prevValueRef.current = null;
    }
  }, [isEditMode, value]);

  const handleOnClickChange = useCallback(() => {
    if (!disabled && onToggleEditMode) {
      onToggleEditMode();
    }
  }, [disabled, onToggleEditMode]);

  const handleOnClickCancel = useCallback(() => {
    if (prevValueRef.current != null) {
      onCancel(prevValueRef.current);
    }

    if (onToggleEditMode) {
      onToggleEditMode();
    }
  }, [onCancel, onToggleEditMode]);

  const handleOnClickSave = useCallback(() => {
    if (onSave) {
      onSave();
    }
  }, [onSave]);

  return (
    <Box borderBottom={`1px solid ${theme.palette.grey[200]}`} pb={4} {...boxProps}>
      <Box
        alignItems="center"
        color={disabled ? 'grey.400' : ''}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography variant="h3">{title}</Typography>
        {!isEditMode && (
          <Button disabled={disabled} onClick={handleOnClickChange} color="secondary">
            <Box color={disabled ? 'grey.400' : 'blue.400'}>Change</Box>
          </Button>
        )}
      </Box>
      <Box mt={2.5} display="flex" flexDirection="column">
        <div>{children({ isEditMode })}</div>
        {isEditMode && (
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleOnClickCancel} color="secondary">
              Cancel
            </Button>
            <Box display="inline" ml={1.5}>
              <Button onClick={handleOnClickSave} variant="outlined" color="secondary">
                Save
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default NestedFormEdit;
