import {
  Box,
  Button,
  Drawer,
  FormLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { riskToRiskSlider } from '~/api/utils';
import {
  ReactComponent as ChevronRight,
  ReactComponent as ChevronRightIcon,
} from '~/static/images/icons/chevron-right.svg';
import { ReactComponent as ScaleIcon } from '~/static/images/icons/scale.svg';
import ContainedSelectorCheckbox from '~/synth/ContainedSelectorCheckbox';
import NestedFormEdit from '~/synth/NestedFormEdit';
import NestedFormEditGroup from '~/synth/NestedFormEditGroup';
import PopoverCard from '~/synth/PopoverCard';
import PopoverLink from '~/synth/PopoverLink';
import PopoverTrigger from '~/synth/PopoverTrigger';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useUser from '~/hooks/useUser';
import { TextHighlightTag } from '~/synth/Tag';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { TiltType } from 'vise-types/template';
import { SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS } from '../../Constants';
import ReturnToSummary from '../../ReturnToSummary';
import { Pce2DraftPortfolio, ScreenProps } from '../../Types';
import { getDefaultTiltAmountFromRisk, isNewTiltType, shouldSkipRestrictions } from '../../utils';
import { ActionFooter, BackButton, ContentBox, ExplainerText } from '../components';
import ActiveTiltsTable from './ActiveTiltsTable';
import LearnMoreDrawer from './LearnMoreDrawer';
import RadioButtonCard from './RadioButtonCard';
import TiltAmountSlider from './TiltAmountSlider';

function DisabledInputText() {
  return (
    <Box color="grey.400">
      <Typography variant="h4">Unavailable when active tilt is disabled</Typography>
    </Box>
  );
}

const DrawerToggleButton = styled(Button)`
  border-bottom: solid 1px ${({ theme }) => theme.palette.grey[200]};
  border-top: solid 1px ${({ theme }) => theme.palette.grey[200]};
  color: ${({ theme }) => theme.palette.grey[600]};
  margin-bottom: 28px;
  padding: 16px 8px;
  width: 100%;

  & .MuiButton-label {
    justify-content: flex-start;
  }

  & .MuiButton-endIcon {
    margin-left: auto;
    justify-self: flex-end;
  }
`;

const DisclosuresDrawer = withStyles(Drawer, (theme) => ({
  paper: { boxShadow: theme.shadows[3] },
}));

function ActiveTiltsScreen({ dpDispatch, draftPortfolio, onBack, onContinue }: ScreenProps) {
  const [disclosuresPanelOpen, setDisclosuresPanelOpen] = useState(false);
  const {
    constructionInfo: {
      activeTilt,
      risk,
      assetClassConcentrationLimits,
      etfExclusive,
      etfExclusiveAssetClasses,
    },
  } = draftPortfolio as Pce2DraftPortfolio;

  const { data: user } = useUser();
  // One-off customization for BB, we should avoid doing this in the future
  const isBBWhiteLabel = user?.organizationId === '4393fe8f-eb3e-47bc-8af7-cf45101438b3';

  const { data: featureFlags } = useFeatureFlags();

  const defaultTiltAmount = useMemo(
    () =>
      isNewTiltType(activeTilt?.tiltType)
        ? 10
        : getDefaultTiltAmountFromRisk(riskToRiskSlider(risk)),
    [risk, activeTilt?.tiltType]
  );

  const { exclusions = [] } = assetClassConcentrationLimits || {};

  const exclusionsSet = useMemo(() => new Set(exclusions), [exclusions]);

  const theme = useTheme();

  const [isLearnMoreDrawerOpen, setIsLearnMoreDrawerOpen] = useState<boolean>(false);

  const [isEnabled, setIsEnabled] = useState<boolean>(!!activeTilt?.isEnabled);
  const [tiltType, setTiltType] = useState<TiltType>(activeTilt?.tiltType ?? 'multi-factor');
  const [tiltAmount, setTiltAmount] = useState<number>(activeTilt?.tiltAmount ?? defaultTiltAmount);

  const handleActiveTiltsToggleClick = useCallback(() => {
    setIsEnabled(!isEnabled);
  }, [isEnabled]);

  function handleActiveTiltsToggleRollback(lastValue: boolean) {
    setIsEnabled(lastValue);
  }

  function hanleActiveTiltsToggleSave() {
    dpDispatch({
      isEnabled,
      type: 'PCE2_SET_ACTIVE_TILT_IS_ENABLED',
    });
  }

  function handleActiveTiltTypeRollback(lastValue: TiltType) {
    setTiltType(lastValue);
  }

  function handleActiveTiltAmountRollback(lastValue: number) {
    setTiltAmount(lastValue);
  }

  function handleActiveTiltTypeSave() {
    dpDispatch({
      tiltType,
      type: 'PCE2_SET_ACTIVE_TILT_TYPE',
    });
    if (isNewTiltType(tiltType)) {
      setTiltAmount(10);
      dpDispatch({
        tiltAmount: 10,
        type: 'PCE2_SET_ACTIVE_TILT_AMOUNT',
      });
    }
  }

  function handleActiveTiltAmountChange(_event: ChangeEvent<{}>, value: number | number[]) {
    setTiltAmount(value as number);
  }

  function handleActiveTiltAmountSave() {
    dpDispatch({
      tiltAmount,
      type: 'PCE2_SET_ACTIVE_TILT_AMOUNT',
    });
  }

  const [hasActiveForm, setHasActiveForm] = useState<boolean>(false);

  function onActiveNestedFormEditChange(activeIndex: number | null) {
    setHasActiveForm(activeIndex != null);
  }

  const skipRestrictions = shouldSkipRestrictions({
    etfExclusive,
    exclusions: assetClassConcentrationLimits?.exclusions,
  });

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (skipRestrictions) {
        onContinue('summary');
      } else {
        onContinue('default');
      }
    },
    [skipRestrictions, onContinue]
  );

  useEffect(() => {
    if (!activeTilt?.tiltAmount && risk != null) {
      setTiltAmount(defaultTiltAmount);
      dpDispatch({
        tiltAmount: defaultTiltAmount,
        type: 'PCE2_SET_ACTIVE_TILT_AMOUNT',
      });
    }
  }, [risk, activeTilt, defaultTiltAmount, dpDispatch]);

  const assetClassKeys = useMemo(() => {
    return SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.filter(
      (assetClassKey) => !exclusionsSet.has(assetClassKey)
    );
  }, [exclusionsSet]);

  const etfExclusiveSet = new Set(etfExclusiveAssetClasses);

  // Special Braun-Bostich copy
  const lowVolatilityTiltTitle = isBBWhiteLabel ? (
    <>
      Braun-Bostich Low Volatility
      <TextHighlightTag severity="priority" ml={1}>
        New
      </TextHighlightTag>
    </>
  ) : (
    <>Low Volatility</>
  );

  const lowVolatilityQualityYieldTitle = isBBWhiteLabel ? (
    <>Braun-Bostich Defensive Dividend</>
  ) : (
    <>Defensive Dividend</>
  );

  const lowVolatilityQualityYieldDescription =
    'Invests in low volatility stocks of well-managed, dividend-paying companies to deliver downside protection as well as the return and income associated with higher quality companies.';

  const lowVolatilityTiltDescription =
    'Invests in low volatility stocks that offer downside protection while continuing to participate in rising equity markets.';

  const multifactorDescription =
    'Invests in attractively valued stocks of smaller, more profitable companies shown historically to provide excess returns.';

  const dividendDescription =
    'Invests in high dividend yield stocks to generate a steady income stream.';

  const qualityTiltDescription =
    'Invests in stocks of well-managed, profitable companies generating cash earnings.';

  const valueTiltDescription =
    'Invests in attractively priced stocks with fundamentals that currently appear undervalued by the market.';

  const momentumTiltDescription =
    'Invests in stocks with strong and consistent price trend that is expected to continue going forward.';

  const qvmTiltDescription =
    'Invests in profitable, well-managed companies that trade at attractive multiples and exhibit strong market sentiment.';

  const incomeQvmTiltDescription =
    'Invests in dividend-paying companies that are profitable, well-managed, trade at attractive multiples and exhibit strong market sentiment.';

  return (
    <>
      <ContentBox>
        <Box mb={2}>
          <Typography variant="h1">Customize your single-security strategies.</Typography>
        </Box>
        {/* TO-DO: Add popover for `single security strategy text` */}
        <ExplainerText mb={3}>
          Active tilts provide the ability to customize security selection by adjusting the
          influence of certain factors within your&nbsp;
          <PopoverTrigger
            overlay={
              <PopoverCard
                body={
                  <>
                    You&apos;ve selected the following strategies:
                    <ul>
                      {!exclusionsSet.has('EQUITY/US/LARGE_CAP') &&
                        !etfExclusiveSet.has('EQUITY/US/LARGE_CAP') && (
                          <li>Large / mid cap U.S. equities</li>
                        )}
                      {!exclusionsSet.has('EQUITY/US/SMALL_CAP') &&
                        !etfExclusiveSet.has('EQUITY/US/SMALL_CAP') && (
                          <li>Small cap U.S. equities</li>
                        )}
                      {!exclusionsSet.has('EQUITY/DEVELOPED/LARGE_CAP') &&
                        !etfExclusiveSet.has('EQUITY/DEVELOPED/LARGE_CAP') && (
                          <li>Dev. large / mid cap equities</li>
                        )}
                    </ul>
                  </>
                }
                title="Vise single-security strategies"
              />
            }
          >
            <PopoverLink variant="body2">single-security strategies.</PopoverLink>
          </PopoverTrigger>
        </ExplainerText>
        <LearnMoreDrawer
          open={isLearnMoreDrawerOpen}
          onClose={() => setIsLearnMoreDrawerOpen(false)}
        />
        <DrawerToggleButton
          endIcon={<ChevronRightIcon color={theme.palette.grey[800]} width="16px" height="16px" />}
          onClick={() => setIsLearnMoreDrawerOpen(true)}
          startIcon={<ScaleIcon color={theme.palette.grey[800]} />}
        >
          Learn more about Vise&apos;s factor-based investing
        </DrawerToggleButton>
        <form onSubmit={onSubmit}>
          <NestedFormEditGroup onActiveChange={onActiveNestedFormEditChange} defaultActiveIndex={1}>
            <NestedFormEdit
              onCancel={handleActiveTiltsToggleRollback}
              onSave={hanleActiveTiltsToggleSave}
              title="Active tilt"
              value={isEnabled}
            >
              {({ isEditMode }) => {
                if (isEditMode) {
                  return (
                    <Box>
                      <Box color="grey.600" mb={3}>
                        <Typography variant="body2">
                          By enabling, holdings within Vise strategies will be selected by their
                          factor profiles. When disabled, Vise will select securities using a
                          passive market-cap weighting.
                        </Typography>
                      </Box>
                      <ContainedSelectorCheckbox
                        checked={isEnabled}
                        label="Enable active tilting for single-security strategies"
                        disabled={false}
                        name="active_tilts_toggle"
                        onClick={handleActiveTiltsToggleClick}
                      />
                    </Box>
                  );
                }
                if (isEnabled) {
                  return (
                    <Box color="grey.600">
                      <Typography variant="h4">
                        <Box mb={1} color="success.400">
                          Enabled
                        </Box>
                      </Typography>
                      <Typography variant="body2">
                        Vise will select securities by their factor profiles instead of a passive
                        market-cap weighting.
                      </Typography>
                    </Box>
                  );
                }

                return (
                  <Box color="grey.600">
                    <Typography variant="h4">
                      <Box mb={1} color="error.400">
                        Disabled
                      </Box>
                    </Typography>
                    <Typography variant="body2">
                      Vise will not apply an active tilt and will maintain a passive, market-cap
                      based approach to security selection.
                    </Typography>
                  </Box>
                );
              }}
            </NestedFormEdit>
            <NestedFormEdit
              disabled={!isEnabled}
              onCancel={handleActiveTiltTypeRollback}
              onSave={handleActiveTiltTypeSave}
              title="Tilt type"
              value={tiltType}
              my={3.5}
            >
              {({ isEditMode }) => {
                if (!isEnabled) {
                  return <DisabledInputText />;
                }

                if (isEditMode) {
                  return (
                    <Box>
                      <Box mb={2.5}>
                        <FormLabel id="active-tilt-type-label">
                          <Typography variant="h4">Choose a type of tilt</Typography>
                        </FormLabel>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <RadioButtonCard
                            checked={tiltType === 'multi-factor'}
                            description={multifactorDescription}
                            label="Multi-factor"
                            value="multi-factor"
                            onClick={() => setTiltType('multi-factor')}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <RadioButtonCard
                            checked={tiltType === 'dividend'}
                            description={dividendDescription}
                            label="Dividends"
                            value="dividend"
                            onClick={() => setTiltType('dividend')}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <RadioButtonCard
                            checked={tiltType === 'low-volatility'}
                            description={lowVolatilityTiltDescription}
                            label={lowVolatilityTiltTitle}
                            value="low-volatility"
                            onClick={() => setTiltType('low-volatility')}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <RadioButtonCard
                            checked={tiltType === 'low-volatility-quality-yield'}
                            description={lowVolatilityQualityYieldDescription}
                            label={lowVolatilityQualityYieldTitle}
                            value="low-volatility-quality-yield"
                            onClick={() => setTiltType('low-volatility-quality-yield')}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <RadioButtonCard
                            checked={tiltType === 'quality'}
                            description={qualityTiltDescription}
                            label="Quality"
                            value="quality"
                            onClick={() => setTiltType('quality')}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <RadioButtonCard
                            checked={tiltType === 'value'}
                            description={valueTiltDescription}
                            label="Value"
                            value="value"
                            onClick={() => setTiltType('value')}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <RadioButtonCard
                            checked={tiltType === 'momentum'}
                            description={momentumTiltDescription}
                            label="Momentum"
                            value="momentum"
                            onClick={() => setTiltType('momentum')}
                          />
                        </Grid>
                        {featureFlags?.enable_qvm_tilt === 'on' ? (
                          <Grid item md={6}>
                            <RadioButtonCard
                              checked={tiltType === 'quality-value-momentum'}
                              description={qvmTiltDescription}
                              label="Quality Value Momentum (QVM)"
                              value="quality-value-momentum"
                              onClick={() => setTiltType('quality-value-momentum')}
                            />
                          </Grid>
                        ) : null}
                        {featureFlags?.enable_income_qvm_tilt === 'on' ? (
                          <Grid item md={6}>
                            <RadioButtonCard
                              checked={tiltType === 'income-quality-value-momentum'}
                              description={incomeQvmTiltDescription}
                              label="Income Focused QVM"
                              value="income-quality-value-momentum"
                              onClick={() => setTiltType('income-quality-value-momentum')}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Box>
                  );
                }

                if (tiltType === 'dividend') {
                  return (
                    <Box>
                      <Typography variant="h4">Dividends</Typography>
                      <Box color="grey.600" mt={1}>
                        <Typography variant="body2">{dividendDescription}</Typography>
                      </Box>
                    </Box>
                  );
                }

                if (tiltType === 'multi-factor') {
                  return (
                    <Box>
                      <Typography variant="h4">Multi-factor</Typography>
                      <Box color="grey.600" mt={1}>
                        <Typography variant="body2">{multifactorDescription}</Typography>
                      </Box>
                    </Box>
                  );
                }

                if (tiltType === 'low-volatility-quality-yield') {
                  return (
                    <Box>
                      <Typography variant="h4">{lowVolatilityQualityYieldTitle}</Typography>
                      <Box color="grey.600" mt={1}>
                        <Typography variant="body2">
                          {lowVolatilityQualityYieldDescription}
                        </Typography>
                      </Box>
                    </Box>
                  );
                }
                if (tiltType === 'quality') {
                  return (
                    <Box>
                      <Typography variant="h4">Quality</Typography>
                      <Box color="grey.600" mt={1}>
                        <Typography variant="body2">{qualityTiltDescription}</Typography>
                      </Box>
                    </Box>
                  );
                }

                if (tiltType === 'value') {
                  return (
                    <Box>
                      <Typography variant="h4">Value</Typography>
                      <Box color="grey.600" mt={1}>
                        <Typography variant="body2">{valueTiltDescription}</Typography>
                      </Box>
                    </Box>
                  );
                }
                if (tiltType === 'momentum') {
                  return (
                    <Box>
                      <Typography variant="h4">Momentum</Typography>
                      <Box color="grey.600" mt={1}>
                        <Typography variant="body2">{momentumTiltDescription}</Typography>
                      </Box>
                    </Box>
                  );
                }
                if (tiltType === 'quality-value-momentum') {
                  return (
                    <Box>
                      <Typography variant="h4">Quality Value Momentum (QVM)</Typography>
                      <Box color="grey.600" mt={1}>
                        <Typography variant="body2">{qvmTiltDescription}</Typography>
                      </Box>
                    </Box>
                  );
                }
                if (tiltType === 'income-quality-value-momentum') {
                  return (
                    <Box>
                      <Typography variant="h4">Income Focused QVM</Typography>
                      <Box color="grey.600" mt={1}>
                        <Typography variant="body2">{incomeQvmTiltDescription}</Typography>
                      </Box>
                    </Box>
                  );
                }
                return (
                  <Box>
                    <Typography variant="h4">{lowVolatilityTiltTitle}</Typography>
                    <Box color="grey.600" mt={1}>
                      <Typography variant="body2">{lowVolatilityTiltDescription}</Typography>
                    </Box>
                  </Box>
                );
              }}
            </NestedFormEdit>
            <NestedFormEdit
              disabled={!isEnabled || (activeTilt?.tiltType && isNewTiltType(activeTilt?.tiltType))}
              onCancel={handleActiveTiltAmountRollback}
              onSave={handleActiveTiltAmountSave}
              title="Tilt amount"
              value={tiltAmount}
            >
              {({ isEditMode }) => {
                if (!isEnabled) {
                  return <DisabledInputText />;
                }

                if (isEditMode) {
                  return (
                    <Box>
                      <Typography variant="h4">Set amount</Typography>
                      <Box color="grey.600" mb={4}>
                        <Typography variant="body1">{`Recommended amount: ${defaultTiltAmount}/10`}</Typography>
                      </Box>
                      <TiltAmountSlider
                        value={tiltAmount}
                        disabled={isNewTiltType(activeTilt?.tiltType)}
                        // known issue in v4 of MUI: https://github.com/mui-org/material-ui/issues/20191
                        // Fixed in v5
                        // TO-DO: Remove once we migrate to v5
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '(_event: ChangeEvent<{}>, value: number | nu... Remove this comment to see the full error message
                        onChange={handleActiveTiltAmountChange}
                        mb={3}
                      />
                      {!isNewTiltType(tiltType) && (
                        <ActiveTiltsTable
                          setDisclosuresPanelOpen={setDisclosuresPanelOpen}
                          assetClassKeys={assetClassKeys}
                          tiltAmount={tiltAmount}
                          tiltType={tiltType}
                        />
                      )}
                    </Box>
                  );
                }
                return (
                  <Box>
                    <Typography variant="h4">
                      {`${tiltAmount}/10 `}
                      {tiltAmount === defaultTiltAmount && (
                        <Box color="grey.600" display="inline">
                          <Typography display="inline" variant="body2">
                            (recommended)
                          </Typography>
                        </Box>
                      )}
                      {isNewTiltType(activeTilt?.tiltType) ? (
                        <Tooltip
                          title={
                            <>
                              <p>
                                For the Low Volatility and Defensive Dividend strategies, Vise
                                targets a lower beta and reduced volatility profile
                              </p>
                              {featureFlags?.enable_quality_tilt === 'on' ? (
                                <p>
                                  For the Quality strategy, Vise targets a higher profitability and
                                  earnings quality profile.
                                </p>
                              ) : null}
                              {featureFlags?.enable_value_tilt === 'on' ? (
                                <p>
                                  For the Value strategy, Vise targets names with attractive
                                  earnings, cash flows and sales and an overall undervalued profile.
                                </p>
                              ) : null}
                            </>
                          }
                          placement="top"
                        >
                          <Box
                            color="grey.600"
                            display="inline-block"
                            position="relative"
                            left="4px"
                            top="2px"
                          >
                            <InformationCircleIcon />
                          </Box>
                        </Tooltip>
                      ) : null}
                    </Typography>
                    <Box color="grey.600" mt={1}>
                      <Typography variant="body2">
                        {isNewTiltType(activeTilt?.tiltType) ? (
                          <>
                            For the newly launched active strategies, Vise currently offers a single
                            tilt amount that provides the target characteristics and active
                            exposures for each strategy.
                          </>
                        ) : (
                          <>
                            Tilt amount is the degree of emphasis on the factors you are pursuing.
                            Higher amounts create larger deviations from market-cap weights which
                            can result in wider ranges of outcomes relative to benchmarks.
                          </>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                );
              }}
            </NestedFormEdit>
          </NestedFormEditGroup>
          <ActionFooter borderTop={0} justifyContent="space-between">
            <BackButton onClick={() => onBack()} />
            <Box display="flex">
              <ReturnToSummary
                draftPortfolio={draftPortfolio}
                disabled={hasActiveForm}
                onReturnToSummary={() => onContinue('summary')}
                mr={1.5}
              />
              <Button disabled={hasActiveForm} color="primary" type="submit" variant="contained">
                Continue
              </Button>
            </Box>
          </ActionFooter>
        </form>
      </ContentBox>
      <DisclosuresDrawer
        open={disclosuresPanelOpen}
        BackdropProps={{ invisible: true }}
        anchor="right"
        onClose={() => setDisclosuresPanelOpen(false)}
      >
        <Box width={500} height="100vh">
          <Box
            position="fixed"
            display="flex"
            width={500}
            justifyContent="space-between"
            px={3}
            height={72}
            alignItems="center"
            boxShadow={8}
            bgcolor="white"
          >
            <Typography variant="h2">Backtesting disclosures</Typography>
            <IconButton
              aria-label="close disclosures drawer"
              onClick={() => setDisclosuresPanelOpen(false)}
              size="large"
            >
              <ChevronRight fontSize={24} />
            </IconButton>
          </Box>
          <Box pt={8.75} px={3} pb={3}>
            <Box mt={3}>
              <Typography variant="h3">General</Typography>
              <Box color="grey.600" mt={1.5}>
                <Typography variant="body1">
                  Back-tested performance represents hypothetical returns and does not reflect
                  trading in actual client accounts. Back-tested performance also differs from
                  actual performance because it is achieved through the retroactive application of
                  portfolios designed with the benefit of hindsight. As a result, the portfolios
                  used in the back-testing process may be changed from time to time and the effect
                  on hypothetical performance results could be different. As a simulation
                  methodology, in general, backtesting has certain limitations. It does not involve
                  or take into account financial risk and does not take into account that material
                  and market factors may have impacted investment decision making, all of which can
                  adversely affect actual trading results and performance. Back-tested performance
                  also does not take into account trading costs or the impact of taxes.
                  <br />
                  <br />
                  Tax liabilities will vary for each client and can result from various activities
                  in taxable and tax-deferred accounts. These activities include, but are not
                  limited to rebalancing of portfolios, any sale of securities, tax-loss harvesting,
                  interest, dividends and capital gains from securities held in taxable accounts.
                  There are also tax liabilities associated with distributions from tax-deferred
                  accounts. Not all Vise clients follow Vise&apos;s recommendations and depending on
                  the unique and changing client and market situations, Vise or the primary adviser
                  of the client may further customize a Vise Portfolio for particular clients so
                  that actual client accounts differ materially from those shown. As a result of
                  these and other variances, actual performance for client accounts can be
                  materially different than the results shown in the back-tested performance.
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="h3">Backtesting methodology</Typography>
                <Box color="grey.600" mt={1.5}>
                  <Typography variant="body1">
                    Simulated data is for research purposes only. This does not reflect actual
                    performance of a live or proposed strategy. Results of an actual account may
                    vary significantly. The performance shown was achieved with the retroactive
                    application of a model designed with the benefit of hindsight; it does not
                    represent actual investment performance. Backtested model performance is
                    hypothetical (it does not reflect trading in actual accounts) and is provided
                    for informational purposes only. Simulated performance does not reflect the
                    impact that economic and market factors might have had on the advisor&apos;s
                    decision making if the advisor were actually managing client money. Simulations
                    include both simulated performance of Vise-managed strategies, as well as
                    Morningstar Index data. The backtesting for Vise-managed employs Vise&apos;s
                    security selection and weighting model based on financial data and prices,
                    according to Vise&apos;s proprietary methodology. For asset classes not managed
                    directly by Vise, index data is used. Indices are not available for direct
                    investment. The backtest shown is a backtest of the initial target allocation
                    for the investor, given their inputs on the Vise platform. The backtest does not
                    account for additional Vise platform features including variable asset
                    allocation through our glide path or target value approach, cash distributions,
                    tax loss harvesting, or portfolio input changes. The simulated performance shown
                    is “gross performance,” which includes the reinvestment of dividends but does
                    not reflect the deduction of investment advisory fees and other expenses. A
                    client&apos;s investment returns will be reduced by the advisory fees or other
                    expenses it may incur. Fees are fully described in Vise&apos;s Form ADV
                    brochure.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DisclosuresDrawer>
    </>
  );
}

export default ActiveTiltsScreen;
