import palette from "./palette.js";
import typography, { FONT_FAMILY } from "./typography.js";
import spacing, { SPACING_INDICES } from "./spacing.js";
import shadow from "./shadow.js";

export default {
  palette,
  typography,
  spacing,
  shadow,
  SPACING_INDICES,
  FONT_FAMILY,
};
