import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Route as RouteType } from '~/models/shared';
import { tokenStore } from '../utils';
import SentryRoute from './SentryRoute';

const renderRoutes = (
  routes: RouteType[] | undefined,
  currentUser: { id: string } | null = null,
  authPathOverride?: { loading: boolean; path: string }
) => {
  const userId = currentUser?.id;
  const isAuthenticated = Boolean(tokenStore.getAuthToken() && userId);

  const defaultRedirectPath = '/secure/dashboard';
  const queryParams = new URLSearchParams(window.location.search);
  const nextPath = queryParams.get('path') || '';
  const nextSearch = queryParams.get('search') || '';
  const defaultAuthPath = `${
    nextPath || authPathOverride?.path || defaultRedirectPath
  }${nextSearch}`;

  const redirectTarget =
    isAuthenticated && !authPathOverride?.loading ? defaultAuthPath : undefined;

  if (routes == null) {
    return null;
  }

  // Bypass react-router rules, since we wanna avoid even rendering the app when we're using OAuth2.
  if (
    isAuthenticated &&
    redirectTarget &&
    (nextPath === '/api/auth/authorize' || nextPath === '/md')
  ) {
    window.location.href = redirectTarget;
    return null;
  }

  return (
    <Switch>
      {routes.map((route, i) => (
        <SentryRoute
          exact={route.exact}
          key={route.key || i}
          path={route.path}
          render={(props) => {
            if (route.notFound === true) {
              return <Redirect to="/404" />;
            }

            if (route.path === '/login' && redirectTarget) {
              return <Redirect to={redirectTarget} />;
            }

            if (route.authenticate) {
              if (isAuthenticated) {
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ route: ComponentRoute; history: History<Po... Remove this comment to see the full error message
                return <route.component {...props} route={route} />;
              }
              return <Redirect to="/login" />;
            }
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ route: ComponentRoute; history: History<Po... Remove this comment to see the full error message
            return <route.component {...props} route={route} />;
          }}
          strict={route.strict}
        />
      ))}
    </Switch>
  );
};

export default renderRoutes;
