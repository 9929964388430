import { Box, Chip, FormLabel } from '@mui/material';
import { debounce } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useMemo } from 'react';
import { ActionMeta, ValueType } from 'react-select';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useInstruments, { useIndexedInstruments } from '~/hooks/useInstruments';
import AsyncMultiselect from '~/synth/inputs/AsyncMultiselect';
import amplitude from '~/utils/amplitude';
import { DetailsText } from './SummarySections';

export interface InstrumentOption {
  label: string;
  value: string;
  description: string;
}

type SelectedInstrumentOptions = InstrumentOption[] | null;

interface InstrumentsSelectorProps {
  /** An array of instrument symbols */
  value: string[];
  /** An array of instrument symbols, to be shown in the chips */
  chipValues: string[];
  /** onChange handler given to the multiselector */
  onChange: (value: InstrumentOption[]) => void;
  /** Callback invoked when user removes a selection via a chip */
  onRemove: (symbol: string) => void;
  /** The name of the questionnaire step on which this selector is used,
   * to be sent with Amplitude events.
   */
  questionnaireStepName?: string | null;
}

export default function InstrumentsSelector({
  questionnaireStepName,
  value,
  chipValues,
  onChange,
  onRemove,
}: InstrumentsSelectorProps) {
  const { data: instruments } = useInstruments();
  const { data: indexedInstruments } = useIndexedInstruments();
  const selectedOptions = useMemo(() => {
    return indexedInstruments
      ? value.map((ticker) => {
          const { name, symbol } = indexedInstruments[ticker] || { name: ticker, symbol: ticker };
          return {
            label: symbol,
            value: symbol,
            description: name,
          };
        })
      : [];
  }, [value, indexedInstruments]);

  const allOptions = useMemo(() => {
    if (instruments) {
      return instruments.map((ins) => ({
        label: ins.symbol,
        value: ins.symbol,
        description: ins.name,
      }));
    }
    return [];
  }, [instruments]);

  const loadOptions = debounce((inputValue: string, callback: (o: InstrumentOption[]) => void) => {
    return callback(
      allOptions == null
        ? []
        : matchSorter<InstrumentOption>(allOptions, inputValue, {
            keepDiacritics: true,
            keys: [{ threshold: matchSorter.rankings.STARTS_WITH, key: 'value' }, 'description'],
          })
    );
  }, 800);

  const handleChange = (
    newValue: ValueType<InstrumentOption>,
    actionMeta: ActionMeta<InstrumentOption>
  ) => {
    const { action, option } = actionMeta;
    if (
      action !== 'select-option' &&
      action !== 'deselect-option' &&
      action !== 'clear' &&
      action !== 'set-value'
    ) {
      return;
    }

    const newInstruments = (newValue as SelectedInstrumentOptions) ?? [];
    if (option != null) {
      amplitude().logEvent(
        (newInstruments?.length ?? 0) > value.length
          ? 'Add company restriction'
          : 'Remove company restriction',
        {
          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
          restrictionQuestion: questionnaireStepName,
          ticker: option.value,
        }
      );
    } else if (action === 'clear') {
      amplitude().logEvent('Clear company restrictions', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        restrictionQuestion: questionnaireStepName,
      });
    }
    onChange(newInstruments);
  };

  return (
    <>
      <Box mb={1}>
        <FormLabel htmlFor="companies">Tickers</FormLabel>
        <Box mt={0.5}>
          <DetailsText variant="body1" color="textSecondary">
            Exclude individual securities listed on the stock exchange.
          </DetailsText>
        </Box>
      </Box>
      <AsyncMultiselect<InstrumentOption>
        cacheOptions
        loadOptions={loadOptions}
        onFocus={() => {
          amplitude().logEvent('Tap company restriction search', {
            category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
            restrictionQuestion: questionnaireStepName,
          });
        }}
        filterOption={null}
        defaultOptions={allOptions}
        isSearchable
        inputId="companies"
        isLoading={allOptions == null}
        onChange={handleChange}
        placeholder="Ex. Apple Inc"
        value={selectedOptions}
      />
      {chipValues.map((r) => (
        <Box mr={1.5} mt={1.5} key={r}>
          <Chip
            label={indexedInstruments?.[r]?.name ?? r}
            onDelete={() => {
              amplitude().logEvent('Remove company restriction', {
                category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                restrictionQuestion: questionnaireStepName,
                ticker: r,
                usedChip: true,
              });
              onRemove(r);
            }}
          />
        </Box>
      ))}
    </>
  );
}
