import { Box, Dialog, InputAdornment, Tab, Tabs, useTheme } from '@mui/material';
import { format } from 'date-fns/esm';
import React, { useMemo, useState } from 'react';
import { Column } from 'react-table';
import { ReactComponent as ShoppingCartIcon } from '~/static/images/icons/shopping-cart.svg';
import { ReactComponent as TagIcon } from '~/static/images/icons/tag.svg';
import { DataTable } from '~/synth/DataTable';
import { SearchIcon } from '~/synth/Icons';

import { matchSorter } from 'match-sorter';
import DialogTitle from '~/synth/DialogTitle';
import TextField from '~/synth/TextField';
import { ACCOUNTING_FORMATTER, formatCurrency, formatQuantity } from '~/utils/format';
import { NoResultsFound } from '../components/TableComponents';
import Truncation from '../components/Truncation';
import { SECTOR_TO_LABEL_MAP } from '../Constants';
import { Trade } from './Utils';

export default function TradesModal({
  date,
  buys,
  sells,
  open,
  onClose,
}: {
  buys: Trade[];
  sells: Trade[];
  open: boolean;
  onClose: () => void;
  date: string | null;
}) {
  const theme = useTheme();
  const [filterQuery, setFilterQuery] = useState('');
  const [activeTab, setActiveTab] = useState<'sells' | 'buys'>(
    buys.length === 0 ? 'sells' : 'buys'
  );

  const tableData = useMemo(
    () =>
      matchSorter(activeTab === 'sells' ? sells : buys, filterQuery, {
        keys: ['ticker', 'name'],
        threshold: matchSorter.rankings.CONTAINS,
      }),
    [filterQuery, sells, buys, activeTab]
  ) as Trade[];

  const columns = useMemo(
    () =>
      [
        { Header: 'Ticker', accessor: 'ticker', width: '8%' },
        {
          Header: 'Name',
          accessor: 'name',
          Cell: (props) => <Truncation>{props.value}</Truncation>,
          width: '28%',
        },
        {
          Header: 'Sector',
          accessor: 'sector',
          Cell: (props) => (props.value ? SECTOR_TO_LABEL_MAP.get(props.value[0]) ?? '-' : '-'),
          width: '17%',
        },
        {
          Header: 'Quantity',
          accessor: 'quantity',
          Cell: (props) => <div style={{ textAlign: 'right' }}>{formatQuantity(props.value)}</div>,
          width: '10%',
          align: 'right',
        },
        {
          Header: 'Price',
          accessor: 'price',
          Cell: (props) => <div style={{ textAlign: 'right' }}>{formatCurrency(props.value)}</div>,
          width: '10%',
          align: 'right',
        },
        {
          Header: 'Total value',
          accessor: 'totalValue',
          Cell: (props) => (
            <div style={{ textAlign: 'right' }}>
              {formatCurrency(props.value, ACCOUNTING_FORMATTER)}
            </div>
          ),
          width: '10%',
          align: 'right',
        },
      ] as Column<Trade>[],
    []
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" keepMounted>
      <DialogTitle onClose={onClose}>
        {date != null ? format(new Date(date), 'MMMM do yyyy') : 'N/A'}
      </DialogTitle>
      <Box height="calc(100vh - 200px)">
        <Box mb={3} boxShadow={theme.shadows[10]} px={3}>
          <Tabs value={activeTab} onChange={(_e, value) => setActiveTab(value)}>
            <Tab
              value="buys"
              label={
                <Box display="flex">
                  <ShoppingCartIcon />
                  <Box ml={1}>Buys</Box>
                </Box>
              }
              disabled={buys.length === 0}
            />
            <Tab
              value="sells"
              label={
                <Box display="flex">
                  <TagIcon />
                  <Box ml={1}>Sells</Box>
                </Box>
              }
              disabled={sells.length === 0}
            />
          </Tabs>
        </Box>
        <Box display="flex" justifyContent="space-between" mx={3} alignItems="center" mb={2}>
          <Box width={400}>
            <TextField
              placeholder="Search by ticker or name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setFilterQuery(e.target.value)}
              fullWidth
            />
          </Box>
          <Box color="grey.500">
            {tableData.length} trade{tableData.length > 1 ? 's' : ''}
          </Box>
        </Box>
        <Box mx={2}>
          <DataTable
            data={tableData}
            columns={columns}
            sortable
            tableMaxHeight="calc(100vh - 350px)"
          />
          {!tableData.length && <NoResultsFound />}
        </Box>
      </Box>
    </Dialog>
  );
}
