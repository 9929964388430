import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { XrayClient } from 'vise-types/xray';
import useEnqueueToast from '~/hooks/useToast';
import useXrayAccounts from '~/hooks/useXrayAccounts';
import DialogTitle from '~/synth/DialogTitle';
import { useHistory } from 'react-router';
import { ProductOnboardingRoutes } from '~/routes/ProductOnboarding/routes';
import { Controller, useForm } from 'react-hook-form';
import { TextHighlightTag } from '~/synth/Tag';
import { Action, State } from './ClientOnboardingState';
import {
  NewHouseholdForm,
  useNewHouseholdFormik,
} from '../Households/Households/LinkAccountOrCreateClientModal';

function AddNewClientModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const newHouseholdForm = useNewHouseholdFormik(null, onClose);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Create a new client</DialogTitle>
      <DialogContent>
        <NewHouseholdForm newHouseholdForm={newHouseholdForm} />
      </DialogContent>
    </Dialog>
  );
}

export default function SelectClient({
  dispatch,
  renderFooter,
  state,
}: {
  dispatch: React.Dispatch<Action>;
  state: State;
  selectedClient: XrayClient | null;
  renderFooter: () => React.ReactNode;
}) {
  const enqueueToast = useEnqueueToast();
  const [showCreateNewClient, setShowCreateNewClient] = useState(false);
  const columns: GridColDef[] = [
    {
      headerName: 'Name',
      field: 'firstName',
      flex: 1,
      renderCell: ({ row }) => `${row.firstName} ${row.lastName}`,
    },
    { headerName: 'Household', field: 'householdName', flex: 1 },
    {
      headerName: 'Status',
      flex: 1,
      field: 'status',
      renderCell: ({ row }) => (
        <TextHighlightTag severity={row.proposalInfos.length > 0 ? 'success' : 'warning'}>
          {row.proposalInfos.length > 0 ? 'Ready to onboard' : 'Incomplete'}
        </TextHighlightTag>
      ),
    },
    {
      headerName: 'Proposals',
      field: 'test',
      renderCell: ({ row }) => row.proposalInfos.length,
      flex: 1,
    },
  ];

  const { data: xrayAccounts, error: xrayAccountsError } = useXrayAccounts();
  const history = useHistory();

  useEffect(() => {
    if (xrayAccountsError != null) {
      enqueueToast({
        title: 'We encountered an error when trying to load prospective clients.',
        content: 'Please reach out to clientservice@vise.com if you have any questions.',
        severity: 'error',
      });
      Sentry.captureException(new Error(`[SelectClient] Failed to load xray accounts`));
    }
  }, [xrayAccountsError, enqueueToast]);

  const rows = xrayAccounts?.data.data ?? [];

  const { handleSubmit, control, getValues } = useForm({ defaultValues: state });

  const onSubmit = ({ selectedClient }: State) => {
    dispatch({
      type: 'selectedClient',
      value: selectedClient,
    });
    if (!getValues('selectedClient')?.proposalInfos.length) {
      history.push(`${ProductOnboardingRoutes.selectClient}/no-xray`);
      return;
    }
    history.push(ProductOnboardingRoutes.selectXray);
  };

  const toast = useEnqueueToast();

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit, () =>
          toast({ title: 'Please select a client', severity: 'warning' })
        )}
      >
        <Box p={4} height="calc(100vh - 200px)">
          <Typography variant="h6" color="grey.600">
            Step 1
          </Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="h2" pr={2}>
              Select client
            </Typography>
          </Box>
          <Box mb={2} color="grey.600" display="flex">
            To begin, search for which client you’d like to onboard, or
            <Box
              sx={{ cursor: 'pointer' }}
              color="primary.main"
              ml={0.5}
              onClick={() => setShowCreateNewClient(!showCreateNewClient)}
            >
              create a new client.
            </Box>
          </Box>
          <Box pb={3} height="calc(100% - 100px)">
            <Controller
              name="selectedClient"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DataGridPro
                  loading={!rows.length}
                  pagination
                  autoPageSize
                  hideFooter={false}
                  columns={columns}
                  rows={rows}
                  getRowId={(row) => row.clientId}
                  onRowSelectionModelChange={(change) =>
                    onChange(rows.find((r) => r.clientId === change?.[0]))
                  }
                  rowSelectionModel={value?.clientId}
                  disableRowSelectionOnClick={false}
                  disableMultipleRowSelection
                />
              )}
              control={control}
            />
          </Box>
          {renderFooter()}
        </Box>
        <AddNewClientModal
          open={showCreateNewClient}
          onClose={() => setShowCreateNewClient(false)}
        />
      </form>
    </>
  );
}
