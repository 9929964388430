import useSWR from 'swr';
import { getTemplatesByParentIds } from '~/api/api';
import generateSwrKey from '../../api/generateSwrKey';

export default function useTemplatesByParentIds({
  ids,
  includeStale,
  includeDeleted,
}: {
  ids: string[];
  includeStale?: boolean;
  includeDeleted?: boolean;
}) {
  return useSWR(generateSwrKey(`allocations-templates-${includeStale}-${includeDeleted}`), () =>
    getTemplatesByParentIds({ ids, includeStale, includeDeleted })
  );
}
