import useSWR from 'swr';
import { getDistributionScheduleSchema } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getDistributionScheduleSchemaSwrKey = () =>
  generateSwrKey(`distributionScheduleSchema`);

const useDistributionScheduleSchema = () => {
  return useSWR(generateSwrKey(`distributionScheduleSchema`), () =>
    getDistributionScheduleSchema()
  );
};

export default useDistributionScheduleSchema;
