import { useTheme } from '@mui/material';

export default function useCategoricalDataVisColors() {
  const theme = useTheme();
  const { categorical } = theme.dataVis;
  return [
    categorical.chartBlack,
    categorical.chartRiver,
    categorical.chartBlue,
    categorical.chartSalmon,
    categorical.chartRuby,
    categorical.chartLavender,
    categorical.chartAmericanGray,
    categorical.chartAqua,
    categorical.chartGreen,
    categorical.chartGold,
    categorical.chartYellow,
  ];
}
