import { Dialog, Box, Typography, DialogActions, Button, useTheme, Divider } from '@mui/material';
import React from 'react';
import DialogTitle from '~/synth/DialogTitle';
import { ReactComponent as ExclamationCircleIcon } from '~/static/images/icons/exclamation-circle.svg';

export default function ExitCreateFlowModal({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center" mt={1}>
          <ExclamationCircleIcon color={theme.palette.error[400]} height={25} width={25} />

          <Box sx={{ lineHeight: 1 }} ml={1}>
            Are you sure you want to exit?
          </Box>
        </Box>
      </DialogTitle>
      <Box mx={3} mb={3}>
        <Typography variant="body2">
          <Box color={theme.palette.grey[600]}>
            Any changes you&apos;ve made will not be saved or applied.
          </Box>
        </Typography>
      </Box>
      <Divider />
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" onClick={onConfirm}>
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
