import { Dialog, Box, Typography, Divider, DialogActions, Button, useTheme } from '@mui/material';
import React from 'react';
import DialogTitle from '~/synth/DialogTitle';
import { ReactComponent as BriefcaseIcon } from '~/static/images/icons/briefcase.svg';
import { ReactComponent as GlobeIcon } from '~/static/images/icons/globe.svg';
import { ReactComponent as ClipboardCheckIcon } from '~/static/images/icons/clipboard-check.svg';

export default function RestrictionHelpModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Choosing restrictions</DialogTitle>
      <Box mx={3} mb={3}>
        <Box alignItems="center" display="flex">
          <BriefcaseIcon />
          <Box ml={1}>
            <Typography variant="h4">Employment</Typography>
          </Box>
        </Box>
        <Box mt={1.5} mb={4}>
          <Typography variant="body2">
            <Box color={theme.palette.grey[600]}>
              If your client works in a highly regulated industry, they may be restricted from
              investing in specific companies or sectors. In addition, some investors prefer to
              eliminate additional exposure to the company or sector in which they (or their spouse,
              if applicable) work.
            </Box>
          </Typography>
        </Box>
        <Divider />
        <Box alignItems="center" display="flex" mt={3}>
          <GlobeIcon />
          <Box ml={1}>
            <Typography variant="h4">International exposure</Typography>
          </Box>
        </Box>
        <Box mt={1.5} mb={4}>
          <Typography variant="body2">
            <Box color={theme.palette.grey[600]}>
              Some clients may wish to limit exposure to certain countries or currencies.
            </Box>
          </Typography>
        </Box>
        <Divider />
        <Box alignItems="center" display="flex" mt={3}>
          <ClipboardCheckIcon />
          <Box ml={1}>
            <Typography variant="h4">Values</Typography>
          </Box>
        </Box>
        <Box mt={1.5} mb={4}>
          <Typography variant="body2" paragraph>
            <Box color={theme.palette.grey[600]}>
              Many investors wish to align their investment portfolios with their personal beliefs.
              For example, an environmentally-conscious investor may desire to exclude companies
              that derive revenue from artic oil and gas exploration, engage in animal testing, or
              produce pesticides.
            </Box>
          </Typography>
          <Typography variant="body2" paragraph>
            <Box color={theme.palette.grey[600]}>
              Using Vise&apos;s values-based restrictions, you may exclude companies that are
              directly involved in specific activities to meet your client&apos;s ethical, impact,
              or compliance needs.
            </Box>
          </Typography>
        </Box>
      </Box>
      <Divider />
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
