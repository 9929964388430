import useSWR from 'swr';
import { getAccountWithAccountNumber } from '~/api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useAccountWithAccountNumber(
  custodianId: string | null | undefined,
  accountNumber: string | null | undefined,
  includeSummary?: boolean
) {
  return useSWR(
    custodianId == null || accountNumber == null
      ? null
      : generateSwrKey(
          `/portfolio/accounts/${custodianId}/${accountNumber}${
            includeSummary ? '?include_summary=true' : ''
          }`
        ),
    custodianId == null || accountNumber == null
      ? undefined
      : () => getAccountWithAccountNumber(custodianId, accountNumber, includeSummary)
  );
}
