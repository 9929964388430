import { Box, Card, Divider } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import useAccountOrders from '~/hooks/useAccountOrders';
import usePositions from '~/hooks/usePositions';
import { getAssetClassFeaturesFromKey } from '~/utils/pce2Migration';
import { captureException } from '@sentry/react';
import { ASSET_CLASS_TO_LABEL_MAP } from '../../PortfolioCreator2/Constants';
import {
  PricedPortfolioHolding,
  getPCE2AccountPositionsRowData,
  transformPCE2AssetClassAllocations,
} from '../../Portfolio/portfolioUtil';
import AllocationMap from '../../Portfolio/components/AllocationMap';
import GPTCardHeader from './GPTCardHeader';
import LoadingCard from './LoadingCard';
import CardDisclosures from './CardDisclosures';
import useAccountWithLogging from './useAccountWithLogging';
import usePortfolioIntelligenceWithLogging from './usePortfolioIntelligenceWithLogging';
import usePortfolioMetricsWithLogging from './usePortfolioMetricsWithLogging';

export default function GPTGeoAllocationMapCard({ accountId }: { accountId?: string }) {
  const { data: accountResponse } = useAccountWithLogging({
    accountId,
    callerName: 'GPTGeoAllocationMapCard',
  });
  const { data: accountOrders, error: ordersError } = useAccountOrders(accountId);

  const account = accountResponse?.data;

  const { data: intelligenceArrayData } = usePortfolioIntelligenceWithLogging(
    account?.portfolioIntelligenceId,
    'GPTGeoAllocationMapCard'
  );
  const intelligence = intelligenceArrayData?.[0];

  const { data: pce2Metrics } = usePortfolioMetricsWithLogging(
    accountId,
    'GPTGeoAllocationMapCard'
  );

  const unadjustedAssetAllocation = useMemo(
    () =>
      pce2Metrics
        ? transformPCE2AssetClassAllocations(pce2Metrics.portfolioMetrics.assetClassAllocations)
        : [],
    [pce2Metrics]
  );

  useEffect(() => {
    if (ordersError != null) {
      captureException(
        new Error(
          `[GPTGeoAllocationMapCard] Failed to load orders for account ${accountId}. ${ordersError}`
        ),
        {
          extra: {
            accountId,
          },
        }
      );
    }
  }, [accountId, ordersError]);

  const { data: positions } = usePositions(
    account?.accountNumber,
    account?.custodianKey,
    true // Fetch instruments
  );

  const positionsGroupedByAssetClass = React.useMemo(() => {
    if (!account || !intelligence || !positions) {
      return {};
    }

    const lockedTickers = new Set(intelligence.constructionRequest.doNotSellSymbolsList);

    return getPCE2AccountPositionsRowData(
      positions.positions,
      account.custodianKey,
      lockedTickers,
      (securityType, assetClass) => {
        if (ASSET_CLASS_TO_LABEL_MAP.get(getAssetClassFeaturesFromKey(assetClass)[0])) {
          return ASSET_CLASS_TO_LABEL_MAP.get(
            getAssetClassFeaturesFromKey(assetClass)[0]
          ) as string;
        }
        if (securityType === 'CASH_OR_EQIV' || securityType === 'MF') {
          return 'Cash';
        }
        return 'Other assets';
      }
    );
  }, [account, intelligence, positions]);

  const sortedHoldings: PricedPortfolioHolding[] = React.useMemo(() => {
    const allHoldings = Object.values(positionsGroupedByAssetClass).flat();
    const sorted = allHoldings.sort((a, b) =>
      a.value != null && b.value != null && a.value < b.value ? 1 : -1
    );
    return sorted.map((holding, idx) => {
      return { ...holding, ranking: idx + 1 };
    });
  }, [positionsGroupedByAssetClass]);

  if (!account || !accountOrders || !intelligence || !positions) {
    return <LoadingCard contentHeight="400px" />;
  }

  return (
    <Card>
      <GPTCardHeader
        title="Geo-allocation"
        buttonPathname={`/secure/accounts/${accountId}/portfolio/overview`}
        buttonCopy="View account"
        account={account}
      />
      <Box
        height="400px"
        sx={{
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'white',
          },
          '& .MuiDataGrid-root': {
            boxShadow: undefined,
            border: '0px solid #000000',
          },
        }}
      >
        <AllocationMap
          assetAllocation={unadjustedAssetAllocation}
          positions={sortedHoldings}
          restrictedCountries={intelligence?.constructionRequest.doNotHoldCountriesList}
          mapOnly
        />
      </Box>
      <Divider />
      <CardDisclosures />
    </Card>
  );
}
