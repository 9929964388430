import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import useUserTracking from '~/hooks/useUserTracking';

import { Route, matchPath, useLocation } from 'react-router';
import { ProductOnboardingRoutes } from '~/routes/ProductOnboarding/routes';
import useProductOnboarding from '~/hooks/useProductOnboarding';
import { tokenStore } from '~/utils';
import { getTreatments } from '~/api/featureFlag';
import { Treatments } from '~/models/featureFlag';
import { RootState } from '~/reducers';
import renderRoutes from './renderRoutes';
import routes from './routes';
import SentryRoute from './SentryRoute';

export default function Root() {
  const currentUser = useSelector((state: RootState) => state.users.currentUser);
  const { productOnboardingRequired } = useProductOnboarding();
  const isAuthenticated = tokenStore.getAuthToken();

  const [featureFlags, setFeatureFlags] = useState<Treatments | undefined>(undefined);
  // Manually fetch feature flags so we immediately figure out the redirect when the user logs in
  useEffect(() => {
    const fetchFlags = async () => {
      if (currentUser) {
        const flagsResponse = await getTreatments();
        setFeatureFlags(flagsResponse);
      }
    };
    fetchFlags();
  }, [currentUser]);

  useUserTracking();
  const redirectToProductOnboarding = productOnboardingRequired && isAuthenticated;

  // Hacky solution to avoid WEG users from accessing the core product, later we will need to use
  const location = useLocation();
  const redirectToModelDelivery =
    featureFlags?.is_enterprise_user === 'on' &&
    !matchPath(location.pathname, [
      '/secure/households',
      '/login',
      '/secure/md',
      '/secure/new-distribution',
      '/secure/accounts/:accountId/distributions/:scheduleId/edit',
    ]);

  useEffect(() => {
    if (redirectToModelDelivery) {
      window.location.href = '/md';
    }
  }, [redirectToModelDelivery]);

  if (redirectToModelDelivery) {
    return null;
  }

  return (
    <Switch>
      <SentryRoute exact path="/">
        <Redirect to="/login" />
      </SentryRoute>
      {redirectToProductOnboarding && (
        <Route path="/secure/(households|distributions)">
          <Redirect to={`${ProductOnboardingRoutes.strategyCenter}?updateStepper=true`} />
        </Route>
      )}
      <SentryRoute exact path="/secure">
        <Redirect to="/secure/households" />
      </SentryRoute>
      {renderRoutes(routes, currentUser, {
        loading: !featureFlags,
        path: featureFlags?.enable_advisor_dashboard === 'on' ? '/secure/dashboard' : '',
      })}
    </Switch>
  );
}
