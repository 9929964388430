import { Box } from '@mui/material';
import React from 'react';
import { TextHighlightTag } from '~/synth/Tag';

export default function UpdatedTitle({ title }: { title: string }) {
  return (
    <Box display="flex" alignItems="center">
      <Box>{title}</Box>
      <Box ml={1}>
        <TextHighlightTag severity="priority">Updated</TextHighlightTag>
      </Box>
    </Box>
  );
}
