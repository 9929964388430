// eslint-disable-next-line import/prefer-default-export
export const ProductOnboardingRoutes = {
  home: '/secure/product-onboarding/dashboard',
  strategyCenter: '/secure/product-onboarding/dashboard/strategy-center',
  xray: '/secure/product-onboarding/dashboard/xray-analysis',
  psa: '/secure/product-onboarding/dashboard/psa',
  startOnboard: '/secure/product-onboarding/dashboard/start-onboarding',
  onboard: '/secure/product-onboarding/onboard-client',
  selectClient: '/secure/product-onboarding/onboard-client/select-client',
  selectXray: '/secure/product-onboarding/onboard-client/select-xray',
  custodianForm: '/secure/product-onboarding/onboard-client/custodian-form',
  review: '/secure/product-onboarding/onboard-client/review',
} as const;
