/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box, Typography, Button, Divider, ThemeProvider } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { theme, tokens } from '@vise_inc/ds-vise';

import empty from '~/static/images/illustrations/empty-butterfly.svg';

import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import { formatCurrency } from '~/utils/format';
import { ReactComponent as ArrowLeftIcon } from '~/static/images/icons/arrow-left.svg';

import { DataGridPro, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { getViseIntelSettings, upsertIntelSettings } from '~/api/gptApi';
import * as Sentry from '@sentry/react';

import useEnqueueToast from '~/hooks/useToast';
import { groupBy, isEqual } from 'lodash';
import useAllAccountsWithHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import { AccountCell, HouseholdCell } from '../ModelTemplateCenter/workflows/CommonComponents';
import EmptyState from '../ModelTemplateCenter/EmptyState';
import ValueCell from '../Households/Households/ValueCell';
import renderAccountStatus from '../Households/components/renderAccountStatus';

interface AccountSelectorTableData {
  account: AccountWithPIAndHouseholdInfo;
  value: number;
  id: string;
}

const MAX_NUMBER_OF_SELECTED_ACCOUNTS = 50;

export default function GPTSettings({ closeSettings }: { closeSettings: () => void }) {
  const [accountIds, setAccountIds] = useState<string[]>([]);
  const [savedAccountIds, setSavedAccountIds] = useState<string[]>([]);

  const { data: accountsData } = useAllAccountsWithHouseholdInfo({ transitioned: true });
  const { data: mdAccountsData } = useAllAccountsWithHouseholdInfo({
    transitioned: true,
    engineType: 'MODEL_DELIVERY',
  });
  const accounts = (accountsData?.data || []).concat(mdAccountsData?.data || []);
  const enqueueToast = useEnqueueToast();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const noNewAccountsSelected = useMemo(
    () => isEqual(new Set(accountIds), new Set(savedAccountIds)),
    [accountIds, savedAccountIds]
  );

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getViseIntelSettings();
        setAccountIds(settings?.enabledAccounts || []);
        setSavedAccountIds(settings?.enabledAccounts || []);
      } catch (e) {
        throw new Error(`Error fetching Vise Intel settings. ${e}`);
      }
    };
    fetchSettings();
  }, []);

  const onSubmit = useCallback(async () => {
    if (accountIds.length === 0) {
      enqueueToast({
        title: 'No accounts selected.',
        content: 'Please select at least one account.',
        severity: 'error',
      });
      return;
    }

    try {
      await upsertIntelSettings({ enabledAccounts: accountIds });
      setSavedAccountIds(accountIds);
      enqueueToast({
        title: 'Settings submitted.',
        content: `${accountIds.length} accounts have been enabled for Vise Intelligence.`,
        severity: 'success',
      });

      closeSettings();
    } catch (error) {
      Sentry.captureException(new Error(`[Intel] Failed to submit settings.`), {
        extra: { error },
      });
      enqueueToast({
        title: 'Failed to submit settings.',
        content: 'Please try again later.',
        severity: 'error',
      });
    }
  }, [accountIds, closeSettings, enqueueToast]);

  if (!accountsData || !mdAccountsData) {
    return <Box pt={6}>Loading data...</Box>;
  }

  return (
    <Box my={5} mx="auto" maxWidth="1280px">
      <Button
        variant="text"
        color="secondary"
        disabled={savedAccountIds.length === 0}
        onClick={() => closeSettings()}
      >
        <Box display="flex" gap={1} alignItems="center">
          <ArrowLeftIcon />
          Back to Vise Intelligence
        </Box>
      </Button>

      <Box
        px={3}
        mt={2}
        pt={3}
        sx={{
          border: ' 1px solid',
          borderRadius: '4px',
          borderColor: tokens.palette.neutralCool[200],
        }}
      >
        <Box m={1}>
          <Typography variant="h1">Settings</Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={3} py={3}>
          <HeaderRow countOfSelectedAccounts={accountIds.length} />

          <Divider />

          <Box display="flex" py={2} px={3} gap={5} alignItems="flex-start" alignSelf="stretch">
            <Box
              flex={1}
              border={`1px solid ${tokens.palette.neutralCool[300]}`}
              borderRadius="4px"
            >
              <AccountSelector
                accounts={accounts}
                selectedAccountIds={accountIds}
                setSelectedAccountIds={(ids) => setAccountIds(ids)}
              />
            </Box>

            <Box width="288px">
              <SelectedAccounts
                allAccounts={accounts}
                selectedAccountIds={accountIds}
                restoreAccounts={() => setAccountIds(savedAccountIds)}
                noNewAccountsSelected={noNewAccountsSelected}
              />
            </Box>
          </Box>

          <Box p={2} display="flex">
            <Box flex={1} />
            <Button
              variant="contained"
              color="primary"
              disabled={submitDisabled || noNewAccountsSelected}
              onClick={async () => {
                setSubmitDisabled(true);
                await onSubmit();
                setSubmitDisabled(false);
              }}
            >
              Save changes
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function HeaderRow({ countOfSelectedAccounts }: { countOfSelectedAccounts: number }) {
  return (
    <Box display="flex" height="118px" px={3} alignItems="center" gap={5}>
      <Box flex={1} display="flex" gap={2} flexDirection="column">
        <Typography variant="h3">Accounts</Typography>
        <Typography variant="body1" color="neutralCool.800">
          By selecting accounts here, you will be able to ask questions and gather insights on these
          particular accounts. The selected accounts can be configured here, and changed at any
          point in time.
        </Typography>
        <Typography variant="body1" fontWeight="500" color="neutralCool.800">
          There is a maximum of {MAX_NUMBER_OF_SELECTED_ACCOUNTS} accounts that can be selected at
          this time.
        </Typography>
      </Box>

      <Box height="100%">
        <Divider orientation="vertical" />
      </Box>

      <ThemeProvider theme={theme}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          width="256px"
          height="90%"
          p={4}
          bgcolor="secondaryTurquoise.100"
          sx={{ borderColor: 'secondaryTurquoise.800', borderStyle: 'solid', borderWidth: '1px' }}
          borderRadius="4px"
        >
          <Typography variant="body2" color="neutralCool.600" fontWeight={500}>
            Accounts
          </Typography>
          <Typography variant="h3" fontWeight={500}>
            {countOfSelectedAccounts}/{MAX_NUMBER_OF_SELECTED_ACCOUNTS}
          </Typography>
          <Typography variant="body2" color="neutralCool.600" fontSize="0.75rem">
            Accounts selected for Vise intelligence
          </Typography>
        </Box>
      </ThemeProvider>
    </Box>
  );
}

function SelectedAccounts({
  allAccounts,
  selectedAccountIds,
  restoreAccounts,
  noNewAccountsSelected,
}: {
  allAccounts: AccountWithPIAndHouseholdInfo[];
  selectedAccountIds: string[];
  restoreAccounts: () => void;
  noNewAccountsSelected: boolean;
}) {
  const selectedAccountIdsSet = useMemo(() => new Set(selectedAccountIds), [selectedAccountIds]);
  const selectedAccounts = useMemo(() => {
    return allAccounts.filter((account) => selectedAccountIdsSet.has(account.id));
  }, [allAccounts, selectedAccountIdsSet]);

  const groupedByHousehold = useMemo(() => {
    return groupBy(
      selectedAccounts,
      (account) => account.householdInfo?.name || 'No Household Info'
    );
  }, [selectedAccounts]);

  return (
    <Box py={3} display="flex" gap={3} flexDirection="column">
      <Box display="flex" alignItems="center" gap={2} justifyContent="space-between">
        <Typography variant="h3">Selected Accounts</Typography>

        {!noNewAccountsSelected && (
          <Button variant="text" color="secondary" onClick={restoreAccounts}>
            Restore
          </Button>
        )}
      </Box>
      <Divider />
      <Box>
        {/* empty */}
        {selectedAccounts.length === 0 && (
          <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
            <img src={empty} alt="" width={120} />
            <Typography variant="body2" color="textSecondary" textAlign="center">
              No accounts have been selected yet. Use the table to the left to select accounts to
              enable for Vise Intelligence.
            </Typography>
          </Box>
        )}

        {selectedAccounts.length > 0 && (
          <Box overflow="scroll" height="550px">
            {Object.keys(groupedByHousehold).map((householdName) => (
              <Box key={householdName} mb={2}>
                <Typography variant="body2" fontWeight="500" mb={1}>
                  {householdName}
                </Typography>
                <Box display="flex" flexDirection="column" gap={1}>
                  {groupedByHousehold[householdName].map((account) => (
                    <Typography key={account.id} variant="body2" color="grey.500">
                      {account.accountName}
                    </Typography>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

function AccountSelector({
  accounts,
  selectedAccountIds,
  setSelectedAccountIds,
}: {
  accounts: AccountWithPIAndHouseholdInfo[];
  selectedAccountIds: string[];
  setSelectedAccountIds: (ids: string[]) => void;
}) {
  const enqueueToast = useEnqueueToast();
  const accountsTableColumns: GridColDef[] = useMemo(
    () =>
      [
        {
          headerName: 'Account',
          field: 'account',
          renderCell: AccountCell,
          width: 232,
          getApplyQuickFilterFn: (query: string) => {
            return (params) =>
              params.value.accountName.toLowerCase().includes(query.toLowerCase()) ||
              params.value.accountNumber.toLowerCase().includes(query.toLowerCase());
          },
          sortComparator: (v1, v2) => v1.accountName.localeCompare(v2.accountName),
        },
        {
          headerName: 'Household Name',
          valueGetter: ({ row }) => row.account,
          renderCell: HouseholdCell,
          field: 'householdName',
          width: 232,
          sortComparator: (v1, v2) => v1.firstName.localeCompare(v2.firstName),
          getApplyQuickFilterFn: (query: string) => {
            return (params) => {
              const name =
                params.row.account.firstName.toLowerCase() +
                params.row.account.lastName.toLowerCase();
              const householdName = params.row.account.householdInfo?.name?.toLowerCase();
              return (
                name.includes(query.toLowerCase()) || householdName?.includes(query.toLowerCase())
              );
            };
          },
        },

        {
          headerName: 'Value',
          field: 'value',
          renderCell: (props) => (
            <ValueCell value={formatCurrency(props.value)} isLoading={false} />
          ),
          flex: 1,
        },
        {
          headerName: 'Status',
          renderCell: (props) =>
            renderAccountStatus({
              value: {
                status: props.row.account.status,
                statusReason: props.row.account.statusReason,
              },
            }),
          flex: 1,
          field: 'status',
        },
      ] as GridColDef[],
    []
  );

  const tableData: AccountSelectorTableData[] = useMemo(() => {
    if (!accounts) {
      return [];
    }
    const result = accounts.map(
      (account) =>
        ({
          account,
          value: account.cachedAum || 0,
          id: account.id,
        } as AccountSelectorTableData)
    );

    return result;
  }, [accounts]);

  return (
    <>
      <Box display="flex" alignItems="center" gap={5} p={3}>
        <Box flex={1} display="flex" flexDirection="column" gap={1}>
          <Typography variant="h3">All managed accounts</Typography>
          <Typography variant="body1">
            This is a list of all of your managed accounts on Vise. You can search and filter by
            account name and household.
          </Typography>
        </Box>
      </Box>

      {accounts && accounts.length === 0 ? (
        <EmptyState
          height={720}
          title="There are no accounts on the platform"
          body="Please contact clientservice@vise.com to add accounts to the platform."
          showCTA={false}
        />
      ) : (
        <Box
          height="550px"
          sx={{
            '& .MuiDataGrid-root': {
              border: 0,
              borderTop: '1px solid #CFD0D9',
              borderRadius: 0,
            },
          }}
        >
          <DataGridPro
            rows={tableData}
            columns={accountsTableColumns}
            checkboxSelection
            getRowId={(row) => row.id}
            onRowSelectionModelChange={(selectionModel) => {
              if (selectionModel.length > MAX_NUMBER_OF_SELECTED_ACCOUNTS) {
                enqueueToast({
                  title: 'You have reached the maximum number of selected accounts.',
                  severity: 'error',
                });
                return;
              }
              setSelectedAccountIds(selectionModel as string[]);
            }}
            rowSelectionModel={selectedAccountIds}
            slots={{
              toolbar: () => (
                <Box my={1} mx={2}>
                  <GridToolbarQuickFilter debounceMs={300} />
                </Box>
              ),
            }}
            rowHeight={72}
            sx={{
              '& .MuiDataGrid-cell': {
                paddingRight: 1,
                paddingLeft: 2,
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: 'white',
                paddingLeft: 2,
              },
              '& .MuiDataGrid-columnHeaderCheckbox': {
                padding: 0,
              },
              '& .MuiDataGrid-cellCheckbox': {
                padding: 0,
              },
            }}
          />
        </Box>
      )}
    </>
  );
}
