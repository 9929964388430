import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Box, CardContent, TextFieldProps } from '@mui/material';
import TextField from '~/synth/TextField';
import ViseLogoBlack from '~/static/images/vise-logo-black.svg';

export const PageContainer = styled.div`
  background-color: #fff;
  height: 100vh;
`;

export const LoginContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
  width: 100%;
`;

export function LoginPage({ children }: { children: React.ReactNode }) {
  return (
    <LoginContainer>
      <Box my={10} height="52px" display="flex" alignItems="center">
        <img alt="Vise" height="32" src={ViseLogoBlack} width="32" />
      </Box>
      {children}
    </LoginContainer>
  );
}

export const StyledCardContent = styled(CardContent)`
  padding: ${({ theme }) => theme.spacing(5)};
  &:last-child {
    padding-bottom: ${({ theme }) => theme.spacing(5)};
  }
`;

export const LoginTextInput = forwardRef<
  HTMLInputElement,
  {
    IconComponent: React.FunctionComponent<{
      width?: string | number;
      height?: string | number;
      color?: string;
    }>;
    textFieldProps: TextFieldProps;
  }
>(({ IconComponent, textFieldProps }, ref) => {
  const loginTextInputStyles = {
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '4px',
      borderColor: 'grey.300',
    },
    '& .MuiInputBase-input': {
      lineHeight: '20px',
      height: 'auto',
      py: '11px',
      // Extends the input far enough left to make room for the mail icon. We do this so the autofill color matches
      paddingLeft: '39px',
    },
  };
  return (
    <Box position="relative">
      <TextField
        ref={ref}
        InputProps={{
          sx: loginTextInputStyles,
        }}
        fullWidth
        required
        {...textFieldProps}
      />
      <Box position="absolute" bottom="9px" left="13px">
        <IconComponent width="16px" height="16px" color="#131313" />
      </Box>
    </Box>
  );
});
