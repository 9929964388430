import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import ViseLogoBlack1 from '~/static/images/vise-logo-black.svg';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '~/synth/LoadingSpinner';
import { CONTAINER_WIDTH } from './constants';
import ExitCreateFlowModal from './ExitCreateFlowModal';

export default function Container({
  children,
  footer,
  headerBarContent,
  getExitConfirmation,
  fullHeight,
  isLoading,
  templateType,
  title,
  exitUrlOverride,
}: {
  children?: React.ReactNode;
  footer?: React.ReactNode;
  headerBarContent?: { left?: React.ReactNode; right?: React.ReactNode };
  getExitConfirmation?: boolean;
  fullHeight?: boolean;
  isLoading?: boolean;
  templateType?: 'allocations' | 'restrictions';
  title?: string;
  exitUrlOverride?: string;
}) {
  const theme = useTheme();
  const history = useHistory();
  let exitUrl = 'secure/strategy-center/bulk-edit';
  if (templateType) {
    exitUrl = `/secure/strategy-center?templateType=${templateType}`;
  } else if (exitUrlOverride) {
    exitUrl = exitUrlOverride;
  }
  const [exitModalOpen, setExitModalOpen] = useState<boolean>(false);
  const onExit = getExitConfirmation ? () => setExitModalOpen(true) : () => history.push(exitUrl);

  return (
    <Box position="relative" height="100vh">
      <Box
        px="120px"
        bgcolor="white"
        height="64px"
        borderBottom={`solid 1px ${theme.palette.grey[300]}`}
        width="100%"
        position="absolute"
        top={0}
      >
        <Box
          maxWidth={CONTAINER_WIDTH}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="64px"
          margin="auto"
        >
          <Box mr={1.5}>
            <img height="16px" src={ViseLogoBlack1} alt="" />
          </Box>
          <Typography variant="h4">{title ?? 'Strategy Center'}</Typography>
          <Box flex={1} />
          <IconButton aria-label="Exit" onClick={onExit} size="large">
            <XIcon color={theme.palette.grey[800]} height={16} width={16} />
          </IconButton>
        </Box>
      </Box>
      {headerBarContent && (
        <Box
          bgcolor="white"
          width="100%"
          borderBottom={`solid 1px ${theme.palette.grey[300]}`}
          position="absolute"
          top="64px"
          height="45px"
          px="120px"
        >
          <Box
            maxWidth={CONTAINER_WIDTH}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="44px"
            margin="auto"
          >
            {headerBarContent.left}
            <Box flex={1} />
            {headerBarContent.right}
          </Box>
        </Box>
      )}
      <Box
        pt={fullHeight ? 0 : 3}
        bgcolor={theme.palette.grey[100]}
        pb={fullHeight ? 0 : 3}
        position="absolute"
        top={`${(headerBarContent ? 45 : 0) + 64}px`}
        bottom="96px"
        overflow="auto"
        width="100%"
        id="strategyCenterWorkflowContent"
        px="120px"
      >
        {isLoading ? (
          <Box height="100%">
            <LoadingSpinner />
          </Box>
        ) : (
          <Box maxWidth={CONTAINER_WIDTH} margin="auto">
            <Box display="flex">
              <ExitCreateFlowModal
                open={exitModalOpen}
                onClose={() => setExitModalOpen(false)}
                onConfirm={() => history.push(exitUrl)}
              />
              {children}
            </Box>
          </Box>
        )}
      </Box>
      {footer && (
        <Box
          borderTop={`solid 1px ${theme.palette.grey[300]}`}
          display="flex"
          alignItems="center"
          height="96px"
          width="100%"
          position="absolute"
          bottom={0}
          px="120px"
        >
          <Box maxWidth={CONTAINER_WIDTH} width="100%" margin="auto">
            {footer}
          </Box>
        </Box>
      )}
    </Box>
  );
}
