import * as emailValidator from 'email-validator';
import React, { useState } from 'react';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Link,
  FormControlLabel,
  Checkbox,
  Typography,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import viseLogoBlack from '~/static/images/vise-logo-black.svg';
import paperAirplanesImage from '~/static/images/onboarding/paper-airplanes.svg';
import { ReactComponent as MailIcon } from '~/static/images/icons/mail-outline.svg';
import { ReactComponent as LockIcon } from '~/static/images/icons/lock-closed-outline.svg';
import TextField from '~/synth/TextField';
import { register } from '~/api/register';
import TestimonialsSidebar from './components/TestimonialsSidebar';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  acceptedTerms: boolean;
}

const RegistrationForm = ({
  onSubmit,
  initialValues,
}: {
  onSubmit: (values: FormValues) => void;
  initialValues: FormValues;
}) => {
  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    const REQUIRED = 'Required';

    if (!values.email) {
      errors.email = REQUIRED;
    } else if (!emailValidator.validate(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = REQUIRED;
    } else if (values.password.length < 8) {
      errors.password = 'Password must be at least 8 characters long';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = REQUIRED;
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Passwords do not match';
    }
    return errors;
  };

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ isSubmitting, touched, errors, isValid, values }) => (
        <Form>
          <Box my={2}>
            <Field name="email">
              {({ field }) => (
                <TextField
                  {...field}
                  name="email"
                  label="Email"
                  variant="outlined"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  disabled={initialValues.email !== ''}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
          </Box>
          <Box my={2}>
            <Field name="password">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Password"
                  variant="outlined"
                  type="password"
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
          </Box>
          <Box my={2}>
            <Field name="confirmPassword">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Confirm Password"
                  variant="outlined"
                  type="password"
                  error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                  fullWidth
                  margin="normal"
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
          </Box>
          <Box my={2}>
            <Field name="acceptedTerms">
              {({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label={
                    <Typography>
                      {' '}
                      I acknowledge that I have read and understand the Vise{' '}
                      <Link href="https://vise.com/termsofuse/" target="_blank" rel="noopener">
                        Terms of Use
                      </Link>
                      ,{' '}
                      <Link
                        href="https://try.vise.com/privacynotice"
                        target="_blank"
                        rel="noopener"
                      >
                        Privacy Policy
                      </Link>
                      ,{' '}
                      <Link href="https://try.vise.com/formadv" target="_blank" rel="noopener">
                        ADV Brochure
                      </Link>
                      , and{' '}
                      <Link href="https://try.vise.com/formcrs" target="_blank" rel="noopener">
                        Form CRS
                      </Link>
                      .
                    </Typography>
                  }
                />
              )}
            </Field>
          </Box>
          <LoadingButton
            sx={{ width: '100%' }}
            variant="contained"
            type="submit"
            color="primary"
            loading={isSubmitting}
            disabled={!isValid || !values.acceptedTerms}
          >
            Register
          </LoadingButton>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Already have an account?{' '}
              <Link href="/login" sx={{ ml: 0.5 }}>
                Login
              </Link>
            </Typography>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default function Registration() {
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const email = queryParams.get('email') || '';
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const formInitialValues: FormValues = {
    email,
    password: '',
    confirmPassword: '',
    acceptedTerms: false,
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      await register(values);
      setRegistrationSuccess(true);
    } catch (err) {
      let errorMessages = ['An error occurred. Please try again later.'];

      const validationErrors = (err as { errors: { msg: string }[] }).errors;
      if (validationErrors) {
        errorMessages = validationErrors.map(({ msg }) => msg);
      }

      setErrorMessages(errorMessages);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Create your account</title>
      </Helmet>
      <Container
        maxWidth={false}
        sx={{
          padding: '0 !important',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Grid container sx={{ width: '100%', height: '100%' }}>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              mt: { xs: 1, sm: 4, xl: 8 },
              mb: { xs: 1 },
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                p: 3,
                width: { xs: '100%', sm: '400px' },
                overflowX: 'hidden',
              }}
            >
              <img src={viseLogoBlack} alt="logo" height="32" style={{ marginBottom: 80 }} />
              <Card elevation={0} sx={{ overflow: 'visible' }}>
                <CardContent
                  sx={{
                    px: '2px',
                    py: 0,
                    '&:last-child': {
                      paddingBottom: 0,
                    },
                  }}
                >
                  {registrationSuccess ? (
                    <>
                      <Box pb={3.5}>
                        <img src={paperAirplanesImage} alt="Paper airplanes" />
                      </Box>
                      <Box pb={2.7}>
                        <Typography variant="h1">Confirm your email</Typography>
                      </Box>
                      <Box>
                        <Typography paragraph variant="body2" color="text.secondary">
                          You are just a few steps away from completing your registration. Please
                          check your email and click on the link we sent you.
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box pb={2.7}>
                        <Typography variant="h1">Get started!</Typography>
                      </Box>
                      <Box pb={0.5}>
                        {errorMessages.length > 0 ? (
                          <Alert severity="error">
                            <ul style={{ margin: 0, padding: 0 }}>
                              {errorMessages.map((message) => (
                                <li key={message}>{message}</li>
                              ))}
                            </ul>
                          </Alert>
                        ) : (
                          <Typography paragraph variant="body2" color="text.secondary">
                            We are excited to have you join our platform, in which you&apos;ll
                            leverage the wide range of tools and strategies that will help you grow
                            your business and meet your clients&apos; needs.
                          </Typography>
                        )}
                      </Box>
                      <RegistrationForm
                        onSubmit={handleFormSubmit}
                        initialValues={formInitialValues}
                      />
                    </>
                  )}
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <TestimonialsSidebar variant="STRATEGY_CENTER" />
        </Grid>
      </Container>
    </div>
  );
}
