import { Box, Card, Typography, useTheme, Link, Collapse } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardHeader from '~/synth/CardHeader';
import { ReactComponent as InsightsBulbIcon } from '~/static/images/icons/insights-bulb.svg';
import { ReactComponent as ArrowRightIcon } from '~/static/images/icons/arrow-right.svg';
import { ReactComponent as ChevronDown } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '~/static/images/icons/chevron-up.svg';
import { ReactComponent as TimeIcon } from '~/static/images/icons/time.svg';
import { ReactComponent as XCircle } from '~/static/images/icons/x-circle.svg';
import { Account } from 'vise-types/portfolio';
import { Link as RouterDOMLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { PortfolioInsight, PortfolioInsightsResponse } from 'vise-types/pce2';
import UnstyledButtonBase from '~/synth/UnstyledButton';
import CardContent from '~/synth/CardContent';
import scrollToTop from '~/utils/scrollToTop';
import Skeleton from '~/synth/Skeleton';
import amplitude from '~/utils/amplitude';
import { EVENT_CATEGORIES } from '~/constants/amplitude';

const UnstyledButton = styled(UnstyledButtonBase)`
  width: 100%;
`;

export type UnavailabilityReason = 'REBALANCER_PAUSED' | 'TRANSITION_MODE' | 'UNKNOWN';

function SubInsights({
  subInsights,
}: {
  subInsights: PortfolioInsight[];
  showSubInsights?: boolean;
}) {
  const theme = useTheme();
  return (
    <Box pt={2} mt={2} mb={4} borderTop={`1px dashed ${theme.palette.grey[200]}`}>
      {subInsights.map((insight) => (
        <Box mb={2} key={insight.insightText}>
          <Typography variant="body2">
            <Box lineHeight="24px">{insight.insightText}</Box>
          </Typography>
          {insight.subInsights && insight.subInsights.length > 0 ? (
            <Box color="grey.500">
              <Box my={0.5} component="ul">
                {insight.subInsights.map((subInsight) => (
                  <li key={subInsight.insightText}>
                    <Box my={0.5}>
                      <Typography variant="body2">
                        <Box lineHeight="24px">{subInsight.insightText}</Box>
                      </Typography>
                    </Box>
                  </li>
                ))}
              </Box>
            </Box>
          ) : null}
        </Box>
      ))}
    </Box>
  );
}

function InsightsRow({
  title,
  border = true,
  insight,
  showSubInsights,
  startsOpen = false,
}: {
  title: string;
  border?: boolean;
  insight: PortfolioInsight;
  showSubInsights?: boolean;
  startsOpen?: boolean;
}) {
  const theme = useTheme();
  const [insightsOpen, setInsightsOpen] = useState<boolean>(startsOpen && !!showSubInsights);

  const row = (
    <Box display="flex" alignItems="center">
      <Box flex={1}>
        <Box mb={1}>
          <Typography variant="h5" color="textSecondary">
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography variant={showSubInsights ? 'h4' : 'body2'}>{insight.insightText}</Typography>
        </Box>
      </Box>
      {insight.subInsights && insight.subInsights.length > 0 && showSubInsights && (
        <Box>
          {insightsOpen ? (
            <ChevronUp width="20" height="20" />
          ) : (
            <ChevronDown width="20" height="20" />
          )}
        </Box>
      )}
    </Box>
  );

  const allowExpand = insight.subInsights && insight.subInsights.length > 0 && showSubInsights;

  return (
    <Box
      borderBottom={border ? `1px solid ${theme.palette.grey[200]}` : undefined}
      px={3}
      pt={2.5}
      pb={insightsOpen ? 0 : 2.5}
    >
      {allowExpand ? (
        <UnstyledButton
          onClick={() => {
            setInsightsOpen(!insightsOpen);
            amplitude().logEvent(`${!insightsOpen ? 'Open' : 'Close'} ${title} insights`, {
              category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
            });
          }}
        >
          {row}
        </UnstyledButton>
      ) : (
        row
      )}
      {insight.subInsights && (
        <Collapse in={insightsOpen}>
          <SubInsights subInsights={insight.subInsights} />
        </Collapse>
      )}
    </Box>
  );
}

type OpenInsightsQueryParam = 'assetAllocation' | 'sectorAllocation' | 'subAssetAllocation';

export function ViewDetailsLink({
  account,
  openInsights,
}: {
  account: Account;
  openInsights?: OpenInsightsQueryParam[];
}) {
  return (
    <Link
      to={`/secure/accounts/${account.id}/portfolio/insights?openInsights=${(
        openInsights || []
      ).join(',')}`}
      component={RouterDOMLink}
      onClick={() => {
        scrollToTop();
        amplitude().logEvent(`View insight details for: ${openInsights?.join(', ') ?? 'overall'}`, {
          category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
        });
      }}
    >
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <Typography variant="h4" component="span">
            View details
          </Typography>
        </Box>
        <ArrowRightIcon width={15} />
      </Box>
    </Link>
  );
}

export default function PortfolioInsights({
  insights,
  link,
  showSubInsights,
  unavailabilityReason,
  name,
}: {
  insights?: PortfolioInsightsResponse;
  link?: React.ReactNode;
  showSubInsights?: boolean;
  unavailabilityReason?: UnavailabilityReason;
  name: string;
}) {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const openInsightsParam = query.get('openInsights') || '';
  const openInsights: OpenInsightsQueryParam[] = openInsightsParam.split(
    ','
  ) as OpenInsightsQueryParam[];

  useEffect(() => {
    amplitude().logEvent(`Impression - Portfolio insights tab`, {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
  }, []);

  const theme = useTheme();

  let unavailabilityIcon = <TimeIcon color={theme.palette.grey[800]} width="15px" height="15px" />;
  let unavailabilityTitle = 'Available soon';
  let unavailabilityText = `Account is currently transitioning toward target. Check back later for ${name}'s latest insights.`;
  if (unavailabilityReason === 'REBALANCER_PAUSED') {
    unavailabilityIcon = <XCircle color={theme.palette.grey[800]} width="15px" height="15px" />;
    unavailabilityText = `Enable automatic rebalancing to see ${name}'s latest insights.`;
    unavailabilityTitle = 'Unavailable';
  } else if (unavailabilityReason === 'UNKNOWN') {
    // Don't show anything, have the parent component alert the user appropriately
    return null;
  }

  let body = (
    <CardContent height={showSubInsights ? 350 : undefined}>
      <Skeleton height="3em" />
    </CardContent>
  );
  if (unavailabilityReason) {
    body = (
      <CardContent>
        <Box textAlign="center" py={showSubInsights ? 14 : 1.5}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {unavailabilityIcon}
            <Box ml="14px">
              <Typography variant="h4">
                <Box component="span" lineHeight={theme.spacing(3)}>
                  {unavailabilityTitle}
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box mt={1.5} color={theme.palette.grey[400]}>
            <Typography variant="body1">{unavailabilityText}</Typography>
          </Box>
        </Box>
      </CardContent>
    );
  } else if (insights) {
    body = (
      <>
        <InsightsRow
          showSubInsights={showSubInsights}
          title="Asset allocation"
          insight={insights.assetAllocation}
          startsOpen={openInsights.includes('assetAllocation')}
        />
        <InsightsRow
          showSubInsights={showSubInsights}
          title="Sub-asset classes"
          insight={insights.subAssetAllocation}
          startsOpen={openInsights.includes('subAssetAllocation')}
        />
        <InsightsRow
          showSubInsights={showSubInsights}
          title="Geographic distribution"
          insight={insights.geographicAllocation}
        />
        <InsightsRow
          showSubInsights={showSubInsights}
          title="Sector allocation"
          insight={insights.sectors}
          startsOpen={openInsights.includes('sectorAllocation')}
        />
        <InsightsRow
          showSubInsights={showSubInsights}
          border={false}
          title="Single-security strategy"
          insight={insights.singleStock}
        />
      </>
    );
  }

  return (
    <Card>
      <CardHeader>
        <Box display="flex" alignItems="center">
          <Box mr={1.5} display="flex" alignItems="center">
            <InsightsBulbIcon height={24} width={24} />
          </Box>
          <Box flex={1}>
            <Typography variant="h4">Portfolio insights</Typography>
          </Box>
          {insights || unavailabilityReason ? link : null}
        </Box>
      </CardHeader>
      {body}
    </Card>
  );
}
