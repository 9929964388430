import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps } from '@mui/material';
import React from 'react';
import { PortfolioIntelligenceCommon } from 'vise-types/pce1';
import useDeleteRenamePCE2Proposal from '~/hooks/usePCE2Proposal';
import DialogTitle from '~/synth/DialogTitle';

type Props = {
  onDelete?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel: () => void;
  proposal: Pick<PortfolioIntelligenceCommon, 'accountId' | 'clientId' | 'id'>;
} & DialogProps;

const ProposalDeleteConfirmationModal = (props: Props) => {
  const { onCancel, onDelete, proposal, ...rest } = props;
  const { deleteProposal, deletePending } = useDeleteRenamePCE2Proposal();

  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await deleteProposal(proposal);
    onDelete?.(e);
  };

  return (
    <Dialog onClose={onCancel} {...rest}>
      <DialogTitle onClose={onCancel}>Delete Proposal</DialogTitle>
      <DialogContent dividers>
        <Box py={3}>Are you sure you want to delete this proposal?</Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="outlined" onClick={handleDelete} disabled={deletePending}>
          Yes, delete proposal
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          No, go back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProposalDeleteConfirmationModal;
