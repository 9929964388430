import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import useCategoricalDataVisColors from '~/hooks/useCategoricalDataVisColors';
import usePortfolioMetrics from '~/hooks/usePortfolioMetrics';
import CardHeader from '~/synth/CardHeader';
import ChartColorSquare from '../../../components/chart/ChartColorSquare';
import AllocationChart from '../../../components/portfolio/AllocationChart2';
import { formatCurrency, formatPercent } from '../../../utils/format';
import {
  adjustSectorAllocationByOther,
  transformPCE2SectorAllocations,
} from '../../Portfolio/portfolioUtil';
import GPTCardHeader from './GPTCardHeader';
import LoadingCard from './LoadingCard';
import CardDisclosures from './CardDisclosures';
import useAccountWithLogging from './useAccountWithLogging';

export default function GPTSectorAllocationChart({ accountId }: { accountId?: string }) {
  const categoricalChartColors = useCategoricalDataVisColors();
  const { data: pce2Metrics } = usePortfolioMetrics(accountId);
  const { data: accountResponse } = useAccountWithLogging({
    accountId,
    includeSummary: true,
    callerName: 'GPTSectorAllocationChart',
  });
  const account = accountResponse?.data;

  // TODO(mkiisa): used for locally testing, will remove later
  // const targetPortfolio = pce2Metrics.strategyMetrics?.targetPortfolio.sectorAllocations || [];

  const sectorAllocation = useMemo(() => {
    const alloc = pce2Metrics
      ? transformPCE2SectorAllocations(pce2Metrics.portfolioMetrics.sectorAllocations)
      : [];

    const adjustedAlloc = adjustSectorAllocationByOther(alloc);
    return adjustedAlloc.sort((a, b) => (a.y < b.y ? 1 : -1));
  }, [pce2Metrics]);

  if (!account || !pce2Metrics) {
    return <LoadingCard contentHeight="510px" />;
  }

  return (
    <Card style={{ overflow: 'visible' }}>
      <GPTCardHeader
        title="Sector Allocation"
        buttonPathname={`/secure/accounts/${accountId}/portfolio/overview`}
        buttonCopy="View account"
        account={account}
      />

      <Grid container data-testid="allocations" p={3} height="510px">
        <Grid item sm={4} xs={12} display="flex" alignItems="center" justifyContent="center">
          <AllocationChart data={sectorAllocation} height={300} />
        </Grid>
        <Grid item sm={1}>
          <Box display="flex" justifyContent="center" height="100%" mr={2}>
            <Divider orientation="vertical" />
          </Box>
        </Grid>
        <Grid item sm={7} xs={12}>
          <Card>
            <CardHeader>
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap={2}>
                <Typography variant="h4">Total Portfolio Value</Typography>
                <Divider orientation="vertical" flexItem />
                <Typography variant="body2">{formatCurrency(account.cachedAum)}</Typography>
              </Box>
            </CardHeader>

            <Box mx={2}>
              <Box height="400px" overflow="scroll" px={1}>
                {sectorAllocation.map((data, idx) => {
                  return (
                    <>
                      <Divider />
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        my={1.5}
                      >
                        <Box display="flex" alignItems="center">
                          <ChartColorSquare
                            color={categoricalChartColors[idx % categoricalChartColors.length]}
                          />
                          <Typography mr={3} variant="body2">
                            {data.name}
                          </Typography>
                        </Box>

                        <Typography variant="body2">{formatPercent(data.y / 100, 1)}</Typography>
                      </Box>
                    </>
                  );
                })}
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Divider />
      <CardDisclosures />
    </Card>
  );
}
