import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Link as MuiLink,
  Typography,
} from '@mui/material';

// eslint-disable-next-line no-restricted-imports
import { withStyles } from 'tss-react/mui';

import React from 'react';
import { Link } from 'react-router-dom';
import { styled as newStyled } from '@mui/material/styles';
import styled, { css } from 'styled-components';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { ReactComponent as ArrowLeftIcon } from '~/static/images/icons/arrow-left.svg';
import InteractiveContainerBase from '~/synth/InteractiveContainerBase';
import amplitude from '~/utils/amplitude';

export const ActionFooter = newStyled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  borderColor: theme.palette.grey[300],
  color: theme.palette.grey[300],
  display: 'flex',
  height: '77px',
  marginTop: theme.spacing(4),
}));

export const BackButton: React.FunctionComponent<React.PropsWithChildren<ButtonProps>> = (
  props
) => (
  <Button {...props} startIcon={<ArrowLeftIcon />} variant="text" color="secondary">
    Back
  </Button>
);

export const PC2Container = newStyled(Container)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    paddingLeft: '70px',
    paddingRight: '70px',
  },
}));

export function logEditButtonClick(section: string) {
  amplitude().logEvent('Edit input from panel', {
    category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    section,
  });
}

export const EditButton: React.FunctionComponent<React.PropsWithChildren<ButtonProps<Link>>> = (
  props
) => {
  // <a> elements don't have a `disabled` attribute so render the "button" as a true
  // <button disabled> when `props.disabled` is true to produce valid HTML.
  const linkProps = props.disabled ? { component: Button } : { component: Link };
  return (
    <MuiLink {...props} {...linkProps} color="primary" variant="h4">
      Edit
    </MuiLink>
  );
};

export const ExplainerText: React.FunctionComponent<React.PropsWithChildren<BoxProps>> = (
  props
) => {
  return (
    <Box {...props} color="grey.500">
      <Typography variant="body2">{props.children}</Typography>
    </Box>
  );
};

export const Card = styled(InteractiveContainerBase)`
  ${({ active, theme }) =>
    active
      ? css`
          border-color: ${theme.palette.grey[800]};
        `
      : ''}

  ${({ selectable, theme }) =>
    selectable
      ? css`
          &.disabled {
            color: ${theme.palette.grey[400]};
          }

          &:not(.disabled) {
            cursor: pointer;
          }
        `
      : ''}
`;

export const CardSection = styled(Box).attrs({ p: 2 })`
  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-top-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }

  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }
`;

export const CardTextTitleAndDescription = ({
  title,
  children,
}: {
  title: React.ReactNode;
  children: string;
}) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography gutterBottom variant="h2">
        {title}
      </Typography>
      <ExplainerText mt={1}>{children}</ExplainerText>
    </Box>
  );
};

export const ContentBox = styled(Box).attrs(({ maxWidth = '640px' }: BoxProps) => ({
  maxWidth,
  py: 4,
}))``;

export const QuestionnaireHeader = ({
  qNumber,
  numberOfQuestions = 6,
}: {
  qNumber: number;
  numberOfQuestions?: number;
}) => (
  <>
    <Box mb={3}>
      <Typography variant="h1">Set your client&apos;s restrictions.</Typography>
    </Box>
    <Box mb={3}>
      <Typography color="textSecondary" variant="h5">
        QUESTION {qNumber} OF {numberOfQuestions}
      </Typography>
    </Box>
  </>
);

function PC2ContainerXL(props: ContainerProps) {
  return <PC2Container {...props} maxWidth="xl" />;
}

const StickyBottomActionContainerBase = withStyles(PC2ContainerXL, (theme) => ({
  root: {
    boxShadow: theme.shadows[6],
    bottom: 0,
    position: 'sticky',
  },
}));

const StickyBottomActionContainer = withStyles(StickyBottomActionContainerBase, (theme) => ({
  root: {
    backgroundColor: 'white',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    maxWidth: 'none',
  },
}));

const ActionBarV2 = withStyles(StickyBottomActionContainerBase, (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    maxWidth: 'none',
  },
}));

export { StickyBottomActionContainer, ActionBarV2 };
