import {
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  Collapse,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { Country } from 'vise-types/pce2_instrument';
import { RestrictionsTemplate } from 'vise-types/template';

// eslint-disable-next-line no-restricted-imports
import { withStyles } from 'tss-react/mui';

import { ReactComponent as ChevronDown } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '~/static/images/icons/chevron-up.svg';
import { ReactComponent as TrashIcon } from '~/static/images/icons/trash.svg';

import {
  ExcludedCountries,
  ExcludedEsgAreas,
  ExcludedSectors,
  RestrictedStocks,
} from '../components/SummarySections';

export type RestrictionsTemplateContent = Pick<
  RestrictionsTemplate,
  'name' | 'tickers' | 'countries' | 'subSectors' | 'sectors' | 'esgAreas'
>;

export function AdditionalRestrictionsCard({
  restrictedStocks,
  excludedSectors,
  excludedIndustries,
  excludedCountries,
  excludedEsgAreas,
}: {
  restrictedStocks: string[];
  excludedSectors: string[];
  excludedIndustries: string[];
  excludedCountries: Country[];
  excludedEsgAreas: string[];
}) {
  return (
    <Card>
      <Box mx={3} my={2}>
        {restrictedStocks.length > 0 && <RestrictedStocks restrictedStocks={restrictedStocks} />}
        {(excludedIndustries.length > 0 || excludedSectors.length > 0) && (
          <ExcludedSectors
            excludedIndustries={excludedIndustries ?? []}
            excludedSectors={excludedSectors ?? []}
          />
        )}
        {excludedCountries.length > 0 && (
          <ExcludedCountries restrictedCountries={excludedCountries} />
        )}
        {excludedEsgAreas.length > 0 && <ExcludedEsgAreas excludedEsgAreas={excludedEsgAreas} />}
      </Box>
    </Card>
  );
}

export const NoneSelectedState = () => (
  <Card>
    <Box color="grey.400" py={2} m="auto">
      <Typography variant="body2">None selected</Typography>
    </Box>
  </Card>
);

const StyledCardActionArea = withStyles(CardActionArea, (_theme, _params, classes) => ({
  root: {
    [`&:hover .${classes.focusHighlight}`]: { opacity: 0 },
  },
  focusHighlight: {},
}));

export function TemplateDetailsCard({
  restrictions,
  onRemove,
  isLast,
}: {
  restrictions: RestrictionsTemplateContent;
  onRemove: () => void;
  isLast?: boolean;
}) {
  const theme = useTheme();
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  function handleToggleOpen() {
    setIsDetailsExpanded(!isDetailsExpanded);
  }
  const { tickers, sectors, subSectors, countries, name, esgAreas } = restrictions;
  return (
    <Box mb={isLast ? 0 : 1.5}>
      <Card>
        <StyledCardActionArea onClick={handleToggleOpen}>
          <Box display="flex" justifyContent="space-between" pl={3} pr={2} py={2}>
            <Box display="flex">
              <Box data-testid="expand-restriction-templates-card">
                {isDetailsExpanded ? (
                  <ChevronUp width="20" height="20" />
                ) : (
                  <ChevronDown width="20" height="20" />
                )}
              </Box>
              <Box ml={3} my="auto">
                <Typography data-testid="restrictions-template-name" variant="h4">
                  {name}
                </Typography>
              </Box>
            </Box>
            <ButtonBase onClick={onRemove} data-testid="remove-selected-template-button">
              <TrashIcon color={theme.palette.grey[400]} height={20} width={20} />
            </ButtonBase>
          </Box>
          <Collapse in={isDetailsExpanded}>
            <Box mx={3}>
              {tickers.length > 0 && <RestrictedStocks restrictedStocks={tickers} />}
              {(subSectors.length > 0 || sectors.length > 0) && (
                <ExcludedSectors excludedIndustries={subSectors} excludedSectors={sectors} />
              )}
              {countries.length > 0 && <ExcludedCountries restrictedCountries={countries} />}
              {esgAreas?.length != null && esgAreas.length > 0 && (
                <ExcludedEsgAreas excludedEsgAreas={esgAreas} />
              )}
            </Box>
          </Collapse>
        </StyledCardActionArea>
      </Card>
    </Box>
  );
}
