import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import React from 'react';

export default function CardBox({
  children,
  title,
  ...boxProps
}: { children: React.ReactNode; title?: React.ReactNode } & BoxProps) {
  const theme = useTheme();
  return (
    <Box
      {...boxProps}
      border={1}
      borderColor={tokens.palette.neutralCool[300]}
      borderRadius={1}
      boxShadow={theme.shadows[1]}
    >
      {title && (
        <Box p={3} borderBottom={1} borderColor={tokens.palette.neutralCool[300]}>
          <Typography variant="h2">{title}</Typography>
        </Box>
      )}
      <Box p={3}>{children}</Box>
    </Box>
  );
}
