"use client";

import { Box, type BoxProps, styled } from "@mui/material";
import React from "react";
import tokens from "../tokens/index.js";

const SelectableCardContainer = styled(Box)<BoxProps>(({ theme }) => ({
  border: `solid 1px ${theme.palette.neutralCool[300]}`,
  cursor: "pointer",
  "&:hover": {
    borderColor: theme.palette.primaryBlue[600],
    boxShadow: "0px 10px 15px 0px rgba(14, 50, 133, 0.1)",
  },
  "&.SelectableCard-selected": {
    borderColor: theme.palette.primaryBlue[600],
    boxShadow: tokens.shadow.hero,
    backgroundColor: theme.palette.primaryBlue[100],
  },
  borderRadius: "4px",
}));

export default function SelectableCard(
  props: BoxProps & { selected: boolean },
) {
  const { selected, ...boxProps } = props;
  return (
    <SelectableCardContainer
      role="button"
      aria-pressed={selected}
      className={selected ? "SelectableCard-selected" : ""}
      {...boxProps}
    >
      {props.children}
    </SelectableCardContainer>
  );
}
