import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
// eslint-disable-next-line no-restricted-imports

export interface TextHighlightTagProps extends BoxProps {
  children: React.ReactNode;
  severity?: 'default' | 'priority' | 'success' | 'error' | 'warning' | 'info' | 'alert';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verticalAlign?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fontWeight?: any;
  hasBorder?: boolean;
}

export const TextHighlightTag = React.forwardRef<unknown, TextHighlightTagProps>(
  ({ children, severity = 'default', verticalAlign, fontWeight, hasBorder, ...restProps }, ref) => {
    let color = 'grey.600';
    let bgcolor = 'grey.200';
    switch (severity) {
      case 'info':
      case 'default':
        break;
      case 'priority':
        bgcolor = 'blue.100';
        color = 'blue.600';
        break;
      case 'success':
        bgcolor = 'success.100';
        color = 'success.400';
        break;
      case 'error':
        bgcolor = 'error.100';
        color = 'error.400';
        break;
      case 'warning':
        bgcolor = 'warning.100';
        color = 'warning.400';
        break;
      case 'alert':
        bgcolor = 'accents.lavender.light';
        color = 'purple.400';
        break;
      default:
        break;
    }

    return (
      <Box
        borderRadius="20px"
        lineHeight={0}
        bgcolor={bgcolor}
        border={hasBorder ? `1px solid` : ''}
        borderColor={hasBorder ? color : ''}
        display="inline-block"
        py={0.35}
        px={1}
        color={color}
        // Box is missing the type definition for a ref. But passing
        // a ref in actually works as intended, hence the @ts-ignore:
        ref={ref}
        {...restProps}
        style={{ verticalAlign }}
      >
        <Typography variant="h5">
          <Box fontWeight={fontWeight} display="flex" alignItems="center">
            {children}
          </Box>
        </Typography>
      </Box>
    );
  }
);

export const Tag = ({
  children,
  verticalAlign,
  ...restProps
}: { children: React.ReactNode; verticalAlign?: string } & BoxProps) => (
  <Box
    borderRadius="100px"
    bgcolor="blue.200"
    color="blue.600"
    display="inline-block"
    textAlign="center"
    px={1.5}
    py={1}
    {...restProps}
    style={{ verticalAlign }}
  >
    <Typography variant="h5" component="div">
      {children}
    </Typography>
  </Box>
);
