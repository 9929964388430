import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useState } from 'react';
import { updateAccount } from '~/api/api';
import useEnqueueToast from '~/hooks/useToast';
import useUnassignedAccounts from '~/hooks/useUnassignedAccounts';
import DialogTitle from '~/synth/DialogTitle';
import { maskAccountNumber } from '~/utils/format';

interface AccountOption {
  value: string;
  label: string;
  description: string;
}
interface AddAccountsModalProps {
  loadingOptions: boolean;
  accountOptions: AccountOption[];
  isSubmitting: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (accountIds: string[]) => void;
}

type SelectedAccountOptions = AccountOption[] | null;

export const AddAccountsModal = ({
  onClose,
  isOpen,
  isSubmitting,
  loadingOptions,
  onSubmit,
  accountOptions,
}: AddAccountsModalProps) => {
  const [selectedAccountOptions, setSelectedAccountOptions] = useState<SelectedAccountOptions>([]);

  const onCloseWithReset = () => {
    onClose();
    setSelectedAccountOptions([]);
  };

  const handleSubmit = () => {
    const accountIds = (selectedAccountOptions || []).map(({ value }) => value);
    onSubmit(accountIds);
    setSelectedAccountOptions([]);
  };

  return (
    <Dialog open={isOpen} onClose={onCloseWithReset} fullWidth>
      <DialogTitle onClose={onCloseWithReset}>Add account(s)</DialogTitle>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <DialogContent>
          <FormControl fullWidth>
            <Autocomplete
              loading={loadingOptions}
              multiple
              id="accounts-search"
              options={accountOptions}
              value={selectedAccountOptions ?? []}
              onChange={(event, newValue) => {
                setSelectedAccountOptions(newValue);
              }}
              disableCloseOnSelect
              getOptionLabel={(option) => `${option.label} - ${option.description}`}
              renderOption={(props, option, { selected }) => (
                <li {...props} value={`${option.label} ${option.description}`}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.label} - {maskAccountNumber(option.description)}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Link accounts" />}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCloseWithReset}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={isSubmitting ? <CircularProgress size="1em" color="inherit" /> : undefined}
            type="submit"
            disabled={isSubmitting || isEmpty(selectedAccountOptions)}
          >
            Save changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

type AddAccountsModalContainerProps = {
  clientId: string;
  onClose: () => void;
  isOpen: boolean;
};

const AddAccountsModalContainer = ({
  clientId,
  onClose,
  isOpen,
}: AddAccountsModalContainerProps) => {
  const enqueueToast = useEnqueueToast();
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const {
    data: unassignedAccounts,
    isLoading: unassignedAccountsIsLoading,
    error: unassignedAccountsError,
  } = useUnassignedAccounts();

  const options: AccountOption[] = useMemo(
    () =>
      unassignedAccounts == null
        ? []
        : unassignedAccounts.map((account) => ({
            value: account.id,
            label: account.name,
            description: account.accountNumber,
          })),
    [unassignedAccounts]
  );

  const handleSubmit = async (accountIds: string[]) => {
    setIsSendingRequest(true);
    try {
      await Promise.all(accountIds.map((accountId) => updateAccount(accountId, clientId)));
    } catch (e) {
      enqueueToast({
        title: `Error linking ${accountIds.length > 1 ? 'accounts' : 'account'} to client`,
        severity: 'error',
      });
      return;
    } finally {
      setIsSendingRequest(false);
      onClose();
    }

    enqueueToast({
      title: `${accountIds.length > 1 ? 'Accounts' : 'Account'} linked successfully`,
      severity: 'success',
    });
  };

  if (unassignedAccountsError) {
    return (
      <Dialog fullWidth open={isOpen} onClose={onClose}>
        <DialogTitle onClose={onClose}>Add account(s)</DialogTitle>
        <DialogContent>
          There was an error fetching available accounts. Please try again later.
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <AddAccountsModal
      loadingOptions={unassignedAccountsIsLoading}
      isSubmitting={isSendingRequest}
      onSubmit={handleSubmit}
      onClose={onClose}
      isOpen={isOpen}
      accountOptions={options}
    />
  );
};

export default AddAccountsModalContainer;
