import { Stepper, createTheme, ThemeProvider, useTheme, StepperProps } from '@mui/material';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const OnboardingStepper = ({
  children,
  stepperProps,
}: {
  children: React.ReactNode;
  stepperProps: StepperProps;
}) => {
  const theme = useTheme();
  const stepperTheme = createTheme({
    components: {
      MuiStep: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            borderTop: `1px solid #CFD0D9`,
            borderBottom: `1px solid #CFD0D9`,
            borderRight: `1px solid #CFD0D9`,
            borderLeft: ownerState.active
              ? `3px solid ${theme.palette.primary.main}`
              : '1px solid #CFD0D9',
            // eslint-disable-next-line no-nested-ternary
            backgroundColor: ownerState.completed
              ? theme.palette.blue[100]
              : ownerState.active
              ? 'white'
              : 'none',
            borderColor: ownerState.active ? theme.palette.primary.main : '',
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(2),
            margin: theme.spacing(1),
            width: 300,
            boxShadow: ownerState.active ? theme.shadows[3] : '',
          }),
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            padding: 0,
            fontWeight: 500,
          },
          labelContainer: { color: theme.palette.grey[800] },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            overflow: 'visible',
            circle: {
              stroke: theme.palette.grey[800],
              strokeWidth: 1,
              fill: 'none',
            },
            text: {
              fill: theme.palette.grey[800],
            },
            '&.Mui-completed': {
              color: theme.palette.primary.main,
            },
            '&.Mui-active': {
              circle: {
                stroke: theme.palette.primary.main,
              },
              text: {
                fill: theme.palette.primary.main,
              },
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={stepperTheme}>
      <Stepper orientation="vertical" nonLinear connector={<></>} {...stepperProps}>
        {children}
      </Stepper>
    </ThemeProvider>
  );
};
