import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { createPortfolioPdf } from '~/api/api';
import { enqueueAlert, closeAlert } from './message';

/** Returns Promise<true> if succeeds; Promise<false> if fails. */
// eslint-disable-next-line import/prefer-default-export
export const getPortfolioPdf =
  (
    intelligenceId: string,
    logoImgUrl?: string
  ): ThunkAction<Promise<boolean>, unknown, unknown, AnyAction> =>
  async (dispatch): Promise<boolean> => {
    const { key } = dispatch(
      enqueueAlert({
        message: `Your PDF is being generated and will begin downloading when it's ready.`,
      })
    );
    let pdfURL: string;
    try {
      pdfURL = await createPortfolioPdf(intelligenceId, logoImgUrl);
    } catch (err) {
      dispatch(closeAlert(key));
      dispatch(
        enqueueAlert({
          message:
            "Sorry, something went wrong when creating the PDF. We've logged the error and will address it as soon as we can.",
        })
      );
      return false;
    }
    window.location.href = pdfURL;
    dispatch(closeAlert(key));
    return true;
  };
