import React from 'react';
import sampleActiveIcon from '~/static/images/PortfolioCreator2/sample-rounded-active.svg';
import sampleInactiveIcon from '~/static/images/PortfolioCreator2/sample-rounded-inactive.svg';

interface SampleIconProps {
  active: boolean;
}

export default function SampleIcon({ active }: SampleIconProps) {
  return (
    <img height={120} width={120} src={active ? sampleActiveIcon : sampleInactiveIcon} alt="" />
  );
}
