import { Box, Button, Link as MuiLink, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import UFOIllustration from '~/static/images/illustrations/404.png';
import { tokenStore } from '../../utils';

export default function NotFound() {
  const loggedIn = !!tokenStore.getAuthToken();

  return (
    <Box width="100%" height="100vh" display="flex">
      <Box m="auto" textAlign="center">
        <img src={UFOIllustration} width={340} alt="" />
        <Typography variant="h2" mt={5}>
          There&apos;s nothing here!
        </Typography>
        <Box color="grey.600" mt={2}>
          We couldn’t find the page you are looking for.
        </Box>
        <Box mb={1.5} mt={6}>
          Return to a familiar starting point.
        </Box>
        <Button
          component={Link}
          to={loggedIn ? '/secure/dashboard' : '/login'}
          variant="outlined"
          color="primary"
        >
          Go home
        </Button>
        <Box mt={5} color="grey.400">
          <Typography variant="caption">
            Our friendly team is here to help. <br /> Please reach out to{' '}
            <MuiLink href="mailto:clientservice@vise.com">clientservice@vise.com</MuiLink> for help.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
