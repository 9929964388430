import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { Position, TaxLot } from 'vise-types/portfolio';
import { InputDollar } from '~/components';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { ReactComponent as PlusIcon } from '~/static/images/icons/plus.svg';
import { ReactComponent as TrashIcon } from '~/static/images/icons/trash.svg';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import { ReactComponent as DotsHorizontal } from '~/static/images/icons/dots-horizontal.svg';
import Banner from '~/synth/Banner';
import DatePicker from '~/synth/DatePicker';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import { Tag } from '~/synth/Tag';
import TextField from '~/synth/TextField';
import amplitude from '~/utils/amplitude';
import { formatCurrency } from '~/utils/format';

export interface Transaction extends TaxLot {
  iDontKnow?: boolean;
}

const makeDrawerStyles = makeStyles()(() => ({
  paper: {
    height: 'calc(100vh - 64px)',
  },
}));

const EditTransactionListItem = ({
  transaction,
  onSave,
  onCancel,
  onDelete,
}: {
  transaction?: Transaction | null;
  onSave: (t: Transaction) => void;
  onCancel: () => void;
  onDelete: (t?: Transaction | null) => void;
}) => {
  const initialValues: Transaction = transaction || {
    purchaseDate: new Date().toDateString(),
    id: uuid(),
    costBasis: 0,
    quantity: 0,
    iDontKnow: false,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => onSave(values),
  });

  const theme = useTheme();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={1.5}>
        <Typography variant="h4">Transaction #</Typography>
        <Button
          variant="outlined"
          size="small"
          endIcon={<TrashIcon />}
          onClick={() => onDelete(transaction)}
        >
          Delete
        </Button>
      </Box>
      <Box my={3} borderBottom={`1px dashed ${theme.palette.grey[200]}`} />
      <Box mt={3}>
        <DatePicker
          maxDate={moment()}
          name="purchaseDate"
          placeholder="Select a date..."
          label="Purchase date"
          value={moment(formik.values.purchaseDate) ?? moment()}
          onChange={(value) => formik.setFieldValue('purchaseDate', value?.toISOString())}
          fullWidth
        />
      </Box>
      <Box my={2}>
        <TextField
          name="quantity"
          label="Quantity"
          placeholder="Ex. 10"
          fullWidth
          autoComplete="off"
          type="number"
          inputProps={{ min: 1 }}
          onChange={formik.handleChange}
          value={formik.values.quantity}
        />
      </Box>
      <TextField
        disabled={formik.values.iDontKnow}
        label="Total lot cost basis"
        placeholder="Ex. $100.00"
        InputProps={{ inputComponent: InputDollar }}
        value={formik.values.costBasis}
        fullWidth
        onChange={(e) => formik.setFieldValue('costBasis', e.target.value)}
        name="costBasis"
      />
      <Box mt={1.5} mb={4}>
        <Box ml={0.5}>
          <FormControlLabel
            control={
              <Checkbox
                name="iDontKnow"
                onChange={(e) => {
                  formik.handleChange(e);
                  amplitude().logEvent(
                    `${e.target.checked ? 'Check' : 'Uncheck'} 'I don't know.' checkbox`,
                    {
                      category: EVENT_CATEGORIES.DISTRIBUTIONS,
                    }
                  );
                }}
                value={formik.values.iDontKnow}
              />
            }
            label="I don't know."
          />
        </Box>
        {formik.values.iDontKnow && (
          <Box mt={1}>
            <Banner
              size="small"
              borderColor="blue.500"
              message={
                <Box display="flex">
                  <Box mr={1} color="primary.main">
                    <InformationCircleIcon width={20} height={20} />
                  </Box>
                  <Box color="grey.700">
                    We recommend making an educated guess about cost basis if possible. Please note
                    that selecting “I don’t know” will reduce the overall accuracy of the proposal,
                    especially if this position has embedded gains.
                  </Box>
                </Box>
              }
            />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="end">
        <Button size="small" variant="outlined" onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Box ml={1}>
          <Button size="small" type="submit" variant="contained" color="primary">
            Save Transaction
          </Button>
        </Box>
      </Box>
    </form>
  );
};

// read only display transaction data
const TransactionListItem = ({
  transaction,
  index,
  onDelete,
  onEdit,
}: {
  transaction: Transaction;
  onDelete: (t: Transaction) => void;
  onEdit: (t: Transaction) => void;
  index: number;
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item sm={6} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
        <Typography variant="h4">Transaction #{index + 1}</Typography>
      </Grid>
      <Grid item sm={6} alignContent="center" alignItems="center">
        <Box display="flex" justifyContent="right">
          <DropdownButtonMenu isIcon buttonContent={<DotsHorizontal />}>
            {(closeMenu) => [
              <DropdownButtonMenuItem
                key="edit"
                onClick={() => {
                  onEdit(transaction);
                  closeMenu();
                }}
              >
                Edit
              </DropdownButtonMenuItem>,
              <DropdownButtonMenuItem
                key="delete"
                onClick={() => {
                  onDelete(transaction);
                  closeMenu();
                }}
              >
                Delete
              </DropdownButtonMenuItem>,
            ]}
          </DropdownButtonMenu>
        </Box>
      </Grid>
      <Grid item sm={6}>
        <Typography color="textSecondary" variant="body1">
          Purchase date
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography color="textSecondary" variant="body1" align="right">
          {new Date(transaction.purchaseDate).toDateString()}
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography color="textSecondary" variant="body1">
          Quantity
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography color="textSecondary" variant="body1" align="right">
          {transaction.quantity}
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography color="textSecondary" variant="body1">
          Cost basis
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography color="textSecondary" variant="body1" align="right">
          {transaction.iDontKnow ? `$0 / I don't know` : `${formatCurrency(transaction.costBasis)}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default function EditHoldingDetailsPanel({
  position,
  open,
  onClose,
  onChange,
  isLoading,
}: {
  position: Position;
  open: boolean;
  onClose: () => void;
  onChange: (t: Position) => void;
  isLoading: boolean;
}) {
  const { classes: paperClasses } = makeDrawerStyles();
  const [localTransactions, setLocalTransactions] = useState<TaxLot[]>(() => {
    if (!position?.taxLots) return [];
    return [...position.taxLots].map((p) => ({ ...p, id: uuid() }));
  });
  const [editTransaction, setEditTransacation] = useState<Transaction | 'new' | null>(null); // null = create a new tax lot in edit mode

  const [newQuantity, setNewQuantity] = useState<number | null>(null);

  const handleAddAnotherTransaction = () => {
    amplitude().logEvent('Action - Add another transaction', {
      category: EVENT_CATEGORIES.DISTRIBUTIONS,
    });
    setEditTransacation('new');
  };

  const handleDelete = (t?: Transaction | null) => {
    amplitude().logEvent('Action - Delete a transaction', {
      category: EVENT_CATEGORIES.DISTRIBUTIONS,
    });
    if (!t) return;
    const transactionToDeleteIndex = localTransactions.findIndex(
      (localTransaction) => localTransaction.id === t.id
    );
    const result = [...localTransactions];
    result.splice(transactionToDeleteIndex, 1);
    setLocalTransactions(result);
  };

  const handleSave = (transaction: Transaction) => {
    amplitude().logEvent('Action - Save a transaction', {
      category: EVENT_CATEGORIES.DISTRIBUTIONS,
    });
    const isEditingExisting = Boolean(localTransactions.find((t) => t.id === transaction.id));
    setEditTransacation(null);
    if (isEditingExisting) {
      const targetTransaction = localTransactions.find((t) => t.id === transaction.id);
      if (!targetTransaction) return;
      Object.assign(targetTransaction, transaction);
      setLocalTransactions([...localTransactions]);
    } else {
      setLocalTransactions([...localTransactions, transaction]);
    }
  };

  const totalLots = localTransactions.reduce((acc, curr) => acc + curr.quantity, 0);
  let totalLotsError: 'incomplete' | 'exceeds' | null = null;
  if (totalLots > (newQuantity ?? position.quantity)) {
    totalLotsError = 'exceeds';
  } else if (totalLots < (newQuantity ?? position.quantity)) {
    totalLotsError = 'incomplete';
  }

  const isCash = position.securityType === 'CASH_OR_EQIV';
  const quantity = isCash ? position.amount : position.quantity;

  return (
    <Drawer open={open} onClose={onClose} anchor="right" classes={{ paper: paperClasses.paper }}>
      <div>
        <Box width={500}>
          <Box
            display="flex"
            zIndex={10}
            width={500}
            justifyContent="space-between"
            px={3}
            alignItems="center"
            position="sticky"
            top={0}
            height={72}
            boxShadow={8}
            bgcolor="white"
          >
            <Typography variant="h2">Edit holding details</Typography>
            <IconButton
              aria-label="close edit holding details drawer"
              onClick={onClose}
              size="large"
            >
              <XIcon fontSize={24} />
            </IconButton>
          </Box>
          <Box m={3} pb={3}>
            <Box>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <TextField label="Ticker" value={position.symbolOrCusip} disabled />
                <TextField
                  type="number"
                  label="Quantity"
                  value={newQuantity ?? quantity}
                  onChange={(e) => setNewQuantity(parseInt(e.target.value, 10))}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  label="Asset class"
                  value={position.assetType ?? position.securityType}
                  disabled
                />
                <TextField label="Value" value={formatCurrency(position.marketValue)} disabled />
              </Box>
            </Box>
            {!isCash && (
              <>
                <Box my={3}>
                  <Divider />
                </Box>
                <Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography variant="h3">Tax lots</Typography>
                    <Tag ml={1}>Optional</Tag>
                  </Box>
                  <Box color="grey.500" mb={2}>
                    <Typography variant="body1">
                      If you&apos;re unsure about an exact value, make an educated guess. Note that
                      the sum of lot quantities must equal the total number of shares.
                    </Typography>
                  </Box>
                  {editTransaction ? (
                    <EditTransactionListItem
                      transaction={editTransaction !== 'new' ? editTransaction : null}
                      onSave={handleSave}
                      onDelete={handleDelete}
                      onCancel={() => setEditTransacation(null)}
                    />
                  ) : (
                    localTransactions.map((transaction, index) => (
                      <>
                        <TransactionListItem
                          key={transaction.id}
                          index={index}
                          transaction={transaction}
                          onDelete={handleDelete}
                          onEdit={() => setEditTransacation(transaction)}
                        />
                        <Box mt={2.5} mb={1}>
                          <Divider />
                        </Box>
                      </>
                    ))
                  )}
                  {!editTransaction && (
                    <Button
                      color="secondary"
                      onClick={handleAddAnotherTransaction}
                      fullWidth
                      variant="outlined"
                      size="small"
                      startIcon={<PlusIcon />}
                    >
                      Add another transaction
                    </Button>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </div>
      <Box
        position="fixed"
        bottom={0}
        py={2.5}
        px={3}
        display="flex"
        width={500}
        boxShadow={9}
        bgcolor="white"
        justifyContent="end"
      >
        {totalLotsError === 'exceeds' && !isCash && (
          <Tooltip title="Sum of lot quantities exceeds the total number of shares. Adjust the total quantity of shares or the lot quantities to continue.">
            <Box mr="auto">
              <Tag bgcolor="error.100" color="error.400">
                Total quantity exceeded
              </Tag>
            </Box>
          </Tooltip>
        )}
        {totalLotsError === 'incomplete' && !isCash && (
          <Tooltip title="Sum of lot quantities must equal the total number of shares. Adjust the total quantity of shares or the lot quantities to continue.">
            <Box mr="auto">
              <Tag alignSelf="center" bgcolor="warning.100" color="warning.400">
                Tax lots incomplete
              </Tag>
            </Box>
          </Tooltip>
        )}
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            amplitude().logEvent('Action - Tap Cancel edit holding button', {
              category: EVENT_CATEGORIES.DISTRIBUTIONS,
            });
            onClose();
          }}
        >
          Cancel
        </Button>
        <Box ml={1.5}>
          <Button
            variant="contained"
            color="primary"
            disabled={(Boolean(totalLotsError) || isLoading) && !isCash}
            startIcon={isLoading ? <CircularProgress size="1em" color="inherit" /> : <></>}
            onClick={() => {
              amplitude().logEvent('Action - Tap Save edit holding button', {
                category: EVENT_CATEGORIES.DISTRIBUTIONS,
              });
              onChange({
                ...position,
                quantity: newQuantity ?? position.quantity,
                taxLots: localTransactions,
              });
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
