import React from 'react';
import {
  BondInstrumentType,
  BondPortfolioCreditQuality,
  BondPortfolioCreditQualityRating,
  BondPortfolioDuration,
  BondPortfolioMuniMaturity,
  BondPortfolioTreasuryMaturity,
  BondPortfolioMetrics,
  BondPortfolioSector,
  BondPortfolioSettings,
  BondPortfolioSettingsMunicipal,
  BondPortfolioStructure,
  BondMuniUse,
  BondPortfolioHolding,
} from 'vise-types/portfolio';
import { formatCurrency, formatPercent, formatQuantity } from '~/utils/format';
import { mapStateValueToLabel } from '../BondPortfolioCreator/states';

export function metricsContent(
  metrics: BondPortfolioMetrics,
  holdings: BondPortfolioHolding[]
): { field: React.ReactNode; value: React.ReactNode }[] {
  return [
    { field: 'Number of bonds', value: holdings.length },
    { field: 'Yield to Worst', value: formatPercent(metrics.yieldToWorst, 2 /* numDigits */) },
    { field: 'Yield to Worst', value: formatCurrency(metrics.yieldToWorstDollar) },
    { field: 'Average coupon', value: formatPercent(metrics.coupon) },
    { field: 'Duration (years)', value: formatQuantity(metrics.oad) },
    {
      field: 'Average maturity (years)',
      value: formatQuantity(metrics.avgFinalEffectiveMaturity),
    },
    { field: 'Average bond price', value: formatCurrency(metrics.avgBondPrice) },
  ];
}

export const INSTRUMENT_TO_LABEL: { [key in BondInstrumentType]: string } = {
  MUNICIPAL: 'Municipal bonds',
  TREASURY: 'Treasury bonds',
};

export const CREDIT_QUALITY_TO_LABEL: { [key in BondPortfolioCreditQuality]: string } = {
  INPUT_AA_MINUS: 'AA- or better',
  INPUT_A: 'A or better',
};

export const DURATION_OR_MATURITY_TO_LABEL: {
  [key in
    | BondPortfolioDuration
    | BondPortfolioMuniMaturity
    | BondPortfolioTreasuryMaturity]: string;
} = {
  SHORT: 'Short',
  INTERMEDIATE: 'Intermediate',
  LONG: 'Long',

  ZERO_TO_TWO: '0-2 years',
  ONE_TO_FIVE: '1-5 years',
  ONE_TO_TEN: '1-10 years',
  ONE_TO_FIFTEEN: '1-15 years',
};

export const STRUCTURE_TO_LABEL: { [key in BondPortfolioStructure]: string } = {
  HIGH_QUALITY: 'Target duration',
  LADDER: 'Ladder',
};

export const SECTOR_TO_LABEL: { [key in BondPortfolioSector]: string } = {
  AIRPORT: 'Airport',
  ASSESSMENT_DISTRICT: 'Assessment district',
  CASH: 'Cash',
  EDUCATION_PRIVATE: 'Education (Private)',
  EDUCATION_PUBLIC: 'Education (Public)',
  ELECTRIC_UTILITY: 'Electric utility',
  GUARANTEED: 'Guaranteed',
  HEALTHCARE: 'Healthcare',
  HIGHER_ED_PRIVATE: 'Higher education (Private)',
  HIGHER_ED_PUBLIC: 'Higher education (Public)',
  HOUSING: 'Housing',
  INDUSTRIAL_DEVELOPMENT: 'Industrial development',
  LOCAL_GENERAL_OBLIGATION: 'Local general obligation',
  LOCAL_LEASE: 'Local lease',
  PORT: 'Port',
  PREPAY_ENERGY: 'Prepay energy',
  PREREFUNDED: 'Prerefunded',
  REVENUE_MISCELLANEOUS: 'Revenue (Misc.)',
  SENIOR_LIVING: 'Senior living',
  SPECIAL_TAX: 'Special tax',
  STATE_GENERAL_OBLIGATION: 'State general obligation',
  STATE_LEASE: 'State lease',
  TOLL_ROADS_TRANSIT: 'Toll roads/transit',
  WATER_SEWER: 'Water/sewer',
  UNKNOWN_SECTOR: 'Unknown',
};

export const MUNI_USE_TO_LABEL: { [key in BondMuniUse]: string } = {
  SEWER: 'Sewer',
  WATER_AND_SEWER: 'Water & sewer',
  OTHER_UTILITIES: 'Other utilities',
  GENERAL_PURPOSE_PUBLIC_IMPT: 'General purpose public improvement',
  WATER: 'Water',
  STREETS_HIGHWAYS: 'Steets & highways',
  PARKS_ZOOS_BEACHES: 'Parks/zoos/beaches',
  CORRECTION_FACILITIES_COURTS: 'Correction facilities/courts',
  CIVIC_CONVENTION_CENTERS: 'Civic/convention centers',
  TOLL_ROAD: 'Toll road',
  GAS: 'Gas',
  HOSPITALS: 'Hospitals',
  COMBINED_UTILITIES: 'Combined utilities',
  ELECTRIC_PUBLIC_POWER: 'Electric & public power',
  GOVERNMENT_PUBLIC_BUILDINGS: 'Government/public buildings',
  STADIUMS_SPORTS_COMPLEX: 'Stadiums/sports complex',
  SOLID_WASTE_RES_RECOVERY: 'Solid waste/res recovery',
  STUDENT_HOUSING: 'Student housing',
  MULTI_FAMILY_HOUSING: 'Multi-family housing',
  SINGLE_FAMILY_HOUSING: 'Single-family housing',
  MASS_RAPID_TRANSIT: 'Mass/rapid transit',
  POLLUTION_CONTROL: 'Polluion control',
  LIBRARIES_OR_MUSUEMS: 'Libraries or musuems',
  OTHER_RECREATION: 'Other recreation',
  SEAPORTS_MARINE_TERMINALS: 'Seaports/marine terminals',
  PRIMARY_SECONDARY_EDUCATION: 'Primary/secondary education',
  PUBLIC_HIGHER_EDUCATION: 'Public higher education',
  PRIVATE_HIGHER_EDUCATION: 'Private higher education',
  OTHER_EDUCATION: 'Other education',
  HIGHER_EDUCATION: 'Higher education',
  FLOOD_CONTROL_STORM_DRAIN: 'Flood control/storm drain',
  AIRPORTS: 'Airports',
  REDEVELOPMENT_LAND_CLEARANCE: 'Redevelopment/land clearance',
  OTHER_HEALTH_CARE: 'Other healthcare',
  CHARTER_SCHOOL: 'Charter school',
  FIRE_STATION_EQUIPMENT: 'Fire station/equipment',
  ECONOMIC_DEVELOPMENT: 'Economic development',
  INDUSTRIAL_DEVELOPMENT: 'Industrial development',
  TELEPHONE: 'Telephone',
  SANITATION: 'Sanitation',
  COGENERATION: 'Cogeneration',
  STUDENT_LOANS: 'Student loans',
  HOTEL: 'Hotel',
  PRIVATE_HUMAN_SERVICE_PROVIDER: 'Private human service provider',
  BRIDGES: 'Bridges',
  IRRIGATION: 'Irrigation',
  PARKING_FACILITIES: 'Parking facilities',
  POLICE_STATION_EQUIPMENT: 'Police station/equipment',
  OTHER_TRANSPORTATION: 'Other transportation',
  UNSECURED_AIRLINES: 'Unsecured airlines',
  THEATERS: 'Theaters',
  OFFICE_BUILDING_LIMITED_PARTNERSHIPS: 'Office building/limited partnerships',
  LIFECARE_RETIREMENT_CENTERS: 'Lifecare/retirement centers',
  CMO_BACKED_HOUSING: 'CMO-backed housing',
  LAND_PRESERVATION: 'Land preservation',
  VETERANS: 'Veterans',
  TUNNELS: 'Tunnels',
  SENIOR_HOUSING_INDEPENDENT_LIVING: 'Senior independent living',
  PENSION_OBLIGATION: 'Pension obligation',
  NURSING_HOMES: 'Nursing homes',
  MALLS_SHOPPING_CENTERS: 'Malls/shopping centers',
  PUBLIC_HUMAN_SERVICE_PROVIDER: 'Public human service provider',

  UNSPECIFIED_MUNI_USE: 'Unknown',
};

export const CREDIT_RATING_TO_LABEL: { [key in BondPortfolioCreditQualityRating]: string } = {
  AAA: 'AAA',
  AA_PLUS: 'AA+',
  AA: 'AA',
  AA_MINUS: 'AA-',
  A_PLUS: 'A+',
  A: 'A',
  BBB: 'BBB',
  BB: 'BB',
  B: 'B',
  CCC: 'CCC',
  CC: 'CC',
  C: 'C',
  D: 'D',
  NOT_RATED: 'Not rated',
  UNKNOWN: 'Unknown',
};

export function taxesContent(
  settings: BondPortfolioSettingsMunicipal
): { field: React.ReactNode; value: React.ReactNode }[] {
  return [
    {
      field: 'Estimated federal rate',
      value: formatPercent(settings.fedTaxRate, 1),
    },
    {
      field: 'Estimated state rate',
      value: formatPercent(settings.stateTaxRate, 1),
    },
    { field: 'Subject to AMT', value: settings.subjectToAmt ? 'Yes' : 'No' },
  ];
}

export function buildContent(
  settings: BondPortfolioSettings
): { field: React.ReactNode; value: React.ReactNode }[] {
  switch (settings.instrumentType) {
    case 'MUNICIPAL':
      return [
        { field: 'Product type', value: 'Municipal Fixed Income' },
        { field: 'State strategy', value: mapStateValueToLabel[settings.stateStrategy] },
        { field: 'Structure', value: settings.duration ? 'Target duration' : 'Ladder' },
        {
          field: `${settings.maturity ? 'Maturity ' : 'Duration'} structure`,
          value: DURATION_OR_MATURITY_TO_LABEL[settings.maturity ?? settings.duration],
        },
        { field: 'Credit quality', value: CREDIT_QUALITY_TO_LABEL[settings.creditQuality] },
        {
          field: 'Tax loss harvesting',
          value: settings.activeTaxManagement ? 'Active' : 'Inactive',
        },
      ];

    case 'TREASURY':
      return [
        { field: 'Product type', value: 'Treasury' },
        { field: 'Maturity structure', value: DURATION_OR_MATURITY_TO_LABEL[settings.maturity] },
      ];
    default:
      // @ts-ignore
      throw new Error(`invalid Fixed Income instrument type '${settings.instrumentType}'`);
  }
}
