import { CreatePortfolioIntelligenceData } from 'vise-types/pce1';
import { createPortfolioIntelligence } from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { FETCH_PROPOSAL_ERROR_TYPE_TO_MESSAGE } from '~/routes/PortfolioCreator2/Constants';
import axios, { AxiosError } from 'axios';
import amplitude from '../utils/amplitude';
import { useEnqueueCoachmark } from './useCoachmark';

export default function useInsertIntelligence() {
  const enqueueCoachmark = useEnqueueCoachmark();

  const insertIntelligence = async (
    preparedPortfolioIntelligenceData: CreatePortfolioIntelligenceData,
    showCoachmark = true
  ) => {
    try {
      return await createPortfolioIntelligence(preparedPortfolioIntelligenceData);
    } catch (err) {
      let coachmarkContent: string | undefined;
      if (axios.isAxiosError(err)) {
        const dataErrors: { param: string; msg: string }[] =
          (err as AxiosError<{ errors: { param: string; msg: string }[] }>).response?.data
            ?.errors || [];
        coachmarkContent = `Details: ${dataErrors
          .map((dataErr) => `${dataErr.param} - ${dataErr.msg}`)
          .join(', ')}`;
      } else {
        coachmarkContent = FETCH_PROPOSAL_ERROR_TYPE_TO_MESSAGE.UNKNOWN.content;
      }
      if (showCoachmark) {
        enqueueCoachmark({
          title: FETCH_PROPOSAL_ERROR_TYPE_TO_MESSAGE.UNKNOWN.title,
          content: coachmarkContent,
          severity: 'error',
        });
      }
      amplitude().logEvent('Summary error', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        errorMessage: FETCH_PROPOSAL_ERROR_TYPE_TO_MESSAGE.UNKNOWN.title,
      });
      return null;
    }
  };
  return { insertIntelligence };
}
