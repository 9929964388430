import { Box, Button, SxProps } from '@mui/material';
import { ArrowLeft } from '@mui/x-date-pickers/internals/components/icons';
import { tokens } from '@vise_inc/ds-vise';
import React, { useReducer, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Route, useHistory } from 'react-router';
import { Switch } from 'react-router-dom';
import { Loader } from '~/components';
import { State, initialState, reducer } from '~/routes/BondPortfolioCreator/BondPortfolioState';
import Build from '~/routes/BondPortfolioCreator/Build';
import Taxes from '~/routes/BondPortfolioCreator/Taxes';
import { steps } from '~/routes/BondPortfolioCreator/steps';
import Stepper from '~/synth/Stepper';
import { PCHeader, StyledHeaderPC2Container } from '../PortfolioCreator2/PortfolioCreator2';
import ClientDetails from './ClientDetails';
import Summary from './Summary';

const BondPortfolioCreatorStepper = ({ state, sx }: { state: State; sx: SxProps }) => {
  const history = useHistory();
  const step = steps(state).find((step) => step.route === history.location.pathname);
  return (
    <Box sx={sx} borderBottom={1} borderColor="grey.200">
      <Stepper value={step?.route || ''}>
        {steps(state).map((s) => (
          <Stepper.Step key={s.route} value={s.route}>
            {s.label}
          </Stepper.Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default function BondPortfolioCreator() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const summaryPanelPortalRef = useRef<HTMLDivElement>(null);

  const Footer = (children: React.ReactNode) => (
    <Box
      zIndex={10}
      borderTop={1}
      borderColor={tokens.palette.neutralCool[200]}
      bgcolor="white"
      position="fixed"
      sx={{ bottom: 0, left: 0, right: 0 }}
      display="flex"
      justifyContent="center"
      py={3}
    >
      <StyledHeaderPC2Container
        maxWidth="xl"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          variant="text"
          startIcon={<ArrowLeft />}
          color="secondary"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
        {children}
      </StyledHeaderPC2Container>
    </Box>
  );

  if (state.isLoading) {
    return <Loader showFixedIncomeMessages />;
  }

  return (
    <>
      <Helmet>
        <title>Portfolio Creator</title>
      </Helmet>
      <PCHeader onBack={() => history.push('/secure/households')} />
      <Box display="flex" flexDirection="column" position="relative">
        <BondPortfolioCreatorStepper
          state={state}
          sx={{ paddingLeft: 8, bgcolor: 'white', boxShadow: '4' }}
        />
        <Box display="flex">
          <StyledHeaderPC2Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 16 }}>
            <Box display="flex" justifyContent="space-between">
              <Box width={640}>
                <Switch>
                  <Route path="/secure/bond-portfolio-creator/select-client">
                    <ClientDetails
                      state={state}
                      dispatch={dispatch}
                      footer={Footer}
                      summaryPanelRef={summaryPanelPortalRef}
                    />
                  </Route>
                  <Route path="/secure/bond-portfolio-creator/taxes">
                    <Taxes
                      state={state}
                      dispatch={dispatch}
                      footer={Footer}
                      summaryPanelRef={summaryPanelPortalRef}
                    />
                  </Route>
                  <Route path="/secure/bond-portfolio-creator/portfolio-build">
                    <Build
                      state={state}
                      dispatch={dispatch}
                      footer={Footer}
                      summaryPanelRef={summaryPanelPortalRef}
                    />
                  </Route>
                  <Route path="/secure/bond-portfolio-creator/summary">
                    <Summary state={state} dispatch={dispatch} footer={Footer} />
                  </Route>
                </Switch>
              </Box>
            </Box>
          </StyledHeaderPC2Container>
          <Box width={379} pb={5} borderLeft={1} borderColor={tokens.palette.neutralCool[300]}>
            <div ref={summaryPanelPortalRef} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
