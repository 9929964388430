import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

export function Step({
  children,
  variant,
}: {
  children: string;
  variant?: 'TITLE' | 'DISABLED' | 'ACTIVE';
}) {
  const theme = useTheme();
  let body = <Typography variant="body1">{children}</Typography>;
  if (variant === 'TITLE') {
    body = <Typography variant="h5">{children}</Typography>;
  } else if (variant === 'DISABLED') {
    body = (
      <Typography variant="body1">
        <Box component="span" color={theme.palette.grey[400]}>
          {children}
        </Box>
      </Typography>
    );
  }
  const bgColor = variant === 'ACTIVE' ? theme.palette.blue[100] : undefined;
  return (
    <Box px={3} py={2} borderBottom={`solid 1px ${theme.palette.grey[200]}`} bgcolor={bgColor}>
      {body}
    </Box>
  );
}

export default function Stepper({ children }: { children: React.ReactNode[] | React.ReactNode }) {
  const theme = useTheme();
  return (
    <Box
      bgcolor="white"
      border={`solid 1px ${theme.palette.grey[300]}`}
      borderRadius="4px"
      display="inline-block"
      position="sticky"
      width="100%"
      top={0}
    >
      {children}
    </Box>
  );
}
