import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import Toast, { ToastProps } from '~/synth/Toast';

export default function useEnqueueToast() {
  const { enqueueSnackbar } = useSnackbar();
  const enqueueToast = useCallback(
    ({ title, content, action, ...props }: ToastProps) => {
      enqueueSnackbar(title, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 5000,
        content: () => <Toast title={title} content={content} action={action} {...props} />,
      });
    },
    [enqueueSnackbar]
  );
  return enqueueToast;
}
