import { DevTool } from '@hookform/devtools';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { tokens } from '@vise_inc/ds-vise';
import { intersection, isEmpty } from 'lodash';
import * as process from 'process';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import {
  BondPortfolioCreditQuality,
  BondPortfolioCreditQualityAllocation,
  BondPortfolioDuration,
  BondPortfolioHolding,
  BondPortfolioMuniMaturity,
  BondPortfolioTreasuryMaturity,
  BondPortfolioMetrics,
  BondPortfolioStateStrategy,
  HighQualityBondPortfolioSampleOption,
  LadderBondPortfolioSampleOption,
  BondPortfolioStructure,
  type BondPortfolioMuniUseAllocation,
} from 'vise-types/portfolio';
import { getBondPortfolioSampleData } from '~/api/api';
import useBondPortfolioModelOptions from '~/hooks/useBondPortfolioModelOptions';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import BondPortfolioCreditRatingChart, {
  ChartLegend as CreditChartLegend,
} from '~/routes/BondPortfolio/BondPortfolioCreditRatingChart';
import BondPortfolioSectorChart, {
  ChartLegend as SectorChartLegend,
} from '~/routes/BondPortfolio/BondPortfolioSectorChart';
import {
  CREDIT_QUALITY_TO_LABEL,
  DURATION_OR_MATURITY_TO_LABEL,
  INSTRUMENT_TO_LABEL,
  STRUCTURE_TO_LABEL,
  metricsContent,
} from '~/routes/BondPortfolio/Utils';
import {
  BondScreenProps,
  statesStrategyArray,
} from '~/routes/BondPortfolioCreator/BondPortfolioState';
import SummaryPanel from '~/routes/BondPortfolioCreator/SummaryPanel';
import StepTitleCard from '~/routes/BondPortfolioCreator/components/StepTitleCard';
import { mapStateValueToLabel } from '~/routes/BondPortfolioCreator/states';
import { ReactComponent as CalculatorIcon } from '~/static/images/icons/calculator.svg';
import { InfoIcon } from '~/synth/Icons';
import Skeleton from '~/synth/Skeleton';
import TextField from '~/synth/TextField';
import BondPortfolioMaturityChart from '../BondPortfolio/BondPortfolioMaturityChart';
import CardBox from '../BondPortfolio/CardBox';
import { routeUrls } from './steps';

/* eslint-disable no-nested-ternary */

const durationToOrder = {
  ONE_TO_FIVE: 0,
  ONE_TO_TEN: 1,
  ONE_TO_FIFTEEN: 2,
  SHORT: 3,
  INTERMEDIATE: 4,
  LONG: 5,
} as const;

const qualityToOrder = {
  INPUT_A: 0,
  INPUT_AA_MINUS: 1,
} as const;

const TREASURY_THRESHOLD = 250_000;
const MUNIS_THRESHOLD = 125_000;

export default function Build({ state, dispatch, footer, summaryPanelRef }: BondScreenProps) {
  useEffect(() => window.scroll(0, 0), []);
  const { data: featureFlags } = useFeatureFlags();
  const history = useHistory();

  const { control, handleSubmit, formState, watch, resetField, getValues } = useForm({
    defaultValues: {
      ...state.buildInputs,
    },
  });

  const { isValid, isDirty, isSubmitted } = formState;

  const instrumentType = watch('bondSector');
  const structure = watch('structure');
  const watchStateStrategy = watch('stateStrategy');

  const [muniUses, setMuniUses] = useState<BondPortfolioMuniUseAllocation>();
  const [metrics, setMetrics] = useState<BondPortfolioMetrics>();
  const [ratings, setRatings] = useState<BondPortfolioCreditQualityAllocation>();
  const [holdings, setHoldings] = useState<BondPortfolioHolding[]>();

  const [isCalculating, setIsCalculating] = useState(false);
  const isCalculated = Boolean(muniUses && metrics && ratings);

  const treasuryAvailable =
    featureFlags?.enable_treasuries === 'on' &&
    // Linked account with enough AUM
    ((state.account?.cachedAum && state.account.cachedAum > TREASURY_THRESHOLD) ||
      // Sample proposal with enough AUM
      (state.cashValue && state.cashValue > TREASURY_THRESHOLD));

  const { data: modelOptionsAxiosResponse } = useBondPortfolioModelOptions();

  let availableModelOptions = modelOptionsAxiosResponse?.data;

  if (availableModelOptions == null) {
    return (
      <>
        <StepTitleCard
          title="Let's design your portfolio."
          description="Configure your bond portfolio to reflect your client's preferences."
        />
        <Card sx={{ mb: 8 }}>
          <CardContent>
            <Skeleton height="25em" />
          </CardContent>
        </Card>
      </>
    );
  }

  if (isEmpty(availableModelOptions)) {
    return <div>error</div>; // TBD how do we handle that?
  }

  availableModelOptions = availableModelOptions.filter((item) => {
    return (
      (watchStateStrategy ? item.productState === watchStateStrategy : true) &&
      (structure
        ? // High quality selected so limit to target duration portfolios
          (structure === 'HIGH_QUALITY' && 'duration' in item) ||
          // Ladder selected so limit to portfolio with a maturity range
          (structure === 'LADDER' && 'maturity' in item)
        : true)
    );
  });

  const availableProductTypes = new Set(
    availableModelOptions.map((s) =>
      'maturity' in s ? 'LADDER' : 'duration' in s ? 'HIGH_QUALITY' : undefined
    )
  );
  const availableStates = new Set(availableModelOptions.map((s) => s.productState));
  const availableDurationLadder = new Set(
    availableModelOptions
      .filter((s): s is LadderBondPortfolioSampleOption => 'maturity' in s && s.maturity != null)
      .map((s) => s.maturity)
  );
  const availableDurationHighQuality = new Set(
    availableModelOptions
      .filter(
        (s): s is HighQualityBondPortfolioSampleOption => 'duration' in s && s.duration != null
      )
      .map((s) => s.duration)
  );
  const structureOptions: (BondPortfolioStructure | '')[] =
    instrumentType === 'MUNICIPAL'
      ? [
          ...(Array.from(availableProductTypes).includes('HIGH_QUALITY')
            ? (['HIGH_QUALITY'] as BondPortfolioStructure[])
            : []),
          ...(Array.from(availableProductTypes).includes('LADDER')
            ? (['LADDER'] as BondPortfolioStructure[])
            : []),
        ]
      : // Treasury portfolios only include Ladders
      instrumentType === 'TREASURY'
      ? (['LADDER'] as BondPortfolioStructure[])
      : [''];
  const availableQuality = new Set(availableModelOptions.map((s) => s.creditQuality));

  const availableDurationMenuItems = (
    d:
      | Set<BondPortfolioDuration>
      | Set<BondPortfolioMuniMaturity>
      | Set<BondPortfolioTreasuryMaturity>
  ) => Array.from(d).sort((a, b) => durationToOrder[a] - durationToOrder[b]);

  const availableDurationLadderMenuItems =
    instrumentType === 'MUNICIPAL'
      ? availableDurationMenuItems(availableDurationLadder)
      : instrumentType === 'TREASURY'
      ? ['ZERO_TO_TWO']
      : [];

  const availableDurationHighQualityMenuItems = availableDurationMenuItems(
    availableDurationHighQuality
  );

  const availableQualityMenuItems = Array.from(availableQuality).sort(
    (a, b) => qualityToOrder[a] - qualityToOrder[b]
  );

  const statesStrategyArrayFiltered = intersection(
    statesStrategyArray,
    Array.from(availableStates)
  );

  const aum = state.account?.cachedAum || state.cashValue;
  const handleCalculate = async () => {
    setIsCalculating(true);
    const values = getValues();

    if (
      values.bondSector === '' ||
      values.structure === '' ||
      (values.bondSector === 'MUNICIPAL' && values.creditQuality === '') ||
      (values.duration === '' && values.structure === 'HIGH_QUALITY') ||
      (values.maturity === '' && values.structure === 'LADDER') ||
      (values.bondSector === 'MUNICIPAL' && values.stateStrategy === '') ||
      aum == null
    ) {
      setIsCalculating(false);
      return;
    }

    const sampleData = await getBondPortfolioSampleData(
      values.bondSector === 'MUNICIPAL'
        ? {
            instrumentType: 'MUNICIPAL',
            productState: values.stateStrategy as BondPortfolioStateStrategy,
            creditQuality: values.creditQuality as BondPortfolioCreditQuality,
            ...(values.structure === 'HIGH_QUALITY'
              ? { duration: values.duration as BondPortfolioDuration }
              : values.structure === 'LADDER'
              ? { maturity: values.maturity as BondPortfolioMuniMaturity }
              : ({} as never)),
          }
        : values.bondSector === 'TREASURY'
        ? {
            instrumentType: 'TREASURY',
            maturity: values.maturity as BondPortfolioTreasuryMaturity,
          }
        : null,
      aum
    );

    dispatch({ type: 'SET_SAMPLE_DATA', value: sampleData.data });

    setMetrics(sampleData.data.metrics);
    setMuniUses(sampleData.data.muniUses);
    setRatings(sampleData.data.creditQuality);
    setHoldings(sampleData.data.holdings);

    resetField('stateStrategy', { defaultValue: getValues().stateStrategy });
    resetField('structure', { defaultValue: getValues().structure });
    resetField('activeTaxManagement', { defaultValue: getValues().activeTaxManagement });
    resetField('maturity', { defaultValue: getValues().maturity });
    resetField('duration', { defaultValue: getValues().duration });
    resetField('creditQuality', { defaultValue: getValues().creditQuality });
    resetField('bondSector', { defaultValue: getValues().bondSector });

    setIsCalculating(false);
  };

  const onSubmit = async (data) => {
    dispatch({ type: 'SET_BUILD_INPUTS', value: data });
    history.push(routeUrls.SUMMARY);
  };

  const STATE_STRATEGY_TOOLTIP = (
    <>
      <p>
        You can customize your municipal bond portfolio by selecting a state strategy. Depending on
        your selection a percentage of your client&apos;s portfolio will be invested within the
        selected state as described below:
      </p>
      <p>
        <Box component="span" fontWeight="bold">
          90% allocation:
        </Box>{' '}
        NY, CA
        <br />
        <Box component="span" fontWeight="bold">
          50% allocation:
        </Box>{' '}
        TX, MA, MD, VA, AL
        <br />
        <Box component="span" fontWeight="bold">
          25% allocation:{' '}
        </Box>
        AZ, CO, CT, GA, IN, MI, MO, NJ, NC, OH, OR, PA
        <br />
      </p>
      <p>
        <b>Why is my state not here?</b>
        <br />
        If you don&apos;t see your client&apos;s state listed it&apos;s because they may live in a
        low issuance state, a state with no income tax, or a state with credit rating concerns. We
        recommend building a national portfolio for these clients which will offer them a state
        diversified portfolio.
      </p>
    </>
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepTitleCard
          title="Let's design your portfolio."
          description="Configure your bond portfolio to reflect your client's preferences."
        />
        <Card sx={{ mb: 8 }}>
          <CardContent>
            <Grid container spacing={4} alignItems="flex-start">
              <Grid xs={6}>
                <InputLabel required>
                  Bond sector
                  {featureFlags?.enable_treasuries === 'on' && (
                    <Tooltip title="Please be advised that Municipal bond portfolios are not available for qualified accounts, and Treasury bond portfolios require a minimum account value of $250,000.">
                      <IconButton size="small" sx={{ padding: 0, paddingLeft: 0.5 }}>
                        <InfoIcon size="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputLabel>
                <Controller
                  rules={{ required: true }}
                  name="bondSector"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      onChange={(e, v) => {
                        field.onChange(v);
                        resetField('creditQuality');
                        resetField('stateStrategy');
                        resetField('duration');
                        resetField('structure');
                        resetField('maturity');
                      }}
                      options={[
                        ...(state.account?.taxable ||
                        (state?.cashValue && state.cashValue >= MUNIS_THRESHOLD)
                          ? ['MUNICIPAL']
                          : []),
                        ...(treasuryAvailable ? ['TREASURY'] : []),
                      ]}
                      renderInput={(params) => <TextField {...params} />}
                      getOptionLabel={(option) => INSTRUMENT_TO_LABEL[option] ?? ''}
                    />
                  )}
                />
              </Grid>
              {instrumentType === 'MUNICIPAL' && (
                <Grid xs={6}>
                  <InputLabel required>
                    State strategy
                    <Tooltip title={STATE_STRATEGY_TOOLTIP}>
                      <IconButton size="small" sx={{ padding: 0, paddingLeft: 0.5 }}>
                        <InfoIcon size="small" />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <Controller
                    rules={{ required: true }}
                    name="stateStrategy"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Autocomplete
                          {...field}
                          onChange={(e, v) => {
                            field.onChange(v);
                            window.scrollTo({
                              top: document.body.offsetHeight,
                              behavior: 'smooth',
                            });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          options={statesStrategyArrayFiltered}
                          getOptionLabel={(v) => mapStateValueToLabel[v] ?? ''}
                        />
                      </>
                    )}
                  />
                </Grid>
              )}
              <Grid xs={6}>
                <InputLabel required>
                  Structure
                  <Tooltip title="A ladder invests in bonds that mature continuously across a selected multi-year time horizon. Alternatively, targeted duration, is designed to provide active duration management within a target duration range based on market conditions.">
                    <IconButton size="small" sx={{ padding: 0, paddingLeft: 0.5 }}>
                      <InfoIcon size="small" />
                    </IconButton>
                  </Tooltip>
                </InputLabel>

                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      onChange={(e, v) => field.onChange(v)}
                      renderInput={(params) => <TextField {...params} />}
                      options={structureOptions}
                      getOptionLabel={(label) => STRUCTURE_TO_LABEL[label] ?? ''}
                    />
                  )}
                  name="structure"
                />
              </Grid>
              <Grid xs={6}>
                <InputLabel required>
                  {structure === 'HIGH_QUALITY' ? 'Duration' : 'Maturity'}
                </InputLabel>
                {!structure && (
                  <Autocomplete
                    disabled
                    renderInput={(params) => <TextField {...params} />}
                    options={[]}
                  />
                )}
                {structure === 'HIGH_QUALITY' && (
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        onChange={(e, v) => field.onChange(v)}
                        renderInput={(params) => <TextField {...params} />}
                        options={availableDurationHighQualityMenuItems}
                        getOptionLabel={(label) => DURATION_OR_MATURITY_TO_LABEL[label] ?? ''}
                      />
                    )}
                    name="duration"
                  />
                )}
                {structure === 'LADDER' && (
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        onChange={(e, v) => field.onChange(v)}
                        renderInput={(params) => <TextField {...params} />}
                        options={availableDurationLadderMenuItems}
                        getOptionLabel={(label) => DURATION_OR_MATURITY_TO_LABEL[label] ?? ''}
                      />
                    )}
                    name="maturity"
                  />
                )}
              </Grid>
              {instrumentType === 'MUNICIPAL' && (
                <>
                  <Grid xs={6}>
                    <InputLabel required>
                      Credit quality
                      <Tooltip title="Personalize your selection based on your client's risk tolerance, potentially attaining enhanced yields by increasing credit risk and choosing securities with ratings of A or higher rather than AA-.">
                        <IconButton size="small" sx={{ padding: 0, paddingLeft: 0.5 }}>
                          <InfoIcon size="small" />
                        </IconButton>
                      </Tooltip>
                    </InputLabel>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          getOptionLabel={(p) => CREDIT_QUALITY_TO_LABEL[p] ?? ''}
                          onChange={(e, v) => field.onChange(v)}
                          renderInput={(params) => <TextField {...params} />}
                          options={availableQualityMenuItems}
                        />
                      )}
                      name="creditQuality"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <Box display="flex" alignItems="center">
                      <Controller
                        render={({ field }) => <Checkbox {...field} defaultChecked />}
                        control={control}
                        name="activeTaxManagement"
                      />
                      <div>Automatically harvest tax losses for this account</div>
                    </Box>
                  </Grid>
                </>
              )}
              {isDirty && isCalculated && (
                <Grid xs={12}>
                  <Alert severity="error">
                    You changed the build inputs and therefore need to hit calculate, prior to being
                    able to continue on.
                  </Alert>
                </Grid>
              )}
              {!isValid && isSubmitted && (
                <Grid xs={12}>
                  <Alert severity="error">Please make sure all required fields are entered.</Alert>
                </Grid>
              )}
            </Grid>

            <Box my={4}>
              <Alert severity="info">
                In order to continue, please make sure calculations are updated.
              </Alert>
            </Box>

            <Box my={4} display="flex" justifyContent="end">
              <Button
                disabled={!isValid}
                onClick={handleCalculate}
                startIcon={<CalculatorIcon style={{ opacity: isValid ? 1 : 0.5 }} />}
                variant="outlined"
                color="secondary"
                size="small"
              >
                Calculate
              </Button>
            </Box>
            <Box my={1}>{isCalculating && <LinearProgress />}</Box>

            {metrics && holdings && (
              <>
                <Typography variant="h3" mb={3}>
                  Portfolio characteristics
                </Typography>
                <Divider sx={{ mb: 4 }} />
                <Box>
                  {metricsContent(metrics, holdings).map(({ field, value }, index) => (
                    <Box
                      py={1}
                      px={1.5}
                      bgcolor={index % 2 === 0 ? tokens.palette.neutralCool['100'] : 'initial'}
                      key={field?.toString()}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <div>{field}</div>
                      <div>{value}</div>
                    </Box>
                  ))}
                </Box>
              </>
            )}

            {instrumentType === 'MUNICIPAL' && muniUses && (
              <CardBox title="Sectors" mt={4}>
                <BondPortfolioSectorChart muniUses={muniUses} />
                <SectorChartLegend muniUses={muniUses} />
              </CardBox>
            )}

            {ratings && (
              <CardBox title="Credit quality" mt={4}>
                <BondPortfolioCreditRatingChart ratings={ratings} />
                <CreditChartLegend ratings={ratings} />
              </CardBox>
            )}

            {holdings && instrumentType === 'TREASURY' && (
              <CardBox title="Maturity distribution" mt={4}>
                <BondPortfolioMaturityChart holdings={holdings} />
              </CardBox>
            )}

            <Box sx={{ my: 4 }}>
              {footer &&
                footer(
                  <Button variant="contained" type="submit" disabled={isDirty || !isCalculated}>
                    Continue
                  </Button>
                )}
            </Box>
            {summaryPanelRef?.current &&
              createPortal(
                <SummaryPanel state={{ ...state, buildInputs: getValues() }} />,
                summaryPanelRef.current
              )}
          </CardContent>
        </Card>
      </form>
      {process.env.APP_ENV === 'local' && <DevTool control={control} />}
    </>
  );
}
