import { useState } from 'react';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { deletePCE2Proposal, renamePCE2Proposal } from '../api/api';
import useEnqueueToast from './useToast';

const useDeleteRenamePCE2Proposal = () => {
  const enqueueToast = useEnqueueToast();

  type Proposal = Pick<PortfolioIntelligenceFull, 'id' | 'accountId' | 'clientId'>;
  const [deletePending, setDeletePending] = useState(false);
  const [renamePending, setRenamePending] = useState(false);

  const deleteProposal = async (proposal: Proposal) => {
    try {
      setDeletePending(true);
      const { id, accountId, clientId } = proposal;
      await deletePCE2Proposal(id, clientId, accountId);
      setDeletePending(false);
      enqueueToast({
        title: 'Successfully deleted proposal',
        severity: 'success',
      });
      return null;
    } catch (e) {
      enqueueToast({
        title: 'Could not delete a proposal',
        severity: 'warning',
        content: 'Please reach out to clientservice@vise.com for help.',
      });
      return e;
    }
  };

  const renameProposal = async (proposal: Proposal, proposalName: string) => {
    try {
      setRenamePending(true);
      const { id, clientId, accountId } = proposal;
      await renamePCE2Proposal(id, proposalName, clientId, accountId);
      setRenamePending(false);
      enqueueToast({
        title: 'Successfully renamed proposal',
        severity: 'success',
      });
      return null;
    } catch (e) {
      enqueueToast({
        title: 'Could not rename a proposal',
        severity: 'warning',
        content: 'Please reach out to clientservice@vise.com for help.',
      });
      return e;
    }
  };

  return { deleteProposal, renameProposal, deletePending, renamePending };
};

export default useDeleteRenamePCE2Proposal;
