import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import portfolioIntelImage from '~/static/images/onboarding/portfolio-intel-screenshot.png';
import strategyCenterImage from '~/static/images/onboarding/strategy-center-screenshot.png';
import wavyLinesImage from '~/static/images/onboarding/wavy-lines-background.svg';

type BackgroundVariantName = 'STRATEGY_CENTER' | 'PORTFOLIO_INTEL' | 'DASHBOARD';

const backgroundVariantMap: { [key in BackgroundVariantName]: { color: string; image: string } } = {
  STRATEGY_CENTER: { color: 'blue.100', image: strategyCenterImage },
  PORTFOLIO_INTEL: { color: 'gold.100', image: portfolioIntelImage },
  DASHBOARD: { color: 'mint.100', image: portfolioIntelImage },
};

const testimonials = [
  {
    message:
      "It's SO fast to build a proposal. Once I adopted and began trusting in the methodology (which admittedly I was resistant to) it was a relief to not overcomplicate or feel the need to double check every position in the proposal.",
    author: 'Tiffany Soricelli, Advisor at Virtuoso Asset Management',
  },
  {
    message:
      'Over time, we’ve gained trust in how Vise operates; we now use Vise for 98% of our clients, making running the investment component of our practice so much more streamlined and efficient...',
    author: 'RIA firm of 150M+',
  },
  {
    message:
      'It doesn’t take long to see there’s a lot to this and it’s not something you can ignore.',
    author: 'Brent T Henry, Hexagon Capital Partners, LLC',
  },
];

const randomTestimonial = testimonials[Math.floor(Math.random() * testimonials.length)];

export default function TestimonialsSidebar(props: { variant: BackgroundVariantName }) {
  const { variant } = props;

  return (
    <Grid
      item
      xs={12}
      md={5}
      sx={{
        display: {
          xs: 'none',
          md: 'block',
        },
        backgroundImage: `url(${backgroundVariantMap[variant].image}), url(${wavyLinesImage})`,
        backgroundColor: backgroundVariantMap[variant].color,
        backgroundPosition: {
          sm: 'left 58px bottom -250px, center 0px',
          lg: 'left 58px bottom -150px, center 0px',
        },
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundSize: '800px',
      }}
    >
      <Grid
        item
        sx={{
          py: 4,
          pl: 7,
          mt: { sm: 6, xl: 10 },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ mb: 6, pr: 5 }}>
          {/* TODO: Randomize multiple testimonials */}
          <Typography variant="h2" sx={{ mb: 2 }}>
            &quot;{randomTestimonial.message}&quot;
          </Typography>
          <Typography variant="body1" fontSize={16} color="grey.600">
            – {randomTestimonial.author}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
