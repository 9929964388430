import { Box, Button, Card, Container, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { removeAccountFromVise } from '~/api/api';
import useAccountState from '~/hooks/useAccountState';
import useAllAccountsWithPIAndHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import useEnqueueToast from '~/hooks/useToast';
import CardContent from '~/synth/CardContent';
import CardHeader from '~/synth/CardHeader';
import ContainedSelectorCheckbox from '~/synth/ContainedSelectorCheckbox';

type ChurnReason = {
  header?: string;
  value: string;
  custodianChurnReason: string;
  clientNotes?: string;
};

const reasonMapping: ChurnReason[] = [
  {
    header: 'Client-directed',
    value: 'Client is changing advisor',
    custodianChurnReason: 'Client changed advisor',
    clientNotes: '',
  },
  {
    header: 'Client-directed',
    value: 'Client is changing custodian',
    custodianChurnReason: 'Client Directed',
    clientNotes: '',
  },
  {
    header: 'Client-directed',
    value: 'Account transfer/liquidation',
    custodianChurnReason: 'Account purpose no longer relevant (liquidation, exhausted balance)',
    clientNotes: '',
  },
  {
    header: 'Dissatisfied with the Vise experience',
    value: 'Dissatisfied with the Vise performance',
    custodianChurnReason: 'Advisor unhappy with performance',
    clientNotes: '',
  },
  {
    header: 'Dissatisfied with the Vise experience',
    value: 'Dissatisfied with the Vise platform',
    custodianChurnReason: 'Advisor is unhappy with the platform',
    clientNotes: '',
  },
  {
    header: 'Dissatisfied with the Vise experience',
    value: 'Dissatisfied with the Vise Client Service',
    custodianChurnReason: 'Advisor unhappy with AM/service team',
    clientNotes: '',
  },
  {
    value: 'Change in investment objective',
    custodianChurnReason: 'N/A',
    clientNotes: '',
  },
  {
    value: 'Account opened/linked in error',
    custodianChurnReason: 'Account opened in error',
    clientNotes: '',
  },
  {
    value: 'Other (add details below)',
    custodianChurnReason: 'N/A',
    clientNotes: '',
  },
];

export default function AccountRemovalRequest() {
  const { accountId } = useParams<{ accountId: string }>();
  const enqueueToast = useEnqueueToast();
  const history = useHistory();
  const theme = useTheme();

  const portfolioPage = `/secure/accounts/${accountId}/portfolio/overview`;
  const householdPage = `/secure/households`;
  const { data: accountStateData } = useAccountState(accountId);
  const isRemovalPending = accountStateData?.isRemovalPending;
  const [removalNotes, setRemovalNotes] = useState<string>('');
  const [isCheckedItem, setCheckedItem] = useState<ChurnReason | null>(null);

  const { data: accountsResp } = useAllAccountsWithPIAndHouseholdInfo({
    includeSummaries: false,
    transitioned: false,
  });
  const accounts = accountsResp?.data;
  const untransitionedAccountsIdList = accounts?.map((account) => account.id);

  function goToOriginalPage() {
    if (untransitionedAccountsIdList?.includes(accountId)) {
      history.push(householdPage);
    } else {
      history.push(portfolioPage);
    }
  }

  async function handleSubmit() {
    try {
      if (!isCheckedItem) {
        throw Error('No reason for removal was checked');
      }
      const notes = removalNotes
        ? `${isCheckedItem.value}: ${removalNotes}`
        : `${isCheckedItem.value}`;
      const reason = reasonMapping.find((reason) => reason.value === isCheckedItem.value);
      await removeAccountFromVise(accountId, reason?.custodianChurnReason || '', notes);
      enqueueToast({
        title: 'Account removal request was submitted',
      });
    } catch (error) {
      enqueueToast({
        title: 'There was an error submitting the account removal request',
        severity: 'error',
      });
    }
    goToOriginalPage();
  }

  async function handleOnClickCancel() {
    goToOriginalPage();
  }

  function isChecked(reason: ChurnReason) {
    return reason.value === isCheckedItem?.value;
  }

  const hasCheckedItem = isCheckedItem || false;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Container>
          <Box m={3} mb={15}>
            <Card>
              <CardHeader>
                <Typography variant="h3">Remove account from Vise</Typography>
                <Box mt={1.5} color="grey.700">
                  Submitting an account removal request will automatically pause the autorebalancer
                  and mark the account as inactive. Client Service team has been notified. A team
                  member will reach out to you within one business day.
                  <br />
                  <br />
                  Any account subject to closure at its respective custodian should be removed from
                  the Vise platform. Removing an account from the Vise platform will end data from
                  being transmitted between the custodian and Vise after you, the advisor, have
                  notified the custodian. The account will be removed from its associated household
                  and client following the termination of the custodial data feed. Any information
                  associated with the account will no longer be accessible from the Vise platform.
                  Historical documentation may be accessible by contacting Client Service.
                </Box>
              </CardHeader>
              <CardContent>
                <Box mb={1} display="block" width="100%">
                  <Box display="flex" flexDirection="row">
                    <Typography variant="h4">Why do you want to unlink this account?</Typography>
                    <Box color="error.300" ml={0.5}>
                      *
                    </Box>
                  </Box>
                </Box>
                <Box mt={4} mb={3} width="100%" display="block">
                  {reasonMapping.map((reason) => (
                    <Box mb={3} key={reason.value}>
                      <ContainedSelectorCheckbox
                        name={reason.value}
                        checked={isChecked(reason)}
                        onClick={(isChecked) =>
                          isChecked ? setCheckedItem(reason) : setCheckedItem(null)
                        }
                        disabled={hasCheckedItem && !isChecked(reason)}
                        label={reason.header ? reason.header : reason.value}
                        description={reason.header ? reason.value : ''}
                      />
                    </Box>
                  ))}
                </Box>
                {isCheckedItem?.value === 'Other (add details below)' && (
                  <Box display="block">
                    <Typography mb={2} variant="h4">
                      Reason *
                    </Typography>
                    <TextField
                      fullWidth
                      autoFocus
                      placeholder="Placeholder"
                      value={removalNotes}
                      onChange={(e) => setRemovalNotes(e.target.value)}
                    />
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>
        </Container>
        <Box
          display="flex"
          position="absolute"
          width="-webkit-calc(100% - 230px)"
          bottom={0}
          py={3}
          justifyContent="flex-end"
          px={3}
          bgcolor="white"
          borderTop={`solid 1px ${theme.palette.grey[200]}`}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1.5}>
              <Button variant="outlined" color="secondary" onClick={handleOnClickCancel}>
                Cancel
              </Button>
            </Box>

            <Button
              variant="contained"
              color="error"
              onClick={handleSubmit}
              disabled={!isCheckedItem || isRemovalPending}
            >
              Request Account Removal
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
}
