import { withStyles } from 'tss-react/mui';
import { TableCell } from '@mui/material';
import { pick } from 'lodash';

export const Td = withStyles(TableCell, (theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

export const Th = withStyles(TableCell, (theme) => ({
  root: {
    ...pick(theme.typography.h6, ['fontSize', 'lineHeight']),
    backgroundColor: '#fff',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.grey[500],
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
  },
}));
