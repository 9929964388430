import useSWR from 'swr';
import generateSwrKey from '../api/generateSwrKey';
import { getAllProposals } from '../api/api';

export const getAllProposalsSwrKey = () => generateSwrKey(`allProposals`);

const useAllProposals = () => {
  return useSWR(getAllProposalsSwrKey(), () => getAllProposals());
};

export default useAllProposals;
