import { Box, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as TimeIcon } from '~/static/images/icons/time.svg';
import { Circle, ExclamationCircle } from './UtilComponents';

export const DistributionDetailsPanelsLowBalanceBanner = () => {
  return (
    <Box bgcolor="#FCF2EB" borderRadius="1px" display="flex" p={2}>
      <ExclamationCircle bgcolor="#E86427" color="white" />
      <Box ml={1.5}>
        <Typography variant="h4">Liquid account balance is too low</Typography>
        <Box color="grey.600" mt={0.5}>
          <Typography variant="body1">
            This distribution schedule will be paused while the liquid account balance is lower than
            the distribution amount. Consider unlocking positions, depositing cash, or lowering the
            distribution amount to keep this distribution on schedule.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const DistributionDetailsPanelPausedBanner = () => {
  return (
    <Box bgcolor="#FCF0F0" borderRadius="1px" display="flex" p={2}>
      <ExclamationCircle bgcolor="#D91F11" color="white" />
      <Box ml={1.5}>
        <Typography variant="h4">Schedule is paused</Typography>
        <Box color="grey.600" mt={0.5}>
          <Typography variant="body1">
            The available balance is too low to generate cash for this distribution. To continue
            with this distribution, unlock positions or change the distribution amount.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const DistributionDetailsPanelCancelledBanner = () => {
  return (
    <Box bgcolor="blue.100" borderRadius="1px" p={2}>
      <Box ml={1.5}>
        <Typography variant="h4">This distribution schedule has been cancelled.</Typography>
        <Box color="grey.600" mt={0.5}>
          <Typography variant="body1">
            A last distribution is required to be made due to pending trades. Afterwards the
            schedule will be deleted.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const DistributionDetailsPanelReinvestBanner = (props: { daysToReinvest: number }) => {
  return (
    <Box bgcolor="grey.100" borderRadius="1px" display="flex" p={2}>
      <Circle
        diameter={40}
        bgcolor="#FCF2EB"
        color="#E86427"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexShrink={0}
      >
        <TimeIcon width={18} height={18} />
      </Circle>
      <Box ml={1.5}>
        <Typography variant="h4">
          Remaining cash from this distribution will be reinvested in {props.daysToReinvest} days.
        </Typography>
        <Box color="grey.600" mt={0.5}>
          <Typography variant="body1">
            Vise reinvests remaining cash seven days after the scheduled distribution date. Cash
            will not reinvest on non-trading days.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
