import { Box, Card, Tooltip, Typography, useTheme } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMap from 'highcharts/modules/map';
import { keyBy } from 'lodash';
import React from 'react';
import { Country } from 'vise-types/pce2_instrument';
import useInstruments from '~/hooks/useInstruments';
import { AdjustedConstructedPortfolioMetrics } from '~/models/api';
import { COUNTRY_TO_LABEL_MAP, DEVELOPED_COUNTRIES } from '~/routes/PortfolioCreator2/Constants';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import CardHeader from '~/synth/CardHeader';
import { formatPercent } from '~/utils/format';
import { PricedPortfolioHolding } from '../portfolioUtil';
import mapData from './maps/mapData.json';
import mapDataDevExUs from './maps/mapDataDevExUs.json';

highchartsMap(Highcharts);

export default function AllocationMap({
  assetAllocation,
  positions,
  restrictedCountries,
  mapOnly,
}: {
  assetAllocation: AdjustedConstructedPortfolioMetrics['asset_allocation'];
  positions?: PricedPortfolioHolding[];
  restrictedCountries?: Country[];
  mapOnly?: boolean;
}) {
  const theme = useTheme();
  const { data: instrumentsData } = useInstruments(
    positions?.map((p) => p.ticker),
    true
  );
  const instrumentsKeyBySymbol = keyBy(instrumentsData, 'symbol');

  let us = 0;
  let devExUs = 0;
  let emerging = 0;

  assetAllocation.forEach((a) => {
    if (a.name.includes('US') || a.name.includes('DOMESTIC')) {
      us += a.y;
    } else if (a.name.includes('DEVELOPED')) {
      devExUs += a.y;
    } else if (a.name.includes('EMERGING')) {
      emerging += a.y;
    }
  });

  const countryMap = Object.fromEntries(DEVELOPED_COUNTRIES.map((c) => [c, 0]));

  let hasCountryAllocation = false;

  positions?.forEach((p) => {
    const country = instrumentsKeyBySymbol?.[p.ticker]?.country;

    if (country && countryMap[country] != null) {
      hasCountryAllocation = true;
      countryMap[country] += p.allocation;
    }
  });

  const mapOptions = {
    chart: {
      type: 'map',
      map: positions && hasCountryAllocation ? mapData : (mapDataDevExUs as unknown),
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
    },
    colorAxis: {
      min: 0,
      minColor: theme.palette.purple[50],
      maxColor: theme.palette.purple[600],
      showInLegend: false,
    },
    tooltip: {
      headerFormat: '',
      pointFormatter() {
        // eslint-disable-next-line react/no-this-in-sfc
        return `<span style="color: ${this.color}">• </span>${this.name}:  <b>${
          // eslint-disable-next-line react/no-this-in-sfc
          this.value ? `${formatPercent(this.value)}%` : 'Restricted'
        }
        </b>`;
      },
    },
    series: [
      {
        name: 'US',
        type: 'map',
        joinBy: 'name',
        data: [
          ...(us > 0 ? [{ name: 'United States of America', value: us }] : []),
          ...(emerging > 0 ? [{ name: 'Emerging Markets', value: emerging }] : []),
          ...(devExUs > 0 ? [{ name: 'Developed ex U.S.', value: devExUs }] : []),
          ...Object.entries(countryMap)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, v]) => v > 0)
            .map(([k, v]) => ({
              name: COUNTRY_TO_LABEL_MAP[k],
              value: v,
            })),
          ...(restrictedCountries
            ? restrictedCountries.map((c) => ({
                name: COUNTRY_TO_LABEL_MAP[c],
                value: 0,
                color: 'black',
              }))
            : []),
        ],
        nullColor: '#e2dff0',
        borderColor: '#ebe6fc',
        showInLegend: false,
        states: {
          hover: {
            color: theme.palette.purple[700],
            borderColor: theme.palette.purple[700],
          },
        },
      },
    ],
  } as Highcharts.Options;

  if (mapOnly) {
    return (
      <HighchartsReact constructorType="mapChart" highcharts={Highcharts} options={mapOptions} />
    );
  }

  return (
    <>
      {mapData != null ? (
        <Card>
          <CardHeader>
            <Typography variant="h4">
              <Box display="flex">
                Geo-allocation
                <Tooltip title="Percentages are based on total portfolio allocation. Region and country information is only available for Vise recognized assets at this time.">
                  <Box display="flex" alignItems="center" ml={0.5}>
                    <InformationCircleIcon />
                  </Box>
                </Tooltip>
              </Box>
            </Typography>
          </CardHeader>
          <Box px={3}>
            <HighchartsReact
              constructorType="mapChart"
              highcharts={Highcharts}
              options={mapOptions}
            />
          </Box>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}
