import { v4 as uuidv4 } from 'uuid';
import { OptionsObject, SnackbarMessage } from 'notistack';
import { ActionType } from '../constants';

export const closeAlert = (key?: string | number) => ({
  dismissAll: !key, // dismiss all if no key has been defined
  key,
  type: ActionType.CLOSE_MESSAGE,
});

export const enqueueAlert = ({
  message,
  options,
}: {
  message: SnackbarMessage;
  options?: OptionsObject;
}) => {
  const key = uuidv4();

  return {
    key,
    message,
    options,
    type: ActionType.SHOW_MESSAGE,
  };
};

export const removeAlert = (key: string) => ({
  key,
  type: ActionType.REMOVE_MESSAGE,
});
