import {
  Box,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState, useMemo, useCallback } from 'react';
import { Column, TableState, usePagination, UsePaginationState, useTable } from 'react-table';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import CardHeader from '~/synth/CardHeader';
import { isEqual, keyBy } from 'lodash';
import { DataTable } from '~/synth/DataTable';
import { SearchIcon } from '~/synth/Icons';
import { formatCurrency, formatDate } from '~/utils/format';
import TextField from '~/synth/TextField';
import PageNavigation from '~/components/table/PageNavigation';
import {
  AllocationsTemplate,
  GetModelTemplateCenterViewDataResponse,
  RestrictionsTemplate,
  TemplateUpdateJob,
} from 'vise-types/template';
import { ReactComponent as MinusCircleIcon } from '~/static/images/icons/minus-circle.svg';
import { matchSorter } from 'match-sorter';
import ValueCell from '~/routes/Households/Households/ValueCell';
import AccountFilter, {
  filterAccounts,
  initialFilterState,
} from '~/components/portfolio/AccountFilter';
import useModelTemplateCenterViewData from '~/hooks/useModelTemplateCenterViewData';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import {
  AccountCell,
  Cell,
  HouseholdCell,
  sortLinkingTable,
  useIconButtonColor,
} from './CommonComponents';
import { Action } from './templateLinkReducer';

const PAGE_SIZE = 10;

interface UnlinkTableData {
  checked: {
    checked: boolean;
    onToggle: () => void;
    inProgressTemplateLinkingJob: TemplateUpdateJob | null;
    testId: string;
  };
  account: AccountWithPIAndHouseholdInfo;
  household: AccountWithPIAndHouseholdInfo;
  value: number;
  linkedSince: string;
}

function RemoveCell(props: {
  value: {
    checked: boolean;
    onToggle: () => void;
    inProgressTemplateLinkingJob?:
      | (TemplateUpdateJob & { submittedBy: { firstName: string; lastName: string } })
      | null;
    testId: string;
  };
}) {
  const theme = useTheme();
  const disabled = !!props.value.inProgressTemplateLinkingJob;
  const buttonColor = useIconButtonColor(theme.palette.orange[600], props.value.checked, disabled);
  const body = (
    <Box alignContent="center">
      <IconButton
        aria-label={props.value.checked ? 'Checked' : 'Unchecked'}
        onClick={props.value.onToggle}
        disabled={disabled}
        size="large"
        data-testid={props.value.testId}
      >
        <MinusCircleIcon height={25} width={25} color={buttonColor} />
      </IconButton>
    </Box>
  );
  if (props.value.inProgressTemplateLinkingJob) {
    return (
      <Tooltip
        title={`This account cannot be unlinked right now because there is a template update for it in progress started by ${props.value.inProgressTemplateLinkingJob.submittedBy.firstName} ${props.value.inProgressTemplateLinkingJob.submittedBy.lastName}.`}
      >
        {body}
      </Tooltip>
    );
  }
  return body;
}

const CheckedHeaderComponent = (props) => {
  const theme = useTheme();
  const buttonColor = useIconButtonColor(theme.palette.orange[600], props.column.allChecked, false);
  return (
    <Box pl={0.5}>
      <IconButton
        aria-label={props.column.allChecked ? 'Checked' : 'Unchecked'}
        onClick={props.column.onToggleAllChecked}
        size="large"
      >
        <MinusCircleIcon height={25} width={25} color={buttonColor} />
      </IconButton>
    </Box>
  );
};

const unlinkTableHeaderCells = {
  checked: CheckedHeaderComponent,
  account: () => <Cell value="Account" />,
  household: () => <Cell value="Client/household" />,
  value: () => <Cell value="Value" />,
  linkedSince: () => <Cell value="Linked since" />,
};

const unlinkTableColumns: Column<UnlinkTableData>[] = [
  {
    Header: unlinkTableHeaderCells.checked,
    accessor: 'checked',
    Cell: RemoveCell,
    width: '8%',
    align: 'center',
  },
  { Header: unlinkTableHeaderCells.account, accessor: 'account', Cell: AccountCell, width: '31%' },
  {
    Header: unlinkTableHeaderCells.household,
    accessor: 'household',
    Cell: HouseholdCell,
    width: '25%',
  },
  {
    Header: unlinkTableHeaderCells.value,
    accessor: 'value',
    Cell: (props) => <ValueCell value={formatCurrency(props.value)} isLoading={false} />,
  },
  { Header: unlinkTableHeaderCells.linkedSince, accessor: 'linkedSince', Cell },
];

export default function Unlink({
  data,
  dispatch,
  unlinkAccountsIds,
  template,
}: {
  data: GetModelTemplateCenterViewDataResponse;
  dispatch: (value: Action) => void;
  unlinkAccountsIds: string[];
  template: AllocationsTemplate | RestrictionsTemplate;
}) {
  const [filterQuery, setFilterQuery] = useState<string>('');
  const accountsById = useMemo(() => keyBy(data.accounts, 'id'), [data.accounts]);
  const removedAccountIds = useMemo(() => keyBy(unlinkAccountsIds), [unlinkAccountsIds]);

  const removeAccount = useCallback(
    (accountId: string) => {
      dispatch({
        type: 'UNLINK_ACCOUNT',
        accountId,
      });
    },
    [dispatch]
  );
  const accountTableDataWithoutDropdownFilters = useMemo(() => {
    const linksToTargetTemplate = data.templateLinks.filter(
      (t) => t.originalTemplateId === template.originalTemplateId
    );
    const accounts = linksToTargetTemplate.map(
      (link) =>
        ({
          checked: {
            checked: !!removedAccountIds[link.accountId],
            onToggle: () => removeAccount(link.accountId),
            inProgressTemplateLinkingJob: accountsById[link.accountId].inProgressTemplateLinkingJob,
            testId: `unlink-${accountsById[link.accountId].custodianKey}-${
              accountsById[link.accountId].accountNumber
            }`,
          },
          account: accountsById[link.accountId],
          household: accountsById[link.accountId],
          value: accountsById[link.accountId]?.cachedAum,
          linkedSince: formatDate(new Date(link.linkedSince)),
        } as UnlinkTableData)
    );
    return sortLinkingTable(
      matchSorter(accounts, filterQuery, {
        keys: ['account.accountName'],
        threshold: matchSorter.rankings.CONTAINS,
      })
    );
  }, [
    data.templateLinks,
    template.originalTemplateId,
    filterQuery,
    removedAccountIds,
    accountsById,
    removeAccount,
  ]);

  const [toggleFilterState, setToggleFilterState] = useState(initialFilterState);
  const { data: modelTemplateCenterData } = useModelTemplateCenterViewData(false);
  const accountTableData = useMemo(() => {
    if (modelTemplateCenterData?.data) {
      const accountIds = filterAccounts(
        accountTableDataWithoutDropdownFilters.map((row) => row.account),
        toggleFilterState,
        modelTemplateCenterData.data
      ).map((acct) => acct.id);
      return accountTableDataWithoutDropdownFilters.filter((row) =>
        accountIds.includes(row.account.id)
      );
    }
    return accountTableDataWithoutDropdownFilters;
  }, [accountTableDataWithoutDropdownFilters, modelTemplateCenterData?.data, toggleFilterState]);

  const allVisibleAndSelectableAccountIds = useMemo(
    () =>
      accountTableData
        .filter(
          (row) =>
            !row.checked.disabledDueToQualifiedStatus && !row.checked.inProgressTemplateLinkingJob
        )
        .map((row) => row.account?.id),
    [accountTableData]
  );

  const allChecked = isEqual(
    new Set(unlinkAccountsIds),
    new Set(allVisibleAndSelectableAccountIds)
  );

  const columnsWithToggleData = useMemo(() => {
    return [
      {
        ...unlinkTableColumns[0],
        allChecked,
        onToggleAllChecked: () =>
          dispatch({ type: 'UNLINK_ACCOUNTS', accountIds: allVisibleAndSelectableAccountIds }),
      },
      ...unlinkTableColumns.slice(1),
    ];
  }, [allChecked, allVisibleAndSelectableAccountIds, dispatch]);

  const accountTable = useTable<UnlinkTableData>(
    {
      columns: columnsWithToggleData,
      data: accountTableData,
      initialState: {
        pageSize: PAGE_SIZE,
      } as TableState<UnlinkTableData> & UsePaginationState<UnlinkTableData>,
      autoResetPage: false,
    },
    usePagination
  );

  const theme = useTheme();

  return (
    <Box>
      <Card>
        <CardHeader>
          <Box display="flex" alignItems="end">
            <Box flex={2}>
              <Typography variant="h6" color="textSecondary">
                STEP 1
              </Typography>
              <Box mb={0.5} />
              <Box display="flex" alignItems="center">
                <Typography variant="h3">Edit linked accounts</Typography>
                {unlinkAccountsIds.length > 0 && (
                  <>
                    <Box bgcolor="grey.300" mx={1.5}>
                      <Divider orientation="vertical" flexItem style={{ height: '17.5px' }} />
                    </Box>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                    >{` ${unlinkAccountsIds.length} removed `}</Typography>
                  </>
                )}
              </Box>
              <Box mb={0.5} />
              <Typography variant="body2" color="textSecondary">
                Remove any accounts you’d like to unlink from this template.
              </Typography>
            </Box>

            <Box width="360px">
              <TextField
                fullWidth
                onChange={(e) => {
                  setFilterQuery(e.target.value);
                  accountTable.gotoPage(0);
                }}
                placeholder="Search by name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <AccountFilter
                      accounts={accountTableDataWithoutDropdownFilters.map((row) => row.account)}
                      filterState={toggleFilterState}
                      onApplyFilter={(filterState) => {
                        setToggleFilterState(filterState);
                        accountTable.gotoPage(0);
                      }}
                      eventCategory={EVENT_CATEGORIES.STRATEGY_CENTER}
                    />
                  ),
                  sx: { paddingRight: 0 },
                }}
              />
            </Box>
          </Box>
        </CardHeader>
        <Box maxWidth="972px">
          <DataTable
            m={0}
            table={accountTable}
            customRowProps={(row) =>
              row.values.checked.checked
                ? { style: { backgroundColor: theme.palette.warning[100] } }
                : {}
            }
            rowSize="large"
          />
          {accountTableData.length > PAGE_SIZE ? (
            <Box
              px={3}
              py={4}
              display="flex"
              justifyContent="end"
              borderTop={`1px solid ${theme.palette.grey[100]}`}
            >
              <PageNavigation
                currentPage={
                  (accountTable.state as UsePaginationState<UnlinkTableData>).pageIndex + 1
                }
                numItems={accountTableData.length}
                pageSize={PAGE_SIZE}
                onNextPageClick={() => {
                  accountTable.nextPage();
                }}
                onPrevPageClick={() => {
                  accountTable.previousPage();
                }}
                onPageNumberClick={(pageNumber) => {
                  accountTable.gotoPage(pageNumber - 1);
                }}
              />
            </Box>
          ) : null}
        </Box>
      </Card>
    </Box>
  );
}
