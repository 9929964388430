import React from 'react';
import { format, isSameDay } from 'date-fns';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import { ReactComponent as ChatIcon } from '~/static/images/icons/chat.svg';
import { tokens } from '@vise_inc/ds-vise';
import { GPTSession } from 'vise-types/gpt';

function renderDate(messages, currIndex) {
  const currMessage = messages[currIndex];
  const prevMessage = messages[currIndex - 1];

  if (
    !currMessage?.createdAt ||
    (prevMessage?.createdAt &&
      isSameDay(new Date(currMessage.createdAt), new Date(prevMessage.createdAt)))
  ) {
    return null;
  }

  return (
    <Box pb={1} key={currMessage.id}>
      <Typography variant="caption" color="textSecondary">
        {format(new Date(currMessage.createdAt), 'MMM dd, yyyy')}
      </Typography>
    </Box>
  );
}

export interface SessionHistoryDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  sessions: GPTSession[];
  onRowClick: (session: GPTSession) => void;
  selectedSessionId: string;
}

export default function SessionHistoryDrawer(props: SessionHistoryDrawerProps) {
  const { isOpen, onClose, sessions, onRowClick, selectedSessionId } = props;

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        borderBottom="1px solid #E5E5E5"
        width={400}
      >
        <Typography variant="h5" fontSize={16}>
          Recent session history
        </Typography>
        <IconButton onClick={onClose}>
          <XIcon />
        </IconButton>
      </Box>
      <Box p={2} sx={{ width: 400 }}>
        <Box>
          {sessions.length === 0 && (
            <Typography variant="body1" color="textSecondary">
              No messages yet.
            </Typography>
          )}

          {sessions.map((session, i) => (
            <Box key={session.sessionId}>
              <Box
                onClick={() => onRowClick(session)}
                sx={{
                  cursor: 'pointer',
                  padding: '8px 14px 16px',
                  backgroundColor:
                    selectedSessionId === session.sessionId
                      ? tokens.palette.neutralCool[200]
                      : tokens.palette.neutralCool[100],
                  border: `1px solid ${tokens.palette.neutralCool[100]}`,
                  borderRadius: '8px',
                  marginBottom: '12px',
                  '&:hover': {
                    borderColor: tokens.palette.neutralCool[200],
                    backgroundColor: tokens.palette.neutralCool[200],
                  },
                }}
              >
                {renderDate(sessions, i)}
                <Box display="flex" pt="10px">
                  <Box>
                    <ChatIcon
                      style={{
                        width: '16px',
                        height: '16px',
                        marginRight: '8px',
                        verticalAlign: 'middle',
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {session.firstMessage}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
}
