import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import amplitude from '~/utils/amplitude';
import AllocationTemplateCreate from './AllocationTemplateCreate';
import RestrictionTemplateCreate from './RestrictionTemplateCreate';

export default function TemplateCreate() {
  const match = useRouteMatch<{ templateType: 'allocation' | 'restriction' }>();
  useEffect(() => {
    amplitude().logEvent(`Impression - ${match.params.templateType} template create flow`, {
      category: EVENT_CATEGORIES.STRATEGY_CENTER,
    });
  }, [match.params.templateType]);
  if (match.params.templateType === 'allocation') {
    return <AllocationTemplateCreate />;
  }
  return <RestrictionTemplateCreate />;
}
