import { Box, Collapse, Divider, Typography } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import React from 'react';
import { State } from '~/routes/BondPortfolioCreator/BondPortfolioState';
import { ReactComponent as UserIcon } from '~/static/images/icons/user.svg';
import { getCustodianDisplayName, maskAccountNumber } from '~/utils/format';
import {
  CREDIT_QUALITY_TO_LABEL,
  DURATION_OR_MATURITY_TO_LABEL,
  INSTRUMENT_TO_LABEL,
  STRUCTURE_TO_LABEL,
} from '../BondPortfolio/Utils';
import Truncation from '../Portfolio/components/Truncation';
import { mapStateValueToLabel } from './states';

function Item({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <Box mb={3}>
      <Typography variant="h2" mb={2}>
        {title}
      </Typography>
      <Collapse in>{children}</Collapse>
    </Box>
  );
}

function SubItem({ title, subTitle }: { title: string; subTitle: string }) {
  return (
    <Box mb={4}>
      <Typography variant="h4" mb={1.5}>
        {title}
      </Typography>
      <Divider sx={{ my: 1.5 }} />
      <Typography>{subTitle}</Typography>
    </Box>
  );
}

export default function SummaryPanel({ state }: { state: State }) {
  const { client } = state;

  return (
    <>
      <Box
        display="flex"
        borderBottom={1}
        borderColor={tokens.palette.neutralCool[300]}
        px={5}
        py={3}
      >
        <Box
          alignItems="center"
          borderRadius="50%"
          display="flex"
          height={40}
          justifyContent="center"
          minHeight={40}
          minWidth={40}
          width={40}
          bgcolor={tokens.palette.primaryBlue[100]}
          color={tokens.palette.primaryBlue[300]}
        >
          <UserIcon />
        </Box>
        {state.account != null && (
          <Box pl={3} pr={5} width="100%">
            <Typography variant="h4">
              <Truncation>{state.account.accountName}</Truncation>
            </Typography>
            <Box display="flex">
              <Typography variant="body1" color="grey.600" mr={1.5}>
                {state.account.taxable ? 'Non-qualified' : 'Qualified'}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography variant="body1" color="grey.600" ml={1.5}>
                {maskAccountNumber(state.account.accountNumber)},{' '}
                {getCustodianDisplayName(state.account.custodianKey)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box px={5} py={3}>
        <Item title="Client Details">
          {client?.lastName && client.lastName && (
            <SubItem
              title="Client Information"
              subTitle={`${client?.firstName} ${client?.lastName}`}
            />
          )}
        </Item>
        {state.taxes.estimatedFederalRate || state.taxes.estimatedStateRate ? (
          <Item title="Estimated Tax rate">
            <SubItem title="Federal rate" subTitle={`${state.taxes.estimatedFederalRate}%`} />
            <SubItem title="State rate" subTitle={`${state.taxes.estimatedStateRate}%`} />
          </Item>
        ) : null}

        {state.buildInputs.bondSector ? (
          <Item title="Portfolio Design">
            <SubItem
              title="Bond sector"
              subTitle={INSTRUMENT_TO_LABEL[state.buildInputs.bondSector]}
            />
            {state.buildInputs.bondSector === 'MUNICIPAL' && (
              <SubItem
                title="State strategy"
                subTitle={mapStateValueToLabel[state.buildInputs.stateStrategy]}
              />
            )}
            <SubItem title="Structure" subTitle={STRUCTURE_TO_LABEL[state.buildInputs.structure]} />
            {state.buildInputs.structure === 'LADDER' && (
              <SubItem
                title="Maturity"
                subTitle={DURATION_OR_MATURITY_TO_LABEL[state.buildInputs.maturity]}
              />
            )}
            {state.buildInputs.structure === 'HIGH_QUALITY' && (
              <SubItem
                title="Duration"
                subTitle={DURATION_OR_MATURITY_TO_LABEL[state.buildInputs.duration]}
              />
            )}
            {state.buildInputs.bondSector === 'MUNICIPAL' && (
              <>
                <SubItem
                  title="Credit quality"
                  subTitle={CREDIT_QUALITY_TO_LABEL[state.buildInputs.creditQuality]}
                />
                <SubItem
                  title="Tax loss harvesting"
                  subTitle={state.buildInputs.activeTaxManagement ? 'Active' : 'Inactive'}
                />
              </>
            )}
          </Item>
        ) : null}
      </Box>
    </>
  );
}
