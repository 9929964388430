import React from "react";
import { SvgIcon, type SvgIconProps } from "@mui/material";

export default function RadioIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="9.5" stroke="currentcolor" />
      </svg>
    </SvgIcon>
  );
}

export const radioIconElement = <RadioIcon />;

export function RadioCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="9.5"
          stroke="currentcolor"
        />
        <circle cx="10" cy="10" r="6" fill="currentcolor" />
      </svg>
    </SvgIcon>
  );
}

export const radioCheckedIconElement = <RadioCheckedIcon />;
