import { Box, Grid } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import { differenceInMonths } from 'date-fns';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { BondPortfolioHolding } from 'vise-types/portfolio';
import { formatPercent } from '~/utils/format';

export default function BondPortfolioMaturityChart({
  holdings,
  horizontal,
}: {
  holdings: BondPortfolioHolding[];
  horizontal?: boolean;
}) {
  const today = new Date();
  const maturityMap = { '0-6': 0, '6-12': 0, '12-18': 0, '18-24': 0 };
  holdings.forEach((h) => {
    // TODO: this is only available for treasuries for now (0-2 years)
    // adapt this to work for longer maturities (1-5, 1-10, 1-15)
    if (h.maturity) {
      const diff = differenceInMonths(new Date(h.maturity), today);
      if (diff < 6) {
        maturityMap['0-6'] += h.weight;
      } else if (diff < 12) {
        maturityMap['6-12'] += h.weight;
      } else if (diff < 18) {
        maturityMap['12-18'] += h.weight;
      } else {
        maturityMap['18-24'] += h.weight;
      }
    }
  });

  const chartOptions: Highcharts.Options = {
    chart: { type: 'pie', backgroundColor: undefined, height: 300 },
    plotOptions: {
      pie: {
        size: '100%',
        innerSize: '80%',
        dataLabels: {
          enabled: false,
        },
      },
    },
    title: { text: '' },
    credits: { enabled: false },
    tooltip: { pointFormat: '<b>{point.percentage:.1f}%</b>' },
    series: [
      {
        type: 'pie',
        name: 'Allocation',
        data: Object.entries(maturityMap).map(([maturity, value], idx) => ({
          name: `${maturity} months`,
          y: value * 100,
          color: Object.values(tokens.palette.primaryBlue).reverse()[idx + 4],
        })),
      },
    ],
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={horizontal ? 6 : 12}>
        <HighchartsReact options={chartOptions} />
      </Grid>
      <Grid item xs={horizontal ? 6 : 12}>
        {Object.entries(maturityMap).map(([maturity, value], idx) => (
          <Box display="flex" justifyContent="space-between" alignItems="baseline" key={maturity}>
            <Box display="flex" alignItems="center" mt={2}>
              <Box
                width={12}
                height={12}
                borderRadius={0.5}
                bgcolor={Object.values(tokens.palette.primaryBlue).reverse()[idx + 4]}
                mr={1}
              />
              {maturity} months
            </Box>
            <Box color={tokens.palette.neutralCool[700]}>{formatPercent(value, 1)}</Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
