import { Box, Button, Card, Typography } from '@mui/material';
import React, { useCallback, useMemo, useRef } from 'react';

import Skeleton from '~/synth/Skeleton';
import { ReactComponent as CheckIcon } from '~/static/images/icons/check.svg';
import { ReactComponent as CopyIcon } from '~/static/images/icons/copy.svg';
import useHouseholdMeetingPrep from '~/hooks/useHouseholdMeetingPrep';
import { formatCurrency, formatDate, getCustodianDisplayName } from '~/utils/format';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { HouseholdMeetingPrepData } from 'vise-types/gpt';
import { CADENCE_TO_LABEL } from '../Distributions/Constants';

// generate copy content
function getAccountSectionContent({ accountInfo }: { accountInfo: HouseholdMeetingPrepData }) {
  const { account, distributions } = accountInfo;
  const depositWithdrawals = accountInfo.depositsWithdrawals as { date: string; amount: number }[];
  const taxesHarvested = accountInfo.taxesHarvested as {
    ytdLongTermGainLoss: number;
    ytdShortTermGainLoss: number;
    ytdShortTermGains: number;
    ytdShortTermLosses: number;
    ytdLongTermGains: number;
    ytdLongTermLosses: number;
  };

  const unrealizedGainsLosses = accountInfo.unrealizedGainsAndLosses;
  const intelligence = accountInfo.intelligence as PortfolioIntelligenceFull;
  const equity = intelligence?.constructionInfo?.assetAllocation?.EQUITY || 0;
  const fi = intelligence?.constructionInfo?.assetAllocation?.FIXED_INCOME || 0;
  const alts = intelligence?.constructionInfo?.assetAllocation?.ALTERNATIVES || 0;
  const lockedPositions = intelligence?.constructionRequest.doNotSellSymbolsList || [];

  const data = {
    account,
    distributions,
    depositWithdrawals,
    taxesHarvested,
    unrealizedGainsLosses,
    intelligence,
    equity,
    performance: accountInfo.performance,
    benchmarks: accountInfo.benchmarks,
    fi,
    alts,
    lockedPositions,
  };

  return data;
}

export default function MeetingNotes({ householdId }: { householdId: string }) {
  const [copySuccess, setCopySuccess] = React.useState(false);
  const copyElement = useRef<HTMLDivElement>(null);
  const { data: meetingPrepData } = useHouseholdMeetingPrep(householdId);

  const accountData = useMemo(() => {
    if (!meetingPrepData) {
      return undefined;
    }
    const data = meetingPrepData?.map((m) => getAccountSectionContent({ accountInfo: m }));
    return data;
  }, [meetingPrepData]);

  const onClickCopy = useCallback(async () => {
    if (!copyElement.current) {
      return false;
    }
    const toCopy = copyElement.current.innerHTML;

    const textToCopy = toCopy.replace(/\n/g, ' <br> ') || '';
    const blob = new Blob([textToCopy], { type: 'text/html' });
    const data = [new ClipboardItem({ 'text/html': blob })];
    try {
      await navigator.clipboard.write(data);
      return true;
    } catch (err) {
      return false;
    }
  }, []);

  if (!accountData) {
    return (
      <Box p={3}>
        <Skeleton height="500px" />
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" px={6} pt={3}>
        <Typography variant="h2">Meeting Notes</Typography>
        <Box flex={1} />
        <Button
          variant="outlined"
          color="secondary"
          onClick={async () => {
            const result = await onClickCopy();
            if (result) {
              setCopySuccess(true);
              setTimeout(() => {
                setCopySuccess(false);
              }, 3000);
            }
          }}
          sx={{ borderRadius: '4px' }}
        >
          <Box display="flex" gap={1} alignItems="center">
            {copySuccess ? <CheckIcon /> : <CopyIcon />}
            {copySuccess ? 'Copied' : 'Copy'}
          </Box>
        </Button>
      </Box>

      <Box my={2} px={5} ref={copyElement}>
        {accountData.map((d) => (
          <Card key={d.account.id} sx={{ p: 1, my: 2 }}>
            <Box p={2}>
              <Typography variant="h2" mb={1}>
                {d.account.accountName} {getCustodianDisplayName(d.account.custodianKey)}{' '}
                <i> {d.account.taxable ? 'taxable' : 'non-taxable'} </i> (
                {d.account.transitioned ? 'transitioned' : 'ready to transition'})
              </Typography>

              <Box display="flex" flexDirection="column" gap={1} p={1}>
                <Typography variant="body2" fontWeight={500}>
                  Total value: {formatCurrency(d.account.cachedAum)}
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  Cash value: {formatCurrency(d.account.cash)}
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  Performance:
                </Typography>
                <Typography variant="body2">
                  - {d.performance.mtd ? `MTD: ${d.performance.mtd.toFixed(2)}%` : `No MTD data`}
                </Typography>
                <Typography variant="body2">
                  - {d.performance.ytd ? `YTD: ${d.performance.ytd.toFixed(2)}%` : `No YTD data`}
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  Benchmark ({d.benchmarks.benchmarkName}):
                </Typography>
                <Typography variant="body2">- MTD: {d.benchmarks.mtd.toFixed(2)}%</Typography>
                <Typography variant="body2">- YTD: {d.benchmarks.ytd.toFixed(2)}%</Typography>

                {d.distributions.length > 0 ? (
                  <>
                    <Typography variant="body2" fontWeight={500}>
                      Distributions:
                    </Typography>
                    {d.distributions.map((dist) => (
                      <Typography key={dist.id} variant="body2">
                        - A {CADENCE_TO_LABEL[dist.cadence]} distribution of{' '}
                        {formatCurrency(dist.amount)} on {formatDate(new Date(dist.date))}{' '}
                        {dist.description ? `(${dist.description})` : ''}
                      </Typography>
                    ))}
                  </>
                ) : (
                  <Typography variant="body2" fontWeight={500}>
                    Distributions: -
                  </Typography>
                )}
                {d.depositWithdrawals.filter((d) => d.amount > 0).length > 0 ? (
                  <>
                    <Typography variant="body2" fontWeight={500}>
                      Deposits:
                    </Typography>
                    {d.depositWithdrawals
                      .filter((d) => d.amount > 0)
                      .map((d) => (
                        <Typography key={d.date} variant="body2">
                          +{formatCurrency(d.amount)} on {formatDate(new Date(d.date))}
                        </Typography>
                      ))}
                  </>
                ) : (
                  <Typography variant="body2" fontWeight={500}>
                    Deposits: -
                  </Typography>
                )}
                {d.depositWithdrawals.filter((d) => d.amount < 0).length > 0 ? (
                  <>
                    <Typography variant="body2" fontWeight={500}>
                      Withdrawals:
                    </Typography>
                    {d.depositWithdrawals
                      .filter((d) => d.amount < 0)
                      .map((d) => (
                        <Typography key={d.date} variant="body2">
                          -{formatCurrency(d.amount)} on {formatDate(new Date(d.date))}
                        </Typography>
                      ))}
                  </>
                ) : (
                  <Typography variant="body2" fontWeight={500}>
                    Withdrawals: -
                  </Typography>
                )}
                <Typography variant="body2" fontWeight={500}>
                  Amount of taxes harvested YTD:
                </Typography>
                <Typography variant="body2">
                  - Short term: {formatCurrency(d.taxesHarvested.ytdShortTermGains)} in gains,{' '}
                  {formatCurrency(d.taxesHarvested.ytdShortTermLosses)} in losses
                </Typography>
                <Typography variant="body2">
                  - Long term: {formatCurrency(d.taxesHarvested.ytdShortTermGains)} in gains,{' '}
                  {formatCurrency(d.taxesHarvested.ytdShortTermLosses)} in losses
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  Unrealized gains/losses:
                </Typography>
                <Typography variant="body2">
                  - Short term:{' '}
                  {formatCurrency(
                    d.unrealizedGainsLosses.unrealizedShortTermGains -
                      d.unrealizedGainsLosses.unrealizedShortTermLosses
                  )}
                </Typography>
                <Typography variant="body2">
                  - Long term:{' '}
                  {formatCurrency(
                    d.unrealizedGainsLosses.unrealizedLongTermGains -
                      d.unrealizedGainsLosses.unrealizedLongTermLosses
                  )}
                </Typography>
                {d.intelligence?.constructionInfo?.assetAllocation ? (
                  <Typography variant="body2" fontWeight={500}>
                    Strategy: {(d.equity * 100).toFixed(0)}/{(d.fi * 100).toFixed(0)}/
                    {(d.alts * 100).toFixed(0)}
                  </Typography>
                ) : null}
                {d.lockedPositions.length > 0 ? (
                  <Typography variant="body2" fontWeight={500}>
                    Locked positions: {d.lockedPositions.join(', ')}
                  </Typography>
                ) : null}
                <br />
              </Box>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
}
