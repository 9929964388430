import { Box, Card, Divider, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import useAccountOrders from '~/hooks/useAccountOrders';
import { format } from 'date-fns';
import { floatCompare } from '~/synth/DataTable';
import GPTCardHeader from './GPTCardHeader';
import { PRIMARY_OBJECTIVE_TO_LABEL, beforeBackfill } from '../../Portfolio/TradeActivity/Utils';
import {
  TurnoverLabel,
  TradeInitiatorLabel,
  CatalystLabel,
} from '../../Portfolio/components/TradeActivityLabels';
import LoadingCard from './LoadingCard';
import CardDisclosures from './CardDisclosures';
import useAccountWithLogging from './useAccountWithLogging';

export default function GPTTradingActivityCard({ accountId }: { accountId?: string }) {
  const { data: accountResponse } = useAccountWithLogging({
    accountId,
    callerName: 'GPTTradingActivityCard',
  });
  const { data: accountOrders } = useAccountOrders(accountId);

  const account = accountResponse?.data;
  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          headerName: 'Order date',
          flex: 1.5,
          field: 'createdAt',
          renderCell: (props) => (
            <Typography variant="h4">
              {props.value != null ? format(new Date(props.value), 'MMMM do, yyyy') : <>N/A</>}
            </Typography>
          ),
        },
        {
          flex: 1,
          field: 'Turnover',
          headerName: 'Turnover',
          description: `Turnover is a measure of the trading activity in a portfolio. It is computed by
                        taking the lesser of purchases or sales in a trade, divided by the portfolio
                        value.
                        A low turnover trade implies small shifts in the overall portfolio, while a high
                        turnover trade implies higher trading activity due to larger shifts in the overall
                        portfolio.`,
          valueGetter: (params) => params.row.metrics && params.row.metrics.turnoverFraction,
          renderCell: (props) => <TurnoverLabel turnover={props.value} />,
          sortType: floatCompare,
        },
        {
          headerName: 'Trade initiator',
          flex: 1,
          field: 'pce2RunSource',
          renderCell: (props) =>
            props.value != null ? <TradeInitiatorLabel runSource={props.value} /> : <>N/A</>,
          sortable: false,
        },
        {
          headerName: 'Catalyst',
          flex: 1,
          field: 'optimizerMode',
          renderCell: (props) =>
            props.value != null ? <CatalystLabel optimizerMode={props.value} /> : <>N/A</>,
          sortable: false,
        },
        {
          headerName: 'Primary objective',
          field: 'intelligence',
          renderCell: (props) => {
            if (props.value?.primaryObjective != null) {
              return PRIMARY_OBJECTIVE_TO_LABEL[props.value.primaryObjective];
            }
            const content =
              props.row.original && beforeBackfill(new Date(props.row.original.createdAt))
                ? 'Unavailable'
                : 'Coming soon…';
            return <Box color="grey.400">{content}</Box>;
          },

          sortable: false,
          flex: 1.5,
        },
      ] as GridColDef[],
    []
  );

  if (!account || !accountOrders) {
    return <LoadingCard contentHeight="350px" />;
  }

  return (
    <Card style={{ overflow: 'visible' }}>
      <GPTCardHeader
        title="Trading activity"
        buttonPathname={`/secure/accounts/${accountId}/portfolio/activity`}
        buttonCopy="View account"
        account={account}
      />
      <Box
        height="350px"
        sx={{
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'white',
          },
          '& .MuiDataGrid-root': {
            boxShadow: undefined,
            border: '0px solid #000000',
          },
        }}
      >
        <DataGridPro
          rows={accountOrders.orders}
          columns={columns}
          getRowId={(row) => row.id}
          sortModel={[{ field: 'createdAt', sort: 'desc' }]}
        />
      </Box>
      <Divider />
      <CardDisclosures />
    </Card>
  );
}
