import usePortfolioMetrics from '~/hooks/usePortfolioMetrics';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export default function usePortfolioMetricsWithLogging(
  accountId: string | undefined,
  callerName: string,
  extra: { [key: string]: unknown } = {}
) {
  const { data, error } = usePortfolioMetrics(accountId);

  useEffect(() => {
    if (error) {
      Sentry.captureException(
        new Error(`[${callerName}] Failed to load metrics for account ${accountId}. ${error}`),
        {
          extra: {
            accountId,
            ...extra,
          },
        }
      );
    }
  }, [accountId, callerName, error, extra]);

  return {
    data,
    error,
  };
}
