import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import CardHeader from '~/synth/CardHeader';

export default function RenameContent({
  metadata,
}: {
  metadata?: Record<string, string> | undefined;
}) {
  if (!metadata?.oldName || !metadata?.newName) {
    return <></>;
  }
  return (
    <Card>
      <CardHeader>
        <Typography variant="h2">Description</Typography>
      </CardHeader>
      <Box p={3}>
        <Typography variant="body2">
          Template name updated from “{metadata.oldName}” to “{metadata.newName}”
        </Typography>
      </Box>
    </Card>
  );
}
