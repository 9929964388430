import React, { useEffect, useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import { ReactComponent as SendMessageIcon } from '~/static/images/icons/send-message.svg';

const GPTInputField = ({
  onSubmit,
  disabled,
  value,
}: {
  onSubmit: (question: string) => void;
  disabled: boolean;
  value?: string;
}) => {
  const [questionValue, setQuestionValue] = useState<string>(value || '');

  useEffect(() => {
    if (value != null) {
      setQuestionValue(value);
    }
  }, [value]);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="12px"
      sx={{ boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.05);' }}
    >
      <TextField
        id="gpt_questions"
        aria-label="Ask me anything"
        placeholder="Ask me anything"
        name="gpt_questions"
        fullWidth
        onChange={(e) => setQuestionValue(e.target.value)}
        value={questionValue}
        disabled={disabled}
        InputProps={{
          onKeyDown: (key) => {
            if (!disabled) {
              if (key.key === 'Enter') {
                onSubmit(questionValue);
                setQuestionValue('');
              }
            }
          },
          endAdornment: (
            <IconButton
              sx={{
                backgroundColor: '#3076FF',
                width: 40,
                height: 40,

                '&:hover': {
                  backgroundColor: '#1753DE',
                },
                '&:disabled': {
                  backgroundColor: '#CCCCCC',
                },
              }}
              disabled={disabled}
              onClick={() => {
                onSubmit(questionValue);
                setQuestionValue('');
              }}
            >
              <SendMessageIcon />
            </IconButton>
          ),
        }}
        autoComplete="off"
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            border: '1px solid #F0F0F0',
            backgroundColor: '#FFFFFF',
            height: '54px',
            boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.1);',
            '&:hover fieldset': {
              borderColor: '#F0F0F0',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#7498EB',
            },
          },
        }}
      />
    </Box>
  );
};

export default GPTInputField;
