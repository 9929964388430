import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { Helmet } from 'react-helmet';
import emailValidator from 'email-validator';
import { inviteToRegister } from '~/api/register';
import useUser from '~/hooks/useUser';
import TextField from '~/synth/TextField';
import Select from '~/synth/inputs/Select';

import { ReactComponent as XCircleIcon } from '~/static/images/icons/x-circle.svg';
import { ReactComponent as MailIcon } from '~/static/images/icons/mail-outline.svg';
import { ReactComponent as TrashIcon } from '~/static/images/icons/trash.svg';
import { AddIcon } from '~/synth/Icons';
import { updateUser } from '~/api/api';
import { internalRole } from 'vise-types/user';
import Wizard from './components/Wizard';

interface FormValues {
  firstName: string;
  lastName: string;
  isSignatory: null | SelectOption<boolean>;
  internalRole: null | SelectOption<internalRole>;
  inviteeEmails: string[];
  signatoryFirstName: string;
  signatoryLastName: string;
  signatoryEmail: string;
}

interface SelectOption<T> {
  label: string;
  value: T;
}

const internalRoleSelectOptions: SelectOption<string>[] = [
  { label: 'Financial Advisor', value: 'Financial Advisor' },
  { label: 'Investment Team', value: 'Investment Team' },
  { label: 'Ops Team', value: 'Ops Team' },
  { label: 'Other', value: 'Other' },
];

const WIZARD_STEPS = {
  INTRO: 0,
  USER_INFO: 1,
  INVITEE_INFO: 2,
} as const;

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  isSignatory: null,
  internalRole: null,
  inviteeEmails: [''],
  signatoryFirstName: '',
  signatoryLastName: '',
  signatoryEmail: '',
};

const getFieldsByWizardStep = (): { [step: number]: (keyof FormValues)[] } => {
  return {
    [WIZARD_STEPS.USER_INFO]: ['firstName', 'lastName', 'internalRole'],
    [WIZARD_STEPS.INVITEE_INFO]: ['inviteeEmails'],
  };
};

const WizardStep = ({ children }) => (
  <Card elevation={0} sx={{ overflow: 'visible' }}>
    <CardContent
      sx={{
        px: '2px',
        py: 0,
        '&:last-child': {
          paddingBottom: 0,
        },
      }}
    >
      {children}
    </CardContent>
  </Card>
);

export default function OnboardingWelcome() {
  const { data: userData, mutate } = useUser();

  // Ensure unique keys on email fields.
  const [inviteeEmailFieldKeys, setInviteeEmailFieldKeys] = useState([uuidv4()]);

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    const REQUIRED = 'Required';
    const MAX_LENGTH = 'Should be under 256 characters';
    const INVALID_EMAIL = 'Invalid email address';

    const validateEmail = (field: string, value: string, index?: number) => {
      errors[field] = errors[field] || [];

      if (!emailValidator.validate(value)) {
        errors[field][index] = INVALID_EMAIL;
        return;
      }

      const emailDomain = userData?.email.split('@')[1];
      if (!value.endsWith(`@${emailDomain}`)) {
        errors[field][index] = `Email must be from same domain as your email: ${emailDomain}`;
      }
    };

    const validateNotEmpty = (field: string, value) => {
      if (value === '' || value === null) errors[field] = REQUIRED;
    };

    const validateMaxLength = (field: string, value: string, maxLength = 256) => {
      if (value.length >= maxLength) errors[field] = MAX_LENGTH;
    };

    const { firstName, lastName, internalRole, inviteeEmails } = values;

    // Common fields.
    validateNotEmpty('firstName', firstName);
    validateMaxLength('firstName', firstName);
    validateNotEmpty('lastName', lastName);
    validateMaxLength('lastName', lastName);
    validateNotEmpty('internalRole', internalRole);

    if (inviteeEmails.length) {
      inviteeEmails.forEach((email, index) => {
        validateEmail('inviteeEmails', email, index);
      });
    }

    return errors;
  };

  const shouldDisableWizardNextButton =
    ({ dirty, errors }) =>
    (activeStep: number) => {
      const fieldsByWizardStep = getFieldsByWizardStep();

      // No fields to validate for this step.
      if (!fieldsByWizardStep[activeStep]) return false;

      const areStepFieldsValid =
        dirty &&
        fieldsByWizardStep[activeStep].every((field) =>
          Array.isArray(errors[field]) ? !errors[field].filter((x) => !!x).length : !errors[field]
        );
      return !areStepFieldsValid;
    };

  const handleShowSkipButton = (step: number) => step === WIZARD_STEPS.INVITEE_INFO;

  const handleWizardNextButtonText = () => 'Next';

  const handleWizardNextClick = (formValues: FormValues) => async (step: number) => {
    const prevStep = step - 1;
    if (prevStep === WIZARD_STEPS.USER_INFO) {
      const { firstName, lastName, internalRole } = formValues;
      await updateUser({
        id: userData?.id as string,
        firstName,
        lastName,
        userMetadata: {
          internalRole: internalRole?.value || 'Other',
          isSignatory: false,
        },
      });
      await mutate();
    } else if (prevStep === WIZARD_STEPS.INVITEE_INFO && formValues.inviteeEmails.length) {
      inviteToRegister(
        formValues.inviteeEmails.filter((x) => !!x).map((email) => ({ inviteeEmail: email }))
      );
    }
  };

  return (
    <div>
      <Helmet>
        <title>Welcome to Vise</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={() => {
          // nothing to do here (submission is handled by the Wizard).
        }}
      >
        {({ touched, dirty, errors, values, setTouched, setFieldValue, setFieldTouched }) => (
          <Form>
            <Wizard
              onNextButtonClick={handleWizardNextClick(values)}
              horizontalStepperProps={{
                disableNextButton: shouldDisableWizardNextButton({ dirty, errors }),
                nextButtonText: handleWizardNextButtonText,
                showSkipButton: handleShowSkipButton,
                numberOfStepsToSkip: 1,
                completedHeading: 'Congratulations on completing the Vise platform registration!',
                completedText: () => (
                  <>
                    <Typography variant="body2" color="text.secondary" mb={2}>
                      You now have access to a wealth of benefits, tools, and strategies that can
                      help you manage your clients&apos; investments.
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mb={1}>
                      Please don&apos;t hesitate to reach out if you need support, email the client
                      success team at clientservice@vise.com.
                    </Typography>
                  </>
                ),
              }}
              showStepLabels
              stepLabels={[
                'Let’s get you up and running on Vise!',
                'Tell us about yourself',
                'Invite (optional)',
              ]}
            >
              {/* Step */}
              <WizardStep>
                <Box justifyContent="center" alignItems="center" pb={2.7}>
                  <Typography variant="h1">Let’s get you up and running on Vise!</Typography>
                </Box>

                <Typography paragraph variant="body2" color="text.secondary">
                  Welcome to the Vise platform! The following information we collect will help us
                  tailor our services to your needs.{' '}
                </Typography>
                <Typography paragraph variant="body2" color="text.secondary">
                  We&apos;re excited to work with you and support your business growth on the Vise
                  platform.
                </Typography>
              </WizardStep>

              {/* Step */}
              <WizardStep>
                <Box pt={1.2} pb={2.5}>
                  <Typography variant="h1">We would love to learn more about you!</Typography>
                </Box>
                <Typography paragraph pb={2.5} variant="body2" color="text.secondary">
                  Please tell us about yourself. This information will help us tailor our services
                  to meet your needs and provide the best possible support.
                </Typography>

                <Box my={2}>
                  <Field name="firstName">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="First name"
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        fullWidth
                        InputLabelProps={{ required: true }}
                      />
                    )}
                  </Field>
                </Box>
                <Box my={2}>
                  <Field name="lastName">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="Last name"
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        fullWidth
                        InputLabelProps={{ required: true }}
                      />
                    )}
                  </Field>
                </Box>

                <Box my={2}>
                  <Field name="internalRole">
                    {({ field }) => (
                      <>
                        <Box mb={1}>
                          <Typography variant="h5">What is your role?</Typography>
                        </Box>
                        <Select
                          {...field}
                          error={touched.internalRole && Boolean(errors.internalRole)}
                          options={internalRoleSelectOptions}
                          onBlur={() => setTouched({ internalRole: true })}
                          InputLabelProps={{ required: true }}
                          onChange={(selectedOption) => {
                            setFieldValue('internalRole', selectedOption);
                          }}
                        />
                        {touched.internalRole && values.internalRole == null && (
                          <Box color="error.main" mt={1} ml={1.6} display="flex">
                            <XCircleIcon width={13} height={13} />
                            <Box ml={0.6} fontSize={12}>
                              {errors.internalRole}
                            </Box>
                          </Box>
                        )}
                      </>
                    )}
                  </Field>
                </Box>
              </WizardStep>

              {/* Step */}
              <WizardStep>
                <Box pt={1.2} pb={2.5}>
                  <Typography variant="h1">Share the experience of Vise</Typography>
                </Box>
                <Typography paragraph pb={2.5} variant="body2" color="text.secondary">
                  Invite your team members to join the platform and explore all the features that
                  Vise offers.
                </Typography>

                <Box my={2}>
                  <Box my={2}>
                    {inviteeEmailFieldKeys.map((key, i) => (
                      <Box key={key} display="flex" alignItems="center" my={2}>
                        <Field name={`inviteeEmails[${i}]`}>
                          {({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              error={
                                touched.inviteeEmails &&
                                touched.inviteeEmails[i] &&
                                errors.inviteeEmails &&
                                Boolean(errors.inviteeEmails[i])
                              }
                              helperText={
                                touched.inviteeEmails &&
                                touched.inviteeEmails[i] &&
                                errors.inviteeEmails &&
                                errors.inviteeEmails[i]
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <>
                                    {i > 0 && (
                                      <IconButton
                                        onClick={() => {
                                          setFieldValue(
                                            'inviteeEmails',
                                            values.inviteeEmails.filter((_, j) => j !== i),
                                            true
                                          );
                                          setInviteeEmailFieldKeys(
                                            inviteeEmailFieldKeys.filter((k) => k !== key)
                                          );
                                        }}
                                        aria-label="Remove email"
                                      >
                                        <TrashIcon height="20" width="20" />
                                      </IconButton>
                                    )}
                                  </>
                                ),
                              }}
                            />
                          )}
                        </Field>
                      </Box>
                    ))}
                    <Box mt={2}>
                      <Button
                        startIcon={<AddIcon display="inline-flex" size="small" />}
                        disabled={values.inviteeEmails.length >= 5}
                        onClick={() => {
                          setFieldValue('inviteeEmails', [...values.inviteeEmails, ''], false);
                          // Keep fields untouched, if they have no value yet.
                          if (values.inviteeEmails.filter((x) => !!x).length === 0) {
                            setFieldTouched('inviteeEmails', false);
                          }
                          setInviteeEmailFieldKeys([...inviteeEmailFieldKeys, uuidv4()]);
                        }}
                        aria-label="Add emails"
                        color="secondary"
                        sx={{
                          px: 0,
                          py: 0,
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <Typography variant="h5">Add emails</Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </WizardStep>
            </Wizard>
          </Form>
        )}
      </Formik>
    </div>
  );
}
