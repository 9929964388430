import {
  cloneDeep,
  get,
  isArray,
  isEmpty,
  isPlainObject,
  mapValues,
  merge,
  mergeWith,
  omit,
  pick,
  set,
  uniq,
} from 'lodash';
import { EffectFunction, EffectReducer, useEffectReducer } from 'use-effect-reducer';
import { PortfolioIntelligenceCommon } from 'vise-types/pce1';
import { PCE2SpecificPortfolioIntelligence } from 'vise-types/pce2';
import { AssetClassKey, Feature } from 'vise-types/pce2_instrument';
import { riskToRiskSlider } from '~/api/utils';
import { MoveToCashConcentrations } from '~/constants';
import { DeepPartial } from '~/models/shared';
import { getAssetClassKeyFromFeatures } from '~/utils/pce2Migration';
import {
  ASSET_CLASS_TREES,
  BLANK_ASSET_CLASS_ALLOCATION,
  DEFAULT_DRAFT_PORTFOLIO_VALUES,
  DEFAULT_DRAFT_PORTFOLIO_VALUES_SMALL,
  NUMBER_OF_SINGLE_SECURITY_ASSET_CLASSES,
  SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS,
} from './Constants';
import {
  AssetClassTreeNode,
  ConstructionInfo,
  DraftPortfolio,
  DraftPortfolioEffect,
  DraftPortfolioEffectTypes,
  DraftPortfolioEvent,
  Pce2ConstructionInfo,
  Pce2DraftPortfolio,
  RestrictionsOrigin,
} from './Types';
import {
  ActiveTiltForDisplay,
  computeActiveTiltForDisplay,
  flipAllAssetClassesWhereApplicable,
  isNewTiltType,
  shouldShowExcludeCountries,
  strategyToPartialConstructionInfo,
} from './utils';

export const initialDraftPortfolio: DraftPortfolio = {
  bootstrappingState: null,
  constructionInfo: {
    assetClasses: null,
    clientId: null,
    concentrationLimits: undefined,
    description: null,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    distributionTimeline: undefined,
    etfExclusive: null,
    excludedCountries: null,
    excludedIndustries: null,
    excludedSectors: null,
    existingPortfolio: null,
    excludedEsgAreas: null,
    focus: 'LOSS_TOLERANCE',
    /** We no longer have a way of getting whether an account is a retirement account, so fundType
     * is always 'INVESTMENT' */
    fundType: 'INVESTMENT',
    initialValue: null,
    investmentPriority: null,
    investmentTimeline: null,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'InvestmentO... Remove this comment to see the full error message
    overlay: null,
    portfolioMaxSize: 40,
    restrictedStocks: null,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message
    risk: undefined,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message
    targetValue: undefined,
    tSliceEnabled: true,
    userId: null,
    assetAllocation: undefined,
    mmf: undefined,
    longTermFederalTaxRate: 0.2,
    shortTermFederalTaxRate: 0.5,
    longTermStateTaxRate: 0,
    shortTermStateTaxRate: 0,
  },
  constructionMetadata: {
    restrictionsOrigins: {
      q2: {
        restrictedStocks: [],
        excludedSectors: [],
        excludedIndustries: [],
        excludedCountries: [],
      },
      q3: {
        restrictedStocks: [],
        excludedSectors: [],
        excludedIndustries: [],
        excludedCountries: [],
      },
      q4: {
        restrictedStocks: [],
        excludedSectors: [],
        excludedIndustries: [],
        excludedCountries: [],
      },
      q5: {
        restrictedStocks: [],
        excludedSectors: [],
        excludedIndustries: [],
        excludedCountries: [],
      },
      q6: {
        restrictedStocks: [],
        excludedSectors: [],
        excludedIndustries: [],
        excludedCountries: [],
      },
    },
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'boolean'.
    skipQuestionnaire: null,
  },
  dirty: false,
  editModeOriginalInvestmentTimeline: null,
  isEditMode: false,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string[]'.
  lockedPositions: null,
  newClientInfo: null,
  newHouseholdInfo: null,
  newSavedStrategyName: null,
  strategyId: null,
  strategyInternalUuid: null,
  allocationsTemplateId: null,
  allocationTemplate: null,
  restrictionsTemplatesIds: null,
  restrictionsTemplates: null,
  overwriteExistingStrategy: null,
  useAllocationTemplate: null,
  pickSavedStrategy: null,
  savedStrategy: null,
  isBootstrappedStrategyOutdated: null,
  bootstrappedStrategy: null,
};

// This is the initial state for the PCE2-only variables (defined in Pce2DraftPortfolio but not
// in Pce1DraftPortfolio).
const initialPce2DraftPortfolioExtension: Partial<Pce2DraftPortfolio> = {
  accountSize: 'UNKNOWN',
  constructionInfo: {
    ...initialDraftPortfolio.constructionInfo,
    assetClassConcentrationLimits: {
      isEnabled: false,
      equities: null,
      fixedIncome: null,
      exclusions: null,
    },
    autoTlh: null,
    capitalGainsLimits: {
      longTermGainsLimits: {
        maximumAmount: null,
        shouldLimitGains: true,
        shouldLimitSmallestAmount: false,
      },
      shortTermGainsLimits: {
        maximumAmount: 0,
        shouldLimitGains: true,
        shouldLimitSmallestAmount: true,
      },
    },
    excludedCountries: null,
    useGlidePath: false,
    etfExclusiveAssetClasses: [],
    minSymbolAssetClasses: [],
    isCustomAllocation: false,
    mmf: undefined,
  },
};

const deepMergedFields = new Set([
  'constructionInfo',
  'constructionMetadata',
  'restrictionsOrigins',
  'q2',
  'q3',
  'q4',
  'q5',
  'q6',
  'activeTilt',
  'assetClassConcentrationLimits',
]);

function customizer(oldValue: unknown, newValue: unknown, key: string) {
  // Only recursively merge certain fields
  if (
    isArray(oldValue) ||
    (isPlainObject(newValue) && isPlainObject(oldValue) && !deepMergedFields.has(key))
  ) {
    return newValue;
  }
  return undefined;
}

export const fillInAssetAllocation = (currentState: { [key in AssetClassKey]?: number }) => {
  // Fill in missing keys, some endpoints don't give us every key
  const newState = { ...BLANK_ASSET_CLASS_ALLOCATION, ...currentState };
  ASSET_CLASS_TREES.forEach((tree) => {
    const postOrderTraversal = (node: AssetClassTreeNode, curBranch: Feature[]) => {
      node.children?.forEach((n) => postOrderTraversal(n, [...curBranch, node.feature]));

      if (node.children) {
        const sum = node.children.reduce((acc, value) => {
          const key = getAssetClassKeyFromFeatures([...curBranch, node.feature, value.feature]);
          return acc + (newState[key] || 0) ?? 0;
        }, 0);
        const nodeKey = getAssetClassKeyFromFeatures([...curBranch, node.feature]);
        newState[nodeKey] = sum;
      }
    };
    postOrderTraversal(tree, []);
  });
  return newState;
};

const draftPortfolioEffects = {
  allChangesEffect(state, _e, dispatch) {
    if (!state.dirty) {
      dispatch({ type: 'SET_DIRTY_TRUE' });
    }
  },
  changeAccountSizeEffect(
    _state,
    effect: DraftPortfolioEffectTypes['ChangeAccountSizeEffect'],
    dispatch
  ) {
    if (effect.nextAccountSize === effect.prevAccountSize) return;

    dispatch({
      equities: undefined,
      type: 'PCE2_SET_CONCENTRATION_LIMITS_EQUITIES',
    });
    dispatch({
      fixedIncome: undefined,
      type: 'PCE2_SET_CONCENTRATION_LIMITS_FIXED_INCOME',
    });
    dispatch({
      exclusions: undefined,
      type: 'PCE2_SET_CONCENTRATION_LIMITS_EXCLUSIONS',
    });
  },
  changeClientIdEffect(
    { bootstrapType, constructionInfo: { existingPortfolio }, newClientInfo, newHouseholdInfo },
    effect: DraftPortfolioEffectTypes['ChangeClientIdEffect'],
    dispatch
  ) {
    const hasExistingPortfolio = existingPortfolio != null;
    const existingPortfolioIsAccount =
      hasExistingPortfolio && existingPortfolio !== 'sample-portfolio';
    const clientIdWillChangeForAccount =
      existingPortfolioIsAccount && existingPortfolio.viseClientId !== effect.nextClientId;
    const accountIsLinkedToClient =
      existingPortfolioIsAccount && existingPortfolio.viseClientId != null;

    let shouldSetAccount: boolean;
    if (bootstrapType === 'ACCOUNT') {
      shouldSetAccount = accountIsLinkedToClient && clientIdWillChangeForAccount;
    } else {
      shouldSetAccount = !hasExistingPortfolio || clientIdWillChangeForAccount;
    }

    if (shouldSetAccount) {
      dispatch({
        type: 'SET_ACCOUNT',
        existingPortfolio: initialDraftPortfolio.constructionInfo.existingPortfolio,
      });
    }

    if (newClientInfo != null) {
      dispatch({
        newClientInfo: null,
        type: 'SET_NEW_CLIENT_INFO',
      });
    }

    if (newHouseholdInfo != null) {
      dispatch({
        newHouseholdInfo: null,
        type: 'SET_NEW_HOUSEHOLD_INFO',
      });
    }
  },
  changeAccountEffect(_, effect: DraftPortfolioEffectTypes['ChangeAccountEffect'], dispatch) {
    if (
      effect.prevExistingPortfolio === effect.nextExistingPortfolio ||
      (effect.prevExistingPortfolio !== 'sample-portfolio' &&
        effect.nextExistingPortfolio !== 'sample-portfolio' &&
        effect.prevExistingPortfolio?.id === effect.nextExistingPortfolio?.id)
    ) {
      return;
    }
    dispatch({
      type: 'SET_LOCKED_POSITIONS',
      lockedPositions: initialDraftPortfolio.lockedPositions,
    });
  },
  changeEtfExclusiveEffect(
    _s,
    effect: DraftPortfolioEffectTypes['ChangeEtfExclusiveEffect'],
    dispatch
  ) {
    if (effect.etfExclusive === true) {
      dispatch({
        type: 'CLEAR_ALL_RESTRICTIONS',
      });
      dispatch({ type: 'SET_OVERLAY', overlay: 'NONE' });
    }
  },
  changeAssetClassesEffect(
    _s,
    effect: DraftPortfolioEffectTypes['ChangeAssetClassesEffect'],
    dispatch
  ) {
    const assetClassesSet = new Set(effect.assetClasses);
    if (!assetClassesSet.has('US_EQUITIES')) {
      dispatch({
        type: 'CLEAR_ALL_RESTRICTIONS',
      });
      dispatch({
        type: 'SET_SKIP_QUESTIONNAIRE',
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'boolean'.
        skipQuestionnaire: null,
      });
    }
  },
  applySavedStrategyEffect(
    _s,
    effect: DraftPortfolioEffectTypes['ApplySavedStrategyEffect'],
    dispatch
  ) {
    if (effect.strategy.etfExclusive !== true) {
      dispatch({
        type: 'SET_SKIP_QUESTIONNAIRE',
        skipQuestionnaire: true,
      });
    }
  },
  changePickSavedStrategyEffect(
    { bootstrappedStrategy, isBootstrappedStrategyOutdated },
    effect: DraftPortfolioEffectTypes['ChangePickSavedStrategyEffect'],
    dispatch
  ) {
    if (effect.nextValue === effect.prevValue) return;
    dispatch({
      type: 'UNSET_SAVED_STRATEGY',
    });
    if (effect.prevValue !== false && effect.nextValue === false) {
      dispatch({
        type: 'SET_NEW_SAVED_STRATEGY_NAME',
        name: '',
      });
    }
    if (effect.nextValue === null && isBootstrappedStrategyOutdated) {
      dispatch({
        type: 'SET_SAVED_STRATEGY',
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'StrategyVersion | null' is not assignable to... Remove this comment to see the full error message
        savedStrategy: bootstrappedStrategy,
      });
    }
  },
  changeConcentrationLimitsExclusionsEffect(
    _s,
    effect: DraftPortfolioEffectTypes['ChangeConcentrationLimitsExclusionsEffect'],
    dispatch
  ) {
    const shouldClearExcludedCountries = !shouldShowExcludeCountries(effect.exclusions);
    if (shouldClearExcludedCountries) {
      dispatch({
        type: 'SET_EXCLUDED_COUNTRIES',
        excludedCountries: null,
        origin: 'skip',
      });
    }
  },
} as {
  [effectName: string]: EffectFunction<DraftPortfolio, DraftPortfolioEvent, DraftPortfolioEffect>;
};

const calculateUpdatedRestrictions = (
  oldRestrictionsOrigins: DraftPortfolio['constructionMetadata']['restrictionsOrigins'],
  oldValue: string[],
  additions: string[],
  deletions: string[],
  key:
    | 'excludedSectors'
    | 'excludedIndustries'
    | 'restrictedStocks'
    | 'excludedCountries'
    | 'excludedEsgAreas',
  origin?: RestrictionsOrigin
) => {
  const deletionsSet = new Set(deletions);
  const newValue = [...oldValue.filter((v) => !deletionsSet.has(v)), ...additions];
  if (origin === 'skip') {
    return {
      constructionInfo: {
        [key]: newValue,
      },
    };
  }

  const newRestrictionsOrigins = Object.keys(
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    initialDraftPortfolio.constructionMetadata.restrictionsOrigins
  ).reduce(
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    (acc, q: keyof typeof initialDraftPortfolio.constructionMetadata.restrictionsOrigins) => {
      const updatedRestrictions =
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        oldRestrictionsOrigins[q][key]?.filter((s) => !deletionsSet.has(s)) ?? [];
      if (q === origin) {
        updatedRestrictions.push(...additions);
      }
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
      acc[q] = {
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...oldRestrictionsOrigins[q],
        [key]: updatedRestrictions,
      };
      return acc;
    },
    {}
  );
  return {
    constructionInfo: {
      [key]: newValue,
    },
    constructionMetadata: {
      restrictionsOrigins: newRestrictionsOrigins,
    },
  };
};

export const DRAFT_PORTFOLIO_CLEARED_RESTRICTIONS_UPDATED_STATE = {
  constructionMetadata: {
    restrictionsOrigins: initialDraftPortfolio.constructionMetadata.restrictionsOrigins,
  },
  constructionInfo: {
    restrictedStocks: [],
    excludedIndustries: [],
    excludedSectors: [],
    excludedCountries: [],
  },
};

export const buildNextDraftPortfolioState = (
  state: DraftPortfolio,
  updatedStateValues: DeepPartial<DraftPortfolio>
) => {
  return mergeWith({}, state, updatedStateValues, customizer);
};

const draftPortfolioReducer: EffectReducer<
  DraftPortfolio,
  DraftPortfolioEvent,
  DraftPortfolioEffect
> = (state, event, exec) => {
  function buildNextState(updatedStateValues: DeepPartial<DraftPortfolio>) {
    return buildNextDraftPortfolioState(state, updatedStateValues);
  }

  // Mark state as "dirty" for any changes that occur after bootstrapping. Re-bootstrapping is
  // prevented when the state is dirty so changing the bootstrapping state must not change the dirty
  // state or there will be a deadlock.
  if (event.type !== 'SET_BOOTSTRAPPING_STATE') {
    exec({ type: 'allChangesEffect' });
  }

  switch (event.type) {
    case 'SET_BOOTSTRAPPING_STATE':
      return {
        ...state,
        bootstrappingState: event.bootstrappingState,
      };
    case 'SET_BOOTSTRAP_TYPE':
      return {
        ...state,
        bootstrapType: event.bootstrapType,
      };
    case 'SET_DIRTY_TRUE':
      return {
        ...state,
        dirty: true,
      };
    case 'SET_NEW_CLIENT_INFO':
      return buildNextState({
        newClientInfo: event.newClientInfo,
      });
    case 'SET_NEW_HOUSEHOLD_INFO':
      return buildNextState({
        newHouseholdInfo: event.newHouseholdInfo,
      });
    case 'SET_EXISTING_CLIENT':
      exec({
        type: 'changeClientIdEffect',
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        prevClientId: state.constructionInfo.clientId,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        nextClientId: event.clientId,
      });
      return buildNextState({
        constructionInfo: {
          clientId: event.clientId,
        },
      });
    case 'SET_EXISTING_CLIENT_AND_ACCOUNT': {
      exec({
        type: 'changeAccountEffect',
        prevExistingPortfolio: state.constructionInfo.existingPortfolio,
        nextExistingPortfolio: event.existingPortfolio,
      });
      const nextInput: DeepPartial<DraftPortfolio> = {
        constructionInfo: {
          clientId: event.clientId,
          existingPortfolio: event.existingPortfolio,
        },
      };
      if (!event.existingPortfolio || event.existingPortfolio === 'sample-portfolio') {
        throw new Error(
          'Programming error: existingPortfolio must be set for SET_EXISTING_CLIENT_AND_ACCOUNT'
        );
      }
      return buildNextState(nextInput);
    }
    case 'SET_PROPOSAL_TYPE': {
      const newExistingPortfolioValue = event.proposalType === 'sample' ? 'sample-portfolio' : null;
      exec({
        type: 'changeAccountEffect',
        prevExistingPortfolio: state.constructionInfo.existingPortfolio,
        nextExistingPortfolio: newExistingPortfolioValue,
      });
      const nextInput: DeepPartial<DraftPortfolio> = {
        constructionInfo: {
          existingPortfolio: newExistingPortfolioValue,
        },
      };

      return buildNextState(nextInput);
    }
    case 'SET_ACCOUNT': {
      exec({
        type: 'changeAccountEffect',
        prevExistingPortfolio: state.constructionInfo.existingPortfolio,
        nextExistingPortfolio: event.existingPortfolio,
      });
      const nextInput: DeepPartial<DraftPortfolio> = {
        constructionInfo: {
          existingPortfolio: event.existingPortfolio,
        },
      };
      return buildNextState(nextInput);
    }
    case 'UPDATE_ACCOUNT_CLIENT_ID': {
      const { existingPortfolio } = state.constructionInfo;
      if (existingPortfolio == null || existingPortfolio === 'sample-portfolio') {
        return state;
      }
      return buildNextState({
        constructionInfo: {
          existingPortfolio: {
            ...existingPortfolio,
            viseClientId: event.viseClientId,
          },
        },
      });
    }
    case 'SET_INITIAL_VALUE':
      return buildNextState({
        constructionInfo: {
          initialValue: event.initialValue,
        },
      });
    case 'SET_LOCKED_POSITIONS':
      return buildNextState({
        lockedPositions: event.lockedPositions,
      });
    case 'SET_INVESTMENT_TIMELINE':
      return buildNextState({
        constructionInfo: {
          investmentTimeline: event.investmentTimeline,
        },
      });
    case 'SET_RISK':
      return buildNextState({
        constructionInfo: {
          risk: event.risk,
        },
      });
    case 'SET_USE_GLIDE_PATH':
      return buildNextState({
        constructionInfo: {
          useGlidePath: event.useGlidePath,
        },
      });
    case 'SET_USE_SAVED_ALLOCATION_TEMPLATE':
      // TODO(rteammco): Probably will need to add an effect like 'changePickSavedStrategyEffect' below
      return buildNextState({
        useAllocationTemplate: event.useAllocationTemplate,
      });
    case 'SET_PICK_SAVED_STRATEGY':
      exec({
        type: 'changePickSavedStrategyEffect',
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
        prevValue: state.pickSavedStrategy,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
        nextValue: event.pickSavedStrategy,
      });
      return buildNextState({
        pickSavedStrategy: event.pickSavedStrategy,
      });
    case 'UNSET_SAVED_STRATEGY':
      return buildNextState({
        constructionInfo: pick(initialPce2DraftPortfolioExtension.constructionInfo, [
          'activeTilt',
          'assetClasses',
          'assetClassConcentrationLimits',
          'excludedCountries',
          'excludedIndustries',
          'excludedSectors',
          'overlay',
          'restrictedStocks',
          'portfolioMaxSize',
          'tSliceEnabled',
          'etfExclusiveAssetClasses',
          'minSymbolAssetClasses',
        ]),
        constructionMetadata: {
          restrictionsOrigins: initialDraftPortfolio.constructionMetadata.restrictionsOrigins,
          skipQuestionnaire: initialDraftPortfolio.constructionMetadata.skipQuestionnaire,
        },
        ...pick(initialDraftPortfolio, [
          'strategyId',
          'strategyInternalUuid',
          'overwriteExistingStrategy',
          'savedStrategy',
          'newSavedStrategyName',
        ]),
      });
    case 'SET_SAVED_STRATEGY': {
      exec({
        type: 'applySavedStrategyEffect',
        strategy: event.savedStrategy,
      });
      const newAssetClassExclusions =
        event.savedStrategy.pceVersion === 'pce2'
          ? new Set(event.savedStrategy.assetClassConcentrationLimits.exclusions ?? [])
          : undefined;
      if (!isEmpty(newAssetClassExclusions)) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Set<AssetClassKey> | undefined' ... Remove this comment to see the full error message
        flipAllAssetClassesWhereApplicable(newAssetClassExclusions);
      }

      const activeTiltForDisplay = computeActiveTiltForDisplay(
        event.savedStrategy.tiltAmount,
        event.savedStrategy.tilt === 'DIVIDEND' ? 'dividend' : 'multi-factor',
        riskToRiskSlider(state.constructionInfo.risk),
        event.savedStrategy.etfExclusive,
        state.accountSize === 'TINY' || state.accountSize === 'SMALL',
        false
      );

      return buildNextState({
        constructionInfo: merge(strategyToPartialConstructionInfo(event.savedStrategy), {
          assetClassConcentrationLimits:
            event.savedStrategy.pceVersion === 'pce1'
              ? undefined
              : // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                { exclusions: Array.from(newAssetClassExclusions) },
          activeTilt:
            activeTiltForDisplay.portfolioType === 'LOSS_TOLERANCE'
              ? activeTiltForDisplay.activeTilt
              : undefined,
        }),
        strategyId: event.savedStrategy.id,
        strategyInternalUuid: event.savedStrategy.internalUuid,
        savedStrategy: event.savedStrategy,
        overwriteExistingStrategy: initialDraftPortfolio.overwriteExistingStrategy,
        newSavedStrategyName: initialDraftPortfolio.newSavedStrategyName,
      });
    }
    case 'SET_OVERLAY':
      return buildNextState({
        constructionInfo: {
          overlay: event.overlay,
        },
      });
    case 'SET_ASSET_CLASSES':
      exec({
        type: 'changeAssetClassesEffect',
        assetClasses: event.assetClasses,
      });
      return buildNextState({
        constructionInfo: {
          assetClasses: event.assetClasses,
        },
      });
    case 'SET_ETF_EXCLUSIVE':
      exec({
        type: 'changeEtfExclusiveEffect',
        etfExclusive: event.etfExclusive,
      });
      return buildNextState({
        constructionInfo: {
          etfExclusive: event.etfExclusive,
        },
      });
    case 'SET_ASSET_CLASS_ETF_EXCLUSIVE': {
      const etfExclusiveAssetClasses = event.etfExclusive
        ? uniq([...state.constructionInfo.etfExclusiveAssetClasses, event.assetClass])
        : state.constructionInfo.etfExclusiveAssetClasses.filter((key) => key !== event.assetClass);

      const excludedSingleSecurityClasses = SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.filter(
        (key) =>
          (state.constructionInfo.assetClassConcentrationLimits?.exclusions || []).find(
            (key2) => key === key2
          )
      );

      const etfExclusive =
        etfExclusiveAssetClasses.length ===
        NUMBER_OF_SINGLE_SECURITY_ASSET_CLASSES - excludedSingleSecurityClasses.length;

      exec({
        type: 'changeEtfExclusiveEffect',
        etfExclusive,
      });
      return buildNextState({
        constructionInfo: {
          etfExclusiveAssetClasses,
          etfExclusive,
          minSymbolAssetClasses: etfExclusive
            ? []
            : // If a class becomes etf-exclusive, we should remove the minimize-ticker setting since it only applies to single-securities
              state.constructionInfo.minSymbolAssetClasses.filter(
                (key) => !etfExclusiveAssetClasses.find((key2) => key === key2)
              ),
          activeTilt: etfExclusive ? undefined : state.constructionInfo.activeTilt,
        },
      });
    }
    case 'SET_MIN_SYMBOL_ASSET_CLASS': {
      // Add or remove the asset class from the list of classes that should have a minimized number of tickers
      const minSymbolAssetClasses = event.minSymbol
        ? uniq([...state.constructionInfo.minSymbolAssetClasses, event.assetClass])
        : state.constructionInfo.minSymbolAssetClasses.filter((key) => key !== event.assetClass);
      return buildNextState({
        constructionInfo: {
          minSymbolAssetClasses,
        },
      });
    }

    // TODO clean up when custom templates released
    case 'SET_EXCLUDED_SECTORS_OLD': {
      const {
        constructionMetadata: { restrictionsOrigins: oldRestrictionsOrigins },
        constructionInfo: { excludedSectors: oldExcludedSectors },
      } = state;
      return buildNextState(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ constructionInfo: { [x: string... Remove this comment to see the full error message
        calculateUpdatedRestrictions(
          oldRestrictionsOrigins,
          oldExcludedSectors ?? [],
          event.sectorsToAdd || [],
          event.sectorsToDelete || [],
          'excludedSectors',
          event.origin
        )
      );
    }

    case 'SET_EXCLUDED_SECTORS': {
      const {
        constructionMetadata: { restrictionsOrigins: oldRestrictionsOrigins },
        constructionInfo: { excludedSectors: oldExcludedSectors },
      } = state;
      const oldSectorsSet = new Set(oldExcludedSectors);
      const newSectorsSet = new Set(event.excludedSectors);
      const sectorsToAdd =
        event.sectorsToAdd ?? event.excludedSectors?.filter((x) => !oldSectorsSet.has(x));
      const sectorsToDelete =
        event.sectorsToDelete ?? oldExcludedSectors?.filter((x) => !newSectorsSet.has(x));
      return buildNextState(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ constructionInfo: { [x: string... Remove this comment to see the full error message
        calculateUpdatedRestrictions(
          oldRestrictionsOrigins,
          oldExcludedSectors ?? [],
          sectorsToAdd ?? [],
          sectorsToDelete ?? [],
          'excludedSectors',
          event.origin
        )
      );
    }
    case 'SET_RESTRICTED_STOCKS': {
      const {
        constructionMetadata: { restrictionsOrigins: oldRestrictionsOrigins },
        constructionInfo: { restrictedStocks: oldRestrictedStocks },
      } = state;
      const oldStocksSet = new Set(oldRestrictedStocks);
      const newStocksSet = new Set(event.restrictedStocks);
      const stocksToAdd = event.restrictedStocks.filter((s) => !oldStocksSet.has(s));
      const stocksToDelete = oldRestrictedStocks?.filter((s) => !newStocksSet.has(s));
      return buildNextState(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ constructionInfo: { [x: string... Remove this comment to see the full error message
        calculateUpdatedRestrictions(
          oldRestrictionsOrigins,
          oldRestrictedStocks ?? [],
          stocksToAdd,
          stocksToDelete ?? [],
          'restrictedStocks',
          event.origin
        )
      );
    }
    case 'DELETE_SOME_RESTRICTED_STOCKS': {
      const {
        constructionMetadata: { restrictionsOrigins: oldRestrictionsOrigins },
        constructionInfo: { restrictedStocks: oldRestrictedStocks },
      } = state;
      return buildNextState(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ constructionInfo: { [x: string... Remove this comment to see the full error message
        calculateUpdatedRestrictions(
          oldRestrictionsOrigins,
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[] | undefined' is not ass... Remove this comment to see the full error message
          oldRestrictedStocks,
          [],
          event.stocksToDelete,
          'restrictedStocks',
          event.origin
        )
      );
    }
    case 'SET_RESTRICTED_ESG_AREAS': {
      const {
        constructionMetadata: { restrictionsOrigins: oldRestrictionsOrigins },
        constructionInfo: { excludedEsgAreas: oldEsgAreas },
      } = state;
      const oldEsgSet = new Set(oldEsgAreas);

      const newEsgSet = new Set(event.restrictedEsgAreas);
      const esgAreasToAdd = event.restrictedEsgAreas.filter((s) => !oldEsgSet.has(s));

      const esgAreasToDelete = oldEsgAreas?.filter((s) => !newEsgSet.has(s));

      const updatedRestrictions = calculateUpdatedRestrictions(
        oldRestrictionsOrigins,
        oldEsgAreas ?? [],
        esgAreasToAdd ?? [],
        esgAreasToDelete ?? [],
        'excludedEsgAreas'
      );

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ constructionInfo: { [x: string... Remove this comment to see the full error message
      return buildNextState(updatedRestrictions);
    }

    case 'SET_EXCLUDED_INDUSTRIES': {
      const {
        constructionMetadata: { restrictionsOrigins: oldRestrictionsOrigins },
        constructionInfo: { excludedIndustries: oldExcludedIndustries },
      } = state;
      const oldIndustriesSet = new Set(oldExcludedIndustries);
      const newIndustriesSet = new Set(event.excludedIndustries);
      const industriesToAdd =
        event.industriesToAdd ?? event.excludedIndustries?.filter((x) => !oldIndustriesSet.has(x));
      const industriesToDelete =
        event.industriesToDelete ?? oldExcludedIndustries?.filter((x) => !newIndustriesSet.has(x));
      return buildNextState(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ constructionInfo: { [x: string... Remove this comment to see the full error message
        calculateUpdatedRestrictions(
          oldRestrictionsOrigins,
          oldExcludedIndustries ?? [],
          industriesToAdd ?? [],
          industriesToDelete ?? [],
          'excludedIndustries',
          event.origin
        )
      );
    }
    case 'CLEAR_ALL_RESTRICTIONS':
      return buildNextState(DRAFT_PORTFOLIO_CLEARED_RESTRICTIONS_UPDATED_STATE);
    case 'SET_SKIP_QUESTIONNAIRE': {
      if (event.skipQuestionnaire === false) {
        /** Covers the case where:
        /* User skips questionnaire -> sets restrictions -> goes back and does not skip
        /* questionnaire -> previously selected restrictions should show up on q6.
        */
        return buildNextState({
          constructionMetadata: {
            skipQuestionnaire: event.skipQuestionnaire,
            restrictionsOrigins: {
              ...initialDraftPortfolio.constructionMetadata.restrictionsOrigins,
              q6: {
                // @ts-expect-error ts-migrate(2488) FIXME: Type 'string[] | undefined' must have a '[Symbol.i... Remove this comment to see the full error message
                restrictedStocks: [...state.constructionInfo.restrictedStocks],
                excludedIndustries: [...state.constructionInfo.excludedIndustries],
                excludedSectors: [...state.constructionInfo.excludedSectors],
              },
            },
          },
        });
      }
      /** Clear restrictions origins mapping if user
       * decides to skip questionnaire
       */
      const nextState = buildNextState({
        constructionMetadata: {
          skipQuestionnaire: event.skipQuestionnaire,
        },
      });
      delete nextState.constructionMetadata.restrictionsOrigins;
      return nextState;
    }
    case 'SET_NEW_SAVED_STRATEGY_NAME': {
      return buildNextState({
        newSavedStrategyName: event.name,
      });
    }
    case 'SET_STRATEGY_METADATA':
      return buildNextState({
        strategyId: event.strategyId,
        strategyInternalUuid: event.strategyInternalUuid,
        overwriteExistingStrategy: event.overwriteExistingStrategy,
        newSavedStrategyName: event.newSavedStrategyName,
      });
    case 'SET_UNSET_PROPERTIES_TO_DEFAULT': {
      const defaultDraftPortfolioValues =
        state.accountSize === 'SMALL'
          ? DEFAULT_DRAFT_PORTFOLIO_VALUES_SMALL
          : DEFAULT_DRAFT_PORTFOLIO_VALUES;
      const changedProperties = event.propertyPaths.reduce(
        (acc, nextPath) =>
          get(state, nextPath) ===
          get({ ...initialDraftPortfolio, ...initialPce2DraftPortfolioExtension }, nextPath)
            ? set(acc, nextPath, get(defaultDraftPortfolioValues, nextPath))
            : acc,
        {}
      );
      return buildNextState(changedProperties);
    }
    case 'SET_EDIT_MODE_EXISTING_SAVED_STRATEGY': {
      return buildNextState({
        pickSavedStrategy: event.isBootstrappedStrategyOutdated ? null : true,
        savedStrategy: event.savedStrategy,
        bootstrappedStrategy: event.savedStrategy,
        strategyId: event.strategyId,
        strategyInternalUuid: event.strategyInternalUuid,
        isBootstrappedStrategyOutdated: event.isBootstrappedStrategyOutdated,
      });
    }
    case 'SET_EXISTING_PORTFOLIO_INTELLIGENCE': {
      const portfolioIntelligence = event.portfolioIntelligence[0] as PortfolioIntelligenceCommon &
        PCE2SpecificPortfolioIntelligence;
      const { pceVersion, constructionInfo, constructionMetadata, constructionRequest } =
        portfolioIntelligence;
      let allocation = BLANK_ASSET_CLASS_ALLOCATION;
      const lockedPositions = portfolioIntelligence.constructionRequest.doNotSellSymbolsList || [];
      if (portfolioIntelligence.proposalType === 'full') {
        allocation =
          constructionInfo.assetAllocation ??
          portfolioIntelligence.constructionResponse.targetAllocation.reduce((obj, alloc) => {
            const newAlloc = { ...obj };
            let prefix = '';
            alloc.assetClass.forEach((feature) => {
              const key = `${prefix}${feature}`;
              newAlloc[key] += alloc.allocation;
              prefix = `${prefix}${feature}/`;
            });
            return newAlloc;
          }, BLANK_ASSET_CLASS_ALLOCATION);
      }

      let isCustomAllocation = false;
      let customAllocations: AssetClassKey[] = [];
      // If there's no asset allocation in the custom risk params it is not custom
      // If there is an asset allocation, it is custom if more asset classes are specified than just the three L1 classes
      // Glide path can have an allocation, but only for L1 classes, so it is not custom
      if (
        portfolioIntelligence.constructionRequest.assetClassAllocationRequest
          ?.customAllocationParams
      ) {
        isCustomAllocation =
          portfolioIntelligence.constructionRequest.assetClassAllocationRequest?.customAllocationParams.allocations.filter(
            (alloc) => {
              const key = alloc.assetClass.join('/');
              return key !== 'EQUITY' && key !== 'FIXED_INCOME' && key !== 'ALTERNATIVES';
            }
          ).length > 0;
        customAllocations = portfolioIntelligence.constructionInfo.customAllocations || [];
      }

      let pce2ConstructionInfoFields: Pce2ConstructionInfo | {};
      let assetClassConcentrationLimits: ConstructionInfo['assetClassConcentrationLimits'];

      let activeTiltForDisplay: ActiveTiltForDisplay;
      if (
        constructionInfo.activeTilt?.tiltType &&
        isNewTiltType(constructionInfo.activeTilt.tiltType)
      ) {
        // "Low volatility" is currently an abstraction for setting all single-security factor tilts to low volatility
        // Unlike multi-factor or dividend tilts we don't have to worry about the default not having been set in the UI
        activeTiltForDisplay = {
          portfolioType: 'LOSS_TOLERANCE',
          activeTilt: {
            tiltType: constructionInfo.activeTilt.tiltType,
            tiltAmount: constructionInfo.activeTilt.tiltAmount,
            isEnabled: constructionInfo.activeTilt.isEnabled,
          },
        };
      } else {
        activeTiltForDisplay = computeActiveTiltForDisplay(
          constructionRequest.activeTilt,
          constructionRequest.dividendTilt ? 'dividend' : 'multi-factor',
          riskToRiskSlider(constructionInfo.risk),
          constructionInfo.etfExclusive,
          portfolioIntelligence.constructionInfo.smallAccount,
          constructionRequest.activeTilt === 0
        );
      }
      if (portfolioIntelligence.pceVersion === 'pce2') {
        const { constructionRequest } = portfolioIntelligence;
        const { taxOptions } = constructionRequest;
        // Add in parent nodes to asset class exclusions if all their leaves are excluded:
        const newExclusions = new Set(
          constructionInfo.assetClassConcentrationLimits?.exclusions ?? []
        );
        flipAllAssetClassesWhereApplicable(newExclusions);
        assetClassConcentrationLimits = {
          ...constructionInfo.assetClassConcentrationLimits,
          isEnabled: constructionInfo.assetClassConcentrationLimits?.isEnabled ?? false,
          exclusions: Array.from(newExclusions),
        };
        let etfExclusiveAssetClasses: AssetClassKey[] | undefined;
        if (event.fillEtfExclusiveAssetClasses && constructionRequest.etfOnly) {
          // Backfill in the asset class keys in case the portfolio was created before we enabled flipping ETFs for individual asset classes
          // We don't want to do this before we flip the flag since it would be uneditable
          etfExclusiveAssetClasses = SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.filter(
            (key) => !newExclusions.has(key)
          );
        }

        pce2ConstructionInfoFields = {
          autoTlh: taxOptions?.autoTlh ?? null,
          capitalGainsLimits: {
            longTermGainsLimits: {
              maximumAmount: taxOptions?.maxCapGainsLong ?? null,
              shouldLimitGains: taxOptions?.maxCapGainsLong != null,
              shouldLimitSmallestAmount: false,
            },
            shortTermGainsLimits: {
              maximumAmount: taxOptions?.maxCapGainsShort ?? null,
              shouldLimitGains: taxOptions?.maxCapGainsShort != null,
              shouldLimitSmallestAmount: false,
            },
          },
          etfExclusiveAssetClasses:
            etfExclusiveAssetClasses ||
            constructionRequest.doNotPromoteAssetClasses.map((assetClass) => assetClass.join('/')),
          minSymbolAssetClasses: constructionRequest.minSymbolAssetClasses.map((assetClass) =>
            assetClass.join('/')
          ),
          isCustomAllocation,
          customAllocations,
        };
      } else {
        // Not pce2:
        assetClassConcentrationLimits = undefined;
        pce2ConstructionInfoFields = {};
      }
      let bootstrappedAccountSize = 'UNKNOWN';
      if (portfolioIntelligence.pceVersion === 'pce2') {
        bootstrappedAccountSize = constructionInfo.smallAccount ? 'SMALL' : 'FULL';
      }
      const existingIntelligence = {
        ...state,
        constructionInfo: merge({}, state.constructionInfo, {
          ...omit(constructionInfo, ['immediateDrawdown']), // The cash distribution is a one-off parameter we don't want to copy
          ...pce2ConstructionInfoFields,
          assetClassConcentrationLimits,
          assetAllocation: allocation,
          excludedIndustries: constructionInfo.excludedIndustries ?? [],
          existingPortfolio:
            constructionInfo.existingPortfolio == null ? 'sample-portfolio' : event.account,
          // TODO(nkang): Legacy portfolios have a string `initialValue` while current ones have a
          // number `initialValue`. Should run a backfill to fix this. In the meantime, cast
          // `initialValue` back to number here to match the DraftPortfolio type.
          initialValue: +constructionInfo.initialValue,
          activeTilt:
            activeTiltForDisplay.portfolioType === 'LOSS_TOLERANCE'
              ? activeTiltForDisplay.activeTilt
              : undefined,
        } as Partial<ConstructionInfo>) as ConstructionInfo,
        editModeOriginalInvestmentTimeline: constructionInfo.investmentTimeline,
        constructionMetadata: merge({}, state.constructionMetadata, constructionMetadata),
        lockedPositions,
        isEditMode: true,
        pickSavedStrategy: false,
        pceVersion,
        accountSize: bootstrappedAccountSize,
        name: portfolioIntelligence.name,
        boostrappedPortfolioIntelligenceId: event.portfolioIntelligenceId,
      } as DraftPortfolio;
      // Ensure backwards compatibility with Portfolio Creator UI v1:
      return mergeWith(
        existingIntelligence,
        DEFAULT_DRAFT_PORTFOLIO_VALUES,
        (oldValue: unknown, newValue: unknown) =>
          // Overwrite `null` or `undefined` fields with their Portfolio Creator 2 default value, if one exists
          oldValue == null ? newValue : oldValue
      );
    }
    case 'PCE2_SET_CONCENTRATION_LIMITS_IS_ENABLED': {
      return buildNextState({
        constructionInfo: {
          assetClassConcentrationLimits: {
            ...((state.constructionInfo as Pce2ConstructionInfo)?.assetClassConcentrationLimits ||
              {}),
            isEnabled: event.isEnabled,
          },
        },
      });
    }
    case 'PCE2_SET_CONCENTRATION_LIMITS_EQUITIES': {
      return buildNextState({
        constructionInfo: {
          assetClassConcentrationLimits: {
            ...((state.constructionInfo as Pce2ConstructionInfo)?.assetClassConcentrationLimits ||
              {}),
            equities: event.equities,
          },
        },
      });
    }
    case 'PCE2_SET_CONCENTRATION_LIMITS_FIXED_INCOME': {
      return buildNextState({
        constructionInfo: {
          assetClassConcentrationLimits: {
            ...((state.constructionInfo as Pce2ConstructionInfo)?.assetClassConcentrationLimits ||
              {}),
            fixedIncome: event.fixedIncome,
          },
        },
      });
    }
    case 'PCE2_SET_CONCENTRATION_LIMITS_ALTERNATIVES': {
      return buildNextState({
        constructionInfo: {
          assetClassConcentrationLimits: {
            ...((state.constructionInfo as Pce2ConstructionInfo)?.assetClassConcentrationLimits ||
              {}),
            alternatives: event.alternatives,
          },
        },
      });
    }
    case 'PCE2_SET_CONCENTRATION_LIMITS_EXCLUSIONS': {
      exec({
        type: 'changeConcentrationLimitsExclusionsEffect',
        exclusions: event.exclusions,
      });
      return buildNextState({
        constructionInfo: {
          assetClassConcentrationLimits: {
            ...((state.constructionInfo as Pce2ConstructionInfo)?.assetClassConcentrationLimits ||
              {}),
            exclusions: event.exclusions,
          },
          etfExclusiveAssetClasses: state.constructionInfo.etfExclusiveAssetClasses?.filter(
            (key) => !event.exclusions?.find((key2) => key === key2)
          ),
          minSymbolAssetClasses: state.constructionInfo.minSymbolAssetClasses?.filter(
            (key) => !event.exclusions?.find((key2) => key === key2)
          ),
          assetAllocation: mapValues(state.constructionInfo.assetAllocation, (num, key) =>
            event.exclusions?.includes(key as AssetClassKey) ? 0 : num
          ),
        },
      });
    }
    case 'SET_ASSET_CLASS_ALLOCATIONS': {
      const {
        allocations: baseAllocations,
        // Set a default during gaps between deploying API and web
        customAllocations = [],
        isCustomAllocation: isCustomAllocationLegacy,
      } = event;
      const allocations = fillInAssetAllocation(baseAllocations);
      // Setting individual allocations makes it custom
      const exclusions: AssetClassKey[] = (
        Object.keys(allocations) as (keyof typeof allocations)[]
      ).filter((key) => allocations[key] === 0);
      const etfExclusiveAssetClasses =
        state.constructionInfo.etfExclusiveAssetClasses?.filter(
          (key) => !exclusions?.find((key2) => key === key2)
        ) || [];
      const excludedSingleSecurityClasses = SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.filter(
        (key) => exclusions.find((key2) => key === key2)
      );
      const etfExclusive =
        etfExclusiveAssetClasses.length ===
        NUMBER_OF_SINGLE_SECURITY_ASSET_CLASSES - excludedSingleSecurityClasses.length;
      exec({
        type: 'changeConcentrationLimitsExclusionsEffect',
        exclusions,
      });
      exec({
        type: 'changeEtfExclusiveEffect',
        etfExclusive,
      });
      const risk =
        (allocations.EQUITY || 0) / ((allocations.EQUITY || 0) + (allocations.FIXED_INCOME || 0));
      const isCustomAllocation = customAllocations.length > 0 || isCustomAllocationLegacy;
      // Traverse the allocation tree and build up the parent allocations as a sum of the children allocations
      return buildNextState({
        constructionInfo: {
          assetAllocation: allocations,
          assetClassConcentrationLimits: {
            ...((state.constructionInfo as Pce2ConstructionInfo)?.assetClassConcentrationLimits ||
              {}),
            exclusions,
          },
          etfExclusiveAssetClasses,
          minSymbolAssetClasses: state.constructionInfo.minSymbolAssetClasses?.filter(
            (key) => !exclusions?.find((key2) => key === key2)
          ),
          etfExclusive,
          isCustomAllocation,
          risk: isNaN(risk) ? 0 : risk / 2,
          activeTilt: etfExclusive ? undefined : state.constructionInfo.activeTilt,
          useGlidePath: isCustomAllocation ? false : state.constructionInfo.useGlidePath,
          investmentTimeline: isCustomAllocation ? null : state.constructionInfo.investmentTimeline,
          customAllocations,
        },
      });
    }
    case 'SET_CASH_CONCENTRATION_LIMIT':
      return buildNextState({
        constructionInfo: {
          concentrationLimits: [
            // Adding "move to cash concentrations" is legacy from the intial implementation of
            // cash allocation from https://bitbucket.org/viseaiteam/web/pull-requests/170
            // Note: this is only be used for PCE1
            ...MoveToCashConcentrations,
            {
              asset_class: 'Cash' as const,
              min: Math.max(0.0, (event.concentrationLimit - 0.01) / 100),
              max: Math.min(1.0, (event.concentrationLimit + 0.01) / 100),
            },
          ],
        },
      });
    case 'PCE2_SET_HARVEST_TAX_LOSSES':
      return buildNextState({
        constructionInfo: {
          autoTlh: event.autoTlh,
        },
      });
    case 'PCE2_SET_LONG_TERM_GAINS_SHOULD_LIMIT_GAINS': {
      const { capitalGainsLimits } = state.constructionInfo as Pce2ConstructionInfo;
      return buildNextState({
        constructionInfo: {
          capitalGainsLimits: {
            ...capitalGainsLimits,
            longTermGainsLimits: {
              ...capitalGainsLimits.longTermGainsLimits,
              shouldLimitGains: event.shouldLimitGains,
            },
          },
        },
      });
    }
    case 'PCE2_SET_LONG_TERM_GAINS_SHOULD_LIMIT_SMALLEST_AMOUNT': {
      const { capitalGainsLimits } = state.constructionInfo as Pce2ConstructionInfo;
      return buildNextState({
        constructionInfo: {
          capitalGainsLimits: {
            ...capitalGainsLimits,
            longTermGainsLimits: {
              ...capitalGainsLimits.longTermGainsLimits,
              shouldLimitSmallestAmount: event.shouldLimitSmallestAmount,
            },
          },
        },
      });
    }
    case 'PCE2_SET_LONG_TERM_GAINS_MAXIMUM_AMOUNT': {
      const { capitalGainsLimits } = state.constructionInfo as Pce2ConstructionInfo;
      return buildNextState({
        constructionInfo: {
          capitalGainsLimits: {
            ...capitalGainsLimits,
            longTermGainsLimits: {
              ...capitalGainsLimits.longTermGainsLimits,
              maximumAmount: event.maximumAmount,
            },
          },
        },
      });
    }
    case 'PCE2_SET_SHORT_TERM_GAINS_SHOULD_LIMIT_GAINS': {
      const { capitalGainsLimits } = state.constructionInfo as Pce2ConstructionInfo;
      return buildNextState({
        constructionInfo: {
          capitalGainsLimits: {
            ...capitalGainsLimits,
            shortTermGainsLimits: {
              ...capitalGainsLimits.shortTermGainsLimits,
              shouldLimitGains: event.shouldLimitGains,
            },
          },
        },
      });
    }
    case 'PCE2_SET_SHORT_TERM_GAINS_SHOULD_LIMIT_SMALLEST_AMOUNT': {
      const { capitalGainsLimits } = state.constructionInfo as Pce2ConstructionInfo;
      return buildNextState({
        constructionInfo: {
          capitalGainsLimits: {
            ...capitalGainsLimits,
            shortTermGainsLimits: {
              ...capitalGainsLimits.shortTermGainsLimits,
              shouldLimitSmallestAmount: event.shouldLimitSmallestAmount,
            },
          },
        },
      });
    }
    case 'PCE2_SET_SHORT_TERM_GAINS_MAXIMUM_AMOUNT': {
      const { capitalGainsLimits } = state.constructionInfo as Pce2ConstructionInfo;
      return buildNextState({
        constructionInfo: {
          capitalGainsLimits: {
            ...capitalGainsLimits,
            shortTermGainsLimits: {
              ...capitalGainsLimits.shortTermGainsLimits,
              maximumAmount: event.maximumAmount,
            },
          },
        },
      });
    }
    case 'SET_EXCLUDED_COUNTRIES': {
      const {
        constructionMetadata: { restrictionsOrigins: oldRestrictionsOrigins },
      } = state;
      const { excludedCountries: oldRestrictedCountries } =
        state.constructionInfo as Pce2ConstructionInfo;
      // Explicity set to `null` as [] will still show the Countries section (with "None selected") in the summary
      if (event.excludedCountries == null) {
        return buildNextState({ constructionInfo: { excludedCountries: null } });
      }
      const oldCountrySet = new Set(oldRestrictedCountries);
      const newCountrySet = new Set(event.excludedCountries);
      const countriesToAdd = event.excludedCountries.filter((s) => !oldCountrySet.has(s));
      const countriesToDelete = (oldRestrictedCountries ?? []).filter((s) => !newCountrySet.has(s));
      return buildNextState(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ constructionInfo: { [x: string... Remove this comment to see the full error message
        calculateUpdatedRestrictions(
          oldRestrictionsOrigins,
          oldRestrictedCountries ?? [],
          countriesToAdd,
          countriesToDelete,
          'excludedCountries',
          event.origin
        )
      );
    }
    case 'DELETE_SOME_EXCLUDED_COUNTRIES': {
      const {
        constructionMetadata: { restrictionsOrigins: oldRestrictionsOrigins },
      } = state;
      const { excludedCountries: oldRestrictedCountries } =
        state.constructionInfo as Pce2ConstructionInfo;
      return buildNextState(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ constructionInfo: { [x: string... Remove this comment to see the full error message
        calculateUpdatedRestrictions(
          oldRestrictionsOrigins,
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Country[] | undefined' is not as... Remove this comment to see the full error message
          oldRestrictedCountries,
          [],
          event.countriesToDelete,
          'excludedCountries',
          event.origin
        )
      );
    }
    case 'RESET_DRAFT_PORTFOLIO': {
      return buildNextState({
        ...initialDraftPortfolio,
        ...initialPce2DraftPortfolioExtension,
        bootstrappingState: 'READY',
      });
    }
    case 'PCE2_SET_ACTIVE_TILT_AMOUNT': {
      return buildNextState({
        constructionInfo: {
          activeTilt: { tiltAmount: event.tiltAmount },
        },
      });
    }
    case 'PCE2_SET_ACTIVE_TILT_TYPE': {
      return buildNextState({
        constructionInfo: {
          activeTilt: { tiltType: event.tiltType },
        },
      });
    }
    case 'PCE2_SET_ACTIVE_TILT_IS_ENABLED': {
      return buildNextState({
        constructionInfo: {
          activeTilt: { isEnabled: event.isEnabled },
        },
      });
    }
    case 'PCE2_UNSET_ACTIVE_TILT': {
      return {
        ...state,
        constructionInfo: {
          ...state.constructionInfo,
          activeTilt: undefined,
        },
      } as Pce2DraftPortfolio;
    }
    case 'SET_ACCOUNT_SIZE': {
      const prevAccountSize = state.accountSize;
      const nextAccountSize = event.accountSize;
      exec({
        prevAccountSize: prevAccountSize ?? 'UNKNOWN',
        nextAccountSize,
        type: 'changeAccountSizeEffect',
      });
      return buildNextState({
        ...state,
        accountSize: nextAccountSize,
      });
    }
    case 'SET_ORIGINAL_DRAFT_PORTFOLIO': {
      const orginalDp = cloneDeep(state);
      orginalDp.originalDraftPortfolio = undefined;
      return buildNextState({
        originalDraftPortfolio: orginalDp,
      });
    }
    case 'SET_PREVIOUS_DRAFT_PORTFOLIO': {
      const previousDp = cloneDeep(state);
      previousDp.previousDraftPortfolio = undefined;
      return buildNextState({
        previousDraftPortfolio: previousDp,
      });
    }
    case 'ADD_RESTRICTIONS_TEMPLATE': {
      const { tickers, countries, sectors, subSectors, esgAreas, id } = event.restrictions;
      const {
        restrictedStocks: oldStocks,
        excludedIndustries: oldIndustries,
        excludedSectors: oldSectors,
        excludedCountries: oldCountries,
        excludedEsgAreas: oldEsgAreas,
      } = state.constructionInfo;

      const newTemplateIds = [...(state.restrictionsTemplatesIds ?? []), id];

      const newTemplates = [...(state.restrictionsTemplates ?? []), event.restrictions];

      const restrictedStocks = Array.from(new Set([...(oldStocks ?? []), ...tickers]));
      const excludedIndustries = Array.from(new Set([...(oldIndustries ?? []), ...subSectors]));
      const excludedSectors = Array.from(new Set([...(oldSectors ?? []), ...(sectors ?? [])]));
      const excludedCountries = Array.from(new Set([...(oldCountries ?? []), ...countries]));
      const excludedEsgAreas = Array.from(new Set([...(oldEsgAreas ?? []), ...(esgAreas ?? [])]));

      return buildNextState({
        constructionInfo: {
          ...state.constructionInfo,
          excludedCountries,
          restrictedStocks,
          excludedSectors,
          excludedIndustries,
          excludedEsgAreas,
        },
        restrictionsTemplates: newTemplates,
        restrictionsTemplatesIds: newTemplateIds,
      });
    }
    case 'REMOVE_RESTRICTIONS_TEMPLATE': {
      const newTemplates = state.restrictionsTemplates?.filter((t) => t.id !== event.templateId);
      const combinedRestrictions = newTemplates?.reduce((t1, t2) => {
        return {
          ...t1,
          tickers: [...(t1.tickers ?? []), ...(t2.tickers ?? [])],
          countries: [...(t1.countries ?? []), ...(t2.countries ?? [])],
          subSectors: [...(t1.subSectors ?? []), ...(t2.subSectors ?? [])],
          sectors: [...(t1.sectors ?? []), ...(t2.sectors ?? [])],
          esgAreas: [...(t1.esgAreas ?? []), ...(t2.esgAreas ?? [])],
        };
      }, newTemplates[0]);

      const combinedRestrictionsWithAdditionalRestrictions = state.constructionInfo
        .additionalRestrictions
        ? {
            tickers: uniq([
              ...(combinedRestrictions?.tickers || []),
              ...state.constructionInfo.additionalRestrictions.restrictedStocks,
            ]),
            countries: uniq([
              ...(combinedRestrictions?.countries || []),
              ...state.constructionInfo.additionalRestrictions.excludedCountries,
            ]),
            sectors: uniq([
              ...(combinedRestrictions?.sectors || []),
              ...state.constructionInfo.additionalRestrictions.excludedSectors,
            ]),
            subSectors: uniq([
              ...(combinedRestrictions?.subSectors || []),
              ...state.constructionInfo.additionalRestrictions.excludedIndustries,
            ]),
            esgAreas: uniq([
              ...(combinedRestrictions?.esgAreas || []),
              ...state.constructionInfo.additionalRestrictions.excludedEsgAreas,
            ]),
          }
        : combinedRestrictions;

      const newTemplateIds = (state.restrictionsTemplatesIds ?? []).filter(
        (i) => i !== event.templateId
      );

      return buildNextState({
        restrictionsTemplatesIds: newTemplateIds.length ? newTemplateIds : null,
        restrictionsTemplates: newTemplates,
        constructionInfo: {
          restrictedStocks: Array.from(
            new Set(combinedRestrictionsWithAdditionalRestrictions?.tickers)
          ),
          excludedCountries: Array.from(
            new Set(combinedRestrictionsWithAdditionalRestrictions?.countries)
          ),
          excludedSectors: Array.from(
            new Set(combinedRestrictionsWithAdditionalRestrictions?.sectors)
          ),
          excludedIndustries: Array.from(
            new Set(combinedRestrictionsWithAdditionalRestrictions?.subSectors)
          ),
          excludedEsgAreas: Array.from(
            new Set(combinedRestrictionsWithAdditionalRestrictions?.esgAreas)
          ),
        },
      });
    }
    case 'ADD_ALLOCATIONS_TEMPLATE': {
      return buildNextState({
        allocationTemplate: event.template,
        allocationsTemplateId: event.template.id,
      });
    }
    case 'REMOVE_ALLOCATIONS_TEMPLATE': {
      return buildNextState({
        allocationTemplate: null,
        allocationsTemplateId: null,
      });
    }
    case 'SET_ADDITIONAL_RESTRICTIONS': {
      return buildNextState({
        constructionInfo: {
          ...state.constructionInfo,
          additionalRestrictions: event.restrictions,
        },
      });
    }
    case 'SET_MMF': {
      return buildNextState({ constructionInfo: { ...state.constructionInfo, mmf: event.mmf } });
    }
    case 'SET_LT_FEDERAL_TAX_RATE': {
      return buildNextState({
        constructionInfo: { longTermFederalTaxRate: event.longTermFederalTaxRate },
      });
    }
    case 'SET_ST_FEDERAL_TAX_RATE': {
      return buildNextState({
        constructionInfo: { shortTermFederalTaxRate: event.shortTermFederalTaxRate },
      });
    }
    case 'SET_LT_STATE_TAX_RATE': {
      return buildNextState({
        constructionInfo: { longTermStateTaxRate: event.longTermStateTaxRate },
      });
    }
    case 'SET_ST_STATE_TAX_RATE': {
      return buildNextState({
        constructionInfo: { shortTermStateTaxRate: event.shortTermStateTaxRate },
      });
    }
    case 'MIGRATE_SMALL_ACCOUNT': {
      return buildNextState({
        accountSize: 'FULL',
        constructionInfo: {
          restrictedStocks: null,
          excludedCountries: null,
          excludedEsgAreas: null,
          excludedIndustries: null,
          excludedSectors: null,
          autoTlh: null,
          assetAllocation: {},
        },
      });
    }
    default:
      throw new Error('Unrecognized action type');
  }
};

declare global {
  interface Window {
    draftPortfolio: DraftPortfolio;
  }
}

const useDraftPortfolioReducer = (initialState: Partial<DraftPortfolio>) => {
  const portfolio = useEffectReducer(
    draftPortfolioReducer,
    {
      ...initialDraftPortfolio,
      ...initialPce2DraftPortfolioExtension,
      ...initialState,
    },
    {
      allChangesEffect: draftPortfolioEffects.allChangesEffect,
      changeAccountEffect: draftPortfolioEffects.changeAccountEffect,
      changeAccountSizeEffect: draftPortfolioEffects.changeAccountSizeEffect,
      changeAssetClassesEffect: draftPortfolioEffects.changeAssetClassesEffect,
      changeClientIdEffect: draftPortfolioEffects.changeClientIdEffect,
      changePCEVersionEffect: draftPortfolioEffects.changePCEVersionEffect,
      changeEtfExclusiveEffect: draftPortfolioEffects.changeEtfExclusiveEffect,
      changeNewClientInfoEffect: draftPortfolioEffects.changeNewClientInfoEffect,
      changePickSavedStrategyEffect: draftPortfolioEffects.changePickSavedStrategyEffect,
      applySavedStrategyEffect: draftPortfolioEffects.applySavedStrategyEffect,
      changeConcentrationLimitsExclusionsEffect:
        draftPortfolioEffects.changeConcentrationLimitsExclusionsEffect,
    }
  );
  if (process.env.NODE_ENV === 'development') {
    [window.draftPortfolio] = portfolio;
  }
  return portfolio;
};

export default useDraftPortfolioReducer;
