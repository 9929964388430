import React from 'react';
import { DeprecatedThemeOptions, createTheme, adaptV4Theme } from '@mui/material/styles';
import { RadioCheckedIcon, RadioIcon } from './RadioIcons';
import { CheckedCheckboxIcon, CheckboxIcon, IndeterminantCheckboxIcon } from './CheckboxIcons';
import { CloseIcon } from './Icons';
import { ReactComponent as CheckCircleIcon } from '../static/images/icons/check-circle.svg';
import { ReactComponent as XCircleIcon } from '../static/images/icons/x-circle.svg';
import { ReactComponent as InfoCircleIcon } from '../static/images/icons/information-circle.svg';

const BORDER_RADIUS_PX = 4;

const BUTTON_PADDING = '12px 16px';
const SHADOW_MD = '0px 4px 8px rgba(0, 0, 0, 0.04)';

const DARK_BLUE_1 = '#1345B9';
const DARK_BLUE_3 = '#05112E';

const GREY_100 = '#f8f8f8';
const GREY_200 = '#E9E9E9';
const GREY_300 = '#D8D8D8';
const GREY_400 = '#999999';
const GREY_500 = '#666666';
const GREY_600 = '#4D4D4D';
const GREY_700 = '#333333';
const GREY_800 = '#131313';

const PALETTE_PRIMARY_DARK = DARK_BLUE_1;
const PALETTE_PRIMARY_LIGHT = '#E0EAFF';
const PALETTE_PRIMARY_MAIN = '#1753DE';

const PALETTE_ERROR_MAIN = '#CF2626'; // Danger Red
const PALETTE_SUCCESS_MAIN = '#078364'; // Happy Green
const PALETTE_WARNING_MAIN = '#FF9A00';

const PALETTE_FEEDBACK_ORANGE_1 = '#FDE9CE';
const PALETTE_FEEDBACK_ORANGE_2 = '#F5A22E';

const PALETTE_LAVENDER = '#CFC2FF';
const PALETTE_LIGHT_PINK = '#F9BBE2';

const SLIDER_THUMB_DIAMETER_PX = 18;

const TEXT_ERROR_COLOR = PALETTE_ERROR_MAIN;
const TEXT_PRIMARY_COLOR = GREY_800;
const TEXT_SECONDARY_COLOR = GREY_500;

const TEXT_P2 = {
  fontFamily:
    '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: 1.25,
};

const TEXT_CAPTION = {
  fontFamily:
    '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.25,
};

const TEXT_P1 = {
  fontFamily:
    '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: 1.25,
};

const TEXT_P3 = {
  fontFamily:
    '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  fontWeight: 500,
  fontSize: '0.75rem',
  lineHeight: 1.25,
};

// 8px spacing base
const SPACING = 8;
function spacing(factor: number) {
  return SPACING * factor;
}

// Default theme reference: https://material-ui.com/customization/default-theme/
const muiThemeConfig = {
  spacing,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      dark: PALETTE_PRIMARY_DARK,
      light: PALETTE_PRIMARY_LIGHT,
      main: PALETTE_PRIMARY_MAIN,
    },
    secondary: {
      light: GREY_400,
      main: GREY_800,
    },
    success: {
      100: '#E8FBF0',
      200: '#0AC295',
      300: '#09A57F',
      400: PALETTE_SUCCESS_MAIN,
      500: PALETTE_SUCCESS_MAIN, // Colors need a main or 500
    },
    error: {
      100: '#FEF6F6',
      200: '#FCD2CF',
      300: '#E57171',
      400: PALETTE_ERROR_MAIN,
      500: PALETTE_ERROR_MAIN, // Colors need a main or 500
    },
    warning: {
      100: '#FEFAE8',
      200: '#FFDA6C',
      300: '#FFB400',
      400: '#E07C02',
      500: PALETTE_WARNING_MAIN,
    },
    orange: {
      light: PALETTE_FEEDBACK_ORANGE_1,
      main: PALETTE_FEEDBACK_ORANGE_2,
      600: '#E07B00',
    },
    blue: {
      100: '#F1F5FE', // Light blue 01
      200: '#E3EBFC', // Light blue 02
      300: '#A3BCF5', // Light blue 03
      400: PALETTE_PRIMARY_MAIN, // Vise blue
      500: PALETTE_PRIMARY_DARK, // Dark blue 01
      600: '#0A235C', // Dark blue 02
      700: '#05112E', // Dark blue 03
    },
    // TODO (awang): consolidate this with synth blue above
    assetBlue: {
      50: '#E3EBFC',
      100: '#BCCEF8',
      200: '#A3BCF5',
      300: '#7A9EF1',
      400: '#5986EE',
      500: '#386EEB',
      600: PALETTE_PRIMARY_MAIN,
      700: '#134AC6',
      800: '#103DA5',
      900: '#0D3184',
      A100: '#0A235C',
      A200: '#05112E',
    },
    purple: {
      50: '#E2D9FF',
      100: PALETTE_LAVENDER,
      200: '#C29DFA',
      300: '#A46CF8',
      400: '#853BF6',
      500: '#670AF4',
      600: '#5208C3',
      700: '#4C157F',
      800: '#280E3F',
      900: '#150231',
    },
    pink: {
      light: PALETTE_LIGHT_PINK,
      main: '#F478C5',
      dark: '#EE34A8',
      100: '#FEF5FB',
    },
    yellow: {
      100: '#FCFDEB',
    },
    mint: {
      main: '#A7ECDB',
      100: '#EAF9FB',
    },
    gold: {
      100: '#FCFAF6',
    },
    accents: {
      magenta: {
        light: PALETTE_LIGHT_PINK,
        main: '#F95CC4',
      },
      lavender: {
        light: '#F7F5FF',
        main: PALETTE_LAVENDER,
        dark: '#7C37FF',
      },
    },
    info: {
      main: PALETTE_PRIMARY_DARK,
    },
    grey: {
      100: GREY_100,
      200: GREY_200,
      300: GREY_300,
      400: GREY_400,
      500: GREY_500,
      600: GREY_600,
      700: GREY_700,
      800: GREY_800,
    },
    text: {
      disabled: GREY_300,
      hint: GREY_400,
      primary: TEXT_PRIMARY_COLOR,
      secondary: TEXT_SECONDARY_COLOR,
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
  }, // -- End palette --
  props: {
    MuiDataGrid: {
      columnHeaderHeight: 42,
      rowHeight: 56,
      hideFooter: true,
      disableColumnMenu: true,
      disableColumnResize: true,
      disableRowSelectionOnClick: true,
    },
    MuiAlert: {
      iconMapping: {
        success: React.createElement(CheckCircleIcon),
        error: React.createElement(XCircleIcon),
        info: React.createElement(InfoCircleIcon),
      },
    },
    MuiChip: {
      deleteIcon: React.createElement(CloseIcon, { display: 'inline-flex' }),
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiMenu: {
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      getContentAnchorEl: undefined,
      transformOrigin: { horizontal: 'right', vertical: 'top' },
    },
    MuiSlider: {
      valueLabelDisplay: 'on',
    },
    MuiDialog: {
      fullWidth: true,
      maxWidth: 'sm',
    },
    MuiCheckbox: {
      checkedIcon: React.createElement(CheckedCheckboxIcon),
      color: 'primary',
      icon: React.createElement(CheckboxIcon),
      indeterminateIcon: React.createElement(IndeterminantCheckboxIcon),
    },
    MuiRadio: {
      checkedIcon: React.createElement(RadioCheckedIcon),
      icon: React.createElement(RadioIcon),
    },
    MuiToggleButtonGroup: {
      size: 'small',
      exclusive: true,
    },
    MuiToggleButton: {
      disableRipple: true,
    },
  }, // -- End props --
  shadows: [
    'none',
    /** 1 same as SM */
    '0px 1px 4px rgba(0, 0, 0, 0.08)',
    /**  2 same as MD */
    SHADOW_MD,
    /** 3 same as LG */
    '0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05)',
    /** 4 same as INNER  */
    'inset 0 2px 4px rgba(0, 0, 0, 0.06)',
    /** 5 same as SM, no y-offset */
    '0 0 2px rgba(0, 0, 0, 0.06), 0 0 3px rgba(0, 0, 0, 0.1)',
    /** 6 all around, no offset */
    '0px 0px 10px rgba(0, 0, 0, 0.1)',
    /** 7 top and bottom */
    '0px 1px 0px #E9E9E9, 0px -1px 0px #E9E9E9',
    /** 8 inset bottom */
    'inset 0px -1px 0px #E9E9E9',
    /** 9 top */
    '0px 0px 8px rgba(0, 0, 0, 0.1)',
    /** 10 bottom */
    '0 4px 2px -2px rgba(0, 0, 0, 0.06)',
    /** TODO: Revisit after product redesign work - use sm for the rest of the shadows. */
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
    '0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1)',
  ], // -- End shadows --
  typography: {
    fontFamily:
      '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: 16,
    fontWeightBold: 500,
    h1: {
      fontFamily:
        '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontWeight: 500,
      fontSize: '2rem',
      lineHeight: 1.25,
    },
    h2: {
      fontFamily:
        '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontWeight: 500,
      fontSize: '1.5rem',
      lineHeight: 1.25,
    },
    h3: {
      fontFamily:
        '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.25,
    },
    h4: {
      fontFamily:
        '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.25,
    },
    h5: {
      fontFamily:
        '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.25,
    },
    h6: {
      fontFamily:
        '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontWeight: 500,
      fontSize: '0.875rem',
      letterSpacing: 1,
      lineHeight: 1.25,
      textTransform: 'uppercase',
    },
    /** NOTE: this is intentionally reversed! body1=P2, body2=P1 */
    /** body1 same as P2 */
    body1: TEXT_P2,
    /** NOTE: this is intentionally reversed! body1=P2, body2=P1 */
    /** body2 same as P1 */
    body2: TEXT_P1,
    body3: TEXT_P3,
    button: {
      fontFamily:
        '"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.25,
      textTransform: undefined,
    },
    caption: TEXT_CAPTION,
  }, // -- End typography --
  shape: {
    borderRadius: BORDER_RADIUS_PX,
  },
  icon: {
    size: {
      default: '20px',
      small: '16px',
      large: '24px',
    },
  },
  overrides: {
    MuiDataGrid: {
      columnHeaderRow: { backgroundColor: GREY_100, color: GREY_600 },
      columnHeader: { padding: `0 ${spacing(3)}px`, fontSize: '0.875rem' },
      cell: { padding: `0 ${spacing(3)}px` },
      root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
      },
      columnSeparator: { display: 'none' },
    },
    MuiAlertTitle: {
      root: {
        fontSize: '1rem',
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiAlert: {
      root: {
        maxWidth: 600,
        boxShadow: SHADOW_MD,
      },
      standardError: {
        color: TEXT_ERROR_COLOR,
        backgroundColor: '#FFF5F5',
      },
      message: TEXT_P2,
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: GREY_800,
        color: '#fff',
      },
    },
    MuiTabs: {
      root: {
        color: GREY_500,
      },
      indicator: {
        backgroundColor: GREY_800,
      },
    },
    MuiTab: {
      root: {
        minHeight: spacing(7),
        padding: 0,
        textTransform: 'none',
        minWidth: 16,
        opacity: 1,
        marginRight: spacing(3),
        '&:hover': {
          '&:not(&$selected)': {
            color: GREY_700,
            opacity: 1,
          },
        },
        '&.Mui-selected': {
          color: GREY_800,
        },
        '@media (min-width: 600px)': {
          minWidth: 16,
        },
        '&:focus': {
          color: GREY_800,
        },
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiLink: {
      root: {
        '&.MuiTypography-colorTextSecondary': {
          '&:hover': {
            color: GREY_700,
          },
        },
      },
    },
    MuiButton: {
      outlined: {
        // Account for the 1px border
        padding: '11px 15px',
      },
      outlinedSizeSmall: {
        '&:hover': {
          backgroundColor: '#fafafa',
        },
        '&:active': {
          backgroundColor: '#f5f5f5',
        },
      },
      root: {
        padding: BUTTON_PADDING,
        '&:active': {
          backgroundColor: GREY_300,
        },
      },
      sizeSmall: {
        fontSize: '0.875rem', // 14px
        lineHeight: 'calc(18 / 14)', // 18px
        padding: '8px 16px',
      },
      text: {
        padding: BUTTON_PADDING,
      },
      textPrimary: {
        '&:active': {
          backgroundColor: '#DAE4FB',
        },
      },
    },
    MuiButtonBase: {
      root: {
        // Let the default browser `outline` highlight buttons when they are focused
        outline: undefined,
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
      },
    },
    MuiInputBase: {
      root: {
        fontSize: undefined,
      },
      inputMarginDense: {
        paddingTop: 7.5,
        paddingBottom: 7.5,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: TEXT_SECONDARY_COLOR,
      },
      iconButton: {
        padding: 4,
        margin: 8,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        height: 'initial',
        backgroundColor: GREY_100,
        padding: `${spacing(2)} ${spacing(3)}`,
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: TEXT_PRIMARY_COLOR,
      },
      toolbarTxt: {
        '&.MuiTypography-subtitle1': {
          // Only way to target the Year selector
          fontSize: TEXT_P2.fontSize,
        },
        color: TEXT_PRIMARY_COLOR,
      },
      toolbar: {
        backgroundColor: GREY_100,
      },
    },
    MuiSnackbar: {
      anchorOriginTopCenter: {
        '@media (min-width:600px)': {
          top: spacing(7),
        },
      },
    },
    MuiChip: {
      root: {
        height: 44,
        backgroundColor: GREY_100,
        color: undefined,
        padding: spacing(1.5),
        borderRadius: BORDER_RADIUS_PX,
        fontSize: undefined,
        border: `solid 1px ${GREY_200}`,
      },
      label: {
        color: TEXT_PRIMARY_COLOR,
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: undefined,
        fontWeight: 500,
      },
      deleteIcon: {
        width: 20,
        height: 20,
        margin: '0 0 0 -2px',
        '&:hover': {
          color: '#000',
        },
      },
      deletable: {
        '& > .MuiChip-label': {
          paddingRight: spacing(1),
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: spacing(3),
      },
    },
    MuiDialog: {
      paper: {
        margin: spacing(8),
        '& > .MuiDialogContent-root': {
          '&:first-child': {
            // dialog without title
            paddingTop: spacing(5),
          },
          '&:last-child': {
            // dialog without footer
            paddingBottom: spacing(5),
          },
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: `${spacing(1.25)} ${spacing(5)} ${spacing(2.5)}`,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: `${spacing(5)} ${spacing(5)} ${spacing(1.25)}`,
      },
    },
    MuiTooltip: {
      arrow: {
        color: DARK_BLUE_3,
      },
      tooltip: {
        ...TEXT_P2,
        backgroundColor: DARK_BLUE_3,
        lineHeight: 1.35,
        padding: spacing(1),
      },
    },
    MuiCard: {
      root: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    // Deprecated
    MuiCardActions: {
      root: {
        padding: `${spacing(1)} ${spacing(3)} ${spacing(3)}`,
      },
    },
    // Deprecated
    MuiCardContent: {
      root: {
        padding: spacing(3),
      },
    },
    MuiCheckbox: {
      root: {
        color: PALETTE_PRIMARY_MAIN,
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
    },
    MuiRadio: {
      root: {
        '&:hover': {
          backgroundColor: 'unset',
        },
        '&$checked': {
          '&.Mui-focusVisible': {
            outline: 'auto',
            outlineOffset: 1,
          },
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        a: {
          color: GREY_700,
        },
        li: {
          marginBottom: spacing(1),
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        '&:hover .MuiCheckbox-root': {
          '&:not(.Mui-disabled)': {
            color: GREY_700,
          },
        },
      },
      label: TEXT_P1,
    },
    MuiFormHelperText: {
      root: {
        display: 'flex',
        marginTop: spacing(1),
        '&.Mui-success': {
          color: PALETTE_SUCCESS_MAIN,
        },
        '&.Mui-warning': {
          color: PALETTE_WARNING_MAIN,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: TEXT_PRIMARY_COLOR,
        fontSize: undefined,
        fontWeight: 500,
        marginBottom: spacing(1),
        '&$focused': {
          // MuiFormLabel changes to the primary color (blue) by default when used inside a MUI
          // FormControl. This removes that "primary" to default to body color & match Vise Styles.
          color: undefined,
        },
      },
      asterisk: {
        color: PALETTE_FEEDBACK_ORANGE_2,
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: BORDER_RADIUS_PX,
        height: 8,
      },
      colorSecondary: {
        backgroundColor: GREY_200,
      },
      bar: {
        borderRadius: BORDER_RADIUS_PX,
      },
      barColorSecondary: {
        backgroundColor: '#000',
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'white',
        minWidth: '36px',
      },
    },
    MuiMenu: {
      list: {
        borderColor: GREY_300,
        borderStyle: 'solid',
        borderRadius: BORDER_RADIUS_PX,
        borderWidth: '1px',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: DARK_BLUE_1,
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: '16px',
        '&.Mui-disabled': {
          backgroundColor: GREY_100,
          color: GREY_400,
        },
        '&.Mui-success .MuiOutlinedInput-notchedOutline': {
          borderColor: PALETTE_SUCCESS_MAIN,
        },
        '&.Mui-warning .MuiOutlinedInput-notchedOutline': {
          borderColor: PALETTE_WARNING_MAIN,
        },
      },
      adornedStart: {
        paddingLeft: '12px',
      },
      input: {
        padding: '12px 14px',
      },
      inputMarginDense: {
        // Note: Need to avoid using shorthand here or else MUI overrides these for some reason.
        paddingTop: 0,
        paddingBottom: 0,
        height: 34,
        paddingLeft: 12,
        paddingRight: 12,
        fontSize: '1rem',
      },
    },
    MuiSlider: {
      mark: {
        height: 17,
        marginTop: '-4.5px',
      },
      markActive: {
        backgroundColor: PALETTE_PRIMARY_DARK,
      },
      markLabel: {
        color: GREY_700,
        fontSize: '0.75rem',
        top: spacing(4.5),
      },
      rail: {
        borderRadius: BORDER_RADIUS_PX,
        height: 8,
      },
      thumb: {
        backgroundColor: 'white',
        border: '5px solid currentColor',
        height: SLIDER_THUMB_DIAMETER_PX,
        width: SLIDER_THUMB_DIAMETER_PX,
        '&::before': {
          border: `1px dashed ${GREY_600}`,
          borderRadius: '50%',
          content: '""',
          height: '400%',
          position: 'absolute',
          transform: 'scale(0)',
          transition: 'transform 0.25s',
          width: '400%',
        },
        '&$focusVisible::before,&:hover::before': {
          transform: 'scale(1)',
        },
        '&$active::before': {
          transform: 'scale(1.25)',
        },
        // Disable MUI's box-shadow for the thumb element, it doesn't match vise-styles.
        '&$active': {
          boxShadow: undefined,
        },
        // Disable MUI's box-shadow for the thumb element, it doesn't match vise-styles.
        '&$focusVisible,&:hover': {
          boxShadow: undefined,
        },
      },
      track: {
        borderRadius: BORDER_RADIUS_PX,
        height: 8,
      },
      valueLabel: {
        // Default CSS `calc` no longer needed with larger "thumb" element.
        left: undefined,
      },
    },
    MuiTableCell: {
      head: {
        padding: '16px',
      },
      root: {
        '&.MuiTableCell-root': {
          border: 'none',
        },
        borderBottom: undefined,
        padding: '14px 16px',
      },
      sizeSmall: {
        padding: '14px 24px',
      },
      stickyHeader: {
        backgroundColor: GREY_100,
      },
    },
    MuiBreadcrumbs: {
      separator: {
        marginRight: spacing(1.5),
        marginLeft: spacing(1.5),
      },
    },
    MuiToggleButtonGroup: {
      grouped: {
        '&.MuiToggleButtonGroup-grouped': {
          padding: '0 8px',
        },
      },
    },

    MuiToggleButton: {
      root: {
        border: 'none',
        '&.Mui-selected': {
          background: 'none',
          '&.Mui-selected:hover': {
            background: 'none',
          },
          '& .MuiToggleButton-label': {
            color: GREY_800,
          },
        },
        '&.Mui-disabled .MuiToggleButton-label': { color: GREY_400 },
        '&:hover': {
          backgroundColor: undefined,
        },
      },
      label: {
        color: GREY_500,
        '&:hover': {
          color: GREY_700,
        },
      },
      sizeSmall: {
        height: spacing(4.25),
        fontSize: '0.875rem',
      },
      sizeLarge: {
        fontSize: '1rem',
      },
    },
    MuiTextField: {
      root: { '.Mui-disabled': { '-webkit-text-fill-color': GREY_400 } },
    },
  },
  dataVis: {
    categorical: {
      chartBlack: GREY_800,
      chartRiver: '#9AADBD',
      chartBlue: PALETTE_PRIMARY_MAIN,
      chartSalmon: '#FFADAD',
      chartRuby: '#AB2378',
      chartLavender: '#6108E8',
      chartAmericanGray: '#808080',
      chartAqua: '#54B399',
      chartGreen: '#037F4C',
      chartGold: '#E0CBAB',
      chartYellow: '#CAB641',
    },
    monochrome: {
      100: '#C9CCD1',
      200: '#D1DDF8',
      300: '#8BA9EE',
      400: PALETTE_PRIMARY_MAIN,
      500: '#103B9D',
      600: '#081C4A',
    },
  },
} as DeprecatedThemeOptions;

const theme = createTheme(adaptV4Theme(muiThemeConfig));

export default theme;
