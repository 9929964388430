import { AssetClassKey } from 'vise-types/pce2_instrument';
import { insertAllocationsTemplate } from '../../api/api';
import useEnqueueToast from '../useToast';

type InsertAllocationsTemplateOptions = {
  name: string;
  userId: string;
  orgId: string;
  allocations: { [key in AssetClassKey]?: number };
  isCustomAllocation: boolean;
  customAllocations: AssetClassKey[];
};

export default function useInsertAllocationsTemplate() {
  const enqueueToast = useEnqueueToast();
  const insertTemplate = async ({
    name,
    userId,
    orgId,
    allocations,
    isCustomAllocation,
    customAllocations,
  }: InsertAllocationsTemplateOptions) => {
    try {
      const template = await insertAllocationsTemplate({
        name,
        userId,
        orgId,
        allocations,
        isCustomAllocation,
        customAllocations,
      });
      enqueueToast({
        severity: 'success',
        title: `You've successfully created your allocations template: ${name}.`,
      });
      return template;
    } catch (e) {
      enqueueToast({
        severity: 'error',
        title: `Failed to create allocations template.`,
        content: `Please reach out to clientservice@vise.com for help.`,
      });
      return false;
    }
  };
  return { insertTemplate };
}
