import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Button, FormLabel, Typography, useTheme } from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import moment from 'moment';
import { debounce } from 'lodash';

import ContainedSelectorCheckbox from '~/synth/ContainedSelectorCheckbox';
import Slider from '~/synth/Slider';
import TextField from '~/synth/TextField';
import amplitude from '~/utils/amplitude';

import { InputYear } from '~/components';
import { riskSliderToRisk, riskToRiskSlider } from '~/api/utils';
import { calculatePortfolioHorizon } from '~/utils/ageUtils';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import usePrevious from '~/hooks/usePrevious';
import AssetClassAllocationDisplay from './AssetClassAllocationDisplay';
import { Pce2ConstructionInfo, ScreenProps } from '../../Types';
import { ActionFooter, BackButton, ExplainerText } from '../components';
import ReturnToSummary from '../../ReturnToSummary';

const SLIDER_START_MARK = {
  label: 'Less risk',
};

const SLIDER_END_MARK = {
  label: 'More risk',
};

const MAX_YEARS = 100;

export function calculateNextDateXYearsFromTomorrow(xYears: number) {
  return moment().utc().add(xYears, 'years').add(1, 'day').format('YYYY-MM-DD');
}

interface FormValues {
  riskSlider: number | null;
  timeHorizon: number | null;
  glidePath: boolean | null;
}

export interface PCE2RiskToleranceScreenProps extends ScreenProps {
  onReturnToSummary: () => void;
}

export default function PCE2RiskToleranceScreen({
  draftPortfolio,
  onBack,
  onContinue,
  dpDispatch,
  onReturnToSummary,
}: PCE2RiskToleranceScreenProps) {
  const { constructionInfo, editModeOriginalInvestmentTimeline } = draftPortfolio;
  const { investmentTimeline, risk } = constructionInfo;

  // This logic is needed to avoid typescript failures as long as we are defining draftPortfolio to be
  // either a PCE1 type or PCE2 type.
  let useGlidePath: Pce2ConstructionInfo['useGlidePath'];
  if ('useGlidePath' in constructionInfo) {
    useGlidePath = constructionInfo.useGlidePath;
  }

  const theme = useTheme();

  const SliderSx = {
    '& .MuiSlider-dragging': {
      border: `1px solid ${theme.palette.grey[600]}`,
    },
    '& .MuiSlider-markLabel': {
      top: '0',
    },
    '& .MuiSlider-rail': {
      background: 'linear-gradient(270deg, #5610E5 0%, #F6B3DD 99%, #D64EA3 100%)',
      borderRadius: '100px',
      height: '2px',
    },
    '& .MuiSlider-thumb': {
      '&:before': {
        border: 'none',
      },
      '&:hover': {
        border: `1px solid ${theme.palette.grey[600]}`,
      },
      border: `1px solid ${theme.palette.grey[300]}`,
      height: '32px',
      width: '32px',
    },
    '& .MuiSlider-track': {
      background: 'none',
      border: 0,
      height: '2px',
    },
  };

  const originalInvestmentTimelineYears = useMemo(() => {
    return editModeOriginalInvestmentTimeline == null
      ? null
      : calculatePortfolioHorizon(editModeOriginalInvestmentTimeline, new Date());
  }, [editModeOriginalInvestmentTimeline]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      riskSlider: risk == null ? null : riskToRiskSlider(risk),
      timeHorizon:
        investmentTimeline == null
          ? null
          : calculatePortfolioHorizon(investmentTimeline, new Date()),
      glidePath: useGlidePath || false,
    },
    mode: 'onChange',
  });

  const riskSlider = useWatch({ control, name: 'riskSlider' });
  const timeHorizon = useWatch({ control, name: 'timeHorizon' });
  const glidePath = useWatch({ control, name: 'glidePath' });

  const previousTimeHorizon = usePrevious(timeHorizon, timeHorizon);

  const commitRisk = useMemo(
    () =>
      debounce((newRisk) => {
        // TO-DO (Jan): We want to make this the pce2 format (0-1) instead of pce1 format
        // (0-0.5).
        // This would require API changes, so convert this after.
        if (newRisk != null) {
          dpDispatch({ type: 'SET_RISK', risk: riskSliderToRisk(newRisk) });
        }
      }, 250),
    [dpDispatch]
  );

  useEffect(() => {
    commitRisk(riskSlider);
  }, [commitRisk, riskSlider]);

  const commitTimeHorizon = useMemo(
    () =>
      debounce((newYears: number | null) => {
        if (errors.timeHorizon) {
          amplitude().logEvent('Portfolio horizon error', {
            category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
            portfolioHorizon: newYears,
          });
          return;
        }

        let nextInvestmentTimeline: string | null;
        if (newYears == null || Number.isNaN(newYears)) {
          nextInvestmentTimeline = null;
        } else if (newYears === originalInvestmentTimelineYears) {
          nextInvestmentTimeline = editModeOriginalInvestmentTimeline;
        } else {
          nextInvestmentTimeline = calculateNextDateXYearsFromTomorrow(newYears);
        }
        dpDispatch({ type: 'SET_INVESTMENT_TIMELINE', investmentTimeline: nextInvestmentTimeline });
      }, 250),
    [errors, editModeOriginalInvestmentTimeline, originalInvestmentTimelineYears, dpDispatch]
  );

  useEffect(() => {
    commitTimeHorizon(timeHorizon);
  }, [commitTimeHorizon, timeHorizon]);

  const commitGlidePath = useMemo(
    () =>
      debounce((newGlidePath: boolean | null) => {
        if (newGlidePath != null) {
          dpDispatch({ type: 'SET_USE_GLIDE_PATH', useGlidePath: newGlidePath });
        }
      }, 250),
    [dpDispatch]
  );

  useEffect(() => {
    commitGlidePath(glidePath);
  }, [commitGlidePath, glidePath]);

  useEffect(() => {
    if (
      timeHorizon != null &&
      !Number.isNaN(timeHorizon) &&
      previousTimeHorizon !== timeHorizon &&
      timeHorizon < 5
    ) {
      setValue('glidePath', false);
    }
  }, [previousTimeHorizon, setValue, timeHorizon]);

  useEffect(() => {
    return () => {
      commitRisk.cancel();
      commitTimeHorizon.cancel();
      // Leaving out the commitGlidePath cancel because it was messing the
      // first glidePath setting based off the time horizon. Doesn't seem to be any downside.
      // TODO(prudraraju) investigate why this was happening.
    };
  }, [commitRisk, commitTimeHorizon]);

  const onSubmit = useCallback(() => {
    commitRisk.flush();
    commitTimeHorizon.flush();
    commitGlidePath.flush();
    onContinue();
  }, [commitRisk, commitTimeHorizon, commitGlidePath, onContinue]);

  const getHandleRiskChange = useCallback(
    (onChangeHandler) => (_event: React.ChangeEvent<{}>, value: number | number[]) => {
      amplitude().logEvent('Change risk slider', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        risk: value,
      });
      onChangeHandler(value);
    },
    []
  );

  const nextButtonDisabled =
    !!errors.timeHorizon ||
    investmentTimeline == null ||
    Number.isNaN(investmentTimeline) ||
    risk == null;

  return (
    <Box maxWidth="568px">
      <Typography variant="h1">Let’s set your client’s risk tolerance.</Typography>
      <ExplainerText mb={4} mt={2}>
        Your risk tolerance and time horizon will affect the expected portfolio outcome, its
        starting equity allocation, glidepath, and expected returns.
      </ExplainerText>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormLabel id="risk">Drag the slider to indicate risk tolerance</FormLabel>
        <Box mb={4} mt={5.25}>
          <Controller
            control={control}
            name="riskSlider"
            // This is to omit ref from being passed down to the slider.
            // Slider doesn't accept a ref argument by default since it is a functional component.
            // I don't think there's any use for it here.
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ field: { ref: _ref, ...fieldRest } }) => (
              <Slider
                {...fieldRest}
                sx={SliderSx}
                aria-labelledby="risk"
                endMark={SLIDER_END_MARK}
                // known issue in v4 of MUI: https://github.com/mui-org/material-ui/issues/20191
                // Fixed in v5
                // TO-DO: Remove once we migrate to v5
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                onChange={getHandleRiskChange(fieldRest.onChange)}
                markVerticalAlignment="top"
                max={100}
                min={0}
                startMark={SLIDER_START_MARK}
                valueLabelDisplay="off"
              />
            )}
          />
        </Box>
        {riskSlider != null && (
          <Box mb={4}>
            <AssetClassAllocationDisplay risk={riskSlider} />
          </Box>
        )}
        <Box width="568px">
          <Controller
            control={control}
            name="timeHorizon"
            // This is to omit ref from being passed down to the slider.
            // TextField seems to have ref prop, but since it's a functional component it might not behave as expected.
            // Things seem to be working, but I'll add a to-do to follow up separately.
            // TO-DO (Jan): Forward ref for TextField
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ field: { ref: _ref, ...fieldRest } }) => (
              <TextField
                {...fieldRest}
                aria-labelledby="riskTimeHorizonLabel"
                error={!!errors.timeHorizon}
                label="Time horizon"
                onFocus={() => {
                  amplitude().logEvent('Tap portfolio horizon', {
                    category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                  });
                }}
                helperText={`${MAX_YEARS} year max. Horizons of less than 5 years will not be eligible to opt in to a glide path.`}
                InputProps={{ inputComponent: InputYear }}
              />
            )}
            rules={{
              min: 1,
              max: MAX_YEARS,
            }}
          />
        </Box>
        <Box mb={4} mt={4}>
          <Controller
            control={control}
            name="glidePath"
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ field: { ref: _ref, ...fieldRest } }) => (
              <ContainedSelectorCheckbox
                {...fieldRest}
                checked={fieldRest.value ?? false}
                onClick={fieldRest.onChange}
                disabled={timeHorizon == null || timeHorizon < 5}
                label="Put this portfolio on a glide path"
                description="For portfolios on a glide path, Vise will automatically reduce the specified equities allocation by 80% over the time horizon."
              />
            )}
          />
        </Box>
        <ActionFooter justifyContent="space-between">
          <BackButton onClick={() => onBack()} />
          <Box display="flex">
            <ReturnToSummary
              draftPortfolio={draftPortfolio}
              disabled={nextButtonDisabled}
              onReturnToSummary={onReturnToSummary}
              mr={1.5}
            />
            <Button color="primary" disabled={nextButtonDisabled} type="submit" variant="contained">
              Continue
            </Button>
          </Box>
        </ActionFooter>
      </form>
    </Box>
  );
}
