import { Box, Link, ThemeProvider, Typography } from '@mui/material';
import { theme, tokens } from '@vise_inc/ds-vise';
import React, { useState } from 'react';
import UnstyledButton from '~/synth/UnstyledButton';
import VideoPlayerModal from './VideoPlayerModal';

export default function Article({
  title,
  header,
  description,
  backgroundColor,
  borderColor,
  icon,
  action,
  to,
  ctaType = 'link',
}: {
  title: React.ReactNode;
  header: React.ReactNode;
  description: React.ReactNode;
  backgroundColor: string;
  borderColor: string;
  icon: React.ReactNode;
  action: React.ReactNode;
  to: string;
  ctaType?: 'link' | 'video';
}) {
  const [videoPlayModalOpen, setVideoPlayerModalOpen] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      {ctaType === 'video' ? (
        <VideoPlayerModal
          open={videoPlayModalOpen}
          onClose={() => setVideoPlayerModalOpen(false)}
          url={to}
        />
      ) : null}
      <Box
        height="100%"
        boxShadow={tokens.shadow.base}
        borderRadius="4px"
        sx={{ borderColor, borderStyle: 'solid', borderWidth: '1px' }}
        padding={5}
        bgcolor={backgroundColor}
      >
        <Typography variant="h5" color="neutralCool.600">
          {title}
        </Typography>
        <Box mt={2} mb={2}>
          <Typography variant="h3" component="div">
            {header}
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body3" color="neutralCool.600">
            {description}
          </Typography>
        </Box>
        {ctaType === 'link' ? (
          <Link href={to} target="_blank" underline="hover">
            <Box display="flex" alignItems="center">
              {icon}
              <Box ml={2}>
                <Typography variant="h5" component="div" color="neutralCool.900">
                  {action}
                </Typography>
              </Box>
            </Box>
          </Link>
        ) : (
          <UnstyledButton onClick={() => setVideoPlayerModalOpen(true)}>
            <Box display="flex" alignItems="center">
              {icon}
              <Box ml={2}>
                <Typography variant="h5" component="div" color="neutralCool.900">
                  {action}
                </Typography>
              </Box>
            </Box>
          </UnstyledButton>
        )}
      </Box>
    </ThemeProvider>
  );
}
