import useAccountValue from './useAccountValue';

export default function useViseManagedValue(
  accountNumber: string | undefined | null,
  custodianId: string | undefined | null,
  lockedPositions: string[],
  targetCashFraction = 0
) {
  const { data, error } = useAccountValue(
    accountNumber,
    custodianId,
    lockedPositions,
    targetCashFraction
  );
  return { data: data ? data.viseManagedValue : data, error };
}
