import * as Highcharts from 'highcharts';
import * as Highstocks from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import memoizeOne from 'memoize-one';

NoDataToDisplay(Highcharts);
NoDataToDisplay(Highstocks);

interface ChartDefaultProps {
  constructorType: keyof typeof Highcharts;
}

interface ChartProps {
  constructorType?: keyof typeof Highcharts;
  highstock?: boolean;
  options: Partial<Highcharts.Options>;
}

const DEFAULT_CHART_OPTIONS = {};

export default class Chart extends React.PureComponent<ChartProps> {
  private chart: Highcharts.Chart | undefined;

  static defaultProps: ChartDefaultProps = {
    constructorType: 'chart',
  };

  componentDidUpdate() {
    if (this.chart != null) this.chart.redraw();
  }

  // Intended to be overriden by subclasses. It's okay that this version does not reference `this`.
  // eslint-disable-next-line class-methods-use-this
  protected getChartOptions(): Highcharts.Options {
    return DEFAULT_CHART_OPTIONS;
  }

  private getChartComponent() {
    return this.props.highstock ? Highstocks : Highcharts;
  }

  private getOptions = memoizeOne((chartOptions, propsOptions) => ({
    ...chartOptions,
    ...propsOptions,
  }));

  private setChart = (chart: Highcharts.Chart) => {
    this.chart = chart;
  };

  render() {
    const { constructorType, options: propsOptions } = this.props;
    const options = this.getOptions(this.getChartOptions(), propsOptions);

    return (
      <HighchartsReact
        callback={this.setChart}
        constructorType={constructorType}
        highcharts={this.getChartComponent()}
        {...this.props}
        options={options}
      />
    );
  }
}
