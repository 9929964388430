import useSWR from 'swr';
import { EngineType } from 'vise-types/portfolio';
import { getClientAccounts } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getClientAccountsSwrKey = (clientId: string, engineType?: EngineType) =>
  generateSwrKey(
    `client/clients/${clientId}/accounts${engineType ? `?engineType=${engineType}` : ''}}`
  );

export default function useClientAccounts(
  clientId: string | null,
  engineType?: EngineType,
  config?: {}
) {
  const { data, error, isValidating, mutate } = useSWR(
    clientId == null ? null : getClientAccountsSwrKey(clientId, engineType),
    clientId == null ? undefined : () => getClientAccounts(clientId, engineType),
    config
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
}
