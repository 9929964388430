import { Typography, Box, Button, Divider } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { Account } from 'vise-types/portfolio';
import CardHeader from '~/synth/CardHeader';
import { maskAccountNumberIfExists } from '~/utils/format';
import Truncation from '../../Portfolio/components/Truncation';

export default function GPTCardHeader({
  title,
  buttonPathname,
  buttonCopy,
  account,
}: {
  title: string;
  buttonPathname: string;
  buttonCopy: string;
  account: Account;
}) {
  const today = moment().tz('America/New_York');
  return (
    <CardHeader height="90px">
      <Box display="flex" alignItems="center" height="100%">
        <Box display="flex" flexDirection="row" alignItems="center" gap={3} height="100%">
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="h2">{title}</Typography>

            <Box display="flex" alignItems="center" gap="4px">
              <Box component="span" mr={0.5}>
                <Typography
                  variant="body2"
                  color={tokens.palette.neutralCool[900]}
                  fontWeight={500}
                  fontSize="0.75rem"
                >
                  {' '}
                  As of:
                </Typography>
              </Box>
              <Typography
                variant="body2"
                color={tokens.palette.neutralCool[600]}
                fontWeight={500}
                fontSize="0.75rem"
              >
                {today.format('MM/DD/YYYY')}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography
                variant="body2"
                color={tokens.palette.neutralCool[600]}
                fontWeight={500}
                fontSize="0.75rem"
              >
                {today.format('hh:mm a')}
              </Typography>
            </Box>
          </Box>

          <Divider orientation="vertical" />
          <Box display="flex" flexDirection="column" gap={0.5} maxWidth={600}>
            <Typography variant="h2">
              <Truncation>{account.accountName}</Truncation>
            </Typography>

            <Box display="flex" alignItems="center">
              <Box component="span">
                <Typography
                  variant="body1"
                  fontSize="0.75rem"
                  fontWeight={500}
                  color={tokens.palette.neutralCool[900]}
                >
                  {' '}
                  Account number:
                </Typography>
              </Box>
              <Box component="span" ml={0.5}>
                <Typography
                  variant="body1"
                  fontSize="0.75rem"
                  fontWeight={500}
                  color={tokens.palette.neutralCool[600]}
                >
                  {maskAccountNumberIfExists(account.accountNumber)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flex={1} />
        <Button
          variant="outlined"
          color="secondary"
          component={Link}
          to={{ pathname: buttonPathname }}
          sx={{ borderRadius: '4px' }}
        >
          {buttonCopy}
        </Button>
      </Box>
    </CardHeader>
  );
}
