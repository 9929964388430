import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, Grid, Typography, Slide } from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import TextField from '~/synth/TextField';
import useHousehold from '~/hooks/useHousehold';
import useHouseholdClients from '~/hooks/useHouseholdClients';
import Skeleton from '~/synth/Skeleton';
import { RawClient, RawHousehold } from '~/models/api';
import AddClientDialogContainer from '~/routes/Households/ManageHouseholdAddClientModalContainer';
import DeleteHouseholdModalContainer from '~/routes/Households/DeleteHouseholdModalContainer';
import { ReactComponent as ArrowRightIcon } from '~/static/images/icons/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '~/static/images/icons/arrow-left.svg';
import { ReactComponent as UserAddIcon } from '~/static/images/icons/user-add.svg';
import SaveFooter from '~/routes/Households/components/ManageHouseholdSaveFooter';
import { updateHousehold } from '~/api/api';
import useEnqueueToast from '~/hooks/useToast';
import amplitude from '~/utils/amplitude';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import ErrorFallback from '~/routes/Households/ErrorFallback';
import {
  EmptyItemsContainer,
  ItemRow,
  ItemRowsContainer,
} from './components/ManageHouseholdItemRow';
import WithLoader from '../../utils/WithLoader';

interface EditHouseholdNameContainerProps {
  householdData: RawHousehold;
  setShowSaveFooter: (showSaveFooter: boolean) => void;
}

interface ClientsContainerProps {
  householdClientsData: RawClient[];
}

interface DeleteHouseholdContainerProps {
  householdId: string;
}

interface FormValues {
  name: string;
}

const ClientsLoadingContainer = () => {
  return (
    <Box>
      <ItemRowsContainer>
        {[0, 1, 2].map((n) => {
          return (
            <ItemRow key={n}>
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <Skeleton inline width="10em" />
                <Skeleton inline width="4em" />
              </Box>
            </ItemRow>
          );
        })}
      </ItemRowsContainer>
    </Box>
  );
};

const DeleteHouseholdContainer = ({ householdId }: DeleteHouseholdContainerProps) => {
  const [deleteHouseholdModalOpen, setDeleteHouseholdModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const enqueueToast = useEnqueueToast();

  return (
    <>
      <Box mt={1.5}>
        <Typography color="textSecondary" variant="body1">
          Please note that deleting this household will also delete any portfolios and proposals
          associated with its clients.
        </Typography>
      </Box>
      <Box mt={3}>
        <Button
          color="error"
          variant="outlined"
          onClick={() => {
            amplitude().logEvent('Tap delete household', {
              category: EVENT_CATEGORIES.HOUSEHOLDS,
              householdId,
            });
            setDeleteHouseholdModalOpen(true);
          }}
        >
          Delete household
        </Button>
      </Box>
      <DeleteHouseholdModalContainer
        householdId={householdId}
        onSuccess={() => {
          enqueueToast({
            title: 'Household deleted successfully',
            severity: 'success',
          });
          history.push('/secure/households');
        }}
        isOpen={deleteHouseholdModalOpen}
        onClose={() => {
          amplitude().logEvent('Tap exit', {
            category: EVENT_CATEGORIES.HOUSEHOLDS,
            page: 'Manage household',
          });
          setDeleteHouseholdModalOpen(false);
        }}
      />
    </>
  );
};

const ClientsContainer = ({ householdClientsData }: ClientsContainerProps) => {
  return (
    <ItemRowsContainer>
      {householdClientsData.length === 0 ? (
        <EmptyItemsContainer>
          <Typography variant="body1" color="textSecondary">
            There are no clients in this household yet.
          </Typography>
        </EmptyItemsContainer>
      ) : (
        householdClientsData.map((clientData) => {
          return (
            <ItemRow key={clientData.id}>
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <Typography variant="h4">{`${clientData.firstName} ${clientData.lastName}`}</Typography>
                <Button
                  color="inherit"
                  component={Link}
                  endIcon={<ArrowRightIcon />}
                  to={`/secure/households/${clientData.clientGroupId}/client/${clientData.id}/manage`}
                  onClick={() => {
                    amplitude().logEvent('Tap edit client', {
                      category: EVENT_CATEGORIES.HOUSEHOLDS,
                    });
                  }}
                >
                  Edit
                </Button>
              </Box>
            </ItemRow>
          );
        })
      )}
    </ItemRowsContainer>
  );
};

const EditHouseholdNameContainer = ({
  householdData,
  setShowSaveFooter,
}: EditHouseholdNameContainerProps) => {
  const enqueueToast = useEnqueueToast();

  const onSubmit = async (updatedHouseholdName) => {
    amplitude().logEvent('Tap save changes', {
      category: EVENT_CATEGORIES.HOUSEHOLDS,
      entity: 'Household',
    });
    const updatedHouseholdData = {
      ...householdData,
      name: updatedHouseholdName,
    };
    try {
      await updateHousehold(updatedHouseholdData);
    } catch (e) {
      enqueueToast({
        title: 'Error updating household',
        severity: 'error',
      });
      return;
    }
    enqueueToast({
      title: 'Household updated successfully',
      severity: 'success',
    });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      name: householdData.name,
    },
    async onSubmit(values) {
      await onSubmit(values.name);
    },

    validate(values) {
      const errors: FormikErrors<FormValues> = {};
      if (values.name === '') errors.name = 'Household name is required';
      return errors;
    },
  });

  useEffect(() => {
    if (!formik.isSubmitting && formik.isValid && formik.values.name !== householdData.name) {
      setShowSaveFooter(true);
    } else {
      setShowSaveFooter(false);
    }
  }, [formik, householdData, setShowSaveFooter]);

  return (
    <form id="edit-household-name" onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        onFocus={() => {
          amplitude().logEvent('Tap edit household name', {
            category: EVENT_CATEGORIES.HOUSEHOLDS,
          });
        }}
        id="name"
        name="name"
        label="Household name"
        inputProps={{ maxLength: 255 }}
        required
      />
    </form>
  );
};

const ManageHousehold = () => {
  const {
    params: { householdId },
  } = useRouteMatch<{ householdId: string }>();

  useEffect(() => {
    amplitude().logEvent('Impression - Manage household', {
      category: EVENT_CATEGORIES.HOUSEHOLDS,
      householdId,
    });
  }, [householdId]);

  const [addClientModalOpen, setAddClientModalOpen] = useState<boolean>(false);

  const {
    data: householdData,
    isLoading: householdIsLoading,
    error: householdError,
  } = useHousehold(householdId);

  const {
    data: householdClientsData,
    isLoading: householdClientsIsLoading,
    error: householdClientsError,
  } = useHouseholdClients(householdId);

  const [showSaveFooter, setShowSaveFooter] = useState<boolean>(false);

  const loadingError = householdError || householdClientsError;
  if (loadingError) {
    return <ErrorFallback error={loadingError} />;
  }

  return (
    <>
      <Helmet>
        <title>Manage household</title>
      </Helmet>
      <Box ml={3} mb={6}>
        <Grid container>
          <Grid item sm={5}>
            <Button
              color="inherit"
              component={Link}
              startIcon={<ArrowLeftIcon />}
              to={`/secure/households/${householdId}/overview`}
            >
              View household
            </Button>
            <Box mt={2}>
              <Typography variant="h1">Manage household</Typography>
            </Box>
            <Box mt={4}>
              <WithLoader isLoading={householdIsLoading} loader={<Skeleton inline width="14em" />}>
                <EditHouseholdNameContainer
                  householdData={householdData as Exclude<typeof householdData, undefined>}
                  setShowSaveFooter={setShowSaveFooter}
                />
              </WithLoader>
            </Box>
            <Box mt={5}>
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <Typography variant="h3">Clients</Typography>
                {!householdClientsIsLoading && (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<UserAddIcon />}
                      onClick={() => {
                        amplitude().logEvent('Tap add client', {
                          category: EVENT_CATEGORIES.HOUSEHOLDS,
                          page: 'Manage Household',
                        });
                        setAddClientModalOpen(true);
                      }}
                    >
                      Add client
                    </Button>
                    <AddClientDialogContainer
                      householdId={householdId}
                      isOpen={addClientModalOpen}
                      onClose={() => {
                        amplitude().logEvent('Tap exit', {
                          category: EVENT_CATEGORIES.HOUSEHOLDS,
                          page: 'Manage Household',
                        });
                        setAddClientModalOpen(false);
                      }}
                    />
                  </>
                )}
              </Box>
              <WithLoader
                isLoading={householdClientsIsLoading}
                loader={<ClientsLoadingContainer />}
              >
                <ClientsContainer
                  householdClientsData={
                    householdClientsData as Exclude<typeof householdClientsData, undefined>
                  }
                />
              </WithLoader>
            </Box>
            <Box mt={5}>
              <Typography variant="h3">Delete household</Typography>
              <DeleteHouseholdContainer householdId={householdId} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Slide in={showSaveFooter} mountOnEnter unmountOnExit direction="up">
        <SaveFooter formId="edit-household-name" />
      </Slide>
    </>
  );
};

export default ManageHousehold;
