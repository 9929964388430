import { Box, Modal } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';

const WIDTH = '960px';
const HEIGHT = '540px';

export default function VideoPlayerModal({
  open,
  url,
  onClose,
}: {
  open: boolean;
  url: string;
  onClose: () => void;
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <ReactPlayer
          url={url}
          controls
          width={WIDTH}
          height={HEIGHT}
          config={{ vimeo: { playerOptions: { width: WIDTH, height: HEIGHT } } }}
        />
      </Box>
    </Modal>
  );
}
