import { Box, Divider, Tooltip, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns/esm';
import { filter } from 'lodash';
import moment, { max, min } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import amplitude from '~/utils/amplitude';
import { ValueType } from 'react-select';
import {
  Column,
  Row,
  TableState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
} from 'react-table';
import { Order } from 'vise-types/orders';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { Account } from 'vise-types/portfolio';
import DateRangeSelector from '~/components/chart/DateRangeSelector';
import { GetPortfolioIntelligenceOrdersResponse, RawGetCapitalGainsResponse } from '~/models/api';
import LoadingTable from '~/routes/Households/Households/LoadingTable';
import { ReactComponent as ChevronRightIcon } from '~/static/images/icons/chevron-right.svg';
import { DataTable, DataTableContainer, DataTableTitle, floatCompare } from '~/synth/DataTable';
import Select from '~/synth/inputs/Select';
import scrollToTop from '~/utils/scrollToTop';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { NoResultsFound } from '../components/TableComponents';
import {
  CatalystLabel,
  TradeInitiatorLabel,
  TurnoverLabel,
} from '../components/TradeActivityLabels';
import TradeDetail from './TradeDetail';
import {
  beforeBackfill,
  CATALYST,
  INITIATOR,
  MODE_TO_CATALYST,
  PRIMARY_OBJECTIVE_TO_LABEL,
  RUN_SOURCE_TO_INITIATOR,
  turnOverToSeverity,
  TURNOVER_SEVERITY,
} from './Utils';

export const ActivityLoadingState = () => (
  <>
    <DataTableContainer>
      <DataTableTitle title="Trade activity" />
      <LoadingTable pageSize={8} />
    </DataTableContainer>
  </>
);

interface SelectOption<T> {
  disabled?: boolean;
  label: string;
  value: T;
}

export default function PortfolioActivity({
  intelligence,
  gains,
  account,
  orders,
}: {
  intelligence: PortfolioIntelligenceFull;
  gains: RawGetCapitalGainsResponse | undefined;
  account: Account | null;
  orders: GetPortfolioIntelligenceOrdersResponse | undefined;
}) {
  useEffect(() => {
    amplitude().logEvent(`Impression - Trade activity tab`, {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
  }, []);

  const theme = useTheme();

  const [orderDetail, setOrderDetail] = useState<Order | undefined>(undefined);
  const [catalyst, setCatalyst] = useState<CATALYST | null>(null);
  const [initiator, setInitiator] = useState<INITIATOR | null>(null);
  const [turnover, setTurnover] = useState<TURNOVER_SEVERITY | null>(null);

  const dates = useMemo(() => {
    if (orders != null) {
      return [...orders?.orders.map((o) => moment(o.createdAt))];
    }
    return [];
  }, [orders]);

  const [dateRangeFilter, setDateRangeFilter] = useState<{
    min?: moment.Moment;
    max?: moment.Moment;
  }>({});

  const filteredOrders = useMemo(
    () =>
      filter(
        orders?.orders,
        (o) =>
          (initiator == null ||
            o.pce2RunSource == null ||
            RUN_SOURCE_TO_INITIATOR[o.pce2RunSource] === initiator) &&
          (catalyst == null ||
            o.optimizerMode == null ||
            MODE_TO_CATALYST[o.optimizerMode] === catalyst) &&
          (turnover == null ||
            o.metrics?.turnoverFraction == null ||
            turnOverToSeverity(o.metrics.turnoverFraction) === turnover) &&
          (dateRangeFilter.min == null || moment(o.createdAt) >= dateRangeFilter.min) &&
          (dateRangeFilter.max == null || moment(o.createdAt) <= dateRangeFilter.max)
      ),
    [catalyst, dateRangeFilter.max, dateRangeFilter.min, initiator, orders?.orders, turnover]
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Order date',
          accessor: 'createdAt',
          Cell: (props) => (
            <Typography variant="h4">
              {props.value != null ? format(new Date(props.value), 'MMMM do, yyyy') : <>N/A</>}
            </Typography>
          ),
          width: '20%',
        },
        {
          Header: () => (
            <Tooltip
              title={
                <>
                  Turnover is a measure of the trading activity in a portfolio. It is computed by
                  taking the lesser of purchases or sales in a trade, divided by the portfolio
                  value.
                  <Box my={2}>
                    Turnover in the portfolio may occur to rebalance asset allocations toward
                    target, harvest tax losses, get single security exposures in line with target,
                    etc.
                  </Box>
                  A low turnover trade implies small shifts in the overall portfolio, while a high
                  turnover trade implies higher trading activity due to larger shifts in the overall
                  portfolio.
                </>
              }
            >
              <div
                // Negative margin to offset border pushing text up
                style={{ borderBottom: `1px dashed ${theme.palette.blue[300]}`, marginTop: '-1px' }}
              >
                Turnover
              </div>
            </Tooltip>
          ),
          accessor: 'metrics.turnoverFraction',
          Cell: (props) => <TurnoverLabel turnover={props.value} />,
          sortType: floatCompare,
        },
        {
          Header: 'Trade initiator',
          accessor: 'pce2RunSource',
          Cell: (props) =>
            props.value != null ? <TradeInitiatorLabel runSource={props.value} /> : <>N/A</>,
          disableSortBy: true,
        },
        {
          Header: 'Catalyst',
          accessor: 'optimizerMode',
          Cell: (props) =>
            props.value != null ? <CatalystLabel optimizerMode={props.value} /> : <>N/A</>,
          disableSortBy: true,
        },
        {
          Header: 'Primary objective',
          accessor: 'intelligence',
          Cell: (props) => {
            if (props.value?.primaryObjective != null) {
              return PRIMARY_OBJECTIVE_TO_LABEL[props.value.primaryObjective];
            }
            const content = beforeBackfill(new Date(props.row.original.createdAt))
              ? 'Unavailable'
              : 'Coming soon…';
            return <Box color="grey.400">{content}</Box>;
          },

          width: '30%',
          disableSortBy: true,
        },
        { id: 'chevron', Cell: <ChevronRightIcon />, width: '5%', disableSortBy: true },
      ] as Column<Order>[] & UseSortByOptions<Order>[],
    // theme.palette.blue[300] is a constant
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const table = useTable<Order>(
    {
      autoResetSortBy: false,
      columns,
      data: filteredOrders,
      initialState: {
        sortBy: [{ id: 'createdAt', desc: true }],
      } as TableState<Order> & UseSortByState<Order>,
    },
    useSortBy
  );

  const [showTradeDetail, setShowTradeDetail] = useState(false);

  const catalystOptions = [
    { label: 'Initial trades', value: 'INITIAL_TRADES' },
    { label: 'Rebalance', value: 'REBALANCE' },
    { label: 'Cash raise', value: 'CASH_RAISE' },
    { label: 'Constraints', value: 'CONSTRAINTS' },
  ] as SelectOption<CATALYST>[];

  const initiatorOptions = [
    { label: 'Vise', value: 'VISE' },
    { label: 'Advisor', value: 'ADVISOR' },
  ] as SelectOption<INITIATOR>[];

  const turnoverOptions = [
    { label: 'Low', value: 'LOW' },
    { label: 'Medium', value: 'MED' },
    { label: 'High', value: 'HIGH' },
  ] as SelectOption<TURNOVER_SEVERITY>[];

  const filters = (
    <Box width="100%" display="flex" alignItems="center">
      <Box width={185}>
        <Select
          isClearable
          placeholder="Turnover"
          size="small"
          options={turnoverOptions}
          onChange={(value: ValueType<SelectOption<TURNOVER_SEVERITY>>) =>
            setTurnover((value as SelectOption<TURNOVER_SEVERITY>)?.value ?? null)
          }
        />
      </Box>
      <Box width={185} mx={1}>
        <Select
          isClearable
          placeholder="Trade initiator"
          size="small"
          options={initiatorOptions}
          onChange={(value: ValueType<SelectOption<INITIATOR>>) =>
            setInitiator((value as SelectOption<INITIATOR>)?.value ?? null)
          }
        />
      </Box>
      <Box width={185}>
        <Select
          isClearable
          placeholder="Catalyst"
          size="small"
          options={catalystOptions}
          onChange={(value: ValueType<SelectOption<CATALYST>>) =>
            setCatalyst((value as SelectOption<CATALYST>)?.value ?? null)
          }
        />
      </Box>
      <Box mx={2.5}>
        <Divider orientation="vertical" style={{ height: '34px' }} />
      </Box>
      <DateRangeSelector
        initialRange="all time"
        firstAvailableDate={min(dates)}
        lastAvailableDate={max(dates)}
        onChangeRange={(range) => {
          setDateRangeFilter({
            min: !range?.min ? undefined : range.min,
            max: !range?.max ? undefined : range.max,
          });
        }}
        hidePresets
      />
    </Box>
  );

  return (
    <>
      {orders != null ? (
        <>
          {!showTradeDetail && (
            <DataTableContainer style={{ minHeight: '250px' }}>
              <DataTableTitle
                title="Trade activity"
                metaData={orders.orders.length > 0 && filters}
              />
              <DataTable
                rowSize="large"
                tableMaxHeight="calc(100vh - 430px)"
                table={table}
                onRowClick={(row: Row<Order>) => {
                  setShowTradeDetail(true);
                  setOrderDetail(row.original);
                  scrollToTop();
                  amplitude().logEvent(`View order details`, {
                    category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
                    orderId: row.original.id,
                  });
                }}
              />
              {filteredOrders.length === 0 && <NoResultsFound />}
            </DataTableContainer>
          )}
          {showTradeDetail && (
            <TradeDetail
              intelligence={intelligence}
              onBack={() => setShowTradeDetail(false)}
              order={orderDetail}
              instruments={orders.instruments}
              gains={gains}
              account={account}
            />
          )}
        </>
      ) : (
        <ActivityLoadingState />
      )}
    </>
  );
}
