import React from 'react';
import { AccountStatus } from 'vise-types/portfolio';
import Truncation from '~/routes/Portfolio/components/Truncation';
import { TextHighlightTag } from '~/synth/Tag';

export const ACCOUNT_STATUS_TO_ORDER = {
  ACTIVE: 0,
  PENDING_PCE_VALIDATION: 1,
  INACTIVE: 2,
  UNLINKED: 3,
} as { [key in AccountStatus]: number };

export default function renderAccountStatus({
  value,
  statusOnly,
}: {
  value?: { status: AccountStatus; statusReason?: string | null };
  statusOnly?: boolean;
}) {
  if (!value) {
    return null;
  }
  const { status, statusReason } = value;
  let text = '';
  let severity: 'success' | 'error' = 'error';
  if (status === 'ACTIVE') {
    text = 'Active';
    severity = 'success';
  } else if (statusReason === 'missing_tax_info') {
    text = 'Missing tax lots';
  } else if (statusReason === 'account_too_small') {
    text = 'Insufficient funding';
  } else if (statusReason === 'empty_position_info') {
    text = 'Zero balance account';
  } else {
    text = 'Inactive';
  }
  return (
    // Set width to make sure truncation activates
    <>
      {statusOnly ? (
        <Truncation>{text}</Truncation>
      ) : (
        <TextHighlightTag severity={severity} fontWeight={400} maxWidth="99%">
          <Truncation>{text}</Truncation>
        </TextHighlightTag>
      )}
    </>
  );
}
