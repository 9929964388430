import React from 'react';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import { Loader } from '~/components';
import SomethingWentWrong from '~/components/SomethingWentWrong';
import useAccountWithAccountNumber from '~/hooks/useAccountWithAccountNumber';

// This component is purely for redirecting to the new portfolio page if a portfolio
// is accessed through the old route.
export default function PortfolioRedirect() {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'custodianId' does not exist on type '{}'... Remove this comment to see the full error message
  const { custodianId, portfolioId } = useParams();
  const { data: accountResponseData, error: accountResponseError } = useAccountWithAccountNumber(
    custodianId,
    portfolioId,
    false
  );
  if (accountResponseError?.response.status === 404) {
    return (
      <SomethingWentWrong
        title="We can't find the account you're looking for."
        description="The link you're using may be
        incorrect, or you may not have access to the account."
        disableCta
        error={new Error('could not find account')}
      />
    );
  }
  if (accountResponseError) {
    return (
      <SomethingWentWrong
        description="There was an error loading the portfolio."
        error={accountResponseError}
      />
    );
  }

  if (!accountResponseData) {
    return <Loader />;
  }

  const { data: account } = accountResponseData;
  return <Redirect to={`/secure/accounts/${account.id}/portfolio`} />;
}
