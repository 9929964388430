import { Box, Divider, Tooltip, Typography, useTheme } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import moment from 'moment';
import React from 'react';
import { Custodian } from 'vise-types/custodian';
import { AccountStatus, EngineType, RebalancerStatus } from 'vise-types/portfolio';
import Truncation from '~/routes/Portfolio/components/Truncation';
import { ReactComponent as ExclamationCircleSvgIcon } from '~/static/images/icons/information-circle.svg';
import { ReactComponent as PausedSvgIcon } from '~/static/images/icons/pause-circle-outline.svg';
import { ReactComponent as WarningSvgIcon } from '~/static/images/icons/warning-amber-rounded.svg';
import { getCustodianDisplayName, maskAccountNumber } from '~/utils/format';

type IconStyle = 'INFO' | 'PAUSED' | 'ERROR';

interface TableWarningIconProps {
  iconStyle: IconStyle;
  tooltipText: string;
}

export function TableWarningIcon(props: TableWarningIconProps) {
  const theme = useTheme();

  let icon: JSX.Element;
  let backgroundColor: string;
  switch (props.iconStyle) {
    case 'PAUSED':
      icon = <PausedSvgIcon color={theme.palette.warning[400]} />;
      // eslint-disable-next-line prefer-destructuring
      backgroundColor = theme.palette.warning[100];
      break;
    case 'ERROR':
      icon = <WarningSvgIcon color={theme.palette.error[500]} />;
      // eslint-disable-next-line prefer-destructuring
      backgroundColor = theme.palette.error[100];
      break;
    case 'INFO':
    default:
      icon = <ExclamationCircleSvgIcon color={theme.palette.blue[600]} fontSize={18} />;
      // eslint-disable-next-line prefer-destructuring
      backgroundColor = theme.palette.blue[100];
      break;
  }

  return (
    <Tooltip placement="top" title={props.tooltipText}>
      <Box
        alignItems="center"
        borderRadius="50%"
        display="flex"
        height={40}
        justifyContent="center"
        minHeight={40}
        minWidth={40}
        width={40}
        style={{ backgroundColor }}
      >
        {icon}
      </Box>
    </Tooltip>
  );
}

export function AccountNameCell({
  accountName,
  accountNumber,
  taxable,
  custodianKey,
  engineType,
}: {
  accountName: string;
  accountNumber: string;
  taxable: boolean;
  custodianKey: Custodian;
  engineType?: EngineType;
}) {
  let engineTypeText: string | undefined;
  switch (engineType) {
    case 'AB_FIXED_INCOME': {
      engineTypeText = 'Fixed Income';
      break;
    }
    default: {
      engineTypeText = undefined;
    }
  }

  return (
    <Box alignItems="center" flexDirection="column" minWidth={0}>
      <Box pb={0.25}>
        <Truncation>{accountName}</Truncation>
      </Box>
      <Box display="flex" fontSize={14}>
        <Typography color="textSecondary">
          {`${taxable ? 'Non-qualified' : 'Qualified'}`}
        </Typography>
        <Box bgcolor="grey.300" alignSelf="stretch" mx={1}>
          <Divider orientation="vertical" flexItem />
        </Box>
        <Typography color="textSecondary">
          {maskAccountNumber(accountNumber)}, {getCustodianDisplayName(custodianKey)}
        </Typography>

        {engineTypeText != null && (
          <>
            <Box bgcolor="grey.300" alignSelf="stretch" mx={1}>
              <Divider orientation="vertical" flexItem />
            </Box>
            <Box
              color={tokens.palette.primaryBlue[400]}
              borderColor={tokens.palette.primaryBlue[400]}
              sx={{ borderBottomStyle: 'dashed' }}
              borderBottom={1}
            >
              {engineTypeText}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export function accountStatusTooltipText({
  rebalancerPausedAt,
  status,
}: {
  rebalancerPausedAt: string | null;
  status: 'ACTIVE' | 'PAUSED' | 'ERROR';
}) {
  const isAccountInactive = status === 'ERROR';
  const isRebalancerPaused = status === 'PAUSED';
  if (isAccountInactive) {
    return 'Account is inactive';
  }
  if (isRebalancerPaused) {
    const durationPaused = moment(rebalancerPausedAt ?? undefined).fromNow(true);
    const rebalancerPausedAtFormatted = moment(rebalancerPausedAt ?? undefined).format('lll');
    return `Auto rebalancer has been paused for ${durationPaused} (since ${rebalancerPausedAtFormatted})`;
  }
  return undefined;
}

export function AccountStatusIcon({
  performanceQaRequired,
  rebalancerPausedAt,
  rebalancerStatus,
  status,
}: {
  performanceQaRequired?: boolean;
  rebalancerPausedAt: string | null;
  rebalancerStatus: RebalancerStatus;
  status: AccountStatus;
}) {
  const isAccountInactive = status === 'INACTIVE';
  const isRebalancerPaused = rebalancerStatus === 'PAUSED';

  let issueIcon = <Box mr={5} />;
  if (isAccountInactive) {
    issueIcon = <TableWarningIcon iconStyle="ERROR" tooltipText="Account is inactive" />;
  } else if (isRebalancerPaused) {
    const durationPaused = moment(rebalancerPausedAt ?? undefined).fromNow(true);
    const rebalancerPausedAtFormatted = moment(rebalancerPausedAt ?? undefined).format('lll');
    issueIcon = (
      <TableWarningIcon
        iconStyle="PAUSED"
        tooltipText={`Auto rebalancer has been paused for ${durationPaused} (since ${rebalancerPausedAtFormatted})`}
      />
    );
  } else if (performanceQaRequired) {
    issueIcon = (
      <TableWarningIcon iconStyle="INFO" tooltipText="Performance is pending reconciliation" />
    );
  }
  return issueIcon;
}
