import React, { useMemo, useState } from 'react';
import { Box, Card, CardContent, Divider, Typography, useTheme } from '@mui/material';
import { PerformanceChart } from '~/components/chart';
import usePerformanceAndBenchmarks from '~/hooks/usePerformanceAndBenchmarks';
import { alignChartData } from '~/utils/benchmarks';
import PercentChange from '~/synth/PercentChange';
import Select from '~/synth/inputs/Select';

import { convertToPercent } from '~/utils/format';
import GPTCardHeader from './GPTCardHeader';
import { calculateChartMetric } from '../../Portfolio/portfolioUtil';
import LoadingCard from './LoadingCard';
import CardDisclosures from './CardDisclosures';
import useAccountWithLogging from './useAccountWithLogging';
import usePortfolioIntelligenceWithLogging from './usePortfolioIntelligenceWithLogging';

interface BenchmarkOption {
  label: string;
  value: string;
}

const GPTPerformanceChart = ({ accountId }) => {
  const { data: accountResponseData } = useAccountWithLogging({
    accountId,
    includeSummary: true,
    callerName: 'GPTPerformanceChart',
  });

  const account = accountResponseData?.data;

  const performanceMetric = account?.bdPerformance?.itdRorFraction;

  const { data: intelligenceArrayData } = usePortfolioIntelligenceWithLogging(
    accountResponseData?.data?.portfolioIntelligenceId,
    'GPTPerformanceChart'
  );
  const intelligence = intelligenceArrayData?.[0];

  const theme = useTheme();
  const performanceSeriesColors = [theme.palette.blue[500], theme.palette.accents.magenta.main];

  const [benchmark, setBenchmark] = useState<BenchmarkOption | null>(null);
  const { data: perfAndBenchmarks } = usePerformanceAndBenchmarks(account?.id || '');

  const instrumentBenchmarks = useMemo(
    () => perfAndBenchmarks?.benchmarks || [],
    [perfAndBenchmarks]
  );

  const benchmarkOptions = useMemo(
    () =>
      instrumentBenchmarks?.map(({ name: label }) => ({
        label,
        value: label,
      })),
    [instrumentBenchmarks]
  );

  const performanceData = useMemo(() => {
    const bdTimeseries = perfAndBenchmarks?.bdTimeseries || [];
    if (!benchmark) {
      return [{ name: 'Account performance', data: bdTimeseries || [] }];
    }

    const alignedData = alignChartData([
      { name: 'Account performance', data: bdTimeseries || [] },
      {
        name: 'Benchmark',
        data: instrumentBenchmarks?.find((b) => b.name === benchmark?.value)?.dailyDataHC || [],
      },
    ]);

    return alignedData;
  }, [perfAndBenchmarks, benchmark, instrumentBenchmarks]);

  // need to be checked
  const benchmarkMetric = useMemo(() => {
    return performanceData[1]?.data && performanceData[1].data.length >= 1
      ? calculateChartMetric(
          performanceData[1].data[0][0],
          performanceData[1].data[performanceData[1].data.length - 1][0],
          performanceData[1].data
        )
      : null;
  }, [performanceData]);

  if (!account || !intelligence) {
    return <LoadingCard contentHeight="460px" />;
  }

  return (
    <Card>
      <GPTCardHeader
        title="Portfolio Performance"
        buttonPathname={`/secure/accounts/${accountId}/portfolio/overview`}
        buttonCopy="View account"
        account={account}
      />

      <Box height="460px">
        <CardContent>
          <Box mb={4} display="flex" justifyContent="space-between">
            <Box display="flex">
              <Box display="flex">
                <Box
                  width={10}
                  height={10}
                  borderRadius="2px"
                  bgcolor="primary.main"
                  mt={0.6}
                  mr={1}
                />
                <Box>
                  <Typography variant="h5" color="textSecondary">
                    Vise Performance (ITD)
                  </Typography>
                  <Box mt={1}>
                    {performanceMetric != null ? (
                      <PercentChange value={convertToPercent(performanceMetric)} />
                    ) : (
                      '-'
                    )}
                  </Box>
                </Box>
              </Box>
              {benchmark && (
                <Box display="flex" ml={5}>
                  <Box
                    width={10}
                    height={10}
                    borderRadius="2px"
                    bgcolor={theme.palette.accents.magenta.main}
                    mt={0.6}
                    mr={1}
                  />
                  <Box>
                    <Typography variant="h5" color="textSecondary">
                      {benchmark.label}
                    </Typography>
                    <Box mt={1}>
                      {benchmarkMetric != null ? (
                        <PercentChange value={convertToPercent(benchmarkMetric)} />
                      ) : (
                        '-'
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Typography variant="body2" color="textSecondary">
                  {' '}
                  Compare:{' '}
                </Typography>
              </Box>
              <Box width="320px">
                <Select
                  isClearable
                  name="benchmark"
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: BenchmarkOption | null) => void' is ... Remove this comment to see the full error message
                  onChange={(value: BenchmarkOption | null) => setBenchmark(value)}
                  options={benchmarkOptions}
                  placeholder="Add benchmark"
                  value={benchmark}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
        <Box mb={2}>
          <PerformanceChart
            chartHeight={300}
            colors={performanceSeriesColors}
            compare
            datas={performanceData}
            view="realview"
          />
        </Box>
      </Box>
      <Divider />
      <CardDisclosures />
    </Card>
  );
};

export default GPTPerformanceChart;
