import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo } from 'react';
import useAllRestrictionsTemplates from '~/hooks/templates/useAllRestrictionsTemplates';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';

export default function useRestrictionsTemplateValidation() {
  const { data: userTemplates, error: userTemplatesError } = useAllRestrictionsTemplates();
  const enqueueCoackmark = useEnqueueCoachmark();
  useEffect(() => {
    if (userTemplatesError) {
      enqueueCoackmark({
        severity: 'error',
        title: 'There was an error retrieving the data for this page.',
        content:
          'This could be due to lost connection, your session ending, or an internal error. Please refresh and try again.',
      });
    }
  }, [enqueueCoackmark, userTemplatesError]);

  const userTemplatesNames = useMemo(
    () => (userTemplates == null ? null : new Set(userTemplates.map((t) => t.name.trim()))),
    [userTemplates]
  );

  const isTemplateNameTaken = useCallback(
    (name: string) =>
      !isEmpty(name) && userTemplatesNames != null && userTemplatesNames.has(name.trim()),
    [userTemplatesNames]
  );

  const isNameValid = useCallback(
    (name: string) => {
      return !isEmpty(name) && userTemplatesNames != null && !userTemplatesNames.has(name.trim());
    },
    [userTemplatesNames]
  );

  // TODO: This file is almost identical to useAllocationsTemplateValidation.ts but we are looking to add
  // content duplication validation which will differ for the two types of templates

  return {
    isNameValid: userTemplatesNames == null ? undefined : isNameValid,
    isTemplateNameTaken: userTemplatesNames == null ? undefined : isTemplateNameTaken,
  };
}
