import { Box, Chip, FormLabel } from '@mui/material';
import React, { useMemo } from 'react';
import { ActionMeta, ValueType } from 'react-select';
import { Country } from 'vise-types/pce2_instrument';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import Multiselect from '~/synth/inputs/Multiselect';
import amplitude from '~/utils/amplitude';
import { COUNTRY_TO_LABEL_MAP, DEVELOPED_COUNTRIES } from '../../Constants';
import { DetailsText } from './SummarySections';

export interface CountryOption {
  label: string;
  value: Country;
}

interface CountriesSelectorProps {
  /** An array of countries */
  value: Country[];
  /** onChange handler given to the multiselector */
  onChange: (value: CountryOption[], action: ActionMeta<CountryOption>) => void;
  /** Callback invoked when user removes a selection via a chip */
  onRemove: (country: Country) => void;
}

type SelectedCountriesOptions = CountryOption[] | null;

export default function CountriesSelector({
  value = [],
  onChange,
  onRemove,
}: CountriesSelectorProps) {
  const selectedOptions = useMemo(() => {
    return value.map((country) => {
      return {
        label: COUNTRY_TO_LABEL_MAP[country],
        value: country,
      };
    });
  }, [value]);

  const allOptions = useMemo(() => {
    return DEVELOPED_COUNTRIES.map((country) => ({
      label: COUNTRY_TO_LABEL_MAP[country],
      value: country,
    }));
  }, []);

  const handleChange = (
    selectedOptions: ValueType<CountryOption>,
    actionMeta: ActionMeta<CountryOption>
  ) => {
    const { action, option } = actionMeta;
    const selectedCountries = (selectedOptions as SelectedCountriesOptions) ?? [];
    if (
      action !== 'select-option' &&
      action !== 'deselect-option' &&
      action !== 'clear' &&
      action !== 'set-value'
    ) {
      return;
    }
    if (option != null) {
      amplitude().logEvent(
        selectedCountries.length > value.length
          ? 'Add country restriction'
          : 'Remove country restriction',
        {
          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
          ticker: option.value,
        }
      );
    } else if (action === 'clear') {
      amplitude().logEvent('Clear country restrictions', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      });
    }
    onChange(selectedCountries, { action, option });
  };

  return (
    <Box data-testid="countries">
      <Box mb={1}>
        <FormLabel htmlFor="countries">Countries</FormLabel>
        <Box mt={0.5}>
          <DetailsText variant="body1" color="textSecondary">
            Exclude countries into which Vise strategies may allocate.
          </DetailsText>
        </Box>
      </Box>
      <Multiselect<CountryOption>
        onFocus={() => {
          amplitude().logEvent('Tap countries restriction search', {
            category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
          });
        }}
        options={allOptions}
        isSearchable
        inputId="countries"
        isLoading={allOptions == null}
        onChange={handleChange}
        placeholder={`Ex. ${allOptions[0].label}`}
        value={selectedOptions}
        noOptionsMessage={(input) =>
          `[${input.inputValue}] is not a country Vise invests in and cannot be restricted.`
        }
      />
      {value.map((r) => (
        <Box mr={1.5} mt={1.5} key={r}>
          <Chip
            label={COUNTRY_TO_LABEL_MAP[r]}
            onDelete={() => {
              amplitude().logEvent('Remove country restriction', {
                category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                ticker: r,
                usedChip: true,
              });
              onRemove(r);
            }}
          />
        </Box>
      ))}
    </Box>
  );
}
