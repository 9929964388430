import React, { ReactElement, useEffect, useState } from 'react';
import { Props as NestedFormEditProps } from './NestedFormEdit';

interface Props<T> {
  children: ReactElement<NestedFormEditProps<T>>[];
  onActiveChange?: (activeIndex: number | null) => void;
  defaultActiveIndex?: number;
}

function NestedFormEditGroup({ children, onActiveChange, defaultActiveIndex }: Props<unknown>) {
  const [activeIndex, setActiveIndex] = useState<number | null>(defaultActiveIndex ?? null);

  function handleOnActiveChange(newActiveIndex: number | null) {
    setActiveIndex(newActiveIndex);
    if (onActiveChange) {
      onActiveChange(newActiveIndex);
    }
  }

  useEffect(() => {
    React.Children.forEach(children, (child, childIndex) => {
      if (activeIndex === childIndex && child.props.disabled) {
        handleOnActiveChange(null);
      }
    });
  });

  return (
    <>
      {React.Children.map(children, (child, childIndex) =>
        React.cloneElement(child, {
          isEditMode: childIndex === activeIndex && !child.props.disabled,
          disabled: child.props.disabled || (activeIndex != null && childIndex !== activeIndex),
          onToggleEditMode: () => {
            if (activeIndex === childIndex) {
              handleOnActiveChange(null);
            } else {
              handleOnActiveChange(childIndex);
            }
          },
          onSave: () => {
            if (child.props.onSave) {
              child.props.onSave();
            }

            const nextChildIndex = childIndex + 1;
            if (nextChildIndex < children.length && !children[nextChildIndex].props.disabled) {
              handleOnActiveChange(nextChildIndex);
            } else {
              handleOnActiveChange(null);
            }
          },
        })
      )}
    </>
  );
}

export default NestedFormEditGroup;
