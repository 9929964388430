import React, { useMemo } from 'react';
import { Column } from 'react-table';

import { DataTable } from '~/synth/DataTable';
import Skeleton from '~/synth/Skeleton';

import { RightAlignBox } from '../../Portfolio/components/TableComponents';

const LoadingTable = ({ pageSize }: { pageSize: number }) => {
  const columns = useMemo(
    () =>
      [
        {
          Header: <Skeleton inline width="10em" />,
          accessor: 'name',
          width: '30%',
          Cell: <Skeleton inline width="25em" />,
        },
        {
          Header: <Skeleton inline width="6em" />,
          accessor: 'itd',
          align: 'right',
          Cell: () => (
            <RightAlignBox>
              <Skeleton inline width="6em" />
            </RightAlignBox>
          ),
          width: '10%',
        },
        {
          Header: <Skeleton inline width="6em" />,
          accessor: 'ytd',
          align: 'right',
          Cell: () => (
            <RightAlignBox>
              <Skeleton inline width="6em" />
            </RightAlignBox>
          ),
          width: '10%',
        },
        {
          Header: <Skeleton inline width="6em" />,
          accessor: 'aum',
          align: 'right',
          Cell: () => (
            <RightAlignBox>
              <Skeleton inline width="7em" />
            </RightAlignBox>
          ),
          width: '10%',
        },
      ] as Column<{ [key in string]: string }>[],
    []
  );
  return <DataTable columns={columns} data={Array(pageSize).fill({})} />;
};

export default LoadingTable;
