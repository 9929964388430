/* eslint-disable react/no-this-in-sfc */

import { Theme } from '@mui/material/styles';
import * as Highcharts from 'highcharts';
import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { withTheme } from 'styled-components';
import { AdjustedSimulation } from '~/models/api';
import amplitude from '../../utils/amplitude';
import { compactDollarFormatter, formatCurrency } from '../../utils/format';
import { EVENT_CATEGORIES } from '../../constants/amplitude';
import Chart from './Chart';
import { getDefaultChartOptions } from './useDefaultChartOptions';

export interface PerformanceData {
  name: string;
  data: [number, number][];
}

interface TooltipProps {
  formatValue: (point: Highcharts.Point) => React.ReactNode;
  points: Highcharts.TooltipFormatterContextObject['points'];
  x: Highcharts.TooltipFormatterContextObject['x'];
}

export function Tooltip({ formatValue, points, x }: TooltipProps) {
  return (
    <>
      <div style={{ fontSize: '10px', marginBottom: '4px' }}>
        {Highcharts.dateFormat('%Y-%m-%d', x)}
      </div>
      <table style={{ fontSize: '12px', width: '100%' }}>
        <tbody>
          {points?.map((context, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={i}>
              <td style={{ paddingRight: '24px', paddingTop: '3px' }}>
                <span
                  style={{
                    backgroundColor: String(context.color),
                    borderRadius: '2px',
                    display: 'inline-block',
                    height: '4px',
                    marginRight: '4px',
                    position: 'relative',
                    top: '-2px',
                    width: '12px',
                  }}
                />
                {context.series.name}
              </td>
              <td
                style={{
                  textAlign: 'right',
                  paddingTop: '3px',
                }}
              >
                {formatValue(context.point)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

type PerformanceChartProps = {
  areaColors?: string[];
  chartHeight?: number;
  colors: string[];
  compare?: boolean;
  /** Not intended for use with Highcharts native date range selector. */
  min?: number;
  max?: number;
  theme: Theme;
  xAxisAfterSetExtremes?: Highcharts.AxisSetExtremesEventCallbackFunction;
  withNativeDateRangeSelector?: boolean;
} & (
  | {
      view: 'realview';
      datas: PerformanceData[];
    }
  | {
      view: 'simulation';
      datas: AdjustedSimulation[];
    }
);

interface AreaColor {
  fillColor: Highcharts.ColorType;
  tooltip: {
    valueDecimals: number;
  };
  type: string;
}

const defaultAreaColors: AreaColor[] = [
  {
    fillColor: {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
      },
      stops: [
        [0, 'rgba(227, 235, 252, 0.23)'],
        [1, 'rgba(254, 254, 254, 0.65)'],
      ],
    },
    tooltip: {
      valueDecimals: 2,
    },
    type: 'area',
  },
  {
    fillColor: {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
      },
      stops: [
        [0, 'rgba(22, 209, 181, 0.16)'],
        [1, '#fff'],
      ],
    },
    tooltip: {
      valueDecimals: 2,
    },
    type: 'area',
  },
  {
    fillColor: {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
      },
      stops: [
        [0, 'rgba(255, 217, 65, 0.10)'],
        [1, '#fff'],
      ],
    },
    tooltip: {
      valueDecimals: 2,
    },
    type: 'area',
  },
];
class PerformanceChart extends Component<PerformanceChartProps> {
  generateSimulationOptions = memoizeOne(
    (
      colors: PerformanceChartProps['colors'],
      data: AdjustedSimulation[],
      chartHeight?: PerformanceChartProps['chartHeight'],
      min?: PerformanceChartProps['min'],
      max?: PerformanceChartProps['max'],
      withNativeDateRangeSelector?: PerformanceChartProps['withNativeDateRangeSelector']
    ) => {
      const defaultChartOptions = getDefaultChartOptions(this.props.theme);
      return merge(defaultChartOptions, {
        chart: {
          height: chartHeight || 400,
        },
        colors,
        lang: {
          noData: 'There is currently no performance data to display. Try again later.',
        },
        noData: {
          style: {
            color: this.props.theme.palette.grey[600],
            fontSize: '15px',
            fontWeight: '500',
          },
        },
        // TODO(nkang): Delete when all charts use the external date range selector.
        rangeSelector: {
          enabled: Boolean(withNativeDateRangeSelector),
          verticalAlign: 'bottom',
        },
        navigator: {
          enabled: Boolean(withNativeDateRangeSelector),
        },
        series: [
          ...data.map(({ configuredData, percentile }, index) => {
            return {
              color: colors[index],
              dashStyle: percentile === 'Average' ? 'Solid' : 'ShortDash',
              data: configuredData,
              fillColor: {
                linearGradient: {
                  x1: 0,
                  x2: 0,
                  y1: 0,
                  y2: 1,
                },
                stops: [
                  [0, colors[index]],
                  [1, '#fff'],
                ],
              },
              name: percentile,

              type: 'line',
            };
          }),
        ],
        tooltip: {
          valueDecimals: 2,
          pointFormatter() {
            return `<span style="color: ${this.color}">• </span>${
              this.series.name
            }:  <b>${formatCurrency(this.y)}</b>`;
          },
          valuePrefix: '$',
          valueSuffix: '',
          xDateFormat: '%B %Y',
        },
        xAxis: {
          min,
          max,
        },
        yAxis: {
          labels: {
            ...defaultChartOptions.yAxis.labels,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return formatCurrency(this.value, compactDollarFormatter);
            },
          },
        },
      } as Highcharts.Options);
    }
  );

  generateBenchmarkOptions = memoizeOne(
    (
      colors: string[],
      datas: PerformanceData[],
      areaColors: AreaColor[],
      theme: Theme,
      xAxisAfterSetExtremes: Highcharts.AxisSetExtremesEventCallbackFunction | undefined,
      compare: boolean | undefined,
      chartHeight?: number | 400,
      min?: number,
      max?: number,
      withNativeDateRangeSelector?: boolean
    ) =>
      merge(getDefaultChartOptions(this.props.theme), {
        chart: {
          spacingBottom: 0,
          style: {
            fontFamily: theme.typography.fontFamily,
          },
          height: chartHeight,
        },
        credits: {
          enabled: false,
        },
        labels: {
          style: {
            fontFamily: theme.typography.fontFamily,
          },
        },
        lang: {
          noData: 'There is currently no performance data to display. Try again later.',
        },
        navigator: {
          enabled: Boolean(this.props.withNativeDateRangeSelector),
          adaptToUpdatedData: false,
        },
        scrollbar: { enabled: false },
        noData: {
          style: {
            color: this.props.theme.palette.grey[600],
            fontSize: '15px',
            fontWeight: '500',
          },
        },
        plotOptions: {
          series: {
            compare: compare ? 'percent' : undefined,
            compareStart: true,
            showInNavigator: true,
          },
        },
        xAxis: {
          events: {
            afterSetExtremes: xAxisAfterSetExtremes,
          },
          min,
          max,
        },
        yAxis: {
          labels: {
            formatter() {
              return `${this.value}%`;
            },
          },
          plotLines: [
            {
              color: 'silver',
              value: 0,
              width: 2,
            },
          ],
        },
        // TODO(nkang): Delete when all charts use the external date range selector.
        rangeSelector: {
          enabled: Boolean(withNativeDateRangeSelector),
          verticalAlign: 'bottom',
          buttons: [
            {
              type: 'month',
              count: 1,
              text: '1M',
            },
            {
              type: 'month',
              count: 3,
              text: '3M',
            },
            {
              type: 'month',
              count: 6,
              text: '6M',
            },
            {
              type: 'ytd',
              text: 'YTD',
            },
            {
              type: 'year',
              count: 1,
              text: '1Y',
            },
            {
              type: 'all',
              text: 'All',
            },
          ].map((button) => {
            return {
              ...button,
              events: {
                click: () => {
                  amplitude().logEvent('Action - Select Date Range', {
                    category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
                    eventProperties: {
                      Range: button.text,
                      ChartType: 'Performance',
                    },
                  });
                },
              },
            };
          }),
          buttonSpacing: 8,
          buttonTheme: {
            fill: 'none',
            r: 2,
            stroke: 'none',
            style: {
              color: theme.palette.grey[500],
              fontWeight: '500',
              fontSize: '14px',
            },
            states: {
              hover: {
                fill: 'rgba(19, 19, 19, 0.04)',
              },
              select: {
                fill: '#E3EBFC',
                style: {
                  color: theme.palette.primary.main,
                  fontWeight: '500',
                },
              },
            },
          },
          inputBoxBorderColor: theme.palette.grey[300],
        },
        series: datas.map((dataItem, index) => {
          let { data } = dataItem;

          if (compare) {
            // We trim the first n points with 0 value because they will have -100% change value as
            // Highcharts uses the first non-zero value as the base to compute change percentages.
            const firstNonZeroPointIndex = dataItem.data.findIndex((point) => point[1] !== 0);
            if (firstNonZeroPointIndex === -1) {
              data = [];
            } else if (firstNonZeroPointIndex > 0) {
              data = dataItem.data.slice(firstNonZeroPointIndex);
            }
          }

          return {
            color: colors[index],
            dashStyle: 'Solid',
            data,

            // Per design, fill chart areas only when there is a single series. Filling when there
            // are multiple series makes it difficult to read.
            fillColor: datas.length > 1 ? 'none' : areaColors[index].fillColor,
            name: dataItem.name,

            // Fill toward 0 ("down" when value is positive, "up" with value is negative) since 0
            // is always the reference number for performance.
            threshold: 0,
            tooltip: areaColors[index].tooltip,
            type: areaColors[index].type,
          };
        }),
        tooltip: {
          backgroundColor: theme.palette.grey[100],
          borderColor: theme.palette.grey[200],
          borderRadius: 4,
          formatter() {
            return ReactDOMServer.renderToStaticMarkup(
              <Tooltip
                formatValue={(point) => {
                  // `point.change` is not part of TS types but is added to HighStocks points when
                  // [`plotOptions.series.compare`][0] == 'percent'.
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const { change } = point as any;
                  const percentageValue = change == null ? point.y : change.toFixed(2);
                  let percentageColor: string | undefined;
                  if (percentageValue > 0) {
                    // We are getting theme colors here.
                    // eslint-disable-next-line prefer-destructuring
                    percentageColor = theme.palette.success[500];
                  } else if (percentageValue < 0) {
                    // eslint-disable-next-line prefer-destructuring
                    percentageColor = theme.palette.error[500];
                  }
                  return <span style={{ color: percentageColor }}>{percentageValue}%</span>;
                }}
                points={this.points}
                x={this.x}
              />
            );
          },
          padding: 8,
          shared: true,
          useHTML: true,
        },
      } as Highcharts.Options)
  );

  getAreaColors = memoizeOne((areaColors: string[] | undefined): AreaColor[] => {
    return areaColors
      ? areaColors.map((color) => ({
          fillColor: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, color],
              [1, '#fff'],
            ],
          },
          tooltip: {
            valueDecimals: 2,
          },
          type: 'area',
        }))
      : defaultAreaColors;
  });

  render() {
    const {
      chartHeight,
      colors,
      compare = false,
      max,
      min,
      theme,
      withNativeDateRangeSelector,
      xAxisAfterSetExtremes,
    } = this.props;
    const areaColors = this.getAreaColors(this.props.areaColors);

    if (this.props.view === 'realview') {
      return (
        <Chart
          constructorType="stockChart"
          highstock
          options={this.generateBenchmarkOptions(
            colors,
            this.props.datas,
            areaColors,
            theme,
            xAxisAfterSetExtremes,
            compare,
            chartHeight,
            min,
            max,
            withNativeDateRangeSelector
          )}
        />
      );
    }

    return (
      <Chart
        constructorType="stockChart"
        highstock
        options={this.generateSimulationOptions(
          colors,
          this.props.datas,
          chartHeight,
          min,
          max,
          withNativeDateRangeSelector
        )}
      />
    );
  }
}

export default withTheme(PerformanceChart);
