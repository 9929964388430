import { Country } from 'vise-types/pce2_instrument';
import { insertRestrictionsTemplate } from '../../api/api';
import useEnqueueToast from '../useToast';

type InsertRestrictionsTemplateOptions = {
  name: string;
  userId: string;
  orgId: string;
  tickers: string[];
  subSectors: string[];
  sectors: string[];
  countries: Country[];
  esgAreas: string[];
};

export default function useInsterRestrictionsTemplate() {
  const enqueueToast = useEnqueueToast();
  const insertTemplate = async ({
    name,
    userId,
    orgId,
    tickers,
    subSectors,
    sectors,
    countries,
    esgAreas,
  }: InsertRestrictionsTemplateOptions) => {
    try {
      const template = await insertRestrictionsTemplate({
        name,
        userId,
        orgId,
        tickers,
        subSectors,
        sectors,
        countries,
        esgAreas,
      });
      enqueueToast({
        severity: 'success',
        title: `You've successfully created your restrictions template: ${name}.`,
      });
      return template;
    } catch (e) {
      enqueueToast({
        severity: 'error',
        title: `Failed to create restrictions template.`,
        content: `Please reach out to clientservice@vise.com for help.`,
      });
      return false;
    }
  };
  return { insertTemplate };
}
