import { Link, LinkProps, TypographyProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { PopoverTriggerProps } from './PopoverTrigger';

const usePopoverLinkStyles = makeStyles()((theme) => ({
  hoverableLink: {
    borderBottom: `1px dashed ${theme.palette.blue[300]}`,
  },
  clickableLink: {
    borderBottom: `1px solid`,
  },
}));

const PopoverLink: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<
      LinkProps<'button'> &
        Pick<PopoverTriggerProps, 'triggerAction'> &
        Pick<TypographyProps, 'variant'>
    >
  >
> = ({ triggerAction, variant, ...props }) => {
  const { classes: popoverLinkClasses, cx } = usePopoverLinkStyles();
  const isHover = triggerAction === 'hover';

  return (
    // Disable lint rule because `component="button"` changes the underlying element
    // to a button, but eslint can't recognize that.
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      component="button"
      underline="none"
      variant={variant ?? 'body1'}
      color="textSecondary"
      className={cx(
        isHover && popoverLinkClasses.hoverableLink,
        !isHover && popoverLinkClasses.clickableLink
      )}
      type="button"
      {...props}
    />
  );
};
export default PopoverLink;
