// chart data array element is like this:
// { name: 'ABC', data: [[time1, value], [time2, value], ...] }
interface ChartSeries {
  data: [number, number][];
  name: string;
}

export const checkSeriesOverlap = (series1: [number, number][], series2: [number, number][]) => {
  if (!series1 || series1.length === 0 || !series2 || series2.length === 0) {
    return false;
  }
  // If the last date of series 1 is before the first date of series 2,
  // or the first date of series 1 is after the last date of series 2,
  // there is no overlap.
  if (
    series1[series1.length - 1][0] < series2[0][0] ||
    series1[0][0] > series2[series2.length - 1][0]
  ) {
    return false;
  }

  return true;
};

// * Assumes `data` arrays are sorted by date, ascending (i.e. from earliest -> latest)
export const alignChartData = (chartSeriesArray: ChartSeries[] | null): ChartSeries[] => {
  if (!chartSeriesArray || chartSeriesArray.length === 0) {
    return [];
  }

  const startDate = Math.max(
    ...chartSeriesArray.map((chartSeries) =>
      chartSeries.data?.length > 0 ? chartSeries.data[0][0] : 0
    )
  );

  const endDate = Math.min(
    ...chartSeriesArray.map((chartSeries) =>
      chartSeries.data?.length > 0
        ? chartSeries.data[chartSeries.data.length - 1][0]
        : Number.MAX_VALUE
    )
  );

  // Align the dates.
  return chartSeriesArray
    .filter((chartSeries) => chartSeries.data?.length > 0)
    .map((chartSeries) => ({
      ...chartSeries,
      data: chartSeries.data.filter((datum) => datum[0] >= startDate && datum[0] <= endDate),
    }));
};
