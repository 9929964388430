import { XrayClient, XrayClientProposal } from 'vise-types/xray';

type AccountHolderInformation = { firstName: string; middleName?: string; lastName: string };

export const CustodianSelectValues = [
  { value: 'FIDELITY', label: 'Fidelity' },
  { value: 'SCHWAB', label: 'Schwab' },
] as const;

export const isSamOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
] as const;

export type State = {
  selectedClient: XrayClient | null;
  selectedXray: XrayClientProposal | null;
  custodian: (typeof CustodianSelectValues)[number] | null;
  currentAccountInformation: AccountHolderInformation;
  accountHolders: AccountHolderInformation[];
  existingAccountNumber: string;
  slMasterAccountNumber: string;
  fidelityAccountNumber: string;
  brokerageAccountNumber: string;
  isNewAccountInAdvisorsBook: boolean;
  faMasterAccountNumber: string;
  managedByAnotherSam: (typeof isSamOptions)[number] | null;
  previousSamName: string;
  previousSamProductName: string;
};

export const initialState: State = {
  selectedClient: null,
  custodian: null,
  currentAccountInformation: { firstName: '', lastName: '' },
  accountHolders: [],
  existingAccountNumber: '',
  slMasterAccountNumber: '',
  fidelityAccountNumber: '',
  brokerageAccountNumber: '',
  isNewAccountInAdvisorsBook: false,
  faMasterAccountNumber: '',
  managedByAnotherSam: null,
  previousSamName: '',
  previousSamProductName: '',
  selectedXray: null,
};

export type Action =
  | { type: 'selectedClient'; value: (typeof initialState)['selectedClient'] }
  | { type: 'custodian'; value: State['custodian'] }
  | { type: 'selectedXray'; value: State['selectedXray'] }
  | { type: 'currentAccountInformation'; value: State['currentAccountInformation'] }
  | { type: 'accountHolders'; value: State['accountHolders'] }
  | { type: 'slMasterAccountNumber'; value: State['slMasterAccountNumber'] }
  | { type: 'brokerageAccountNumber'; value: State['brokerageAccountNumber'] }
  | { type: 'existingAccountNumber'; value: State['existingAccountNumber'] }
  | { type: 'isNewAccountInAdvisorsBook'; value: State['isNewAccountInAdvisorsBook'] }
  | { type: 'faMasterAccountNumber'; value: State['faMasterAccountNumber'] }
  | { type: 'managedByAnotherSam'; value: State['managedByAnotherSam'] }
  | { type: 'previousSamName'; value: State['previousSamName'] }
  | { type: 'previousSamProductName'; value: State['previousSamProductName'] }
  | { type: 'fidelityAccountNumber'; value: State['fidelityAccountNumber'] };
