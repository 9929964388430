import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { OptionsObject, SnackbarMessage } from 'notistack';
import { enqueueAlert as enqueueAlertAction } from '../actions';

export default function useEnqueueAlert() {
  const dispatch = useDispatch();
  const enqueueAlert = useCallback(
    (args: { message: SnackbarMessage; options?: OptionsObject }) => {
      dispatch(enqueueAlertAction(args));
    },
    [dispatch]
  );
  return enqueueAlert;
}
