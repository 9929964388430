import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { tokens } from '@vise_inc/ds-vise';
import React, { useEffect, useState } from 'react';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useTransitionAnalysis from '~/hooks/useTransitionAnalysis';
import { CapitalGains } from '~/models/api';
import Skeleton from '~/synth/Skeleton';
import { ACCOUNTING_FORMATTER, formatCurrency, formatPercent } from '~/utils/format';
import CaptionWithContent from '../../components/CaptionWithContent';
import { SectionSubtitle, TaxTransitionDataVis, TaxTransitionDisclaimer } from './UtilComponents';

const Td = styled(TableCell)(() => ({
  fontSize: 14,
  padding: '16px',
}));

const SideTd = styled(Td)(() => ({
  fontWeight: 500,
  color: tokens.palette.neutralCool[600],
}));

export default function TaxScenarios({
  gains,
  intelligence,
}: {
  gains: CapitalGains;
  intelligence: PortfolioIntelligenceFull;
}) {
  const [highlightedSection, setHighlightedSection] = useState<'unrealized' | 'tax-rates' | null>();
  const enqueueCoachmark = useEnqueueCoachmark();
  const { data: transitionAnalysisData, error } = useTransitionAnalysis(
    intelligence.accountId,
    intelligence.id
  );
  if (intelligence.proposalType === 'light') {
    throw new Error(`Portfolio intelligence should be PCE2 and FULL. (id: ${intelligence.id})`);
  }

  const {
    unrealizedShortTermGains,
    unrealizedShortTermLosses,
    unrealizedLongTermGains,
    unrealizedLongTermLosses,
  } = gains;

  // Error capturing
  if (error) {
    enqueueCoachmark({
      title: 'There was an error loading the tax transition settings.',
      severity: 'error',
    });
  }

  useEffect(() => {
    if (error != null) {
      Sentry.captureException(
        new Error(`[TransitionSettingsScenarios] Failed to load transition analysis: ${error}.`),
        {
          extra: {
            intelligenceId: intelligence.id,
          },
        }
      );
    }
  }, [error, intelligence.id]);

  const data = transitionAnalysisData?.data;

  const {
    constructionRequest: { taxOptions },
  } = intelligence;

  return (
    <>
      <Box mb={5} mt={3}>
        <SectionSubtitle
          variant="h6"
          title={`Where did your portfolio start in ${new Date().getFullYear()}?`}
          id="where-did-you-start"
          mb={4}
        />
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <CaptionWithContent
              caption="Starting Portfolio Value"
              content={
                <Typography variant="h2">
                  {formatCurrency(
                    intelligence.constructionResponse.metrics.proposedPortfolioMetrics.totalValue
                  )}
                </Typography>
              }
              mr="auto"
            />
          </Grid>
          <Grid item xs={1}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={9}>
            <Table size="small" padding="none">
              <TableHead>
                <TableRow
                  sx={{
                    '.MuiTableCell-head': {
                      color: tokens.palette.neutralCool[600],
                    },
                    '.MuiTableCell-root.MuiTableCell-root': {
                      borderBottom: 1,
                      borderColor: tokens.palette.neutralCool[200],
                    },
                  }}
                >
                  <Td />
                  <Td>Capital gains budget</Td>
                  <Td>Net unrealized gains</Td>
                  <Td>Est. client tax rates</Td>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    '.MuiTableCell-root.MuiTableCell-root': {
                      borderBottom: 1,
                      borderColor: tokens.palette.neutralCool[200],
                    },
                  }}
                >
                  <SideTd>Short Term</SideTd>
                  <Td>
                    {taxOptions?.maxCapGainsShort == null
                      ? 'No limit set'
                      : formatCurrency(taxOptions?.maxCapGainsShort)}
                  </Td>
                  <Td>
                    {formatCurrency(
                      unrealizedShortTermGains - unrealizedShortTermLosses,
                      ACCOUNTING_FORMATTER
                    )}
                  </Td>
                  <Td>
                    <Box
                      bgcolor={
                        highlightedSection === 'tax-rates'
                          ? tokens.palette.secondaryPurple[100]
                          : ''
                      }
                      p={0.5}
                      width="fit-content"
                      borderRadius={1}
                    >
                      {formatPercent(taxOptions?.shortTermTaxRate)}
                    </Box>
                  </Td>
                </TableRow>
                <TableRow>
                  <SideTd>Long Term</SideTd>
                  <Td>
                    {taxOptions?.maxCapGainsLong == null
                      ? 'No limit set'
                      : formatCurrency(taxOptions?.maxCapGainsLong)}
                  </Td>
                  <Td>
                    <Box
                      bgcolor={
                        highlightedSection === 'unrealized'
                          ? tokens.palette.secondaryPurple[100]
                          : ''
                      }
                      p={0.5}
                      width="fit-content"
                      borderRadius={1}
                    >
                      {formatCurrency(
                        unrealizedLongTermGains - unrealizedLongTermLosses,
                        ACCOUNTING_FORMATTER
                      )}
                    </Box>
                  </Td>
                  <Td>
                    <Box
                      bgcolor={
                        highlightedSection === 'tax-rates'
                          ? tokens.palette.secondaryPurple[100]
                          : ''
                      }
                      p={0.5}
                      width="fit-content"
                      borderRadius={1}
                    >
                      {formatPercent(taxOptions?.longTermTaxRate)}
                    </Box>
                  </Td>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3} mb={5}>
        <SectionSubtitle variant="h6" title="Tax Transition Scenarios" id="tax-transition" mb={2} />
        <Grid item container xs={12} spacing={2}>
          <Grid item container xs={12}>
            {data == null ? (
              <Grid container spacing={2}>
                {[...Array(6).keys()].map((k) => (
                  <Grid key={k} item xs={2}>
                    <Skeleton height="400px" variant="rect" />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <TaxTransitionDataVis
                data={data}
                setHighlightedSection={setHighlightedSection}
                taxOptions={taxOptions}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Box color={tokens.palette.neutralCool[800]}>
              The <b>target scenario</b> is based off the tax inputs that you shared with Vise.
              <Box mt={2}>
                The additional scenarios assume a long-term capital gains budget that is a
                percentage of the total net unrealized gains in the portfolio. Short-term budgets
                are restricted to $0 in all scenarios except for the target and no budget scenarios.
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TaxTransitionDisclaimer taxOptions={taxOptions} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
