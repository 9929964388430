import { AxiosError } from 'axios';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

interface ErrorCodeAndMessage {
  status?: number;
  message: string;
}

function maybeGetAxiosError(
  maybeAxiosError: unknown
): AxiosError<{ message?: string; errors?: unknown[] }> | null {
  try {
    const axiosError = maybeAxiosError as AxiosError<{ message?: string; errors?: unknown[] }>;
    return axiosError;
  } catch (_e) {
    return null;
  }
}

// Extract message and response code from an Axios error. Since caught API errors have unknown
// types, this function attempts to cast it to an AxiosError first, and otherwise returns the
// best possible error message available.
//
// AxiosError has the form: error => { response => { status, data } }
export default function extractStatusAndMessageFromAPIError(error: unknown): ErrorCodeAndMessage {
  const axiosError = maybeGetAxiosError(error) as AxiosError<{
    errors: { msg: string }[];
    message: string;
  }>;
  if (axiosError == null || axiosError.response == null) {
    const maybeErrorMessage = error instanceof Error ? error.message : undefined;
    return { message: maybeErrorMessage ?? DEFAULT_ERROR_MESSAGE };
  }

  const { status, data } = axiosError.response;
  if (data != null) {
    const { errors: expressValidationErrors, message } = data;
    // From Express validator, error data has the form: data => { errors[] => { msg } }
    if (
      expressValidationErrors != null &&
      Array.isArray(expressValidationErrors) &&
      expressValidationErrors.length > 0
    ) {
      const lastValidationError = expressValidationErrors[expressValidationErrors.length - 1];
      if (lastValidationError.msg != null) {
        return { status, message: lastValidationError.msg };
      }
    }
    // Otherwise, error data has the form: data => { message }
    if (message != null) {
      return { status, message };
    }
  }

  return { status, message: DEFAULT_ERROR_MESSAGE };
}
