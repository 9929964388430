import { Box, CardContent } from '@mui/material';
import { last } from 'lodash';
import React from 'react';
import { PortfolioSummary } from 'vise-types/portfolio';
import AccountValueChart from '~/components/chart/AccountValueChart';
import Metric from '~/synth/Metric';
import { formatCurrency } from '~/utils/format';
import { getSummaryDataInRange } from '../portfolioUtil';

export default function PortfolioAccountValueChart({
  accountSummary,
  minDate,
  maxDate,
}: {
  accountSummary: PortfolioSummary[];
  minDate: number;
  maxDate: number;
}) {
  const summaryDataInRange = getSummaryDataInRange(accountSummary, minDate, maxDate);
  const netContribution = last(summaryDataInRange)?.netContribution;
  return (
    <>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Metric
              label="Net contributions"
              metric={netContribution != null ? formatCurrency(netContribution) : '-'}
            />
          </Box>
        </Box>
      </CardContent>
      <AccountValueChart
        min={minDate}
        max={maxDate}
        aggSummaries={accountSummary}
        valueSeriesName="Account value"
        valueSeriesTooltipTitle="Net cumulative balance (deposits - withdrawals + earnings) starting with the account balance upon being onboarded to Vise."
      />
    </>
  );
}
