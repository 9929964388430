import { Box, Button, Typography, useTheme } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { sum } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAccountSize from '~/hooks/useAccountSize';
import { useCoachmarkEffect } from '~/hooks/useCoachmark';
import useViseManagedValue from '~/hooks/useViseManagedValue';
import Truncation from '~/routes/Portfolio/components/Truncation';
import { getCustodianCashNameFromTicker } from '~/routes/Portfolio/portfolioUtil';
import { ReactComponent as ExclamationCircleIcon } from '~/static/images/icons/exclamation-circle.svg';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import Banner from '~/synth/Banner';
import { LockedIcon } from '~/synth/Icons';
import Skeleton from '~/synth/Skeleton';
import amplitude from '~/utils/amplitude';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import usePositions from '../../../hooks/usePositions';
import { ACCOUNTING_FORMATTER, formatCurrency, formatPercent } from '../../../utils/format';
import { ASSET_TYPE_TO_LABEL } from '../Constants';
import ReturnToSummary from '../ReturnToSummary';
import { ScreenProps } from '../Types';
import { formatPce1CashConcentrationLimit, getSampleProposalAccountSize } from '../utils';
import { ActionFooter, BackButton, CardSection, ContentBox, ExplainerText } from './components';

// TODO: add learn more about vise-managed value
export const NotEnoughValueBanner = ({ children }) => {
  return (
    <Banner
      bgColor="error.100"
      borderColor="error.100"
      message={
        <Box>
          <Box display="flex" alignItems="center" color="#D91F11">
            <ExclamationCircleIcon width={18} height={18} />
            <Box ml={1.25}>
              <Typography variant="h4">There is not enough available value</Typography>
            </Box>
          </Box>
          <Box color="grey.600" mt={1} ml={3.5}>
            {children}
          </Box>
        </Box>
      }
    />
  );
};

export const LowValueBanner = ({ children }) => (
  <Banner
    bgColor="blue.200"
    borderColor="blue.200"
    message={
      <Box>
        <Box display="flex" alignItems="center">
          <InformationCircleIcon width={18} height={18} color="#1753DE" />
          <Box ml={1.25}>
            <Typography variant="h4">Low Vise-Managed Value</Typography>
          </Box>
        </Box>
        <Box color="grey.600" mt={1} ml={3.5}>
          {children}
        </Box>
      </Box>
    }
  />
);

interface PositionsTableData {
  lockable: boolean;
  symbolOrCusip: string;
  name?: string;
  assetType?: string;
  marketValue?: number;
  gainLoss?: number;
  allocation?: number;
  selected: boolean;
}

export default function LockedPositionsScreen({
  draftPortfolio,
  onBack,
  onContinue,
  dpDispatch,
}: ScreenProps) {
  useEffect(() => {
    amplitude().logEvent('Impression - Locked Positions', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);
  const theme = useTheme();

  const lockedPositionsSet = useMemo(
    () => new Set(draftPortfolio.lockedPositions),
    [draftPortfolio.lockedPositions]
  );

  const dataTableColumns: GridColDef<PositionsTableData>[] = useMemo(
    () => [
      {
        headerName: 'Ticker',
        valueGetter: ({ row }) => (row.symbolOrCusip === ':CASH' ? null : row.symbolOrCusip),
        field: 'ticker',
        maxWidth: 150,
        flex: 1,
      },
      {
        headerName: 'Name',
        renderCell: (props) => <Truncation>{props.value}</Truncation>,
        field: 'name',
        flex: 1,
      },
      {
        headerName: 'Asset type',
        renderCell: ({ value }) => <Truncation>{ASSET_TYPE_TO_LABEL[value]}</Truncation>,
        field: 'assetType',
        flex: 1,
      },
      {
        headerName: 'Value',
        renderCell: ({ value }) => <>{formatCurrency(value)}</>,
        field: 'marketValue',
        flex: 1,
      },
      {
        headerName: 'Gain/loss',
        renderCell: ({ value }) => <>{formatCurrency(value, ACCOUNTING_FORMATTER)}</>,
        field: 'gainLoss',
        flex: 1,
      },
      {
        headerName: 'Allocation',
        renderCell: (props) => {
          return (
            <Box display="flex">
              {formatPercent(props.value)}
              <Box ml={2}>
                {lockedPositionsSet.has(props.row.symbolOrCusip) && (
                  <LockedIcon size="small" top="2px" color={theme.palette.grey[800]} />
                )}
              </Box>
            </Box>
          );
        },
        field: 'allocation',
        flex: 1,
      },
    ],
    [lockedPositionsSet, theme.palette.grey]
  );

  const { bootstrappingState, constructionInfo, lockedPositions, accountSize } = useMemo(
    () => draftPortfolio,
    [draftPortfolio]
  );
  const { existingPortfolio, initialValue, concentrationLimits } = useMemo(
    () => constructionInfo,
    [constructionInfo]
  );

  const { data } = usePositions(
    existingPortfolio === 'sample-portfolio' ? null : existingPortfolio?.accountNumber,
    existingPortfolio === 'sample-portfolio' ? null : existingPortfolio?.custodianKey,
    true /* includePCE2Instruments */,
    true /* includeTaxLots */
  );

  const cashConcentrationLimit = useMemo(() => {
    return concentrationLimits?.find(
      (concentrationLimit) => concentrationLimit.asset_class === 'Cash'
    );
  }, [concentrationLimits]);

  const { data: accountSizeData, error: accountSizeError } = useAccountSize(
    existingPortfolio === 'sample-portfolio' ? undefined : existingPortfolio?.id,
    lockedPositions,
    cashConcentrationLimit
      ? (cashConcentrationLimit.max + cashConcentrationLimit.min) / 2
      : undefined
  );

  useCoachmarkEffect({
    show: accountSizeError != null,
    severity: 'error',
    title: 'There was an error fetching account size data.',
  });

  const { data: calculatedInitialValue } = useViseManagedValue(
    existingPortfolio === 'sample-portfolio' ? null : existingPortfolio?.accountNumber,
    existingPortfolio === 'sample-portfolio' ? null : existingPortfolio?.custodianKey,
    lockedPositions,
    0
  );

  useEffect(() => {
    if (calculatedInitialValue != null) {
      dpDispatch({ type: 'SET_INITIAL_VALUE', initialValue: calculatedInitialValue });
    }
  }, [calculatedInitialValue, dpDispatch]);

  const { data: featureFlags } = useFeatureFlags();

  const dataTableData: PositionsTableData[] | undefined = useMemo(() => {
    return data == null
      ? undefined
      : data.positions
          .map((position) => {
            const { symbolOrCusip, name: positionName, assetType, marketValue, taxLots } = position;
            const custodian =
              existingPortfolio === 'sample-portfolio'
                ? undefined
                : existingPortfolio?.custodianKey;

            let name = positionName;
            if (getCustodianCashNameFromTicker(symbolOrCusip, custodian)) {
              // Override the name with a specific name for cash depending on the custodian

              name = getCustodianCashNameFromTicker(position.symbolOrCusip, custodian);
            }

            const totalCostBasis =
              taxLots != null && sum(taxLots.map((taxLot) => taxLot.costBasis));
            const gainLoss = totalCostBasis !== false ? marketValue - totalCostBasis : undefined;

            const allocation =
              calculatedInitialValue != null
                ? position.marketValue / calculatedInitialValue
                : undefined;

            return {
              lockable: position.symbolOrCusip !== ':CASH',
              symbolOrCusip,
              name,
              assetType,
              marketValue,
              gainLoss,
              allocation,
              selected: lockedPositionsSet.has(position.symbolOrCusip),
            };
          })
          .sort((a) => (a.lockable ? -1 : 1));
  }, [data, existingPortfolio, calculatedInitialValue, lockedPositionsSet]);

  useEffect(() => {
    const sampleProposalAccountSize = getSampleProposalAccountSize(
      initialValue,
      parseFloat(formatPce1CashConcentrationLimit(cashConcentrationLimit))
    );
    const accountSizeToSet =
      existingPortfolio === 'sample-portfolio' ? sampleProposalAccountSize : accountSizeData;
    // Deprecate creating new small account proposals and instead use large account inputs and trust
    // PCE2 to handle it. However if they have a tiny account then disable continuing (we would error in PCE)
    if (featureFlags?.disable_small_accounts_ui === 'on' && accountSizeToSet !== 'TINY') {
      dpDispatch({ type: 'SET_ACCOUNT_SIZE', accountSize: 'FULL' });
      return;
    }
    if (accountSizeToSet != null) {
      dpDispatch({ type: 'SET_ACCOUNT_SIZE', accountSize: accountSizeToSet });
      if (accountSizeToSet === 'SMALL') {
        dpDispatch({ type: 'CLEAR_ALL_RESTRICTIONS' });
        dpDispatch({ type: 'PCE2_UNSET_ACTIVE_TILT' });
      }
    }
  }, [
    accountSizeData,
    cashConcentrationLimit,
    dpDispatch,
    existingPortfolio,
    initialValue,
    featureFlags?.disable_small_accounts_ui,
  ]);

  const nextButtonDisabled =
    bootstrappingState === 'LOADING' ||
    bootstrappingState === 'ERROR' ||
    accountSizeData == null ||
    accountSizeError != null ||
    accountSizeData === 'TINY';

  return (
    <ContentBox maxWidth="821px">
      <Box mb={2}>
        <Typography variant="h1">Do you want to lock positions?</Typography>
      </Box>
      <ExplainerText mb={4}>
        Locked positions will be excluded from Vise&apos;s portfolio optimization &mdash; we&apos;ll
        avoid buying or selling these holdings.
      </ExplainerText>
      {bootstrappingState === 'LOADING' || bootstrappingState === 'ERROR' ? (
        <Skeleton height="100px" />
      ) : (
        <>
          {/* `overflow:hidden` ensures background colors are clipped by border-radius */}
          {dataTableData ? (
            <Box height="400px">
              <DataGridPro
                columns={dataTableColumns}
                rows={dataTableData}
                checkboxSelection
                getRowId={(row) => row.symbolOrCusip}
                onRowSelectionModelChange={(selectionModel) => {
                  dpDispatch({
                    type: 'SET_LOCKED_POSITIONS',
                    lockedPositions: selectionModel as string[],
                  });
                }}
                rowSelectionModel={draftPortfolio.lockedPositions}
                slots={{
                  toolbar: () => (
                    <Box my={1} mx={2}>
                      <GridToolbarQuickFilter />
                    </Box>
                  ),
                }}
                isRowSelectable={(params) => params.row.lockable}
              />
              <CardSection
                bgcolor="grey.100"
                boxShadow={5}
                display="flex"
                justifyContent="space-between"
              >
                <Box>
                  <strong>Total portfolio value</strong>
                </Box>
                <Box>
                  {initialValue === 0 ? (
                    <strong>Loading…</strong>
                  ) : (
                    <strong>{formatCurrency(initialValue)}</strong>
                  )}
                </Box>
              </CardSection>
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
      {accountSize === 'TINY' && (
        <Box mt={8}>
          <NotEnoughValueBanner>
            By locking unrecognized positions, the Vise-managed value has dropped below $500.00.
            Unlock unrecognized positions in or reduce the cash allocation in order to continue.
          </NotEnoughValueBanner>
        </Box>
      )}
      {accountSize === 'SMALL' && (
        <Box mt={8}>
          <LowValueBanner>
            A combination of the locked unrecognized positions and target cash allocation has
            lowered the total available value that Vise is able to manage below $5,000.00. When this
            happens, Vise adjusts the types of portfolio inputs and asset classes available in order
            to optimize for smaller account sizes.
          </LowValueBanner>
        </Box>
      )}
      <ActionFooter justifyContent="space-between">
        <BackButton
          disabled={bootstrappingState === 'LOADING' || bootstrappingState === 'ERROR'}
          onClick={() => onBack()}
        />
        <Box display="flex">
          <ReturnToSummary
            draftPortfolio={draftPortfolio}
            disabled={nextButtonDisabled}
            onReturnToSummary={() => onContinue('summary')}
            mr={1.5}
          />
          <Button
            color="primary"
            disabled={nextButtonDisabled}
            onClick={() => {
              amplitude().logEvent('Continue to cash allocation from position lock', {
                category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
              });
              onContinue();
            }}
            type="submit"
            variant="contained"
          >
            Continue
          </Button>
        </Box>
      </ActionFooter>
    </ContentBox>
  );
}
