import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import amplitude from '~/utils/amplitude';
import useAllAllocationsTemplates from '~/hooks/templates/useAllAllocationsTemplates';
import AllocationIcon from '../../icons/AllocationIcon';
import UseTemplateIcon from '../../icons/UseTemplateIcon';
import { ScreenProps } from '../../Types';
import {
  ActionFooter,
  BackButton,
  Card,
  CardSection,
  CardTextTitleAndDescription,
  ContentBox,
} from '../components';
import ReturnToSummary from '../../ReturnToSummary';

export default function BuildOrUseAllocationTemplateScreen({
  draftPortfolio,
  dpDispatch,
  onBack,
  onContinue,
}: ScreenProps) {
  const { useAllocationTemplate } = draftPortfolio;
  // TODO(rteammco): We may need to consider isBootstrappedStrategyOutdated as well, and handle
  // the UX (enqueueCoachmark) when a change in allocation selection would undo previous inputs.
  // See BuildOrUseStrategyScreen.tsx as example.

  useEffect(() => {
    amplitude().logEvent('Impression - Build allocation or use allocation template', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);

  const { data: allAllocationsTemplates } = useAllAllocationsTemplates();

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    amplitude().logEvent(
      `${
        useAllocationTemplate
          ? 'Continue to select an allocation template'
          : 'Continue to build custom allocation'
      }`,
      { category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION }
    );

    if (useAllocationTemplate) {
      onContinue('selectTemplate');
    } else {
      onContinue('default');
    }
  }

  function setUseAllocationTemplate(useAllocationTemplate: boolean | null) {
    dpDispatch({ type: 'SET_USE_SAVED_ALLOCATION_TEMPLATE', useAllocationTemplate });
  }

  const disableUseAllocationTemplateOption =
    allAllocationsTemplates == null || allAllocationsTemplates.length === 0;

  const isInputValid = useAllocationTemplate != null;

  return (
    <ContentBox>
      <Box mb={5}>
        <Typography variant="h1">Now, let&apos;s design your client&apos;s allocation.</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Card
          active={useAllocationTemplate === true}
          disabled={disableUseAllocationTemplateOption}
          data-cy="use-an-allocation-template-card"
          mb={2}
          onClick={() => {
            if (useAllocationTemplate !== true && !disableUseAllocationTemplateOption) {
              amplitude().logEvent('Select use allocation template', {
                category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
              });
              setUseAllocationTemplate(true);
            }
          }}
          selectable
        >
          <CardSection>
            <Box display="flex">
              <Box mr={2}>
                <UseTemplateIcon active={useAllocationTemplate === true} />
              </Box>
              <CardTextTitleAndDescription title="Use an allocation template">
                Skip the legwork of manually adjusting asset classes and risk tolerance by using a
                saved template.
              </CardTextTitleAndDescription>
            </Box>
          </CardSection>
        </Card>
        <Card
          mb={2}
          data-cy="build-a-new-custom-allocation-card"
          active={useAllocationTemplate === false}
          onClick={() => {
            amplitude().logEvent('Select build new allocation', {
              category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
            });
            if (useAllocationTemplate !== false) {
              setUseAllocationTemplate(false);
            }
            dpDispatch({ type: 'REMOVE_ALLOCATIONS_TEMPLATE' });
          }}
          selectable
        >
          <CardSection>
            <Box display="flex">
              <Box mr={2}>
                <AllocationIcon active={useAllocationTemplate === false} />
              </Box>
              <CardTextTitleAndDescription title="Build a new allocation">
                Go custom and build your client&apos;s portfolio around their asset classes and risk
                tolerance. You can save your strategy as a template to apply it to other portfolios
                later.
              </CardTextTitleAndDescription>
            </Box>
          </CardSection>
        </Card>
        <ActionFooter justifyContent="space-between">
          <BackButton onClick={() => onBack()} />
          <Box display="flex">
            <ReturnToSummary
              disabled={false}
              draftPortfolio={draftPortfolio}
              onReturnToSummary={() => onContinue('summary')}
              mr={1.5}
            />
            <Button color="primary" disabled={!isInputValid} type="submit" variant="contained">
              Continue
            </Button>
          </Box>
        </ActionFooter>
      </form>
    </ContentBox>
  );
}
