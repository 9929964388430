import { Box, Collapse, CollapseProps, Grid, Typography } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import { format } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useMemo, useState } from 'react';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import useInstrumentBenchmarks from '~/hooks/useInstrumentBenchmarks';
import { PERFORMANCE_EVENTS } from '~/routes/PortfolioCreator2/Constants';
import { ReactComponent as BearIcon } from '~/static/images/icons/bear.svg';
import { ReactComponent as BullIcon } from '~/static/images/icons/bull.svg';
import EventDefault from '~/static/images/icons/event-default.svg';
import EventHover from '~/static/images/icons/event-hover.svg';
import EventSelected from '~/static/images/icons/event-selected.svg';
import { ReactComponent as TrendingDown } from '~/static/images/icons/trending-down.svg';
import { ReactComponent as TrendingUp } from '~/static/images/icons/trending-up.svg';
import Skeleton from '~/synth/Skeleton';
import { formatPercent } from '~/utils/format';
import { calculateChartMetric, transformPCE2BacktestResults } from '../portfolioUtil';
import { SectionSubtitle, SectionTitle } from './components/UtilComponents';

type ProcessedPerformanceEvent = {
  visePortfolioPerformance: number;
  marketPerformance: number;
  periodName: string;
  startPeriod: Date;
  endPeriod: Date;
  description: string;
  type: 'bear' | 'bull';
};

function HypotheticalReturn({ intelligence }: { intelligence: PortfolioIntelligenceFull }) {
  if (intelligence.proposalType === 'light') {
    throw new Error(`Portfolio intelligence should be PCE2 and FULL. (id: ${intelligence.id})`);
  }

  const {
    constructionRequest: { assetClassAllocationRequest },
    constructionResponse,
  } = intelligence;

  const usingGlidePath = assetClassAllocationRequest?.glidePathParams != null;
  let initialRisk = usingGlidePath
    ? assetClassAllocationRequest?.glidePathParams?.initialRisk
    : assetClassAllocationRequest?.risk;
  if (
    assetClassAllocationRequest?.customAllocationParams ||
    assetClassAllocationRequest?.glidePathParams?.initialL1Allocations.length
  ) {
    initialRisk = constructionResponse.risk;
  }

  const { data: instrumentBenchmarks } = useInstrumentBenchmarks({
    fetchDailyData: true,
    risk: initialRisk,
  });

  const normalizedBacktest = useMemo(
    () =>
      intelligence.backtestResults
        ? transformPCE2BacktestResults(intelligence.backtestResults)
        : [],

    [intelligence.backtestResults]
  );

  const [selectedEvent, setSelectedEvent] = useState<ProcessedPerformanceEvent | undefined>();

  const performanceEvents = useMemo(() => {
    const value =
      instrumentBenchmarks?.[0].dailyDataHC && normalizedBacktest && normalizedBacktest.length > 0 // I.E. we actually finished solving the backtest, the array will have 0 length otherwise
        ? PERFORMANCE_EVENTS.map((e) => ({
            ...e,
            visePortfolioPerformance: calculateChartMetric(
              e.startPeriod.valueOf(),
              e.endPeriod.valueOf(),
              normalizedBacktest
            ),
            marketPerformance: calculateChartMetric(
              e.startPeriod.valueOf(),
              e.endPeriod.valueOf(),
              instrumentBenchmarks[0].dailyDataHC
            ),
          }))
        : null;
    setSelectedEvent(value?.[0]);
    return value;
  }, [instrumentBenchmarks, normalizedBacktest]);

  const chartOptions: (event: ProcessedPerformanceEvent) => Highcharts.Options = ({
    periodName,
    visePortfolioPerformance,
    marketPerformance,
  }) =>
    ({
      chart: {
        type: 'column',
        height: '100%',
        backgroundColor: undefined,
      },
      title: { text: '' },
      xAxis: {
        categories: [periodName],
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        allowDecimals: false,
        plotLines: [{ value: 0, color: tokens.palette.neutralCool[800], width: 2 }],
        title: {
          text: '',
        },
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: '%',
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: 'Vise',
          data: [visePortfolioPerformance * 100],
          color: tokens.palette.primary.main,
        },
        {
          type: 'column',
          name: 'Market',
          data: [marketPerformance * 100],
          color: tokens.palette.neutralCool[800],
        },
      ],
    } as Highcharts.Options);

  const lineChartOptions: Highcharts.Options = {
    chart: {
      type: 'line',
      height: '58%',
      style: { fontFamily: '"Helvetica Neue", Arial, "Noto Sans", sans-serif' },
    },
    credits: { enabled: false },
    title: { text: '' },
    legend: {
      align: 'left',
      verticalAlign: 'top',
      itemStyle: { fontWeight: '400', fontSize: '14px' },
    },
    xAxis: {
      tickInterval: 31556926000,
      labels: {
        format: '{value:%Y}',
      },
      title: { text: 'Year', style: { fontWeight: '500' } },
      type: 'datetime',
      plotBands: PERFORMANCE_EVENTS.map((e) => ({
        color:
          selectedEvent?.periodName === e.periodName ? 'rgba(23, 83, 222, 0.1)' : 'transparent',
        from: e.startPeriod.valueOf(),
        to: e.endPeriod.valueOf(),
        cursor: 'pointer',
        events: {
          click: () =>
            setSelectedEvent(performanceEvents?.find((k) => k.periodName === e.periodName)),
          mouseover: function mouseover() {
            if (selectedEvent?.periodName !== e.periodName) {
              // @ts-ignore
              // eslint-disable-next-line react/no-this-in-sfc
              this.svgElem.attr('fill', 'rgba(23, 83, 222, 0.05)');
              // @ts-ignore
              // eslint-disable-next-line react/no-this-in-sfc
              this.svgElem.htmlCss({ cursor: 'pointer' });
            }
          },
          mouseout: function mouseout() {
            if (selectedEvent?.periodName !== e.periodName) {
              // @ts-ignore
              // eslint-disable-next-line react/no-this-in-sfc
              this.svgElem.attr('fill', 'transparent');
            }
          },
        },
      })),
    },
    yAxis: {
      title: { text: 'Return over time', style: { fontWeight: '500' } },
      labels: { format: '{value}%' },
      min: Math.min(...normalizedBacktest.map((n) => n[1] - 100)) - 30,
    },

    tooltip: {
      valueDecimals: 2,
      valueSuffix: '%',
      xDateFormat: '%B %d %Y',
    },
    series: [
      {
        type: 'line',
        name: 'Vise',
        data: normalizedBacktest.map((n) => [n[0], n[1] - 100]),
        color: tokens.palette.primary.main,
        marker: { symbol: 'circle' },
      },
      {
        type: 'line',
        name: 'Benchmark',
        data: instrumentBenchmarks?.[0].dailyDataHC
          // Ensure the graph isn't longer for the benchmark vs. the Vise backtest
          //
          // Backtests are only every month vs. benchmarks could have more data than that
          .filter((b) => b[0] <= normalizedBacktest[normalizedBacktest.length - 1][0])
          .map((b) => [b[0], b[1] - 100]),
        color: tokens.palette.neutralCool[800],
        marker: { symbol: 'circle' },
      },
      {
        cursor: 'pointer',
        type: 'scatter',
        allowPointSelect: true,
        showInLegend: false,
        marker: {
          symbol: `url(${EventDefault})`,
          states: { select: { lineColor: 'red', lineWidth: 2 } },
        },
        tooltip: {
          headerFormat: '',
          pointFormatter: function format() {
            // @ts-ignore
            // eslint-disable-next-line react/no-this-in-sfc
            return `${this.event.periodName}`;
          },
        },
        data: performanceEvents?.map((p) => ({
          y: Math.min(...normalizedBacktest.map((n) => n[1] - 100)) - 30,
          x: (p.endPeriod.getTime() + p.startPeriod.getTime()) / 2,
          event: p,
          selected: selectedEvent?.periodName === p.periodName,
          events: {
            select: function select() {
              // eslint-disable-next-line react/no-this-in-sfc
              this.update({ marker: { symbol: `url(${EventSelected})` } });
              setSelectedEvent(performanceEvents?.find((k) => k.periodName === p.periodName));
            },
            unselect: function unselect() {
              // eslint-disable-next-line react/no-this-in-sfc
              this.update({ marker: { symbol: `url(${EventDefault})` } });
            },
            mouseOver: function mouseOver() {
              // @ts-ignore
              // eslint-disable-next-line react/no-this-in-sfc
              this.update({ marker: { symbol: `url(${EventHover})` } });
            },
            mouseOut: function mouseOut() {
              // eslint-disable-next-line react/no-this-in-sfc
              if (!this.selected) {
                // @ts-ignore
                // eslint-disable-next-line react/no-this-in-sfc
                this.update({ marker: { symbol: `url(${EventDefault})` } });
              }
            },
          },
        })),
      },
    ],
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={9}>
            <SectionSubtitle title="Hypothetical return over time" id="hypothetical" />
            <HighchartsReact highcharts={Highcharts} options={lineChartOptions} />
          </Grid>
          <Grid item xs={3}>
            {selectedEvent && (
              <Box
                borderRadius={1}
                border={1}
                borderColor={tokens.palette.neutralCool[300]}
                overflow="hidden"
                height="100%"
              >
                <Box
                  py={1}
                  px={2}
                  borderBottom={1}
                  borderColor={tokens.palette.neutralCool[300]}
                  sx={{
                    background: 'linear-gradient(92deg, #E3EBFC 22.57%, #EDE0FF 89.12%)',
                  }}
                >
                  <Box
                    fontWeight={700}
                    lineHeight={1.25}
                    color="black"
                    mb={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {selectedEvent.periodName}
                    <Box display="flex" alignItems="center" ml={1}>
                      {selectedEvent.type === 'bull' ? (
                        <TrendingUp width={16} height={16} />
                      ) : (
                        <TrendingDown width={16} height={16} />
                      )}
                      <Box ml={0.5}>
                        {selectedEvent.type === 'bull' ? (
                          <BullIcon width={24} height={24} />
                        ) : (
                          <BearIcon width={24} height={24} />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex">
                    {format(selectedEvent.startPeriod, "MMM ''yy")}
                    <Box mx={0.5}>-</Box>
                    {format(selectedEvent.endPeriod, "MMM ''yy")}
                  </Box>
                </Box>
                <Box px={2} py={3} bgcolor="grey.100" height="100%">
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h3" color="primary.main">
                      {formatPercent(selectedEvent.visePortfolioPerformance)}
                    </Typography>
                    vs
                    <Typography variant="h3" color="black">
                      {formatPercent(selectedEvent.marketPerformance)}
                    </Typography>
                  </Box>
                  <HighchartsReact highcharts={Highcharts} options={chartOptions(selectedEvent)} />
                  <Box color="grey.600" mt={1}>
                    {selectedEvent.description}
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <Typography mt={3} color="grey.600" fontSize={12}>
          *The above analysis compares the Vise Target Portfolio versus a Morningstar Custom
          Weighted{' '}
          {initialRisk != null
            ? `(${(initialRisk * 100).toFixed(0)}/${((1 - initialRisk) * 100).toFixed(0)})`
            : ''}{' '}
          Benchmark. The benchmark is a custom blend of the Morningstar Global Markets Index &
          Morningstar US Core Bond Index based on your target allocations.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function HistoricPerformance({
  intelligence,
  collapseProps,
  onClick,
}: {
  intelligence: PortfolioIntelligenceFull;
  collapseProps?: CollapseProps;
  onClick: () => void;
}) {
  return (
    <Box mt={3} mb={5}>
      <SectionTitle
        title="Past Performance"
        subtext="See how your current portfolio would have performed against a Vise managed portfolio during major market events in the past."
        collapseProps={collapseProps}
        onClick={onClick}
        id="hypothetical"
      />

      <Collapse {...collapseProps}>
        {intelligence.status === 'BACKTEST_SOLVING' ? (
          <Skeleton width="100%" height="20em" variant="rect" />
        ) : (
          <HypotheticalReturn intelligence={intelligence} />
        )}
      </Collapse>
    </Box>
  );
}
