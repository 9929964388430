import { Box, DialogProps, ThemeProvider, Typography } from '@mui/material';
import { Dialog, theme as dsTheme } from '@vise_inc/ds-vise';
import React from 'react';
import { PortfolioIntelligenceCommon } from 'vise-types/pce1';
import usePCE2UnacceptProposal from '~/hooks/usePCE2UnacceptProposal';

type Props = {
  onUnaccept: () => void;
  onClose: () => void;
  proposal: Pick<PortfolioIntelligenceCommon, 'accountId' | 'clientId' | 'id'>;
  accountId: string;
} & DialogProps;

const ProposalUnacceptConfirmationModal = (props: Props) => {
  const { onClose, onUnaccept, proposal, accountId, ...rest } = props;
  const { unacceptProposal, unacceptPending } = usePCE2UnacceptProposal();

  const handleUnaccept = async () => {
    await unacceptProposal(proposal, accountId);
    onUnaccept();
  };

  return (
    <ThemeProvider theme={dsTheme}>
      <Dialog
        variant="destructive"
        onClose={onClose}
        {...rest}
        title="Unaccept Proposal"
        confirmation="Unaccept proposal"
        disabled={unacceptPending}
        onConfirm={handleUnaccept}
        size="large"
      >
        <Typography variant="h4" color="neutralCool.1000">
          <Box>Are you sure you want to unaccept this proposal?</Box>
        </Typography>
        <Box py={3}>
          <Typography variant="body1" color="neutralCool.600">
            This will undo any changes you made to the portfolio. The most recently executed/active
            (and not unaccepted) proposal for this account will now be the active proposal.{' '}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="neutralCool.600">
            You can find the unaccepted proposal in the bottom of Manage Households page. If there
            are no active proposals for the account, you can find the unaccepted proposals in
            Unexecuted Portfolios page.
          </Typography>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default ProposalUnacceptConfirmationModal;
