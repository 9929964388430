import React from 'react';
import { Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export interface MetricProps {
  label?: React.ReactNode;
  metric: React.ReactNode;
  subtext?: string;
  size?: 'small' | 'medium' | 'large';
  subtextColor?: 'primary' | 'default';
}

const useLabelBoxStyles = makeStyles()((theme) => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  root: theme.typography.h5 as any,
}));

const Metric = ({
  label,
  metric,
  subtext,
  size = 'medium',
  subtextColor = 'default',
}: MetricProps) => {
  let metricTextVariant: 'body2' | 'h3' | 'h2';
  if (size === 'small') {
    metricTextVariant = 'body2';
  } else if (size === 'medium') {
    metricTextVariant = 'h3';
  } else {
    metricTextVariant = 'h2';
  }

  let subtextPt: 0.5 | 1 | 1.5;
  if (size === 'small') {
    subtextPt = 0.5;
  } else if (size === 'medium') {
    subtextPt = 1;
  } else {
    subtextPt = 1.5;
  }

  const { classes: labelBoxStyles } = useLabelBoxStyles();

  return (
    <div>
      {label && (
        <Box
          display="flex"
          pb={size === 'small' ? 0.5 : 1}
          color="grey.500"
          className={labelBoxStyles.root}
        >
          {label}
        </Box>
      )}
      <Typography variant={metricTextVariant} component="span">
        {metric}
      </Typography>
      {subtext && (
        <Box
          display="flex"
          pt={subtextPt}
          color={subtextColor === 'default' ? 'grey.500' : 'primary.main'}
        >
          <Typography variant={size === 'large' ? 'body1' : 'caption'}>{subtext}</Typography>
        </Box>
      )}
    </div>
  );
};

export default Metric;
