import { Box, ThemeProvider, Typography } from '@mui/material';
import { theme, tokens } from '@vise_inc/ds-vise';
import React from 'react';
import { Link } from 'react-router-dom';

export default function TopLevelMetricsCard({
  title,
  header,
  description,
  backgroundColor,
  borderColor,
  icon,
  to,
}: {
  title: React.ReactNode;
  header: React.ReactNode;
  description?: React.ReactNode;
  backgroundColor: string;
  borderColor: string;
  icon: React.ReactNode;
  to: string;
}) {
  return (
    <ThemeProvider theme={theme}>
      <Link to={to} style={{ textDecoration: 'none' }}>
        <Box
          boxShadow={tokens.shadow.base}
          borderRadius="4px"
          sx={{ borderColor, borderStyle: 'solid', borderWidth: '1px' }}
          padding={2}
          bgcolor={backgroundColor}
          height="100%"
        >
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <Typography variant="h5" color="neutralCool.600">
                {title}
              </Typography>
            </Box>
            {icon}
          </Box>
          <Box my={2}>
            <Typography variant="h3" component="div">
              {header}
            </Typography>
          </Box>
          <Box mb={description ? 4 : 7}>
            <Typography variant="body2" color="neutralCool.600">
              {description}
            </Typography>
          </Box>
        </Box>
      </Link>
    </ThemeProvider>
  );
}
