import useSWR from 'swr';
import { getTransitionAnalysis } from '~/api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useTransitionAnalysis(
  accountId: string | null | undefined,
  proposalId: string | null | undefined
) {
  return useSWR(
    accountId == null || proposalId == null
      ? null
      : generateSwrKey(`/xray/transition-analysis/${accountId}/${proposalId}`),
    accountId == null || proposalId == null
      ? undefined
      : () => getTransitionAnalysis(accountId, proposalId)
  );
}
