import {
  Box,
  Button,
  Collapse,
  CollapseProps,
  Divider,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import ViseLogo from '~/static/images/vise-logo-black.svg';
// eslint-disable-next-line no-restricted-imports
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { tokens } from '@vise_inc/ds-vise';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { keyBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { AssetClassKey, Feature, Sector } from 'vise-types/pce2_instrument';
import { Account, PortfolioPositionResponse } from 'vise-types/portfolio';
import { PortfolioMetrics, SectorAllocation } from 'vise-types/portfolio_metrics';
import {
  BenchmarkDataResponse,
  CalculateFeesResponse,
  CalculateFixedIncomeMetricsResponse,
  FixedIncomeMetrics,
  FundOverlapResponse,
  PortfolioFees,
  SectorBenchmarkData,
} from 'vise-types/xray';
import { getXrayBenchmarks } from '~/api/api';
import ChartColorSquare from '~/components/chart/ChartColorSquare';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useIsEtfOnlyProposal from '~/hooks/useIsEtfOnlyProposal';
import usePositions from '~/hooks/usePositions';
import { Allocation, AssetClassAllocation as PCE2AssetClassAllocation } from '~/models/api';
import { ReactComponent as ArrowDownIcon } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ArrowUpIcon } from '~/static/images/icons/chevron-up.svg';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import Metric from '~/synth/Metric';
import PopoverCard from '~/synth/PopoverCard';
import PopoverLink from '~/synth/PopoverLink';
import PopoverTrigger from '~/synth/PopoverTrigger';
import Skeleton from '~/synth/Skeleton';
import { WHOLE_DOLLAR_FORMATTER, formatCurrency, formatPercent } from '~/utils/format';
import {
  ASSET_CLASS_TO_LABEL_MAP,
  FIXED_INCOME_ASSET_CLASS_KEY,
} from '../../PortfolioCreator2/Constants';
import { SECTOR_TO_LABEL_MAP } from '../Constants';
import { AssetAllocationChart } from '../card/AllocationCard2';
import { transformPCE2AssetClassAllocations } from '../portfolioUtil';
import AssetAllocationBreakdown from './components/AssetAllocationBreakdown';
import {
  SectionSubtitle,
  SectionTitle,
  StyledList,
  StyledListItem,
} from './components/UtilComponents';

const EQUITY_COPY_REASON = {
  TECHNOLOGY: 'Late-cycle and recessionary periods',
  HEALTHCARE: 'Early-cycle growth periods',
  FINANCIAL_SERVICES: 'Late-cycle and recessionary periods',
  CONSUMER_CYCLICAL: 'Late-cycle and recessionary periods',
  INDUSTRIALS: 'Late-cycle and recessionary periods',
  COMMUNICATION_SERVICES: 'Recessionary periods',
  CONSUMER_DEFENSIVE: 'Early-cycle growth periods',
  ENERGY: 'Early-cycle growth periods',
  REAL_ESTATE: 'Late-cycle and recessionary periods',
  BASIC_MATERIALS: 'Mid-cycle expansion periods',
  UTILITIES: 'Early-cycle growth periods',
};

const EQUITY_COPY_DESCRIPTION_ABOVE_BENCHMARK = {
  TECHNOLOGY: 'profit margins decline and multiples for economically sensitive sectors decline.',
  HEALTHCARE: 'investors move from defensive sectors to more cyclical, growth-oriented sectors.',
  FINANCIAL_SERVICES: 'the business cycle weakens.',
  CONSUMER_CYCLICAL: 'discretionary spending slows down and demand declines.',
  INDUSTRIALS: 'investors move away from economically sensitive sectors.',
  COMMUNICATION_SERVICES: 'spending on advertising and media declines.',
  CONSUMER_DEFENSIVE:
    'investors move from defensive sectors to more cyclical, growth-oriented sectors.',
  ENERGY: 'investors prefer more growth-oriented sectors.',
  REAL_ESTATE: 'discretionary spending on real estate declines during these periods.',
  BASIC_MATERIALS: 'investors prefer more growth-oriented sectors and less defensive exposure.',
  UTILITIES: 'investors move from defensive sectors to more cyclical, growth-oriented sectors.',
};

const EQUITY_COPY_DESCRIPTION_BELOW_BENCHMARK = {
  TECHNOLOGY:
    'expansionary periods when spending on technology drives up profitability for the sector, or during risk-on environments when investors are willing to pay higher multiples associated with the tech sector.',
  HEALTHCARE:
    'economic slowdowns, when health care outperforms due to its defensive and stable revenue and profitability profile.',
  FINANCIAL_SERVICES:
    'periods of expansion when credit demand is high, or higher rate growth periods when interest margins are wide.',
  CONSUMER_CYCLICAL: 'periods of consumer strength, when spending is high.',
  INDUSTRIALS: 'periods of expansion, when capex spending is high.',
  COMMUNICATION_SERVICES: 'periods of economic expansion, when demand for advertising is high.',
  CONSUMER_DEFENSIVE:
    'recessions when defensive sectors with stable earnings outperform more volatile growth names.',
  ENERGY:
    'inflationary periods, when oil and gas prices are rising or when geopolitical tensions increase.',
  REAL_ESTATE:
    'times of economic expansion or when interest rates are low, as low interest rates can drive up demand for real estate.',
  BASIC_MATERIALS: 'periods of expansion, when infrastructure spending is high.',
  UTILITIES:
    'recessions when defensive sectors with stable earnings outperform more volatile growth names.',
};

const FIXED_INCOME_COPY_ABOVE_THRESHOLD = {
  'FIXED_INCOME/DOMESTIC/TREASURY':
    'during loosening business conditions and risk-on periods as credit spreads contract and corporate fixed income outperforms, and investors take on more risk.',
  'FIXED_INCOME/DOMESTIC/SECURITIZED':
    'when consumer income and savings comes under pressure as many securitized products (mortgages, asset-backed) are backed by loans to consumers.',
  'FIXED_INCOME/DOMESTIC/CORPORATE':
    'tightening business conditions and slowdowns as credit spreads expand amidst growing concerns about defaults.',
  'FIXED_INCOME/DOMESTIC/HIGHYIELD':
    'tightening business conditions and slowdowns as credit spreads expand amidst growing concerns about defaults.',
  'FIXED_INCOME/DOMESTIC/TIPS':
    'periods when inflation is lower than expected as as inflation expectations are already priced into TIPs.',
  'FIXED_INCOME/DOMESTIC/MUNICIPAL': 'tightening fiscal conditions for local authorities.',
  'FIXED_INCOME/INTERNATIONAL':
    'risk-off periods as as investors move towards safer US Treasuries.',
  'FIXED_INCOME/EMERGING_FI':
    'periods of global slowdown, dollar strength or international instability.',
};

const FIXED_INCOME_COPY_BELOW_THRESHOLD = {
  'FIXED_INCOME/DOMESTIC/TREASURY':
    'an economic slowdown or recession, when there is a flight to safety by investors.',
  'FIXED_INCOME/DOMESTIC/SECURITIZED':
    'periods with a strong housing market and low interest rates, when consumer credit is strong.',
  'FIXED_INCOME/DOMESTIC/CORPORATE':
    'low default rates, economic expansion, falling interest rates.',
  'FIXED_INCOME/DOMESTIC/HIGHYIELD':
    'strong economic growth periods, when credit conditions are improving for corporates.',
  'FIXED_INCOME/DOMESTIC/TIPS': 'periods of unexpectedly high inflation.',
  'FIXED_INCOME/DOMESTIC/MUNICIPAL':
    'loosening fiscal conditions or periods of favorable tax treatment.',
  'FIXED_INCOME/INTERNATIONAL': 'periods of US-specific volatility.',
  'FIXED_INCOME/EMERGING_FI': 'risk-on periods when investors move away from safer Treasuries.',
};

function AllocationCharts({ current, target }: { current: Allocation; target: Allocation }) {
  return (
    <Box mb={5}>
      <SectionSubtitle title="Asset Class Breakdown" id="current-vs-vise" mb={4} />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <AssetAllocationChart assetAllocation={current} chartTitle="Current Portfolio" />
        </Grid>
        <Grid item xs={6}>
          <AssetAllocationChart
            assetAllocation={target}
            chartTitle={
              <Box display="flex">
                <img alt="V" src={ViseLogo} height="21" width="17" style={{ marginRight: 8 }} />
                Vise Portfolio
              </Box>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function normalizeFixedIncomeAllocation(allocation: Allocation) {
  const fixedIncomeAssetClasses = allocation.filter((a) => a.name.split('/')[0] === 'FIXED_INCOME');
  const fiAllocation = fixedIncomeAssetClasses.map((f) => f.y).reduce((a, b) => a + b, 0);
  return allocation.map((a) => ({ name: a.name, y: a.y / fiAllocation }));
}

const createOptions: (
  data: NonNullable<Highcharts.SeriesBarOptions['data']>,
  benchmarK: NonNullable<Highcharts.SeriesBarOptions['data']>,
  categories: string[],
  color: string,
  max?: number
) => Highcharts.Options = (data, benchmarK, categories, color, max) =>
  ({
    chart: {
      style: { fontFamily: '"Helvetica Neue", Arial, "Noto Sans", sans-serif' },
      legend: undefined,
    },
    credits: {
      enabled: false,
    },
    title: undefined,
    tooltip: {
      shared: true,
      pointFormat:
        '<span style="color:{series.color}" >◆</span> {series.name}: <b>{point.y}</b><br/>',
      valueDecimals: 2,
      valueSuffix: '%',
    },
    yAxis: {
      min: 0,
      max,
      gridLineWidth: 0,
      lineWidth: 1,
      labels: {
        formatter: function format() {
          // eslint-disable-next-line react/no-this-in-sfc
          return `${this.value}%`;
        },
      },
      title: { text: null },
    },
    xAxis: {
      lineWidth: 0,
      categories,
      labels: {
        align: 'left',
        reserveSpace: true,
      },
    },
    plotOptions: {
      bar: { grouping: false },
      series: {
        stacking: 'normal',
      },
    },
    series: [
      {
        type: 'bar',
        pointWidth: 16,
        pointPadding: 0,
        data: data.map((p) => (max ?? 1) - (p as number)),
        showInLegend: false,
        animation: false,
        color: tokens.palette.neutralCool[200],
        enableMouseTracking: false,
        dataLabels: {
          enabled: false,
        },
      },
      {
        name: 'Portfolio Weight',
        showInLegend: false,
        type: 'bar',
        pointWidth: 16,
        pointPadding: 0,
        data,
        color,
      },
      {
        name: 'Benchmark Weight',
        showInLegend: false,
        lineWidth: 0,
        states: { hover: { enabled: false } },
        type: 'line',
        data: benchmarK,
        marker: {
          symbol: 'diamond',
        },
        color: tokens.palette.neutralCool[800],
        dataLabels: {
          enabled: false,
        },
      },
    ],
  } as Highcharts.Options);

interface DescriptionItem {
  key?: Sector | AssetClassKey;
  message?: React.ReactNode;
  value?: number;
}

export function EquitySectorAnalysis({
  currentSectors,
  targetSectors,
}: {
  currentSectors: SectorAllocation;
  targetSectors: SectorAllocation;
}) {
  const [benchmark, setBenchmarkData] = useState<BenchmarkDataResponse | null>(null);
  const [selectedEquityBenchmarkType, setSelectedEquityBenchmarkType] = useState<string | null>(
    null
  );
  const [showBenchmark, setShowBenchmark] = useState<boolean>(true);

  const { data: featureFlags } = useFeatureFlags();
  const additional_benchmarks_enabled = featureFlags?.enable_additional_benchmarks;

  useEffect(() => {
    const FetchBenchmark = async () => {
      const result = await getXrayBenchmarks();
      const benchmarkData = result.data;

      if (additional_benchmarks_enabled !== 'on') {
        benchmarkData.equities.splice(0, 2);
      }

      setBenchmarkData(benchmarkData);
      setSelectedEquityBenchmarkType(benchmarkData.equities[0].name);
    };
    FetchBenchmark();
  }, [additional_benchmarks_enabled]);

  if (!benchmark || !selectedEquityBenchmarkType)
    return <Skeleton width="100%" height="25em" variant="rect" />;

  const EQUITY_BENCHMARK_TYPES = benchmark.equities.map((b) => b.name);

  const currentEquityBenchmarks = benchmark.equities.find(
    (eq) => eq.name === selectedEquityBenchmarkType
  );

  if (!currentEquityBenchmarks) return null;

  let EquityDescriptionUnderweightItem: DescriptionItem = {};
  let EquityDescriptionOverweightItem: DescriptionItem = {};
  type SectorWeights = {
    sector: Sector;
    currentRelative: number;
    targetRelative: number;
    currentPercent: number;
    targetPercent: number;
    benchmark: number;
  };

  const calculateEquityWeights = (
    current: SectorAllocation,
    target: SectorAllocation
  ): SectorWeights[] =>
    currentEquityBenchmarks.data
      .filter((d: SectorBenchmarkData) => d.sector !== 'UNKNOWN_SECTOR')
      .map<SectorWeights>((d: SectorBenchmarkData) => {
        const currentSector = d.sector;

        const currentSectorAllocation =
          current.find((f) => f.sector === currentSector)?.allocationFraction ?? 0;

        const targetSectorAllocation =
          target.find((f) => f.sector === currentSector)?.allocationFraction ?? 0;

        if (currentSectorAllocation - d.allocation > 0.03) {
          const label = SECTOR_TO_LABEL_MAP.get(currentSector);

          if (
            (EquityDescriptionOverweightItem.value || 0) <=
              currentSectorAllocation - d.allocation ||
            !EquityDescriptionOverweightItem.value
          ) {
            EquityDescriptionOverweightItem = {
              key: currentSector,
              message: (
                <>
                  <b style={{ color: tokens.palette.secondaryPurple[600] }}>{label}</b> may
                  underperform during {EQUITY_COPY_REASON[currentSector]} as{' '}
                  {EQUITY_COPY_DESCRIPTION_ABOVE_BENCHMARK[currentSector]}
                </>
              ),
              value: currentSectorAllocation - d.allocation,
            };
          }
        }

        if (currentSectorAllocation - d.allocation < -0.03) {
          const label = SECTOR_TO_LABEL_MAP.get(currentSector);

          if (
            (EquityDescriptionUnderweightItem.value || 0) >=
              currentSectorAllocation - d.allocation ||
            !EquityDescriptionUnderweightItem.value
          ) {
            EquityDescriptionUnderweightItem = {
              key: currentSector,
              message: (
                <>
                  An underweight to{' '}
                  <b style={{ color: tokens.palette.secondaryPurple[600] }}>{label}</b> may hurt
                  returns during {EQUITY_COPY_DESCRIPTION_BELOW_BENCHMARK[currentSector]}
                </>
              ),
              value: currentSectorAllocation - d.allocation,
            };
          }
        }

        const currentResult = currentSectorAllocation - d.allocation;
        const targetResult = targetSectorAllocation - d.allocation;

        return {
          sector: currentSector,
          currentRelative: currentResult * 100,
          targetRelative: targetResult * 100,
          currentPercent: currentSectorAllocation * 100,
          targetPercent: targetSectorAllocation * 100,
          benchmark: d.allocation * 100,
        };
      })
      .sort((a, b) => b.currentRelative - a.currentRelative);

  const currentEquityWeights = calculateEquityWeights(currentSectors, targetSectors);

  const maxEquityWeights =
    Math.max(
      ...currentSectors.map((s) => s.allocationFraction),
      ...targetSectors.map((s) => s.allocationFraction),
      ...currentEquityBenchmarks.data.map((b) => b.allocation)
    ) *
      100 +
    5;

  return (
    <Box mt={3} mb={5}>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <SectionSubtitle title="Equity: Sector Analysis" id="equity-sector" />
        <Box display="flex">
          <Box display="flex" alignItems="center" mr={2}>
            <Switch checked={showBenchmark} onChange={() => setShowBenchmark(!showBenchmark)} />{' '}
            <span style={{ color: tokens.palette.neutralCool[600] }}>Benchmark Comparison</span>
          </Box>
          <DropdownButtonMenu
            disabled={!showBenchmark}
            buttonContent={selectedEquityBenchmarkType}
            buttonProps={{ variant: 'outlined' }}
          >
            {(closeMenu) =>
              EQUITY_BENCHMARK_TYPES.map((name) => (
                <DropdownButtonMenuItem
                  key={name}
                  onClick={() => {
                    setSelectedEquityBenchmarkType(name);
                    closeMenu();
                  }}
                >
                  {name}
                </DropdownButtonMenuItem>
              ))
            }
          </DropdownButtonMenu>
        </Box>
      </Box>
      <Typography color="grey.600" mb={4}>
        The bar charts show absolute weights from your current portfolio & the Vise portfolio. The
        benchmark selected will show how over or under-weight a sector is in relation to the
        benchmark.
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h4">Current Portfolio</Typography>
          <HighchartsReact
            options={createOptions(
              currentEquityWeights.map((e) => e.currentPercent),
              showBenchmark ? currentEquityWeights.map((e) => e.benchmark) : [],
              currentEquityWeights.map((e) => SECTOR_TO_LABEL_MAP.get(e.sector) ?? 'Unknown'),
              tokens.palette.secondaryPurple[300],
              maxEquityWeights
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h4">
            <Box display="flex">
              <img alt="V" src={ViseLogo} height="21" width="17" style={{ marginRight: 8 }} />
              Vise Portfolio
            </Box>
          </Typography>
          <HighchartsReact
            options={createOptions(
              currentEquityWeights.map((e) => e.targetPercent),
              showBenchmark ? currentEquityWeights.map((e) => e.benchmark) : [],
              currentEquityWeights.map((e) => SECTOR_TO_LABEL_MAP.get(e.sector) ?? 'Unknown'),
              tokens.palette.secondaryPurple[300],
              maxEquityWeights
            )}
            allowChartUpdate
          />
        </Grid>
      </Grid>
      <StyledList sx={{ mt: 4 }}>
        {EquityDescriptionUnderweightItem && EquityDescriptionUnderweightItem.message ? (
          <StyledListItem>{EquityDescriptionUnderweightItem.message}</StyledListItem>
        ) : null}
        {EquityDescriptionOverweightItem && EquityDescriptionOverweightItem.message ? (
          <StyledListItem>{EquityDescriptionOverweightItem.message}</StyledListItem>
        ) : null}
      </StyledList>
    </Box>
  );
}

const RATING_ORDER = {
  AAA: 1,
  AA: 2,
  A: 3,
  BBB: 4,
  BB: 5,
  B: 6,
  CCC: 7,
  CC: 8,
  C: 9,
  D: 10,
  NR: 11,
  UNKNOWN: 12,
};

function FixedIncomeMetricsSection({
  fixedIncomeMetrics,
}: {
  fixedIncomeMetrics: CalculateFixedIncomeMetricsResponse | undefined;
}) {
  const RATING_TO_COLOR = {
    AAA: tokens.palette.neutralCool[1000],
    AA: '#ECE0CB',
    A: tokens.palette.primaryBlue[700],
    BBB: '#F9BBE2',
    BB: '#BE2A86',
    B: tokens.palette.secondaryPurple[700],
    CCC: tokens.palette.secondaryTurquoise[300],
    CC: tokens.palette.secondaryTurquoise[700],
    C: '#2FBAB3',
    'Not rated': tokens.palette.neutralCool[400],
    Unknown: tokens.palette.neutralCool[600],
  };

  const currentPortfolio = fixedIncomeMetrics?.currentPortfolio;
  const targetPortfolio = fixedIncomeMetrics?.targetPortfolio;

  const qualityAllocationChartOptions = (
    allocation: Highcharts.SeriesPieOptions['data']
  ): Highcharts.Options => ({
    chart: { type: 'pie', height: 200, width: 200 },
    credits: {
      enabled: false,
    },
    lang: {
      noData: 'No data to display',
    },
    plotOptions: {
      pie: {
        center: ['50%', '50%'],
        dataLabels: {
          enabled: false,
        },
      },
    },
    title: {
      text: undefined,
    },
    tooltip: {
      pointFormatter: function format() {
        // eslint-disable-next-line react/no-this-in-sfc
        return formatPercent(this.y, 1);
      },
    },
    series: [
      {
        type: 'pie',
        data: allocation,
        size: '110%',
        innerSize: '80%',
        borderWidth: 1,
      },
    ],
  });

  function FixIncomeMetricsSubSection({
    metrics,
    variant,
  }: {
    metrics?: FixedIncomeMetrics | null;
    variant: 'target' | 'current';
  }) {
    const legendLabel = (label: string) => {
      if (label === 'NOT_RATED') return 'Not rated';
      if (label === 'UNKNOWN') return 'Unknown';
      return label;
    };
    const qualityData = metrics?.debtorQualities
      .sort((a, b) => RATING_ORDER[a.rating] - RATING_ORDER[b.rating])
      .map((d) => ({
        name: legendLabel(d.rating),
        y: d.weight,
        color: RATING_TO_COLOR[legendLabel(d.rating)],
      }));

    return (
      <>
        {metrics == null || qualityData == null ? (
          <Skeleton width="100%" height="25em" variant="rect" />
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h4">
                <Box display="flex">
                  {variant === 'target' && (
                    <img alt="V" src={ViseLogo} height="21" width="17" style={{ marginRight: 8 }} />
                  )}
                  {variant === 'target' ? 'Vise' : 'Current'} Portfolio
                </Box>
              </Typography>
              <Box display="flex" mt={1} data-testid="fi-metrics-container">
                <Metric label="Coupon rate" metric={formatPercent(metrics.couponRate)} />
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <Metric label="Yield to maturity" metric={formatPercent(metrics.yieldToMaturity)} />
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <Metric
                  label="Effective duration"
                  metric={`${metrics.effectiveDuration.toFixed(2)} years`}
                />
              </Box>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={7}>
                <HighchartsReact options={qualityAllocationChartOptions(qualityData)} />
              </Grid>
              <Grid item xs={5}>
                <Box>
                  {qualityData.map((d) => {
                    if (d.y > 0.005) {
                      return (
                        <Box key={d.name} display="flex" justifyContent="flex-start" my={1.5}>
                          <ChartColorSquare color={RATING_TO_COLOR[d.name]} />
                          {d.name}
                          <Box color="grey.600" ml="auto" mr={0}>
                            {formatPercent(d.y, 1)}
                          </Box>
                        </Box>
                      );
                    }
                    return null;
                  })}
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }

  return (
    <Box mt={3} mb={5}>
      <SectionSubtitle title="Fixed Income: Quality Comparison" id="fi-metrics" mb={4} />
      <Box mb={4}>
        Compare the characteristics of the fixed income funds in your current portfolio versus the
        recommended Vise target. See a breakdown of the aggregate credit quality of each portfolio,
        alongside the estimated income, yield to maturity and durations.
      </Box>
      <Grid container spacing={2}>
        <Grid item container spacing={3} xs={5}>
          <FixIncomeMetricsSubSection metrics={currentPortfolio} variant="current" />
        </Grid>
        <Grid xs={1} item />
        <Grid item container spacing={3} xs={5}>
          <FixIncomeMetricsSubSection metrics={targetPortfolio} variant="target" />
        </Grid>
      </Grid>
    </Box>
  );
}

function FixedIncomeSectorAnalysis({
  currentAllocations,
  targetAllocations,
}: {
  currentAllocations: PCE2AssetClassAllocation;
  targetAllocations: PCE2AssetClassAllocation;
}) {
  const isL4 = (allocation: PCE2AssetClassAllocation[number]['name']) =>
    allocation.match(/\//g)?.length === 3;

  let FixedIncomeDescriptionUnderweightItem: DescriptionItem = {};
  let FixedIncomeDescriptionOverweightItem: DescriptionItem = {};

  // if allocation has L4 level, we need to merge all L4 into a single L4 level allocation
  const mergeL4Alloactions = (allocations: PCE2AssetClassAllocation) =>
    allocations.reduce<
      Map<PCE2AssetClassAllocation[number]['name'], PCE2AssetClassAllocation[number]['y']>
    >((acc, allocation) => {
      const newKey = allocation.name
        .split('/', 3)
        .reduce((acc, val) => (acc ? `${acc}/${val}` : val), '') as AssetClassKey;

      if (isL4(allocation.name)) {
        if (acc.get(newKey)) {
          const currentMapValue = acc.get(newKey);
          if (!currentMapValue) return acc;
          acc.set(newKey, currentMapValue + allocation.y);
          return acc;
        }
      }

      acc.set(newKey, allocation.y);
      return acc;
    }, new Map());

  const updatedCurrentAlloactions = normalizeFixedIncomeAllocation(
    Array.from(mergeL4Alloactions(currentAllocations), (v) => ({
      name: v[0],
      y: v[1],
    }))
  );
  const updatedTargetAlloactions = normalizeFixedIncomeAllocation(
    Array.from(mergeL4Alloactions(targetAllocations), (v) => ({
      name: v[0],
      y: v[1],
    }))
  );

  const [benchmark, setBenchmarkData] = useState<BenchmarkDataResponse | null>(null);
  const [showBenchmark, setShowBenchmark] = useState<boolean>(true);

  const [selectedFixedIncomeBenchmarkType, setSelectedFixedIncomeBenchmarkType] = useState<
    string | null
  >(null);

  useEffect(() => {
    const fetchBenchmark = async () => {
      const result = await getXrayBenchmarks();
      setBenchmarkData(result.data);
      setSelectedFixedIncomeBenchmarkType(result.data.fixedIncome[0].name);
    };
    fetchBenchmark();
  }, []);

  if (!benchmark) return <Skeleton width="100%" height="25em" variant="rect" />;

  const AssetClassKeyToLabel = (longName: AssetClassKey) => {
    const labels = longName.split('/') as Feature[];
    return ASSET_CLASS_TO_LABEL_MAP.get(labels[labels.length - 1]) ?? 'Unknown';
  };

  const FIXED_INCOME_BENCHMARK_TYPES = benchmark.fixedIncome.map((b) => b.name);

  const currentFixedBenchmarks = benchmark.fixedIncome.find(
    (t) => t.name === selectedFixedIncomeBenchmarkType
  );

  if (!currentFixedBenchmarks) return null;

  type FixedIncomeWeights = {
    assetClass: AssetClassKey;
    currentRelative: number;
    targetRelative: number;
    currentPercent: number;
    targetPercent: number;
    benchmark: number;
  };

  const calculateFixedWeights = (current: Allocation, target: Allocation): FixedIncomeWeights[] =>
    currentFixedBenchmarks.data
      .map((d) => {
        const currentAllocation = d.assetClass;

        const currentFixedIncomeAllocation =
          current.find((f) => f.name === currentAllocation)?.y ?? 0;
        const targetFixedIncomeAllocation =
          target.find((f) => f.name === currentAllocation)?.y ?? 0;

        const currentResult = currentFixedIncomeAllocation - d.allocation;
        const targetResult = targetFixedIncomeAllocation - d.allocation;

        if (currentResult > 0.03) {
          if (
            (FixedIncomeDescriptionOverweightItem.value || 0) <= currentResult ||
            !FixedIncomeDescriptionOverweightItem.value
          ) {
            FixedIncomeDescriptionOverweightItem = {
              key: d.assetClass,
              message: (
                <>
                  <b>{AssetClassKeyToLabel(d.assetClass)}</b> may underperform during{' '}
                  {FIXED_INCOME_COPY_ABOVE_THRESHOLD[currentAllocation]}
                </>
              ),
              value: currentResult,
            };
          }
        } else if (currentResult < -0.03) {
          if (
            (FixedIncomeDescriptionUnderweightItem.value || 0) >= currentResult ||
            !FixedIncomeDescriptionUnderweightItem.value
          ) {
            FixedIncomeDescriptionUnderweightItem = {
              key: d.assetClass,
              message: (
                <>
                  An underweight to{' '}
                  <b style={{ color: tokens.palette.primaryBlue[600] }}>
                    {AssetClassKeyToLabel(d.assetClass)}
                  </b>{' '}
                  may hurt returns during {FIXED_INCOME_COPY_BELOW_THRESHOLD[currentAllocation]}
                </>
              ),
              value: currentResult,
            };
          }
        }

        return {
          assetClass: d.assetClass,
          currentRelative: currentResult * 100,
          targetRelative: targetResult * 100,
          currentPercent: currentFixedIncomeAllocation * 100,
          targetPercent: targetFixedIncomeAllocation * 100,
          benchmark: d.allocation * 100,
        };
      })
      .sort((a, b) => Object.values(b)[0][0] - Object.values(a)[0][0]);

  const fixedIncomeWeights = calculateFixedWeights(
    updatedCurrentAlloactions,
    updatedTargetAlloactions
  );

  const maxFiWeights =
    Math.max(
      ...updatedCurrentAlloactions.map((s) => s.y),
      ...updatedTargetAlloactions.map((s) => s.y),
      ...currentFixedBenchmarks.data.map((b) => b.allocation * 100)
    ) + 5;

  return (
    <Box mt={3} mb={5}>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <SectionSubtitle title="Fixed Income: Sector Analysis" id="fi-sector" />
        <Box display="flex">
          <Box display="flex" alignItems="center" mr={2}>
            <Switch checked={showBenchmark} onChange={() => setShowBenchmark(!showBenchmark)} />{' '}
            <span style={{ color: tokens.palette.neutralCool[600] }}>Benchmark Comparison</span>
          </Box>
          <DropdownButtonMenu
            disabled={!showBenchmark}
            buttonContent={selectedFixedIncomeBenchmarkType}
            buttonProps={{ variant: 'outlined' }}
          >
            {(closeMenu) =>
              FIXED_INCOME_BENCHMARK_TYPES.map((name) => (
                <DropdownButtonMenuItem
                  key={name}
                  onClick={() => {
                    setSelectedFixedIncomeBenchmarkType(name);
                    closeMenu();
                  }}
                >
                  {name}
                </DropdownButtonMenuItem>
              ))
            }
          </DropdownButtonMenu>
        </Box>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h4">
            <Box display="flex">Current Portfolio</Box>
          </Typography>
          <HighchartsReact
            options={createOptions(
              fixedIncomeWeights.map((f) => f.currentPercent),
              showBenchmark ? fixedIncomeWeights.map((f) => f.benchmark) : [],
              fixedIncomeWeights.map((f) => AssetClassKeyToLabel(f.assetClass)),
              tokens.palette.primaryBlue[300],
              maxFiWeights
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h4">
            <Box display="flex">
              <img alt="V" src={ViseLogo} height="21" width="17" style={{ marginRight: 8 }} />
              Vise Portfolio
            </Box>
          </Typography>
          <HighchartsReact
            options={createOptions(
              fixedIncomeWeights.map((f) => f.targetPercent),
              showBenchmark ? fixedIncomeWeights.map((f) => f.benchmark) : [],
              fixedIncomeWeights.map((f) => AssetClassKeyToLabel(f.assetClass)),
              tokens.palette.primaryBlue[300],
              maxFiWeights
            )}
          />
        </Grid>
        <Box color="grey.600" fontStyle="italic" fontSize={10} mb={2}>
          The absolute weight denotes sector allocation percentages for the current and the Vise
          target portfolio
        </Box>
        <Box color="grey.600" fontStyle="italic" fontSize={10} mb={2}>
          The relative weight indicates the degree to which a specific sector is overweight or
          underweight compared to the selected benchmark. For example, a positive value in the chart
          indicates a sector allocation exceeds the benchmark by X%, while a negative value
          indicates a relative underweight of Y%.
        </Box>
      </Grid>
      <StyledList sx={{ mt: 4 }}>
        {FixedIncomeDescriptionUnderweightItem.message && (
          <StyledListItem>{FixedIncomeDescriptionUnderweightItem.message}</StyledListItem>
        )}
        {FixedIncomeDescriptionOverweightItem.message && (
          <StyledListItem>{FixedIncomeDescriptionOverweightItem.message}</StyledListItem>
        )}
      </StyledList>
    </Box>
  );
}

function ExpenseRatioVisualization({
  portfolioFees,
  variant,
}: {
  portfolioFees: PortfolioFees;
  variant: 'current' | 'target';
}) {
  const { totalExpenseRatio, totalFees } = portfolioFees;
  return (
    <Box
      mt={1}
      display="flex"
      fontSize={56}
      fontWeight={700}
      alignItems="center"
      letterSpacing={-1}
      color={variant === 'current' ? 'purple.500' : 'primary.main'}
      data-testid="current-portfolio-expense-ratio"
    >
      {formatPercent(totalExpenseRatio)}
      <Divider orientation="vertical" flexItem sx={{ height: '20px', margin: 'auto 16px' }} />
      {formatCurrency(totalFees, WHOLE_DOLLAR_FORMATTER)}
    </Box>
  );
}

function FundOverlap({
  fundOverlap,
  positions,
}: {
  fundOverlap: FundOverlapResponse | undefined;
  positions: PortfolioPositionResponse | undefined;
}) {
  const [overlapCollapsed, setOverlapCollapsed] = useState(true);
  const positionsMap = keyBy(positions?.positions, 'symbolOrCusip');

  const overlapColumns: GridColDef<FundOverlapResponse['overlappedAssets'][0]>[] = [
    { field: 'symbol', headerName: 'Ticker', flex: 1 },
    {
      field: 'SSandFunds',
      headerName: 'Total Value',
      renderCell: (params) => {
        const val = params.row.totalValue + (positionsMap[params.row.symbol]?.marketValue ?? 0);
        return <>{formatCurrency(val)}</>;
      },
      flex: 1.5,
    },
    {
      field: 'heldInSS',
      headerName: 'Single Stock Value',
      renderCell: (params) => {
        const marketValue = positionsMap[params.row.symbol]?.marketValue;

        return <>{formatCurrency(marketValue)}</>;
      },
      flex: 1.5,
    },
    {
      field: 'totalValue', // Amount in funds not total
      headerName: 'Value in Funds',
      renderCell: ({ value }) => <>{formatCurrency(value)}</>,
      flex: 1.5,
    },
    {
      field: 'funds',
      headerName: 'Funds',
      valueGetter: (params) => params.row.heldBy,
      renderCell: ({ value }) => (
        <div>
          <div>{value[0].symbol}</div>
          <div>{value[1].symbol}</div>
          {value.length > 2 && (
            <PopoverTrigger
              triggerAction="hover"
              overlay={() => (
                <PopoverCard
                  title=""
                  body={
                    <div>
                      {value.slice(2).map((v) => (
                        <div key={v.symbol}>{v.symbol}</div>
                      ))}
                    </div>
                  }
                />
              )}
            >
              <PopoverLink variant="caption" sx={{ marginTop: -1 }}>
                +{value.length - 2} more
              </PopoverLink>
            </PopoverTrigger>
          )}
        </div>
      ),
      flex: 1,
    },
  ];
  return (
    <>
      {fundOverlap != null && fundOverlap.overlappedAssets.length > 0 ? (
        <Box mt={3} mb={5}>
          <Divider />
          <SectionSubtitle title="Holdings Overlap" id="holdings-overlap" mt={3} mb={4} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {fundOverlap == null ? (
                <Skeleton width="100%" height="15em" variant="rect" />
              ) : (
                <Box>
                  <b>Current Portfolio Fund Overlap:</b>
                  <Box my={1} color="purple.500">
                    <Box
                      fontSize={56}
                      fontWeight={700}
                      lineHeight={1.25}
                      data-testid="num-overlapped-assets"
                    >
                      {fundOverlap.overlappedAssets.length.toLocaleString()}
                    </Box>
                    <Typography variant="h4">Securities found in multiple funds</Typography>
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={6}>
              <StyledList>
                <StyledListItem>
                  Holdings overlap is a hidden risk in portfolios that hold Mutual Funds and ETFs.
                </StyledListItem>
                <StyledListItem>
                  A high amount of overlap can increase a portfolio&apos;s expected volatility and
                  returns.
                </StyledListItem>
              </StyledList>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" mt={4} mb={2}>
                <Box color="grey.600">
                  We&apos;ve identified single securities in the current portfolio that also exist
                  in ETF&apos;s and mutual funds held.
                </Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  startIcon={overlapCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
                  onClick={() => setOverlapCollapsed(!overlapCollapsed)}
                >
                  {overlapCollapsed ? 'View' : 'Hide'} details
                </Button>
              </Box>
              <Collapse in={!overlapCollapsed}>
                <DataGridPro
                  rowHeight={72}
                  columns={overlapColumns}
                  rows={fundOverlap.overlappedAssets}
                  getRowId={(row) => row.symbol}
                  pagination
                  hideFooter={false}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                />
              </Collapse>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
}

function Fees({ fees, account }: { fees: CalculateFeesResponse | undefined; account: Account }) {
  const { data: positionsData } = usePositions(account.accountNumber, account.custodianKey);
  const positionsKeyByTicker = keyBy(positionsData?.positions, 'symbolOrCusip');

  const tableData = fees?.currentPortfolio.assetsWithFees.map((asset) => ({
    ...asset,
    marketValue: positionsKeyByTicker[asset.symbol]?.marketValue ?? 0,
  }));

  const feesColumns: GridColDef[] = [
    { field: 'symbol', headerName: 'Ticker', flex: 1 },
    {
      field: 'marketValue',
      headerName: 'Market value',
      renderCell: ({ value }) => <>{formatCurrency(value)}</>,
      flex: 1,
    },
    {
      field: 'netExpenseRatio',
      headerName: 'Net expense ratio',
      renderCell: ({ value }) => <>{formatPercent(value)}</>,
      flex: 1,
    },
    {
      field: 'totalFees',
      headerName: 'Yearly expense',
      renderCell: ({ value }) => <>{formatCurrency(value)}</>,
      flex: 1,
    },
  ];

  return (
    <Box mt={3} mb={5}>
      <SectionSubtitle title="Expense Ratio" id="expense-ratio" mb={4} mt={3} />
      <Grid container spacing={2}>
        {fees == null ? (
          <Grid item xs={12}>
            <Skeleton width="100%" height="15em" variant="rect" />
          </Grid>
        ) : (
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <b>Current Portfolio Expense Ratio</b>
                <Tooltip title="Estimated yearly expense.">
                  <Box display="flex" alignItems="center" ml={0.5}>
                    <InformationCircleIcon />
                  </Box>
                </Tooltip>
                :
              </Box>
              <ExpenseRatioVisualization portfolioFees={fees.currentPortfolio} variant="current" />
            </Grid>
            <Grid item xs={6}>
              <StyledList>
                <StyledListItem>
                  The estimated expense ratio for your current portfolio is{' '}
                  <b>{formatPercent(fees.currentPortfolio.totalExpenseRatio)}</b>
                </StyledListItem>
                <StyledListItem>
                  There may be opportunities to to transition into lower fee positions with a
                  similar risk profile.
                </StyledListItem>
              </StyledList>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Box color="grey.600" mt={4} mb={2}>
        The table below provides insights and guidance on items that may require you to take action.
      </Box>
      {tableData == null ? (
        <Skeleton width="100%" height="15em" variant="rect" />
      ) : (
        <DataGridPro
          autoHeight
          rowHeight={44}
          columns={feesColumns}
          rows={tableData}
          getRowId={(row) => row.symbol}
          pagination
          hideFooter={false}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
        />
      )}
    </Box>
  );
}

export default function AssetAllocationSection({
  intelligence,
  collapseProps,
  onClick,
  account,
  portfolioMetrics,
  fixedIncomeMetrics,
  fees,
  fundOverlap,
}: {
  intelligence: PortfolioIntelligenceFull;
  collapseProps?: CollapseProps;
  onClick: () => void;
  account: Account;
  portfolioMetrics: PortfolioMetrics;
  fixedIncomeMetrics: CalculateFixedIncomeMetricsResponse | undefined;
  fees: CalculateFeesResponse | undefined;
  fundOverlap: FundOverlapResponse | undefined;
}) {
  const { data: isEtfOnlyProposalResponse } = useIsEtfOnlyProposal({ proposalId: intelligence.id });
  const { data: positions } = usePositions(account.accountNumber, account.custodianKey);
  const isEtfOnly = isEtfOnlyProposalResponse?.data;

  if (intelligence.proposalType === 'light') {
    throw new Error(`Portfolio intelligence should be PCE2 and FULL. (id: ${intelligence.id})`);
  }

  const {
    constructionResponse: {
      metrics: {
        strategyMetrics: { targetPortfolio },
      },
    },
  } = intelligence;

  const currentAllocation = transformPCE2AssetClassAllocations(
    portfolioMetrics.assetClassAllocations
  );

  const targetAllocation = transformPCE2AssetClassAllocations(
    targetPortfolio.assetClassAllocations
  );

  const showFixedIncomeAnalysis =
    currentAllocation?.some((ca) => ca.name.startsWith(FIXED_INCOME_ASSET_CLASS_KEY)) &&
    targetAllocation.some((ta) => ta.name.startsWith(FIXED_INCOME_ASSET_CLASS_KEY));
  return (
    <>
      <SectionTitle
        title="Asset Allocation"
        subtext={`See how ${intelligence.client.firstName}'s newly customized Vise Portfolio compares
            against their current portfolio. Vise optimizes asset allocations based on your
            client's risk tolerance and goals.`}
        onClick={onClick}
        collapseProps={collapseProps}
        id="asset-allocation"
      />
      <Collapse {...collapseProps}>
        <>
          <AllocationCharts current={currentAllocation} target={targetAllocation} />

          <AssetAllocationBreakdown
            currentAllocation={portfolioMetrics.assetClassAllocations}
            targetAllocation={targetPortfolio.assetClassAllocations}
          />

          {/* Currently, PCE output does not have sector information for etf only portfolios
              so we are hiding this section for now as the target portfolio section does not make
              a lot of sense (all 0% allocation) */}
          {!isEtfOnly && (
            <>
              <Divider />
              <EquitySectorAnalysis
                currentSectors={portfolioMetrics.sectorAllocations}
                targetSectors={targetPortfolio.sectorAllocations}
              />
            </>
          )}
          {showFixedIncomeAnalysis && (
            <>
              <Divider />
              <FixedIncomeMetricsSection fixedIncomeMetrics={fixedIncomeMetrics} />
              <Divider />
              <FixedIncomeSectorAnalysis
                currentAllocations={currentAllocation}
                targetAllocations={targetAllocation}
              />
            </>
          )}
          <>
            <Divider />
            <Fees fees={fees} account={account} />
            <FundOverlap fundOverlap={fundOverlap} positions={positions} />
          </>
        </>
      </Collapse>
    </>
  );
}
