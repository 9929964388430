import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import { format, isSameDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import {
  BondPortfolioCreditQuality,
  BondPortfolioDuration,
  type BondPortfolioMuniMaturity,
  type BondPortfolioTreasuryMaturity,
  BondPortfolioSampleOptionTreasury,
  BondPortfolioSettings,
  BondPortfolioStateStrategy,
  HighQualityBondPortfolioSampleOption,
  LadderBondPortfolioSampleOption,
} from 'vise-types/portfolio';
import { executeBondProposal } from '~/api/api';
import ExecutePortfolio from '~/components/modal/ExecutePortfolio';
import useAccount from '~/hooks/useAccount';
import useBondPortfolioSampleData from '~/hooks/useBondPortfolioSampleData';
import useBondProposal from '~/hooks/useBondProposal';
import useClient from '~/hooks/useClient';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import DocumentCheck from '~/static/images/illustrations/document-check.svg';
import Banner from '~/synth/Banner';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import Skeleton from '~/synth/Skeleton';
import { getCustodianDisplayName, maskAccountNumber } from '~/utils/format';
import { scrollToTop } from '../ModelTemplateCenter/workflows/CommonComponents';
import ExportMenu from '../Portfolio/ExportMenu';
import SentryRoute from '../SentryRoute';
import BondPortfolioAllocation from './BondPortfolioAllocation';
import BondPortfolioInputs from './BondPortfolioInputs';
import BondPortfolioOverview from './BondPortfolioOverview';

/* eslint-disable no-nested-ternary */

// TODO: individual loading states for each tab
export const ProposalLoading = () => {
  return (
    <>
      <Box pt={3}>
        <Container>
          <Box display="flex" justifyContent="space-between" pb={3}>
            <Box>
              <Box width="16em" mb={2}>
                <Typography variant="body1" component="h1">
                  <Skeleton />
                </Typography>
              </Box>
              <Box width="25em">
                <Typography variant="h1" component="h1">
                  <Skeleton />
                </Typography>
              </Box>
              <Box width="16em">
                <Typography variant="body1" component="h1">
                  <Skeleton />
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" width="20%">
              <Skeleton>
                <Button variant="contained" color="secondary">
                  Execute
                </Button>
              </Skeleton>
            </Box>
          </Box>
        </Container>
        <Box border={1} borderLeft={0} borderRight={0} borderColor="grey.200">
          <Container>
            <Box display="flex" justifyContent="space-between">
              <Tabs value="overview" aria-label="Portfolio tabs">
                <Tab value="overview" label="Overview" disabled />
                <Tab value="allocation" label="Allocation" disabled />
                <Tab value="inputs" label="Inputs" disabled />
              </Tabs>
              <Box display="flex" justifyContent="end" width="20%">
                <Skeleton width="10em" />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Container>
        <Box pt={4} pb={8}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Skeleton height="10em" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton height="10em" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton height="10em" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton height="10em" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton height="30em" />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

function settingsToQuery(
  settings: BondPortfolioSettings
):
  | LadderBondPortfolioSampleOption
  | HighQualityBondPortfolioSampleOption
  | BondPortfolioSampleOptionTreasury {
  return settings.instrumentType === 'MUNICIPAL'
    ? ({
        instrumentType: 'MUNICIPAL',
        productState: settings.stateStrategy as BondPortfolioStateStrategy,
        ...(settings.duration != null
          ? { duration: settings.duration as BondPortfolioDuration }
          : settings.maturity != null
          ? { maturity: settings.maturity as BondPortfolioMuniMaturity }
          : {}),
        creditQuality: settings.creditQuality as BondPortfolioCreditQuality,
      } as LadderBondPortfolioSampleOption | HighQualityBondPortfolioSampleOption)
    : settings.instrumentType === 'TREASURY'
    ? ({
        instrumentType: 'TREASURY',
        maturity: settings.maturity as BondPortfolioTreasuryMaturity,
      } as BondPortfolioSampleOptionTreasury)
    : ({} as never);
}

export default function BondProposal() {
  const { proposalId } = useParams<{ proposalId: string }>();
  const { data: bondProposalData } = useBondProposal(proposalId);
  const { data: accountData } = useAccount(bondProposalData?.data.accountId || null);

  const aum = accountData?.data.cachedAum || bondProposalData?.data?.aum;
  const settings = bondProposalData?.data != null ? bondProposalData.data : null;

  const { data: sampleDataData } = useBondPortfolioSampleData(
    settings != null ? settingsToQuery(settings) : null,
    aum
  );
  const { data: clientData } = useClient(
    // Use the associated client for a linked account proposal. Otherwise use the client ID associated with the settings from sample_proposals table
    accountData?.data.viseClientId ?? settings?.clientId
  );

  const routeMatch = useRouteMatch([`/secure/bond-proposal/${proposalId}/:tabName`]);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tabName' does not exist on type '{}'.
  const tabName = routeMatch?.params?.tabName;

  const [executePortfolioModalOpen, setExecutePortfolioModalOpen] = useState(false);
  const isCreatedToday =
    bondProposalData?.data.createdAt != null &&
    isSameDay(new Date(bondProposalData?.data.createdAt), new Date());
  const [firstTimeModalOpen, setFirstTimeModalOpen] = useState(false);
  const enqueueCoachmark = useEnqueueCoachmark();
  const history = useHistory();

  useEffect(() => {
    if (isCreatedToday) {
      setFirstTimeModalOpen(true);
    }
  }, [isCreatedToday]);

  if (bondProposalData == null || sampleDataData == null || clientData == null) {
    return <ProposalLoading />;
  }

  const account = accountData?.data;
  const sampleData = sampleDataData.data;
  const bondProposal = bondProposalData.data;

  const canExecute =
    account?.engineType === 'AB_FIXED_INCOME' && account?.canExecute && account.status === 'ACTIVE';

  const onExecute = async (form: { id: string }) => {
    try {
      if (account == null) {
        // UI shouldn't allow this state
        throw new Error(`proposal cannot be executed for sample proposal`);
      }
      if (settings == null) {
        throw new Error(`settings not found for proposal ${proposalId}`);
      }

      await executeBondProposal(form.id);
    } catch (e) {
      enqueueCoachmark({
        title: 'There was an error executing this proposal.',
        content: 'Please reach out to clientservice@vise.com for help.',
        severity: 'error',
      });
      return;
    }
    history.push(`/secure/accounts/${account.id}/bond-portfolio/overview`);
    scrollToTop();
    enqueueCoachmark({
      title: 'Proposal successfully executed!',
      content:
        'Once executed, we will begin building your bond portfolio, this typically takes up to 10 business days as we identify bonds that match your clients unique circumstances.',
      severity: 'success',
    });
  };

  return (
    <>
      <Helmet>
        <title>Proposal</title>
      </Helmet>
      <Box pt={3}>
        <Container>
          <Box display="flex" justifyContent="space-between" alignItems="center" pb={3}>
            <Box>
              <PathBreadcrumbs
                path={[
                  ...(clientData.clientGroup != null
                    ? [
                        {
                          name: clientData.clientGroup?.name ?? '',
                          to: `/secure/households/${clientData.clientGroup?.id}/overview`,
                        },
                      ]
                    : []),
                  {
                    name:
                      account != null
                        ? account.accountName
                        : `${(clientData.lastName, clientData.firstName)}`,
                  },
                ]}
                ariaLabel="Page path breadcrumbs"
              />
              {account != null ? (
                <Box>
                  <Box mb={1} mt={2}>
                    <Typography variant="h2" component="h1">
                      {account.accountName}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box display="inline-block" mr={1.5}>
                      <Typography variant="body1" color="textSecondary">
                        {account.taxable ? 'Non-qualified' : 'Qualified'}
                      </Typography>
                    </Box>
                    <Box bgcolor="grey.300" alignSelf="stretch">
                      <Divider orientation="vertical" flexItem />
                    </Box>
                    <Box ml={1.5}>
                      <Typography variant="body1" color="textSecondary">
                        {maskAccountNumber(account.accountNumber)},{' '}
                        {getCustodianDisplayName(account.custodianKey)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Typography mt={2} mb={1} variant="h2" component="h1">
                  {clientData.firstName} {clientData.lastName} - Sample
                </Typography>
              )}
            </Box>
            <Box display="flex">
              <Tooltip title="Coming soon!">
                <span>
                  <ExportMenu sx={{ mr: 2 }} isIcon disabled onExportCsv={() => null} />
                </span>
              </Tooltip>
              <Tooltip
                disableHoverListener={canExecute}
                title="Please contact the client service team to activate your account"
              >
                <span>
                  <Button
                    variant="contained"
                    onClick={() => setExecutePortfolioModalOpen(true)}
                    sx={{ alignSelf: 'center' }}
                    disabled={!canExecute}
                  >
                    Execute
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
          <Box mb={3}>
            <Banner
              size="small"
              message={
                <Box display="flex">
                  <InformationCircleIcon
                    fontSize={16}
                    style={{ marginRight: 3, marginTop: 1.5 }}
                    color={tokens.palette.primary.main}
                  />
                  <Box>
                    You can find this proposal in the&nbsp;
                    <a
                      style={{ display: 'inline-block' }}
                      href={`/secure/households/${clientData.clientGroup?.id}/overview`}
                    >
                      {clientData.clientGroup?.name ?? ''} Household overview
                    </a>
                    . If you have further questions around this proposal or a transition analysis
                    please contact clientservice@vise.com.
                  </Box>
                </Box>
              }
            />
          </Box>
        </Container>
        <Box border={1} borderLeft={0} borderRight={0} borderColor="grey.200">
          <Container>
            <Box display="flex" justifyContent="space-between">
              <Tabs value={tabName || 'overview'} aria-label="Portfolio tabs">
                <Tab
                  value="overview"
                  label="Overview"
                  id="tab-overview"
                  to={`/secure/bond-proposal/${proposalId}/overview`}
                  aria-controls="overview-screen"
                  component={Link}
                />
                <Tab
                  value="allocation"
                  label="Allocation"
                  id="tab-allocation"
                  to={`/secure/bond-proposal/${proposalId}/allocation`}
                  aria-controls="allocation-screen"
                  component={Link}
                />
                <Tab
                  value="inputs"
                  label="Inputs"
                  id="tab-inputs"
                  to={`/secure/bond-proposal/${proposalId}/inputs`}
                  aria-controls="inputs-screen"
                  component={Link}
                />
              </Tabs>
              {bondProposal.createdAt != null && (
                <Box display="flex" alignItems="center" alignSelf="center">
                  <Typography color="textSecondary" variant="body1">
                    Proposal saved {format(new Date(bondProposal.createdAt), 'MMM d, yyyy')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
      <Container>
        <Box pt={4} pb={8}>
          <Switch>
            <SentryRoute exact path="/secure/bond-proposal/:proposalId/overview">
              <Box id="overview-screen" aria-labelledby="tab-overview" role="tabpanel">
                <BondPortfolioOverview
                  account={account}
                  cashFlow={sampleData.cashFlows}
                  metrics={sampleData.metrics}
                  settings={bondProposal}
                  holdings={sampleData.holdings}
                  isProposal
                />
              </Box>
            </SentryRoute>
            <SentryRoute exact path="/secure/bond-proposal/:proposalId/allocation">
              <Box id="allocation-screen" aria-labelledby="tab-allocation" role="tabpanel">
                <BondPortfolioAllocation
                  metrics={sampleData.metrics}
                  holdings={sampleData.holdings}
                  muniUses={sampleData.muniUses}
                  creditRating={sampleData.creditQuality}
                  instrumentType={bondProposal.instrumentType}
                  isProposal
                />
              </Box>
            </SentryRoute>
            <SentryRoute exact path="/secure/bond-proposal/:proposalId/inputs">
              <Box id="allocation-screen" aria-labelledby="tab-allocation" role="tabpanel">
                <BondPortfolioInputs settings={bondProposal} />
              </Box>
            </SentryRoute>

            <SentryRoute exact path="/secure/bond-proposal/:proposalId">
              <Redirect to={`/secure/bond-proposal/${proposalId}/overview`} />
            </SentryRoute>
          </Switch>
        </Box>
      </Container>
      <ExecutePortfolio
        open={executePortfolioModalOpen}
        onClose={() => setExecutePortfolioModalOpen(false)}
        onSubmit={onExecute}
        form={{ portfolioIntelligenceId: proposalId }}
      />
      <Dialog open={firstTimeModalOpen} onClose={() => setFirstTimeModalOpen(false)}>
        <DialogContent sx={{ textAlign: 'center' }}>
          <img src={DocumentCheck} alt="Document check" />
          <Typography variant="h2">Proposal created</Typography>
          <Box color={tokens.palette.neutralCool[600]} py={3}>
            Your new proposal is available anytime at Households Overview on the next to the
            associated account or at the {clientData.clientGroup?.name} Household Overview page.
          </Box>
          <Button
            variant="contained"
            size="small"
            fullWidth
            onClick={() => setFirstTimeModalOpen(false)}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
