import { Box, Collapse, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import CardFooter from '~/synth/CardFooter';
import amplitude from '~/utils/amplitude';
import UnstyledButtonBase from '~/synth/UnstyledButton';
import { ReactComponent as InsightsBulbIcon } from '~/static/images/icons/insights-bulb.svg';
import { ReactComponent as ChevronDown } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '~/static/images/icons/chevron-up.svg';
import styled from 'styled-components';
import { EVENT_CATEGORIES } from '~/constants/amplitude';

const UnstyledButton = styled(UnstyledButtonBase)`
  width: 100%;
`;

export default function InsightsCardFooter({
  title,
  body,
  link,
}: {
  title: string;
  body: string;
  link: React.ReactNode;
}) {
  const theme = useTheme();
  const [insightsOpen, setInsightsOpen] = useState<boolean>(false);
  return (
    <CardFooter alignItems="center" borderStyle="dashed">
      <UnstyledButton
        onClick={() => {
          setInsightsOpen((prev) => !prev);
          amplitude().logEvent(`${!insightsOpen ? 'Open' : 'Close'} asset class insight footer`, {
            category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
            title,
          });
        }}
      >
        <Box display="flex" alignItems="center" mt={1} mb={1}>
          <Box display="flex" mr={1.5}>
            <InsightsBulbIcon width={24} height={24} />
          </Box>
          <Box flex={1}>
            <Typography variant="h4" component="p">
              {title}
            </Typography>
          </Box>
          <Box>
            {insightsOpen ? (
              <ChevronUp width="20" height="20" />
            ) : (
              <ChevronDown width="20" height="20" />
            )}
          </Box>
        </Box>
      </UnstyledButton>
      <Collapse in={insightsOpen}>
        <Box mt={3} pb={3} borderBottom={`1px dashed ${theme.palette.grey[200]}`}>
          <Typography variant="body1">{body}</Typography>
        </Box>
        {link}
      </Collapse>
    </CardFooter>
  );
}
