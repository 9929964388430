import { useState } from 'react';
import { useHistory } from 'react-router';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { getAccount } from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { FetchNewProposalErrorType } from '~/errors';
import useToast from '~/hooks/useToast';
import { FETCH_PROPOSAL_ERROR_TYPE_TO_MESSAGE } from '~/routes/PortfolioCreator2/Constants';
import amplitude from '~/utils/amplitude';
import { useEnqueueCoachmark } from './useCoachmark';

export default function useGetNewPce2Proposal(failureOrigin: string) {
  const enqueueCoachmark = useEnqueueCoachmark();
  const enqueueToast = useToast();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleError = (errorType: FetchNewProposalErrorType) => {
    const message =
      FETCH_PROPOSAL_ERROR_TYPE_TO_MESSAGE[errorType] ||
      FETCH_PROPOSAL_ERROR_TYPE_TO_MESSAGE.UNKNOWN;
    enqueueCoachmark({
      title: message.title,
      content: message.content,
      severity: 'error',
    });
    amplitude().logEvent(failureOrigin, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      errorMessage: `${message.title} | ${message.content}`,
    });
    setIsLoading(false);
  };

  async function getNewPce2Proposal<
    T extends Pick<PortfolioIntelligenceFull, 'status' | 'id' | 'constructionInfo'>
  >(portfolioIntelligence: T, executedProposal: boolean) {
    // TODO(awang) : Verify if this is actually needed (probably not).
    if (portfolioIntelligence == null) {
      handleError('NOT_FOUND');
      return;
    }

    const accountId = portfolioIntelligence.constructionInfo?.existingPortfolio?.id;

    if (executedProposal) {
      if (accountId == null) {
        handleError('INFEASIBLE');
        return;
      }

      enqueueToast({
        title: 'Proposal successfully executed!',
        content:
          'Trade orders have been generated and sent for execution. Once executed, trades will be reflected here on the next business day.',
        severity: 'success',
      });

      history.push(`/secure/accounts/${accountId}/portfolio/overview`);
      return;
    }

    setIsLoading(true);

    if (
      portfolioIntelligence.status === 'READY' ||
      portfolioIntelligence.status === 'BACKTEST_FAILED' ||
      portfolioIntelligence.status === 'BACKTEST_SOLVING'
    ) {
      const account = accountId ? await getAccount(accountId) : null;
      const redirUrl = `/secure/proposal/${portfolioIntelligence.id}${
        account?.data.accountDataSource === 'XRAY' ? '/x-ray' : ''
      }`;
      // Per design spec, wait 3 seconds to load the page for the new proposal (a loading screen should be shown during this timeout).
      setTimeout(() => {
        history.push(redirUrl);
      }, 3000);
    }
  }

  return { isLoading, getNewPce2Proposal };
}
