import React from 'react';
import { Box, BoxProps, Tooltip } from '@mui/material';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/question-mark-circle.svg';

export default function startingAssetAllocationCopy(risk?: number) {
  if (risk == null) {
    return '';
  }

  if (risk < 10) {
    return 'You’re looking to invest in a portfolio that emphasizes stability over higher expected returns, and are seeking out a portfolio with the lowest degree of volatility.';
  }

  if (risk < 20) {
    return 'You’re looking to invest in a portfolio that emphasizes stability over higher expected returns, and are comfortable with a very low degree of potential volatility.';
  }

  if (risk < 30) {
    return 'You’re looking to invest in a portfolio that emphasizes stability over higher expected returns, and are comfortable with a low degree of potential volatility.';
  }

  if (risk < 40) {
    return 'You’re looking to invest in a portfolio that emphasizes stability over higher expected returns, and are comfortable with a fairly low degree of potential volatility.';
  }

  if (risk < 50) {
    return 'You’re looking to invest in a portfolio that emphasizes stability over higher expected returns, and are comfortable with a more modest degree of potential volatility.';
  }

  if (risk === 50) {
    return 'You’re looking to invest in a portfolio that balances stability and higher expected returns, and are comfortable with a more modest degree of potential volatility.';
  }

  if (risk <= 60) {
    return 'You’re looking to invest in a portfolio that pursues higher expected returns, and are comfortable with a modest degree of potential volatility.';
  }

  if (risk <= 70) {
    return 'You’re looking to invest in a portfolio that pursues higher expected returns, and are comfortable with a fair degree of potential volatility.';
  }

  if (risk <= 80) {
    return 'You’re looking to invest in a portfolio that pursues higher expected returns, and are comfortable with potential volatility.';
  }

  if (risk <= 90) {
    return 'You’re looking to invest in a portfolio that pursues higher expected returns, and are comfortable with a high degree of potential volatility.';
  }

  return 'You’re looking to invest in a portfolio that pursues higher expected returns, and are comfortable with the maximum degree of potential volatility.';
}

export function StartingAssetAllocationTooltip(boxProps: BoxProps) {
  return (
    <Tooltip title="Equities and fixed income target percentages may change based on subsequent asset allocation inputs.">
      <Box component="span" {...boxProps}>
        <InformationCircleIcon width={12} height={12} />
      </Box>
    </Tooltip>
  );
}
