import useSWR from 'swr';
import { getAccountOrders } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getOrdersSwrKey = (intelligenceId: string) =>
  generateSwrKey(`/portfolio/intelligence/${intelligenceId}/orders`);

export default function useAccountOrders(accountId: string | undefined | null) {
  return useSWR(
    accountId ? getOrdersSwrKey(accountId) : null,
    accountId == null ? undefined : () => getAccountOrders(accountId)
  );
}
