import { ActionType } from '../constants';

const initialState = {
  // { ID => Message }
  messages: {},
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function messageReducer(state = initialState, action: any) {
  switch (action.type) {
    case ActionType.CLOSE_MESSAGE: {
      const newMessages = {};
      Object.keys(state.messages).forEach((k) => {
        newMessages[k] =
          action.dismissAll || k === action.key
            ? { ...state.messages[k], dismissed: true }
            : { ...state.messages[k] };
      });

      return {
        ...state,
        messages: newMessages,
      };
    }
    case ActionType.SHOW_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.key]: {
            message: action.message,
            options: action.options,
          },
        },
      };

    case ActionType.REMOVE_MESSAGE: {
      const nextMessages = { ...state.messages };
      delete nextMessages[action.key];

      return {
        ...state,
        messages: nextMessages,
      };
    }
    default:
      return state;
  }
}
