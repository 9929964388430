import { Box, FormLabel, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useMemo } from 'react';
import { ActionMeta, components, OptionProps, ValueType } from 'react-select';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useInstruments, { useIndexedInstruments } from '~/hooks/useInstruments';
import { AsyncCreatableSelect } from '~/synth/inputs/Select';

import amplitude from '~/utils/amplitude';

export interface TickerOption {
  label: string;
  value: string;
  description: string;
}

export type SelectedTickerOption = TickerOption | null;

interface TickerSelectorProps {
  value: string | null;
  onChange: (value: TickerOption) => void;
}

const Option = React.memo(function Option(props: OptionProps<TickerOption>) {
  const { data } = props;
  return (
    <components.Option {...props}>
      <Box fontWeight={500} mb={0.5}>
        {data.value}
      </Box>
      <Typography variant="body1" color="textSecondary">
        {data.description}
      </Typography>
    </components.Option>
  );
});

export default function TickerSelector({ value, onChange }: TickerSelectorProps) {
  const { data: instruments } = useInstruments();
  const { data: indexedInstruments } = useIndexedInstruments();
  const selectedOption = useMemo(() => {
    return value
      ? {
          // To account for created option
          label: indexedInstruments?.[value]?.symbol ?? value.toLocaleUpperCase(),
          value: indexedInstruments?.[value]?.symbol ?? value.toLocaleLowerCase(),
          description: indexedInstruments?.[value]?.name ?? value.toLocaleUpperCase(),
        }
      : null;
  }, [value, indexedInstruments]);

  const allOptions = useMemo(() => {
    if (instruments) {
      return instruments.map((ins) => ({
        label: ins.symbol,
        value: ins.symbol,
        description: ins.name,
      }));
    }
    return [];
  }, [instruments]);

  const loadOptions = debounce((inputValue: string, callback: (o: TickerOption[]) => void) => {
    return callback(
      allOptions == null
        ? []
        : matchSorter<TickerOption>(allOptions, inputValue, {
            keepDiacritics: true,
            keys: [
              { threshold: matchSorter.rankings.STARTS_WITH, key: 'value' },
              { threshold: matchSorter.rankings.CONTAINS, key: 'description' },
            ],
          })
    );
  }, 800);

  const handleChange = (
    newValue: ValueType<TickerOption>,
    actionMeta: ActionMeta<TickerOption>
  ) => {
    const { action } = actionMeta;
    const newInstrument = (newValue as SelectedTickerOption) ?? null;

    if (
      (action !== 'select-option' &&
        action !== 'deselect-option' &&
        action !== 'clear' &&
        action !== 'set-value') ||
      newInstrument == null
    ) {
      return;
    }

    onChange(newInstrument);
  };

  return (
    <>
      <Box mb={1}>
        <FormLabel htmlFor="companies">Tickers</FormLabel>
      </Box>
      <AsyncCreatableSelect<TickerOption>
        cacheOptions
        loadOptions={loadOptions}
        onFocus={() => {
          amplitude().logEvent('Tap ticker restriction search', {
            category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
          });
        }}
        filterOption={null}
        defaultOptions={allOptions}
        isSearchable
        inputId="tickers"
        isLoading={allOptions == null}
        onChange={handleChange}
        placeholder="Ex. Apple Inc"
        value={selectedOption}
        components={{ Option }}
        onCreateOption={(inputValue: string) => {
          const newOption = inputValue.toLocaleUpperCase();
          handleChange(
            {
              value: newOption,
              label: newOption,
              description: newOption,
            },
            { action: 'select-option' }
          );
        }}
        optionHeight={65}
      />
    </>
  );
}
