import {
  Box,
  BoxProps,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { pick } from 'lodash';
import React, { useEffect } from 'react';
import {
  Column,
  IdType,
  PluginHook,
  Row,
  TableInstance,
  useExpanded,
  UseExpandedOptions,
  usePagination,
  useRowSelect,
  UseRowSelectHooks,
  useSortBy,
  UseSortByOptions,
  useTable,
  UseTableOptions,
} from 'react-table';
import { ReactComponent as ArrowDownIcon } from '~/static/images/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '~/static/images/icons/arrow-up.svg';
import { ReactComponent as ChevronDown } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ChevronRight } from '~/static/images/icons/chevron-right.svg';

const Td = withStyles(TableCell, (theme) => ({
  root: {
    height: theme.spacing(6.5),
    '&.MuiTableCell-sizeSmall': {
      padding: theme.spacing(0, 2),
    },
  },
}));

const MediumTd = withStyles(Td, (theme) => ({
  root: {
    height: theme.spacing(7.5),
  },
}));

const LargeTd = withStyles(Td, (theme) => ({
  root: {
    height: theme.spacing(9.25),
  },
}));

const Th = withStyles(TableCell, (theme) => ({
  root: {
    ...pick(theme.typography.h6, ['fontSize', 'lineHeight']),
    height: theme.spacing(6.25),
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
    whiteSpace: 'nowrap',
    '&:first-child': {
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    '&:last-child': {
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    },
    padding: theme.spacing(2),
  },
}));

const SmallTh = withStyles(Th, (theme) => ({
  root: {
    height: theme.spacing(4.25),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const SubHeader = withStyles(TableCell, (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[800],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
  },
}));

const DataTableHead = withStyles(TableHead, (theme) => ({
  root: {
    // This is to create spacing between the thead and tbody as margin/paddings don't work on thead/tbody/tr elements
    '&::after': {
      content: '"@"',
      display: 'block',
      lineHeight: theme.spacing(1),
      textIndent: -9999,
    },
  },
}));

function SortedIcon({ isSortedDesc }: { isSortedDesc: boolean }) {
  return <Box mx={0.25}>{isSortedDesc ? <ArrowDownIcon /> : <ArrowUpIcon />}</Box>;
}

export const DataTableContainer = withStyles(TableContainer, (theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    backgroundColor: '#fff',
  },
}));

export function DataTableTitle({
  title,
  metaData,
  ...restProps
}: {
  title: React.ReactNode;
  metaData?: React.ReactNode;
} & BoxProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={3}
      py={2.5}
      // There seems to be a bug with MUI such that border color is not applied when border is specific by position
      border={1}
      borderTop={0}
      borderLeft={0}
      borderRight={0}
      borderColor="grey.200"
      {...restProps}
    >
      <Box fontWeight="fontWeightBold">{title}</Box>
      <Box color="text.secondary" fontSize="body1.fontSize">
        {metaData}
      </Box>
    </Box>
  );
}

export function DataTableActionBar({ children, ...restProps }: BoxProps) {
  return (
    <Box px={1} pt={2} {...restProps}>
      {children}
    </Box>
  );
}

export function DataTableFooter({ children, ...restProps }: BoxProps) {
  return (
    <Box
      px={4}
      py={2.75}
      bgcolor="grey.700"
      color="grey.200"
      borderRadius="1px"
      mx={1}
      mb={1}
      boxShadow={6}
      {...restProps}
    >
      {children}
    </Box>
  );
}

function StyledBox({ children, m, ...restProps }: BoxProps) {
  return (
    <Box overflow="auto" m={m == null ? 2 : m} {...restProps}>
      {children}
    </Box>
  );
}

const DataTableBox = withStyles(StyledBox, () => ({
  root: {
    // DataTable with subheaders are actually just multiple tables stacked together with
    // the thead of subsequent tables replaced by a subheader.
    // This is a little hacky because for Safari visibility: collapse leaves a whitespace
    // where the thead is so we have to manually hide the content in the header.
    '&.datatable-with-subheaders ~ &.datatable-with-subheaders thead th': {
      height: 0,
      padding: 0,
    },
    '&.datatable-with-subheaders ~ &.datatable-with-subheaders thead th div': {
      display: 'none',
    },
    // Only target chrome, this extra space in the top does not work with safari
    '@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm)': {
      '&.datatable-with-subheaders:first-of-type .datatable-subheader': {
        // Let subheader scroll up to hit main header then stay sticky
        top: '50px',
      },
    },
  },
}));

const DataTableRow = withStyles(TableRow, (theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    '&:last-child': {
      border: 'none',
    },
  },
}));

const StyledClickableRow = withStyles(TableRow, (theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    '&:last-child': {
      border: 'none',
    },
    '&:hover': {
      background: `${theme.palette.grey[100]}`,
      cursor: 'pointer',
    },
    '&:focus': {
      background: `${theme.palette.grey[200]}`,
      outline: 'none',
    },
  },
}));

type ClickableRowProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
>;

const ClickableRow = React.forwardRef(({ children, ...restProps }: ClickableRowProps, ref) => {
  return (
    <StyledClickableRow
      role="button"
      tabIndex={0}
      {...restProps}
      ref={ref as React.MutableRefObject<HTMLTableRowElement>}
    >
      {children}
    </StyledClickableRow>
  );
});

const EMPTY_ARRAY = [];

export const caseInsensitive = <D extends Object>(
  row1: Row<D>,
  row2: Row<D>,
  columnName: IdType<D>
) => {
  const row1Value = row1.values[columnName];
  const row2Value = row2.values[columnName];
  return row1Value.localeCompare(row2Value);
};

export const floatCompare = <D extends Object>(
  row1: Row<D>,
  row2: Row<D>,
  columnName: IdType<D>
) => {
  const float1 = row1.values[columnName];
  const float2 = row2.values[columnName];
  return float1 > float2 ? 1 : -1;
};

export const momentCompare = <D extends Object>(
  row1: Row<D>,
  row2: Row<D>,
  columnName: IdType<D>
) => {
  const date1 = row1.values[columnName];
  const date2 = row2.values[columnName];
  return date1.isAfter(date2) ? 1 : -1;
};

export function DataTable<D extends object>({
  tableMaxHeight,
  sortable = false,
  paginated = false,
  expandable = false,
  subHeader = '',
  headerSize = 'large',
  rowSize = 'small',
  onRowClick,
  onRowSelect,
  table,
  footer,
  m,
  customRowProps,
  ...useTableOptions
}: Partial<
  UseTableOptions<D> & UseSortByOptions<D> & UseExpandedOptions<D> & UseRowSelectHooks<D>
> & {
  tableMaxHeight?: number | string;
  sortable?: boolean;
  paginated?: boolean;
  expandable?: boolean;
  subHeader?: string;
  headerSize?: 'small' | 'large';
  rowSize?: 'small' | 'medium' | 'large';
  onRowClick?: (row: Row<D>) => void;
  onRowSelect?: (rows: Row<D>[]) => void;
  footer?: React.ReactNode;
  table?: TableInstance<D>;
  m?: number;
  customRowProps?: (row: Row<D>) => Partial<TableRowProps>;
}) {
  const pluginConfig: [PluginHook<D>, boolean][] = [
    [useSortBy, sortable],
    [usePagination, paginated],
    [useExpanded, expandable],
    [useRowSelect, !!onRowSelect],
  ];

  const { columns, data, sortTypes, initialState } = useTableOptions;

  const reactTable = useTable<D>(
    {
      columns: columns || EMPTY_ARRAY,
      data: data || EMPTY_ARRAY,
      sortTypes: {
        caseInsensitive,
        floatCompare,
        dateCompare: momentCompare,
        ...(sortTypes || {}),
      },
      initialState,
    },
    ...pluginConfig.reduce((acc, [plugin, enabled]) => {
      if (enabled) acc.push(plugin);
      return acc;
    }, [] as PluginHook<D>[]),
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...(onRowSelect != null
          ? [
              {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <Checkbox {...getToggleAllRowsSelectedProps()} />
                ),
                Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
                width: '50px',
              } as Column<D>,
            ]
          : []),
        ...columns,
      ]);
    }
  );

  const { getTableProps, headerGroups, page, prepareRow, rows, visibleColumns, selectedFlatRows } =
    table || reactTable;

  useEffect(() => {
    onRowSelect?.(selectedFlatRows);
  }, [onRowSelect, selectedFlatRows]);

  const DynamicTh = headerSize === 'large' ? Th : SmallTh;
  let DynamicTd: typeof Td | typeof MediumTd | typeof LargeTd = Td;
  if (rowSize === 'large') {
    DynamicTd = LargeTd;
  } else if (rowSize === 'medium') {
    DynamicTd = MediumTd;
  }

  const DynamicTr = onRowClick ? ClickableRow : DataTableRow;

  return (
    <DataTableBox
      maxHeight={tableMaxHeight}
      m={m}
      className={subHeader && 'datatable-with-subheaders'}
    >
      <Table
        stickyHeader
        size="small"
        style={{ tableLayout: 'fixed', borderCollapse: 'collapse' }}
        {...getTableProps()}
      >
        <DataTableHead>
          {headerGroups.map((headerGroup) => (
            // `key` prop is generated by react-table, no need to include one
            // eslint-disable-next-line react/jsx-key
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {expandable ? <DynamicTh style={{ width: '10px' }} /> : null}
              {headerGroup.headers.map((column) => (
                // `key` prop is generated by react-table, no need to include one
                // eslint-disable-next-line react/jsx-key
                <DynamicTh
                  {...column.getHeaderProps(
                    // `getSortByToggleProps` will only exist if this instance is "sortable"
                    column.getSortByToggleProps && column.getSortByToggleProps()
                  )}
                  {...column.getHeaderProps({
                    style: {
                      width: column.width,
                    },
                  })}
                >
                  {column.align === 'right' ? (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      color={column.isSorted ? 'grey.800' : 'grey.500'}
                      style={{
                        cursor:
                          typeof column.getSortByToggleProps === 'function' ? 'pointer' : 'auto',
                      }}
                      component={
                        typeof column.getSortByToggleProps === 'function' ? 'button' : 'div'
                      }
                      // Override default button styles
                      bgcolor="inherit"
                      border="inherit"
                      fontWeight="inherit"
                      fontFamily="inherit"
                      p={0}
                      // Add margin left for right align
                      ml="auto"
                    >
                      {column.isSorted && (
                        <SortedIcon isSortedDesc={Boolean(column.isSortedDesc)} />
                      )}
                      {column.render('Header')}
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent={column.align === 'center' ? 'center' : 'flex-start'}
                      alignItems="center"
                      color={column.isSorted ? 'grey.800' : 'grey.500'}
                      style={{
                        cursor:
                          typeof column.getSortByToggleProps === 'function' ? 'pointer' : 'auto',
                      }}
                      component={
                        typeof column.getSortByToggleProps === 'function' ? 'button' : 'div'
                      }
                      // Override default button styles
                      bgcolor="inherit"
                      border="inherit"
                      fontWeight="inherit"
                      fontFamily="inherit"
                      p={0}
                      mx={column.align === 'center' ? 'auto' : 0}
                    >
                      {column.render('Header')}
                      {column.isSorted && (
                        <SortedIcon isSortedDesc={Boolean(column.isSortedDesc)} />
                      )}
                    </Box>
                  )}
                </DynamicTh>
              ))}
            </TableRow>
          ))}
        </DataTableHead>
        <TableBody>
          {subHeader ? (
            <TableRow>
              <SubHeader
                className="datatable-subheader"
                colSpan={visibleColumns.length}
                variant="head"
                style={{
                  position: 'sticky',
                }}
              >
                <Typography variant="h5">{subHeader}</Typography>
              </SubHeader>
            </TableRow>
          ) : null}
          {(page || rows).map((row, index) => {
            prepareRow(row);
            return (
              // `key` prop is generated by react-table, no need to include one
              // eslint-disable-next-line react/jsx-key
              <DynamicTr
                {...row.getRowProps()}
                className={subHeader && index === 0 ? 'datatable-subheader-first-row' : undefined}
                onClick={onRowClick ? () => onRowClick(row) : undefined}
                {...(customRowProps ? customRowProps(row) : {})}
              >
                {/* Toggle for expanding row */}
                {expandable ? (
                  <DynamicTd {...row.getToggleRowExpandedProps()}>
                    {row.canExpand ? (
                      <>{row.isExpanded ? <ChevronDown /> : <ChevronRight />}</>
                    ) : (
                      // If there are no subrows, still leave a space
                      <div />
                    )}
                  </DynamicTd>
                ) : null}
                {row.cells.map((cell) => (
                  // `key` prop is generated by react-table, no need to include one
                  // eslint-disable-next-line react/jsx-key
                  <DynamicTd {...cell.getCellProps()}>{cell.render('Cell')}</DynamicTd>
                ))}
              </DynamicTr>
            );
          })}
          {footer ? (
            <TableRow>
              <TableCell colSpan={visibleColumns.length} variant="footer">
                {footer}
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </DataTableBox>
  );
}
