"use client";

import { Box, Link as LinkBase, styled } from "@mui/material";
import type { LinkProps as MuiLinkProps, Theme } from "@mui/material";
import React from "react";
import { SPACING_INDICES } from "../tokens/spacing.js";

type LinkProps<C extends React.ElementType> = MuiLinkProps<
  C,
  { component: C }
> & {
  linkVariant: "primary" | "secondary";
  displayVariant: "standalone" | "inline" | "icon";
  icon?: React.ReactNode;
  disabled?: boolean;
};

function getColor(
  theme: Theme,
  linkVariant: "primary" | "secondary",
  disabled?: boolean,
): string {
  if (disabled != null && disabled) {
    return theme.palette.neutralCool[400];
  }
  return linkVariant === "primary"
    ? theme.palette.primaryBlue[700]
    : theme.palette.neutralCool[900];
}

const StyledLinkBase = styled(LinkBase, {
  shouldForwardProp: (prop) => prop !== "linkVariant" && prop !== "disabled",
})<MuiLinkProps & Pick<LinkProps<"a">, "linkVariant" | "disabled">>(
  ({ theme, disabled, linkVariant }) => ({
    "&:hover": {
      color: getColor(theme, linkVariant, disabled),
      textDecorationColor: getColor(theme, linkVariant, disabled),
    },
    pointerEvents: disabled != null && disabled ? "none" : "auto",
    cursor: disabled != null && disabled ? "not-allowed" : "pointer",
    color: getColor(theme, linkVariant, disabled),
    textDecorationColor: getColor(theme, linkVariant, disabled),
  }),
);

/**
 * This component *should* be used instead of the MUI Link component. It has a toggle for primary or secondary
 * colors, as well as whether to display it as an inline or standalone variant, with the addition of an "icon" variant
 * for the standalone option. Besides that, the Typography variant should also be passed in.
 *
 * @param props MUI Link props plus "linkVariant", "displayVariant", "icon" element, and "disabled"
 * @returns
 */
export default function Link<C extends React.ElementType>(
  props: LinkProps<C>,
): React.ReactElement {
  let underline: typeof props.underline = "none" as const;
  let display: typeof props.display = "block";
  const { linkVariant, displayVariant, icon, disabled, ...otherProps } = props;

  if (displayVariant === "inline") {
    underline = "always";
    display = "inline";
  }

  if (displayVariant === "icon") {
    const { children, ...muiProps } = otherProps;
    return (
      <StyledLinkBase
        underline={underline}
        linkVariant={linkVariant}
        disabled={disabled}
        {...muiProps}
      >
        <Box display="flex" alignItems="center">
          <Box mr={SPACING_INDICES.xs}>{children}</Box>
          <Box
            height="20px"
            width="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {icon}
          </Box>
        </Box>
      </StyledLinkBase>
    );
  }

  return (
    <StyledLinkBase
      {...otherProps}
      linkVariant={linkVariant}
      underline={underline}
      display={display}
      disabled={disabled}
    />
  );
}
