import React, { useRef, useState } from 'react';
import { Box, ButtonBase, Card, LinearProgress, Paper, Typography } from '@mui/material';

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from 'tss-react/mui';

import CardContent from '~/synth/CardContent';
import CsvUploadIcon from '~/static/images/PortfolioCreator2/csv-upload.svg';
import { ReactComponent as CheckIcon } from '~/static/images/icons/check-circle-green.svg';
import { ReactComponent as TrashIcon } from '~/static/images/icons/trash.svg';
import amplitude from '~/utils/amplitude';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { AxiosError } from 'axios';

const makeCardStyles = makeStyles<{ error?: unknown }>()((theme, props) => ({
  root: {
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.06)',
    backgroundColor: props.error != null ? theme.palette.error[100] : null,
  },
}));

const makePaperStyles = makeStyles<{ uploaded: unknown }>()((theme, props) => ({
  root: {
    padding: theme.spacing(3),
    height: '100%',
    borderStyle: props.uploaded ? 'none' : 'dashed',
    borderColor: theme.palette.blue[300],
    boxShadow: 'none',
  },
}));

const DEFAULT_ERROR_MESSAGE =
  'File type or custodian may be incorrect based on your selection. Please review and try again.';
const DEFAULT_ERROR_DETAILS = 'Alternatively, try additional options below.';

const CsvError = ({ fileUploadError }: { fileUploadError: Error | null }) => {
  let message = DEFAULT_ERROR_MESSAGE;
  let details = DEFAULT_ERROR_DETAILS;

  const axiosError = fileUploadError as AxiosError<{ msg: string; details: string }>;
  if (
    // TODO(sfaulkner): Showing the default error message for error codes other than 400 is not good UX
    axiosError.code === AxiosError.ERR_BAD_REQUEST &&
    axiosError.response != null
  ) {
    const { msg, details: errorDetails } = axiosError.response.data;

    message = msg;
    details = errorDetails;
  }

  return (
    <>
      <Box mb={0.5}>{message}</Box>
      <Typography variant="caption">{details}</Typography>
    </>
  );
};

const CsvFileItem = ({
  filename,
  loading,
  children,
  onDelete,
  error = null,
}: {
  filename?: string | null;
  loading: boolean;
  children: React.ReactNode;
  onDelete: () => void;
  error?: Error | null;
}) => {
  const { classes: cardStyles } = makeCardStyles({ error });
  return (
    <Box my={2}>
      <Card classes={{ root: cardStyles.root }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" my="auto">
            <Box display="flex" flexDirection="column">
              {!loading && (
                <Box mb={0.5}>
                  <Typography variant="h4">{children}</Typography>
                </Box>
              )}
              {loading ? (
                <Box mb={1}>
                  <Typography variant="h4">Uploading...</Typography>
                </Box>
              ) : (
                <Typography style={{ maxWidth: '400px' }} noWrap>
                  {filename}
                </Typography>
              )}
            </Box>
            {!loading && (
              <Box display="flex">
                <Box pl={1}>
                  <ButtonBase title="Delete a file" onClick={onDelete}>
                    <TrashIcon width={24} height={24} />
                  </ButtonBase>
                </Box>
              </Box>
            )}
          </Box>
          {loading && <LinearProgress />}
        </CardContent>
      </Card>
    </Box>
  );
};

export default function CsvUploadCard({
  fileName,
  allowUpload,
  onUpload,
  onDelete,
  fileLoading,
  setFileLoading,
  allowPdf,
}: {
  fileName?: string | null;
  allowUpload: boolean;
  onUpload: (file: File) => unknown;
  onDelete: () => Promise<void>;
  fileLoading: boolean;
  setFileLoading: React.Dispatch<React.SetStateAction<boolean>>;
  allowPdf?: boolean;
}) {
  const { classes: CardClasses } = makeCardStyles({});
  const { classes: PaperClasses } = makePaperStyles({ uploaded: Boolean(fileName) });
  const fileSelectInputRef = useRef<HTMLInputElement | null>(null);
  const [fileUploadError, setFileUploadError] = useState<Error | null>(null);

  const handleFileBrowse = (e) => {
    e.preventDefault();

    if (fileSelectInputRef.current == null) return;
    fileSelectInputRef.current.click();
  };

  const handleFileUpload = async (file: File) => {
    setFileUploadError(null);
    setFileLoading(true);
    try {
      // TBD wire up fileType
      await onUpload(file);
      setFileLoading(false);
    } catch (e) {
      setFileLoading(false);
      setFileUploadError(e as Error);
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    if (!allowUpload) return;
    amplitude().logEvent(`Upload csv file (drag)`, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
    e.preventDefault();
    await handleFileUpload(e.dataTransfer.files[0]);
  };

  const handleDelete = async () => {
    if (fileUploadError) {
      setFileUploadError(null);
      return;
    }

    await onDelete();
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.currentTarget?.files?.[0];
    if (!file) return;
    await handleFileUpload(file);
  };

  return (
    <Card classes={{ root: CardClasses.root }}>
      <CardContent>
        <Paper
          classes={{ root: PaperClasses.root }}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          sx={{
            opacity: allowUpload ? 1 : 0.5,
            cursor: !allowUpload ? 'not-allowed' : 'initial',
          }}
        >
          <Box flexDirection="column" display="flex" alignItems="center">
            <Box mb={3}>
              {fileName ? (
                <CheckIcon width={72} height={72} />
              ) : (
                <img src={CsvUploadIcon} alt="CSV upload icon" width={72} height={72} />
              )}
            </Box>
            <Box mb={2}>
              {fileName ? (
                <Typography variant="h4">Your file has been uploaded successfully!</Typography>
              ) : (
                <Box display="flex" alignItems="end">
                  <Typography variant="h4">Drop your file here, or</Typography>
                  <input
                    ref={fileSelectInputRef}
                    type="file"
                    accept={`.csv,.xls,.xlsx${allowPdf ? ',.pdf' : ''}`}
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                    disabled={!allowUpload}
                  />
                  <Box component="span" color="blue.400" ml={0.5}>
                    <ButtonBase onClick={handleFileBrowse}>
                      <Typography variant="h4">browse</Typography>
                    </ButtonBase>
                  </Box>
                </Box>
              )}
            </Box>
            <Box mb={1}>
              {fileName ? (
                <Typography>Continue below to finish your client’s proposal.</Typography>
              ) : (
                <Typography>
                  Supports {allowPdf ? 'CSV, Excel, and PDF' : 'CSV & Excel'}, 7MB and under
                </Typography>
              )}
            </Box>
          </Box>
        </Paper>
        {(fileName || fileLoading || fileUploadError) && (
          <CsvFileItem
            filename={fileName}
            loading={fileLoading}
            onDelete={handleDelete}
            error={fileUploadError}
          >
            {fileUploadError ? (
              <CsvError fileUploadError={fileUploadError} />
            ) : (
              <Box>File uploaded successfully</Box>
            )}
          </CsvFileItem>
        )}
      </CardContent>
    </Card>
  );
}
