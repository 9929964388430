import { ProposalSource } from 'vise-types/pce2';
import {
  AssetClassKey,
  AssetType,
  Country,
  Feature,
  SecurityType,
} from 'vise-types/pce2_instrument';
import { TiltType } from 'vise-types/template';
import { PerformanceEvent } from 'vise-types/xray';
import { FetchNewProposalErrorType } from '~/errors';
import { getAssetClassKeyFromFeatures } from '~/utils/pce2Migration';
import { AssetClassTreeNode, DraftPortfolio } from './Types';

export const LAYOUT_L_PADDING_SPACING_XS = 3;
export const LAYOUT_R_PADDING_SPACING = 3;
export const LAYOUT_L_PADDING_EXACT_SM = 70;

export const FOCUS_TO_LABEL = {
  LOSS_TOLERANCE: 'Risk tolerance',
  TARGET_VALUE: 'Target value',
};

export const OVERLAY_TO_LABEL = {
  NONE: 'None selected',
  DIVIDEND: 'Dividend tilt',
};

export const DEFAULT_DRAFT_PORTFOLIO_VALUES_SMALL: {
  constructionInfo: Partial<DraftPortfolio['constructionInfo']>;
  lockedPositions: DraftPortfolio['lockedPositions'];
} = {
  constructionInfo: {
    activeTilt: {
      isEnabled: true,
      tiltType: 'multi-factor',
    },
    assetClasses: ['US_EQUITIES', 'US_EQUITIES_SMALL_CAP', 'US_BONDS', 'INTERNATIONAL_EQUITIES'],
    assetClassConcentrationLimits: {
      equities: undefined,
      exclusions: [],
      fixedIncome: undefined,
      isEnabled: false,
    },
    autoTlh: true,
    concentrationLimits: [],
    etfExclusive: false,
    excludedIndustries: [],
    excludedSectors: [],
    excludedCountries: [],
    excludedEsgAreas: [],
    overlay: 'NONE',
    restrictedStocks: [],
  },
  lockedPositions: [],
};

export const DEFAULT_DRAFT_PORTFOLIO_VALUES: {
  constructionInfo: Partial<DraftPortfolio['constructionInfo']>;
  lockedPositions: DraftPortfolio['lockedPositions'];
} = {
  ...DEFAULT_DRAFT_PORTFOLIO_VALUES_SMALL,
  constructionInfo: {
    ...DEFAULT_DRAFT_PORTFOLIO_VALUES_SMALL.constructionInfo,
    assetClassConcentrationLimits: {
      equities: undefined,
      exclusions: ['FIXED_INCOME/DOMESTIC/MUNICIPAL', 'ALTERNATIVES/COMMODITIES', 'ALTERNATIVES'],
      fixedIncome: undefined,
      isEnabled: false,
    },
  },
};

export const FETCH_PROPOSAL_ERROR_TYPE_TO_MESSAGE = {
  TIMEOUT: {
    title: 'Your request to the server timed out. Try again later.',
  },
  SERVER_ISSUE: {
    title: 'Your request hit an unexpected error.',
    content: 'Please contact us at clientservice@vise.com so we can help resolve it.',
  },
  INFEASIBLE: {
    title: `Hmm... we can't create a portfolio with your selected constraints.`,
    content: `Not to worry, we're looking into the problem and we'll get back to you.`,
  },
  UNKNOWN: {
    title: 'Hmm... we encountered a bug. Please try again.',
  },
  NOT_FOUND: {
    title: 'Hmm... we encountered a bug. Please try again.',
  },
} as { [key in FetchNewProposalErrorType]: { title: string; content?: string } };

export const ASSET_CLASS_TO_LABEL_MAP = new Map<Feature | 'LOCKED', string>([
  ['EQUITY', 'Equities'],
  ['US', 'U.S. Equities'],
  ['LARGE_CAP', 'Large/Mid Cap'],
  ['SMALL_CAP', 'Small Cap'],
  ['DEVELOPED', 'Developed ex U.S.'],
  ['EMERGING_EQUITY', 'Emerging Markets'],
  ['GLOBAL_EX_US', 'Global ex U.S.'],
  ['US_REIT', 'U.S. REIT'],
  ['FIXED_INCOME', 'Fixed Income'],
  ['DOMESTIC', 'U.S.'],
  ['TREASURY', 'Treasury'],
  ['CORPORATE', 'Investment Grade Corporate'],
  ['SHORT', 'Short Term'],
  ['INTERMEDIATE', 'Intermediate Term'],
  ['THREE_MONTH', 'Ultra-Short (Three month)'],
  ['TWELVE_MONTH', 'Ultra-Short (Twelve month)'],
  ['SECURITIZED', 'Securitized'],
  ['HIGH_YIELD', 'High Yield'],
  ['TIPS', 'Inflation-Protected (TIPS)'],
  ['MUNICIPAL', 'Municipal'],
  ['EMERGING_FI', 'Emerging Markets Government'],
  ['ALTERNATIVES', 'Alternatives'],
  ['COMMODITIES', 'Commodities'],
  ['CASH', 'Cash'],
  ['INTERNATIONAL', 'International Developed Government and Corporate'],
  ['UNKNOWN', 'Unclassified securities'],
  ['LOCKED', 'Other assets'],
]);

export const COUNTRY_TO_LABEL_MAP = {
  UNKNOWN_COUNTRY: 'Unknown country',
  US: 'United States',
  AUSTRALIA: 'Australia',
  BELGIUM: 'Belgium',
  CANADA: 'Canada',
  DENMARK: 'Denmark',
  FINLAND: 'Finland',
  FRANCE: 'France',
  GERMANY: 'Germany',
  HONG_KONG: 'Hong Kong',
  IRELAND: 'Ireland',
  ISRAEL: 'Israel',
  ITALY: 'Italy',
  JAPAN: 'Japan',
  SOUTH_KOREA: 'South Korea',
  LUXEMBOURG: 'Luxembourg',
  NETHERLANDS: 'Netherlands',
  NEW_ZEALAND: 'New Zealand',
  NORWAY: 'Norway',
  SINGAPORE: 'Singapore',
  SPAIN: 'Spain',
  SWEDEN: 'Sweden',
  SWITZERLAND: 'Switzerland',
  UNITED_KINGDOM: 'United Kingdom',
  CHINA: 'China',
  PORTUGAL: 'Portugal',
  AUSTRIA: 'Austria',
} as { [key in Country]: string };

// This is based off of ingestion/consts and should be updated when changes are made the go struct
// TODO: read this from a proto text to make sure this list doesn't get out of sync
export const DEVELOPED_COUNTRIES = [
  'AUSTRALIA',
  'AUSTRIA',
  'BELGIUM',
  'CANADA',
  'DENMARK',
  'FINLAND',
  'FRANCE',
  'GERMANY',
  'HONG_KONG',
  'IRELAND',
  'ISRAEL',
  'ITALY',
  'JAPAN',
  'LUXEMBOURG',
  'NETHERLANDS',
  'NEW_ZEALAND',
  'NORWAY',
  'PORTUGAL',
  'SINGAPORE',
  'SOUTH_KOREA',
  'SPAIN',
  'SWEDEN',
  'SWITZERLAND',
  'UNITED_KINGDOM',
] as Country[];

export const ASSET_CLASS_TREES_SMALL: AssetClassTreeNode[] = [
  {
    feature: 'EQUITY',
    children: [
      {
        feature: 'US',
        children: [
          {
            feature: 'LARGE_CAP',
          },
        ],
      },
      {
        feature: 'GLOBAL_EX_US',
      },
    ],
  },
  {
    feature: 'FIXED_INCOME',
    children: [
      {
        feature: 'DOMESTIC',
      },
    ],
  },
];

export const ASSET_CLASS_TREES: AssetClassTreeNode[] = [
  {
    feature: 'EQUITY',
    children: [
      {
        feature: 'US',
        children: [
          {
            feature: 'LARGE_CAP',
          },
          {
            feature: 'SMALL_CAP',
          },
        ],
      },
      {
        feature: 'DEVELOPED',
        children: [
          {
            feature: 'LARGE_CAP',
          },
          {
            feature: 'SMALL_CAP',
          },
        ],
      },
      {
        feature: 'EMERGING_EQUITY',
      },
      {
        feature: 'US_REIT',
      },
    ],
  },
  {
    feature: 'FIXED_INCOME',
    children: [
      {
        feature: 'DOMESTIC',
        children: [
          {
            feature: 'TREASURY',
            children: [
              {
                feature: 'THREE_MONTH',
              },
              {
                feature: 'TWELVE_MONTH',
              },
              {
                feature: 'SHORT',
              },
              {
                feature: 'INTERMEDIATE',
              },
            ],
          },
          {
            feature: 'CORPORATE',
            children: [
              {
                feature: 'SHORT',
              },
              {
                feature: 'INTERMEDIATE',
              },
            ],
          },
          {
            feature: 'SECURITIZED',
          },
          {
            feature: 'HIGH_YIELD',
          },
          {
            feature: 'TIPS',
          },
          {
            feature: 'MUNICIPAL',
          },
        ],
      },
      {
        feature: 'INTERNATIONAL',
      },
      {
        feature: 'EMERGING_FI',
      },
    ],
  },
  {
    feature: 'ALTERNATIVES',
    children: [
      {
        feature: 'COMMODITIES',
      },
    ],
  },
];

function buildAssetClassKeyToDescendantsKeyMap(
  assetClassTree: AssetClassTreeNode[]
): Map<AssetClassKey, AssetClassKey[]> {
  return assetClassTree.reduce((map, assetClassNode) => {
    const getTreeNodeMappings = (node: AssetClassTreeNode, curBranch: Feature[]) => {
      if (!curBranch) {
        return;
      }

      curBranch.push(node.feature);

      const descendentAssetClassKeys: AssetClassKey[] = [];
      map.set(getAssetClassKeyFromFeatures(curBranch), descendentAssetClassKeys);
      if (node.children) {
        node.children.forEach((child) => {
          const childAssetClassKey = getAssetClassKeyFromFeatures([...curBranch, child.feature]);

          getTreeNodeMappings(child, curBranch.slice());
          descendentAssetClassKeys.push(childAssetClassKey, ...(map.get(childAssetClassKey) ?? []));
        });
      }
    };

    getTreeNodeMappings(assetClassNode, []);
    return map;
  }, new Map<AssetClassKey, AssetClassKey[]>());
}

export const ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP: Map<AssetClassKey, AssetClassKey[]> =
  buildAssetClassKeyToDescendantsKeyMap(ASSET_CLASS_TREES);

const ALL_LARGE_ACCOUNT_ASSET_CLASS_ALLOCATION_KEYS: AssetClassKey[] =
  (function getAllLargeAccountAssetClassAllocationKeys() {
    const keys: AssetClassKey[] = [];
    function getAllLargeAccountAssetClassAllocationKeysRecursive(
      prefix: string,
      nodes: AssetClassTreeNode[]
    ) {
      nodes.forEach((node) => {
        const newPrefix = prefix === '' ? node.feature : `${prefix}/${node.feature}`;
        keys.push(newPrefix as AssetClassKey);
        if (node.children) {
          getAllLargeAccountAssetClassAllocationKeysRecursive(newPrefix, node.children);
        }
      });
    }
    getAllLargeAccountAssetClassAllocationKeysRecursive('', ASSET_CLASS_TREES);
    return keys;
  })();

export const BLANK_ASSET_CLASS_ALLOCATION: { [key in AssetClassKey]?: number } =
  ALL_LARGE_ACCOUNT_ASSET_CLASS_ALLOCATION_KEYS.reduce((obj, key) => {
    return {
      ...obj,
      [key]: 0,
    };
  }, {});

export const ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP_SMALL: Map<AssetClassKey, AssetClassKey[]> =
  buildAssetClassKeyToDescendantsKeyMap(ASSET_CLASS_TREES_SMALL);

export const SMALL_ACCOUNT_EQUITY_ASSET_CLASS_KEYS: AssetClassKey[] = [
  'EQUITY/US/LARGE_CAP',
  'EQUITY/GLOBAL_EX_US',
];

export const SMALL_ACCOUNT_FI_ASSET_CLASS_KEYS: AssetClassKey[] = ['FIXED_INCOME/DOMESTIC'];

function buildAssetClassLeaves(
  assetClassKeyToDescendantsKeyMap: Map<AssetClassKey, AssetClassKey[]>
): Set<AssetClassKey> {
  return new Set(
    Array.from(assetClassKeyToDescendantsKeyMap.entries())
      .filter(([, descendentKeys]) => descendentKeys.length === 0)
      .map(([key]) => key)
  );
}

export const ASSET_CLASS_LEAVES = buildAssetClassLeaves(ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP);
export const ASSET_CLASS_LEAVES_SMALL = buildAssetClassLeaves(
  ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP_SMALL
);

export const EQUITIES_ASSET_CLASS_KEY = 'EQUITY';
export const FIXED_INCOME_ASSET_CLASS_KEY = 'FIXED_INCOME';
export const ALTERNATIVES_ASSET_CLASS_KEY = 'ALTERNATIVES';

export const ASSET_CLASSES_WITH_VISE_SINGLE_SECURITY_STRATEGY_TO_LABEL_MAP = new Map<
  AssetClassKey,
  string
>([
  [
    'EQUITY/US/LARGE_CAP',
    'The Vise U.S. Large Cap strategy invests in U.S. companies ranked in the top 1,000 by market capitalization. Our factor-based approach considers company size, value, and profitability when constructing the strategy.',
  ],
  [
    'EQUITY/US/SMALL_CAP',
    'The Vise U.S. Small Cap strategy invests in U.S. companies ranked between 1,000 and 3,000 by market capitalization. Our factor-based approach considers company size, value, and profitability when constructing the strategy.',
  ],
  [
    'EQUITY/DEVELOPED/LARGE_CAP',
    'The Vise Developed ex U.S. Large Cap strategy invests in U.S.-listed and ADR-listed large and mid cap Developed ex U.S. companies. Our factor-based approach considers company size, value, and profitability when constructing the strategy.',
  ],
]);

export const ASSET_CLASSES_WITH_VISE_SINGLE_SECURITY_STRATEGY_TO_ETF_FALLBACK_LABEL_MAP = new Map<
  AssetClassKey,
  string
>([
  [
    'EQUITY/US/LARGE_CAP',
    'Based on your client’s account value, risk preference, and asset class preferences, Vise has intelligently defaulted into a Large/Mid Cap ETF instead of our single security strategy for optimal diversification.',
  ],
  [
    'EQUITY/US/SMALL_CAP',
    'Based on your client’s account value, risk preference, and asset class preferences, Vise has intelligently defaulted into a Small Cap ETF instead of our single security strategy for optimal diversification.',
  ],
  [
    'EQUITY/DEVELOPED/LARGE_CAP',
    'Based on your client’s account value, risk preference, and asset class preferences, Vise has intelligently defaulted into a Large/Mid Cap ETF instead of our Developed ex U.S. single security stategy.',
  ],
]);

export const DEVELOPED_ASSET_CLASS_KEYS = [
  'EQUITY/DEVELOPED/LARGE_CAP',
  'EQUITY/DEVELOPED/SMALL_CAP',
  'EQUITY/DEVELOPED',
] as AssetClassKey[];

export const ULTRA_SHORT_ASSET_CLASS_KEYS = [
  'FIXED_INCOME/DOMESTIC/TREASURY/THREE_MONTH',
  'FIXED_INCOME/DOMESTIC/TREASURY/TWELVE_MONTH',
] as AssetClassKey[];

export const PROPOSAL_SOURCE_MAP = {
  0: 'UNKNOWN',
  1: 'SHADOW_MODE',
  2: 'PCE1_MIGRATION',
  3: 'ADVISOR',
  4: 'STRATEGY_CENTER',
} as { [key: number]: ProposalSource };

export const SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS = [
  'EQUITY/US/LARGE_CAP',
  'EQUITY/US/SMALL_CAP',
  'EQUITY/DEVELOPED/LARGE_CAP',
] as AssetClassKey[];

export const NUMBER_OF_SINGLE_SECURITY_ASSET_CLASSES =
  SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.length;

export const ACTIVE_TILT_TYPE_TO_LABEL = {
  dividend: 'Dividends',
  'multi-factor': 'Multi-factor',
  'low-volatility': 'Low Volatility',
  'low-volatility-quality-yield': 'Defensive Dividend',
  quality: 'Quality',
  value: 'Value',
  momentum: 'Momentum',
  'quality-value-momentum': 'Quality Value Momentum (QVM)',
  'income-quality-value-momentum': 'Income Focused QVM',
} as { [key in TiltType]: string };

export const SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL = {
  'EQUITY/US/LARGE_CAP': 'U.S. Large cap',
  'EQUITY/GLOBAL_EX_US': 'Global ex U.S.',
  'FIXED_INCOME/DOMESTIC': 'U.S. Broad Market',
  CASH: 'Cash',
} as { [key in AssetClassKey | 'CASH']: string };

export const SINGLE_SECURITY_THRESHOLD = 10000;

export const ESG_GROUPS = {
  Beliefs: [
    ['Abortion', 'AB'],
    ['Adult Entertainment', 'AE'],
    ['Contraceptives', 'CO'],
    ['Gambling', 'GB'],
    ['Pork Products', 'PP'],
    ['Stem Cell', 'SC'],
  ],
  Health: [
    ['Alcohol', 'AL'],
    ['Cannabis', 'CB'],
    ['Tobacco', 'TP'],
  ],
  Environment: [
    ['GMOs', 'GM'],
    ['Palm Oil', 'PM'],
    ['Pesticides', 'PE'],
  ],
  Energy: [
    ['Oil Sands', 'OS'],
    ['Arctic Oil & Gas', 'AC'],
    ['Shale Energy', 'SE'],
    ['Thermal Coal', 'TC'],
    ['Oil & Gas', 'OG'],
    ['Nuclear Power', 'NP'],
  ],
  'Business Practices': [
    ['Animal Testing', 'AT'],
    ['Fur and Specialty Leather', 'FL'],
    ['Predatory Lending', 'PL'],
    ['Private Prisons', 'PS'],
    ['Whale Meat', 'WM'],
  ],
  'Military, Defense & Firearms': [
    ['Controversial Weapons', 'CW'],
    ['Civilian Firearms / Small Arms', 'FA'],
    ['Military Contracting', 'MC'],
    ['Riot Control', 'RC'],
  ],
} as { [key in string]: string[][] };

export const ESG_LABEL_MAP = {};
Object.keys(ESG_GROUPS).forEach((key) => {
  ESG_GROUPS[key].forEach((esgArea) => {
    ESG_LABEL_MAP[esgArea[1]] = {
      label: esgArea[0],
      group: key,
    };
  });
});

export const SECURITY_TYPE_TO_LABEL = {
  UNKNOWN: 'Unrecognized',
  EQUITY: 'Equities',
  FIXED_INCOME: 'Fixed Income',
  OPTION: 'Option',
  CASH_OR_EQIV: 'Cash',
  MUTUAL_FUND: 'Mutual Fund',
} as { [key in SecurityType]: string };

export const ASSET_TYPE_TO_LABEL = {
  UNKNOWN: 'Unrecognized',
  EQUITY: 'Equities',
  ALTERNATIVES: 'Alternatives',
  COMMODITIES: 'Commodities',
  CASH: 'Cash',
  MONEY_MARKET: 'Money market fund',
  BOND: 'Bond',
  MIXED_ASSETS: 'Mixed assets',
  REAL_ESTATE: 'Real estate',
} as { [key in AssetType]: string };

export const PERFORMANCE_EVENTS: PerformanceEvent[] = [
  {
    periodName: '2008 Global Financial Crisis',
    startPeriod: new Date('2007-10-09 00:00'),
    endPeriod: new Date('2009-03-09 00:00'),
    description:
      'A downturn in the housing market led to the most significant financial crisis since the Great Depression.',
    type: 'bear',
  },
  {
    periodName: 'GFC Recovery',
    startPeriod: new Date('4/1/2009'),
    endPeriod: new Date('5/1/2011'),
    description:
      'Following the global financial crisis, the U.S economy saw a period of close to zero interest rates which helped revive the economy and encouraged consumer spending.',
    type: 'bull',
  },
  {
    periodName: '2011 Debt Ceiling Crisis',
    startPeriod: new Date('2011-06-01 00:00'),
    endPeriod: new Date('2011-10-03 00:00'),
    description:
      'The U.S. reached a crisis point of near default on public debt. The delay in raising the debt ceiling resulted in the first downgrade in the United States credit rating.',
    type: 'bear',
  },
  {
    periodName: 'Lower for Longer',
    startPeriod: new Date('1/8/2016'),
    endPeriod: new Date('2/5/2018'),
    description:
      'As the Fed kept rates lower for longer, the U.S. economy witnessed continued economic growth and growth-oriented stocks, particularly in technology, led the market up over a multi-year period.',
    type: 'bull',
  },
  {
    periodName: '2018 China-U.S. Trade War',
    startPeriod: new Date('2018-09-20 00:00'),
    endPeriod: new Date('2018-12-24 00:00'),
    description:
      'Investors feared tightening monetary policy, a slowing economy, and an intensifying trade war between the U.S. and China. It marked the worst December for stocks since 1931.',
    type: 'bear',
  },
  {
    periodName: 'COVID Crash',
    startPeriod: new Date('2020-02-19 00:00'),
    endPeriod: new Date('2020-03-23 00:00'),
    description:
      'Stock markets worldwide suddenly crashed due to growing instability and fear as the COVID-19 pandemic spread worldwide.',
    type: 'bear',
  },
  {
    periodName: 'The Pandemic Rally',
    startPeriod: new Date('4/1/2020'),
    endPeriod: new Date('12/31/2021'),
    description:
      'The U.S. Government pumped a record breaking $5 trillion dollars into the economy to combat fears of a depression following Covid-19 lockdowns, triggering a boom in stocks.',
    type: 'bull',
  },
  {
    periodName: '2022 Bear Market',
    startPeriod: new Date('2022-01-03 00:00'),
    endPeriod: new Date('2022-10-12 00:00'),
    description:
      'The invasion of Ukraine, coupled with near double digit inflation and tightening monetary policy led to widespread declines in equities and fixed income markets across the world.',
    type: 'bear',
  },
  {
    periodName: 'Post-Pandemic Recovery',
    startPeriod: new Date('11/1/2022'),
    endPeriod: new Date('9/30/2023'),
    description:
      'The U.S. markets continued to rally Post-Pandemic as a result of record household savings rates during the lockdowns and loose monetary policy.',
    type: 'bull',
  },
];

export const SMALL_ACCOUNT_MIGRATION_STARTING_POINT = 6.2;
