import useSWR from 'swr';
import { checkGPTJob } from '~/api/gptApi';
import generateSwrKey from '../api/generateSwrKey';

export default function useGPTJob(jobId: string | null, onSuccess: (data) => void) {
  const { data, error, isValidating, mutate } = useSWR(
    jobId == null ? null : generateSwrKey(jobId),
    () => checkGPTJob(jobId),
    {
      refreshInterval: 1000,
      onSuccess,
    }
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
}
