import useSWR from 'swr';
import { getHouseholdClients } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getHouseholdClientsSwrKey = (householdId: string) =>
  generateSwrKey(`householdClients/${householdId}`);

const useHouseholdClients = (householdId: string | undefined | null) => {
  const { data, error, isValidating, mutate } = useSWR(
    householdId ? getHouseholdClientsSwrKey(householdId) : null,
    householdId == null ? undefined : () => getHouseholdClients(householdId)
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export default useHouseholdClients;
