import { Box, Typography, Drawer, IconButton, Divider } from '@mui/material';
import React from 'react';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import { ExplainerText } from '../components';

const InformationPanelSection = ({
  main,
  description,
}: {
  main: string;
  description: React.ReactNode;
}) => (
  <Box mt={3}>
    <Typography variant="h4">{main}</Typography>
    <ExplainerText mt={3}>{description}</ExplainerText>
  </Box>
);

export default function InformationPanel({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={500} height="100vh">
        <Box
          display="flex"
          width={500}
          justifyContent="space-between"
          px={3}
          py={2}
          alignItems="center"
          position="fixed"
          height={72}
          boxShadow={8}
          bgcolor="white"
        >
          <Typography variant="h2">Choosing restrictions</Typography>
          <IconButton aria-label="close restrictions details drawer" onClick={onClose} size="large">
            <XIcon fontSize={24} />
          </IconButton>
        </Box>
        <Box m={3} pt={8.75} pb={3}>
          <InformationPanelSection
            main="Employment"
            description="If your client works in a highly regulated industry, they may be restricted from investing in specific companies or sectors. In addition, some investors prefer to eliminate additional exposure to the company or sector in which they (or their spouse, if applicable) work."
          />
          <Box my={3}>
            <Divider />
          </Box>
          <InformationPanelSection
            main="International Exposure"
            description="Some clients may wish to limit exposure to certain countries or currencies."
          />
          <Box my={3}>
            <Divider />
          </Box>
          <InformationPanelSection
            main="Values"
            description="Many investors wish to align their investment portfolios with their personal beliefs. For example, an environmentally-conscious investor may desire to exclude companies that derive revenue from artic oil and gas exploration, engage in animal testing, or produce pesticides."
          />
          <InformationPanelSection
            main=""
            description="Using Vise's values-based restrictions, you may exclude companies that are directly involved in specific activities to meet your client's ethical, impact, or compliance needs. "
          />
        </Box>
      </Box>
    </Drawer>
  );
}
