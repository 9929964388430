import React, { useMemo } from 'react';
import { mapValues } from 'lodash';
import { useIndexedInstruments } from '~/hooks/useInstruments';
import { useIndexedSectors } from '~/hooks/useSectors';
import { fillInAssetAllocation } from '~/routes/PortfolioCreator2/useDraftPortfolioReducer';
import useTemplatesByParentIds from '~/hooks/templates/useTemplatesByParentIds';
import { Box, Typography } from '@mui/material';
import { isValid } from 'date-fns';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import AllocationTemplateCard from '../workflows/AllocationTemplateCard';
import { isUpdated } from '../workflows/AllocationTemplateCreate';
import UpdatedTitle from '../workflows/UpdatedTitle';
import RestrictionsTemplateCard from '../workflows/RestrictionsTemplateCard';
import { compareStringArrays } from '../workflows/RestrictionTemplateCreate';

export default function EditContent({
  originalTemplateId,
  newTemplateId,
}: {
  originalTemplateId?: string;
  newTemplateId: string;
}) {
  const { data: templates } = useTemplatesByParentIds({
    ids: [originalTemplateId || '', newTemplateId],
    includeStale: true,
    includeDeleted: true,
  });

  const { data: featureFlags } = useFeatureFlags();
  const gicsCutoffDate = useMemo(
    () => new Date(featureFlags?.gics_frontend_cutoff_date || 'Invalid'),
    [featureFlags?.gics_frontend_cutoff_date]
  );

  const newTemplate = useMemo(
    () => templates?.find((t) => t.parentId === newTemplateId),
    [newTemplateId, templates]
  );

  const templateCreatedBeforeCutoffDate =
    isValid(gicsCutoffDate) && newTemplate && new Date(newTemplate.createdAt) < gicsCutoffDate;

  const originalTemplate = useMemo(
    () => templates?.find((t) => t.parentId === originalTemplateId),
    [originalTemplateId, templates]
  );

  const originalAllocationOutOf100 =
    originalTemplate?.type === 'allocations'
      ? fillInAssetAllocation(
          mapValues(originalTemplate.allocations, (alloc) => (alloc || 0) * 100)
        )
      : {};

  const newTemplateAllocationOutOf100 =
    newTemplate?.type === 'allocations'
      ? fillInAssetAllocation(mapValues(newTemplate.allocations, (alloc) => (alloc || 0) * 100))
      : {};

  const { data: instruments } = useIndexedInstruments();
  const { data: sectorInformation } = useIndexedSectors(templateCreatedBeforeCutoffDate);

  if (!newTemplate) {
    return (
      <Box padding={2}>
        <Typography variant="body2">Error loading data</Typography>
      </Box>
    );
  }

  if (originalTemplate && originalTemplate.type !== newTemplate.type) {
    return (
      <Box padding={2}>
        <Typography variant="body2">Error loading data: Template type is inconsistent</Typography>
      </Box>
    );
  }

  return (
    <>
      {newTemplate.type === 'allocations' &&
        (!originalTemplate || originalTemplate?.type === 'allocations') && (
          <AllocationTemplateCard
            maxWidth="1280px"
            equitiesTitle={
              !originalTemplate ||
              !isUpdated(newTemplateAllocationOutOf100, originalAllocationOutOf100, 'EQUITY') ? (
                'Equities'
              ) : (
                <UpdatedTitle title="Equities" />
              )
            }
            fixedIncomeTitle={
              !originalTemplate ||
              !isUpdated(
                newTemplateAllocationOutOf100,
                originalAllocationOutOf100,
                'FIXED_INCOME'
              ) ? (
                'Fixed income'
              ) : (
                <UpdatedTitle title="Fixed income" />
              )
            }
            alternativesTitle={
              !originalTemplate ||
              !isUpdated(
                newTemplateAllocationOutOf100,
                originalAllocationOutOf100,
                'ALTERNATIVES'
              ) ? (
                'Alternatives'
              ) : (
                <UpdatedTitle title="Alternatives" />
              )
            }
            allocations={newTemplateAllocationOutOf100}
            originalAllocations={
              Object.keys(originalAllocationOutOf100).length !== 0
                ? originalAllocationOutOf100
                : undefined
            }
            tiltSelection={newTemplate.tiltSelection}
          />
        )}

      {newTemplate.type === 'restrictions' &&
        (!originalTemplate || originalTemplate?.type === 'restrictions') && (
          <RestrictionsTemplateCard
            maxWidth="1280px"
            tickersTitle={
              !originalTemplate ||
              compareStringArrays([...originalTemplate?.tickers], [...newTemplate.tickers]) ? (
                'Tickers'
              ) : (
                <UpdatedTitle title="Tickers" />
              )
            }
            tickers={newTemplate.tickers}
            instruments={instruments}
            sectorsTitle={
              !originalTemplate ||
              compareStringArrays(
                [...originalTemplate?.subSectors, ...originalTemplate?.sectors],
                [...newTemplate.subSectors, ...newTemplate.sectors]
              ) ? (
                'Sectors'
              ) : (
                <UpdatedTitle title="Sectors" />
              )
            }
            sectors={newTemplate.sectors}
            industries={newTemplate.subSectors}
            sectorInformation={sectorInformation}
            countriesTitle={
              !originalTemplate ||
              compareStringArrays([...originalTemplate?.countries], [...newTemplate.countries]) ? (
                'Countries'
              ) : (
                <UpdatedTitle title="Countries" />
              )
            }
            countries={newTemplate.countries}
            esgTitle={
              !originalTemplate ||
              compareStringArrays([...originalTemplate?.esgAreas], [...newTemplate.esgAreas]) ? (
                'Values'
              ) : (
                <UpdatedTitle title="Values" />
              )
            }
            esgAreas={newTemplate.esgAreas}
          />
        )}
    </>
  );
}
