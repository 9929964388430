import {
  Box,
  BoxProps,
  Button,
  Container,
  Divider,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGridPro, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { keyBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { DistributionSchedule, DistributionStatus } from 'vise-types/distribution';
import { Account, AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import { updateAccount } from '~/api/api';
import AutoRebalancer from '~/components/modal/AutoRebalancer';
import RebalancerStatusButton from '~/components/portfolio/RebalancerStatusButton';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useEnqueueToast from '~/hooks/useToast';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { ReactComponent as PlusIcon } from '~/static/images/icons/plus.svg';
import Banner from '~/synth/Banner';
import { DataTableContainer } from '~/synth/DataTable';
import Select from '~/synth/inputs/Select';
import amplitude from '~/utils/amplitude';
import { formatCurrency, getCustodianDisplayName, maskAccountNumber } from '~/utils/format';
import useIsModelDelivery from '~/routes/Distributions/useIsModelDelivery';
import { tokens } from '@vise_inc/ds-vise';
import { ReactComponent as ArrowBackIcon } from '~/static/images/icons/arrow-left.svg';
import UntradeableErrorMessage from '../Portfolio/UntradeableErrorMessage';
import { DISTRIBUTION_TABLE_TYPE, STATUS_TO_LABEL } from './Constants';
import DistributionDetailsPanel, {
  DistributionDetailsPanelProps,
} from './DistributionDetailsPanel';
import {
  DistributionsErrorBanner,
  DistributionsWarningBanner,
} from './components/DistributionsBanners';
import { Circle } from './components/UtilComponents';
import CancelDistributionModal, {
  CancelDistributionModalProps,
} from './modals/CancelDistributionModal';

const DISTRIBUTION_DETAILS_PANEL_CLOSED_SETTINGS: Pick<
  DistributionDetailsPanelProps,
  'open' | 'distribution'
> = { open: false, distribution: undefined };

const CANCEL_MODAL_CLOSED_SETTINGS: Pick<CancelDistributionModalProps, 'open' | 'distribution'> = {
  open: false,
  distribution: undefined,
};

function CashMetrics({
  text,
  tooltip,
  amount,
  color,
  ...boxProps
}: {
  text: string;
  tooltip?: string;
  amount: number | undefined;
  color?: string;
} & BoxProps) {
  return (
    <Box display="flex" {...boxProps}>
      {color && amount != null && amount > 0 && (
        <Circle bgcolor={color} diameter={6} mt={0.75} mr={1} />
      )}
      <Box>
        <Box display="flex">
          <Typography variant="h5" color="textSecondary">
            {text}
          </Typography>
          {tooltip !== undefined ? (
            <Tooltip placement="top" title={tooltip}>
              <Box color="grey.500" ml={0.5} style={{ verticalAlign: 'middle' }}>
                <Typography color="textSecondary" display="inline">
                  <InformationCircleIcon />
                </Typography>
              </Box>
            </Tooltip>
          ) : null}
        </Box>
        <Box mt={1}>{formatCurrency(amount)}</Box>
      </Box>
    </Box>
  );
}

interface StatusOption {
  label: string;
  value: DistributionStatus;
}

interface TypeOption {
  label: string;
  value: 'ONE_TIME' | 'RECURRING';
}
export interface DistributionsProps {
  distributionSchedule: DistributionSchedule[];
  allAccountInfo?: AccountWithPIAndHouseholdInfo[];
  cashToWithdraw?: number;
  account?: Account;
  columns: GridColDef[];
  mutateDistributionSchedule: () => {};
  showTableOnly?: boolean;
}

export default function Distributions({
  distributionSchedule,
  allAccountInfo,
  cashToWithdraw,
  account,
  columns,
  mutateDistributionSchedule,
  showTableOnly,
}: DistributionsProps) {
  useEffect(() => {
    amplitude().logEvent(
      `Impression - ${account != null ? 'Account' : 'Global'} Distributions page`,
      {
        category: EVENT_CATEGORIES.DISTRIBUTIONS,
      }
    );
  }, [account]);

  const theme = useTheme();

  const enqueueToast = useEnqueueToast();

  const isModelDelivery = useIsModelDelivery();

  const [autoRebalancerModalOpen, setAutoRebalancerModalOpen] = useState(false);

  const [statusFilter, setStatusFilter] = useState<StatusOption>();
  const statusOptions = useMemo(
    () => [
      { label: STATUS_TO_LABEL.SCHEDULED, value: 'SCHEDULED' },
      { label: STATUS_TO_LABEL.TRADES_INITIATED, value: 'TRADES_INITIATED' },
      { label: STATUS_TO_LABEL.TRADES_EXECUTED, value: 'TRADES_EXECUTED' },
      { label: STATUS_TO_LABEL.CASH_AVAILABLE, value: 'CASH_AVAILABLE' },
    ],
    []
  );

  const [typeFilter, setTypeFilter] = useState<TypeOption>();
  const typeOptions = useMemo(
    () => [
      { label: 'One-time', value: 'ONE_TIME' },
      { label: 'Recurring', value: 'RECURRING' },
    ],
    []
  );

  const [distributionDetailsPanelSettings, setDistributionDetailsPanelSettings] = useState<
    Pick<DistributionDetailsPanelProps, 'open' | 'distribution'>
  >(DISTRIBUTION_DETAILS_PANEL_CLOSED_SETTINGS);

  const [cancelDistributionModalSettings, setCancelDistributionModalSettings] = useState<
    Pick<CancelDistributionModalProps, 'open' | 'distribution'>
  >(CANCEL_MODAL_CLOSED_SETTINGS);

  let schedulesWithAccountInfo = distributionSchedule;
  const idToAccount = allAccountInfo ? keyBy(allAccountInfo, 'id') : null;

  if (idToAccount != null) {
    schedulesWithAccountInfo = distributionSchedule
      .filter(
        (schedule) =>
          schedule.accountId != null &&
          (idToAccount[schedule.accountId]?.intelligence?.pceVersion === 'pce2' ||
            idToAccount[schedule.accountId]?.engineType === 'MODEL_DELIVERY')
      )
      .map((schedule) => {
        const { accountId } = schedule;
        if (accountId != null) {
          return {
            ...schedule,
            accountName: idToAccount[accountId]?.accountName,
            accountNumber: idToAccount[accountId]?.accountNumber,
            custodianCode: idToAccount[accountId]?.custodianKey,
            rebalancerStatus: idToAccount[accountId]?.rebalancerStatus,
          };
        }
        return schedule;
      });
  }

  const hasPausedRebalancer = schedulesWithAccountInfo.some((s) => s.rebalancerStatus === 'PAUSED');

  const hasPausedSchedule = schedulesWithAccountInfo.some(
    (schedule) => schedule.status === 'PAUSED'
  );
  const numLowBalanceSchedules = schedulesWithAccountInfo.filter(
    (schedule) => schedule.lowBalance
  ).length;

  const filteredSchedules = useMemo(() => {
    let filtered = schedulesWithAccountInfo;
    if (statusFilter != null) {
      filtered = filtered.filter((s) => s.status === statusFilter.value);
    }
    if (typeFilter != null) {
      const cadences =
        typeFilter.value === 'ONE_TIME'
          ? new Set(['DO_NOT_REPEAT'])
          : new Set(['MONTHLY', 'QUARTERLY', 'YEARLY']);
      filtered = filtered.filter((s) => cadences.has(s.cadence));
    }

    return filtered;
  }, [schedulesWithAccountInfo, statusFilter, typeFilter]);

  const newDistributionLink =
    account != null
      ? {
          pathname: `/secure/accounts/${account.id}/new-distribution`,
          state: { from: DISTRIBUTION_TABLE_TYPE.ACCOUNT, isModelDelivery },
        }
      : {
          pathname: `/secure/new-distribution`,
          state: { from: DISTRIBUTION_TABLE_TYPE.GLOBAL, isModelDelivery },
        };

  let notFoundMessage = '';
  if (schedulesWithAccountInfo.length === 0) {
    notFoundMessage = 'No distributions are scheduled.';
  } else if (filteredSchedules.length === 0) {
    notFoundMessage = 'No distributions found.';
  }

  async function onAutoRebalancerFormSubmit(active: boolean) {
    amplitude().logEvent('SubmitAutoRebalancer', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
    if (account && account.viseClientId) {
      try {
        await updateAccount(account.id, account.viseClientId, active ? 'ACTIVE' : 'PAUSED');
      } catch (err) {
        enqueueToast({
          content: err instanceof Error ? err.message : '',
          severity: 'error',
          title: 'Error while attempting to pause auto rebalancer',
        });
      }
    }
  }

  const table = (
    <DataGridPro
      getRowId={(row) => row.scheduleId}
      rowHeight={72}
      rows={filteredSchedules}
      columns={columns}
      onRowClick={(row) => {
        amplitude().logEvent('Action - Open distribution panel', {
          category: EVENT_CATEGORIES.DISTRIBUTIONS,
          scheduleId: row.row.scheduleId,
        });
        setDistributionDetailsPanelSettings({
          open: true,
          distribution: row.row,
        });
      }}
      autoHeight
      getRowClassName={({ row }) => {
        return row.status === 'PAUSED' || row.lowBalance ? 'highlight' : '';
      }}
      sx={{
        '.highlight': {
          background: theme.palette.warning[100],
          '&:hover': {
            background: '#fff8d9',
          },
        },
      }}
      slots={{
        noRowsOverlay: () => (
          <Box py={8} display="flex" alignItems="center" flexDirection="column">
            <Typography variant="h3">{notFoundMessage}</Typography>
            <Box mt={1.5}>
              <Button
                startIcon={<PlusIcon />}
                color="primary"
                component={Link}
                to={newDistributionLink}
              >
                Schedule new distribution
              </Button>
            </Box>
          </Box>
        ),
        toolbar: () => (
          <Box mt={2} mb={1} mx={2} display="flex">
            <GridToolbarQuickFilter variant="outlined" placeholder="Search distributions" />
            <>
              <Box ml={2} width={240}>
                <Select
                  isClearable
                  options={statusOptions}
                  placeholder="Status"
                  value={statusFilter}
                  onChange={(selectedStatus) => setStatusFilter(selectedStatus as StatusOption)}
                />
              </Box>
              <Box ml={2} width={240}>
                <Select
                  isClearable
                  options={typeOptions}
                  placeholder="Distribution type"
                  value={typeFilter}
                  onChange={(selectedType) => setTypeFilter(selectedType as TypeOption)}
                />
              </Box>
            </>
          </Box>
        ),
      }}
      pagination
      hideFooter={false}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
    />
  );

  if (showTableOnly) {
    return table;
  }

  return (
    <>
      <Helmet>
        <title>Distributions</title>
      </Helmet>
      {account != null && <UntradeableErrorMessage account={account} distribution />}
      <Box bgcolor="white" height="100%">
        <Box p={4} boxShadow={account != null ? 'none' : '0px 1px 0px #E9E9E9'}>
          <Container>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h1">
                {account != null ? 'Scheduled distributions' : 'All scheduled distributions'}
              </Typography>
              <Box display="flex">
                {account == null ? (
                  <Box mr={4} pr={3} boxShadow="1px 0px 0px #E9E9E9">
                    <Typography variant="h5" color="textSecondary">
                      Scheduled
                    </Typography>
                    <Box mt={1}>{schedulesWithAccountInfo.length}</Box>
                  </Box>
                ) : null}
                <Button
                  variant="contained"
                  startIcon={<PlusIcon />}
                  component={Link}
                  color="primary"
                  to={newDistributionLink}
                  onClick={() =>
                    amplitude().logEvent('Action - Tap New distribution button', {
                      category: EVENT_CATEGORIES.DISTRIBUTIONS,
                      accountId: account != null ? account.id : '',
                    })
                  }
                >
                  New distribution
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
        {account != null ? (
          <Box p={4} boxShadow={theme.shadows[7]}>
            <Container>
              <Box display="flex" justifyContent="space-between">
                <div>
                  <Link
                    to={`/secure/accounts/${account.id}/portfolio`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="h3">{account.accountName}</Typography>
                  </Link>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Box display="inline-block" mr={1.5}>
                      <Typography variant="body1" color="textSecondary">
                        {account.taxable ? 'Non-qualified' : 'Qualified'}
                      </Typography>
                    </Box>
                    <Box bgcolor="grey.300" alignSelf="stretch">
                      <Divider orientation="vertical" flexItem />
                    </Box>
                    <Box ml={1.5}>
                      <Typography variant="body1" color="textSecondary">
                        {maskAccountNumber(account.accountNumber)},{' '}
                        {getCustodianDisplayName(account.custodianKey)}
                      </Typography>
                    </Box>
                    <Box ml={1.5}>
                      <RebalancerStatusButton
                        accountStatus={account.status}
                        onClick={() => setAutoRebalancerModalOpen(true)}
                        rebalancerStatus={account.rebalancerStatus}
                      />
                    </Box>
                  </Box>
                </div>
                <CashMetrics
                  color="#0AB389"
                  text="Available to withdraw (est.)"
                  tooltip="Estimated cash value available for withdrawal based on distributions from the last seven days. This number does not consider cash allocation or cash deposits."
                  amount={cashToWithdraw}
                />
              </Box>
            </Container>
          </Box>
        ) : null}
        <Box p={4}>
          <Container>
            {account != null && account.rebalancerStatus === 'PAUSED' && (
              <Box mb={3}>
                <Banner
                  size="small"
                  message={
                    <>
                      <Box mr={1} color="primary.main" display="flex" alignItems="center">
                        <InformationCircleIcon width={16} height={16} />
                      </Box>
                      The rebalancer is paused for this account and will not raise cash to meet
                      scheduled distribution needs. Immediate distributions will still be funded as
                      necessary.
                    </>
                  }
                />
              </Box>
            )}
            {hasPausedRebalancer ? (
              <Box mb={3}>
                <Banner
                  size="small"
                  message={
                    <>
                      <Box mr={1} color="primary.main" display="flex" alignItems="center">
                        <InformationCircleIcon width={16} height={16} />
                      </Box>
                      Accounts with the rebalancer paused will not raise cash to meet scheduled
                      distribution needs. Immediate distributions will still be funded as necessary.
                    </>
                  }
                />
              </Box>
            ) : null}
            {hasPausedSchedule ? (
              <Box mb={3}>
                <DistributionsErrorBanner />
              </Box>
            ) : null}
            {numLowBalanceSchedules > 0 ? (
              <Box mb={3}>
                <DistributionsWarningBanner numSchedules={numLowBalanceSchedules} />
              </Box>
            ) : null}
            <DataTableContainer>{table}</DataTableContainer>
          </Container>
        </Box>
      </Box>
      <DistributionDetailsPanel
        open={distributionDetailsPanelSettings.open}
        onClose={() =>
          setDistributionDetailsPanelSettings(DISTRIBUTION_DETAILS_PANEL_CLOSED_SETTINGS)
        }
        distribution={distributionDetailsPanelSettings.distribution}
        setCancelDistributionModalSettings={setCancelDistributionModalSettings}
        isGlobalDistribution={account == null}
      />
      <CancelDistributionModal
        open={cancelDistributionModalSettings.open}
        onClose={() => setCancelDistributionModalSettings(CANCEL_MODAL_CLOSED_SETTINGS)}
        distribution={cancelDistributionModalSettings.distribution}
        mutateSchedule={mutateDistributionSchedule}
        setDistributionDetailsPanelSettings={setDistributionDetailsPanelSettings}
      />
      {account != null && (
        <AutoRebalancer
          rebalancerPausedAt={account.rebalancerPausedAt}
          rebalancerStatus={account.rebalancerStatus}
          accountStatus={account.status}
          onClose={() => setAutoRebalancerModalOpen(false)}
          onSubmit={(value: boolean) => {
            onAutoRebalancerFormSubmit(value);
            setAutoRebalancerModalOpen(false);
          }}
          open={autoRebalancerModalOpen}
        />
      )}
      {isModelDelivery && (
        <Box mb={4} position="sticky" bottom={0}>
          <Banner
            fullWidth
            bgColor="white"
            borderColor={tokens.palette.neutralCool[300]}
            message={
              <Button startIcon={<ArrowBackIcon />} href="/md" variant="outlined" color="secondary">
                Back
              </Button>
            }
          />
        </Box>
      )}
    </>
  );
}
