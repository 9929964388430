import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format, isDate } from 'date-fns';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Column } from 'react-table';
import { XrayClient, XrayClientProposal } from 'vise-types/xray';
import useXrayAccounts from '~/hooks/useXrayAccounts';
import { ReactComponent as DotsHorizontal } from '~/static/images/icons/dots-horizontal.svg';
import { ReactComponent as SearchIcon } from '~/static/images/icons/search.svg';
import { ReactComponent as XCircleIcon } from '~/static/images/icons/x-circle.svg';
import { ReactComponent as XrayIcon } from '~/static/images/icons/x-ray.svg';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import ShuttleImg from '~/static/images/stuttle.svg';
import XrayLandingImg from '~/static/images/xray-landing.svg';
import { DataTable, DataTableContainer } from '~/synth/DataTable';
import DialogTitle from '~/synth/DialogTitle';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import Metric from '~/synth/Metric';
import { TextHighlightTag } from '~/synth/Tag';
import TextField from '~/synth/TextField';
import EmptyState from '~/synth/deprecated/EmptyState';
import LoadingTable from '../Households/Households/LoadingTable';

function SuccessDialog(props: DialogProps & { clientName: string }) {
  const { onClose, clientName } = props;
  return (
    <Dialog {...props}>
      <DialogTitle onClose={() => (onClose ? onClose({}, 'backdropClick') : null)} />
      <DialogContent>
        <Typography textAlign="center" mx={2} variant="h2">
          The first step, a success!
        </Typography>
        <Box my={4} display="flex" justifyContent="center">
          <img src={ShuttleImg} width={150} height={150} alt="shuttle" />
        </Box>
        <Typography px={2} mb={1} textAlign="center" color="grey.600">
          Congratulations on starting the portfolio onboarding process for {clientName}, to the Vise
          platform. Our client success team will be reaching out to you within the next 24 hours to
          discuss the next steps.
        </Typography>
        <Typography px={2} mb={2} textAlign="center" color="grey.600">
          {`Please let us know if you have any questions or concerns in the meantime. We're here
          to help and ensure a smooth onboarding experience for you and your clients.`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose && onClose({}, 'backdropClick')} variant="contained">
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ViewXrayDialog(
  props: DialogProps & {
    isOnboarding?: boolean;
    xrayClient?: XrayClient;
  }
) {
  const history = useHistory();
  const [selectedProposal, setSelectedProposal] = useState<XrayClientProposal>();
  const { xrayClient, onClose, isOnboarding } = props;

  const clientName = `${xrayClient?.firstName} ${xrayClient?.lastName}`;

  const handleConfirm = async () => {
    if (!selectedProposal || !xrayClient) return;
    history.push(
      `/secure/product-onboarding/onboard-client/select-client?selectedClient=${encodeURIComponent(
        JSON.stringify(xrayClient)
      )}&selectedXray=${encodeURIComponent(JSON.stringify(selectedProposal))}`
    );
  };

  const columns: GridColDef[] = [
    {
      headerName: 'Date Created',
      field: 'createdAt',
      align: 'left',
      renderCell: (props) => {
        const createdAt = props.value;
        if (!createdAt) return <div>-</div>;
        const date = new Date(createdAt);
        const isValidDate = Boolean(isDate(date) && createdAt);
        if (isValidDate) {
          try {
            const dateFormatted = format(date, 'MM/dd/yyyy');
            const timeFormatted = format(date, 'p');
            return (
              <div>
                <>{dateFormatted}</>
                <Box color="grey.600">
                  <Typography variant="body1">{timeFormatted}</Typography>
                </Box>
              </div>
            );
          } catch (e) {
            return <div>invalid date</div>;
          }
        }
        return <div>-</div>;
      },
      flex: 1,
    },
    {
      headerName: 'Proposal Name',
      field: 'proposalName',
      align: 'left',
      renderCell: ({ value }) => value || '-',
      flex: 1,
    },
    {
      headerName: 'Account Type',
      align: 'left',
      field: 'taxable',
      renderCell: ({ value }) => (value ? 'Non-qualified' : 'Qualified'),
      flex: 1,
    },
    {
      headerName: ' ',
      field: 'proposalId',
      align: 'right',
      renderCell: ({ value }) => (
        <Button color="primary" href={`/secure/proposal/${value}/x-ray`}>
          View proposal
        </Button>
      ),
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <Dialog
      {...props}
      maxWidth="md"
      PaperProps={{
        sx: {
          minHeight: 400,
        },
      }}
    >
      <DialogTitle onClose={() => onClose?.({}, 'backdropClick')}>
        {isOnboarding ? <>Ready to onboard this client to Vise?</> : <>Vise X-Ray&apos;s</>}
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        {isOnboarding ? (
          <>
            <Typography color="grey.600" mb={2}>
              We want to assure you that the client success team is more than happy to partner with
              you and assist with the onboarding of this particular client onto the Vise platform.
              We are committed to making this process as seamless as possible for you.
            </Typography>
            <Typography color="grey.600" mb={2}>
              First, select a portfolio x-ray you’ve created for {clientName} below, then download
              to associated authorization forms for onboarding the account.
            </Typography>
          </>
        ) : (
          <>
            <Typography mb={2}>
              Select a portfolio x-ray you’ve created for {clientName} below.
            </Typography>
          </>
        )}

        <Box height={250}>
          {isOnboarding ? (
            <DataGridPro
              rows={xrayClient?.proposalInfos ?? []}
              columns={columns}
              getRowId={(row) => row.proposalId}
              onRowSelectionModelChange={(change) =>
                setSelectedProposal(
                  xrayClient?.proposalInfos.find((r) => r.proposalId === change?.[0])
                )
              }
              rowSelectionModel={selectedProposal?.proposalId}
              disableRowSelectionOnClick={false}
              disableMultipleRowSelection
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
              }}
            />
          ) : (
            <DataGridPro
              rows={xrayClient?.proposalInfos ?? []}
              columns={columns}
              getRowId={(row) => row.proposalId}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
              }}
            />
          )}
        </Box>
      </DialogContent>

      {isOnboarding && (
        <DialogActions>
          {onClose && (
            <Button
              variant="outlined"
              onClick={() => onClose({}, 'backdropClick')}
              color="secondary"
            >
              Cancel
            </Button>
          )}
          <Button
            disabled={!selectedProposal}
            onClick={handleConfirm}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default function XrayLandingPage() {
  const theme = useTheme();

  const [filterQuery, setFilterQuery] = useState('');

  const [openViewXray, setOpenViewXray] = useState<'onboarding' | 'view' | undefined>();
  const [xrayClient, setXrayClient] = useState<XrayClient | undefined>();

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const history = useHistory();

  const { data: accountsResponse, isValidating, error } = useXrayAccounts();
  const responesErrors = accountsResponse?.data.errors;
  const isError = Boolean(error || responesErrors);
  const isLoading = isValidating || (accountsResponse?.data.data === undefined && !isError);

  const createXray = useCallback(() => history.push('/secure/portfolio-creator-next'), [history]);

  const filteredTableData = useMemo(
    () =>
      accountsResponse?.data.data?.length
        ? matchSorter(accountsResponse.data.data, filterQuery, {
            keys: ['firstName', 'lastName', 'householdName'],
            threshold: matchSorter.rankings.CONTAINS,
          })
        : [],
    [accountsResponse?.data.data, filterQuery]
  ) as XrayClient[];

  const xrayTableColumns = useMemo(
    () =>
      [
        {
          Header: 'Name',
          width: '20%',
          accessor: (row) => `${row.firstName} ${row.lastName}`,
        },
        {
          Header: 'Household',
          accessor: 'householdName',
          width: '20%',
        },
        {
          Header: 'Status',
          width: '20%',
          accessor: (row) => row.proposalInfos.length > 0,
          Cell: ({ value }) => (
            <TextHighlightTag severity={value ? 'success' : 'warning'}>
              {value ? 'Ready to onboard' : 'Incomplete'}
            </TextHighlightTag>
          ),
        },
        {
          Header: 'Proposals',
          accessor: 'proposalInfos.length',
          width: '20%',
          Cell: ({ value }) => value ?? '-',
        },
        {
          Header: ' ',
          width: '20%',
          align: 'right',
          accessor: 'clientId',
          disableSortBy: true,
          Cell: ({ row, value }) => (
            <Box textAlign="right">
              <DropdownButtonMenu isIcon buttonContent={<DotsHorizontal />}>
                {(closeMenu) => [
                  <DropdownButtonMenuItem
                    key="1"
                    onClick={() => history.push(`/secure/portfolio-creator-next?clientId=${value}`)}
                  >
                    Create proposal
                  </DropdownButtonMenuItem>,
                  <DropdownButtonMenuItem
                    key="2"
                    onClick={() => {
                      setOpenViewXray('view');
                      setXrayClient(row.original);
                      closeMenu();
                    }}
                    disabled={!row.original.proposalInfos.length}
                  >
                    View proposal
                  </DropdownButtonMenuItem>,
                  <DropdownButtonMenuItem
                    key="4"
                    onClick={() => {
                      setOpenViewXray('onboarding');
                      setXrayClient(row.original);
                      closeMenu();
                    }}
                    disabled={!row.original.proposalInfos.length}
                  >
                    Onboard client
                  </DropdownButtonMenuItem>,
                ]}
              </DropdownButtonMenu>
            </Box>
          ),
        },
      ] as Column<XrayClient>[],
    [history]
  );

  return (
    <>
      <Helmet>
        <title>Portfolio X-Ray</title>
      </Helmet>
      <Box bgcolor="white" mb={4}>
        <Container>
          <Grid container alignItems="center" py={3} gap={4}>
            <Grid item xs={7}>
              <Typography variant="h1">X-Ray Analysis for Prospective Clients</Typography>
              <Box color="grey.500" mt={1.5}>
                Vise’s new X-Ray Analysis tool provides a detailed analysis and insights on how a
                Vise managed portfolio can benefit you and your clients.
              </Box>
            </Grid>
            <Grid item xs="auto" ml="auto">
              <Metric
                label="Prospective clients"
                metric={accountsResponse?.data.data?.length ?? '-'}
              />
            </Grid>
            <Grid xs="auto">
              <Metric
                label="No. of proposals"
                metric={accountsResponse?.data.data?.reduce(
                  (acc, client) => acc + client.proposalInfos.length,
                  0
                )}
              />
            </Grid>
            <Grid xs="auto">
              <Box display="flex" justifyContent="end">
                <Box sx={{ pl: 2, borderLeft: `1px solid ${theme.palette.grey[300]}` }}>
                  <Button color="primary" variant="contained" onClick={createXray}>
                    Create Proposal
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Box
          display="flex"
          border={1}
          borderRadius={1}
          borderColor={theme.palette.purple[300]}
          mb={4}
          overflow="hidden"
          bgcolor={theme.palette.accents.lavender.light}
        >
          <Box p={2.5}>
            <img src={XrayLandingImg} width={140} height={103} alt="xray logo" />
          </Box>
          <Box display="flex" flexDirection="column" pl={3} pt={4}>
            <Typography mb={2} variant="h3">
              How to use X-Ray Analysis 🎉
            </Typography>
            <Box color="grey.600">
              <Typography>
                Build sample proposals for prospective clients using Vise’s new analysis tool. Get
                started by selecting create proposal on the top right hand side of this page. You
                can view the analysis in the X-ray tab once you create a sample proposal.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box width="100%">
          <DataTableContainer>
            <Box p={3} pb={1} display="flex" justifyContent="space-between" alignItems="start">
              <div>
                <Typography variant="h2">Manage clients</Typography>
                <Box mt={1} color="grey.600">
                  Prospective client details and generated sample proposals will be populated below.
                  Once you create a proposal, use the dots at the end of each section to view
                  proposals and onboard a prospective client to Vise.
                </Box>
              </div>
              <Box width={500} ml={5}>
                <TextField
                  InputProps={{
                    endAdornment: filterQuery ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setFilterQuery('');
                          }}
                          size="small"
                          type="button"
                        >
                          <XIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                    onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
                      if (event.currentTarget === event.target && event.key === 'Escape') {
                        setFilterQuery('');
                      }
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon display="inline-flex" color={theme.palette.grey[500]} />
                      </InputAdornment>
                    ),
                  }}
                  aria-label="Filter clients by name or hosuehold"
                  fullWidth
                  id="client_fileter"
                  name="filter_clients"
                  onChange={(e) => setFilterQuery(e.target.value)}
                  placeholder="Filter by name or household"
                  value={filterQuery}
                />
              </Box>
            </Box>
            {isLoading && <LoadingTable pageSize={10} />}
            {!isLoading &&
              !isError &&
              (accountsResponse?.data.data?.length ? (
                <DataTable
                  sortable
                  columns={xrayTableColumns}
                  tableMaxHeight="32vh"
                  data={filteredTableData}
                />
              ) : (
                <Box py={8}>
                  <EmptyState
                    icon={<XrayIcon />}
                    large
                    title="There are no prospective clients or X-Rays"
                    subtitle="Create X-Ray"
                    subtitleOnClick={createXray}
                  />
                </Box>
              ))}
            {isError && (
              <Box py={8}>
                <EmptyState
                  icon={<XCircleIcon />}
                  large
                  title="We encountered an error when trying to load prospective clients."
                  subtitle="Please reach out to clientservice@vise.com if you have any questions."
                />
              </Box>
            )}
          </DataTableContainer>
        </Box>
      </Container>
      <ViewXrayDialog
        open={openViewXray !== undefined}
        isOnboarding={openViewXray === 'onboarding'}
        onClose={() => {
          setOpenViewXray(undefined);
        }}
        xrayClient={xrayClient}
      />
      <SuccessDialog
        open={successDialogOpen}
        clientName={`${xrayClient?.firstName} ${xrayClient?.lastName}`}
        onClose={() => setSuccessDialogOpen(false)}
      />
    </>
  );
}
