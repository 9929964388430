import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { DistributionStatus } from 'vise-types/distribution';
import { TextHighlightTag } from '~/synth/Tag';
import { STATUS_TO_LABEL } from '../Constants';

export default (props: { status: DistributionStatus }) => {
  let color = '';
  let bgColor = '';
  switch (props.status) {
    case 'CASH_AVAILABLE':
      return (
        <TextHighlightTag severity="success" fontWeight={400}>
          <Tooltip title="Funds are ready to be withdrawn.">
            <Typography variant="body1">Ready</Typography>
          </Tooltip>
        </TextHighlightTag>
      );
    case 'PAUSED':
      bgColor = '#D91F11';
      color = 'white';
      break;
    case 'SCHEDULED':
    case 'TRADES_INITIATED':
      return (
        <TextHighlightTag severity="default" fontWeight={400}>
          <Tooltip title="Rebalancer initiated, pending trades.">
            <Typography variant="body1">In progress</Typography>
          </Tooltip>
        </TextHighlightTag>
      );
    case 'TRADES_EXECUTED':
    default:
      bgColor = '#E9E9E9';
      color = '#4D4D4D';
  }

  return (
    <Box
      borderRadius="20px"
      bgcolor={bgColor}
      display="inline-block"
      py={0.5}
      px={1}
      color={color}
      style={{ verticalAlign: 'bottom' }}
    >
      <Typography variant="body1">{STATUS_TO_LABEL[props.status]}</Typography>
    </Box>
  );
};
