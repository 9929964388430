import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { DataTable, DataTableContainer } from '~/synth/DataTable';
import Skeleton from '~/synth/Skeleton';
import { ReactComponent as PlusIcon } from '~/static/images/icons/plus.svg';
import { RightAlignBox } from '../../Portfolio/components/TableComponents';

export default function DistributionsLoading({
  isGlobalDistribution,
}: {
  isGlobalDistribution: boolean;
}) {
  const theme = useTheme();
  const columns = useMemo(
    () =>
      [
        {
          Header: <Skeleton inline width="10em" />,
          id: 'description',
          Cell: <Skeleton inline width="12em" />,
        },
        {
          Header: <Skeleton inline width="6em" />,
          id: 'amount',
          align: 'right',
          Cell: () => (
            <RightAlignBox>
              <Skeleton inline width="6em" />
            </RightAlignBox>
          ),
        },
        {
          Header: <Skeleton inline width="6em" />,
          id: 'cadence',
          Cell: <Skeleton inline width="12em" />,
        },
        {
          Header: <Skeleton inline width="6em" />,
          id: 'nextRun',
          Cell: <Skeleton inline width="12em" />,
        },
        {
          Header: <Skeleton inline width="6em" />,
          id: 'lastRun',
          Cell: <Skeleton inline width="12em" />,
        },
        {
          Header: <Skeleton inline width="6em" />,
          id: 'status',
          Cell: <Skeleton inline width="12em" />,
        },
      ] as Column<{ [key in string]: string }>[],
    []
  );
  return (
    <Box bgcolor="white" height="100%">
      <Box p={4}>
        <Container>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h1">
              {isGlobalDistribution ? 'All scheduled distributions' : 'Scheduled distributions'}
            </Typography>
            <Box>
              <Button color="primary" variant="contained" startIcon={<PlusIcon />} disabled>
                New distribution
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      {isGlobalDistribution ? (
        <Box boxShadow="0px 1px 0px #E9E9E9" />
      ) : (
        <Box p={4} boxShadow={theme.shadows[7]}>
          <Container>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Skeleton width="12em" height="2em" />
                <Skeleton width="6em" height="2em" />
              </Box>
              <Box>
                <Skeleton width="12em" height="2em" />
                <Skeleton width="6em" height="2em" />
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      <Box p={4}>
        <Container>
          <DataTableContainer>
            <DataTable columns={columns} data={Array(10).fill({})} />
          </DataTableContainer>
        </Container>
      </Box>
    </Box>
  );
}
