import React from 'react';
import { Box, Button, Typography, Chip as ChipBase, Divider } from '@mui/material';
import { ReactComponent as PencilIcon } from '~/static/images/icons/pencil.svg';
import { Instrument } from 'vise-types/pce2_instrument';
import { Dictionary } from 'lodash';
import { Subsector, Sector } from '~/models/api';
import { COUNTRY_TO_LABEL_MAP, ESG_LABEL_MAP } from '~/routes/PortfolioCreator2/Constants';
import Card, { CardTitle } from './Card';

interface Props {
  onEdit?: () => void;
  tickersTitle: React.ReactNode;
  tickers: string[];
  instruments?: Dictionary<Instrument>;
  sectorsTitle: React.ReactNode;
  industries: string[];
  sectors: string[];
  sectorInformation: { [key: string]: Sector | Subsector } | undefined;
  countriesTitle: React.ReactNode;
  countries: string[];
  esgTitle: React.ReactNode;
  esgAreas: string[];
  maxWidth?: string;
}

function Chip({ label, subtitle }: { label: string; subtitle?: string }) {
  return (
    <ChipBase
      label={
        <Box display="flex" alignItems="center" height="100%">
          <Typography variant="body1">{label}</Typography>
          {subtitle && (
            <>
              <Box height="18px" mx={1}>
                <Divider orientation="vertical" />
              </Box>
              <Typography variant="caption" color="textSecondary">
                {subtitle}
              </Typography>
            </>
          )}
        </Box>
      }
    />
  );
}

export function RestrictionsTemplateBody({
  tickersTitle,
  tickers,
  instruments,
  sectorsTitle,
  industries,
  sectors,
  countries,
  countriesTitle,
  esgAreas,
  esgTitle,
  sectorInformation,
}: Pick<
  Props,
  | 'tickersTitle'
  | 'tickers'
  | 'instruments'
  | 'sectorsTitle'
  | 'industries'
  | 'sectors'
  | 'countriesTitle'
  | 'countries'
  | 'esgAreas'
  | 'esgTitle'
  | 'sectorInformation'
>) {
  return (
    <>
      <CardTitle title={tickersTitle} />
      <Box display="flex" flexWrap="wrap" mb={3}>
        {tickers.map((t) => {
          if (!instruments) {
            return null;
          }
          const instrument = instruments[t];
          if (!instrument) {
            return null;
          }
          return (
            <Box mr={1} key={t} mb={1}>
              <Chip label={t} subtitle={instrument.name} />
            </Box>
          );
        })}
      </Box>
      <CardTitle title={sectorsTitle} />
      <Box display="flex" flexWrap="wrap" mb={3}>
        {[...industries, ...sectors].map((s) => {
          if (!sectorInformation) {
            return null;
          }
          const sector = sectorInformation[s];
          if (!sector) {
            return null;
          }
          return (
            <Box mr={1} key={s} mb={1}>
              <Chip
                label={sector.name}
                subtitle={
                  'industries' in sector ? `All ${sector.industries.length} sub-sectors` : undefined
                }
              />
            </Box>
          );
        })}
      </Box>
      <CardTitle title={countriesTitle} />
      <Box display="flex" flexWrap="wrap" mb={3}>
        {countries.map((c) => {
          return (
            <Box mr={1} key={c} mb={1}>
              <Chip label={COUNTRY_TO_LABEL_MAP[c]} />
            </Box>
          );
        })}
      </Box>
      <CardTitle title={esgTitle} />
      <Box display="flex" flexWrap="wrap" mb={3}>
        {esgAreas.map((esg) => {
          return (
            <Box mr={1} key={esg} mb={1}>
              <Chip label={ESG_LABEL_MAP[esg].label} />
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default function RestrictionsTemplateCard({
  onEdit,
  tickersTitle,
  tickers,
  instruments,
  sectorsTitle,
  industries,
  sectors,
  countries,
  countriesTitle,
  esgAreas,
  esgTitle,
  sectorInformation,
  maxWidth,
}: Props) {
  return (
    <Card
      maxWidth={maxWidth}
      header={
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography variant="h3">Restrictions</Typography>
          </Box>
          {onEdit && (
            <Box>
              <Button
                variant="outlined"
                onClick={() => {
                  onEdit();
                }}
                startIcon={<PencilIcon />}
                color="primary"
              >
                Edit
              </Button>
            </Box>
          )}
        </Box>
      }
    >
      <RestrictionsTemplateBody
        tickersTitle={tickersTitle}
        tickers={tickers}
        instruments={instruments}
        sectorsTitle={sectorsTitle}
        industries={industries}
        sectors={sectors}
        sectorInformation={sectorInformation}
        countriesTitle={countriesTitle}
        countries={countries}
        esgTitle={esgTitle}
        esgAreas={esgAreas}
      />
    </Card>
  );
}
