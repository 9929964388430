import { Box, Button, Tab, Tabs, Tooltip } from '@mui/material';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { BondPortfolioSettings, EngineType } from 'vise-types/portfolio';
import { riskToFocus } from '~/routes/Portfolio/portfolioUtil';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { ReactComponent as UserIcon } from '~/static/images/icons/user.svg';
import { DataTableContainer } from '~/synth/DataTable';
import EmptyState from '~/synth/deprecated/EmptyState';
import scrollToTop from '~/utils/scrollToTop';
import { AccountData, ProposalSummary, UntransitionedAccountData } from '../../../models/household';
import PercentChange from '../../../synth/PercentChange';
import { convertToPercent, formatCurrency } from '../../../utils/format';
import LoadingTable from '../Households/LoadingTable';
import { UnavailableCellText } from '../Households/ValueCell';
import renderAccountStatus, { ACCOUNT_STATUS_TO_ORDER } from '../components/renderAccountStatus';
import renderRebalancerStatus from '../components/renderRebalancerStatus';
import { AccountNameCell } from './UtilComponents';

interface AccountsTableProps {
  /** Must be memoized */
  untransitionedAccountsData: UntransitionedAccountData[];
  /** Must be memoized */
  viseAccountsData: AccountData[];
  /** Should be memoized */
  viewProposalsOnClick: (accountData: {
    accountName: string;
    proposals: ProposalSummary[];
    id: string;
    accountNumber: string;
    viseClientId?: string | undefined;
    bondProposals: BondPortfolioSettings[];
    engineType: EngineType;
  }) => void;
  householdId: string;
  showPerformance: boolean;
}

const renderUnavailableCell = () => <UnavailableCellText>Unavailable</UnavailableCellText>;

function ActionCell({
  accountName,
  accountNumber,
  proposals,
  portfolioIntelligenceId,
  viseClientId,
  accountId,
  viewProposalsOnClick,
  engineType,
  bondProposals,
}: {
  accountName: string;
  accountNumber: string;
  proposals: ProposalSummary[];
  portfolioIntelligenceId?: string;
  viseClientId: string;
  accountId: string;
  viewProposalsOnClick: (accountData: {
    accountName: string;
    proposals: ProposalSummary[];
    id: string;
    accountNumber: string;
    viseClientId?: string | undefined;
    bondProposals: BondPortfolioSettings[];
    engineType: EngineType;
  }) => void;
  engineType: EngineType;
  bondProposals: BondPortfolioSettings[];
}) {
  return (
    <Box display="flex" justifyContent="end">
      {proposals.length > 0 || bondProposals.length > 0 ? (
        <Button
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            viewProposalsOnClick({
              proposals,
              id: accountId,
              viseClientId,
              accountNumber,
              accountName,
              bondProposals,
              engineType,
            });
          }}
          size="small"
        >
          View {proposals.length + bondProposals.length}{' '}
          {proposals.length || bondProposals.length > 1 ? 'proposals' : 'proposal'}
        </Button>
      ) : undefined}
      {engineType === 'AB_FIXED_INCOME' ? (
        <Button
          color="primary"
          href={`/secure/bond-portfolio-creator/select-client?accountId=${accountId}`}
          onClick={(event: React.MouseEvent) => {
            // Prevent clicking this link from triggering the `onclick` in the ancestor row
            event.stopPropagation();
          }}
          size="small"
        >
          Create proposal
        </Button>
      ) : (
        <Button
          color="primary"
          href={
            portfolioIntelligenceId != null
              ? `/secure/portfolio-creator-next/summary-landing?pid=${encodeURIComponent(
                  portfolioIntelligenceId
                )}`
              : `/secure/portfolio-creator-next?clientId=${viseClientId}&accountId=${accountId}`
          }
          onClick={(event: React.MouseEvent) => {
            // Prevent clicking this link from triggering the `onclick` in the ancestor row
            event.stopPropagation();
          }}
          size="small"
        >
          {portfolioIntelligenceId != null ? 'Edit portfolio' : 'Create proposal'}
        </Button>
      )}
    </Box>
  );
}

export const AccountsTableLoading = () => (
  <DataTableContainer>
    <Box border={1} borderLeft={0} borderRight={0} borderTop={0} pl={3} borderColor="grey.200">
      <Tabs value="portfolios">
        <Tab value="portfolios" label="Vise Portfolios" disabled />
        <Tab value="untransitioned" label="Ready to transition" disabled />
      </Tabs>
    </Box>
    <LoadingTable pageSize={25} />
  </DataTableContainer>
);

/** Table component */
export default function AccountsTable({
  untransitionedAccountsData,
  viseAccountsData,
  viewProposalsOnClick,
  householdId,
  showPerformance,
}: AccountsTableProps) {
  const history = useHistory();
  const [tabValue, setTabValue] = useState<'portfolios' | 'untransitioned'>('portfolios');

  const viseAccountsColumns: GridColDef[] = [
    {
      field: 'accountName',
      headerName: 'Account name',
      renderCell: (params: GridRenderCellParams<AccountData>) => {
        const { accountName, accountNumber, taxable, custodianKey, engineType } = params.row;
        return (
          <AccountNameCell
            accountName={accountName}
            accountNumber={accountNumber}
            taxable={taxable}
            custodianKey={custodianKey}
            engineType={engineType}
          />
        );
      },
      minWidth: 325,
      sortComparator: (accountNameA, accountNameB, rowA, rowB) => {
        const accountNumberA = rowA.api.getCellValue(rowA.id, 'accountNumber');
        const accountNumberB = rowB.api.getCellValue(rowB.id, 'accountNumber');

        const compareResult = accountNameA.localeCompare(accountNameB);
        if (compareResult !== 0) {
          return compareResult;
        }

        return accountNumberA.localeCompare(accountNumberB);
      },
      flex: 1,
    },
    {
      headerName: 'Account status',
      field: 'status',
      renderCell: (params: GridRenderCellParams<AccountData>) => {
        const { status, statusReason } = params.row;
        return renderAccountStatus({ value: { status, statusReason } });
      },
      sortComparator: (statusA, statusB) => {
        return ACCOUNT_STATUS_TO_ORDER[statusA] > ACCOUNT_STATUS_TO_ORDER[statusB] ? 1 : -1;
      },
      flex: 1,
    },
    {
      headerName: 'Rebalancer status',
      field: 'rebalancerDisplayStatus',
      valueGetter: ({ row }) => row.rebalancerDisplayStatus.status,
      renderCell: (params: GridRenderCellParams<AccountData>) => {
        return renderRebalancerStatus({
          value: params.row.rebalancerDisplayStatus,
          engineType: params.row.engineType,
        });
      },
      sortComparator: (displayStatus) => (displayStatus.status === 'ACTIVE' ? 1 : -1),
      flex: 1,
    },
    ...(showPerformance
      ? [
          {
            renderCell: (props) => {
              if (props.values == null) {
                return renderUnavailableCell();
              }
              return <PercentChange value={convertToPercent(props.value)} />;
            },
            renderHeader: () => {
              return (
                <Box alignItems="center" display="flex">
                  ITD
                  <Tooltip
                    title="Time-weighted return since the inception of the household."
                    data-testid="itd-tooltip"
                  >
                    <Box color="grey.400" display="flex" ml={0.5} tabIndex={0}>
                      <InformationCircleIcon />
                    </Box>
                  </Tooltip>
                </Box>
              );
            },
            valueGetter: ({ row }) => row.bdPerformance?.itdRorFraction,
            field: 'performance',
            flex: 1,
          },
        ]
      : [
          {
            headerName: 'Focus',
            field: 'riskScore',
            renderCell: (props) =>
              props.value ? riskToFocus(props.value)[0] : renderUnavailableCell(),
            flex: 1,
          },
        ]),
    {
      headerName: 'Account value',
      field: 'accountValue',
      renderCell: (props) =>
        props.value != null ? formatCurrency(props.value) : renderUnavailableCell(),
      flex: 1,
    },
    {
      headerName: '',
      field: 'proposals',
      renderCell: (params) => {
        const {
          accountName,
          accountNumber,
          id,
          viseClientId,
          proposals,
          portfolioIntelligenceId,
          engineType,
          bondProposals,
        } = params.row;
        return (
          <ActionCell
            viewProposalsOnClick={viewProposalsOnClick}
            accountName={accountName}
            accountNumber={accountNumber}
            accountId={id}
            viseClientId={viseClientId}
            proposals={proposals}
            portfolioIntelligenceId={portfolioIntelligenceId}
            engineType={engineType}
            bondProposals={bondProposals}
          />
        );
      },
      sortable: false,
      flex: 1,
      minWidth: 300,
    },
  ];

  const untransitionedAccountsColumns: GridColDef[] = [
    {
      field: 'accountName',
      headerName: 'Account name',
      renderCell: (params: GridRenderCellParams<UntransitionedAccountData>) => {
        const { accountName, accountNumber, taxable, custodianKey, engineType } = params.row;
        return (
          <AccountNameCell
            accountName={accountName}
            accountNumber={accountNumber}
            taxable={taxable}
            custodianKey={custodianKey}
            engineType={engineType}
          />
        );
      },
      minWidth: 300,
      sortComparator: (accountNameA, accountNameB, rowA, rowB) => {
        const accountNumberA = rowA.api.getCellValue(rowA.id, 'accountNumber');
        const accountNumberB = rowB.api.getCellValue(rowB.id, 'accountNumber');

        const compareResult = accountNameA.localeCompare(accountNameB);
        if (compareResult !== 0) {
          return compareResult;
        }

        return accountNumberA.localeCompare(accountNumberB);
      },
      flex: 1,
    },
    {
      field: 'proposals',
      headerName: '',
      renderCell: (params) => {
        const {
          accountName,
          accountNumber,
          id,
          viseClientId,
          proposals,
          portfolioIntelligenceId,
          engineType,
          bondProposals,
        } = params.row;
        return (
          <ActionCell
            viewProposalsOnClick={viewProposalsOnClick}
            accountName={accountName}
            accountNumber={accountNumber}
            accountId={id}
            viseClientId={viseClientId}
            proposals={proposals}
            portfolioIntelligenceId={portfolioIntelligenceId}
            engineType={engineType}
            bondProposals={bondProposals}
          />
        );
      },
      sortable: false,
      align: 'right',
      flex: 1,
    },
  ];

  return (
    <DataTableContainer>
      <Box pl={3} border={1} borderLeft={0} borderRight={0} borderTop={0} borderColor="grey.200">
        <Tabs value={tabValue} onChange={(_event, newValue) => setTabValue(newValue)}>
          <Tab value="portfolios" label="Vise Portfolios" />
          <Tab value="untransitioned" label="Ready to transition" />
        </Tabs>
      </Box>
      <Box hidden={tabValue !== 'portfolios'}>
        {!viseAccountsData.length ? (
          <Box py={8}>
            <EmptyState
              icon={<UserIcon />}
              large
              title="No Vise Portfolios"
              subtitle="Please execute a proposal on an account to transition it to a Vise Portfolio."
            />
          </Box>
        ) : (
          <DataGridPro
            rows={viseAccountsData}
            columns={viseAccountsColumns}
            rowHeight={72}
            onRowClick={({ row }) => {
              history.push(
                `/secure/accounts/${row.id}/${
                  ['AB_FIXED_INCOME'].indexOf(row.engineType) >= 0 ? 'bond-' : ''
                }portfolio/overview`
              );
              scrollToTop();
            }}
            sx={{
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
            }}
          />
        )}
      </Box>
      <Box hidden={tabValue !== 'untransitioned'}>
        {!untransitionedAccountsData.length ? (
          <Box py={8}>
            <EmptyState
              large
              title="There are no accounts set up for this household"
              subtitle="Add an account"
              subtitleOnClick={() => history.push(`/secure/households/${householdId}/manage`)}
            />
          </Box>
        ) : (
          <DataGridPro
            rows={untransitionedAccountsData}
            columns={untransitionedAccountsColumns}
            rowHeight={72}
          />
        )}
      </Box>
    </DataTableContainer>
  );
}
