import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { ReactComponent as CheckIcon } from '~/static/images/icons/check.svg';
import { ReactComponent as DotsHorizontal } from '~/static/images/icons/dots-horizontal.svg';
import { ReactComponent as ExclamationCircleIcon } from '~/static/images/icons/exclamation-circle.svg';
import DialogTitle from '~/synth/DialogTitle';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import Skeleton from '~/synth/Skeleton';
import { TextHighlightTag } from '~/synth/Tag';
import TextField from '~/synth/TextField';
import amplitude from '~/utils/amplitude';
import {
  LAYOUT_L_PADDING_EXACT_SM,
  LAYOUT_L_PADDING_SPACING_XS,
  LAYOUT_R_PADDING_SPACING,
} from '../Constants';

const PaddedContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(LAYOUT_L_PADDING_SPACING_XS),
  paddingRight: theme.spacing(LAYOUT_R_PADDING_SPACING),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: LAYOUT_L_PADDING_EXACT_SM,
  },
}));

const BalancedPaddedContainer = styled(PaddedContainer)(({ theme }) => ({
  paddingRight: theme.spacing(LAYOUT_L_PADDING_SPACING_XS),
  [theme.breakpoints.up('sm')]: {
    paddingRight: LAYOUT_L_PADDING_EXACT_SM,
  },
  paddingBottom: theme.spacing(6),
}));

function TemplateSkeletons() {
  return (
    <>
      {Array(6)
        .fill(0)
        .map((n, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid key={idx} item lg={4} xs={12}>
            <Skeleton variant="rect" height="400px" width="100%" />
          </Grid>
        ))}
    </>
  );
}

const useStyles = makeStyles()((theme) => ({
  disabled: {
    opacity: 0.5,
  },
  root: {
    height: '100%',
  },
  selected: {
    borderColor: theme.palette.grey[800],
  },
}));

function NewTemplateNameField({
  value,
  error,
  success,
  onChange,
  variant,
}: {
  value: string;
  error: boolean;
  success: boolean;
  onChange: (value: string) => void;
  variant: 'restrictions' | 'allocation';
}) {
  let nameInputHelperText: string | undefined;
  if (error) {
    nameInputHelperText = 'Template name already taken';
  } else if (success) {
    nameInputHelperText = 'Template name available';
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value);
  }

  return (
    <TextField
      fullWidth
      success={success}
      error={error}
      placeholder={variant === 'restrictions' ? 'Ex. Environmental ESG' : 'Ex. 70/30 Aggressive'}
      helperText={nameInputHelperText}
      name={`${variant}-template-name`}
      required
      onChange={handleChangeName}
      value={value}
    />
  );
}

export function DeleteTemplateModal({
  deleteTemplate,
  open,
  templateId,
  templateName,
  onClose,
  onTemplateDeleted,
  variant,
  eventCategory,
}: {
  deleteTemplate: (id: string, name: string) => Promise<boolean>;
  open: boolean;
  templateId: string;
  templateName: string;
  onClose: () => void;
  onTemplateDeleted: () => void;
  variant: 'restrictions' | 'allocation';
  eventCategory: string;
}) {
  const theme = useTheme();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  async function handleDeleteTemplate() {
    amplitude().logEvent(`Delete ${variant} template`, {
      category: eventCategory,
      templateId,
    });
    setIsDeleting(true);
    const success = await deleteTemplate(templateId, templateName);
    setIsDeleting(false);
    if (success) {
      onClose();
      onTemplateDeleted();
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          <ExclamationCircleIcon color={theme.palette.error.main} />
          <Box ml={1}>Delete template</Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        This template will be permanently removed from your Vise account. However, proposals and
        active portfolios linked to this template will not be affected.
        <Box my={2}>
          Are you sure you want to delete <b>{templateName}</b>?
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isDeleting} variant="outlined" onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          disabled={isDeleting}
          variant="outlined"
          color="error"
          onClick={handleDeleteTemplate}
        >
          Delete template
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function RenameTemplateModal({
  open,
  templateId,
  onClose,
  onTemplateRenamed,
  renameTemplate,
  isNameValid,
  isTemplateNameTaken,
  variant,
}: {
  open: boolean;
  templateId: string;
  onClose: () => void;
  onTemplateRenamed: () => void;
  renameTemplate: (id: string, name: string) => Promise<boolean>;
  isNameValid: ((name: string) => boolean) | undefined;
  isTemplateNameTaken: ((name: string) => boolean) | undefined;
  variant: 'restrictions' | 'allocation';
}) {
  const [templateName, setTemplateName] = useState('');

  const [helperTextState, setHelperTextState] = useState<'error' | 'success' | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleRenameTemplate() {
    amplitude().logEvent(`Rename ${variant} template`, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      templateId,
    });
    setIsSubmitting(true);
    const success = await renameTemplate(templateId, templateName);
    setIsSubmitting(false);
    if (success) {
      setTemplateName('');
      onClose();
      onTemplateRenamed();
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkTemplateName = useCallback(
    debounce((newName: string) => {
      if (isNameValid?.(newName)) {
        setHelperTextState('success');
      } else if (isTemplateNameTaken?.(newName)) {
        setHelperTextState('error');
        amplitude().logEvent('Error - template name already taken', {
          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        });
      }
    }, 500),
    [isNameValid, isTemplateNameTaken]
  );

  useEffect(() => {
    return () => {
      checkTemplateName.cancel();
    };
  }, [checkTemplateName]);

  return (
    <Dialog open={open} onClose={onClose} id="rename-template-modal">
      <DialogTitle onClose={onClose}>Edit template name</DialogTitle>
      <DialogContent dividers>
        <NewTemplateNameField
          variant={variant}
          value={templateName}
          error={helperTextState === 'error'}
          success={helperTextState === 'success'}
          onChange={(v) => {
            setHelperTextState(null);
            setTemplateName(v);
            checkTemplateName(v);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRenameTemplate}
          disabled={
            isSubmitting ||
            !templateName ||
            helperTextState !== 'success' ||
            isNameValid == null ||
            isTemplateNameTaken == null
          }
        >
          Save and continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TemplateCard({
  children,
  createdAt,
  creatorFirstName,
  creatorLastName,
  disabled,
  id,
  name,
  selected,
  onSelect,
  variant,
  onClickDelete,
  onClickRename,
}: {
  children: React.ReactNode;
  createdAt: string;
  creatorFirstName: string;
  creatorLastName: string;
  disabled?: boolean;
  id: string;
  name: string;
  selected: boolean;
  onSelect: (selected: boolean) => void;
  variant: 'restrictions' | 'allocation';
  onClickDelete: () => void;
  onClickRename: () => void;
}) {
  const { classes, cx } = useStyles();
  const { data: featureFlags } = useFeatureFlags();
  const content = (
    <span>
      <Card
        variant="outlined"
        className={cx(classes.root, {
          [classes.disabled]: disabled,
          [classes.selected]: selected,
        })}
        data-cy="custom-alloc-template-card"
      >
        <Box bgcolor="grey.100">
          <Box alignItems="center" p={1.5} justifyContent="space-between" display="flex">
            <Button
              disabled={disabled}
              color="secondary"
              startIcon={selected ? <CheckIcon /> : undefined}
              size="small"
              variant="outlined"
              onClick={() => onSelect(selected)}
            >
              {selected ? 'Template selected' : 'Select template'}
            </Button>
            <>
              <DropdownButtonMenu
                isIcon
                buttonContent={<DotsHorizontal />}
                buttonProps={{ name: 'template-edit-delete-menu-button' }}
              >
                {(closeMenu) => [
                  <DropdownButtonMenuItem
                    onClick={() => {
                      onClickRename();
                      amplitude().logEvent(`Open rename ${variant} template modal`, {
                        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                        templateId: id,
                      });
                      closeMenu();
                    }}
                    key={`${id}-rename`}
                    data-cy="rename-template-button"
                  >
                    Edit name
                  </DropdownButtonMenuItem>,
                  featureFlags?.enable_model_template_center !== 'on' ? (
                    <DropdownButtonMenuItem
                      onClick={() => {
                        onClickDelete();
                        amplitude().logEvent(`Open delete ${variant} template modal`, {
                          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                          templateId: id,
                        });
                        closeMenu();
                      }}
                      key={`${id}-delete`}
                      data-cy="delete-template-button"
                    >
                      <Box color="error.main">Delete</Box>
                    </DropdownButtonMenuItem>
                  ) : null,
                ]}
              </DropdownButtonMenu>
            </>
          </Box>
        </Box>
        <Box border={1} borderLeft={0} borderRight={0} borderColor="grey.300" p={2.5}>
          <Box mb={1}>
            <Typography variant="h2">{name}</Typography>
          </Box>
          <Box display="flex">
            <Box maxWidth="50%" mr={1}>
              <Typography color="textSecondary" display="inline" variant="body2">
                Built by {creatorFirstName} {creatorLastName}
              </Typography>
            </Box>
            <TextHighlightTag severity="priority" flexShrink={0}>
              Created {format(new Date(createdAt), 'PP')}
            </TextHighlightTag>
          </Box>
        </Box>
        <Box mx={3} my={2.5}>
          {children}
        </Box>
      </Card>
    </span>
  );

  if (variant === 'allocation' && disabled) {
    return (
      <Tooltip
        title="Non-Taxable accounts are not eligible to select asset templates that contain Municipal Bonds"
        placement="top"
      >
        {content}
      </Tooltip>
    );
  }
  if (variant === 'restrictions' && disabled) {
    return (
      <Tooltip
        title="Portfolios non-inclusive of developed ex U.S. equities are not compatible with templates that restrict developed countries"
        placement="top"
      >
        {content}
      </Tooltip>
    );
  }
  return content;
}

export {
  PaddedContainer,
  BalancedPaddedContainer,
  TemplateSkeletons,
  TemplateCard,
  NewTemplateNameField,
};
