import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Typography,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { RebalancerStatus } from 'vise-types/portfolio';
import DialogTitle from '~/synth/DialogTitle';
import { ReactComponent as AlertIcon } from '~/static/images/icons/alert-circle-outline.svg';
import { ReactComponent as PauseIcon } from '~/static/images/icons/pause-circle-outline.svg';

const BannerText = styled(Typography)`
  display: table-cell;
  vertical-align: middle;
`;

interface AutoRebalancerProps {
  rebalancerPausedAt: string | null;
  rebalancerStatus: RebalancerStatus;
  accountStatus: string;
  onSubmit: (enabled: boolean) => void;
  onClose: () => void;
  open: boolean;
}

export default function AutoRebalancer({
  rebalancerPausedAt,
  rebalancerStatus,
  accountStatus,
  onSubmit,
  onClose,
  open,
}: AutoRebalancerProps) {
  const inactiveAccount = accountStatus === 'INACTIVE';

  const [checkboxState, setCheckboxState] = useState(
    inactiveAccount ? false : rebalancerStatus === 'ACTIVE'
  );

  const onModalClose = (): void => {
    setCheckboxState(inactiveAccount ? false : rebalancerStatus === 'ACTIVE');
    onClose();
  };

  const checkboxWithoutTooltip = (
    <FormControlLabel
      control={
        <Checkbox
          checked={checkboxState}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
            setCheckboxState(event.target.checked)
          }
        />
      }
      disabled={inactiveAccount}
      label="Automatically rebalance this account"
    />
  );

  const checkbox = inactiveAccount ? (
    <Tooltip
      title="Autobalancer will be available once the account becomes active"
      placement="top-start"
    >
      {checkboxWithoutTooltip}
    </Tooltip>
  ) : (
    checkboxWithoutTooltip
  );

  // "Inactive" and "Paused" are mutually exclusive with "Inactive" taking priority. Only 1 of the
  // messages should display at a time.
  let bannerEl: React.ReactElement | undefined;
  if (inactiveAccount) {
    bannerEl = (
      <Box px={3} py={2} borderTop={1} borderColor="grey.200" display="table">
        <Box color="warning.400" display="flex" alignItems="center">
          <AlertIcon height={20} width={20} />
        </Box>
        <BannerText>Paused by Vise due to the account being currently inactive</BannerText>
      </Box>
    );
  } else if (rebalancerPausedAt != null) {
    bannerEl = (
      <Box px={3} py={2} borderTop={1} borderColor="grey.200" display="table">
        <Box color="warning.400" display="flex" alignItems="center">
          <PauseIcon height={20} width={20} />
        </Box>
        <BannerText>
          Manually paused for {moment(rebalancerPausedAt).fromNow(true)} (since{' '}
          <time dateTime={rebalancerPausedAt} title={rebalancerPausedAt}>
            {moment(rebalancerPausedAt).format('lll')})
          </time>
        </BannerText>
      </Box>
    );
  }

  return (
    <Dialog maxWidth="sm" open={open} onClose={onModalClose}>
      <DialogTitle onClose={onModalClose}>Auto rebalancer</DialogTitle>
      {bannerEl}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(checkboxState);
        }}
      >
        <DialogContent dividers>
          <Box mt={1} mb={2}>
            <FormGroup>{checkbox}</FormGroup>
            <Box pl={4}>
              <Typography color="textSecondary">
                Pausing the automatic rebalancer temporarily stops Vise&apos;s daily rebalancing
                evaluation and any portfolio optimization activities, which may result in portfolio
                drift from targets if left paused. Changing this setting will go into effect in the
                following trading session and can be changed at any time.
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onModalClose} type="button" variant="outlined">
            Cancel
          </Button>
          <Button color="primary" type="submit" variant="contained" disabled={inactiveAccount}>
            Save changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
