import { Box, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { TemplateUpdateEventStatus } from '~/models/api';
import { DataTable } from '~/synth/DataTable';
import { Column } from 'react-table';
import { formatCurrency } from '~/utils/format';
import { ReactComponent as XCircleIcon } from '~/static/images/icons/x-circle.svg';
import { TemplateUpdateJob } from 'vise-types/template';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import Card from './Card';
import { AccountCell, leftPad, CTACell } from './CommonComponents';
import { CONTAINER_WIDTH } from './constants';

interface ProposalErrorTableType {
  account?: AccountWithPIAndHouseholdInfo;
  value?: number | null;
  error: string;
  link?: {
    path: string;
    buttonCopy: string;
  };
}

function ErrorCell({ value }: { value: ProposalErrorTableType['error'] }) {
  return <Box py={2}>{value}</Box>;
}

const proposalErrorColumns: Column<ProposalErrorTableType>[] = [
  {
    Header: leftPad(() => <>Account</>),
    accessor: 'account',
    Cell: leftPad(AccountCell),
    width: '25%',
  },
  {
    Header: 'Value',
    accessor: (row) => formatCurrency(row.value),
    width: '15%',
  },
  {
    Header: 'Error',
    accessor: 'error',
    Cell: ErrorCell,
  },
];

export default function ErroredProposalsSection({
  jobData,
  accountsById,
  activityLogFlag,
  maxWidth,
}: {
  jobData: TemplateUpdateJob;
  accountsById: {
    [key: string]: AccountWithPIAndHouseholdInfo | undefined;
  };
  activityLogFlag?: boolean;
  maxWidth?: string;
}) {
  const theme = useTheme();
  const proposalErrorTableData = useMemo(() => {
    return (
      jobData?.events
        .filter(
          (ev) =>
            ev.status === TemplateUpdateEventStatus.PROPOSAL_ERROR &&
            // If an account becomes untransitioned after a template update job for it is kicked off,
            // exclude it from all actions.
            // This can happening with unlinking/linking or account removal requests etc.
            // We should not be transitioning accounts through template update.
            // If an account is not accepted the proposal is simply deleted so it will be a noop.
            accountsById[ev.accountId] != null
        )
        .map((ev) => {
          if (ev.status !== TemplateUpdateEventStatus.PROPOSAL_ERROR) {
            throw new Error('impossible');
          }
          const account = accountsById[ev.accountId];
          return {
            account,
            value: account?.cachedAum,
            error: ev.error || 'Unspecified error',
            link: account?.id
              ? {
                  path: `/secure/accounts/${ev.accountId}/portfolio/overview`,
                  buttonCopy: 'View account',
                }
              : undefined,
          };
        }) || []
    );
  }, [jobData?.events, accountsById]);

  const errorColumnsWithViewAccount: Column<ProposalErrorTableType>[] = useMemo(() => {
    if (activityLogFlag) {
      return [...proposalErrorColumns, { Header: '', Cell: CTACell, accessor: 'link' }];
    }
    return proposalErrorColumns;
  }, [activityLogFlag]);

  if (!proposalErrorTableData.length) {
    return null;
  }

  return (
    <Box my={3}>
      <Card fullWidth maxWidth={maxWidth || CONTAINER_WIDTH}>
        <Box display="flex" alignItems="center" mx={3}>
          <Box>
            <Box display="flex" alignItems="center">
              <XCircleIcon color={theme.palette.error[400]} />
              <Box ml={1}>
                <Typography variant="h4">Errors</Typography>
              </Box>
            </Box>
            <Box mt={1}>
              <Typography variant="body1" color="textSecondary">
                {proposalErrorTableData.length} account
                {proposalErrorTableData.length === 1 ? '' : 's'} failed to generate proposals.{' '}
                {!activityLogFlag &&
                  `You
                may review and address errors after executing changes.`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={3}>
          <DataTable
            columns={errorColumnsWithViewAccount}
            data={proposalErrorTableData}
            m={0}
            rowSize="large"
          />
        </Box>
      </Card>
    </Box>
  );
}
