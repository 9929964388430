import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Link,
  Slider,
  Tooltip,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { styled } from '@mui/system';
import { FormikErrors, useFormik } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { ConcentrationLimit } from 'vise-types/pce1';
import { MMFEligibility } from 'vise-types/portfolio';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAccountSize from '~/hooks/useAccountSize';
import { useCoachmarkEffect } from '~/hooks/useCoachmark';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useMMFEligibility from '~/hooks/useMMFEligibility';
import snoxxPdf from '~/static/snoxx-document.pdf';
import ContainedSelectorCheckbox from '~/synth/ContainedSelectorCheckbox';
import PopoverCard from '~/synth/PopoverCard';
import PopoverLink from '~/synth/PopoverLink';
import PopoverTrigger from '~/synth/PopoverTrigger';
import TextField from '~/synth/TextField';
import amplitude from '~/utils/amplitude';
import ReturnToSummary from '../ReturnToSummary';
import { ScreenProps } from '../Types';
import {
  formatPce1CashConcentrationLimit,
  getSampleProposalAccountSize,
  isSmallAccount,
} from '../utils';
import { LowValueBanner, NotEnoughValueBanner } from './LockedPositionsScreen';
import { ActionFooter, BackButton, ContentBox, ExplainerText } from './components';

const DEFAULT_CASH_ALLOCATION_INPUT = '1.0';

export const CashFractionSlider = withStyles(Slider, (theme) => ({
  root: {
    pointerEvents: 'none',
  },
  //  Target marker
  mark: {
    width: '18px !important',
    height: '18px !important',
    borderRadius: '50% !important',
    background: 'white !important',
    border: `2px solid ${theme.palette.primary.main} !important`,
    opacity: 1,
    // Negative margins to account for size of marker
    marginTop: '0px !important',
    marginLeft: '-9px !important',
  },
  rail: {
    background: 'linear-gradient(270deg, #CBCDFF, #A2BFFF, #CBCDFF); !important',
    height: '12px !important',
    borderRadius: '12px !important',
  },
  track: {
    backgroundColor: '#8BA9EE !important',
    height: '12px !important',
  },
  valueLabel: {
    display: 'none',
  },
  // Range markers
  thumb: {
    height: '14px !important',
    width: '14px !important',
    marginTop: 0,
    border: '2px solid #131313 !important',
    marginLeft: 0,
  },
}));

const RebalanceTriggered = styled(Box)(({ theme }) => ({
  color: `${theme.palette.grey[400]}`,
  fontSize: 12,
  display: 'inline-block',
  width: '25%',
  textAlign: 'center',
  // Line behind text
  '&::after': {
    content: '""',
    width: '100%',
    height: '0.5px',
    background: `${theme.palette.grey[400]}`,
    display: 'block',
    position: 'relative',
    top: '-7.5px',
    zIndex: -1,
  },
  // Arrows base
  '&::before': {
    content: '""',
    width: 6,
    height: 6,
    display: 'block',
    position: 'relative',
    top: '10.5px',
    zIndex: -1,
  },
  // Left range arrow
  '&.rebalance-triggered-left::before': {
    borderLeft: `0.5px solid ${theme.palette.grey[400]}`,
    borderBottom: `0.5px solid ${theme.palette.grey[400]}`,
    transform: 'rotate(45deg)',
  },
  // Right range arrow
  '&.rebalance-triggered-right::before': {
    borderRight: `0.5px solid ${theme.palette.grey[400]}`,
    borderTop: `0.5px solid ${theme.palette.grey[400]}`,
    transform: 'rotate(45deg)',
    left: '96%',
  },
}));

const AcceptableRange = styled(RebalanceTriggered)(({ theme }) => ({
  borderRight: `0.5px solid ${theme.palette.blue[300]}`,
  borderLeft: `0.5px solid ${theme.palette.blue[300]}`,
  width: '50%',
  '&::after': {
    background: `${theme.palette.blue[300]}`,
  },
  '&::before': {
    display: 'none',
  },
}));

const RangeLabel = styled(Box)(() => ({
  background: 'white',
  width: 'fit-content',
  margin: 'auto',
  padding: '0 8px',
}));

interface FormValues {
  cashConcentrationLimit: string;
}

export default function CashAllocationScreen({
  dpDispatch,
  draftPortfolio,
  onBack,
  onContinue,
}: ScreenProps) {
  const {
    constructionInfo: { concentrationLimits, existingPortfolio, initialValue, mmf },
    lockedPositions,
    accountSize,
  } = draftPortfolio;

  const custodian =
    existingPortfolio != null && existingPortfolio !== 'sample-portfolio'
      ? existingPortfolio.custodianKey
      : null;

  const cashConcentrationLimit = concentrationLimits?.find(
    (concentrationLimit) => concentrationLimit.asset_class === 'Cash'
  );

  const cashTargetString = formatPce1CashConcentrationLimit(cashConcentrationLimit);

  const overRangeThreshold = initialValue != null ? initialValue >= 40000 : false;

  const { data: featureFlags } = useFeatureFlags();

  // TODO: get sample proposal account size from endpoint
  const sampleProposalAccountSize = getSampleProposalAccountSize(
    initialValue,
    parseFloat(cashTargetString)
  );

  const { data: accountSizeData, error: accountSizeError } = useAccountSize(
    existingPortfolio === 'sample-portfolio' ? undefined : existingPortfolio?.id,
    lockedPositions,
    cashConcentrationLimit
      ? (cashConcentrationLimit.max + cashConcentrationLimit.min) / 2
      : undefined
  );
  let accountSizeToSet =
    existingPortfolio === 'sample-portfolio' ? sampleProposalAccountSize : accountSizeData;
  // Deprecate creating new small account proposals and instead use large account inputs and trust
  // PCE2 to handle it. However if they have a tiny account then disable continuing (we would error in PCE)
  if (featureFlags?.disable_small_accounts_ui === 'on' && accountSizeToSet !== 'TINY') {
    accountSizeToSet = 'FULL';
  }

  const { data: mmfEligibilityData, error: mmfEligibilityError } = useMMFEligibility(
    existingPortfolio === 'sample-portfolio' ? undefined : existingPortfolio?.id,
    lockedPositions,
    cashConcentrationLimit
      ? (cashConcentrationLimit.max + cashConcentrationLimit.min) / 2
      : undefined
  );

  const [mmfEligible, setMMFEligible] = useState<MMFEligibility | undefined>(undefined);

  useCoachmarkEffect({
    show: accountSizeError != null || mmfEligibilityError != null,
    severity: 'error',
    title: 'There was an error fetching account size data.',
  });

  useEffect(() => {
    amplitude().logEvent('Impression - Cash Allocation', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);

  // Set to default cash allocation (1.0%) when we first land on this page.
  useEffect(() => {
    if (cashConcentrationLimit == null) {
      dpDispatch({
        concentrationLimit: parseFloat(DEFAULT_CASH_ALLOCATION_INPUT),
        type: 'SET_CASH_CONCENTRATION_LIMIT',
      });
    }
  }, [cashConcentrationLimit, dpDispatch]);

  useEffect(() => {
    if (accountSizeToSet != null) {
      dpDispatch({ type: 'SET_ACCOUNT_SIZE', accountSize: accountSizeToSet });
      if (accountSizeToSet === 'SMALL') {
        dpDispatch({ type: 'CLEAR_ALL_RESTRICTIONS' });
        dpDispatch({ type: 'PCE2_UNSET_ACTIVE_TILT' });
      }
    }
  }, [dpDispatch, accountSizeToSet]);

  useEffect(() => {
    if (mmfEligibilityData != null && (custodian === 'SANDBOX' || custodian === 'SCHWAB')) {
      setMMFEligible(mmfEligibilityData);
      // Auto opt out if not eligible, auto opt in if eligible
      dpDispatch({ type: 'SET_MMF', mmf: mmfEligibilityData.eligible });
    }
  }, [dpDispatch, mmfEligibilityData, custodian]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const commitValues = useCallback(
    // Debounce "committing" values so only values that have settled are possibly written to the
    // store (and to the summary sidebar from the user's view). Values are only submitted if
    // (1) they are valid (2) they have changed from the last time this function was called.
    debounce(
      (
        _currValues: { cashConcentrationLimit: ConcentrationLimit | undefined },
        nextValues: FormValues,
        nextErrors: FormikErrors<FormValues>
      ) => {
        if (!nextErrors.cashConcentrationLimit) {
          dpDispatch({
            concentrationLimit: parseFloat(nextValues.cashConcentrationLimit),
            type: 'SET_CASH_CONCENTRATION_LIMIT',
          });
        }
      },
      250
    ),
    [dpDispatch]
  );

  // Flush any pending debounced function calls to ensure this component does no work after it
  // unmounts.
  useEffect(() => {
    return () => {
      commitValues.flush();
    };
  }, [commitValues]);

  // `undefined` is an expected case here, hence the `?.`. That means no cash ConcentrationLimit has
  // been set in the PortfolioIntelligence.
  const dpCashConcentrationLimit = concentrationLimits?.find(
    (concentrationLimit) => concentrationLimit.asset_class === 'Cash'
  );
  const formik = useFormik<FormValues>({
    initialValues: {
      // Default cash allocation is 1.0%
      cashConcentrationLimit:
        formatPce1CashConcentrationLimit(dpCashConcentrationLimit) || DEFAULT_CASH_ALLOCATION_INPUT,
    },
    onSubmit() {
      if (!isSmallAccount(accountSize)) {
        amplitude().logEvent('Continue to allocation template selection screen', {
          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        });
        onContinue();
      } else {
        onContinue('riskTolerance');
      }
    },
    validate(values) {
      const errors: FormikErrors<FormValues> = {};
      const parsedCashConcentrationLimit = parseFloat(values.cashConcentrationLimit);
      if (
        Number.isNaN(parsedCashConcentrationLimit) ||
        parsedCashConcentrationLimit < 0.5 ||
        parsedCashConcentrationLimit > 100
      ) {
        errors.cashConcentrationLimit = 'Cash allocation must be between 0.5 and 100';
      }

      if (formik.dirty) {
        commitValues({ cashConcentrationLimit: dpCashConcentrationLimit }, values, errors);
      }

      return errors;
    },
  });

  const isValid =
    Object.keys(formik.errors).length === 0 && accountSizeError == null && accountSize !== 'TINY';

  return (
    <ContentBox>
      <Box mb={2}>
        <Typography display="inline" variant="h1">
          Set your client&apos;s target cash allocation.
        </Typography>
      </Box>
      <ExplainerText mb={3}>
        Moving some or all of a client&apos;s portfolio to cash is an investment decision that
        should only be considered in extreme conditions and is not recommended by Vise. This change
        may result in unintended gains or losses being realized in the client&apos;s portfolio.
      </ExplainerText>
      <Box mb={3}>
        <PopoverTrigger
          overlay={({ close }) => (
            <PopoverCard
              title="Cash allocation"
              body="A target cash allocation has been added to this portfolio. Adjusting the target cash allocation will change the Vise-managed value of this portfolio, which may change the portfolio's target allocation across all other asset classes."
              onClose={close}
            />
          )}
        >
          <PopoverLink variant="body2">
            How does cash allocation impact my portfolio allocation?
          </PopoverLink>
        </PopoverTrigger>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          sx={{ borderColor: 'grey.200' }}
          border={1}
          borderLeft={0}
          borderRight={0}
          borderBottom={0}
          py={4}
        >
          <Box mb={2}>Set target cash allocation</Box>
          <NumberFormat // Use `NumberFormat` for its `decimalScale` functionality
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            customInput={TextField}
            decimalScale={1} // Allow only a single decimal place, like "2.5"
            error={
              formik.touched.cashConcentrationLimit && Boolean(formik.errors.cashConcentrationLimit)
            }
            helperText="Default is 1%. Cash allocation must be a minimum of 0.5%."
            name="cashConcentrationLimit"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.cashConcentrationLimit}
          />
        </Box>
        {accountSize === 'TINY' && (
          <NotEnoughValueBanner>
            By increasing the cash allocation and locking unrecognized positions, the Vise-managed
            value has dropped below $500.00. Lower the cash allocation or unlock unrecognized
            positions in order to continue.
          </NotEnoughValueBanner>
        )}
        {accountSize === 'SMALL' && (
          <LowValueBanner>
            Unrecognized positions that have been locked and the cash allocation has lowered the
            Vise-managed value beneath $5,000.00. When this happens, Vise adjusts the types of
            portfolio inputs and asset classes available in order to optimize for smaller account
            size.
          </LowValueBanner>
        )}
        <Box pt={4} hidden={accountSize === 'TINY' || accountSize === 'SMALL'}>
          <Box
            display="flex"
            pl="calc(25% - 35px)"
            pr="calc(25% - 35px)"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box color="grey.500" textAlign="center">
              <Typography variant="body2">
                {overRangeThreshold
                  ? `${(parseFloat(cashTargetString) - 0.5).toFixed(1)}%`
                  : `${cashTargetString}% - $200`}
              </Typography>
              <Typography variant="caption">Lower bound</Typography>
            </Box>
            <Box textAlign="center">
              <Box fontWeight={500}>{cashTargetString}%</Box>
              <Typography variant="caption">Target cash allocation</Typography>
            </Box>
            <Box color="grey.500" textAlign="center">
              <Typography variant="body2">
                {overRangeThreshold
                  ? `${Math.min(parseFloat(cashTargetString) + 0.5, 100).toFixed(1)}%`
                  : `${cashTargetString}% + $200`}
              </Typography>
              <Typography variant="caption">Upper bound</Typography>
            </Box>
          </Box>
          <CashFractionSlider value={[25, 75]} marks={[{ value: 50 }]} />
          <Box pt={1}>
            <RebalanceTriggered className="rebalance-triggered-left">
              <RangeLabel>Rebalance triggered</RangeLabel>
            </RebalanceTriggered>
            <AcceptableRange>
              <RangeLabel>Range of acceptable cash values</RangeLabel>
            </AcceptableRange>
            <RebalanceTriggered className="rebalance-triggered-right">
              <RangeLabel>Rebalance triggered</RangeLabel>
            </RebalanceTriggered>
          </Box>
        </Box>
        <Box color="grey.500" pt={4}>
          <Typography variant="body1">
            Cash allocation may fluctuate ±0.5% of the target allocation for accounts with
            &gt;$40,000 in assets and ±$200 for accounts with &lt;$40,000 in assets. If the value of
            cash in the portfolio reaches beyond the upper or lower bounds displayed above, a
            rebalance will be triggered. Every rebalance targets the midpoint of the acceptable
            range.
          </Typography>
        </Box>
        {featureFlags?.enable_mmf === 'on' ? (
          <>
            <Box py={3}>
              <Divider />
            </Box>
            <Typography variant="h3">Money market fund</Typography>
            <ExplainerText mt={2} mb={2}>
              Invest excess cash in{' '}
              <Link href={snoxxPdf} target="_blank" rel="noopener noreferrer">
                money market fund (MMF)
              </Link>
              .
            </ExplainerText>
            <>
              This opt-in is only available for accounts:
              <ul>
                <li>custodied at Charles Schwab, and</li>
                <li>have no locked MMFs, and</li>
                <li>have a target cash allocation percentage ≥ 1.5%, and</li>
                <li>have a target cash allocation value ≥ $200</li>
              </ul>
            </>
            <Tooltip
              title={
                <>
                  {!mmfEligible?.eligible ||
                  !(custodian === 'SANDBOX' || custodian === 'SCHWAB') ? (
                    <>
                      MMF opt-in is not available because:{' '}
                      <ul>
                        {!(custodian === 'SANDBOX' || custodian === 'SCHWAB') ? (
                          <li>The account is not custodied at Charles Schwab</li>
                        ) : null}
                        {mmfEligible != null && !mmfEligible.unlockedMMF ? (
                          <li>There are locked MMFs</li>
                        ) : null}
                        {mmfEligible != null && !mmfEligible.validTargetCashFraction ? (
                          <li>Target cash allocation is &lt; 1.5%</li>
                        ) : null}
                        {mmfEligible != null && !mmfEligible.validTargetCashValue ? (
                          <li>Target cash value from allocation is &lt; $200</li>
                        ) : null}
                      </ul>
                    </>
                  ) : (
                    ''
                  )}
                </>
              }
            >
              <div>
                <ContainedSelectorCheckbox
                  checked={!!mmf}
                  label={<>Opt-in to MMF</>}
                  disabled={
                    !mmfEligible?.eligible || !(custodian === 'SANDBOX' || custodian === 'SCHWAB')
                  }
                  name="mmf"
                  onClick={() => dpDispatch({ type: 'SET_MMF', mmf: !mmf })}
                />
              </div>
            </Tooltip>
            <Box mt={2}>
              <Typography variant="body1" color="textSecondary">
                For illustrative purposes: A $100,000 account with a cash allocation of 10% that
                selects Money Market Fund (MMF) Cash Sweep will target 1% of physical cash and 9% of
                MMF for a total cash allocation of 10%.
              </Typography>
            </Box>
          </>
        ) : null}
        <ActionFooter justifyContent="space-between">
          <BackButton onClick={() => onBack()} />
          <Box display="flex">
            <ReturnToSummary
              draftPortfolio={draftPortfolio}
              disabled={!isValid}
              onReturnToSummary={() => onContinue('summary')}
              mr={1.5}
            />
            <Button color="primary" disabled={!isValid} type="submit" variant="contained">
              Continue
            </Button>
          </Box>
        </ActionFooter>
      </form>
    </ContentBox>
  );
}
