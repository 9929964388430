import { useTheme } from '@mui/material';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import { isEmpty } from 'lodash';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { AssetClassKey, Sector } from 'vise-types/pce2_instrument';
import useCategoricalDataVisColors from '~/hooks/useCategoricalDataVisColors';
import {
  AllocationChartDataPoint,
  getPCE2AssetClassTitleAndSubtitle,
} from '~/routes/Portfolio/portfolioUtil';
import { SECTOR_TO_LABEL_MAP } from '~/routes/Portfolio/Constants';
import { ASSET_CLASS_TO_LABEL_MAP } from '~/routes/PortfolioCreator2/Constants';
import { formatPercent } from '~/utils/format';
import { getAssetClassFeaturesFromKey } from '~/utils/pce2Migration';

import { PieChart } from '..';

interface SharedTooltipPointProps {
  point: Highcharts.Point;
}

function SharedTooltipPoint({ point }: SharedTooltipPointProps) {
  const pointName = point.name;
  let label = pointName;
  const assetClassFeatures = getAssetClassFeaturesFromKey(pointName as AssetClassKey);

  if (ASSET_CLASS_TO_LABEL_MAP.get(assetClassFeatures[0]) != null) {
    // Lipper does not return beyond L1 asset classes so if we are seeing L1 classification
    // at this point for the outer ring series (individual asset class allocation), it should be
    // classified as "Other Equities" etc.
    if (
      point.series.name === 'Allocation' &&
      assetClassFeatures.length === 1 &&
      assetClassFeatures[0] !== 'CASH' &&
      assetClassFeatures[0] !== 'UNKNOWN' &&
      assetClassFeatures[0] !== 'LOCKED'
    ) {
      label = `Other ${ASSET_CLASS_TO_LABEL_MAP.get(assetClassFeatures[0])}`;
    } else {
      const assetDisplayName = getPCE2AssetClassTitleAndSubtitle(pointName as AssetClassKey);
      label =
        (assetDisplayName.subtitle || '') +
        (assetDisplayName.subtitle ? ` - ${assetDisplayName.title}` : assetDisplayName.title);
    }
  } else if (SECTOR_TO_LABEL_MAP.get(pointName as Sector) != null) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    label = SECTOR_TO_LABEL_MAP.get(pointName as Sector)!;
  } else if (pointName === 'MORE') {
    label = 'More...';
  }

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Helvetica',
        padding: '0',
        margin: '0',
      }}
    >
      <div>
        <span
          style={{
            backgroundColor: String(point.color),
            borderRadius: '4px 0 0 4px',
            display: 'inline-block',
            height: '32px',
            marginRight: '4px',
            position: 'relative',
            top: '0',
            left: '-8px',
            width: '4px',
            margin: '-8px 0 -12px 0',
          }}
        />
      </div>
      <div>{label}</div>
      <div style={{ paddingLeft: '10px', textAlign: 'right' }}>
        {formatPercent(point?.y != null ? point.y : null, 1)}
      </div>
    </div>
  );
}
export interface AllocationChartProps {
  data: AllocationChartDataPoint[];
  groupedData?: AllocationChartDataPoint[];
  height?: number;
  onClickSelection?: (event) => void;
}

const AllocationChart = ({ data, groupedData, height, onClickSelection }: AllocationChartProps) => {
  const theme = useTheme();
  const chartColors = useCategoricalDataVisColors();
  const noData = isEmpty(data);
  const series: SeriesOptionsType[] = React.useMemo(() => {
    if (noData) {
      // Show a skeleton pie chart if there is no data.
      return [
        {
          data: [{ name: 'EMPTY', y: 100, color: theme.palette.grey[200] }],
          size: '110%',
          innerSize: '80%',
          name: 'Allocation',
          type: 'pie',
          borderWidth: 0,
        },
        {
          data: [{ name: 'EMPTY INNER', y: 100, color: theme.palette.grey[100] }],
          innerSize: '85%',
          size: '85%',
          name: 'Grouped allocation',
          type: 'pie',
        },
      ];
    }
    if (groupedData) {
      return [
        {
          data,
          size: '110%',
          innerSize: '80%',
          name: 'Allocation',
          type: 'pie',
          borderWidth: 0,
        },
        {
          data: groupedData,
          innerSize: '85%',
          size: '85%',
          name: 'Grouped allocation',
          type: 'pie',
        },
      ];
    }
    return [
      {
        data,
        size: '110%',
        innerSize: '80%',
        name: 'Allocation',
        type: 'pie',
        colors: chartColors,
        borderWidth: 1,
      },
    ];
  }, [chartColors, data, groupedData, noData, theme.palette.grey]);
  const allocationOptions: Highcharts.Options = React.useMemo(
    () => ({
      chart: {
        height: height || 200,
        width: height || undefined,
      },
      credits: {
        enabled: false,
      },
      lang: {
        noData: undefined,
      },
      plotOptions: {
        pie: {
          cursor: onClickSelection ? 'pointer' : undefined,
          enableMouseTracking: !noData,
          center: ['50%', '50%'],
          dataLabels: {
            enabled: false,
          },
          size: '100%',
          colors: chartColors,
          states: {
            hover: {
              // Disable tooltip/label on hover for "empty" chart
              enabled: !noData,
            },
          },
          events: {
            click: onClickSelection,
          },
        },
      },
      series,
      title: {
        text: '',
      },
      tooltip: {
        enabled: !noData,
        pointFormatter() {
          return ReactDOMServer.renderToStaticMarkup(
            // eslint-disable-next-line react/no-this-in-sfc
            <SharedTooltipPoint point={this} />
          );
        },
        shared: true,
        useHTML: true,
        headerFormat: '',
        borderColor: 'none',
        borderRadius: 4,
      },
    }),
    [height, noData, chartColors, onClickSelection, series]
  );

  return <PieChart options={allocationOptions} />;
};

export default AllocationChart;
