import { Box, CardContent, Tooltip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Highstocks from 'highcharts/highstock';
import { merge } from 'lodash';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { PortfolioSummary } from 'vise-types/portfolio';
import ChartColorCircle from '~/components/chart/ChartColorCircle';
import ChartColorSquare from '~/components/chart/ChartColorSquare';
import UnderlineTooltipIndicator from '~/synth/UnderlineTooltipIndicator';
import { EVENT_CATEGORIES } from '../../constants/amplitude';
import amplitude from '../../utils/amplitude';
import { compactDollarFormatter, formatCurrency, WHOLE_DOLLAR_FORMATTER } from '../../utils/format';
import useDefaultChartOptions from './useDefaultChartOptions';

interface AccountValueChartProps {
  aggSummaries: PortfolioSummary[];
  valueSeriesName: string;
  valueSeriesTooltipTitle: string;
  /** Only relevant if withNativeDateRangeSelector === true. */
  xAxisAfterSetExtremes?: Highcharts.AxisSetExtremesEventCallbackFunction;
  /** Only needed if withNativeDateRangeSelector === false. */
  min?: Highcharts.XAxisOptions['min'];
  /** Only needed if withNativeDateRangeSelector === false. */
  max?: Highcharts.XAxisOptions['max'];
  withNativeDateRangeSelector?: boolean;
}

function DespositWithdrawalPoint({ depositWithdrawals }: { depositWithdrawals: number[] }) {
  return (
    <>
      {depositWithdrawals.map((dw, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}
        >
          <div>{dw > 0 ? <>Deposit</> : <>Withdrawal</>}</div>
          <div style={{ fontWeight: 'bold', paddingLeft: '10px', textAlign: 'right' }}>
            {formatCurrency(dw, WHOLE_DOLLAR_FORMATTER)}
          </div>
        </div>
      ))}
    </>
  );
}

function SharedTooltipPoint({ series, y }) {
  return (
    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <span
          style={{
            backgroundColor: String(series.color),
            borderRadius: '2px',
            display: 'inline-block',
            height: '4px',
            marginRight: '4px',
            position: 'relative',
            top: '-2px',
            width: '12px',
          }}
        />
        {series.name}
      </div>
      <div style={{ fontWeight: 'bold', paddingLeft: '10px', textAlign: 'right' }}>
        {formatCurrency(y, WHOLE_DOLLAR_FORMATTER)}
      </div>
    </div>
  );
}

export default function AccountValueChart({
  aggSummaries,
  valueSeriesName,
  valueSeriesTooltipTitle,
  xAxisAfterSetExtremes,
  min,
  max,
  withNativeDateRangeSelector,
}: AccountValueChartProps) {
  const theme = useTheme();
  const defaultChartOptions = useDefaultChartOptions();
  const options = React.useMemo(() => {
    const cumulativeBalanceData = aggSummaries.map((agg) => [agg.date, agg.marketValue]);
    const netContributionData = aggSummaries.map((agg) => [agg.date, agg.netContribution]);

    return merge(defaultChartOptions, {
      chart: {
        spacingBottom: 0,
      },
      lang: {
        noData: 'There is no data available.',
      },
      credits: {
        enabled: false,
      },
      labels: {
        style: {
          color: '#000',
        },
      },
      legend: {
        enabled: false,
      },
      navigator: {
        enabled: Boolean(withNativeDateRangeSelector),
      },
      // TODO(nkang): Delete when all charts use the external date range selector.
      rangeSelector: {
        enabled: Boolean(withNativeDateRangeSelector),
        buttons: [
          {
            type: 'month',
            count: 1,
            text: '1M',
          },
          {
            type: 'month',
            count: 3,
            text: '3M',
          },
          {
            type: 'month',
            count: 6,
            text: '6M',
          },
          {
            type: 'ytd',
            text: 'YTD',
          },
          {
            type: 'year',
            count: 1,
            text: '1Y',
          },
          {
            type: 'all',
            text: 'All',
          },
        ].map((button) => {
          return {
            ...button,
            events: {
              click: () => {
                amplitude().logEvent('Action - Select Date Range', {
                  category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
                  eventProperties: {
                    Range: button.text,
                    Chart: 'Account Value',
                  },
                });
              },
            },
          };
        }),
        buttonSpacing: 8,
        buttonTheme: {
          fill: 'none',
          r: 2,
          stroke: 'none',
          style: {
            color: theme.palette.grey[500],
            fontWeight: '500',
            fontSize: '14px',
          },
          states: {
            hover: {
              fill: 'rgba(19, 19, 19, 0.04)',
            },
            select: {
              fill: '#E3EBFC',
              style: {
                color: theme.palette.primary.main,
                fontWeight: '500',
              },
            },
          },
        },
        inputBoxBorderColor: theme.palette.grey[300],
        verticalAlign: 'bottom',
      },
      series: [
        {
          color: theme.palette.blue[500],
          dashStyle: 'Solid',
          data: cumulativeBalanceData,
          name: valueSeriesName,
          tooltip: {
            pointFormatter() {
              return ReactDOMServer.renderToStaticMarkup(
                // eslint-disable-next-line react/no-this-in-sfc
                <SharedTooltipPoint series={this.series} y={this.y} />
              );
            },
          },
          type: 'line',
          yAxis: 0,
        },
        {
          color: theme.palette.accents.lavender.main,
          dashStyle: 'Dot',
          data: netContributionData,
          fillColor: alpha(theme.palette.accents.lavender.dark, 0.03),
          name: 'Net contributions',
          step: true,
          threshold: null,
          tooltip: {
            pointFormatter() {
              return ReactDOMServer.renderToStaticMarkup(
                // eslint-disable-next-line react/no-this-in-sfc
                <SharedTooltipPoint series={this.series} y={this.y} />
              );
            },
          },
          type: 'area',
          yAxis: 0,
        },
        {
          // Filter to summaries that contain deposits/withdrawals. The default for summaries is to
          // have an array with single number, 0, to represent no change for that day. Filter only
          // non-zero changes to plot on the x-axis.
          data: aggSummaries
            .filter(
              (aggSummary) =>
                aggSummary.depositWithdrawals &&
                aggSummary.depositWithdrawals.length > 0 &&
                aggSummary.depositWithdrawals[0] !== 0
            )
            .map((aggSummary) => ({ options: { aggSummary }, x: aggSummary.date, y: 0 })),
          marker: {
            fillColor: 'transparent',
            lineColor: theme.palette.accents.lavender.dark,
            lineWidth: 2,
            radius: 4,
            symbol: 'circle',
          },
          name: 'Deposits and withdrawals',
          tooltip: {
            headerFormat: '<div style="font-size: 10px; margin-bottom: 4px;">{point.key}</div>',
            pointFormatter() {
              return ReactDOMServer.renderToStaticMarkup(
                <DespositWithdrawalPoint
                  // `options` is meant to be extensible and a holder for any type of data.
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/no-this-in-sfc
                  depositWithdrawals={(this.options as any).aggSummary.depositWithdrawals}
                />
              );
            },
            xDateFormat: '%Y-%m-%d',
          },
          // Use a scatter chart to just a bunch of disparate points. Deposits/withdrawals are
          // represented as points along the x-axis.
          type: 'scatter',
          yAxis: 1,
        },
      ],
      tooltip: {
        backgroundColor: theme.palette.grey[100],
        borderColor: theme.palette.grey[200],
        borderRadius: 4,
        padding: 8,
        // For points on the same y-axis, use a single joined tooltip. This means there is a tooltip
        // for the balance/contributions line chart and a tooltip for the deposits/withdrawals
        // scatter chart.
        shared: true,
        split: false,
        useHTML: true,
        valuePrefix: '$',
        xDateFormat: '%Y-%m-%d',
      },
      xAxis: {
        min,
        max,
        crosshair: true,
        events: {
          afterSetExtremes: xAxisAfterSetExtremes,
        },
        type: 'datetime',
      },
      // Create two y axes: the deposits/withdrawals belong to their own y-axis so the points can
      // always be plotted along 0 and the visible bottom of the chart.
      yAxis: [
        {
          ...defaultChartOptions.yAxis,
          labels: {
            ...defaultChartOptions.yAxis.labels,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return formatCurrency(this.value, compactDollarFormatter);
            },
          },
        },
        { visible: false },
      ],
    } as Highcharts.Options);
  }, [
    aggSummaries,
    defaultChartOptions,
    withNativeDateRangeSelector,
    theme.palette.grey,
    theme.palette.primary.main,
    theme.palette.blue,
    theme.palette.accents.lavender.main,
    theme.palette.accents.lavender.dark,
    valueSeriesName,
    min,
    max,
    xAxisAfterSetExtremes,
  ]);
  return (
    <>
      <HighchartsReact constructorType="stockChart" highcharts={Highstocks} options={options} />
      <CardContent data-testid="account-value-key">
        <Box display="flex" justifyContent="space-between">
          <Box alignItems="center" display="flex">
            <Box alignItems="center" display="inline-flex">
              <ChartColorSquare color={theme.palette.blue[500]} mr={2} />
              <Tooltip title={valueSeriesTooltipTitle} data-testid="account-value-tooltip">
                <UnderlineTooltipIndicator>{valueSeriesName}</UnderlineTooltipIndicator>
              </Tooltip>
            </Box>
            <Box alignItems="center" display="inline-flex" mx={6}>
              <ChartColorSquare color={theme.palette.accents.lavender.main} mr={2} />
              Net contributions
            </Box>
            <Box alignItems="center" display="inline-flex">
              <ChartColorCircle borderColor={theme.palette.accents.lavender.dark} mr={2} />
              Deposit/withdrawal
            </Box>
          </Box>
        </Box>
      </CardContent>
    </>
  );
}
