import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import DialogTitle from '~/synth/DialogTitle';
import { ReactComponent as ClipboardCheckIcon } from '~/static/images/icons/clipboard-check.svg';
import { CircleIcon } from './SummaryUtils';

export interface SkipProposalModalProps {
  open: boolean;
  onClose: () => void;
  awaitingPost: boolean;
  onCreatePortfolioIntelligence: () => Promise<void>;
  onCreateAndExecutePortfolioIntelligence: () => Promise<void>;
}

export default function SkipProposalModal({
  open,
  onClose,
  awaitingPost,
  onCreatePortfolioIntelligence,
  onCreateAndExecutePortfolioIntelligence,
}: SkipProposalModalProps) {
  const [executeProposal, setExecuteProposal] = useState(false);
  const [createProposal, setCreateProposal] = useState(false);

  const onCreateProposal = async () => {
    setCreateProposal(true);
    await onCreatePortfolioIntelligence();
    setCreateProposal(false);
  };

  const onExecuteChanges = async () => {
    setExecuteProposal(true);
    await onCreateAndExecutePortfolioIntelligence();
    setExecuteProposal(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          <CircleIcon width={52} height={52} mr={3}>
            <ClipboardCheckIcon width={28} height={28} />
          </CircleIcon>
          Execute portfolio changes?
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box color="grey.600" py={1}>
          <Box>
            The inputs you&apos;ve modified <u>do not</u> affect the target portfolio. However, new
            trades may occur to reflect your updates.
          </Box>
          <Box mt={2}>
            Would you like to execute these portfolio changes now or view a proposal first?
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={awaitingPost}
          onClick={onCreateProposal}
          color="secondary"
        >
          {awaitingPost && createProposal ? 'Creating proposal...' : 'Create Proposal'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={awaitingPost}
          onClick={onExecuteChanges}
        >
          {awaitingPost && executeProposal ? 'Executing changes...' : 'Execute portfolio changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
