import React, { useEffect, useMemo, useState } from 'react';
import { getTemplateUpdateJob } from '~/api/api';
import { TemplateAccountUpdateType, TemplateUpdateEventStatus } from '~/models/api';
import { keyBy, mapValues } from 'lodash';
import { ActivityDetailsResponse, TemplateUpdateJob } from 'vise-types/template';
import useAllAccountsWithHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import useTemplatesByParentIds from '~/hooks/templates/useTemplatesByParentIds';
import SuccessfulProposalsSection from '../workflows/SuccessfulProposalsSection';
import ErroredProposalsSection from '../workflows/ErroredProposalsSection';
import UnlinkedAccountsActivityTable from './UnlinkedAccountsActivityTable';

export default function LinkUpdateContent({ data }: { data: ActivityDetailsResponse }) {
  const [jobData, setJobData] = useState<TemplateUpdateJob | undefined>(undefined);
  const [accountsToLink, setAccountsToLink] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const fetchJobData = async () => {
      if (!data.jobId) {
        return;
      }
      const jobDataResponse = await getTemplateUpdateJob(data.jobId);
      if (jobDataResponse) {
        setJobData(jobDataResponse);
        setAccountsToLink(
          mapValues(
            keyBy(
              jobDataResponse.events
                .filter(
                  (ev) =>
                    ev.accountUpdateType === TemplateAccountUpdateType.APPLY &&
                    ev.status === TemplateUpdateEventStatus.PROPOSAL_CREATED
                )
                .map((ev) => ev.accountId),
              (id) => id
            ),
            () => true
          )
        );
      }
    };
    fetchJobData();
  }, [data.jobId]);

  const { data: accounts } = useAllAccountsWithHouseholdInfo({
    transitioned: true,
  });

  const { data: templates } = useTemplatesByParentIds({
    ids: data.template ? [data.template.parentId] : [],
    includeStale: true,
    includeDeleted: true,
  });
  const template = useMemo(() => (templates ? templates[0] : undefined), [templates]);

  const accountsById: { [key: string]: AccountWithPIAndHouseholdInfo | undefined } = useMemo(
    () => keyBy(accounts?.data, (account) => account.id),
    [accounts?.data]
  );

  if (!accounts) {
    return <>Loading ...</>;
  }
  return (
    <>
      {jobData && (
        <SuccessfulProposalsSection
          jobData={jobData}
          accountsById={accountsById}
          accountsToLink={accountsToLink}
          setAccountsToLink={setAccountsToLink}
          template={template}
          activityLogFlag
          maxWidth="1280px"
        />
      )}

      <UnlinkedAccountsActivityTable
        jobData={jobData}
        accountsById={accountsById}
        noProposalUnlinkAccountIds={data.metadata?.unlinkAccountsNoProposal || []}
        maxWidth="1280px"
      />

      {jobData && (
        <ErroredProposalsSection
          jobData={jobData}
          accountsById={accountsById}
          activityLogFlag
          maxWidth="1280px"
        />
      )}
    </>
  );
}
