import { combineReducers } from 'redux';
import app from './app';
import message from './message';
import users from './user';

const rootReducer = (state, action) => {
  return combineReducers({
    // custom reducers
    app,
    message,
    users,
  })(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export type GetState = () => RootState;

export default rootReducer;
