import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import React from 'react';
import { BondPortfolioSettings } from 'vise-types/portfolio';
import CardHeader from '~/synth/CardHeader';
import { buildContent, taxesContent } from './Utils';

export default function BondPortfolioInputs({ settings }: { settings: BondPortfolioSettings }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {settings.instrumentType === 'MUNICIPAL' && (
          <Card>
            <CardHeader>
              <Typography variant="h4">Taxes</Typography>
            </CardHeader>
            <CardContent>
              {taxesContent(settings).map(({ field, value }) => (
                <Box key={value?.toString()} display="flex" justifyContent="space-between" py={1}>
                  <Box color="black">{field}</Box>
                  <Box color={tokens.palette.neutralCool[800]}>{value}</Box>
                </Box>
              ))}
            </CardContent>
          </Card>
        )}
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader>
            <Typography variant="h4">Portfolio design</Typography>
          </CardHeader>
          <CardContent>
            {buildContent(settings).map(({ field, value }) => (
              <Box key={value?.toString()} display="flex" justifyContent="space-between" py={1}>
                <Box color="black">{field}</Box>
                <Box color={tokens.palette.neutralCool[800]}>{value}</Box>
              </Box>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
