import { Box, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import noTemplateGraphic from '~/static/images/modelTemplateCenter/no-templates-graphic.svg';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';

export default function EmptyState({
  height,
  title = 'No templates',
  body = 'There are currently no templates, click below to start building.',
  graphic = noTemplateGraphic,
  showCTA = true,
}: {
  height: number;
  title?: string;
  body?: string;
  graphic?: string;
  showCTA?: boolean;
}) {
  const history = useHistory();
  return (
    <Box width="100%" height={`${height}px`} display="flex" justifyContent="center">
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <img src={graphic} alt="" />
        <Box mt={2} mb={1.5}>
          <Typography variant="h4">{title}</Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1">
            <Box color="grey.400">{body}</Box>
          </Typography>
        </Box>
        {showCTA && (
          <Box>
            <DropdownButtonMenu
              buttonProps={{ variant: 'outlined' }}
              buttonContent="Create template"
            >
              {() => [
                <DropdownButtonMenuItem
                  key="allocation"
                  onClick={() => history.push('/secure/template/allocation/create')}
                >
                  Allocation
                </DropdownButtonMenuItem>,
                <DropdownButtonMenuItem
                  key="restrictions"
                  onClick={() => history.push('/secure/template/restriction/create')}
                >
                  Restrictions
                </DropdownButtonMenuItem>,
              ]}
            </DropdownButtonMenu>
          </Box>
        )}
      </Box>
    </Box>
  );
}
