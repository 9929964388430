import useAccount from '~/hooks/useAccount';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export default function useAccountWithLogging({
  accountId,
  includeSummary,
  includeAllocationChanges,
  callerName,
  extra = {},
}: {
  accountId: string | undefined;
  includeSummary?: boolean | undefined;
  includeAllocationChanges?: boolean | undefined;
  callerName: string;
  extra?: { [key: string]: unknown };
}) {
  const { data, error } = useAccount(accountId, includeSummary, includeAllocationChanges);

  useEffect(() => {
    if (error) {
      Sentry.captureException(
        new Error(`[${callerName}] Failed to load account with id: ${accountId}. ${error}`),
        {
          extra: {
            accountId,
            ...extra,
          },
        }
      );
    }
  }, [accountId, callerName, error, extra]);

  return {
    data,
    error,
  };
}
