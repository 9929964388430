import { Box, Button, ButtonBase, Divider, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { isEqual } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef, useState } from 'react';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAllRestrictionsTemplates from '~/hooks/templates/useAllRestrictionsTemplates';
import useUser from '~/hooks/useUser';
import { ReactComponent as ChevronRight } from '~/static/images/icons/chevron-right.svg';
import { ReactComponent as DocumentAddIcon } from '~/static/images/icons/document-add.svg';
import { ReactComponent as PlusIcon } from '~/static/images/icons/plus.svg';
import { ReactComponent as QuestionMarkCircleIcon } from '~/static/images/icons/question-mark-circle.svg';
import { ReactComponent as SearchIcon } from '~/static/images/icons/search.svg';
import { Tag } from '~/synth/Tag';
import amplitude from '~/utils/amplitude';
import ReturnToSummary from '../../ReturnToSummary';
import { Pce2ConstructionInfo, ScreenProps } from '../../Types';
import { shouldShowExcludeCountries } from '../../utils';
import { ActionFooter, BackButton, ContentBox, ExplainerText } from '../components';
import CountriesSelector from '../components/CountriesSelector';
import EsgSelector from '../components/EsgSelector';
import InstrumentsSelector from '../components/InstrumentsSelector';
import SectorsSelector from '../components/SectorsSelector';
import InformationPanel from './InformationPanel';
import SaveRestrictionsTemplateModal, {
  EMPTY_TEMPLATE,
  SaveRestrictionsTemplateModalProps,
} from './SaveRestrictionsTemplateModal';
import {
  AdditionalRestrictionsCard,
  NoneSelectedState,
  RestrictionsTemplateContent,
  TemplateDetailsCard,
} from './UtilComponents';

const SAVE_TEMPLATE_MODAL_CLOSED_SETTINGS = {
  open: false,
} as Pick<SaveRestrictionsTemplateModalProps, 'templateContent' | 'orgId' | 'userId' | 'open'>;

const StyledButtonBase = withStyles(ButtonBase, (theme) => ({
  root: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

export default function BuildRestrictionsScreen({
  draftPortfolio,
  dpDispatch,
  onBack,
  onContinue,
}: ScreenProps) {
  const { constructionInfo, restrictionsTemplates } = draftPortfolio;
  const {
    excludedCountries,
    excludedIndustries,
    excludedSectors,
    restrictedStocks,
    excludedEsgAreas,
    additionalRestrictions: loadedAdditionalRestrictions,
    existingPortfolio,
  } = constructionInfo;

  const showExcludeCountries = shouldShowExcludeCountries(
    (constructionInfo as Pce2ConstructionInfo).assetClassConcentrationLimits?.exclusions
  );

  const [activeSection, setActiveSection] = useState<'templates' | 'additional' | null>(null);
  const [informationSidePanelOpen, setInformationSidePanelOpen] = useState(false);
  const [saveTemplateModalSettings, setSaveTemplateModalSettings] = useState<
    Pick<SaveRestrictionsTemplateModalProps, 'templateContent' | 'orgId' | 'userId' | 'open'>
  >(SAVE_TEMPLATE_MODAL_CLOSED_SETTINGS);
  const [additionalRestrictionsModified, setAdditionalRestrictionsModified] = useState(false);

  const templateTickers = new Set(restrictionsTemplates?.map((x) => x.tickers).flat() ?? []);
  const templateSectors = new Set(restrictionsTemplates?.map((x) => x.sectors).flat() ?? []);
  const templateIndustries = new Set(restrictionsTemplates?.map((x) => x.subSectors).flat() ?? []);
  const templateCountries = new Set(restrictionsTemplates?.map((x) => x.countries).flat() ?? []);
  const templateEsgs = new Set(restrictionsTemplates?.map((x) => x.esgAreas).flat() ?? []);

  const [additionalRestrictions, setAdditionalRestrictionsBase] =
    useState<RestrictionsTemplateContent>({
      name: '',
      tickers: loadedAdditionalRestrictions
        ? loadedAdditionalRestrictions.restrictedStocks
        : restrictedStocks?.filter((x) => !templateTickers.has(x)) ?? [],
      sectors: loadedAdditionalRestrictions
        ? loadedAdditionalRestrictions.excludedSectors
        : excludedSectors?.filter((x) => !templateSectors.has(x)) ?? [],
      subSectors: loadedAdditionalRestrictions
        ? loadedAdditionalRestrictions.excludedIndustries
        : excludedIndustries?.filter((x) => !templateIndustries.has(x)) ?? [],
      countries: loadedAdditionalRestrictions
        ? loadedAdditionalRestrictions.excludedCountries
        : excludedCountries?.filter((x) => !templateCountries.has(x)) ?? [],
      esgAreas: loadedAdditionalRestrictions
        ? loadedAdditionalRestrictions.excludedEsgAreas
        : excludedEsgAreas?.filter((x) => !templateEsgs.has(x)) ?? [],
    });
  const originalAdditionalRestrictions = useRef(additionalRestrictions);
  const setAdditionalRestrictions = (additionalRestrictions: RestrictionsTemplateContent) => {
    setAdditionalRestrictionsModified(true);
    setAdditionalRestrictionsBase(additionalRestrictions);
  };
  const [liveTemplate, setLiveTemplate] = useState<RestrictionsTemplateContent>(EMPTY_TEMPLATE);

  const { data: userData } = useUser();

  const {
    tickers: additionalRestrictedStocks,
    sectors: additionalExcludedSectors,
    subSectors: additionalExcludedIndustries,
    countries: additionalExcludedCountries,
    esgAreas: additionalExcludedEsgAreas,
  } = additionalRestrictions;

  const {
    tickers: liveRestrictedStocks,
    sectors: liveExcludedSectors,
    subSectors: liveExcludedIndustries,
    countries: liveExcludedCountries,
    esgAreas: liveExcludedEsgAreas,
  } = liveTemplate;

  useEffect(() => {
    amplitude().logEvent('Impression - Build restrictions screen', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);

  const { data: allRestrictionsTemplates } = useAllRestrictionsTemplates();
  const disableSelectExistingTemplate =
    allRestrictionsTemplates == null || allRestrictionsTemplates.length === 0;

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    let nextScreen: string;
    if (existingPortfolio !== 'sample-portfolio' && existingPortfolio?.taxable) {
      nextScreen = 'tax management';
      onContinue('capGains');
    } else {
      nextScreen = 'summary';
      onContinue();
    }
    amplitude().logEvent(`Continue to ${nextScreen}`, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }

  function handleSectorsChange(
    industriesToAdd: string[] | null,
    industriesToDelete: string[] | null,
    sectorsToAdd: string[] | null,
    sectorsToDelete: string[] | null
  ) {
    if (activeSection == null) {
      return;
    }
    const setRestrictions =
      activeSection === 'templates' ? setLiveTemplate : setAdditionalRestrictions;

    const existingRestrictions =
      activeSection === 'templates' ? liveTemplate : additionalRestrictions;

    if (!isEmpty(industriesToAdd) || !isEmpty(industriesToDelete)) {
      const newIndustries = (existingRestrictions.subSectors ?? [])
        .concat(industriesToAdd ?? [])
        .filter((x) => !industriesToDelete?.includes(x));
      setRestrictions({ ...existingRestrictions, subSectors: newIndustries });
    }
    if (!isEmpty(sectorsToAdd) || !isEmpty(sectorsToDelete)) {
      const newSectors = (existingRestrictions.sectors ?? [])
        .concat(sectorsToAdd ?? [])
        .filter((x) => !sectorsToDelete?.includes(x));
      setRestrictions({ ...existingRestrictions, sectors: newSectors });
    }
  }

  function handleEsgChange(esgAreasToAdd: string[] | null, esgAreasToDelete: string[] | null) {
    if (activeSection == null) {
      return;
    }
    const setRestrictions =
      activeSection === 'templates' ? setLiveTemplate : setAdditionalRestrictions;

    const existingRestrictions =
      activeSection === 'templates' ? liveTemplate : additionalRestrictions;

    if (!isEmpty(esgAreasToAdd) || !isEmpty(esgAreasToDelete)) {
      const newEsgAreas = (existingRestrictions.esgAreas ?? [])
        .concat(esgAreasToAdd ?? [])
        .filter((x) => !esgAreasToDelete?.includes(x));
      setRestrictions({ ...existingRestrictions, esgAreas: newEsgAreas });
    }
  }

  function handleConfirmAdditionalRestrictions() {
    amplitude().logEvent('Add one off restrictions', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
    dpDispatch({
      type: 'SET_EXCLUDED_COUNTRIES',
      excludedCountries: [
        ...additionalExcludedCountries,
        ...(restrictionsTemplates?.map((x) => x.countries).flat() ?? []),
      ],
    });
    dpDispatch({
      type: 'SET_EXCLUDED_INDUSTRIES',
      excludedIndustries: [
        ...additionalExcludedIndustries,
        ...(restrictionsTemplates?.map((x) => x.subSectors).flat() ?? []),
      ],
    });
    dpDispatch({
      type: 'SET_EXCLUDED_SECTORS',
      excludedSectors: [
        ...additionalExcludedSectors,
        ...(restrictionsTemplates?.map((x) => x.sectors).flat() ?? []),
      ],
    });
    dpDispatch({
      type: 'SET_RESTRICTED_STOCKS',
      restrictedStocks: [
        ...additionalRestrictedStocks,
        ...(restrictionsTemplates?.map((x) => x.tickers).flat() ?? []),
      ],
    });
    dpDispatch({
      type: 'SET_RESTRICTED_ESG_AREAS',
      restrictedEsgAreas: [
        ...additionalExcludedEsgAreas,
        ...(restrictionsTemplates?.map((x) => x.esgAreas).flat() ?? []),
      ],
    });
    dpDispatch({
      type: 'SET_ADDITIONAL_RESTRICTIONS',
      restrictions: {
        excludedCountries: additionalExcludedCountries,
        excludedEsgAreas: additionalExcludedEsgAreas,
        excludedIndustries: additionalExcludedIndustries,
        excludedSectors: additionalExcludedSectors,
        restrictedStocks: additionalRestrictedStocks,
      },
    });
    setActiveSection(null);
  }

  function handleRemoveTemplate(id: string) {
    dpDispatch({ type: 'REMOVE_RESTRICTIONS_TEMPLATE', templateId: id });
    amplitude().logEvent('Deselect restrictions template from restrictions page', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      templateId: id,
    });
  }

  return (
    <>
      <ContentBox>
        <form onSubmit={handleSubmit}>
          <Box mb={2} display="flex" alignItems="center">
            <Typography variant="h1">Set your client&apos;s restrictions.</Typography>
            <Tag ml={1.5}>Optional</Tag>
          </Box>
          <ExplainerText mb={2}>
            Please note that introducing constraints via restrictions will impact expected returns.
          </ExplainerText>
          <StyledButtonBase onClick={() => setInformationSidePanelOpen(true)}>
            <Box
              my={2}
              display="flex"
              justifyContent="space-bewteen"
              alignItems="center"
              width="100%"
            >
              <Box display="flex" mr="auto" alignItems="center" fontSize="1rem">
                <QuestionMarkCircleIcon />
                <Box fontWeight={500} color="grey.600" ml={2}>
                  Learn more about common restrictions
                </Box>
              </Box>
              <ChevronRight />
            </Box>
          </StyledButtonBase>
          <Box py={3.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
              {activeSection === 'templates' ? (
                <>
                  <Typography variant="h3">New restrictions template</Typography>
                  <Box display="flex">
                    <Box mr={1.5}>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                          setActiveSection(null);
                          setLiveTemplate(EMPTY_TEMPLATE);
                          amplitude().logEvent('Exit template section in restrictions screen', {
                            category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      data-testid="save-restrictions-template-button"
                      disabled={isEqual(liveTemplate, EMPTY_TEMPLATE) || userData == null}
                      onClick={() => {
                        amplitude().logEvent('Open save restrictions template modal', {
                          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                        });
                        return (
                          userData != null &&
                          setSaveTemplateModalSettings({
                            open: true,
                            userId: userData.id,
                            orgId: userData.organizationId,
                            templateContent: liveTemplate,
                          })
                        );
                      }}
                    >
                      Save and apply
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="h3">Restrictions templates</Typography>
                  <Box display="flex">
                    <Box mr={1.5}>
                      <Button
                        color="primary"
                        disabled={activeSection != null || disableSelectExistingTemplate}
                        onClick={() => {
                          onContinue('selectRestrictionsTemplate');
                          amplitude().logEvent('Continue to select a restrictions template', {
                            category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                          });
                        }}
                        startIcon={<SearchIcon />}
                        data-testid="browse-restriction-templates-button"
                      >
                        Browse existing
                      </Button>
                    </Box>
                    <Button
                      color="primary"
                      disabled={activeSection != null}
                      name="create-restrictions-template-button"
                      onClick={() => {
                        setActiveSection('templates');
                        amplitude().logEvent('Enter template section on restrictions screen', {
                          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                        });
                      }}
                      startIcon={<DocumentAddIcon />}
                    >
                      Create new
                    </Button>
                  </Box>
                </>
              )}
            </Box>
            {activeSection === 'templates' ? (
              <>
                <Box mb={4}>
                  <InstrumentsSelector
                    value={liveRestrictedStocks ?? []}
                    chipValues={liveRestrictedStocks ?? []}
                    onChange={(stocks) =>
                      setLiveTemplate({
                        ...liveTemplate,
                        tickers: stocks.map((s) => s.value),
                      })
                    }
                    onRemove={(toRemove) =>
                      setLiveTemplate({
                        ...liveTemplate,
                        tickers: liveRestrictedStocks?.filter((x) => x !== toRemove),
                      })
                    }
                  />
                </Box>
                <Box mb={5}>
                  <SectorsSelector
                    chipsSectors={liveExcludedSectors ?? []}
                    chipsIndustries={liveExcludedIndustries ?? []}
                    excludedSectors={liveExcludedSectors ?? []}
                    excludedIndustries={liveExcludedIndustries ?? []}
                    onChange={handleSectorsChange}
                  />
                </Box>
                {showExcludeCountries && (
                  <Box mb={5}>
                    <CountriesSelector
                      value={liveExcludedCountries ?? []}
                      onChange={(countries) =>
                        setLiveTemplate({
                          ...liveTemplate,
                          countries: countries.map((c) => c.value),
                        })
                      }
                      onRemove={(toRemove) =>
                        setLiveTemplate({
                          ...liveTemplate,
                          countries: liveExcludedCountries?.filter((x) => x !== toRemove),
                        })
                      }
                    />
                  </Box>
                )}

                <Box mb={5}>
                  <EsgSelector
                    excludedProductAreas={liveExcludedEsgAreas ?? []}
                    onChange={handleEsgChange}
                  />
                </Box>
              </>
            ) : (
              <>
                <ExplainerText mb={3}>
                  Skip the legwork of manually adding restrictions by using one or more saved
                  templates.
                </ExplainerText>
                {restrictionsTemplates?.length ? (
                  <>
                    {restrictionsTemplates.map((t, k) => (
                      <TemplateDetailsCard
                        key={t.id}
                        restrictions={t}
                        onRemove={() => handleRemoveTemplate(t.id)}
                        isLast={k === restrictionsTemplates.length}
                      />
                    ))}
                  </>
                ) : (
                  <NoneSelectedState />
                )}
              </>
            )}
          </Box>
          <Divider />
          <Box py={3.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
              <Typography variant="h3">Ad-hoc restrictions</Typography>
              {activeSection === 'additional' ? (
                <Box display="flex" alignItems="center">
                  <Box mr={1.5}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        setActiveSection(null);
                        setAdditionalRestrictions(originalAdditionalRestrictions.current);
                        setAdditionalRestrictionsModified(false);
                        amplitude().logEvent('Exit additional restrictions section', {
                          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmAdditionalRestrictions}
                    disabled={!additionalRestrictionsModified}
                  >
                    Confirm
                  </Button>
                </Box>
              ) : (
                <Button
                  name="add-additional-restrictions"
                  color="primary"
                  disabled={activeSection != null}
                  onClick={() => {
                    setActiveSection('additional');
                    amplitude().logEvent('Enter one off restrictions section', {
                      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                    });
                    originalAdditionalRestrictions.current = additionalRestrictions;
                  }}
                  startIcon={<PlusIcon />}
                >
                  Add
                </Button>
              )}
            </Box>

            {activeSection === 'additional' ? (
              <>
                <Box mb={4}>
                  <InstrumentsSelector
                    value={additionalRestrictedStocks ?? []}
                    chipValues={additionalRestrictedStocks ?? []}
                    onChange={(stock) =>
                      setAdditionalRestrictions({
                        ...additionalRestrictions,
                        tickers: stock.map((s) => s.value),
                      })
                    }
                    onRemove={(toRemove) =>
                      setAdditionalRestrictions({
                        ...additionalRestrictions,
                        tickers: additionalRestrictedStocks?.filter((stock) => stock !== toRemove),
                      })
                    }
                  />
                </Box>
                <Box mb={5}>
                  <SectorsSelector
                    chipsSectors={additionalExcludedSectors ?? []}
                    chipsIndustries={additionalExcludedIndustries ?? []}
                    excludedSectors={additionalExcludedSectors ?? []}
                    excludedIndustries={additionalExcludedIndustries ?? []}
                    onChange={handleSectorsChange}
                  />
                </Box>
                {showExcludeCountries && (
                  <Box mb={5}>
                    <CountriesSelector
                      value={additionalExcludedCountries ?? []}
                      onChange={(country) =>
                        setAdditionalRestrictions({
                          ...additionalRestrictions,
                          countries: country.map((c) => c.value),
                        })
                      }
                      onRemove={(toRemove) =>
                        setAdditionalRestrictions({
                          ...additionalRestrictions,
                          countries: additionalExcludedCountries?.filter(
                            (country) => country !== toRemove
                          ),
                        })
                      }
                    />
                  </Box>
                )}
                <Box mb={5}>
                  <EsgSelector
                    excludedProductAreas={additionalExcludedEsgAreas ?? []}
                    onChange={handleEsgChange}
                  />
                </Box>
              </>
            ) : (
              <>
                <ExplainerText mb={3}>
                  Add one-off ticker, sector, country, and/or value exclusions to this client&apos;s
                  portfolio.
                </ExplainerText>
                {additionalExcludedCountries?.length ||
                additionalExcludedIndustries?.length ||
                additionalRestrictedStocks?.length ||
                additionalExcludedSectors?.length ||
                additionalExcludedEsgAreas?.length ? (
                  <AdditionalRestrictionsCard
                    restrictedStocks={additionalRestrictedStocks}
                    excludedSectors={additionalExcludedSectors}
                    excludedIndustries={additionalExcludedIndustries}
                    excludedCountries={additionalExcludedCountries}
                    excludedEsgAreas={additionalExcludedEsgAreas}
                  />
                ) : (
                  <NoneSelectedState />
                )}
              </>
            )}
          </Box>
        </form>
        <ActionFooter justifyContent="space-between">
          <BackButton onClick={() => onBack()} />
          <Box display="flex">
            <ReturnToSummary
              disabled={activeSection != null}
              draftPortfolio={draftPortfolio}
              onReturnToSummary={() => onContinue('summary')}
              mr={1.5}
            />
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={activeSection != null || allRestrictionsTemplates == null}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Box>
        </ActionFooter>
      </ContentBox>
      <SaveRestrictionsTemplateModal
        open={saveTemplateModalSettings.open}
        onClose={() => {
          setSaveTemplateModalSettings(SAVE_TEMPLATE_MODAL_CLOSED_SETTINGS);
          amplitude().logEvent('Close save restrictions template modal', {
            category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
          });
        }}
        onSetLiveTemplate={setLiveTemplate}
        onSetActiveSection={setActiveSection}
        dpDispatch={dpDispatch}
        templateContent={saveTemplateModalSettings.templateContent}
        userId={saveTemplateModalSettings.userId}
        orgId={saveTemplateModalSettings.orgId}
      />
      <InformationPanel
        open={informationSidePanelOpen}
        onClose={() => setInformationSidePanelOpen(false)}
      />
    </>
  );
}
