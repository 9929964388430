import { Box, Button, ButtonBase, Divider, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useMemo, useState } from 'react';
import { Custodian } from 'vise-types/custodian';
import { CsvFileType } from 'vise-types/xray';
import { deleteClosedLots, uploadClosedLots } from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { ReactComponent as ChevronRightIcon } from '~/static/images/icons/chevron-right.svg';
import { ReactComponent as QuestionMarkCircleIcon } from '~/static/images/icons/question-mark-circle.svg';
import { Tag } from '~/synth/Tag';
import Select from '~/synth/inputs/Select';
import amplitude from '~/utils/amplitude';
import { formatCurrency, getCustodianDisplayName } from '~/utils/format';
import { ScreenProps } from './Types';
import {
  CUSTODIAN_SELECTION_OPTIONS,
  UploadData,
  getLabelByFileType,
} from './screens/CsvUploadScreen';
import { ActionFooter, BackButton, ContentBox } from './screens/components';
import CsvUploadCard from './screens/components/CsvUploadCard';
import LearnMoreDrawer from './screens/components/CustodianLearnMoreDrawer';

interface TotalTableData {
  shortTermGainLoss: number;
  longTermGainLoss: number;
  totalGainLoss: number;
}

export default function DayOneClosedLotsScreen(props: ScreenProps) {
  const { onContinue, onBack } = props;
  const [fileName, setFileName] = useState<string>();
  const { existingPortfolio, clientId } = props.draftPortfolio.constructionInfo;
  const [fileFormat, setFileFormat] = useState<CsvFileType>();
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [custodianKey, setCustodianKey] = useState<Custodian>();
  const [closedLots, setClosedLots] = useState<{ gainLoss: number; capitalGainType: string }[]>();

  const handleDelete = async () => {
    if (
      !clientId ||
      !existingPortfolio ||
      existingPortfolio === 'sample-portfolio' ||
      !custodianKey
    )
      return;

    await deleteClosedLots({ accountId: existingPortfolio.id });
    setFileName(undefined);
    setClosedLots(undefined);
  };

  const handleUpload = async ({ fileType, file }: UploadData) => {
    if (
      !clientId ||
      !existingPortfolio ||
      existingPortfolio === 'sample-portfolio' ||
      !custodianKey
    )
      return;
    const result = await uploadClosedLots(
      await file.arrayBuffer(),
      fileType,
      existingPortfolio.id,
      file.type
    );
    setFileName(file.name);
    setClosedLots(result.data.closedLots);
  };

  const tableData: TotalTableData[] = useMemo(() => {
    if (!closedLots) {
      return [];
    }
    const result = closedLots.reduce(
      (acc, lot) => {
        if (lot.capitalGainType === 'SHORT_TERM') {
          acc.shortTermGainLoss += lot.gainLoss;
        } else if (lot.capitalGainType === 'LONG_TERM') {
          acc.longTermGainLoss += lot.gainLoss;
        }
        return acc;
      },
      { shortTermGainLoss: 0, longTermGainLoss: 0 }
    );
    return [
      {
        id: 1,
        totalGainLoss: result.shortTermGainLoss + result.longTermGainLoss,
        ...result,
      },
    ];
  }, [closedLots]);
  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    onContinue();
  }

  const handleCustodianChange = (e) => {
    amplitude().logEvent(`Change csv custodian to ${e.custodian} format ${e.value}`, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
    setFileFormat(e.value.fileType);
    setCustodianKey(e.value.custodian);
  };

  const tableColumns = [
    {
      field: 'totalGainLoss',
      headerName: 'Realized gain/loss ($/%)',
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
    },
    {
      field: 'shortTermGainLoss',
      headerName: 'Short-term gain/loss',
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
    },
    {
      field: 'longTermGainLoss',
      headerName: 'Long-term gain/loss',
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
    },
  ];

  return (
    <ContentBox>
      <form onSubmit={handleSubmit}>
        <Box my={2} display="flex">
          <Typography variant="h1">Upload a realized gains file.</Typography>
          <Box ml={1.5}>
            <Tag>Optional</Tag>
          </Box>
        </Box>
        <Box mb={2} color="grey.600">
          <Typography variant="body2">
            Vise does not receive trade activity from the custodian prior to our management. As a
            result, we are not aware of those trades and this account could be susceptible to wash
            sales without uploading this file.
          </Typography>
        </Box>

        <Box>
          {fileFormat !== 'generic' && fileFormat && (
            <ButtonBase
              sx={{ borderTop: 1, borderBottom: 1, borderColor: 'grey.200', paddingY: 2 }}
              onClick={() => {
                setDrawerOpen(!drawerOpen);
                amplitude().logEvent(`Open learn more drawer for csv upload`, {
                  category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                });
              }}
              style={{ width: '100%', display: 'block' }}
            >
              <Box display="flex" alignItems="center">
                <QuestionMarkCircleIcon />
                <Typography variant="h5" style={{ lineHeight: 1 }} ml={2}>
                  Learn how to import portfolio files from{' '}
                  {custodianKey == null ? 'N/A' : getCustodianDisplayName(custodianKey)}
                </Typography>
                <Box ml="auto" component="span">
                  <ChevronRightIcon width={16} height={16} />
                </Box>
              </Box>
            </ButtonBase>
          )}
          {custodianKey && (
            <LearnMoreDrawer
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              onXClick={() => setDrawerOpen(false)}
              custodian={custodianKey}
            />
          )}
        </Box>

        <>
          <Box my={2}>
            <Divider />
          </Box>

          <Box sx={{ marginBottom: 2 }}>
            <Select
              defaultValue={getLabelByFileType(fileFormat || null)}
              // TODO(mkiisa): temporarily disabled Manual CSV upload for realized gains files
              options={CUSTODIAN_SELECTION_OPTIONS.slice(0, 3)}
              onChange={handleCustodianChange}
            />
          </Box>
        </>

        <CsvUploadCard
          fileName={fileName}
          allowUpload={!!fileFormat}
          onDelete={handleDelete}
          onUpload={async (file) => {
            if (!fileFormat) return;
            await handleUpload({ file, fileType: fileFormat });
          }}
          fileLoading={fileLoading}
          setFileLoading={setFileLoading}
        />

        {tableData.length > 0 && (
          <Box mt={6}>
            <Box
              p={3}
              sx={{ borderColor: 'grey.200' }}
              border={1}
              borderRadius="4px"
              borderBottom={0}
            >
              <Typography variant="h1">Total known </Typography>
            </Box>

            <DataGridPro rows={tableData} columns={tableColumns} />
          </Box>
        )}

        <Box py={5.5}>
          <Divider />
        </Box>

        <ActionFooter justifyContent="space-between">
          <BackButton onClick={() => onBack()}>Back</BackButton>
          <Button color="primary" type="submit" variant="contained">
            Continue
          </Button>
        </ActionFooter>
      </form>
    </ContentBox>
  );
}
