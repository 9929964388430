import { PortfolioPositionResponse } from 'vise-types/portfolio';
import usePositions from './usePositions';

export function calculateValueFromPositions(
  data: PortfolioPositionResponse,
  lockedPositionsSet: Set<string>,
  targetCashFraction: number
) {
  const { totalValue, lockedUnrecognizedValue } = data.positions.reduce(
    ({ totalValue, lockedUnrecognizedValue }, currentPosition) => {
      const isUnknown =
        !currentPosition.assetClass || currentPosition.assetClass.includes('UNKNOWN');

      const positionValue = currentPosition.marketValue;
      return {
        totalValue: totalValue + positionValue,
        lockedUnrecognizedValue:
          lockedUnrecognizedValue +
          (isUnknown && lockedPositionsSet.has(currentPosition.symbolOrCusip) ? positionValue : 0),
      };
    },
    { totalValue: 0, lockedUnrecognizedValue: 0 }
  );

  const viseManagedValue = totalValue * (1 - targetCashFraction) - lockedUnrecognizedValue;
  return {
    data: { viseManagedValue: viseManagedValue >= 0 ? viseManagedValue : 0, totalValue },
    error: undefined,
  };
}

/*
 * Calculate Vise-managed value and total value based on an account's positions. Vise-managed value is the
 * portion of the account that excludes target cash allocation and locked+unrecognized positions.
 * This manually calculates it with locked positions and target cash as inputs so it can be used in
 * creator flow where these things will change. Two points to recognized
 *
 * 1) Cash allocation is out of the total value, so theoretically this could go negative. To deal with this we
 * have a floor of 0.
 *
 * 2) We use PCE2 instrument data where possible to determine value.
 */
export default function useAccountValue(
  accountNumber: string | undefined | null,
  custodianId: string | undefined | null,
  lockedPositions: string[],
  targetCashFraction = 0
) {
  const { error, data } = usePositions(
    accountNumber,
    custodianId,
    true /* includePCE2Instruments */
  );
  if (error != null) {
    return { data: undefined, error };
  }

  const lockedPositionsSet = new Set(lockedPositions);

  if (data != null) {
    return calculateValueFromPositions(data, lockedPositionsSet, targetCashFraction);
  }

  return { data: undefined, error };
}
