import { Route } from '~/models/shared';
import AccountProposals from './AccountProposals/AccountProposals';
import BondPortfolio from './BondPortfolio/BondPortfolio';
import BondProposal from './BondPortfolio/BondProposal';
import BondPortfolioCreator from './BondPortfolioCreator';
import BulkUpdate from './BulkUpdate/BulkUpdate';
import Dashboard from './Dashboard';
import DashboardHistory from './Dashboard/DashboardHistory';
import Disclosures from './Disclosures/Disclosures';
import AccountDistributions from './Distributions/AccountDistributions';
import AddOrEditDistribution from './Distributions/AddOrEditDistribution/AddOrEditDistribution';
import GlobalDistributions from './Distributions/GlobalDistributions';
import GPTDashboard from './GPT/GPTDashboard';
import AccountRemovalRequest from './Households/AccountRemovalRequest';
import HouseholdOverview from './Households/HouseholdOverview';
import Households from './Households/Households/Households';
import LiquidateAccount from './Households/LiquidateAccount/LiquidateAccount';
import LiquidateAccountConfirmation from './Households/LiquidateAccount/LiquidateAccountConfirmation';
import ManageClient from './Households/ManageClient';
import ManageHousehold from './Households/ManageHousehold';
import Login from './Login/Login';
import ModelTemplateCenter from './ModelTemplateCenter';
import TemplateLandingPage from './ModelTemplateCenter/TemplateLandingPage';
import ReviewProposals from './ModelTemplateCenter/workflows/ReviewProposals';
import TemplateCreate from './ModelTemplateCenter/workflows/TemplateCreate';
import TemplateEdit from './ModelTemplateCenter/workflows/TemplateEdit';
import TemplateLink from './ModelTemplateCenter/workflows/TemplateLink';
import ViewProposalInJob from './ModelTemplateCenter/workflows/ViewProposalInJob';
import NotFound from './NotFound/NotFound';
import OnboardingWelcome from './Onboarding/OnboardingWelcome';
import Portfolio2 from './Portfolio/Portfolio2';
import PortfolioPreview from './Portfolio/PortfolioPreview';
import PortfolioRedirect from './Portfolio/PortfolioRedirect';
import Proposal from './Portfolio/Proposal';
import PortfolioCreator2 from './PortfolioCreator2/PortfolioCreator2';
import XrayLandingPage from './PortfolioXray';
import ProductOnboarding from './ProductOnboarding';
import ConfirmEmail from './Registration/ConfirmEmail';
import Registration from './Registration/Registration';
import Settings from './Settings/Settings';
import MainLayout from './layouts/MainLayout';
import SidebarLayout from './layouts/SidebarLayout';

const routes: Route[] = [
  {
    component: Login,
    exact: false,
    path: '/login',
  },
  {
    component: Registration,
    exact: true,
    path: '/register',
  },
  {
    component: ConfirmEmail,
    exact: true,
    path: '/confirm-email/:confirmationCode',
  },
  {
    component: NotFound,
    exact: true,
    path: '/404',
  },
  {
    component: Disclosures,
    exact: true,
    path: '/disclosures',
  },
  {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'FunctionComponent<{ route: Route; }>' is not... Remove this comment to see the full error message
    component: MainLayout,
    routes: [
      {
        authenticate: true,
        component: OnboardingWelcome,
        exact: true,
        path: '/secure/onboarding-welcome/:activeStep?',
      },
      {
        authenticate: true,
        component: PortfolioCreator2,
        exact: false,
        path: '/secure/portfolio-creator-next',
      },
      {
        authenticate: true,
        component: AddOrEditDistribution,
        exact: false,
        path: '/secure/new-distribution',
      },
      {
        authenticate: true,
        component: AddOrEditDistribution,
        exact: false,
        path: '/secure/accounts/:accountId/new-distribution',
      },
      {
        authenticate: true,
        component: AddOrEditDistribution,
        exact: false,
        path: '/secure/accounts/:accountId/distributions/:scheduleId/edit',
      },
      {
        authenticate: true,
        component: TemplateCreate,
        exact: true,
        path: '/secure/template/:templateType/create',
      },
      {
        authenticate: true,
        component: TemplateLink,
        exact: true,
        path: '/secure/template/link/:parentTemplateId',
      },
      {
        component: TemplateEdit,
        exact: true,
        path: '/secure/template/:templateType/edit/:templateId',
      },
      {
        component: ReviewProposals,
        exact: true,
        path: '/secure/template/job/:jobId',
      },
      {
        component: ViewProposalInJob,
        exact: false,
        path: '/secure/strategy-center-proposal/:jobId/:intelligenceId',
      },
      {
        component: ProductOnboarding,
        exact: false,
        path: '/secure/product-onboarding/onboard-client',
      },
      {
        authenticate: true,
        component: GPTDashboard,
        exact: true,
        path: '/secure/intelligence',
      },
      {
        authenticate: true,
        component: GPTDashboard,
        exact: true,
        path: '/secure/intelligence/:sessionId',
      },
      { component: BulkUpdate, exact: true, path: '/secure/update' },
      {
        component: BondPortfolioCreator,
        exact: false,
        path: '/secure/bond-portfolio-creator',
      },
      {
        authenticate: true,
        component: DashboardHistory,
        exact: true,
        path: '/secure/dashboard/history',
      },
      {
        authenticate: true,
        component: GlobalDistributions,
        exact: false,
        path: '/secure/md/distributions',
      },
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'FunctionComponent<{ route: Route; }>' is not... Remove this comment to see the full error message
        component: SidebarLayout,
        routes: [
          {
            authenticate: true,
            component: ProductOnboarding,
            exact: false,
            path: '/secure/product-onboarding',
          },
          {
            authenticate: true,
            component: ModelTemplateCenter,
            exact: false,
            path: '/secure/strategy-center',
          },
          {
            authenticate: true,
            component: TemplateLandingPage,
            exact: false,
            path: '/secure/view-template/:templateId',
          },
          { authenticate: true, component: XrayLandingPage, exact: false, path: '/secure/x-ray' },
          {
            authenticate: true,
            component: PortfolioPreview,
            exact: true,
            path: '/secure/portfolio-preview/:intelligenceId',
          },
          {
            authenticate: true,
            component: Proposal,
            exact: false,
            path: '/secure/proposal/:intelligenceId',
          },
          {
            authenticate: true,
            component: BondProposal,
            exact: false,
            path: '/secure/bond-proposal/:proposalId',
          },
          {
            authenticate: true,
            component: AccountProposals,
            exact: true,
            path: '/accounts/:accountId/proposals',
          },
          // Even though all new traffic from navigating on the site should leader
          // to '/secure/accounts/:accountId/portfolio', we are keeping this route
          // for backwards compatibility.
          {
            authenticate: true,
            component: PortfolioRedirect,
            exact: true,
            path: '/secure/clients/:clientId/portfolios/:custodianId/:portfolioId',
          },
          {
            authenticate: true,
            component: Portfolio2,
            exact: false,
            path: '/secure/accounts/:accountId/portfolio',
          },
          {
            authenticate: true,
            component: BondPortfolio,
            exact: false,
            path: '/secure/accounts/:accountId/bond-portfolio',
          },
          {
            authenticate: true,
            component: AccountDistributions,
            exact: false,
            path: '/secure/accounts/:accountId/distributions',
          },
          {
            authenticate: true,
            component: LiquidateAccount,
            exact: false,
            path: '/secure/accounts/:accountId/liquidate',
          },
          {
            authenticate: true,
            component: LiquidateAccountConfirmation,
            exact: false,
            path: '/secure/accounts/:accountId/confirm',
          },
          {
            authenticate: true,
            component: GlobalDistributions,
            exact: false,
            path: '/secure/distributions',
          },
          {
            authenticate: true,
            component: Households,
            exact: true,
            path: '/secure/households/:tabName',
          },
          {
            authenticate: true,
            component: HouseholdOverview,
            exact: true,
            path: '/secure/households/:householdId/overview',
          },
          {
            authenticate: true,
            component: ManageHousehold,
            exact: true,
            path: '/secure/households/:householdId/manage',
          },
          {
            authenticate: true,
            component: ManageClient,
            exact: true,
            path: '/secure/households/:householdId/client/:clientId/manage',
          },
          {
            authenticate: true,
            component: Households,
            exact: true,
            path: '/secure/households',
          },
          {
            authenticate: true,
            component: Dashboard,
            exact: true,
            path: '/secure/dashboard',
          },
          {
            authenticate: true,
            component: AccountRemovalRequest,
            exact: true,
            path: '/secure/client/accounts/:accountId/remove',
          },
          {
            authenticate: true,
            component: Disclosures,
            exact: true,
            path: '/secure/disclosures',
          },
          {
            authenticate: true,
            component: Settings,
            exact: false,
            path: '/secure/settings',
            routes: [
              {
                authenticate: true,
                component: Settings,
                exact: true,
                path: '/secure/settings/account-details',
              },
              {
                authenticate: true,
                component: Settings,
                exact: true,
                path: '/secure/settings/investor-details',
              },
            ],
          },
          {
            notFound: true,
            path: '/:noMatch',
          },
        ],
      },
    ],
  },
  {
    notFound: true,
    path: '/:noMatch',
  },
];

export default routes;
