import { ActionType } from '../constants';
import { tokenStore } from '../utils';

interface User {
  id: string;
}

const initialState = {
  // { userId => user }
  users: {},
  currentUser: null,
  mfaToken: null,

  fetchingUsers: false,
  loggingIn: false,
};

const extractUserInfo = (info) => {
  if (!info) {
    return null;
  }

  return {
    id: info.id,
    email: info.email,
    firstName: info.firstName,
    lastName: info.lastName,
    lastLogin: info.lastLogin,
    isStaff: info.isStaff,
    isSuperuser: info.isSuperuser,
    organization: info.organization,
    portfolioMaxSize: info.portfolioMaxSize,
  };
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.UPSERT_USER_FULFILLED: {
      const { user } = action;

      return {
        ...state,
        users: {
          ...state.users,
          [user.id]: user,
        },
      };
    }

    case ActionType.GET_USERS_REQUESTED: {
      return {
        ...state,
        fetchingUsers: true,
      };
    }

    case ActionType.GET_USERS_FULFILLED: {
      const { users } = action;

      const userMap = users.reduce(
        (accu, user: User) => ({
          ...accu,
          [user.id]: user,
        }),
        {}
      );

      return {
        ...state,
        fetchingUsers: false,
        users: {
          ...state.users,
          ...userMap,
        },
      };
    }

    case ActionType.GET_USER_INFO_REQUESTED: {
      return {
        ...state,
        fetchingUsers: true,
      };
    }

    case ActionType.GET_USER_INFO_FULFILLED: {
      const { user } = action;

      return {
        ...state,
        fetchingUsers: false,
        currentUser: user,
      };
    }

    case ActionType.LOGIN_USER_FAILED: {
      return {
        ...state,
        loggingIn: false,
      };
    }

    case ActionType.LOGIN_USER_REQUESTED: {
      return {
        ...state,
        loggingIn: true,
      };
    }

    case ActionType.MFA_REQUIRED: {
      const { data } = action;

      return {
        ...state,
        mfaToken: data ? data.mfaToken : null,
      };
    }

    case ActionType.MFA_CANCELLED: {
      return {
        ...state,
        mfaToken: null,
      };
    }

    case ActionType.LOGIN_USER_FULFILLED: {
      const { loginResponse } = action;

      if (loginResponse) {
        tokenStore.setAuthToken({
          accessToken: loginResponse.accessToken,
          refreshToken: loginResponse.refreshToken,
        });
      }

      const currentUser = extractUserInfo(loginResponse);

      return {
        ...state,
        loggingIn: false,
        currentUser,
      };
    }

    case ActionType.LOGOUT_USER_FULFILLED: {
      tokenStore.clear();

      return {
        ...state,
        currentUser: null,
      };
    }

    case ActionType.REFRESH_TOKEN_FULFILLED: {
      const { refreshResponse } = action;

      if (refreshResponse) {
        tokenStore.setAuthToken({
          accessToken: refreshResponse.accessToken,
          refreshToken: refreshResponse.refreshToken,
        });
      }

      const currentUser = extractUserInfo(refreshResponse);

      return {
        ...state,
        currentUser,
      };
    }

    case ActionType.VERIFY_USER: {
      const { verifyResponse } = action;
      const currentUser = extractUserInfo(verifyResponse);

      return {
        ...state,
        currentUser,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
