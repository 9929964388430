import useSWR from 'swr';
import { getPortfolioIntelligenceOrders } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getOrdersSwrKey = (intelligenceId: string) =>
  generateSwrKey(`/portfolio/intelligence/${intelligenceId}/orders`);

export default function useIntelligenceOrders(intelligenceId: string | undefined | null) {
  return useSWR(
    intelligenceId ? getOrdersSwrKey(intelligenceId) : null,
    intelligenceId == null ? undefined : () => getPortfolioIntelligenceOrders(intelligenceId)
  );
}
