import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as ArrowUpIcon } from '~/static/images/icons/chevron-up.svg';
import { ReactComponent as ArrowDownIcon } from '~/static/images/icons/chevron-down.svg';
import { DataTable } from '~/synth/DataTable';
import { Column } from 'react-table';
import { keyBy, map, uniq } from 'lodash';
import { TemplateUpdateJobStatus } from '~/models/api';
import { TextHighlightTag } from '~/synth/Tag';
import { useHistory } from 'react-router';
import CardHeader from '~/synth/CardHeader';
import { GetModelTemplateCenterViewDataResponse } from 'vise-types/template';
import useInProgressJobs from '~/hooks/useInProgressJobs';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import { ReactComponent as DotsHorizontalIcon } from '~/static/images/icons/dots-horizontal.svg';
import { updateLinkedAccounts } from '~/api/api';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import amplitude from '~/utils/amplitude';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { getStaleAccountsFromViewData } from './utils';
import { EventCell, TemplateCell } from './workflows/CommonComponents';

interface NewProposalsTable {
  template: {
    name: string;
    type: 'allocations' | 'restrictions';
  };
  event: {
    type: 'LINK' | 'UPDATE';
    submittedByName: string;
  };
  status: {
    progress: number;
    status: TemplateUpdateJobStatus;
    reviewProposals: () => void;
  };
}

interface ActionItemsTable {
  template: {
    name: string;
    type: 'allocations' | 'restrictions';
  };
  event: {
    type: 'EDIT';
    submittedByName: string;
  };
  description: string;
  actions: React.ReactNode;
}

function NumberCircle({ children, bgColor }: { children: React.ReactNode; bgColor: string }) {
  return (
    <Box
      borderRadius="10px"
      width="20px"
      height="20px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={bgColor}
      color="white"
    >
      {children}
    </Box>
  );
}

function getDismissedActionItems() {
  const dismissedValue = localStorage.getItem('dismissedActionItems');
  const result = dismissedValue ? JSON.parse(dismissedValue) : [];
  return result;
}

function dismissAnActionItem({ templateId }: { templateId: string }) {
  const dismissedIds = getDismissedActionItems();
  const newIds = uniq([...dismissedIds, templateId]);
  localStorage.setItem('dismissedActionItems', JSON.stringify(newIds));
}

const StyledLinearProgress = withStyles(LinearProgress, (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
  bar1Determinate: {
    backgroundColor: theme.palette.accents.lavender.main,
  },
}));

function ProgressCell({
  value: { progress, status, reviewProposals },
}: {
  value: {
    progress: number;
    status: TemplateUpdateJobStatus;
    reviewProposals: () => void;
  };
}) {
  if (status === TemplateUpdateJobStatus.PENDING) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="40px">
          <StyledLinearProgress variant="determinate" value={progress * 100} />
        </Box>
        <Box ml={1.5} flex={1}>
          {(progress * 100).toFixed(0)}% done
        </Box>
        <Box>
          <Button variant="outlined" disabled>
            Review proposals
          </Button>
        </Box>
      </Box>
    );
  }
  if (status === TemplateUpdateJobStatus.PROPOSALS_CREATED) {
    return (
      <Box display="flex" alignItems="center">
        <TextHighlightTag severity="alert">Ready for review</TextHighlightTag>
        <Box flex={1} />
        <Button variant="outlined" onClick={reviewProposals}>
          Review proposals
        </Button>
      </Box>
    );
  }
  return null;
}

const actionItemsColumns: Column<ActionItemsTable>[] = [
  {
    Header: () => <Box ml={1}>Template</Box>,
    accessor: 'template',
    Cell: TemplateCell,
  },
  {
    Header: 'Event',
    accessor: 'event',
    Cell: EventCell,
  },
  { Header: 'Description', accessor: 'description' },
  {
    Header: '',
    accessor: 'actions',
  },
];

const newProposalsColumns: Column<NewProposalsTable>[] = [
  {
    Header: () => <Box ml={1}>Name</Box>,
    accessor: 'template',
    Cell: TemplateCell,
    width: '25%',
  },
  {
    Header: 'Event',
    accessor: 'event',
    Cell: EventCell,
    width: '18%',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ProgressCell,
  },
];

export default function OperationSummary({
  data,
  mutateData,
  mt = 4,
}: {
  data: GetModelTemplateCenterViewDataResponse;
  mutateData: () => void;
  mt?: number;
}) {
  const { data: jobs, mutate } = useInProgressJobs();
  const templatesByParentIds = useMemo(() => {
    return keyBy([...data.allocationsTemplates, ...data.restrictionsTemplates], (t) => t.parentId);
  }, [data.allocationsTemplates, data.restrictionsTemplates]);
  const history = useHistory();

  // Provide a smooth, fake progress bar. We will use it as a floor for the progress up to 10%
  // It allocates 3s per proposal, ticking every 1s.
  const [fakeProgressByJobId, setFakeProgressByJobId] = useState<{ [key: string]: number }>({}); // jobId -> progress
  useEffect(() => {
    const interval = setInterval(() => {
      setFakeProgressByJobId((prev) => {
        const newFakeProgressByJobId = { ...prev };
        jobs?.data.forEach((j) => {
          if (!newFakeProgressByJobId[j.id]) {
            newFakeProgressByJobId[j.id] = 0;
          }
          if (j.status === TemplateUpdateJobStatus.PENDING) {
            newFakeProgressByJobId[j.id] = Math.min(
              0.1,
              newFakeProgressByJobId[j.id] + 1 / (3 * j.events.length)
            );
          }
        });
        return newFakeProgressByJobId;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [jobs?.data]);
  const theme = useTheme();
  const enqueueCoachmark = useEnqueueCoachmark();
  const freshTemplateIdsToStaleAccountIds = getStaleAccountsFromViewData(data, jobs?.data);

  const dismissedActionItems = new Set(getDismissedActionItems());
  const result = Object.entries(freshTemplateIdsToStaleAccountIds).filter(
    (item) => !dismissedActionItems.has(item[0])
  );
  const filteredFreshTemplateIdsToStaleAccountIds = Object.fromEntries(result);

  const actionItemsTableData: ActionItemsTable[] = useMemo(() => {
    return map(filteredFreshTemplateIdsToStaleAccountIds, (staleAccountIds, templateId) => {
      return {
        template: {
          name: templatesByParentIds[templateId]?.name || '',
          type: templatesByParentIds[templateId]?.type,
        },
        event: {
          type: 'EDIT',
          submittedByName: `${templatesByParentIds[templateId]?.editedBy.firstName} ${templatesByParentIds[templateId]?.editedBy.lastName}`,
        },
        description: `Updates available for ${staleAccountIds.length} linked account${
          staleAccountIds.length > 1 ? 's' : ''
        }`,
        actions: (
          <Box display="flex" justifyContent="end">
            <DropdownButtonMenu
              isIcon
              buttonContent={<DotsHorizontalIcon color={theme.palette.grey[800]} />}
              buttonProps={{ 'aria-label': 'Action item menu' }}
            >
              {(close) => [
                <DropdownButtonMenuItem
                  key="update linked accounts"
                  onClick={() => {
                    updateLinkedAccounts(templateId, [], staleAccountIds, 'UPDATE')
                      .catch(() => {
                        enqueueCoachmark({
                          title: 'Failed to update accounts',
                          content: 'Please reach out to clientservice@vise.com for help.',
                          severity: 'error',
                        });
                      })
                      .then(() => {
                        mutate();
                        mutateData();
                      });
                    close();
                  }}
                >
                  Update linked accounts
                </DropdownButtonMenuItem>,
                <DropdownButtonMenuItem
                  key="dismiss"
                  onClick={() => {
                    dismissAnActionItem({ templateId });
                    mutateData();
                    close();
                  }}
                  sx={{ color: theme.palette.error[400] }}
                >
                  Dismiss
                </DropdownButtonMenuItem>,
              ]}
            </DropdownButtonMenu>
          </Box>
        ),
      };
    });
  }, [
    filteredFreshTemplateIdsToStaleAccountIds,
    templatesByParentIds,
    theme.palette.grey,
    theme.palette.error,
    enqueueCoachmark,
    mutate,
    mutateData,
  ]);

  const newProposalsTableData = useMemo(() => {
    return (jobs?.data
      .map((j) => {
        const template = templatesByParentIds[j.templateId];
        const numberErrors = j.events.filter((e) => e.status === 'PROPOSAL_ERROR').length;
        const numberProposals = j.events.filter((e) => e.status === 'PROPOSAL_CREATED').length;
        return {
          name: template?.name,
          templateType: template?.type === 'restrictions' ? 'Restriction' : 'Allocation',
          template: {
            name: template?.name || '',
            type: template?.type,
          },
          event: {
            type: j.reason,
            submittedByName: `${j.submittedBy.firstName} ${j.submittedBy.lastName}`,
          },
          status: {
            progress: Math.max(
              (numberErrors + numberProposals) / j.events.length,
              fakeProgressByJobId[j.id] || 0
            ),
            status: j.status,
            reviewProposals: () => {
              history.push(`/secure/template/job/${j.id}`);
              amplitude().logEvent('Click review proposals', {
                category: EVENT_CATEGORIES.STRATEGY_CENTER,
                jobId: j.id,
              });
            },
          },
          createdAt: j.createdAt,
        };
      })
      .sort((job1, job2) => job2.createdAt.localeCompare(job1.createdAt)) ||
      []) as NewProposalsTable[];
  }, [jobs, history, templatesByParentIds, fakeProgressByJobId]);
  const [tabStateChoice, setTabState] = useState<string>('NEW_PROPOSALS');

  let tabState = tabStateChoice;
  if (actionItemsTableData.length === 0 && newProposalsTableData.length > 0) {
    tabState = 'NEW_PROPOSALS';
  } else if (actionItemsTableData.length > 0 && newProposalsTableData.length === 0) {
    tabState = 'ACTION_ITEMS';
  }

  const [expanded, setExpanded] = useState<boolean>(true);
  if (!jobs?.data || (!newProposalsTableData.length && !actionItemsTableData.length)) {
    // TODO empty state
    return null;
  }
  return (
    <Box mt={mt} border={0.5} borderColor={theme.palette.grey[200]}>
      <Card>
        <CardHeader>
          <Box display="flex" alignItems="center">
            <Typography variant="h2">Notifications</Typography>
            <Box flex={1} />
            <Box alignItems="center" display="flex">
              <Button
                endIcon={expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
                onClick={() => setExpanded(!expanded)}
              >
                View details
              </Button>
            </Box>
          </Box>
        </CardHeader>
        <Collapse in={expanded}>
          <Box mx={3}>
            <Tabs value={tabState} onChange={(ev, newValue) => setTabState(newValue)}>
              {newProposalsTableData.length ? (
                <Tab
                  label={
                    <Box display="flex" alignItems="center">
                      <NumberCircle bgColor={theme.palette.purple[500]}>
                        {newProposalsTableData.length}
                      </NumberCircle>
                      <Box ml={1}>New proposals</Box>
                    </Box>
                  }
                  value="NEW_PROPOSALS"
                />
              ) : null}
              {actionItemsTableData.length ? (
                <Tab
                  label={
                    <Box display="flex" alignItems="center">
                      <NumberCircle bgColor={theme.palette.orange[600]}>
                        {actionItemsTableData.length}
                      </NumberCircle>
                      <Box ml={1}>Action items</Box>
                    </Box>
                  }
                  value="ACTION_ITEMS"
                />
              ) : null}
            </Tabs>
          </Box>
          <Divider />
          {tabState === 'NEW_PROPOSALS' && (
            <Box>
              <DataTable
                columns={newProposalsColumns}
                data={newProposalsTableData}
                m={0}
                rowSize="large"
              />
            </Box>
          )}
          {tabState === 'ACTION_ITEMS' && (
            <Box>
              <DataTable
                columns={actionItemsColumns}
                data={actionItemsTableData}
                m={0}
                rowSize="large"
              />
            </Box>
          )}
        </Collapse>
      </Card>
    </Box>
  );
}
