import React from 'react';
import { Button, ButtonProps } from '@mui/material';

interface CreatePortfolioButtonProps extends ButtonProps {
  loading?: boolean;
}

export default function CreatePortfolioButton({
  loading = false,
  ...rest
}: CreatePortfolioButtonProps) {
  return loading ? (
    <Button {...rest} color="primary" disabled variant="contained">
      Creating proposal…
    </Button>
  ) : (
    <Button {...rest} color="primary" variant="contained">
      Create proposal
    </Button>
  );
}
