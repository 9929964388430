import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from '~/synth/DatePicker';
import Select from '~/synth/inputs/Select';

export type PresetDateRangeValue =
  | 'all time'
  | 'month'
  | '3 months'
  | '6 months'
  | 'year'
  | 'ytd'
  | 'custom';

interface DateRangeSelectorProps {
  initialRange: PresetDateRangeValue;
  firstAvailableDate: moment.MomentInput;
  lastAvailableDate: moment.MomentInput;
  disabled?: boolean;
  /** Range in ms is passed if user picks a pre selected range. Max and min dates (as Moment objects)
   * are passed if the user picks a custom date via the date fields.
   */
  onChangeRange: (
    range: { max: moment.Moment | null; min: moment.Moment | null },
    presetUsed?: PresetDateRangeValue
  ) => void;
  hidePresets?: boolean;
}

interface PresetDateRangeOption {
  label: string;
  value: PresetDateRangeValue;
}

const PRESET_DATE_RANGE_OPTIONS = [
  {
    label: 'All time',
    value: 'all time',
  },
  {
    label: 'Past month',
    value: 'month',
  },
  {
    label: 'Past 3 months',
    value: '3 months',
  },
  {
    label: 'Past 6 months',
    value: '6 months',
  },
  {
    label: 'Past year',
    value: 'year',
  },
  {
    label: 'Year to date',
    value: 'ytd',
  },
  { label: 'Custom', value: 'custom' },
] as PresetDateRangeOption[];

function maxMoment(moment1: moment.Moment, moment2: moment.Moment) {
  return moment1.isAfter(moment2) ? moment1 : moment2;
}

export default function DateRangeSelector({
  initialRange,
  firstAvailableDate,
  lastAvailableDate,
  disabled,
  onChangeRange,
  hidePresets,
}: DateRangeSelectorProps) {
  const firstAvailableDateMoment = useMemo(() => moment(firstAvailableDate), [firstAvailableDate]);
  const lastAvailableDateMoment = useMemo(() => moment(lastAvailableDate), [lastAvailableDate]);
  const [presetDateRangeValue, setPresetDateRangeSelectorValue] =
    useState<PresetDateRangeValue>(initialRange);
  const selectedPresetDateRangeOption = useMemo(
    () => PRESET_DATE_RANGE_OPTIONS.find((d) => d.value === presetDateRangeValue),
    [presetDateRangeValue]
  );

  // If the user selected "Custom" in the dropdown and we need to auto open
  // the two min and max calendars sequentially:
  const [shouldAutoOpenMaxCalendarAfterMin, setShouldAutoOpenMaxCalendarAfterMin] = useState(false);

  const presetDateRangeValueToDates = useMemo(
    () =>
      ({
        'all time': { max: lastAvailableDateMoment, min: firstAvailableDateMoment },
        month: {
          max: moment(),
          min: maxMoment(moment().subtract(1, 'month'), firstAvailableDateMoment),
        },
        '3 months': {
          max: moment(),
          min: maxMoment(moment().subtract(3, 'month'), firstAvailableDateMoment),
        },
        '6 months': {
          max: moment(),
          min: maxMoment(moment().subtract(6, 'month'), firstAvailableDateMoment),
        },
        year: {
          max: moment(),
          min: maxMoment(moment().subtract(1, 'year'), firstAvailableDateMoment),
        },
        ytd: { max: moment(), min: maxMoment(moment().startOf('year'), firstAvailableDateMoment) },
      } as { [key in PresetDateRangeValue]: { max: moment.Moment; min: moment.Moment } }),
    [firstAvailableDateMoment, lastAvailableDateMoment]
  );
  const [minDateInputValue, setMinDateInputValue] = useState<moment.Moment | null>(
    presetDateRangeValueToDates[initialRange]?.min ?? firstAvailableDateMoment
  );
  const [maxDateInputValue, setMaxDateInputValue] = useState<moment.Moment | null>(
    presetDateRangeValueToDates[initialRange]?.max ?? lastAvailableDateMoment
  );

  const validateCustomDateCommon = (
    date: moment.Moment | null | undefined
  ): date is moment.Moment => {
    return !!date && date.isValid();
  };

  const validateRange = (
    range: [min: moment.Moment | null | undefined, max: moment.Moment | null | undefined]
  ): range is [moment.Moment, moment.Moment] => {
    return (
      validateCustomDateCommon(range[0]) &&
      validateCustomDateCommon(range[1]) &&
      range[0].isBefore(range[1])
    );
  };

  const isRangeValidAndInChartBounds = (
    range: [min: moment.Moment | null | undefined, max: moment.Moment | null | undefined]
  ): boolean => {
    return (
      validateRange(range) &&
      range[0].isSameOrAfter(firstAvailableDate) &&
      range[1].isSameOrBefore(lastAvailableDate)
    );
  };

  const minDateInputHasError =
    !validateCustomDateCommon(minDateInputValue) ||
    (!!maxDateInputValue && !validateRange([minDateInputValue, maxDateInputValue]));
  const maxDateInputHasError =
    !validateCustomDateCommon(maxDateInputValue) ||
    (!!minDateInputValue && !validateRange([minDateInputValue, maxDateInputValue]));

  useEffect(() => {
    if (presetDateRangeValue !== 'custom') {
      const newRange =
        selectedPresetDateRangeOption == null
          ? { max: null, min: null }
          : presetDateRangeValueToDates[selectedPresetDateRangeOption.value];
      onChangeRange(newRange, presetDateRangeValue);
      setMinDateInputValue(newRange.min);
      setMaxDateInputValue(newRange.max);
    } else if (isRangeValidAndInChartBounds([minDateInputValue, maxDateInputValue])) {
      onChangeRange({ min: minDateInputValue, max: maxDateInputValue });
    } else {
      // If the first available date or last available date change and the current min
      // and max inputs are not valid or not within the chart range, reset this selector
      // to original setting.
      const newRange = presetDateRangeValueToDates[initialRange];
      setPresetDateRangeSelectorValue(initialRange);
      setMinDateInputValue(newRange.min);
      setMaxDateInputValue(newRange.max);
      onChangeRange(newRange, presetDateRangeValue);
    }

    // Only need to execute this effect if the first available date and/or last available date change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstAvailableDate, lastAvailableDate, presetDateRangeValueToDates]);

  return (
    <Box display="flex">
      {!hidePresets && (
        <Box mr={1.5} minWidth={155}>
          <Select<PresetDateRangeOption>
            size="small"
            fullWidth
            isSearchable={false}
            isDisabled={disabled}
            onChange={(origOption) => {
              const option = origOption as PresetDateRangeOption;
              setPresetDateRangeSelectorValue(option.value);
              if (option.value !== 'custom') {
                const newMax = presetDateRangeValueToDates[option.value].max;
                const newMin = presetDateRangeValueToDates[option.value].min;
                setMaxDateInputValue(newMax);
                setMinDateInputValue(newMin);
                onChangeRange(
                  {
                    max: newMax,
                    min: newMin,
                  },
                  option.value
                );
              } else {
                setShouldAutoOpenMaxCalendarAfterMin(true);
              }
            }}
            options={PRESET_DATE_RANGE_OPTIONS}
            value={selectedPresetDateRangeOption}
          />
        </Box>
      )}
      <Box maxWidth={150}>
        <DatePicker
          value={minDateInputValue}
          error={minDateInputHasError}
          size="small"
          disabled={disabled}
          minDate={firstAvailableDateMoment}
          maxDate={maxDateInputValue && maxDateInputValue.isValid() ? maxDateInputValue : moment()}
          onChange={(newCustomMinDate) => {
            if (presetDateRangeValue !== 'custom') {
              setPresetDateRangeSelectorValue('custom');
            }
            setMinDateInputValue(newCustomMinDate);
            if (shouldAutoOpenMaxCalendarAfterMin && validateCustomDateCommon(newCustomMinDate)) {
              setShouldAutoOpenMaxCalendarAfterMin(false);
            }
            if (validateRange([newCustomMinDate, maxDateInputValue])) {
              onChangeRange({ min: newCustomMinDate, max: maxDateInputValue });
            }
          }}
        />
      </Box>
      <Box mx={1} alignSelf="center">
        <Typography display="inline" variant="body2" color="textSecondary">
          to
        </Typography>
      </Box>
      <Box maxWidth={150}>
        <DatePicker
          value={maxDateInputValue}
          size="small"
          disabled={disabled}
          error={maxDateInputHasError}
          minDate={
            minDateInputValue && minDateInputValue.isValid()
              ? minDateInputValue
              : firstAvailableDateMoment
          }
          maxDate={lastAvailableDateMoment}
          onChange={(newCustomMaxDate) => {
            if (presetDateRangeValue !== 'custom') {
              setPresetDateRangeSelectorValue('custom');
            }
            setMaxDateInputValue(newCustomMaxDate);
            if (validateRange([minDateInputValue, newCustomMaxDate])) {
              onChangeRange({ min: minDateInputValue, max: newCustomMaxDate });
            }
          }}
        />
      </Box>
    </Box>
  );
}
