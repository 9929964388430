import useSWR from 'swr';
import { Sector, Subsector } from '~/models/api';
import { getSectors } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getSectorsSwrKey = (useLegacySectors?: boolean) =>
  generateSwrKey(`sectors?useLegacySectors=${useLegacySectors}`);
export const getIndexedSectorsSwrKey = (useLegacySectors?: boolean) =>
  generateSwrKey(`indexed-sectors?useLegacySectors=${useLegacySectors}`);

export default function useSectors(useLegacySectors?: boolean) {
  return useSWR(getSectorsSwrKey(useLegacySectors), () => getSectors(useLegacySectors));
}

export const useIndexedSectors = (useLegacySectors?: boolean) => {
  const { data: sectorsData, error: sectorsError } = useSectors(useLegacySectors);
  const { data, error, isValidating, mutate } = useSWR(
    () => (sectorsData ? getIndexedSectorsSwrKey(useLegacySectors) : null),
    () => {
      return Promise.resolve(
        (sectorsData || []).reduce<{ [key: string]: Sector | Subsector }>((acc, sector) => {
          acc[sector.key] = sector;
          sector.industries.forEach((subsector) => {
            acc[subsector.key] = subsector;
          });
          return acc;
        }, {})
      );
    }
  );

  return {
    data,
    isLoading: (!sectorsError && !sectorsData) || (!error && !data),
    isValidating,
    error: sectorsError || error,
    mutate,
  };
};
