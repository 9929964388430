import useSWR, { ConfigInterface } from 'swr';
import { getHouseholdProposals } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getHouseholdProposalsSwrKey = (householdId: string) =>
  generateSwrKey(`householdProposals/${householdId}`);

const useHouseholdProposals = (
  householdId: string | undefined | null,
  options?: ConfigInterface
) => {
  const { data, error, isValidating, mutate } = useSWR(
    householdId ? getHouseholdProposalsSwrKey(householdId) : null,
    householdId == null ? undefined : () => getHouseholdProposals(householdId),
    options
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export default useHouseholdProposals;
