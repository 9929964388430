import {
  Button,
  ThemeProvider,
  Typography,
  Box,
  Container,
  Card,
  Grid,
  useTheme,
  Divider,
  LinearProgress,
  Collapse,
  IconButton,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ReactComponent as ArrowUpIcon } from '~/static/images/icons/chevron-up.svg';
import { ReactComponent as ArrowDownIcon } from '~/static/images/icons/chevron-down.svg';
import {
  Dialog,
  ExclamationIcon,
  Link,
  Link as LinkComponent,
  Pill,
  TrashIcon,
  theme as dsTheme,
  tokens,
} from '@vise_inc/ds-vise';
import { format, parseISO } from 'date-fns';
import React, { useMemo, useRef, useState } from 'react';
import { GetModelTemplateCenterViewDataResponse, TemplateUpdateJob } from 'vise-types/template';
import {
  cancelBulkUpload,
  downloadBulkEditCSV,
  downloadUploadedBulkEditCSV,
  uploadBulkEditCSV,
} from '~/api/api';
import { ReactComponent as DownloadIcon } from '~/static/images/icons/download.svg';
import useInProgressJobs from '~/hooks/useInProgressJobs';
import useEnqueueToast from '~/hooks/useToast';
import { generateCsvDownload } from '~/utils/export';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';

function NoRows() {
  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
      <Typography variant="body1">
        No bulk edits in progress. Download a CSV to get started.
      </Typography>
    </Box>
  );
}

function PendingEdits({
  jobs,
  refetchJobs,
}: {
  jobs: TemplateUpdateJob[];
  refetchJobs: () => Promise<unknown>;
}) {
  const history = useHistory();
  const [deleteModalOpenId, setDeleteModalOpenId] = useState<undefined | string>(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const enqueueToast = useEnqueueToast();
  const columns = useMemo(
    () => [
      { field: 'name', headerName: 'Type', flex: 1 },
      {
        field: 'event',
        headerName: 'Event',
        flex: 1,
        renderCell: (props) => (
          <Box>
            <Typography variant="body1">Bulk update uploaded</Typography>
            <Box mt={tokens.SPACING_INDICES.xs}>
              <Typography variant="body3" color="neutralCool.600">
                {format(parseISO(props.value.createdAt), 'MM/dd/yyyy hh:mm:ss a')}
              </Typography>
            </Box>
            <Box mt={tokens.SPACING_INDICES.xs}>
              <LinkComponent
                variant="h5"
                linkVariant="primary"
                displayVariant="icon"
                component="button"
                onClick={async () => {
                  try {
                    const resp = await downloadUploadedBulkEditCSV(props.value.jobId);
                    if (resp.data) {
                      window.location.href = resp.data.url;
                    }
                  } catch (e) {
                    enqueueToast({
                      title: 'There was an error downloading the file. Please try again later.',
                      severity: 'error',
                    });
                  }
                }}
                icon={<DownloadIcon height={20} width={20} />}
              >
                {props.value.csvKey}
              </LinkComponent>
            </Box>
          </Box>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (props) => (
          <Pill variant={props.value === 'SCHEDULED' ? 'info' : 'in-progress'}>
            {props.value === 'SCHEDULED' ? 'Scheduled' : 'Ready for review'}
          </Pill>
        ),
      },
      {
        field: 'accountsToUpdate',
        headerName: 'Accounts to update',
        flex: 1,
      },
      {
        field: 'cta',
        headerName: '',
        flex: 1,
        renderCell: (props) => (
          <Box display="flex" justifyContent="end" width="100%">
            {props.value.status === 'SCHEDULED' ? (
              <Button variant="destructive" onClick={() => setDeleteModalOpenId(props.value.id)}>
                Delete
              </Button>
            ) : (
              <Button
                variant="tertiary"
                onClick={() => history.push(`/secure/template/job/${props.value.id}`)}
              >
                Review proposals
              </Button>
            )}
          </Box>
        ),
        sortable: false,
      },
    ],
    [history, enqueueToast]
  );
  const rows = useMemo(
    () =>
      jobs.map((job) => {
        return {
          name: '2024 Capital gains budget',
          event: {
            createdAt: job.createdAt,
            csvKey: 'Download input file',
            jobId: job.id,
          },
          id: job.id,
          status: job.status,
          accountsToUpdate: job.events.length,
          cta: {
            status: job.status,
            id: job.id,
          },
        };
      }),
    [jobs]
  );
  return (
    <Card>
      <Dialog
        variant="destructive"
        open={deleteModalOpenId != null}
        onClose={() => setDeleteModalOpenId(undefined)}
        onConfirm={async () => {
          try {
            setIsDeleting(true);
            if (deleteModalOpenId != null) {
              await cancelBulkUpload(deleteModalOpenId);
            }
          } catch (e) {
            enqueueToast({
              title: 'There was an error deleting the update. Please try again.',
              severity: 'error',
            });
          } finally {
            try {
              await refetchJobs();
            } catch (e) {
              // ignore
            }
            setIsDeleting(false);
            setDeleteModalOpenId(undefined);
          }
        }}
        size="small"
        title="Delete this request?"
        confirmation="Delete"
        disabled={isDeleting}
      >
        <Typography variant="body1" color="neutralCool.600">
          Your scheduled updates will not be processed and you will be required to resubmit your
          request.
        </Typography>
      </Dialog>
      <Box p={tokens.SPACING_INDICES.xl}>
        <Typography variant="h3">Pending requests</Typography>
      </Box>
      <DataGridPro
        columns={columns}
        rows={rows}
        autoHeight
        sx={{ borderBottomWidth: 0 }}
        slots={{ noRowsOverlay: NoRows }}
        rowHeight={72}
      />
    </Card>
  );
}

function BulkEditBody({ data }: { data: GetModelTemplateCenterViewDataResponse }) {
  const [disclosuresIn, setDisclosuresIn] = useState(false);
  const theme = useTheme();
  const enqueueToast = useEnqueueToast();
  const [isDownloading, setIsDownloading] = useState(false);
  const { data: inProgressJobs, mutate } = useInProgressJobs({
    bulkEdit: true,
    refreshInterval: 3000,
  });
  const fileSelectInputRef = useRef<HTMLInputElement | null>(null);
  const handleFileBrowse = (e) => {
    e.preventDefault();

    if (fileSelectInputRef.current == null) return;
    fileSelectInputRef.current.click();
  };

  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    hasError: false,
    fileName: '',
    title: '',
    content: '',
  });
  if (!inProgressJobs) {
    return null;
  }

  const uploadFile = async (file: File) => {
    try {
      setIsUploading(true);
      setErrorMessage({
        ...errorMessage,
        hasError: false,
      });
      const buffer = await file.arrayBuffer();
      const result = await uploadBulkEditCSV(buffer, file.name);
      if (!result.data.success && result.data.details) {
        // Issues with the CSV
        setErrorMessage({
          hasError: true,
          fileName: file.name,
          title: result.data.title || '',
          content: result.data.details,
        });
      } else {
        // Refetch pending jobs
        await mutate();
      }
    } catch (e) {
      enqueueToast({
        severity: 'error',
        title:
          e instanceof AxiosError && e.response?.data?.message
            ? e.response.data.message
            : 'There was an error uploading the file. Please try again.',
      });
    } finally {
      setIsUploading(false);
    }
  };

  const bulkEditJobs = inProgressJobs.data.filter((job) => job.reason === 'BULK_EDIT');

  const fileUploadDisabled = isUploading;

  return (
    <Container>
      <Box
        mt={tokens.SPACING_INDICES['3xl']}
        mx={tokens.SPACING_INDICES.lg}
        mb={tokens.SPACING_INDICES['2xl']}
      >
        <Typography variant="h2">Update 2024 capital gains budgets</Typography>
        <Box mt={tokens.SPACING_INDICES.sm}>
          <Typography variant="body1" color="neutralCool.600">
            Follow the below instructions to bulk update your client’s 2024 capital gains budgets.
          </Typography>
        </Box>
        <Box mt={tokens.SPACING_INDICES.xl}>
          <PendingEdits jobs={bulkEditJobs} refetchJobs={mutate} />
        </Box>
        <Box mt={tokens.SPACING_INDICES.xl}>
          <Grid container spacing={theme.spacing(tokens.SPACING_INDICES.xl)}>
            <Grid item lg={6} xs={12}>
              <Card sx={{ height: '100%' }}>
                <Box p={tokens.SPACING_INDICES.xl}>
                  <Typography variant="h4">Instructions</Typography>
                  <Box
                    sx={{
                      listStyle: 'decimal',
                      paddingLeft: theme.spacing(tokens.SPACING_INDICES.xl),
                      '& li': {
                        marginBottom: theme.spacing(tokens.SPACING_INDICES.lg),
                        color: theme.palette.neutralCool[600],
                      },
                    }}
                    component="ol"
                  >
                    <Typography variant="body1" component="li">
                      Click the “Download CSV” button on the right to download a list of
                      non-qualified accounts
                    </Typography>
                    <Typography variant="body1" component="li">
                      Fill out the CSV with updated budgets. Sample inputs are provided within the
                      CSV.
                    </Typography>
                    <Typography variant="body1" component="li">
                      Save and upload the updated file on the right.
                    </Typography>
                    <Typography variant="body1" component="li">
                      Once confirmed, the request will be scheduled. New proposals will be generated
                      and become available for your review and execution on{' '}
                      <Box display="inline" fontWeight={700}>
                        Jan 2 2024.
                      </Box>
                    </Typography>
                    <Typography variant="body1" component="li">
                      If you wish to delete a scheduled update, you can do so once a file has been
                      uploaded.
                    </Typography>
                    <Typography variant="body1" component="li">
                      Updates can be made until{' '}
                      <Box display="inline" fontWeight={700}>
                        Jan 16, 2024.
                      </Box>
                    </Typography>
                  </Box>
                  <Box mt={tokens.SPACING_INDICES.xl}>
                    <Typography variant="body1" color="neutralCool.600">
                      Please contact{' '}
                      <Link
                        displayVariant="inline"
                        linkVariant="primary"
                        href="mailto:clientservice@vise.com"
                        component="a"
                      >
                        Client Services
                      </Link>{' '}
                      if you have any questions.
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Card sx={{ height: '100%' }}>
                <Box p={tokens.SPACING_INDICES.xl}>
                  <Typography variant="h4">Accounts</Typography>
                  <Grid container mt={tokens.SPACING_INDICES.lg}>
                    <Grid item xs={12} lg={6}>
                      <Typography variant="h5" color="neutralCool.600">
                        Total non-qualified accounts
                      </Typography>
                      <Box mt={tokens.SPACING_INDICES.sm}>
                        <Typography variant="h3">
                          {data.accounts.filter((acct) => acct.taxable).length}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box sx={{ [theme.breakpoints.up('lg')]: { textAlign: 'right' } }}>
                        <Button
                          variant="secondary"
                          endIcon={<DownloadIcon />}
                          disabled={isDownloading}
                          onClick={() => {
                            setIsDownloading(true);
                            downloadBulkEditCSV()
                              .then((resp) => {
                                generateCsvDownload({
                                  csv: resp.data.csv,
                                  filename: 'capital-gains-budgets-2024.csv',
                                });
                              })
                              .catch(() =>
                                enqueueToast({
                                  title:
                                    'There was an error generating the download. Please try again later.',
                                  severity: 'error',
                                })
                              )
                              .finally(() => setIsDownloading(false));
                          }}
                        >
                          Download CSV
                        </Button>
                      </Box>
                    </Grid>
                    {isDownloading ? (
                      <Grid item xs={12}>
                        <Box mt={tokens.SPACING_INDICES.lg}>
                          <Box mb={tokens.SPACING_INDICES.sm}>
                            <Typography variant="body3" color="neutralCool.600">
                              Download in progress. This may take some time...
                            </Typography>
                          </Box>
                          <LinearProgress />
                        </Box>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Box mt={tokens.SPACING_INDICES['2xl']}>
                    <Divider />
                  </Box>
                  <Box mt={tokens.SPACING_INDICES.xl}>
                    <Typography variant="h4">File upload</Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: theme.spacing(tokens.SPACING_INDICES.lg),
                      height: '270px',
                      border: `dashed 1px ${theme.palette.primaryBlue[300]}`,
                      bgcolor: theme.palette.primaryBlue[100],
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      opacity: fileUploadDisabled ? 0.5 : 1,
                      cursor: fileUploadDisabled ? 'not-allowed' : 'initial',
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={async (e) => {
                      e.preventDefault();
                      if (fileUploadDisabled || !e.dataTransfer.files[0]) {
                        return;
                      }
                      const file = e.dataTransfer.files[0];
                      uploadFile(file);
                    }}
                  >
                    <Box>
                      <Box display="flex" justifyContent="center">
                        <Box
                          p={tokens.SPACING_INDICES.xl}
                          bgcolor="neutralCool.900"
                          borderRadius="4px"
                        >
                          <Typography variant="h6" color="#fff">
                            CSV
                          </Typography>
                        </Box>
                      </Box>
                      <input
                        ref={fileSelectInputRef}
                        type="file"
                        accept=".csv"
                        style={{ display: 'none' }}
                        onChange={async (e) => {
                          e.preventDefault();
                          if (fileUploadDisabled) {
                            return;
                          }
                          const file = e.currentTarget?.files?.[0];
                          if (!file) {
                            return;
                          }
                          uploadFile(file);
                        }}
                        disabled={fileUploadDisabled}
                      />
                      <Box mt={tokens.SPACING_INDICES.lg}>
                        <Typography variant="h4">
                          Drop your files here or{' '}
                          <LinkComponent
                            variant="h4"
                            displayVariant="inline"
                            linkVariant="primary"
                            onClick={handleFileBrowse}
                            component={fileUploadDisabled ? 'span' : 'button'}
                            sx={{ verticalAlign: 'baseline' }}
                            disabled={fileUploadDisabled}
                          >
                            browse
                          </LinkComponent>
                        </Typography>
                      </Box>
                      <Box mt={tokens.SPACING_INDICES.md} textAlign="center">
                        <Typography variant="body2" color="neutralCool.600">
                          File type must be CSV
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {errorMessage.hasError ? (
                    <Box mt={tokens.SPACING_INDICES.lg}>
                      <Box
                        borderRadius="4px"
                        border={`solid 1px ${theme.palette.semanticRed[600]}`}
                        p={tokens.SPACING_INDICES.lg}
                        bgcolor="semanticRed.100"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center">
                          <ExclamationIcon
                            htmlColor={theme.palette.semanticRed[600]}
                            fontSize="medium"
                          />
                          <Box ml={tokens.SPACING_INDICES.md} flex={1}>
                            <Typography variant="h4">{errorMessage.title}</Typography>
                          </Box>
                          <Box>
                            <IconButton
                              onClick={() =>
                                setErrorMessage({
                                  ...errorMessage,
                                  hasError: false,
                                })
                              }
                            >
                              <TrashIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box mt={tokens.SPACING_INDICES.md}>
                          <Typography variant="body1" color="neutralCool.900">
                            {errorMessage.content}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box mt={tokens.SPACING_INDICES['2xl']}>
          <Typography variant="body3" color="neutralCool.600">
            By providing a maximum capital gains amount, you constrain the capital gains of the
            account realized from trading, net of realized losses and cumulative for the current
            calendar year. A lower maximum capital gains figure may result in more tracking error
            against your target portfolio allocation and reduced portfolio turnover when compared to
            a similar portfolio with no such limitation. Capital gains limits may be breached if
            advisors make a cash distribution request, a request to sell restricted stocks, a
            request to sell funds designed for tax deferred accounts, or if compliance limits were
            breached by advisor initiated activity.
          </Typography>
        </Box>
        <Collapse in={disclosuresIn}>
          <Box mt={tokens.SPACING_INDICES.lg}>
            <Typography variant="body3" color="neutralCool.600" paragraph>
              Neither Vise nor any of our affiliates provides tax or legal advice and, therefore,
              are not responsible for developing, implementing, or evaluating any tax strategies
              that may be employed by the Client. The investment and tax strategies mentioned here
              may not be suitable for everyone. Each investor needs to review an investment or tax
              strategy for his or her own particular situation before making any decision.This
              information is not intended to be a substitute for specific individualized tax, legal
              or investment planning advice. Where specific advice is necessary or appropriate, Vise
              recommends consultation with a qualified tax advisor, CPA, financial planner or
              investment manager.
            </Typography>
            <Typography variant="body3" color="neutralCool.600" paragraph>
              Vise is not responsible for any trading activity that occurred prior to Vise&apos;s
              management of the account, and Vise may lack visibility to certain wash sales, should
              they occur as a result of transactions in unlinked or external accounts. Be aware that
              if the Client and/or the Client&apos;s spouse have other taxable or non-taxable
              investment accounts, and the Client holds in those accounts any of the securities
              (including options contracts) held in the Client&apos;s account at Vise, the
              Intermediary cannot trade any of those securities 30 days before or after Vise trades
              those same securities as part of the tax-loss harvesting strategy to avoid possible
              wash sales and, as a result, a nullification of any tax benefits of the strategy. For
              more information on the wash sale rule, please read IRS Publication 550.
            </Typography>
          </Box>
        </Collapse>
        <Box mt={tokens.SPACING_INDICES.lg}>
          <Button
            variant="tertiary"
            endIcon={disclosuresIn ? <ArrowUpIcon /> : <ArrowDownIcon />}
            onClick={() => setDisclosuresIn(!disclosuresIn)}
          >
            Full disclosures
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default function BulkEdit({ data }: { data: GetModelTemplateCenterViewDataResponse }) {
  return (
    <ThemeProvider theme={dsTheme}>
      <BulkEditBody data={data} />
    </ThemeProvider>
  );
}
