import React, { useMemo } from 'react';
import { Box, Tab, Tabs, ThemeProvider } from '@mui/material';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import { useHistory } from 'react-router';
import { Pill, theme as dsTheme } from '@vise_inc/ds-vise';
import useActivityMonitor from '~/hooks/useActivityMonitor';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Custodian } from 'vise-types/custodian';
import Container from '../ModelTemplateCenter/workflows/Container';
import ActionBar from '../ModelTemplateCenter/workflows/ActionBar';
import { useActivityMonitorRows } from './components/Sections';
import { AccountNameCell } from '../Households/table/UtilComponents';

export default function DashboardHistory() {
  const history = useHistory();
  const { data: activityMonitorNotifications } = useActivityMonitor(true);
  const rows = useActivityMonitorRows(activityMonitorNotifications?.data.data?.notifications);
  const columns = useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: 'Date',
        flex: 1,
        renderCell: ({
          value,
          row,
        }: {
          value?: string;
          row: { icon: React.ReactNode; pillVariant: 'success' | 'priority' | 'warning' };
        }) => (
          <ThemeProvider theme={dsTheme}>
            <Pill icon={row.icon} variant={row.pillVariant}>
              {value}
            </Pill>
          </ThemeProvider>
        ),
      },
      { field: 'title', headerName: 'Activity', flex: 2 },
      {
        field: 'accountName',
        headerName: 'Account',
        renderCell: ({
          row: { account },
        }: {
          row: {
            account: {
              accountName: string;
              accountNumber: string;
              taxable: boolean;
              custodianKey: Custodian;
            };
          };
        }) => (
          <AccountNameCell
            accountName={account.accountName}
            accountNumber={account.accountNumber || ''}
            taxable={account.taxable}
            custodianKey={account.custodianKey}
          />
        ),
        flex: 2,
      },
      { field: 'bodyShort', headerName: 'Summary', flex: 4 },
    ],
    []
  );
  return (
    <Container
      title="Activity Center"
      exitUrlOverride="/secure/dashboard"
      headerBarContent={{
        left: (
          <Box color="text.secondary">
            <PathBreadcrumbs
              path={[
                {
                  name: 'Dashboard',
                  to: '/secure/dashboard',
                },
                {
                  name: 'Activity Center',
                },
              ]}
              ariaLabel="Dashboard breadcrumbs"
            />
          </Box>
        ),
      }}
      footer={
        <ActionBar
          showBackButton={false}
          continueButtonProps={{
            children: 'Close',
            onClick: () => {
              history.push('/secure/dashboard');
            },
          }}
        />
      }
    >
      {activityMonitorNotifications && (
        <Box width="100%">
          <Box
            borderLeft="solid 1px rgb(224, 224, 224)"
            borderRight="solid 1px rgb(224, 224, 224)"
            borderTop="solid 1px rgb(224, 224, 224)"
            borderRadius="4px"
            px={3}
          >
            <Tabs value="history" aria-label="History tabs">
              <Tab
                value="history"
                label="History"
                id="tab-history"
                aria-controls="overview-screen"
              />
            </Tabs>
          </Box>
          <DataGridPro
            columns={columns}
            rows={rows}
            autoHeight
            rowHeight={72}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            pagination
            hideFooter={false}
          />
        </Box>
      )}
    </Container>
  );
}
