import useSWR from 'swr';
import { getHousehold } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getHouseholdSwrKey = (householdId: string) =>
  generateSwrKey(`household/${householdId}`);

const useHousehold = (householdId: string | undefined | null) => {
  const { data, error, isValidating, mutate } = useSWR(
    householdId ? getHouseholdSwrKey(householdId) : null,
    householdId == null ? undefined : () => getHousehold(householdId)
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export default useHousehold;
