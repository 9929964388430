import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Container, Paper, Step, StepLabel, StepperProps, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { ArrowLeft } from '@mui/x-date-pickers/internals/components/icons';
import React, { Reducer, useMemo, useReducer } from 'react';
import { Route, useHistory } from 'react-router';
import { XrayClient, XrayClientProposal } from 'vise-types/xray';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { ProductOnboardingReviewPage } from '~/routes/ProductOnboarding/Review';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import { Action, State, initialState } from './ClientOnboardingState';
import CustodianForm from './CustodianForm';
import { OnboardingStepper } from './OnboardingStepper';
import SelectClient from './SelectClient';
import SelectXray, { NoXrayDialog } from './SelectXray';
import { ProductOnboardingRoutes } from './routes';

const reducer: Reducer<typeof initialState, Action> = (state, action) => {
  return { ...state, [action.type]: action.value };
};

function Footer(props: {
  buttonLabel?: string;
  disabled?: boolean;
  state: State;
  loading?: boolean;
}) {
  const history = useHistory();
  const { buttonLabel = 'Continue', disabled, loading } = props;
  return (
    <Paper
      elevation={1}
      sx={{ position: 'fixed', left: 0, bottom: 0, width: '100%', px: 4, py: 2 }}
    >
      <Box display="flex" justifyContent="space-between">
        <Button startIcon={<ArrowLeft />} onClick={() => history.goBack()} color="secondary">
          Back
        </Button>
        <LoadingButton loading={loading} type="submit" variant="contained" disabled={disabled}>
          {buttonLabel}
        </LoadingButton>
      </Box>
    </Paper>
  );
}

export default function ClientOnboardingLanding(props: StepperProps) {
  const { data: featureFlags } = useFeatureFlags();
  const urlParams = new URLSearchParams(window.location.search);
  const selectedClientString = urlParams.get('selectedClient');
  const selectedXrayString = urlParams.get('selectedXray');
  const selectedClient: XrayClient | undefined =
    selectedClientString && JSON.parse(selectedClientString);
  const selectedXray: XrayClientProposal | undefined =
    selectedXrayString && JSON.parse(selectedXrayString);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...(selectedClient && { selectedClient }),
    ...(selectedXray && { selectedXray }),
  });

  const steps = useMemo(
    () => [
      { label: 'Select client', route: ProductOnboardingRoutes.selectClient },
      ...(state.selectedClient?.proposalInfos.length
        ? [{ label: 'Select proposal', route: ProductOnboardingRoutes.selectXray }]
        : []),
      { label: 'Authorization forms', route: ProductOnboardingRoutes.custodianForm },
      { label: 'Review', route: ProductOnboardingRoutes.review },
    ],
    [state]
  );

  const OnboardClientStepper = () => {
    const history = useHistory();
    const activeStep: number = steps.findIndex((step) => step.route === history.location.pathname);
    const theme = useTheme();

    return (
      <OnboardingStepper
        stepperProps={{
          activeStep,
          ...props,
        }}
      >
        {steps.map((s, i) => (
          <Step
            sx={{
              marginTop: 0,
              marginBlock: 0,
              borderRadius: 0,
              ...(i === steps.length - 1
                ? {
                    borderBottomWidth: 1,
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 5,
                    color: theme.palette.primary.main,
                  }
                : null),
              ...(i === 0
                ? {
                    borderTopWidth: 1,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    color: theme.palette.primary.main,
                  }
                : null),
            }}
            key={s.route}
          >
            <StepLabel StepIconComponent={() => <></>}>{s.label}</StepLabel>
          </Step>
        ))}
      </OnboardingStepper>
    );
  };

  return (
    <Box bgcolor="grey.100" minHeight="100vh">
      <Paper sx={{ display: 'flex', alignItem: 'center', py: 2, mb: 2, pl: 3 }}>
        <PathBreadcrumbs
          path={[
            ...(featureFlags?.enable_product_onboarding === 'on'
              ? [{ name: 'Product onboarding', to: ProductOnboardingRoutes.home }]
              : []),
            { name: 'Onboard client' },
          ]}
        />
      </Paper>
      <Container style={{ display: 'flex', alignItems: 'start' }}>
        <Box mr={4} position="sticky" top="26px">
          <OnboardClientStepper />
        </Box>
        <Paper sx={{ width: '100%', minHeight: '100%', mb: 20 }}>
          <Route path={ProductOnboardingRoutes.selectClient}>
            <SelectClient
              selectedClient={state.selectedClient}
              dispatch={dispatch}
              state={state}
              renderFooter={() => <Footer state={state} />}
            />
          </Route>
          <Route path={`${ProductOnboardingRoutes.selectClient}/no-xray`}>
            {state.selectedClient && <NoXrayDialog open selectedClient={state.selectedClient} />}
          </Route>
          <Route path={ProductOnboardingRoutes.selectXray}>
            <SelectXray
              state={state}
              selectedClient={state.selectedClient}
              dispatch={dispatch}
              renderFooter={() => <Footer state={state} />}
            />
          </Route>
          <Route path={ProductOnboardingRoutes.custodianForm}>
            <CustodianForm
              state={state}
              dispatch={dispatch}
              renderFooter={() => <Footer state={state} />}
            />
          </Route>
          <Route path={ProductOnboardingRoutes.review}>
            <ProductOnboardingReviewPage
              state={state}
              renderFooter={({ isLoading }) => (
                <Footer loading={isLoading} state={state} buttonLabel="Download PDF" />
              )}
            />
          </Route>
        </Paper>
      </Container>
    </Box>
  );
}
