import React from 'react';
import useTemplateActiveIcon from '~/static/images/PortfolioCreator2/useTemplate-rounded-active.svg';
import useTemplateInactiveIcon from '~/static/images/PortfolioCreator2/useTemplate-rounded-inactive.svg';

interface UseTemplateIconProps {
  active: boolean;
}

export default function UseTemplateIcon({ active }: UseTemplateIconProps) {
  return (
    <img
      height={120}
      width={120}
      src={active ? useTemplateActiveIcon : useTemplateInactiveIcon}
      alt=""
    />
  );
}
