import styled, { css } from 'styled-components';
import InteractiveContainerBase from './InteractiveContainerBase';

const SelectorContainer = styled(InteractiveContainerBase)`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  ${({ active, theme }) =>
    active
      ? css`
          border-color: ${theme.palette.grey[700]};
        `
      : ''}
  ${({ selectable, theme }) =>
    selectable
      ? css`
          &.disabled {
            background-color: ${theme.palette.grey[100]}
            color: ${theme.palette.grey[400]};
          }
          &:not(.disabled) {
            color: ${theme.palette.grey[500]};
            cursor: pointer;
          }
          &:not(.disabled):focus {
            box-shadow: ${theme.shadows[3]};
          }
          &:not(.disabled):hover,
          &:not(.disabled):focus {
            .RadioIcon {
              box-shadow: inset 0 0 0 1px ${theme.palette.primary.main},
                inset 0 -1px 0 ${theme.palette.primary.main};
            }
            .CheckboxIcon {
              box-shadow: inset 0 0 0 1px ${theme.palette.primary.main},
                inset 0 -1px 0 ${theme.palette.primary.main};
            }
          }
          `
      : ''};
`;

export default SelectorContainer;
