import useSWR from 'swr';
import { getRestrictionsTemplates } from '~/api/api';
import generateSwrKey from '../../api/generateSwrKey';

export default function useRestrictionsTemplates(
  ids: string[] | null | undefined,
  fetchDeleted?: boolean
) {
  return useSWR(
    ids == null
      ? null
      : generateSwrKey(`restrictions-template-${ids}-fetchDeleted=${!!fetchDeleted}`),
    ids == null ? undefined : () => getRestrictionsTemplates(ids, fetchDeleted)
  );
}
