import { Card, Box, Typography, IconButton } from '@mui/material';
import React from 'react';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import { ReactComponent as AlertIcon } from '~/static/images/icons/alert-circle-outline.svg';
import { ReactComponent as CheckIcon } from '~/static/images/icons/check.svg';

function ValidationsPopover({
  validations,
  onClose,
}: {
  validations: { question: string; answer: string; label: string }[];
  onClose: () => void;
}) {
  return (
    <Box maxWidth="280px">
      <Card elevation={2}>
        <Box p={1.5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            border={1}
            pb={1.5}
            borderColor="grey.200"
            borderLeft={0}
            borderRight={0}
            borderTop={0}
            mb={1.5}
          >
            <Typography variant="h5" component="span">
              Validations
            </Typography>
            {onClose && (
              <Box pl={1.5}>
                <IconButton aria-label="close" size="small" color="inherit" onClick={onClose}>
                  <XIcon height="16" width="16" />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box color="grey.500">
            {validations.map((validation) => (
              <Box key={validation.label} display="flex" alignItems="center" gap={1}>
                <Typography variant="body1">{validation.label}</Typography>
                {validation.answer === 'yes' ? (
                  <Box color="error.400">
                    <AlertIcon height="16px" width="16px" />
                  </Box>
                ) : (
                  <CheckIcon color="green" />
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default ValidationsPopover;
