import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Link, Typography } from '@mui/material';

import apxMethodology from '~/static/APX Methodology.pdf';
import PageContentContainer from '../../components/PageContentContainer';

const Disclosures = () => (
  <>
    <Helmet>
      <title>Disclosures</title>
    </Helmet>
    <PageContentContainer>
      <Typography gutterBottom variant="h1">
        Disclosures
      </Typography>
      <Box paddingTop={2}>
        <Typography variant="h3">GENERAL</Typography>
        <p>
          Vise AI Advisors, LLC (&quot;Vise&quot;) is an investment adviser registered with the
          Securities and Exchange Commission (SEC). Registration of an investment adviser does not
          imply any specific level of skill or training and does not constitute an endorsement of
          Vise by the Commission.
        </p>
        <p>
          DISCLAIMER: THERE ARE NO WARRANTIES, EXPRESSED OR IMPLIED, AS TO ACCURACY, COMPLETENESS,
          OR RESULTS OBTAINED FROM ANY INFORMATION PROVIDED HEREIN OR ON THE MATERIAL PROVIDED. This
          document and the information which it accompanies or to which it refers and relates does
          not constitute a complete description of Vise&apos;s investment services and is for
          informational purposes only. It is in no way a solicitation or an offer to sell securities
          or investment advisory services.
        </p>
        <p>
          Any statements regarding market or other ﬁnancial information are obtained from sources
          that Vise and its suppliers believe to be reliable, but Vise does not warrant or guarantee
          the timeliness or accuracy of this information. Neither Vise&apos;s information providers
          nor Vise shall be liable for any errors or inaccuracies, regardless of cause, or the lack
          of timeliness of, or for any delay or interruption in the transmission thereof to the
          user. All investments involve risk, including foreign currency exchange rates, political
          risks, market risk, different methods of accounting and ﬁnancial reporting, and foreign
          taxes. Your use of these and all materials provided by Vise, including the{' '}
          <Link href="https://www.vise.com" target="_blank">
            www.vise.com
          </Link>{' '}
          website, is your acknowledgment that you have read and understood the full disclaimer as
          stated above.
        </p>
        <p>
          Holdings and values are generally based on same-day morning prices. Past performance is
          not indicative of future results. Hypothetical performance does not reﬂect the performance
          of actual accounts and is not intended to serve as a guarantee of future results.
          Differences in account size, timing of transactions, and market conditions prevailing at
          the time of investment may lead to different results, and clients may lose money.
        </p>
      </Box>
      <Box paddingTop={2}>
        <Typography variant="h3">TWRR PERFORMANCE CALCULATION METHODOLOGY</Typography>
        <p>
          All returns presented on the Vise Platform are calculated using Time-Weighted Rate of
          Return methodology (TWRR). The timing and size of external cash flows into and out of an
          account can create significant skew in the rate of return on an investment portfolio.
        </p>
        <p>
          By assigning weights to the variable for cash flows within the calculation, the TWRR
          formula effectively mitigates the skewing effect that cash flows can present, and isolates
          the investments for the performance period at hand.
        </p>
        <p>
          Net-of-Fees (NoF) performance is calculated as Gross-of-Fee (GoF) performance less
          management fees. NoF performance displayed on the Vise platform accounts for both
          Intermediary Firm and Vise Subadviser Fees.
        </p>
        <p>
          Vise utilizes a third-party service (Advent APX) for all performance calculations that are
          displayed on the platform. More information on assumptions and methodology can be found
          <Link href={apxMethodology} target="_blank" rel="noopener noreferrer">
            {' '}
            here
          </Link>
          .
        </p>
        <p>
          Vise reports cumulative return values for all periods of less than one year. For periods
          greater than one year, cumulative returns are annualized to display the average annual
          rate of return.
        </p>
        <p>
          For portfolio and household-level returns, Vise employs a rollup variation of the same
          TWRR approach. The formula is applied to the combined holdings and transactional activity
          of all constituent accounts within the group. The same geometric compounding of each
          holding period applied at the account level is also applied at the roll up level. The
          contributions of the individual accounts to the overall group’s return are weighted
          proportional to their market values.
        </p>
      </Box>
      <Box paddingTop={2}>
        <Typography variant="h3">SIMULATED BACKTESTED PERFORMANCE RESULTS</Typography>
        <p>Vise Portfolio proposals use hypothetical backtested performance data.</p>
        <p>
          There are certain limitations inherent in hypothetical model results like those portrayed,
          particularly that such hypothetical model returns do not reﬂect trading in actual client
          accounts and do not reﬂect the impact that material economic and market factors may have
          had on the adviser&apos;s decision-making had the adviser actually been managing client
          funds. Backtested performance is developed with the benefit of hindsight and has inherent
          limitations. Unlike an actual performance record, hypothetical backtested performance
          results do not represent actual trading. In addition, simulated trading does not involve
          or take into account ﬁnancial risk and does not take into account that material and market
          factors may have impacted Vise&apos;s decision-making, all of which can adversely affect
          actual trading results and performance.
        </p>
        <p>
          Hypothetical backtested performance does not represent actual performance, trading costs,
          or the impact of taxes and should not be interpreted as an indication of such performance,
          nor is it indicative of an adviser&apos;s skill.
        </p>
        <p>
          Hypothetical backtested performance also differs from actual performance because it is
          achieved through the retroactive application of model index portfolios designed with the
          beneﬁt of hindsight. As a result, Vise Portfolios may be changed from time to time, and
          the effect on hypothetical performance results could be either favorable or unfavorable.
          Hypothetical backtested performance results for Vise Portfolios include the reinvestment
          of dividends and capital gains and are shown net of Vise&apos;s highest annual advisory
          fee of 0.50% and net of mutual fund or exchange-traded fund (ETF) fees. In the
          hypothetical performance ﬁgures shown, the annual advisory fee is deducted from
          quarter-end returns (.125% per quarter) unless stated otherwise. However, actual client
          advisory fees may be deducted quarterly, in advance, by Vise. Depending on the amount of
          assets under management and other factors (please see Vise&apos;s Form ADV Part 2 Brochure
          for additional information), investment management fees may be less. Note that a
          client&apos;s return will be reduced by the amount of advisory fees charged by Vise and
          any other expenses, and the inclusion of Vise&apos;s advisory fees will have a negative
          impact on client account performance. These results do not consider any fees of the
          primary adviser, custodian, or any other ﬁnancial services ﬁrm that may charge the client
          a fee—including any costs associated with trading—which will reduce client performance.
        </p>
        <Typography variant="h4" style={{ textDecoration: 'underline' }}>
          TAX LIABILITIES
        </Typography>
        <p>
          Tax liabilities will vary for each client and can result from various activities in
          taxable and tax-deferred accounts. These activities include but are not limited to the
          rebalancing of portfolios, any sale of securities, tax loss harvesting, interest,
          dividends, and capital gains distributions from equity funds and individual securities in
          taxable accounts. There are also tax liabilities associated with distributions from
          tax-deferred accounts. Not all Vise clients follow Vise&apos;s recommendations and
          depending on unique and changing client, and market situations, Vise or the primary
          adviser of the client may customize the construction and implementation of Vise&apos;s
          portfolios for particular clients so that actual client accounts differ materially from
          those shown. Vise provides various automated portfolio implementation strategies. These
          various implementation options for Vise Portfolios are expected to have risks and
          potential returns that vary from the Vise Portfolio models. As a result of these and other
          variances, actual performance for client accounts has been and is likely to be materially
          different and may be lower than the results shown in the Vise Portfolio models.
        </p>
        <Typography variant="h4" style={{ textDecoration: 'underline' }}>
          SIMULATED BACKTESTED PERFORMANCE METHODOLOGY AND ASSUMPTIONS
        </Typography>
        <p>
          Simulated data does not reflect the actual performance of a live strategy. The results of
          an actual account may vary significantly. The performance shown was achieved with the
          retroactive application of a model designed with the benefit of hindsight (as described
          above); it does not represent actual investment performance. Backtested model performance
          is hypothetical (i.e., it does not reflect trading in actual accounts) and is provided for
          informational purposes only. Simulated performance does not reflect the impact that
          economic and market factors might have had on the advisor&apos;s decision-making if the
          advisor were actually managing client money.
        </p>
        <p>
          Simulations include both simulated performance of Vise-managed strategies, as well as
          Morningstar Index data. The backtesting for Vise-managed single-security asset classes
          uses Vise&apos;s security selection and weighting model based on financial data and
          prices, according to Vise&apos;s proprietary methodology. For non-single-security asset
          classes, Vise uses index and/or representative ETF data. Indices are not available for
          direct investment.
        </p>
        <p>
          The backtest shown is a backtest of the initial target allocation for the investor, given
          their inputs on the Vise platform. The backtest does not account for additional Vise
          platform features, including variable asset allocation through our glide path or target
          value approach, cash distributions, tax loss harvesting, or portfolio input changes.
        </p>
        <p>
          As stated above, hypothetical backtested performance results for Vise Portfolios include
          the reinvestment of dividends and capital gains and are shown net of Vise&apos;s highest
          annual advisory fee of 0.50% and net of mutual funds or ETF fees. In the hypothetical
          performance ﬁgures shown, the annual advisory fee is deducted from quarter-end returns
          (.125% per quarter) unless stated otherwise. However, actual client advisory fees may be
          deducted quarterly, in advance, by Vise. Depending on the amount of assets under
          management and other factors (please see Vise&apos;s Form ADV Part 2 Brochure for
          additional information), investment management fees may be less. Note that a client&apos;s
          return will be reduced by the amount of advisory fees charged by Vise and any other
          expenses, and the inclusion of Vise&apos;s advisory fees will have a negative impact on
          client account performance. These results do not consider any fees of the primary adviser,
          custodian, or any other ﬁnancial services ﬁrm that may charge the client a fee—including
          any costs associated with trading—which will reduce client performance.
        </p>
      </Box>
      <Box paddingTop={2}>
        <Typography variant="h3">MONTE CARLO SIMULATION</Typography>
        <p>
          The Monte Carlo simulation results on Vise.com are provided for educational purposes and
          to model the probability of different outcomes of using Vise&apos;s services. The Monte
          Carlo model projects how the portfolio may perform over a range of random outcomes based
          on the composition of the portfolio, the investment time horizon, and any contributions.
          The Monte Carlo results are created using Vise&apos;s capital markets assumptions. The
          median return can be used as an illustration of one potential outcome, but actual returns
          are likely to be better or worse.
        </p>
        <p>
          Vise uses the initial portfolio for which we have calculated the expected mean and
          expected volatility. We simulate the annual expected return for the portfolio using 1000
          random simulations over the investment horizon (random paths). From those simulations,
          Vise displays the 25th, 50th, 75th, and 95th percentile to provide the user an
          illustration of how well or poorly the given portfolio could theoretically perform. For
          reference, the 25th percentile is a poor but possible outcome, where 25% of the simulated
          outcomes were at that level or worse, but 75% were better. The 75th percentile is an
          unusually good outcome. Only 25% of the simulated outcomes were better, but 75% were
          worse. Half of the simulated outcomes (50% of the total) fell between those two levels,
          creating a helpful range to look at when planning for retirement.
        </p>
        <p>Simulations are rebalanced monthly.</p>
      </Box>
      <Box paddingTop={2}>
        <Typography variant="h3">TAX-LOSS HARVESTING</Typography>
        <p>
          Tax-loss harvesting is not suitable for all investors. Clients should confer with their
          personal tax advisor regarding the tax consequences of investing with Vise and engaging in
          the tax-loss harvesting strategy based on their particular circumstances. Clients who do
          not have any taxable accounts will not benefit from the operation of tax loss harvesting.
          The effectiveness of the tax-loss harvesting strategy to reduce the tax liability of the
          Client will depend on the Client&apos;s entire tax and investment profile, including
          purchases and dispositions in a Client&apos;s (or Client&apos;s spouse&apos;s) accounts
          outside of Vise and type of investments (e.g., taxable or nontaxable) or holding period
          (e.g., short-term or long-term).
        </p>
        <p>
          The wash sale rule disallows the realization of a loss from selling a security if a
          “substantially identical” security is purchased 30 days before or after the sale. The wash
          sale rule applies not just to situations when a “substantially identical” purchase is made
          in the same account but also when the purchase is made in a different account or even the
          account of a spouse. In general, tax loss harvesting seeks to avoid potential wash sales
          that could arise from transactions in the same securities. Under certain limited
          circumstances, there is a chance that Vise trading attributed to tax-loss harvesting may
          create capital gains and/or wash sales. In addition, tax-loss harvesting strategies may
          produce losses that may not be offset by sufficient gains in the account. Not all the
          losses may be used to offset gains in the year they were recognized due to wash sales.
          Thus, wash sales can diminish the effectiveness of tax-loss harvesting by deferring to a
          future year a tax loss that could have been used to offset income or capital gains in the
          current year. Vise is not able to avoid wash sales associated with accounts that are held
          outside of Vise&apos;s management.
        </p>
        <p>
          The tax consequences of tax loss harvesting are complex and uncertain and may be
          challenged by the IRS or any other tax authority. The internal revenue code, as well as
          judicial and administrative interpretations of it, are subject to change, and any such
          change could have a material impact on the consequences of using Vise&apos;s tax loss
          harvesting strategies. State income tax laws are also subject to change and may differ
          from federal law in material ways. Clients and their tax advisors are responsible for how
          transactions conducted in their accounts are reported to the IRS, or any other tax
          authority, on clients&apos; personal tax returns. Vise assumes no responsibility for the
          tax consequences to any client of any transaction associated with Vise&apos;s tax loss
          harvesting.
        </p>
      </Box>
      <Box paddingTop={2}>
        <Typography variant="h3">X-RAY ANALYSIS TOOL DISCLOSURES</Typography>
        <p>
          Vise Portfolio proposals contain a comparative analysis tool that allows advisors to
          compare their client’s current portfolio with a hypothetical Vise-managed portfolio.
        </p>
        <p>
          All calculations & outputs should not be interpreted as personalized investment or tax
          advice from Vise. The information in this section is only meant to serve as a resource to
          help build a portfolio - advisors are responsible for making their own independent
          judgment as to how to use this information as advice for their own clients.
        </p>
        <p>
          Performance of any account or portfolio derived from this information may vary materially
          from the performance shown herein. There is no guarantee that any investment strategy
          illustrated will be successful or achieve any particular level of results.
        </p>
        <Typography variant="h4" style={{ textDecoration: 'underline' }}>
          EXPENSES & FEES
        </Typography>
        <p>
          All expense ratios and associated metrics are calculated against uploaded position data
          and extracted from Thompson Reuters Lipper.
        </p>
        <Typography variant="h4" style={{ textDecoration: 'underline' }}>
          TAX ESTIMATIONS{' '}
        </Typography>
        <p>
          By providing a maximum capital gains amount, you constrain the capital gains of the
          account realized from trading, net of realized losses, and cumulative for the current
          calendar year. A lower maximum capital gains figure may result in more tracking errors
          against your target portfolio allocation and reduced portfolio turnover when compared to a
          similar portfolio with no such limitation. Capital gains limits may be breached if
          advisors make a cash distribution request, a request to sell restricted stocks, a request
          to sell funds designed for tax-deferred accounts, or if compliance limits were breached by
          advisor-initiated activity.
        </p>
        <p>
          Neither Vise nor any of our affiliates provide tax or legal advice and, therefore, is not
          responsible for developing, implementing, or evaluating any tax strategies that may be
          employed by the Client. The investment and tax strategies mentioned here may not be
          suitable for everyone. Each investor needs to review an investment or tax strategy for his
          or her own particular situation before making any decision. This information is not
          intended to be a substitute for specific individualized tax, legal, or investment planning
          advice. Where specific advice is necessary or appropriate, Vise recommends consultation
          with a qualified tax advisor, CPA, financial planner, or investment manager.
        </p>
        <p>
          Vise is not responsible for any trading activity that occurred prior to Vise&apos;s
          management of the account, and Vise may lack visibility to certain wash sales, should they
          occur as a result of transactions in unlinked or external accounts. Be aware that if the
          Client and/or the Client&apos;s spouse have other taxable or non-taxable investment
          accounts, and the Client holds in those accounts any of the securities (including options
          contracts) held in the Client&apos;s account at Vise, the Intermediary cannot trade any of
          those securities 30 days before or after Vise trades those same securities as part of the
          tax-loss harvesting strategy to avoid possible wash sales and, as a result, a
          nullification of any tax benefits of the strategy. For more information on the wash sale
          rule, please read IRS Publication 550.
        </p>
        <p>
          The below sections correlate to the outputs provided within the X-ray analysis tool
          output.
        </p>
        <Typography variant="h4" style={{ textDecoration: 'underline' }}>
          AFTER-TAX PERFORMANCE BENEFIT & ESTIMATED TAX SAVINGS
        </Typography>
        <p>
          Vise seeks to opportunistically tax-loss harvest for managed single securities. All
          estimates are based on historical simulations of tax losses harvested in an equivalent
          risk Vise model portfolio. An equivalent model portfolio is assumed based on equity
          allocation, tilt type, tilt amount, and target number of holdings. For each single
          security equity sleeve, tax-loss harvesting is calculated, and a weighted average is
          derived from a 5-year rolling average from 2000 through 2023.
        </p>
        <p>
          The after-tax performance benefit is calculated as taxes saved as a percentage of the
          total value of single security holdings. Estimated total tax savings is represented as a
          numeric dollar amount of estimated tax savings.
        </p>
        <p>
          Estimated tax savings over time or year 1 tax savings growth is calculated by using the
          estimated total tax savings out and running this calculation through Monte Carlo
          simulations (above).
        </p>
        <Typography variant="h4" style={{ textDecoration: 'underline' }}>
          TAX TRANSITION SCENARIOS
        </Typography>
        <p>
          All tax-transition scenarios are estimated calculations based on inputs provided by
          advisors. Provided scenarios assume a long-term capital gains budget that is a percentage
          of the total unrealized gains in the portfolio. Short-term budgets are restricted to $0.
          Tax cost is calculated by multiplying estimated short-term & long-term gains by assumed
          tax rates of 50% and 20%, respectively.
        </p>
        <p>
          Tax-loss analysis is only limited to clients with taxable accounts. Clients should confer
          with their personal tax advisor regarding the tax consequences of investing with Vise and
          engaging in the tax-loss harvesting strategy based on their particular circumstances.
        </p>
        <Typography variant="h4" style={{ textDecoration: 'underline' }}>
          HISTORIC PERFORMANCE
        </Typography>
        <p>
          Past performance comparisons are generated by creating synthetic custom ETF’s leveraging
          MorningStar past performance and index data. Back tests are run against Vise target
          portfolios to generate historic performance for the specified time periods.
        </p>
      </Box>
      <Box paddingTop={4}>
        <Typography variant="body2">
          Vise<sup>®</sup> is a registered trademark of Vise Technologies, Inc. | 521 Broadway, 3rd
          Floor, New York, NY 10012
        </Typography>
        <Box paddingTop={2}>
          <Typography variant="body1">Updated January 2024</Typography>
        </Box>
      </Box>
    </PageContentContainer>
  </>
);

export default Disclosures;
