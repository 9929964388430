import { Box, Button, Divider, Grid, IconButton, ThemeProvider, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Pill, theme, tokens } from '@vise_inc/ds-vise';
import { format } from 'date-fns';
import { chunk, range } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Custodian } from 'vise-types/custodian';
import { ActivityMonitorNotificationData, ActivityMonitorResponse } from 'vise-types/response';
import { completeActivityMonitorNotification, updateActivityMonitorNotification } from '~/api/api';
import PageNavigation from '~/components/table/PageNavigation';
import useActivityMonitor from '~/hooks/useActivityMonitor';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import LinkAccountOrCreateClientModal from '~/routes/Households/Households/LinkAccountOrCreateClientModal';
import { AccountNameCell } from '~/routes/Households/table/UtilComponents';
import Truncation from '~/routes/Portfolio/components/Truncation';
import createGraphic from '~/static/images/dashboard/create.svg';
import validationGraphic from '~/static/images/dashboard/validation.svg';
import { ReactComponent as ArrowRightIcon } from '~/static/images/icons/arrow-right.svg';
import { ReactComponent as CalendarIcon } from '~/static/images/icons/calendar.svg';
import { ReactComponent as ChevronRight } from '~/static/images/icons/chevron-right.svg';
import { ReactComponent as ExclamationIcon } from '~/static/images/icons/exclamation.svg';
import { ReactComponent as ViewGridIcon } from '~/static/images/icons/view-grid.svg';
import { ReactComponent as ViewListIcon } from '~/static/images/icons/view-list.svg';
import UnstyledButton from '~/synth/UnstyledButton';
import { formatCurrency, getCustodianDisplayName, maskAccountNumber } from '~/utils/format';

const PAGE_SIZE = 4;

function EmptyState({ text }: { text: string }) {
  return (
    <ThemeProvider theme={theme}>
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <img alt="" src={createGraphic} />
        <Box mt={tokens.SPACING_INDICES.lg}>
          <Typography variant="body3" color="neutralCool.600" fontWeight="500">
            {text}
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export type ActivityMonitorRow = {
  title: string;
  borderColor: React.CSSProperties['borderColor'];
  pillVariant: 'success' | 'priority' | 'warning';
  body: string;
  cta: {
    ctaText: string;
    ctaAction: () => void;
  };
  icon: React.ReactNode;
  id: string;
  account: {
    accountName: string;
    custodianKey: Custodian;
    accountNumber: string;
    taxable: boolean;
  };
  amount: string;
  createdAt: string;
};

export function useActivityMonitorRows(
  activityMonitorResponse?: ActivityMonitorResponse[],
  openLinkAccountModal?: (id: string, notif: ActivityMonitorNotificationData) => void
): ActivityMonitorRow[] {
  const history = useHistory();
  return useMemo(
    () =>
      activityMonitorResponse
        ?.sort((notif1, notif2) => {
          // Sort by most recent notification created time
          return notif2.createdAt.localeCompare(notif1.createdAt);
        })
        .map((notif) => {
          let title = '';
          let borderColor = '';
          let pillVariant = 'success' as 'success' | 'priority' | 'warning';
          let body = '';
          let ctaText = '';
          let bodyShort = '';
          let ctaAction: () => void = () => undefined;
          let icon: React.ReactNode = null;
          if (notif.notifType === 'UNEXECUTED_FUNDED_ACCOUNT') {
            title = notif.data.clientId
              ? 'Create and Execute Portfolio'
              : 'Create or Add a Household';
            ({ 200: borderColor } = tokens.palette.secondaryPurple);
            pillVariant = 'success';
            bodyShort = notif.data.clientId
              ? "Your client's custodial account has been successfully linked."
              : "Your client's custodial account has been successfully added to the platform.";
            body = notif.data.clientId
              ? `${bodyShort} Vise can begin managing this account once you create and execute a portfolio.`
              : `${bodyShort} Link your account to a household in order to create a Vise managed potfolio.`;
            ctaText = notif.data.clientId ? 'Create' : 'Link household';
            icon = <CalendarIcon height={16} width={16} />;
            ctaAction = () => {
              if (!notif.data.clientId && openLinkAccountModal) {
                openLinkAccountModal(notif.id, notif.data);
              } else {
                history.push(
                  `/secure/portfolio-creator-next?${
                    notif.data.clientId != null ? `clientId=${notif.data.clientId}&` : ''
                  }accountId=${notif.data.accountId}`
                );
              }
            };
          } else if (notif.notifType === 'ASSET_CLASSES_ELIGIBLE_FOR_UPGRADE') {
            title = 'Single Security Opt-in';
            ({ 200: borderColor } = tokens.palette.secondaryTurquoise);
            pillVariant = 'priority';
            bodyShort = 'This account is now eligible for single-security equity offerings.';
            body = `${bodyShort} Click below to make updates to your client's portfolio.`;
            ctaText = 'Create';
            ctaAction = () =>
              history.push(
                `/secure/portfolio-creator-next?${
                  notif.data.clientId != null ? `clientId=${notif.data.clientId}&` : ''
                }accountId=${notif.data.accountId}`
              );
            icon = <CalendarIcon height={16} width={16} />;
          } else if (notif.notifType === 'ACCOUNT_REMOVED') {
            title = 'Inactive Account';
            ({ 200: borderColor } = tokens.palette.secondaryPurple);
            pillVariant = 'warning';
            bodyShort = 'This account is now inactive on the Vise platform.';
            body = `${bodyShort} Please reach out to Client services with any questions.`;
            ctaText = 'Contact CS';
            ctaAction = () => {
              window.location.href = 'mailto:clientservice@vise.com';
            };
            icon = <ExclamationIcon height={16} width={16} />;
          } else if (notif.notifType === 'REBALANCER_PAUSED') {
            title = 'Rebalancer Paused';
            ({ 200: borderColor } = tokens.palette.primaryBlue);
            pillVariant = 'priority';
            bodyShort = "The rebalancer for this client's portfolio has been paused.";
            body = `${bodyShort} Click below if you want to make any changes.`;
            ctaText = 'View portfolio';
            ctaAction = () =>
              history.push(`/secure/accounts/${notif.data.accountId}/portfolio/overview`);
            icon = <CalendarIcon height={16} width={16} />;
          } else if (notif.notifType === 'ACCOUNT_THRESHOLD_CROSSED') {
            title = 'Update Asset Allocation Strategy';
            ({ 200: borderColor } = tokens.palette.secondaryTurquoise);
            pillVariant = 'priority';
            bodyShort = 'This account is now eligible for our full asset class selection.';
            body = `${bodyShort} Click below to make updates your client's portfolio.`;
            ctaText = 'View portfolio';
            ctaAction = () =>
              history.push(`/secure/accounts/${notif.data.accountId}/portfolio/overview`);
            icon = <CalendarIcon height={16} width={16} />;
          } else if (notif.notifType === 'CASH_DEPOSITED') {
            title = 'Cash Inflows Received';
            ({ 200: borderColor } = tokens.palette.secondaryTurquoise);
            pillVariant = 'priority';
            bodyShort = 'This account has received new cash inflows.';
            body = `${bodyShort} Pause the account if you would not like to spend down the cash.`;
            ctaText = 'Pause';
            ctaAction = () =>
              history.push(`/secure/accounts/${notif.data.accountId}/portfolio/overview`);
            icon = <CalendarIcon height={16} width={16} />;
          } else if (notif.notifType === 'STALE_DISTRIBUTION') {
            title = 'Stale Distribution';
            ({ 200: borderColor } = tokens.palette.secondaryPurple);
            pillVariant = 'warning';
            bodyShort = 'There is a distribution on this account that has not been withdrawn yet.';
            body = `${bodyShort} You can cancel this distribution to spend down the cash.`;
            ctaText = 'View distribution';
            ctaAction = () => history.push('/secure/distributions');
            icon = <ExclamationIcon height={16} width={16} />;
          }
          return {
            id: notif.id,
            title,
            borderColor,
            pillVariant,
            bodyShort,
            body,
            cta: {
              ctaText,
              ctaAction,
            },
            icon,
            amount: notif.data.amount,
            createdAt: format(new Date(notif.createdAt), 'MM/dd/yy'),
            accountName: notif.data.accountName,
            account: {
              accountName: notif.data.accountName,
              custodianKey: notif.data.custodianKey,
              accountNumber: notif.data.accountNumber,
              taxable: notif.data.taxable,
            },
          };
        }) || [],
    [activityMonitorResponse, history, openLinkAccountModal]
  );
}

function NotificationCard({
  row,
  setActiveStep,
  mutate,
}: {
  row: ActivityMonitorRow;
  setActiveStep: (step: number) => void;
  mutate: () => void;
}) {
  const {
    title,
    borderColor,
    pillVariant,
    body,
    cta: { ctaText, ctaAction },
    icon,
    id,
    account: { accountName, custodianKey, accountNumber },
    amount,
    createdAt,
  } = row;

  const cardStyle = {
    borderRadius: 1,
    boxShadow: tokens.shadow.medium,
    height: '100%',
    width: '100%',
    position: 'absolute',
    backfaceVisibility: 'hidden',
    backgroundColor: tokens.palette.neutralCool[100],
  };

  const [completed, setCompleted] = useState(false);
  const enqueueCoachmark = useEnqueueCoachmark();

  const handleClickDismiss = async () => {
    try {
      const response = await completeActivityMonitorNotification(id);
      if (response?.data.success === true) {
        setCompleted(true);
        setActiveStep(0);
        mutate();
      }
    } catch (e) {
      enqueueCoachmark({
        title: 'There was an error dismissing the notification',
        content: 'Please try again later',
        severity: 'error',
      });
    }
  };

  return (
    <Grid item xs={12} sm={6} lg={3}>
      <Box height={420} width="100%" sx={{ perspective: '1000px' }}>
        <Box
          position="relative"
          width="100%"
          height="100%"
          sx={{
            transition: 'transform 0.6s',
            transformStyle: 'preserve-3d',
            '&.completed': {
              transform: 'rotateY(180deg)',
            },
          }}
          id={`card-container-${id}`}
          className={completed ? 'completed' : ''}
        >
          {/* "Backside" of card */}
          <Box
            p={2}
            border={`solid 1px ${tokens.palette.neutralCool[200]}`}
            sx={{ ...cardStyle, transform: 'rotateY(180deg)' }}
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img alt="" width={160} src={validationGraphic} />
            <Box fontWeight={500} mb={1} mt={2}>
              Activity complete!
            </Box>
            <Box color={tokens.palette.neutralCool[800]}>
              This activity is now complete. View activity history{' '}
              <Link to="/secure/dashboard/history">here</Link>.
            </Box>
          </Box>
          {/* "Frontside" of card */}
          <Box
            p={2}
            border={`solid 1px ${borderColor}`}
            sx={{
              '&:hover': {
                background: tokens.palette.primaryBlue[100],
              },
              ...cardStyle,
            }}
          >
            <ThemeProvider theme={theme}>
              <Pill icon={icon} variant={pillVariant}>
                {createdAt}
              </Pill>
              <Box
                mt={tokens.SPACING_INDICES.sm}
                mb={3}
                pb={3}
                borderColor={tokens.palette.neutralCool[300]}
                sx={{ borderStyle: 'dotted', borderLeft: 0, borderTop: 0, borderRight: 0 }}
              >
                <Typography variant="h3">{title}</Typography>
              </Box>

              <Typography variant="h5" textOverflow="ellipsis" whiteSpace="nowrap">
                <Truncation>{accountName}</Truncation>
              </Typography>
              <Box my={tokens.SPACING_INDICES.sm}>
                <Typography variant="body3" color="neutralCool.600">
                  {/* a bit of a hack for QA until we fix the data */}
                  {accountNumber ? maskAccountNumber(accountNumber) : ''},{' '}
                  {getCustodianDisplayName(custodianKey)}
                </Typography>
              </Box>
              <Typography variant="h5">{formatCurrency(parseFloat(amount))}</Typography>
              <Box
                my={3}
                height="120px"
                pt={3}
                borderColor={tokens.palette.neutralCool[300]}
                sx={{ borderStyle: 'dotted', borderLeft: 0, borderRight: 0 }}
              >
                <Typography variant="body2" color="neutralCool.800">
                  {body}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" mt={6}>
                <Box flex={1}>
                  <UnstyledButton onClick={ctaAction}>
                    <Typography variant="h5" sx={{ '&:hover': { textDecoration: 'underline' } }}>
                      {ctaText} <ArrowRightIcon style={{ position: 'relative', top: '2px' }} />
                    </Typography>
                  </UnstyledButton>
                </Box>
                <UnstyledButton onClick={handleClickDismiss}>
                  <Typography variant="h5" sx={{ '&:hover': { textDecoration: 'underline' } }}>
                    Dismiss
                  </Typography>
                </UnstyledButton>
              </Box>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

const ActivityMonitor = () => {
  const { data: activityMonitorNotifications, mutate } = useActivityMonitor();

  const { data: activityMonitorNotificationsHistory } = useActivityMonitor(true);
  const numHistoryItems = activityMonitorNotificationsHistory?.data.data?.notifications.length || 0;
  const [linkAccountModalSettings, setLinkAccountModalSettings] = useState<{
    accountId: string | null;
    open: boolean;
    notif: null | ActivityMonitorNotificationData;
    id: null | string;
  }>({ accountId: null, open: false, notif: null, id: null });
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: 'Date',
        flex: 1,
        renderCell: ({
          value,
          row,
        }: {
          value?: string;
          row: { icon: React.ReactNode; pillVariant: 'success' | 'priority' | 'warning' };
        }) => (
          <ThemeProvider theme={theme}>
            <Pill icon={row.icon} variant={row.pillVariant}>
              {value}
            </Pill>
          </ThemeProvider>
        ),
      },
      { field: 'title', headerName: 'Activity', flex: 2 },
      {
        field: 'accountName',
        headerName: 'Account',
        renderCell: ({
          row: { account },
        }: {
          row: {
            account: {
              accountName: string;
              accountNumber: string;
              taxable: boolean;
              custodianKey: Custodian;
            };
          };
        }) => (
          <AccountNameCell
            accountName={account.accountName}
            accountNumber={account.accountNumber || ''}
            taxable={account.taxable}
            custodianKey={account.custodianKey}
          />
        ),
        flex: 2,
      },
      {
        field: 'body',
        headerName: 'Summary',
        flex: 2,
        renderCell: ({ value }: { value?: string }) => <Truncation>{value}</Truncation>,
      },
      {
        field: 'cta',
        type: 'actions',
        headerName: '',
        flex: 1,
        renderCell: ({ value }: { value?: { ctaAction: () => void; ctaText: string } }) =>
          value ? (
            <UnstyledButton onClick={value.ctaAction}>
              <Typography variant="h5" sx={{ '&:hover': { textDecoration: 'underline' } }}>
                {value.ctaText} <ChevronRight style={{ position: 'relative', top: '2px' }} />
              </Typography>
            </UnstyledButton>
          ) : null,
      },
    ],
    []
  );

  const [viewType, setViewType] = useState<'CARD' | 'TABLE'>('CARD');

  const openLinkAccountModal = useCallback((id: string, notif: ActivityMonitorNotificationData) => {
    setLinkAccountModalSettings({
      open: true,
      accountId: notif.accountId,
      notif,
      id,
    });
  }, []);

  const rows = useActivityMonitorRows(
    activityMonitorNotifications?.data?.data?.notifications,
    openLinkAccountModal
  );

  const [activeStep, setActiveStep] = useState(0);

  const notifications = rows.map((row) => (
    <NotificationCard setActiveStep={setActiveStep} key={row.id} row={row} mutate={mutate} />
  ));
  const chunkedNotifications = chunk(notifications || [], PAGE_SIZE);
  const activeCards = chunkedNotifications[activeStep] || [];

  const cardsBody = [
    ...activeCards,
    ...range(0, PAGE_SIZE - activeCards.length).map((i) => (
      <Grid item lg={3} key={i}>
        <Box
          p={2}
          border={`solid 1px ${tokens.palette.secondaryPurple[200]}`}
          borderRadius="4px"
          boxShadow={tokens.shadow.medium}
          height="420px"
        >
          <EmptyState text="Currently there is no additional activity." />
        </Box>
      </Grid>
    )),
  ];

  const onLinkAccount = async (clientId) => {
    if (linkAccountModalSettings.id != null && linkAccountModalSettings.notif != null) {
      await updateActivityMonitorNotification(linkAccountModalSettings.id, {
        ...linkAccountModalSettings.notif,
        clientId,
      });
      await mutate();
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box my={tokens.SPACING_INDICES.lg} display="flex">
          <Box flex={1}>
            <Typography variant="h2">Activity Center</Typography>
            <Box mt={tokens.SPACING_INDICES.sm}>
              <Typography variant="body2" color="neutralCool.600">
                View valuable insights into key activities across client accounts.
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              sx={{
                color: viewType === 'CARD' ? '#000' : theme.palette.neutralCool[400],
              }}
              disabled={rows.length === 0}
              onClick={() => {
                setActiveStep(0);
                setViewType('CARD');
              }}
            >
              <ViewGridIcon />
            </IconButton>
            <IconButton
              sx={{
                color: viewType === 'TABLE' ? '#000' : theme.palette.neutralCool[400],
              }}
              disabled={rows.length === 0}
              onClick={() => setViewType('TABLE')}
            >
              <ViewListIcon />
            </IconButton>
            <Box height="44px" mx={tokens.SPACING_INDICES.lg}>
              <Divider orientation="vertical" />
            </Box>
            <Button
              variant="tertiary"
              disabled={numHistoryItems === 0}
              onClick={() => history.push(`/secure/dashboard/history`)}
            >
              View History
            </Button>
          </Box>
        </Box>
        <Box height={tokens.SPACING_INDICES.xl} />
      </ThemeProvider>
      <Box display="flex" flexDirection="column">
        {rows.length === 0 && (
          <Box
            height="439px"
            border={`solid 1px ${theme.palette.secondaryPurple[200]}`}
            boxShadow={tokens.shadow.medium}
            borderRadius="4px"
          >
            <EmptyState text="No new activities, check back soon for updates." />
          </Box>
        )}
        {viewType === 'CARD' && rows.length > 0 && (
          <Grid container spacing={3}>
            {cardsBody}
          </Grid>
        )}
        {viewType === 'TABLE' && rows.length > 0 && (
          <DataGridPro
            columns={columns}
            rows={rows}
            autoHeight
            rowHeight={72}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pagination
            hideFooter={false}
          />
        )}
        {viewType === 'CARD' && rows.length > 0 && (
          <>
            <Box mt={3}>
              <Divider />
            </Box>
            {(notifications || []).length > 0 && (
              <Box height="72px" display="flex" alignItems="center">
                <Box width="100%">
                  <PageNavigation
                    currentPage={activeStep + 1}
                    pageSize={PAGE_SIZE}
                    onNextPageClick={() => setActiveStep(activeStep + 1)}
                    onPrevPageClick={() => setActiveStep(activeStep - 1)}
                    onPageNumberClick={(page) => setActiveStep(page - 1)}
                    numItems={(notifications || []).length}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
      <LinkAccountOrCreateClientModal
        accountId={linkAccountModalSettings.accountId}
        open={linkAccountModalSettings.open}
        onLinkAccountToExistingClient={async (clientId) => {
          await onLinkAccount(clientId);
        }}
        onSuccess={async (client) => {
          await onLinkAccount(client.id);
        }}
        onClose={() =>
          setLinkAccountModalSettings({
            accountId: null,
            open: false,
            notif: null,
            id: null,
          })
        }
      />
    </>
  );
};

export default ActivityMonitor;
