import React from 'react';
import { Box, BoxProps } from '@mui/material';

export interface CardContentProps extends BoxProps {
  borderBottom?: 0 | 1;
  borderRight?: 0 | 1;
}

const CardContent = ({ borderBottom = 0, borderRight = 0, ...restProps }: CardContentProps) => (
  <Box
    flexGrow={1}
    sx={{ borderColor: 'grey.200' }}
    border={1}
    // `borderColor` is not respected with `border` declaration, use a workaround
    // see https://github.com/mui-org/material-ui/issues/16995#issuecomment-594134826
    borderBottom={borderBottom === 0 || borderBottom === undefined ? 0 : undefined}
    borderTop={0}
    borderLeft={0}
    borderRight={borderRight === 0 || borderRight === undefined ? 0 : undefined}
    p={3}
    {...restProps}
  />
);

export default CardContent;
