import { Box, Button, IconButton, InputAdornment, useTheme } from '@mui/material';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Column,
  TableState,
  UsePaginationState,
  UseSortByOptions,
  UseSortByState,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { BondPortfolioSettings, EngineType } from 'vise-types/portfolio';
import PageNavigation from '~/components/table/PageNavigation';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { ProposalSummary } from '~/models/household';
import { NoResultsFound } from '~/routes/Portfolio/components/TableComponents';
import { ReactComponent as DotsIcon } from '~/static/images/icons/dots-horizontal.svg';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';
import { DataTable, DataTableActionBar } from '~/synth/DataTable';
import DropdownButtonMenu, { DropdownButtonMenuItem } from '~/synth/DropdownButtonMenu';
import { SearchIcon } from '~/synth/Icons';
import TextField from '~/synth/TextField';
import EmptyState from '~/synth/deprecated/EmptyState';
import { WithLoader } from '~/utils';
import amplitude from '~/utils/amplitude';
import { formatCurrency } from '~/utils/format';
import scrollToTop from '~/utils/scrollToTop';
import renderAccountRemovalStatus from '../components/renderAccountRemovalStatus';
import { PROPOSALS_MODAL_CLOSED_SETTINGS } from '../modal/ProposalEditRenameContextMenu';
import { ProposalsModal, ProposalsModalProps } from '../modal/ProposalsModal';
import { AccountNameCell } from '../table/UtilComponents';
import LinkAccountOrCreateClientModal from './LinkAccountOrCreateClientModal';
import LoadingTable from './LoadingTable';
import { AccountTableData, renderClientAndHouseholdCell } from './PortfoliosTable';
import ValueCell from './ValueCell';

type UntransitionedAccountsTableProps = {
  tableData: AccountTableData[] | undefined;
  pageSize?: number;
};

export default function UntransitionedAccountsTable({
  tableData,
  pageSize = 50,
}: UntransitionedAccountsTableProps) {
  const history = useHistory();
  const accountsIsLoading = !tableData;
  const { data: featureFlags } = useFeatureFlags();

  const [proposalsModalSettings, setProposalsModalSettings] = useState<
    Pick<
      ProposalsModalProps,
      | 'open'
      | 'accountName'
      | 'proposals'
      | 'accountId'
      | 'accountNumber'
      | 'viseClientId'
      | 'accountId'
      | 'bondProposals'
      | 'engineType'
    >
  >(PROPOSALS_MODAL_CLOSED_SETTINGS);

  const [linkAccountModalSettings, setLinkAccountModalSettings] = useState<{
    accountId: string | null;
    open: boolean;
  }>({ accountId: null, open: false });

  const closeProposalsModal = () => {
    setProposalsModalSettings(PROPOSALS_MODAL_CLOSED_SETTINGS);
  };
  const openProposalsModal = useCallback(
    (accountData: {
      accountName: string;
      proposals: ProposalSummary[];
      id: string;
      accountNumber: string;
      viseClientId?: string | undefined;
      bondProposals: BondPortfolioSettings[];
      engineType: EngineType;
    }) =>
      setProposalsModalSettings({
        open: true,
        accountName: accountData.accountName,
        proposals: accountData.proposals,
        accountId: accountData.id,
        accountNumber: accountData.accountNumber,
        viseClientId: accountData.viseClientId,
        bondProposals: accountData.bondProposals,
        engineType: accountData.engineType,
      }),
    []
  );
  const [filterQuery, setFilterQuery] = useState('');
  const filteredTableData = useMemo(
    () =>
      tableData
        ? matchSorter(tableData, filterQuery, {
            keys: ['accountName', 'accountNumber'],
            threshold: matchSorter.rankings.CONTAINS,
          })
        : [],
    [filterQuery, tableData]
  ) as AccountTableData[];

  const accountColumns = useMemo(
    () =>
      [
        {
          Header: 'Account',
          accessor: 'accountName',
          Cell: (props) => {
            const { accountName, accountNumber, taxable, custodianKey, engineType } =
              props.row.original;
            return (
              <AccountNameCell
                accountName={accountName}
                accountNumber={accountNumber}
                taxable={taxable}
                custodianKey={custodianKey}
                engineType={engineType}
              />
            );
          },
          width: '40%',
          // Sort by account name & then by account number
          sortType: (rowA, rowB) => {
            const {
              original: { accountName: accountNameA, accountNumber: accountNumberA },
            } = rowA;
            const {
              original: { accountName: accountNameB, accountNumber: accountNumberB },
            } = rowB;
            const compareResult = accountNameA.localeCompare(accountNameB);
            if (compareResult !== 0) {
              return compareResult;
            }

            return accountNumberA.localeCompare(accountNumberB);
          },
        },
        {
          Header: 'Client / Household',
          accessor: 'viseClient',
          Cell: renderClientAndHouseholdCell,
          width: '20%',
          // First sort by household name within each household sort by account
          sortType: (rowA, rowB) => {
            const result = (rowA.original.viseClient?.clientGroup?.name || '').localeCompare(
              rowB.original.viseClient?.clientGroup?.name || ''
            );
            if (result !== 0) {
              return result;
            }

            const accountNameResult = rowA.original.accountName.localeCompare(
              rowB.original.accountName
            );
            if (accountNameResult !== 0) {
              return accountNameResult;
            }

            return rowA.original.accountNumber.localeCompare(rowB.original.accountNumber);
          },
        },
        {
          Header: 'Account value',
          accessor: 'cachedAum',
          Cell: (props) => (
            <ValueCell value={formatCurrency(props.value)} isLoading={accountsIsLoading} />
          ),
        },
        {
          accessor: 'proposals',
          width: '30%',
          Cell: ({
            cell: {
              row: {
                original: {
                  accountName,
                  accountNumber,
                  proposals,
                  id,
                  viseClientId,
                  status,
                  statusReason,
                  engineType,
                  bondProposals,
                },
              },
            },
            value,
          }) => (
            <Box display="flex" justifyContent="end">
              {value && value.length > 0 ? (
                <Button
                  size="small"
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                    openProposalsModal({
                      accountName,
                      proposals: proposals ?? [],
                      id,
                      accountNumber,
                      viseClientId,
                      bondProposals: bondProposals ?? [],
                      engineType: engineType ?? 'PCE2',
                    });
                  }}
                  color="primary"
                >
                  View {value.length} {value.length > 1 ? 'proposals' : 'proposal'}
                </Button>
              ) : null}
              {(featureFlags?.enable_pc_for_unlinked_accounts === 'on' || viseClientId != null) &&
                statusReason !== 'account_removal_event' && (
                  <Button
                    color="primary"
                    onClick={(event: React.MouseEvent) => {
                      // Prevent clicking this link from triggering the `onclick` in the ancestor row
                      event.stopPropagation();
                      if (viseClientId == null) {
                        setLinkAccountModalSettings({
                          open: true,
                          accountId: id,
                        });
                      } else {
                        // eslint-disable-next-line no-unused-expressions
                        engineType === 'AB_FIXED_INCOME'
                          ? history.push(`/secure/bond-portfolio-creator/select-client`)
                          : history.push(
                              `/secure/portfolio-creator-next?${
                                viseClientId != null ? `clientId=${viseClientId}&` : ''
                              }accountId=${id}`
                            );
                      }
                    }}
                    size="small"
                  >
                    {viseClientId == null ? 'Link account' : 'Create proposal'}
                  </Button>
                )}

              {renderAccountRemovalStatus({
                value: {
                  status,
                  statusReason,
                },
              })}

              {statusReason !== 'account_removal_event' && (
                <Box display="flex">
                  <DropdownButtonMenu
                    buttonContent={<DotsIcon height="20" width="20" />}
                    isIcon
                    buttonProps={{ 'aria-label': 'Action item menu' }}
                  >
                    {(closeMenu) => [
                      <DropdownButtonMenuItem
                        key="mi-remove-account"
                        onClick={() => {
                          history.push(`/secure/client/accounts/${id}/remove`);
                          amplitude().logEvent('RemoveAccountFromVise', {
                            category: EVENT_CATEGORIES.REMOVE_ACCOUNT,
                          });
                          closeMenu();
                        }}
                      >
                        Remove Account From Vise
                      </DropdownButtonMenuItem>,
                    ]}
                  </DropdownButtonMenu>
                </Box>
              )}
            </Box>
          ),
        },
      ] as Column<AccountTableData>[] & UseSortByOptions<AccountTableData>[],
    [openProposalsModal, featureFlags, history, accountsIsLoading]
  );

  const accountTable = useTable<AccountTableData>(
    {
      columns: accountColumns,
      data: filteredTableData,
      initialState: {
        sortBy: [{ id: 'accountName' }],
        pageSize,
      } as TableState<AccountTableData> &
        UseSortByState<AccountTableData> &
        UsePaginationState<AccountTableData>,
    },
    useSortBy,
    usePagination
  );

  const theme = useTheme();
  return (
    <>
      <DataTableActionBar px={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box width={330}>
            <TextField
              InputProps={{
                endAdornment: filterQuery ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setFilterQuery('');
                      }}
                      size="small"
                      type="button"
                    >
                      <XIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
                onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
                  if (event.currentTarget === event.target && event.key === 'Escape') {
                    setFilterQuery('');
                  }
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon display="inline-flex" color={theme.palette.grey[500]} />
                  </InputAdornment>
                ),
              }}
              aria-label="Filter accounts by name or account number"
              fullWidth
              id="account_filter"
              name="filter_accounts"
              onChange={(e) => setFilterQuery(e.target.value)}
              placeholder="Filter by name or account number"
              value={filterQuery}
            />
          </Box>
        </Box>
      </DataTableActionBar>
      <WithLoader isLoading={accountsIsLoading} loader={<LoadingTable pageSize={pageSize} />}>
        {tableData != null && tableData.length > 0 ? (
          <>
            <DataTable columns={accountColumns} table={accountTable} rowSize="large" />
            {!filteredTableData.length && <NoResultsFound />}
          </>
        ) : (
          <Box py={8}>
            <EmptyState
              large
              title="No new accounts"
              subtitle="Accounts will appear in this section once onboarded through the custodian."
            />
          </Box>
        )}
      </WithLoader>
      {filteredTableData != null && filteredTableData.length > pageSize && (
        <Box
          alignItems="center"
          borderColor="grey.200"
          borderTop={1}
          display="flex"
          justifyContent="flex-end"
          px={3}
          py={2}
        >
          <PageNavigation
            currentPage={(accountTable.state as UsePaginationState<AccountTableData>).pageIndex + 1}
            numItems={filteredTableData.length}
            pageSize={pageSize}
            onNextPageClick={() => {
              scrollToTop();
              accountTable.nextPage();
            }}
            onPrevPageClick={() => {
              scrollToTop();
              accountTable.previousPage();
            }}
            onPageNumberClick={(pageNumber) => {
              scrollToTop();
              accountTable.gotoPage(pageNumber - 1);
            }}
          />
        </Box>
      )}
      <ProposalsModal
        open={proposalsModalSettings.open}
        onClose={() => closeProposalsModal()}
        accountName={proposalsModalSettings.accountName}
        accountId={proposalsModalSettings.accountId}
        accountNumber={proposalsModalSettings.accountNumber}
        viseClientId={proposalsModalSettings.viseClientId}
        proposals={proposalsModalSettings.proposals}
        bondProposals={proposalsModalSettings.bondProposals}
        engineType={proposalsModalSettings.engineType}
      />
      <LinkAccountOrCreateClientModal
        open={linkAccountModalSettings.open}
        onClose={() => {
          setLinkAccountModalSettings({
            accountId: null,
            open: false,
          });
        }}
        accountId={linkAccountModalSettings.accountId}
      />
    </>
  );
}
