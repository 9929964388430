import {
  Action,
  Middleware,
  PreloadedState,
  Reducer,
  applyMiddleware,
  compose,
  createStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

export default function configureStore<State>(
  preloadedState: PreloadedState<State>,
  rootReducer: Reducer<State, Action<unknown>>
) {
  // Note: order is significant here. Logger must come _after_ Thunk to prevent thunks and promises
  // from being logged.
  //
  // See https://github.com/LogRocket/redux-logger/issues/302#issuecomment-416767088
  let middlewares: Middleware[] = [thunk];
  if (process.env.NODE_ENV === 'development')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    middlewares = [...middlewares, createLogger({ collapsed: true })] as any;
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];

  const composedEnhancers =
    (process.env.NODE_ENV === 'development' && composeWithDevTools(...enhancers)) ||
    compose(...enhancers);

  return createStore(rootReducer, preloadedState, composedEnhancers);
}
