import { Box, Button, Card, Divider, Grid, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import CardHeader from '~/synth/CardHeader';
import CardContent from '~/synth/CardContent';

import { keyBy } from 'lodash';
import { ReactComponent as PlusCircleIcon } from '~/static/images/icons/plus-circle.svg';
import { ReactComponent as MinusCircleIcon } from '~/static/images/icons/minus-circle.svg';
import {
  AllocationsTemplate,
  GetModelTemplateCenterViewDataResponse,
  RestrictionsTemplate,
} from 'vise-types/template';
import { formatCurrency, formatDate } from '~/utils/format';
import { formatTemplateNames, getAccountsWithExistingNames } from '../utils';

export default function LinkingSummary({
  data,
  linkAccountIds,
  unlinkAccountIds,
  setStep,
  template,
}: {
  data: GetModelTemplateCenterViewDataResponse;
  linkAccountIds: string[];
  unlinkAccountIds: string[];
  setStep: React.Dispatch<React.SetStateAction<'LINK' | 'UNLINK' | 'REVIEW'>>;
  template: AllocationsTemplate | RestrictionsTemplate;
}) {
  const accountsById = useMemo(() => keyBy(data.accounts, 'id'), [data.accounts]);
  const theme = useTheme();
  const accountsWithExistingNames = useMemo(
    () => getAccountsWithExistingNames(data, template),
    [data, template]
  );

  const unlinkData = useMemo(() => {
    const linksToTargetTemplate = data.templateLinks.filter(
      (t) => t.originalTemplateId === template.originalTemplateId
    );
    const toUnlinkSet = new Set(unlinkAccountIds);
    const links = linksToTargetTemplate.filter((link) => toUnlinkSet.has(link.accountId));
    return links.map((link) => ({
      account: accountsById[link.accountId],
      linkedSince: link.linkedSince,
      value: accountsById[link.accountId]?.cachedAum,
    }));
  }, [accountsById, data.templateLinks, template.originalTemplateId, unlinkAccountIds]);

  const toLinkData = useMemo(() => {
    const toLinkSet = new Set(linkAccountIds);
    const accounts = accountsWithExistingNames.filter((acc) => toLinkSet.has(acc.id));
    return accounts.map((account) => ({
      account,
      value: account.cachedAum,
      currentTemplates: formatTemplateNames(account.existingTemplateNames),
    }));
  }, [accountsWithExistingNames, linkAccountIds]);

  return (
    <>
      <Box width="971px" mx="auto" mb={3}>
        <Card>
          <CardHeader>
            <Box display="flex" alignItems="center">
              <Box flex={2}>
                <Typography variant="h6" color="textSecondary">
                  STEP{' '}
                  {data.templateLinks.filter(
                    (l) => l.originalTemplateId === template.originalTemplateId
                  ).length === 0
                    ? '2'
                    : '3'}
                </Typography>
                <Box mb={0.5} />
                <Typography variant="h3">Review & Save template</Typography>
                <Box mb={0.5} />
                <Typography variant="body2" color="textSecondary">
                  Add any accounts you’d like to link to this template.
                </Typography>
              </Box>
            </Box>
          </CardHeader>
        </Card>
      </Box>

      {/* Unlinked accounts */}
      {unlinkData.length > 0 && (
        <Box width="971px" mx="auto" mb={3}>
          <Card>
            <CardHeader display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Box mx={1} pt={0.5}>
                  <MinusCircleIcon height="25" width="25" color={theme.palette.warning.main} />
                </Box>
                <Box>
                  <Typography variant="h3"> Unlinked accounts</Typography>
                </Box>
                <Box bgcolor="grey.300" mx={1.5}>
                  <Divider orientation="vertical" flexItem style={{ height: '17.5px' }} />
                </Box>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >{` ${unlinkAccountIds.length} removed `}</Typography>
              </Box>
              <Button variant="outlined" onClick={() => setStep('UNLINK')}>
                Edit
              </Button>
            </CardHeader>
            <CardContent>
              {unlinkData.length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} key="item.id">
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="h4">Account</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="h4">Value</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h4">Linked since</Typography>
                      </Grid>
                      <Divider />

                      {unlinkData &&
                        unlinkData.map((row) => (
                          <>
                            <Grid item xs={5}>
                              <Typography variant="body2">{row.account.accountName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="body2">{formatCurrency(row.value)}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body2">
                                {formatDate(new Date(row.linkedSince))}
                              </Typography>
                            </Grid>
                          </>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Box>
      )}

      {toLinkData.length > 0 && (
        <Box width="971px" mx="auto">
          <Card>
            <CardHeader display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center">
                <Box mx={1} pt={0.5}>
                  <PlusCircleIcon height="25" width="25" color={theme.palette.success[400]} />
                </Box>
                <Box>
                  <Typography variant="h3"> New accounts</Typography>
                </Box>
                <Box bgcolor="grey.300" mx={1.5}>
                  <Divider orientation="vertical" flexItem style={{ height: '17.5px' }} />
                </Box>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >{` ${toLinkData.length} added `}</Typography>
              </Box>
              <Button variant="outlined" onClick={() => setStep('LINK')}>
                Edit
              </Button>
            </CardHeader>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} key="item.id">
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Typography variant="h4">Account</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="h4">Value</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h4">
                        Current {template.type === 'allocations' ? 'allocation' : 'restriction'}{' '}
                        template(s)
                      </Typography>
                    </Grid>
                    <Divider />

                    {toLinkData.map((row) => (
                      <>
                        <Grid item xs={5}>
                          <Typography variant="body2">{row.account.accountName}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body2">{formatCurrency(row.value)}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">{row.currentTemplates}</Typography>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}
