import React from 'react';
import { Box, BoxProps, Button, Tooltip } from '@mui/material';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { DraftPortfolio } from './Types';
import { cantSkipCategories, CATEGORY_TO_COPY } from './screens/SummaryLandingScreen/SummaryUtils';

interface ReturnToSummaryProps extends BoxProps {
  draftPortfolio: DraftPortfolio;
  disabled: boolean;
  onReturnToSummary: () => void;
}

export default function ReturnToSummary({
  draftPortfolio,
  disabled,
  onReturnToSummary,
  ...boxProps
}: ReturnToSummaryProps) {
  const { data: featureFlags } = useFeatureFlags();

  const categoriesCantSkip = cantSkipCategories({
    draftPortfolio,
    featureFlags,
  });

  if (
    draftPortfolio.originalDraftPortfolio == null ||
    draftPortfolio.previousDraftPortfolio == null ||
    featureFlags?.enable_return_to_summary !== 'on'
  ) {
    return <></>;
  }

  const categoryCopy = Array.from(
    categoriesCantSkip.reduce<Set<string>>((acc, category) => {
      if (category in CATEGORY_TO_COPY) {
        acc.add(CATEGORY_TO_COPY[category]);
      } else {
        acc.add('Unknown');
      }
      return acc;
    }, new Set())
  );

  return (
    <Box {...boxProps}>
      <Tooltip
        title={
          categoriesCantSkip.length > 0 ? (
            <>
              Return to summary unavailable because the following inputs were updated:
              <ul>
                {categoryCopy.map((category) => (
                  <li key={category}>{category}</li>
                ))}
              </ul>
              These changes may have a material impact on the target portfolio. Please review
              remaining portfolio creator steps before returning to summary.
            </>
          ) : (
            ''
          )
        }
      >
        <Box>
          <Button
            color="secondary"
            disabled={categoriesCantSkip.length > 0 || disabled}
            onClick={onReturnToSummary}
            variant="outlined"
          >
            Return to summary
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
}
