import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DialogTitle from '~/synth/DialogTitle';
import TextField from '~/synth/TextField';
import { PASSWORD_MATCH_REGEX } from '../../constants';

interface UpdatePasswordProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (form: { oldPassword: string; newPassword: string }) => Promise<boolean>;
}

export default function UpdatePassword({ open, onClose, onSubmit }: UpdatePasswordProps) {
  const [awaitingSubmit, setAwaitingSubmit] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');

  useEffect(() => {
    setNewPassword('');
    setOldPassword('');
  }, [open]);

  function captureFormChange({ target: { name, value } }): void {
    if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'oldPassword') {
      setOldPassword(value);
    }
  }

  async function handleFormSubmit(event: React.FormEvent): Promise<void> {
    event.preventDefault();
    setAwaitingSubmit(true);

    let success = false;
    try {
      success = await onSubmit({ oldPassword, newPassword });
      if (success) {
        onClose();
      }
    } catch (error) {
      // TODO: return meaningful error types, explain to user what is happening
    } finally {
      setAwaitingSubmit(false);
    }
  }

  function disableFormSubmit(): boolean {
    if (!oldPassword) {
      return true;
    }

    return !PASSWORD_MATCH_REGEX.test(newPassword);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Update password</DialogTitle>
      <form onSubmit={handleFormSubmit}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Type in the old password, and pick a new one. Don&apos;t use names, surnames,
                birthdays, anniversaries, or common phrases for the password. Allowed special
                symbols are {'~!@#$%^&*_+=|<>?'}.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="oldPassword"
                label="Old password"
                name="oldPassword"
                onChange={captureFormChange}
                type="password"
                value={oldPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="The password must be at least 8 characters long and include at least one lowercase, one uppercase, and one number character."
                id="newPassword"
                label="New password"
                name="newPassword"
                onChange={captureFormChange}
                type="password"
                value={newPassword}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} type="button" variant="outlined">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={disableFormSubmit() || awaitingSubmit}
            type="submit"
            variant="contained"
          >
            {awaitingSubmit ? 'Updating...' : 'Update password'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
