import { Box, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { TemplateAccountUpdateType, TemplateUpdateEventStatus } from '~/models/api';
import { ReactComponent as MinusCircleIcon } from '~/static/images/icons/minus-circle.svg';
import { DataTable } from '~/synth/DataTable';
import { Column } from 'react-table';
import { formatCurrency } from '~/utils/format';
import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import { TemplateUpdateEvent, TemplateUpdateJob } from 'vise-types/template';
import Card from '../workflows/Card';
import { AccountCell, CTACell } from '../workflows/CommonComponents';
import { CONTAINER_WIDTH } from '../workflows/constants';

interface UnlinkAccountTableType {
  account?: AccountWithPIAndHouseholdInfo;
  value?: number | null;
  link: {
    path: string;
    buttonCopy: string;
  };
  operation: string;
}

function leftPad(Component) {
  return (props) => (
    <Box ml={1}>
      <Component {...props} />
    </Box>
  );
}

const unlinkAccountColumns: Column<UnlinkAccountTableType>[] = [
  { Header: leftPad(() => <>Account</>), accessor: 'account', Cell: leftPad(AccountCell) },
  {
    Header: 'Value',
    accessor: (row) => formatCurrency(row.value),
  },
  {
    Header: 'Operation',
    accessor: (row) => row.operation,
  },
  { Header: '', Cell: CTACell, accessor: 'link' },
];

type UnlinkedEventType = TemplateUpdateEvent &
  (
    | {
        status: 'PROPOSAL_EXECUTED';
        accountUpdateType: 'REMOVE';
      }
    | {
        status: 'PROPOSAL_IGNORED';
      }
    | {
        status: 'PROPOSAL_CANCELLED';
      }
  );

export default function UnlinkedAccountsActivityTable({
  jobData,
  accountsById,
  noProposalUnlinkAccountIds,
  maxWidth,
}: {
  jobData?: TemplateUpdateJob;
  accountsById: {
    [key: string]: AccountWithPIAndHouseholdInfo | undefined;
  };
  noProposalUnlinkAccountIds: string[];
  maxWidth: string;
}) {
  const theme = useTheme();
  const unlinkAccountTableData: UnlinkAccountTableType[] = useMemo(() => {
    const asyncUnlinks =
      jobData?.events
        .filter(
          (ev): ev is UnlinkedEventType =>
            (ev.accountUpdateType === TemplateAccountUpdateType.REMOVE &&
              ev.status === TemplateUpdateEventStatus.PROPOSAL_EXECUTED) ||
            ev.status === TemplateUpdateEventStatus.PROPOSAL_IGNORED ||
            ev.status === TemplateUpdateEventStatus.PROPOSAL_CANCELLED
        )
        .map((ev) => {
          const account = accountsById[ev.accountId];
          let operation = 'Apply';
          if (jobData.reason === 'BULK_EDIT') {
            operation = 'Update 2024 capital gains budget';
          } else if (ev.accountUpdateType === 'REMOVE') {
            operation = 'Unlinked';
          } else if (ev.accountUpdateType === 'APPLY') {
            operation = 'Omitted from linking';
          }
          return {
            account,
            link: {
              path: `/secure/accounts/${ev.accountId}/portfolio/overview`,
              buttonCopy: 'View account',
            },
            value: account?.cachedAum,
            operation,
          };
        }) || [];

    const noProposalUnlinks = noProposalUnlinkAccountIds.map((accId) => {
      const account = accountsById[accId];
      return {
        account,
        link: {
          path: `/secure/accounts/${accId}/portfolio/overview`,
          buttonCopy: 'View account',
        },
        value: account?.cachedAum,
        operation: 'Unlinked',
      };
    });

    return [...noProposalUnlinks, ...asyncUnlinks];
  }, [jobData?.events, noProposalUnlinkAccountIds, accountsById, jobData?.reason]);

  if (!unlinkAccountTableData.length) {
    return null;
  }

  return (
    <Box my={3}>
      <Card fullWidth maxWidth={maxWidth || CONTAINER_WIDTH}>
        <Box display="flex" alignItems="center" mx={3}>
          <Box>
            <Box display="flex" alignItems="center">
              <MinusCircleIcon color={theme.palette.warning[400]} />
              <Box ml={1}>
                <Typography variant="h4">
                  {jobData?.reason === 'BULK_EDIT'
                    ? 'Canceled updates'
                    : 'Unlinked and ignored accounts'}
                </Typography>
              </Box>
            </Box>
            <Box mt={1}>
              {jobData?.reason === 'BULK_EDIT' ? (
                <Typography variant="body1" color="textSecondary">
                  {unlinkAccountTableData.length} update
                  {unlinkAccountTableData.length > 1 ? 's' : ''}
                  {unlinkAccountTableData.length === 1 ? ' was' : 's were'} not applied.
                </Typography>
              ) : (
                <Typography variant="body1" color="textSecondary">
                  {unlinkAccountTableData.length} account
                  {unlinkAccountTableData.length === 1 ? ' was' : 's were'} unlinked from the
                  template or omitted from linking.
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box mt={3}>
          <DataTable
            columns={unlinkAccountColumns}
            data={unlinkAccountTableData}
            m={0}
            rowSize="large"
          />
        </Box>
      </Card>
    </Box>
  );
}
