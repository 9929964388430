import { Box } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import type {
  BondPortfolioCreditQualityAllocation,
  BondPortfolioCreditQualityRating,
} from 'vise-types/portfolio';
import useCreditToColor from '~/routes/BondPortfolio/useCreditToColor';
import { formatPercent } from '~/utils/format';
import { CREDIT_RATING_TO_LABEL } from './Utils';

/**
 * Sorted list of ratings for the order they should appear in pie chart + legend.
 */
const CREDIT_RATING_SORT_PRIORITY: BondPortfolioCreditQualityRating[] = [
  'AAA',
  'AA_PLUS',
  'AA',
  'AA_MINUS',
  'A_PLUS',
  'A',
  'BBB',
  'BB',
  'B',
  'CCC',
  'CC',
  'C',
  'D',
  'NOT_RATED',
  'UNKNOWN',
];

type PropType = {
  ratings: BondPortfolioCreditQualityAllocation;
};

export default function BondPortfolioCreditRatingChart({ ratings }: PropType) {
  const { CREDIT_TO_COLOR } = useCreditToColor();

  const creditRatingChartOptions: Highcharts.Options = {
    chart: { type: 'pie', backgroundColor: undefined, height: 300 },
    plotOptions: {
      pie: {
        size: '100%',
        innerSize: '80%',
        dataLabels: {
          enabled: false,
        },
      },
    },
    title: { text: '' },
    credits: { enabled: false },
    tooltip: { pointFormat: '<b>{point.percentage:.1f}%</b>' },
    series: [
      {
        type: 'pie',
        name: 'Credit quality',
        data: ratings
          .sort(
            (a, b) =>
              CREDIT_RATING_SORT_PRIORITY.indexOf(a.rating) -
              CREDIT_RATING_SORT_PRIORITY.indexOf(b.rating)
          )
          .map((rating) => ({
            name: CREDIT_RATING_TO_LABEL[rating.rating] ?? '-',
            y: rating.percentage,
            color: CREDIT_TO_COLOR[rating.rating],
          })),
      },
    ],
  };

  return <HighchartsReact options={creditRatingChartOptions} />;
}

export function ChartLegend({ ratings }: PropType) {
  const { CREDIT_TO_COLOR } = useCreditToColor();

  return (
    <Box>
      {ratings
        .filter((rating) => rating.percentage > 0)
        .sort(
          (a, b) =>
            CREDIT_RATING_SORT_PRIORITY.indexOf(a.rating) -
            CREDIT_RATING_SORT_PRIORITY.indexOf(b.rating)
        )
        .map((rating) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            key={rating.rating}
          >
            <Box display="flex" alignItems="center" mt={2}>
              <Box
                width={12}
                height={12}
                borderRadius={0.5}
                bgcolor={CREDIT_TO_COLOR[rating.rating]}
                mr={1}
              />
              {CREDIT_RATING_TO_LABEL[rating.rating] ?? '-'}
            </Box>
            <Box color={tokens.palette.neutralCool[700]}>{formatPercent(rating.percentage, 1)}</Box>
          </Box>
        ))}
    </Box>
  );
}
