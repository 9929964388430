import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React from 'react';
import FillInLotsWarning from '~/static/images/PortfolioCreator2/csv-fill-in-lots-warning.svg';

export default function IncompleteTaxLotsModal({
  open,
  onClose,
  handleEditHoldings,
  handleFillInLots,
  disableActions,
}: {
  open: boolean;
  onClose: () => void;
  handleEditHoldings: () => void;
  handleFillInLots: () => void;
  disableActions: boolean;
}) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        <Box textAlign="center">
          <Box mb={4}>
            <img src={FillInLotsWarning} width={150} height={150} alt="Missing tax lots" />
          </Box>
          <Typography variant="h2">Continue with incomplete tax lots?</Typography>
          <Box mt={2}>
            We recommend making an educated guess about cost basis for each holding if possible. If
            you continue holdings with missing tax lots will be given a <b>$0</b> cost basis and
            assumed to be held long term. This will reduce the proposal&apos;s overall accuracy,
            especially if these positions have embedded gains.
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleEditHoldings}
          disabled={disableActions}
          color="secondary"
        >
          Edit holdings
        </Button>
        <Box ml={1.5}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFillInLots}
            disabled={disableActions}
          >
            Continue
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
