import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ValueType } from 'react-select';
import { Account } from 'vise-types/portfolio';
import { PortfolioInsightsResponse } from 'vise-types/pce2';
import { Feature, Sector } from 'vise-types/pce2_instrument';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { AssetToColorMap, useAssetToColorMap } from '~/hooks/useAssetToColorMap';
import useCategoricalDataVisColors from '~/hooks/useCategoricalDataVisColors';
import {
  AdjustedConstructedPortfolioMetrics,
  Allocation,
  AssetClassAllocation,
  SectorAllocation,
} from '~/models/api';
import { ASSET_CLASS_TO_LABEL_MAP } from '~/routes/PortfolioCreator2/Constants';
import CardFooter from '~/synth/CardFooter';
import CardHeader from '~/synth/CardHeader';
import Select from '~/synth/inputs/Select';
import PopoverTrigger from '~/synth/PopoverTrigger';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import ChartColorSquare from '../../../components/chart/ChartColorSquare';
import AllocationChart from '../../../components/portfolio/AllocationChart2';
import amplitude from '../../../utils/amplitude';
import { formatPercent } from '../../../utils/format';
import Truncation from '../components/Truncation';
import {
  adjustAllocationByPctOfManagedValue,
  adjustSectorAllocationByOther,
  AllocationChartDataPoint,
  groupAssetAllocation,
} from '../portfolioUtil';
import InsightsCardFooter from './InsightsCardFooter';
import { ViewDetailsLink } from '../PortfolioInsights';
import { SECTOR_TO_LABEL_MAP } from '../Constants';

interface AllocationOption {
  label: string;
  value: string;
}

const ALLOCATION_OPTIONS = [
  { label: 'Total portfolio', value: 'total-portfolio' },
  { label: 'Vise managed', value: 'vise-managed' },
];

function RemainingSectorsPopoverListItem({ assetName, y }: { assetName: Sector; y: number }) {
  return (
    <Box
      role="listitem"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height={36}
      borderColor="grey.100"
      border={1}
      borderLeft={0}
      borderRight={0}
      borderTop={0}
      width={350}
      maxHeight={260}
    >
      <Box pl={1}>{SECTOR_TO_LABEL_MAP.get(assetName) || 'Unknown'}</Box>
      <Box pr={1}>{formatPercent(y, 1)}</Box>
    </Box>
  );
}

export function processAssetClassAllocationData(
  adjustedAllocationData: AllocationChartDataPoint[],
  assetToColorMap: AssetToColorMap
) {
  // There is no way in Highcharts to group the two donut charts together so we have to make sure
  // the data is in the right order. (Eg. all equities => all fixed income => all alternatives => cash).
  const assetClassData: AllocationChartDataPoint[] = [];
  const assetClassCategoryData: AllocationChartDataPoint[] = [];
  const groupedAssetClassAllocation = groupAssetAllocation(adjustedAllocationData);

  Object.entries(groupedAssetClassAllocation).forEach(([key, value]) => {
    const valueWithColors = value.map((holding, idx) => {
      return { ...holding, color: assetToColorMap[key].colors[idx] };
    });

    // Extract the grouped data for the more detailed outer donut chart.
    assetClassData.push(...valueWithColors);
    // Sum up percentages for the inner asset group donut chart.
    const groupSum = value.reduce((acc, el) => {
      return acc + el.y;
    }, 0);
    assetClassCategoryData.push({
      name: key,
      color: assetToColorMap[key].main,
      y: groupSum,
    });
  });
  return [assetClassData, assetClassCategoryData];
}

function processSectorAllocationData(
  adjustedSectorData: AllocationChartDataPoint[],
  assetToColorMap: AssetToColorMap
): [AllocationChartDataPoint[], AllocationChartDataPoint[]] {
  if (!adjustedSectorData.length) {
    return [[], []];
  }

  const showingViseManged = adjustedSectorData[adjustedSectorData.length - 1].name === 'LOCKED';
  const viseManaged = showingViseManged ? adjustedSectorData.pop() : null;
  const sortedSectorData = adjustedSectorData.sort((a, b) => (a.y < b.y ? 1 : -1));
  const chartData = sortedSectorData.length > 6 ? sortedSectorData.slice(0, 5) : sortedSectorData;
  const remainingSectorsData = sortedSectorData.length > 6 ? sortedSectorData.slice(5) : [];
  const remainigSectorsAllocation = remainingSectorsData.reduce((acc, el) => {
    return acc + el.y;
  }, 0);
  if (remainigSectorsAllocation > 0) {
    chartData.push({
      name: 'MORE',
      y: remainigSectorsAllocation,
      color: assetToColorMap.MORE.main,
    });
  }
  if (viseManaged) {
    // It is more clear to show the whole path for accessing colors.
    // eslint-disable-next-line prefer-destructuring
    viseManaged.color = assetToColorMap.MORE.colors[1];
    chartData.push(viseManaged);
  }

  return [remainingSectorsData, chartData];
}

export function AssetAllocationChart({
  assetAllocation,
  chartTitle = 'Asset Classes',
}: {
  assetAllocation: Allocation;
  chartTitle?: React.ReactNode;
}) {
  const assetToColorMap = useAssetToColorMap();
  const assetAllocationChartData = processAssetClassAllocationData(
    assetAllocation,
    assetToColorMap
  );

  return (
    <Grid container data-testid="allocations">
      <Grid item sm={5} xs={12}>
        <AllocationChart
          data={assetAllocationChartData[0]}
          groupedData={assetAllocationChartData[1]}
        />
      </Grid>
      <Grid item sm={7} xs={12}>
        <Box>
          {assetAllocation.length === 0 ? (
            <Box mt={1.5}>
              <Typography color="textSecondary" variant="body2">
                No asset allocation
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="h5" display="inline">
                {chartTitle}
              </Typography>
              <Box data-testid="allocations-breakdown">
                {assetAllocationChartData[1].map(
                  ({ name: assetName, y, color }, idx) =>
                    y > 0.0005 && (
                      <Box key={assetName} mt={idx === 0 ? 2 : 1.5}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start">
                          <ChartColorSquare color={color} />

                          <Truncation>
                            {(assetName === 'MORE' && 'More...') ||
                              (assetName === 'UNKNOWN' && 'Other assets') ||
                              ASSET_CLASS_TO_LABEL_MAP.get(assetName as Feature)}
                          </Truncation>
                          <Box color="grey.600" ml="auto" mr={0}>
                            {formatPercent(y, 1)}
                          </Box>
                        </Box>
                      </Box>
                    )
                )}
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export function SectorAllocationChart({ sectorAllocation }: { sectorAllocation: Allocation }) {
  const assetToColorMap = useAssetToColorMap();
  const categoricalChartColors = useCategoricalDataVisColors();

  const sectorAllocationChartData = processSectorAllocationData(sectorAllocation, assetToColorMap);

  const remainingSectorsPopover = () => (
    <ul style={{ listStyle: 'none', padding: 8, margin: 0, maxHeight: 260 }}>
      {sectorAllocationChartData[0].map((data: { name: string; y: number }) => (
        <RemainingSectorsPopoverListItem
          assetName={data.name as Sector}
          y={data.y}
          key={data.name}
        />
      ))}
    </ul>
  );

  return (
    <Grid container data-testid="allocations">
      <Grid item sm={5} xs={12}>
        <AllocationChart data={sectorAllocationChartData[1]} />
      </Grid>
      <Grid item sm={7} xs={12}>
        <Box display="flex">
          <Typography variant="h5" display="inline">
            Individual equities sector breakdown
          </Typography>
          <Tooltip title="ETFs and unclassifiable equities are not included in this breakdown.">
            <Typography color="textSecondary" display="inline">
              <Box ml={0.5} style={{ verticalAlign: 'middle' }}>
                <InformationCircleIcon width={16} height={16} />
              </Box>
            </Typography>
          </Tooltip>
        </Box>
        <Box data-testid="allocations-breakdown">
          {sectorAllocation.length === 0 ? (
            <Box mt={1.5}>
              <Typography color="textSecondary" variant="body2">
                Only sectors for equities are displayed.
              </Typography>
              <Box mt={2}>
                <Typography color="textSecondary" variant="body2">
                  Sectors for ETFs are not available.
                </Typography>
              </Box>
            </Box>
          ) : (
            sectorAllocationChartData[1].map(
              ({ name: sectorName, y, color }, idx) =>
                y > 0.05 && (
                  <Box key={sectorName} mt={idx === 0 ? 2 : 1.5}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                      {sectorName === 'MORE' ? (
                        <PopoverTrigger overlay={remainingSectorsPopover} triggerAction="hover">
                          <div>
                            <ChartColorSquare display="inline-block" color={color} />
                            More...
                          </div>
                        </PopoverTrigger>
                      ) : (
                        <>
                          <ChartColorSquare
                            color={categoricalChartColors[idx % categoricalChartColors.length]}
                          />
                          <Truncation>{SECTOR_TO_LABEL_MAP.get(sectorName as Sector)}</Truncation>
                        </>
                      )}
                      <Box color="grey.600" ml="auto" mr={0}>
                        {formatPercent(y, 1)}
                      </Box>
                    </Box>
                  </Box>
                )
            )
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

interface AllocationCardProps {
  allocationType?: 'CURRENT' | 'TARGET';
  assetAllocation: AssetClassAllocation;
  pctOfManagedValue?:
    | AdjustedConstructedPortfolioMetrics['pct_of_managed_value']
    | null
    | undefined;
  lockedUnrecognizedFraction?: number | null | undefined;
  sectorAllocation: SectorAllocation;
  endAdornment?: React.ReactNode;
  expanded?: boolean;
  portfolioInsights?: PortfolioInsightsResponse;
  showInsights?: boolean;
  account?: Account;
}

const AllocationCard2 = ({
  allocationType = 'CURRENT',
  assetAllocation,
  pctOfManagedValue,
  sectorAllocation,
  endAdornment,
  lockedUnrecognizedFraction,
  expanded,
  portfolioInsights,
  showInsights = false,
  account,
}: AllocationCardProps) => {
  const [allocationToggle, setAllocationToggle] = useState<'assets' | 'sectors'>('assets');
  const [selectedAllocationOption, setSelectedAllocationOption] = useState<{
    label: string;
    value: string;
  } | null>(ALLOCATION_OPTIONS[0]);
  const onToggleChange = (event: React.MouseEvent<HTMLElement>, newValue: 'assets' | 'sectors') => {
    const tabName = allocationToggle === 'assets' ? 'Assets' : 'Sectors';
    amplitude().logEvent(`Action - Tap ${tabName}`, {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
    if (newValue !== null) {
      setAllocationToggle(newValue);
    }
  };

  const adjustedSectorAllocationData = useMemo(
    () => adjustSectorAllocationByOther(sectorAllocation),
    [sectorAllocation]
  );

  const [allocationsOfViseManagedValue, allocationsOfTotalPortfolio] = [
    adjustAllocationByPctOfManagedValue(assetAllocation, lockedUnrecognizedFraction, true),
    adjustAllocationByPctOfManagedValue(assetAllocation, lockedUnrecognizedFraction, false),
  ];

  const showViseManagedControls =
    pctOfManagedValue != null &&
    pctOfManagedValue < 1 &&
    // Since we zero out cash allocation, a cash-only portfolio will not have any allocations
    allocationsOfViseManagedValue.length > 0;

  const adjustedAssetAllocationData =
    showViseManagedControls && selectedAllocationOption?.value === 'vise-managed'
      ? allocationsOfViseManagedValue
      : allocationsOfTotalPortfolio;

  const toggleButtonGroup =
    showInsights && expanded ? (
      <ToggleButtonGroup
        onChange={(e, value) => {
          setSelectedAllocationOption(
            ALLOCATION_OPTIONS.find((opt) => opt.value === value) || null
          );
        }}
        value={selectedAllocationOption?.value}
        size="large"
      >
        <ToggleButton value="total-portfolio">Total</ToggleButton>
        <ToggleButton value="vise-managed">Vise-managed</ToggleButton>
      </ToggleButtonGroup>
    ) : (
      <ToggleButtonGroup onChange={onToggleChange} value={allocationToggle} size="large">
        <ToggleButton value="assets">Assets</ToggleButton>
        <ToggleButton value="sectors">Sectors</ToggleButton>
      </ToggleButtonGroup>
    );

  return (
    <Card style={{ overflow: 'visible', height: '100%' }}>
      <CardHeader pl={3} pr={2} py={2.5} display="flex">
        <Box alignItems="center" display="flex" justifyContent="space-between" flex={1}>
          <Typography variant="h4">
            {allocationType === 'TARGET' ? 'Target allocation' : 'Allocation'}
          </Typography>
          {expanded && !showInsights ? undefined : toggleButtonGroup}
        </Box>
      </CardHeader>

      {expanded ? (
        <Box display="flex" flexDirection="row" py={3} pr={5.5}>
          <AssetAllocationChart assetAllocation={adjustedAssetAllocationData} />
          <Box ml={3.5}>
            <Divider orientation="vertical" />
          </Box>
          <SectorAllocationChart sectorAllocation={adjustedSectorAllocationData} />
        </Box>
      ) : (
        <CardContent>
          {allocationToggle === 'assets' ? (
            <AssetAllocationChart assetAllocation={adjustedAssetAllocationData} />
          ) : (
            <SectorAllocationChart sectorAllocation={adjustedSectorAllocationData} />
          )}
        </CardContent>
      )}
      {(showViseManagedControls || endAdornment) && !expanded && showInsights && (
        <CardFooter
          marginTop="auto"
          display="flex"
          flexDirection="row"
          justifyContent={showViseManagedControls ? 'space-between' : 'flex-end'}
        >
          {showViseManagedControls && (
            <Box width="220px">
              <Select
                onChange={(value: ValueType<AllocationOption>) => {
                  setSelectedAllocationOption(value as AllocationOption);
                }}
                options={ALLOCATION_OPTIONS}
                value={selectedAllocationOption}
              />
            </Box>
          )}
          {endAdornment}
        </CardFooter>
      )}
      {showInsights && portfolioInsights && account && (
        <InsightsCardFooter
          title={`About ${account.accountNameShort}'s allocation`}
          body={`${portfolioInsights.assetAllocation.insightText} ${portfolioInsights.sectors.insightText}`}
          link={
            <Box mt={2.5} mb={1}>
              <ViewDetailsLink
                account={account}
                openInsights={['assetAllocation', 'sectorAllocation']}
              />
            </Box>
          }
        />
      )}
    </Card>
  );
};

export default AllocationCard2;
