import useSWR from 'swr';
import { getPortfolioOverview } from '~/api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function usePortfolioOverview(accountId: string | null | undefined) {
  return useSWR(
    accountId == null ? null : generateSwrKey(`/xray/overview/${accountId}`),
    accountId == null ? undefined : () => getPortfolioOverview(accountId)
  );
}
