import { Box, Card, Grid, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { keyBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { AssetClassKey, Feature, Sector } from 'vise-types/pce2_instrument';
import { Account } from 'vise-types/portfolio';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { RawGetCapitalGainsResponse } from '~/models/api';
import { ReactComponent as ExclamationCircleIcon } from '~/static/images/icons/exclamation-circle.svg';
import Banner from '~/synth/Banner';
import CardContent from '~/synth/CardContent';
import CardHeader from '~/synth/CardHeader';
import { DataTable, DataTableContainer } from '~/synth/DataTable';
import Metric from '~/synth/Metric';
import Skeleton from '~/synth/Skeleton';
import {
  ACCOUNTING_FORMATTER,
  formatCurrency,
  formatPercent,
  formatQuantity,
} from '~/utils/format';
import { getAssetClassFeaturesFromKey } from '~/utils/pce2Migration';
import amplitude from '../../utils/amplitude';
import LoadingTable from '../Households/Households/LoadingTable';
import { ASSET_CLASS_TO_LABEL_MAP } from '../PortfolioCreator2/Constants';
import { SECTOR_TO_LABEL_MAP } from './Constants';
import AllocationCard2 from './card/AllocationCard2';
import AllocationMap from './components/AllocationMap';
import HoldingsModal from './components/HoldingsModal';
import {
  AllocationBar,
  Locked,
  PCE2AssetNameBox,
  ViewPositionsButton,
} from './components/TableComponents';
import TaxImpactTable from './components/TaxImpactTable';
import Truncation from './components/Truncation';
import {
  AssetAllocationWithPositionsAndValue,
  PricedPortfolioHolding,
  addPositionsAndValueToAssetAllocation,
  adjustAllocationByPctOfManagedValue,
  assetClassChartWithTargets,
  getPCE2AssetClassTitleAndSubtitle,
  getPCE2ProposalHoldingsRowData,
  transformPCE2AssetClassAllocations,
  transformPCE2Buys,
  transformPCE2SectorAllocations,
  transformPCE2Sells,
} from './portfolioUtil';

function MetricSection({
  children,
  hasBorder,
}: {
  children: React.ReactNode;
  hasBorder?: boolean;
}) {
  return (
    <Box
      py={2.5}
      px={3}
      width="20%"
      border={hasBorder ? 1 : 0}
      borderLeft={0}
      borderTop={0}
      borderBottom={0}
      borderColor="grey.200"
    >
      {children}
    </Box>
  );
}

function NullFallback<ValueT, FallbackT, RenderT>({
  value,
  fallback = null,
  render,
}: {
  value: ValueT;
  fallback?: FallbackT | null | undefined;
  render: () => RenderT;
}) {
  if (value == null) {
    return <>{fallback}</>;
  }
  return <>{render()}</>;
}

interface ProposalInitialTradesProps {
  account: Account | null;
  intelligence: PortfolioIntelligenceFull;
  gains: RawGetCapitalGainsResponse | undefined;
  fromBulkMigration?: boolean;
}

export default function ProposalInitialTrades({
  account,
  intelligence,
  gains,
  fromBulkMigration = false,
}: ProposalInitialTradesProps) {
  useEffect(() => {
    amplitude().logEvent(
      `Impression - Proposal initail trades${fromBulkMigration && ' from bulk migration'}`,
      {
        category: fromBulkMigration
          ? EVENT_CATEGORIES.BULK_MIGRATION
          : EVENT_CATEGORIES.PROPOSAL_OVERVIEW,
      }
    );
  }, [fromBulkMigration]);

  if (intelligence.proposalType === 'light') {
    throw new Error(`Portfolio intelligence should be PCE2 and FULL. (id: ${intelligence.id})`);
  }

  const { data: featureFlags } = useFeatureFlags();

  const {
    constructionInfo: { smallAccount },
    constructionResponse: {
      proposedAccount,
      proposedBuys,
      proposedSells,
      metrics: {
        proposedPortfolioMetrics,
        proposedTradesMetrics,
        strategyMetrics: { targetPortfolio },
      },
    },
    constructionRequest: { doNotSellSymbolsList, taxOptions, doNotHoldCountriesList },
    pce2Instruments,
  } = intelligence;

  const pce2InstrumentsBySymbol = useMemo(
    () => keyBy(pce2Instruments, 'symbol'),
    [pce2Instruments]
  );

  const buysTotal = proposedBuys.reduce(
    (prev, cur) => prev + cur.buyNotionalSharePrice * cur.shares,
    0
  );

  const sellsTotal = proposedSells.reduce(
    (prev, cur) => prev + cur.sellNotionalSharePrice * cur.shares,
    0
  );

  const theme = useTheme();

  const [holdingsModalOpen, setHoldingsModalOpen] = useState(false);
  const [holdingsModalTitle, setHoldingsModalTitle] = useState('');
  const [holdingsModalSubtitle, setHoldingsModalSubtitle] = useState<string | undefined>(undefined);
  const [holdingsModalData, setHoldingsModalData] = useState<PricedPortfolioHolding[]>([]);
  const [holdingsModalColumns, setHoldingsModalColumns] = useState<'asset' | 'sector' | undefined>(
    undefined
  );

  function openModal(
    title: string,
    data: PricedPortfolioHolding[],
    type?: 'asset' | 'sector',
    subtitle?: string
  ) {
    setHoldingsModalTitle(title);
    setHoldingsModalData(data);
    setHoldingsModalColumns(type);
    setHoldingsModalSubtitle(subtitle);
    amplitude().logEvent('OpenHoldingsModal', {
      category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
    });
    setHoldingsModalOpen(true);
  }

  const [tabValue, setTabValue] = useState<'allocation' | 'trades' | 'locked'>('allocation');

  const handleTabChange = (
    _event: React.ChangeEvent<{}>,
    newValue: 'allocation' | 'trades' | 'locked'
  ) => {
    setTabValue(newValue);
  };

  const lockedTickers: Set<string> = useMemo(
    () => new Set(doNotSellSymbolsList),
    [doNotSellSymbolsList]
  );

  const positionsGroupedByAssetClass = getPCE2ProposalHoldingsRowData(
    proposedAccount.positions,
    proposedPortfolioMetrics.totalValue,
    account?.custodianKey ?? null,
    pce2InstrumentsBySymbol,
    lockedTickers,
    (assetClass, securityType): Feature => {
      const features = getAssetClassFeaturesFromKey(assetClass);
      if (features.length > 0 && ASSET_CLASS_TO_LABEL_MAP.get(features[0])) {
        return features[0] as Feature;
      }

      if (securityType === 'CASH_OR_EQIV' || securityType === 'MF') {
        return 'CASH';
      }

      return 'UNKNOWN';
    }
  );

  const allHoldings = Object.values(positionsGroupedByAssetClass).flat();
  const sorted = allHoldings.sort((a, b) =>
    a.value != null && b.value != null && a.value < b.value ? 1 : -1
  );
  const sortedHoldings: PricedPortfolioHolding[] = sorted.map((holding, idx) => {
    return { ...holding, ranking: idx + 1 };
  });

  const nonCashHoldings = sortedHoldings.filter((h) => h.assetClass !== 'CASH');

  const lockedHoldings = sortedHoldings.filter((pos) => pos.locked);

  const { assetAllocation: unadjustedAssetAllocation, sectorAllocation } = {
    assetAllocation: transformPCE2AssetClassAllocations(
      proposedPortfolioMetrics.assetClassAllocations
    ),
    sectorAllocation: transformPCE2SectorAllocations(proposedPortfolioMetrics.sectorAllocations),
  };

  const assetAllocation = adjustAllocationByPctOfManagedValue(
    unadjustedAssetAllocation,
    proposedPortfolioMetrics.lockedUnrecognizedFraction * 100,
    false
  );

  const groupedAssetAllocation = useMemo(
    () =>
      assetClassChartWithTargets(
        addPositionsAndValueToAssetAllocation(positionsGroupedByAssetClass, assetAllocation),
        transformPCE2AssetClassAllocations(targetPortfolio.assetClassAllocations)
      ),
    [assetAllocation, positionsGroupedByAssetClass, targetPortfolio.assetClassAllocations]
  );

  const topAssetAllocation = Math.max(...assetAllocation.map((asset) => asset.y));

  const groupedTrades = {
    Buys: transformPCE2Buys(proposedBuys, pce2InstrumentsBySymbol),
    Sells: transformPCE2Sells(proposedSells, pce2InstrumentsBySymbol),
  };

  const wholePortfolioColumns = useMemo(
    () =>
      [
        {
          Header: 'Sub-asset class',
          accessor: 'name',
          width: '18%',
          Cell: (props: { value: AssetClassKey }) => {
            const assetDisplayName = getPCE2AssetClassTitleAndSubtitle(props.value, smallAccount);
            return (
              <PCE2AssetNameBox
                title={assetDisplayName.title}
                subtitle={assetDisplayName.subtitle}
              />
            );
          },
        },
        {
          Header: 'Target allocation',
          accessor: 'target',
          width: '12%',
          Cell: (props: { value: number }) => (
            <div style={{ textAlign: 'right' }}>
              <NullFallback
                value={props.value}
                render={() => (
                  <Typography variant="body2" color="textSecondary">
                    {props.value < 0.0005 ? '< 0.1%' : formatPercent(props.value, 1)}
                  </Typography>
                )}
                fallback={
                  <Typography variant="body2" color="textSecondary">
                    -
                  </Typography>
                }
              />
            </div>
          ),
          align: 'right',
        },
        {
          Header: 'Initial allocation',
          accessor: 'y',
          width: '12%',
          Cell: (props: { value: number }) => (
            <div style={{ textAlign: 'right' }}>
              <NullFallback
                value={props.value}
                render={() => (props.value < 0.0005 ? '< 0.1%' : formatPercent(props.value, 1))}
                fallback="-"
              />
            </div>
          ),
          align: 'right',
        },
        {
          accessor: 'color',
          Cell: (props: { row: { original: { y: number } } }) => (
            <NullFallback
              value={props.row.original.y}
              render={() => (
                <AllocationBar
                  allocation={props.row.original.y}
                  topAssetAllocation={topAssetAllocation}
                />
              )}
            />
          ),
        },
        {
          Header: 'Value',
          accessor: 'value',
          Cell: (props) =>
            props.value && props.value > 0 ? (
              <div style={{ textAlign: 'right' }}>{formatCurrency(props.value)}</div>
            ) : null,
          align: 'right',
        },
        {
          Header: '# of positions',
          accessor: 'positions',
          width: '20%',
          Cell: (props: {
            value: PricedPortfolioHolding[];
            row: { original: { name: string } };
          }) => {
            const assetName = props.row.original.name;
            const assetDisplayName = getPCE2AssetClassTitleAndSubtitle(
              assetName as AssetClassKey,
              smallAccount
            );
            return (
              <NullFallback
                value={props.value}
                render={() => (
                  <ViewPositionsButton
                    count={props.value.length}
                    onClick={() => {
                      openModal(
                        assetDisplayName.title,
                        props.value,
                        assetName === 'CASH' || assetName === 'Unclassified UNKNOWN'
                          ? undefined
                          : 'sector',
                        assetDisplayName.subtitle
                      );
                    }}
                  />
                )}
              />
            );
          },
          align: 'right',
        },
      ] as Column<AssetAllocationWithPositionsAndValue | { name: string; target: number }>[],
    [smallAccount, topAssetAllocation]
  );

  const lockedColumns: GridColDef[] = [
    {
      headerName: '',
      field: 'locked',
      renderCell: ({ value }) => <Locked locked={value} />,
      flex: 0.2,
    },
    { headerName: 'Ticker', field: 'ticker' },
    {
      headerName: 'Name',
      field: 'name',
      renderCell: (props) => <Truncation>{props.value}</Truncation>,
      flex: 1,
    },
    {
      headerName: 'Sector',
      field: 'sector',
      renderCell: (props) => SECTOR_TO_LABEL_MAP.get(props.value as Sector) ?? '-',
      flex: 1,
    },
    {
      headerName: 'Quantity',
      field: 'shares',
      renderCell: (props) => formatQuantity(props.value),
      flex: 0.7,
      align: 'right',
      headerAlign: 'right',
    },
    {
      headerName: 'Price',
      field: 'price',
      renderCell: (props) => formatCurrency(props.value),
      flex: 0.7,
      align: 'right',
      headerAlign: 'right',
    },
    {
      headerName: 'Total value',
      field: 'value',
      renderCell: (props) => formatCurrency(props.value),
      flex: 0.8,
      align: 'right',
      headerAlign: 'right',
    },
    {
      headerName: 'Total allocation',
      headerAlign: 'right',
      field: 'allocation',
      renderCell: (props) => formatPercent(props.value),
      flex: 0.8,
      align: 'right',
    },
  ];

  const tradesColumns: GridColDef[] = [
    { field: 'ticker', headerName: 'Ticker', flex: 0.3 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'shares', headerName: 'Quantity', flex: 0.5 },
    {
      field: 'price',
      headerName: 'Price',
      renderCell: ({ value }) => formatCurrency(value),
      flex: 0.8,
    },
    {
      field: 'value',
      headerName: 'Value',
      valueGetter: (params) => (params.row.action === 'Sell' ? 1 : -1) * params.value,
      renderCell: ({ value }) => formatCurrency(value, ACCOUNTING_FORMATTER),
      flex: 0.8,
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Box display="flex" justifyContent="start">
            <MetricSection hasBorder>
              <Metric
                label="Turnover"
                metric={formatPercent(proposedTradesMetrics.turnoverFraction, 0)}
              />
            </MetricSection>
            <MetricSection hasBorder>
              <Metric label="No. of positions" metric={nonCashHoldings.length} />
            </MetricSection>
            <MetricSection hasBorder>
              <Metric label="Buys" metric={formatCurrency(-buysTotal, ACCOUNTING_FORMATTER)} />
            </MetricSection>
            <MetricSection>
              <Metric label="Sells" metric={formatCurrency(sellsTotal, ACCOUNTING_FORMATTER)} />
            </MetricSection>
            <MetricSection hasBorder>
              <Metric
                label="Net cash proceeds"
                metric={formatCurrency(sellsTotal - buysTotal, ACCOUNTING_FORMATTER)}
              />
            </MetricSection>
          </Box>
        </Card>
      </Grid>
      {gains != null && taxOptions != null && account?.taxable && (
        <Grid item xs={12}>
          <TaxImpactTable
            gains={gains}
            taxOptions={taxOptions}
            taxMetrics={proposedTradesMetrics}
            variant="proposal"
          />
        </Grid>
      )}
      <Grid item container spacing={3}>
        <Grid item xs={featureFlags?.enable_map === 'on' ? 6 : 12}>
          <AllocationCard2
            assetAllocation={unadjustedAssetAllocation}
            sectorAllocation={sectorAllocation}
            pctOfManagedValue={proposedPortfolioMetrics.managedFraction * 100}
            lockedUnrecognizedFraction={proposedPortfolioMetrics.lockedUnrecognizedFraction * 100}
            expanded={featureFlags?.enable_map !== 'on'}
          />
        </Grid>
        {featureFlags?.enable_map === 'on' ? (
          <Grid item xs={6}>
            <AllocationMap
              assetAllocation={unadjustedAssetAllocation}
              positions={allHoldings}
              restrictedCountries={doNotHoldCountriesList}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid item>
        <Banner
          size="small"
          bgColor="warning.100"
          borderColor="warning.200"
          message={
            <div>
              <Box display="flex" alignItems="center">
                <ExclamationCircleIcon color={theme.palette.warning[400]} fontSize={18} />
                <Box fontWeight={500} ml={1.5}>
                  Proposed trades can change when executed.
                </Box>
              </Box>
              <Box ml={3.75} mt={1}>
                Holdings and proposed trades are decided on the market conditions when the proposal
                is executed. The items below are illustrative of potential trades and can be
                expected to change when executed.
              </Box>
            </div>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <DataTableContainer>
          <Box
            border={1}
            borderTop={0}
            borderLeft={0}
            borderRight={0}
            borderColor="grey.200"
            px={3}
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Tabs value={tabValue} aria-label="Proposal tabs" onChange={handleTabChange}>
              <Tab
                value="allocation"
                label="Allocation breakdown"
                id="tab-allocation"
                aria-controls="allocations-tab"
              />
              <Tab
                value="trades"
                label="Proposed trades"
                id="tab-trades"
                aria-controls="trades-tab"
              />
              <Tab
                value="locked"
                label="Locked positions"
                id="tab-locked"
                aria-controls="locked-tab"
              />
            </Tabs>
            <ViewPositionsButton
              count={nonCashHoldings.length}
              onClick={() => {
                openModal('All holdings', nonCashHoldings, 'asset');
              }}
            />
          </Box>
          <div
            role="tabpanel"
            hidden={tabValue !== 'allocation'}
            id="allocations-tab"
            aria-labelledby="tab-allocation"
          >
            {Object.entries(groupedAssetAllocation).map(([assetClass, holdings]) => {
              return holdings.length ? (
                <DataTable
                  columns={wholePortfolioColumns}
                  data={holdings}
                  subHeader={ASSET_CLASS_TO_LABEL_MAP.get(assetClass as Feature)}
                  key={assetClass}
                  rowSize="large"
                />
              ) : null;
            })}
          </div>
          <div
            role="tabpanel"
            hidden={tabValue !== 'trades'}
            id="trades-tab"
            aria-labelledby="tab-trades"
          >
            {Object.entries(groupedTrades).map(([action, trades]) => {
              return trades.length ? (
                <Box height={400}>
                  <DataGridPro
                    experimentalFeatures={{ columnGrouping: true }}
                    columns={tradesColumns}
                    rows={trades}
                    getRowId={(row) => row.ticker}
                    columnGroupingModel={[
                      {
                        groupId: action,
                        headerName: action,
                        children: [
                          { field: 'ticker' },
                          { field: 'name' },
                          { field: 'shares' },
                          { field: 'price' },
                          { field: 'value' },
                        ],
                      },
                    ]}
                  />
                </Box>
              ) : null;
            })}
          </div>
          <div
            role="tabpanel"
            hidden={tabValue !== 'locked'}
            id="locked-tab"
            aria-labelledby="tab-locked"
          >
            <Box height={380}>
              <DataGridPro
                rows={lockedHoldings}
                columns={lockedColumns}
                getRowId={(row) => row.ticker}
              />
            </Box>
            {!lockedHoldings.length && (
              <Box display="flex" flexDirection="column" alignItems="center" mt={5} mb={8}>
                <Box mb={1.5}>
                  <Typography variant="h4">
                    There are no positions locked in this portfolio
                  </Typography>
                </Box>
                <Typography variant="body1" color="textSecondary">
                  To lock a position, edit the portfolio and navigate to Transition Settings.
                </Typography>
              </Box>
            )}
          </div>
        </DataTableContainer>
      </Grid>
      <HoldingsModal
        title={holdingsModalTitle}
        subtitle={holdingsModalSubtitle}
        type={holdingsModalColumns}
        data={holdingsModalData}
        onClose={() => setHoldingsModalOpen(false)}
        open={holdingsModalOpen}
        smallAccount={smallAccount}
      />
    </Grid>
  );
}

export const InitialTradesLoadingState = () => {
  return (
    <>
      <Box mb={2.5}>
        <Card>
          <Box display="flex">
            {[0, 1, 2, 3, 4].map((n) => {
              return (
                <CardContent key={n}>
                  <Skeleton width="10em" height="5em" />
                </CardContent>
              );
            })}
          </Box>
        </Card>
      </Box>
      <Card>
        <CardHeader>
          <Skeleton variant="text" width="15%" />
        </CardHeader>
        <LoadingTable pageSize={5} />
      </Card>

      <Card style={{ height: '20em', marginBottom: '20px', marginTop: '20px' }}>
        <CardHeader>
          <Skeleton variant="text" width="15%" />
        </CardHeader>
        <Box p={4} display="flex" alignContent="center">
          <Skeleton width="100%" height="12em" />
          <Box width={40} />
          <Skeleton width="100%" height="12em" />
        </Box>
      </Card>

      <Card>
        <CardContent>
          <Skeleton width="100%" height="50em" />
        </CardContent>
      </Card>
    </>
  );
};
