import { Typography, Box, Button, Divider } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import moment from 'moment-timezone';
import React from 'react';
import { Account } from 'vise-types/portfolio';
import CardHeader from '~/synth/CardHeader';
import { maskAccountNumberIfExists } from '~/utils/format';
import { ReactComponent as CopyIcon } from '~/static/images/icons/copy.svg';
import { ReactComponent as CheckIcon } from '~/static/images/icons/check.svg';

export default function EmailCardHeader({
  title,
  onClickCopy,
  copyDisabled,
  account,
}: {
  title: string;
  onClickCopy: () => Promise<boolean>;
  copyDisabled: boolean;
  account?: Account;
}) {
  const [copySuccess, setCopySuccess] = React.useState(false);
  const today = moment().tz('America/New_York');
  return (
    <CardHeader height="90px">
      <Box display="flex" alignItems="center" height="100%">
        <Box display="flex" flexDirection="row" alignItems="center" gap={3} height="100%">
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="h2">{title}</Typography>

            <Typography
              variant="body2"
              color={tokens.palette.neutralCool[600]}
              fontWeight={500}
              fontSize="0.75rem"
            >
              <Box display="flex" alignItems="center" gap="4px">
                <Box component="span" mr={0.5} color={tokens.palette.neutralCool[900]}>
                  As of:
                </Box>
                {today.format('MM/DD/YYYY')}
                <Divider orientation="vertical" flexItem />
                {today.format('hh:mm a')}
              </Box>
            </Typography>
          </Box>

          {account && <Divider orientation="vertical" />}
          {account && (
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="h2">{account.accountName}</Typography>
              <Typography variant="body1" fontSize="0.75rem" fontWeight={500}>
                <Box display="flex" alignItems="center">
                  <Box component="span" color={tokens.palette.neutralCool[900]}>
                    {' '}
                    Account number:
                  </Box>
                  <Box component="span" ml={0.5} color={tokens.palette.neutralCool[600]}>
                    {maskAccountNumberIfExists(account.accountNumber)}
                  </Box>
                </Box>
              </Typography>
            </Box>
          )}
        </Box>
        <Box flex={1} />
        <Button
          variant="outlined"
          color="secondary"
          onClick={async () => {
            const result = await onClickCopy();
            if (result) {
              setCopySuccess(true);
              setTimeout(() => {
                setCopySuccess(false);
              }, 3000);
            }
          }}
          sx={{ borderRadius: '4px' }}
          disabled={copyDisabled}
        >
          <Box display="flex" gap={1} alignItems="center">
            {copySuccess ? <CheckIcon /> : <CopyIcon />}
            {copySuccess ? 'Copied' : 'Copy'}
          </Box>
        </Button>
      </Box>
    </CardHeader>
  );
}
