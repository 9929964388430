import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import { CustodianName } from '~/constants';
import { ReactComponent as ArrowLeft } from '~/static/images/icons/arrow-left.svg';
import { maskAccountNumber } from '~/utils/format';
import useAccount from '~/hooks/useAccount';
import NotFound from '../NotFound/NotFound';

export default function AccountProposals() {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'accountId' does not exist on type '{}'.
  const { accountId } = useParams();
  const { data, error } = useAccount(accountId);

  if (error?.response.status === 404) {
    return <NotFound />;
  }

  if (error != null) {
    return (
      <Box mt={3}>
        <Container>Error loading account with ID {accountId}</Container>
      </Box>
    );
  }

  if (data == null) {
    return (
      <Box mt={3}>
        <Container>Loading…</Container>
      </Box>
    );
  }

  const { data: account } = data;
  const clientName = `${account.firstName} ${account.lastName}`;

  return (
    <Box mt={3}>
      <Container>
        <Box mb={4}>
          <Button component={Link} size="small" startIcon={<ArrowLeft />} to="/secure/households">
            Back to link accounts
          </Button>
        </Box>
        <Box mb={1.5}>
          <Typography variant="h1">Link a proposal to {clientName}&apos;s account</Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body1">
            Linked proposals will need to be refreshed with the latest market data before trading
            can begin.
          </Typography>
        </Box>
      </Container>
      <Box border={1} borderLeft={0} borderRight={0} borderColor="grey.200" py={2}>
        <Container>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <Box>
                <Typography color="textSecondary" variant="h5">
                  CLIENT NAME
                </Typography>
              </Box>
              <Box>
                <Typography variant="h3">{clientName}</Typography>
              </Box>
            </Grid>
            <Grid item sm xs={12}>
              <Box>
                <Typography color="textSecondary" variant="h5">
                  CUSTODIAN
                </Typography>
              </Box>
              <Box>
                <Typography variant="h3">
                  {CustodianName[account.custodianKey] ?? account.custodianKey}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm xs={12}>
              <Box>
                <Typography color="textSecondary" variant="h5">
                  ACCOUNT NUMBER
                </Typography>
              </Box>
              <Box>
                <Typography variant="h3">{maskAccountNumber(account.accountNumber)}</Typography>
              </Box>
            </Grid>
            <Grid item sm xs={12}>
              <Box>
                <Typography color="textSecondary" variant="h5">
                  ACCOUNT NAME
                </Typography>
              </Box>
              <Box>
                <Typography variant="h3">{account.accountName}</Typography>
              </Box>
            </Grid>
            <Grid item sm xs={12}>
              <Box>
                <Typography color="textSecondary" variant="h5">
                  PROPOSALS
                </Typography>
              </Box>
              <Box>
                <Typography variant="h3">???</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
