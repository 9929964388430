import useSWR from 'swr';
import { getProposalStatus } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getProposalStatusSwrKey = (proposalId: string) =>
  generateSwrKey(`/portfolio/intelligence/${proposalId}/status`);

export default function useProposalStatus(proposalId: string | undefined | null) {
  const useSWRRes = useSWR(
    proposalId ? getProposalStatusSwrKey(proposalId) : null,
    proposalId == null ? undefined : () => getProposalStatus(proposalId)
  );
  return useSWRRes;
}
