import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router';

import SomethingWentWrong from '~/components/SomethingWentWrong';
import usePortfolioIntelligence from '~/hooks/usePortfolioIntelligence';
import { Loader } from '../../components';

export default function PortfolioPreview() {
  const { intelligenceId } = useParams<{ intelligenceId: string }>();

  const { data: intelligenceArrayData, error: intelligenceError } =
    usePortfolioIntelligence(intelligenceId);

  const intelligence = intelligenceArrayData?.[0];

  useEffect(() => {
    if (intelligenceArrayData && intelligenceArrayData[0] == null) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligenceId} failed to load: missing intelligence data.`
        ),
        {
          extra: {
            intelligenceId,
          },
        }
      );
    }
  }, [intelligenceArrayData, intelligenceId]);

  useEffect(() => {
    if (intelligenceError != null) {
      Sentry.captureException(
        new Error(
          `[Proposal.tsx] Intelligence ${intelligenceId} failed to load. ${intelligenceError}.`
        ),
        {
          extra: {
            intelligenceId,
          },
        }
      );
    }
  }, [intelligenceError, intelligenceId]);

  if (intelligence == null) {
    return <Loader />;
  }

  if (intelligence.pceVersion === 'pce2') {
    return <Redirect to={`/secure/proposal/${intelligence.id}`} />;
  }

  return (
    <SomethingWentWrong
      description="There is an issue with the inputs in the current proposal, please contact clientservice@vise.com for assistance."
      disableCta
      error={new Error('pce1 portfolio')}
    />
  );
}
