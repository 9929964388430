import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { insertXrayCashPosition } from '~/api/api';
import { InputDollar } from '~/components';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import usePositions from '~/hooks/usePositions';
import TextField from '~/synth/TextField';
import amplitude from '~/utils/amplitude';
import { ScreenProps } from '../Types';
import { XrayContext, XrayDispatchContext } from '../context/XrayContext';
import { ActionFooter, BackButton, ContentBox, ExplainerText } from './components';

export default function CashPositionScreen({ draftPortfolio, onBack, onContinue }: ScreenProps) {
  useEffect(() => {
    amplitude().logEvent('Impression - Cash position', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { cashAmount } = useContext(XrayContext);
  const xrayDispatch = useContext(XrayDispatchContext);

  const {
    constructionInfo: { existingPortfolio },
  } = draftPortfolio;

  const enqueueCoachmark = useEnqueueCoachmark();
  const [noCash, setNoCash] = useState(false);

  const { data: positionsData } = usePositions(
    existingPortfolio != null && existingPortfolio !== 'sample-portfolio'
      ? existingPortfolio.accountNumber
      : null,
    existingPortfolio != null && existingPortfolio !== 'sample-portfolio'
      ? existingPortfolio.custodianKey
      : null
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (
        existingPortfolio === 'sample-portfolio' ||
        existingPortfolio == null ||
        cashAmount == null
      )
        return;
      if (cashAmount === 0) {
        onContinue();
        return;
      }
      const response = await insertXrayCashPosition({
        cashAmount,
        accountId: existingPortfolio.id,
      });
      if (response.status === 200) {
        onContinue();
      }
    } catch (e) {
      // TBD
      enqueueCoachmark({ title: 'Could not add cash position.', severity: 'error' });
    }
    setIsSubmitting(false);
  };

  return (
    <ContentBox>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <Typography variant="h1">Enter a cash position</Typography>
        </Box>
        <ExplainerText mb={4}>
          Please indicate how much cash is present in this client&apos;s current portfolio.
        </ExplainerText>
        <Box>
          <TextField
            defaultValue={
              positionsData == null
                ? 0
                : positionsData.positions.find((p) => p.symbolOrCusip === ':CASH')
            }
            InputProps={{ inputComponent: InputDollar }}
            label="Cash"
            placeholder="ex. $10,000.00"
            fullWidth
            disabled={noCash}
            value={cashAmount}
            onChange={(event) => {
              const value = Number.parseFloat(event.target.value);
              if (!Number.isNaN(value))
                xrayDispatch({ type: 'SET_XRAY_CASH_AMOUNT', cashAmount: value });
            }}
          />
          <Box mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={noCash}
                  onChange={(event) => {
                    amplitude().logEvent(
                      `${event.target.checked ? 'Check' : 'Uncheck'} no cash position checkbox`,
                      {
                        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                      }
                    );
                    setNoCash(event.target.checked);
                    if (event.target.checked) {
                      xrayDispatch({ type: 'SET_XRAY_CASH_AMOUNT', cashAmount: 0 });
                    }
                  }}
                />
              }
              label="This portfolio does not contain any cash."
            />
          </Box>
        </Box>

        <ActionFooter justifyContent="space-between">
          <BackButton onClick={() => onBack()} />
          <Box display="flex">
            <Button
              color="primary"
              disabled={
                existingPortfolio === 'sample-portfolio' ||
                existingPortfolio == null ||
                cashAmount == null ||
                isSubmitting
              }
              type="submit"
              variant="contained"
              startIcon={isSubmitting ? <CircularProgress size="1em" color="inherit" /> : undefined}
            >
              Continue
            </Button>
          </Box>
        </ActionFooter>
      </form>
    </ContentBox>
  );
}
