import { Box, Container, useTheme } from '@mui/material';
import React from 'react';
import { Account } from 'vise-types/portfolio';
import { ReactComponent as WarningIcon } from '~/static/images/icons/exclamation.svg';
import Banner from '~/synth/Banner';
import { timestampToLocaleString } from './portfolioUtil';

interface UntradeableErrorMessageProps {
  account: Account;
  lastUpdated?: string;
  distribution?: boolean;
}

const UntradeableErrorMessage = ({
  account,
  lastUpdated,
  distribution,
}: UntradeableErrorMessageProps) => {
  const theme = useTheme();
  let message: React.ReactNode;
  if (account.status === 'INACTIVE') {
    if (account.statusReason === 'missing_tax_info') {
      message = (
        <Box>
          <Box fontWeight={500}>
            <div>There is a problem with this account and it cannot be traded.</div>
            {distribution ? (
              <div>
                Recurring distributions can still be scheduled, but trades to generate cash cannot
                be made until the account issue is resolved.
              </div>
            ) : null}
          </Box>
          <Box mt={1}>
            Please reach out to clientservice@vise.com if you have any questions.{' '}
            {lastUpdated && <>&mdash; {timestampToLocaleString(lastUpdated)}</>}
          </Box>
        </Box>
      );
    } else if (account.statusReason === 'account_removal_event') {
      message = (
        <Box>
          <Box fontWeight={500}>
            <div>The account has a removal event and it cannot be traded.</div>
            {distribution ? (
              <div>
                Recurring distributions can still be scheduled, but trades to generate cash cannot
                be made until the account issue is resolved.
              </div>
            ) : null}
          </Box>
          <Box mt={1}>
            Please reach out to clientservice@vise.com if you have any questions.{' '}
            {lastUpdated && <>&mdash; {timestampToLocaleString(lastUpdated)}</>}
          </Box>
        </Box>
      );
    } else {
      message = (
        <Box>
          <Box fontWeight={500}>
            <div>There is a problem with this account and it cannot be traded.</div>
            {distribution ? (
              <div>
                Recurring distributions can still be scheduled, but trades to generate cash cannot
                be made until the account issue is resolved.
              </div>
            ) : null}
          </Box>
          <Box mt={1}>
            Please reach out to clientservice@vise.com if you have any questions.{' '}
            {lastUpdated && <>&mdash; {timestampToLocaleString(lastUpdated)}</>}
          </Box>
        </Box>
      );
    }
  } else {
    return null;
  }

  return (
    <Banner
      bgColor="error.100"
      borderColor="error.200"
      message={
        <Container>
          <Box display="flex" flexDirection="row">
            <Box mr={1}>
              <WarningIcon fontSize="1.2em" color={theme.palette.error[500]} />
            </Box>
            {message}
          </Box>
        </Container>
      }
      size="small"
      fullWidth
    />
  );
};

export default UntradeableErrorMessage;
