import {
  Box,
  BoxProps,
  Button,
  Link as MuiLink,
  Tabs as MuiTabs,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { keyBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import PageContentContainer from '~/components/PageContentContainer';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAllAccountsWithPIAndHouseholdInfo from '~/hooks/useAllAccountsWithHouseholdInfo';
import useAllProposals from '~/hooks/useAllProposals';
import useClients from '~/hooks/useClients';
import useHouseholdsWithSummary from '~/hooks/useHouseholdsWithSummary';
import { ProposalSummary } from '~/models/household';
import SentryRoute from '~/routes/SentryRoute';
import { ReactComponent as SparkleIcon } from '~/static/images/icons/sparkle.svg';
import { DataTableContainer } from '~/synth/DataTable';
import { AddIcon } from '~/synth/Icons';
import Skeleton from '~/synth/Skeleton';
import { TextHighlightTag } from '~/synth/Tag';
import UnderlineTooltipIndicator from '~/synth/UnderlineTooltipIndicator';
import amplitude from '~/utils/amplitude';
import { formatCurrency } from '~/utils/format';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import AddHouseholdModalContainer from '../AddHouseholdModalContainer';
import ErrorFallback from '../ErrorFallback';
import { getAccountKey, reduceProposalsToSummaries } from '../HouseholdOverview';
import HouseholdsTable from './HouseholdsTable';
import PortfoliosTable, { AccountTableData } from './PortfoliosTable';
import UntransitionedAccountsTable from './UntransitionedAccountsTable';

const Tabs = styled(MuiTabs)`
  .MuiTabs-flexContainer {
    /* Necessary for the labels to appear centered within the surrounding box
     * component */
    height: 100%;
  }
`;

function HouseholdMetric({
  name,
  metric,
  tooltip,
  ...restProps
}: {
  name: string;
  tooltip?: string;
  metric: string | number | React.ReactElement | null | undefined;
} & BoxProps) {
  return (
    <Box {...restProps}>
      <Box display="flex" alignItems="center">
        <Tooltip title={tooltip ?? ''}>
          <UnderlineTooltipIndicator color="grey.500" variant="h5">
            {name}
          </UnderlineTooltipIndicator>
        </Tooltip>
      </Box>
      <Box mt={1}>
        {metric == null ? (
          <Skeleton width="120px" />
        ) : (
          <Typography variant="h3" component="span">
            {metric}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default function Households() {
  const routeMatch = useRouteMatch<{ tabName: 'households' | 'portfolios' | 'untransitioned' }>([
    '/secure/households/:tabName',
  ]);
  const tabName = routeMatch?.params.tabName;
  const { data: featureFlags } = useFeatureFlags();
  const isModelDelivery = featureFlags?.is_enterprise_user === 'on';
  const [isAddHouseholdModalOpen, setIsAddHouseholdModalOpen] = useState(false);
  const {
    data: households,
    isLoading: householdsIsLoading,
    error: householdsError,
  } = useHouseholdsWithSummary(true /* excludeMarketData */);

  const { data: householdsWithData, isLoading: householdsWithDataIsLoading } =
    useHouseholdsWithSummary(false /* excludeMarketData */);

  const { data: untransitionedAccounts, error: accountsNoSummaryError } =
    useAllAccountsWithPIAndHouseholdInfo({
      includeSummaries: false,
      transitioned: false,
      engineType: isModelDelivery ? 'MODEL_DELIVERY' : undefined,
    });
  const { data: transitionedAccounts, error: transitionedAccountsError } =
    useAllAccountsWithPIAndHouseholdInfo({
      includeSummaries: false,
      transitioned: true,
      engineType: isModelDelivery ? 'MODEL_DELIVERY' : undefined,
    });

  const aum = transitionedAccounts?.data.reduce((sum, acct) => sum + (acct.cachedAum || 0), 0);

  const { data: clients, error: clientsError } = useClients();
  const { data: proposalData, error: proposalsError } = useAllProposals();

  const proposals: { [accountKey: string]: ProposalSummary[] } | undefined =
    reduceProposalsToSummaries(proposalData);

  const untransitionedAccountsData: AccountTableData[] | undefined = useMemo(() => {
    if (untransitionedAccounts?.data == null || clients == null) return undefined;
    const clientsById = keyBy(clients, 'id');
    return untransitionedAccounts.data.map((account) => ({
      ...account,
      viseClient: account.viseClientId == null ? undefined : clientsById[account.viseClientId],
      proposals: proposals?.[getAccountKey(account.custodianKey, account.accountNumber)] ?? [],
    }));
  }, [untransitionedAccounts?.data, clients, proposals]);

  const history = useHistory();

  const openAddHouseholdModal = () => {
    amplitude().logEvent('AddHouseholdOpen', {
      category: EVENT_CATEGORIES.HOUSEHOLDS,
    });
    setIsAddHouseholdModalOpen(true);
  };

  useEffect(() => {
    if (accountsNoSummaryError != null || transitionedAccountsError != null) {
      Sentry.captureException(new Error('[Household2.tsx] Accounts failed to load'), {});
    }
  }, [accountsNoSummaryError, transitionedAccountsError]);

  useEffect(() => {
    if (clientsError != null) {
      Sentry.captureException(new Error('[Household2.tsx] Clients failed to load'), {});
    }
  }, [clientsError]);

  useEffect(() => {
    if (proposalsError != null) {
      Sentry.captureException(new Error('[Household2.tsx] Proposals failed to load'), {});
    }
  }, [proposalsError]);

  if (householdsError) {
    return <ErrorFallback error={householdsError} />;
  }

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Helmet>
        <title>Households</title>
      </Helmet>
      {isAddHouseholdModalOpen && (
        <AddHouseholdModalContainer
          onHouseholdCreated={(householdId: string) => {
            history.push(`/secure/households/${householdId}/overview`);
          }}
          open
          onClose={() => setIsAddHouseholdModalOpen(false)}
        />
      )}
      <Box width="100%" px={3} py={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          maxWidth={1280}
          m="auto"
        >
          <Box display="flex">
            <HouseholdMetric
              name="Unexecuted Accounts"
              tooltip="All accounts ready to be executed."
              metric={untransitionedAccounts?.data.length}
            />
            <HouseholdMetric
              name="Managed Accounts"
              tooltip="All accounts with a proposal executed on Vise."
              metric={transitionedAccounts?.data.length}
              px={4}
            />
            <HouseholdMetric
              name="AUM"
              tooltip="Total AUM for Vise Portfolios."
              metric={aum == null ? null : formatCurrency(aum)}
            />
          </Box>
        </Box>
      </Box>
      <PageContentContainer centerContent>
        <DataTableContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            borderBottom={1}
            px={3}
            borderColor="grey.200"
            alignItems="baseline"
          >
            <Tabs value={tabName || 'households'}>
              <Tab
                label="Households"
                value="households"
                data-testid="tab-households"
                to="/secure/households/households"
                component={Link}
              />
              <Tab
                label="Portfolios"
                value="portfolios"
                data-testid="tab-portfolios"
                to="/secure/households/portfolios"
                component={Link}
              />
              <Tab
                to="/secure/households/untransitioned"
                component={Link}
                style={{ maxWidth: 'unset' }}
                label={
                  <Box display="flex" alignItems="center">
                    Unexecuted Accounts
                    <>
                      {untransitionedAccountsData != null ? (
                        <Box display="flex" alignItems="center" ml={0.5}>
                          ({untransitionedAccountsData.length})
                          {/* TODO: Revisit this when we have a more comprehensive solution */}
                          {untransitionedAccountsData.length === 1 && (
                            <Box ml={0.5}>
                              <TextHighlightTag severity="alert">
                                <SparkleIcon /> <Box ml={0.5}>New account added</Box>
                              </TextHighlightTag>
                            </Box>
                          )}
                        </Box>
                      ) : null}
                    </>
                  </Box>
                }
                value="untransitioned"
              />
            </Tabs>
            <Button
              color="primary"
              startIcon={<AddIcon style={{ fontSize: '12px' }} display="inline-flex" />}
              onClick={openAddHouseholdModal}
              variant="contained"
              size="small"
            >
              <Typography variant="h5">Add household</Typography>
            </Button>
          </Box>
          <Switch>
            <SentryRoute path="/secure/households/households" exact>
              <HouseholdsTable
                households={householdsWithData || households}
                householdsIsLoading={householdsIsLoading}
                openAddHouseholdModal={() => setIsAddHouseholdModalOpen(true)}
                aumIsLoading={householdsWithDataIsLoading}
              />
            </SentryRoute>
            <SentryRoute path="/secure/households/portfolios" exact>
              <PortfoliosTable />
            </SentryRoute>
            <SentryRoute path="/secure/households/untransitioned" exact>
              <UntransitionedAccountsTable tableData={untransitionedAccountsData} />
            </SentryRoute>
            <SentryRoute path="/secure/households">
              <Redirect to="/secure/households/households" />
            </SentryRoute>
          </Switch>
        </DataTableContainer>
        <Box mt={3}>
          <Typography color="textSecondary" paragraph variant="caption">
            For more information, see our{' '}
            <MuiLink component={Link} to="/secure/disclosures">
              methodology, assumptions, and other disclosures here
            </MuiLink>
            .
          </Typography>
        </Box>
      </PageContentContainer>
    </Sentry.ErrorBoundary>
  );
}
