import React, { useRef } from 'react';
import { CheckboxProps } from '@mui/material';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import SelectorRow, { SelectorRowGroup } from './SelectorRow';
import {
  MultilineSelectorFormControlLabel,
  MultilineSelectorDescription,
  MultilineSelectorTitle,
  MultilineCheckbox,
} from './MultilineSelector';

export const SelectorCheckboxGroup = SelectorRowGroup;

export type ContainedSelectorCheckboxProps = {
  label: React.ReactNode;
  checked: boolean;
  description?: string;
  name: string;
  className?: string;
  inputProps?: SwitchBaseProps['inputProps'];
  onChange?: CheckboxProps['onChange'];
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  // TODO: Disabled variant
};

const SelectorCheckbox = ({
  checked,
  className,
  description,
  inputProps,
  label,
  name,
  onChange,
  onClick,
  style,
  disabled,
}: ContainedSelectorCheckboxProps) => {
  const checkboxRef = useRef<HTMLElement>();

  return (
    <SelectorRow
      className={className}
      selectable
      active={checked}
      onClick={() => checkboxRef.current?.click()}
      style={style}
      disabled={disabled}
    >
      <MultilineSelectorFormControlLabel
        onClick={(e) => e.stopPropagation()}
        control={
          <MultilineCheckbox
            // Disallow tab indexing on checkbox in favor of using tab indexing on the entire container
            tabIndex={-1}
            inputProps={inputProps}
            color="default"
            checked={checked}
            name={name}
            inputRef={checkboxRef}
            onChange={onChange}
            onClick={onClick}
            disabled={disabled}
          />
        }
        label={
          <>
            <MultilineSelectorTitle disabled={disabled}>{label}</MultilineSelectorTitle>
            {description && (
              <MultilineSelectorDescription>{description}</MultilineSelectorDescription>
            )}
          </>
        }
      />
    </SelectorRow>
  );
};

export default SelectorCheckbox;
