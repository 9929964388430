import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Slide, Typography } from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import TextField from '~/synth/TextField';
import { AddIcon } from '~/synth/Icons';
import { ReactComponent as ArrowLeftIcon } from '~/static/images/icons/arrow-left.svg';
import { ReactComponent as TrashIcon } from '~/static/images/icons/trash.svg';
import useClient from '~/hooks/useClient';
import useClientAccounts from '~/hooks/useClientAccounts';
import Skeleton from '~/synth/Skeleton';
import { Account, RawClient } from '~/models/api';
import { updateClient } from '~/api/api';
import { maskAccountNumber } from '~/utils/format';
import useEnqueueToast from '~/hooks/useToast';
import amplitude from '~/utils/amplitude';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import ErrorFallback from '~/routes/Households/ErrorFallback';
import AddAccountsModalContainer from './AddAccountsModalContainer2';
import SaveFooter from './components/ManageHouseholdSaveFooter';
import {
  EmptyItemsContainer,
  ItemRow,
  ItemRowsContainer,
} from './components/ManageHouseholdItemRow';
import UnlinkAccountModalContainer, {
  UnlinkAccountModalContainerProps,
} from './UnlinkAccountModalContainer';
import DeleteClientModalContainer from './DeleteClientModalContainer';
import WithLoader from '../../utils/WithLoader';

interface EditClientNameContainerProps {
  clientData: RawClient;
  setShowSaveFooter: (showSaveFooter: boolean) => void;
}

interface AccountsContainerProps {
  clientAccountsData: Account[];
  clientAccountsRefetch: () => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
}

const AccountsLoadingContainer = () => {
  return (
    <Box>
      <ItemRowsContainer>
        {[0, 1, 2].map((n) => {
          return (
            <ItemRow key={n}>
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <Box>
                  <Skeleton width="10em" />
                  <Skeleton width="5em" />
                </Box>
                <Skeleton inline width="2em" />
              </Box>
            </ItemRow>
          );
        })}
      </ItemRowsContainer>
    </Box>
  );
};

const DeleteClientContainer = ({
  clientId,
  householdId,
}: {
  clientId: string;
  householdId: string;
}) => {
  const [deleteClientModalOpen, setDeleteClientModalOpen] = useState(false);
  const enqueueToast = useEnqueueToast();
  const history = useHistory();

  return (
    <>
      <Box mt={1.5}>
        <Typography color="textSecondary" variant="body1">
          Please note that deleting this client will also delete any portfolios and proposals
          associated with them.
        </Typography>
      </Box>
      <Box mt={3}>
        <Button
          color="error"
          variant="outlined"
          onClick={() => {
            amplitude().logEvent('Tap delete client', {
              category: EVENT_CATEGORIES.HOUSEHOLDS,
              clientId,
            });
            setDeleteClientModalOpen(true);
          }}
        >
          Delete client
        </Button>
      </Box>
      <DeleteClientModalContainer
        clientId={clientId}
        onSuccess={() => {
          enqueueToast({
            title: 'Client deleted successfully',
            severity: 'success',
          });
          setDeleteClientModalOpen(false);
          history.push(`/secure/households/${householdId}/manage`);
        }}
        isOpen={deleteClientModalOpen}
        onClose={() => {
          amplitude().logEvent('Tap exit', {
            category: EVENT_CATEGORIES.HOUSEHOLDS,
            page: 'Edit client',
          });
          setDeleteClientModalOpen(false);
        }}
      />
    </>
  );
};

const EditClientNameContainer = ({
  clientData,
  setShowSaveFooter,
}: EditClientNameContainerProps) => {
  const enqueueToast = useEnqueueToast();

  const onSubmit = async (updatedFirstName, updatedLastName) => {
    amplitude().logEvent('Tap save changes', {
      category: EVENT_CATEGORIES.HOUSEHOLDS,
      entity: 'Client',
    });
    const updatedClient = {
      ...clientData,
      firstName: updatedFirstName,
      lastName: updatedLastName,
    };
    try {
      await updateClient(updatedClient);
    } catch (e) {
      enqueueToast({
        title: 'Error updating client',
        severity: 'error',
      });
      return;
    }
    enqueueToast({
      title: 'Client updated successfully',
      severity: 'success',
    });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: clientData.firstName,
      lastName: clientData.lastName,
    },
    async onSubmit(values) {
      await onSubmit(values.firstName, values.lastName);
    },

    validate(values) {
      const errors: FormikErrors<FormValues> = {};
      if (values.firstName === '') errors.firstName = 'First name is required';
      if (values.lastName === '') errors.lastName = 'Last name is required';
      return errors;
    },
  });

  useEffect(() => {
    if (
      !formik.isSubmitting &&
      formik.isValid &&
      (formik.values.firstName !== clientData.firstName ||
        formik.values.lastName !== clientData.lastName)
    ) {
      setShowSaveFooter(true);
    } else {
      setShowSaveFooter(false);
    }
  }, [formik, clientData, setShowSaveFooter]);

  return (
    <form id="edit-client-name" onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={() => {
              amplitude().logEvent('Tap edit client first name', {
                category: EVENT_CATEGORIES.HOUSEHOLDS,
              });
            }}
            id="firstName"
            name="firstName"
            label="First name"
            inputProps={{ maxLength: 255 }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={() => {
              amplitude().logEvent('Tap edit client last name', {
                category: EVENT_CATEGORIES.HOUSEHOLDS,
              });
            }}
            id="lastName"
            name="lastName"
            label="Last name"
            inputProps={{ maxLength: 255 }}
            required
          />
        </Grid>
      </Grid>
    </form>
  );
};

const UNLINK_ACCOUNT_MODAL_CLOSED_SETTINGS = {
  accountId: undefined,
  clientId: undefined,
  isOpen: false,
};

const AccountsContainer = ({
  clientAccountsData,
  clientAccountsRefetch,
}: AccountsContainerProps) => {
  const enqueueToast = useEnqueueToast();

  const [unlinkAccountModalSettings, setUnlinkAccountModalSettings] = useState<
    Pick<UnlinkAccountModalContainerProps, 'isOpen' | 'accountId' | 'clientId'>
  >(UNLINK_ACCOUNT_MODAL_CLOSED_SETTINGS);

  const closeUnlinkAccountModal = () => {
    setUnlinkAccountModalSettings(UNLINK_ACCOUNT_MODAL_CLOSED_SETTINGS);
  };

  const openUnlinkAccountModal = (clientId: string, accountId: string) => {
    setUnlinkAccountModalSettings({
      isOpen: true,
      accountId,
      clientId,
    });
  };

  return (
    <>
      <ItemRowsContainer>
        {clientAccountsData.length === 0 ? (
          <EmptyItemsContainer>
            <Typography variant="body1" color="textSecondary">
              There are no accounts associated with this client.
            </Typography>
          </EmptyItemsContainer>
        ) : (
          clientAccountsData.map((accountData) => (
            <ItemRow key={accountData.id}>
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <Box>
                  <Box mb={1}>
                    <Typography
                      component="span"
                      display="block"
                      variant="h4"
                    >{`${accountData.name}`}</Typography>
                  </Box>
                  <Typography color="textSecondary" variant="body1">
                    {maskAccountNumber(accountData.accountNumber)}, {accountData.custodianKey}
                  </Typography>
                </Box>
                <IconButton
                  aria-label="Unlink account"
                  color="inherit"
                  onClick={() => {
                    amplitude().logEvent('Tap unlink account', {
                      category: EVENT_CATEGORIES.HOUSEHOLDS,
                      accountId: accountData.id,
                    });
                    if (accountData.viseClientId != null)
                      openUnlinkAccountModal(accountData.viseClientId, accountData.id);
                  }}
                  size="large"
                >
                  <TrashIcon height="24" width="24" />
                </IconButton>
              </Box>
            </ItemRow>
          ))
        )}
      </ItemRowsContainer>
      <UnlinkAccountModalContainer
        {...unlinkAccountModalSettings}
        onSuccess={() => {
          enqueueToast({
            title: 'Account unlinked successfully',
            severity: 'success',
          });
          // Need to do an explicit refetch because we don't automatically revalidate after a delete request
          clientAccountsRefetch();
          closeUnlinkAccountModal();
        }}
        onClose={() => {
          amplitude().logEvent('Tap exit', {
            category: EVENT_CATEGORIES.HOUSEHOLDS,
            page: 'Edit client',
          });
          closeUnlinkAccountModal();
        }}
      />
    </>
  );
};

const ManageClient = () => {
  const {
    params: { householdId, clientId },
  } = useRouteMatch<{ householdId: string; clientId: string }>();

  useEffect(() => {
    amplitude().logEvent('Impression - Edit client', {
      category: EVENT_CATEGORIES.HOUSEHOLDS,
      clientId,
    });
  }, [clientId]);

  const [addAccountsModalOpen, setAddAccountsModalOpen] = useState(false);

  const { data: clientData, isLoading: clientIsLoading, error: clientError } = useClient(clientId);

  const {
    data: clientAccountsData,
    isLoading: clientAccountsIsLoading,
    mutate: clientAccountsRefetch,
    error: clientAccountsError,
  } = useClientAccounts(clientId);

  const [showSaveFooter, setShowSaveFooter] = useState<boolean>(false);

  const loadingError = clientError || clientAccountsError;
  if (loadingError) {
    return <ErrorFallback error={loadingError} />;
  }

  return (
    <>
      <Helmet>
        <title>Edit client</title>
      </Helmet>
      <Box ml={3} mb={6}>
        <Grid container>
          <Grid item sm={5}>
            <Button
              color="inherit"
              component={Link}
              startIcon={<ArrowLeftIcon />}
              to={`/secure/households/${householdId}/manage`}
            >
              Manage household
            </Button>
            <Box mt={2}>
              <Typography variant="h1">Edit client</Typography>
            </Box>
            <Box mt={4}>
              <WithLoader
                isLoading={clientIsLoading}
                loader={
                  <Box display="flex" justifyContent="space-between">
                    <Skeleton inline width="14em" />
                    <Skeleton inline width="14em" />
                  </Box>
                }
              >
                {clientData != null && (
                  <EditClientNameContainer
                    clientData={clientData}
                    setShowSaveFooter={setShowSaveFooter}
                  />
                )}
              </WithLoader>
            </Box>
            <Box mt={5}>
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <Typography variant="h3">Accounts</Typography>
                {!clientAccountsIsLoading && (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon display="inline-flex" />}
                      onClick={() => {
                        amplitude().logEvent('Tap add account', {
                          category: EVENT_CATEGORIES.HOUSEHOLDS,
                          page: 'Edit client',
                        });
                        setAddAccountsModalOpen(true);
                      }}
                    >
                      Add account
                    </Button>
                    <AddAccountsModalContainer
                      clientId={clientId}
                      isOpen={addAccountsModalOpen}
                      onClose={() => {
                        amplitude().logEvent('Tap exit', {
                          category: EVENT_CATEGORIES.HOUSEHOLDS,
                          page: 'Edit client',
                        });
                        setAddAccountsModalOpen(false);
                      }}
                    />
                  </>
                )}
              </Box>
              <WithLoader isLoading={clientAccountsIsLoading} loader={<AccountsLoadingContainer />}>
                {clientAccountsData != null && (
                  <AccountsContainer
                    clientAccountsData={clientAccountsData}
                    clientAccountsRefetch={clientAccountsRefetch}
                  />
                )}
              </WithLoader>
            </Box>
            <Box mt={5}>
              <Typography variant="h3">Delete client</Typography>
              <DeleteClientContainer householdId={householdId} clientId={clientId} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Slide in={showSaveFooter} mountOnEnter unmountOnExit direction="up">
        <SaveFooter formId="edit-client-name" />
      </Slide>
    </>
  );
};

export default ManageClient;
