import styled from 'styled-components';

export const RadioIcon = styled.span.attrs(() => ({
  className: 'RadioIcon',
}))`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.palette.grey[300]},
    inset 0 -1px 0 ${({ theme }) => theme.palette.grey[300]};
  background-color: transparent;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
  .Mui-focusVisible & {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.palette.primary.main},
      inset 0 -1px 0 ${({ theme }) => theme.palette.primary.main};
  }
  input:hover:not([disabled]) ~ & {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.palette.primary.main},
      inset 0 -1px 0 ${({ theme }) => theme.palette.primary.main};
  }
  input:disabled ~ & {
    background: ${({ theme }) => theme.palette.grey[200]};
  }
`;

export const RadioCheckedIcon = styled(RadioIcon).attrs((props) => ({
  className: props.className ? `${props.className} RadioIconChecked` : 'RadioIconChecked',
}))`
  background-color: ${({ theme }: { theme }) => theme.palette.primary.main};
  background-image: unset;
  box-shadow: inset 0 0 0 1px ${({ theme }: { theme }) => theme.palette.primary.main},
    inset 0 -1px 0 ${({ theme }: { theme }) => theme.palette.primary.main};
  &:before {
    display: block;
    width: 20px;
    height: 20px;
    background-image: radial-gradient(#fff, #fff 21%, transparent 21%);
    content: '';
  }
  input:hover ~ & {
    background-color: ${({ theme }: { theme }) => theme.palette.primary.main};
  }
`;
