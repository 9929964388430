import { doRequestWithAuth } from './apiUtil';
import { FeatureFlagResponse, Treatments, TreatmentsWithConfig } from '../models/featureFlag';

// eslint-disable-next-line import/prefer-default-export
export const getTreatments = async (): Promise<Treatments> => {
  const { data } = await doRequestWithAuth<FeatureFlagResponse>({
    requestUrl: `/split/treatments`,
    method: 'GET',
    dataSourceIfNotCoreApi: 'misc_immutable',
  });
  return data;
};

export const getTreatmentsWithConfig = async () => {
  const { data } = await doRequestWithAuth<TreatmentsWithConfig>({
    requestUrl: `/split/treatments-with-config`,
    method: 'GET',
    dataSourceIfNotCoreApi: 'misc_immutable',
  });
  return data;
};
