// import MomentUtils from '@date-io/moment';
// import { ReactComponent as CalendarIcon } from '~/static/images/icons/calendar.svg';
// import TextField from '~/synth/TextField';
import { OutlinedInputProps, styled } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Input } from '~/components';

export interface DatePickerProps {
  onChange: (val: moment.Moment | null) => void;
  value: moment.Moment | null;
  maxDate?: moment.Moment | null;
  minDate?: moment.Moment | null;
}

const StyledInput = styled(Input)({
  '&.MuiOutlinedInput-root': {
    marginTop: 0,
  },
});

const DatePicker = (props: Omit<OutlinedInputProps, 'onChange'> & DatePickerProps) => {
  const { value, maxDate, minDate, onChange, ...other } = props;
  return (
    <div>
      <StyledInput
        type="date"
        value={value?.format('YYYY-MM-DD')}
        inputProps={{
          max: maxDate?.format('YYYY-MM-DD'),
          min: minDate?.format('YYYY-MM-DD'),
        }}
        sx={{ height: '35px', marginTop: 0 }}
        {...other}
        onChange={(e) => onChange(moment(e.currentTarget.value))}
        size="small"
      />
    </div>
  );
};

export default DatePicker;
