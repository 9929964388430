import { Box, Card, InputAdornment, Typography, useTheme, Container } from '@mui/material';
import React, { useMemo, useState } from 'react';
import CardHeader from '~/synth/CardHeader';
import TextField from '~/synth/TextField';
import { SearchIcon } from '~/synth/Icons';
import emptyActivityLogGraphic from '~/static/images/modelTemplateCenter/empty-activity-log.svg';

import useActivityLogViewData from '~/hooks/useActivityLogViewData';
import { DataTable } from '~/synth/DataTable';
import { ActivityLogViewData } from 'vise-types/template';
import { Column, TableState, usePagination, UsePaginationState, useTable } from 'react-table';
import LoadingSpinner from '~/synth/LoadingSpinner';
import { useHistory, useRouteMatch } from 'react-router';
import PageNavigation from '~/components/table/PageNavigation';
import { matchSorter } from 'match-sorter';
import EmptyState from '../EmptyState';
import {
  BulkEditCell,
  Cell,
  DateCell,
  EventCell,
  TemplateCell,
} from '../workflows/CommonComponents';
import ActivityLogDetails from './ActivityLogDetails';

const PAGE_SIZE = 10;

function ErrorsCell(props: { value?: number }) {
  return <Box>{props.value ? props.value : 'None'}</Box>;
}

function UpdateTypeCell(props: { value: ActivityLogViewData['event'] }) {
  return <Box>{props.value.type === 'BULK_EDIT' ? 'Bulk update' : 'Template'}</Box>;
}

const activityLogTableColumns: Column<ActivityLogViewData>[] = [
  {
    Header: () => <Cell value="Type" />,
    accessor: 'event',
    Cell: UpdateTypeCell,
    id: 'typeCell',
  },
  {
    Header: () => <Cell value="Template/Bulk edit" />,
    accessor: 'template',
    Cell: (props) => {
      if (!props.value) {
        return <BulkEditCell />;
      }
      return <TemplateCell value={props.value} />;
    },
  },
  {
    Header: 'Event',
    accessor: 'event',
    Cell: EventCell,
  },
  {
    Header: 'Date/Time',
    // Use updated at so linking jobs are ordered by when the job completed
    accessor: 'updatedAt',
    Cell: DateCell,
  },
  {
    Header: 'Errors',
    accessor: 'errors',
    Cell: ErrorsCell,
  },
];

export default function ActivityLog() {
  const [filterQuery, setFilterQuery] = useState('');
  const history = useHistory();
  const { data: activityLogData, error } = useActivityLogViewData();
  const theme = useTheme();
  const match = useRouteMatch<{ activityId: string }>(
    '/secure/strategy-center/activity/:activityId'
  );
  const activityId = match?.params.activityId;

  const activityLogTableData = useMemo(() => {
    return matchSorter(activityLogData?.data || [], filterQuery, {
      keys: ['template.name'],
      threshold: matchSorter.rankings.CONTAINS,
    });
  }, [activityLogData?.data, filterQuery]);

  const activityLogTable = useTable<ActivityLogViewData>(
    {
      columns: activityLogTableColumns,
      data: activityLogTableData || [],
      initialState: {
        pageSize: PAGE_SIZE,
      } as TableState<ActivityLogViewData> & UsePaginationState<ActivityLogViewData>,
      autoResetPage: false,
    },
    usePagination
  );

  // TODO(mkiisa): Refactor in the future. This component doesn't require loading the rest of the activities.
  if (activityId) {
    return <ActivityLogDetails activityId={activityId} />;
  }

  if (error) {
    return (
      <Box padding={2}>
        <Typography variant="body2">Error loading data</Typography>
      </Box>
    );
  }
  if (!activityLogData) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Card>
        <CardHeader>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <Typography variant="h2">Activity Log</Typography>
            </Box>
            <Box width="360px">
              <TextField
                fullWidth
                value={filterQuery}
                onChange={(e) => {
                  setFilterQuery(e.target.value);
                  activityLogTable.gotoPage(0);
                }}
                placeholder="Search by template name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </CardHeader>
        {activityLogTableData && activityLogTableData.length === 0 ? (
          <EmptyState
            height={720}
            graphic={emptyActivityLogGraphic}
            title="There are currently no recorded activities"
            body="Any actions performed in Template Hub will be recorded here."
            showCTA={false}
          />
        ) : (
          <>
            <DataTable
              table={activityLogTable}
              onRowClick={(row) => {
                history.push(`/secure/strategy-center/activity/${row.original.id}`);
              }}
              rowSize="large"
            />
            {activityLogTableData && activityLogTableData.length > PAGE_SIZE && (
              <Box px={3} py={4} display="flex" borderTop={`1px solid ${theme.palette.grey[100]}`}>
                <Box flex={1} />
                <PageNavigation
                  currentPage={
                    (activityLogTable.state as UsePaginationState<ActivityLogViewData>).pageIndex +
                    1
                  }
                  numItems={activityLogTableData.length}
                  pageSize={PAGE_SIZE}
                  onNextPageClick={() => {
                    activityLogTable.nextPage();
                  }}
                  onPrevPageClick={() => {
                    activityLogTable.previousPage();
                  }}
                  onPageNumberClick={(pageNumber) => {
                    activityLogTable.gotoPage(pageNumber - 1);
                  }}
                />
              </Box>
            )}
          </>
        )}
      </Card>
    </Container>
  );
}
