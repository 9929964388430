import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';

const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const StyledSkeleton = styled.div<SkeletonProps>`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  background: ${({ variant }) =>
    variant !== 'chart'
      ? `linear-gradient(90deg, rgba(242, 245, 252, 0.947917) 0%, #e4eaf9 100%)`
      : `linear-gradient(87.41deg, rgba(229, 235, 249, 0.8) 0.36%, rgba(242, 245, 252, 0) 99.62%), linear-gradient(180deg, rgba(229, 235, 249, 0.81) 0%, rgba(242, 245, 252, 0) 100%)`};
  background-blend-mode: ${({ variant }) => (variant === 'chart' ? 'multiply' : 'initial')};
  height: ${({ height, variant, children }) => {
    if (height) {
      return height;
    }
    if (variant === 'text' || children) {
      return 'auto';
    }
    return '1.2em';
  }};
  margin-top: ${({ variant }) => (variant === 'text' ? '0' : 'initial')};
  margin-bottom: ${({ variant }) => (variant === 'text' ? '0' : 'initial')};
  transform-origin: ${({ variant }) => (variant === 'text' ? '0 60%' : 'initial')};
  transform: ${({ variant }) => (variant === 'text' ? 'scale(1, 0.60)' : 'initial')};
  border-radius: ${({ variant }) => {
    if (variant === 'text') {
      return '28px';
    }
    if (variant === 'circle') {
      return '50%';
    }
    return 'initial';
  }};
  ${({ variant }) =>
    variant === 'text'
      ? css`
          &:empty:before {
            content: '\\00a0';
          }
        `
      : ''}
  ${({ disableAnimation }) =>
    !disableAnimation
      ? css`
          position: relative;
          overflow: hidden;
          &::after {
            animation: ${shimmer} 1.6s linear 0.5s infinite;
            background: linear-gradient(90deg, transparent, rgb(246 249 255), transparent);
            content: '';
            position: absolute;
            transform: translateX(-100%);
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
          }
        `
      : ''}
  ${({ children }) =>
    children
      ? css`
          & > * {
            visibility: hidden;
          }
        `
      : ''}
  max-width: ${({ children, width }) => (children && !width ? 'fit-content' : 'initial')};
  ${({ width }) => width && `width: ${width};`}
  ${({ variant }) =>
    variant === 'chart'
      ? css`
          clip-path: polygon(0 6vw, 100% 0, 100% 100%, 0 100%);
        `
      : ''}
`;

type SkeletonProps = {
  /** Custom height (CSS string) for the skeleton component. Otherwise, infers the dimensions with em or with
   * the height of the child element (if one is provided). */
  height?: string;
  /** Custom width (CSS string) for the skeleton component. Otherwise, infers the dimensions with
   * the width of the child element (if one is provided). */
  width?: string;
  /** Set if component should be displayed inline (inline-block) */
  inline?: boolean;
  disableAnimation?: boolean;
  variant?: 'text' | 'rect' | 'circle' | 'chart';
  /** Optional children to infer width and height from */
  children?: React.ReactNode;
};

/** Display a placeholder preview of your content before the data gets loaded to reduce load-time frustration.
 * The component supports 4 shape variants - rectangle, circle, chart, and text (default).
 *
 * In addition to accepting `width` and `height` props, the component can also infer the dimensions.
 * It works well when it comes to typography as its height is set using `em` units.
 *
 * But when it comes to other components, you may not want to repeat the width and height.
 * In these instances, you can pass children and it will infer its width and height from them.
 */
const Skeleton = ({
  height,
  width,
  variant = 'text',
  disableAnimation,
  inline,
  children,
}: SkeletonProps) => (
  <StyledSkeleton
    height={height}
    width={width}
    disableAnimation={disableAnimation}
    variant={variant}
    inline={inline}
    data-testid="loading-skeleton"
  >
    {children}
  </StyledSkeleton>
);

export default Skeleton;
