import { Box, DrawerProps, Drawer, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react';
import { TextHighlightTag } from '~/synth/Tag';
import { ReactComponent as ChevronRightIcon } from '~/static/images/icons/chevron-right.svg';

interface Props {
  onClose: (event: {}, closeReason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick') => void;
  open: DrawerProps['open'];
}

function LearnMoreDrawer({ open, onClose }: Props) {
  const theme = useTheme();

  function handleOnClose() {
    if (onClose) {
      onClose({}, 'closeButtonClick');
    }
  }

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box maxWidth="500px">
        <Box
          boxShadow={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignContent="center"
          p={3}
        >
          <Typography variant="h2">Active tilt strategies</Typography>
          <Box mt={-1}>
            <IconButton onClick={handleOnClose} size="large">
              <ChevronRightIcon color={theme.palette.grey[800]} height="24" width="24" />
            </IconButton>
          </Box>
        </Box>
        <Box p={3}>
          <Box borderBottom={`1px solid ${theme.palette.grey[200]}`} mb={3.5} pb={3.5}>
            <Typography variant="h3">What are active tilts?</Typography>
            <Box color="grey.600" mt={1.5} mb={2.5}>
              Active tilts are deviations from market cap weights intended to emphasize various
              factors that can help clients pursue goals such as higher expected returns or dividend
              income. The factors available for investment through Vise are backed by valuation
              theory, heavily vetted with empirical research.
            </Box>
            <Typography variant="h4">How does factor investing work?</Typography>
            <Box color="grey.600" mt={1.5} mb={2.5}>
              Factor investing, fundamentally, is about understanding which characteristics of
              securities reliably explain differences in returns. With this understanding, Vise
              constructs strategies that deviate from market capitalization weights to pursue
              factors based on client preferences.
            </Box>
          </Box>
          <Box>
            <Typography variant="h3">What factors does active tilting include?</Typography>
            <Box display="flex" flexDirection="row" mt={3}>
              <Typography variant="h4">Value</Typography>
              <TextHighlightTag ml={1} severity="priority" verticalAlign="bottom">
                Multi-factor tilt
              </TextHighlightTag>
            </Box>
            <Box color="grey.600" mt={1.5} mb={3}>
              Value companies, measured by Vise as companies with lower intangible-adjusted
              price-to-book ratios, have historically outperformed growth companies. The value
              factor was first proposed by Eugene Fama and Kenneth French in the early 1990s and has
              been improved upon with modern research into the impact of intangible assets on
              valuation ratios.
            </Box>
            <Box mt={1.5} mb={3} display="flex" flexDirection="row">
              <Typography variant="h4">Profitability</Typography>
              <TextHighlightTag ml={1} severity="priority" verticalAlign="bottom">
                Multi-factor tilt
              </TextHighlightTag>
            </Box>
            <Box color="grey.600" mt={1.5} mb={3}>
              Sensibly, companies with higher profitability have historically outperformed companies
              with lower profitability. Robert Novy-Marx’s research in 2012 helped to define
              possible definitions for capturing this intuitive premium.
            </Box>
            <Box display="flex" flexDirection="row">
              <Typography variant="h4">Size</Typography>
              <TextHighlightTag ml={1} severity="priority" verticalAlign="bottom">
                Multi-factor tilt
              </TextHighlightTag>
            </Box>
            <Box color="grey.600" mt={1.5} mb={3}>
              Smaller market capitalization companies have historically outperformed larger market
              capitalization companies. This factor was first discovered by Rolf Banz in the early
              1980s.
            </Box>
            <Box display="flex" flexDirection="row">
              <Typography variant="h4">Dividends</Typography>
              <TextHighlightTag ml={1} severity="success" verticalAlign="bottom">
                Dividends tilt
              </TextHighlightTag>
            </Box>
            <Box color="grey.600" mt={1.5} mb={3}>
              Investing in dividend paying companies is a reliable way to increase the yield
              generated by the equities in your portfolio.
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default LearnMoreDrawer;
