import useSWR from 'swr';
import { fullXrayAnalysis } from '~/api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useGetFullXray(proposalId: string | null | undefined) {
  return useSWR(
    proposalId == null ? null : generateSwrKey(`/xray/full-xray-analysis/${proposalId}`),
    proposalId == null ? undefined : () => fullXrayAnalysis(proposalId)
  );
}
