import useSWR from 'swr';
import { getAllocationsTemplate } from '~/api/api';
import generateSwrKey from '../../api/generateSwrKey';

export default function useAllocationsTemplate(
  id: string | null | undefined,
  fetchDeleted?: boolean
) {
  return useSWR(
    id == null ? null : generateSwrKey(`allocations-template-${id}-fetchDeleted=${!!fetchDeleted}`),
    id == null ? undefined : () => getAllocationsTemplate(id, fetchDeleted)
  );
}
