import { Box, Card, Divider, useTheme } from '@mui/material';
import React from 'react';
import usePositions from '~/hooks/usePositions';
import { getAssetClassFeaturesFromKey } from '~/utils/pce2Migration';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { capitalizeWord, formatCurrency, formatPercent, formatQuantity } from '~/utils/format';
import { AssetClassKey, Sector } from 'vise-types/pce2_instrument';
import { LockedIcon } from '~/synth/Icons';
import GPTCardHeader from './GPTCardHeader';
import {
  PricedPortfolioHolding,
  getPCE2AccountPositionsRowData,
} from '../../Portfolio/portfolioUtil';
import { ASSET_CLASS_TO_LABEL_MAP } from '../../PortfolioCreator2/Constants';
import { SECTOR_TO_LABEL_MAP } from '../../Portfolio/Constants';
import LoadingCard from './LoadingCard';
import CardDisclosures from './CardDisclosures';
import usePortfolioIntelligenceWithLogging from './usePortfolioIntelligenceWithLogging';
import useAccountWithLogging from './useAccountWithLogging';

export default function GPTPortfolioHoldingsCard({ accountId }: { accountId?: string }) {
  const { data: accountResponse } = useAccountWithLogging({
    accountId,
    callerName: 'GPTPortfolioHoldingsCard',
  });
  const { data: positions } = usePositions(
    accountResponse?.data?.accountNumber,
    accountResponse?.data?.custodianKey,
    true
  );

  const { data: intelligenceResponse } = usePortfolioIntelligenceWithLogging(
    accountResponse?.data.portfolioIntelligenceId,
    'GPTPortfolioHoldingsCard'
  );

  const theme = useTheme();

  const intelligence = intelligenceResponse?.[0];

  const account = accountResponse?.data;

  const positionsGroupedByAssetClass = React.useMemo(() => {
    if (!account || !positions || !intelligence) {
      return {};
    }
    const lockedTickers = new Set(intelligence.constructionRequest.doNotSellSymbolsList);

    return getPCE2AccountPositionsRowData(
      positions.positions,
      account.custodianKey,
      lockedTickers,
      (securityType, assetClass) => {
        if (ASSET_CLASS_TO_LABEL_MAP.get(getAssetClassFeaturesFromKey(assetClass)[0])) {
          return ASSET_CLASS_TO_LABEL_MAP.get(
            getAssetClassFeaturesFromKey(assetClass)[0]
          ) as string;
        }
        if (securityType === 'CASH_OR_EQIV' || securityType === 'MF') {
          return 'Cash';
        }
        return 'Other assets';
      }
    );
  }, [account, positions, intelligence]);

  const sortedHoldings: PricedPortfolioHolding[] = React.useMemo(() => {
    const allHoldings = Object.values(positionsGroupedByAssetClass).flat();

    const sorted = allHoldings.sort((a, b) =>
      a.value != null && b.value != null && a.value < b.value ? 1 : -1
    );

    return sorted.map((holding, idx) => {
      return { ...holding, ranking: idx + 1 };
    });
  }, [positionsGroupedByAssetClass]);

  const columns = React.useMemo(
    () =>
      [
        {
          headerName: '',
          field: 'locked',
          renderCell: (props) =>
            props.value ? (
              <LockedIcon size="small" top="2px" color={theme.palette.grey[800]} />
            ) : null,
          flex: 0.2,
          sortable: false,
        },
        {
          headerName: 'Ticker',
          field: 'ticker',
          flex: 0.7,
          renderCell: (props) => <Box>{props.value}</Box>,
        },
        {
          headerName: 'Name',
          field: 'name',
          flex: 2,

          renderCell: (props) => (
            <div
              title={capitalizeWord(props.value)}
              style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden' }}
            >
              {props.value}
            </div>
          ),
        },
        {
          headerName: 'Sector',
          field: 'sector',
          flex: 1,
          renderCell: (props: { value: string | AssetClassKey }) => {
            let label: string | undefined = props.value;
            if (!label) {
              return '-';
            }

            label = props.value
              ? SECTOR_TO_LABEL_MAP.get(props.value.split('/')[0] as Sector)
              : undefined;

            return (
              <div
                title={label}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden' }}
              >
                {label}
              </div>
            );
          },
        },
        {
          headerName: 'QTY',
          field: 'shares',
          flex: 1,
          renderCell: (props) => <div>{formatQuantity(props.value)}</div>,
        },
        {
          headerName: 'Allocation',
          field: 'allocation',
          flex: 1,
          renderCell: (props: { value: number }) => (
            <div>{props.value < 0.0005 ? '< 0.1%' : formatPercent(props.value, 1)}</div>
          ),
        },
        {
          headerName: <Box pr={1}>Value</Box>,
          field: 'value',
          flex: 1,
          renderCell: (props) =>
            props.value && props.value > 0 ? <div>{formatCurrency(props.value)}</div> : <></>,
        },
      ] as GridColDef[],
    [theme.palette.grey]
  );

  if (!account || sortedHoldings.length === 0 || !positions || !intelligence) {
    return <LoadingCard contentHeight="350px" />;
  }

  return (
    <Card style={{ overflow: 'visible' }}>
      <GPTCardHeader
        title="Portfolio Holdings"
        buttonPathname={`/secure/accounts/${accountId}/portfolio/overview`}
        buttonCopy="View account"
        account={account}
      />
      <Box
        height="350px"
        sx={{
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'white',
          },
          '& .MuiDataGrid-root': {
            boxShadow: undefined,
            border: '0px solid #000000',
          },
        }}
      >
        <DataGridPro
          rows={sortedHoldings}
          columns={columns}
          getRowId={(row) => row.ticker}
          sortModel={[{ field: 'value', sort: 'desc' }]}
        />
      </Box>
      <Divider />
      <CardDisclosures />
    </Card>
  );
}
