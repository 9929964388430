import { deleteSchedule } from '../api/api';
import useEnqueueToast from './useToast';

export default function useDeleteDistribution() {
  const enqueueToast = useEnqueueToast();
  const deleteDistribution = async (scheduleId: string, accountId: string) => {
    try {
      await deleteSchedule(scheduleId, accountId);
      enqueueToast({
        severity: 'success',
        title: `You've successfully deleted your distribution schedule.`,
      });
      return true;
    } catch (e) {
      enqueueToast({
        severity: 'error',
        title: `Failed to delete distribution schedule.`,
        content: `Please reach out to clientservice@vise.com for help.`,
      });
      return false;
    }
  };
  return { deleteDistribution };
}
