import useSWR from 'swr';
import { getHouseholdMeetingPrep } from '~/api/gptApi';
import generateSwrKey from '../api/generateSwrKey';

export const getHouseholdMeetingPrepSwrKey = (householdId: string) =>
  generateSwrKey(`householdMeetingPrep/${householdId}`);

const useHouseholdMeetingPrep = (householdId: string | null | undefined) => {
  const { data, error, isValidating, mutate } = useSWR(
    householdId ? getHouseholdMeetingPrepSwrKey(householdId) : null,
    householdId == null ? undefined : () => getHouseholdMeetingPrep({ householdId })
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export default useHouseholdMeetingPrep;
