import React, { useEffect } from 'react';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import amplitude from '~/utils/amplitude';
import { ScreenProps } from '../Types';
import { ContentBox } from './components';
import PCE2RiskToleranceScreen from './PCE2RiskToleranceScreen';

export default function TornadoScreen(props: ScreenProps) {
  useEffect(() => {
    amplitude().logEvent('Impression - Portfolio Focus Inputs', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);

  const { onContinue: dpOnContinue } = props;
  const onContinue = () => {
    dpOnContinue('default');
  };

  return (
    <ContentBox maxWidth="748px">
      <PCE2RiskToleranceScreen
        {...props}
        onContinue={onContinue}
        onReturnToSummary={() => dpOnContinue('summary')}
      />
    </ContentBox>
  );
}
