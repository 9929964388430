import moment from 'moment';

export const calculateRetirementAge = (birthday: string | Date, retirementDate: string | Date) => {
  return moment
    .utc(retirementDate)
    .startOf('date')
    .diff(moment.utc(birthday).startOf('date'), 'years');
};

export const calculateCurrentAge = (birthday: string | Date) => {
  return moment.utc().startOf('date').diff(moment.utc(birthday).startOf('date'), 'years');
};

export const calculateRetirementDate = (birthday: string | Date, retirementAge: number) => {
  return moment.utc(birthday).startOf('date').add(retirementAge, 'y');
};

export const isValidRetirementAge = (birthday: string | Date, retirementAge: number) => {
  return moment
    .utc(birthday)
    .startOf('date')
    .add(retirementAge, 'years')
    .isAfter(moment.utc().startOf('date'), 'day');
};

/** Do not use this to show a "years remaining" count; Math.ceil should be used for that. */
export const calculatePortfolioHorizon = (
  investmentTimeline: string | Date | moment.Moment,
  creationDate: string | Date | moment.Moment
) => {
  const preciseYears = moment
    .utc(investmentTimeline)
    .startOf('date')
    .diff(moment.utc(creationDate).startOf('date'), 'years', true);
  const roundedYears = Math.round(preciseYears);
  // Round up to 1 if needed; return 0 if horizon has passed
  return Math.max(0, preciseYears > 0 && preciseYears < 1 ? 1 : roundedYears);
};
