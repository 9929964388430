import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { mapValues } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AllocationsTemplate } from 'vise-types/template';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAllAllocationsTemplates from '~/hooks/templates/useAllAllocationsTemplates';
import useDeleteAllocationsTemplate from '~/hooks/templates/useDeleteAllocationsTemplate';
import useRenameAllocationsTemplate from '~/hooks/templates/useRenameAllocationsTemplate';
import { useCoachmarkEffect } from '~/hooks/useCoachmark';
import { RootState } from '~/reducers';
import { ReactComponent as ChevronDownIcon } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '~/static/images/icons/chevron-up.svg';
import amplitude from '~/utils/amplitude';
import { tiltSelectionToLabel } from '~/routes/ModelTemplateCenter/utils';
import {
  BalancedPaddedContainer,
  DeleteTemplateModal,
  PaddedContainer,
  RenameTemplateModal,
  TemplateCard,
  TemplateSkeletons,
} from '../../templates/CommonComponents';
import TemplatesSearchAndFilterBar from '../../templates/TemplatesSearchAndFilterBar';
import { ScreenProps } from '../../Types';
import {
  AssetClassTreeNodeWithPercentage,
  buildAllocationsTreesWithPercentages,
  getAssetClassNameFromKey,
} from '../../utils';
import { BackButton, ContentBox, ExplainerText, StickyBottomActionContainer } from '../components';
import { SubSectionBody, SubSectionHeader } from '../components/SummarySections';
import useAllocationsTemplateValidation from '../CustomAllocations/useAllocationsTemplateValidation';

const DELETE_TEMPLATE_MODAL_CLOSED = { open: false, templateId: '', templateName: '' };
const RENAME_TEMPLATE_MODAL_CLOSED = { open: false, templateId: '' };

function TemplateCardSection({
  assetClassTreeRoot,
  showL3Nodes,
}: {
  assetClassTreeRoot: AssetClassTreeNodeWithPercentage;
  showL3Nodes: boolean;
}) {
  if (assetClassTreeRoot.percentage == null) {
    return null;
  }
  const sectionRows = assetClassTreeRoot.children?.map((l2Node, index) => {
    if (l2Node.percentage == null) {
      return null;
    }
    let l3Rows: React.ReactNode = null;
    if (showL3Nodes) {
      l3Rows = l2Node.children?.map((l3Node) => {
        return (
          l3Node.percentage != null && (
            <Box
              key={`${l3Node.assetClassKey}_row`}
              paddingTop={1}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography color="textSecondary" variant="body2">
                {getAssetClassNameFromKey(l3Node.assetClassKey)}
              </Typography>
              <Typography color="textSecondary" variant="h4">
                {l3Node.percentage}%
              </Typography>
            </Box>
          )
        );
      });
    }
    return (
      <Box key={`${l2Node.assetClassKey}_row`}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingTop={showL3Nodes && index > 0 ? 2 : 1}
        >
          <Box color="grey.600">
            <Typography variant={!showL3Nodes || l3Rows == null ? 'body2' : 'h4'}>
              {getAssetClassNameFromKey(l2Node.assetClassKey)}
            </Typography>
          </Box>
          {(!showL3Nodes || l3Rows == null) && <Box color="grey.500">{l2Node.percentage}%</Box>}
        </Box>
        {l3Rows}
      </Box>
    );
  });
  return (
    <>
      <SubSectionHeader>
        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Box>{getAssetClassNameFromKey(assetClassTreeRoot.assetClassKey)}</Box>
          <Box>{`${assetClassTreeRoot.percentage}%`}</Box>
        </Box>
      </SubSectionHeader>
      <SubSectionBody p={0}>{sectionRows}</SubSectionBody>
    </>
  );
}

export default function SelectAllocationsTemplatesScreen({
  draftPortfolio,
  dpDispatch,
  onBack,
  onContinue,
}: ScreenProps) {
  useEffect(() => {
    amplitude().logEvent('Impression - Select allocations template', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);

  const {
    allocationsTemplateId,
    constructionInfo: { existingPortfolio },
  } = draftPortfolio;

  const [expandedTemplates, setExpandedTemplates] = useState<Set<string>>(new Set());

  const {
    data: allAllocationsTemplates,
    error: allocationsTemplatesError,
    mutate: mutateAllocationsTemplates,
  } = useAllAllocationsTemplates();
  const currentUserId = useSelector((state: RootState) => state.users.currentUser.id);
  const { deleteTemplate } = useDeleteAllocationsTemplate();
  const { renameTemplate } = useRenameAllocationsTemplate();
  const { isNameValid, isTemplateNameTaken } = useAllocationsTemplateValidation();

  useCoachmarkEffect({
    show: allocationsTemplatesError,
    title: 'Sorry, there was an error loading your allocation templates. Please re-load this page.',
    severity: 'error',
  });

  function getTemplateCardSectionsForAllocations(
    allocations: AllocationsTemplate['allocations'],
    templateId: string,
    tiltSelection: AllocationsTemplate['tiltSelection']
  ): React.ReactNode {
    const assetClassRootNodes = buildAllocationsTreesWithPercentages(allocations);
    const templateCardSections: React.ReactNode[] = [];
    const equityTree = assetClassRootNodes.get('EQUITY');
    if (equityTree != null) {
      templateCardSections.push(
        <TemplateCardSection
          key="template_card_equity_section"
          assetClassTreeRoot={equityTree}
          showL3Nodes={expandedTemplates.has(templateId)}
        />
      );
    }
    const fixedIncomeTree = assetClassRootNodes.get('FIXED_INCOME');
    if (fixedIncomeTree != null) {
      templateCardSections.push(
        <TemplateCardSection
          key="template_card_fixed_income_section"
          assetClassTreeRoot={fixedIncomeTree}
          showL3Nodes={expandedTemplates.has(templateId)}
        />
      );
    }
    const alternativesTree = assetClassRootNodes.get('ALTERNATIVES');
    if (alternativesTree != null) {
      templateCardSections.push(
        <TemplateCardSection
          key="template_card_alternatives_section"
          assetClassTreeRoot={alternativesTree}
          showL3Nodes={expandedTemplates.has(templateId)}
        />
      );
    }
    templateCardSections.push(
      <React.Fragment key="tilt-section">
        <Box my={2}>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" my={2}>
          <Box color="grey.600">
            <Typography variant="body2">Tilt Type</Typography>
          </Box>
          <Box color="grey.500">{tiltSelectionToLabel(tiltSelection).type}</Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box color="grey.600">
            <Typography variant="body2">Tilt Amount</Typography>
          </Box>
          <Box color="grey.500">{tiltSelectionToLabel(tiltSelection).value}</Box>
        </Box>
      </React.Fragment>
    );
    templateCardSections.push(<Box key="template_card_spacing" height={30} />);
    return templateCardSections;
  }

  const [filteredTemplates, setFilteredTemplates] = useState<AllocationsTemplate[] | undefined>(
    undefined
  );

  const [deleteTemplateModalSettings, setDeleteTemplateModalSettings] = useState<{
    open: boolean;
    templateId: string;
    templateName: string;
  }>(DELETE_TEMPLATE_MODAL_CLOSED);

  const [renameTempalateModalSettings, setRenameTemplateModalSettings] = useState<{
    open: boolean;
    templateId: string;
  }>(RENAME_TEMPLATE_MODAL_CLOSED);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const selectedTemplate = allAllocationsTemplates?.find(
      (template) => template.id === allocationsTemplateId
    );
    if (selectedTemplate != null) {
      // We may have templates that were created prior to the edit logic change (making only manually edited values count as custom).
      // If so, we still want to correctly record whether they are custom or not for the purposes of disabling glide path
      dpDispatch({
        type: 'SET_ASSET_CLASS_ALLOCATIONS',
        allocations: mapValues(selectedTemplate.allocations, (value) => value ?? 0),
        customAllocations: selectedTemplate.customAllocations,
        isCustomAllocation: selectedTemplate.isCustomAllocation,
      });
      if (selectedTemplate.tiltSelection) {
        dpDispatch({
          type: 'PCE2_SET_ACTIVE_TILT_IS_ENABLED',
          isEnabled: selectedTemplate.tiltSelection.isEnabled,
        });
        if (selectedTemplate.tiltSelection.isEnabled) {
          dpDispatch({
            type: 'PCE2_SET_ACTIVE_TILT_TYPE',
            tiltType: selectedTemplate.tiltSelection.tiltType,
          });
          dpDispatch({
            type: 'PCE2_SET_ACTIVE_TILT_AMOUNT',
            tiltAmount: selectedTemplate.tiltSelection.tiltAmount,
          });
        }
      }
    }
    onContinue('default');
  }

  function toggleExpandTemplateCard(templateId: string) {
    const updatedExpandedTemplates = new Set(expandedTemplates);
    if (updatedExpandedTemplates.has(templateId)) {
      updatedExpandedTemplates.delete(templateId);
    } else {
      updatedExpandedTemplates.add(templateId);
    }
    setExpandedTemplates(updatedExpandedTemplates);
  }

  function handleSelectTemplateClick(template: AllocationsTemplate) {
    let action: string;
    if (template.id === allocationsTemplateId) {
      dpDispatch({ type: 'REMOVE_ALLOCATIONS_TEMPLATE' });
      action = 'Deselect';
    } else {
      dpDispatch({ type: 'ADD_ALLOCATIONS_TEMPLATE', template });
      action = 'Select';
    }
    amplitude().logEvent(`${action} allocations template`, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      templateId: template.id,
    });
  }

  function handleTemplateDeleted(templateId: string) {
    if (allocationsTemplateId === templateId) {
      dpDispatch({ type: 'REMOVE_ALLOCATIONS_TEMPLATE' });
    }
    setFilteredTemplates((prev) => prev?.filter((template) => template.id !== templateId));
    mutateAllocationsTemplates();
  }
  return (
    <>
      <Box position="sticky" top="0" zIndex={10} bgcolor="white">
        <ContentBox maxWidth={750}>
          <PaddedContainer>
            <Box mb={2}>
              <Typography variant="h1">Now, select an asset allocation template.</Typography>
            </Box>
            <ExplainerText>
              We&apos;ll build a target portfolio using the risk tolerance and asset classes
              configured below.
            </ExplainerText>
          </PaddedContainer>
        </ContentBox>
        <TemplatesSearchAndFilterBar<AllocationsTemplate>
          allTemplates={allAllocationsTemplates ?? []}
          currentUserId={currentUserId}
          filteredTemplates={filteredTemplates ?? []}
          onFilteredTemplatesUpdated={setFilteredTemplates}
        />
      </Box>
      <form onSubmit={handleSubmit}>
        <BalancedPaddedContainer>
          <Grid container spacing={3}>
            {filteredTemplates == null ? (
              <TemplateSkeletons />
            ) : (
              <>
                {filteredTemplates.map((t) => (
                  <Grid key={t.id} item lg={4} xs={12}>
                    <Box position="relative" height="100%">
                      <TemplateCard
                        createdAt={t.createdAt}
                        creatorFirstName={t.user.firstName}
                        creatorLastName={t.user.lastName}
                        id={t.id}
                        name={t.name}
                        selected={t.id === allocationsTemplateId}
                        onSelect={() => handleSelectTemplateClick(t)}
                        disabled={
                          existingPortfolio !== 'sample-portfolio' &&
                          !existingPortfolio?.taxable &&
                          !!t.allocations['FIXED_INCOME/DOMESTIC/MUNICIPAL']
                        }
                        variant="allocation"
                        onClickDelete={() =>
                          setDeleteTemplateModalSettings({
                            open: true,
                            templateId: t.id,
                            templateName: t.name,
                          })
                        }
                        onClickRename={() =>
                          setRenameTemplateModalSettings({ open: true, templateId: t.id })
                        }
                      >
                        {getTemplateCardSectionsForAllocations(
                          t.allocations,
                          t.id,
                          t.tiltSelection
                        )}
                        <Box
                          border={1}
                          borderBottom={0}
                          borderColor="grey.300"
                          borderLeft={0}
                          borderRight={0}
                          bottom={0}
                          left={0}
                          paddingLeft={1}
                          position="absolute"
                          width="100%"
                        >
                          <Button
                            color="secondary"
                            endIcon={
                              expandedTemplates.has(t.id) ? (
                                <ChevronUpIcon color="gray" style={{ fontSize: 16 }} />
                              ) : (
                                <ChevronDownIcon color="gray" style={{ fontSize: 16 }} />
                              )
                            }
                            variant="text"
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
                            onClick={() => toggleExpandTemplateCard(t.id)}
                          >
                            <Typography color="textSecondary" variant="body2">
                              View all
                            </Typography>
                          </Button>
                        </Box>
                      </TemplateCard>
                    </Box>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </BalancedPaddedContainer>
        <StickyBottomActionContainer>
          <Box display="flex" justifyContent="space-between">
            <BackButton onClick={() => onBack()} />
            <Button
              color="primary"
              disabled={allocationsTemplateId == null}
              type="submit"
              variant="contained"
            >
              Continue
            </Button>
          </Box>
        </StickyBottomActionContainer>
      </form>
      <DeleteTemplateModal
        variant="allocation"
        onTemplateDeleted={() => handleTemplateDeleted(deleteTemplateModalSettings.templateId)}
        open={deleteTemplateModalSettings.open}
        onClose={() => setDeleteTemplateModalSettings(DELETE_TEMPLATE_MODAL_CLOSED)}
        templateId={deleteTemplateModalSettings.templateId}
        templateName={deleteTemplateModalSettings.templateName}
        deleteTemplate={deleteTemplate}
        eventCategory={EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION}
      />
      <RenameTemplateModal
        variant="allocation"
        open={renameTempalateModalSettings.open}
        templateId={renameTempalateModalSettings.templateId}
        onClose={() => setRenameTemplateModalSettings(RENAME_TEMPLATE_MODAL_CLOSED)}
        renameTemplate={renameTemplate}
        isNameValid={isNameValid}
        isTemplateNameTaken={isTemplateNameTaken}
        onTemplateRenamed={() => mutateAllocationsTemplates}
      />
    </>
  );
}
