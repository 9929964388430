import { Translations } from '../constants';

const options = {
  en: 'enUS', // NOTE(whuang): Needed for Safari.
  'en-US': 'enUS',
};

const translate = (locale: string, key: string) => {
  const [p, t] = key.split('.');
  const targetLocale = options[locale] || options.en;

  return Translations[targetLocale][p][t];
};

export default translate;
