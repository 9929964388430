import { Box, Button, Card, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { find, reduce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { withStyles } from 'tss-react/mui';

import { ValueContainerProps, components } from 'react-select';
import { AssetClassKey, Feature } from 'vise-types/pce2_instrument';
import { getNumSymbolsPerAssetClass } from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useViseManagedValue from '~/hooks/useViseManagedValue';
import { ReactComponent as AlertIcon } from '~/static/images/icons/alert-circle-outline.svg';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import Banner from '~/synth/Banner';
import CardHeaderBase from '~/synth/CardHeader';
import PopoverCard from '~/synth/PopoverCard';
import PopoverLink from '~/synth/PopoverLink';
import PopoverTrigger from '~/synth/PopoverTrigger';
import ViseBadge from '~/synth/ViseBadge';
import Select from '~/synth/inputs/Select';
import amplitude from '~/utils/amplitude';
import {
  ASSET_CLASSES_WITH_VISE_SINGLE_SECURITY_STRATEGY_TO_LABEL_MAP,
  ASSET_CLASS_TO_LABEL_MAP,
  SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS,
} from '../../Constants';
import ReturnToSummary from '../../ReturnToSummary';
import { ScreenProps } from '../../Types';
import {
  assetClassIsAboveSingleSecurityThreshold,
  fillDefaultsInPartialConstructionInfo,
  formatPce1CashConcentrationLimit,
  shouldShowActiveTiltsScreen,
} from '../../utils';
import { ActionFooter, BackButton, ContentBox } from '../components';

type VehicleOptionType = {
  label: string;
  value: 'etfExclusive' | 'singleSecurity';
  assetClassKey: string;
};

type MinSymbolOptionType = {
  label: string;
  value: 'viseRecommended' | 'minSymbol';
};

const OverflowCard = withStyles(Card, (theme) => ({
  root: {
    overflow: 'visible',
    boxShadow: 'none',
    border: `solid 1px ${theme.palette.grey[200]}`,
  },
}));

const CardHeader = withStyles(CardHeaderBase, (theme) => ({
  root: {
    paddingRight: theme.spacing(3),
  },
}));

const ETFValueContainer: React.ComponentType<
  ValueContainerProps<{ label: string; value: string; assetClassKey: string }>
> = ({ children, ...props }) => {
  const value = props.getValue();
  // The children must be structured like this. The children prop must be a tuple of label/placeholder
  // and input or bluring won't work correctly.
  return (
    <components.ValueContainer {...props}>
      {value && value[0].value === 'singleSecurity' && children ? (
        <>
          <Box display="flex">
            <Tooltip
              title={
                ASSET_CLASSES_WITH_VISE_SINGLE_SECURITY_STRATEGY_TO_LABEL_MAP.get(
                  value[0].assetClassKey
                ) ?? ''
              }
            >
              <ViseBadge mr={1} />
            </Tooltip>
            {value[0].label}
          </Box>
          {children[1]}
        </>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};

export default function TickerNumberScreen({
  draftPortfolio,
  onContinue,
  dpDispatch,
  onBack,
}: ScreenProps) {
  const theme = useTheme();
  const { data: featureFlags } = useFeatureFlags();
  useEffect(() => {
    amplitude().logEvent('Impression - Asset Classes', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);
  const { constructionInfo, accountSize, lockedPositions } = draftPortfolio;

  const targetCashFraction = parseFloat(
    formatPce1CashConcentrationLimit(
      constructionInfo.concentrationLimits?.find(
        (concentrationLimit) => concentrationLimit.asset_class === 'Cash'
      )
    )
  );

  const { data: viseManagedValueResponse } = useViseManagedValue(
    constructionInfo.existingPortfolio === 'sample-portfolio'
      ? null
      : constructionInfo.existingPortfolio?.accountNumber,
    constructionInfo.existingPortfolio === 'sample-portfolio'
      ? null
      : constructionInfo.existingPortfolio?.custodianKey,
    lockedPositions,
    isNaN(targetCashFraction) ? undefined : targetCashFraction / 100
  );

  let viseManagedValue = viseManagedValueResponse;
  if (
    constructionInfo.existingPortfolio === 'sample-portfolio' &&
    constructionInfo.initialValue != null
  ) {
    viseManagedValue =
      constructionInfo.initialValue *
      (1 - (isNaN(targetCashFraction) ? 0 : targetCashFraction / 100));
  }

  const { etfExclusive, focus, etfExclusiveAssetClasses, minSymbolAssetClasses } = constructionInfo;
  let exclusions: AssetClassKey[] | undefined | null;

  if ('assetClassConcentrationLimits' in constructionInfo) {
    exclusions = constructionInfo.assetClassConcentrationLimits?.exclusions;
  }

  const showActiveTiltsScreen = shouldShowActiveTiltsScreen({
    accountSize,
    etfExclusive,
    exclusions,
    focus,
    allocationTemplate: draftPortfolio.allocationTemplate,
  });

  useEffect(() => {
    if (!showActiveTiltsScreen && !draftPortfolio.allocationTemplate?.tiltSelection) {
      dpDispatch({ type: 'PCE2_UNSET_ACTIVE_TILT' });
    }
  }, [showActiveTiltsScreen, dpDispatch, draftPortfolio.allocationTemplate?.tiltSelection]);

  const enqueueCoachmark = useEnqueueCoachmark();

  const [numSymbolsPerAssetClass, setNumSymbolsPerAssetClass] = useState<
    { [key in AssetClassKey]?: number } | undefined
  >();
  useEffect(() => {
    if (featureFlags?.disable_hold_on_custom_number_of_tickers !== 'on') {
      return;
    }
    const account = draftPortfolio.constructionInfo.existingPortfolio;
    getNumSymbolsPerAssetClass({
      accountId: !account || account === 'sample-portfolio' ? undefined : account.id,
      constructionInfo: fillDefaultsInPartialConstructionInfo(draftPortfolio.constructionInfo),
      lockedPositions: draftPortfolio.lockedPositions,
    })
      .then((res) => setNumSymbolsPerAssetClass(res.data))
      .catch(() => {
        setNumSymbolsPerAssetClass(undefined);
        enqueueCoachmark({
          title: 'Could not provide an estimated number of tickers.',
          severity: 'info',
        });
      });
  }, [
    draftPortfolio.constructionInfo,
    draftPortfolio.lockedPositions,
    enqueueCoachmark,
    featureFlags,
  ]);

  let targetNumSymbols;
  if (numSymbolsPerAssetClass) {
    targetNumSymbols = reduce(numSymbolsPerAssetClass, (sum, num) => sum + (num || 0), 0);
  }

  const handleOnContinue = useCallback(() => {
    amplitude().logEvent('Continue to special conditions', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
    onContinue('default');
  }, [onContinue]);

  const selectValues = new Map(
    SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.map((key) => [
      key,
      find(etfExclusiveAssetClasses, (assetClass) => assetClass === key)
        ? 'etfExclusive'
        : 'singleSecurity',
    ])
  );

  const minSymbolValues = new Map(
    SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.map((key) => [
      key,
      find(minSymbolAssetClasses, (assetClass) => assetClass === key)
        ? 'minSymbol'
        : 'viseRecommended',
    ])
  );
  const singleSecurityKeys = SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.filter(
    (key) => !find(exclusions, (exclusion) => exclusion === key)
  );

  let assetClassesBelowThreshold: AssetClassKey[] = [];
  const { assetAllocation } = constructionInfo;
  if (assetAllocation) {
    assetClassesBelowThreshold = SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.filter(
      (key) =>
        viseManagedValue != null &&
        !assetClassIsAboveSingleSecurityThreshold(key, assetAllocation, viseManagedValue)
    );
  }

  const belowThresholdWarning = (
    <Box mt={2}>
      <Banner
        bgColor="warning.100"
        borderColor="warning.200"
        message={
          <Box display="flex">
            <Box color="warning.400" mr={1}>
              <AlertIcon height={15} width={15} />
            </Box>
            <Box color="grey.700">
              <Typography variant="body2">
                Given the current allocation, this asset class will start as ETF-only and transition
                to your selected single-security strategy once its holdings surpass $15k.
              </Typography>
            </Box>
          </Box>
        }
      />
    </Box>
  );

  const defaultBody = (
    <OverflowCard>
      <CardHeader bgcolor="grey.100">
        <Box display="flex">
          <Box flex={1}>
            <Typography variant="h4">Equities</Typography>
          </Box>
          <Box width="219px">
            <Box display="flex" alignItems="center">
              <Typography variant="h4">Investment vehicle</Typography>
              <Tooltip
                placement="top"
                title="Please note that ETFs will incur fees set by their expense ratios. Selected ETFs meet Vise’s standards for liquidity, expenses, and performance. Vise is not compensated from these or any other funds available on the platform. ETF-only portfolios may not leverage Vise’s full suite of capabilities."
              >
                <Box ml={0.5} display="flex" alignItems="center" color="grey.600">
                  <InformationCircleIcon />
                </Box>
              </Tooltip>
            </Box>
          </Box>
          <Box width="219px">
            <Box display="flex" alignItems="center">
              <Typography variant="h4">Asset concentration</Typography>
              <Tooltip
                placement="top"
                title="By default, Vise will increase the number of holdings for larger accounts to decrease tracking error. If your client prefers fewer holdings you can request a target of 50 per single-security asset class. 50 holdings is the minimum we can target while still maintaining the benefits of a diversified portfolio. Please note that number of holdings cannot be adjusted for ETF-only strategies. "
              >
                <Box ml={0.5} display="flex" alignItems="center" color="grey.600">
                  <InformationCircleIcon />
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </CardHeader>
      <Box>
        {singleSecurityKeys.map((key, idx) => {
          const features = key.split('/');
          const labels = features
            .map((feature) => ASSET_CLASS_TO_LABEL_MAP.get(feature as Feature))
            .filter((label) => label);
          const primaryLabel = labels[labels.length - 1];
          const secondaryLabel = labels[labels.length - 2];
          const options: VehicleOptionType[] = [
            { label: 'Single-securities', value: 'singleSecurity', assetClassKey: key },
            { label: 'ETF-only', value: 'etfExclusive', assetClassKey: key },
          ];
          const selectedOption =
            options.find((opt) => opt.value === selectValues.get(key)) || options[0];

          const minSymbolOptions = [
            { label: 'Vise-recommended', value: 'viseRecommended' },
            { label: 'Fewer holdings', value: 'minSymbol' },
          ];
          const selectedMinSymbolOption =
            minSymbolOptions.find((opt) => opt.value === minSymbolValues.get(key)) ||
            minSymbolOptions[0];
          return (
            <Box
              key={key}
              py={3}
              px={3}
              display="flex"
              borderBottom={
                idx === singleSecurityKeys.length - 1
                  ? undefined
                  : `dotted 1px ${theme.palette.grey[200]}`
              }
            >
              <Box flex={1}>
                <Typography variant="h4">{primaryLabel}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {secondaryLabel}
                </Typography>
              </Box>
              <Box width="438px" flexWrap="wrap" display="flex">
                <Box
                  width="219px"
                  data-testid={`select-vehicle-${key}`.replace(/\//g, '-').replace(/_/g, '-')}
                  pr={2}
                >
                  <Select
                    name={`${key.replace(/\//g, '-')}-vehicle`}
                    placeholder=""
                    options={options}
                    value={selectedOption}
                    removeIndicatorSeparator
                    components={{ ValueContainer: ETFValueContainer }}
                    onChange={(e) => {
                      const val = e as VehicleOptionType;
                      dpDispatch({
                        type: 'SET_ASSET_CLASS_ETF_EXCLUSIVE',
                        assetClass: key,
                        etfExclusive: val.value === 'etfExclusive',
                      });
                    }}
                  />
                </Box>
                <Box
                  width="219px"
                  data-testid={`min-symbol-${key}`.replace(/\//g, '-').replace(/_/g, '-')}
                >
                  <Select
                    name={`${key.replace(/\//g, '-')}-min-symbol`}
                    isDisabled={selectedOption.value === 'etfExclusive'}
                    placeholder=""
                    removeIndicatorSeparator
                    options={minSymbolOptions}
                    value={selectedMinSymbolOption}
                    onChange={(e) => {
                      const val = e as MinSymbolOptionType;
                      dpDispatch({
                        type: 'SET_MIN_SYMBOL_ASSET_CLASS',
                        assetClass: key,
                        minSymbol: val.value === 'minSymbol',
                      });
                    }}
                  />
                </Box>
                {assetClassesBelowThreshold.includes(key) &&
                  selectValues.get(key) !== 'etfExclusive' &&
                  belowThresholdWarning}
              </Box>
            </Box>
          );
        })}
      </Box>
      {targetNumSymbols != null && (
        <Box padding={3} display="flex" borderTop="solid 1px" borderColor="grey.200">
          <Box flex={1} display="flex">
            <Box>
              <Typography variant="body1">Target number of holdings</Typography>
            </Box>
            <Tooltip
              placement="top"
              title="This is an estimate of the total number of individual stocks in your client’s target portfolio. Portfolios with a larger total value, higher number of legacy positions, and higher number of asset classes (especially those with single-securities enabled) will have a larger target number of holdings. In addition, taxable accounts with tax-loss harvesting enabled have a higher likelihood of drifting from this estimate. "
            >
              <Box ml={0.5} display="flex" alignItems="center" color="grey.600">
                <InformationCircleIcon />
              </Box>
            </Tooltip>
          </Box>
          <Box textAlign="right">
            <Typography variant="h4">{targetNumSymbols || 'Limit to ETFs'}</Typography>
          </Box>
        </Box>
      )}
    </OverflowCard>
  );

  const simpleInputBody = (
    <>
      <Box mb={4} display="flex" alignItems="center">
        <Typography variant="h3">Investment vehicles</Typography>
        <Tooltip
          placement="top"
          title="Please note that ETFs will incur fees set by their expense ratios. Selected ETFs meet Vise’s standards for liquidity, expenses, and performance. Vise is not compensated from these or any other funds available on the platform. ETF-only portfolios may not leverage Vise’s full suite of capabilities."
        >
          <Box ml={0.5} display="flex" alignItems="center" color="grey.600">
            <InformationCircleIcon />
          </Box>
        </Tooltip>
      </Box>
      {singleSecurityKeys.map((key, idx) => {
        const features = key.split('/');
        const labels = features
          .map((feature) => ASSET_CLASS_TO_LABEL_MAP.get(feature as Feature))
          .filter((label) => label);

        const options: VehicleOptionType[] = [
          { label: 'Single-securities', value: 'singleSecurity', assetClassKey: key },
          { label: 'ETF-only', value: 'etfExclusive', assetClassKey: key },
        ];
        const selectedOption =
          options.find((opt) => opt.value === selectValues.get(key)) || options[0];
        return (
          <Box
            pb={4}
            key={key}
            mt={3}
            borderBottom={
              idx === singleSecurityKeys.length - 1
                ? undefined
                : `solid 1px ${theme.palette.grey[200]}`
            }
          >
            <Typography variant="h4">{`${labels[labels.length - 2]} ${
              labels[labels.length - 1]
            }`}</Typography>
            <Box
              mt={0.5}
              width="100%"
              data-testid={`select-vehicle-${key}`.replace(/\//g, '-').replace(/_/g, '-')}
            >
              <Select
                name={`${key.replace(/\//g, '-')}-vehicle`}
                placeholder=""
                options={options}
                value={selectedOption}
                removeIndicatorSeparator
                components={{ ValueContainer: ETFValueContainer }}
                onChange={(e) => {
                  const val = e as VehicleOptionType;
                  dpDispatch({
                    type: 'SET_ASSET_CLASS_ETF_EXCLUSIVE',
                    assetClass: key,
                    etfExclusive: val.value === 'etfExclusive',
                  });
                }}
              />
            </Box>
            {assetClassesBelowThreshold.includes(key) &&
              selectValues.get(key) !== 'etfExclusive' &&
              belowThresholdWarning}
          </Box>
        );
      })}
    </>
  );

  const singleSecurityPopover = (
    <PopoverTrigger
      overlay={({ close }) => (
        <PopoverCard
          body={
            <>
              <p>
                Vise single security strategies are designed to provide investors with diversified
                exposure to specific asset classes using our factor-based approach and proprietary
                scaling methodology. They also provide investors with holdings-level customization
                abilities and increased tax-loss harvesting opportunities.
              </p>
              <p>
                Vise will default to investing in ETFs when the account value is too small to
                provide optimal single-security diversification.
              </p>
            </>
          }
          onClose={close}
          title="Single-security strategies"
        />
      )}
    >
      <PopoverLink>
        <Typography variant="body2" component="span">
          single-securities
        </Typography>
      </PopoverLink>
    </PopoverTrigger>
  );

  const instructions =
    featureFlags?.disable_hold_on_custom_number_of_tickers === 'on' ? (
      <Typography color="textSecondary" variant="body2">
        Configure investment vehicles and asset concentration for eligible equities below. Vise
        defaults to {singleSecurityPopover} as well as our recommended number of holdings.
      </Typography>
    ) : (
      <Typography color="textSecondary" variant="body2">
        Configure investment vehicles for eligible equities below. Vise defaults to our{' '}
        {singleSecurityPopover} strategy.
      </Typography>
    );

  return (
    <ContentBox>
      <Box maxWidth={640}>
        <Box mb={2}>
          <Typography variant="h1">
            Now, let&apos;s fine-tune your client&apos;s investment strategy.
          </Typography>
        </Box>
        <Box mb={4}>{instructions}</Box>
        {featureFlags?.disable_hold_on_custom_number_of_tickers === 'on'
          ? defaultBody
          : simpleInputBody}
      </Box>
      <ActionFooter borderTop={0} justifyContent="space-between">
        <BackButton onClick={() => onBack()} />
        <Box display="flex">
          <ReturnToSummary
            disabled={false}
            draftPortfolio={draftPortfolio}
            onReturnToSummary={() => onContinue('summary')}
            mr={1.5}
          />
          <Button color="primary" type="button" onClick={handleOnContinue} variant="contained">
            Continue
          </Button>
        </Box>
      </ActionFooter>
    </ContentBox>
  );
}
