import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import Skeleton from '~/synth/Skeleton';

export const UnavailableCellText = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.palette.grey[400]};
`;

export default function ValueCell({
  value,
  isLoading,
}: {
  value?: React.ReactNode;
  isLoading: boolean;
}) {
  let content: React.ReactNode = <UnavailableCellText>Unavailable</UnavailableCellText>;
  if (isLoading) {
    content = (
      <Box width="25%">
        <Skeleton />
      </Box>
    );
  } else if (value != null) {
    content = value;
  }
  return <Box>{content}</Box>;
}
