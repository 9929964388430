import useSWR from 'swr';
import { getClient } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getClientSwrKey = (clientId: string) => generateSwrKey(`client/${clientId}`);

const useClient = (clientId: string | undefined | null) => {
  const { data, error, isValidating, mutate } = useSWR(
    clientId ? getClientSwrKey(clientId) : null,
    clientId == null ? undefined : () => getClient(clientId)
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export default useClient;
