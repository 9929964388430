import Highcharts from 'highcharts';
import memo from 'memoize-one';
import Chart from './Chart';

const PointFormat =
  '<tr><td style="color: {series.color}">{series.name}: </td>' +
  '<td style="text-align: right"><b>{point.percentage:.2f} %</b></td></tr>';

export default class PieChart extends Chart {
  // Overriding a method from `Chart`, it's okay `this` is not referenced.
  // eslint-disable-next-line class-methods-use-this
  protected getChartOptions = memo((): Highcharts.Options => {
    return {
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          center: ['50%', '50%'],
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          size: '100%',
        },
      },
      title: {
        text: '',
      },
      tooltip: {
        footerFormat: '</table>',
        headerFormat: '<small>{point.key}</small><table>',
        pointFormat: PointFormat,
        shared: true,
        useHTML: true,
        valueDecimals: 2,
      },
    };
  });
}
