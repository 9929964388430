import React from 'react';
import styled from 'styled-components';

interface HelperTextProps {
  color?: string;
  margin?: string;
}

const StyledHelperText = styled.p<HelperTextProps>`
  color: ${({ color }) => color || 'rgba(0, 0, 0, 0.54)'};
  display: flex;
  font-size: 0.75rem;
  font-weight: 400;
  align-items: center;
  line-height: 1.5;
  margin-top: 8px;
  margin: ${({ margin }) => margin || '0'};
  min-height: 1em;
  text-align: left;
`;

export const HelperText = ({
  children,
  color,
  margin,
}: {
  children: React.ReactNode;
  color?: string;
  margin?: string;
}) => (
  <StyledHelperText color={color} margin={margin}>
    {children}
  </StyledHelperText>
);

interface PProps {
  color?: string;
  margin?: string;
  size?: string;
  weight?: number;
}

const PWrapper = styled.p<PProps>`
  color: ${({ color }) => color || 'inherit'};
  font-size: ${({ size }) => size || '16px'};
  font-weight: ${({ weight }) => weight || 'normal'};
  margin: ${({ margin }) => margin || '0'};
`;

export const P = ({
  children,
  color,
  margin,
  size,
  weight,
}: {
  children: React.ReactNode;
  color?: string;
  margin?: string;
  size?: string;
  weight?: number;
}) => (
  <PWrapper color={color} margin={margin} size={size} weight={weight}>
    {children}
  </PWrapper>
);

export const Heading = ({
  children,
  color,
  margin,
  type,
  weight,
}: {
  children: React.ReactNode;
  color?: string;
  margin?: string;
  type: string;
  weight?: number;
}) =>
  React.createElement(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styled(type as any)`
      color: ${color || 'inherit'};
      font-weight: ${weight || 'normal'};
      margin: ${margin || '0'};
    `,
    {},
    children
  );
