import { Box, Slider as MuiSlider, SliderProps as MuiSliderProps } from '@mui/material';
import React from 'react';

interface MarkLabelProps {
  label?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  textAlign?: any;
  value?: number;
}

function MarkLabel({ label, textAlign = 'center', value }: MarkLabelProps) {
  return (
    <Box textAlign={textAlign}>
      {value && <Box fontWeight={500}>{value}</Box>}
      {label == null ? null : <Box>{label}</Box>}
    </Box>
  );
}

interface MimicMuiMarkLabelProps extends Partial<MarkLabelProps> {
  left?: number;
  right?: number;
  verticalAlignment?: 'top' | 'bottom';
}

// Mimics the `MuiSlider-markLabel` component and extends its styling to enable left/right
// alignment within a container.
function MimicMuiMarkLabel({
  left,
  right,
  verticalAlignment,
  ...markLabelProps
}: MimicMuiMarkLabelProps) {
  const top = verticalAlignment === 'top' ? '-24px' : '36px';
  return (
    <div
      aria-hidden="true"
      className="MuiSlider-markLabel"
      style={{ left, right, top, transform: 'none' }}
    >
      <MarkLabel {...markLabelProps} />
    </div>
  );
}

interface Mark {
  label: React.ReactNode;
  value: number;
}

interface SliderProps extends MuiSliderProps {
  endMark?: Partial<Mark>;
  markVerticalAlignment?: MimicMuiMarkLabelProps['verticalAlignment'];
  startMark?: Partial<Mark>;
}

export default function Slider({
  endMark,
  markVerticalAlignment,
  marks,
  startMark,
  sx,
  ...rest
}: SliderProps) {
  const styledMarks: SliderProps['marks'] = Array.isArray(marks)
    ? marks.map((m) => ({ label: <MarkLabel {...m} />, value: m.value }))
    : marks;
  return (
    <Box position="relative">
      {startMark == null ? null : (
        <MimicMuiMarkLabel
          {...startMark}
          verticalAlignment={markVerticalAlignment}
          left={0}
          textAlign="left"
        />
      )}
      {endMark == null ? null : (
        <MimicMuiMarkLabel
          {...endMark}
          verticalAlignment={markVerticalAlignment}
          right={0}
          textAlign="right"
        />
      )}
      {/*
        Casting StyledValueLabel since the type declaration for the ValueLabelComponent doesn't include
        the other added props.
        The internal component this is based on was written in JS, so they didn't need to do anything to
        work around this:
        https://github.com/mui-org/material-ui/blob/56984eaabad285ba44a4026c038ca4787624c624/packages/material-ui/src/Slider/ValueLabel.js

        This is probably better fixed by a version bump at some point
      */}
      <MuiSlider
        name="VSValueLabel"
        {...rest}
        sx={{
          '& .MuiSlider-valueLabel': {
            color: 'black',
            fontWeight: 500,
            position: 'absolute',
            transition: 'transform 0.25s',
            top: -28,
            transformOrigin: 'bottom center',
            transform: 'scale(0)',
            zIndex: 1,
          },
          ...sx,
        }}
        marks={styledMarks}
      />
    </Box>
  );
}

// [0]: https://github.com/mui-org/material-ui/blob/56984eaabad285ba44a4026c038ca4787624c624/packages/material-ui/src/Slider/ValueLabel.js
