import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';

const ContentContainer = styled(Box)`
  flex-grow: 1;
  height: 100vh;
  margin-left: ${({ open, noSidebar }: { open: boolean; noSidebar: boolean }) => {
    if (noSidebar) return '0';
    return open ? '230px' : '57px';
  }};
  overflow-y: scroll;
  padding-top: 56px;
  transition: ${({ open }: { open: boolean }) =>
    open
      ? 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
      : 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'};
`;

export const Content: React.FunctionComponent<
  React.PropsWithChildren<{ open?: boolean; noSidebar?: boolean }>
> = ({ children, open = true, noSidebar = false }) => {
  const bgWhiteMatch = useRouteMatch({
    // Use an all-white background for all paths in this array
    path: [
      '/accounts/:accountId/proposals',
      '/households/:householdId/proposals',
      '/secure/households/:householdId/manage',
      '/secure/households/:householdId/client/:clientId/manage',
    ],
  });
  return (
    <ContentContainer
      bgcolor={bgWhiteMatch == null ? 'grey.100' : '#ffffff'}
      id="content-container"
      open={open}
      noSidebar={noSidebar}
    >
      {children}
    </ContentContainer>
  );
};

const ContainerContainer = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding: ${({ theme }) => theme.spacing(3)};
`;

// Deprecated. Use PageContentContainer and/or a custom container instead.
export const Container: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => (
  <ContainerContainer>{children}</ContainerContainer>
);
