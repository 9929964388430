import { State } from './BondPortfolioState';

export const routeUrls = {
  SELECT_CLIENT: '/secure/bond-portfolio-creator/select-client',
  TAXES: '/secure/bond-portfolio-creator/taxes',
  BUILD: '/secure/bond-portfolio-creator/portfolio-build',
  SUMMARY: '/secure/bond-portfolio-creator/summary',
} as const;

export const steps = (state: State) =>
  [
    { label: 'Client Details', route: routeUrls.SELECT_CLIENT },
    ...(state.account?.taxable || state.cashValue
      ? [{ label: 'Taxes', route: routeUrls.TAXES }]
      : []),
    { label: 'Portfolio Design', route: routeUrls.BUILD },
    { label: 'Summary', route: routeUrls.SUMMARY },
  ] as const;
