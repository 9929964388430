import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  DialogActions,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField as MuiTextField,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { tokens } from '@vise_inc/ds-vise';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Account } from 'vise-types/portfolio';
import { TransitionAnalysis } from 'vise-types/xray';
import { previewTaxTransition } from '~/api/api';
import { InputDollar } from '~/components';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAccount from '~/hooks/useAccount';
import useAccountValue from '~/hooks/useAccountValue';
import useCapitalGains from '~/hooks/useCapitalGains';
import { useLoadPageDataErrorCoachmarkEffect } from '~/hooks/useCoachmark';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useEnqueueToast from '~/hooks/useToast';
import { Account as APIAccount, RawGetCapitalGainsResponse } from '~/models/api';
import {
  TaxTransitionDataVis,
  TaxTransitionDisclaimer,
} from '~/routes/Portfolio/XrayInsights/components/UtilComponents';
import { TaxDisclosureExpansion } from '~/routes/Portfolio/components/DisclosureExpansion';
import { getOnboardedDate } from '~/routes/Portfolio/portfolioUtil';
import balancedTransitionIconActive from '~/static/images/PortfolioCreator2/balanced-transition-active.svg';
import balancedTransitionIcon from '~/static/images/PortfolioCreator2/balanced-transition.svg';
import taxSavingsIconActive from '~/static/images/PortfolioCreator2/tax-savings-active.svg';
import taxSavingsIcon from '~/static/images/PortfolioCreator2/tax-savings.svg';
import transitionSpeedIconActive from '~/static/images/PortfolioCreator2/transition-speed-active.svg';
import transitionSpeedIcon from '~/static/images/PortfolioCreator2/transition-speed.svg';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { ReactComponent as QuestionIcon } from '~/static/images/icons/question-mark-circle.svg';
import { ReactComponent as RefreshIcon } from '~/static/images/icons/refresh.svg';
import { ReactComponent as XCircle } from '~/static/images/icons/x-circle.svg';
import Calculate from '~/static/images/illustrations/synchronize.svg';
import HelpButton from '~/synth/Button';
import DialogTitle from '~/synth/DialogTitle';
import Skeleton from '~/synth/Skeleton';
import { TextHighlightTag } from '~/synth/Tag';
import TextField from '~/synth/TextField';
import UnderlineTooltipIndicator from '~/synth/UnderlineTooltipIndicator';
import { WithLoader } from '~/utils';
import amplitude from '~/utils/amplitude';
import { ACCOUNTING_FORMATTER, capitalizeWord, formatCurrency } from '~/utils/format';
import ContainedSelectorCheckbox from '../../../synth/ContainedSelectorCheckbox';
import ReturnToSummary from '../ReturnToSummary';
import { Pce2DraftPortfolio, ScreenProps } from '../Types';
import { shouldShowCapitalLossesAndGainsScreen } from '../utils';
import { ActionFooter, BackButton, ContentBox } from './components';
import HelpDialog from './components/HelpDialog';

const HelpModalFormControl = withStyles(FormControlLabel, () => ({
  root: {
    alignItems: 'start',
  },
}));

type CapitalGainsType = 'short-term' | 'long-term';
type CapitalGainsLimitOptionType = 'yes' | 'no';

const StyledCapitalGainsOptionsSection = styled(Box)(({ theme }) => ({
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.grey[200],
  marginBottom: '2rem',
  paddingBottom: '2rem',
}));

function OptionalTooltip({
  tooltipEnabled,
  children,
  ...tooltipProps
}: { tooltipEnabled: boolean; children: React.ReactElement } & TooltipProps) {
  if (!tooltipEnabled) {
    return <>{children}</>;
  }
  return <Tooltip {...tooltipProps}>{children}</Tooltip>;
}

function TaxRateInput({
  value,
  onChange,
  error,
  disabled,
  errorText,
}: {
  value: number;
  onChange?: (event) => void;
  error?: boolean;
  disabled?: boolean;
  errorText?: string;
}) {
  return (
    <>
      <NumberFormat
        style={{ width: '50%' }}
        customInput={MuiTextField}
        fixedDecimalScale
        decimalScale={1}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          inputProps: { min: 0, max: 100 },
        }}
        value={value}
        error={error}
        onChange={onChange}
        disabled={disabled}
      />
      {error && errorText ? (
        <Box
          display="flex"
          color="error.main"
          mt={0.5}
          justifyContent="end"
          alignItems="center"
          className="tax-rate-error"
        >
          <XCircle width={12} />
          <Typography variant="caption" sx={{ marginLeft: '4px' }}>
            {errorText}
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

function CapitalGainSetting({
  capitalGainsType,
  isCapGainsDataLoading,
  account,
  maximumGainsAmount,
  realizedGains,
  shouldLimitGains,
  shouldLimitSmallestAmount,
  showEmptyInputError,
  onMaximumGainsAmountChanged,
  onShouldLimitGainsChanged,
  onShouldLimitSmallestAmountChanged,
  onShouldShowEmptyInputError,
  curMaximumGainsAmount,
  setCurMaximumGainsAmount,
}: {
  capitalGainsType: CapitalGainsType;
  isCapGainsDataLoading: boolean;
  account: Account | undefined;
  maximumGainsAmount: number | null;
  realizedGains: number;
  shouldLimitGains: boolean;
  shouldLimitSmallestAmount: boolean;
  showEmptyInputError: boolean;
  onMaximumGainsAmountChanged: (newMaximumGainsAmount: number | null) => void;
  onShouldLimitGainsChanged: (shouldLimitGains: boolean) => void;
  onShouldLimitSmallestAmountChanged: (shouldLimitSmallestAmount: boolean) => void;
  onShouldShowEmptyInputError: () => void;
  curMaximumGainsAmount: number | null;
  setCurMaximumGainsAmount: (amount: number | null) => void;
}): ReactElement {
  const [errorText, setErrorText] = useState<string | null>(null);

  const onboardedDate = account != null ? moment(getOnboardedDate(account)) : null;

  const isPortfolioNewThisYear =
    onboardedDate == null ? false : onboardedDate >= moment().startOf('year');

  useEffect(() => {
    if (shouldLimitSmallestAmount) {
      setCurMaximumGainsAmount(realizedGains);
      setErrorText(null);
    }
  }, [shouldLimitSmallestAmount, realizedGains, setCurMaximumGainsAmount]);

  function validateAndSetErrorMessage(): void {
    let newErrorText: string | null = null;
    if (curMaximumGainsAmount == null && showEmptyInputError)
      newErrorText = 'Please enter a maximum amount.';
    if (curMaximumGainsAmount != null && curMaximumGainsAmount < realizedGains) {
      newErrorText = `The limit must be greater than the current ${capitalGainsType} realized gain of ${formatCurrency(
        realizedGains
      )}`;
    }
    setErrorText(newErrorText);
  }

  function checkForErrorsAndUpdateMaximumGainsAmount(): void {
    validateAndSetErrorMessage();
    if (errorText == null) {
      onMaximumGainsAmountChanged(curMaximumGainsAmount);
    } else {
      onMaximumGainsAmountChanged(null);
    }
  }

  useEffect(validateAndSetErrorMessage, [
    capitalGainsType,
    curMaximumGainsAmount,
    realizedGains,
    showEmptyInputError,
  ]);

  function onShouldLimitGainsOptionChanged(
    _e: React.ChangeEvent<HTMLInputElement>,
    value: CapitalGainsLimitOptionType
  ): void {
    const shouldLimitGains = value === 'yes';
    onShouldLimitGainsChanged(shouldLimitGains);
    if (shouldLimitGains) {
      checkForErrorsAndUpdateMaximumGainsAmount();
    } else {
      onMaximumGainsAmountChanged(null);
    }
  }

  function onMaximumGainsAmountInputChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = Number.parseFloat(event.target.value);
    setCurMaximumGainsAmount(Number.isNaN(value) ? null : value);
  }

  function onLimitSmallestAmountCheckboxClicked(
    _e: React.ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ): void {
    onShouldLimitSmallestAmountChanged(isChecked);
  }

  // Note: time zone is not taken into consideration. Implications on December 31st are unclear,
  // but U.S. markets are closed, and this is a very unlikely edge case.
  const currentYear = moment().year();

  // Expand this up into 4 individual strings for eventual localizability
  let realizedGainsText = '';
  if (capitalGainsType === 'short-term') {
    realizedGainsText = isPortfolioNewThisYear
      ? `Net short-term realized capital gains (since ${onboardedDate?.format('YYYY-MM-DD')})`
      : 'Net short-term realized capital gains (YTD)';
  } else {
    realizedGainsText = isPortfolioNewThisYear
      ? `Net long-term realized capital gains (since ${onboardedDate?.format('YYYY-MM-DD')})`
      : 'Net long-term realized capital gains (YTD)';
  }

  return (
    <Box data-testid={`${capitalGainsType}-capital-gains-limits`}>
      <Box mb={-1}>
        <FormLabel component="legend">{`${capitalizeWord(
          capitalGainsType
        )} capital gains`}</FormLabel>
        <RadioGroup
          value={shouldLimitGains ? 'yes' : 'no'}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(_e: ChangeEvent<HTMLInputElement>, value: C... Remove this comment to see the full error message
          onChange={onShouldLimitGainsOptionChanged}
        >
          <FormControlLabel
            value="no"
            control={<Radio />}
            label={`Do not limit ${capitalGainsType} capital gains`}
          />
          <FormControlLabel value="yes" control={<Radio />} label="Set a maximum amount" />
        </RadioGroup>
      </Box>
      <Collapse in={shouldLimitGains} mountOnEnter unmountOnExit>
        <Box mt={2}>
          <TextField
            InputProps={{
              id: `${capitalGainsType}-maximum-amount-input`,
              inputComponent: InputDollar,
              startAdornment:
                curMaximumGainsAmount == null ? (
                  <InputAdornment position="start">$</InputAdornment>
                ) : null,
            }}
            disabled={isCapGainsDataLoading || shouldLimitSmallestAmount}
            error={errorText != null}
            helperText={errorText}
            value={curMaximumGainsAmount}
            onBlur={() => {
              onShouldShowEmptyInputError();
              checkForErrorsAndUpdateMaximumGainsAmount();
            }}
            onChange={onMaximumGainsAmountInputChanged}
          />
          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldLimitSmallestAmount}
                  disabled={isCapGainsDataLoading}
                  onChange={onLimitSmallestAmountCheckboxClicked}
                  name="limit-smallest-amount"
                />
              }
              label={`Do not realize ${
                realizedGains > 0 ? 'additional' : 'a'
              } net ${capitalGainsType} capital gain${realizedGains > 0 ? 's' : ''} this year.`}
            />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                {realizedGainsText}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                {isCapGainsDataLoading ? (
                  <CircularProgress size={16} />
                ) : (
                  formatCurrency(realizedGains)
                )}
              </Typography>
            </Grid>
          </Grid>
          <Box mt={1.5}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography color="textSecondary" variant="body2">
                  Remaining to realize in {currentYear}
                </Typography>
              </Grid>
              <Grid item>
                {isCapGainsDataLoading ? (
                  <CircularProgress size={16} />
                ) : (
                  <Typography color="textSecondary" variant="body2">
                    {maximumGainsAmount != null
                      ? formatCurrency(maximumGainsAmount - realizedGains)
                      : '-'}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}

const Td = withStyles(TableCell, (theme) => ({
  root: { padding: '4px  0', '&:not(.gains-table-side-label)': { textAlign: 'right' } },
  head: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.25,
    color: theme.palette.grey[500],
  },
}));

const UnrealizedCapitalGainsTable = function UnrealizedCapitalGainsTable({
  gains,
}: {
  gains: RawGetCapitalGainsResponse;
}) {
  const {
    unrealizedShortTermLosses,
    unrealizedLongTermLosses,
    unrealizedShortTermGains,
    unrealizedLongTermGains,
  } = gains;
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <Td variant="head" />
            <Td variant="head">Unrealized gains</Td>
            <Td variant="head">Unrealized losses</Td>
            <Td variant="head">Net unrealized</Td>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <Td variant="head" className="gains-table-side-label">
              Short-term
            </Td>
            <Td>{formatCurrency(unrealizedShortTermGains, ACCOUNTING_FORMATTER)}</Td>
            <Td>{formatCurrency(unrealizedShortTermLosses * -1, ACCOUNTING_FORMATTER)}</Td>
            <Td>
              {formatCurrency(
                unrealizedShortTermGains - unrealizedShortTermLosses,
                ACCOUNTING_FORMATTER
              )}
            </Td>
          </TableRow>
          <TableRow>
            <Td variant="head" className="gains-table-side-label">
              Long-term
            </Td>
            <Td>{formatCurrency(unrealizedLongTermGains, ACCOUNTING_FORMATTER)}</Td>
            <Td>{formatCurrency(unrealizedLongTermLosses * -1, ACCOUNTING_FORMATTER)}</Td>
            <Td>
              {formatCurrency(
                unrealizedLongTermGains - unrealizedLongTermLosses,
                ACCOUNTING_FORMATTER
              )}
            </Td>
          </TableRow>
          <TableRow>
            <Td variant="head" className="gains-table-side-label">
              Total
            </Td>
            <Td>{formatCurrency(unrealizedLongTermGains + unrealizedShortTermGains)}</Td>
            <Td>
              {formatCurrency(
                (unrealizedLongTermLosses + unrealizedShortTermLosses) * -1,
                ACCOUNTING_FORMATTER
              )}
            </Td>
            <Td>
              {formatCurrency(
                unrealizedLongTermGains +
                  unrealizedShortTermGains -
                  unrealizedLongTermLosses -
                  unrealizedShortTermLosses,
                ACCOUNTING_FORMATTER
              )}
            </Td>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default function CapitalLossesAndGainsScreen({
  draftPortfolio,
  onBack,
  onContinue,
  dpDispatch,
}: ScreenProps): ReactElement {
  useEffect(() => {
    amplitude().logEvent('Impression - Capital Losses and Gains', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);
  const { data: featureFlags } = useFeatureFlags();
  const pce2DraftPortfolio = draftPortfolio as Pce2DraftPortfolio;
  const account = pce2DraftPortfolio.constructionInfo.existingPortfolio as APIAccount;
  const {
    autoTlh,
    capitalGainsLimits,
    longTermFederalTaxRate,
    shortTermFederalTaxRate,
    longTermStateTaxRate,
    shortTermStateTaxRate,
  } = pce2DraftPortfolio.constructionInfo;
  const { longTermGainsLimits, shortTermGainsLimits } = capitalGainsLimits;

  const [showLongTermEmptyInputError, setShowLongTermEmptyInputError] = useState(false);
  const [showShortTermEmptyInputError, setShowShortTermEmptyInputError] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [helpModalSelectedOption, setHelpModalSelectedOption] = useState<
    'TAX_SAVINGS' | 'BALANCED' | 'TRANSITION_SPEED' | null
  >(null);
  const [calculatingTaxTransition, setCalculatingTaxTransition] = useState(false);
  const enqueueToast = useEnqueueToast();
  const theme = useTheme();

  const { data: accountResponseData } = useAccount(account.id, true);

  const { data: capitalGainsData, error: capitalGainsDataError } = useCapitalGains(
    account.accountNumber,
    account.custodianKey,
    draftPortfolio.lockedPositions,
    /* includeComplianceGainsLosses  = */ true
  );
  const { data: accountValue } = useAccountValue(
    account.accountNumber,
    account.custodianKey,
    draftPortfolio.lockedPositions,
    undefined
  );

  const [transitionAnalysisData, setTransitionAnalysisData] = useState<TransitionAnalysis | null>();

  useLoadPageDataErrorCoachmarkEffect(
    capitalGainsDataError != null,
    'There was an error retrieving existing capital gains data for this page.'
  );

  function onHarvestTaxLossesCheckboxClicked(): void {
    dpDispatch({
      type: 'PCE2_SET_HARVEST_TAX_LOSSES',
      autoTlh: !autoTlh,
    });
  }

  // Long-term gains callbacks:
  function onLongTermGainsShouldLimitGainsChanged(shouldLimitGains: boolean): void {
    dpDispatch({
      type: 'PCE2_SET_LONG_TERM_GAINS_SHOULD_LIMIT_GAINS',
      shouldLimitGains,
    });
  }

  function onLongTermGainsMaximumAmountChanged(maximumAmount: number | null): void {
    dpDispatch({
      type: 'PCE2_SET_LONG_TERM_GAINS_MAXIMUM_AMOUNT',
      maximumAmount,
    });
  }

  function onLongTermGainsShouldLimitSmallestAmountChanged(
    shouldLimitSmallestAmount: boolean
  ): void {
    if (capitalGainsData != null) {
      dpDispatch({
        type: 'PCE2_SET_LONG_TERM_GAINS_SHOULD_LIMIT_SMALLEST_AMOUNT',
        shouldLimitSmallestAmount,
      });
      onLongTermGainsMaximumAmountChanged(capitalGainsData.ytdLongTermGainLoss);
    }
  }

  // Short-term gains callbacks:
  function onShortTermGainsShouldLimitGainsChanged(shouldLimitGains: boolean): void {
    dpDispatch({
      type: 'PCE2_SET_SHORT_TERM_GAINS_SHOULD_LIMIT_GAINS',
      shouldLimitGains,
    });
  }

  function onShortTermGainsMaximumAmountChanged(maximumAmount: number | null): void {
    dpDispatch({
      type: 'PCE2_SET_SHORT_TERM_GAINS_MAXIMUM_AMOUNT',
      maximumAmount,
    });
  }

  function onShortTermGainsShouldLimitSmallestAmountChanged(
    shouldLimitSmallestAmount: boolean
  ): void {
    if (capitalGainsData != null) {
      dpDispatch({
        type: 'PCE2_SET_SHORT_TERM_GAINS_SHOULD_LIMIT_SMALLEST_AMOUNT',
        shouldLimitSmallestAmount,
      });
      onShortTermGainsMaximumAmountChanged(capitalGainsData.ytdShortTermGainLoss);
    }
  }

  const [longTermGainsFormLimit, setLongTermGainsFormLimit] = useState(
    longTermGainsLimits.maximumAmount
  );
  const [shortTermGainsFormLimit, setShortTermGainsFormLimit] = useState(
    shortTermGainsLimits.maximumAmount
  );

  const closeHelpModal = () => {
    setHelpModalOpen(false);
    setHelpModalSelectedOption(null);
  };

  let longTermGainsModalDisplayValue = '-';
  if (accountValue && helpModalSelectedOption === 'TAX_SAVINGS') {
    longTermGainsModalDisplayValue = formatCurrency(accountValue.totalValue * 0.03);
  } else if (accountValue && helpModalSelectedOption === 'BALANCED') {
    longTermGainsModalDisplayValue = formatCurrency(accountValue.totalValue * 0.05);
  } else if (helpModalSelectedOption === 'TRANSITION_SPEED') {
    longTermGainsModalDisplayValue = 'None';
  }

  let shortTermGainsModalTipText =
    'In order to avoid a less favorable tax rate, Vise aims to realize no additional short-term capital gains by default.';
  let shortTermGainsDisplayValue = '$0.00';
  if (capitalGainsData?.ytdShortTermGainLoss) {
    shortTermGainsModalTipText =
      'This account has realized short-term gains this year. In order to avoid a less favorable tax rate, Vise aims to realize no additional short-term capital gains by default.';
    shortTermGainsDisplayValue = formatCurrency(capitalGainsData.ytdShortTermGainLoss);
  }
  const shortTermGainsModalDisplayValue = (
    <Tooltip title={shortTermGainsModalTipText} placement="top">
      <Box display="flex" alignItems="center">
        <Box position="relative" top="2px">
          <InformationCircleIcon color={theme.palette.grey[600]} height="16px" width="16px" />
        </Box>
        <Box ml={1}>
          <Typography variant="body1">{shortTermGainsDisplayValue} per year</Typography>
        </Box>
      </Box>
    </Tooltip>
  );

  const applyHelpModalOption = () => {
    if (!accountValue || !helpModalSelectedOption) {
      return;
    }
    onShortTermGainsShouldLimitSmallestAmountChanged(true);
    onShortTermGainsShouldLimitGainsChanged(true);
    if (helpModalSelectedOption === 'TAX_SAVINGS') {
      const value = accountValue.totalValue * 0.03;
      onLongTermGainsShouldLimitGainsChanged(true);
      setLongTermGainsFormLimit(value);
      dpDispatch({ type: 'PCE2_SET_LONG_TERM_GAINS_MAXIMUM_AMOUNT', maximumAmount: value });
    } else if (helpModalSelectedOption === 'BALANCED') {
      const value = accountValue.totalValue * 0.05;
      onLongTermGainsShouldLimitGainsChanged(true);
      setLongTermGainsFormLimit(value);
      dpDispatch({ type: 'PCE2_SET_LONG_TERM_GAINS_MAXIMUM_AMOUNT', maximumAmount: value });
    } else {
      onLongTermGainsShouldLimitGainsChanged(false);
    }
    closeHelpModal();
  };

  // Screen continue handler:
  function handleSubmit(event: React.FormEvent): void {
    event.preventDefault();
    if (!autoTlh) {
      amplitude().logEvent('Continue to summary', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      });
      onContinue();
      return;
    }
    const longTermLimitsNotFilled =
      longTermGainsLimits.shouldLimitGains && longTermGainsLimits.maximumAmount == null;
    const shortTermLimitsNotFilled =
      shortTermGainsLimits.shouldLimitGains && shortTermGainsLimits.maximumAmount == null;

    const validTaxRates =
      longTermFederalTaxRate != null &&
      shortTermFederalTaxRate != null &&
      longTermStateTaxRate != null &&
      shortTermStateTaxRate != null &&
      longTermFederalTaxRate + longTermStateTaxRate <= 1 &&
      shortTermFederalTaxRate + shortTermStateTaxRate <= 1;
    if (longTermLimitsNotFilled || shortTermLimitsNotFilled || !validTaxRates) {
      enqueueToast({ title: 'Please fix the errors before continuing.', severity: 'error' });
    }

    if (longTermLimitsNotFilled) setShowLongTermEmptyInputError(true);
    if (shortTermLimitsNotFilled) setShowShortTermEmptyInputError(true);

    if (longTermLimitsNotFilled)
      document.getElementById('long-term-maximum-amount-input')?.scrollIntoView();
    else if (shortTermLimitsNotFilled)
      document.getElementById('short-term-maximum-amount-input')?.scrollIntoView();
    // TODO(rteammco): handle event logging as needed
    else if (!validTaxRates) {
      document.getElementsByClassName('tax-rate-error')?.[0]?.scrollIntoView();
    } else {
      amplitude().logEvent('Continue to summary', {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      });
      onContinue();
    }
  }

  async function handleClickCalculate() {
    setTransitionAnalysisData(null);
    setCalculatingTaxTransition(true);
    try {
      const data = await previewTaxTransition(
        account.id,
        draftPortfolio.lockedPositions,
        draftPortfolio.constructionInfo
      );
      setTransitionAnalysisData(data.data);
    } catch (e) {
      enqueueToast({ title: 'Error calculating tax transition', severity: 'error' });
    }
    setCalculatingTaxTransition(false);
  }

  if (!shouldShowCapitalLossesAndGainsScreen(draftPortfolio)) {
    amplitude().logEvent('shouldShowCapitalLossesAndGainsScreen was false, go to summary', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
    onContinue();
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ReactElemen... Remove this comment to see the full error message
    return null;
  }

  const taxSavingsOptionDisabled =
    !accountValue ||
    !capitalGainsData ||
    accountValue.totalValue * 0.03 < capitalGainsData.ytdLongTermGainLoss;

  const balancedOptionDisabled =
    !accountValue ||
    !capitalGainsData ||
    accountValue.totalValue * 0.05 < capitalGainsData.ytdLongTermGainLoss;

  return (
    <>
      <ContentBox>
        <Box mb={2}>
          <Typography variant="h1">Configure your tax management settings.</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
            Automated tax loss harvesting{' '}
            <Tooltip title="Please be advised that all sales placed by Vise are disposed of via Versus Purchase Tax Lot. If your clients account has elected average cost disposition method for mutual funds Vise may breach your selected tax budget. If you have any questions please reach out to clientservice@vise.com">
              <Box ml={0.5} display="flex" alignItems="cente">
                <InformationCircleIcon />
              </Box>
            </Tooltip>
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <ContainedSelectorCheckbox
            checked={autoTlh ?? true}
            description="Vise looks for opportunities to harvest losses in line with this portfolio’s goals."
            disabled={false}
            label="Automatically harvest tax losses for this account"
            name="tax_loss"
            onClick={onHarvestTaxLossesCheckboxClicked}
          />
          <>
            <Box mb={1.5} mt={4} display="flex" alignItems="center">
              <Typography display="inline" variant="h3">
                Set limits on capital gains
              </Typography>
              {accountValue &&
                capitalGainsData &&
                featureFlags?.enable_cap_gains_help_modal === 'on' && (
                  <Box ml={2}>
                    <HelpButton
                      onClick={() => setHelpModalOpen(true)}
                      color="primary"
                      size="small"
                      startIcon={<QuestionIcon />}
                    >
                      Assist me
                    </HelpButton>
                  </Box>
                )}
            </Box>
            <StyledCapitalGainsOptionsSection>
              <Typography color="textSecondary" paragraph>
                You can specify a maximum amount of long-term and short-term capital gains that Vise
                can realize over a calendar year. These limits may be exceeded in certain
                situations. Note that unrealized gains amounts are for unlocked positions only.
              </Typography>
              <WithLoader
                isLoading={capitalGainsData == null}
                loader={<Skeleton width="100%" height="10em" />}
              >
                {capitalGainsData != null ? (
                  <UnrealizedCapitalGainsTable gains={capitalGainsData} />
                ) : null}
              </WithLoader>
            </StyledCapitalGainsOptionsSection>
            <StyledCapitalGainsOptionsSection>
              <CapitalGainSetting
                capitalGainsType="long-term"
                isCapGainsDataLoading={capitalGainsData == null}
                account={accountResponseData?.data}
                maximumGainsAmount={longTermGainsLimits.maximumAmount}
                realizedGains={capitalGainsData?.ytdLongTermGainLoss ?? 0}
                shouldLimitGains={longTermGainsLimits.shouldLimitGains}
                shouldLimitSmallestAmount={longTermGainsLimits.shouldLimitSmallestAmount}
                showEmptyInputError={showLongTermEmptyInputError}
                onMaximumGainsAmountChanged={onLongTermGainsMaximumAmountChanged}
                onShouldLimitGainsChanged={onLongTermGainsShouldLimitGainsChanged}
                onShouldLimitSmallestAmountChanged={onLongTermGainsShouldLimitSmallestAmountChanged}
                onShouldShowEmptyInputError={() => setShowLongTermEmptyInputError(true)}
                curMaximumGainsAmount={longTermGainsFormLimit}
                setCurMaximumGainsAmount={setLongTermGainsFormLimit}
              />
            </StyledCapitalGainsOptionsSection>
            <StyledCapitalGainsOptionsSection>
              <CapitalGainSetting
                capitalGainsType="short-term"
                isCapGainsDataLoading={capitalGainsData == null}
                account={accountResponseData?.data}
                maximumGainsAmount={shortTermGainsLimits.maximumAmount}
                realizedGains={capitalGainsData?.ytdShortTermGainLoss ?? 0}
                shouldLimitGains={shortTermGainsLimits.shouldLimitGains}
                shouldLimitSmallestAmount={shortTermGainsLimits.shouldLimitSmallestAmount}
                showEmptyInputError={showShortTermEmptyInputError}
                onMaximumGainsAmountChanged={onShortTermGainsMaximumAmountChanged}
                onShouldLimitGainsChanged={onShortTermGainsShouldLimitGainsChanged}
                onShouldLimitSmallestAmountChanged={
                  onShortTermGainsShouldLimitSmallestAmountChanged
                }
                onShouldShowEmptyInputError={() => setShowShortTermEmptyInputError(true)}
                curMaximumGainsAmount={shortTermGainsFormLimit}
                setCurMaximumGainsAmount={setShortTermGainsFormLimit}
              />
            </StyledCapitalGainsOptionsSection>
            {account.accountDataSource === 'XRAY' && featureFlags?.enable_tax_rates === 'on' ? (
              <StyledCapitalGainsOptionsSection>
                <Box display="flex" alignItems="center">
                  <Typography variant="h4">Tax rates</Typography>
                  <TextHighlightTag ml={1}>
                    <Tooltip title="Tax Transition Settings are optional.Vise will default to Vise tax rates if inputs aren't provided.">
                      <UnderlineTooltipIndicator variant="body1">
                        Optional
                      </UnderlineTooltipIndicator>
                    </Tooltip>
                  </TextHighlightTag>
                </Box>
                <Box my={1.5}>
                  <Typography variant="body1" color="textSecondary">
                    Input your client&apos;s capital gains tax rates. If you need help determining
                    these values visit{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.nerdwallet.com/article/taxes/capital-gains-tax-rates"
                    >
                      www.nerdwallet.com
                    </a>
                  </Typography>
                </Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <Td variant="head" />
                      <Td variant="head">Long-term</Td>
                      <Td variant="head">Short-term</Td>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <Td variant="head" className="gains-table-side-label">
                        Federal
                      </Td>
                      <Td>
                        <TaxRateInput
                          value={longTermFederalTaxRate ? longTermFederalTaxRate * 100 : 0}
                          error={Boolean(
                            longTermFederalTaxRate &&
                              (longTermFederalTaxRate > 1 || longTermFederalTaxRate < 0)
                          )}
                          onChange={(event) =>
                            dpDispatch({
                              type: 'SET_LT_FEDERAL_TAX_RATE',
                              longTermFederalTaxRate: event.target.value
                                ? Number.parseFloat(event.target.value) / 100
                                : 0,
                            })
                          }
                        />
                      </Td>
                      <Td>
                        <TaxRateInput
                          value={shortTermFederalTaxRate ? shortTermFederalTaxRate * 100 : 0}
                          error={Boolean(
                            shortTermFederalTaxRate &&
                              (shortTermFederalTaxRate > 1 || shortTermFederalTaxRate < 0)
                          )}
                          onChange={(event) =>
                            dpDispatch({
                              type: 'SET_ST_FEDERAL_TAX_RATE',
                              shortTermFederalTaxRate: event.target.value
                                ? Number(event.target.value) / 100
                                : 0,
                            })
                          }
                        />
                      </Td>
                    </TableRow>
                    <TableRow>
                      <Td variant="head" className="gains-table-side-label">
                        State
                      </Td>
                      <Td>
                        <TaxRateInput
                          value={longTermStateTaxRate ? longTermStateTaxRate * 100 : 0}
                          error={Boolean(
                            longTermStateTaxRate &&
                              (longTermStateTaxRate > 1 || longTermStateTaxRate < 0)
                          )}
                          onChange={(event) =>
                            dpDispatch({
                              type: 'SET_LT_STATE_TAX_RATE',
                              longTermStateTaxRate: event.target.value
                                ? Number(event.target.value) / 100
                                : 0,
                            })
                          }
                        />
                      </Td>
                      <Td>
                        <TaxRateInput
                          value={shortTermStateTaxRate ? shortTermStateTaxRate * 100 : 0}
                          error={Boolean(
                            shortTermStateTaxRate &&
                              (shortTermStateTaxRate > 1 || shortTermStateTaxRate < 0)
                          )}
                          onChange={(event) =>
                            dpDispatch({
                              type: 'SET_ST_STATE_TAX_RATE',
                              shortTermStateTaxRate: event.target.value
                                ? Number(event.target.value) / 100
                                : 0,
                            })
                          }
                        />
                      </Td>
                    </TableRow>
                    <TableRow>
                      <Td variant="head" className="gains-table-side-label">
                        Total
                      </Td>
                      <Td>
                        <TaxRateInput
                          value={
                            longTermFederalTaxRate != null && longTermStateTaxRate != null
                              ? (longTermFederalTaxRate + longTermStateTaxRate) * 100
                              : 0
                          }
                          disabled
                          error={
                            longTermFederalTaxRate != null &&
                            longTermStateTaxRate != null &&
                            longTermFederalTaxRate + longTermStateTaxRate > 1
                          }
                          errorText="Combined tax rate must be ≤ 100%."
                        />
                      </Td>
                      <Td>
                        <TaxRateInput
                          value={
                            shortTermFederalTaxRate != null && shortTermStateTaxRate != null
                              ? (shortTermFederalTaxRate + shortTermStateTaxRate) * 100
                              : 0
                          }
                          disabled
                          error={
                            shortTermFederalTaxRate != null &&
                            shortTermStateTaxRate != null &&
                            shortTermFederalTaxRate + shortTermStateTaxRate > 1
                          }
                          errorText="Combined tax rate must be ≤ 100%."
                        />
                      </Td>
                    </TableRow>
                  </TableBody>
                </Table>
              </StyledCapitalGainsOptionsSection>
            ) : null}
            {featureFlags?.enable_preview_tax_transition === 'on' ? (
              <Box>
                <Box mb={3} mt={1}>
                  {transitionAnalysisData ? (
                    <>
                      <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        sx={{ marginLeft: 'auto', display: 'flex' }}
                        disabled={calculatingTaxTransition}
                        onClick={handleClickCalculate}
                        startIcon={<RefreshIcon />}
                      >
                        Recalculate
                      </Button>
                      <Box fontWeight={500} mb={1}>
                        Estimated tax transition scenarios
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="110%"
                        overflow="scroll"
                      >
                        <TaxTransitionDataVis data={transitionAnalysisData} />
                      </Box>
                      <TaxTransitionDisclaimer />
                    </>
                  ) : (
                    <div>
                      <img
                        src={Calculate}
                        alt=""
                        style={{
                          position: 'static',
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      />
                      <Box mt={3} textAlign="center" color={tokens.palette.neutralCool[800]}>
                        Create a tax transition analysis based on your tax inputs. You may have
                        several options in transitioning to a Vise managed portfolio.
                      </Box>
                    </div>
                  )}
                </Box>
                {calculatingTaxTransition && <LinearProgress variant="indeterminate" />}{' '}
                {transitionAnalysisData == null && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickCalculate}
                    disabled={calculatingTaxTransition}
                    sx={{
                      marginTop: 3,
                      position: 'static',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    Calculate
                  </Button>
                )}
              </Box>
            ) : null}
            <Box mt={4}>
              <TaxDisclosureExpansion
                minimizedTaxDisclosures="By providing a maximum capital gains amount, you constrain the capital gains of the
                account realized from trading, net of realized losses and cumulative for the current
                calendar year. A lower maximum capital gains figure may result in more tracking
                error against your target portfolio allocation and reduced portfolio turnover when
                compared to a similar portfolio with no such limitation. Capital gains limits may be
                breached if advisors make a cash distribution request, a request to sell restricted
                stocks, a request to sell funds designed for tax deferred accounts, or if compliance
                limits were breached by advisor initiated activity."
              />
            </Box>
          </>

          <ActionFooter sx={{ justifyContent: 'space-between' }}>
            <BackButton onClick={() => onBack()} />
            <Box display="flex">
              <ReturnToSummary
                draftPortfolio={draftPortfolio}
                disabled={false}
                onReturnToSummary={() => onContinue()}
                mr={1.5}
              />
              <Button color="primary" type="submit" variant="contained">
                Continue
              </Button>
            </Box>
          </ActionFooter>
        </form>
      </ContentBox>

      {helpModalOpen && (
        <HelpDialog open={helpModalOpen}>
          <DialogTitle onClose={closeHelpModal}>
            <Typography variant="h2">Choosing capital gains limits</Typography>
          </DialogTitle>
          <Box mx={3}>
            <Typography variant="body1" color="textSecondary">
              Select an option below based on your client&apos;s tax sensitivity and how much
              turnover you anticipate based on their current positions.
            </Typography>
            <RadioGroup
              name="help modal group"
              value={helpModalSelectedOption}
              onChange={(e, value) =>
                setHelpModalSelectedOption(value as 'TAX_SAVINGS' | 'BALANCED' | 'TRANSITION_SPEED')
              }
            >
              <Box my={3}>
                <OptionalTooltip
                  title="This account has already realized long-term gains in excess of this option."
                  placement="top"
                  tooltipEnabled={taxSavingsOptionDisabled}
                >
                  <HelpModalFormControl
                    value="TAX_SAVINGS"
                    control={<Radio />}
                    disabled={taxSavingsOptionDisabled}
                    label={
                      <>
                        <Box display="flex" alignItems="center" mt={1}>
                          <img
                            src={
                              helpModalSelectedOption === 'TAX_SAVINGS'
                                ? taxSavingsIconActive
                                : taxSavingsIcon
                            }
                            alt=""
                          />
                          <Box ml={1}>
                            <Typography variant="h5">Optimize for tax savings</Typography>
                          </Box>
                        </Box>
                        <Box mt={1}>
                          <Typography variant="body1" color="textSecondary">
                            Limit long-term capital gains to 3% of total portfolio value per year to
                            minimize the tax impact of transition.
                          </Typography>
                        </Box>
                      </>
                    }
                    labelPlacement="end"
                  />
                </OptionalTooltip>
              </Box>
              <Box mb={3}>
                <OptionalTooltip
                  title="This account has already realized long-term gains in excess of this option."
                  placement="top"
                  tooltipEnabled={balancedOptionDisabled}
                >
                  <HelpModalFormControl
                    value="BALANCED"
                    control={<Radio />}
                    disabled={balancedOptionDisabled}
                    label={
                      <>
                        <Box display="flex" alignItems="center" mt={1}>
                          <img
                            src={
                              helpModalSelectedOption === 'BALANCED'
                                ? balancedTransitionIconActive
                                : balancedTransitionIcon
                            }
                            alt=""
                          />
                          <Box ml={1}>
                            <Typography variant="h5">
                              Balance for tax savings and transition speed
                            </Typography>
                          </Box>
                        </Box>
                        <Box mt={1}>
                          <Typography variant="body1" color="textSecondary">
                            Limit long-term capital gains to 5% of total portfolio value per year to
                            transition a little faster.
                          </Typography>
                        </Box>
                      </>
                    }
                    labelPlacement="end"
                  />
                </OptionalTooltip>
              </Box>
              <Box mb={3}>
                <HelpModalFormControl
                  value="TRANSITION_SPEED"
                  control={<Radio />}
                  label={
                    <>
                      <Box display="flex" alignItems="center" mt={1}>
                        <img
                          src={
                            helpModalSelectedOption === 'TRANSITION_SPEED'
                              ? transitionSpeedIconActive
                              : transitionSpeedIcon
                          }
                          alt=""
                        />
                        <Box ml={1}>
                          <Typography variant="h5">Optimize for transition speed</Typography>
                        </Box>
                      </Box>
                      <Box mt={1}>
                        <Typography variant="body1" color="textSecondary">
                          Do not limit long-term gains to transition as quickly as possible.
                        </Typography>
                      </Box>
                    </>
                  }
                  labelPlacement="end"
                />
              </Box>
            </RadioGroup>
          </Box>
          <Box
            bgcolor="grey.100"
            borderTop={`solid 1px ${theme.palette.grey[200]}`}
            borderBottom={`solid 1px ${theme.palette.grey[200]}`}
            px={3}
            py={2.5}
          >
            <Box display="flex" alignItems="center" mb={1.5}>
              <Box flex={1}>
                <Typography variant="h5" color="textSecondary">
                  Long-term gains limit
                </Typography>
              </Box>
              <Typography variant="body1">{longTermGainsModalDisplayValue}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box flex={1}>
                <Typography variant="h5" color="textSecondary">
                  Short-term gains limit
                </Typography>
              </Box>
              <Typography variant="body1">{shortTermGainsModalDisplayValue}</Typography>
            </Box>
          </Box>
          <DialogActions>
            <Button onClick={closeHelpModal} type="button" variant="outlined" color="secondary">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!helpModalSelectedOption}
              onClick={applyHelpModalOption}
            >
              Apply limits
            </Button>
          </DialogActions>
        </HelpDialog>
      )}
    </>
  );
}
