import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useInsertAllocationsTemplate from '~/hooks/templates/useInsertAllocationTemplate';
import DialogTitle from '~/synth/DialogTitle';
import amplitude from '~/utils/amplitude';
import { NewTemplateNameField } from '../../templates/CommonComponents';
import { DraftPortfolioDispatch } from '../../Types';
import useAllocationsTemplateValidation from './useAllocationsTemplateValidation';

export interface SaveAllocationsTemplateModalProps {
  open: boolean;
  onClose: () => void;
  dpDispatch: DraftPortfolioDispatch;
  allocations?: { [key in AssetClassKey]?: number };
  userId?: string;
  orgId?: string;
  isCustomAllocation: boolean;
  customAllocations: AssetClassKey[];
}

export default function SaveAllocationsTemplateModal({
  open,
  allocations,
  userId,
  orgId,
  onClose,
  dpDispatch,
  isCustomAllocation,
  customAllocations,
}: SaveAllocationsTemplateModalProps) {
  const { insertTemplate } = useInsertAllocationsTemplate();

  const [templateName, setTemplateName] = useState('');

  const { isNameValid, isTemplateNameTaken } = useAllocationsTemplateValidation();

  const [helperTextState, setHelperTextState] = useState<'error' | 'success' | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkTemplateName = useCallback(
    debounce((newName: string) => {
      if (isNameValid?.(newName)) {
        setHelperTextState('success');
      } else if (isTemplateNameTaken?.(newName)) {
        setHelperTextState('error');
        amplitude().logEvent('Error - template name already taken', {
          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
        });
      }
    }, 500),
    [isNameValid, isTemplateNameTaken]
  );

  async function handleClickSaveTemplate() {
    amplitude().logEvent('Action - Confirm save allocations template', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
    if (userId != null && orgId != null && !isSubmitting && allocations != null && templateName) {
      setIsSubmitting(true);
      const insertedTemplate = await insertTemplate({
        allocations,
        userId,
        orgId,
        name: templateName,
        isCustomAllocation,
        customAllocations,
      });
      if (insertedTemplate) {
        // The allocations will be set on submit of main page
        dpDispatch({
          type: 'ADD_ALLOCATIONS_TEMPLATE',
          template: insertedTemplate,
        });
        onClose();
      }
      setTemplateName('');
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    return () => {
      checkTemplateName.cancel();
    };
  }, [checkTemplateName]);

  return (
    <Dialog open={open}>
      <DialogTitle onClose={onClose}>Name your allocation template.</DialogTitle>
      <DialogContent dividers>
        <NewTemplateNameField
          variant="allocation"
          value={templateName}
          error={helperTextState === 'error'}
          success={helperTextState === 'success'}
          onChange={(v) => {
            setHelperTextState(null);
            setTemplateName(v);
            checkTemplateName(v);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickSaveTemplate}
          data-testid="save-custom-allocation-template"
          disabled={
            isSubmitting ||
            allocations == null ||
            userId == null ||
            orgId == null ||
            !templateName ||
            helperTextState !== 'success' ||
            isNameValid == null ||
            isTemplateNameTaken == null
          }
        >
          Save and continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
