import React, { useCallback, useState } from 'react';
import { Box, Button, Dialog, DialogActions, TextField, Typography } from '@mui/material';

import DialogTitle from '~/synth/DialogTitle';
import { submitMessageFeedback, submitSessionFeedback } from '~/api/gptApi';
import EmptyBoxGraphic from '~/static/images/viseIntelligence/empty-box.svg';

const FeedbackModal = ({
  open,
  onClose,
  type,
  resourceId,
}: {
  open: boolean;
  onClose: () => void;
  type: 'message' | 'session';
  resourceId: string;
}) => {
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');

  const handleFormSubmit = useCallback(async () => {
    setSubmitDisabled(true);
    if (type === 'message') {
      await submitMessageFeedback({ messageId: resourceId, content });
    } else {
      await submitSessionFeedback({ sessionId: resourceId, content });
    }
    setSubmitDisabled(false);
    onClose();
  }, [content, resourceId, onClose, type]);
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle onClose={onClose} />
      <Box display="flex" justifyContent="center" mb={4}>
        <img src={EmptyBoxGraphic} alt="Empty Box" />
      </Box>

      <Box mx={3}>
        <Typography variant="h2" align="center" mb={2}>
          {type === 'message'
            ? 'What did you think about this response?'
            : 'What do you think about Vise Intelligence?'}
        </Typography>

        <Box mx={3} mb={2} mt={3}>
          <TextField
            fullWidth
            autoFocus
            multiline
            rows={4}
            placeholder="Type here..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
            InputProps={{
              style: { padding: '0' },
              onKeyDown: (e) => {
                if (e.key === 'Enter' && e.shiftKey) {
                  handleFormSubmit();
                }
              },
            }}
          />
        </Box>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            disabled={submitDisabled || content.length === 0}
            onClick={handleFormSubmit}
          >
            Send Feedback
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default FeedbackModal;
