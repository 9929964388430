import styled, { css } from 'styled-components';
import Multiselect from './Multiselect';

const StyledMultiselect = styled(Multiselect)`
  & .react-select__control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:not(.react-select__control--is-focused) {
      ${({ error, theme }) =>
        error
          ? css`
              border-top-color: ${theme.palette.error[500]};
              border-right-color: ${theme.palette.error[500]};
              border-bottom-color: ${theme.palette.error[500]};
            `
          : null}
    }
  }
`;

export default StyledMultiselect;
