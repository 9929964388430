import { SvgIcon, type SvgIconProps } from "@mui/material";
import React from "react";

/**
 * Exclamation icon for alerts. Note that you should use HTMLColor to style the color. Font-size should be one of small, medium, large, which correspond to 16, 20 and 24 px size Icons
 * @param props MUI SVGIcon props
 * @returns
 */
export default function XIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0002 7.50028V9.16695M10.0002 12.5003H10.0085M4.22685 15.8336H15.7735C17.0568 15.8336 17.8585 14.4444 17.2168 13.3336L11.4435 3.33361C10.8018 2.22278 9.19851 2.22278 8.55685 3.33361L2.78351 13.3336C2.14185 14.4444 2.94351 15.8336 4.22685 15.8336Z"
          stroke="currentcolor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
