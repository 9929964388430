import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const SpinnerContainer = styled.div<{ color?: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color, theme }) => color || theme.palette.primary.light};
`;

type LoadingSpinnerProps = {
  size?: number | string;
  color?: string;
};

const LoadingSpinner = ({ size, color }: LoadingSpinnerProps) => (
  <SpinnerContainer color={color} data-testid="loading-spinner">
    <CircularProgress size={size} variant="indeterminate" color="inherit" />
  </SpinnerContainer>
);

export default LoadingSpinner;
