import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Container,
  Stepper,
  Step,
  StepLabel,
  useTheme,
} from '@mui/material';

import Skeleton from '~/synth/Skeleton';
import { useHistory, useRouteMatch } from 'react-router';
import ViseLogoBlack from '~/static/images/vise-logo-black.svg';
import stepsBackground from '~/static/images/onboarding/wizard-steps-background.svg';
import HorizontalStepper, { HorizontalStepperProps } from './HorizontalStepper';
import { Colors } from '../../../constants';

export const ContentSkeleton = () => (
  <Card>
    <CardContent
      style={{
        alignItems: 'center',
        borderBottom: `1px solid ${Colors.gray}`,
        display: 'flex',
      }}
    >
      <Skeleton height="5em" width="100%" />
    </CardContent>
    <CardContent>
      <Skeleton height="18em" width="100%" />
    </CardContent>
  </Card>
);

interface WizardProps {
  isLoading?: boolean;
  onNextButtonClick?: (step: number) => void;
  children: React.ReactElement[] | React.ReactElement;
  secondaryAreaStyle?: React.CSSProperties;
  horizontalStepperProps?: Partial<HorizontalStepperProps>;
  showStepLabels?: boolean;
  stepLabels?: string[];
}

function getStepLabels(children: React.ReactElement[] | React.ReactElement, stepLabels?: string[]) {
  if (stepLabels) return stepLabels;

  const emptyLabel = '';
  if (Array.isArray(children)) {
    return children.map(() => emptyLabel);
  }
  return [emptyLabel];
}

const Wizard = ({
  children,
  isLoading,
  onNextButtonClick,
  horizontalStepperProps,
  secondaryAreaStyle,
  showStepLabels,
  stepLabels,
}: WizardProps) => {
  const theme = useTheme();
  const history = useHistory();
  const match = useRouteMatch<{ activeStep: string }>();
  const activeStepInUrl = Number(match.params.activeStep || 0);
  const [activeStep, setActiveStep] = useState(activeStepInUrl);
  const labels = useMemo(() => getStepLabels(children, stepLabels), [children, stepLabels]);

  const stepCount = stepLabels?.length || 0;
  const sidebarBackgroundColorMap = {
    [stepCount - 1]: theme.palette.pink[100],
    [stepCount]: theme.palette.yellow[100],
  };

  useEffect(() => {
    setActiveStep(activeStepInUrl);
  }, [activeStepInUrl]);

  const replaceActiveStepInURL = ({ url, params }, step) => {
    let newUrl = url.endsWith('/') ? url.slice(0, -1) : url;
    newUrl = params.activeStep
      ? newUrl.replace(newUrl.substring(newUrl.lastIndexOf('/') + 1), '') + step
      : `${newUrl}/${step}`;

    history.push(newUrl);
  };

  const handleNextClick = (currStep) => {
    replaceActiveStepInURL(match, currStep);
    if (onNextButtonClick) {
      onNextButtonClick(currStep);
    }
  };

  const handleSkipClick = (currStep) => {
    replaceActiveStepInURL(match, currStep);
  };

  const handlePreviousClick = (currStep) => {
    replaceActiveStepInURL(match, currStep);
  };

  const handleExitClick = () => {
    history.push('/');
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        padding: '0 !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Grid container sx={{ width: '100%', height: '100%' }}>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            mt: { xs: 1, sm: 4, xl: 8 },
            mb: { xs: 1 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              p: 3,
              width: { xs: '100%', sm: '400px' },
              overflow: 'hidden',
            }}
          >
            <img src={ViseLogoBlack} alt="logo" height="32" style={{ marginBottom: 80 }} />

            {isLoading ? (
              <ContentSkeleton />
            ) : (
              <HorizontalStepper
                currentStep={activeStep}
                showConfettiOnStep={-1}
                onNextClick={handleNextClick}
                onSkipClick={handleSkipClick}
                onPreviousClick={handlePreviousClick}
                onExitClick={handleExitClick}
                {...horizontalStepperProps}
              >
                {children}
              </HorizontalStepper>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: {
              xs: 'none',
              md: 'block',
              background: `${
                sidebarBackgroundColorMap[activeStep] ?? theme.palette.blue[100]
              } url(${stepsBackground}) no-repeat left bottom`,
            },
          }}
          style={secondaryAreaStyle}
        >
          <Grid
            item
            sx={{
              mt: { sm: 32, xl: 38 },
              mb: { xs: 1 },
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {showStepLabels ? (
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                sx={{
                  '.MuiStepConnector-line.MuiStepConnector-lineVertical': {
                    minHeight: '50px',
                    marginLeft: '4px',
                  },
                }}
              >
                {labels.map((label) => (
                  <Step
                    key={label}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        height: '1.2em',
                        width: '1.2em',
                      },
                      '& .MuiStepLabel-root .Mui-completed': {
                        color: 'primary.dark',
                      },
                      '& .MuiStepLabel-iconContainer': {
                        paddingRight: '12px',
                      },
                      '& .MuiStepLabel-label': {
                        fontWeight: 400,
                      },
                    }}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Wizard;
