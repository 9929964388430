import {
  Box,
  Button,
  Divider,
  InputAdornment,
  InputProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  difference,
  forEach,
  inRange,
  isEqual,
  mapValues,
  pickBy,
  range,
  toLower,
  uniq,
} from 'lodash';
import React, { useMemo, useReducer, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation } from 'react-router';
import { ValueType } from 'react-select';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import { AllocationsTemplate, TiltSelection, TiltType } from 'vise-types/template';
import {
  editTemplate,
  getAssetAllocation,
  insertAllocationsTemplate,
  updateUserMetadata,
} from '~/api/api';
import useAllAllocationsTemplates from '~/hooks/templates/useAllAllocationsTemplates';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useUser from '~/hooks/useUser';
import {
  ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP,
  ASSET_CLASS_TO_LABEL_MAP,
  ASSET_CLASS_TREES,
} from '~/routes/PortfolioCreator2/Constants';
import ChooseAllocationModal from '~/routes/PortfolioCreator2/screens/components/ChooseAllocationModal';
import { fillInAssetAllocation } from '~/routes/PortfolioCreator2/useDraftPortfolioReducer';
import {
  closeEquality,
  flipAllAssetClassesWhereApplicable,
  isNewTiltType,
} from '~/routes/PortfolioCreator2/utils';
import { ReactComponent as ExclamationIcon } from '~/static/images/icons/exclamation.svg';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { ReactComponent as PlusMinusIcon } from '~/static/images/icons/plus-minus.svg';
import { ReactComponent as QuestionMarkCircleIcon } from '~/static/images/icons/question-mark-circle.svg';
import { ReactComponent as XCircleIcon } from '~/static/images/icons/x-circle.svg';
import PathBreadcrumbs from '~/synth/PathBreadcrumbs';
import { SelectorCheckboxGroup } from '~/synth/SelectorCheckbox';
import TextField from '~/synth/TextField';
import Select from '~/synth/inputs/Select';
import { getAssetClassKeyFromFeatures } from '~/utils/pce2Migration';
import ActionBar from './ActionBar';
import AllocationTemplateCard from './AllocationTemplateCard';
import AllocationTemplateChart from './AllocationTemplateChart';
import AssetClassTree, { LocallyDelayedNumberFormat } from './AssetClassTree';
import Card from './Card';
import { scrollToTop } from './CommonComponents';
import Container from './Container';
import NameInput from './NameInput';
import RestoreVersionModalOpen from './RestoreVersionModalOpen';
import Stepper, { Step } from './Stepper';
import UpdatedTitle from './UpdatedTitle';
import {
  CENTER_CARD_WIDTH_LARGE,
  CENTER_CARD_WIDTH_SMALL,
  RIGHT_CARD_WIDTH_LARGE,
  RIGHT_CARD_WIDTH_SMALL,
  STEPPER_WIDTH,
} from './constants';

function TotalNumberFormatTextField({ ...props }) {
  const theme = useTheme();
  let inputProps: Partial<InputProps> = props.error
    ? {
        style: { backgroundColor: theme.palette.error[100] },
      }
    : { style: { backgroundColor: theme.palette.success[100] } };
  if (props.InputProps != null) {
    inputProps = { ...props.InputProps, ...inputProps };
  }
  return <TextField {...props} InputProps={inputProps} />;
}

interface StateT {
  split: {
    EQUITY: number;
    FIXED_INCOME: number;
    ALTERNATIVES: number;
  };
  lockedAssetClasses: AssetClassKey[];
  assetClassExclusions: AssetClassKey[];
  allocations: { [key in AssetClassKey]?: number };
  customAllocationClasses: AssetClassKey[];
  name: string;
  nameIsFree?: boolean;
  originalTemplate?: AllocationsTemplate;
  saving: boolean;
  tiltSelection?: TiltSelection;
}

type ActionT =
  | {
      type: 'SPLIT_CHANGE';
      assetClassKey: 'EQUITY' | 'FIXED_INCOME' | 'ALTERNATIVES';
      formValue: number;
    }
  | {
      type: 'TOGGLE_ASSET_CLASS_LOCK';
      assetClassKey: AssetClassKey;
    }
  | {
      type: 'TOGGLE_ASSET_CLASS_EXCLUSION';
      assetClassKey: AssetClassKey;
    }
  | {
      type: 'ALLOCATION_CHANGE';
      assetClassKey: AssetClassKey;
      formValue: string;
    }
  | {
      type: 'REBALANCE_COMPLETE';
      allocations: { [key in AssetClassKey]?: number };
    }
  | {
      type: 'CLEAR_ALL';
    }
  | {
      type: 'CHANGE_NAME';
      formValue: string;
    }
  | {
      type: 'NAME_IS_FREE';
      free?: boolean;
    }
  | {
      type: 'CHOOSE_VISE_RECOMMENDED_ALLOCATION';
      allocations: { [key in AssetClassKey]?: number };
    }
  | {
      type: 'RENAME_TEMPLATE_SUCCESS';
      name: string;
    }
  | {
      type: 'SAVE';
    }
  | {
      type: 'SAVE_FAILURE';
    }
  | {
      type: 'LOAD_OLD_TEMPLATE';
      template: AllocationsTemplate;
    }
  | {
      type: 'SELECT_TILT_TYPE';
      tiltType?: TiltType | 'none' | null;
    }
  | {
      type: 'SELECT_TILT_AMOUNT';
      tiltAmount: number;
    };

const initialState: StateT = {
  split: {
    EQUITY: 0,
    FIXED_INCOME: 0,
    ALTERNATIVES: 0,
  },
  lockedAssetClasses: [],
  assetClassExclusions: [],
  allocations: {},
  customAllocationClasses: [],
  name: '',
  nameIsFree: undefined,
  originalTemplate: undefined,
  saving: false,
  tiltSelection: undefined,
};

function flipAssetClasses(assetClassKeys: AssetClassKey[]) {
  const exclusionSet = new Set(assetClassKeys);
  const toggledSet = new Set<AssetClassKey>();

  const addAllFeatureSets = (node, curFeatureSet) => {
    if (!node) {
      return;
    }

    const assetClassKey = getAssetClassKeyFromFeatures(curFeatureSet);
    if (!exclusionSet.has(assetClassKey)) {
      toggledSet.add(assetClassKey);
    }

    if (node.children) {
      node.children.forEach((child) => addAllFeatureSets(child, [...curFeatureSet, child.feature]));
    }
  };

  ASSET_CLASS_TREES.forEach((node) => addAllFeatureSets(node, [node.feature]));

  return toggledSet;
}

function stateFromTemplate(template: AllocationsTemplate): StateT {
  const allocationsOutOf100 = fillInAssetAllocation(
    mapValues(template.allocations, (alloc) => alloc && alloc * 100)
  );
  return {
    split: {
      EQUITY: allocationsOutOf100.EQUITY || 0,
      FIXED_INCOME: allocationsOutOf100.FIXED_INCOME || 0,
      ALTERNATIVES: allocationsOutOf100.ALTERNATIVES || 0,
    },
    lockedAssetClasses: template.customAllocations,
    assetClassExclusions: Object.entries(template.allocations)
      .filter((kvPair) => !kvPair[1])
      .map((kvPair) => kvPair[0]) as AssetClassKey[],
    allocations: allocationsOutOf100,
    customAllocationClasses: template.customAllocations,
    name: template.name,
    originalTemplate: template,
    saving: false,
    tiltSelection: template.tiltSelection || undefined,
  };
}

function reducer(state: StateT, action: ActionT): StateT {
  function toggleAssetClass(assetClassKeys: AssetClassKey[], assetClassKey: AssetClassKey) {
    if (assetClassKeys.includes(assetClassKey)) {
      return assetClassKeys.filter((k) => k !== assetClassKey);
    }
    return [...assetClassKeys, assetClassKey];
  }
  switch (action.type) {
    case 'SPLIT_CHANGE': {
      return {
        ...state,
        split: {
          ...state.split,
          [action.assetClassKey]: action.formValue,
        },
        lockedAssetClasses: [],
        assetClassExclusions: [],
        customAllocationClasses: [],
        allocations: {},
        tiltSelection: undefined,
      };
    }
    case 'TOGGLE_ASSET_CLASS_LOCK': {
      return {
        ...state,
        lockedAssetClasses: toggleAssetClass(state.lockedAssetClasses, action.assetClassKey),
      };
    }
    case 'TOGGLE_ASSET_CLASS_EXCLUSION': {
      const toggledAssetClasses = flipAssetClasses(state.assetClassExclusions);
      const exclusionsToToggle = [
        action.assetClassKey,
        ...(ASSET_CLASS_KEY_TO_DESCENDENTS_KEY_MAP.get(action.assetClassKey) || []),
      ];
      const newExclusions = toggledAssetClasses.has(action.assetClassKey)
        ? uniq([...state.assetClassExclusions, ...exclusionsToToggle])
        : difference(state.assetClassExclusions, exclusionsToToggle);
      const newExclusionsSet = new Set(newExclusions);
      flipAllAssetClassesWhereApplicable(newExclusionsSet, true);
      return {
        ...state,
        assetClassExclusions: Array.from(newExclusionsSet),
        lockedAssetClasses: state.lockedAssetClasses.filter((k) => !newExclusionsSet.has(k)),
        allocations: fillInAssetAllocation(
          mapValues(state.allocations, (allocation, assetClassKey) =>
            newExclusionsSet.has(assetClassKey as AssetClassKey) ? 0 : allocation
          )
        ),
        customAllocationClasses: state.customAllocationClasses.filter(
          (k) => !newExclusionsSet.has(k)
        ),
      };
    }
    case 'ALLOCATION_CHANGE': {
      const value = parseFloat(action.formValue);
      return {
        ...state,
        allocations: fillInAssetAllocation({
          ...state.allocations,
          [action.assetClassKey]: isNaN(value) ? 0 : value,
        }),
        customAllocationClasses: uniq([...state.customAllocationClasses, action.assetClassKey]),
        lockedAssetClasses: uniq([...state.lockedAssetClasses, action.assetClassKey]),
      };
    }
    case 'REBALANCE_COMPLETE': {
      const allocations = mapValues(
        fillInAssetAllocation(action.allocations),
        (n) => (n || 0) * 100
      );
      return {
        ...state,
        allocations,
        assetClassExclusions: Object.keys(
          pickBy(allocations, (alloc) => alloc === 0)
        ) as AssetClassKey[],
      };
    }
    case 'CLEAR_ALL':
      return {
        ...state,
        allocations: fillInAssetAllocation({}),
        assetClassExclusions: [],
        customAllocationClasses: [],
        lockedAssetClasses: [],
      };
    case 'CHANGE_NAME':
      return {
        ...state,
        name: action.formValue,
        nameIsFree: undefined,
      };
    case 'NAME_IS_FREE':
      return {
        ...state,
        nameIsFree: action.free,
      };
    case 'CHOOSE_VISE_RECOMMENDED_ALLOCATION': {
      const allocations = mapValues(
        fillInAssetAllocation(action.allocations),
        (alloc) => alloc && alloc * 100
      );
      return {
        ...state,
        split: {
          EQUITY: allocations.EQUITY || 0,
          FIXED_INCOME: allocations.FIXED_INCOME || 0,
          ALTERNATIVES: allocations.ALTERNATIVES || 0,
        },
        allocations,
      };
    }
    case 'RENAME_TEMPLATE_SUCCESS': {
      if (!state.originalTemplate?.name) {
        return state;
      }
      return {
        ...state,
        nameIsFree: undefined,
        originalTemplate: {
          ...state.originalTemplate,
          name: action.name,
        },
      };
    }
    case 'SAVE': {
      return {
        ...state,
        saving: true,
      };
    }
    case 'SAVE_FAILURE': {
      return {
        ...state,
        saving: false,
      };
    }
    case 'LOAD_OLD_TEMPLATE': {
      return {
        ...stateFromTemplate(action.template),
        name: state.name,
        nameIsFree: state.nameIsFree,
        originalTemplate: state.originalTemplate,
        saving: state.saving,
      };
    }
    case 'SELECT_TILT_TYPE': {
      if (!action.tiltType) {
        return {
          ...state,
          tiltSelection: undefined,
        };
      }
      if (action.tiltType === 'none') {
        return {
          ...state,
          tiltSelection: {
            isEnabled: false,
          },
        };
      }
      return {
        ...state,
        tiltSelection: {
          isEnabled: true,
          tiltType: action.tiltType,
          tiltAmount: isNewTiltType(action.tiltType)
            ? 10
            : Math.floor(
                (state.split.EQUITY * 10) / (state.split.FIXED_INCOME + state.split.EQUITY)
              ),
        },
      };
    }
    case 'SELECT_TILT_AMOUNT': {
      if (!state.tiltSelection || !state.tiltSelection.isEnabled) {
        return state;
      }
      return {
        ...state,
        tiltSelection: {
          ...state.tiltSelection,
          tiltAmount: action.tiltAmount,
        },
      };
    }
    default:
      return state;
  }
}

type TiltSelectOption = {
  label: string;
  value: 'none' | TiltType;
};
type TiltAmountOption = { label: string; value: number };

export function isUpdated(
  allocation1: { [key in AssetClassKey]?: number },
  allocation2: { [key in AssetClassKey]?: number },
  prefix?: string
) {
  let updated = false;
  forEach(allocation1, (alloc, key) => {
    if (prefix && !key.startsWith(prefix)) {
      return;
    }
    updated = updated || !closeEquality(alloc, allocation2[key]);
  });
  return updated;
}

// to determine if user has any unsubmitted changes
function equalStates(a: Partial<StateT>, b: Partial<StateT>): boolean {
  return (
    isEqual(a.allocations, b.allocations) &&
    isEqual(a.split, b.split) &&
    isEqual(a.lockedAssetClasses, b.lockedAssetClasses) &&
    isEqual(a.customAllocationClasses, b.customAllocationClasses)
  );
}

export default function AllocationTemplateCreate({ template }: { template?: AllocationsTemplate }) {
  const { data: featureFlags } = useFeatureFlags();
  const theme = useTheme();
  const [state, dispatch] = useReducer(
    reducer,
    template ? stateFromTemplate(template) : initialState
  );

  const [restoreVersionModalOpen, setRestoreVersionModalOpen] = useState<boolean>(false);
  const confirmExit = useMemo(
    () =>
      template
        ? !equalStates(state, stateFromTemplate(template))
        : !equalStates(state, initialState),
    [state, template]
  );
  // Either we are editing a template so we keep the name the same, or we are creating a template so we must pick a name not in use
  const nameIsValid =
    state.name.trim() &&
    ((state.originalTemplate && state.name === state.originalTemplate.name) ||
      (!state.originalTemplate && state.nameIsFree));
  const [chooseAllocationModalOpen, setChooseAllocationModalOpen] = useState(false);
  const total = state.split.EQUITY + state.split.FIXED_INCOME + state.split.ALTERNATIVES;
  const toggledAssetClasses = flipAssetClasses(state.assetClassExclusions);
  const history = useHistory();
  const { state: locationState } = useLocation<{ productOnboardingRedirectUrl: string }>();
  const isValidSplit = closeEquality(total, 100);
  const { data: user } = useUser();
  const { data: allTemplates } = useAllAllocationsTemplates(true);
  const staleTemplates = useMemo(
    () =>
      allTemplates?.filter(
        (t) => t.originalTemplateId === template?.originalTemplateId && template.id !== t.id
      ),
    [allTemplates, template?.id, template?.originalTemplateId]
  );

  const enqueueCoachmark = useEnqueueCoachmark();
  const onSplitChange = async (
    split: 'EQUITY' | 'FIXED_INCOME' | 'ALTERNATIVES',
    valueBase: number
  ) => {
    const value = isNaN(valueBase) ? 0 : valueBase;
    dispatch({
      type: 'SPLIT_CHANGE',
      assetClassKey: split,
      formValue: value,
    });
    const newSplit = {
      ...state.split,
      [split]: value,
    };
    if (!isNaN(newSplit.EQUITY) && !isNaN(newSplit.FIXED_INCOME) && !isNaN(newSplit.ALTERNATIVES)) {
      const newTotal = newSplit.EQUITY + newSplit.FIXED_INCOME + newSplit.ALTERNATIVES;
      if (closeEquality(newTotal, 100)) {
        const newAllocation = await getAssetAllocation({
          assetAllocation: mapValues(newSplit, (alloc) => alloc / 100),
        });
        dispatch({
          type: 'REBALANCE_COMPLETE',
          allocations: newAllocation.data,
        });
      }
    }
  };
  const onRebalance = async () => {
    const params: { [key in AssetClassKey]?: number } = { ...state.split };
    state.lockedAssetClasses.forEach((k) => {
      params[k] = state.allocations[k] || 0;
    });
    state.assetClassExclusions.forEach((k) => {
      params[k] = 0;
    });
    const newAllocation = await getAssetAllocation({
      assetAllocation: mapValues(params, (alloc) => (alloc || 0) / 100),
    });
    dispatch({
      type: 'REBALANCE_COMPLETE',
      allocations: newAllocation.data,
    });
  };
  const [step, setStepBase] = useState<'EDIT' | 'REVIEW' | 'EDIT_LANDING'>(
    template ? 'EDIT_LANDING' : 'EDIT'
  );
  const setStep = (step) => {
    setStepBase(step);
    scrollToTop();
  };
  const allocationIsValid =
    closeEquality(
      (state.allocations.EQUITY || 0) +
        (state.allocations.FIXED_INCOME || 0) +
        (state.allocations.ALTERNATIVES || 0),
      100
    ) &&
    closeEquality(state.allocations.EQUITY || 0, state.split.EQUITY) &&
    closeEquality(state.allocations.FIXED_INCOME || 0, state.split.FIXED_INCOME) &&
    closeEquality(state.allocations.ALTERNATIVES || 0, state.split.ALTERNATIVES);

  const originalAllocationOutOf100 =
    state.originalTemplate?.allocations &&
    fillInAssetAllocation(
      mapValues(state.originalTemplate.allocations, (alloc) => (alloc || 0) * 100)
    );

  let reviewStepperState;
  if (step === 'REVIEW') {
    reviewStepperState = 'ACTIVE';
  } else if (!allocationIsValid) {
    reviewStepperState = 'DISABLED';
  }

  const restoreVersionButton =
    staleTemplates && staleTemplates.length > 0 && template ? (
      <Button variant="text" color="primary" onClick={() => setRestoreVersionModalOpen(true)}>
        Restore previous version
      </Button>
    ) : (
      <></>
    );

  let actionBar: React.ReactNode | undefined = (
    <ActionBar
      showBackButton
      backButtonProps={{ onClick: () => setStep('EDIT') }}
      continueButtonProps={{
        disabled: !nameIsValid || !user || state.saving,
        onClick: async () => {
          const body = {
            name: state.name,
            userId: user?.id || '',
            orgId: user?.organizationId || '',
            allocations: mapValues(state.allocations, (alloc) => (alloc || 0) / 100),
            isCustomAllocation: state.customAllocationClasses.length > 0,
            customAllocations: state.customAllocationClasses,
            tiltSelection: state.tiltSelection,
          };
          dispatch({ type: 'SAVE' });
          try {
            if (state.originalTemplate) {
              const { data } = await editTemplate({
                templateId: state.originalTemplate.parentId,
                templateBody: body,
              });
              if (data && data.templateId) {
                history.push(
                  `/secure/strategy-center?editTemplateId=${data.templateId}&templateType=allocation`
                );
              }
            } else {
              const template = await insertAllocationsTemplate(body);
              if (locationState && locationState.productOnboardingRedirectUrl) {
                await updateUserMetadata({ templateOnboardingStatus: 'COMPLETED' });
                history.push(locationState.productOnboardingRedirectUrl, {
                  ...template,
                });
              } else {
                history.push(
                  `/secure/strategy-center?createTemplateId=${template.parentId}&templateType=allocation`
                );
              }
            }
          } catch (e) {
            dispatch({ type: 'SAVE_FAILURE' });
            enqueueCoachmark({
              title: 'Failed to save template, please try again later',
              severity: 'error',
            });
          }
        },
        children: 'Save template',
      }}
    />
  );
  if (step === 'EDIT') {
    actionBar = (
      <ActionBar
        showBackButton={false}
        continueButtonProps={{
          disabled: !allocationIsValid,
          onClick: () => {
            setStep('REVIEW');
          },
        }}
      />
    );
  } else if (step === 'EDIT_LANDING') {
    actionBar = undefined;
  }

  let tiltTypeSelection;
  if (state.tiltSelection && !state.tiltSelection.isEnabled) {
    tiltTypeSelection = 'none';
  } else if (state.tiltSelection?.isEnabled) {
    tiltTypeSelection = state.tiltSelection.tiltType || 'none';
  }

  const tiltOptions: TiltSelectOption[] = [
    { label: 'Passive Market (No Tilt)', value: 'none' },
    { label: 'Multi-factor', value: 'multi-factor' },
    { label: 'Dividends', value: 'dividend' },
    { label: 'Low Volatility', value: 'low-volatility' },
    { label: 'Defensive Dividend', value: 'low-volatility-quality-yield' },
    { label: 'Quality', value: 'quality' },
    { label: 'Value', value: 'value' },
    { label: 'Momentum', value: 'momentum' },
  ];

  if (featureFlags?.enable_qvm_tilt === 'on') {
    tiltOptions.push({ label: 'Quality Value Momentum (QVM)', value: 'quality-value-momentum' });
  }

  if (featureFlags?.enable_income_qvm_tilt === 'on') {
    tiltOptions.push({ label: 'Income Focused QVM', value: 'income-quality-value-momentum' });
  }

  const tiltAmountOptions: TiltAmountOption[] = range(1, 11).map((val) => ({
    label: val.toString(),
    value: val,
  }));

  if (state.tiltSelection && !state.tiltSelection.isEnabled) {
    tiltAmountOptions.push({ label: 'N/A', value: -1 });
  }

  return (
    <Container
      templateType="allocations"
      footer={actionBar}
      headerBarContent={{
        left: (
          <Box color="text.secondary">
            <PathBreadcrumbs
              path={[
                {
                  name: 'Strategy Center',
                  to: '/secure/strategy-center',
                },
                {
                  name: template?.name || 'New allocation template',
                },
              ]}
              ariaLabel="Allocations template creation breadcrumbs"
            />
          </Box>
        ),
        right: restoreVersionButton,
      }}
      getExitConfirmation={confirmExit}
    >
      <ChooseAllocationModal
        open={chooseAllocationModalOpen}
        onClose={() => setChooseAllocationModalOpen(false)}
        taxable
        onLoadAllocation={(allocations) =>
          dispatch({
            type: 'CHOOSE_VISE_RECOMMENDED_ALLOCATION',
            allocations,
          })
        }
      />

      {staleTemplates && staleTemplates.length > 0 && template && (
        <RestoreVersionModalOpen
          open={restoreVersionModalOpen}
          onCloseModal={() => setRestoreVersionModalOpen(false)}
          updateStateFromOldVersion={(template) => {
            if (template.type !== 'allocations') {
              return;
            }
            dispatch({
              type: 'LOAD_OLD_TEMPLATE',
              template,
            });

            setRestoreVersionModalOpen(false);
            setStep('REVIEW');
          }}
          templates={staleTemplates}
        />
      )}
      {step !== 'EDIT_LANDING' && (
        <Box mr={2} maxWidth={STEPPER_WIDTH} width="100%">
          <Stepper>
            <Step variant={step === 'EDIT' ? 'ACTIVE' : undefined}>Edit details</Step>
            <Step variant={reviewStepperState}>Review & save</Step>
          </Stepper>
        </Box>
      )}
      {step === 'EDIT' && (
        <Box>
          <Card>
            <Typography variant="h6" color="textSecondary">
              STEP 1
            </Typography>
            <Box mt={1} mb={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="h2">Enter a portfolio split</Typography>
                <Box mx={2} height="34px">
                  <Divider orientation="vertical" />
                </Box>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<QuestionMarkCircleIcon />}
                  onClick={() => setChooseAllocationModalOpen(true)}
                >
                  Help me choose a split
                </Button>
              </Box>
            </Box>
            <Box mb={4}>
              <Typography variant="body2" color="textSecondary">
                Indicate the high-level asset class breakdown you’re aiming for with this allocation
                template.
              </Typography>
            </Box>
            <Box display="flex">
              <Box mr={2} width="162px">
                <LocallyDelayedNumberFormat
                  label="Equities"
                  value={state.split.EQUITY}
                  name="split.EQUITY"
                  onChange={(e) => onSplitChange('EQUITY', parseFloat(e.target.value))}
                  customInput={TextField}
                  decimalScale={1}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  isAllowed={(val) => inRange(val.floatValue || 0, 0, 100.1)}
                />
              </Box>
              <Box mr={2} width="162px">
                <LocallyDelayedNumberFormat
                  label="Fixed income"
                  value={state.split.FIXED_INCOME}
                  name="split.FIXED_INCOME"
                  onChange={(e) => onSplitChange('FIXED_INCOME', parseFloat(e.target.value))}
                  customInput={TextField}
                  decimalScale={1}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  isAllowed={(val) => inRange(val.floatValue || 0, 0, 100.1)}
                />
              </Box>
              <Box mr={2} width="162px">
                <LocallyDelayedNumberFormat
                  label="Alternatives"
                  value={state.split.ALTERNATIVES}
                  name="split.ALTERNATIVES"
                  onChange={(e) => onSplitChange('ALTERNATIVES', parseFloat(e.target.value))}
                  customInput={TextField}
                  decimalScale={1}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  isAllowed={(val) => inRange(val.floatValue || 0, 0, 100.1)}
                />
              </Box>
              <Box mr={2} width="162px">
                <NumberFormat
                  label="Total"
                  value={total}
                  customInput={TotalNumberFormatTextField}
                  decimalScale={1}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  disabled
                  error={!closeEquality(total, 100)}
                />
              </Box>
            </Box>
          </Card>
          {isValidSplit && (
            <Box display="flex">
              <Card mt={3} fullWidth>
                <Box mx={3}>
                  <Typography variant="h6" color="textSecondary">
                    STEP 2
                  </Typography>
                  <Box mt={1} mb={2}>
                    <Typography variant="h2">Adjust sub-asset allocation</Typography>
                  </Box>
                  <Box mb={4}>
                    <Typography variant="body2" color="textSecondary">
                      Based on your portfolio split, we’ve provided an initial recommendation for
                      this template’s sub-asset class allocation below – feel free to make
                      adjustments.
                    </Typography>
                  </Box>
                </Box>
                <>
                  {ASSET_CLASS_TREES.map((assetClass) => {
                    return (
                      <>
                        <Box
                          display="flex"
                          py={2}
                          bgcolor={theme.palette.grey[100]}
                          borderTop={`solid 1px ${theme.palette.grey[200]}`}
                          borderBottom={`solid 1px ${theme.palette.grey[200]}`}
                          px={3}
                          alignItems="center"
                        >
                          <Box flex={1}>
                            <Typography variant="h3">
                              {ASSET_CLASS_TO_LABEL_MAP.get(assetClass.feature)}
                            </Typography>
                          </Box>
                          <Typography variant="h4" component="div">
                            % of Vise managed value
                          </Typography>
                        </Box>
                        <Box mx={3}>
                          {state.allocations[assetClass.feature] !== 0 ? (
                            <>
                              {assetClass.children?.map((assetClassBranch) => (
                                <SelectorCheckboxGroup
                                  key={ASSET_CLASS_TO_LABEL_MAP.get(assetClassBranch.feature)}
                                >
                                  <AssetClassTree
                                    curFeatureSet={[assetClass.feature]}
                                    curAssetClass={assetClassBranch}
                                    depth={0}
                                    lockedAssetClasses={state.lockedAssetClasses}
                                    onLockClick={(assetClassKey) =>
                                      dispatch({ type: 'TOGGLE_ASSET_CLASS_LOCK', assetClassKey })
                                    }
                                    onClickCreator={(assetClassKey) => () => {
                                      dispatch({
                                        type: 'TOGGLE_ASSET_CLASS_EXCLUSION',
                                        assetClassKey,
                                      });
                                    }}
                                    onAllocationChange={(key, value) =>
                                      dispatch({
                                        type: 'ALLOCATION_CHANGE',
                                        assetClassKey: key,
                                        formValue: value,
                                      })
                                    }
                                    checkedSet={toggledAssetClasses}
                                    isEtfExclusive={false}
                                    assetAllocation={state.allocations}
                                    exclusions={state.assetClassExclusions}
                                    customAllocations={state.customAllocationClasses}
                                    disabledCheck={() => false}
                                  />
                                </SelectorCheckboxGroup>
                              ))}
                              <Box display="flex" justifyContent="space-between" py={1.5}>
                                <Typography variant="subtitle1">Total</Typography>
                                <Box width={125}>
                                  <NumberFormat
                                    id={`subtotal-${toLower(assetClass.feature)}`}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">%</InputAdornment>
                                      ),
                                    }}
                                    error={
                                      !closeEquality(
                                        state.allocations[assetClass.feature],
                                        state.split[assetClass.feature]
                                      )
                                    }
                                    customInput={TotalNumberFormatTextField}
                                    decimalScale={1}
                                    value={state.allocations[assetClass.feature]}
                                    disabled
                                  />
                                  {!closeEquality(
                                    state.allocations[assetClass.feature],
                                    state.split[assetClass.feature]
                                  ) && (
                                    <Box display="flex" alignItems="center" mt={0.5}>
                                      <Box mr={0.5} display="flex">
                                        <ExclamationIcon color="red" />
                                      </Box>
                                      <Typography style={{ color: 'red' }} variant="caption">
                                        Must equal{' '}
                                        {Math.round(state.split[assetClass.feature] * 10) / 10}%
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </>
                          ) : (
                            <Box p={3} color="grey.400">
                              <Typography>
                                {`Adjust the portfolio split at the top of the page to add ${ASSET_CLASS_TO_LABEL_MAP.get(
                                  assetClass.feature
                                )} to the portfolio.`}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </>
                    );
                  })}
                </>
              </Card>
              <Box ml={2} mt={3}>
                <Card fullWidth maxWidth={STEPPER_WIDTH} position="sticky" top={0}>
                  <Box mx={2}>
                    <Button
                      variant="outlined"
                      onClick={onRebalance}
                      fullWidth
                      startIcon={<PlusMinusIcon />}
                      color="primary"
                    >
                      Recalculate
                    </Button>
                    <Box mt={1.5} mb={3}>
                      <Button
                        startIcon={<XCircleIcon />}
                        variant="outlined"
                        color="secondary"
                        onClick={() => dispatch({ type: 'CLEAR_ALL' })}
                        fullWidth
                      >
                        Clear all
                      </Button>
                    </Box>
                  </Box>
                  <Divider />
                  <Box py={2} px={2}>
                    <Typography variant="body1" color="textSecondary">
                      Recalculating will treat custom values as inputs and adjust remaining
                      sub-asset classes pro-rata based on portfolio split.
                    </Typography>
                  </Box>
                </Card>
              </Box>
            </Box>
          )}
          {isValidSplit && (
            <Box display="flex">
              <Card mt={3} fullWidth>
                <Box mx={3}>
                  <Typography variant="h6" color="textSecondary">
                    STEP 3
                  </Typography>
                  <Box mt={1} mb={2}>
                    <Typography variant="h2">Enter tilt settings (optional)</Typography>
                  </Box>
                  <Box mb={4}>
                    <Typography variant="body2" color="textSecondary">
                      Tilt settings is an optional step, these will default to not applicable if
                      nothing is selected.
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Box flex={1}>
                      <Box mb={1}>
                        <Typography variant="h4">Tilt type</Typography>
                      </Box>
                      <Select
                        options={tiltOptions}
                        isClearable
                        menuPlacement="top"
                        styles={{ menu: (provided) => ({ ...provided, marginBottom: 0 }) }}
                        value={
                          tiltTypeSelection
                            ? tiltOptions.find((opt) => opt.value === tiltTypeSelection)
                            : null
                        }
                        onChange={(selection: ValueType<TiltSelectOption>) =>
                          dispatch({
                            type: 'SELECT_TILT_TYPE',
                            tiltType: (selection as TiltSelectOption)?.value,
                          })
                        }
                      />
                    </Box>
                    <Box flex={1} ml={1}>
                      <Box mb={1}>
                        <Typography variant="h4">
                          Tilt amount{' '}
                          {isNewTiltType(
                            state.tiltSelection?.isEnabled
                              ? state.tiltSelection.tiltType
                              : undefined
                          ) ? (
                            <Tooltip
                              title={
                                <>
                                  <p>
                                    For the newly launched active strategies, Vise currently offers
                                    a single tilt amount that provides the target characteristics
                                    and active exposures for each strategy.
                                  </p>
                                  <p>
                                    For the Low Volatility and Defensive Dividend strategies, Vise
                                    targets a lower beta and reduced volatility profile
                                  </p>
                                  <p>
                                    For the Quality strategy, Vise targets a higher profitability
                                    and earnings quality profile.
                                  </p>
                                  <p>
                                    For the Value strategy, Vise targets names with attractive
                                    earnings, cash flows and sales and an overall undervalued
                                    profile.
                                  </p>
                                  <p>
                                    For the Momentum strategy, Vise targets stocks with strong and
                                    consistent price trend that is expected to continue going
                                    forward.
                                  </p>
                                  {featureFlags?.enable_qvm_tilt === 'on' ? (
                                    <p>
                                      For the Quality Value Momentum (QVM) strategy, Vise targets
                                      profitable, well-managed companies that trade at attractive
                                      multiples and exhibit strong market sentiment.
                                    </p>
                                  ) : null}
                                  {featureFlags?.enable_income_qvm_tilt === 'on' ? (
                                    <p>
                                      For the Income Focused QVM strategy, Vise targets
                                      dividend-paying companies that are profitable, well-managed,
                                      trade at attractive multiples and exhibit strong market
                                      sentiment. :+1: 1
                                    </p>
                                  ) : null}
                                </>
                              }
                              placement="top"
                            >
                              <Box
                                color="grey.600"
                                display="inline-block"
                                position="relative"
                                top="2px"
                              >
                                <InformationCircleIcon />
                              </Box>
                            </Tooltip>
                          ) : null}
                        </Typography>
                      </Box>
                      <Select
                        options={tiltAmountOptions}
                        menuPlacement="top"
                        styles={{ menu: (provided) => ({ ...provided, marginBottom: 0 }) }}
                        isDisabled={
                          !tiltTypeSelection ||
                          tiltTypeSelection === 'none' ||
                          isNewTiltType(tiltTypeSelection)
                        }
                        value={
                          tiltAmountOptions.find((opt) => {
                            if (!state.tiltSelection) {
                              return false;
                            }
                            if (!state.tiltSelection.isEnabled) {
                              return opt.value === -1;
                            }
                            return opt.value === state.tiltSelection.tiltAmount;
                          }) ?? null
                        }
                        onChange={(selection: ValueType<TiltAmountOption>) =>
                          dispatch({
                            type: 'SELECT_TILT_AMOUNT',
                            tiltAmount: (selection as TiltAmountOption).value,
                          })
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
          )}
        </Box>
      )}
      {(step === 'REVIEW' || step === 'EDIT_LANDING') && (
        <Box
          width="100%"
          maxWidth={step === 'REVIEW' ? CENTER_CARD_WIDTH_SMALL : CENTER_CARD_WIDTH_LARGE}
        >
          {step === 'REVIEW' && state.originalTemplate ? (
            <Card>
              <Typography variant="h6" color="textSecondary">
                STEP 3
              </Typography>
              <Box mt={1} mb={1.5}>
                <Typography variant="h2">Review & save template</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                Your changes have been applied – review the details of this template below before
                saving.
              </Typography>
            </Card>
          ) : (
            <NameInput
              name={state.name}
              nameIsFree={state.nameIsFree}
              onChange={(val) => dispatch({ type: 'CHANGE_NAME', formValue: val })}
              onCheckTemplateName={(free) => dispatch({ type: 'NAME_IS_FREE', free })}
              templateType="ALLOCATION"
              placeholder="Ex. 80/20 Aggressive"
              editMode={!!state.originalTemplate}
              originalName={state.originalTemplate?.name}
              originalId={state.originalTemplate?.id}
              onRenameTemplateSuccess={(name) =>
                dispatch({ type: 'RENAME_TEMPLATE_SUCCESS', name })
              }
            />
          )}
          <Box mt={3}>
            <AllocationTemplateCard
              editDisabled={
                !!(state.originalTemplate?.name && state.originalTemplate.name !== state.name)
              }
              onEdit={() => setStep('EDIT')}
              equitiesTitle={
                !state.originalTemplate ||
                !isUpdated(state.allocations, originalAllocationOutOf100 || {}, 'EQUITY') ? (
                  'Equities'
                ) : (
                  <UpdatedTitle title="Equities" />
                )
              }
              fixedIncomeTitle={
                !state.originalTemplate ||
                !isUpdated(state.allocations, originalAllocationOutOf100 || {}, 'FIXED_INCOME') ? (
                  'Fixed income'
                ) : (
                  <UpdatedTitle title="Fixed income" />
                )
              }
              alternativesTitle={
                !state.originalTemplate ||
                !isUpdated(state.allocations, originalAllocationOutOf100 || {}, 'ALTERNATIVES') ? (
                  'Alternatives'
                ) : (
                  <UpdatedTitle title="Alternatives" />
                )
              }
              allocations={state.allocations}
              originalAllocations={
                state.originalTemplate &&
                isUpdated(stateFromTemplate(state.originalTemplate).allocations, state.allocations)
                  ? stateFromTemplate(state.originalTemplate).allocations
                  : undefined
              }
              tiltSelection={state.tiltSelection}
            />
          </Box>
        </Box>
      )}
      {(step === 'EDIT_LANDING' || step === 'REVIEW') && (
        <Box
          ml={2}
          width="100%"
          maxWidth={step === 'EDIT_LANDING' ? RIGHT_CARD_WIDTH_LARGE : RIGHT_CARD_WIDTH_SMALL}
        >
          <AllocationTemplateChart allocations={state.allocations} />
        </Box>
      )}
    </Container>
  );
}
