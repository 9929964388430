import useFeatureFlags from './useFeatureFlags';
import useUser from './useUser';

export default function useProductOnboarding() {
  const { data: featureFlags } = useFeatureFlags();
  const { data: userData } = useUser(false, true);
  const metadata = userData?.userMetaData;
  const { organization } = userData || {};
  const featureFlagOn = featureFlags?.enable_product_onboarding === 'on';
  const productOnboardingRequired =
    featureFlagOn &&
    metadata &&
    (metadata?.templateOnboardingStatus !== 'COMPLETED' ||
      metadata.xrayOnboardingStatus !== 'COMPLETED' ||
      metadata.clientOnboardingStatus !== 'COMPLETED');
  return {
    organizationName: userData?.organizationName,
    organizationDomain: userData?.organization?.domain,
    productOnboardingRequired,
    featureFlagOn,
    ...metadata,
    isXrayComplete: metadata?.xrayOnboardingStatus === 'COMPLETED',
    isTemplateOnboardingComplete: metadata?.templateOnboardingStatus === 'COMPLETED',
    isPsaSigned: Boolean(organization?.psaSigned),
    isClientOnboardingComplete: metadata?.clientOnboardingStatus === 'COMPLETED',
    isSignatory: Boolean(metadata?.isSignatory),
  };
}
