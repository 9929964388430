import {
  Box,
  Button,
  ButtonBase,
  Collapse,
  CollapseProps,
  Divider,
  Grid,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import { format } from 'date-fns';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { RiskMetricsByPeriod } from 'vise-types/xray';
import ChartColorSquare from '~/components/chart/ChartColorSquare';
import { ReactComponent as ArrowDownIcon } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ArrowUpIcon } from '~/static/images/icons/chevron-up.svg';
import { ReactComponent as LightBulbIcon } from '~/static/images/icons/light-bulb.svg';
import { formatPercent } from '~/utils/format';
import { SectionSubtitle, SectionTitle } from './components/UtilComponents';

const METRIC_TO_INSIGHT = {
  annualizedReturn: (
    <>
      Annualized return is the overall percentage increase or decrease in an investment&apos;s value
      over time, expressed as an annual rate of return.
    </>
  ),
  annualizedStandardDeviation: (
    <>
      Annualized risk, measured by standard deviation, is a measure of how much your
      portfolio&apos;ss performance deviates around its average annual return.
      <Box my={1}>
        A higher standard deviation implies more volatility of returns and potentially larger
        upswings and downswings in any given period, relative to the portfolio&apos;s average
        return.
      </Box>{' '}
      A lower standard deviation implies a more predictable return stream and potentially smaller
      upswings and downswings in any given period, relative to the portfolio&apos;s average return.
    </>
  ),
  maximumDrawdown: (
    <>
      Maximum drawdown measures the largest observed loss from peak to trough. It highlights the
      potential downside risk of a portfolio.{' '}
      <Box my={1}>
        A large maximum drawdown implies the portfolio declined significantly from its peak value
        which is a sign of higher volatility.
      </Box>
      A smaller maximum drawdown implies the portfolio offered some downside protection and has
      lower volatility.
    </>
  ),
  sharpeRatio: (
    <>
      Sharpe ratio quantifies the returns of the portfolio, per unit of risk taken (measured by
      standard deviation). Sharpe ratios are a risk-adjusted measure of performance.
      <Box my={1}>
        A high Sharpe ratio implies higher returns generated per unit of risk taken, signaling
        risk-adjusted outperformance.
      </Box>
      A low Sharpe ratio, on the other hand, may signal that the portfolio is not delivering
      sufficient returns for the amount of risk taken.
    </>
  ),
};

function MetricCard({
  active,
  metricName,
  vise,
  benchmark,
  onClick,
  isPercent = true,
}: {
  active: boolean;
  metricName: string;
  vise: number | undefined;
  benchmark: number | undefined;
  onClick: () => void;
  isPercent?: boolean;
}) {
  const metricFormat = isPercent ? formatPercent : (value: number) => value.toFixed(2);
  const activeStyles: SxProps = {
    backgroundColor: tokens.palette.primaryBlue[100],
    borderColor: 'transparent',
    borderLeft: `4px solid ${tokens.palette.primaryBlue[400]}`,
    boxShadow: tokens.shadow.large,
  };
  return (
    <ButtonBase onClick={onClick} sx={{ width: '100%', textAlign: 'left' }}>
      <Box
        borderRadius={1}
        p={2}
        border={1}
        borderColor={tokens.palette.neutralCool[300]}
        width="100%"
        sx={
          active
            ? activeStyles
            : {
                '&:hover': {
                  background: tokens.palette.neutralCool[100],
                  borderCollapse: tokens.palette.primaryBlue[300],
                },
              }
        }
      >
        <Typography variant="h3" color="grey.600" mb={1}>
          {metricName}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography variant="h2" color="primary.main" data-testid="risk-metric-value">
              {vise != null ? metricFormat(vise) : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={5}>
            <Typography
              variant="h2"
              color={tokens.palette.neutralCool[800]}
              fontWeight="bold"
              data-testid="risk-metric-value"
            >
              {benchmark != null ? metricFormat(benchmark) : 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </ButtonBase>
  );
}

const Td = styled(TableCell)(() => ({
  root: { fontSize: 12, padding: 8, width: 200 },
  head: {
    fontWeight: 500,
    color: tokens.palette.neutralCool[600],
  },
}));

const ViewMore = ({
  truncatedContent,
  restContent,
}: {
  truncatedContent: React.ReactNode;
  restContent: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box color={tokens.palette.neutralCool[800]}>
      <Box>{truncatedContent}</Box>
      <Box display={isOpen ? 'block' : 'none'} mt={1}>
        {restContent}
      </Box>
      <Button
        color="secondary"
        size="small"
        variant="outlined"
        startIcon={isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        onClick={() => setIsOpen(!isOpen)}
        sx={{ mt: 2 }}
      >
        {isOpen ? 'View less' : 'View more'}
      </Button>
    </Box>
  );
};

export default function RiskMetricsSection({
  intelligence,
  collapseProps,
  onClick,
  riskMetrics,
}: {
  intelligence: PortfolioIntelligenceFull;
  collapseProps?: CollapseProps;
  onClick: () => void;
  riskMetrics: RiskMetricsByPeriod | undefined;
}) {
  const [selectedMetric, setSelectedMetrics] = useState<
    'annualizedReturn' | 'annualizedStandardDeviation' | 'maximumDrawdown' | 'sharpeRatio'
  >('annualizedReturn');

  const metricFormat =
    selectedMetric === 'sharpeRatio'
      ? (value: number | undefined) => (value == null ? 'N/A' : value.toFixed(2))
      : formatPercent;

  const metricChartOptions: Highcharts.Options = {
    chart: {
      height: 200,
      marginRight: 0,
      type: 'column',
      backgroundColor: undefined,
      style: { fontFamily: '"Helvetica Neue", Arial, "Noto Sans", sans-serif' },
    },
    title: { text: '' },
    credits: { enabled: false },
    yAxis: {
      gridLineDashStyle: 'Dash',
      labels: {
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return metricFormat(this.value);
        },
      },
      title: { text: '' },
    },
    xAxis: {
      labels: { enabled: false },
    },
    tooltip: {
      headerFormat: '',
      pointFormatter(this) {
        // eslint-disable-next-line react/no-this-in-sfc
        return `${this.series.name}: <b>${metricFormat(this.y as number)}</b>`;
      },
    },
    series: [
      {
        name: 'Vise Portfolio',
        data: [
          riskMetrics?.oneYear?.backtestPortfolioPerformance?.[selectedMetric] ?? 0,
          riskMetrics?.threeYear?.backtestPortfolioPerformance?.[selectedMetric] ?? 0,
          riskMetrics?.fiveYear?.backtestPortfolioPerformance?.[selectedMetric] ?? 0,
          riskMetrics?.tenYear?.backtestPortfolioPerformance?.[selectedMetric] ?? 0,
          riskMetrics?.inceptionToDate?.backtestPortfolioPerformance?.[selectedMetric] ?? 0,
        ],
        type: 'column',
        color: tokens.palette.primary.main,
      },
      {
        name: 'Benchmark',
        data: [
          riskMetrics?.oneYear?.benchmarkPerformance?.[selectedMetric] ?? 0,
          riskMetrics?.threeYear?.benchmarkPerformance?.[selectedMetric] ?? 0,
          riskMetrics?.fiveYear?.benchmarkPerformance?.[selectedMetric] ?? 0,
          riskMetrics?.tenYear?.benchmarkPerformance?.[selectedMetric] ?? 0,
          riskMetrics?.inceptionToDate?.benchmarkPerformance?.[selectedMetric] ?? 0,
        ],
        type: 'column',
        color: tokens.palette.neutralCool[800],
      },
    ] as SeriesOptionsType[],
    plotOptions: {
      column: { pointPadding: 0.1, groupPadding: 0.22 },
    },
    legend: { enabled: false },
  };

  if (intelligence.proposalType === 'light') {
    throw new Error(`Portfolio intelligence should be PCE2 and FULL. (id: ${intelligence.id})`);
  }

  const { risk } = intelligence.constructionResponse;

  return (
    <Box>
      <SectionTitle
        title="Risk Metrics & Performance"
        subtext="This section provides insight into the portfolio that goes beyond absolute performance, offering a comprehensive set of risk and return metrics that highlight the portfolio’s behavior across different market environments and help you understand the risk and return expectations associated with the portfolio."
        onClick={onClick}
        collapseProps={collapseProps}
        id="risk-metrics-and-performance"
      />

      <Collapse {...collapseProps}>
        <SectionSubtitle title="Vise Portfolio vs. Benchmark" id="vise-vs-benchmark" mb={3} />
        {riskMetrics && (
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Grid item container spacing={2} xs={12}>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <ChartColorSquare color={tokens.palette.primaryBlue[500]} />
                    Vise Portfolio
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <ChartColorSquare color={tokens.palette.neutralCool[800]} />
                    Benchmark
                  </Box>
                </Grid>
              </Grid>
              <Box
                fontSize={12}
                fontStyle="italic"
                color={tokens.palette.neutralCool[600]}
                mb={2}
                mt={0.5}
              >
                Metrics are calculated from inception to date{' '}
                {riskMetrics.inceptionToDate?.asOf &&
                  format(new Date(riskMetrics.inceptionToDate.asOf), 'M/d/y')}
                .
              </Box>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <MetricCard
                    active={selectedMetric === 'annualizedReturn'}
                    metricName="Annualized return"
                    vise={
                      riskMetrics.inceptionToDate?.backtestPortfolioPerformance?.annualizedReturn
                    }
                    benchmark={riskMetrics.inceptionToDate?.benchmarkPerformance.annualizedReturn}
                    onClick={() => setSelectedMetrics('annualizedReturn')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MetricCard
                    active={selectedMetric === 'annualizedStandardDeviation'}
                    metricName="Annualized risk"
                    vise={
                      riskMetrics.inceptionToDate?.backtestPortfolioPerformance
                        ?.annualizedStandardDeviation
                    }
                    benchmark={
                      riskMetrics.inceptionToDate?.benchmarkPerformance.annualizedStandardDeviation
                    }
                    onClick={() => setSelectedMetrics('annualizedStandardDeviation')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MetricCard
                    active={selectedMetric === 'maximumDrawdown'}
                    metricName="Maximum drawdown"
                    vise={
                      riskMetrics.inceptionToDate?.backtestPortfolioPerformance?.maximumDrawdown
                    }
                    benchmark={riskMetrics.inceptionToDate?.benchmarkPerformance.maximumDrawdown}
                    onClick={() => setSelectedMetrics('maximumDrawdown')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MetricCard
                    active={selectedMetric === 'sharpeRatio'}
                    metricName="Sharpe ratio"
                    vise={riskMetrics.inceptionToDate?.backtestPortfolioPerformance?.sharpeRatio}
                    benchmark={riskMetrics.inceptionToDate?.benchmarkPerformance.sharpeRatio}
                    onClick={() => setSelectedMetrics('sharpeRatio')}
                    isPercent={false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Box mr={3}>
                <Table>
                  <TableHead
                    sx={{
                      '.MuiTableCell-head': { color: tokens.palette.neutralCool[600] },
                    }}
                  >
                    <TableRow>
                      <Td variant="head">Timeframe</Td>
                      <Td align="right">1 YR</Td>
                      <Td align="right">3 YR</Td>
                      <Td align="right">5 YR</Td>
                      <Td align="right">10 YR</Td>
                      <Td align="right">ITD</Td>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <Td align="right">
                        <Box display="flex" alignItems="center" fontSize={14}>
                          <ChartColorSquare color={tokens.palette.primaryBlue[500]} />
                          Vise
                        </Box>
                      </Td>
                      <Td align="right">
                        {metricFormat(
                          riskMetrics.oneYear?.backtestPortfolioPerformance?.[selectedMetric]
                        )}
                      </Td>
                      <Td align="right">
                        {metricFormat(
                          riskMetrics.threeYear?.backtestPortfolioPerformance?.[selectedMetric]
                        )}
                      </Td>
                      <Td align="right">
                        {metricFormat(
                          riskMetrics.fiveYear?.backtestPortfolioPerformance?.[selectedMetric]
                        )}
                      </Td>
                      <Td align="right">
                        {metricFormat(
                          riskMetrics.tenYear?.backtestPortfolioPerformance?.[selectedMetric]
                        )}
                      </Td>
                      <Td align="right">
                        {metricFormat(
                          riskMetrics.inceptionToDate?.backtestPortfolioPerformance?.[
                            selectedMetric
                          ]
                        )}
                      </Td>
                    </TableRow>
                    <TableRow>
                      <Td align="right">
                        <Box display="flex" alignItems="center" fontSize={14}>
                          <ChartColorSquare color={tokens.palette.neutralCool[800]} />
                          Benchmark
                        </Box>
                      </Td>
                      <Td align="right">
                        {metricFormat(riskMetrics.oneYear?.benchmarkPerformance?.[selectedMetric])}
                      </Td>
                      <Td align="right">
                        {metricFormat(
                          riskMetrics.threeYear?.benchmarkPerformance?.[selectedMetric]
                        )}
                      </Td>
                      <Td align="right">
                        {metricFormat(riskMetrics.fiveYear?.benchmarkPerformance?.[selectedMetric])}
                      </Td>
                      <Td align="right">
                        {metricFormat(riskMetrics.tenYear?.benchmarkPerformance?.[selectedMetric])}
                      </Td>
                      <Td align="right">
                        {metricFormat(
                          riskMetrics.inceptionToDate?.benchmarkPerformance?.[selectedMetric]
                        )}
                      </Td>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box width="85%" ml="auto">
                <HighchartsReact options={metricChartOptions} />
              </Box>
              <Box
                fontWeight="bold"
                fontSize={12}
                fontStyle="italic"
                color={tokens.palette.neutralCool[600]}
              >
                The above analysis compares the Vise Target Portfolio versus a Morningstar Custom
                Weighted{' '}
                {risk != null
                  ? `(${(risk * 100).toFixed(0)}/${((1 - risk) * 100).toFixed(0)})`
                  : ''}{' '}
                Benchmark. The benchmark is a custom blend of the Morningstar Global Markets Index &
                Morningstar US Core Bond Index based on your target allocations.
              </Box>
              <Box fontWeight="bold" display="flex" alignItems="center" color="black" mt={8} mb={2}>
                <LightBulbIcon />
                Insights
              </Box>
              {METRIC_TO_INSIGHT[selectedMetric]}
            </Grid>
          </Grid>
        )}
        <Divider sx={{ mt: 5, mb: 3 }} />
        <SectionSubtitle title="Vise Portfolio Risk Metrics" id="risk-metrics" />
        <Box
          fontSize={12}
          fontStyle="italic"
          color={tokens.palette.neutralCool[600]}
          mb={3}
          mt={0.5}
        >
          Metrics are calculated from inception to date{' '}
          {riskMetrics?.inceptionToDate?.asOf &&
            format(new Date(riskMetrics.inceptionToDate.asOf), 'M/d/y')}
          .
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <Typography variant="h4" color="textSecondary">
              Total beta
            </Typography>
            <Typography variant="h3" mt={0.5}>
              {riskMetrics?.inceptionToDate?.beta.toFixed(2) ?? 'N/A'}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box color={tokens.palette.neutralCool[800]}>
              Beta measures how sensitive a portfolio is to movements in the benchmark.{' '}
              <Box mt={1}>
                A beta of 1 indicates that the investment moves in line with the market, while a
                beta greater than 1 suggests higher volatility compared to the market, and a beta
                less than 1 implies lower volatility.
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h4" color="textSecondary">
              Historical tracking error
            </Typography>
            <Typography variant="h3" mt={0.5}>
              {formatPercent(riskMetrics?.inceptionToDate?.historicalTrackingError)}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <ViewMore
              truncatedContent={
                <>
                  Historical tracking error measures the variability of a portfolio&apos;s returns
                  compared to a benchmark over a period of time. It shows how much the
                  investment&apos;s performance deviated from the benchmark&apos;s returns, on
                  average.
                </>
              }
              restContent={
                <>
                  A higher tracking error indicates a portfolio&apos;s performance differs from the
                  benchmark, suggesting higher deviations. These portfolios take on more “active
                  risk” and can outperform or underperform their benchmarks by a larger magnitude.
                  <Box mt={1}>
                    A lower tracking error implies that the investment closely follows the
                    benchmark&apos;s performance. Portfolios with lower tracking error take on less
                    “active risk” and have lower potential to outperform or underperform their
                    benchmarks.
                  </Box>
                </>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h4" color="textSecondary">
              Upmarket capture
            </Typography>
            <Typography variant="h3" mt={0.5}>
              {formatPercent(riskMetrics?.inceptionToDate?.upmarketCapture)}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <ViewMore
              truncatedContent={
                <>
                  Upmarket Capture measures how an investment performs compared to its benchmark
                  during upward trending markets.
                  <Box mt={1}>
                    A value above 100% indicates that the investment tends to perform better than
                    the overall market when the market is doing well.
                  </Box>
                </>
              }
              restContent={
                <>
                  For example, an upmarket capture value of 120% implies the portfolio is
                  outperforming the market by a factor of .2.
                  <Box mt={1}>
                    Alternatively, a value below 100% suggests the investment doesn&apos;t capture
                    as much growth as the broader market. An upmarket capture value of 80% implies
                    the portfolio would be up 0.8% for every 1% increase in the market.
                  </Box>
                  Upmarket capture should be analyzed in combination with downmarket capture.
                </>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h4" color="textSecondary">
              Downmarket capture
            </Typography>
            <Typography variant="h3" mt={0.5}>
              {formatPercent(riskMetrics?.inceptionToDate?.downmarketCapture)}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <ViewMore
              truncatedContent={
                <>
                  Downmarket Capture is a measure of how well an investment performs compared to the
                  benchmark during periods when the benchmark has negative returns.
                  <Box mt={1}>
                    A value less than 100% implies that the investment typically declines less than
                    the overall market during negative market periods.
                  </Box>
                </>
              }
              restContent={
                <>
                  On the other hand, a value higher than 100% suggests that the investment tends to
                  lose more value compared to the broader market when the market is down.
                  <Box mt={1}>
                    For example, a downmarket capture value of 80% implies the portfolio would be
                    down 0.8% for every 1% decline in the market. Conversely, a downmarket capture
                    value of 120% implies the portfolio would be down 1.2% for every 1% decline in
                    the market.
                  </Box>
                  Ideally, portfolios should have upmarket capture ratios higher than their
                  downmarket capture ratios - this would imply strong participation in upmarkets and
                  strong protection in downmarkets.
                </>
              }
            />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
}
