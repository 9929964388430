import React, { useCallback, useEffect } from 'react';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import amplitude from '~/utils/amplitude';
import { ScreenProps } from '../../Types';
import { isSmallAccount, showTickerNumberScreen as showTickerNumberScreenFn } from '../../utils';
import PCE2AssetClassScreen from './PCE2AssetClassScreen';
import PCE2SmallAccountAssetClassScreen from './PCE2SmallAccountAssetClassScreen';
import { AssetClassScreenProps } from './types';

export default function CustomAllocations({
  draftPortfolio,
  onContinue,
  dpDispatch,
  ...rest
}: ScreenProps) {
  useEffect(() => {
    amplitude().logEvent('Impression - Asset Classes', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);
  const { constructionInfo, accountSize } = draftPortfolio;

  let exclusions: AssetClassKey[] | undefined | null;

  if ('assetClassConcentrationLimits' in constructionInfo) {
    exclusions = constructionInfo.assetClassConcentrationLimits?.exclusions;
  }

  const showTickerNumberScreen = showTickerNumberScreenFn(accountSize, exclusions);

  const handleOnContinue = useCallback(() => {
    if (showTickerNumberScreen) {
      onContinue('default');
    } else {
      onContinue('specialConditions');
    }
  }, [onContinue, showTickerNumberScreen]);

  const assetClassScreenProps = {
    dpDispatch,
    draftPortfolio,
    onContinue: handleOnContinue,
    onReturnToSummary: () => onContinue('summary'),
  };

  let AssetClasScreen: (props: AssetClassScreenProps) => JSX.Element;
  if (isSmallAccount(accountSize)) {
    AssetClasScreen = PCE2SmallAccountAssetClassScreen;
  } else {
    AssetClasScreen = PCE2AssetClassScreen;
  }

  return <AssetClasScreen {...rest} {...assetClassScreenProps} />;
}
