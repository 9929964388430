import { Box, Button, ButtonBase, Link, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { CsvFileType } from 'vise-types/xray';
import {
  createXRayAccount,
  deleteClosedLots,
  deleteXrayPositions,
  uploadClosedLots,
  uploadOpenLots,
} from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { Account } from '~/models/api';
import { ReactComponent as ChevronRightIcon } from '~/static/images/icons/chevron-right.svg';
import { ReactComponent as QuestionMarkCircleIcon } from '~/static/images/icons/question-mark-circle.svg';
import closedLotsFileDownloadUrl from '~/static/manual-closed-lots.xlsx';
import openLotsFileDownloadUrl from '~/static/manual-open-lots.xlsx';
import { Tag } from '~/synth/Tag';
import Select from '~/synth/inputs/Select';
import amplitude from '~/utils/amplitude';
import { getCustodianDisplayName } from '~/utils/format';
import { DraftPortfolioDispatch, ScreenProps } from '../Types';
import { XrayAction, XrayContext, XrayDispatchContext } from '../context/XrayContext';
import { ActionFooter, BackButton, ContentBox } from './components';
import CsvUploadCard from './components/CsvUploadCard';
import LearnMoreDrawer from './components/CustodianLearnMoreDrawer';

export type UploadData = {
  file: File;
  fileType: string;
};

export type CsvFileUploadProps = {
  xrayDispatch: React.Dispatch<XrayAction>;
  dpDispatch: DraftPortfolioDispatch;
  accountId: string | null;
  clientId: string;
} & UploadData;

type DeleteProps = {
  xrayDispatch: React.Dispatch<XrayAction>;
  accountId: string;
  dpDispatch: DraftPortfolioDispatch;
};

type CsvScreenProps = ScreenProps & {
  fileName?: string | null;
  handleSubmit: (event: React.FormEvent) => void;
  successUploadMessage: string | React.ReactNode;
  type: 'realized' | 'unrealized';
  onDelete: (props: DeleteProps) => Promise<unknown>;
  onUpload: (props: CsvFileUploadProps) => Promise<Account | undefined>;
  canContinue: boolean;

  /**
   * canSkip determines if the screen can be skipped entirely.
   *
   * Mostly applicable for the realized gains upload screen which is entirely
   * optional.
   */
  canSkip?: boolean;
};

export const CUSTODIAN_SELECTION_OPTIONS = [
  {
    value: {
      custodian: 'FIDELITY',
      fileType: 'FIDELITY',
    },
    label: 'Fidelity',
  },
  {
    value: {
      custodian: 'SCHWAB',
      fileType: 'SCHWAB',
    },
    label: 'Schwab',
  },
  {
    value: {
      custodian: 'TDA',
      fileType: 'TDA',
    },
    label: 'TDA',
  },
  {
    value: {
      fileType: 'generic',
    },
    label: 'Vise template',
  },
] as const;

export const getLabelByFileType = (fileType: CsvFileType | null) => {
  const option = CUSTODIAN_SELECTION_OPTIONS.find((e) => {
    return e.value.fileType === fileType;
  });
  return option;
};

export function CsvUploadScreen({
  onBack,
  draftPortfolio,
  onContinue,
  dpDispatch,
  onUpload,
  fileName,
  handleSubmit,
  onDelete,
  type,
  canContinue,
  canSkip,
}: CsvScreenProps) {
  useEffect(() => {
    amplitude().logEvent(`Impression - Upload ${type} gains file screen`, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, [type]);

  const { data: featureFlags } = useFeatureFlags();

  const {
    custodian: custodianId,
    realizedGains,
    unrealizedGains,
    csvFileType,
    taxable,
  } = useContext(XrayContext);
  const xrayDispatch = useContext(XrayDispatchContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const enqueueCoachmark = useEnqueueCoachmark();
  const [fileLoading, setFileLoading] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [allowUpload, setAllowUpload] = useState(type === 'realized');

  const { existingPortfolio, clientId } = draftPortfolio.constructionInfo;
  if (!clientId) throw new Error('no client id');

  let accountId: string | null;
  let accountNumber: string | null = null;
  if (existingPortfolio !== 'sample-portfolio' && existingPortfolio) {
    accountId = existingPortfolio.id;
    accountNumber = existingPortfolio.accountNumber;
  }

  const handleDelete = async () => {
    try {
      if (!accountId || !accountNumber)
        throw new Error('cant delete -  accountId or account number is not defined');

      amplitude().logEvent(`Click delete ${type} gains csv file`, {
        category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
      });
      await onDelete({ accountId, xrayDispatch, dpDispatch });
    } catch (e) {
      enqueueCoachmark({ title: 'Could not delete a file', severity: 'error' });
    }
  };

  const handleUpload = async (file: File) => {
    if (!csvFileType) return;
    if (realizedGains && unrealizedGains) return;

    await onUpload({
      fileType: csvFileType,
      xrayDispatch,
      dpDispatch,
      file,
      accountId,
      clientId,
    });
  };

  let subTitleText = '';

  if (type === 'unrealized') {
    subTitleText =
      "Select the file format you would like to upload your client's portfolio. This file will contain the client's holdings and cost basis information.";
  } else {
    subTitleText =
      'Uploading this file is highly recommended, as it will enable Vise to provide a more accurate estimate of tax impact and initial trades for this client’s proposal.';
  }

  const handleFiletypeChange = (e) => {
    amplitude().logEvent(`Change csv format to ${e.value.fileType}`, {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
    xrayDispatch({ type: 'SET_XRAY_CUSTODIAN', xrayCustodian: e.value.custodian });
    xrayDispatch({ type: 'SET_CSV_FILE_TYPE', fileType: e.value.fileType });
    setAllowUpload(true);
  };

  return (
    <ContentBox>
      <form onSubmit={handleSubmit}>
        <Box my={2} display="flex">
          <Typography variant="h1">
            Upload {type === 'realized' ? 'a realized' : 'an unrealized'} gains file.
          </Typography>
          {type === 'realized' ? (
            <Box ml={1.5}>
              <Tag>Optional</Tag>
            </Box>
          ) : null}
        </Box>
        <Box mb={2} color="grey.600">
          <Typography variant="body2">{subTitleText}</Typography>
        </Box>
        <>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h4" mb={1.5}>
              Select a file type:
            </Typography>
            <Select
              defaultValue={getLabelByFileType(csvFileType)}
              options={[
                ...CUSTODIAN_SELECTION_OPTIONS,
                ...(featureFlags?.['gpt-csv-parsing'] === 'on'
                  ? [{ value: { fileType: 'pdf' }, label: 'PDF statement' }]
                  : []),
              ]}
              onChange={handleFiletypeChange}
              isDisabled={
                (type === 'unrealized' && unrealizedGains != null) ||
                (type === 'realized' && realizedGains != null)
              }
            />
          </Box>
        </>
        <Box mt={2.5} mb={2}>
          {(csvFileType === 'SCHWAB' || csvFileType === 'FIDELITY' || csvFileType === 'TDA') &&
            csvFileType &&
            type === 'unrealized' && (
              <ButtonBase
                sx={{ borderTop: 1, borderBottom: 1, borderColor: 'grey.200', paddingY: 2 }}
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                  amplitude().logEvent(`Open learn more drawer for csv upload`, {
                    category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                  });
                }}
                style={{ width: '100%', display: 'block' }}
              >
                <Box display="flex" alignItems="center">
                  <QuestionMarkCircleIcon />
                  <Typography variant="h5" style={{ lineHeight: 1 }} ml={2}>
                    Learn how to import portfolio files from{' '}
                    {custodianId == null ? 'N/A' : getCustodianDisplayName(custodianId)}
                  </Typography>
                  <Box ml="auto" component="span">
                    <ChevronRightIcon width={16} height={16} />
                  </Box>
                </Box>
              </ButtonBase>
            )}
          {custodianId && csvFileType !== 'generic' && (
            <LearnMoreDrawer
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              onXClick={() => setDrawerOpen(false)}
              custodian={custodianId}
            />
          )}
        </Box>
        <CsvUploadCard
          fileName={fileName}
          allowUpload={allowUpload}
          onUpload={handleUpload}
          onDelete={handleDelete}
          fileLoading={fileLoading}
          setFileLoading={setFileLoading}
          allowPdf={featureFlags?.['gpt-csv-parsing'] === 'on'}
        />
        <div hidden={type === 'realized'}>
          <Typography variant="h4" mt={2.5}>
            Additional options
          </Typography>
          <Box my={2}>
            <b>Option 1:</b> You can try downloading a Vise template Excel workbook{' '}
            <Link
              download="Vise template - Open lots.xlsx"
              href={openLotsFileDownloadUrl}
              onClick={() =>
                amplitude().logEvent(`Download example Excel file`, {
                  category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
                })
              }
            >
              here
            </Link>
            . Input your client&apos;s positions and then upload the Excel workbook above. Make sure
            to select the &quot;Vise template&quot; file type above.
          </Box>
          <>
            <b>Option 2:</b> Additionally, if the Vise template Excel workbook is not working, you
            can manually input your client&apos;s positions{' '}
            <ButtonBase
              sx={{ color: 'primary.main' }}
              onClick={async (event) => {
                event.preventDefault();
                try {
                  const account = draftPortfolio.constructionInfo.existingPortfolio;
                  if (account === 'sample-portfolio') return;
                  const accountId = account?.id ? account.id : uuid();
                  setCreatingAccount(true);
                  const response = await createXRayAccount({
                    clientId,
                    taxable,
                    accountId,
                  });
                  if (response.status === 200) {
                    dpDispatch({ type: 'SET_ACCOUNT', existingPortfolio: response.data });
                    onContinue('manual');
                  }
                } catch (e) {
                  enqueueCoachmark({
                    title: 'Error setting account for manual position upload.',
                    severity: 'error',
                  });
                }
                setCreatingAccount(false);
              }}
              disabled={fileLoading || creatingAccount}
            >
              here
            </ButtonBase>
            .
          </>
        </div>
        <div hidden={type === 'unrealized'}>
          <Typography variant="h4" mt={2.5} mb={1}>
            Vise template
          </Typography>
          If you opted for the Vise template option, download the Vise template Excel workbook for
          closed lots{' '}
          <Link
            download="Vise template - Closed lots.xlsx"
            href={closedLotsFileDownloadUrl}
            onClick={() =>
              amplitude().logEvent(`Download closed lots example Excel file`, {
                category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
              })
            }
          >
            here
          </Link>
          . Input your client&apos;s closed lots and then upload the file above. Make sure to select
          the &quot;Vise template&quot; file type.
        </div>
        <ActionFooter justifyContent="space-between">
          <BackButton onClick={() => onBack()}>Back</BackButton>
          <Box display="flex">
            {canSkip && (
              <Box mr={1.5}>
                <Button color="secondary" type="submit" variant="outlined">
                  Skip
                </Button>
              </Box>
            )}
            <Button color="primary" type="submit" variant="contained" disabled={!canContinue}>
              Continue
            </Button>
          </Box>
        </ActionFooter>
      </form>
    </ContentBox>
  );
}

function CsvUnrealizedGainsUploadScreen(props: ScreenProps) {
  const { unrealizedGains, taxable = true } = useContext(XrayContext);
  const { onContinue, onBack } = props;

  const handleDelete = async ({ accountId, xrayDispatch }: DeleteProps) => {
    const result = await Promise.all([
      deleteXrayPositions({ accountId }),
      // NOTE: closed lots may not have been uploaded yet, but if they are, they'll be deleted.
      deleteClosedLots({ accountId }),
    ]);

    xrayDispatch({ type: 'SET_XRAY_UNREALIZED_GAINS_FILE', fileName: null });
    xrayDispatch({ type: 'SET_XRAY_REALIZED_GAINS_FILE', fileName: null });
    return result;
  };

  const handleUpload = async ({
    file,
    fileType,
    xrayDispatch,
    dpDispatch,
    accountId,
    clientId,
  }: CsvFileUploadProps) => {
    const result = await uploadOpenLots(
      await file.arrayBuffer(),
      fileType,
      clientId,
      accountId,
      taxable,
      file.type,
      file.name
    );
    dpDispatch({ type: 'SET_ACCOUNT', existingPortfolio: result.data.account });
    xrayDispatch({ type: 'SET_XRAY_UNREALIZED_GAINS_FILE', fileName: file.name });
    return result.data.account;
  };

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    // Taxable => realized gains
    if (taxable) {
      onContinue();
      return;
    }

    // Non-taxable => cash position
    onContinue('cash');
  }

  const fileName = unrealizedGains;
  const xrayDispatch = useContext(XrayDispatchContext);

  return (
    <CsvUploadScreen
      type="unrealized"
      {...props}
      fileName={fileName}
      handleSubmit={handleSubmit}
      successUploadMessage="Unrealized gains file uploaded successfully"
      onDelete={handleDelete}
      onUpload={handleUpload}
      canContinue={Boolean(fileName)}
      onBack={async () => {
        // TBD replace with synth dialog
        // eslint-disable-next-line no-alert
        const result = confirm(
          'Uploaded and manually entered position data will be lost. Are you sure you want to continue?'
        );
        if (result) {
          xrayDispatch({ type: 'RESET_XRAY_CONTEXT' });
          onBack();
        }
      }}
    />
  );
}

function CsvRealizedGainsUploadScreen(props: ScreenProps) {
  const { realizedGains } = useContext(XrayContext);
  const { onContinue } = props;

  const handleDelete = async ({ accountId, xrayDispatch }) => {
    const result = await deleteClosedLots({ accountId });

    xrayDispatch({ type: 'SET_XRAY_REALIZED_GAINS_FILE', fileName: null });
    return result;
  };

  const handleUpload = async ({
    xrayDispatch,
    fileType,
    file,
    accountId,
    clientId,
  }: CsvFileUploadProps & UploadData) => {
    if (!accountId || !clientId) return undefined;

    const result = await uploadClosedLots(
      await file.arrayBuffer(),
      fileType,
      accountId,
      file.type,
      file.name
    );
    xrayDispatch({ type: 'SET_XRAY_REALIZED_GAINS_FILE', fileName: file.name });

    return result.data.account;
  };

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    onContinue();
  }

  return (
    <CsvUploadScreen
      type="realized"
      {...props}
      fileName={realizedGains}
      handleSubmit={handleSubmit}
      successUploadMessage="Realized gains file uploaded successfully"
      onDelete={handleDelete}
      onUpload={handleUpload}
      canContinue={Boolean(realizedGains)}
      canSkip
    />
  );
}

export { CsvRealizedGainsUploadScreen, CsvUnrealizedGainsUploadScreen };
