import { Cadence, DistributionStatus } from 'vise-types/distribution';

export const CADENCE_TO_LABEL = {
  DO_NOT_REPEAT: 'One-time',
  QUARTERLY: 'Quarterly',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
} as {
  [key in Cadence]: string;
};

export const STATUS_TO_LABEL = {
  SCHEDULED: 'Scheduled',
  TRADES_INITIATED: 'Rebalance initiated',
  TRADES_EXECUTED: 'Cash generated',
  CASH_AVAILABLE: 'Ready to withdraw',
  PAUSED: 'Paused',
} as {
  [key in DistributionStatus]: string;
};

const DISTRIBUTION_STATUS_TO_ORDER = {
  SCHEDULED: 0,
  TRADES_INITIATED: 1,
  TRADES_EXECUTED: 2,
  CASH_AVAILABLE: 3,
  PAUSED: 4,
} as { [key in DistributionStatus]: number };

export const sortScheduleByDistributionStatus = (scheduleA, scheduleB) => {
  const statusAOrder = scheduleA ? DISTRIBUTION_STATUS_TO_ORDER[scheduleA] : -1;
  const statusBOrder = scheduleB ? DISTRIBUTION_STATUS_TO_ORDER[scheduleB] : -1;

  return statusAOrder < statusBOrder ? -1 : 1;
};

export const sortByDistributionStatus = (rowA, rowB) => {
  return sortScheduleByDistributionStatus(rowA, rowB);
};

export const sortByNextDistribution = (rowA, rowB) => {
  return (rowA?.[0] ?? -1) < (rowB?.[0] ?? -1) ? -1 : 1;
};

export const sortByProcessUntil = (rowA, rowB) => {
  return (rowA ?? -1) < (rowB ?? -1) ? 1 : -1;
};

export enum DISTRIBUTION_TABLE_TYPE {
  GLOBAL = 'GLOBAL',
  ACCOUNT = 'ACCOUNT',
}
