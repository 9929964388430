/**
 * Primary blue
 */
const PRIMARY_BLUE_1000 = "#05112E";
const PRIMARY_BLUE_900 = "#0A235C";
const PRIMARY_BLUE_800 = "#0E3285";
const PRIMARY_BLUE_700 = "#1345B9";
const PRIMARY_BLUE_600 = "#1753DE";
const PRIMARY_BLUE_500 = "#4575E5";
const PRIMARY_BLUE_400 = "#7498EB";
const PRIMARY_BLUE_300 = "#A3BCF5";
const PRIMARY_BLUE_200 = "#E3EBFC";
const PRIMARY_BLUE_100 = "#F1F5FE";

const PRIMARY_BLUE = PRIMARY_BLUE_600;

/**
 * Neutral Colors
 */
const NEUTRAL_COOL_1000 = "#161617";
const NEUTRAL_COOL_900 = "#333336";
const NEUTRAL_COOL_800 = "#3F3F42";
const NEUTRAL_COOL_700 = "#49494D";
const NEUTRAL_COOL_600 = "#64646A";
const NEUTRAL_COOL_500 = "#93939C";
const NEUTRAL_COOL_400 = "#A1A1AB";
const NEUTRAL_COOL_300 = "#CFD0D9";
const NEUTRAL_COOL_200 = "#EBECF4";
const NEUTRAL_COOL_100 = "#FAFAFD";

/**
 * Secondary green
 */
const SECONDARY_GREEN_1000 = "#0B3109";
const SECONDARY_GREEN_900 = "#156312";
const SECONDARY_GREEN_800 = "#20941B";
const SECONDARY_GREEN_700 = "#2AC624";
const SECONDARY_GREEN_600 = "#35F72D";
const SECONDARY_GREEN_500 = "#5DF957";
const SECONDARY_GREEN_400 = "#86FA81";
const SECONDARY_GREEN_300 = "#AEFCAB";
const SECONDARY_GREEN_200 = "#D7FDD5";
const SECONDARY_GREEN_100 = "#EBFEEA";

/**
 * Secondary subtle green
 */
const SECONDARY_SUBTLE_GREEN_1000 = "#12231B";
const SECONDARY_SUBTLE_GREEN_900 = "#254837";
const SECONDARY_SUBTLE_GREEN_800 = "#36684F";
const SECONDARY_SUBTLE_GREEN_700 = "#448463";
const SECONDARY_SUBTLE_GREEN_600 = "#53A079";
const SECONDARY_SUBTLE_GREEN_500 = "#88B79D";
const SECONDARY_SUBTLE_GREEN_400 = "#AECBBB";
const SECONDARY_SUBTLE_GREEN_300 = "#CCDED4";
const SECONDARY_SUBTLE_GREEN_200 = "#E7EFEA";
const SECONDARY_SUBTLE_GREEN_100 = "#F3F7F5";

/**
 * Secondary subtle orange
 */
const SECONDARY_SUBTLE_ORANGE_1000 = "#38220F";
const SECONDARY_SUBTLE_ORANGE_900 = "#73451F";
const SECONDARY_SUBTLE_ORANGE_800 = "#A6642C";
const SECONDARY_SUBTLE_ORANGE_700 = "#D27E38";
const SECONDARY_SUBTLE_ORANGE_600 = "#FF9944";
const SECONDARY_SUBTLE_ORANGE_500 = "#FFB281";
const SECONDARY_SUBTLE_ORANGE_400 = "#FFC8AA";
const SECONDARY_SUBTLE_ORANGE_300 = "#FFDCCA";
const SECONDARY_SUBTLE_ORANGE_200 = "#FFEEE6";
const SECONDARY_SUBTLE_ORANGE_100 = "#FFF7F3";

/**
 * Secondary subtle yellow
 */
const SECONDARY_SUBTLE_YELLOW_1000 = "#382E0E";
const SECONDARY_SUBTLE_YELLOW_900 = "#735F1D";
const SECONDARY_SUBTLE_YELLOW_800 = "#A5892A";
const SECONDARY_SUBTLE_YELLOW_700 = "#D1AD35";
const SECONDARY_SUBTLE_YELLOW_600 = "#FED340";
const SECONDARY_SUBTLE_YELLOW_500 = "#FEDD80";
const SECONDARY_SUBTLE_YELLOW_400 = "#FEE6A9";
const SECONDARY_SUBTLE_YELLOW_300 = "#FFEECA";
const SECONDARY_SUBTLE_YELLOW_200 = "#FFF7E6";
const SECONDARY_SUBTLE_YELLOW_100 = "#FFFBF3";

/**
 * Secondary turquoise
 */
const SECONDARY_TURQUOISE_1000 = "#09272A";
const SECONDARY_TURQUOISE_900 = "#124E54";
const SECONDARY_TURQUOISE_800 = "#1A767E";
const SECONDARY_TURQUOISE_700 = "#239DA8";
const SECONDARY_TURQUOISE_600 = "#2CC4D2";
const SECONDARY_TURQUOISE_500 = "#56D0DB";
const SECONDARY_TURQUOISE_400 = "#80DCE4";
const SECONDARY_TURQUOISE_300 = "#ABE7ED";
const SECONDARY_TURQUOISE_200 = "#D5F3F6";
const SECONDARY_TURQUOISE_100 = "#EAF9FB";

/**
 * Secondary yellow
 */
const SECONDARY_YELLOW_1000 = "#2D2F0A";
const SECONDARY_YELLOW_900 = "#5A5F15";
const SECONDARY_YELLOW_800 = "#878E1F";
const SECONDARY_YELLOW_700 = "#B4BE2A";
const SECONDARY_YELLOW_600 = "#E1ED34";
const SECONDARY_YELLOW_500 = "#E7F15D";
const SECONDARY_YELLOW_400 = "#EDF485";
const SECONDARY_YELLOW_300 = "#F3F8AE";
const SECONDARY_YELLOW_200 = "#F9FBD6";
const SECONDARY_YELLOW_100 = "#FCFDEB";

/**
 * Secondary purple
 */
const SECONDARY_PURPLE_1000 = "#12022C";
const SECONDARY_PURPLE_900 = "#320577";
const SECONDARY_PURPLE_800 = "#4707A9";
const SECONDARY_PURPLE_700 = "#5508CA";
const SECONDARY_PURPLE_600 = "#670AF4";
const SECONDARY_PURPLE_500 = "#9372F6";
const SECONDARY_PURPLE_400 = "#B4A1F8";
const SECONDARY_PURPLE_300 = "#D0C6FB";
const SECONDARY_PURPLE_200 = "#E9E4FD";
const SECONDARY_PURPLE_100 = "#F4F2FE";

/**
 * Secondary magenta
 */
const SECONDARY_MAGENTA_1000 = "#340B25";
const SECONDARY_MAGENTA_900 = "#741952";
const SECONDARY_MAGENTA_800 = "#A52474";
const SECONDARY_MAGENTA_700 = "#C52B8B";
const SECONDARY_MAGENTA_600 = "#EE34A8";
const SECONDARY_MAGENTA_500 = "#F17BBD";
const SECONDARY_MAGENTA_400 = "#F5A6CF";
const SECONDARY_MAGENTA_300 = "#F8C8E0";
const SECONDARY_MAGENTA_200 = "#FCE5F0";
const SECONDARY_MAGENTA_100 = "#FDF2F8";

/**
 * Secondary gold
 */
const SECONDARY_GOLD_1000 = "#2D2922";
const SECONDARY_GOLD_900 = "#5A5143";
const SECONDARY_GOLD_800 = "#867A65";
const SECONDARY_GOLD_700 = "#B3A286";
const SECONDARY_GOLD_600 = "#E0CBA8";
const SECONDARY_GOLD_500 = "#E6D5B9";
const SECONDARY_GOLD_400 = "#ECE0CB";
const SECONDARY_GOLD_300 = "#F3EADC";
const SECONDARY_GOLD_200 = "#F9F5EE";
const SECONDARY_GOLD_100 = "#FCFAF6";

/**
 * Secondary lime green
 */
const SECONDARY_LIME_GREEN_1000 = "#0B3109";
const SECONDARY_LIME_GREEN_900 = "#156312";
const SECONDARY_LIME_GREEN_800 = "#20941B";
const SECONDARY_LIME_GREEN_700 = "#2AC624";
const SECONDARY_LIME_GREEN_600 = "#35F72D";
const SECONDARY_LIME_GREEN_500 = "#5DF957";
const SECONDARY_LIME_GREEN_400 = "#86FA81";
const SECONDARY_LIME_GREEN_300 = "#AEFCAB";
const SECONDARY_LIME_GREEN_200 = "#D7FDD5";
const SECONDARY_LIME_GREEN_100 = "#EBFEEA";

/**
 * Semantic red
 */
const SEMANTIC_RED_1000 = "#290808";
const SEMANTIC_RED_900 = "#530F0F";
const SEMANTIC_RED_800 = "#7C1717";
const SEMANTIC_RED_700 = "#A61E1E";
const SEMANTIC_RED_600 = "#CF2626";
const SEMANTIC_RED_500 = "#E57171";
const SEMANTIC_RED_400 = "#ECA8A8";
const SEMANTIC_RED_300 = "#FCD2CF";
const SEMANTIC_RED_200 = "#FAE9E9";
const SEMANTIC_RED_100 = "#FEF6F6";

/**
 * Semantic green
 */
const SEMANTIC_GREEN_1000 = "#011A14";
const SEMANTIC_GREEN_900 = "#033428";
const SEMANTIC_GREEN_800 = "#044F3C";
const SEMANTIC_GREEN_700 = "#066950";
const SEMANTIC_GREEN_600 = "#078364";
const SEMANTIC_GREEN_500 = "#399C83";
const SEMANTIC_GREEN_400 = "#6AB5A2";
const SEMANTIC_GREEN_300 = "#A7ECDB";
const SEMANTIC_GREEN_200 = "#CDE6E0";
const SEMANTIC_GREEN_100 = "#E8FBF0";

/**
 * Semantic orange
 */
const SEMANTIC_ORANGE_1000 = "#2D1900";
const SEMANTIC_ORANGE_900 = "#5A3100";
const SEMANTIC_ORANGE_800 = "#864A00";
const SEMANTIC_ORANGE_700 = "#B36200";
const SEMANTIC_ORANGE_600 = "#E07B00";
const SEMANTIC_ORANGE_500 = "#FFB400";
const SEMANTIC_ORANGE_400 = "#FFDA6C";
const SEMANTIC_ORANGE_300 = "#F3DF99";
const SEMANTIC_ORANGE_200 = "#F9EFCC";
const SEMANTIC_ORANGE_100 = "#FEFAE8";

export default {
  primary: {
    main: PRIMARY_BLUE,
  },
  primaryBlue: {
    100: PRIMARY_BLUE_100,
    200: PRIMARY_BLUE_200,
    300: PRIMARY_BLUE_300,
    400: PRIMARY_BLUE_400,
    500: PRIMARY_BLUE_500,
    600: PRIMARY_BLUE_600,
    700: PRIMARY_BLUE_700,
    800: PRIMARY_BLUE_800,
    900: PRIMARY_BLUE_900,
    1000: PRIMARY_BLUE_1000,
  },
  neutralCool: {
    100: NEUTRAL_COOL_100,
    200: NEUTRAL_COOL_200,
    300: NEUTRAL_COOL_300,
    400: NEUTRAL_COOL_400,
    500: NEUTRAL_COOL_500,
    600: NEUTRAL_COOL_600,
    700: NEUTRAL_COOL_700,
    800: NEUTRAL_COOL_800,
    900: NEUTRAL_COOL_900,
    1000: NEUTRAL_COOL_1000,
  },
  secondaryGreen: {
    100: SECONDARY_GREEN_100,
    200: SECONDARY_GREEN_200,
    300: SECONDARY_GREEN_300,
    400: SECONDARY_GREEN_400,
    500: SECONDARY_GREEN_500,
    600: SECONDARY_GREEN_600,
    700: SECONDARY_GREEN_700,
    800: SECONDARY_GREEN_800,
    900: SECONDARY_GREEN_900,
    1000: SECONDARY_GREEN_1000,
  },
  secondaryTurquoise: {
    100: SECONDARY_TURQUOISE_100,
    200: SECONDARY_TURQUOISE_200,
    300: SECONDARY_TURQUOISE_300,
    400: SECONDARY_TURQUOISE_400,
    500: SECONDARY_TURQUOISE_500,
    600: SECONDARY_TURQUOISE_600,
    700: SECONDARY_TURQUOISE_700,
    800: SECONDARY_TURQUOISE_800,
    900: SECONDARY_TURQUOISE_900,
    1000: SECONDARY_TURQUOISE_1000,
  },
  secondaryYellow: {
    100: SECONDARY_YELLOW_100,
    200: SECONDARY_YELLOW_200,
    300: SECONDARY_YELLOW_300,
    400: SECONDARY_YELLOW_400,
    500: SECONDARY_YELLOW_500,
    600: SECONDARY_YELLOW_600,
    700: SECONDARY_YELLOW_700,
    800: SECONDARY_YELLOW_800,
    900: SECONDARY_YELLOW_900,
    1000: SECONDARY_YELLOW_1000,
  },
  semanticRed: {
    100: SEMANTIC_RED_100,
    200: SEMANTIC_RED_200,
    300: SEMANTIC_RED_300,
    400: SEMANTIC_RED_400,
    500: SEMANTIC_RED_500,
    600: SEMANTIC_RED_600,
    700: SEMANTIC_RED_700,
    800: SEMANTIC_RED_800,
    900: SEMANTIC_RED_900,
    1000: SEMANTIC_RED_1000,
  },
  semanticGreen: {
    100: SEMANTIC_GREEN_100,
    200: SEMANTIC_GREEN_200,
    300: SEMANTIC_GREEN_300,
    400: SEMANTIC_GREEN_400,
    500: SEMANTIC_GREEN_500,
    600: SEMANTIC_GREEN_600,
    700: SEMANTIC_GREEN_700,
    800: SEMANTIC_GREEN_800,
    900: SEMANTIC_GREEN_900,
    1000: SEMANTIC_GREEN_1000,
  },
  secondaryPurple: {
    100: SECONDARY_PURPLE_100,
    200: SECONDARY_PURPLE_200,
    300: SECONDARY_PURPLE_300,
    400: SECONDARY_PURPLE_400,
    500: SECONDARY_PURPLE_500,
    600: SECONDARY_PURPLE_600,
    700: SECONDARY_PURPLE_700,
    800: SECONDARY_PURPLE_800,
    900: SECONDARY_PURPLE_900,
    1000: SECONDARY_PURPLE_1000,
  },
  semanticOrange: {
    100: SEMANTIC_ORANGE_100,
    200: SEMANTIC_ORANGE_200,
    300: SEMANTIC_ORANGE_300,
    400: SEMANTIC_ORANGE_400,
    500: SEMANTIC_ORANGE_500,
    600: SEMANTIC_ORANGE_600,
    700: SEMANTIC_ORANGE_700,
    800: SEMANTIC_ORANGE_800,
    900: SEMANTIC_ORANGE_900,
    1000: SEMANTIC_ORANGE_1000,
  },
  secondaryMagenta: {
    100: SECONDARY_MAGENTA_100,
    200: SECONDARY_MAGENTA_200,
    300: SECONDARY_MAGENTA_300,
    400: SECONDARY_MAGENTA_400,
    500: SECONDARY_MAGENTA_500,
    600: SECONDARY_MAGENTA_600,
    700: SECONDARY_MAGENTA_700,
    800: SECONDARY_MAGENTA_800,
    900: SECONDARY_MAGENTA_900,
    1000: SECONDARY_MAGENTA_1000,
  },
  secondarySubtleGreen: {
    100: SECONDARY_SUBTLE_GREEN_100,
    200: SECONDARY_SUBTLE_GREEN_200,
    300: SECONDARY_SUBTLE_GREEN_300,
    400: SECONDARY_SUBTLE_GREEN_400,
    500: SECONDARY_SUBTLE_GREEN_500,
    600: SECONDARY_SUBTLE_GREEN_600,
    700: SECONDARY_SUBTLE_GREEN_700,
    800: SECONDARY_SUBTLE_GREEN_800,
    900: SECONDARY_SUBTLE_GREEN_900,
    1000: SECONDARY_SUBTLE_GREEN_1000,
  },
  secondarySubtleOrange: {
    100: SECONDARY_SUBTLE_ORANGE_100,
    200: SECONDARY_SUBTLE_ORANGE_200,
    300: SECONDARY_SUBTLE_ORANGE_300,
    400: SECONDARY_SUBTLE_ORANGE_400,
    500: SECONDARY_SUBTLE_ORANGE_500,
    600: SECONDARY_SUBTLE_ORANGE_600,
    700: SECONDARY_SUBTLE_ORANGE_700,
    800: SECONDARY_SUBTLE_ORANGE_800,
    900: SECONDARY_SUBTLE_ORANGE_900,
    1000: SECONDARY_SUBTLE_ORANGE_1000,
  },
  secondarySubtleYellow: {
    100: SECONDARY_SUBTLE_YELLOW_100,
    200: SECONDARY_SUBTLE_YELLOW_200,
    300: SECONDARY_SUBTLE_YELLOW_300,
    400: SECONDARY_SUBTLE_YELLOW_400,
    500: SECONDARY_SUBTLE_YELLOW_500,
    600: SECONDARY_SUBTLE_YELLOW_600,
    700: SECONDARY_SUBTLE_YELLOW_700,
    800: SECONDARY_SUBTLE_YELLOW_800,
    900: SECONDARY_SUBTLE_YELLOW_900,
    1000: SECONDARY_SUBTLE_YELLOW_1000,
  },
  secondaryGold: {
    100: SECONDARY_GOLD_100,
    200: SECONDARY_GOLD_200,
    300: SECONDARY_GOLD_300,
    400: SECONDARY_GOLD_400,
    500: SECONDARY_GOLD_500,
    600: SECONDARY_GOLD_600,
    700: SECONDARY_GOLD_700,
    800: SECONDARY_GOLD_800,
    900: SECONDARY_GOLD_900,
    1000: SECONDARY_GOLD_1000,
  },
  secondaryLimeGreen: {
    100: SECONDARY_LIME_GREEN_100,
    200: SECONDARY_LIME_GREEN_200,
    300: SECONDARY_LIME_GREEN_300,
    400: SECONDARY_LIME_GREEN_400,
    500: SECONDARY_LIME_GREEN_500,
    600: SECONDARY_LIME_GREEN_600,
    700: SECONDARY_LIME_GREEN_700,
    800: SECONDARY_LIME_GREEN_800,
    900: SECONDARY_LIME_GREEN_900,
    1000: SECONDARY_LIME_GREEN_1000,
  },

  text: {
    primary: NEUTRAL_COOL_1000,
  },
};
