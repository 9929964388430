import {
  Box,
  Button,
  Dialog,
  DialogActions,
  InputAdornment,
  Typography,
  useTheme,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { matchSorter } from 'match-sorter';
import React, { useState, useMemo } from 'react';
import { Column } from 'react-table';
import { AssetClassKey, Sector } from 'vise-types/pce2_instrument';
import DialogTitle from '~/synth/DialogTitle';
import { DataTableActionBar, DataTable, DataTableContainer } from '~/synth/DataTable';
import { SearchIcon } from '~/synth/Icons';
import { formatQuantity, formatCurrency, formatPercent } from '~/utils/format';
import TextField from '~/synth/TextField';
import { SECTOR_TO_LABEL_MAP } from '../Constants';
import { PricedPortfolioHolding, getPCE2AssetClassTitleAndSubtitle } from '../portfolioUtil';
import { Locked, NoResultsFound } from './TableComponents';

const StyledDialogTitle = withStyles(DialogTitle, (theme) => ({
  root: {
    padding: theme.spacing(3),
    boxShadow: theme.shadows[1],
  },
}));

// This table container also doubles as a dialog content container
// for this use case so we need some different styles.
const StyledTableContainer = withStyles(DataTableContainer, (theme) => ({
  root: {
    height: '80vh',
    // Prevent scrolling on container.
    overflow: 'clip',
    boxShadow: 'none',
    border: 'none',
    background: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

interface HoldingsModalProps {
  title: string;
  subtitle?: string;
  data: PricedPortfolioHolding[];
  type?: 'asset' | 'sector';
  onClose: () => void;
  open: boolean;
  smallAccount?: boolean;
}

export default function HoldingsModal({
  title,
  subtitle,
  data,
  type,
  onClose,
  open,
  smallAccount,
}: HoldingsModalProps) {
  const theme = useTheme();
  const [filterQuery, setFilterQuery] = useState('');
  const tableData = useMemo(
    () =>
      matchSorter(data, filterQuery, {
        keys: ['ticker', 'name', 'sector', 'assetClass'],
        threshold: matchSorter.rankings.CONTAINS,
      }),
    [filterQuery, data]
  ) as PricedPortfolioHolding[];

  const columns = React.useMemo(
    () =>
      [
        { accessor: 'locked', Cell: (props) => <Locked locked={props.value} />, width: '3%' },
        {
          Header: 'Ticker',
          accessor: 'ticker',
          width: '8%',
        },
        {
          Header: 'Name',
          accessor: 'name',
          width: '25%',
          Cell: (props) => (
            <div
              title={props.value}
              style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden' }}
            >
              {props.value}
            </div>
          ),
          sortType: 'caseInsensitive',
        },
        {
          Header: type === 'asset' ? 'Asset class' : 'Sector',
          accessor: type === 'asset' ? 'assetClass' : 'sector',
          width: '23%',
          Cell: (props: { value: string | AssetClassKey }) => {
            let label: string | undefined = props.value;
            if (!label) {
              return '-';
            }
            if (type === 'asset') {
              const assetDisplayName = getPCE2AssetClassTitleAndSubtitle(
                props.value as AssetClassKey,
                smallAccount
              );

              label =
                (assetDisplayName.subtitle || '') +
                (assetDisplayName.subtitle
                  ? ` - ${assetDisplayName.title}`
                  : assetDisplayName.title);
            } else {
              label = props.value
                ? SECTOR_TO_LABEL_MAP.get(props.value.split('/')[0] as Sector)
                : undefined;
            }
            return (
              <div
                title={label}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden' }}
              >
                {label}
              </div>
            );
          },
        },
        {
          Header: 'QTY',
          accessor: 'shares',
          Cell: (props) => <div style={{ textAlign: 'right' }}>{formatQuantity(props.value)}</div>,
          align: 'right',
          width: '8%',
        },
        {
          Header: 'Price',
          accessor: 'price',
          Cell: (props) => <div style={{ textAlign: 'right' }}>{formatCurrency(props.value)}</div>,
          align: 'right',
          width: '10%',
        },
        {
          Header: 'Allocation',
          accessor: (d) => d.allocation.toFixed(5),
          Cell: (props: { value: number }) => (
            <div style={{ textAlign: 'right' }}>
              {props.value < 0.0005 ? '< 0.1%' : formatPercent(props.value, 1)}
            </div>
          ),
          align: 'right',
          width: '10%',
        },
        {
          Header: <Box pr={1}>Value</Box>,
          accessor: 'value',
          Cell: (props) =>
            props.value && props.value > 0 ? (
              <div style={{ textAlign: 'right' }}>{formatCurrency(props.value)}</div>
            ) : (
              <></>
            ),
          align: 'right',
          width: '13%',
        },
      ] as Column<PricedPortfolioHolding>[],
    [type, smallAccount]
  );

  const simpleColumns = React.useMemo(
    () =>
      [
        { accessor: 'locked', Cell: (props) => <Locked locked={props.value} />, width: '3%' },
        {
          Header: 'Ticker',
          accessor: 'ticker',
          width: '8%',
        },
        {
          Header: 'Name',
          accessor: 'name',
          width: '48%',
          Cell: (props) => <div>{props.value}</div>,
          sortType: 'caseInsensitive',
        },
        {
          Header: 'QTY',
          accessor: 'shares',
          Cell: (props) => (
            <div style={{ textAlign: 'right' }}>
              {props.row.original.assetClass === 'CASH' ? null : props.value.toFixed()}
            </div>
          ),
          align: 'right',
          width: '10%',
        },
        {
          Header: 'Price',
          accessor: 'price',
          Cell: (props) => (
            <div style={{ textAlign: 'right' }}>
              {props.row.original.assetClass === 'CASH' ? null : formatCurrency(props.value)}
            </div>
          ),
          align: 'right',
          width: '10%',
        },
        {
          Header: 'Allocation',
          accessor: (d) => d.allocation.toFixed(5),
          Cell: (props: { value: number }) => (
            <div style={{ textAlign: 'right' }}>
              {props.value < 0.0005 ? '< 0.1%' : formatPercent(props.value, 1)}
            </div>
          ),
          align: 'right',
        },
        {
          Header: <Box pr={1}>Value</Box>,
          accessor: 'value',
          Cell: (props) =>
            props.value && props.value > 0 ? (
              <div style={{ textAlign: 'right' }}>{formatCurrency(props.value)}</div>
            ) : (
              <></>
            ),
          align: 'right',
          width: '13%',
        },
      ] as Column<PricedPortfolioHolding>[],
    []
  );

  return (
    <Dialog keepMounted maxWidth="lg" open={open} onClose={onClose} data-testid="holdings">
      <StyledDialogTitle onClose={onClose}>
        <Box mb={0.5}>{title}</Box>
        <Typography variant="body1" color="textSecondary">
          {subtitle}
        </Typography>
      </StyledDialogTitle>
      <StyledTableContainer>
        <DataTableActionBar>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box width="400px">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                id="filter_accounts"
                aria-label="Search holdings"
                placeholder="Search holdings"
                name="filter_holdings"
                onChange={(e) => setFilterQuery(e.target.value)}
                fullWidth
              />
            </Box>
            <Box color="grey.600" pr={2}>
              <Typography variant="body2">
                {tableData.length} {tableData.length > 1 ? 'holdings' : 'holding'}
              </Typography>
            </Box>
          </Box>
        </DataTableActionBar>
        <DataTable
          data={tableData}
          columns={type ? columns : simpleColumns}
          tableMaxHeight="calc(100% - 80px)"
          sortable
        />
        {!tableData.length && <NoResultsFound />}
      </StyledTableContainer>
      <DialogActions style={{ boxShadow: theme.shadows[6] }}>
        <Button variant="outlined" onClick={onClose} color="secondary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
