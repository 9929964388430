import { Box, LinearProgress as LinearProgressM, LinearProgressProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

// Disable no-unused-vars here so we can easily extract passed props other than color
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledProgress = styled(({ color, ...other }) => <LinearProgressM {...other} />)<
  LinearProgressProps & { color?: string; height?: string }
>`
  & .MuiLinearProgress-barColorPrimary {
    background-color: ${({ color, theme }) => color || theme.palette.primary.main};
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }
`;

const Progress = ({
  color,
  defaultValue,
  my = 2,
  value,
  variant = 'determinate',
}: {
  color?: string;
  defaultValue?: number;
  height?: string;
  my?: number;
  value?: number;
  variant?: string;
}) => (
  <Box my={my}>
    <StyledProgress color={color} defaultValue={defaultValue} value={value} variant={variant} />
  </Box>
);

export default Progress;
