import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, Divider, Typography } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import { startCase } from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { createBondProposal } from '~/api/api';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useEnqueueToast from '~/hooks/useToast';
import { routeUrls } from '~/routes/BondPortfolioCreator/steps';
import { ReactComponent as PencilIcon } from '~/static/images/icons/pencil.svg';
import amplitude from '~/utils/amplitude';
import { formatCurrency, getCustodianDisplayName, maskAccountNumber } from '~/utils/format';
import {
  CREDIT_QUALITY_TO_LABEL,
  DURATION_OR_MATURITY_TO_LABEL,
  INSTRUMENT_TO_LABEL,
  STRUCTURE_TO_LABEL,
  metricsContent,
} from '../BondPortfolio/Utils';
import { BondScreenProps, bondPortfolioStateToSettings } from './BondPortfolioState';
import StepTitleCard from './components/StepTitleCard';
import { mapStateValueToLabel } from './states';

function SummarySection({
  title,
  content,
  route,
}: {
  title: string;
  content: { field: React.ReactNode; value: React.ReactNode }[];
  route?: keyof typeof routeUrls;
}) {
  return (
    <CardContent>
      <Box display="flex" justifyContent="space-between" alignItems="center" pb={1}>
        <Typography variant="h3">{title}</Typography>
        {route && (
          <Link to={routeUrls[route]}>
            <Button
              href={routeUrls[route]}
              variant="text"
              color="primary"
              size="small"
              startIcon={<PencilIcon width={16} />}
            >
              Edit
            </Button>
          </Link>
        )}
      </Box>
      <Divider />
      <Box mt={3}>
        {content.map(({ field, value }, index) => (
          <Box
            key={value?.toString()}
            display="flex"
            justifyContent="space-between"
            py={1}
            px={1.5}
            bgcolor={index % 2 === 1 ? tokens.palette.neutralCool[100] : ''}
          >
            <Box color="black">{field}</Box>
            <Box color={tokens.palette.neutralCool[800]}>{value}</Box>
          </Box>
        ))}
      </Box>
    </CardContent>
  );
}

export default function Summary({ state, footer, dispatch }: BondScreenProps) {
  useEffect(() => window.scroll(0, 0), []);

  const history = useHistory();
  const enqueueToast = useEnqueueToast();
  const { account, client, cashValue, taxes, buildInputs } = state;
  const clientContent = [
    { field: 'Household', value: client?.clientGroup?.name },
    { field: 'Client name', value: `${client?.firstName} ${client?.lastName}` },
  ];
  const accountContent =
    account != null
      ? [
          { field: 'Account name', value: account.accountName },
          { field: 'Custodian', value: getCustodianDisplayName(account.custodianKey) },
          { field: 'Account number', value: maskAccountNumber(account.accountNumber) },
          { field: 'Account type', value: account.taxable ? 'Non-qualified' : 'Qualified' },
          { field: 'Account value', value: formatCurrency(account.cachedAum) },
          { field: 'Proposal type', value: 'Linked to account' },
        ]
      : [
          { field: 'Account value', value: formatCurrency(cashValue) },
          { field: 'Proposal type', value: 'Sample proposal' },
        ];

  const taxContent = [
    ...(taxes.taxFillingStatus
      ? [{ field: 'Tax filing status', value: startCase(taxes.taxFillingStatus) }]
      : []),
    ...(taxes.state ? [{ field: 'Tax state filing', value: taxes.state }] : []),
    {
      field: 'Estimated federal rate',
      value: `${taxes.estimatedFederalRate}%`,
    },
    {
      field: 'Estimated state rate',
      value: `${taxes.estimatedStateRate}%`,
    },
    ...(taxes.isAlternativeMinimumTax !== ''
      ? [
          {
            field: 'Subject to AMT',
            value: taxes.isAlternativeMinimumTax === 'true' ? 'Yes' : 'No',
          },
        ]
      : []),
  ];

  const buildContent = [
    { field: 'Bond sector', value: INSTRUMENT_TO_LABEL[buildInputs.bondSector] },
    ...(buildInputs.bondSector === 'MUNICIPAL'
      ? [{ field: 'State strategy', value: mapStateValueToLabel[buildInputs.stateStrategy] }]
      : []),
    {
      field: 'Product type',
      value: STRUCTURE_TO_LABEL[buildInputs.structure],
    },
    {
      field: `${buildInputs.maturity ? 'Maturity' : 'Duration'} structure`,
      value: DURATION_OR_MATURITY_TO_LABEL[buildInputs.duration || buildInputs.maturity],
    },

    ...(buildInputs.bondSector === 'MUNICIPAL'
      ? [
          { field: 'Tax loss harvesting', value: buildInputs.activeTaxManagement ? 'Yes' : 'No' },
          { field: 'Credit quality', value: CREDIT_QUALITY_TO_LABEL[buildInputs.creditQuality] },
        ]
      : []),
  ];

  async function handleCreate() {
    try {
      amplitude().logEvent('Bond Builder - Create proposal', {
        category: EVENT_CATEGORIES.BOND_BUILDER,
      });
      const settings = bondPortfolioStateToSettings(state);
      const proposal = await createBondProposal(settings);
      dispatch({ type: 'SET_IS_LOADING', value: true });
      // User experience
      await new Promise((resolve) => setTimeout(resolve, 3000));
      history.push(`/secure/bond-proposal/${proposal.data.id}/overview`);
    } catch (e) {
      enqueueToast({
        title: 'There was an error creating the proposal.',
        content: 'Please try again later.',
        severity: 'error',
      });
    }
  }

  return (
    <>
      <StepTitleCard title="Portfolio Summary" />
      <Card>
        <SummarySection
          route="SELECT_CLIENT"
          title="Client Details"
          content={[...clientContent, ...accountContent]}
        />

        <SummarySection route="TAXES" title="Taxes" content={taxContent} />

        <SummarySection route="BUILD" title="Portfolio design" content={buildContent} />

        {state.sampleData && (
          <SummarySection
            title="Bond portfolio characteristics"
            content={metricsContent(state.sampleData.metrics, state.sampleData.holdings)}
          />
        )}
        {footer &&
          footer(
            <LoadingButton color="primary" variant="contained" onClick={handleCreate}>
              Create proposal
            </LoadingButton>
          )}
      </Card>
    </>
  );
}
