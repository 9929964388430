"use client";

import React from "react";
import {
  type DialogProps as MuiDialogProps,
  Dialog as MuiDialog,
  styled,
  useTheme,
  Box,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { SPACING_INDICES } from "../tokens/spacing.js";
import XIcon from "./Icons/XIcon.js";
import ExclamationCircleIcon from "./Icons/ExclamationCircleIcon.js";

type DialogProps = {
  variant: "destructive" | "base";
  size: "small" | "large";
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: React.ReactNode;
  confirmation: React.ReactNode;
  disabled?: boolean;
} & Omit<
  MuiDialogProps,
  | "variant"
  | "size"
  | "open"
  | "onClose"
  | "onConfirm"
  | "title"
  | "confirmation"
  | "disabled"
>;

const StyledDialog = styled(MuiDialog, {
  shouldForwardProp: (prop) => prop !== "size",
})<MuiDialogProps & { size: "small" | "large" }>(({ size }) => ({
  "& .MuiDialog-paper": {
    width: size === "small" ? "400px" : "600px",
  },
}));

/**
 * WIP
 * @param props See DialogProps type definition
 * @returns
 */
export default function Dialog(props: DialogProps): React.ReactElement {
  const {
    size,
    onClose,
    title,
    children,
    confirmation,
    onConfirm,
    disabled,
    variant,
    ...muiProps
  } = props;
  const theme = useTheme();
  return (
    <StyledDialog size={size} {...muiProps}>
      <Box p={SPACING_INDICES.sm} display="flex" justifyContent="end">
        <IconButton onClick={onClose}>
          <XIcon fontSize="large" htmlColor={theme.palette.neutralCool[1000]} />
        </IconButton>
      </Box>
      <Box mx={SPACING_INDICES.xl}>
        <Box display="flex" alignItems="center">
          {variant === "destructive" ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mr={SPACING_INDICES.sm}
            >
              <ExclamationCircleIcon fontSize="large" />
            </Box>
          ) : null}
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Box mt={SPACING_INDICES.xl} mb={SPACING_INDICES.lg}>
          {children}
        </Box>
      </Box>
      <Box p={SPACING_INDICES.xl}>
        <Button
          disabled={disabled}
          variant={variant === "destructive" ? "destructive" : "primary"}
          fullWidth
          onClick={onConfirm}
        >
          {confirmation}
        </Button>
        <Box mt={SPACING_INDICES.lg}>
          <Button
            disabled={disabled}
            variant="tertiary"
            fullWidth
            onClick={onClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
}
