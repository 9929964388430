import { Box, Divider, Typography, Tooltip } from '@mui/material';
import React from 'react';
import { format, subDays } from 'date-fns';
import { ReactComponent as InsightsBulbIcon } from '~/static/images/icons/insights-bulb.svg';
import { ReactComponent as PortfolioChartIcon } from '~/static/images/icons/portfolio-chart.svg';
import UnderlineTooltipIndicator from '~/synth/UnderlineTooltipIndicator';

const LastUpdated = ({
  date,
  insightsDate,
  hideTooltip,
}: {
  date: Date;
  insightsDate?: Date;
  hideTooltip?: boolean;
}) => {
  const updatedDate = format(insightsDate ? subDays(insightsDate, 1) : date, 'MMM d');
  return (
    <Box display="flex" alignItems="center">
      <Box color="grey.500" mr={3}>
        <Typography variant="h5">Last updated:</Typography>
      </Box>
      {insightsDate && (
        <>
          <Box display="flex" alignItems="center">
            <InsightsBulbIcon width={20} height={20} />
            <Box ml={1}>
              <Tooltip title="Portfolio insights are updated each time an account is rebalanced.">
                <UnderlineTooltipIndicator color="grey.500">
                  {format(insightsDate, 'MMM d')}
                </UnderlineTooltipIndicator>
              </Tooltip>
            </Box>
          </Box>
          <Box mx={2}>
            <Divider orientation="vertical" style={{ height: '24px' }} />
          </Box>
        </>
      )}
      <Box display="flex" alignItems="center">
        <PortfolioChartIcon width={20} height={20} />
        <Box ml={1}>
          {hideTooltip ? (
            updatedDate
          ) : (
            <Tooltip title="Account data is refreshed daily from the custodian. Recent trades may not be reflected yet.">
              <UnderlineTooltipIndicator color="grey.500">
                {format(insightsDate ? subDays(insightsDate, 1) : date, 'MMM d')}
              </UnderlineTooltipIndicator>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LastUpdated;
