import React, { useEffect, useState } from 'react';
import { Alert, Box, Card, CardContent, Container, Grid, Typography, Button } from '@mui/material';
import { useRouteMatch } from 'react-router';
import { Helmet } from 'react-helmet';
import ViseLogoBlack from '~/static/images/vise-logo-black.svg';
import TestimonialsSidebar from '~/routes/Registration/components/TestimonialsSidebar';
import { confirmEmail } from '~/api/register';
import LoadingSpinner from '~/synth/LoadingSpinner';

type ConfirmationStatus = 'Loading' | 'Success' | 'Error';

export default function ConfirmEmail() {
  const match = useRouteMatch<{ confirmationCode: string }>();
  const { confirmationCode } = match.params;
  const [confirmationStatus, setConfirmationStatus] = useState<ConfirmationStatus>('Loading');

  useEffect(() => {
    const confirmUserEmail = async (confirmationCode: string) => {
      try {
        await confirmEmail(confirmationCode);
        setConfirmationStatus('Success');
      } catch (err) {
        // Probably already confirmed.
        if ((err as { response: { status: number } }).response?.status === 404) {
          window.location.href = '/404';
        } else {
          setConfirmationStatus('Error');
        }
      }
    };

    confirmUserEmail(confirmationCode);
  }, [confirmationCode]);

  return (
    <div>
      <Helmet>
        <title>Email confirmation</title>
      </Helmet>
      <Container
        maxWidth={false}
        sx={{
          padding: '0 !important',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Grid container sx={{ width: '100%', height: '100%' }}>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              mt: { xs: 1, sm: 4, xl: 8 },
              mb: { xs: 1 },
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                p: 3,
                width: { xs: '100%', sm: '400px' },
                overflowX: 'hidden',
              }}
            >
              <img src={ViseLogoBlack} alt="logo" height="32" style={{ marginBottom: 80 }} />
              <Card elevation={0} sx={{ overflow: 'visible' }}>
                <CardContent
                  sx={{
                    px: '2px',
                    py: 0,
                  }}
                >
                  {confirmationStatus === 'Error' ? (
                    <Alert severity="error">
                      Error confirming your email. Please contact support.
                    </Alert>
                  ) : (
                    <Box>
                      {confirmationStatus === 'Success' ? (
                        <>
                          <Box pb={2.7}>
                            <Typography variant="h1">Email confirmed</Typography>
                          </Box>
                          <Box pb={0.5}>
                            <Typography paragraph variant="body2" color="text.secondary">
                              Thanks for confirming your email. You can now access the Vise
                              platform.
                            </Typography>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              py={4.5}
                            >
                              <Button variant="contained" href="/secure/onboarding-welcome">
                                Go to Login page
                              </Button>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <Box pb={2.7}>
                          <LoadingSpinner />
                        </Box>
                      )}
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <TestimonialsSidebar variant="PORTFOLIO_INTEL" />
        </Grid>
      </Container>
    </div>
  );
}
