import React from 'react';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import styled from 'styled-components';

interface VirtualizedListProps {
  noRowsRenderer?: () => void;
  onRowClick?: () => void;
  rowCount: number;
  rowHeight: number;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowRenderer: (row: ListRowProps) => any;
}

const StyledList = styled(List)`
  outline: none;
`;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/prefer-default-export
export class VirtualizedList extends React.PureComponent<VirtualizedListProps> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    rowHeight: 40,
  };

  public render() {
    const { rowCount, rowRenderer, ...tableProps } = this.props;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <StyledList
            height={height}
            rowCount={rowCount}
            rowRenderer={rowRenderer}
            width={width}
            {...tableProps}
          />
        )}
      </AutoSizer>
    );
  }
}
