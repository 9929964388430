import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useClients from '~/hooks/useClients';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useHousehold from '~/hooks/useHousehold';
import useInsertIntelligence from '~/hooks/useInsertIntelligence';
import { Account, NewClient, NewHousehold, RawClient, RawHousehold } from '~/models/api';
import summaryBannerImage from '~/static/images/summary-banner-image.svg';
import Skeleton from '~/synth/Skeleton';
import amplitude from '~/utils/amplitude';
import useToast from '~/hooks/useToast';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { useHistory } from 'react-router';
import {
  createClient,
  executePortfolioIntelligence,
  updateUserMetadata,
} from '../../../../api/api';
import { RootState } from '../../../../reducers';
import preparePortfolioIntelligenceData from '../../preparePortfolioIntelligenceData';
import { ConstructionInfo, ScreenProps } from '../../Types';
import { shouldShowCapitalLossesAndGainsScreen } from '../../utils';
import { ActionBarV2, BackButton } from '../components';
import CreatePortfolioButton from '../SummaryLandingScreen/CreatePortfolioButton';
import SummaryLandingScreen, { Body, HeaderContainer } from './SummaryLandingScreen';
import { Section } from '../components/SummarySections';
import { SMALL_ACCOUNT_MIGRATION_STARTING_POINT } from '../../Constants';

function buildNewClientData(clientData: NewClient) {
  return {
    ...clientData,
    employmentStatus: 'SELF_EMPLOYED',
    lossBehavior: 'HOLD',
  } as RawClient;
}

const NUMBER_OF_SKELETON_SECTIONS = 2;
const NUMBER_OF_SKELETON_SUBSECTIONS = 4;

const BootstrappingView = () => {
  return (
    <>
      <HeaderContainer>
        <Box flex={1} py={3}>
          <Typography variant="h1">Portfolio Summary</Typography>
        </Box>
        <img alt="" src={summaryBannerImage} />
      </HeaderContainer>
      <Body>
        {[...Array(NUMBER_OF_SKELETON_SECTIONS).keys()].map((k) => (
          <Section isOuterSection key={k}>
            <Section>
              <Box display="flex" justifyContent="space-between">
                <Skeleton width="150px" height="40px" />
                <Button disabled color="secondary">
                  Edit
                </Button>
              </Box>
            </Section>
            {[...Array(NUMBER_OF_SKELETON_SUBSECTIONS).keys()].map((x) => (
              <Section key={x}>
                <Skeleton width="150px" height="15px" />
                <Skeleton width="250px" height="25px" />
              </Section>
            ))}
          </Section>
        ))}
      </Body>
      <ActionBarV2>
        <Box display="flex" justifyContent="space-between">
          <BackButton disabled />
          <Box textAlign="right">
            <CreatePortfolioButton disabled />
          </Box>
        </Box>
      </ActionBarV2>
    </>
  );
};

export default function SummaryLandingScreenContainer({
  dpDispatch,
  draftPortfolio,
  onBack,
  onComplete,
  setLargestStepVisited,
}: ScreenProps) {
  const {
    accountSize,
    bootstrappingState,
    constructionMetadata,
    constructionInfo,
    lockedPositions,
    newClientInfo,
    newHouseholdInfo,
    newSavedStrategyName,
    overwriteExistingStrategy,
    strategyId,
    strategyInternalUuid,
    allocationsTemplateId,
    restrictionsTemplatesIds,
  } = draftPortfolio;

  useEffect(() => {
    amplitude().logEvent('Impression - Summary', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });
  }, []);

  const enqueueCoachmark = useEnqueueCoachmark();
  const [awaitingPost, setAwaitingPost] = useState(false);
  const { data: clientsData } = useClients(constructionInfo.clientId);
  const { data: householdData } = useHousehold(
    newHouseholdInfo?.type === 'existing' ? newHouseholdInfo.household.id : null
  );

  let client: RawClient | NewClient | undefined;
  let household: Pick<RawHousehold, 'name'> | RawHousehold | undefined;
  const isBootstrapping = bootstrappingState === 'LOADING' || bootstrappingState === 'ERROR';

  if (!isBootstrapping) {
    if (constructionInfo.clientId != null) {
      client = clientsData?.find((clientDatum) => clientDatum.id === constructionInfo.clientId);
      household = client?.clientGroup;
    } else if (newHouseholdInfo?.type === 'existing') {
      client = newClientInfo?.client;
      household = householdData;
    } else {
      client = newClientInfo?.client;
      household = newHouseholdInfo?.household;
    }
  }

  const { insertIntelligence } = useInsertIntelligence();
  const newHouseholdType = newHouseholdInfo?.type;

  const { concentrationLimits, existingPortfolio } = constructionInfo;
  const { data: featureFlags } = useFeatureFlags();
  const mustCreatePortfolioFromStart =
    !isBootstrapping && accountSize !== 'FULL' && featureFlags?.disable_small_accounts_ui === 'on';
  const cashConcentrationLimit = concentrationLimits?.find(
    (concentrationLimit) => concentrationLimit.asset_class === 'Cash'
  );
  const sortedLockedPositions = useMemo(
    () => (lockedPositions === null ? null : lockedPositions.slice().sort()),
    [lockedPositions]
  );

  const userId = useSelector((state: RootState) => state.users.currentUser.id);

  const account =
    existingPortfolio !== 'sample-portfolio'
      ? (constructionInfo.existingPortfolio as Account)
      : null;

  const enqueueToast = useToast();

  // This does not use `useCallback` because some of its dependencies are only set conditionally,
  // but hooks must not be run conditionally. Creating the function on each render ensures correct
  // data is used when the function is called.

  async function handleCreatePortfolioIntelligence({
    proposalName,
    executeProposal,
  }: { proposalName?: string; executeProposal?: boolean } = {}) {
    setAwaitingPost(true);
    amplitude().logEvent('Create proposal', {
      category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
    });

    let clientForConstruction: RawClient | null = null;
    let constructionInfoWithClient: ConstructionInfo;

    // Only existing clients will have an `id`. This enables TypeScript to differentiate between
    // "new" and "existing" client types.
    if (client != null && 'id' in client) {
      clientForConstruction = client;
      constructionInfoWithClient = constructionInfo;
    } else {
      let newClient: NewClient | undefined;
      let newHousehold: NewHousehold | undefined;
      if (newHouseholdType === 'existing' && client != null) {
        newClient = {
          ...client,
          // We know `household` is of the type RawHousehold here because
          // when newHouseholdInfo.type === 'existing' (meaning newHouseholdType === 'existing'),
          // `household` is set to a RawHousehold value above
          clientGroupId: (household as RawHousehold).id,
        };
      } else {
        newClient = client;
        newHousehold = household;
      }

      try {
        if (newClient != null) {
          clientForConstruction = await createClient(buildNewClientData(newClient), newHousehold);
        }
      } catch (error) {
        setAwaitingPost(false);
        enqueueCoachmark({
          title: 'Error creating new client',
          content: 'Try again and contact us if the problem persists',
          severity: 'error',
        });
        return;
      }

      constructionInfoWithClient = {
        ...constructionInfo,
        clientId: clientForConstruction?.id ?? null,
      };
    }

    const preparedPortfolioIntelligenceData = preparePortfolioIntelligenceData(
      userId,
      constructionInfoWithClient,
      constructionMetadata,
      lockedPositions,
      {
        newSavedStrategyName,
        strategyId,
        strategyInternalUuid,
        overwriteExistingStrategy,
        allocationTemplateId: allocationsTemplateId || undefined,
        restrictionTemplateIds: restrictionsTemplatesIds || undefined,
      },
      false,
      !account || account.taxable,
      accountSize,
      proposalName
    );
    const createdPortfolioIntelligence = await insertIntelligence(
      preparedPortfolioIntelligenceData
    );

    await updateUserMetadata({ xrayOnboardingStatus: 'COMPLETED' });

    let executedProposal = false;
    if (createdPortfolioIntelligence != null && executeProposal) {
      try {
        await executePortfolioIntelligence(createdPortfolioIntelligence.id);
        executedProposal = true;
      } catch (error) {
        // TODO(rkurohara): handle error message properly
        enqueueToast({
          title: 'Error while executing proposal. Proposal was still created.',
          severity: 'error',
        });
      }
    }

    setAwaitingPost(false);

    if (createdPortfolioIntelligence) {
      onComplete({
        portfolioIntelligence: createdPortfolioIntelligence,
        executedProposal,
      });
    }
  }

  const history = useHistory();

  useEffect(() => {
    if (!mustCreatePortfolioFromStart || bootstrappingState !== 'READY') {
      return;
    }
    const url = `/secure/portfolio-creator-next/build-or-use-allocation-template`;
    dpDispatch({
      type: 'MIGRATE_SMALL_ACCOUNT',
    });
    history.replace(url);
    setLargestStepVisited(SMALL_ACCOUNT_MIGRATION_STARTING_POINT);
  }, [
    mustCreatePortfolioFromStart,
    history,
    dpDispatch,
    setLargestStepVisited,
    bootstrappingState,
  ]);

  const clientName = client == null ? 'Loading…' : `${client.firstName} ${client.lastName}`;
  const householdName = household == null ? 'Loading…' : household.name;

  // Because client info from the API is required in the case where a new client is being added to
  // an existing household, allow clicking "Create portfolio" only once client info is loaded.
  const isCreatePortfolioDisabled = client == null || household == null;
  // While loading we actually cannot determine the pce version of the intelligence so default to pce2.
  // This is not the perfect solution but as all new proposal should be pce2 we should default to such.
  if (isBootstrapping) {
    return <BootstrappingView />;
  }

  if (mustCreatePortfolioFromStart) {
    // We're going to redirect
    return null;
  }

  return (
    <SummaryLandingScreen
      clientName={clientName}
      householdName={householdName}
      draftPortfolio={draftPortfolio}
      sortedLockedPositions={sortedLockedPositions}
      shouldShowCapitalLossesAndGainsScreen={shouldShowCapitalLossesAndGainsScreen(draftPortfolio)}
      onBack={onBack}
      handleCreatePortfolioIntelligence={handleCreatePortfolioIntelligence}
      awaitingPost={awaitingPost}
      isCreatePortfolioDisabled={isCreatePortfolioDisabled}
      cashConcentrationLimit={cashConcentrationLimit}
      dpDispatch={dpDispatch}
    />
  );
}
