import useSWR from 'swr';
import generateSwrKey from '../api/generateSwrKey';
import { getAllAccountsWithHouseholdInfo } from '../api/api';

export const getAllAccountsSwrKey = (
  includeSummaries = false,
  includePi = true,
  transitioned: boolean | undefined,
  perfSuppressionEnabled: boolean,
  engineType: string | undefined
) =>
  generateSwrKey(
    `allAccountsWithPI${includeSummaries ? '?include_summaries=true' : ''}
    ${includePi ? '?include_pi=true' : ''}
    ${transitioned == null ? '' : `&transitioned=${transitioned}`}
    ${`perf_suppression_enabled=${perfSuppressionEnabled ? 'true' : 'false'}`} engineType=${
      engineType || ''
    }`
  );

export default function useAllAccountsWithHouseholdInfo({
  includeSummaries = false,
  includePi = true,
  transitioned,
  perfSuppressionEnabled = false,
  engineType,
}: {
  includeSummaries?: boolean;
  includePi?: boolean;
  transitioned?: boolean;
  perfSuppressionEnabled?: boolean;
  engineType?: string;
}) {
  return useSWR(
    getAllAccountsSwrKey(
      includeSummaries,
      includePi,
      transitioned,
      perfSuppressionEnabled,
      engineType
    ),
    () =>
      getAllAccountsWithHouseholdInfo(
        includeSummaries,
        includePi,
        transitioned,
        perfSuppressionEnabled,
        engineType
      )
  );
}
