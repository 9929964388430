import { Box, BoxProps, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Cadence, DistributionSchedule } from 'vise-types/distribution';
import Truncation from '~/routes/Portfolio/components/Truncation';
import { ReactComponent as ExclamationCircleIcon } from '~/static/images/icons/exclamation-circle.svg';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import { formatCurrency } from '~/utils/format';
import { CADENCE_TO_LABEL } from '../Constants';

export function getCadenceDisplayText(cadence: Cadence) {
  return cadence === 'DO_NOT_REPEAT' ? 'One-time' : 'Recurring';
}

export const DistributionDetailsRow = ({
  title,
  content,
  tooltip,
  ...boxProps
}: {
  title: React.ReactNode;
  content: string;
  tooltip?: string;
} & BoxProps) => (
  <Box display="flex" justifyContent="space-between" {...boxProps}>
    <Box color="grey.600" display="flex">
      {title}
      {tooltip != null ? (
        <Tooltip title={tooltip} placement="top">
          <Box ml={0.5} mt={0.25}>
            <InformationCircleIcon />
          </Box>
        </Tooltip>
      ) : null}
    </Box>
    <Box textAlign="right" width="85%">
      <Truncation>{content}</Truncation>
    </Box>
  </Box>
);

export const DistributionDetailsDisplay = ({
  distribution,
  variant,
}: {
  distribution: DistributionSchedule;
  variant: 'preview' | 'side-panel';
}) => {
  return (
    <>
      {distribution.accountName != null ? (
        <DistributionDetailsRow title="Account" content={distribution.accountName} my={2} />
      ) : null}
      <DistributionDetailsRow
        title="Description"
        content={
          distribution.description ?? `${getCadenceDisplayText(distribution.cadence)} distribution`
        }
        my={2}
      />
      <DistributionDetailsRow title="Amount" content={formatCurrency(distribution.amount)} my={2} />
      <DistributionDetailsRow
        title="Frequency"
        content={CADENCE_TO_LABEL[distribution.cadence]}
        my={2}
      />
      {distribution.cadence === 'DO_NOT_REPEAT' ? (
        <DistributionDetailsRow
          title="Scheduled date"
          content={moment(distribution.processOn).format('ll')}
          my={2}
        />
      ) : null}
      {distribution.cadence !== 'DO_NOT_REPEAT' && variant === 'preview' ? (
        <DistributionDetailsRow
          title="Starts on"
          content={moment(distribution.processOn).format('ll')}
          my={2}
        />
      ) : null}
      {distribution.cadence !== 'DO_NOT_REPEAT' && variant !== 'preview' ? (
        <DistributionDetailsRow
          title="Next distribution"
          content={
            distribution.nextRuns != null && distribution.nextRuns.length > 0
              ? moment(distribution.nextRuns?.[0]).format('ll')
              : '-'
          }
          my={2}
        />
      ) : null}
      {distribution.cadence !== 'DO_NOT_REPEAT' ? (
        <DistributionDetailsRow
          title="Ends on"
          content={distribution.processUntil ? moment(distribution.processUntil).format('ll') : '-'}
          my={2}
        />
      ) : null}
      <DistributionDetailsRow
        title="Wash sales"
        content={distribution?.allowWashSaleIfNeeded ? 'Permitted' : 'Not Allowed'}
        tooltip="If permitted, Vise will incur wash sales to fund this distribution if necessary. If not allowed, we'll cancel the distribution and attempt to resume payment on the next scheduled date in the event that wash sales are required to generate sufficient cash."
        my={2}
      />
    </>
  );
};

export const Circle = ({ diameter, ...boxProps }: { diameter: number } & BoxProps) => (
  <Box width={diameter} height={diameter} borderRadius="50%" {...boxProps} />
);

export const ExclamationCircle = ({ diameter, ...boxProps }: { diameter?: number } & BoxProps) => (
  <Circle
    diameter={diameter ?? 40}
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexShrink={0}
    {...boxProps}
  >
    <ExclamationCircleIcon width={18} height={18} />
  </Circle>
);

export const CASH_AVAILABLE_TOOLTIP =
  'Amount available consists of the value of unlocked positions in the portfolio excluding those that cannot be sold for compliance reasons as well as cash scheduled for another distribution.';

export const CashAvailableAndTooltip = ({ cashAvailable }: { cashAvailable: number }) => (
  <Box color="grey.600" mt={0.5} display="flex">
    <Typography variant="body1">Available: {formatCurrency(cashAvailable)}</Typography>
    <Tooltip title={CASH_AVAILABLE_TOOLTIP}>
      <Box ml={0.5}>
        <InformationCircleIcon />
      </Box>
    </Tooltip>
  </Box>
);

export const DateCell = ({ date }: { date: Date | null | undefined }) => (
  <Box color="grey.600">{date != null ? moment(date.toString()).format('ll') : '-'}</Box>
);

export const DescriptionCell = ({
  title,
  subtitle,
  paused,
  lowBalance,
}: {
  title: string;
  subtitle?: string;
  paused: boolean;
  lowBalance: boolean;
}) => (
  <Box display="flex" width="100%">
    {paused || lowBalance ? (
      <Box mr={2} display="flex" alignItems="center" justifyContent="center">
        <ExclamationCircleIcon width={18} height={18} color="#E86427" />
      </Box>
    ) : null}
    <Box alignSelf="center" width="80%" ml={paused || lowBalance ? 0 : 4}>
      <Truncation>{title}</Truncation>
      {subtitle != null && (
        <Box color="grey.600">
          <Typography variant="body1">{subtitle}</Typography>
        </Box>
      )}
    </Box>
  </Box>
);
