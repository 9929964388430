import { Box } from '@mui/material';
import React from 'react';
import { RUN_SOURCE } from 'vise-types/orders';
import { OptimizerMode } from 'vise-types/pce2';
import { ReactComponent as ArrowRightLeftIcon } from '~/static/images/icons/arrow-right-left.svg';
import { ReactComponent as ClipboardCheckIcon } from '~/static/images/icons/clipboard-check.svg';
import { ReactComponent as DollarCircleIcon } from '~/static/images/icons/currency-dollar.svg';
import { ReactComponent as HighTurnoverIcon } from '~/static/images/icons/high-turnover.svg';
import { ReactComponent as KnobsIcon } from '~/static/images/icons/knobs.svg';
import { ReactComponent as LowTurnoverIcon } from '~/static/images/icons/low-turnover.svg';
import { ReactComponent as MediumTurnoverIcon } from '~/static/images/icons/mid-turnover.svg';
import { ReactComponent as UserIcon } from '~/static/images/icons/user.svg';
import ViseLogoBlack from '~/static/images/vise-logo-black.svg';
import CardContent from '~/synth/CardContent';
import { TextHighlightTag } from '~/synth/Tag';
import { formatPercent } from '~/utils/format';
import {
  CATEGORY_TO_LABEL,
  MODE_TO_CATALYST,
  RUN_SOURCE_TO_INITIATOR,
  turnOverToSeverity,
} from '../TradeActivity/Utils';

export function TurnoverLabel({ turnover }: { turnover: number }) {
  const severity = turnOverToSeverity(turnover);
  let icon: JSX.Element;
  if (severity === 'HIGH') {
    icon = <HighTurnoverIcon width={40} />;
  } else if (severity === 'MED') {
    icon = <MediumTurnoverIcon width={40} />;
  } else {
    icon = <LowTurnoverIcon width={40} />;
  }
  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Box ml={1}>{formatPercent(turnover, 1)}</Box>
    </Box>
  );
}

export function TradeInitiatorLabel({ runSource }: { runSource: RUN_SOURCE }) {
  const initiator = RUN_SOURCE_TO_INITIATOR[runSource];
  return (
    <Box display="flex" alignItems="center">
      {initiator === 'ADVISOR' ? (
        <>
          <UserIcon />
          <Box ml={1.75}>Advisor</Box>
        </>
      ) : (
        <>
          <img alt="V" src={ViseLogoBlack} height="21" width="17" />
          <Box ml={1.75}>Vise</Box>
        </>
      )}
    </Box>
  );
}

export function CatalystLabel({ optimizerMode }: { optimizerMode: OptimizerMode }) {
  const catalyst = MODE_TO_CATALYST[optimizerMode];

  let label: React.ReactNode;
  switch (catalyst) {
    case 'REBALANCE':
      label = (
        <>
          <KnobsIcon />
          <Box ml={1.5}>Rebalance</Box>
        </>
      );
      break;
    case 'CONSTRAINTS':
      label = (
        <>
          <ClipboardCheckIcon />
          <Box ml={1.5}>Constraints</Box>
        </>
      );
      break;
    case 'CASH_RAISE':
      label = (
        <>
          <DollarCircleIcon />
          <Box ml={1.5}>Cash raise</Box>
        </>
      );
      break;
    case 'INITIAL_TRADES':
      label = (
        <>
          <ArrowRightLeftIcon />
          <Box ml={1.5}>Initial trades</Box>
        </>
      );
      break;
    default:
      label = 'N/A';
  }
  return (
    <Box display="flex" alignItems="center">
      {label}
    </Box>
  );
}

export function IntelligenceLineItem({
  category,
  description,
  primary,
  isLast,
}: {
  category: string;
  description: string;
  primary?: boolean;
  isLast?: boolean;
}) {
  return (
    <CardContent style={{ borderBottom: `${isLast ? '' : '1px dashed #E9E9E9'}` }}>
      <Box fontWeight={500} mb={1.5} display="flex" alignItems="center">
        {CATEGORY_TO_LABEL[category]}
        {primary && (
          <Box ml={1}>
            <TextHighlightTag severity="success">Primary objective</TextHighlightTag>
          </Box>
        )}
      </Box>
      <Box color="grey.600">{description}</Box>
    </CardContent>
  );
}
