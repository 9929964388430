import React, { useState } from 'react';
import { ReactComponent as ArrowRightIcon } from '~/static/images/icons/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '~/static/images/icons/arrow-left.svg';
import {
  Box,
  Button,
  IconButton,
  Link,
  MobileStepper,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { tokens, theme } from '@vise_inc/ds-vise';

export function StepperBase({
  stepsLength,
  activeStep,
  setActiveStep,
}: {
  stepsLength: number;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}) {
  return (
    <>
      {/* This absolute positioning is a little janky but we aren't normally able to customize the order of the chevrons/dots in the mobile stepper */}
      <MobileStepper
        variant="dots"
        steps={stepsLength}
        activeStep={activeStep}
        position="static"
        sx={{
          '&.MuiMobileStepper-root': { background: 'none', padding: 0, position: 'relative' },
          '& .MuiMobileStepper-dot': {
            backgroundColor: tokens.palette.neutralCool[200],
          },
          '& .MuiMobileStepper-dots': {
            ml: 4,
            position: 'absolute',
          },
          '& .MuiMobileStepper-dotActive': {
            backgroundColor: tokens.palette.primaryBlue[600],
          },
        }}
        backButton={
          <IconButton
            aria-label="back"
            onClick={() => setActiveStep((prevStep) => prevStep - 1)}
            disabled={activeStep === 0}
            sx={{ position: 'absolute', right: '40px' }}
          >
            <ArrowLeftIcon height={16} color={tokens.palette.neutralCool[600]} />
          </IconButton>
        }
        nextButton={
          <IconButton
            aria-label="next"
            onClick={() => setActiveStep((prevStep) => prevStep + 1)}
            disabled={activeStep === stepsLength - 1}
            sx={{ position: 'absolute', right: 0 }}
          >
            <ArrowRightIcon height={16} color={tokens.palette.neutralCool[600]} />
          </IconButton>
        }
      />
    </>
  );
}

export default function Stepper({
  steps,
}: {
  steps: {
    actionType: string;
    title: string;
    body: string;
    cta: string;
    ctaLink: string;
  }[];
}) {
  const [activeStep, setActiveStep] = useState(0);
  return (
    <Box height="237px" display="flex" flexDirection="column">
      <Box flex={1}>
        <Typography variant="body2" color="neutralCool.600">
          {steps[activeStep].actionType}
        </Typography>
        <Box mt={2}>
          <Typography variant="h3">{steps[activeStep].title}</Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="body2" color="neutralCool.600">
            {steps[activeStep].body}
          </Typography>
        </Box>
        <Box mt={6}>
          {/* TODO add button component */}
          <ThemeProvider theme={theme}>
            <Button
              sx={{ boxShadow: 'none', '&:hover': { boxShadow: 'none' }, textTransform: 'none' }}
              variant="contained"
              component={Link}
              href={steps[activeStep].ctaLink}
            >
              {steps[activeStep].cta}
            </Button>
          </ThemeProvider>
        </Box>
      </Box>
      <StepperBase
        stepsLength={steps.length}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </Box>
  );
}
