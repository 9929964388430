import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link as MuiLink,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { find, findLast, first, groupBy, keyBy, last } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Custodian } from 'vise-types/custodian';
import { PortfolioIntelligenceOverview } from 'vise-types/pce1';
import {
  AccountStatus,
  BondPortfolioSettings,
  EngineType,
  PortfolioSummary,
} from 'vise-types/portfolio';
import AccountValueChart from '~/components/chart/AccountValueChart';
import DateRangeSelector from '~/components/chart/DateRangeSelector';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useHouseholdBondProposals from '~/hooks/useHouseholdBondProposals';
import ErrorFallback from '~/routes/Households/ErrorFallback';
import { ReactComponent as CollectionIcon } from '~/static/images/icons/collection.svg';
import { ReactComponent as UserGroupIcon } from '~/static/images/icons/user-group.svg';
import { EVENT_CATEGORIES } from '../../constants/amplitude';
import useHousehold from '../../hooks/useHousehold';
import useHouseholdAccountsWithSummary from '../../hooks/useHouseholdAccountsWithSummary';
import useHouseholdClients from '../../hooks/useHouseholdClients';
import useHouseholdProposals from '../../hooks/useHouseholdProposals';
import { ProposalSummary } from '../../models/household';
import Skeleton from '../../synth/Skeleton';
import WithLoader from '../../utils/WithLoader';
import amplitude from '../../utils/amplitude';
import { WHOLE_DOLLAR_FORMATTER, formatCurrency } from '../../utils/format';
import { HouseholdPortfoliosPerformanceQANotification } from '../Portfolio/AccountPerformanceQANotifications';
import HouseholdStat, { HouseholdStatHeader } from './components/HouseholdStat';
import { PROPOSALS_MODAL_CLOSED_SETTINGS } from './modal/ProposalEditRenameContextMenu';
import { ProposalsModal, ProposalsModalProps } from './modal/ProposalsModal';
import { SampleProposalsModal } from './modal/SampleProposalsModal';
import AccountsTable, { AccountsTableLoading } from './table/AccountsTable';
import MeetingNotes from './MeetingNotes';

type PropsType = {
  householdAggSummary: PortfolioSummary[];
  min?: number;
  max?: number;
};

function HouseholdAccountValueChart({ householdAggSummary, min, max }: PropsType) {
  const [extremes, setExtremes] = React.useState<{ max: number; min: number } | null>(null);
  const xAxisAfterSetExtremes = React.useCallback((event) => {
    setExtremes({ max: event.max, min: event.min });
  }, []);

  let aggSummaryDataInRange: [PortfolioSummary, PortfolioSummary] | null;
  if (householdAggSummary.length === 0) {
    aggSummaryDataInRange = null;
  } else if (extremes == null) {
    aggSummaryDataInRange = [
      first(householdAggSummary) as (typeof householdAggSummary)[number],
      last(householdAggSummary) as (typeof householdAggSummary)[number],
    ];
  } else {
    aggSummaryDataInRange = [
      find(
        householdAggSummary,
        (datum) => datum.date >= extremes.min
      ) as (typeof householdAggSummary)[number],
      findLast(
        householdAggSummary,
        (datum) => datum.date <= extremes.max
      ) as (typeof householdAggSummary)[number],
    ];
  }

  return (
    <>
      <CardContent>
        <HouseholdStatHeader>Household value</HouseholdStatHeader>
        <HouseholdStat>
          {aggSummaryDataInRange == null ? (
            <>N/A</>
          ) : (
            formatCurrency(aggSummaryDataInRange[1].marketValue, WHOLE_DOLLAR_FORMATTER)
          )}
        </HouseholdStat>
        <Box mt={0.5}>
          <Typography color="textSecondary" variant="caption">
            {aggSummaryDataInRange == null ? (
              <>N/A</>
            ) : (
              <>as of {moment.utc(aggSummaryDataInRange[1].date).format('LL')}</>
            )}
          </Typography>
        </Box>
      </CardContent>
      <AccountValueChart
        min={min}
        max={max}
        aggSummaries={householdAggSummary}
        valueSeriesName="Household value"
        valueSeriesTooltipTitle="Net cumulative balance (deposits - withdrawals + earnings) of all accounts within the household."
        xAxisAfterSetExtremes={xAxisAfterSetExtremes}
      />
    </>
  );
}

const SAMPLE_PROPOSAL_KEY = 'sample' as const;

export const getAccountKey = (custodianKey?: Custodian, accountNumber?: string) =>
  // Not ideal, but we don't have the accountDataSource here
  accountNumber && custodianKey && !accountNumber.startsWith('xray')
    ? `${custodianKey} ${accountNumber}`
    : SAMPLE_PROPOSAL_KEY;

export const reduceProposalsToSummaries = (
  proposals: PortfolioIntelligenceOverview[] | undefined
) => {
  return proposals?.reduce(
    (
      acc,
      {
        id,
        createdAt,
        constructionInfo: {
          focus,
          targetValue,
          distributionTimeline,
          initialValue,
          risk,
          existingPortfolio,
        },
        pceVersion,
        name,
      }
    ) => {
      const accountKey = getAccountKey(
        existingPortfolio?.custodianKey,
        existingPortfolio?.accountNumber
      );
      return {
        ...acc,
        [accountKey]: [
          ...(acc[accountKey] || []),
          {
            id,
            dateCreated: createdAt,
            riskScore: risk,
            startValue: initialValue,
            horizon: distributionTimeline,
            projectedValue: targetValue, // TODO: Verify
            focus,
            pceVersion,
            name,
          },
        ],
      };
    },
    {}
  );
};

const HouseholdOverview = () => {
  const {
    params: { householdId },
  } = useRouteMatch<{ householdId: string }>();

  const { data: featureFlags } = useFeatureFlags();
  const showPerformance = featureFlags?.enable_performance_data === 'on';

  const [proposalsModalSettings, setProposalsModalSettings] = useState<
    Pick<
      ProposalsModalProps,
      | 'open'
      | 'accountName'
      | 'proposals'
      | 'accountId'
      | 'accountNumber'
      | 'viseClientId'
      | 'accountId'
      | 'bondProposals'
      | 'engineType'
    >
  >(PROPOSALS_MODAL_CLOSED_SETTINGS);
  const closeProposalsModal = () => {
    setProposalsModalSettings(PROPOSALS_MODAL_CLOSED_SETTINGS);
  };
  const openProposalsModal = useCallback(
    (accountData: {
      accountName: string;
      proposals: ProposalSummary[];
      bondProposals: BondPortfolioSettings[];
      id: string;
      accountNumber: string;
      viseClientId?: string | undefined;
      engineType: EngineType;
    }) =>
      setProposalsModalSettings({
        open: true,
        accountName: accountData.accountName,
        proposals: accountData.proposals,
        accountId: accountData.id,
        accountNumber: accountData.accountNumber,
        viseClientId: accountData.viseClientId,
        bondProposals: accountData.bondProposals,
        engineType: accountData.engineType,
      }),
    []
  );

  const [isSampleProposalsModalOpen, setIsSampleProposalsModalOpen] = useState(false);
  const [activeChartType, setActiveChartType] = useState<'value' | 'performance' | 'meeting'>(
    'value'
  );

  const handleChartTypeChange = (
    _event: React.ChangeEvent<{}>,
    newValue: 'value' | 'performance' | 'meeting'
  ) => {
    setActiveChartType(newValue);
  };

  const {
    data: householdData,
    isLoading: householdIsLoading,
    error: householdError,
  } = useHousehold(householdId);
  const { name: householdName } = householdData || {};

  const {
    data: accountsData,
    isLoading: accountsIsLoading,
    error: accountsError,
  } = useHouseholdAccountsWithSummary(householdId);

  const {
    data: rawProposalsData,
    isLoading: proposalsIsLoading,
    error: proposalsError,
  } = useHouseholdProposals(householdId, { refreshInterval: 8000 });

  const { data: bondProposalData, error: bondProposalError } =
    useHouseholdBondProposals(householdId);

  const {
    data: clients,
    isLoading: clientsIsLoading,
    error: clientsError,
  } = useHouseholdClients(householdId);

  const {
    accounts,
    householdAggSummary,
    timeWeightedReturnsForValidPerformanceAccounts: timeWeightedReturns,
  } = accountsData || {};

  const validProposals = useMemo(() => {
    const accountsById = keyBy(accounts, 'id');
    return rawProposalsData?.filter(
      (p) =>
        !(p.pceVersion === 'pce1' && accountsById[p.accountId]?.intelligence?.pceVersion === 'pce2')
    );
  }, [rawProposalsData, accounts]);

  let currentChartFirstAvailableDate: moment.MomentInput | null;
  let currentChartLastAvailableDate: moment.MomentInput | null;

  if (activeChartType === 'performance' && timeWeightedReturns?.length !== 0) {
    currentChartFirstAvailableDate = timeWeightedReturns?.[0]?.date;
    currentChartLastAvailableDate = timeWeightedReturns?.[timeWeightedReturns.length - 1]?.date;
  } else {
    currentChartFirstAvailableDate = householdAggSummary?.[0]?.date;
    currentChartLastAvailableDate = householdAggSummary?.[householdAggSummary.length - 1]?.date;
  }

  const [chartRangeOptions, setChartRangeOptions] = React.useState<{
    min?: number;
    max?: number;
  }>({});

  const proposals: { [accountKey: string]: ProposalSummary[] } | undefined =
    reduceProposalsToSummaries(validProposals);

  const bondProposals = groupBy(bondProposalData, 'accountId');
  const bondCashProposals = bondProposalData?.filter(
    (p) => p.accountId == null || p.accountId === ''
  );

  const tableData = useMemo(
    () =>
      accounts && proposals && bondProposals
        ? {
            viseAccounts: accounts
              .filter((account) => account.transitioned)
              .map(
                ({
                  id,
                  name,
                  viseClientId,
                  intelligence,
                  accountMetrics,
                  accountNumber,
                  custodianKey,
                  summary,
                  portfolioIntelligenceId,
                  performanceQaRequired,
                  rebalancerPausedAt,
                  rebalancerStatus,
                  status,
                  taxable,
                  statusReason,
                  engineType,
                }) => ({
                  id,
                  accountMetrics,
                  accountName: name,
                  accountNumber,
                  viseClientId,
                  custodianKey,
                  riskScore: intelligence?.risk,
                  accountValue: summary.length > 0 ? summary[summary.length - 1].marketValue : 0,
                  proposals: proposals[getAccountKey(custodianKey, accountNumber)] || [],
                  rebalancerPausedAt,
                  status: status as AccountStatus,
                  portfolioIntelligenceId,
                  performanceQaRequired,
                  taxable,
                  rebalancerStatus,
                  rebalancerDisplayStatus: {
                    status: rebalancerStatus,
                    rebalancerPausedAt,
                  },
                  statusReason,
                  engineType,
                  bondProposals: bondProposals[id]?.filter((p) => !p.acceptedAt) || [],
                })
              ),
            untransitionedAccounts: accounts
              .filter((account) => !account.transitioned)
              .map(
                ({
                  id,
                  name,
                  accountNumber,
                  custodianKey,
                  viseClientId,
                  rebalancerPausedAt,
                  rebalancerStatus,
                  status,
                  taxable,
                  engineType,
                }) => ({
                  id,
                  viseClientId,
                  accountName: name,
                  accountNumber,
                  proposals: proposals[getAccountKey(custodianKey, accountNumber)] || [],
                  rebalancerPausedAt,
                  rebalancerStatus,
                  status,
                  taxable,
                  custodianKey,
                  engineType,
                  bondProposals: bondProposals[id]?.filter((p) => !p.acceptedAt) || [],
                })
              ),
          }
        : undefined,
    [accounts, proposals, bondProposals]
  );

  const loadingError =
    householdError || accountsError || proposalsError || clientsError || bondProposalError;
  if (loadingError) {
    return <ErrorFallback error={loadingError} />;
  }

  const numAccounts = accounts?.length ?? 0;
  const numAccountsRequiringPerformanceQA =
    accounts != null
      ? accounts?.reduce(
          (count, nextAccount) =>
            nextAccount.performanceQaRequired && nextAccount.portfolioIntelligenceId
              ? count + 1
              : count,
          0
        )
      : 0;

  const accountChartNode =
    householdAggSummary != null ? (
      <HouseholdAccountValueChart
        min={chartRangeOptions.min}
        max={chartRangeOptions.max}
        householdAggSummary={householdAggSummary}
      />
    ) : null;

  return (
    <Box px={6}>
      <Helmet>
        <title>Household</title>
      </Helmet>
      <ProposalsModal
        open={proposalsModalSettings.open}
        onClose={() => closeProposalsModal()}
        accountName={proposalsModalSettings.accountName}
        accountId={proposalsModalSettings.accountId}
        accountNumber={proposalsModalSettings.accountNumber}
        viseClientId={proposalsModalSettings.viseClientId}
        proposals={proposalsModalSettings.proposals}
        bondProposals={proposalsModalSettings.bondProposals}
        engineType={proposalsModalSettings.engineType}
      />
      <SampleProposalsModal
        open={isSampleProposalsModalOpen}
        proposals={proposals ? proposals[SAMPLE_PROPOSAL_KEY] : []}
        bondProposals={bondCashProposals as BondPortfolioSettings[]}
        onClose={() => setIsSampleProposalsModalOpen(false)}
      />
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4} mt={1}>
        <Box alignItems="center" display="flex">
          <Box mr={2}>
            <Typography variant="h1">
              <WithLoader isLoading={householdIsLoading} loader={<Skeleton inline width="10em" />}>
                <>{householdName}</>
              </WithLoader>
            </Typography>
          </Box>
        </Box>
        <Box alignItems="center" display="flex">
          <Box mr={1}>
            <Button
              component={Link}
              to={`/secure/households/${householdId}/manage`}
              variant="outlined"
              startIcon={<UserGroupIcon />}
              onClick={() => {
                amplitude().logEvent('Tap manage household', {
                  category: EVENT_CATEGORIES.HOUSEHOLDS,
                  householdId,
                });
              }}
            >
              Manage household
            </Button>
          </Box>
          <Button
            disabled={!proposals && !proposals?.[SAMPLE_PROPOSAL_KEY] && !bondCashProposals}
            onClick={() => setIsSampleProposalsModalOpen(true)}
            variant="outlined"
            startIcon={<CollectionIcon />}
          >
            View sample proposals
          </Button>
        </Box>
      </Box>
      {numAccountsRequiringPerformanceQA > 0 && (
        <Box mb={4}>
          <HouseholdPortfoliosPerformanceQANotification
            numAccountsRequiringPerformanceQA={numAccountsRequiringPerformanceQA}
          />
        </Box>
      )}
      <Box mb={2}>
        <Card id="stats">
          <CardContent>
            <Grid container alignItems="flex-end">
              <Grid item sm>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12} md={showPerformance ? 3 : 4}>
                    <HouseholdStatHeader>Clients</HouseholdStatHeader>
                    <HouseholdStat>
                      <WithLoader
                        isLoading={clientsIsLoading}
                        loader={<Skeleton inline width="4em" />}
                      >
                        <>{clients?.length}</>
                      </WithLoader>
                    </HouseholdStat>
                  </Grid>
                  <Grid item xs={12} md={showPerformance ? 3 : 4}>
                    <HouseholdStatHeader>Portfolios</HouseholdStatHeader>
                    <HouseholdStat>
                      <WithLoader
                        isLoading={!tableData?.viseAccounts}
                        loader={<Skeleton inline width="4em" />}
                      >
                        <>{tableData?.viseAccounts?.length}</>
                      </WithLoader>
                    </HouseholdStat>
                  </Grid>
                  <Grid item xs={12} md={showPerformance ? 3 : 4}>
                    <HouseholdStatHeader>Total value</HouseholdStatHeader>
                    <HouseholdStat>
                      <WithLoader
                        isLoading={accountsIsLoading}
                        loader={<Skeleton inline width="4em" />}
                      >
                        <>
                          {householdAggSummary != null && householdAggSummary?.length > 0
                            ? formatCurrency(
                                householdAggSummary[householdAggSummary.length - 1].marketValue
                              )
                            : '--'}
                        </>
                      </WithLoader>
                    </HouseholdStat>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box display="flex" justifyContent="end">
          <Box mt={1}>
            <WithLoader isLoading={accountsIsLoading} loader={<Skeleton inline width="14em" />}>
              <>
                {householdAggSummary != null && householdAggSummary?.length > 0 && (
                  <Typography color="textSecondary" variant="caption">
                    Last updated:{' '}
                    {moment
                      .utc(householdAggSummary[householdAggSummary.length - 1].date)
                      .format('l')}
                    &nbsp;&nbsp;(updated daily)
                  </Typography>
                )}
              </>
            </WithLoader>
          </Box>
        </Box>
      </Box>
      <Box mb={4}>
        <Card id="charts">
          <Box
            border={1}
            borderLeft={0}
            borderRight={0}
            borderTop={0}
            px={3}
            borderColor="grey.300"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tabs
              onChange={handleChartTypeChange}
              value={activeChartType}
              aria-label="Household overview tabs"
            >
              <Tab
                value="value"
                disabled={accountsIsLoading || numAccounts < 1}
                label="Household value"
              />
              {featureFlags?.enable_gpt_meeting_prep === 'on' && (
                <Tab value="meeting" label="Meeting Notes" />
              )}
              <Tooltip title="Performance reporting is currently under construction. Please reach out to clientservice@vise.com for ad hoc performance reporting needs. We apologize for the inconvenience.">
                <span>
                  <Tab label="Performance" disabled />
                </span>
              </Tooltip>
            </Tabs>
            <DateRangeSelector
              initialRange="all time"
              firstAvailableDate={currentChartFirstAvailableDate}
              lastAvailableDate={currentChartLastAvailableDate}
              onChangeRange={(range, presetUsed) => {
                const tabName = activeChartType?.split('-').slice(0, -1).join(' ');
                if (presetUsed) {
                  amplitude().logEvent('Action - select date range', {
                    category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
                    range: presetUsed,
                    chartType: tabName,
                  });
                } else {
                  amplitude().logEvent('Action - submit custom date range', {
                    category: EVENT_CATEGORIES.PORTFOLIO_OVERVIEW,
                    chartType: tabName,
                  });
                }
                setChartRangeOptions({
                  min: !range?.min ? undefined : range.min.valueOf(),
                  max: !range?.max ? undefined : range.max.valueOf(),
                });
              }}
            />
          </Box>
          {activeChartType === 'value' && (
            <WithLoader
              isLoading={accountsIsLoading}
              loader={
                <CardContent>
                  <Skeleton variant="chart" height="264px" width="100%" />
                </CardContent>
              }
            >
              {accountChartNode}
            </WithLoader>
          )}

          {activeChartType === 'meeting' && (
            <>
              <MeetingNotes householdId={householdId} />
            </>
          )}
        </Card>
      </Box>
      {(accountsIsLoading || numAccounts > 0) && (
        <Box mt={4}>
          <WithLoader
            isLoading={accountsIsLoading || proposalsIsLoading}
            loader={<AccountsTableLoading />}
          >
            <>
              {tableData && (
                <AccountsTable
                  untransitionedAccountsData={tableData?.untransitionedAccounts}
                  viseAccountsData={tableData?.viseAccounts}
                  viewProposalsOnClick={openProposalsModal}
                  householdId={householdId}
                  showPerformance={showPerformance}
                />
              )}
            </>
          </WithLoader>
        </Box>
      )}
      <Box mt={4}>
        <Grid container>
          <Grid item sm={6}>
            <Typography color="textSecondary" paragraph variant="caption">
              For information about our backtesting, performance results and tax loss harvesting,
              see our{' '}
              <MuiLink component={Link} to="/secure/disclosures">
                methodology and assumptions page
              </MuiLink>
              .
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HouseholdOverview;
