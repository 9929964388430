import {
  Box,
  Container,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Dictionary, keyBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Order } from 'vise-types/orders';
import { Instrument } from 'vise-types/pce2_instrument';
import { Td, Th } from '~/components/table/BannerTableCell';
import { ReactComponent as CheckCircleIcon } from '~/static/images/icons/check-circle.svg';
import Banner from '~/synth/Banner';
import { formatCurrency } from '~/utils/format';
import { TradeInitiatorLabel } from './components/TradeActivityLabels';
import { timestampToLocaleString } from './portfolioUtil';

interface PendingOrderBannerProps {
  instrumentsBySymbol: Dictionary<Instrument>;
  order: Order;
}

export function PendingOrderBanner({ instrumentsBySymbol, order }: PendingOrderBannerProps) {
  return (
    <Banner
      bgColor="blue.100"
      borderColor="blue.300"
      stripeColor="blue.500"
      actionCollapse="Hide trades"
      actionExpand="View trades"
      expandable
      expandedContent={
        <Box maxHeight="250px" overflow="auto" position="relative">
          <Box px={3} py={2}>
            <Typography color="textSecondary">
              Share price, value, and quantity may change based on market data at the time of trade
              execution.
            </Typography>
          </Box>
          <Table size="small" stickyHeader style={{ borderCollapse: 'separate' }}>
            <TableHead>
              <TableRow>
                <Th>Ticker</Th>
                <Th>Name</Th>
                <Th>Action</Th>
                <Th align="right">Quantity</Th>
                <Th align="right">Est. price</Th>
                <Th align="right">Est. value</Th>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.items?.map((orderItem) => {
                const instrument = instrumentsBySymbol[orderItem.symbolOrCusip];
                return (
                  <TableRow key={`${orderItem.symbolOrCusip}-${orderItem.transactionType}`}>
                    <Td>{orderItem.symbolOrCusip}</Td>
                    <Td>{instrument?.name ?? 'N/A'}</Td>
                    <Td>{orderItem.transactionType === 'BUY' ? 'Buy' : 'Sell'}</Td>
                    <Td align="right">{orderItem.quantity}</Td>
                    <Td align="right">
                      {instrument == null ? 'N/A' : formatCurrency(instrument.price)}
                    </Td>
                    <Td align="right">
                      {instrument?.price == null
                        ? 'N/A'
                        : formatCurrency(instrument.price * orderItem.quantity)}
                    </Td>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      }
      message={
        <>
          {timestampToLocaleString(order.createdAt)}
          <Box mx={2} height="20px">
            <Divider orientation="vertical" />
          </Box>
          {order.pce2RunSource == null ? null : (
            <>
              <Box mr={0.6}>
                <TradeInitiatorLabel runSource="VISE_ADMIN" />
              </Box>
              Initiated
              <Box mx={2} height="20px">
                <Divider orientation="vertical" />
              </Box>
            </>
          )}
          {order.items?.length} trades are pending execution by Vise
        </>
      }
      title="Trades Pending"
    />
  );
}

export function RecentlyExecutedOrderBanner({ fullWidth = false }: { fullWidth?: boolean }) {
  return (
    <Banner
      bgColor="success.100"
      borderColor="mint.main"
      message={
        <Container>
          <Typography component="div" variant="body2">
            <Box
              color="success.500"
              display="inline-block"
              mr={1}
              style={{ verticalAlign: 'middle' }}
            >
              <CheckCircleIcon />
            </Box>{' '}
            Trades have been executed and will be reflected in Vise after the next custodian data
            refresh.
          </Typography>
        </Container>
      }
      size="small"
      fullWidth={fullWidth}
    />
  );
}

interface OrderNotificationsProps {
  custodianPositionDate: string;
  instruments: Instrument[];
  orders: Order[];
}

export default function OrderNotifications({
  custodianPositionDate,
  instruments,
  orders,
}: OrderNotificationsProps) {
  const instrumentsBySymbol = React.useMemo(() => keyBy(instruments, 'symbol'), [instruments]);
  return (
    <>
      {orders.map((order, index) => {
        if (order.status === 'PENDING') {
          return (
            <Box key={order.id} mt={index === 0 ? undefined : 2}>
              <PendingOrderBanner instrumentsBySymbol={instrumentsBySymbol} order={order} />
            </Box>
          );
        }

        if (
          order.status === 'EXECUTED' &&
          moment(custodianPositionDate).isBefore(order.executedAt ?? undefined)
        ) {
          return (
            <Box key={order.id} mt={index === 0 ? undefined : 2}>
              <RecentlyExecutedOrderBanner />
            </Box>
          );
        }

        return null;
      })}
    </>
  );
}
