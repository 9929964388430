import { datadogRum } from '@datadog/browser-rum';
import { Box, Button, Link as MuiLink, StyledEngineProvider, Typography } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { History, createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { SWRConfig } from 'swr';
import Notifier from '~/routes/Notifier';
import spilledCoffee from '~/static/images/illustrations/spilled-coffee.png';
import rootReducer from '../reducers';
import Root from '../routes/Root';
import configureStore from '../store/configureStore';
import ThemeProvider from '../synth/ThemeProvider';
import { initializeAmplitude } from '../utils/amplitude';

const version = process.env.VERSION ?? undefined;
const history: History = createBrowserHistory();

LicenseInfo.setLicenseKey(
  'a5d2a67a24f3fb4e585bc28e5daaad94Tz05MzI5MyxFPTE3NTExMDIyNjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

if (process.env.NODE_ENV !== 'development' && !window.Cypress) {
  // Only initialize Sentry if we're not running locally (NODE_ENV = 'development')
  Sentry.init({
    dsn: 'https://823eca80a7a0410ea4eae6c905cf529b@o459488.ingest.sentry.io/5458818',
    environment: process.env.VISE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    // dev & staging leave the sample rate lower
    tracesSampleRate: process.env.VISE_ENV === 'prod' ? 0.05 : 1,
  });

  datadogRum.init({
    applicationId: '76f09cfd-cd00-4384-9abe-4970322b5664',
    clientToken: 'pub36a8225d70740ebbde1163d2cbf8cc11',
    env: process.env.VISE_ENV,
    site: 'datadoghq.com',
    service: 'vise-web',
    // Specify a version number to identify the deployed version of your application in Datadog
    version,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingOrigins: [/https:\/\/.*\.vise\.com/],
  });

  datadogRum.startSessionReplayRecording();
}

initializeAmplitude();

// See https://swr.vercel.app/docs/global-configuration
const globalSwrConfig = {
  // Disable revalidating when focusing the browser tab; it leads to unexpected requests and
  // undesired re-renders without any explicit user action.
  revalidateOnFocus: false,
  // Disable revalidating when the client reconnects to the internet; it leads to unexpected
  // requests and undesired re-renders without any explicit user action.
  revalidateOnReconnect: false,
};

const store = configureStore({}, rootReducer);

// For some reason putting this hook directly in the ErrorFallback doesn't work, perhaps it's not rendered as a component
function LogToDatadog({ error }) {
  useEffect(() => {
    datadogRum.addError(error);
  }, [error]);
  return null;
}

const ErrorFallback = ({ error }) => (
  <Box
    width="100%"
    height="100vh"
    display="flex"
    fontFamily='"Helvetica Neue", Arial, "Noto Sans", sans-serif'
  >
    <LogToDatadog error={error} />
    <Box m="auto" textAlign="center">
      <img src={spilledCoffee} width={271} alt="" />
      <Typography mt={5} variant="h2" fontWeight={500} fontSize={24} lineHeight={1.25}>
        This one&apos;s on us!
      </Typography>
      <Box color="grey.600" mt={2}>
        An unexpected error occurred while processing your request.
      </Box>
      <Box mb={1.5} mt={6}>
        Give it another go.
      </Box>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => window.location.reload()}
        sx={{ textTransform: 'capitalize' }}
      >
        Refresh
      </Button>
      <Box mt={5} color="grey.400">
        <Typography variant="caption">
          Our friendly team is here to help. <br /> Please reach out to{' '}
          <MuiLink href="mailto:clientservice@vise.com">clientservice@vise.com</MuiLink> for help.
        </Typography>
      </Box>
    </Box>
  </Box>
);
const container = document.getElementById('root');
const root = createRoot(container as Element);

function render() {
  root.render(
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Helmet defaultTitle="Vise" titleTemplate="%s | Vise" />
      <Provider store={store}>
        <SWRConfig value={globalSwrConfig}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider>
              <SnackbarProvider
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                maxSnack={5}
                preventDuplicate
              >
                <Router history={history}>
                  <Root />
                </Router>
                <Notifier />
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </SWRConfig>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}
render();
