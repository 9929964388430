import { withStyles } from 'tss-react/mui';
import { Dialog } from '@mui/material';

const HelpDialog = withStyles(Dialog, () => ({
  paper: {
    maxWidth: '500px',
  },
}));

export default HelpDialog;
