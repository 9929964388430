import { Radio, Box, Typography } from '@mui/material';
import React from 'react';
import { CardProps } from '~/synth/InteractiveContainerBase';
import { Card, CardSection } from '../components';

interface Props {
  checked: boolean;
  description: string;
  label: React.ReactNode;
  onClick: CardProps['onClick'];
  value: string;
}

function RadioButtonCard({ checked, description, label, onClick, value }: Props) {
  return (
    <Card active={checked} onClick={onClick} selectable height="100%">
      <CardSection>
        <Box alignItems="center" display="flex" flexDirection="row" ml={-1}>
          <Radio value={value} checked={checked} />
          {label}
        </Box>
        {description && (
          <Box color="grey.600" mt={2}>
            <Typography variant="body1">{description}</Typography>
          </Box>
        )}
      </CardSection>
    </Card>
  );
}

export default RadioButtonCard;
