import useSWR from 'swr';
import { Custodian } from 'vise-types/custodian';
import { getDistributionSchedule } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getDistributionScheduleSwrKey = (
  clientId: string,
  custodianCode: Custodian,
  accountNumber: string
) =>
  generateSwrKey(
    `distributionSchedule?client_id=${clientId}&?custodian_code=${custodianCode}&?account_number=${accountNumber}`
  );

const useDistributionSchedule = (
  clientId: string | null | undefined,
  custodianCode: Custodian | null | undefined,
  accountNumber: string | null | undefined
) => {
  return useSWR(
    clientId == null || custodianCode == null || accountNumber == null
      ? null
      : getDistributionScheduleSwrKey(clientId, custodianCode, accountNumber),
    () =>
      clientId == null || custodianCode == null || accountNumber == null
        ? undefined
        : getDistributionSchedule(clientId, custodianCode, accountNumber)
  );
};

export default useDistributionSchedule;
