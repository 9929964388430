import {
  Box,
  Card,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
  Button,
  Container,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { GetModelTemplateCenterViewDataResponse } from 'vise-types/template';
import CardHeader from '~/synth/CardHeader';

import { AccountWithPIAndHouseholdInfo } from 'vise-types/portfolio';
import { groupBy, keyBy } from 'lodash';
import {
  Column,
  TableState,
  usePagination,
  UsePaginationState,
  useSortBy,
  UseSortByState,
  useTable,
} from 'react-table';
import { formatCurrency } from '~/utils/format';
import PageNavigation from '~/components/table/PageNavigation';
import { DataTable } from '~/synth/DataTable';
import { SearchIcon } from '~/synth/Icons';
import AccountFilter, {
  filterAccounts,
  initialFilterState,
} from '~/components/portfolio/AccountFilter';
import { matchSorter } from 'match-sorter';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import { exportCSV } from '~/utils/export';
import { format } from 'date-fns/esm';
import amplitude from '~/utils/amplitude';
import EmptyState from './EmptyState';
import { AccountCell, Cell, CTACell } from './workflows/CommonComponents';
import ValueCell from '../Households/Households/ValueCell';

const PAGE_SIZE = 100;

interface AccountsTableData {
  account: AccountWithPIAndHouseholdInfo;
  template?: {
    name: string;
    lastEdit: string;
  };
  value: number;
  cta: {
    path: string;
    buttonCopy: string;
  };
}

export default function AccountsAndAllocationsTable({
  data,
}: {
  data: GetModelTemplateCenterViewDataResponse;
}) {
  const [dropdownFilterState, setDropdownFilterState] = useState(initialFilterState);
  const [filterQuery, setFilterQuery] = useState('');
  const theme = useTheme();

  const accountsTableColumns: Column<AccountsTableData>[] = useMemo(
    () => [
      {
        Header: 'Account',
        accessor: 'account',
        Cell: AccountCell,
        width: '24%',
        id: 'account',
      },
      {
        Header: () => <Cell value="Template" />,
        accessor: 'template',
        Cell: (props) => (
          <Box ml={1}>
            <Typography variant="body2" fontStyle={props.value ? '' : 'italic'}>
              {props.value ? props.value.name : 'Custom allocation'}
            </Typography>
          </Box>
        ),
        width: '24%',
        sortType: (rowA, rowB) => {
          const nameA = rowA.original.template?.name;
          const nameB = rowB.original.template?.name;
          if (!nameA) {
            return 1;
          }
          if (!nameB) {
            return -1;
          }

          const result = nameB.localeCompare(nameA);
          return result;
        },
        id: 'template',
      },
      {
        Header: 'Value',
        accessor: 'value',
        Cell: (props) => <ValueCell value={formatCurrency(props.value)} isLoading={false} />,
        width: '20%',
        id: 'value',
      },
      {
        Header: 'Last template edit',
        accessor: (row) => row.template?.lastEdit,
        Cell: (props) =>
          props.value ? <Box ml={1}>{format(new Date(props.value), 'M/d/yy')}</Box> : <></>,
        width: '12%',
        id: 'lastEdit',
      },
      {
        Header: '',
        accessor: (row) => row.cta,
        Cell: CTACell,
        disableSortBy: true,
        id: 'cta',
      },
    ],
    []
  );

  const tableData = useMemo(() => {
    const linkMap = groupBy(data.templateLinks, 'accountId');
    const templatesByParentId = keyBy(data.allocationsTemplates, 'parentId');

    const result =
      data.accounts.map((acc) => {
        const templates =
          linkMap[acc.id]
            ?.filter((link) => link.templateType === 'allocations')
            .map((link) => templatesByParentId[link.templateId]) || [];

        if (templates.length > 1) {
          throw new Error('Account can have at most one allocation template');
        }

        const template = templates[0];

        return {
          account: acc,
          template: template
            ? {
                name: template.name,
                lastEdit: template.createdAt,
              }
            : undefined,
          value: acc.cachedAum,
          cta: {
            path: `/secure/accounts/${acc.id}/portfolio/overview`,
            buttonCopy: 'View Account',
          },
        } as AccountsTableData;
      }) || [];
    return matchSorter(result, filterQuery, {
      keys: ['account.accountName', 'template.name', 'account.accountNumber'],
      threshold: matchSorter.rankings.CONTAINS,
    });
  }, [data.accounts, data.allocationsTemplates, data.templateLinks, filterQuery]);

  const filteredTableData = useMemo(() => {
    if (data) {
      const accountIds = new Set(
        filterAccounts(
          tableData.map((t) => t.account),
          dropdownFilterState,
          data
        ).map((acc) => acc.id)
      );
      return tableData.filter((row) => accountIds.has(row.account.id));
    }
    return tableData;
  }, [data, dropdownFilterState, tableData]);

  const accountTable = useTable<AccountsTableData>(
    {
      columns: accountsTableColumns,
      data: filteredTableData || [],
      initialState: {
        pageSize: PAGE_SIZE,
        sortBy: [{ id: 'template', desc: true }],
      } as TableState<AccountsTableData> &
        UsePaginationState<AccountsTableData> &
        UseSortByState<AccountsTableData>,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  const handleClickExportCsv = () => {
    const timestamp = format(new Date(), 'MM/dd/yyyy_h:mm_a');
    const exportData = tableData.map((t) => ({
      accountName: t.account.accountName,
      householdName: t.account.householdInfo?.name,
      accountNumber: t.account.accountNumber,
      value: t.value,
      templateName: t.template?.name || 'Custom allocation',
      lastTemplateEdit: t.template?.lastEdit,
    }));
    exportCSV({
      data: exportData,
      filename: `active-accounts-and-strategies-${timestamp}.csv`,
    });
    amplitude().logEvent('Accounts & Allocations CSV Export initiated', {
      category: EVENT_CATEGORIES.STRATEGY_CENTER,
    });
  };
  return (
    <Container>
      <Card>
        <CardHeader>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <Typography variant="h2">Accounts & Allocations</Typography>
            </Box>
            <Box mr={1}>
              <Button
                variant="outlined"
                type="submit"
                onClick={() => handleClickExportCsv()}
                color="secondary"
              >
                Export to CSV
              </Button>
            </Box>
            <Box width="440px">
              <TextField
                fullWidth
                value={filterQuery}
                onChange={(e) => setFilterQuery(e.target.value)}
                placeholder="Search by template/account name or number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon display="inline-flex" color={theme.palette.grey[500]} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <AccountFilter
                      accounts={tableData.map((row) => row.account)}
                      filterState={dropdownFilterState}
                      onApplyFilter={(filterState) => {
                        setDropdownFilterState(filterState);
                        accountTable.gotoPage(0);
                      }}
                      eventCategory={EVENT_CATEGORIES.STRATEGY_CENTER}
                    />
                  ),
                  sx: { paddingRight: 0 },
                }}
              />
            </Box>
          </Box>
        </CardHeader>
        {data.accounts && data.accounts.length === 0 ? (
          <EmptyState
            height={720}
            title="There are no executed accounts on the platform"
            body="Any actions performed in Template Hub will be recorded here."
            showCTA={false}
          />
        ) : (
          <>
            <DataTable table={accountTable} rowSize="large" sortable />
            {tableData &&
              tableData.length >
                (accountTable.state as UsePaginationState<AccountsTableData>).pageSize && (
                <Box px={3} py={4} borderTop={`1px solid ${theme.palette.grey[100]}`}>
                  <PageNavigation
                    currentPage={
                      (accountTable.state as UsePaginationState<AccountsTableData>).pageIndex + 1
                    }
                    numItems={tableData.length}
                    pageSize={
                      (accountTable.state as UsePaginationState<AccountsTableData>).pageSize
                    }
                    onNextPageClick={() => {
                      accountTable.nextPage();
                    }}
                    onPrevPageClick={() => {
                      accountTable.previousPage();
                    }}
                    onPageNumberClick={(pageNumber) => {
                      accountTable.gotoPage(pageNumber - 1);
                    }}
                    setPageSize={accountTable.setPageSize}
                  />
                </Box>
              )}
          </>
        )}
      </Card>
    </Container>
  );
}
