import { Box, ButtonBase, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { CheckIcon } from './Icons';

const StyledCheckIcon = styled(CheckIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  height: 20px;
  margin-left: 2px;
  width: 20px;
`;

type StepProps = {
  active?: boolean;
  checked?: boolean;
  children?: React.ReactNode;
  className?: string;
  value: string;
  replace?: boolean;
  onClick?: () => void;
};

function Step({ active, checked, children, className, onClick }: StepProps) {
  const content = (
    <>
      <Typography variant="h6">{children}</Typography>
      {checked ? (
        <>
          {' '}
          <StyledCheckIcon customSize="20px" display="inline-flex" />
        </>
      ) : null}
    </>
  );
  return (
    <Box
      height="100%"
      alignItems="center"
      className={className}
      display="flex"
      marginRight="auto"
      borderBottom={active ? 2 : 0}
    >
      {onClick && (checked || active) ? (
        <ButtonBase onClick={onClick}>{content}</ButtonBase>
      ) : (
        content
      )}
    </Box>
  );
}

const StyledStep = styled(Step)`
  ${({ active, checked, theme }) => (active || checked ? '' : `color: ${theme.palette.grey[400]}`)}
  transition: color 0.25s;
`;

const StyledInnerStepperBox = styled(Box)`
  color: ${({ theme }) => theme.palette.grey[800]};
  height: 56px;
  max-width: 1850px;
`;

type Props = {
  children?: React.ReactNode;
  /**
   * If this is true, clicking a Step link wll replace the current entry
   * in the history stack instead of adding a new one.
   */
  linkReplace?: boolean;
  value: string;
};

const Stepper = ({ children, value, linkReplace }: Props) => {
  let activeIndex = -1;
  React.Children.forEach(children, (child, index) => {
    if (React.isValidElement(child) && child.props.value === value) {
      if (activeIndex === -1) {
        activeIndex = index;
      } else if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn(
          `Multiple <Stepper.Step> children found with value "${value}". Only first instance will be used.`
        );
      }
    }
  });

  if (process.env.NODE_ENV === 'development' && activeIndex === -1) {
    // eslint-disable-next-line no-console
    console.warn(`No <Stepper.Step> child found with value "${value}"`);
  }

  return (
    <StyledInnerStepperBox alignItems="center" display="flex">
      {React.Children.map(children, (child, index) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              ...child.props,
              active: index === activeIndex,
              checked: index < activeIndex,
              replace: linkReplace,
            })
          : null
      )}
    </StyledInnerStepperBox>
  );
};

Stepper.Step = StyledStep;

export default Stepper;
