import useSWR from 'swr';
import { getModelTemplateCenterViewData } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export default function useModelTemplateCenterViewData(refresh = true) {
  return useSWR(
    generateSwrKey('modelTemplateCenterViewData'),
    () => getModelTemplateCenterViewData(),
    { refreshInterval: refresh ? 10000 : undefined }
  );
}
