import { Box, BoxProps, Link, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { LockedIcon, SearchIcon } from '~/synth/Icons';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';
import Truncation from './Truncation';

const ASSETS_NOT_MANAGED_BY_VISE_TOOLTIP =
  'Vise does not currently have fundamental data for these holdings. They are not included in our optimization.';
const UNCLASSIFIED_SECURITIES_TOOLTIP =
  'These holdings are from the custodian, but Vise is unable to classify them at this time.';

export function RightAlignBox({ children, ...restProps }: BoxProps) {
  return (
    <Box textAlign="right" {...restProps}>
      {children}
    </Box>
  );
}

export function CenterAlignBox({ children, ...restProps }: BoxProps) {
  return (
    <Box textAlign="center" {...restProps}>
      {children}
    </Box>
  );
}

export function Locked({ locked }: { locked: boolean }) {
  return locked ? (
    <Tooltip title="Security is locked">
      <Box>
        <LockedIcon display="inline-flex" size="small" />
      </Box>
    </Tooltip>
  ) : null;
}

export function AllocationBar({
  allocation,
  topAssetAllocation,
}: {
  allocation: number;
  topAssetAllocation: number;
}) {
  const allocationFactor = allocation / topAssetAllocation;
  return (
    <Box
      // MUI width attribute converts values [0,1] to percentages
      style={{ width: `${128 * allocationFactor}px` }}
      height={16}
      bgcolor="grey.700"
      borderRadius="2px"
      display="block"
    />
  );
}

export function ViewPositionsButton({ count, onClick }: { count: number; onClick: () => void }) {
  return count > 0 ? (
    <Box textAlign="right">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link component="button" variant="h4" color="textPrimary" onClick={onClick}>
        View {count} {count > 1 ? 'holdings' : 'holding'}
      </Link>
    </Box>
  ) : null;
}

export const NoResultsFound = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" my={7}>
      <Box
        mb={2}
        borderRadius="50%"
        bgcolor="grey.100"
        width="48px"
        height="48px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow={1}
      >
        <SearchIcon size="large" display="inline-flex" />
      </Box>
      <Box mb={1}>
        <Typography variant="h4">No results found</Typography>
      </Box>
      <Box color="grey.600">
        <Typography variant="body1">
          Try adjusting your search to find what you&apos;re looking for.
        </Typography>
      </Box>
    </Box>
  );
};

export function AssetNameBox({ children }: { children: React.ReactNode }) {
  let tooltip = '';
  if (children === 'Assets not managed by Vise') {
    tooltip = ASSETS_NOT_MANAGED_BY_VISE_TOOLTIP;
  }
  if (children === 'Unclassified securities') {
    tooltip = UNCLASSIFIED_SECURITIES_TOOLTIP;
  }

  return tooltip ? (
    <Box width="140%" display="flex">
      {children}
      <Tooltip title={tooltip}>
        <Box display="flex" alignItems="center" ml={0.75}>
          <InformationCircleIcon />
        </Box>
      </Tooltip>
    </Box>
  ) : (
    <Box width="140%">{children}</Box>
  );
}

export function PCE2AssetNameBox({
  title,
  subtitle,
  singleStrategyTooltip,
}: {
  title: string;
  subtitle?: string;
  singleStrategyTooltip?: React.ReactNode;
}) {
  let tooltipTitle = '';
  if (title === 'Assets not managed by Vise') {
    tooltipTitle = ASSETS_NOT_MANAGED_BY_VISE_TOOLTIP;
  }
  if (title === 'Unclassified securities') {
    tooltipTitle = UNCLASSIFIED_SECURITIES_TOOLTIP;
  }

  if (tooltipTitle) {
    return (
      <Box width="140%" display="flex">
        {title}
        <Box>
          <Tooltip title={tooltipTitle}>
            <Box display="flex" alignItems="center" ml={0.75}>
              <InformationCircleIcon />
            </Box>
          </Tooltip>
        </Box>
      </Box>
    );
  }

  return (
    <Box width="140%">
      <Box display="flex">
        <Truncation style={{ marginBottom: '2px' }}>{title}</Truncation>
        <Box ml={0.75}>{singleStrategyTooltip}</Box>
      </Box>
      <Typography variant="body1" color="textSecondary">
        {subtitle}
      </Typography>
    </Box>
  );
}
