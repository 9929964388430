import { Custodian } from 'vise-types/custodian';
import { executeImmediateDrawdown } from '../api/api';
import useEnqueueToast from './useToast';

type ImmediateDrawdownOptions = {
  amount: number;
  custodianCode: Custodian;
  accountNumber: string;
  proposalId?: string;
  processOn?: Date;
  description?: string;
  id?: string;
  allowWashSaleIfNeeded?: boolean;
};

export default function useImmediateDrawdown() {
  const enqueueToast = useEnqueueToast();
  const immediateDrawdown = async ({
    amount,
    processOn,
    custodianCode,
    accountNumber,
    proposalId,
    description,
    id,
    allowWashSaleIfNeeded,
  }: ImmediateDrawdownOptions) => {
    try {
      await executeImmediateDrawdown(
        amount,
        custodianCode,
        accountNumber,
        proposalId,
        processOn?.toLocaleDateString(),
        description,
        id,
        allowWashSaleIfNeeded
      );
      enqueueToast({
        severity: 'success',
        title: `You've successfully set your distribution.`,
      });
      return true;
    } catch (e) {
      return false;
    }
  };
  return { immediateDrawdown };
}
