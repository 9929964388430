import {
  Box,
  Card,
  CardContent,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGridPro, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { ReactComponent as InformationCircleIcon } from '~/static/images/icons/information-circle.svg';

import { tokens } from '@vise_inc/ds-vise';
import { format } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMap from 'highcharts/modules/map';
import React, { useState } from 'react';
import {
  BondInstrumentType,
  BondPortfolioCreditQualityAllocation,
  BondPortfolioHolding,
  BondPortfolioMetrics,
  type BondPortfolioMuniUseAllocation,
} from 'vise-types/portfolio';
import BondPortfolioCreditRatingChart, {
  ChartLegend as CreditChartLegend,
} from '~/routes/BondPortfolio/BondPortfolioCreditRatingChart';
import Banner from '~/synth/Banner';
import CardHeader from '~/synth/CardHeader';
import Metric from '~/synth/Metric';
import { formatPercent, formatQuantity } from '~/utils/format';
import mapDataWorld from '../Portfolio/components/maps/mapData.json';
import BondPortfolioMaturityChart from './BondPortfolioMaturityChart';
import BondPortfolioSectorChart, {
  ChartLegend as SectorChartLegend,
} from './BondPortfolioSectorChart';
import { CREDIT_RATING_TO_LABEL, MUNI_USE_TO_LABEL } from './Utils';
import mapDataUsa from './mapDataUsa.json';

highchartsMap(Highcharts);

export default function BondPortfolioAllocation({
  metrics,
  muniUses,
  creditRating,
  holdings,
  instrumentType,
  isProposal,
}: {
  metrics: BondPortfolioMetrics;
  muniUses: BondPortfolioMuniUseAllocation;
  creditRating: BondPortfolioCreditQualityAllocation;
  holdings: BondPortfolioHolding[];
  instrumentType: BondInstrumentType;
  isProposal?: boolean;
}) {
  const theme = useTheme();

  const [allocationCardToggle, setAllocationCardToggle] = useState<
    'credit' | 'sectors' | 'maturity'
  >('credit');

  const stateMap = {};
  holdings.forEach((holding) => {
    if (holding.state == null) {
      return;
    }

    const hcKey = `us-${holding.state.toLocaleLowerCase()}`;
    if (stateMap[hcKey]) {
      stateMap[hcKey] += holding.weight;
    } else {
      stateMap[hcKey] = holding.weight;
    }
  });
  // Ideally this conversion happens in the Highcharts tooltip formatter
  Object.keys(stateMap).forEach((key) => {
    stateMap[key] *= 100;
  });

  const holdingsColumns: GridColDef[] = [
    { headerName: 'CUSIP', field: 'cusip', flex: 0.6 },
    { headerName: 'Description', field: 'holding', flex: 1 },

    ...(instrumentType === 'MUNICIPAL'
      ? [
          {
            headerName: 'Sector',
            field: 'muniUse',
            renderCell: (params) => (
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {MUNI_USE_TO_LABEL[params.value] ?? '-'}
              </span>
            ),
            flex: 0.9,
          },
          { headerName: 'State', field: 'state', flex: 0.5 },
        ]
      : []),
    {
      headerName: 'Maturity',
      field: 'maturity',
      flex: 0.5,
      renderCell: ({ value }) => (value ? format(new Date(value), 'MM/dd/yyyy') : 'N/A'),
    },
    {
      headerName: 'Coupon',
      field: 'coupon',
      flex: 0.5,
      renderCell: ({ value }) => formatPercent(value),
    },
    {
      headerName: 'Rating',
      field: 'creditQualityRating',
      flex: 0.5,
      renderCell: ({ value }) => CREDIT_RATING_TO_LABEL[value],
    },
    {
      headerName: 'Weight',
      field: 'weight',
      flex: 0.5,
      renderCell: ({ value }) => formatPercent(value, 1),
    },
  ];

  const mapOptions: Highcharts.Options = {
    chart: {
      type: 'map',
      // @ts-ignore
      map: instrumentType === 'MUNICIPAL' ? mapDataUsa : mapDataWorld,
      backgroundColor: undefined,
    },
    credits: { enabled: false },
    title: { text: '' },
    legend: { enabled: false },
    colorAxis: {
      min: 0,
      minColor: theme.palette.purple[50],
      maxColor: theme.palette.purple[600],
      showInLegend: false,
    },
    tooltip: {
      headerFormat: '',
      pointFormat: `{point.name}: <b>{point.value:.1f}%</b>`,
    },
    series: [
      {
        type: 'map',
        data: instrumentType === 'MUNICIPAL' ? Object.entries(stateMap) : [['us', 100]],
      },
    ],
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Metric label="Duration" metric={`${formatQuantity(metrics.oad)} year(s)`} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Metric
                  label="Average maturity"
                  metric={`${formatQuantity(metrics.avgFinalEffectiveMaturity)} year(s)`}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Metric label="Current yield" metric={formatPercent(metrics.avgCurrentYield, 2)} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ height: '100%' }}>
            <CardHeader>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h4">Allocation</Typography>
                <ToggleButtonGroup
                  exclusive
                  onChange={(_, value: 'credit' | 'sectors' | 'maturity') => {
                    if (value) {
                      setAllocationCardToggle(value);
                    }
                  }}
                  value={allocationCardToggle}
                  size="large"
                >
                  <ToggleButton value="credit">Credit quality</ToggleButton>
                  {instrumentType === 'MUNICIPAL' && (
                    <ToggleButton value="sectors">Sectors</ToggleButton>
                  )}
                  {instrumentType === 'TREASURY' && (
                    <ToggleButton value="maturity">Maturity</ToggleButton>
                  )}
                </ToggleButtonGroup>
              </Box>
            </CardHeader>
            <CardContent>
              {allocationCardToggle === 'sectors' && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <BondPortfolioSectorChart muniUses={muniUses} />
                  </Grid>
                  <Grid item xs={6}>
                    <SectorChartLegend muniUses={muniUses} />
                  </Grid>
                </Grid>
              )}
              {allocationCardToggle === 'credit' && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <BondPortfolioCreditRatingChart ratings={creditRating} />
                  </Grid>
                  <Grid item xs={6}>
                    <CreditChartLegend ratings={creditRating} />
                  </Grid>
                </Grid>
              )}
              {allocationCardToggle === 'maturity' && (
                <BondPortfolioMaturityChart holdings={holdings} horizontal />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ height: '100%' }}>
            <CardHeader>
              <Typography variant="h4">Geo-allocation</Typography>
            </CardHeader>
            <Box p={1}>
              <HighchartsReact
                constructorType="mapChart"
                highcharts={Highcharts}
                options={mapOptions}
              />
            </Box>
          </Card>
        </Grid>
        {isProposal && (
          <Grid item xs={12}>
            <Banner
              size="small"
              bgColor="warning.100"
              borderColor="warning.200"
              message={
                <div>
                  <Box display="flex" alignItems="center">
                    <InformationCircleIcon color={theme.palette.warning[400]} fontSize={18} />
                    <Box fontWeight={500} ml={1.5}>
                      Proposed holdings can change when executed.
                    </Box>
                  </Box>
                  <Box ml={3.75} mt={1}>
                    Holdings are decided based on available liquidity when the proposal is executed.
                    The items below are illustrative of potential holdings and can be expected to
                    change when executed.
                  </Box>
                </div>
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DataGridPro
            sx={{ bgcolor: 'white' }}
            rows={holdings}
            columns={holdingsColumns}
            getRowId={(row) => row.cusip}
            pagination
            hideFooter={false}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            slots={{
              toolbar: () => (
                <Box
                  px={3}
                  py={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={1}
                  borderColor={tokens.palette.neutralCool[300]}
                >
                  <Typography variant="h2">Holdings</Typography>
                  <GridToolbarQuickFilter variant="outlined" />
                </Box>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
