import React from 'react';
import { Box, BoxProps } from '@mui/material';
import ViseLogoBlack from '~/static/images/vise-logo-black.svg';

/*
  Right now, only supports black, but we can extend this when we need other variations.
*/

type ViseBadgeProps = Pick<
  BoxProps,
  | 'mr'
  | 'marginRight'
  | 'ml'
  | 'marginLeft'
  | 'mb'
  | 'marginBottom'
  | 'mt'
  | 'marginTop'
  | 'm'
  | 'margin'
  | 'mx'
  | 'marginX'
  | 'my'
  | 'marginY'
>;

const ViseBadge = React.forwardRef((props: ViseBadgeProps, ref) => {
  return (
    <Box
      height={20}
      width={20}
      borderRadius="50%"
      bgcolor="grey.200"
      display="flex"
      justifyContent="center"
      alignItems="center"
      // This is unfortunate, but ref is missing in the type definition of Box: https://github.com/mui-org/material-ui/issues/17010
      // Apparently fixed in mui v5, so we can remove this ts-ignore once we upgrade to mui v5.
      // This is important for having this be a tooltip, for example.
      ref={ref}
      {...props}
    >
      <img alt="V" src={ViseLogoBlack} height="10" width="9" />
    </Box>
  );
});

export default ViseBadge;
