import React from 'react';

import { Box, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

export default function CardDisclosures() {
  return (
    <Box p={3}>
      For more information, see our{' '}
      <MuiLink component={Link} to="/secure/disclosures">
        methodology, assumptions, and other disclosures here.
      </MuiLink>
    </Box>
  );
}
