import { Box, BoxProps, useTheme } from '@mui/material';
import React from 'react';

export default function ChartColorCircle(props: BoxProps) {
  const theme = useTheme();
  return (
    <Box
      bgcolor={props.bgcolor == null ? '#fff' : props.bgcolor}
      borderRadius="50%"
      display="inline-block"
      height="9px"
      width="9px"
      border={2}
      borderColor={props.borderColor == null ? theme.palette.grey[200] : props.borderColor}
      {...props}
    />
  );
}
