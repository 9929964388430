import { Box, Card, Grid, Link, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { downloadUploadedBulkEditCSV } from '~/api/api';

import useActivityDetails from '~/hooks/useActivityDetails';
import { ReactComponent as DownloadIcon } from '~/static/images/icons/download.svg';
import LoadingSpinner from '~/synth/LoadingSpinner';
import { ACTIVITY_LOG_LABELS } from '../workflows/CommonComponents';
import EditContent from './EditContent';
import LinkUpdateContent from './LinkUpdateContent';
import RenameContent from './RenameContent';

export default function ActivityLogDetails({ activityId: id }: { activityId: string }) {
  const { data, error } = useActivityDetails(id);

  if (error) {
    return (
      <Box padding={2}>
        <Typography variant="body2">Error loading data</Typography>
      </Box>
    );
  }

  if (!data) {
    return <LoadingSpinner />;
  }
  let updateDetailsText = 'Capital gains';
  if (data.data.template?.type === 'allocations') {
    updateDetailsText = 'Allocation';
  }
  if (data.data.template?.type === 'restrictions') {
    updateDetailsText = 'Restrictions';
  }

  const gridItemSize = data.data.jobId && data.data.event.type === 'BULK_EDIT' ? 3 : 4;

  return (
    <>
      <Box mt={6} mx="auto" maxWidth="1280px">
        <Card>
          <Grid container>
            <Grid item xs={gridItemSize}>
              <Box p={3} borderRight={1} borderColor="grey.200">
                <Typography variant="h5" color="textSecondary">
                  {data.data.template ? 'Template' : 'Type'}
                </Typography>
                <Box mt={1} />
                <Typography variant="h3">
                  {data.data.template?.name ?? '2024 Capital gains budgets'}
                </Typography>
                <Box mt={1} />
                <Typography variant="body2" color="textSecondary">
                  {updateDetailsText}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={gridItemSize}>
              <Box p={3} borderRight={1} borderColor="grey.200">
                <Typography variant="h5" color="textSecondary">
                  Event
                </Typography>
                <Box mt={1} />
                <Typography variant="h3">{ACTIVITY_LOG_LABELS[data.data.event.type]}</Typography>
                <Box mt={1} />
                <Typography variant="body2" color="textSecondary">
                  By {data.data.event.submittedByName}
                </Typography>
              </Box>
            </Grid>

            {data.data.jobId && data.data.event.type === 'BULK_EDIT' ? (
              <Grid item xs={gridItemSize}>
                <Box p={3} borderRight={1} borderColor="grey.200">
                  <Typography variant="h5" color="textSecondary">
                    Input file
                  </Typography>
                  <Box mt={1} />
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    onClick={async () => {
                      if (!data.data.jobId) {
                        return;
                      }
                      const resp = await downloadUploadedBulkEditCSV(data.data.jobId);
                      if (resp.data) {
                        window.location.href = resp.data.url;
                      }
                    }}
                    component="button"
                    sx={{ textDecoration: 'none' }}
                  >
                    <Box display="flex">
                      <Box mr={0.5}>
                        <Typography variant="h4" component="div">
                          Download
                        </Typography>
                      </Box>
                      <DownloadIcon height={20} width={20} />
                    </Box>
                  </Link>
                  <Box mt={1} />
                  <Typography variant="body2" color="textSecondary">
                    CSV file uploaded for this bulk edit
                  </Typography>
                </Box>
              </Grid>
            ) : null}

            <Grid item xs={gridItemSize}>
              <Box p={3} borderRight={1} borderColor="grey.200">
                <Typography variant="h5" color="textSecondary">
                  Date/time
                </Typography>
                <Box mt={1} />
                <Typography variant="h3">
                  {moment(data.data.updatedAt).format('MM/DD/YY')}
                </Typography>
                <Box mt={1} />
                <Typography variant="body2" color="textSecondary">
                  {moment(data.data.updatedAt).format('h:mmA')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>

      <Box mt={3} mb={4} mx="auto" maxWidth="1280px">
        {data && data.data.event.type === 'RENAME' && (
          <RenameContent metadata={data.data.metadata} />
        )}
        {data &&
          data.data.template &&
          ['INSERT', 'EDIT', 'DELETE'].includes(data.data.event.type) && (
            <EditContent
              originalTemplateId={data.data.metadata?.oldTemplateId}
              newTemplateId={data.data.template.parentId}
            />
          )}

        {data && ['LINK', 'UPDATE', 'BULK_EDIT'].includes(data.data.event.type) && (
          <LinkUpdateContent data={data.data} />
        )}
      </Box>
    </>
  );
}
