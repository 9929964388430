import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import DialogTitle from '~/synth/DialogTitle';
import React from 'react';

export default function GPTDisclosuresModal({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}) {
  const [isApproved, setIsApproved] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle onClose={onClose} />

      <Box display="flex" justifyContent="center">
        <Typography variant="h2" fontWeight={500} color="#161617">
          Vise Intelligence Disclosure
        </Typography>
      </Box>

      <DialogContent>
        <Box py={2}>
          <Typography
            variant="body2"
            sx={{
              color: '#3F3F42',
              lineHeight: '20px',
            }}
          >
            Welcome to Vise Intelligence, our beta intelligence interface powered by AI. Vise
            intelligence can help you answer questions about specific clients or portfolios,
            performance, trading activity, and macroeconomic trends—and can even help you draft
            client emails.
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="body2" fontWeight={500}>
            As you use Vise Intelligence, remember that:
          </Typography>
        </Box>

        <Typography variant="body2" sx={{ lineHeight: '125%' }}>
          <ol>
            <li>
              It’s an experimental product, so while it tries its best to be as accurate as
              possible, it won’t always get it right.
            </li>
            <br />
            <li>
              The tool may provide information or suggestions that are inaccurate, incomplete, or
              incorrect. You are responsible for verifying the information before relying on it.
            </li>
            <br />
            <li>
              This tool is not intended to provide investment or any other professional advice.
            </li>
            <br />
            <li>
              We value your feedback. Use the ‘thumbs up’ for helpful responses, and ‘thumbs down’
              for responses that were not helpful or accurate. You can also leave written feedback
              on a single response or use the button at the top of the screen to leave feedback on
              your overall experience with Vise Intelligence.
            </li>
            <br />
          </ol>
        </Typography>

        <Box display="flex" alignItems="center">
          <Checkbox
            checked={isApproved}
            onChange={() => {
              setIsApproved(!isApproved);
            }}
          />
          <Box ml={1}>
            <Typography variant="body2" color="#131313">
              I have read and agree to the above terms.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          disabled={!isApproved || buttonDisabled}
          onClick={async () => {
            setButtonDisabled(true);
            await onConfirm();
            setButtonDisabled(false);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
