import amplitude from 'amplitude-js';

let initialized = false;
let initializingPromise: Promise<void> | undefined;

export const initializeAmplitude = () => {
  if (initialized) {
    return Promise.resolve();
  }
  if (initializingPromise != null) {
    return initializingPromise;
  }

  initializingPromise = new Promise<void>((resolve) => {
    const key = process.env.AMPLITUDE_API_KEY ?? '';
    amplitude.getInstance().init(key, undefined, undefined, () => {
      initialized = true;
      initializingPromise = undefined;
      resolve();
    });
  });

  return initializingPromise;
};

const getAmplitude = () => {
  return amplitude.getInstance();
};

export default getAmplitude;
