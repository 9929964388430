import { Box, Collapse, Divider, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import deprecatedStyled from 'styled-components';
import { ConcentrationLimit } from 'vise-types/pce1';
import { AssetClassKey, Feature } from 'vise-types/pce2_instrument';
import { AllocationsTemplate } from 'vise-types/template';
import { riskToRiskSlider } from '~/api/utils';
import useClients from '~/hooks/useClients';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useHousehold from '~/hooks/useHousehold';
import { Account } from '~/models/api';
import { getRemainingYears } from '~/routes/Portfolio/portfolioUtil';
import { ReactComponent as ChevronDown } from '~/static/images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '~/static/images/icons/chevron-up.svg';
import { ReactComponent as UserIcon } from '~/static/images/icons/user.svg';
import Skeleton from '~/synth/Skeleton';
import { getAssetClassFeaturesFromKey } from '~/utils/pce2Migration';
import {
  formatCurrency,
  formatPercent,
  getCustodianDisplayName,
  maskAccountNumber,
} from '../../../utils/format';
import Truncation from '../../Portfolio/components/Truncation';
import {
  ASSET_CLASS_TO_LABEL_MAP,
  ASSET_CLASS_TREES,
  SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS,
  SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL,
  SMALL_ACCOUNT_EQUITY_ASSET_CLASS_KEYS,
  SMALL_ACCOUNT_FI_ASSET_CLASS_KEYS,
} from '../Constants';
import useScreens from '../Screens';
import {
  AssetClassTreeNode,
  ConstructionInfo,
  DraftPortfolio,
  Pce2CapitalGainsLimits,
  Pce2ConstructionInfo,
} from '../Types';
import { cantSkipCategories } from '../screens/SummaryLandingScreen/SummaryUtils';
import {
  alternativesKeys,
  equitiesKeys,
  fixedIncomeKeys,
} from '../screens/SummaryLandingScreenV2/SummaryLandingScreen';
import { EditButton, logEditButtonClick } from '../screens/components';
import ActiveTiltSelection from '../screens/components/ActiveTiltSelection';
import {
  BorderBox,
  DetailsText,
  ExcludedCountries,
  ExcludedEsgAreas,
  ExcludedSectors,
  RestrictedStocks,
  SubCategoryAllocation,
  SubDetailsText,
  SubSectionBody,
  SubSectionHeader,
  SubSectionSubText,
  SubSectionText,
  sumKeys,
} from '../screens/components/SummarySections';
import {
  ActiveTiltForDisplay,
  computeActiveTiltForDisplay,
  formatPce1CashConcentrationLimit,
  getActiveTiltAmountText,
  getToggledAssetClassLeafLabel,
  getToggledAssetClassLeavesInAssetClassTree,
  isSmallAccount,
  shouldShowCapitalLossesAndGainsScreen,
  shouldSkipRestrictions,
  showTickerNumberScreen,
} from '../utils';

const PADDING_X = 5;

const Body = deprecatedStyled(Box).attrs({ flex: 1 })`
  overflow: auto;
`;

export const Section = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(0, PADDING_X),
}));

const SectionHeader = deprecatedStyled(Box).attrs({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  mb: 1.5,

  // When a button is present inside a header it makes the header 44px tall. Ensure header is always
  // this height so the layout stays the same with our without the button.
  minHeight: '44px',
  pr: 3,
})``;

const UserCircleIcon = () => {
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      borderRadius="50%"
      display="flex"
      height={40}
      justifyContent="center"
      minHeight={40}
      minWidth={40}
      width={40}
      bgcolor={theme.palette.blue[100]}
      color={theme.palette.blue[300]}
    >
      <UserIcon />
    </Box>
  );
};

// Renders one of the following:
//
//   - `clientId` non-null?
//     * that real client and its household
//   - `newClientInfo` non-null OR `newHouseholdInfo` non-null?
//     * new client info + "existing" household info
//     * new client info + "new" client info
const HouseholdAndClientBody = React.memo(function HouseholdAndClientBody({
  clientId,
  newClientInfo,
  newHouseholdInfo,
  existingPortfolio,
  initialValue,
}: {
  clientId: string | null;
  newClientInfo: DraftPortfolio['newClientInfo'];
  newHouseholdInfo: DraftPortfolio['newHouseholdInfo'];
  existingPortfolio: 'sample-portfolio' | Account | null;
  initialValue: number | null;
}) {
  const { data: clientsData, isValidating: clientsIsValidating } = useClients(
    clientId == null ? null : undefined
  );

  const { data: householdData } = useHousehold(
    newHouseholdInfo?.type === 'existing' ? newHouseholdInfo.household.id : null
  );

  const client = useMemo(() => {
    return clientsData == null ? null : clientsData.find((datum) => datum.id === clientId);
  }, [clientId, clientsData]);

  if (clientId == null && newClientInfo == null && newHouseholdInfo == null) {
    return <SubSectionBody>None selected</SubSectionBody>;
  }

  let content: React.ReactNode;
  if (clientId == null) {
    let clientContent: React.ReactNode;
    let householdContent: React.ReactNode;
    if (newClientInfo != null) {
      clientContent = (
        <SubSectionSubText>
          {newClientInfo.client.firstName} {newClientInfo.client.lastName}
        </SubSectionSubText>
      );
    }

    if (newHouseholdInfo?.type === 'new') {
      householdContent = <SubSectionText>{newHouseholdInfo.household.name}</SubSectionText>;
    } else if (householdData != null) {
      householdContent = <SubSectionText>{householdData.name}</SubSectionText>;
    }

    content = (
      <>
        {householdContent}
        {clientContent}
      </>
    );
  } else if (clientsData == null && clientsIsValidating) {
    content = <SubSectionSubText>Loading client…</SubSectionSubText>;
  } else if (client == null) {
    content = <SubSectionSubText>Unknown client</SubSectionSubText>;
  } else if (existingPortfolio != null) {
    let PortfolioInfo: JSX.Element;
    if (existingPortfolio !== 'sample-portfolio') {
      const isXray = existingPortfolio.accountDataSource === 'XRAY';
      const accountInformation = `${client.firstName} ${client.lastName} ${
        !isXray ? `- ${existingPortfolio.accountType}` : ''
      }`;
      PortfolioInfo = (
        <>
          <Typography variant="h4">
            <Truncation>{accountInformation}</Truncation>
          </Typography>
          <Box display="flex" color="grey.500" mt={0.5}>
            {existingPortfolio.taxable ? 'Non-qualified' : 'Qualified'}
            {!isXray && (
              <>
                <Box bgcolor="grey.300" mx={1}>
                  <Divider orientation="vertical" flexItem />
                </Box>
                <Box>
                  {maskAccountNumber(existingPortfolio.accountNumber)}
                  {', '}
                  {getCustodianDisplayName(existingPortfolio.custodianKey)}
                </Box>
              </>
            )}
          </Box>
        </>
      );
    } else {
      const clientName = `${client.firstName} ${client.lastName}`;
      PortfolioInfo = (
        <>
          <Typography variant="h4">
            <Truncation>{clientName}</Truncation>
          </Typography>
          <Box color="grey.500" mt={0.5}>
            Initial value: {formatCurrency(initialValue)}
          </Box>
        </>
      );
    }

    content = (
      <Box display="flex" alignItems="center" data-testid="account-summary" py={3}>
        <UserCircleIcon />
        <Box ml={3} width={235}>
          {PortfolioInfo}
        </Box>
      </Box>
    );
  }

  return content == null ? null : (
    <Section style={{ position: 'sticky', top: '0', zIndex: 10, backgroundColor: 'white' }}>
      {content}
    </Section>
  );
});

function ActiveTiltAmountText({
  activeTiltForDisplay,
  risk,
  canEdit,
}: {
  activeTiltForDisplay: ActiveTiltForDisplay;
  risk: number;
  canEdit: boolean;
}) {
  let activeTiltAmountText;
  if (activeTiltForDisplay.portfolioType === 'TARGET_VALUE') {
    activeTiltAmountText = 'Auto-adjusted by Vise';
  } else if (activeTiltForDisplay.portfolioType === 'LOSS_TOLERANCE') {
    activeTiltAmountText = getActiveTiltAmountText(
      activeTiltForDisplay.activeTilt,
      riskToRiskSlider(risk)
    );
  } else {
    return null;
  }
  return (
    <>
      <Box mb={1.5} data-testid="active-tilts-amount-text">
        <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
          <Typography variant="h4">Amount</Typography>
          {canEdit ? (
            <EditButton
              onClick={() => logEditButtonClick('active tilts')}
              to="/secure/portfolio-creator-next/active-tilts"
            />
          ) : null}
        </BorderBox>

        <SubSectionBody>
          <SubSectionText>{activeTiltAmountText}</SubSectionText>
        </SubSectionBody>
      </Box>
    </>
  );
}

interface Collapsable {
  open: boolean;
  onClick: () => void;
  canEdit?: boolean;
}

type CollapseSectionProps = {
  title: string | React.ReactNode;
  children: React.ReactNode;
} & Collapsable;

const CollapseSection = ({ title, children, open, onClick }: CollapseSectionProps) => {
  return (
    <Box width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        onClick={onClick}
        style={{
          cursor: 'pointer',
        }}
        py={3}
      >
        {title}
        <div>
          {open ? <ChevronUp width="20" height="20" /> : <ChevronDown width="20" height="20" />}
        </div>
      </Box>
      <Collapse in={open}>{children}</Collapse>
    </Box>
  );
};

type TransitionSettingsProps = {
  currentScreenName: string | number;
  draftPortfolio: DraftPortfolio;
  cashConcentrationLimit?: ConcentrationLimit;
  sortedLockedPositions: string[] | null;
  lockedPositions: string[];
} & Partial<ConstructionInfo | Pce2ConstructionInfo> &
  Collapsable;
const TransitionSettings = ({
  currentScreenName,
  lockedPositions,
  existingPortfolio,
  sortedLockedPositions,
  draftPortfolio,
  concentrationLimits,
  cashConcentrationLimit,
  mmf,
  ...rest
}: TransitionSettingsProps) => {
  if (!existingPortfolio) return null;
  if (!draftPortfolio) return null;
  return (
    <Section>
      <CollapseSection title={<Typography variant="h3">Transition Settings</Typography>} {...rest}>
        <div>
          {(currentScreenName !== 'locked-positions' && lockedPositions == null) ||
          existingPortfolio === 'sample-portfolio' ? null : (
            <Box data-testid="locked-positions-summary">
              <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
                <Typography variant="h4">Locked positions</Typography>
                {currentScreenName !== 'locked-positions' && (
                  <EditButton
                    onClick={() => logEditButtonClick('locked positions')}
                    to="/secure/portfolio-creator-next/locked-positions"
                  />
                )}
              </BorderBox>
              {sortedLockedPositions == null || sortedLockedPositions.length === 0 ? (
                <SubSectionBody>
                  <SubSectionText>None selected</SubSectionText>
                </SubSectionBody>
              ) : (
                Array.isArray(sortedLockedPositions) && (
                  <SubSectionBody>
                    {sortedLockedPositions.map((lp) => (
                      <SubSectionText key={lp}>{lp}</SubSectionText>
                    ))}
                  </SubSectionBody>
                )
              )}
            </Box>
          )}
          {concentrationLimits != null && (
            <Box mt={1.5}>
              <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
                <Typography variant="h4">Cash allocation</Typography>
                {currentScreenName !== 'cash-allocation' && (
                  <EditButton
                    onClick={() => logEditButtonClick('cash allocation')}
                    to="/secure/portfolio-creator-next/cash-allocation"
                  />
                )}
              </BorderBox>
              <SubSectionBody>
                <SubSectionText>
                  {cashConcentrationLimit == null ? (
                    <>No allocation set</>
                  ) : (
                    `${formatPce1CashConcentrationLimit(cashConcentrationLimit)}%`
                  )}
                </SubSectionText>
                {mmf && (
                  <Box mt={0.5}>
                    <Typography color="textSecondary" variant="body1">
                      MMF enabled
                    </Typography>
                  </Box>
                )}
              </SubSectionBody>
            </Box>
          )}
        </div>
      </CollapseSection>
    </Section>
  );
};

type TaxManagementProps = {
  currentScreenName: string | number;
  draftPortfolio: DraftPortfolio;
  longTermGainsLimits: Pce2CapitalGainsLimits | null;
  shortTermGainsLimits: Pce2CapitalGainsLimits | null;
} & Partial<ConstructionInfo | Pce2ConstructionInfo> &
  Collapsable;

const TaxManagement = ({
  currentScreenName,
  existingPortfolio,
  draftPortfolio,
  autoTlh,
  longTermGainsLimits,
  shortTermGainsLimits,
  longTermFederalTaxRate,
  longTermStateTaxRate,
  shortTermFederalTaxRate,
  shortTermStateTaxRate,
  ...rest
}: TaxManagementProps) => {
  const { data: featureFlags } = useFeatureFlags();
  if (!existingPortfolio) return null;
  if (existingPortfolio === 'sample-portfolio' || !existingPortfolio.taxable) return null;
  if (!draftPortfolio) return null;
  return (
    <>
      {currentScreenName === 'capital-losses-and-gains' || autoTlh != null ? (
        <Section>
          <CollapseSection title={<Typography variant="h3">Taxes</Typography>} {...rest}>
            <div>
              <Box mt={1.5} data-testid="tax-management-summary">
                <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
                  <Typography variant="h4">Tax management</Typography>
                  {currentScreenName !== 'capital-losses-and-gains' && (
                    <EditButton
                      onClick={() => logEditButtonClick('tax management')}
                      to="/secure/portfolio-creator-next/capital-losses-and-gains"
                    >
                      Edit
                    </EditButton>
                  )}
                </BorderBox>
                <SubSectionBody>
                  <SubSectionText>
                    Tax loss harvesting:{' '}
                    {autoTlh ? (
                      <Box color="green" display="inline">
                        Active
                      </Box>
                    ) : (
                      <>Off</>
                    )}
                  </SubSectionText>
                  {longTermGainsLimits != null && longTermGainsLimits.shouldLimitGains ? (
                    <Box mt={2}>
                      <Typography variant="inherit">Long-term gain limit</Typography>
                      <Box mt={0.5}>
                        <Typography color="textSecondary" variant="body1">
                          {longTermGainsLimits.maximumAmount != null
                            ? formatCurrency(longTermGainsLimits.maximumAmount) +
                              (longTermGainsLimits.shouldLimitSmallestAmount
                                ? ' (minimum amount)'
                                : '')
                            : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                  {shortTermGainsLimits != null && shortTermGainsLimits.shouldLimitGains ? (
                    <Box mt={2}>
                      <Typography variant="inherit">Short-term gain limit</Typography>
                      <Box mt={0.5}>
                        <Typography color="textSecondary" variant="body1">
                          {shortTermGainsLimits.maximumAmount != null
                            ? formatCurrency(shortTermGainsLimits.maximumAmount) +
                              (shortTermGainsLimits.shouldLimitSmallestAmount
                                ? ' (minimum amount)'
                                : '')
                            : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                  {existingPortfolio.accountDataSource === 'XRAY' &&
                  featureFlags?.enable_tax_rates === 'on' ? (
                    <>
                      <Box mt={2}>
                        <Typography variant="inherit">Long-term tax rate</Typography>
                        <Box mt={0.5}>
                          <Typography color="textSecondary" variant="body1">
                            {longTermFederalTaxRate != null && longTermStateTaxRate != null
                              ? formatPercent(longTermFederalTaxRate + longTermStateTaxRate, 1)
                              : '-'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box mt={2}>
                        <Typography variant="inherit">Short-term tax rate</Typography>
                        <Box mt={0.5}>
                          <Typography color="textSecondary" variant="body1">
                            {shortTermFederalTaxRate != null && shortTermStateTaxRate != null
                              ? formatPercent(shortTermFederalTaxRate + shortTermStateTaxRate, 1)
                              : '-'}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  ) : null}
                </SubSectionBody>
              </Box>
            </div>
          </CollapseSection>
        </Section>
      ) : null}
    </>
  );
};

const SmallAccountAssetClassCategorySectionBody = ({
  exclusions,
}: {
  exclusions: AssetClassKey[];
}) => {
  const exclusionsSet = new Set(exclusions);
  const toggledEquities = SMALL_ACCOUNT_EQUITY_ASSET_CLASS_KEYS.filter(
    (x) => !exclusionsSet.has(x)
  );
  const toggledFi = SMALL_ACCOUNT_FI_ASSET_CLASS_KEYS.filter((x) => !exclusionsSet.has(x));

  return (
    <>
      <SubSectionHeader>Equities</SubSectionHeader>
      <SubSectionBody>
        {toggledEquities.length > 0 ? (
          toggledEquities.map((equity) => (
            <SubSectionText key={equity}>
              {SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL[equity]}
            </SubSectionText>
          ))
        ) : (
          <SubSectionText>None selected</SubSectionText>
        )}
      </SubSectionBody>
      <SubSectionHeader>Fixed income</SubSectionHeader>
      <SubSectionBody>
        {toggledFi.length > 0 ? (
          toggledFi.map((fi) => (
            <SubSectionText key={fi}> {SMALL_ACCOUNT_ASSET_CLASS_KEY_TO_LABEL[fi]}</SubSectionText>
          ))
        ) : (
          <SubSectionText>None selected</SubSectionText>
        )}
      </SubSectionBody>
    </>
  );
};

const CustomAllocations = ({
  assetAllocation,
  currentScreenName,
}: {
  assetAllocation?: { [key in AssetClassKey]?: number };
  currentScreenName: string;
}) => (
  <>
    <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
      <Typography variant="h4">Portfolio split</Typography>
      {currentScreenName !== 'custom-allocation' && (
        <EditButton
          onClick={() => logEditButtonClick('portfolio split')}
          to="/secure/portfolio-creator-next/custom-allocation"
        >
          Edit
        </EditButton>
      )}
    </BorderBox>
    <SubSectionBody>
      <DetailsText>Equities</DetailsText>
      <SubDetailsText>{(sumKeys(equitiesKeys, assetAllocation) * 100).toFixed(0)}%</SubDetailsText>
      <Box my={2}>
        <DetailsText>Fixed Income</DetailsText>
        <SubDetailsText>
          {(sumKeys(fixedIncomeKeys, assetAllocation) * 100).toFixed(0)}%
        </SubDetailsText>
      </Box>
      <DetailsText>Alternatives</DetailsText>
      <SubDetailsText>
        {(sumKeys(alternativesKeys, assetAllocation) * 100).toFixed(0)}%
      </SubDetailsText>
    </SubSectionBody>

    {assetAllocation === undefined || Object.keys(assetAllocation).length === 0 ? null : (
      <>
        <Box mt={1.5}>
          <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
            <Typography variant="h4">Equities</Typography>
            {currentScreenName !== 'custom-allocation' && (
              <EditButton
                onClick={() => logEditButtonClick('equities')}
                to="/secure/portfolio-creator-next/custom-allocation"
              />
            )}
          </BorderBox>

          <SubSectionBody pb={1.5}>
            <SubCategoryAllocation parent="EQUITY/US" assetAllocation={assetAllocation} />
            <SubCategoryAllocation parent="EQUITY/DEVELOPED" assetAllocation={assetAllocation} />
            <SubCategoryAllocation
              parent="EQUITY/EMERGING_EQUITY"
              assetAllocation={assetAllocation}
            />
            <SubCategoryAllocation parent="EQUITY/US_REIT" assetAllocation={assetAllocation} />
          </SubSectionBody>
        </Box>
        <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
          <Typography variant="h4">Fixed income</Typography>
          {currentScreenName !== 'custom-allocation' && (
            <EditButton
              onClick={() => logEditButtonClick('fixed income')}
              to="/secure/portfolio-creator-next/custom-allocation"
            />
          )}
        </BorderBox>
        <SubSectionBody pb={1.5}>
          <SubCategoryAllocation parent="FIXED_INCOME/DOMESTIC" assetAllocation={assetAllocation} />
          <SubCategoryAllocation
            parent="FIXED_INCOME/INTERNATIONAL"
            assetAllocation={assetAllocation}
          />
          <SubCategoryAllocation
            parent="FIXED_INCOME/EMERGING_FI"
            assetAllocation={assetAllocation}
          />
        </SubSectionBody>
        <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
          <Typography variant="h4">Alternatives</Typography>
          {currentScreenName !== 'custom-allocation' && (
            <EditButton
              onClick={() => logEditButtonClick('alternatives')}
              to="/secure/portfolio-creator-next/custom-allocation"
            />
          )}
        </BorderBox>
        <SubSectionBody pb={1.5}>
          <SubCategoryAllocation
            parent="ALTERNATIVES/COMMODITIES"
            assetAllocation={assetAllocation}
          />
        </SubSectionBody>
      </>
    )}
  </>
);

type AssetClassCategorySectionBodyProps = {
  exclusions: AssetClassKey[];
  isSmallAccount: boolean;
  primaryAssetClassTreeRoot: AssetClassTreeNode;
};

const AssetClassCategorySectionBody = (props: AssetClassCategorySectionBodyProps) => {
  const { exclusions, isSmallAccount, primaryAssetClassTreeRoot } = props;
  const toggledAssetClassMap = getToggledAssetClassLeavesInAssetClassTree(
    primaryAssetClassTreeRoot,
    exclusions,
    isSmallAccount
  );

  if (toggledAssetClassMap.size === 0) {
    return (
      <SubSectionBody>
        <SubSectionText>None selected</SubSectionText>
      </SubSectionBody>
    );
  }

  const assetClassTreeSections: JSX.Element[] = [];

  toggledAssetClassMap.forEach((toggledDescendentKeys, topLevelAssetClassKey) => {
    const topLevelAssetClassFeatures = getAssetClassFeaturesFromKey(topLevelAssetClassKey);
    const topLevelAssetClassLabel = ASSET_CLASS_TO_LABEL_MAP.get(
      topLevelAssetClassFeatures[topLevelAssetClassFeatures.length - 1]
    );

    // Top level key is also a leaf in this case.
    if (toggledDescendentKeys == null) {
      assetClassTreeSections.push(
        <SubSectionBody key={topLevelAssetClassLabel}>
          <SubSectionText>{topLevelAssetClassLabel}</SubSectionText>
        </SubSectionBody>
      );
    } else {
      assetClassTreeSections.push(
        <Box pr={PADDING_X} paddingTop={2} key={topLevelAssetClassLabel}>
          <SubSectionText>{topLevelAssetClassLabel}</SubSectionText>
          {toggledDescendentKeys.length > 0 ? (
            <>
              {toggledDescendentKeys.map((descendentKey) => (
                <SubSectionSubText key={descendentKey}>
                  {getToggledAssetClassLeafLabel(topLevelAssetClassKey, descendentKey)}
                </SubSectionSubText>
              ))}
            </>
          ) : (
            <SubSectionSubText>None selected</SubSectionSubText>
          )}
        </Box>
      );
    }
  });

  return <>{assetClassTreeSections}</>;
};

type AssetClassesProps = {
  exclusions: AssetClassKey[];
  isSmallAccount: boolean;
  canEdit: boolean;
  assetAllocation?: { [key in AssetClassKey]?: number };
  allocationTemplate?: AllocationsTemplate | null;
  largestStepVisited: number;
  currentScreenName: string;
  useAllocationTemplate?: boolean | null;
} & Partial<ConstructionInfo | Pce2ConstructionInfo> &
  Collapsable;

export const AssetClasses = ({
  exclusions,
  isSmallAccount,
  etfExclusive,
  canEdit,
  assetAllocation,
  allocationTemplate,
  useGlidePath,
  investmentTimeline,
  assetClassConcentrationLimits,
  largestStepVisited,
  currentScreenName,
  useAllocationTemplate,
  ...rest
}: AssetClassesProps) => {
  const screens = useScreens(isSmallAccount);

  const { data: featureFlags } = useFeatureFlags();

  let etfExclusiveAssetClasses: AssetClassKey[] = [];
  if ('etfExclusiveAssetClasses' in rest) {
    etfExclusiveAssetClasses = rest.etfExclusiveAssetClasses || [];
  }
  const minSymbolAssetClasses = rest.minSymbolAssetClasses || [];
  const minSymbolAssetClassesSet = new Set(minSymbolAssetClasses);
  const etfExclusiveAssetClassesSet = new Set(etfExclusiveAssetClasses);
  const exclusionsSet = new Set(exclusions);

  const nonSmallAccountAllocationsContent =
    assetAllocation == null ? (
      <>
        <Box mb={4} data-testid="equities-summary">
          <SubSectionHeader>Equities</SubSectionHeader>
          <AssetClassCategorySectionBody
            exclusions={exclusions}
            isSmallAccount={isSmallAccount}
            primaryAssetClassTreeRoot={ASSET_CLASS_TREES[0]}
          />
        </Box>
        <Box mb={4} data-testid="fixed-income-summary">
          <SubSectionHeader>Fixed income</SubSectionHeader>
          <AssetClassCategorySectionBody
            isSmallAccount={isSmallAccount}
            exclusions={exclusions}
            primaryAssetClassTreeRoot={ASSET_CLASS_TREES[1]}
          />
        </Box>
        <Box data-testid="alternatives-summary">
          <SubSectionHeader>Alternatives</SubSectionHeader>
          <AssetClassCategorySectionBody
            isSmallAccount={isSmallAccount}
            exclusions={exclusions}
            primaryAssetClassTreeRoot={ASSET_CLASS_TREES[2]}
          />
        </Box>
      </>
    ) : (
      <CustomAllocations currentScreenName={currentScreenName} assetAllocation={assetAllocation} />
    );

  const AssetClassContent = () => (
    <>
      {isSmallAccount ? (
        <SmallAccountAssetClassCategorySectionBody exclusions={exclusions} />
      ) : (
        <>{nonSmallAccountAllocationsContent}</>
      )}
    </>
  );

  const InvestmentStrategyContent = () => (
    <>
      {isSmallAccount ? (
        <Box mb={1.5} data-testid="securities-summary">
          <SubSectionHeader>Investment Vehicles</SubSectionHeader>
          <SubSectionBody>
            <SubSectionText>Limit to ETFs</SubSectionText>
          </SubSectionBody>
        </Box>
      ) : (
        <Box mb={1.5} data-testid="securities-summary">
          <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
            <Typography variant="h4">Investment strategy</Typography>
            {canEdit && currentScreenName !== 'fine-tune-strategy' ? (
              <EditButton
                onClick={() => logEditButtonClick('investment strategy')}
                to="/secure/portfolio-creator-next/fine-tune-strategy"
              >
                Edit
              </EditButton>
            ) : null}
          </BorderBox>
          {!showTickerNumberScreen(isSmallAccount ? 'SMALL' : 'FULL', exclusions) ||
          etfExclusive ? (
            <SubSectionBody>
              <SubSectionText>Limit to ETFs</SubSectionText>
            </SubSectionBody>
          ) : (
            <SubSectionBody>
              {SINGLE_SECURITY_STRATEGY_ASSET_CLASS_KEYS.filter(
                (key) => !exclusionsSet.has(key)
              ).map((key, i) => {
                const name = key
                  .split('/')
                  .slice(1)
                  .map((feature) => ASSET_CLASS_TO_LABEL_MAP.get(feature as Feature) || '')
                  .join(' ');
                return (
                  <Box key={key} mt={i === 0 ? 0 : 2}>
                    <SubSectionText>{name}</SubSectionText>
                    <SubSectionSubText>
                      {etfExclusiveAssetClassesSet.has(key) ? 'ETF-only' : 'Single-securities'}
                    </SubSectionSubText>
                    <Box hidden={featureFlags?.disable_hold_on_custom_number_of_tickers !== 'on'}>
                      <SubSectionSubText>
                        {minSymbolAssetClassesSet.has(key)
                          ? 'Fewer holdings'
                          : 'Vise-recommended asset concentration'}
                      </SubSectionSubText>
                    </Box>
                  </Box>
                );
              })}
            </SubSectionBody>
          )}
        </Box>
      )}
    </>
  );

  const SpecialConditionsContent = () => (
    <Box mb={1.5}>
      <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
        <Typography variant="h4">Special conditions</Typography>
        {canEdit && currentScreenName !== 'special-conditions' ? (
          <EditButton
            onClick={() => logEditButtonClick('special conditions')}
            to="/secure/portfolio-creator-next/special-conditions"
          >
            Edit
          </EditButton>
        ) : null}
      </BorderBox>
      <SubSectionBody>
        <>
          {!useGlidePath && !assetClassConcentrationLimits?.isEnabled ? (
            'None selected'
          ) : (
            <>
              {useGlidePath ? (
                <>
                  <SubSectionText>Glide path</SubSectionText>
                  {investmentTimeline != null ? (
                    <SubSectionSubText>
                      {getRemainingYears(moment(investmentTimeline), moment())} years remaining)
                    </SubSectionSubText>
                  ) : null}
                </>
              ) : null}
              {assetClassConcentrationLimits?.isEnabled &&
              (assetClassConcentrationLimits?.equities ||
                assetClassConcentrationLimits?.fixedIncome) ? (
                <SubSectionText>Max concentration limits</SubSectionText>
              ) : null}
              {assetClassConcentrationLimits?.isEnabled &&
              assetClassConcentrationLimits?.equities ? (
                <SubSectionSubText>
                  Equities {assetClassConcentrationLimits?.equities}%
                </SubSectionSubText>
              ) : null}
              {assetClassConcentrationLimits?.isEnabled &&
              assetClassConcentrationLimits?.fixedIncome ? (
                <SubSectionSubText>
                  Fixed income {assetClassConcentrationLimits?.fixedIncome}%
                </SubSectionSubText>
              ) : null}
              {assetClassConcentrationLimits?.isEnabled &&
              assetClassConcentrationLimits?.alternatives ? (
                <SubSectionSubText>
                  Alternatives {assetClassConcentrationLimits?.alternatives}%
                </SubSectionSubText>
              ) : null}
            </>
          )}
        </>
      </SubSectionBody>
    </Box>
  );

  return largestStepVisited >= screens['build-or-use-allocation-template'].order ? (
    <Section>
      <CollapseSection title={<Typography variant="h3">Asset classes</Typography>} {...rest}>
        <>
          <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
            <Typography variant="h4">Strategy type</Typography>
            {canEdit && currentScreenName !== 'build-or-use-allocation-template' ? (
              <EditButton
                onClick={() => logEditButtonClick('allocation strategy')}
                to="/secure/portfolio-creator-next/build-or-use-allocation-template"
              >
                Edit
              </EditButton>
            ) : null}
          </BorderBox>
          <SubSectionBody data-cy="allocation-template-name">
            <DetailsText>
              {useAllocationTemplate == null && 'None selected'}
              {useAllocationTemplate && !allocationTemplate && 'Saved template'}
              {useAllocationTemplate === false && 'New allocation'}
            </DetailsText>
            {allocationTemplate?.name}
            {allocationTemplate?.createdAt && (
              <Box component="span" color="grey.400" ml={1}>
                Created {format(new Date(allocationTemplate.createdAt), 'MMM dd, yyyy')}
              </Box>
            )}
          </SubSectionBody>
        </>
        <>
          {largestStepVisited > screens['build-or-use-allocation-template'].order ? (
            <AssetClassContent />
          ) : null}
        </>
        <>
          {largestStepVisited >= screens['fine-tune-strategy'].order && exclusions != null ? (
            <>
              <InvestmentStrategyContent />
            </>
          ) : null}
          {largestStepVisited >= screens['special-conditions'].order && (
            <>
              <SpecialConditionsContent />
            </>
          )}
        </>
      </CollapseSection>
    </Section>
  ) : null;
};

type TiltProps = {
  activeTiltForDisplay?: ActiveTiltForDisplay;
  canEdit: boolean;
  risk: number;
  currentScreenName: string;
} & Collapsable;
export const Tilt = ({
  activeTiltForDisplay,
  risk,
  canEdit,
  currentScreenName,
  ...rest
}: TiltProps) => {
  return (
    <>
      {!activeTiltForDisplay ? null : (
        <Section data-testid="active-tilts-summary">
          <CollapseSection title={<Typography variant="h3">Tilt</Typography>} {...rest}>
            <Box mb={1.5} data-testid="active-tilts-selection">
              <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
                <Typography variant="h4">Selection</Typography>
                {canEdit && currentScreenName !== 'active-tilts' ? (
                  <EditButton
                    onClick={() => logEditButtonClick('active tilts')}
                    to="/secure/portfolio-creator-next/active-tilts"
                  />
                ) : null}
              </BorderBox>

              <SubSectionBody>
                <SubSectionText>
                  <ActiveTiltSelection activeTiltForDisplay={activeTiltForDisplay} />
                </SubSectionText>
              </SubSectionBody>
            </Box>
            <ActiveTiltAmountText
              activeTiltForDisplay={activeTiltForDisplay}
              risk={risk}
              canEdit={canEdit && currentScreenName !== 'active-tilts'}
            />
          </CollapseSection>
        </Section>
      )}
    </>
  );
};

type RestrictionsProps = {
  currentScreenName: string | number;
  exclusions: AssetClassKey[];
  canEdit: boolean;
} & Pick<
  ConstructionInfo,
  | 'etfExclusive'
  | 'restrictedStocks'
  | 'excludedCountries'
  | 'excludedSectors'
  | 'excludedIndustries'
  | 'excludedEsgAreas'
> &
  Pick<DraftPortfolio, 'accountSize' | 'restrictionsTemplates'> &
  Collapsable;
const Restrictions = ({
  currentScreenName,
  etfExclusive,
  restrictedStocks,
  excludedCountries,
  excludedSectors,
  excludedEsgAreas,
  accountSize,
  excludedIndustries,
  exclusions,
  canEdit,
  restrictionsTemplates,
  ...rest
}: RestrictionsProps) => {
  const isSmall = isSmallAccount(accountSize);
  return (
    <>
      {!isSmall &&
      (currentScreenName === 'build-restrictions' ||
        restrictedStocks != null ||
        excludedSectors != null ||
        excludedCountries != null) ? (
        <Section>
          <CollapseSection title={<Typography variant="h3">Restrictions</Typography>} {...rest}>
            <div data-testid="restrictions-summary">
              <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
                <Typography variant="h4">Saved templates</Typography>
                {canEdit &&
                !shouldSkipRestrictions({
                  etfExclusive,
                  exclusions,
                }) &&
                currentScreenName !== 'build-restrictions' ? (
                  <EditButton
                    onClick={() => logEditButtonClick('restrictions')}
                    to="/secure/portfolio-creator-next/build-restrictions"
                  />
                ) : null}
              </BorderBox>
              <SubSectionBody data-testid="restrictions-template-name-summary" mb={1.5}>
                {restrictionsTemplates?.length ? (
                  <>
                    {restrictionsTemplates.map((t) => (
                      <SubDetailsText key={t.name}>
                        {t.name}{' '}
                        <Box component="span" color="grey.400">
                          (Created {moment(t.createdAt).format('MMM DD, YYYY')})
                        </Box>
                      </SubDetailsText>
                    ))}
                  </>
                ) : (
                  'None selected'
                )}
              </SubSectionBody>
              <Box mb={1.5}>
                <RestrictedStocks restrictedStocks={restrictedStocks} editButton={canEdit} />
              </Box>
              <Box mb={1.5}>
                <ExcludedSectors
                  excludedIndustries={excludedIndustries}
                  excludedSectors={excludedSectors}
                  editButton={canEdit}
                />
              </Box>
              <Box mb={1.5}>
                <ExcludedCountries restrictedCountries={excludedCountries} editButton={canEdit} />
              </Box>
              <Box mb={1.5}>
                <ExcludedEsgAreas excludedEsgAreas={excludedEsgAreas} editButton={canEdit} />
              </Box>
            </div>
          </CollapseSection>
        </Section>
      ) : null}
    </>
  );
};

function ClientSection({
  draftPortfolio,
  open,
  onClick,
  currentScreenName,
}: {
  draftPortfolio: DraftPortfolio;
  open: boolean;
  onClick: () => void;
  currentScreenName: string;
}) {
  const { data: clientsData } = useClients();
  const selectedClient = clientsData?.find(
    (client) => client.id === draftPortfolio.constructionInfo.clientId
  );
  return (
    <Section>
      <CollapseSection
        open={open}
        onClick={onClick}
        title={<Typography variant="h3">Client details</Typography>}
      >
        <BorderBox display="flex" alignItems="baseline" justifyContent="space-between">
          <Typography variant="h4">Household and client</Typography>
          {currentScreenName !== 'select-client' ? (
            <EditButton
              onClick={() => logEditButtonClick('restrictions')}
              to="/secure/portfolio-creator-next/select-client"
            />
          ) : null}
        </BorderBox>
        <SubSectionBody>
          {!selectedClient ? (
            <SubSectionText>None selected</SubSectionText>
          ) : (
            <>
              <SubSectionText>{selectedClient.clientGroup?.name ?? ''}</SubSectionText>
              <SubSectionSubText>{`${selectedClient.firstName} ${selectedClient.lastName}`}</SubSectionSubText>
            </>
          )}
        </SubSectionBody>
      </CollapseSection>
    </Section>
  );
}

function PortfolioSummaryContent({
  currentScreenName,
  draftPortfolio,
  largestStepVisited,
}: PortfolioSummaryProps) {
  const {
    constructionInfo,
    lockedPositions,
    newClientInfo,
    newHouseholdInfo,
    accountSize,
    allocationTemplate,
    useAllocationTemplate,
  } = draftPortfolio;
  const {
    activeTilt,
    clientId,
    concentrationLimits,
    etfExclusive,
    existingPortfolio,
    risk,
    initialValue,
    useGlidePath,
    assetClassConcentrationLimits,
  } = constructionInfo;

  type PortfolioSummarySection =
    | 'CLIENT'
    | 'TRANSITION_SETTINGS'
    | 'ASSET_CLASSES'
    | 'TILTS'
    | 'RESTRICTIONS'
    | 'TAXES';
  const [collapseItems, setCollapseItems] = useState(new Array<PortfolioSummarySection>());
  const screens = useScreens(false);
  const updateCollapseItem = useCallback((item: PortfolioSummarySection) => {
    setCollapseItems((prev) => {
      if (prev.includes(item)) {
        const idx = prev.findIndex((i) => i === item);
        const updatedItems = [...prev];
        updatedItems.splice(idx, 1);
        return updatedItems;
      }
      return [...prev, item];
    });
  }, []);

  useEffect(() => {
    switch (currentScreenName) {
      case 'select-client': {
        setCollapseItems(['CLIENT']);
        break;
      }
      case 'locked-positions': {
        setCollapseItems(['TRANSITION_SETTINGS']);
        break;
      }
      case 'build-or-use-allocation-template': {
        setCollapseItems(['ASSET_CLASSES']);
        break;
      }
      case 'active-tilts': {
        setCollapseItems(['TILTS']);
        break;
      }
      case 'build-restrictions': {
        setCollapseItems(['RESTRICTIONS']);
        break;
      }
      case 'capital-losses-and-gains': {
        setCollapseItems(['TAXES']);
        break;
      }
      default:
        break;
    }
  }, [currentScreenName, updateCollapseItem]);

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'exclusions' does not exist on type 'Asse... Remove this comment to see the full error message
  let exclusions: Pce2ConstructionInfo['assetClassConcentrationLimits']['exclusions'];
  if ('assetClassConcentrationLimits' in constructionInfo) {
    exclusions = constructionInfo.assetClassConcentrationLimits?.exclusions;
  }

  const {
    constructionInfo: {
      autoTlh,
      capitalGainsLimits,
      assetAllocation,
      longTermFederalTaxRate,
      longTermStateTaxRate,
      shortTermFederalTaxRate,
      shortTermStateTaxRate,
    },
  } = draftPortfolio;

  const {
    longTermGainsLimits,
    shortTermGainsLimits,
  }: {
    longTermGainsLimits: Pce2CapitalGainsLimits | null;
    shortTermGainsLimits: Pce2CapitalGainsLimits | null;
  } = capitalGainsLimits ?? { longTermGainsLimits: null, shortTermGainsLimits: null };

  const sortedLockedPositions = useMemo(
    // Slice `lockedPositions` to copy it first because `sort` mutates the array.
    () => (lockedPositions == null ? null : lockedPositions.slice().sort()),
    [lockedPositions]
  );

  const cashConcentrationLimit = concentrationLimits?.find(
    (concentrationLimit) => concentrationLimit.asset_class === 'Cash'
  );

  const isSmall = isSmallAccount(accountSize);

  const activeTiltForDisplay =
    largestStepVisited > screens['active-tilts'].order
      ? computeActiveTiltForDisplay(
          activeTilt?.tiltAmount,
          activeTilt?.tiltType,
          riskToRiskSlider(risk),
          !!etfExclusive,
          isSmall,
          activeTilt ? !activeTilt.isEnabled : false
        )
      : undefined;

  const { data: featureFlags } = useFeatureFlags();

  const cantEditCatogories = cantSkipCategories({
    draftPortfolio,
    featureFlags,
  });

  return (
    <>
      <>
        {clientId && existingPortfolio && (
          <HouseholdAndClientBody
            clientId={clientId}
            newClientInfo={newClientInfo}
            newHouseholdInfo={newHouseholdInfo}
            existingPortfolio={existingPortfolio}
            initialValue={initialValue}
          />
        )}
      </>
      <ClientSection
        currentScreenName={currentScreenName}
        draftPortfolio={draftPortfolio}
        open={collapseItems.includes('CLIENT')}
        onClick={() => updateCollapseItem('CLIENT')}
      />
      {largestStepVisited >= screens['locked-positions'].order &&
        shouldShowCapitalLossesAndGainsScreen(draftPortfolio) && (
          <TransitionSettings
            open={collapseItems.includes('TRANSITION_SETTINGS')}
            onClick={() => updateCollapseItem('TRANSITION_SETTINGS')}
            currentScreenName={currentScreenName}
            lockedPositions={lockedPositions}
            existingPortfolio={existingPortfolio}
            sortedLockedPositions={sortedLockedPositions}
            draftPortfolio={draftPortfolio}
            concentrationLimits={concentrationLimits}
            cashConcentrationLimit={cashConcentrationLimit}
          />
        )}
      <AssetClasses
        open={collapseItems.includes('ASSET_CLASSES')}
        onClick={() => updateCollapseItem('ASSET_CLASSES')}
        exclusions={exclusions}
        isSmallAccount={isSmall}
        canEdit={cantEditCatogories.length === 0}
        assetAllocation={assetAllocation}
        allocationTemplate={allocationTemplate}
        useGlidePath={useGlidePath}
        assetClassConcentrationLimits={assetClassConcentrationLimits}
        largestStepVisited={largestStepVisited}
        currentScreenName={currentScreenName}
        useAllocationTemplate={useAllocationTemplate}
        {...constructionInfo}
      />
      <Tilt
        canEdit={cantEditCatogories.length === 0}
        open={collapseItems.includes('TILTS')}
        onClick={() => updateCollapseItem('TILTS')}
        activeTiltForDisplay={activeTiltForDisplay}
        risk={risk}
        currentScreenName={currentScreenName}
      />
      <Restrictions
        canEdit={cantEditCatogories.length === 0}
        open={collapseItems.includes('RESTRICTIONS')}
        onClick={() => updateCollapseItem('RESTRICTIONS')}
        currentScreenName={currentScreenName}
        exclusions={exclusions}
        {...draftPortfolio}
        {...draftPortfolio.constructionInfo}
      />
      <TaxManagement
        open={collapseItems.includes('TAXES')}
        onClick={() => updateCollapseItem('TAXES')}
        currentScreenName={currentScreenName}
        existingPortfolio={existingPortfolio}
        draftPortfolio={draftPortfolio}
        autoTlh={autoTlh}
        longTermGainsLimits={longTermGainsLimits}
        shortTermGainsLimits={shortTermGainsLimits}
        longTermFederalTaxRate={longTermFederalTaxRate}
        longTermStateTaxRate={longTermStateTaxRate}
        shortTermFederalTaxRate={shortTermFederalTaxRate}
        shortTermStateTaxRate={shortTermStateTaxRate}
      />
    </>
  );
}

interface PortfolioSummaryProps {
  draftPortfolio: DraftPortfolio;
  currentScreenName: string;
  largestStepVisited: number;
}

export default function PortfolioSummary({
  draftPortfolio,
  currentScreenName,
  largestStepVisited,
}: PortfolioSummaryProps) {
  // This dummy div is to ensure the portfolio summary side bar is always scroll to the bottom
  const hiddenDiv = useRef(null);
  useEffect(() => {
    if (hiddenDiv.current) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      hiddenDiv.current.scrollIntoView({ block: 'end' });
    }
  });

  return (
    <Box
      bgcolor="white"
      sx={{ borderLeft: 1, borderLeftColor: 'grey.200' }}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      {draftPortfolio.accountSize === 'SMALL' && (
        <Box bgcolor="blue.100" display="flex" alignItems="center" height={56} px={2}>
          <Typography variant="body2">Optimized for low value.</Typography>
        </Box>
      )}
      {draftPortfolio.accountSize === 'TINY' && (
        <Box bgcolor="#FCF0F0" display="flex" alignItems="center" height={56} px={2}>
          <Typography variant="body2">Insufficient Vise-managed value.</Typography>
        </Box>
      )}
      <Body>
        {draftPortfolio.bootstrappingState === 'READY' ? (
          <>
            <PortfolioSummaryContent
              currentScreenName={currentScreenName}
              draftPortfolio={draftPortfolio}
              largestStepVisited={largestStepVisited}
            />
            <div ref={hiddenDiv} />
          </>
        ) : (
          <Section>
            <SectionHeader>
              <Typography variant="h3">Client details</Typography>
            </SectionHeader>
            <SubSectionHeader>
              <Skeleton height="30px" />
            </SubSectionHeader>
            <SubSectionBody>
              <Skeleton height="30px" />
            </SubSectionBody>
          </Section>
        )}
      </Body>
    </Box>
  );
}
