import { Box, Chip, FormControl, FormLabel, Grid, Link } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ActionMeta } from 'react-select';
import PopoverCard from '~/synth/PopoverCard';
import PopoverLink from '~/synth/PopoverLink';
import PopoverTrigger from '~/synth/PopoverTrigger';
import StyledMultiselect from '~/synth/inputs/StyledMultiselect';
import StyledSelect from '~/synth/inputs/StyledSelect';
import { ESG_GROUPS } from '../../Constants';
import { DetailsText } from './SummarySections';

type EsgGroup = {
  label: string;
  value: string;
  areas: EsgProductArea[];
};

type EsgProductArea = {
  label: string;
  value: string;
  group: string;
};

type EsgSelectorProps = {
  excludedProductAreas: string[];
  onChange: (esgAreasToAdd: string[] | null, esgAreasToDelete: string[] | null) => void;
};

export default function EsgSelector({ excludedProductAreas, onChange }: EsgSelectorProps) {
  const [selectedEsgGroup, setSelectedEsgGroup] = useState<EsgGroup | null>(null);
  const esgGroupOptions: EsgGroup[] = Object.keys(ESG_GROUPS).map((key) => ({
    label: key,
    value: key,
    areas: ESG_GROUPS[key].map((area) => ({
      label: area[0],
      value: area[1],
      group: key,
    })),
  }));

  const esgProductAreaToGroupMap = esgGroupOptions.reduce((acc, group) => {
    group.areas.forEach((val) => {
      acc[val.value] = { group: group.value, label: val.label };
    });
    return acc;
  }, {});

  const esgProductAreaOptions = useMemo(
    () => (selectedEsgGroup == null ? [] : selectedEsgGroup.areas),
    [selectedEsgGroup]
  );
  const excludedProductAreasSet = useMemo(
    () => new Set(excludedProductAreas),
    [excludedProductAreas]
  );

  const selectedEsgProductAreas = useMemo(() => {
    if (selectedEsgGroup == null) {
      return [];
    }

    return selectedEsgGroup.areas.filter((productArea) =>
      excludedProductAreasSet.has(productArea.value)
    );
  }, [excludedProductAreasSet, selectedEsgGroup]);

  function handleEsgGroupChange(option: EsgGroup) {
    if (option == null || Array.isArray(option)) {
      return;
    }
    setSelectedEsgGroup(option);
  }

  function handleEsgAreasChange(
    allSelectedOptions: EsgProductArea[],
    { action }: ActionMeta<EsgProductArea>
  ) {
    if (
      action !== 'select-option' &&
      action !== 'deselect-option' &&
      action !== 'clear' &&
      action !== 'set-value'
    )
      return;

    const selectedProductAreas = allSelectedOptions.map((area) => area.value);
    const selectedProductAreasSet = new Set(selectedProductAreas);
    const esgAreasToAdd = selectedProductAreas.filter((a) => !excludedProductAreasSet.has(a));

    const esgAreasToDelete = excludedProductAreas.filter(
      (area) =>
        esgProductAreaToGroupMap[area].group === selectedEsgGroup?.value &&
        !selectedProductAreasSet.has(area)
    );
    onChange(esgAreasToAdd, esgAreasToDelete);
  }

  return (
    <>
      <FormControl fullWidth>
        <Box mb={1}>
          {/* Unset lineheight set by form label */}
          <FormLabel htmlFor="esg" style={{ lineHeight: 1.25 }}>
            <Box display="flex" mb={0.5}>
              Values
            </Box>
          </FormLabel>
          <DetailsText variant="body1" color="textSecondary">
            Exclude companies according to ethical, impact, compliance, or ESG&nbsp;
            <PopoverTrigger
              overlay={({ close }) => (
                <PopoverCard
                  title="Values criteria"
                  body={
                    <>
                      <p>
                        Most categories use revenue as a proxy for involvement in a Value. For
                        example, the ‘Shale Energy’ sub-category includes companies that derive 5%
                        or more of their revenues from shale energy exploration and/or production.
                      </p>
                      <p>
                        Other categories include companies that have material ties to a Value, but
                        do not derive revenue from a specific product or service (e.g. Animal
                        Testing, Controversial Weapons).
                      </p>
                      <p>
                        For detailed definitions, see our&nbsp;
                        <Link
                          href="https://drive.google.com/file/d/1BNGF-UN49yG4Ufgtvji_i9gRxCI9rJbE"
                          target="_blank"
                        >
                          Values criteria whitepaper.
                        </Link>
                      </p>
                    </>
                  }
                  onClose={close}
                />
              )}
            >
              <PopoverLink style={{ marginBottom: '0.125rem' }}>criteria</PopoverLink>
            </PopoverTrigger>
            .
          </DetailsText>
        </Box>
        <Grid container>
          <Grid item xs={4}>
            <StyledSelect
              isLoading={esgGroupOptions == null}
              options={esgGroupOptions}
              onChange={handleEsgGroupChange}
              placeholder="All ESG Groups"
              isSearchable={false}
              classNamePrefix="react-select"
              inputId="esgGroups"
              value={selectedEsgGroup}
            />
          </Grid>
          <Grid item xs={8}>
            <StyledMultiselect
              aria-describedby="subsectors-helper"
              isDisabled={selectedEsgGroup == null}
              options={esgProductAreaOptions}
              allowSelectAll
              classNamePrefix="react-select"
              onChange={handleEsgAreasChange}
              placeholder="Browse sub-categories"
              inputId="esgAreas"
              value={selectedEsgProductAreas}
            />
          </Grid>
        </Grid>
      </FormControl>
      {excludedProductAreas.map((area) => (
        <Box mr={1.5} mt={1.5} key={area}>
          <Chip
            label={esgProductAreaToGroupMap[area].label}
            onDelete={() => {
              onChange(null, [area]);
            }}
          />
        </Box>
      ))}
    </>
  );
}
