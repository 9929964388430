import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { pullAt, size } from 'lodash';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { cache } from 'swr';
import { PortfolioIntelligenceFull } from 'vise-types/pce1';
import { AssetClassKey } from 'vise-types/pce2_instrument';
import { normalizeRawAccount } from '~/api/api';
import { Loader } from '~/components';
import { EVENT_CATEGORIES } from '~/constants/amplitude';
import useAllocationsTemplate from '~/hooks/templates/useAllocationsTemplate';
import useRestrictionsTemplates from '~/hooks/templates/useRestrictionsTemplates';
import useAccount from '~/hooks/useAccount';
import useClientAccounts from '~/hooks/useClientAccounts';
import { useEnqueueCoachmark } from '~/hooks/useCoachmark';
import useEnqueueAlert from '~/hooks/useEnqueueAlert';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useFullHeightRootContainer from '~/hooks/useFullHeightRootContainer';
import useGetNewPce2Proposal from '~/hooks/useGetNewPce2Proposal';
import usePortfolioIntelligence, {
  getPortfolioIntelligenceSwrKey,
} from '~/hooks/usePortfolioIntelligence';
import ViseLogoBlack from '~/static/images/vise-logo-black.svg';
import { CloseIcon } from '~/synth/Icons';
import Stepper from '~/synth/Stepper';
import amplitude from '~/utils/amplitude';
import { SMALL_ACCOUNT_MIGRATION_STARTING_POINT } from './Constants';
import ScreenHistoryContext from './ScreenHistoryContext';
import useScreens from './Screens';
import SummaryLayout from './SummaryLayout';
import { BootstrapType, DraftPortfolio, DraftPortfolioDispatch, Screen } from './Types';
import {
  XrayContext,
  XrayData,
  XrayDispatchContext,
  XrayReducer,
  defaultXrayData,
} from './context/XrayContext';
import { PC2Container } from './screens/components';
import useDraftPortfolioReducer from './useDraftPortfolioReducer';
import {
  isSmallAccount,
  prereqsFulfilled,
  shouldShowActiveTiltsScreen,
  shouldSkipRestrictions,
} from './utils';

export const StyledHeaderPC2Container = styled(PC2Container)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    paddingRight: theme.spacing(4),
  },
}));

export const PCHeader = ({ onBack }: { onBack?: () => void }) => {
  const history = useHistory();
  return (
    <Box bgcolor="white" color="grey.800" height="60px" flexShrink={0} position="sticky" top={0}>
      <StyledHeaderPC2Container maxWidth="xl">
        <Box alignItems="center" display="flex" height="100%" justifyContent="space-between">
          <Box display="flex">
            <img alt="V" src={ViseLogoBlack} height="21" width="17" />
            <Box ml={2.25} textAlign="left">
              <Typography variant="h4">Portfolio creator</Typography>
            </Box>
          </Box>
          <Box textAlign="right">
            <IconButton
              aria-label="Exit"
              color="inherit"
              onClick={() => (onBack ? onBack() : history.goBack())}
              role="link"
              size="large"
            >
              <CloseIcon display="inline-flex" />
            </IconButton>
          </Box>
        </Box>
      </StyledHeaderPC2Container>
    </Box>
  );
};

function useBootstrapType() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchClientId = searchParams.get('clientId');
  const searchPid = searchParams.get('pid');

  if (searchClientId != null) {
    return 'client';
  }

  if (searchPid != null) {
    return 'portfolioIntelligence';
  }

  return null;
}

// Bootstraps data into the store when needed. If the `clientId` is present and the resulting client
// is a client of the current user, the client is set.
function useAccountAndClientBootstrapper({
  dpDispatch,
  draftPortfolio: { dirty },
}: {
  dpDispatch: DraftPortfolioDispatch;
  draftPortfolio: DraftPortfolio;
}) {
  const enqueueAlert = useEnqueueAlert();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchAccountId = searchParams.get('accountId');
  const searchClientId = searchParams.get('clientId');

  let bootstrapType: BootstrapType;
  if (dirty) {
    bootstrapType = 'NONE';
  } else if (searchClientId != null) {
    bootstrapType = 'CLIENT';
  } else if (searchAccountId != null) {
    bootstrapType = 'ACCOUNT';
  } else {
    bootstrapType = 'NONE';
  }

  // Only fetch if there is no client in the store yet to prevent browser navigation from
  // re-inserting a value from the URL.
  const { data: clientAccountsData, error: clientAccountsError } = useClientAccounts(
    bootstrapType === 'CLIENT' ? searchClientId : null
  );
  useEffect(() => {
    if (clientAccountsError != null) {
      enqueueAlert({
        message: `Error with client ID ${searchClientId}, select a client from options`,
      });
    }
  }, [clientAccountsError, enqueueAlert, searchClientId]);

  const { data: selectedAccountRawData, error: selectedAccountsError } = useAccount(
    bootstrapType === 'ACCOUNT' ? searchAccountId : null
  );
  const { data: selectedAccountData } = selectedAccountRawData ?? { data: undefined };
  useEffect(() => {
    if (selectedAccountsError != null) {
      enqueueAlert({
        message: `Error with account ID ${searchAccountId}, select a client from options`,
      });
    }
  }, [enqueueAlert, searchAccountId, selectedAccountsError]);

  useEffect(() => {
    let didBootstrap = false;
    // TODO: Consider inserting data into store if `bootstrapType` is 'none'
    if (bootstrapType === 'CLIENT' && clientAccountsData != null) {
      /* URL had no `searchAccountId`, just set the (valid) client */
      if (searchAccountId == null) {
        dpDispatch({
          clientId: searchClientId,
          type: 'SET_EXISTING_CLIENT',
        });
      } else {
        const existingPortfolio = clientAccountsData.find((datum) => datum.id === searchAccountId);
        /* Client is valid but provided accountId is not, set client and warn about bad account */
        if (existingPortfolio == null) {
          dpDispatch({
            clientId: searchClientId,
            type: 'SET_EXISTING_CLIENT',
          });
          enqueueAlert({
            message: `Error with account ID ${searchAccountId}, select an account from options`,
          });
        } /* All the data is good! Set client and account */ else {
          dpDispatch({
            clientId: searchClientId,
            existingPortfolio,
            type: 'SET_EXISTING_CLIENT_AND_ACCOUNT',
          });
        }
      }
      didBootstrap = true;
    } else if (bootstrapType === 'ACCOUNT' && selectedAccountData != null) {
      dpDispatch({
        existingPortfolio: normalizeRawAccount(selectedAccountData),
        type: 'SET_ACCOUNT',
      });
      didBootstrap = true;
    }
    if (didBootstrap) {
      dpDispatch({ bootstrappingState: 'READY', type: 'SET_BOOTSTRAPPING_STATE' });
      dpDispatch({
        bootstrapType,
        type: 'SET_BOOTSTRAP_TYPE',
      });
    }
  }, [
    bootstrapType,
    clientAccountsData,
    selectedAccountData,
    dpDispatch,
    enqueueAlert,
    searchAccountId,
    searchClientId,
  ]);
}

function usePortfolioIntelligenceBootstrapper({
  dpDispatch,
  draftPortfolio: { dirty },
}: {
  dpDispatch: DraftPortfolioDispatch;
  draftPortfolio: DraftPortfolio;
}) {
  const isFirstRun = useRef(true);
  const history = useHistory();
  const { path } = useRouteMatch();
  const enqueueCoachmark = useEnqueueCoachmark();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchPid = searchParams.get('pid');
  const { data: featureFlags } = useFeatureFlags();

  let bootstrapType: 'none' | 'portfolioIntelligence';
  if (dirty) {
    bootstrapType = 'none';
  } else if (searchPid != null) {
    bootstrapType = 'portfolioIntelligence';

    // Ensure the useSWR cache is empty for this key before fetching because this bootstrapper runs
    // just once and can't work with stale data. Because useSWR does not indicate whether data is
    // _going_ to be re-fetched, force a fetch here by emptying the cache prior to checking.
    //
    // * This must be done synchronously (i.e. not inside a `useEffect`) to guarantee the cache
    //   is cleared before the `usePortfolioIntelligence` call runs below
    if (isFirstRun.current) {
      cache.delete(getPortfolioIntelligenceSwrKey(searchPid));
    }
  } else {
    bootstrapType = 'none';
  }

  // Only fetch if there is no client in the store yet to prevent browser navigation from
  // re-inserting a value from the URL.
  const {
    data: portfolioIntelligenceData,
    error: portfolioIntelligenceError,
    isValidating,
  } = usePortfolioIntelligence(bootstrapType === 'portfolioIntelligence' ? searchPid : null);

  // If the loaded proposal is attached to an account (i.e. not sample), fetch the account so we can get its current
  // pce2 migration status.
  const includeSummary = featureFlags?.enable_optional_proposal_review === 'on';
  const { data: accountResponseData, error: accountResponseError } = useAccount(
    portfolioIntelligenceData?.[0] == null || !portfolioIntelligenceData?.[0].accountId
      ? null
      : portfolioIntelligenceData[0].accountId,
    includeSummary
  );

  const { data: allocationsTemplateData, error: allocationsTemplateError } = useAllocationsTemplate(
    portfolioIntelligenceData?.[0]?.allocationTemplateId,
    true
  );

  const { data: restrictionsTemplatesData, error: restrictionsTemplatesError } =
    useRestrictionsTemplates(portfolioIntelligenceData?.[0]?.restrictionTemplateIds, true);

  useEffect(() => {
    if (bootstrapType === 'portfolioIntelligence') {
      if (portfolioIntelligenceError != null) {
        enqueueCoachmark({
          content: `Refresh the page to try again.`,
          title: `Error loading portfolio ID ${searchPid}`,
          severity: 'error',
        });
        dpDispatch({ bootstrappingState: 'ERROR', type: 'SET_BOOTSTRAPPING_STATE' });
      } else if (portfolioIntelligenceData != null && portfolioIntelligenceData[0] == null) {
        enqueueCoachmark({
          content: 'Go back and click the "Edit" button to try again.',
          title: `No portfolio with ID ${searchPid} found`,
          severity: 'error',
        });
        dpDispatch({ bootstrappingState: 'ERROR', type: 'SET_BOOTSTRAPPING_STATE' });
      } else if (accountResponseError != null) {
        enqueueCoachmark({
          content: 'Refresh the page to try again.',
          title: `Error loading the account associated with portfolio ${searchPid}`,
          severity: 'error',
        });
        dpDispatch({ bootstrappingState: 'ERROR', type: 'SET_BOOTSTRAPPING_STATE' });
      } else if (allocationsTemplateError != null) {
        enqueueCoachmark({
          content: 'Refresh the page to try again.',
          title: `Error loading the allocations template associated with portfolio ${searchPid}`,
          severity: 'error',
        });
        dpDispatch({ bootstrappingState: 'ERROR', type: 'SET_BOOTSTRAPPING_STATE' });
      } else if (restrictionsTemplatesError != null) {
        enqueueCoachmark({
          content: 'Refresh the page to try again.',
          title: `Error loading the restrictions template associated with portfolio ${searchPid}`,
          severity: 'error',
        });
        dpDispatch({ bootstrappingState: 'ERROR', type: 'SET_BOOTSTRAPPING_STATE' });
      } else if (
        portfolioIntelligenceData != null &&
        (accountResponseData != null || !portfolioIntelligenceData[0].accountId)
      ) {
        dpDispatch({
          portfolioIntelligence: portfolioIntelligenceData,
          account:
            accountResponseData == null ? null : normalizeRawAccount(accountResponseData.data),
          portfolioIntelligenceId: searchPid as string,
          fillEtfExclusiveAssetClasses: true,
          type: 'SET_EXISTING_PORTFOLIO_INTELLIGENCE',
        });

        if (allocationsTemplateData != null && allocationsTemplateData.deletedAt == null) {
          dpDispatch({ type: 'ADD_ALLOCATIONS_TEMPLATE', template: allocationsTemplateData });
        }

        const validRestrictionsTemplates = restrictionsTemplatesData?.filter(
          (t) => t.deletedAt == null
        );
        if (validRestrictionsTemplates != null) {
          validRestrictionsTemplates.forEach((t) =>
            dpDispatch({ type: 'ADD_RESTRICTIONS_TEMPLATE', restrictions: t })
          );
        }

        dpDispatch({
          type: 'SET_ORIGINAL_DRAFT_PORTFOLIO',
        });

        dpDispatch({ bootstrappingState: 'READY', type: 'SET_BOOTSTRAPPING_STATE' });
      }
    }
  }, [
    accountResponseData,
    accountResponseError,
    bootstrapType,
    dpDispatch,
    enqueueCoachmark,
    history,
    isValidating,
    path,
    portfolioIntelligenceData,
    portfolioIntelligenceError,
    searchPid,
    allocationsTemplateError,
    allocationsTemplateData,
    restrictionsTemplatesError,
    restrictionsTemplatesData,
  ]);

  isFirstRun.current = false;
}

export default function PortfolioCreator2() {
  const history = useHistory();
  const location = useLocation();
  const bootstrapType = useBootstrapType();
  const [screenHistory, setScreenHistory] = useState<string[]>([]);
  const [xrayData, xrayDisptach] = useReducer(XrayReducer, defaultXrayData);
  const [draftPortfolio, dpDispatch] = useDraftPortfolioReducer({
    bootstrappingState: bootstrapType == null ? 'READY' : 'LOADING',
  });
  const screens = useScreens(isSmallAccount(draftPortfolio.accountSize));
  const { path } = useRouteMatch();
  const match = useRouteMatch<{ screen: string }>(`${path}/:screen`);
  const pathMatchScreenName = match?.params?.screen;
  const [currentScreenName, setCurrentScreenName] = useState<string>(
    pathMatchScreenName ?? 'select-client'
  );
  const currentStep = screens[currentScreenName]?.stepperValue;
  const { data: featureFlags } = useFeatureFlags();

  const setPortfolioCreatorPage = useCallback(
    (nextScreenName: string, direction: 'back' | 'next' = 'next') => {
      setCurrentScreenName(nextScreenName);
      if (direction === 'next') {
        setScreenHistory((prev) => [...prev, nextScreenName]);
      } else {
        setScreenHistory((prev) => {
          const current = [...prev];
          pullAt(current, current.length - 1);
          return current;
        });
      }
      history.replace(`${path}/${nextScreenName}`);
    },
    [history, path]
  );

  const { constructionInfo, accountSize } = draftPortfolio;

  const { etfExclusive, focus } = constructionInfo;

  let exclusions: AssetClassKey[] | undefined | null;

  if ('assetClassConcentrationLimits' in constructionInfo) {
    exclusions = constructionInfo.assetClassConcentrationLimits?.exclusions;
  }

  const { isLoading: pce2IsNewProposalLoading, getNewPce2Proposal } =
    useGetNewPce2Proposal('Summary error');

  useAccountAndClientBootstrapper({
    dpDispatch,
    draftPortfolio: draftPortfolio as DraftPortfolio,
  });

  usePortfolioIntelligenceBootstrapper({
    dpDispatch,
    draftPortfolio: draftPortfolio as DraftPortfolio,
  });

  // Make root of the app `height: 100%` so all descendant components can fit the height of the
  // viewport.
  useFullHeightRootContainer();

  // Warns user of unsaved changes when the tab/window is being closed. This enables a native level
  // warning that forces focus to the tab/window when it's being closed.
  useEffect(() => {
    function handleBeforeUnload(event: Event) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = true;
      return true;
    }
    if (draftPortfolio.dirty) window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      if (draftPortfolio.dirty) window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [draftPortfolio.dirty]);

  useEffect(() => {
    if (pathMatchScreenName) {
      setCurrentScreenName(pathMatchScreenName);
    }
  }, [pathMatchScreenName]);

  const goNextScreen = useCallback(
    (nextScreenKey = 'default', currentScreen: Screen) => {
      const nextScreenName = currentScreen.next?.[nextScreenKey];
      if (nextScreenName != null) {
        if (screens[nextScreenName].defaultsSetOnLand) {
          dpDispatch({
            type: 'SET_UNSET_PROPERTIES_TO_DEFAULT',
            propertyPaths: screens[nextScreenName].defaultsSetOnLand ?? [],
          });
        }
        setPortfolioCreatorPage(nextScreenName, 'next');
      }
    },
    [dpDispatch, setPortfolioCreatorPage, screens]
  );

  const goBack = useCallback(
    (currentScreen: Screen, lastScreen?: string, xrayData?: XrayData) => {
      const prevScreenName = currentScreen.getPrevScreen(
        draftPortfolio as DraftPortfolio,
        featureFlags,
        lastScreen,
        xrayData
      );
      setPortfolioCreatorPage(lastScreen ?? prevScreenName, 'back');
    },
    [draftPortfolio, setPortfolioCreatorPage, featureFlags]
  );

  const currentScreen = screens[currentScreenName];

  const deprecateScreenReplacement =
    currentScreen?.deprecateScreenReplacement &&
    currentScreen.deprecateScreenReplacement(featureFlags);

  useEffect(() => {
    if (deprecateScreenReplacement) {
      setPortfolioCreatorPage(deprecateScreenReplacement);
    }
    if (
      !prereqsFulfilled(currentScreen, {
        draftPortfolio: draftPortfolio as DraftPortfolio,
        searchPid: new URLSearchParams(location.search).get('pid'),
        featureFlags,
      })
    ) {
      setPortfolioCreatorPage('select-client');
    }
  }, [
    deprecateScreenReplacement,
    draftPortfolio,
    currentScreen,
    featureFlags,
    location.search,
    setPortfolioCreatorPage,
  ]);

  const currentOrder = screens[currentScreenName]?.order || -1;
  const [largestStepVisited, setLargestStepVisited] = useState(
    draftPortfolio.originalDraftPortfolio ? 1000 : currentOrder
  );
  useEffect(() => {
    if (currentOrder > largestStepVisited) {
      setLargestStepVisited(currentOrder);
    } else if (
      // Hacky, but if the account was a small account, then we want them to go through the rest of the flow without skipping steps
      currentOrder === SMALL_ACCOUNT_MIGRATION_STARTING_POINT &&
      size(constructionInfo.assetAllocation || {}) === 0 &&
      featureFlags?.disable_accounts_ui === 'on'
    ) {
      setLargestStepVisited(SMALL_ACCOUNT_MIGRATION_STARTING_POINT);
    }
  }, [
    currentOrder,
    largestStepVisited,
    constructionInfo.assetAllocation,
    featureFlags?.disable_accounts_ui,
  ]);
  const currentScreenEl = (
    <currentScreen.component
      setLargestStepVisited={setLargestStepVisited}
      dpDispatch={dpDispatch}
      draftPortfolio={draftPortfolio as DraftPortfolio}
      onBack={(lastScreen, xrayData) => {
        amplitude().logEvent('Select back', {
          category: EVENT_CATEGORIES.PORTFOLIO_CONSTRUCTION,
          page: currentScreenName,
        });
        goBack(currentScreen, lastScreen, xrayData);
      }}
      onComplete={({ portfolioIntelligence, executedProposal }) =>
        getNewPce2Proposal(
          portfolioIntelligence as Pick<
            PortfolioIntelligenceFull,
            'status' | 'id' | 'constructionInfo'
          >,
          executedProposal
        )
      }
      onContinue={(nextScreenName?: string) => goNextScreen(nextScreenName, currentScreen)}
    />
  );

  const screenContent = (
    <Box display="flex" height="100%" justifyContent="center" width="100%">
      <Box
        sx={{
          maxWidth: currentScreen.showSummary
            ? 1925 /* = max-width of StyledInnerStepperBox (1850px) + 75px right padding */
            : null,
        }}
        width="100%"
      >
        {currentScreen.showSummary ? (
          <SummaryLayout
            currentScreenName={currentScreenName}
            draftPortfolio={draftPortfolio as DraftPortfolio}
            largestStepVisited={largestStepVisited}
          >
            {currentScreenEl}
          </SummaryLayout>
        ) : (
          currentScreenEl
        )}
      </Box>
    </Box>
  );

  let newProposalLoadingContent: JSX.Element | undefined;
  if (pce2IsNewProposalLoading) {
    newProposalLoadingContent = <Loader showMessages />;
  }

  let stepper: React.ReactNode;
  if (!isSmallAccount(accountSize)) {
    stepper = (
      <Stepper linkReplace value={currentStep == null ? 'client-details' : currentStep}>
        <Stepper.Step
          onClick={() => setPortfolioCreatorPage('select-client')}
          value="client-details"
        >
          Client Details
        </Stepper.Step>
        <Stepper.Step
          onClick={() =>
            setPortfolioCreatorPage(
              constructionInfo.existingPortfolio === 'sample-portfolio'
                ? 'cash-allocation'
                : 'locked-positions'
            )
          }
          value="transition-settings"
        >
          Transition Settings
        </Stepper.Step>
        <Stepper.Step
          onClick={() => setPortfolioCreatorPage('build-or-use-allocation-template')}
          value="allocation"
        >
          Allocation
        </Stepper.Step>
        {!draftPortfolio.allocationTemplate?.tiltSelection ? (
          <Stepper.Step onClick={() => setPortfolioCreatorPage('active-tilts')} value="tilts">
            Tilts
          </Stepper.Step>
        ) : null}
        <Stepper.Step
          onClick={() => setPortfolioCreatorPage('build-restrictions')}
          value="restrictions"
        >
          Restrictions
        </Stepper.Step>
        {constructionInfo.existingPortfolio !== 'sample-portfolio' &&
          (constructionInfo.existingPortfolio?.taxable || xrayData.taxable) && (
            <Stepper.Step
              onClick={() => setPortfolioCreatorPage('capital-losses-and-gains')}
              value="taxes"
            >
              Taxes
            </Stepper.Step>
          )}
        <Stepper.Step onClick={() => setPortfolioCreatorPage('summary-landing')} value="summary">
          Summary
        </Stepper.Step>
      </Stepper>
    );
  } else {
    stepper = (
      <Stepper linkReplace value={currentStep == null ? 'client-details' : currentStep}>
        <Stepper.Step
          onClick={() => setPortfolioCreatorPage('select-client')}
          value="client-details"
        >
          Client Details
        </Stepper.Step>
        <Stepper.Step
          onClick={() => setPortfolioCreatorPage('risk-tolerance')}
          value="portfolio-focus"
        >
          Focus
        </Stepper.Step>
        {!isSmallAccount(draftPortfolio.accountSize) && (
          <Stepper.Step
            onClick={() => setPortfolioCreatorPage('build-or-use-strategy')}
            value="portfolio-strategy"
          >
            Strategy
          </Stepper.Step>
        )}
        <Stepper.Step
          onClick={() => setPortfolioCreatorPage('asset-classes')}
          value="asset-classes"
        >
          Asset Classes
        </Stepper.Step>
        {shouldShowActiveTiltsScreen({
          focus,
          etfExclusive,
          exclusions,
          accountSize,
          allocationTemplate: draftPortfolio.allocationTemplate,
        }) && (
          <Stepper.Step onClick={() => setPortfolioCreatorPage('active-tilts')} value="tilts">
            Tilts
          </Stepper.Step>
        )}
        {!shouldSkipRestrictions({ etfExclusive, exclusions, accountSize }) && (
          <Stepper.Step
            onClick={() => setPortfolioCreatorPage('restrictions')}
            value="restrictions"
          >
            Restrictions
          </Stepper.Step>
        )}
        <Stepper.Step onClick={() => setPortfolioCreatorPage('summary-landing')} value="summary">
          Summary
        </Stepper.Step>
      </Stepper>
    );
  }

  return (
    <XrayContext.Provider value={xrayData}>
      <XrayDispatchContext.Provider value={xrayDisptach}>
        <ScreenHistoryContext.Provider value={screenHistory}>
          <Helmet>
            <title>Portfolio Creator</title>
          </Helmet>
          {newProposalLoadingContent == null ? (
            <Box display="flex" flexDirection="column" height="calc(100% - 117px)">
              <PCHeader />
              {currentScreenName != null &&
              (!screens[currentScreenName]?.showStepper ||
                screens[currentScreenName].showStepper?.(draftPortfolio as DraftPortfolio)) ? (
                <Box
                  pl={8.75}
                  pr={2}
                  border={1}
                  borderTop={0}
                  borderLeft={0}
                  borderRight={0}
                  borderColor="grey.200"
                  m="auto"
                  maxWidth={1925}
                  width="100%"
                >
                  {stepper}
                </Box>
              ) : null}
              {screenContent}
            </Box>
          ) : (
            newProposalLoadingContent
          )}
        </ScreenHistoryContext.Provider>
      </XrayDispatchContext.Provider>
    </XrayContext.Provider>
  );
}
