import { Box, Card, IconButton, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as XIcon } from '~/static/images/icons/x.svg';

interface PopoverContentProps {
  title: React.ReactNode;
  body: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const PopoverCard = ({ title, body, onClose }: PopoverContentProps) => {
  return (
    <Box maxWidth="280px">
      <Card elevation={3}>
        <Box p={1.5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            border={1}
            pb={1.5}
            borderColor="grey.200"
            borderLeft={0}
            borderRight={0}
            borderTop={0}
            mb={1.5}
          >
            <Typography variant="h5" component="span">
              {title}
            </Typography>
            {onClose && (
              <Box pl={1.5}>
                <IconButton aria-label="close" size="small" color="inherit" onClick={onClose}>
                  <XIcon height="16" width="16" />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box color="grey.500">
            <Typography variant="body1">{body}</Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default PopoverCard;
