import { Avatar, Box, Button, Card, Grid, Skeleton, Typography } from '@mui/material';
import { tokens } from '@vise_inc/ds-vise';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { uploadOrgLogo, uploadUserProfilePhoto } from '~/api/api';
import SomethingWentWrong from '~/components/SomethingWentWrong';
import UpdatePassword from '~/components/modal/UpdatePassword';
import UploadImage from '~/components/modal/UploadImage';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useUser from '~/hooks/useUser';
import { ReactComponent as CameraIcon } from '~/static/images/icons/camera.svg';
import CardContent from '~/synth/CardContent';
import CardHeader from '~/synth/CardHeader';
import { updatePassword } from '../../actions/user';
import { Container } from '../../components';

export default function Settings() {
  const { data: featureFlags } = useFeatureFlags();
  const { data: userData, error: userError } = useUser(false, true, true);
  const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = React.useState(false);
  const [uploadPhotoModalOpen, setUploadPhotoModalOpen] = React.useState(false);
  const [uploadLogoModalOpen, setUploadLogoModalOpen] = React.useState(false);

  const dispatch = useDispatch();

  const onUpdatePasswordSubmit = async (form: { oldPassword: string; newPassword: string }) => {
    // FIXME: Dispatch + Thunk Redux type is not correct in the codebase
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return dispatch(updatePassword(form)) as any;
  };

  if (userError) {
    return (
      <SomethingWentWrong error={userError} description="There was an error loading user data." />
    );
  }

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Container>
        <Box mb={3} marginTop={1.5}>
          <Typography variant="h1">Settings</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%' }}>
              <CardHeader>
                <Typography variant="h3">Profile</Typography>
              </CardHeader>
              {userData == null ? (
                <Skeleton width="100%" height="20em" />
              ) : (
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <div>
                      <b>
                        {userData.firstName} {userData.lastName}
                      </b>
                      <Box mt={1}>{userData.userMetaData?.internalRole}</Box>
                    </div>
                    <Box display="flex" flexDirection="column" alignItems="center">
                      {userData.imgUrl != null ? (
                        <Box borderRadius="50%" width={96} height={96} mb={2} overflow="hidden">
                          <img src={userData.imgUrl} width={96} alt="profile" />
                        </Box>
                      ) : (
                        <Avatar
                          sizes="large"
                          sx={{ bgcolor: '#d5f3f6', color: '#1a767e', width: 96, height: 96 }}
                        >
                          {userData.firstName[0]}
                          {userData.lastName[0]}
                        </Avatar>
                      )}
                      {featureFlags?.enable_upload_images === 'on' ? (
                        <Button
                          variant="text"
                          size="small"
                          color="secondary"
                          onClick={() => setUploadPhotoModalOpen(true)}
                          startIcon={<CameraIcon />}
                          sx={{ mt: 1 }}
                        >
                          Change photo
                        </Button>
                      ) : null}
                    </Box>
                  </Box>
                </CardContent>
              )}
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%' }}>
              <CardHeader>
                <Typography variant="h3">Password & security</Typography>
              </CardHeader>
              {userData == null ? (
                <Skeleton width="100%" height="20em" />
              ) : (
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box color={tokens.palette.neutralCool[600]}>Email</Box>
                    <div>{userData.email}</div>
                  </Box>
                  <Box
                    textAlign="right"
                    mt={1}
                    color={tokens.palette.neutralCool[600]}
                    fontStyle="italic"
                    pb={2}
                    fontSize={12}
                  >
                    If you would like to change your email, please reach out to
                    clientservice@vise.com
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      borderTop: 1,
                      borderTopStyle: 'dotted',
                      borderTopColor: tokens.palette.neutralCool[300],
                    }}
                    py={2}
                  >
                    <Box color={tokens.palette.neutralCool[600]}>Password</Box>
                    <div>••••••••••••••••••••</div>
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={() => setUpdatePasswordModalOpen(true)}
                    sx={{ float: 'right' }}
                  >
                    Change password
                  </Button>
                </CardContent>
              )}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader>
                <Typography variant="h3">Organization</Typography>
              </CardHeader>
              {userData == null ? (
                <Skeleton width="100%" height="20em" />
              ) : (
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={1}
                    borderColor={tokens.palette.neutralCool[300]}
                    sx={{ borderBottomStyle: 'dotted' }}
                    pb={3}
                    mb={3}
                  >
                    <b>{userData.organizationName}</b>
                    <Box display="flex" flexDirection="column" alignItems="center">
                      {featureFlags?.enable_upload_images === 'on' ? (
                        <>
                          {userData.organization?.logoUrl == null ? (
                            <Box
                              p={4}
                              border={1}
                              borderRadius={1}
                              borderColor={tokens.palette.neutralCool[300]}
                              fontSize={12}
                              color={tokens.palette.neutralCool[600]}
                            >
                              No logo uploaded
                            </Box>
                          ) : (
                            <img src={userData.organization?.logoUrl} width={96} alt="logo" />
                          )}
                          <Button
                            variant="text"
                            size="small"
                            color="secondary"
                            onClick={() => setUploadLogoModalOpen(true)}
                            startIcon={<CameraIcon />}
                            sx={{ mt: 1 }}
                          >
                            Change logo
                          </Button>
                        </>
                      ) : null}
                    </Box>
                  </Box>
                  {[
                    { field: 'Email', value: userData.organization?.contactEmail },
                    { field: 'Phone', value: userData.organization?.contactPhone },
                  ].map(({ field, value }) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      my={1}
                      key={field}
                    >
                      <Box color={tokens.palette.neutralCool[600]}>{field}</Box>
                      <div>{value}</div>
                    </Box>
                  ))}
                  <Box
                    mt={3}
                    color={tokens.palette.neutralCool[600]}
                    fontStyle="italic"
                    fontSize={12}
                  >
                    This information is managed by clientservice@vise.com
                  </Box>
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
      <UpdatePassword
        open={updatePasswordModalOpen}
        onClose={() => {
          setUpdatePasswordModalOpen(false);
        }}
        onSubmit={onUpdatePasswordSubmit}
      />
      <UploadImage
        title="Add photo"
        dialogText="Add a profile photo for a touch of personalization to the Vise experience."
        open={uploadPhotoModalOpen}
        onClose={() => setUploadPhotoModalOpen(false)}
        handleSave={async (data) => {
          if (data?.get('image') == null || userData == null) return;
          await uploadUserProfilePhoto(data, userData.id);
        }}
        defaultImage={userData?.imgUrl}
      />
      <UploadImage
        title="Add logo"
        dialogText="Add a photo of your organization's logo if you would like it represented in deliverables, like proposal PDFs."
        open={uploadLogoModalOpen}
        onClose={() => setUploadLogoModalOpen(false)}
        handleSave={async (data) => {
          if (data?.get('image') == null || userData?.organizationId == null) return;
          await uploadOrgLogo(data, userData.organizationId);
        }}
        defaultImage={userData?.organization?.logoUrl}
      />
    </>
  );
}
