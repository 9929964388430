/** Single select dropdown */
import { Box, Theme, useTheme } from '@mui/material';
import omit from 'lodash/omit';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { OptionProps, OptionTypeBase, Props as ReactSelectProps, Styles } from 'react-select';
import AsyncReactSelect, { Props as ReactAsyncSelectProps } from 'react-select/async';
import AsyncReactCreatableSelect, {
  Props as ReactAsyncCreatableSelectProps,
} from 'react-select/async-creatable';
import ReactSelectCreatable from 'react-select/creatable';
import ReactSelect, { components, WindowedMenuList } from 'react-windowed-select';

function Option<T extends OptionTypeBase>(props: OptionProps<T>) {
  const { label, selectProps } = props;
  return (
    <components.Option {...props}>
      <Box justifyContent="space-between" display="flex">
        <Highlighter
          autoEscape
          highlightTag="strong"
          searchWords={selectProps.inputValue == null ? [] : [selectProps.inputValue.trim()]}
          textToHighlight={label}
        />
      </Box>
    </components.Option>
  );
}

const getSearchIcon = (theme: Theme) => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /%3E%3C/svg%3E")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    content: '" "',
    display: 'block',
    marginRight: theme.spacing(1),
    height: '1em',
    width: '1em',
  },
});

interface CustomSelectProps {
  error?: boolean;
  size?: 'small';
  optionHeight?: number;
  removeIndicatorSeparator?: boolean;
}
export interface SelectProps<T extends OptionTypeBase = { label: string; value: string }>
  extends ReactSelectProps<T>,
    CustomSelectProps {}

export interface AsyncSelectProps<T extends OptionTypeBase = { label: string; value: string }>
  extends ReactAsyncSelectProps<T>,
    CustomSelectProps {}

export interface AsyncCreatableSelectProps<
  T extends OptionTypeBase = { label: string; value: string }
> extends ReactAsyncCreatableSelectProps<T>,
    CustomSelectProps {}

function useSelectProps<T extends OptionTypeBase = { label: string; value: string }>(
  props: AsyncSelectProps<T> | SelectProps<T>
) {
  const themeContext = useTheme();
  const inputAdornmentStyle = props.isSearchable ? getSearchIcon(themeContext) : {};
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor:
        props.error && !state.isFocused ? themeContext.palette.error[500] : provided.borderColor,
      background: props.isDisabled ? themeContext.palette.grey[100] : provided.background,
      maxHeight: props.size === 'small' ? 34 : 44,
      minHeight: props.size === 'small' ? 34 : 44,
    }),
    menu: (provided) => ({
      ...provided,
      marginBottom: 60,
      zIndex: themeContext.zIndex.tooltip,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 340,
      minHeight: 52,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: themeContext.palette.grey[800],
      padding: '0px 8px',
      margin: props.size === 'small' ? '6px 0px' : '8px 0px',
      // borderLeft: `1px solid ${themeContext.palette.grey[400]}`,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      '&:hover': {
        color: 'inherit',
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      marginBottom: props.size === 'small' ? '6px' : '8px',
      marginTop: props.size === 'small' ? '6px' : '8px',
      backgroundColor: `${themeContext.palette.grey[400]}`,
      display: props.removeIndicatorSeparator ? 'none' : '',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: themeContext.palette.grey[800],
      padding: props.size === 'small' ? '6px 8px' : '8px',
      '&:hover': {
        color: 'inherit',
      },
    }),
    option: (provided) => ({
      ...provided,
      padding: themeContext.spacing(1.5, 1.5),
      height: props.optionHeight,
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      padding: themeContext.spacing(props.size === 'small' ? 0.25 : 1.0, 1.5),
      paddingRight: props.removeIndicatorSeparator ? '0' : undefined,
    }),
    group: (provided) => ({
      ...provided,
      paddingBottom: 0,
      borderBottom: `1px solid ${themeContext.palette.grey[300]}`,
      '&:last-of-type': {
        borderBottom: 'unset',
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      ...themeContext.typography.h6,
      paddingTop: themeContext.spacing(1.25),
      paddingBottom: themeContext.spacing(1.5),
      color: themeContext.palette.grey[500],
    }),
    input: (provided) => ({ ...provided, ...inputAdornmentStyle }),
    placeholder: (provided) => ({ ...provided, ...inputAdornmentStyle, fontSize: '1rem' }),
    singleValue: (provided) => ({ ...provided, ...inputAdornmentStyle, fontSize: '1rem' }),
  } as Styles;

  const themeFn = (theme) => ({
    ...theme,
    borderRadius: themeContext.shape.borderRadius,
    colors: {
      ...theme.colors,
      neutral30: themeContext.palette.grey[400],
      neutral40: themeContext.palette.grey[400],
      neutral50: themeContext.palette.grey[400],
      primary25: themeContext.palette.grey[100],
      primary50: themeContext.palette.grey[200],
      neutral80: themeContext.palette.grey[800],
      primary: themeContext.palette.primary.main,
    },
  });

  return {
    tabSelectsValue: false,
    components: {
      Option,
      ...props.components,
    },
    theme: themeFn,
    ...omit(props, ['components', 'styles']),
    styles: { ...customStyles, ...(props.styles || {}) },
  };
}

export function CreatableSelect(props) {
  const newProps = useSelectProps(props);
  return <ReactSelectCreatable {...newProps} />;
}

export default function Select<T extends OptionTypeBase = { label: string; value: string }>(
  props: SelectProps<T>
) {
  const newProps = useSelectProps<T>(props);
  return <ReactSelect<T> {...newProps} />;
}

export function AsyncSelect<T extends OptionTypeBase = { label: string; value: string }>(
  props: AsyncSelectProps<T>
) {
  const { components: customComponents, ...newProps } = useSelectProps<T>(
    props
  ) as AsyncSelectProps<T>;
  return (
    <AsyncReactSelect<T>
      components={{ ...customComponents, MenuList: WindowedMenuList }}
      {...newProps}
    />
  );
}

export function AsyncCreatableSelect<T extends OptionTypeBase = { label: string; value: string }>(
  props: AsyncCreatableSelectProps<T>
) {
  const { components: customComponents, ...newProps } = useSelectProps<T>(
    props
  ) as AsyncCreatableSelectProps<T>;
  return (
    <AsyncReactCreatableSelect<T>
      components={{ ...customComponents, MenuList: WindowedMenuList }}
      {...newProps}
    />
  );
}
