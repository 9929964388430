import useSWR from 'swr';
import { Custodian } from 'vise-types/custodian';
import { getCapitalGains } from '../api/api';
import generateSwrKey from '../api/generateSwrKey';

export const getCapitalGainsSwrKey = (
  accountNumber: string,
  custodianId: string,
  lockedPositions?: string[] | null, // If not declared will use locked positions of existing portfolio
  includeComplianceGainsLosses?: boolean | null
) =>
  generateSwrKey(
    `/portfolio/gains?account_number=${accountNumber}&custodian_id=${custodianId}${
      lockedPositions != null ? `&locked_positions=${lockedPositions}` : ''
    }&include_compliance_gains_losses=${includeComplianceGainsLosses ? 'true' : 'false'}`
  );

const useCapitalGains = (
  accountNumber: string | undefined | null,
  custodianKey: Custodian | undefined | null,
  lockedPositions?: string[] | undefined, // optional (If not declared will use locked positions of existing portfolio)
  includeComplianceGainsLosses?: boolean | undefined // optional
) => {
  const { data, error, isValidating, mutate } = useSWR(
    accountNumber == null || custodianKey == null
      ? null
      : getCapitalGainsSwrKey(
          accountNumber,
          custodianKey,
          lockedPositions,
          includeComplianceGainsLosses
        ),
    accountNumber == null || custodianKey == null
      ? undefined
      : () =>
          getCapitalGains(
            accountNumber,
            custodianKey,
            lockedPositions,
            includeComplianceGainsLosses
          )
  );
  return {
    data,
    isLoading: !error && !data,
    isValidating,
    error,
    mutate,
  };
};

export default useCapitalGains;
